import { InsulinProtocolModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { usePatientId } from "@madmedical/user";
import { useEffect } from "react";
import { useUpdateInsulinProtocolMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
    protocol: {
        id: string;
        instructions: string;
    };
    refetchProtocol: () => void;
}

const UpdateInsulinProtocolModalForm = ({
    open,
    onClose,
    protocol,
    refetchProtocol,
}: Props) => {
    const { id, instructions } = protocol;

    const { patientId } = usePatientId();

    const [updateProtocol, { isSuccess }] = useUpdateInsulinProtocolMutation();

    useEffect(() => {
        if (isSuccess) {
            refetchProtocol();
        }
    }, [isSuccess, refetchProtocol]);

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{
                    patientId,
                    id,
                    instructions,
                }}
                mutation={updateProtocol}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <InsulinProtocolModalForm
                        isSuccess={isSuccess}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default UpdateInsulinProtocolModalForm;
