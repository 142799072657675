import Svg, { Path, Rect } from "react-native-svg";

const GreenCoco = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M40.282 42.0117C40.282 42.0117 21.9982 47.7092 21.1724 74.5286C20.8689 84.3923 23.6156 91.2182 27.2375 95.8509C41.9451 109.447 66.6843 103.811 68.0089 83.9556C77.6112 60.1511 74.0508 44.9592 51.776 42.0125L40.282 42.0117Z"
            fill="#B6D2C6"
        />
        <Path
            d="M62.8125 42.6914H40.282C39.2745 43.0405 38.3012 43.4809 37.3746 44.007L37.039 44.8458C37.039 44.8458 37.3436 47.6786 41.2081 48.5459C42.1806 48.7591 43.0931 49.1851 43.8794 49.793C45.8537 51.337 50.9397 53.7812 61.5821 50.7842C61.8854 50.7261 62.1936 50.6619 62.5068 50.5918C63.2747 50.4128 64.0771 50.4443 64.8285 50.6828C65.5799 50.9213 66.2522 51.358 66.7741 51.9463C67.5732 52.8537 68.2751 53.8416 68.8683 54.8939C69.5018 56.0567 69.7663 57.3831 69.627 58.6985C69.4877 60.0138 68.9512 61.2563 68.088 62.2622C63.1924 68.0371 52.4222 82.8145 56.9821 96.5382C57.095 96.8875 57.1298 97.2571 57.0841 97.6212C57.0384 97.9853 56.9132 98.335 56.7174 98.6459C56.5216 98.9568 56.2599 99.2214 55.9505 99.4212C55.6412 99.6209 55.2917 99.751 54.9265 99.8023C54.7918 99.8218 54.653 99.839 54.5102 99.854C53.5999 99.9469 52.6802 99.8593 51.8042 99.5962C50.9282 99.3331 50.1134 98.8998 49.4068 98.3214C47.241 96.5473 45.316 94.5008 43.6803 92.2331C43.5751 92.0834 43.4196 91.9758 43.2419 91.9297C43.0642 91.8836 42.8758 91.902 42.7104 91.9815C42.545 92.061 42.4135 92.1964 42.3393 92.3636C42.2652 92.5308 42.2533 92.7188 42.3058 92.8939C42.8173 94.6418 43.5428 96.3206 44.4658 97.8925C44.6124 98.1329 44.6969 98.4058 44.7119 98.6866C44.7268 98.9674 44.6716 99.2476 44.5513 99.5021C44.431 99.7566 44.2493 99.9775 44.0223 100.145C43.7954 100.313 43.5303 100.422 43.2506 100.463C39.6216 100.954 32.3901 99.5536 27.2386 95.9313C29.6701 99.0106 32.7969 101.476 36.3653 103.129C41.9392 105.693 46.417 105.163 48.7698 104.567C49.6895 104.326 50.6646 104.406 51.532 104.794C52.7782 105.365 55.1493 106.066 59.2738 106.066C66.2334 106.066 73.311 97.7334 73.311 97.7334L80.1529 59.9438C80.1529 59.9438 76.4959 45.7426 62.8125 42.6914Z"
            fill="#7B9791"
        />
        <Path
            d="M37.6759 45.6417C38.8242 46.5259 40.8581 47.19 44.2205 46.8515C44.2205 46.8515 45.8371 49.4886 51.8845 49.4886C56.6473 49.4886 60.9002 48.0372 63.2879 45.2569C63.5297 44.9722 63.702 44.6356 63.7912 44.2736C63.8804 43.9115 63.8841 43.5339 63.8021 43.1702C63.7201 42.8065 63.5546 42.4666 63.3185 42.1772C63.0824 41.8877 62.7821 41.6566 62.4412 41.5019C57.961 39.449 47.7526 36.072 37.9243 42.0028C37.6154 42.187 37.3559 42.4428 37.1679 42.7484C36.9799 43.054 36.869 43.4002 36.8446 43.7577C36.8202 44.1151 36.883 44.4732 37.0278 44.8012C37.1726 45.1293 37.3949 45.4176 37.6759 45.6417Z"
            fill="#7B9791"
        />
        <Path
            d="M32.1268 22.9258H33.5626C34.143 22.9296 34.7083 23.1109 35.1816 23.4453C35.6549 23.7796 36.0134 24.2508 36.2084 24.7947C36.7724 26.3225 38.1122 28.3727 41.2987 29.2808C43.0905 29.7887 44.6248 30.9485 45.597 32.5298C46.4061 33.8557 48.2604 35.418 50.9639 36.1754C51.1333 37.8417 50.6707 39.1984 49.4797 40.1988C47.1842 39.6627 44.4993 38.4765 42.6422 35.8989C41.6921 34.579 40.3316 33.607 38.7709 33.1329C35.8937 32.2635 31.6557 29.812 32.1268 22.9258Z"
            fill="#B6D2C6"
        />
        <Path
            d="M49.4802 40.1972C50.9668 40.5484 52.4968 40.6824 54.0222 40.595V36.6276C52.9881 36.6032 51.9612 36.4507 50.9649 36.1738C50.1648 36.7935 49.1239 38.0398 49.4802 40.1972Z"
            fill="#7B9791"
        />
        <Path
            d="M58.2623 34.5634C57.3771 35.0359 56.4084 35.3339 55.4096 35.4409C53.9953 35.6127 52.0537 36.287 51.832 38.6682C51.6302 40.8348 52.8582 41.7405 53.948 42.1187C54.7407 42.3846 55.5928 42.4221 56.4061 42.227C56.6395 42.1729 56.9103 42.1033 57.2082 42.0129C57.7427 41.6228 58.3694 41.3766 59.0277 41.2981C59.887 40.8812 60.6847 40.3489 61.3985 39.7162C61.7598 39.3933 62.1621 39.1189 62.5952 38.9001C62.7569 38.7321 62.9555 38.6036 63.1754 38.5246C63.3953 38.4456 63.6306 38.4181 63.8629 38.4444C64.2555 38.3553 64.6568 38.3099 65.0596 38.3091C66.3592 38.3114 67.63 37.9274 68.7089 37.2065C68.8857 36.8989 69.1219 36.629 69.4039 36.4125C69.6858 36.196 70.008 36.037 70.3519 35.9447C70.5842 35.7937 70.8254 35.6555 71.0713 35.5278C72.4018 34.8378 73.6982 34.2082 74.9606 33.6391C75.3861 33.2602 75.9207 33.0242 76.4885 32.9645C79.8063 31.5473 83.2087 30.3351 86.6765 29.3348C87.3088 28.8477 87.9134 28.5742 88.4498 28.8315C89.9655 28.4126 91.3368 28.0593 92.5476 27.7345C93.227 27.5527 93.8389 27.1787 94.309 26.6579C94.779 26.1371 95.0872 25.4918 95.1961 24.8003L95.4515 23.1768C92.7604 23.4187 90.091 23.8586 87.4652 24.4929C87.03 24.9553 86.2549 25.1058 85.2524 25.0487C83.6143 25.4903 81.8543 26.0325 80.0305 26.6977C79.4506 27.1801 78.8389 27.4962 78.1538 27.419C75.6414 28.4272 73.2119 29.6292 70.8877 31.0141C69.9913 31.5508 69.0302 31.9726 68.0274 32.2693C67.5022 32.5783 66.9835 32.7564 66.4798 32.6254C65.8357 32.732 65.1845 32.7901 64.5316 32.7994C62.3287 32.8706 60.1765 33.4762 58.2623 34.5634Z"
            fill="#B6D2C6"
        />
        <Path
            d="M30.0837 87.6489C29.9373 87.6491 29.7929 87.6149 29.6623 87.5491C29.5316 87.4832 29.4185 87.3877 29.332 87.2701C26.684 83.6848 27.2897 78.8613 27.3169 78.6578C27.3494 78.4138 27.4779 78.1926 27.6743 78.043C27.8707 77.8933 28.1188 77.8273 28.364 77.8596C28.6093 77.8919 28.8316 78.0198 28.9821 78.2151C29.1325 78.4105 29.1988 78.6574 29.1664 78.9014C29.159 78.9587 28.6425 83.2037 30.8352 86.1731C30.9371 86.3113 30.9985 86.4749 31.0126 86.6457C31.0267 86.8165 30.993 86.9878 30.9151 87.1407C30.8373 87.2936 30.7184 87.422 30.5716 87.5117C30.4249 87.6014 30.256 87.6489 30.0837 87.6489Z"
            fill="#7B9791"
        />
        <Path
            d="M34.5678 87.1839C34.4409 87.184 34.3153 87.1583 34.1987 87.1083C34.0821 87.0583 33.9771 86.9852 33.89 86.8933C32.4108 85.3347 32.2704 82.8991 32.265 82.7963C32.2555 82.5527 32.3429 82.3152 32.5081 82.1351C32.6734 81.9551 32.9032 81.847 33.1479 81.8343C33.3926 81.8215 33.6326 81.9052 33.8158 82.0671C33.999 82.229 34.1108 82.4562 34.1269 82.6994C34.1269 82.7181 34.2436 84.5638 35.2456 85.6194C35.3705 85.7511 35.4539 85.9164 35.4856 86.0948C35.5172 86.2732 35.4957 86.4569 35.4236 86.6233C35.3516 86.7896 35.2321 86.9314 35.08 87.031C34.9279 87.1306 34.7499 87.1838 34.5678 87.1839Z"
            fill="#7B9791"
        />
        <Path
            d="M57.4361 50.0679C58.7438 52.3557 59.6983 54.8261 60.2676 57.3962C60.2927 57.5128 60.3575 57.6172 60.4509 57.6918C60.5443 57.7664 60.6608 57.8067 60.7806 57.8059C60.9004 57.8052 61.0163 57.7633 61.1087 57.6874C61.2012 57.6116 61.2645 57.5063 61.2881 57.3895C61.7991 54.7586 61.6402 52.0423 60.8257 49.4883L57.4361 50.0679Z"
            fill="#7B9791"
        />
        <Path
            d="M59.0277 41.2965C57.4502 40.0413 57.1644 38.2659 57.2133 36.9606C57.2177 36.8718 57.1909 36.7841 57.1375 36.7128C57.0841 36.6415 57.0074 36.5909 56.9205 36.5696C56.8337 36.5484 56.7421 36.5579 56.6616 36.5965C56.581 36.6351 56.5165 36.7004 56.479 36.7812C55.9016 38.057 55.5769 39.9809 57.2079 42.0111C57.8328 41.822 58.4414 41.583 59.0277 41.2965Z"
            fill="#7B9791"
        />
        <Path
            d="M63.8634 38.4448C63.3926 37.8303 63.1835 36.8021 63.0877 35.9895C63.0813 35.9285 63.0536 35.8717 63.0094 35.8288C62.9653 35.7859 62.9075 35.7597 62.846 35.7548C62.7846 35.7498 62.7233 35.7663 62.6728 35.8015C62.6222 35.8368 62.5857 35.8884 62.5694 35.9476C62.2982 36.9151 62.3076 37.9392 62.5966 38.9016C62.999 38.6999 63.4247 38.5479 63.8642 38.4492L63.8634 38.4448Z"
            fill="#7B9791"
        />
        <Path
            d="M70.3541 35.9462C67.8014 35.1669 68.0279 32.2705 68.0279 32.2705C68.0279 32.2705 66.9998 32.5411 66.4804 32.6266C66.3686 33.4297 66.2242 35.9819 68.7106 37.2063C69.0101 37.0077 69.2946 36.7876 69.5619 36.5477C69.8101 36.3273 70.075 36.1262 70.3541 35.9462Z"
            fill="#7B9791"
        />
        <Path
            d="M75.8488 31.1128C75.8118 31.0656 75.7605 31.0314 75.7026 31.0153C75.6446 30.9991 75.583 31.0018 75.5267 31.023C75.4704 31.0441 75.4224 31.0826 75.3896 31.1329C75.3568 31.1831 75.3411 31.2424 75.3446 31.3022C75.3936 32.1326 75.2062 33.2247 74.96 33.639C75.4757 33.4045 75.985 33.1796 76.488 32.9644C76.4769 32.2955 76.2532 31.6473 75.8488 31.1128Z"
            fill="#7B9791"
        />
        <Path
            d="M79.8499 29.2414C79.8649 29.2977 79.897 29.3481 79.942 29.3855C79.9869 29.423 80.0423 29.4456 80.1007 29.4504C80.1591 29.4552 80.2176 29.4419 80.2681 29.4124C80.3186 29.3828 80.3586 29.3384 80.3827 29.2852C80.6335 28.717 80.7738 27.8084 80.0327 26.6992C79.4131 26.9251 78.7871 27.1655 78.1547 27.4206C78.566 27.5619 78.9359 27.802 79.2316 28.1196C79.5274 28.4372 79.7397 28.8226 79.8499 29.2414Z"
            fill="#7B9791"
        />
        <Path
            d="M86.6778 29.3367C87.2887 29.1581 87.8797 28.9903 88.4508 28.8331C88.6768 28.0866 88.7048 27.2946 88.5321 26.5341C88.3594 25.7736 87.9919 25.0705 87.4652 24.4932C86.7581 24.658 86.0188 24.8422 85.2524 25.0487C85.254 25.0498 87.2346 26.3367 86.6778 29.3367Z"
            fill="#7B9791"
        />
        <Path
            d="M39.6451 85.8818C40.169 85.8818 40.5937 85.4592 40.5937 84.938C40.5937 84.4167 40.169 83.9941 39.6451 83.9941C39.1211 83.9941 38.6964 84.4167 38.6964 84.938C38.6964 85.4592 39.1211 85.8818 39.6451 85.8818Z"
            fill="#7B9791"
        />
        <Path
            d="M64.7859 99.05C69.1996 102.72 74.4318 105.287 80.0457 106.536C83.31 107.233 86.6835 107.255 89.9568 106.6C93.23 105.946 96.333 104.629 99.0729 102.731C99.6239 101.83 100.477 101.151 101.482 100.816C102.46 99.9299 103.362 98.9639 104.177 97.9281C104.542 96.7575 105.129 95.9195 105.918 95.3903C105.965 95.3113 106.011 95.232 106.057 95.1521C106.817 93.8493 107.44 92.472 107.916 91.0422C107.885 89.3585 108.196 87.6859 108.832 86.1251C108.908 84.375 108.728 82.6231 108.299 80.9243C104.629 66.236 93.3304 59.75 93.3304 59.75L64.7859 99.05Z"
            fill="#AA8D8C"
        />
        <Path
            d="M69.8102 68.9683C69.8102 68.9683 60.3867 81.0349 61.6082 92.4069C62.5182 100.88 69.8708 101.739 73.5602 101.626C74.1497 101.611 74.7268 101.455 75.2429 101.171C75.7589 100.887 76.1986 100.483 76.5247 99.9945C77.7994 98.0666 80.3721 94.8382 83.5346 94.4074C84.5967 94.2765 85.5936 93.8273 86.3931 93.1194C89.9046 89.9381 98.4122 81.2947 98.3429 72.2671C98.3525 69.8569 98.0028 67.4587 97.3053 65.1506C95.5339 59.5129 89.3579 56.4804 83.7427 58.3994C79.7468 59.7645 74.7465 62.7212 69.8102 68.9683Z"
            fill="#7B5F5E"
        />
        <Path
            d="M81.5682 63.9756C81.5682 63.9756 69.7685 70.4426 66.8655 86.492C66.5533 88.12 66.5524 89.7919 66.8628 91.4202C67.022 92.2469 67.2839 93.0506 67.6422 93.813C68.1786 94.9396 69.1313 95.8168 70.3018 96.2617C71.4722 96.7066 72.7701 96.6848 73.9249 96.201C74.6745 95.8842 75.3364 95.3923 75.8543 94.7669C76.9607 87.892 85.3653 78.4756 93.4196 69.1809C92.3659 62.5542 87.8579 62.4773 87.8579 62.4773C87.8579 62.4773 85.1637 61.8049 81.5682 63.9756Z"
            fill="white"
        />
        <Path
            d="M77.3099 97.0049C77.3099 97.0049 79.5613 98.8178 82.3284 100.681C82.5076 100.802 82.6376 100.982 82.695 101.189C82.7524 101.397 82.7335 101.618 82.6416 101.812C82.5498 102.007 82.391 102.163 82.194 102.251C81.9969 102.34 81.7745 102.356 81.5669 102.296C79.3848 101.685 77.3893 100.545 75.7591 98.9783L77.3099 97.0049Z"
            fill="#7B5F5E"
        />
        <Path
            d="M101.479 100.811C91.228 99.8969 84.3059 92.4443 84.3059 92.4443L81.566 93.57C81.566 93.57 88.734 101.538 99.072 102.728C99.9152 102.142 100.719 101.501 101.479 100.811Z"
            fill="#7B5F5E"
        />
        <Path
            d="M105.916 95.3866C105.162 94.1982 104.086 93.246 102.812 92.6401C102.652 92.5639 102.47 92.5438 102.297 92.5831C102.124 92.6224 101.969 92.7187 101.858 92.8565C101.746 92.9944 101.685 93.1656 101.684 93.3424C101.682 93.5192 101.741 93.6913 101.85 93.8307C102.765 95.0278 103.691 96.5012 104.174 97.9242C104.81 97.117 105.391 96.2693 105.916 95.3866Z"
            fill="#7B5F5E"
        />
        <Path
            d="M91.8669 85.7544C93.4706 86.7669 95.0015 87.8893 96.4483 89.1135C96.5521 89.2011 96.6814 89.2534 96.8172 89.2628C96.9531 89.2722 97.0884 89.2381 97.2034 89.1656C97.3184 89.0931 97.4071 88.986 97.4566 88.8598C97.5061 88.7336 97.5138 88.595 97.4785 88.4641C97.0162 86.7903 96.0044 84.3698 93.8229 82.749L91.8669 85.7544Z"
            fill="#7B5F5E"
        />
        <Path
            d="M107.918 91.0413C108.446 89.4513 108.754 87.797 108.832 86.1242C105.811 78.2584 97.5041 73.1465 97.5041 73.1465L96.7154 76.7994C96.7154 76.7994 105.108 81.1077 107.918 91.0413Z"
            fill="#7B5F5E"
        />
        <Path
            d="M86.3273 104.241C86.8512 104.241 87.2759 103.819 87.2759 103.297C87.2759 102.776 86.8512 102.354 86.3273 102.354C85.8033 102.354 85.3786 102.776 85.3786 103.297C85.3786 103.819 85.8033 104.241 86.3273 104.241Z"
            fill="#7B5F5E"
        />
        <Path
            d="M85.0824 88.4576C88.1335 85.5488 93.6336 79.316 93.6377 72.1847C93.6435 71.1793 93.5694 70.175 93.416 69.1812C91.5011 69.0645 89.078 69.7083 86.015 71.6197C76.3207 77.6695 73.2681 88.5428 75.8494 94.765C76.1173 94.4397 76.3427 94.082 76.5201 93.7002C77.6649 92.0413 79.3924 90.869 81.3626 90.414C82.7573 90.1077 84.0426 89.4317 85.0824 88.4576Z"
            fill="#E9EFF4"
        />
        <Path
            d="M44.5659 48.7151C44.5659 48.7151 41.5198 50.2877 38.2223 55.7452C38.0948 55.9565 37.8946 56.1147 37.6589 56.1904C37.4231 56.2661 37.1678 56.2543 36.9402 56.157C36.7125 56.0598 36.528 55.8838 36.4208 55.6616C36.3136 55.4394 36.291 55.1861 36.3571 54.9486C37.0471 52.4592 38.2726 49.6123 40.4465 47.1641L44.5659 48.7151Z"
            fill="#7B9791"
        />
        <Path
            d="M97.2528 69.1416C98.4307 69.9674 99.5426 70.8824 100.579 71.8786C100.664 71.9607 100.775 72.0106 100.894 72.0198C101.012 72.0291 101.13 71.9971 101.227 71.9294C101.324 71.8616 101.394 71.7624 101.426 71.6486C101.457 71.5349 101.448 71.4138 101.4 71.3061C100.464 69.1955 98.8732 67.4183 96.9052 65.3994L97.2528 69.1416Z"
            fill="#7B5F5E"
        />
        <Path
            d="M36.3731 66.3096C36.9772 63.5659 35.5526 60.9245 33.1912 60.4099C30.8298 59.8953 28.4258 61.7024 27.8217 64.4461C27.2177 67.1898 28.6423 69.8312 31.0037 70.3458C33.3651 70.8604 35.7691 69.0534 36.3731 66.3096Z"
            fill="#DCEAE4"
        />
    </Svg>
);

export default GreenCoco;
