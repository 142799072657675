import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { hasActiveFilter } from "../utils";
import Button from "../../../atoms/Button";
import FilterButton from "../../../molecules/FilterButton";
import { useDocuments } from "../../../providers/document";

const DocumentListEvaluationDesktopFilter = () => {
    const { filterChoices, onFilterChange } = useDocuments("evaluations");
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof typeof filterChoices>(key: TFilterKey) =>
        (selected: (typeof filterChoices)[TFilterKey][0]["key"][]) => {
            onFilterChange?.(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange?.("metricTypes", []);
        onFilterChange?.("doctors", []);
    };

    if (!filterChoices) {
        return null;
    }

    return (
        <HStack width="full" space={2}>
            <FilterButton
                isSearchable
                title={t("measures:type")}
                items={filterChoices.metricTypes}
                onChange={handleFilterChange("metricTypes")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("evaluatingDoctor")}
                items={filterChoices.doctors}
                onChange={handleFilterChange("doctors")}
                isMultiselect={true}
            />
            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default DocumentListEvaluationDesktopFilter;
