import { Box, HStack, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import AlertBabyIcon from "../../pics/Pregnancy/AlertBabyIcon";
import Paper from "../../atoms/Paper";
import IconButton from "../../atoms/IconButton";
import Button from "../../atoms/Button";

const PregnancyDashboardAlert = () => {
    const { t } = useTranslation();

    return (
        <Box width="full" my={2}>
            <Paper
                backgroundColor="#F4FCFA"
                borderColor="#A5E3D7"
                borderWidth={1}
                borderRadius={4}
                p={4}
                height="full"
                position="relative"
                overflow="hidden"
            >
                <Box
                    flexDir={"row"}
                    mb={2}
                    alignItems={"flex-start"}
                    justifyContent="space-between"
                    width="full"
                >
                    <Box
                        px={2}
                        flex={1}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                    >
                        <HStack alignItems={"start"}>
                            <Box mt={1}>
                                <AlertBabyIcon />
                            </Box>
                            <Box px={4} width={"auto"} flex={1}>
                                <Text fontWeight="medium" color="#0E6369">
                                    {t("pregnancy:packageActivev") + "!"}
                                </Text>
                                <Text color="#0E6369">
                                    {t("pregnancy:addDataAndGainAccess")}
                                </Text>
                                <Button
                                    variant="link"
                                    onPress={() => console.log("Hello")}
                                    p="0"
                                    mt={2}
                                    justifyContent={"flex-start"}
                                >
                                    {t("settings")}
                                </Button>
                            </Box>
                        </HStack>
                    </Box>
                    <IconButton
                        variant="ghostLink"
                        iconName="close"
                        size="md"
                        mt={-2}
                        mr={-2}
                        onPress={() => console.log("close")}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

export default PregnancyDashboardAlert;
