const baseStyle = () => ({
    // roundedRight: '4',
    px: 2,
    py: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightRadius: "sm",
    _text: {
        fontWeight: 400,
    },
    alignItems: "center",
    justifyContent: "center",
    _light: {
        bg: "white",
        borderColor: "gray.200",
        _text: {
            color: "gray.600",
        },
    },
});

export default { baseStyle };
