import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    CreateMeasurementCommentRequest,
    MeasurementComment,
    MeasurementCommentFilter,
    PatientIdRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["comments"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getComments: build.query<
                MeasurementComment[],
                PatientIdRequest & MeasurementCommentFilter
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/comments`,
                    params,
                }),
                providesTags: ["comments"],
            }),
            getComment: build.query<MeasurementComment, Ulid>({
                query: (id) => `medical/comments/${id}`,
            }),
            createComment: build.mutation<
                MeasurementComment,
                PatientIdRequest & CreateMeasurementCommentRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/comments`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["comments"],
            }),
            deleteComment: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `medical/comments/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["comments"],
            }),
        }),
    });

export const {
    useGetCommentsQuery,
    useGetCommentQuery,
    useCreateCommentMutation,
    useDeleteCommentMutation,
} = api;
