import type { OnFilterChange, OnSearchChange } from "@madmedical/ui";
import {
    PatientMeasurements,
    PatientMeasurementsProvider,
} from "@madmedical/ui";
import { useGetTreatedPatientsQuery, useRouteNavigate } from "@madmedical/user";
import type { DatePeriodVariant, Ulid } from "@madmedical/utils";
import { rangeToDate, useDebounce } from "@madmedical/utils";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "@madmedical/routing";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import {
    getAlertLevelChoices,
    getMetricTypeChoices,
} from "@madmedical/medical";
import type { AggregatedMeasurementFilter } from "../model";
import { useGetAggregatedMeasurementsQuery } from "../api";

const AggregatedMeasurements = () => {
    const { byPeriod } = useParams<{ byPeriod: DatePeriodVariant }>();
    const navigate = useRouteNavigate();
    const { data: patients } = useGetTreatedPatientsQuery();
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<AggregatedMeasurementFilter>({});
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300, () => {
        setPage(1);
    });
    const { currentData } = useGetAggregatedMeasurementsQuery({
        byPeriod: byPeriod ?? "day",
        ...filter,
        search: debouncedSearch,
        page,
    });

    // TODO: row click should lead to day/week/month view of metric type

    const filterChoices = useMemo(
        () => ({
            patients:
                patients?.map(({ userId, fullName }) => ({
                    key: userId,
                    text: fullName,
                    isSelected: !!filter.patientUserIds?.includes(userId),
                })) ?? [],
            metricTypes: getMetricTypeChoices(filter.metricTypes),
            alertLevels: getAlertLevelChoices(filter.alertLevel),
        }),
        [filter, patients]
    );

    const handleFilterChange = useCallback<
        OnFilterChange<typeof filterChoices>
    >((key, selected) => {
        setPage(1);

        // TODO: Why does type inference fail me?
        const diff =
            key === "metricTypes"
                ? { metricTypes: selected as MetricType[] }
                : key === "alertLevels"
                ? { alertLevel: selected[0] as AlertLevel }
                : key === "patients"
                ? { patientUserIds: selected as Ulid[] }
                : {};

        setFilter((prevFilter) => ({
            ...prevFilter,
            ...diff,
        }));
    }, []);

    const handleSearchChange: OnSearchChange = (search) => {
        setSearch(search);
    };

    const handleEndReached = useCallback(() => {
        if (!currentData) {
            return;
        }

        const { currentPage, pageCount } = currentData.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [currentData]);

    const handlePeriodChange = (byPeriod: DatePeriodVariant) => {
        setPage(1);
        navigate("patients_measurements", { byPeriod });
    };

    const adaptedMeasurements = useMemo(
        () =>
            currentData?.items.map(
                ({
                    dateTrunc,
                    dateRange,
                    opinionCount,
                    ...aggregatedMeasurement
                }) => ({
                    dateTrunc: new Date(dateTrunc),
                    dateRange: rangeToDate(dateRange),
                    evaluationCount: opinionCount,
                    ...aggregatedMeasurement,
                })
            ) ?? [],
        [currentData?.items]
    );

    return (
        <PatientMeasurementsProvider
            value={{
                patientMeasurements: adaptedMeasurements,
                onSearchChange: handleSearchChange,
                filterChoices,
                onFilterChange: handleFilterChange,
            }}
        >
            <PatientMeasurements
                period={byPeriod ?? "day"}
                onEndReached={handleEndReached}
                onPeriodChange={handlePeriodChange}
            />
        </PatientMeasurementsProvider>
    );
};

export default AggregatedMeasurements;
