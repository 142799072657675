import type { ReactNode } from "react";
import { Box, ScrollView } from "native-base";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../../providers/routing";
import Alert from "../../molecules/Alert";
import PreOpAlert from "./PreOpAlert";
import Evaluations from "./Evaluations";
import Charts from "./Charts";
import Devices from "./Devices";

interface Props {
    charts: ReactNode;
    insulinList?: ReactNode;
    pregnancyDashboard?: ReactNode | null;
}

const Health = ({ charts, insulinList, pregnancyDashboard }: Props) => {
    const navigate = useRouteNavigate();
    const { t } = useTranslation();

    return (
        <ScrollView>
            <Box
                width="100%"
                bgColor="gray.50"
                px={{
                    base: 4,
                    md: 0,
                }}
                pb={{
                    base: 90,
                    md: 0,
                }}
            >
                <PreOpAlert />
                {pregnancyDashboard}
                <Charts charts={charts} />
                <Alert
                    title={t("dashboard:baseInfo")}
                    showCloseButton={false}
                    description={t("dashboard:baseDescription")}
                    links={[
                        {
                            text: t("continue"),
                            onPress: () => {
                                navigate("onboarding", {});
                            },
                        },
                    ]}
                />
                <Evaluations />
                {insulinList}
                {isWeb && <Devices />}
            </Box>
            <Box h={10} w={1} />
        </ScrollView>
    );
};

export default Health;
