import { Text } from "native-base";
import BabyWeek01 from "./BabyWeekTexts/BabyWeek01";
import BabyWeek09 from "./BabyWeekTexts/BabyWeek09";
import BabyWeek10 from "./BabyWeekTexts/BabyWeek10";
import BabyWeek11 from "./BabyWeekTexts/BabyWeek11";
import BabyWeek12 from "./BabyWeekTexts/BabyWeek12";
import BabyWeek13 from "./BabyWeekTexts/BabyWeek13";
import BabyWeek14 from "./BabyWeekTexts/BabyWeek14";
import BabyWeek15 from "./BabyWeekTexts/BabyWeek15";
import BabyWeek16 from "./BabyWeekTexts/BabyWeek16";
import BabyWeek17 from "./BabyWeekTexts/BabyWeek17";
import BabyWeek18 from "./BabyWeekTexts/BabyWeek18";
import BabyWeek19 from "./BabyWeekTexts/BabyWeek19";
import BabyWeek20 from "./BabyWeekTexts/BabyWeek20";
import BabyWeek21 from "./BabyWeekTexts/BabyWeek21";
import BabyWeek22 from "./BabyWeekTexts/BabyWeek22";
import BabyWeek23 from "./BabyWeekTexts/BabyWeek23";
import BabyWeek24 from "./BabyWeekTexts/BabyWeek24";
import BabyWeek25 from "./BabyWeekTexts/BabyWeek25";
import BabyWeek26 from "./BabyWeekTexts/BabyWeek26";
import BabyWeek27 from "./BabyWeekTexts/BabyWeek27";
import BabyWeek28 from "./BabyWeekTexts/BabyWeek28";
import BabyWeek29 from "./BabyWeekTexts/BabyWeek29";
import BabyWeek30 from "./BabyWeekTexts/BabyWeek30";
import BabyWeek31 from "./BabyWeekTexts/BabyWeek31";
import BabyWeek32 from "./BabyWeekTexts/BabyWeek32";
import BabyWeek33 from "./BabyWeekTexts/BabyWeek33";
import BabyWeek34 from "./BabyWeekTexts/BabyWeek34";
import BabyWeek35 from "./BabyWeekTexts/BabyWeek35";
import BabyWeek36 from "./BabyWeekTexts/BabyWeek36";
import BabyWeek37 from "./BabyWeekTexts/BabyWeek37";
import BabyWeek38 from "./BabyWeekTexts/BabyWeek38";
import BabyWeek39 from "./BabyWeekTexts/BabyWeek39";
import BabyWeek40 from "./BabyWeekTexts/BabyWeek40";
import BabyWeek41 from "./BabyWeekTexts/BabyWeek41";
import BabyWeek02 from "./BabyWeekTexts/BabyWeek02";
import BabyWeek03 from "./BabyWeekTexts/BabyWeek03";
import BabyWeek04 from "./BabyWeekTexts/BabyWeek04";
import BabyWeek05 from "./BabyWeekTexts/BabyWeek05";
import BabyWeek06 from "./BabyWeekTexts/BabyWeek06";
import BabyWeek07 from "./BabyWeekTexts/BabyWeek07";
import BabyWeek08 from "./BabyWeekTexts/BabyWeek08";

interface Props {
    weekNr: number;
}

const PregnancyBabyWeekTexts = ({ weekNr }: Props) => {
    switch (weekNr) {
        case 1:
            return <BabyWeek01 />;
        case 2:
            return <BabyWeek02 />;
        case 3:
            return <BabyWeek03 />;
        case 4:
            return <BabyWeek04 />;
        case 5:
            return <BabyWeek05 />;
        case 6:
            return <BabyWeek06 />;
        case 7:
            return <BabyWeek07 />;
        case 8:
            return <BabyWeek08 />;
        case 9:
            return <BabyWeek09 />;
        case 10:
            return <BabyWeek10 />;
        case 11:
            return <BabyWeek11 />;
        case 12:
            return <BabyWeek12 />;
        case 13:
            return <BabyWeek13 />;
        case 14:
            return <BabyWeek14 />;
        case 15:
            return <BabyWeek15 />;
        case 16:
            return <BabyWeek16 />;
        case 17:
            return <BabyWeek17 />;
        case 18:
            return <BabyWeek18 />;
        case 19:
            return <BabyWeek19 />;
        case 20:
            return <BabyWeek20 />;
        case 21:
            return <BabyWeek21 />;
        case 22:
            return <BabyWeek22 />;
        case 23:
            return <BabyWeek23 />;
        case 24:
            return <BabyWeek24 />;
        case 25:
            return <BabyWeek25 />;
        case 26:
            return <BabyWeek26 />;
        case 27:
            return <BabyWeek27 />;
        case 28:
            return <BabyWeek28 />;
        case 29:
            return <BabyWeek29 />;
        case 30:
            return <BabyWeek30 />;
        case 31:
            return <BabyWeek31 />;
        case 32:
            return <BabyWeek32 />;
        case 33:
            return <BabyWeek33 />;
        case 34:
            return <BabyWeek34 />;
        case 35:
            return <BabyWeek35 />;
        case 36:
            return <BabyWeek36 />;
        case 37:
            return <BabyWeek37 />;
        case 38:
            return <BabyWeek38 />;
        case 39:
            return <BabyWeek39 />;
        case 40:
            return <BabyWeek40 />;
        case 41:
            return <BabyWeek41 />;
        default:
            return <Text>–</Text>;
    }
};

export default PregnancyBabyWeekTexts;
