import type { FieldValues } from "@madmedical/form";
import { useWatchForm } from "@madmedical/form";
import { useEffect, useState } from "react";
import useResponsive from "../../../util/useResponsive";
import Button from "../../../atoms/Button";

interface Props<TFieldValues extends FieldValues> {
    isDisabled: boolean;
    isSubmitting?: boolean;
    onPress: () => void;
    label: string;
    validate: (values: Partial<TFieldValues>) => boolean;
}

const PreOpNextButton = <TFieldValues extends FieldValues>({
    isDisabled,
    isSubmitting = false,
    onPress,
    label,
    validate,
}: Props<TFieldValues>) => {
    const { isSmallScreen } = useResponsive();
    const values = useWatchForm<TFieldValues>();
    const [isValid, setValid] = useState(!validate);

    useEffect(() => {
        if (!validate) {
            return;
        }

        setValid(validate(values));
    }, [values, validate]);

    return (
        <Button
            size="md"
            isDisabled={isDisabled || !isValid}
            isLoading={isSubmitting}
            isLoadingText={`${label}…`}
            onPress={onPress}
            flex={isSmallScreen ? 1 : "unset"}
        >
            {label}
        </Button>
    );
};

export default PreOpNextButton;
