import { useDropzone } from "react-dropzone";
import useUploadCommon from "./useUploadCommon";

export default () => {
    const { files, addFiles, resetFiles, formProps } = useUploadCommon();

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open: openSelectFile,
    } = useDropzone({
        multiple: true,
        noClick: true,
        onDrop: addFiles,
        onDragEnter: undefined,
        onDragOver: undefined,
        onDragLeave: undefined,
        accept: {
            "application/msword": [".doc"],
            "application/pdf": [".pdf"],
            "application/rtf": [".rtf"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
            "application/vnd.oasis.opendocument.text": [".odt"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
            "image/bmp": [".bmp"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "image/tiff": [".tif", ".tiff"],
            "text/plain": [".txt"],
        },
    });

    return {
        files,
        resetFiles,
        formProps: {
            ...formProps,
            dropzoneProps: getRootProps(),
            inputProps: getInputProps(),
            isDragActive,
            onSelectPress: openSelectFile,
        },
    };
};
