import { Box, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Exit = ({ onPreviousPress, onNextPress }: Props) => {
    const { t } = useTranslation();

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            isLastScreen
        >
            <Box
                flex={1}
                maxWidth={{
                    base: "full",
                    md: "2/3",
                }}
                p={{
                    base: 4,
                    md: 6,
                }}
            >
                <Text lineHeight="lg" fontWeight="medium" fontSize="md" mb={4}>
                    {t("respectedClient")}
                </Text>
                <Text lineHeight="lg" fontSize="md" color="gray.600" mb={4}>
                    {t("preop:thankYouForFillingOut")}
                </Text>
            </Box>
        </PreOpWrapper>
    );
};

export default Exit;
