import { useCallback } from "react";
import type { DateRange, DateRangeStrings } from "@madmedical/utils";
import { rangeToString } from "@madmedical/utils";
import { useDispatch, useSelector } from "../context";
import { bodyDateRangeSelector } from "./selectors";
import { setBodyDateRange as setDateRangeAction } from "./slice";

export default () => {
    const dateRange = useSelector(bodyDateRangeSelector);
    const dispatch = useDispatch();

    const setDateRange = useCallback(
        (newValue: DateRange | DateRangeStrings) => {
            dispatch(setDateRangeAction(rangeToString(newValue)));
        },
        [dispatch]
    );

    return { dateRange, setDateRange };
};
