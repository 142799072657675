import type {
    FieldValues,
    SubmitHandler,
    UseFormReturn,
} from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import type { ReactNode } from "react";
import { useState } from "react";
import type { DefaultValues } from "react-hook-form/dist/types/form";
import type {
    BaseQueryError,
    MutationActionCreatorResult,
} from "@madmedical/store";
import { handleError } from "./apiError";

interface ChildrenProps<Values extends FieldValues>
    extends Pick<UseFormReturn<Values>, "getValues" | "setValue"> {
    isSuccess: boolean;
    submitForm: () => void;
}

interface Props<Values extends FieldValues, MutationResult> {
    readonly defaultValues: DefaultValues<Values>;
    readonly mutation: (
        values: Values
    ) => MutationActionCreatorResult<MutationResult>;
    readonly resetOnSuccess?: boolean;
    readonly onSuccess?: (result: MutationResult) => void;
    readonly children: (props: ChildrenProps<Values>) => ReactNode;
}

const ApiFormWrapper = <Values extends FieldValues, MutationResult>({
    defaultValues,
    mutation,
    resetOnSuccess = false,
    onSuccess,
    children,
}: Props<Values, MutationResult>) => {
    const methods = useForm<Values>({ defaultValues });
    const [isSuccess, setSuccess] = useState(false);

    const {
        reset,
        setError,
        handleSubmit: makeHandleSubmit,
        getValues,
        setValue,
    } = methods;
    const onSubmit: SubmitHandler<Values> = async (values) => {
        try {
            const result = await mutation(values).unwrap();
            if (resetOnSuccess) {
                reset();
            }
            if (result) {
                setSuccess(true);
                onSuccess?.(result);
            }
        } catch (error) {
            handleError({ setError })(error as BaseQueryError);
        }
    };

    const handleSubmit = () => {
        void makeHandleSubmit(onSubmit)();
    };

    return (
        <FormProvider {...methods}>
            {children({
                getValues,
                setValue,
                isSuccess,
                submitForm: handleSubmit,
            })}
        </FormProvider>
    );
};

export default ApiFormWrapper;
