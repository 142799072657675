import { Text, VStack } from "native-base";
import { MetricType, forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../atoms/PressableLink";
import { thumbnailRoot, thumbnailUnit } from "./useStyles";

interface Props {
    metricType: MetricType;
}

const EmptyDashboardWidget = ({ metricType }: Props) => {
    const clickableMetricTypes = [
        MetricType.BloodSugar,
        MetricType.BloodPressure,
        MetricType.BloodOxygen,
        MetricType.BodyTemperature,
        MetricType.BodyWeight,
    ];
    const { t } = useTranslation();

    const content = (
        <>
            <Text fontSize="sm" fontWeight="medium" color="black">
                {t(`measures:${forHumans(metricType)}`)}
            </Text>
            <Text style={thumbnailUnit}>{t("measures:noData")}</Text>
        </>
    );

    return (
        <VStack style={thumbnailRoot}>
            {clickableMetricTypes.includes(metricType) ? (
                <PressableLink
                    route={"measurements"}
                    params={{ metricType: metricType }}
                    flex={1}
                >
                    {content}
                </PressableLink>
            ) : (
                content
            )}
        </VStack>
    );
};

export default EmptyDashboardWidget;
