import { useCallback, useMemo } from "react";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import useMe from "../useMe";

const useRoutePaths = () => {
    const {
        roles: { isDoctor, isCallCenter, isHotline, isAdmin },
    } = useMe();

    const isPatientInPath = isDoctor || isCallCenter || isHotline || isAdmin;

    const prefixPath = useCallback(
        (path: string) =>
            isPatientInPath ? `patients/:patientId/${path}` : path,
        [isPatientInPath]
    );

    const paths: Record<keyof AppRoutes, string> = useMemo(
        () =>
            ({
                dashboard: "",
                onboarding: "onboarding",
                preop: prefixPath("preop"),
                measurements: prefixPath("measurements/:metricType"),
                measurements_list: prefixPath("measurements/:metricType/list"),
                insulin: prefixPath("insulin"),
                insulin_administrations: prefixPath("insulin/administrations"),
                insulin_reminders: prefixPath("insulin/reminders"),
                opinions: prefixPath("opinions"),
                opinion: prefixPath("opinions/:opinionId"),
                opinion_edit: prefixPath("opinions/:opinionId/edit"),
                opinion_create: prefixPath("opinions/create/:metricType"),
                predictions: prefixPath("predictions"),
                prediction: prefixPath("predictions/:predictionId"),
                devices_connected: "devices",
                devices_all: "devices/all",
                device: "devices/:provider",
                documents_general: prefixPath("documents/general"),
                documents_opinion: prefixPath("documents/opinion"),
                document: prefixPath("documents/:documentId"),
                representatives: "representatives",
                patients: "patients",
                patient: "patients/:patientId",
                patients_measurements: "measurements/:byPeriod",
                callcenter_devices: "devices",
                callcenter_device: "devices/:deviceId",
                pregnancy_body: "pregnancy/body",
                pregnancy_baby: "pregnancy/baby",
                pregnancy_measurement: prefixPath("pregnancy/measurement"),
                pregnancy_feeling: prefixPath("pregnancy/feeling"),
                doctor_edit_pregnancy: prefixPath("pregnancy/edit"),
            } as const),
        [prefixPath]
    );

    const getPath = useCallback((route: RouteId) => paths[route], [paths]);

    return { getPath };
};

export default useRoutePaths;
