import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta13 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M95.8651 86.9254C96.0769 87.8504 96.01 88.8089 95.8651 89.7339C95.5531 91.7512 95.007 93.7461 94.4163 95.6965C93.937 97.2679 93.4467 99.196 91.8752 100.01C91.0282 100.444 90.0809 100.467 89.1558 100.299C88.9998 101.648 88.4202 102.952 87.4729 103.933C86.4922 104.947 85.1993 105.582 83.8174 105.783C83.6613 105.939 83.4942 106.095 83.3381 106.251C79.0919 110.085 73.1516 111.723 67.5122 111.3C61.4159 110.843 55.7208 107.934 51.1513 103.977C46.1806 99.6864 42.5251 94.1251 39.6274 88.2739C38.1117 85.209 36.7631 82.0439 35.5594 78.8452C34.3112 75.5129 33.2747 72.0579 32.8958 68.5138C32.3162 63.1196 33.4307 57.2573 37.064 53.1225C36.83 52.621 36.5848 52.1083 36.373 51.5957C35.0133 48.3302 34.1552 44.8529 33.8877 41.32C33.6202 37.8316 33.9546 34.2875 34.9688 30.9217C35.8381 28.0463 37.2423 25.338 39.0701 22.9418C40.8756 20.5791 43.1046 18.5284 45.6457 16.9904C48.209 15.4412 51.0733 14.4271 54.0602 14.115C57.0693 13.7918 60.1342 14.1484 63.0096 15.0623C65.8962 15.9874 68.6044 17.4919 70.8891 19.4869C73.1962 21.5153 75.0239 24.0563 76.261 26.8649C77.5204 29.7292 78.178 32.8498 78.2114 35.9704C78.2449 38.712 77.7991 41.4537 76.8629 44.0282C76.952 44.63 77.1192 45.2207 77.3532 45.8003C77.6987 46.625 78.2337 47.2826 78.7352 48.007C79.1699 48.62 79.4819 49.3555 79.1476 50.1022C78.8355 50.8155 78.1111 51.2725 77.487 51.7071C76.8629 52.1418 76.2387 52.543 75.6035 52.9554C75.7707 53.847 75.7261 54.7831 75.4363 55.6413C76.3836 56.1428 77.2975 56.7224 78.1111 57.4134C79.8052 58.8622 81.1091 60.9129 81.2206 63.1976C81.2874 64.6911 80.7859 66.2402 79.7829 67.3436C80.8862 67.9231 81.9339 68.6253 82.8923 69.4277C84.9876 71.1775 86.6705 73.3842 87.8519 75.8361C88.8661 77.9313 89.479 80.1938 89.6462 82.5119C91.4851 82.8017 93.3686 83.3255 94.6949 84.7187C95.2856 85.3316 95.6979 86.0783 95.8874 86.9031L95.8651 86.9254Z"
            fill="#FFDCD0"
        />
        <Path
            d="M75.78 55.1058C71.0322 55.6073 67.5438 59.3074 65.0919 63.119C64.3898 64.2224 63.7322 65.348 63.0747 66.4848C60.9125 63.9215 58.717 61.3804 56.2985 59.0511C54.9054 57.7137 53.4454 56.4432 51.8962 55.3064C50.4808 54.281 48.8759 53.3114 47.0927 53.1554C45.4432 53.0105 43.6266 53.59 42.6904 55.0278C42.5344 55.2618 42.8799 55.5516 43.0359 55.3064C43.8495 54.0581 45.3875 53.5009 46.8364 53.5789C48.5861 53.6681 50.1576 54.5931 51.5396 55.5962C54.5376 57.7694 57.1678 60.4108 59.6308 63.1636C61.0128 64.7128 62.3502 66.2954 63.6876 67.8891C63.866 68.1009 64.2337 67.8445 64.0443 67.6328C63.8214 67.3653 63.5985 67.1089 63.3867 66.8415C65.6492 62.9184 68.1122 58.6945 72.325 56.5992C73.4284 56.0531 74.6097 55.6853 75.8357 55.5516C76.1143 55.5181 76.0697 55.0835 75.7911 55.1169L75.78 55.1058Z"
            fill="#DD8F8C"
        />
        <Path
            d="M61.9051 80.8395C60.824 80.4048 59.9212 79.9813 58.9182 79.4575C58.829 79.4129 58.7399 79.3572 58.6507 79.3126C57.9486 78.9337 57.2687 78.5213 56.6 78.0866C55.3964 77.3065 54.2484 76.4372 53.1562 75.4899C51.451 74.0076 49.7236 72.2689 47.3831 71.89C47.1045 71.8454 47.1713 71.4108 47.45 71.4554C48.6759 71.656 49.8016 72.2244 50.8158 72.9488C51.7854 73.6398 52.6436 74.4645 53.5574 75.2447C54.4156 75.9914 55.3072 76.6824 56.2434 77.3176C56.4217 77.4402 56.6 77.5517 56.7784 77.6743C57.5362 78.1758 58.2941 78.6216 59.0631 79.0451C60.0327 79.5578 61.0246 80.0259 62.0834 80.4494C62.3509 80.5497 62.1725 80.9509 61.9051 80.8506V80.8395Z"
            fill="#DD8F8C"
        />
        <Path
            d="M75.8272 65.0365C75.0136 66.764 73.8991 68.3243 72.5505 69.684C72.3499 69.8846 72.6954 70.1632 72.896 69.9626C74.2669 68.5806 75.4037 66.9758 76.2284 65.226C76.351 64.9697 75.9498 64.7802 75.8272 65.0477V65.0365Z"
            fill="#DD8F8C"
        />
        <Path
            d="M79.3488 66.9644C78.3123 66.552 77.365 65.9502 76.5402 65.2035C76.3285 65.014 76.0499 65.3595 76.2616 65.549C77.1086 66.3291 78.1005 66.9421 79.1705 67.3767C79.4379 67.4771 79.6163 67.0758 79.3488 66.9755V66.9644Z"
            fill="#DD8F8C"
        />
        <Path
            d="M75.8839 58.3937C75.7947 58.1262 75.3824 58.2822 75.4715 58.5497C75.9396 59.9317 75.9842 61.4251 75.6387 62.8405C75.5718 63.1192 76.0065 63.186 76.0734 62.9074C76.4411 61.414 76.3743 59.8425 75.8839 58.3825V58.3937Z"
            fill="#DD8F8C"
        />
        <Path
            d="M62.2283 42.1334C61.8605 41.4535 61.4927 40.7626 60.9466 40.2053C60.4005 39.6481 59.6761 39.2914 58.8959 39.2134C57.3133 39.0351 55.8533 39.871 54.9172 41.1081C53.8807 42.4677 53.4683 44.2509 53.3569 45.9338C53.2454 47.6056 53.5352 49.3442 54.5605 50.7039C55.6193 52.1082 57.4359 52.8995 59.1634 52.3422C59.4309 52.2531 59.2749 51.8407 59.0074 51.9299C57.592 52.3868 56.054 51.785 55.1066 50.6816C54.137 49.5448 53.7692 48.0403 53.7804 46.5691C53.7804 45.02 54.1036 43.3705 54.8614 42.0108C55.5636 40.7737 56.7784 39.7484 58.2384 39.6481C59.0185 39.5923 59.8098 39.8152 60.4117 40.3279C61.0358 40.8517 61.437 41.5984 61.8159 42.3117C61.9497 42.5569 62.362 42.4009 62.2283 42.1557V42.1334Z"
            fill="#DD8F8C"
        />
        <Path
            d="M66.4432 60.9573C63.891 61.325 61.2831 60.935 58.9538 59.8316C58.6974 59.709 58.508 60.1102 58.7755 60.2328C61.1716 61.3696 63.8799 61.7708 66.4989 61.3919C66.7776 61.3473 66.733 60.9127 66.4544 60.9573H66.4432Z"
            fill="#DD8F8C"
        />
        <Path
            d="M90.57 85.6877C90.0462 86.0332 89.5001 85.8883 89.032 85.5763C89.3998 84.6289 89.6672 83.6593 89.8233 82.6563C89.8679 82.3776 89.4332 82.2996 89.3886 82.5894C89.2437 83.5144 89.0097 84.4172 88.6753 85.2865C88.5082 85.1305 88.3521 84.9521 88.2295 84.785C87.6389 83.9825 87.1485 83.1021 86.6804 82.2216C85.7442 80.4384 84.9418 78.5772 83.9721 76.8051C82.2781 73.6957 79.8596 70.7868 76.2709 69.9175C72.7937 69.0816 69.0601 70.1627 66.3296 72.4029C65.0591 73.4393 64.0003 74.721 63.2313 76.1699C62.3731 77.7636 61.9384 79.5468 61.7155 81.33C61.2363 85.0524 61.6375 88.9532 60.4116 92.553C60.2667 92.9877 60.0884 93.4223 59.8878 93.8347C59.7652 94.091 60.1664 94.2805 60.289 94.013C61.8939 90.5915 61.7044 86.7465 61.9719 83.0686C62.1168 81.1963 62.3731 79.3016 63.0641 77.5407C63.6771 76.0027 64.6133 74.6207 65.7946 73.4616C68.3134 70.9874 71.9578 69.6389 75.4796 70.2184C77.3409 70.5193 79.0015 71.3998 80.3723 72.6815C81.6651 73.8963 82.6682 75.3897 83.5263 76.9389C84.496 78.6998 85.2873 80.5499 86.2234 82.3331C86.6804 83.1912 87.1596 84.0494 87.7169 84.8518C87.9398 85.1639 88.1961 85.476 88.497 85.7323C88.497 85.7323 88.7422 85.9552 88.7868 85.9552C89.0654 86.1335 89.3663 86.2561 89.7118 86.2895C90.1019 86.323 90.4808 86.245 90.804 86.0221C91.0381 85.866 90.7817 85.5094 90.5477 85.6654L90.57 85.6877Z"
            fill="#DD8F8C"
        />
        <Path
            d="M89.0786 99.9647C87.3734 97.2676 85.6125 94.5147 83.0492 92.5532C80.4078 90.5471 77.0754 89.3992 74.891 86.8135C73.7319 85.4427 72.9852 83.6372 73.2861 81.8206C73.3307 81.542 72.8961 81.4751 72.8515 81.7537C72.584 83.3363 73.0632 84.93 73.9214 86.2563C74.8018 87.616 76.0835 88.6636 77.4209 89.5441C78.8698 90.5025 80.4189 91.2938 81.8678 92.2523C83.2721 93.1885 84.4869 94.3253 85.5568 95.6404C86.7382 97.0781 87.7301 98.6607 88.722 100.232C88.878 100.477 89.2346 100.21 89.0786 99.9758V99.9647Z"
            fill="#DD8F8C"
        />
        <Path
            d="M83.6387 105.404C81.5657 104.925 79.7937 103.42 78.9132 101.492C77.9993 99.5086 78.1777 97.2238 79.2364 95.3292C79.8383 94.2593 80.7187 93.3565 81.7886 92.7324C82.0338 92.5875 81.7775 92.2309 81.5323 92.3758C79.5373 93.5348 78.1888 95.6747 77.9213 97.9483C77.6538 100.233 78.5677 102.484 80.2283 104.045C81.1645 104.925 82.3236 105.549 83.5718 105.839C83.8505 105.906 83.9173 105.471 83.6387 105.404Z"
            fill="#DD8F8C"
        />
        <Path
            d="M83.0499 101.302C83.1502 100.444 82.5261 99.6752 81.6456 99.5972C81.6456 98.9397 81.5899 98.2264 81.1664 97.6914C80.7874 97.2122 80.1745 96.9336 79.5726 97.1342C79.3052 97.2233 79.4612 97.6357 79.7287 97.5465C80.219 97.3905 80.7206 97.7471 80.9435 98.1706C81.211 98.6722 81.1998 99.2852 81.1998 99.8424C81.1998 99.965 81.3335 100.043 81.445 100.043C82.2474 99.9873 82.827 100.779 82.5372 101.536C82.4703 101.704 82.6375 101.915 82.8158 101.837C83.4957 101.536 84.2313 102.049 84.1421 102.796C84.1087 103.074 84.5433 103.152 84.5767 102.863C84.6993 101.938 83.908 101.191 83.0387 101.314L83.0499 101.302Z"
            fill="#DD8F8C"
        />
        <Path
            d="M73.9994 44.4185C72.4614 45.7893 70.0763 45.6667 68.7166 44.0842C68.5272 43.8724 68.1705 44.1287 68.36 44.3405C69.8534 46.0903 72.5282 46.3132 74.2557 44.764C74.4675 44.5745 74.2111 44.2179 73.9994 44.4074V44.4185Z"
            fill="#5D6982"
        />
        <Path
            opacity="0.3"
            d="M69.5843 50.8816C69.863 51.4612 70.1527 52.1076 70.0301 52.7763C69.9298 53.3335 69.5732 53.8351 69.1051 54.1583C68.1021 54.8715 66.7089 54.8158 65.5944 54.4257C65.1152 54.2586 64.6805 54.0134 64.2905 53.7125C63.41 53.0883 62.6744 52.2079 62.2955 51.1937C61.4931 49.0761 62.5296 46.3456 64.9815 46.0558C66.1294 45.9221 67.1324 46.4459 67.79 47.3709C68.1355 47.8502 68.3807 48.3851 68.637 48.909C68.9602 49.5665 69.2723 50.2241 69.5843 50.8705V50.8816Z"
            fill="#D16B7D"
        />
        <Mask
            id="mask0_1024_101427"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101427)">
            <Path
                d="M59.3664 81.1153L59.9816 79.7473L57.2456 78.5168L56.6304 79.8848L59.3664 81.1153ZM107.498 52L108.1 53.3742L107.498 52ZM112.911 30.9423C113.708 30.7147 114.169 29.8844 113.941 29.0879C113.714 28.2913 112.884 27.8301 112.087 28.0577L112.911 30.9423ZM81.9984 83.0001L80.9399 81.9372L81.9984 83.0001ZM87.6082 55.009C89.4935 52.9352 91.9011 53.0873 95.6184 53.7283C99.1148 54.3311 103.606 55.3401 108.1 53.3742L106.897 50.6258C103.39 52.1601 99.8817 51.4191 96.1282 50.7719C92.5955 50.1628 88.5031 49.5648 85.3884 52.991L87.6082 55.009ZM108.1 53.3742C110.68 52.2454 111.984 49.9688 112.62 47.5235C113.249 45.1037 113.285 42.3095 113.19 39.7544C113.09 37.0676 112.861 34.8163 112.868 32.95C112.871 32.0395 112.937 31.4137 113.049 31.0282C113.165 30.6263 113.212 30.8564 112.911 30.9423L112.087 28.0577C110.912 28.3936 110.396 29.4048 110.168 30.1921C109.934 30.9957 109.872 31.9672 109.868 32.939C109.861 34.8375 110.104 37.4914 110.192 39.8663C110.286 42.3729 110.229 44.7971 109.716 46.7684C109.21 48.7142 108.324 50.0016 106.897 50.6258L108.1 53.3742ZM56.6304 79.8848C55.3291 82.7783 55.5158 85.4791 57.0452 87.5792C58.5138 89.596 61.0243 90.785 63.8365 91.2142C69.4855 92.0764 77.0781 90.0167 83.0568 84.063L80.9399 81.9372C75.5879 87.2669 68.9319 88.9572 64.2892 88.2485C61.9556 87.8924 60.3096 86.9657 59.4703 85.8132C58.6917 84.744 58.419 83.2219 59.3664 81.1153L56.6304 79.8848ZM83.0568 84.063C87.8854 79.2545 86.5807 73.5284 85.6087 68.6549C84.5954 63.575 83.843 59.1508 87.6082 55.009L85.3884 52.991C80.5009 58.3672 81.6773 64.2817 82.6666 69.2417C83.6971 74.4081 84.4688 78.423 80.9399 81.9372L83.0568 84.063Z"
                fill="#DD8F8C"
            />
        </G>
    </Svg>
);

export default Placenta13;
