import { isDaily } from "@madmedical/medical";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import { HStack, Text, VStack } from "native-base";
import type { Ulid } from "@madmedical/utils";
import { formatTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import ActionButton from "../ActionButton";
import Badge from "../../atoms/Badge";

interface Props {
    id: Ulid;
    displayValue: string;
    measuredAt: Date;
    manual: boolean;
    alertLevel: AlertLevel | null;
    metricType: MetricType;
    deleted: boolean;
    onDeletePress: (id: Ulid) => void;
}

const MeasurementListItem = ({
    id,
    displayValue,
    measuredAt,
    manual,
    alertLevel,
    metricType,
    deleted,
    onDeletePress,
}: Props) => {
    const { t } = useTranslation();
    const ALERT_LEVELS = {
        0: {
            text: t("measures:unmeasured"),
            variant: "ghost",
        },
        1: {
            text: t("measures:ok"),
            variant: "ghostSuccess",
        },
        2: {
            text: t("measures:warning"),
            variant: "ghostWarning",
        },
        3: {
            text: t("measures:alert"),
            variant: "ghostError",
        },
    } as const;

    return (
        <HStack
            space={4}
            justifyContent="space-between"
            alignItems="center"
            bgColor="white"
            px={4}
            py={5.5}
            borderBottomColor="gray.100"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            width="full"
        >
            <VStack>
                <Text fontSize="md" fontWeight="medium">
                    {displayValue}
                </Text>

                <HStack space={1.5} alignItems="center" mt={1}>
                    {alertLevel ? (
                        <Badge
                            variant={ALERT_LEVELS[alertLevel].variant}
                            size="sm"
                        >
                            {ALERT_LEVELS[alertLevel].text}
                        </Badge>
                    ) : null}
                    {!isDaily(metricType) && (
                        <Text lineHeight={1} fontSize="xs" color="gray.600">
                            {formatTime(measuredAt)}
                        </Text>
                    )}
                    {manual && !deleted && (
                        <Badge variant="ghost" size="xs">
                            {t("measures:manual")}
                        </Badge>
                    )}
                    {deleted && (
                        <Badge variant="ghost" size="xs">
                            {t("measures:deleted")}
                        </Badge>
                    )}
                </HStack>
            </VStack>
            <ActionButton
                actions={
                    manual && !deleted
                        ? [
                              {
                                  text: t("delete"),
                                  iconName: "trashCan",
                                  onPress: () => {
                                      onDeletePress(id);
                                  },
                              },
                          ]
                        : []
                }
            />
        </HStack>
    );
};

export default MeasurementListItem;
