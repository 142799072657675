import { Box, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import DocumentListCard from "../../molecules/DocumentListCard";
import ListHeader from "../../molecules/ListHeader";
import Headline from "../../molecules/Headline";
import Paper from "../../atoms/Paper";
import type { DocumentGeneral } from "../../providers/document";
import useDownloadPreview from "../../util/useDownloadPreview";

interface Props {
    documents: DocumentGeneral[];
}

// TODO: needs refact with new DocumentListItem

const AttachmentSection = ({ documents }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { download, isDownloading } = useDownloadPreview();
    const { t } = useTranslation();
    if (isDownloading) {
        // TODO: Spinner
    }

    return (
        <Box>
            {isSmallScreen && (
                <ListHeader
                    title={t("attachedDocuments")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}
            <Paper borderRadius={{ base: "lg", md: "none" }}>
                {!isSmallScreen && (
                    <Headline title={t("attachedDocuments")} size="xs" />
                )}
                <Box
                    px={{
                        base: 4,
                        md: 6,
                    }}
                    py={4}
                >
                    {documents ? (
                        documents.map((document) => (
                            <DocumentListCard
                                px={0}
                                pt={2}
                                pb={2}
                                key={document.id}
                                rightIconName="documentDownload"
                                label={document.filename}
                                subText="240 KB"
                                note="Porttitor amet eu adipiscing urna. Quis cras egestas elementum elementum cras. Viverra libero, auctor quisque risus quisque. Placerat semper elementum sed maecenas rutrum sociis tempor. Habitant vulputate ipsum ultricies sit amet posuere vitae facilisi suscipit. "
                                onRightIconPress={() => {
                                    void download(document);
                                }}
                            />
                        ))
                    ) : (
                        <Text>{t("noAttachedDocuments")}</Text>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default AttachmentSection;
