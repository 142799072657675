import type { CellProps, Column } from "react-table";
import { Box, FlatList, Text } from "native-base";
import { useCallback, useMemo } from "react";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { DocumentGeneral } from "../providers/document";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import { useDocuments } from "../providers/document";
import useResponsive from "../util/useResponsive";
import DocumentListItem from "../molecules/ListItem/DocumentListItem";
import useDownloadPreview from "../util/useDownloadPreview";
import { cropFilename } from "../util/strings";
import Tag from "../atoms/Tag";
import Spinner from "../atoms/Spinner";
import { DocumentService } from "../form/upload/model";

// TODO: Discriminate by withoutAction
interface Props {
    showOnlyGeneral: boolean;
    onDeletePress?: (key: string) => void;
    readonly onEndReached?: () => unknown;
    withoutAction?: boolean;
}

const DocumentsTableGeneral = ({
    showOnlyGeneral,
    onDeletePress,
    onEndReached,
    withoutAction = false,
}: Props) => {
    const { documents, onSortChange } = useDocuments(
        showOnlyGeneral ? "general" : "both"
    );
    const navigate = useRouteNavigate();
    const { download, isDownloading } = useDownloadPreview();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const handleRowPress = useCallback(
        ({ id }: DocumentGeneral) => {
            navigate("document", { documentId: id });
        },
        [navigate]
    );

    const handleViewPress = useCallback(
        (documentId: string) => () => {
            navigate("document", { documentId });
        },
        [navigate]
    );

    const getTagVariant = useCallback((service: DocumentService) => {
        switch (service) {
            case DocumentService.Pregnancy:
                return "violet";
            case DocumentService.PrePostOp:
                return "blue";
            default:
                return "lime";
        }
    }, []);

    const columns = useMemo<Column<DocumentGeneral>[]>(
        () => [
            {
                Header: t("documentName"),
                accessor: "filename",
                width: "auto",
                defaultCanSort: !!onSortChange,
                Cell: ({ value }) => (
                    <Text numberOfLines={1}>
                        {cropFilename(value, isSmallScreen ? 20 : 40)}
                    </Text>
                ),
            },
            {
                Header: t("package"),
                accessor: "service",
                width: "auto",
                defaultCanSort: !!onSortChange,
                Cell: ({ value }) =>
                    value ? (
                        <Tag variant={getTagVariant(value.value)} size="sm">
                            {t(`backend:${value?.forHumans}`)}
                        </Tag>
                    ) : (
                        <Text>-</Text>
                    ),
            },
            {
                Header: t("uploadDate"),
                accessor: "createdAt",
                defaultCanSort: !!onSortChange,
                width: 200,
                Cell: ({ value: createdAt }) => (
                    <>{formatDateTime(createdAt)}</>
                ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({
                    row: { original: document },
                }: CellProps<DocumentGeneral>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            actions={[
                                {
                                    text: t("view"),
                                    iconName: "documentView",
                                    onPress: handleViewPress(document.id),
                                },
                                {
                                    text: t("download"),
                                    iconName: "documentDownload",
                                    onPress: () => {
                                        void download(document);
                                    },
                                },
                                ...(onDeletePress
                                    ? ([
                                          {
                                              text: t("delete"),
                                              iconName: "trashCan",
                                              onPress: () => {
                                                  onDeletePress(document.id);
                                              },
                                          },
                                      ] as const)
                                    : []),
                            ]}
                        />
                    </Box>
                ),
            },
        ],
        [
            onSortChange,
            isSmallScreen,
            handleViewPress,
            download,
            onDeletePress,
            getTagVariant,
            t,
        ]
    );
    if (withoutAction) {
        columns.pop();
    }

    if (isDownloading) {
        <Spinner />;
    }

    return isSmallScreen ? (
        <FlatList
            minWidth="full"
            showsVerticalScrollIndicator={false}
            data={documents}
            renderItem={({ item }) => (
                <DocumentListItem
                    document={item}
                    onDeletePress={onDeletePress}
                />
            )}
            keyExtractor={(item) => item.id}
            overflow="hidden"
            onEndReached={onEndReached}
        />
    ) : (
        <ReactTable
            columns={columns}
            data={documents}
            onSortChange={onSortChange}
            onEndReached={onEndReached}
            onPress={handleRowPress}
        />
    );
};

export default DocumentsTableGeneral;
