import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDate, formatMeasuredAt } from "@madmedical/utils";
import { BODY_PARTS_FOR_HUMANS } from "@madmedical/medical";
import type { InsulinAdministration } from "../../providers/insulinAdministration";
import { formatType } from "./../insulinFormatters";
import { Inter } from "./inter-normal";
import { InterBold } from "./inter-bold";

const headerCellStyle = {
    halign: "left" as const,
    fillColor: "#f4f7f8",
    textColor: "#768087",
    cellPadding: 2,
    font: "Inter",
};

const bodyStyles = {
    lineWidth: {
        top: 0,
        right: 0,
        bottom: 0.05,
        left: 0,
    },
    lineColor: 175,
    fillColor: "#fff",
    font: "Inter",
    fontSize: 9,
};

export default (
    administrations: InsulinAdministration[],
    pacientName: string
) => {
    const doc = new jsPDF();

    doc.addFileToVFS("Inter-Regular.ttf", Inter);
    doc.addFileToVFS("Inter-SemiBold.ttf", InterBold);

    doc.addFont("Inter-Regular.ttf", "Inter", "normal", "normal");
    doc.addFont("Inter-SemiBold.ttf", "Inter", "normal", "bold");

    doc.setFont("Inter", "normal");
    doc.setFontSize(12);

    doc.text(`Név: ${pacientName}`, 15, 15);
    doc.text(`Dátum: ${formatDate(new Date())}`, 15, 23);

    autoTable(doc, {
        theme: "plain",
        head: [
            [
                { content: "Időpont", styles: headerCellStyle },
                { content: "Mennyiség", styles: headerCellStyle },
                { content: "Típus", styles: headerCellStyle },
                { content: "Termék neve", styles: headerCellStyle },
                { content: "Beadási hely", styles: headerCellStyle },
                { content: "Megjegyzés", styles: headerCellStyle },
            ],
        ],
        body: administrations.map((item) => [
            {
                content: formatMeasuredAt(item.administeredAt),
                styles: { cellWidth: 30, styles: { halign: "start" } },
            },
            {
                content: item.units.toLocaleString(),
                styles: { halign: "center", cellWidth: 25 },
            },
            {
                content: formatType(item.product.type),
                styles: { cellWidth: 20 },
            },
            { content: item.product.name, styles: { cellWidth: 30 } },
            {
                content: `${
                    BODY_PARTS_FOR_HUMANS[item.bodyLocation.bodyPart]
                } (${item.bodyLocation.side === "right" ? "jobb" : "bal"})`,
                styles: { cellWidth: 25 },
            },
            { content: item.comment ?? "", styles: { cellWidth: "auto" } },
        ]),
        startY: 30,
        bodyStyles,
    });

    doc.save("inzulin.pdf");
};
