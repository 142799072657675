import type { Column } from "react-table";
import { Box, HStack, Text, useTheme } from "native-base";
import { useMemo } from "react";
import { formatDate } from "@madmedical/utils";
import { serviceForHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { Patient, PatientDoctor } from "../providers/patient";
import ReactTable from "../molecules/ReactTable";
import Tooltip from "../atoms/Tooltip";
import Icon from "../icons/Icon";
import Tag from "../atoms/Tag";
import AvatarTexts from "../molecules/AvatarTexts";
import { usePatients } from "../providers/patient";

interface Props {
    onEndReached: () => unknown;
}

const DoctorPatientsTable = ({ onEndReached }: Props) => {
    const { colors } = useTheme();
    const { patients, onSortChange } = usePatients<PatientDoctor>();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const handleRowPress = ({ userId }: Patient) => {
        navigate("patient", { patientId: userId });
    };

    const columns = useMemo<Column<PatientDoctor>[]>(
        () => [
            {
                Header: t("patientName"),
                accessor: "fullName",
                defaultCanSort: !!onSortChange,
                width: "20%",
                Cell: ({ row }) => {
                    const { fullName, initials, avatarUrl } = row.original;

                    return (
                        <AvatarTexts
                            initials={initials}
                            label={fullName}
                            source={avatarUrl}
                        />
                    );
                },
            },
            {
                accessor: "services",
                disableSortBy: true,
                width: "auto",
                Header: () => (
                    <HStack alignItems="center">
                        <Text color="gray.600">{t("package")}</Text>
                        <Tooltip
                            hasArrow
                            label={t("doctor:packageLabelDescription")}
                            placement="right"
                        >
                            <Box ml={2}>
                                <Icon
                                    name="help"
                                    size={14}
                                    fill={colors.gray[600]}
                                />
                            </Box>
                        </Tooltip>
                    </HStack>
                ),
                Cell: ({ value: services }) => (
                    <HStack space={1}>
                        {services.map((service) => (
                            <Tag key={service}>
                                {t(
                                    `backend:${serviceForHumans(service)}`
                                ).toLowerCase()}
                            </Tag>
                        ))}
                    </HStack>
                ),
            },
            {
                Header: t("socialSecurityNumber"),
                accessor: "socialSecurityNumber",
                disableSortBy: true,
                width: "15%",
                Cell: ({ value: socialSecurityNumber }) => (
                    <>{socialSecurityNumber ?? "-"}</>
                ),
            },
            {
                Header: t("dateOfBirth"),
                accessor: "birthDate",
                defaultCanSort: !!onSortChange,
                width: "10%",
                Cell: ({ value: birthDate }) => (
                    <>{birthDate ? formatDate(birthDate) : "-"}</>
                ),
            },
        ],
        [colors, onSortChange, t]
    );

    return (
        <ReactTable
            columns={columns}
            data={patients}
            onSortChange={onSortChange}
            onEndReached={onEndReached}
            onPress={handleRowPress}
        />
    );
};

export default DoctorPatientsTable;
