import { Modal, PregnancyMoodModal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { usePatientId } from "@madmedical/user";
import { useCreatePregnancyWellBeingMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}
const PregnancyMoodModalForm = ({ open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const [createPregnancyWellBeing] = useCreatePregnancyWellBeingMutation();

    return (
        <Modal open={open} onClose={onClose} size="sm">
            <ApiFormWrapper
                defaultValues={{
                    createdAt: new Date().toISOString(),
                    patientId,
                    status: undefined,
                    symptoms: [],
                    comment: "",
                }}
                mutation={createPregnancyWellBeing}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <PregnancyMoodModal
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default PregnancyMoodModalForm;
