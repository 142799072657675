import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { hasActiveFilter } from "../utils";
import FilterButton from "../../../molecules/FilterButton";
import Button from "../../../atoms/Button";
import { usePatientMeasurements } from "../../../providers/patientMeasurements";

const PatientMeasurementsDesktopFilter = () => {
    const { filterChoices, onFilterChange } = usePatientMeasurements();
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof typeof filterChoices>(key: TFilterKey) =>
        (selected: (typeof filterChoices)[TFilterKey][0]["key"][]) => {
            onFilterChange?.(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange?.("patients", []);
        onFilterChange?.("metricTypes", []);
        onFilterChange?.("alertLevels", []);
    };

    return (
        <HStack
            width="full"
            space={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <FilterButton
                isSearchable
                title={t("patients")}
                items={filterChoices.patients}
                onChange={handleFilterChange("patients")}
                isMultiselect={true}
            />
            <FilterButton
                isSearchable
                title={t("measures:type")}
                items={filterChoices.metricTypes}
                onChange={handleFilterChange("metricTypes")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("alertLevel")}
                items={filterChoices.alertLevels}
                onChange={handleFilterChange("alertLevels")}
                isMultiselect={false}
            />

            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default PatientMeasurementsDesktopFilter;
