import type { CellProps, Column } from "react-table";
import { forHumans, riskForHumans } from "@madmedical/medical";
import { useMemo } from "react";
import { formatDateRange } from "@madmedical/utils";
import { Divider, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import { usePredictions } from "../providers/prediction";
import ReactTable from "../molecules/ReactTable";
import Tag from "../atoms/Tag";
import type { Prediction } from "../providers/prediction";
import useResponsive from "../util/useResponsive";

interface Props {
    readonly onEndReached?: () => unknown;
}

const PredictionsTable = ({ onEndReached }: Props) => {
    const { predictions } = usePredictions();
    const navigate = useRouteNavigate();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const columns = useMemo<Column<Prediction>[]>(
        () => [
            {
                Header: t("measures:title"),
                width: "auto",
                Cell: ({ row }: CellProps<Prediction>) => (
                    <>
                        {row.original.trends.map(({ metricType }) => (
                            <Tag mr={1} key={metricType}>
                                {t(`measures:${forHumans(metricType)}`)}
                            </Tag>
                        ))}
                    </>
                ),
            },
            {
                Header: t("measures:measuredPeriod"),
                accessor: "dateRange",
                width: "30%",
                Cell: ({ value: dateRange }) => (
                    <>{formatDateRange(dateRange, "long")}</>
                ),
            },
            {
                Header: t("measures:alert"),
                width: "30%",
                accessor: "risks",
                Cell: ({ value: risks }) => (
                    <>
                        {risks.map((risk) => t(riskForHumans(risk))).join(", ")}
                    </>
                ),
            },
        ],
        [t]
    );
    const handleRowPress = ({ id }: Prediction) => {
        navigate("prediction", { predictionId: id });
    };

    return isSmallScreen ? (
        <VStack divider={<Divider />}>
            {predictions.map((prediction) => (
                <Pressable
                    onPress={() => handleRowPress(prediction)}
                    key={prediction.id}
                >
                    <VStack backgroundColor="white" px="4" py="5" space="1">
                        <HStack space="1" flexWrap="wrap">
                            {prediction.trends.map(({ metricType }) => (
                                <Tag key={metricType} variant="ghost">
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Tag>
                            ))}
                        </HStack>

                        <HStack space="1" flexWrap="wrap">
                            <Text fontSize="xs" color="gray.600">
                                {formatDateRange(prediction.dateRange, "short")}
                            </Text>
                            <Divider orientation="vertical" />
                            <Text fontSize="xs" color="gray.600">
                                {prediction.risks
                                    .map((risk) => riskForHumans(risk))
                                    .join(", ")}
                            </Text>
                        </HStack>
                    </VStack>
                </Pressable>
            ))}
        </VStack>
    ) : (
        <ReactTable
            columns={columns}
            data={predictions}
            onEndReached={onEndReached}
            onPress={handleRowPress}
        />
    );
};

export default PredictionsTable;
