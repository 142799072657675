import { Box, ScrollView } from "native-base";
import { useCallback } from "react";
import { useTranslation } from "@madmedical/i18n";
import DevicesList from "../organisms/DevicesList";
import Headline from "../molecules/Headline";
import Alert from "../molecules/Alert";
import Breadcrumb from "../molecules/Breadcrumb";
import Paper from "../atoms/Paper";
import openExternalUrl from "../util/openExternalUrl";

interface Props {
    showWebshopAlert: boolean;
    onDismissWebshopAlert: () => void;
}

const Devices = ({ showWebshopAlert, onDismissWebshopAlert }: Props) => {
    const handleWebshopPress = useCallback(() => {
        openExternalUrl("https://www.nexthealth.app/webshop");
    }, []);
    const { t } = useTranslation();

    return (
        <ScrollView>
            <Paper
                mb={{
                    base: 24,
                    md: 4,
                }}
            >
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link
                                    route="devices_connected"
                                    params={{}}
                                >
                                    {t("devices")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("allDevice")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <Headline
                    size="md"
                    title={t("allDevice")}
                    // rightContent={
                    //     <Button
                    //         leftIconName="shoppingBag"
                    //         variant="outlined"
                    //         size="md"
                    //         onPress={handleWebshopPress}
                    //     >
                    //         Webshop
                    //     </Button>
                    // }
                />

                {showWebshopAlert && (
                    <Box
                        px={{ base: 4, md: 6 }}
                        mt={{ base: 0, md: 4 }}
                        mb={{ base: 4, md: 0 }}
                        width="full"
                    >
                        <Alert
                            status="success"
                            iconName="shoppingBag"
                            title={t("buyDevices")}
                            description="Szerezz be új eszközt a webshopon keresztül!"
                            links={[
                                {
                                    text: "Webshop",
                                    onPress: handleWebshopPress,
                                },
                            ]}
                            onDismiss={onDismissWebshopAlert}
                        />
                    </Box>
                )}
                <DevicesList />
            </Paper>
            <Box h={10} w={1} />
        </ScrollView>
    );
};
export default Devices;
