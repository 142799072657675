import { Fragment, useMemo } from "react";
import { Defs, Line, Marker, Path } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import Thresholds from "./Thresholds";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

export default function GraphColumn({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const {
        coords = [],
        randomIdFragment,
        limits,
        dashboardThumbnail: { isDashboardThumbnail = false } = {},
    } = graphData;

    const columnTopPath =
        "m 0.14 0.14 a 0.14 0.14 1 0 1 -0.14 -0.14 l 1 0 a 0.14 0.14 0 0 1 -0.14 0.14 z";

    const columnWidth = useMemo(() => {
        const gapPx = 1;
        const deltaXs = [28 + gapPx]; // 28px by design

        coords.forEach(({ x }, i) => {
            if (i > 0) {
                const deltaX = x - coords[i - 1].x;
                if (deltaX > 0) deltaXs.push(deltaX);
            }
        });

        return `${Math.min(...deltaXs) - gapPx}px`;
    }, [coords]);

    return (
        <>
            <Defs>
                <Marker id="column-top" refX={0.5} refY={0.025}>
                    <Path fill={colors.blue400} d={columnTopPath} />
                </Marker>
                <Marker id="column-top-focused" refX={0.5} refY={0.025}>
                    <Path fill={colors.blue800} d={columnTopPath} />
                </Marker>
            </Defs>

            {!isDashboardThumbnail && <Thresholds graphData={graphData} />}

            {coords.length > 0 &&
                coords.map(({ x, yHigh }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <Fragment key={id}>
                            {yHigh &&
                                (isWeb ? (
                                    <Line
                                        strokeWidth={columnWidth}
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        x1={x}
                                        y1={limits?.yMin}
                                        x2={x}
                                        y2={yHigh}
                                        markerEnd={
                                            tooltip.onShow && tooltip.id === id
                                                ? "url(#column-top-focused)"
                                                : "url(#column-top)"
                                        }
                                    />
                                ) : (
                                    <Line
                                        scaleY={-1}
                                        strokeWidth={columnWidth}
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        x1={x}
                                        y1={limits?.yMin}
                                        x2={x}
                                        y2={yHigh}
                                        markerEnd={
                                            tooltip.onShow && tooltip.id === id
                                                ? "url(#column-top-focused)"
                                                : "url(#column-top)"
                                        }
                                    />
                                ))}
                        </Fragment>
                    );
                })}
        </>
    );
}
