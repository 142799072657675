import { Controller, useField } from "@madmedical/form";
import BaseDateTimeInput from "../molecules/Form/DateTimeInput";

interface Props {
    readonly name: string;
    readonly isRequired: boolean;
    readonly label?: string;
}

const DateTimeInput = ({ name, isRequired, label }: Props) => {
    const { control, isError, error } = useField(name);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => (
                <BaseDateTimeInput
                    onChange={onChange}
                    value={value as string}
                    label={label}
                    isInvalid={isError}
                    error={error}
                />
            )}
        />
    );
};

export default DateTimeInput;
