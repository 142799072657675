import { Divider, HStack, Text, VStack } from "native-base";
import type { Ulid } from "@madmedical/utils";
import { formatTime, isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { formatInsertPoint, formatType } from "../../util/insulinFormatters";
import ActionButton from "../ActionButton";
import type {
    InsulinAdministration,
    InsulinBodyLocation,
    InsulinProduct,
} from "../../providers/insulinAdministration";
import { InsulinQuantityBubbleMobile } from "./InsulinQuantityListItem";

interface Props {
    id?: Ulid;
    manual?: boolean;
    editable?: boolean;
    deleted?: boolean;
    onDeletePress?: (id: Ulid) => void;
    onEditPress?: (measurement: InsulinAdministration) => void;

    administeredAt?: Date;
    product?: InsulinProduct;
    units?: number;
    comment: string | null;
    bodyLocation?: InsulinBodyLocation;
}

const InsulinMeasurementListItem = ({
    id,
    administeredAt,
    product,
    units,
    comment,
    bodyLocation,
    deleted,
    editable,
    onDeletePress,
    onEditPress,
}: Props) => {
    const { t } = useTranslation();

    return (
        <HStack
            space={4}
            justifyContent="space-between"
            alignItems="center"
            bgColor="white"
            px={4}
            py={5.5}
            borderBottomColor="gray.100"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            width="full"
        >
            <VStack>
                <HStack space={1} alignItems="center" mb={1}>
                    <Text>{units}</Text>
                    <Text>E,</Text>
                    <Text>{product?.name}</Text>
                </HStack>
                <HStack alignItems="center" mb={1}>
                    <Text fontSize="xs" color="gray.600">
                        {administeredAt
                            ? formatTime(administeredAt)
                            : t("noAppointment")}
                    </Text>
                    <Divider
                        bg="gray.100"
                        orientation="vertical"
                        mx={2}
                        height={3}
                    />
                    <Text fontSize="xs" color="gray.600">
                        {bodyLocation
                            ? t(formatInsertPoint(bodyLocation.bodyPart)) +
                              `(${t(bodyLocation.side)})`
                            : t("noInjectionPlace")}{" "}
                    </Text>
                </HStack>
                {product?.type ? (
                    <HStack space={2} alignItems="center" mb={1}>
                        <InsulinQuantityBubbleMobile
                            units={units}
                            type={product.type}
                        />
                        <Text fontSize="xs" color="gray.600">
                            {t(formatType(product.type))}
                        </Text>
                    </HStack>
                ) : null}
                {comment ? (
                    <Text
                        fontSize="xs"
                        color="gray.600"
                        isTruncated
                        maxW={isWeb ? "85vw" : "90%"}
                        w="90%"
                        noOfLines={3}
                    >
                        {comment}
                    </Text>
                ) : null}
            </VStack>
            <ActionButton
                actions={
                    !deleted && editable
                        ? [
                              {
                                  text: t("edit"),
                                  iconName: "edit",
                                  onPress: () => {
                                      onEditPress?.({
                                          id: id as Ulid,
                                          administeredAt:
                                              administeredAt as Date,
                                          product: product as InsulinProduct,
                                          units: units as number,
                                          comment: comment as string,
                                          bodyLocation:
                                              bodyLocation as InsulinBodyLocation,
                                      });
                                  },
                              },
                              {
                                  text: t("delete"),
                                  iconName: "trashCan",
                                  onPress:
                                      onDeletePress && id
                                          ? () => {
                                                onDeletePress(id);
                                            }
                                          : () => null,
                              },
                          ]
                        : []
                }
            />
        </HStack>
    );
};

export default InsulinMeasurementListItem;
