import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const BabyCap = () => (
    <Svg width="146" height="130" viewBox="0 0 146 130" fill="none">
        <Circle cx="71.9991" cy="65" r="65" fill="white" fillOpacity="0.83" />
        <G clipPath="url(#clip0_225_71594)">
            <Path
                d="M83.0149 40.6942C83.2814 39.6972 84.7419 38.8228 84.7419 37.7418C84.7419 36.661 83.2814 35.7866 83.0149 34.7897C82.7393 33.7584 83.5588 32.2725 83.0365 31.3697C82.5067 30.4538 80.8051 30.424 80.0618 29.6806C79.3186 28.9372 79.2888 27.2358 78.3729 26.7058C77.4698 26.1831 75.9837 27.0026 74.9524 26.7269C73.9554 26.4606 73.0808 25 72 25C70.9191 25 70.0447 26.4606 69.0478 26.727C68.0165 27.0028 66.5306 26.1831 65.6277 26.7056C64.7117 27.2355 64.682 28.937 63.9386 29.6803C63.1952 30.4237 61.4936 30.4533 60.9637 31.3692C60.4411 32.2723 61.2606 33.7584 60.9849 34.7898C60.7184 35.7867 59.2578 36.6611 59.2578 37.7421C59.2578 38.823 60.7184 39.6974 60.9849 40.6943C61.0921 41.0953 61.0336 41.5652 60.9529 42.0427L63.9381 45.8034C64.6813 46.5468 64.7111 48.2482 65.627 48.7782C66.5301 49.3007 68.0162 48.4814 69.0476 48.7571C70.0446 49.0236 70.919 50.4841 72 50.4841C73.0808 50.4841 73.9552 49.0236 74.9521 48.7571C75.9834 48.4815 77.4693 49.301 78.3723 48.7787C79.2882 48.2488 79.3179 46.5472 80.0614 45.804L83.0468 42.0427C82.966 41.565 82.9077 41.0952 83.0149 40.6942Z"
                fill="#EA9E9E"
            />
            <Path
                d="M60.9528 42.0421C60.8258 42.7924 60.6444 43.5619 60.9636 44.1137C61.4935 45.0296 63.195 45.0594 63.9383 45.8028C64.6817 46.5462 64.7113 48.2477 65.6272 48.7776C66.5303 49.3002 68.0164 48.4807 69.0478 48.7565C70.0447 49.0229 70.9193 50.4835 72.0001 50.4835C73.081 50.4835 73.9554 49.0229 74.9523 48.7565C75.9835 48.4809 77.4695 49.3004 78.3723 48.7781C79.2882 48.2482 79.3181 46.5466 80.0614 45.8034C80.8048 45.06 82.5062 45.0303 83.0362 44.1144C83.3556 43.5624 83.174 42.7927 83.0472 42.0421C79.5679 40.9099 75.8573 40.29 72.0001 40.29C68.1429 40.2899 64.4322 40.9097 60.9528 42.0421Z"
                fill="#EA9E9E"
            />
            <Path
                d="M105.04 73.5154L103.635 66.1028C99.8656 54.0003 89.3421 44.8757 76.4746 43.1385C75.0113 42.9408 73.5175 43.2843 72 43.2843C53.7033 43.2843 42.1178 57.6711 42.1178 75.9677V87.4356L105.129 81.0646V75.9677C105.129 75.1429 105.099 74.3251 105.04 73.5154Z"
                fill="#FAC5B1"
            />
            <Path
                d="M51.6128 81.0647C49.5016 81.0647 47.7903 79.3532 47.7903 77.2421V75.9678C47.7903 59.1846 60.2993 45.3175 76.4855 43.1382C75.026 42.9416 73.5134 42.8389 71.9999 42.8389C57.1397 42.8389 44.5658 52.6228 40.3666 66.1004L38.9617 73.5157C38.9024 74.3253 38.871 75.1431 38.871 75.9678V87.4356H105.129V81.0647H51.6128Z"
                fill="#FAC5B1"
            />
            <Path
                d="M105.129 102.714H47.7903C46.3828 102.714 45.2419 101.573 45.2419 100.166V86.1616C45.2419 84.7542 46.383 83.6133 47.7903 83.6133H105.129C106.537 83.6133 107.677 84.7543 107.677 86.1616V101.452L105.129 102.714Z"
                fill="#EA9E9E"
            />
            <Path
                d="M47.7903 96.3553V83.6133H38.8709C37.4634 83.6133 36.3225 84.7543 36.3225 86.1616V101.452C36.3225 102.86 37.4635 104 38.8709 104H105.129C106.536 104 107.677 102.859 107.677 101.452H52.887C50.0722 101.452 47.7903 99.1701 47.7903 96.3553Z"
                fill="#EA9E9E"
            />
            <Path
                d="M103.635 66.1031C100.696 66.7902 99.6815 68.3228 95.1791 68.3228C89.3802 68.3228 89.3802 65.7744 83.5811 65.7744C77.7861 65.7744 77.7861 68.3228 71.9911 68.3228C66.1943 68.3228 66.1943 65.7744 60.3973 65.7744C54.6026 65.7744 54.6026 68.3228 48.8076 68.3228C48.7643 68.3228 48.7239 68.3174 48.6811 68.3171L45.5381 71.8679L47.7908 75.9396C48.1093 75.9582 48.4474 75.9679 48.8078 75.9679C54.6026 75.9679 54.6026 73.4195 60.3975 73.4195C66.1944 73.4195 66.1944 75.9679 71.9912 75.9679C77.7861 75.9679 77.7861 73.4195 83.5812 73.4195C89.3803 73.4195 89.3803 75.9679 95.1792 75.9679C100.323 75.9679 100.927 73.9685 105.04 73.5153C104.852 70.9537 104.372 68.47 103.635 66.1031Z"
                fill="#EA9E9E"
            />
            <Path
                d="M48.6813 68.3164C44.2992 68.2862 43.2704 66.7802 40.3667 66.0996C39.6294 68.4665 39.1494 70.9535 38.9618 73.515C42.7777 73.9368 43.5745 75.6933 47.7911 75.9388C47.7934 73.3173 48.1031 70.7618 48.6813 68.3164Z"
                fill="#EA9E9E"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_225_71594">
                <Rect
                    width="79"
                    height="79"
                    fill="white"
                    transform="translate(32.5 25)"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default BabyCap;
