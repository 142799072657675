import { Box, Stack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import DeviceCard from "../../organisms/DeviceCard";
import { useDevices } from "../../providers/device";

const Devices = () => {
    const { devices } = useDevices();
    const { t } = useTranslation();

    return (
        <Paper>
            <Headline title={t("devices")} size="xs" py={6} />
            <Stack
                direction="row"
                px={4}
                py={2}
                space={0}
                width="full"
                flexWrap="wrap"
            >
                {devices.map((device) => (
                    <Box key={device.provider} width="1/3" p={1}>
                        <DeviceCard device={device} />
                    </Box>
                ))}
            </Stack>
        </Paper>
    );
};
export default Devices;
