import { useCallback, useMemo, useRef, useState } from "react";
import { Svg } from "react-native-svg";
import { Box, HStack, Text } from "native-base";
import { formatDateTime, isWeb } from "@madmedical/utils";
import {
    svgNative,
    svgWeb,
    thumbnailFooter,
    thumbnailGraphSlot,
    thumbnailHeader,
    thumbnailRoot,
    thumbnailUnit,
    thumbnailValue,
    thumbnailValueSlot,
} from "./useStyles";
import { normalizeGraphProps } from "./utils";
import type { GraphData, GraphProps } from "./types";
import GraphBar from "./GraphBar";
import GraphColumn from "./GraphColumn";
import GraphDot from "./GraphDot";
import GraphLinePaired from "./GraphLinePaired";
import GraphLineSingle from "./GraphLineSingle";

export default function GraphTumbnail({
    graphProps = {},
}: {
    graphProps?: GraphProps;
}) {
    // ##########################

    const [slotX, setSlotX] = useState(graphProps.slot?.x ?? 100);

    const graphData = useMemo<GraphData | undefined>(() => {
        const graphPropsMiddleware = { ...graphProps };

        if (isWeb && graphPropsMiddleware.slot?.x)
            graphPropsMiddleware.slot.x = slotX;

        const normalizedGraphProps = normalizeGraphProps(graphPropsMiddleware);

        return {
            ...normalizedGraphProps,
            coords: normalizedGraphProps?.coordsAll,
        };
    }, [graphProps, slotX]);

    // ##########################

    const {
        type,
        coords = [],
        slot = { x: 0, y: 0 },
        unit,
        dashboardThumbnail: { title = "", valueToDisplay = "" } = {},
    } = graphData ?? {};

    const lastMeasureMsec = useMemo(
        () => [...coords].reverse().find(({ yHigh }) => yHigh)?.msec,
        [coords]
    );

    const viewBoxOriginY = isWeb ? 0 : 0 - slot.y;

    // ##########################

    const resizeObserver = useRef<ResizeObserver>();

    const anchor = useCallback(
        (div: HTMLDivElement) => {
            if (isWeb) {
                if (!div) return;

                if (resizeObserver.current) resizeObserver.current.disconnect();

                resizeObserver.current = new ResizeObserver((entries) =>
                    setSlotX(entries[0].contentRect.width)
                );

                resizeObserver.current.observe(div);
            } else {
                if (!div?.parentElement?.parentElement) return;
                slot.x =
                    div.parentElement.parentElement.getBoundingClientRect()
                        .width - 50;
            }
        },
        [slot]
    );

    // ##########################

    return (
        <Box ref={anchor} style={thumbnailRoot}>
            <Text style={thumbnailHeader} numberOfLines={1}>
                {title}
            </Text>

            {valueToDisplay && (
                <>
                    <HStack style={thumbnailValueSlot} space={1}>
                        <Text numberOfLines={1} style={thumbnailValue}>
                            {valueToDisplay}
                        </Text>
                        <Text style={thumbnailUnit}>{unit}</Text>
                    </HStack>
                </>
            )}

            <Box
                style={[
                    thumbnailGraphSlot,
                    {
                        width: slot.x,
                        height: slot.y,
                    },
                ]}
            >
                <Svg
                    // xmlns="http://www.w3.org/2000/svg"
                    viewBox={`0 ${viewBoxOriginY - 2} ${slot.x} ${slot.y + 5}`}
                    style={isWeb ? svgWeb : svgNative}
                >
                    {type === "line-single" ? (
                        <GraphLineSingle graphData={graphData} />
                    ) : type === "line-paired" ? (
                        <GraphLinePaired graphData={graphData} />
                    ) : type === "column" ? (
                        <GraphColumn graphData={graphData} />
                    ) : type === "dot" ? (
                        <GraphDot graphData={graphData} />
                    ) : type === "bar" ? (
                        <GraphBar graphData={graphData} />
                    ) : (
                        <></>
                    )}
                </Svg>
            </Box>

            <Text style={thumbnailFooter}>
                {lastMeasureMsec ? formatDateTime(lastMeasureMsec) : ""}
            </Text>
        </Box>
    );
}
