import { Dialog, Text, usePregnancy, useShowToast } from "@madmedical/ui";
import { useTranslation } from "@madmedical/i18n";
import { useDeletePregnancyWellBeingMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}

const DeletePregnancyMoodModalForm = ({ open, onClose }: Props) => {
    const { pregnancyWellBeingData } = usePregnancy();
    const [deletePregnancyWellBeing, { isLoading: isSubmitting }] =
        useDeletePregnancyWellBeingMutation();

    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!pregnancyWellBeingData?.id) {
        return null;
    }

    const handleConfirm = () => {
        void deletePregnancyWellBeing(pregnancyWellBeingData?.id)
            .unwrap()
            .then(() => {
                showToast(t("textInputDeleted"), { variant: "success" });
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            onClose={onClose}
        >
            <Text>{t("sureToDeleteTextInput")}</Text>
        </Dialog>
    );
};

export default DeletePregnancyMoodModalForm;
