import type { ComponentProps, ReactNode } from "react";
import { Stack, Text } from "native-base";
import Tag from "../../atoms/Tag";

interface Props extends ComponentProps<typeof Stack> {
    label: ReactNode;
    accessory?: ReactNode;
    withTag?: boolean;
    content?: ReactNode;
}

const SimpleListItem = ({
    label,
    accessory,
    content,
    withTag,
    ...rest
}: Props) => (
    <Stack
        direction={{
            base: "row",
            md: "column",
        }}
        justifyContent="space-between"
        alignItems={{
            base: "center",
            md: "flex-start",
        }}
        bgColor="white"
        borderBottomColor="gray.100"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        px={4}
        py={4.5}
        width="full"
        {...rest}
    >
        <Text
            color="gray.600"
            fontSize="sm"
            mb={{
                base: 0,
                md: 2,
            }}
            display="flex"
            alignItems="center"
            maxWidth={{
                base: "full",
                sm: "1/2",
            }}
        >
            {label} {accessory}
        </Text>
        {withTag ? (
            <Stack
                fontFamily="Inter"
                fontSize="sm"
                fontWeight="medium"
                flex={1}
                mr={{
                    base: 0,
                    md: 3,
                }}
                direction="row"
                flexWrap="wrap"
                justifyContent={{
                    base: "flex-end",
                    md: "flex-start",
                }}
                space={1}
            >
                <Tag>{content}</Tag>
            </Stack>
        ) : (
            (
                <Text textAlign={{ base: "right", md: "left" }}>{content}</Text>
            ) ?? <Text>-</Text>
        )}
    </Stack>
);

export default SimpleListItem;
