import { createAsyncThunk } from "@reduxjs/toolkit";
import { invalidateTokens } from "./auth/slice";
import { removeRefreshToken } from "./auth/refreshTokenStorage";
import baseApi from "./api";

// TODO: Implement in side-effects instead
export default createAsyncThunk("resetState", async (_, { dispatch }) => {
    // This might look redundant, but we want to make sure that
    // when emptying the state triggers any side-effects, it doesn't find a refresh token
    await removeRefreshToken();

    dispatch(invalidateTokens());
    dispatch(baseApi.util.resetApiState());
});
