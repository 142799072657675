import { CallCenterDevice, Spinner } from "@madmedical/ui";
import { invariant } from "@madmedical/utils";
import { adaptPatient, useGetPatientQuery } from "@madmedical/user";
import { skipToken } from "@madmedical/store";
import { useParams } from "@madmedical/routing";
import { useState } from "react";
import adaptDevice from "./adaptDevice";
import { useGetLogisticsDeviceQuery } from "./api";
import UpdateDeviceForm from "./UpdateDeviceForm";

enum Modal {
    Closed,
    Edit,
}

const Device = () => {
    const { deviceId } = useParams<"deviceId">();
    invariant(deviceId);
    const { data: device, isLoading } = useGetLogisticsDeviceQuery(deviceId);
    const { data: patient } = useGetPatientQuery(
        device?.patientId ?? skipToken
    );
    const [modal, setModal] = useState(Modal.Closed);

    const handleEditPress = () => {
        setModal(Modal.Edit);
    };

    const handleModalClose = () => {
        setModal(Modal.Closed);
    };

    if (isLoading) {
        return <Spinner />;
    }

    invariant(device);

    return (
        <>
            <CallCenterDevice
                device={adaptDevice(device)}
                patient={patient ? adaptPatient(patient) : undefined}
                onEditPress={handleEditPress}
            />
            <UpdateDeviceForm
                id={deviceId}
                open={modal === Modal.Edit}
                onClose={handleModalClose}
            />
        </>
    );
};

export default Device;
