import { PregnancyBodyPageContent, Spinner } from "@madmedical/ui";
import { usePatientId } from "@madmedical/user";
import { useGetPregnancyByPatientQuery } from "../api";

const PregnancyBodyChangePage = () => {
    const { patientId } = usePatientId();

    const { data: pregnancyData, isLoading } = useGetPregnancyByPatientQuery({
        patientId,
    });

    if (isLoading) {
        return <Spinner />;
    }

    if (!pregnancyData) {
        return null;
    }

    return <PregnancyBodyPageContent pregnancyData={pregnancyData} />;
};

export default PregnancyBodyChangePage;
