import Svg, { Path, Rect } from "react-native-svg";

const Placeholder = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M59.0668 38.1297C50.8161 29.9568 37.4857 29.9568 29.235 38.1297C20.9217 46.3648 20.9217 59.7634 29.235 67.9986L62.5367 100.986C63.901 102.338 66.099 102.338 67.4633 100.986L100.765 67.9986C109.078 59.7634 109.078 46.3648 100.765 38.1297C92.5142 29.9568 79.1838 29.9568 70.9332 38.1297L65 44.0071L59.0668 38.1297Z"
            fill="#F9E1E1"
        />
        <Path
            d="M61.3271 48.853C56.2195 43.7157 47.9673 43.7157 42.8598 48.853C37.7134 54.0293 37.7134 62.4513 42.8598 67.6277L63.4751 88.3629C64.3197 89.2124 65.6803 89.2124 66.5249 88.3629L87.1403 67.6277C92.2866 62.4513 92.2866 54.0293 87.1403 48.853C82.0326 43.7157 73.7804 43.7157 68.6729 48.853L65 52.5473L61.3271 48.853Z"
            fill="#FAB1B1"
        />
        <Path
            d="M62.8754 58.7514C60.6163 56.4162 56.9663 56.4162 54.7072 58.7514C52.4309 61.1042 52.4309 64.9324 54.7072 67.2853L63.8255 76.7104C64.1991 77.0965 64.8009 77.0965 65.1745 76.7104L74.2928 67.2853C76.5691 64.9324 76.5691 61.1042 74.2928 58.7514C72.0336 56.4162 68.3836 56.4162 66.1246 58.7514L64.5 60.4306L62.8754 58.7514Z"
            fill="#E47D7D"
        />
    </Svg>
);

export default Placeholder;
