import { getEnv } from "@madmedical/config";

const environment = getEnv("ENVIRONMENT");

export default () => ({
    // Sentry config
    environment,
    release: getEnv("RELEASE"),
    namespace: getEnv("NAMESPACE"),

    // App config
    isProduction: environment === "production",
    integrator: null,
    integratorToken: null,
    integratorAuthLoading: false,
    baseUri: `https://${getEnv("HOSTNAME_FRONTEND")}`,
    mobileScheme: "nexthealth",
    basePath: "",
    appStoreUrl: "https://example.com/appStore",
    googlePlayUrl: "https://example.com/googlePlay",
    onConsultationPress: () => {
        console.log("Button was pressed");
    },
    language: "en",
});
