import { Fragment } from "react";
import { G, Line, Polygon } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import type { GraphData } from "./types";
import { colors } from "./useStyles";

export default function Thresholds({
    graphData = {},
}: {
    graphData: GraphData;
}) {
    const {
        randomIdFragment = "",
        coords = [],
        limits,
        thresholds = [],
        slot,
        paddings,
    } = graphData;

    const yMax = limits?.yMax ?? slot?.y ?? 0;
    const yMin = limits?.yMin ?? 0;

    const graphSlotTop = yMax + (paddings?.y ?? 0);
    const graphSlotBottom = yMin - (paddings?.y ?? 0);

    return coords.length > 0 && thresholds.length ? (
        <G>
            {slot &&
            thresholds[0]?.limit &&
            thresholds[1]?.limit &&
            thresholds[0]?.limit > graphSlotBottom &&
            thresholds[1]?.limit > graphSlotBottom &&
            thresholds[0]?.limit < graphSlotTop &&
            thresholds[1]?.limit < graphSlotTop ? (
                isWeb ? (
                    <Polygon
                        fill={colors.warning500Bg}
                        points={`${0},${thresholds[0].limit ?? yMax} ${
                            slot.x
                        },${thresholds[0].limit ?? yMax} ${slot.x},${
                            thresholds[1].limit ?? yMax
                        } ${0},${thresholds[1].limit ?? yMax}`}
                    />
                ) : (
                    <Polygon
                        scaleY={-1}
                        fill={colors.warning500Bg}
                        points={`${0},${thresholds[0].limit ?? yMax} ${
                            slot.x
                        },${thresholds[0].limit ?? yMax} ${slot.x},${
                            thresholds[1].limit ?? yMax
                        } ${0},${thresholds[1].limit ?? yMax}`}
                    />
                )
            ) : (
                <></>
            )}

            {thresholds.map(({ limit = yMax }, i) => (
                <Fragment key={`threshold-${limit}-${i}-${randomIdFragment}`}>
                    {slot && limit > graphSlotBottom && limit < graphSlotTop ? (
                        isWeb ? (
                            <Line
                                fill="none"
                                stroke={colors.warning500}
                                strokeWidth="2px"
                                x1={0}
                                y1={limit}
                                x2={slot.x}
                                y2={limit}
                            />
                        ) : (
                            <Line
                                scaleY={-1}
                                fill="none"
                                stroke={colors.warning500}
                                strokeWidth="2px"
                                x1={0}
                                y1={limit}
                                x2={slot.x}
                                y2={limit}
                            />
                        )
                    ) : (
                        <></>
                    )}
                </Fragment>
            ))}
        </G>
    ) : (
        <></>
    );
}
