import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type { PatientIdRequest, Prediction } from "./model";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPrediction: build.query<Prediction, Ulid>({
            query: (id) => `medical/predictions/${id}`,
        }),
        getPredictions: build.query<
            Paginated<Prediction>,
            PatientIdRequest & PageQuery
        >({
            query: ({ patientId, ...params }) => ({
                url: `medical/patients/${patientId}/predictions`,
                params,
            }),
            serializeQueryArgs: ({ queryArgs }) => {
                const { page, pageSize, ...args } = queryArgs;

                return args;
            },
            merge: (cachedData, responseData) => {
                if (responseData.pagination.currentPage === 1) {
                    // Reset to first page
                    return responseData;
                }

                cachedData.items.push(...responseData.items);
                cachedData.pagination = responseData.pagination;
            },
            forceRefetch: ({ currentArg, previousArg }) =>
                !currentArg ||
                !previousArg ||
                currentArg.page !== previousArg.page ||
                currentArg.pageSize !== previousArg.pageSize,
        }),
    }),
});

export const { useGetPredictionsQuery, useGetPredictionQuery } = api;
