import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    BasePatient,
    BaseQuestionnaireRequest,
    Doctor,
    GetExternalUserRequest,
    Institution,
    Me,
    Patient,
    PatientFilter,
    PatientForCallCenter,
    PatientForDoctor,
    PatientIdRequest,
    Pregnancy,
    UpdatePatientRequest,
    User,
} from "./model";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getMe: build.query<Me, void>({
            query: () => "medical/me",
        }),
        getDoctor: build.query<Doctor, Ulid>({
            query: (id) => `medical/doctors/${id}`,
        }),
        getTreatingDoctors: build.query<Doctor[], Ulid>({
            query: (patientId) => `medical/patients/${patientId}/doctors`,
        }),
        getTreatedPatients: build.query<BasePatient[], void>({
            query: () => "medical/patients/treated",
        }),
        // TODO: Wrong types, either all forCallCenter or all forDoctor
        getPatients: build.query<
            Paginated<PatientForDoctor | PatientForCallCenter>,
            PatientFilter & PageQuery
        >({
            query: (params) => ({
                url: `medical/patients`,
                params,
            }),
            serializeQueryArgs: ({ queryArgs }) => {
                const { page, pageSize, ...args } = queryArgs;

                return args;
            },
            merge: (cachedData, responseData) => {
                if (responseData.pagination.currentPage === 1) {
                    // Reset to first page
                    return responseData;
                }

                cachedData.items.push(...responseData.items);
                cachedData.pagination = responseData.pagination;
            },
            forceRefetch: ({ currentArg, previousArg }) =>
                !currentArg ||
                !previousArg ||
                currentArg.page !== previousArg.page ||
                currentArg.pageSize !== previousArg.pageSize,
        }),
        getPatient: build.query<Patient, Ulid>({
            query: (id) => `medical/patients/${id}`,
        }),
        updatePatient: build.mutation<PatientForDoctor, UpdatePatientRequest>({
            query: ({ id, ...body }) => ({
                url: `medical/patients/${id}`,
                method: "PATCH",
                body,
            }),
        }),
        getExternalUser: build.query<User, GetExternalUserRequest>({
            query: ({ integrator, externalId }) =>
                `security/users/${integrator}/${externalId}`,
        }),
        getInstitutions: build.query<Institution[], void>({
            query: () => "medical/institutions",
        }),
        baseQuestionnaire: build.mutation<
            void,
            PatientIdRequest & BaseQuestionnaireRequest
        >({
            query: ({ patientId, ...body }) => ({
                url: `medical/patients/${patientId}/onboarding`,
                method: "POST",
                body,
            }),
        }),
        getUserPregnancyByPatient: build.query<Pregnancy, PatientIdRequest>({
            query: ({ patientId }) => ({
                url: `medical/patients/${patientId}/pregnancies`,
            }),
        }),
    }),
});

export const {
    useGetMeQuery,
    useGetDoctorQuery,
    useGetTreatingDoctorsQuery,
    useGetTreatedPatientsQuery,
    useGetPatientsQuery,
    useGetPatientQuery,
    useGetExternalUserQuery,
    useLazyGetExternalUserQuery,
    useUpdatePatientMutation,
    useGetInstitutionsQuery,
    useBaseQuestionnaireMutation,
    useGetUserPregnancyByPatientQuery,
} = api;
