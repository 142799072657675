import { Pressable as BasePressable } from "native-base";
import type { ComponentProps, ReactNode } from "react";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import { useLinking } from "../providers/routing";

interface ChildrenProps {
    isActive: boolean;
}

interface Props<TRouteId extends RouteId>
    extends Omit<ComponentProps<typeof BasePressable>, "onPress" | "children"> {
    route: TRouteId;
    params: AppRoutes[TRouteId];
    search?: Record<string, string>;
    readonly children: ((props: ChildrenProps) => ReactNode) | ReactNode;
}

/**
 * Add routing capabilities to Pressable
 */
const PressableLink = <TRouteId extends RouteId>({
    route,
    params,
    search,
    children,
    ...rest
}: Props<TRouteId>) => {
    const { isActive, ...linkProps } = useLinking(route, params, search);

    return (
        <BasePressable {...rest} {...linkProps}>
            {children instanceof Function ? children({ isActive }) : children}
        </BasePressable>
    );
};

export default PressableLink;
