import { useMemo } from "react";
import type { Column } from "react-table";
import { ProviderConnectionStatus, forHumans } from "@madmedical/medical";
import { formatDateTime } from "@madmedical/utils";
import { Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { Device } from "../providers/device";
import ReactTable from "../molecules/ReactTable";
import Tag from "../atoms/Tag";
import AvatarTexts from "../molecules/AvatarTexts";
import { useDevices } from "../providers/device";
import getImage from "./DeviceItem/getImage";

const MAX_TAGS = 3;

const ConnectedDevicesTable = () => {
    const { devices } = useDevices();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const columns = useMemo<Column<Device>[]>(
        () => [
            {
                Header: t("deviceName"),
                accessor: "provider",
                defaultCanSort: true,
                width: "25%",
                Cell: ({ row: { original: device } }) => (
                    <AvatarTexts
                        size="xl"
                        isCircle={false}
                        source={getImage(device.provider)}
                        label={device.deviceName}
                        subText={t(`backend:${device.manufacturer}`)}
                        status={
                            device.status === ProviderConnectionStatus.Off
                                ? undefined
                                : device.status
                        }
                    />
                ),
            },
            {
                Header: t("measures:type"),
                accessor: "metricTypes",
                width: "auto",
                Cell: ({ value: metricTypes }) => {
                    const count = metricTypes.length;
                    if (!count) {
                        return <Text>{"–"}</Text>;
                    }

                    const types = metricTypes.slice(0, MAX_TAGS);
                    const remains = count - types.length;

                    return (
                        <>
                            {types.map((metricType) => (
                                <Tag key={metricType} mr={2}>
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Tag>
                            ))}
                            {remains ? (
                                <Tag key={remains} mr={2}>
                                    +{remains}
                                </Tag>
                            ) : null}
                        </>
                    );
                },
            },
            {
                Header: t("measures:lastRecord"),
                accessor: "lastRecordedAt",
                width: "20%",
                defaultCanSort: true,
                sortType: (a, b) =>
                    b.values.lastRecordedAt - a.values.lastRecordedAt,
                Cell: ({ value: lastRecordedAt }) => (
                    <>{lastRecordedAt ? formatDateTime(lastRecordedAt) : "-"}</>
                ),
            },
        ],
        [t]
    );

    const handlePress = ({ provider }: Device) => {
        navigate("device", { provider });
    };

    return (
        <ReactTable columns={columns} data={devices} onPress={handlePress} />
    );
};

export default ConnectedDevicesTable;
