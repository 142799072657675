import Svg, { Path, Rect } from "react-native-svg";

const SweetPotato = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M76.7893 26.2619C76.5485 27.2452 76.5955 28.2769 76.9246 29.2343C77.6441 31.3682 78.1475 33.5691 78.4272 35.8036C78.277 36.7692 78.3707 37.757 78.6999 38.677C78.7842 40.1089 78.7967 41.5441 78.7373 42.9772C63.0047 67.039 30.473 67.2206 28.7012 46.8977C29.2174 45.7421 29.8093 44.6218 30.473 43.5441C31.0772 43.2505 31.393 42.6725 31.5405 41.9308C32.1042 41.1357 32.7247 40.347 33.4021 39.5648C33.7256 39.4364 34.0162 39.2371 34.2527 38.9816C34.4891 38.7261 34.6653 38.421 34.7683 38.0885C35.7263 37.117 36.74 36.2022 37.8043 35.3485C42.467 31.5458 47.7019 28.5046 53.315 26.3379C54.3851 26.2911 55.1769 25.9983 55.7264 25.4921C61.5682 23.6104 66.1779 23.3292 68.5158 23.3403C69.6929 23.3467 70.8514 23.0469 71.8776 22.4704C72.3274 22.2174 72.8316 22.077 73.3474 22.0611C73.8632 22.0452 74.3752 22.1542 74.8397 22.3789L74.9894 22.4512C75.6798 22.7851 76.235 23.3455 76.5625 24.039C76.8901 24.7325 76.9701 25.5173 76.7893 26.2627V26.2619Z"
            fill="#EFB493"
        />
        <Path
            d="M77.186 53.1056C78.069 49.7962 78.5891 46.4005 78.7373 42.9785C74.0201 43.3108 62.3107 45.0691 52.3319 54.1695C46.1065 59.8467 36.2988 58.8777 31.4328 51.9994C30.3185 50.4163 29.4015 48.7031 28.702 46.898C23.8198 57.8346 27.4154 68.206 27.4154 68.206C29.1379 73.5907 29.4104 77.2244 29.3482 79.2879C29.3285 79.9873 29.4672 80.6821 29.7537 81.3203C30.0403 81.9586 30.4674 82.5239 31.0031 82.9739C31.544 83.4299 32.2343 83.6699 32.9413 83.6478C33.6484 83.6257 34.3224 83.3431 34.8338 82.8543C35.1184 82.5826 35.4558 82.3723 35.8252 82.2367C36.1945 82.101 36.5878 82.0428 36.9806 82.0658C58.8799 83.337 73.2722 67.9303 77.186 53.1056Z"
            fill="#DC866A"
        />
        <Path
            d="M102.786 50.8129L102.823 50.6797C103.013 49.9947 103.008 49.2703 102.809 48.5878C102.611 47.9052 102.226 47.2914 101.699 46.8149C101.145 46.3151 100.458 45.9877 99.7213 45.8728C98.9845 45.758 98.2302 45.8608 97.551 46.1685L97.207 46.3243C96.2041 46.7789 95.105 46.9806 94.006 46.9118C93.0573 47.1789 91.9598 47.1694 90.6028 46.6785C84.4916 46.4293 76.4472 47.043 68.8475 50.3499C68.2748 50.8763 67.5549 51.2153 66.7843 51.3216C63.5484 52.955 60.5873 55.0835 58.008 57.6303C57.9674 58.2373 57.4105 58.6634 56.6745 59.0268C55.516 60.3051 54.4336 61.6503 53.4326 63.0553C53.4789 63.7859 53.0795 64.2819 52.3695 64.6175C43.9434 77.5824 44.2443 91.9358 44.7286 97.3153C44.8387 98.5939 44.5555 99.8755 43.9169 100.989C43.4573 101.805 43.1114 102.681 42.8886 103.591C42.792 103.982 42.7843 104.389 42.8659 104.782C42.9476 105.176 43.1166 105.546 43.3605 105.866C43.6044 106.186 43.9169 106.446 44.2751 106.629C44.6332 106.812 45.0278 106.912 45.4297 106.922C46.3678 106.941 47.3058 106.879 48.233 106.735C49.0271 106.611 49.8376 106.64 50.6207 106.821C54.169 107.617 57.7988 107.992 61.435 107.938C62.1123 107.752 62.8241 107.728 63.5123 107.87C64.813 107.798 66.1719 107.67 67.5752 107.47C68.1775 107.219 68.8296 107.112 69.4804 107.156C75.1887 106.107 80.6193 103.892 85.4332 100.65C85.5825 100.352 85.8028 100.096 86.0748 99.9031C86.3467 99.7107 86.6618 99.5883 86.9923 99.5466C88.4267 98.4813 89.7955 97.3306 91.0915 96.1007C91.4102 95.4998 91.9048 95.0106 92.5091 94.6985C94.0947 93.058 95.5682 91.3127 96.9197 89.4745C96.9197 89.4745 100.355 84.2892 102.392 76.1047C102.299 74.9193 102.502 74.0696 102.965 73.5149C103.191 72.3514 103.383 71.1394 103.531 69.8839C103.361 68.8322 103.452 67.7546 103.797 66.7467C104.021 62.5264 103.66 58.2955 102.723 54.1744C102.465 53.066 102.487 51.9109 102.786 50.8129Z"
            fill="#EFB493"
        />
        <Path
            d="M45.9912 45.8271C46.5222 45.8271 46.9526 45.3967 46.9526 44.8657C46.9526 44.3347 46.5222 43.9043 45.9912 43.9043C45.4602 43.9043 45.0298 44.3347 45.0298 44.8657C45.0298 45.3967 45.4602 45.8271 45.9912 45.8271Z"
            fill="#DC866A"
        />
        <Path
            d="M82.3177 63.0615C82.8487 63.0615 83.2791 62.631 83.2791 62.1001C83.2791 61.5691 82.8487 61.1387 82.3177 61.1387C81.7868 61.1387 81.3563 61.5691 81.3563 62.1001C81.3563 62.631 81.7868 63.0615 82.3177 63.0615Z"
            fill="#DC866A"
        />
        <Path
            d="M56.076 99.539C56.607 99.539 57.0374 99.1086 57.0374 98.5776C57.0374 98.0466 56.607 97.6162 56.076 97.6162C55.5451 97.6162 55.1146 98.0466 55.1146 98.5776C55.1146 99.1086 55.5451 99.539 56.076 99.539Z"
            fill="#DC866A"
        />
        <Path
            d="M63.5118 107.868C62.3797 105.577 61.0427 103.393 59.5171 101.342C59.3652 101.147 59.1425 101.018 58.8969 100.985C58.6513 100.952 58.4024 101.017 58.2041 101.165C58.0057 101.314 57.8736 101.534 57.8362 101.779C57.7989 102.024 57.8593 102.274 58.0045 102.475C59.286 104.205 60.4327 106.031 61.4344 107.936C62.1088 107.929 62.8012 107.906 63.5118 107.868Z"
            fill="#DC866A"
        />
        <Path
            d="M69.481 107.154C67.3861 101.683 64.2249 96.6835 60.1811 92.445C60.0095 92.2625 59.7724 92.1556 59.522 92.1479C59.2716 92.1402 59.0283 92.2323 58.8458 92.4039C58.6633 92.5755 58.5564 92.8126 58.5487 93.0631C58.541 93.3135 58.633 93.5567 58.8047 93.7393C62.5875 97.7058 65.566 102.368 67.5755 107.467C68.2026 107.378 68.8377 107.273 69.481 107.154Z"
            fill="#DC866A"
        />
        <Path
            d="M83.1198 92.5927C83.055 92.4856 82.9696 92.3924 82.8686 92.3186C82.7675 92.2448 82.6528 92.1918 82.5311 92.1627C82.4094 92.1335 82.2831 92.1289 82.1596 92.149C82.0361 92.1691 81.9178 92.2135 81.8115 92.2797C81.7053 92.3458 81.6133 92.4325 81.5409 92.5345C81.4684 92.6365 81.4169 92.7519 81.3894 92.874C81.3619 92.9961 81.359 93.1224 81.3807 93.2457C81.4024 93.3689 81.4484 93.4866 81.516 93.5919C82.6985 95.4896 84.2971 98.3814 85.4322 100.647C85.9539 100.295 86.4743 99.9294 86.9924 99.5449C85.8405 97.2679 84.283 94.4597 83.1198 92.5927Z"
            fill="#DC866A"
        />
        <Path
            d="M59.8559 67.6761C57.7231 66.0364 55.1614 64.2277 53.4348 63.0566C53.0657 63.5745 52.7114 64.0952 52.3719 64.6187C54.0702 65.7704 56.5985 67.5552 58.7037 69.1741C58.9024 69.3268 59.1535 69.3943 59.4019 69.3618C59.6504 69.3293 59.8757 69.1994 60.0284 69.0008C60.1811 68.8021 60.2486 68.551 60.2161 68.3026C60.1836 68.0541 60.0537 67.8288 59.8551 67.6761H59.8559Z"
            fill="#DC866A"
        />
        <Path
            d="M92.507 94.696C90.4936 90.6865 88.2163 86.815 85.69 83.1072C85.6204 83.0022 85.5305 82.9121 85.4255 82.8424C85.3205 82.7727 85.2027 82.7246 85.0789 82.7011C84.9551 82.6776 84.8278 82.6792 84.7046 82.7056C84.5814 82.732 84.4647 82.7829 84.3614 82.8551C84.2581 82.9273 84.1704 83.0194 84.1032 83.1261C84.0361 83.2328 83.991 83.3518 83.9706 83.4761C83.9502 83.6005 83.9549 83.7277 83.9843 83.8502C84.0138 83.9727 84.0675 84.0881 84.1423 84.1895C86.7271 87.9962 89.0488 91.9751 91.091 96.0984C91.5665 95.6483 92.0385 95.1808 92.507 94.696Z"
            fill="#DC866A"
        />
        <Path
            d="M66.8311 66.0851C66.9284 66.1639 67.0403 66.2226 67.1604 66.2577C67.2806 66.2929 67.4065 66.3038 67.5309 66.2899C67.6553 66.276 67.7757 66.2375 67.885 66.1766C67.9944 66.1158 68.0906 66.0338 68.1681 65.9355C68.2455 65.8372 68.3027 65.7244 68.3362 65.6038C68.3697 65.4832 68.3789 65.3572 68.3633 65.233C68.3477 65.1088 68.3076 64.989 68.2453 64.8804C68.1829 64.7719 68.0997 64.6768 68.0003 64.6007C64.7908 62.1089 61.4561 59.7826 58.0094 57.6309C57.6327 58.0041 57.2615 58.389 56.8956 58.7855C56.8215 58.8666 56.7501 58.9478 56.677 59.029C60.1837 61.2005 63.5731 63.5558 66.8311 66.0851Z"
            fill="#DC866A"
        />
        <Path
            d="M77.5864 58.8909C77.7803 59.0492 78.029 59.1241 78.278 59.0993C78.527 59.0744 78.756 58.9518 78.9147 58.7583C79.0734 58.5648 79.1489 58.3162 79.1245 58.0671C79.1002 57.8181 78.978 57.5888 78.7849 57.4297C75.6377 54.8468 72.318 52.4816 68.8489 50.3506C68.1567 50.6517 67.469 50.9755 66.7856 51.322C70.5721 53.5683 74.1827 56.0985 77.5864 58.8909Z"
            fill="#DC866A"
        />
        <Path
            d="M99.0942 67.9982C98.9411 67.8026 98.717 67.6751 98.4706 67.6435C98.2242 67.6118 97.9752 67.6785 97.7776 67.829C97.5799 67.9796 97.4495 68.2019 97.4146 68.4479C97.3797 68.6939 97.4431 68.9437 97.5911 69.1433C98.9457 70.9211 101.03 73.9503 102.392 76.1045C102.6 75.2716 102.791 74.4083 102.965 73.5144C101.686 71.5819 100.166 69.4058 99.0942 67.9982Z"
            fill="#DC866A"
        />
        <Path
            d="M103.796 66.7461C101.548 63.4527 99.02 60.3597 96.2397 57.5015C96.0644 57.324 95.8261 57.2231 95.5767 57.2207C95.3272 57.2184 95.087 57.3148 94.9084 57.4888C94.7297 57.6629 94.6272 57.9006 94.6232 58.15C94.6191 58.3994 94.7139 58.6403 94.8868 58.8201C97.6422 61.6485 101.249 65.9365 103.53 69.8831C103.65 68.8651 103.74 67.8188 103.796 66.7461Z"
            fill="#DC866A"
        />
        <Path
            d="M96.3731 51.4088C96.5511 51.5821 96.7901 51.6784 97.0386 51.6767C97.287 51.6751 97.5248 51.5757 97.7004 51.4C97.8761 51.2243 97.9755 50.9866 97.9772 50.7381C97.9788 50.4897 97.8826 50.2506 97.7092 50.0726C96.533 48.9511 95.2968 47.8943 94.006 46.9068C93.9335 46.9022 93.8607 46.9 93.7879 46.8932C92.8029 46.7993 91.7357 46.7236 90.6028 46.6768C94.0428 49.262 95.6406 50.6766 96.3731 51.4088Z"
            fill="#DC866A"
        />
        <Path
            d="M40.2737 46.0792C37.4488 44.5227 34.5327 43.1377 31.5413 41.9316C31.161 42.4674 30.805 43.005 30.4733 43.5443C33.5233 44.7547 36.4943 46.1555 39.3686 47.7385C39.5882 47.8542 39.8445 47.8788 40.0821 47.8071C40.3198 47.7354 40.5196 47.573 40.6385 47.3551C40.7574 47.1372 40.7858 46.8813 40.7175 46.6427C40.6493 46.404 40.4898 46.2018 40.2737 46.0798V46.0792Z"
            fill="#DC866A"
        />
        <Path
            d="M40.4231 40.6562C38.529 39.7259 36.3177 38.7434 34.7697 38.0889C34.2891 38.577 33.834 39.069 33.4043 39.5649C34.8977 40.1811 37.4501 41.2999 39.5902 42.352C39.7016 42.4067 39.8226 42.4389 39.9464 42.4468C40.0702 42.4547 40.1943 42.4382 40.3117 42.3981C40.4291 42.358 40.5375 42.2952 40.6306 42.2133C40.7238 42.1314 40.7999 42.0319 40.8545 41.9205C40.9092 41.8092 40.9414 41.6882 40.9494 41.5644C40.9573 41.4406 40.9407 41.3164 40.9007 41.199C40.8606 41.0816 40.7978 40.9732 40.7159 40.8801C40.6339 40.787 40.5344 40.7109 40.4231 40.6562Z"
            fill="#DC866A"
        />
        <Path
            d="M60.551 37.8374C60.7491 37.8376 60.9422 37.7755 61.1031 37.6599C61.264 37.5444 61.3844 37.3812 61.4475 37.1934C61.5105 37.0056 61.5129 36.8028 61.4543 36.6135C61.3958 36.4243 61.2792 36.2583 61.1212 36.1389C58.9594 34.5057 56.6962 32.946 54.3951 31.5046C54.1828 31.3715 53.9264 31.3282 53.6821 31.3843C53.4379 31.4403 53.226 31.5911 53.0929 31.8034C52.9599 32.0157 52.9166 32.2722 52.9727 32.5164C53.0287 32.7606 53.1795 32.9726 53.3918 33.1056C55.6463 34.5186 57.8638 36.0464 59.9822 37.6466C60.1459 37.7707 60.3457 37.8377 60.551 37.8374Z"
            fill="#DC866A"
        />
        <Path
            d="M60.2813 30.6259C60.4894 30.7658 60.7446 30.8174 60.9907 30.7692C61.2369 30.721 61.4537 30.577 61.5937 30.3688C61.7336 30.1607 61.7852 29.9055 61.7369 29.6594C61.6887 29.4133 61.5447 29.1964 61.3366 29.0565C59.4154 27.7652 57.4634 26.4859 55.7279 25.4912C54.9453 25.7438 54.1416 26.0257 53.3167 26.3371C55.6256 27.4963 58.6954 29.56 60.2813 30.6259Z"
            fill="#DC866A"
        />
        <Path
            d="M78.7002 38.6849C78.6396 37.6602 78.5443 36.6988 78.4266 35.804C76.8269 34.2954 75.0085 32.6527 73.5768 31.4305C73.4828 31.3476 73.3732 31.2842 73.2545 31.2441C73.1357 31.2039 73.0102 31.1878 72.8851 31.1967C72.7601 31.2056 72.6381 31.2392 72.5262 31.2957C72.4143 31.3522 72.3147 31.4304 72.2333 31.5257C72.1519 31.6211 72.0903 31.7316 72.0521 31.851C72.0139 31.9704 71.9998 32.0962 72.0106 32.2211C72.0215 32.346 72.0571 32.4674 72.1154 32.5784C72.1736 32.6894 72.2534 32.7877 72.35 32.8676C74.2587 34.497 76.8664 36.8863 78.6912 38.6779C78.6939 38.6806 78.6975 38.6822 78.7002 38.6849Z"
            fill="#DC866A"
        />
    </Svg>
);

export default SweetPotato;
