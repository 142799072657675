import type { ReactNode } from "react";
import { Box, Button, ScrollView, Stack, Text, VStack } from "native-base";
import { MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import MeasurementsTabs from "../../organisms/MeasurementsTabs";
import useResponsive from "../../util/useResponsive";
import Paper from "../../atoms/Paper";
import ListHeader from "../../molecules/ListHeader";
import InsulinBodyChartWrapper from "./Charts/InsulinBodyChartWrapper";
import InsulinPortioningChart from "./Charts/InsulinPortioningChart";
import MainInsulinChart from "./Charts/MainInsulinChart";

interface Props {
    addCommentButton: ReactNode;
    onAddInsulinAdministration: () => void;
    onAddInsulinReminder: () => void;
    isPatient?: boolean;
    insulinUser?: boolean;
    insulinProtocol?: {
        id: string;
        instructions: string;
    };
    editInsulinProtocol?: (id: string, instructions: string) => void;
    BodyChartWrapper: ({ children }: { children: JSX.Element }) => JSX.Element;
    PortioningWrapper: ({ children }: { children: JSX.Element }) => JSX.Element;
}

const InsulinPageContent = ({
    addCommentButton,
    onAddInsulinAdministration,
    onAddInsulinReminder,
    isPatient = true,
    insulinProtocol,
    editInsulinProtocol,
    BodyChartWrapper,
    PortioningWrapper,
    insulinUser,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const updateInsulinProtocol = () => {
        if (insulinProtocol && editInsulinProtocol) {
            editInsulinProtocol?.(
                insulinProtocol.id,
                insulinProtocol.instructions
            );
        }
    };
    const { t } = useTranslation();

    return (
        <ScrollView>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link route="dashboard" params={{}}>
                                {t("health")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>{t("insulin")}</Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <MeasurementsTabs
                metricType={MetricType.BloodSugar}
                isPatient
                insulinUser={insulinUser}
            />

            <VStack space={4}>
                <MainInsulinChart
                    addCommentButton={addCommentButton}
                    onAddInsulinAdministration={onAddInsulinAdministration}
                    onAddInsulinReminder={onAddInsulinReminder}
                    isPatient={isPatient}
                />

                {insulinProtocol && (
                    <Stack
                        space={isSmallScreen ? 0 : 4}
                        direction={isSmallScreen ? "column" : "row"}
                        p={isSmallScreen ? 4 : 0}
                    >
                        {isSmallScreen && (
                            <ListHeader
                                title={t("measures:insulinInjectionProtocol")}
                                size="xs"
                                borderBottomWidth={0}
                                bgColor="none"
                                px={0}
                            />
                        )}

                        <Paper borderRadius="lg" flex="1">
                            {!isSmallScreen && (
                                <Headline
                                    title={t(
                                        "measures:insulinInjectionProtocol"
                                    )}
                                    rightContent={
                                        !isPatient &&
                                        editInsulinProtocol && (
                                            <Button
                                                p={0}
                                                size="xs"
                                                variant="link"
                                                onPress={updateInsulinProtocol}
                                            >
                                                {t("edit")}
                                            </Button>
                                        )
                                    }
                                    size="xs"
                                />
                            )}
                            <Box
                                p={{
                                    base: 4,
                                    md: 6,
                                }}
                                width="full"
                            >
                                <Text fontSize="xs" color="gray.600">
                                    {insulinProtocol.instructions}
                                </Text>
                            </Box>
                        </Paper>
                    </Stack>
                )}

                <PortioningWrapper key="portion">
                    <InsulinPortioningChart />
                </PortioningWrapper>
                <BodyChartWrapper key="bodyChart">
                    <InsulinBodyChartWrapper />
                </BodyChartWrapper>
            </VStack>

            <Box w={1} h={100} />
        </ScrollView>
    );
};

export default InsulinPageContent;
