import type { MetricType } from "@madmedical/medical";
import { AlertLevel, forHumans } from "@madmedical/medical";
import { Box, HStack, VStack } from "native-base";
import type { Ulid } from "@madmedical/utils";
import { formatBytes, formatDateRange } from "@madmedical/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import ThresholdsProtocolsTable from "../organisms/ThresholdsProtocolsTable";
import useUpload from "../form/upload/useUpload";
import DocumentUploadFormInner from "../organisms/DocumentUploadFormInner";
import { useCurrentPatient } from "../providers/currentPatient";
import PatientDetailsSection from "../organisms/Patient/PatientDetailsSection";
import DeviceSection from "../organisms/DeviceSection";
import { useRouteNavigate } from "../providers/routing";
import StatusBadge from "../atoms/StatusBadge";
import IconButton from "../atoms/IconButton";
import Button from "../atoms/Button";
import Breadcrumb from "../molecules/Breadcrumb";
import Headline from "../molecules/Headline";
import RoutableTabs from "../molecules/RoutableTabs";
import Metrics from "../molecules/Metrics";
import DocumentListCard from "../molecules/DocumentListCard";
import { useChart } from "../providers/chart";
import type { FileToUpload } from "../form/upload/model";
import FormControl from "../molecules/Form/FormControl";
import type { Evaluation } from "../providers/evaluation";

interface OpinionForm {
    text: string;
    newFiles: FileToUpload[];
    alertLevel: AlertLevel;
}

interface Props {
    evaluation: Evaluation | undefined;
    metricType: MetricType;
    isSaving: boolean;
    isUploading: boolean;
    isSubmitDisabled: boolean;
    onSubmit: (form: OpinionForm) => Promise<void>;
    onDeleteDocumentPress: (id: Ulid) => void;
}

const EvaluatingProcess = ({
    evaluation,
    metricType,
    isSaving,
    isUploading,
    isSubmitDisabled,
    onSubmit,
    onDeleteDocumentPress,
}: Props) => {
    const navigate = useRouteNavigate();
    const { patient } = useCurrentPatient();
    const { chart, displayRange, dateRange, alertLevel } = useChart();
    const { files: newFiles, resetFiles, formProps } = useUpload();
    const [text, setText] = useState(evaluation?.text ?? "");
    const { t } = useTranslation();
    const handleClosePress = () => {
        if (evaluation) {
            navigate("opinion", { opinionId: evaluation.id });
        } else {
            navigate("measurements", { metricType });
        }
    };

    const handleDeleteDocumentPress = (id: Ulid) => () => {
        onDeleteDocumentPress(id);
    };

    const handleSubmitPress = useCallback(() => {
        void onSubmit({
            text,
            newFiles,
            alertLevel: alertLevel ?? AlertLevel.Unevaluated,
        }).then(() => {
            resetFiles();
        });
    }, [text, newFiles, alertLevel, onSubmit, resetFiles]);

    return (
        <VStack
            mb={{
                base: 24,
                md: 6,
            }}
            width="full"
        >
            <Box opacity={0.4} width="full">
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Link
                                route="patient"
                                params={{ patientId: patient.userId }}
                            >
                                <Breadcrumb.Text>
                                    {patient.fullName}
                                </Breadcrumb.Text>
                            </Breadcrumb.Link>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <Headline
                    leftContent={
                        <RoutableTabs
                            tabs={[
                                {
                                    route: "measurements",
                                    params: {
                                        metricType,
                                    },
                                    title: t(
                                        `measures:${forHumans(metricType)}`
                                    ),
                                },
                                {
                                    route: "measurements_list",
                                    params: {
                                        metricType,
                                    },
                                    title: t("measures:title"),
                                },
                            ]}
                        />
                    }
                />
            </Box>

            <Box
                borderStyle="dashed"
                borderWidth={1}
                borderColor="gray.600"
                borderRadius="xl"
                shadow="4"
                width="full"
                bg="white"
                mb={3}
            >
                <Headline
                    borderTopRadius="xl"
                    leftContent={
                        <>
                            <VStack>
                                <Box>{formatDateRange(dateRange, "long")}</Box>
                                <HStack alignItems="center">
                                    {displayRange && (
                                        <Metrics
                                            displayValue={[displayRange]}
                                            valueFontSize="5xl"
                                        />
                                    )}
                                    <Box pt={3.5}>
                                        <StatusBadge
                                            level={alertLevel}
                                            size="lg"
                                        />
                                    </Box>
                                </HStack>
                            </VStack>
                        </>
                    }
                    rightContent={
                        <IconButton
                            variant="ghost"
                            iconName="close"
                            onPress={handleClosePress}
                        />
                    }
                />

                <Box my={6} width="100%">
                    {chart}
                </Box>

                <Box px={6} py={4}>
                    <Headline
                        px={0}
                        py={0}
                        mb={2}
                        borderBottomWidth={0}
                        minHeight="auto"
                        title={t("doctor:opinion")}
                        size="sm"
                        _title={{
                            textTransform: "none",
                        }}
                    />
                    <FormControl
                        multiline
                        minH={40}
                        numberOfLines={4}
                        value={text}
                        onChangeText={setText}
                    />
                </Box>

                <Box px={6} py={4}>
                    <VStack>
                        {evaluation?.documents.map(
                            ({ id, filename, size, note }) => (
                                <DocumentListCard
                                    key={id}
                                    leftIconName="documentBlank"
                                    rightIconName="trashCan"
                                    label={filename}
                                    subText={formatBytes(size)}
                                    note={note}
                                    onRightIconPress={handleDeleteDocumentPress(
                                        id
                                    )}
                                    isEditable
                                />
                            )
                        )}
                    </VStack>
                    <DocumentUploadFormInner
                        {...formProps}
                        files={newFiles.map(
                            ({ file: { name, size }, note }) => ({
                                name,
                                size,
                                note,
                            })
                        )}
                        isUploading={isUploading}
                    />
                    {/* TODO: Not sure how this should work
                            <Box
                                flexDirection="row"
                                justifyContent="flex-start"
                                mt={2}
                            >
                                <Button
                                    size="xs"
                                    onPress={handleUploadMorePress}
                                    leftIconName="documentAttachment"
                                    variant="outlined"
                                >
                                    Továbbiak csatolása
                                </Button>
                            </Box>
                        */}
                </Box>
                <HStack
                    mt={4}
                    px={6}
                    py={4}
                    borderTopWidth={1}
                    borderTopColor="gray.100"
                    justifyContent="flex-end"
                >
                    <Button variant="outlined" onPress={handleClosePress}>
                        {t("cancel")}
                    </Button>
                    <Button
                        onPress={handleSubmitPress}
                        isLoading={isSaving}
                        isLoadingText={t("saveResult") + "…"}
                        isDisabled={!text || isSubmitDisabled}
                        variant="primary"
                        ml={2}
                    >
                        {t("saveResult")}
                    </Button>
                </HStack>
            </Box>

            <VStack opacity={0.4} width="full" space={3}>
                <PatientDetailsSection borderRadius="lg" />

                <ThresholdsProtocolsTable
                    title={t("measures:measureDetails")}
                />

                <DeviceSection />
            </VStack>
        </VStack>
    );
};

export default EvaluatingProcess;
