import {
    FormControl as BaseFormControl,
    Box,
    HStack,
    VStack,
} from "native-base";
import {
    genderForHumans,
    getUnderlyingConditionChoices,
    serviceForHumans,
} from "@madmedical/medical";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import { useCurrentPatient } from "../providers/currentPatient";
import Tag from "../atoms/Tag";
import Button from "../atoms/Button";
import ModalContent from "../molecules/ModalContent";
import DateInput from "../molecules/Form/DateInput";
import type { PatientDoctor } from "../providers";
import FormControl from "../molecules/Form/FormControl";
import MultiSelect from "../form/MultiSelect";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const PatientDataModalForm = ({ onClose, onSubmitPress, isSuccess }: Props) => {
    const { patient } = useCurrentPatient<PatientDoctor>();

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("editPersonalData")}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton onPress={onSubmitPress} label={t("save")} />
                </>
            }
        >
            <VStack>
                <HStack space={2} mb={6}>
                    <FormControl
                        value={patient.fullName}
                        label={t("name")}
                        isReadOnly
                    />
                    <FormControl
                        value={patient.socialSecurityNumber ?? undefined}
                        label={t("socialSecurityNumber")}
                        isReadOnly
                    />
                </HStack>
                <HStack space={2} mb={6}>
                    <Box flex={1}>
                        <BaseFormControl.Label>
                            {t("dateOfBirth")}
                        </BaseFormControl.Label>
                        {patient.birthDate && (
                            <DateInput
                                value={new Date(patient.birthDate)}
                                size="sm"
                                isReadOnly
                            />
                        )}
                    </Box>
                    <Box flex={1}>
                        <FormControl
                            isReadOnly
                            label={t("no")}
                            value={
                                patient.gender
                                    ? t(
                                          `pregnancy:${genderForHumans(
                                              patient.gender
                                          )}`
                                      )
                                    : "–"
                            }
                        />
                    </Box>
                </HStack>
                <VStack space={2} mb={6}>
                    <BaseFormControl.Label>
                        {t("packages")}
                    </BaseFormControl.Label>
                    <HStack space={1}>
                        {patient.services.map((service) => (
                            <Tag key={service}>
                                {t(
                                    `backend:${serviceForHumans(service)}`
                                ).toLowerCase()}
                            </Tag>
                        ))}
                    </HStack>
                    <BaseFormControl.Label>
                        {t("illnesses")}
                    </BaseFormControl.Label>
                    <MultiSelect
                        name="underlyingConditions"
                        list={getUnderlyingConditionChoices(undefined, true)}
                        width="full"
                    />
                </VStack>
            </VStack>
        </ModalContent>
    );
};

export default PatientDataModalForm;
