import {
    Box,
    Divider,
    FlatList,
    HStack,
    ScrollView,
    Text,
    VStack,
} from "native-base";
import { Fragment } from "react";
import { useWindowDimensions } from "react-native";
import {
    PREGNANCY_SYMPTOMS_FOR_HUMANS,
    type PregnancyWellBeing,
} from "@madmedical/medical";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PregnantMoodPics from "../../molecules/Pregnancy/PregnantMoodPics";
import ActionButton from "../../molecules/ActionButton";
import ListHeader from "../../molecules/ListHeader";
import { usePregnancy } from "../../providers";
import Tag from "../../atoms/Tag";

type HeaderType = {
    title: string;
    badge: number;
};

type ExtendedData = PregnancyWellBeing & { editable?: boolean };

type SectionType = {
    header: HeaderType;
    data: ExtendedData[];
};

interface Props {
    sections: SectionType[];
    isDoctor?: boolean;
}

const PregnancyMoodListMobile = ({ sections, isDoctor = false }: Props) => {
    const screenHeight = useWindowDimensions().height;
    const { onEditPress, onDeletePress } = usePregnancy();
    const { t } = useTranslation();

    return (
        <ScrollView maxHeight={screenHeight - 200} width="full">
            <VStack pb="50">
                <FlatList
                    data={sections}
                    // onEndReached={onEndReached}
                    renderItem={({ item: section, index }) => (
                        <Fragment key={`mood-table-section-${index}`}>
                            <Box
                                bgColor="gray.50"
                                borderColor="gray.100"
                                borderBottomWidth={1}
                                borderTopWidth={1}
                            >
                                <ListHeader
                                    title={section.header.title}
                                    badge={section.header.badge}
                                    variant="inverted"
                                    bgColor="transparent"
                                    px={4}
                                    pb={3}
                                    pt={8}
                                />
                            </Box>

                            <VStack
                                divider={<Divider />}
                                backgroundColor={"white"}
                            >
                                {section.data.map((item) => (
                                    <HStack
                                        key={item.id}
                                        px={4}
                                        py={4}
                                        alignItems="flex-start"
                                        width={"100%"}
                                    >
                                        <HStack
                                            mr="auto"
                                            space={2}
                                            mb={2}
                                            alignItems={"flex-start"}
                                        >
                                            <Box w={"64px"} h={"64px"}>
                                                <PregnantMoodPics
                                                    status={item.status}
                                                />
                                            </Box>
                                            <Box>
                                                <HStack
                                                    space={1}
                                                    flexWrap={"wrap"}
                                                    width={"full"}
                                                >
                                                    {item?.symptoms?.map(
                                                        (symptom) => (
                                                            <Tag
                                                                key={symptom}
                                                                my={1}
                                                            >
                                                                {t(
                                                                    `pregnancy:${PREGNANCY_SYMPTOMS_FOR_HUMANS[symptom]}`
                                                                )}
                                                            </Tag>
                                                        )
                                                    )}
                                                </HStack>
                                                {item?.comment && (
                                                    <Text
                                                        isTruncated
                                                        noOfLines={6}
                                                        maxWidth="95%"
                                                        py={1}
                                                    >
                                                        {item?.comment}
                                                    </Text>
                                                )}

                                                <Text
                                                    fontSize={"xs"}
                                                    color={"gray.500"}
                                                >
                                                    {formatDateTime(
                                                        item?.createdAt
                                                    )}
                                                </Text>
                                            </Box>
                                        </HStack>
                                        <ActionButton
                                            actions={
                                                item.editable && !isDoctor
                                                    ? [
                                                          {
                                                              text: t("edit"),
                                                              iconName: "edit",
                                                              onPress: () => {
                                                                  onEditPress?.(
                                                                      {
                                                                          id: item.id,
                                                                          createdAt:
                                                                              item.createdAt,
                                                                          status: item.status,
                                                                          symptoms:
                                                                              item.symptoms,
                                                                          comment:
                                                                              item.comment,
                                                                      }
                                                                  );
                                                              },
                                                          },
                                                          {
                                                              text: t("delete"),
                                                              iconName:
                                                                  "trashCan",
                                                              onPress: () =>
                                                                  onDeletePress?.(
                                                                      item.id
                                                                  ),
                                                          },
                                                      ]
                                                    : []
                                            }
                                        />
                                    </HStack>
                                ))}
                            </VStack>
                        </Fragment>
                    )}
                />
            </VStack>
        </ScrollView>
    );
};

export default PregnancyMoodListMobile;
