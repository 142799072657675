import type { BloodSugar } from "@madmedical/medical";
import type { MeasurementRangeResponse } from "../../measurement/model";

export type BloodSugarAverages = Record<string, number>;

export const calculateBloodSugarAveragesByDate = (
    data: MeasurementRangeResponse<BloodSugar>
) => {
    const groupedData = data.items.reduce<
        Record<string, { count: number; sum: 0 }>
    >((acc, item) => {
        const date = new Date(item.measuredAt).toISOString().split("T")[0];
        if (!acc[date]) {
            acc[date] = { sum: 0, count: 0 };
        }
        acc[date].sum += item.metric.mmolPerL;
        acc[date].count += 1;

        return acc;
    }, {});

    const averagesByDate = Object.entries(groupedData).reduce<
        Record<string, number>
    >((acc, [date, data]) => {
        const dateKey = new Date(date.valueOf()).setHours(0, 0, 0, 0);
        acc[dateKey] = data.sum / data.count;

        return acc;
    }, {});

    return averagesByDate;
};
