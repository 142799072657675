import { useCallback, useEffect, useMemo, useState } from "react";
import { Dimensions } from "react-native";
import { Box, Pressable, Text } from "native-base";
import { Circle, G, Path, Svg } from "react-native-svg";
import { isWeb, useWindowSize } from "@madmedical/utils";
import type { GestureResponderEvent } from "react-native";
import useResponsive from "../util/useResponsive";
import type {
    MoodTooltipType,
    PregnancyBodyValueType,
    PregnancyPointData,
} from "./types";
import MoodTooltip from "./MoodTooltip";

type GraphType = "weight" | "bmi" | "circumference";

type Props = {
    pointData: PregnancyPointData[];
    graphType: "weight" | "circumference";
    ranges?: {
        weight?: {
            min: number;
            max: number;
        };
        bmi?: {
            min: number;
            max: number;
        };
        circumference?: {
            min: number;
            max: number;
        };
        percentile?: {
            min: number;
            max: number;
        };
    };
    percentile?: {
        pregnancyWeek: number;
        valuePercentileMin: number;
        valuePercentileMax: number;
    }[];
};

const PregnancyBodyGraph = ({
    pointData,
    graphType = "weight",
    ranges,
    percentile,
}: Props) => {
    // const { breakpoints } = useTheme();
    const { isSmallScreen } = useResponsive();

    const nativeWindowsWidth = Dimensions.get("window").width;

    const { width } = useWindowSize();

    const weekDivision = [
        "1.",
        "5.",
        "10.",
        "15.",
        "20.",
        "25.",
        "30.",
        "35.",
        "40.",
    ];

    const graphWidth = isWeb
        ? document.getElementById("mood-graph")?.clientWidth ?? 1000
        : nativeWindowsWidth - 50;

    const divideWidth = graphWidth / weekDivision.length;
    const weekUnit = divideWidth / 5;

    const valueRanges = useMemo(
        () => ({
            weight: {
                min: ranges?.weight?.min ?? 10,
                max: ranges?.weight?.max ?? 30,
            },
            bmi: {
                min: ranges?.bmi?.min ?? 40,
                max: ranges?.bmi?.max ?? 80,
            },
            circumference: {
                min: ranges?.circumference?.min ?? 50,
                max: ranges?.circumference?.max ?? 150,
            },
            percentile: {
                min: ranges?.percentile?.min ?? 0,
                max: ranges?.percentile?.max ?? 100,
            },
        }),
        [ranges]
    );

    const svgSpaceHeight = 256;

    const calculateWeekPosition = useCallback(
        (pregnancyWeek: number) => {
            const weekPosition = weekUnit * pregnancyWeek;

            return weekPosition;
        },
        [weekUnit]
    );

    const calculateValue = useCallback(
        (
            value: number,
            key: "weight" | "bmi" | "circumference" | "percentile" = "weight"
        ) => {
            const range = valueRanges[key].max - valueRanges[key].min;
            const unit = range / svgSpaceHeight;

            const position = -(
                (value - valueRanges[key].min) / unit -
                svgSpaceHeight
            );

            return position;
        },
        [valueRanges]
    );

    const pathCoordinates = (valueType: PregnancyBodyValueType) => {
        const pathCoordinates = pointData.map((point) => {
            if (point[valueType] === undefined) return;
            const key =
                valueType === "valueWeight"
                    ? "weight"
                    : valueType === "valueBmi"
                    ? "bmi"
                    : "circumference";
            const weekPosition =
                calculateWeekPosition(point.pregnancyWeek) + 25;
            const valuePosition =
                calculateValue(point[valueType] ?? 0, key) + 25;

            return `L${weekPosition},${valuePosition}`;
        });

        return `M25,${svgSpaceHeight + 25} ${pathCoordinates.join(" ")}`;
    };

    const calculatePercentyle = useCallback(
        (type: "min" | "max" = "min") => {
            if (percentile === undefined) return;
            const percentilePoints =
                percentile?.map((point) => {
                    const value =
                        type === "min"
                            ? point.valuePercentileMin
                            : point.valuePercentileMax ?? 0;
                    const weekPosition =
                        calculateWeekPosition(point.pregnancyWeek) + 25;
                    const valuePosition =
                        calculateValue(value, "percentile") + 25;

                    return `L${weekPosition},${valuePosition}`;
                }) ?? [];

            const lastPoint =
                percentile?.[percentile?.length - 1].pregnancyWeek;
            const lastPointPosition = lastPoint
                ? calculateWeekPosition(lastPoint) + 25
                : 25;

            return `M25,${svgSpaceHeight + 25} ${percentilePoints?.join(
                " "
            )} ${lastPointPosition},${svgSpaceHeight + 25} Z`;
        },
        [percentile, calculateValue, calculateWeekPosition]
    );

    const calculatePercentyleRange = useCallback(() => {
        if (percentile === undefined) return;
        const percentilePointsMin =
            percentile?.map((point) => {
                const weekPosition =
                    calculateWeekPosition(point.pregnancyWeek) + 25;
                const valuePositionMin =
                    calculateValue(point.valuePercentileMin, "percentile") + 25;

                return `L${weekPosition},${valuePositionMin}`;
            }) ?? [];
        const percentilePointsMax =
            percentile?.map((point) => {
                const weekPosition =
                    calculateWeekPosition(point.pregnancyWeek) + 25;
                const valuePositionMax =
                    calculateValue(point.valuePercentileMax, "percentile") + 25;

                return `L${weekPosition},${valuePositionMax}`;
            }) ?? [];

        return `M25,${svgSpaceHeight + 25} ${percentilePointsMin?.join(
            " "
        )}  ${percentilePointsMax?.reverse().join(" ")} Z`;
    }, [calculateValue, calculateWeekPosition, percentile]);

    const graphBlue = "#418DFF";
    const graphGreen = "#1FBA9C";

    const calculateTooltipBottomPosition = useCallback(
        (value: number | undefined, type: GraphType) => {
            if (value === undefined) return 0;
            const range = valueRanges[type].max - valueRanges[type].min;
            const ratio = (value - valueRanges[type].min) / range;
            const mappedValue = ratio * svgSpaceHeight;
            const tooltipHeightOffset = 70;

            return mappedValue + tooltipHeightOffset;
        },
        [svgSpaceHeight, valueRanges]
    );

    const calculateTooltipLeftPosition = useCallback(
        (coordinate: number) => {
            const offset = coordinate * weekUnit + 60;

            return offset > 120 ? offset : 120;
        },
        [weekUnit]
    );

    const showTooltip = useCallback(
        (
            event: GestureResponderEvent,
            point: PregnancyPointData,
            type: GraphType
        ) => {
            const value =
                type === "circumference"
                    ? point.valueCircumference
                    : type === "weight"
                    ? point.valueWeight
                    : point.valueBmi;
            setTooltip((state) => ({
                ...state,
                measuredAt: new Date(point?.measuredAt),
                onShow: true,
                pregnancyWeek: point.pregnancyWeek,
                bottom: calculateTooltipBottomPosition(value, type),
                left: calculateTooltipLeftPosition(point.pregnancyWeek),
                valueWeight: point.valueWeight,
                valueBmi: point.valueBmi,
                valueCircumference: point.valueCircumference,
                graphType,
            }));
        },
        [
            graphType,
            calculateTooltipBottomPosition,
            calculateTooltipLeftPosition,
        ]
    );

    const [tooltip, setTooltip] = useState<MoodTooltipType>({
        measuredAt: new Date(),
        onShow: false,
        pregnancyWeek: 0,
        graphType,
    });

    useEffect(() => {
        setTooltip((state) => ({ ...state, graphType }));
    }, [graphType]);

    // clear tooltip on resize
    useEffect(() => {
        setTooltip((state) => ({ ...state, onShow: false }));
    }, [width]);

    return (
        <Box
            w="100%"
            // overflow="hidden"
            paddingBottom="24px"
            nativeID="mood-graph"
        >
            <Pressable
                onPress={() =>
                    setTooltip((state) => ({ ...state, onShow: false }))
                }
            >
                <Box
                    position="relative"
                    // width={isSmallScreen ? "410px" : "1000px"}
                    background="white"
                    paddingBottom="24px"
                >
                    <Box
                        ml="25px"
                        mb="-14px"
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        width="100%"
                    >
                        {weekDivision.map((day) => (
                            <Text
                                fontSize="xs"
                                color="gray.600"
                                paddingTop="6px"
                                marginBottom="-6px"
                                width={divideWidth}
                                key={day}
                            >
                                {day}
                            </Text>
                        ))}
                    </Box>
                    <Box
                        position="absolute"
                        zIndex={-1}
                        top="0"
                        left="24px"
                        paddingTop="30px"
                        w="100%"
                        h="100%"
                        display="flex"
                        flexDirection="row"
                        borderRightStyle="dashed"
                        borderRightWidth={2}
                    >
                        {weekDivision.map((day, index) => (
                            <Box
                                key={`${day}-bg-${index}`}
                                borderTopWidth={2}
                                borderColor="gray.100"
                                borderLeftWidth={2}
                                borderLeftStyle="dashed"
                                borderRightWidth={
                                    index === weekDivision.length - 1 ? 2 : 0
                                }
                                borderRightStyle="dashed"
                                width={divideWidth}
                                height="256px"
                            >
                                <Box
                                    borderBottomWidth={1}
                                    borderBottomColor="gray.100"
                                    height="25%"
                                />
                                <Box
                                    borderBottomWidth={1}
                                    borderBottomColor="gray.100"
                                    height="25%"
                                />
                                <Box
                                    borderBottomWidth={1}
                                    borderBottomColor="gray.100"
                                    height="25%"
                                />
                                <Box
                                    borderBottomWidth={2}
                                    borderBottomColor="gray.100"
                                    height="25%"
                                />
                            </Box>
                        ))}
                    </Box>
                    <Box position="absolute" top="30px" left="12px">
                        <Box
                            h="255px"
                            w="24px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                >
                                    {graphType === "weight"
                                        ? valueRanges.bmi.max
                                        : valueRanges.circumference.max}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                    noOfLines={1}
                                    isTruncated={false}
                                >
                                    {graphType === "weight"
                                        ? (valueRanges.bmi.max +
                                              valueRanges.bmi.min) /
                                          2
                                        : (valueRanges.circumference.max +
                                              valueRanges.circumference.min) /
                                          2}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                >
                                    {graphType === "weight"
                                        ? valueRanges.bmi.min
                                        : valueRanges.circumference.min}
                                </Text>
                            </Box>
                        </Box>
                        {graphType == "weight" && (
                            <Box
                                background="white"
                                px={1}
                                shadow="1"
                                borderRadius={4}
                                mt="10px"
                            >
                                <Text fontSize="xs" color="blue.500">
                                    BMI
                                </Text>
                            </Box>
                        )}
                    </Box>
                    <Svg
                        width={graphWidth > 1000 ? graphWidth : 1000}
                        height={svgSpaceHeight + 50}
                        viewBox={`0 0 ${
                            graphWidth > 1000 ? graphWidth : 1000
                        } ${svgSpaceHeight + 50}`}
                        fill="none"
                        preserveAspectRatio="xMaxYMin meet"
                    >
                        {graphType === "weight" && percentile && (
                            <>
                                <G x={isSmallScreen ? -1 : 0} id="range">
                                    <Path
                                        d={calculatePercentyleRange()}
                                        fill="#1fba9c30"
                                    />
                                </G>
                                <G x={isSmallScreen ? -1 : 0} id="min">
                                    <Path
                                        d={calculatePercentyle("min")}
                                        fill="#00000008"
                                    />
                                </G>
                            </>
                        )}
                        <G x={isSmallScreen ? -1 : 0}>
                            <Path
                                d={
                                    graphType === "weight"
                                        ? pathCoordinates("valueBmi")
                                        : pathCoordinates("valueCircumference")
                                }
                                fill="none"
                                stroke={graphBlue}
                                strokeWidth={2}
                            />
                        </G>
                        {graphType === "weight" && (
                            <G x={isSmallScreen ? -1 : 0}>
                                <Path
                                    d={pathCoordinates("valueWeight")}
                                    fill="none"
                                    stroke={graphGreen}
                                    strokeWidth={2}
                                />
                            </G>
                        )}
                        {pointData.map((point, i) => {
                            if (point.valueBmi || point.valueCircumference) {
                                const theValue =
                                    graphType == "weight"
                                        ? point.valueBmi
                                        : point.valueCircumference;
                                const key =
                                    graphType == "weight"
                                        ? "bmi"
                                        : "circumference";
                                if (!theValue) return null;

                                return (
                                    <G
                                        x={calculateWeekPosition(
                                            point.pregnancyWeek
                                        )}
                                        y={calculateValue(theValue, key)}
                                        key={`points-${theValue}-${i}`}
                                    >
                                        <Circle
                                            cx="25"
                                            cy="25"
                                            r="4.5"
                                            fill={"white"}
                                            stroke={graphBlue}
                                            strokeWidth="2"
                                        />
                                    </G>
                                );
                            }
                        })}
                        {graphType === "weight" &&
                            pointData.map((point, i) => {
                                if (point.valueWeight) {
                                    return (
                                        <G
                                            x={calculateWeekPosition(
                                                point.pregnancyWeek
                                            )}
                                            y={calculateValue(
                                                point.valueWeight,
                                                "weight"
                                            )}
                                            key={`points-${point.valueWeight}-${i}`}
                                        >
                                            <Circle
                                                cx="25"
                                                cy="25"
                                                r="4.5"
                                                fill={"white"}
                                                stroke={graphGreen}
                                                strokeWidth="2"
                                            />
                                        </G>
                                    );
                                }
                            })}
                    </Svg>
                    {/* The pressable areas */}
                    <Box
                        position="absolute"
                        top="30px"
                        left="24px"
                        width="100%"
                        height="100%"
                    >
                        {pointData.map((point, i) => {
                            const getValue =
                                graphType == "weight"
                                    ? point.valueWeight
                                    : point.valueCircumference;
                            if (!getValue) return null;
                            const key =
                                graphType == "weight"
                                    ? "weight"
                                    : "circumference";
                            const heightValue =
                                getValue &&
                                -(
                                    calculateValue(getValue, key) -
                                    svgSpaceHeight -
                                    70 +
                                    (!isWeb ? 25 : 0)
                                );

                            return (
                                <Pressable
                                    onPress={(e) => showTooltip(e, point, key)}
                                    position="absolute"
                                    left={
                                        calculateWeekPosition(
                                            point.pregnancyWeek
                                        ) - 10
                                    }
                                    bottom={heightValue}
                                    key={`pressable-${point.pregnancyWeek}-${i}`}
                                    width={"20px"}
                                    height={"20px"}
                                    // borderColor="black"
                                    // borderWidth={1}
                                />
                            );
                        })}
                        {graphType == "weight" &&
                            pointData.map((point, i) => {
                                if (!point.valueBmi) return null;
                                const getValue = point.valueBmi;
                                const heightValue =
                                    getValue &&
                                    -(
                                        calculateValue(getValue, "bmi") -
                                        svgSpaceHeight -
                                        70 +
                                        (!isWeb ? 25 : 0)
                                    );

                                return (
                                    <Pressable
                                        onPress={(e) =>
                                            showTooltip(e, point, "bmi")
                                        }
                                        position="absolute"
                                        left={
                                            calculateWeekPosition(
                                                point.pregnancyWeek
                                            ) - 10
                                        }
                                        bottom={heightValue}
                                        key={`pressable-${point.pregnancyWeek}-${i}`}
                                        width={"20px"}
                                        height={"20px"}
                                        // borderColor="black"
                                        // borderWidth={1}
                                    />
                                );
                            })}
                    </Box>
                    <Box
                        position="absolute"
                        top="30px"
                        right={0}
                        alignItems="flex-end"
                    >
                        <Box
                            h="255px"
                            w="24px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color={
                                        graphType === "weight"
                                            ? graphGreen
                                            : graphBlue
                                    }
                                    textAlign="center"
                                >
                                    {graphType === "weight"
                                        ? valueRanges.weight.max
                                        : valueRanges.circumference.max}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color={
                                        graphType === "weight"
                                            ? graphGreen
                                            : graphBlue
                                    }
                                    textAlign="center"
                                    noOfLines={1}
                                    isTruncated={false}
                                >
                                    {graphType === "weight"
                                        ? (valueRanges.weight.max +
                                              valueRanges.weight.min) /
                                          2
                                        : (valueRanges.circumference.max +
                                              valueRanges.circumference.min) /
                                          2}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color={
                                        graphType === "weight"
                                            ? graphGreen
                                            : graphBlue
                                    }
                                    textAlign="center"
                                >
                                    {graphType === "weight"
                                        ? valueRanges.weight.min
                                        : valueRanges.circumference.min}
                                </Text>
                            </Box>
                        </Box>
                        {graphType == "weight" && (
                            <Box
                                background="white"
                                px={1}
                                shadow="1"
                                borderRadius={4}
                                mt="10px"
                            >
                                <Text fontSize="xs" color="green.500">
                                    kg
                                </Text>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Pressable>
            <MoodTooltip tooltip={tooltip} />
        </Box>
    );
};

export default PregnancyBodyGraph;
