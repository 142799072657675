import type { AppRoutes, RouteId } from "@madmedical/routing";
import {
    generatePath as baseGeneratePath,
    createSearchParams,
} from "@madmedical/routing";
import { useAppConfig } from "@madmedical/config";
import { useCallback } from "react";
import useAdminPatientId from "../useAdminPatientId";
import useRoutePaths from "./useRoutePaths";

export default () => {
    const { basePath } = useAppConfig();
    const { getPath } = useRoutePaths();
    const patientId = useAdminPatientId();

    return useCallback(
        <TRouteId extends RouteId>(
            route: TRouteId,
            params: AppRoutes[TRouteId],
            search?: Record<string, string>
        ) =>
            `${basePath}/${baseGeneratePath(getPath(route), {
                patientId,
                ...params,
            })}${search ? `?${createSearchParams(search).toString()}` : ""}`,
        [basePath, getPath, patientId]
    );
};
