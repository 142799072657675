import {
    Box,
    HStack,
    Pressable,
    Text,
    TextArea,
    VStack,
    useTheme,
} from "native-base";
import { PRENATAL_TESTING_METHOD_FOR_HUMANS } from "@madmedical/medical";
import type { Pregnancy, PrenatalTestingMethod } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import IconButton from "../../atoms/IconButton";
import { usePregnancyData } from "../../providers";
import DateInput from "../../molecules/Form/DateInput";
import Radio from "../../atoms/Radio";
import Select from "../../atoms/Select";
import Button from "../../atoms/Button";
import Icon from "../../icons/Icon";
import { useRouteNavigate } from "../../providers/routing";

interface Props {
    pregnancyData: Pregnancy;
    editPregnancyData: (
        key: string,
        value: unknown,
        infant?: number | undefined
    ) => void;
    uploadDocuments: () => void;
    handleDeletePress: (id: Ulid) => void;
    hasPatientEdited: boolean;
}

const PregnancyEditor = ({
    pregnancyData,
    editPregnancyData,
    uploadDocuments,
    handleDeletePress,
    hasPatientEdited,
}: Props) => {
    const { expectedAt, infants, genderMethod, infantComment, isHighRisk } =
        pregnancyData;

    const doctorGender =
        infants[0]?.doctorGender ?? infants[0]?.gender ?? undefined;

    const { pregnancyDocuments } = usePregnancyData();
    const { t } = useTranslation();
    const navigate = useRouteNavigate();

    const { colors } = useTheme();

    const prenatalTestingMethodOptions: {
        value: PrenatalTestingMethod;
        label: string;
    }[] = [
        {
            value: "genetic",
            label: PRENATAL_TESTING_METHOD_FOR_HUMANS["genetic"],
        },
        {
            value: "ultrasound",
            label: PRENATAL_TESTING_METHOD_FOR_HUMANS["ultrasound"],
        },
    ];

    return (
        <Box width={"100%"} py={2} px={6}>
            <VStack
                width="full"
                space={2}
                py={4}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
            >
                <Box flex={1}>
                    <Text>{t("pregnancy:estimatedDueDate")}</Text>
                </Box>
                <Box
                    w={{
                        base: "100%",
                        md: 480,
                    }}
                >
                    <DateInput
                        value={new Date(expectedAt)}
                        onChange={(val) => editPregnancyData("expectedAt", val)}
                    />
                </Box>
            </VStack>
            <HStack alignItems={"center"} py={6}>
                <Box flex={1}>
                    <Text>{t("pregnancy:gender")}</Text>
                </Box>
                <HStack space={4}>
                    <Radio.Group
                        name={"doctorGender"}
                        flexDirection="row"
                        onChange={(val) =>
                            editPregnancyData("doctorGender", val, 0)
                        }
                        value={doctorGender}
                        defaultValue={doctorGender}
                    >
                        <Radio value="male" isDisabled={hasPatientEdited}>
                            Fiú
                        </Radio>
                        <Radio
                            value="female"
                            ml={4}
                            isDisabled={hasPatientEdited}
                        >
                            Lány
                        </Radio>
                    </Radio.Group>
                </HStack>
            </HStack>
            {doctorGender && (
                <>
                    <VStack
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        borderTopWidth={1}
                        borderTopColor="gray.100"
                        borderTopStyle="solid"
                        py={4}
                        px={6}
                        space={2}
                    >
                        <Text>Mi alapján lett megállapítva a baba neme?</Text>
                        <Select
                            choices={prenatalTestingMethodOptions}
                            value={
                                genderMethod ??
                                prenatalTestingMethodOptions[0]?.value
                            }
                            onChange={(val) =>
                                editPregnancyData("genderMethod", val)
                            }
                            width={{
                                base: "100%",
                                md: 436,
                            }}
                            placeholder="Válassz"
                        />
                    </VStack>
                    <VStack py={4} px={6} space={2}>
                        <Text>{t("comment")}</Text>
                        <TextArea
                            value={infantComment ?? undefined}
                            autoCompleteType="off"
                            h={24}
                            onChangeText={(val) =>
                                editPregnancyData("infantComment", val)
                            }
                            width="100%"
                        />
                    </VStack>
                </>
            )}
            <HStack
                alignItems={"center"}
                py={6}
                borderTopWidth={1}
                borderTopColor="gray.100"
                borderTopStyle="solid"
            >
                <Box flex={1}>
                    <Text>{t("pregnancy:isPregnancyHighRisk")}</Text>
                </Box>
                <HStack space={4}>
                    <Radio.Group
                        name={"isHighRisk"}
                        flexDirection="row"
                        value={isHighRisk ? "yes" : "no"}
                        onChange={(val) => {
                            const sendVal = val == "yes" ? true : false;
                            editPregnancyData("isHighRisk", sendVal);
                        }}
                        defaultValue={isHighRisk ? "yes" : "no"}
                    >
                        <Radio value="yes">{t("yes")}</Radio>
                        <Radio value="no" ml={4}>
                            {t("no")}
                        </Radio>
                    </Radio.Group>
                </HStack>
            </HStack>
            <HStack
                alignItems={"center"}
                py={6}
                borderTopWidth={1}
                borderTopColor="gray.100"
                borderTopStyle="solid"
            >
                {pregnancyDocuments?.length ? (
                    <VStack space={6} width={"100%"}>
                        {pregnancyDocuments.map((doc) => (
                            <HStack
                                key={doc.id}
                                justifyContent={"space-between"}
                                width={"100%"}
                                alignItems={"flex-start"}
                            >
                                <HStack space={4} alignItems={"center"}>
                                    <Icon
                                        name={"documentBlank"}
                                        fill={colors.green[500]}
                                        size={32}
                                    />
                                    <Pressable
                                        onPress={() =>
                                            navigate("document", {
                                                documentId: doc.id,
                                            })
                                        }
                                    >
                                        <VStack>
                                            <Text fontWeight={"medium"}>
                                                {doc.filename}
                                            </Text>
                                            <Text
                                                fontSize="xs"
                                                color="gray.400"
                                            >
                                                {doc.size} kB
                                            </Text>
                                        </VStack>
                                    </Pressable>
                                </HStack>
                                <IconButton
                                    variant="ghostLink"
                                    iconName="trashCan"
                                    onPress={() => handleDeletePress(doc.id)}
                                />
                            </HStack>
                        ))}
                    </VStack>
                ) : (
                    <Button
                        leftIconName="documentAdd"
                        variant="outlined"
                        onPress={uploadDocuments}
                    >
                        {t("uploadDocument")}
                    </Button>
                )}
            </HStack>
        </Box>
    );
};

export default PregnancyEditor;
