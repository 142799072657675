import { useAuth } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import { invariant } from "@madmedical/utils";
import useMe from "./useMe";
import { useRepresentedPatient } from "./representedPatient";
import useAdminPatientId from "./useAdminPatientId";

type UsePatientId = () => {
    patientId: Ulid;
    isSelfOrRepresented: boolean;
};

// Gets patient from either URL or current user id if having patient role.
// It shall only be called when there is either one, so hide routes by roles, please
const usePatientId: UsePatientId = () => {
    const adminPatientId = useAdminPatientId();
    const {
        roles: { isPatient, isRepresentative },
    } = useMe();
    const { userId } = useAuth();
    const { representedPatient } = useRepresentedPatient();

    if (adminPatientId) {
        return {
            patientId: adminPatientId,
            isSelfOrRepresented: false,
        };
    }

    if (isRepresentative) {
        // For representatives, UserWrapper should ensure that we already have selected patient by now
        invariant(representedPatient);
        const patientId = representedPatient.userId;

        return { patientId, isSelfOrRepresented: true };
    }

    // If no patient id in path, then we expect current user to be a patient
    invariant(isPatient);

    // And we surely expect this hook not to be used when user is not authenticated
    invariant(userId);

    return {
        patientId: userId,
        isSelfOrRepresented: true,
    };
};

export default usePatientId;
