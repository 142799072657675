import { getLanguage } from "@madmedical/i18n";
import {
    addDays,
    eachDayOfInterval,
    endOfDay,
    endOfMonth,
    endOfWeekWithOptions,
    differenceInDays as fnsDifferenceInDays,
    differenceInWeeksWithOptions as fnsDifferenceInWeeks,
    format,
    getDay,
    getISOWeek,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isSameWeekWithOptions,
    setDate,
    startOfDay,
    startOfMonth,
    startOfWeekWithOptions,
    subDays,
} from "date-fns/fp";
import getTimeZone from "./getTimeZone";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-datetimeformat/polyfill";
import "@formatjs/intl-datetimeformat/locale-data/hu";
import "@formatjs/intl-datetimeformat/add-golden-tz"; // Replace with add-all-tz if the need arises

export { format } from "date-fns";
export type { Locale } from "date-fns";
export { enUS, hu } from "date-fns/locale";
/**
 * Timestamp in milliseconds or ISO date string or date object
 */
type DateInput = number | string | Date;

interface DateRangeInput {
    from: DateInput;
    to: DateInput;
}

export type DateRangeFormat = "short" | "long" | "numeric" | "time";

const createDate = (date: DateInput) =>
    typeof date === "object" ? date : new Date(date);

export const rangeToString = (dateRange: DateRangeInput) => ({
    from: createDate(dateRange.from).toISOString(),
    to: createDate(dateRange.to).toISOString(),
});

export const rangeToDate = (dateRange: DateRangeInput) => ({
    from: createDate(dateRange.from),
    to: createDate(dateRange.to),
});

const toLocaleString = (
    date: DateInput,
    options?: Intl.DateTimeFormatOptions
) =>
    createDate(date).toLocaleString(getLanguage(), {
        timeZone: getTimeZone(),
        ...options,
    });

export const formatDate = (date: DateInput) =>
    toLocaleString(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

export const formatTime = (date: DateInput) =>
    toLocaleString(date, {
        hour: "2-digit",
        minute: "2-digit",
    });

export const formatDateTime = (date: DateInput) =>
    toLocaleString(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });

export const formatDateTimeLongMonth = (date: DateInput) =>
    toLocaleString(date, {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });

const isMidnight = (date: DateInput) =>
    createDate(date).toLocaleTimeString(getLanguage(), {
        timeZone: getTimeZone(),
    }) === "00:00:00";

export const formatMeasuredAt = (date: DateInput) =>
    isMidnight(date) ? formatDate(date) : formatDateTime(date);

export const formatMeasuredAtShort = (date: DateInput) => formatDate(date);

export const formatYear = (date: DateInput) =>
    toLocaleString(date, {
        year: "numeric",
    });

export const formatYearMonth = (date: DateInput) =>
    toLocaleString(date, {
        year: "numeric",
        month: "long",
    });

export const formatMonth = (
    date: DateInput,
    format: "short" | "long" = "short"
) =>
    toLocaleString(date, {
        month: format,
    });

export const formatDayOfMonth = (date: DateInput) =>
    toLocaleString(date, {
        day: "numeric",
    });

export const formatMonthDay = (date: DateInput) =>
    toLocaleString(date, {
        month: "long",
        day: "numeric",
    });

export const formatWeekOfYear = (date: DateInput) =>
    `${getISOWeek(createDate(date))}. hét`;

export const formatVerboseMonthDay = (date: DateInput) =>
    toLocaleString(date, {
        month: "long",
        day: "2-digit",
    });

export const formatDateRange = (
    range: DateRangeInput,
    format: DateRangeFormat | undefined = "short",
    showYear = false
) => {
    const options =
        format === "time"
            ? ({ hour: "numeric", minute: "2-digit" } as const)
            : ({ month: format, day: "numeric" } as const);
    const formatter = new Intl.DateTimeFormat(getLanguage(), {
        timeZone: getTimeZone(),
        ...options,
        year: showYear ? "numeric" : undefined,
    });

    return formatter.formatRange(createDate(range.from), createDate(range.to));
};

export const formatDateRangeWithYear = (
    range: DateRangeInput,
    format: DateRangeFormat | undefined = "short"
) => {
    const options =
        format === "time"
            ? ({ hour: "numeric", minute: "2-digit" } as const)
            : ({ month: format, day: "numeric" } as const);

    const formatter = new Intl.DateTimeFormat(getLanguage(), {
        timeZone: getTimeZone(),
        ...options,
        year: "numeric",
    });

    return formatter.formatRange(createDate(range.from), createDate(range.to));
};

export const startOfWeek = startOfWeekWithOptions({ weekStartsOn: 1 });
export const endOfWeek = endOfWeekWithOptions({ weekStartsOn: 1 });
const isSameWeek = isSameWeekWithOptions({ weekStartsOn: 1 });

export type DatePeriodVariant = "day" | "week" | "month";
export const resolvePeriod = (dateRange: DateRangeInput): DatePeriodVariant => {
    const { from, to } = rangeToDate(dateRange);

    switch (true) {
        case isSameDay(from, to):
            return "day";
        case isSameWeek(from, to):
            return "week";
        default:
            return "month";
    }
};

export const dateToRange = (date: DateInput, period: DatePeriodVariant) => {
    const from = createDate(date);

    switch (period) {
        case "day":
            return { from, to: endOfDay(from) };
        case "week":
            return { from, to: endOfWeek(from) };
        case "month":
            return { from, to: endOfMonth(from) };
    }
};

const isValidDateInput = (v: unknown): v is DateInput =>
    v instanceof Date ||
    ((typeof v === "number" || typeof v === "string") &&
        !isNaN(new Date(v).getTime()));

export const isValidDateRange = (v: unknown): v is DateRangeInput =>
    !!v &&
    typeof v === "object" &&
    "from" in v &&
    isValidDateInput(v.from) &&
    "to" in v &&
    isValidDateInput(v.to);

export const formatIsoWithTz = (date: Date) =>
    format("yyyy-MM-dd'T'HH:mm:ss.SSSxx", date);

export const createDateRangeArray = (dateRange: DateRangeInput) =>
    eachDayOfInterval({
        start: createDate(dateRange.from),
        end: createDate(dateRange.to),
    });

export const differenceInWeeks = (startDate: Date, endDate: Date) => {
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;

    return Math.abs(Math.round(diff));
};

export const differenceInDays = (startDate: Date, endDate: Date) => {
    const diff = fnsDifferenceInDays(endDate, startDate);

    return Math.abs(diff);
};

export const currentPregnancyWeek = (startDate: Date, endDate: Date) => {
    const weeks = fnsDifferenceInWeeks(
        {
            roundingMethod: "ceil",
        },
        startDate,
        endDate
    );

    return Math.abs(weeks);
};

export const displayPeriodOfDay = (date: DateInput) => {
    const dateObject = new Date(date);
    const hour = dateObject.getHours();

    if (hour >= 5 && hour < 12) {
        return "Reggel";
    } else if (hour >= 12 && hour < 17) {
        return "Dél";
    } else if (hour >= 17 && hour < 21) {
        return "Este";
    }

    return "Éjjel";
};

export {
    startOfDay,
    endOfDay,
    startOfMonth,
    endOfMonth,
    isAfter,
    isBefore,
    isEqual,
    subDays,
    getDay,
    setDate,
    addDays,
};
