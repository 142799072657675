import type { AppRoutes, RouteId } from "@madmedical/routing";
import type { ComponentProps } from "react";
import { useRouteNavigate } from "../providers/routing";
import Button from "./Button";

interface Props<TRouteId extends RouteId>
    extends Omit<ComponentProps<typeof Button>, "onPress"> {
    route: TRouteId;
    params: AppRoutes[TRouteId];
}

const ButtonLink = <TRouteId extends RouteId>({
    route,
    params,
    ...props
}: Props<TRouteId>) => {
    const navigate = useRouteNavigate();

    const handlePress = () => {
        navigate(route, params);
    };

    return <Button onPress={handlePress} {...props} />;
};

export default ButtonLink;
