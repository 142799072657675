import type { ComponentProps, ReactNode } from "react";
import { Box, Stack } from "native-base";
import { isWeb } from "@madmedical/utils";
import BottomNavigation from "../molecules/BottomNavigation";
import useResponsive from "../util/useResponsive";
import { colors } from "../charts/useStyles";

interface Props {
    readonly children: ReactNode;
    readonly bottomNavigation?: ComponentProps<
        typeof BottomNavigation
    >["navigation"];
    readonly sidebar?: ReactNode;
    readonly isRepresentative?: boolean;
}

/* const styles = StyleSheet.create({
    safeArea: { backgroundColor: colors.grey50, flex: 1, width: "auto" },
});
 */
const MainLayout = ({ children, bottomNavigation, sidebar }: Props) => {
    const { isSmallScreen } = useResponsive();

    return (
        <Box backgroundColor={colors.grey50} flex={1} width="full" safeArea>
            <Box
                width="full"
                // minHeight={isWeb && isSmallScreen ? "100vh" : null}
                height={"100%"}
                background="gray.50"
            >
                <Stack
                    nativeID="native-base-container"
                    direction="row"
                    space={{
                        base: 0,
                        md: 2,
                    }}
                    minWidth="full"
                    minHeight="full"
                >
                    {sidebar && (
                        <Box
                            minHeight="full"
                            bg="white"
                            borderColor="gray.100"
                            borderWidth={1}
                            borderStyle="solid"
                            width={62}
                            borderRadius="lg"
                            display={{
                                base: "none",
                                md: "flex",
                            }}
                        >
                            {sidebar}
                        </Box>
                    )}
                    <Box flex={1} nativeID="native-content-container">
                        {children}
                    </Box>
                </Stack>
                {isSmallScreen && bottomNavigation && (
                    <Box
                        position={isWeb ? "fixed" : "absolute"}
                        bottom={0}
                        width="full"
                        justifyContent="center"
                        alignItems="center"
                        marginLeft={isWeb ? "-16px" : 0}
                    >
                        <BottomNavigation navigation={bottomNavigation} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MainLayout;
