import type { DailySteps } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import { ceil2 } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<DailySteps>;

const DailyStepsChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.DailySteps, widget);
    const { t } = useTranslation();
    const graphProps = useMemo(() => {
        const { max } = data.stats;

        return {
            type: "column",
            unit: t("measures:steps"),
            limits: {
                yMax: max ? ceil2(max.steps + 250) : 10000,
                yMin: 0,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.steps,
            })),
        } as const;
    }, [data, dateRange, thresholds, t]);

    return (
        <GraphWrapper
            metricType={MetricType.DailySteps}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default DailyStepsChart;
