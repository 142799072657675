const baseStyle = () => ({
    fontFamily: "body",
    borderRadius: "sm",
    borderWidth: 1,
    overflow: "hidden",
    _disabled: {
        bgColor: "white",
        color: "gray.400",
        borderColor: "gray.200",
        _web: {
            disabled: true,
            cursor: "not-allowed",
        },
    },
    _readOnly: {
        bgColor: "gray.25",
        color: "gray.600",
        borderColor: "gray.200",
        _web: {
            disabled: true,
            cursor: "not-allowed",
        },
        _hover: {
            borderColor: "gray.200",
        },
    },
    _web: {
        outlineWidth: "0",
        overflow: "auto",
        lineHeight: "lg", // Todo: Move to _web inside size so that sm and xs don't have this much height
        style: {
            outlineWidth: 0,
        },
        cursor: "auto",
    },
    _input: {
        bg: "transparent",
        flex: 1,
        w: "100%",
        h: "100%",
    },
    _light: {
        placeholderTextColor: "gray.600",
        color: "gray.900",
        borderColor: "gray.200",
        _hover: {
            borderColor: "primary.300",
        },
        _focus: {
            borderColor: "primary.300",
            _hover: {
                borderColor: "primary.300",
            },
            _stack: {
                style: {
                    outlineWidth: 0,
                },
            },
        },
        _invalid: {
            borderColor: "rose.300",
            _hover: {
                borderColor: "rose.300",
            },
            _stack: {
                style: {
                    outlineWidth: 0,
                },
            },
        },
        _ios: {
            selectionColor: "gray.800",
        },
        _android: {
            selectionColor: "gray.800",
        },
        _disabled: {
            placeholderTextColor: "gray.400",
            _hover: {
                borderColor: "gray.500",
            },
        },
        _stack: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            overflow: "hidden",
        },
    },
});

const outlined = () => ({
    borderWidth: 1,
    borderColor: "gray.200",
    bg: "white",
    placeholderTextColor: "gray.600",
    color: "gray.900",
    _hover: {
        borderColor: "primary.300",
    },
    _focus: {
        borderColor: "primary.300",
        _hover: {
            borderColor: "primary.300",
        },
        _stack: {
            style: {
                outlineWidth: 0,
            },
        },
    },
});

const unstyled = () => ({
    borderWidth: 0,
    _focus: {
        bg: "transparent",
    },
    _invalid: {
        _stack: {
            style: {
                outlineWidth: 0,
            },
        },
    },
    _stack: {
        _focus: {
            style: {
                outlineWidth: "0",
            },
        },
    },
});

const variants = {
    outlined,
    unstyled,
};

const sizes = {
    sm: {
        fontSize: "sm",
        _input: {
            minHeight: 8,
        },
    },
    md: {
        fontSize: "md",
        _input: {
            minHeight: 11,
        },
    },
};

const defaultProps = {
    size: "md",
    variant: "outlined",
};

// Input
export const Input = {
    baseStyle,
    defaultProps,
    variants,
    sizes,
};

export default {};
