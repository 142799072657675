import { Box, Center, FlatList } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import EvaluationListItem from "../../molecules/ListItem/EvaluationListItem";
import Headline from "../../molecules/Headline";
import HealthEvaluationTable from "../../organisms/HealthEvaluationTable";
import { useEvaluations } from "../../providers/evaluation";
import ButtonLink from "../../atoms/ButtonLink";
import { useCurrentPatient } from "../../providers/currentPatient";

interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}

const Evaluations = () => {
    const { isSmallScreen } = useResponsive();
    const { evaluations } = useEvaluations();
    const { t } = useTranslation();
    const { patient } = useCurrentPatient();

    const packages = (patient?.packages ?? []) as MyPackages[];

    if (patient && packages.some((item) => item?.type === "basic")) {
        return null;
    }

    if (!evaluations.length) return null;

    return isSmallScreen ? (
        <>
            <ListHeader
                title={t("latestResults")}
                borderBottomWidth={0}
                px={0}
            />

            <Paper mb={3}>
                <FlatList
                    minWidth="full"
                    showsVerticalScrollIndicator={false}
                    data={evaluations}
                    renderItem={({ item }) => (
                        <EvaluationListItem evaluation={item} />
                    )}
                    keyExtractor={({ id }) => id}
                    overflow="hidden"
                />
                <Center my={4} width="full">
                    <ButtonLink
                        size="sm"
                        variant="outlined"
                        route="opinions"
                        params={{}}
                    >
                        {t("viewAll")}
                    </ButtonLink>
                </Center>
            </Paper>
        </>
    ) : (
        <Paper mb={3}>
            <Headline title={t("latestResults")} size="xs" py={6} />
            <Box px={6} py={4} width="full">
                <HealthEvaluationTable />
                <Center mt={4}>
                    <ButtonLink
                        size="sm"
                        variant="outlined"
                        route="opinions"
                        params={{}}
                    >
                        {t("viewAll")}
                    </ButtonLink>
                </Center>
            </Box>
        </Paper>
    );
};

export default Evaluations;
