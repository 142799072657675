import { Box, HStack, Popover, Text } from "native-base";
import { useEffect, useState } from "react";
import { useFormContext } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";
import useResponsive from "../util/useResponsive";
import SubmitButton from "../form/SubmitButton";
import DateTimeInput from "../form/DateTimeInput";
import FormTextAreaControl from "../form/FormTextAreaControl";

interface Props {
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const AddCommentButtonDesktop = ({ onSubmitPress, isSuccess }: Props) => {
    const [isOpen, setOpen] = useState(false);

    const { isSmallScreen } = useResponsive();
    const { setValue } = useFormContext();

    useEffect(() => {
        if (isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isOpen) {
            setValue("date", new Date().toISOString());
        }
    }, [isOpen, setValue]);
    const { t } = useTranslation();

    return (
        <Box>
            <Popover
                useRNModal
                placement="top right"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                trigger={(triggerProps) =>
                    !isSmallScreen ? (
                        <Button
                            {...triggerProps}
                            onPress={() => setOpen(!isOpen)}
                            variant="outlined"
                            leftIconName="chat"
                            forceIcon={true}
                        >
                            {t("addComment")}
                        </Button>
                    ) : (
                        <></>
                    )
                }
            >
                <Popover.Content
                    size={80}
                    width="96"
                    height="auto"
                    top={isSmallScreen ? 0 : undefined}
                    mb={4}
                >
                    <Popover.Header
                        bgColor="white"
                        borderTopRadius={4}
                        shadow="none"
                        flexDirection="row"
                    >
                        <Popover.CloseButton />
                        <Text textAlign="left" flex="1">
                            {t("addComment")}
                        </Text>
                    </Popover.Header>

                    <Popover.Body bgColor="white" shadow="none" flex="1">
                        <HStack mb={4}>
                            <DateTimeInput
                                name="date"
                                isRequired
                                label={t("appointment")}
                            />
                        </HStack>
                        <FormTextAreaControl
                            name="comment"
                            isRequired
                            label={t("comment")}
                            placeholder={t("addAComment")}
                        />
                    </Popover.Body>

                    <Popover.Footer
                        bgColor="white"
                        borderBottomRadius={4}
                        shadow="none"
                    >
                        <SubmitButton
                            onPress={onSubmitPress}
                            label={t("save")}
                            width="full"
                        />
                    </Popover.Footer>
                    <Popover.Arrow />
                </Popover.Content>
            </Popover>
        </Box>
    );
};

export default AddCommentButtonDesktop;
