import { PregnancyWellBeingStatus } from "@madmedical/medical";
import BlandMood from "../../pics/Pregnancy/moods/BlandMood";
import ChangingMood from "../../pics/Pregnancy/moods/ChangingMood";
import CalmMood from "../../pics/Pregnancy/moods/CalmMood";
import HappyMood from "../../pics/Pregnancy/moods/HappyMood";
import BadMood from "../../pics/Pregnancy/moods/BadMood";

interface Props {
    status: PregnancyWellBeingStatus;
}

const PregnantMoodPics = ({ status }: Props) => {
    switch (status) {
        case PregnancyWellBeingStatus.BadTemper:
            return <BadMood />;

        case PregnancyWellBeingStatus.Moody:
            return <BlandMood />;

        case PregnancyWellBeingStatus.Fluctuating:
            return <ChangingMood />;

        case PregnancyWellBeingStatus.Calm:
            return <CalmMood />;

        case PregnancyWellBeingStatus.Cheerful:
            return <HappyMood />;
    }
};

export default PregnantMoodPics;
