import {
    CallCenterPatient,
    CurrentPatientProvider,
    Spinner,
} from "@madmedical/ui";
import { adaptPatient, usePatient } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import { useState } from "react";
import UpdateDeviceForm from "./UpdateDeviceForm";
import { useGetLogisticsDevicesQuery } from "./api";
import adaptDevice from "./adaptDevice";

enum Modal {
    Closed,
    EditDevice,
}

const Patient = () => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const { data: devicesData, isLoading: isDevicesLoading } =
        useGetLogisticsDevicesQuery({ patientId, pageSize: 5000 });
    const [modal, setModal] = useState(Modal.Closed);
    const [deviceIdForModal, setDeviceIdForModal] = useState<string>();

    const handleEditPress = (id: string) => {
        setDeviceIdForModal(id);
        setModal(Modal.EditDevice);
    };

    const handleModalClose = () => {
        setDeviceIdForModal(undefined);
        setModal(Modal.Closed);
    };

    if (isPatientLoading || isDevicesLoading) {
        return <Spinner />;
    }

    invariant(patient);
    invariant(devicesData);

    return (
        <>
            <CurrentPatientProvider value={{ patient: adaptPatient(patient) }}>
                <CallCenterPatient
                    devices={devicesData.items.map(adaptDevice)}
                    onEditPress={handleEditPress}
                />
            </CurrentPatientProvider>{" "}
            {deviceIdForModal && (
                <UpdateDeviceForm
                    id={deviceIdForModal}
                    open={modal === Modal.EditDevice}
                    onClose={handleModalClose}
                />
            )}
        </>
    );
};

export default Patient;
