import {
    Box,
    FormControl,
    HStack,
    Popover,
    Pressable,
    ScrollView,
    Text,
    VStack,
    useTheme,
} from "native-base";
import { useEffect, useState } from "react";
import { isWeb } from "@madmedical/utils";
import Icon from "../../icons/Icon";
import Input from "./Input";

type Time = `${number}:${number}`;

type Props = {
    value?: Time;
    label?: string;
    isInvalid?: boolean;
    isRequired?: boolean;
    error?: string;
    isReadOnly?: boolean;
    onChange: (time: string) => void;
};

type TimeSelectorItemProps = {
    num: number;
    onPress: () => void;
    active?: boolean;
};

const formatTime = (num: number) => String(num).padStart(2, "0");

const TimeSelectorItem = ({
    num,
    active = false,
    onPress,
}: TimeSelectorItemProps) => (
    <Pressable
        onPress={onPress}
        bg={active ? "green.500" : undefined}
        _hover={{ bg: active ? "green.600" : "blueGray.100" }}
    >
        <Box px="2" py="1" borderRadius="sm">
            <Text color={active ? "white" : "gray.600"} textAlign="center">
                {formatTime(num)}
            </Text>
        </Box>
    </Pressable>
);

const TimeInput = ({
    value = "00:00",
    label,
    isRequired = false,
    isReadOnly = false,
    isInvalid = false,
    error,
    onChange,
}: Props) => {
    const time = value.split(":").map((t) => Number(t));
    const [hour, setHour] = useState(time[0]);
    const [min, setMin] = useState(time[1]);
    const [val, setVal] = useState<string>(value);

    const { colors } = useTheme();

    useEffect(() => {
        setVal(`${formatTime(hour)}:${formatTime(min)}`);
        onChange(`${formatTime(hour)}:${formatTime(min)}`);
    }, [hour, min, onChange]);

    return (
        <>
            <Popover
                useRNModal
                trigger={(triggerProps) => (
                    <FormControl>
                        {label && (
                            <FormControl.Label
                                _text={{
                                    color: "gray.800",
                                }}
                                fontWeight="medium"
                                isRequired={isRequired}
                            >
                                {label}
                            </FormControl.Label>
                        )}

                        <HStack
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            // bgColor={"white"}
                            width="full"
                            height={9}
                            borderColor={isInvalid ? "red.500" : "gray.200"}
                            borderWidth={1}
                            borderRadius={5}
                        >
                            <Pressable
                                width={8}
                                // bgColor="green.100"
                                pl={3}
                                py={2}
                                {...(!isReadOnly ? triggerProps : {})}
                            >
                                <Icon
                                    name="time"
                                    size={14}
                                    fill={colors.gray[800]}
                                />
                            </Pressable>
                            <HStack
                                height={12}
                                width={8}
                                ml={isWeb ? 2 : 4}
                                justifyContent="center"
                                alignItems="center"
                                bgColor="transparent"
                            >
                                <Input
                                    value={val.split(":")[0]}
                                    isReadOnly={isReadOnly}
                                    isInvalid={isInvalid}
                                    onChangeText={(text) => {
                                        setHour(parseInt(text, 10));
                                    }}
                                    onBlur={() =>
                                        setHour((hour) =>
                                            hour < 24 ? hour : 23
                                        )
                                    }
                                    width={5}
                                    px={1}
                                    mr={1}
                                    borderWidth={0}
                                    //zIndex={100}
                                    focusOutlineColor={"transparent"}
                                    textAlign={"right"}
                                    height={8}
                                />
                                <Text height={4} lineHeight={"2xs"} width={1}>
                                    :
                                </Text>
                                <Input
                                    value={val.split(":")[1]}
                                    isReadOnly={isReadOnly}
                                    isInvalid={isInvalid}
                                    onChangeText={(text) =>
                                        setMin(parseInt(text, 10))
                                    }
                                    onBlur={() =>
                                        setMin((min) => (min < 60 ? min : 59))
                                    }
                                    width={6}
                                    px={1}
                                    borderWidth={0}
                                    zIndex={100}
                                    focusOutlineColor={"transparent"}
                                    textAlign={"left"}
                                    height={8}
                                />
                            </HStack>

                            <Pressable
                                px={2}
                                flex={1}
                                {...(!isReadOnly ? triggerProps : {})}
                            >
                                <Text>&nbsp;</Text>
                            </Pressable>
                        </HStack>
                        {error && (
                            <FormControl.ErrorMessage
                                mt={1}
                                _text={{ fontSize: "sm" }}
                            >
                                {error}
                            </FormControl.ErrorMessage>
                        )}
                    </FormControl>
                )}
            >
                <Popover.Content
                    overflow="visible"
                    shadow={2}
                    bg="white"
                    borderRadius="md"
                    p="2"
                >
                    <HStack alignItems="center" space={2}>
                        <ScrollView height={62}>
                            <VStack width={20}>
                                {Array.from(Array(24)).map((_, i) => (
                                    <TimeSelectorItem
                                        key={`hour-${i}`}
                                        num={i}
                                        active={i === hour}
                                        onPress={() => setHour(i)}
                                    />
                                ))}
                            </VStack>
                        </ScrollView>

                        <ScrollView height={62}>
                            <VStack width={20}>
                                {Array.from(Array(60)).map((_, i) => (
                                    <TimeSelectorItem
                                        num={i}
                                        key={`min-${i}`}
                                        active={i === min}
                                        onPress={() => setMin(i)}
                                    />
                                ))}
                            </VStack>
                        </ScrollView>
                    </HStack>
                </Popover.Content>
            </Popover>
        </>
    );
};

export default TimeInput;
