import { Spinner as BaseSpinner, Box } from "native-base";

const Spinner = () => (
    <Box alignItems="center" p={8}>
        <BaseSpinner
            color="green.300"
            size="lg"
            accessibilityLabel="Betöltés…"
        />
    </Box>
);

export default Spinner;
