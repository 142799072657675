import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { useGetInvitesQuery, useReInviteMutation } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const ReInviteDialog = ({ id, open, onClose }: Props) => {
    const { invite } = useGetInvitesQuery(undefined, {
        selectFromResult: ({ data: invites }) => ({
            invite: invites?.find((invite) => invite.id === id),
        }),
    });
    const [reInvite, { isLoading: isSubmitting }] = useReInviteMutation();
    const showToast = useShowToast();

    if (!id || !invite) {
        return null;
    }

    const handleConfirm = () => {
        void reInvite(id)
            .unwrap()
            .then(() => {
                showToast("Meghívó újraküldve.", { variant: "success" });
                onClose();
            });
    };

    const { email } = invite;

    return (
        <Dialog
            open={open}
            confirmLabel="Újraküldés"
            onConfirm={handleConfirm}
            onClose={onClose}
            isSubmitting={isSubmitting}
            header={email}
        >
            <Text>Biztosan újraküldi a meghívást?</Text>
        </Dialog>
    );
};

export default ReInviteDialog;
