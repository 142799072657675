import {
    CurrentPatientProvider,
    DashboardInsulinMeasurementList,
    DeviceProvider,
    EvaluationProvider,
    Health,
    PreOpProvider,
    PregnancyDashboard,
    Spinner,
} from "@madmedical/ui";
import {
    DashboardWidgets,
    adaptOpinion,
    useCreateEmpathyMutation,
    useCreateWelcomeMutation,
    useDeletePregnancyMutation,
    useGetInsulinAdministrationsQuery,
    useGetOpinionsQuery,
    useGetPregnancyByPatientQuery,
} from "@madmedical/measurement";
import type { PatientMe } from "@madmedical/user";
import {
    adaptPatient,
    usePatient,
    usePatientId,
    useRouteNavigate,
} from "@madmedical/user";
import { useDevices } from "@madmedical/device";
import { useEffect, useMemo } from "react";
import { invariant } from "@madmedical/utils";
import {
    adaptQuestionnaire,
    useGetLatestQuestionnaireQuery,
} from "@madmedical/preop";
import { UnderlyingCondition } from "@madmedical/medical";
import type { AppRoutes } from "@madmedical/routing";

interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}

const PatientDashboard = () => {
    const { patientId } = usePatientId();
    const { patient, refetch: refetchPatient } = usePatient();

    useEffect(() => {
        void refetchPatient();
    }, [refetchPatient]);

    const { data, isLoading: isOpinionsLoading } = useGetOpinionsQuery({
        patientId,
        sort: "dateRange",
        order: "DESC",
        page: 1,
        pageSize: 5,
    });
    const { devices, isLoading: isDevicesLoading } = useDevices();
    const { data: questionnaire } = useGetLatestQuestionnaireQuery({
        patientId,
    });
    const opinions = useMemo(
        () => data?.items.map(adaptOpinion) ?? [],
        [data?.items]
    );

    const typedPatient = patient as PatientMe;
    const isInsulin = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Insulin
    );

    const { data: insulinList, isLoading: isInsulinLoading } =
        useGetInsulinAdministrationsQuery(
            {
                patientId,
            },
            {
                skip: !isInsulin,
            }
        );

    const isPregnant = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Pregnancy
    );

    const { data: pregnancyData } = useGetPregnancyByPatientQuery(
        {
            patientId,
        },
        {
            skip: !isPregnant,
        }
    );

    const navigate = useRouteNavigate();

    const goToLink = (link: string) => {
        const navigateLink = link as keyof AppRoutes;
        navigate(navigateLink, {});
    };

    const [createEmpathy] = useCreateEmpathyMutation();
    const [createCongratulation] = useCreateWelcomeMutation();
    const [deletePregnancy] = useDeletePregnancyMutation();

    const packages = (patient?.packages ?? []) as MyPackages[];
    const isPregnancyPackage = !!(
        patient && packages.some((item) => item?.type === "pregnancy")
    );
    const isInsulinPackage = !!(
        patient && packages.some((item) => item?.type === "diabetes")
    );

    if (isOpinionsLoading || isDevicesLoading || isInsulinLoading) {
        return <Spinner />;
    }

    invariant(data);

    return (
        <EvaluationProvider
            value={{
                evaluations: opinions,
                pagination: data.pagination,
                onSortChange: undefined,
                onSearchChange: undefined,
                filterChoices: {
                    metricTypes: [],
                    alertLevels: [],
                    doctors: [],
                },
                onFilterChange: undefined,
            }}
        >
            <DeviceProvider
                value={{
                    devices: devices.filter((device) => device.isConnected),
                }}
            >
                <PreOpProvider
                    value={{
                        questionnaire: questionnaire
                            ? adaptQuestionnaire(questionnaire)
                            : null,
                    }}
                >
                    {patient && (
                        <CurrentPatientProvider
                            value={{
                                patient: adaptPatient(patient),
                            }}
                        >
                            <Health
                                charts={
                                    <DashboardWidgets
                                        isInsulin={
                                            isInsulinPackage && isInsulin
                                        }
                                    />
                                }
                                insulinList={
                                    isInsulinPackage &&
                                    isInsulin &&
                                    !isInsulinLoading &&
                                    patient && (
                                        <DashboardInsulinMeasurementList
                                            data={insulinList?.items ?? []}
                                        />
                                    )
                                }
                                pregnancyDashboard={
                                    isPregnancyPackage &&
                                    isPregnant &&
                                    pregnancyData && (
                                        <PregnancyDashboard
                                            patientId={patientId}
                                            pregnancyData={pregnancyData}
                                            goToLink={goToLink}
                                            createEmpathy={createEmpathy}
                                            createCongratulation={
                                                createCongratulation
                                            }
                                            deletePregnancy={deletePregnancy}
                                        />
                                    )
                                }
                            />
                        </CurrentPatientProvider>
                    )}
                </PreOpProvider>
            </DeviceProvider>
        </EvaluationProvider>
    );
};

export default PatientDashboard;
