import { Device, useShowToast } from "@madmedical/ui";
import { useGenerateDeepLinkUrl, useGenerateUrl } from "@madmedical/config";
import { useTranslation } from "@madmedical/i18n";
import { useConnectOauthMutation, useDisconnectOauthMutation } from "../api";
import openAuth from "../openAuth";
import type { ResolvedCloudDevice } from "../model";
import useDevice from "../useDevice";

interface Props {
    device: ResolvedCloudDevice;
    canAddDevice: boolean;
    showAppDownload: boolean;
    onDismissAppDownload: () => void;
}

const CloudDevice = ({
    device,
    canAddDevice,
    showAppDownload,
    onDismissAppDownload,
}: Props) => {
    const [connect, { isLoading: isConnecting }] = useConnectOauthMutation();
    const [disconnect, { isLoading: isDisconnecting }] =
        useDisconnectOauthMutation();
    const { refetch, isFetching: isRefetching } = useDevice(device.provider);
    const generateUrl = useGenerateUrl();
    const generateDeepLinkUrl = useGenerateDeepLinkUrl();
    const showToast = useShowToast();
    const { t } = useTranslation();
    const handleConnectPress = () => {
        openAuth(
            async () => {
                const { authUrl } = await connect({
                    provider: device.provider,
                    returnUrl: generateUrl("/oauth-result"),
                }).unwrap();

                return authUrl;
            },
            generateDeepLinkUrl("/"), // Android needs this ¯\_(ツ)_/¯
            (result) => {
                if (result === "success") {
                    showToast(t("successfulConnection"), {
                        variant: "success",
                    });
                    void refetch();
                } else {
                    showToast(t("unsuccessfulConnection"), {
                        variant: "error",
                    });
                }
            }
        );
    };

    const handleDisconnectPress = async () => {
        try {
            await disconnect({ provider: device.provider }).unwrap();
            showToast(t("deviceDisconnected"), { variant: "success" });
        } catch (e) {
            console.error(e);
            showToast(t("deviceDisconnectedUnsuccessful"), {
                variant: "error",
            });
        }
    };

    return (
        <Device
            device={device}
            protocols={[]}
            onConnectPress={handleConnectPress}
            onDisconnectPress={handleDisconnectPress}
            canAddDevice={canAddDevice}
            isConnectLoading={isConnecting || isDisconnecting || isRefetching}
            showAppDownload={showAppDownload}
            onDismissAppDownload={onDismissAppDownload}
        />
    );
};

export default CloudDevice;
