import { Dimensions } from "react-native";
import { isWeb } from "@madmedical/utils";
import type { Styles, StylesNull } from "./types";

const screenWidth = Dimensions.get("window").width - 45;

export const colors = {
    black: "#131517",
    blue100: "#D7E8FC",
    blue300: "#8DBBFA",
    blue400: "rgb(103, 164, 252)", // #67a4fc
    blue400Rgb: "103, 164, 252",
    blue500: "#418DFF",
    blue600: "#3471CC",
    blue800: "#1A3866",
    green25: "#F4FCFA", // rgb(244, 252, 250)
    green50: "#E9F8F5",
    green100: "#D2F1EB", // rgb(210, 241, 235)
    green300: "#79D6C4",
    green500: "#1FBA9C",
    green600: "#19957D",
    green700: "#13705E",
    green800: "#0C4A3E",
    grey25: "#FAFBFC",
    grey50: "#F4F7F8",
    grey75: "#EFF3F4",
    grey100: "#E9EFF1", // rgb(233, 239, 241)
    grey150: "#dee4e7",
    grey200: "#D5DADD",
    grey400: "#A9B5BB",
    grey600: "#768087",
    grey700: "#596166",
    grey800: "#3A3F43",
    transparent: "transparent",
    violet500: "#B047E2",
    warning200: "#ffe7a3",
    warning200Bg: "#fff9e8",
    warning500: "rgb(255, 195, 25)", // #ffc319
    warning500Bg: "rgba(255, 195, 25, 0.05)", // #FFFCF3
    warning800: "#674f0b",
    white: "white",
};

export const boxShadow: {
    shadowColor: string;
    shadowOffset: {
        width: number;
        height: number;
    };
    shadowOpacity: number;
    shadowRadius: number;
    elevation: number;
} = {
    // boxShadow:
    //     "1px 4px 8px -2px rgba(19, 21, 23, 0.08), 0px 2px 4px -2px rgba(19, 21, 23, 0.04)",
    // https://ethercreative.github.io/react-native-shadow-generator/
    shadowColor: colors.black,
    shadowOffset: {
        width: 0,
        height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
};

export const commentContentBox: Styles = {
    backgroundColor: colors.white,
    borderColor: colors.grey50,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    maxWidth: 300,
    minWidth: 300,
    padding: 12,
    position: "relative",
};
export const commentDropItem: Styles = {
    fontSize: 10,
    fontWeight: "500",
    lineHeight: 15,
    borderRadius: 800,
    height: "100%",
};

export const commentDropSlot: Styles = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    fontSize: 10,
    height: 20,
    padding: 2,
    width: 20,
    overflow: "hidden",
};

export const crosshairCanvas: Styles = {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    position: "absolute",
    pointerEvents: "none",
};

export const crosshairTag: Styles = {
    backgroundColor: colors.green50,
    borderColor: colors.green100,
    color: colors.green800,
    fontSize: 12,
};

export const graphSlot: Styles = {
    position: "relative",
};

export const root: Styles = {
    backgroundColor: colors.white,
    fontSize: 12,
    maxWidth: "100%",
    position: "relative",
};

export const svgWeb: Styles = {
    height: "100%",
    width: "100%",
    transform: "scaleY(-1)",
};

export const svgNative: Styles = {
    height: "100%",
    width: "100%",
};

export const tag: Styles = {
    borderRadius: 4,
    fontSize: 12,
    lineHeight: 20,
    paddingLeft: 4,
    paddingRight: 4,
    position: "absolute",
    textAlign: "center",
    zIndex: 1,
};

export const tagThreshold: Styles = {
    backgroundColor: colors.warning200Bg,
    borderColor: colors.warning200,
    borderStyle: "solid",
    borderWidth: 1,
    color: colors.warning800,
};

export const tagYaxis: Styles = {
    backgroundColor: colors.white,
    color: colors.warning800,
};

export const timeGridLinesHorizontalBottom: Styles = {
    borderBottomColor: colors.grey150,
    borderBottomWidth: 1,
    borderStyle: "solid",
};

export const timeGridLinesHorizontalTop: Styles = {
    borderStyle: "solid",
    borderTopColor: colors.grey150,
    borderTopWidth: 1,
    position: "absolute",
};
export const timeGridLinesVerticalDashed: Styles = {
    borderLeftColor: colors.grey150,
    borderLeftWidth: 1,
    borderStyle: isWeb ? "dashed" : "solid",
    position: "absolute",
};

export const timeGridLinesVerticalSolid: Styles = {
    borderLeftColor: colors.grey150,
    borderLeftWidth: 1,
    borderStyle: "solid",
    bottom: 4,
    position: "absolute",
    top: 4,
};

export const timeGridStamp: Styles = {
    color: colors.grey600,
    flexShrink: 1, // whiteSpace: "nowrap",
    fontSize: 12,
    lineHeight: 20,
    position: "absolute",
    textTransform: "capitalize",
    overflow: "hidden",
};

export const timeGridStamps: Styles = {
    borderBottomColor: colors.grey100,
    borderBottomWidth: 1,
    flexDirection: "row",
    height: 20,
    maxWidth: "100%",
    position: "relative",
    overflow: "hidden",
};

export const tooltipArrow: Styles = {
    borderColor: colors.transparent,
    borderStyle: "solid",
    borderWidth: 5,
    left: "50%",
    marginLeft: -5,
    position: "absolute",
    bottom: -10,
};

export const tooltipContentBox: Styles = {
    backgroundColor: colors.white,
    borderRadius: 4,
    padding: 8,
    position: "relative",
};

export const tooltipCoordContentBox: Styles = {
    backgroundColor: colors.white,
    borderColor: colors.grey50,
    borderStyle: "solid",
    borderWidth: 1,
    padding: 12,
    width: 168,
};

export const tooltipCoordManualTag: Styles = {
    backgroundColor: colors.grey75,
    borderRadius: 8,
    color: colors.grey600,
    // fontFamily: 'Inter, "sans-serif"',
    fontSize: 10,
    left: 8,
    lineHeight: 16,
    paddingLeft: 4.5,
    paddingRight: 4.5,
    position: "absolute",
    textTransform: "uppercase",
    top: -8,
};

export const tooltipCoordText: Styles = {
    color: colors.grey600,
    fontSize: 12,
    lineHeight: 18,
};

export const tooltipDragSelectText: Styles = {
    color: colors.white,
    fontSize: 12,
    lineHeight: 18,
    paddingLeft: 12,
    paddingRight: 12,
};

export const thumbnailFooter: Styles = {
    fontSize: 12,
    fontWeight: "400",
    color: colors.grey600,
    textAlign: "right",
    textTransform: "capitalize",
    minHeight: 18,
};

export const thumbnailGraphSlot: Styles = {
    position: "relative",
    marginTop: 16,
    marginBottom: 5,
};

export const thumbnailHeader: Styles = {
    fontSize: 14,
    fontWeight: "500",
    color: colors.black,
};

export const thumbnailRoot: StylesNull = {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: isWeb ? 16 : 10,
    borderWidth: 1,
    borderColor: colors.grey100,
    overflow: "hidden",
    width: isWeb ? null : screenWidth / 2,
    maxWidth: isWeb ? null : screenWidth / 2,
    marginBottom: isWeb ? null : 12,
    flexGrow: 1,
};

export const thumbnailValueSlot: StylesNull = {
    alignItems: isWeb ? "baseline" : "flex-end",
    paddingTop: 0,
    width: isWeb ? null : "100%",
    marginTop: 3,
};

export const thumbnailValue: Styles = {
    fontSize: isWeb ? 26 : 18,
    fontWeight: "500",
    color: colors.black,
};

export const thumbnailUnit: StylesNull = {
    fontSize: 14,
    fontWeight: "400",
    color: colors.grey600,
};
