import { AlertLevel, forHumans } from "@madmedical/medical";
import { Box, HStack, Text } from "native-base";
import type { ComponentProps } from "react";

interface Props {
    length: number;
    selectedIndex: number;
    alertLevel: Exclude<AlertLevel, AlertLevel.Unevaluated>;
}

const RangeChart = ({ length, selectedIndex, alertLevel }: Props) => {
    const getColor = (): ComponentProps<typeof Box>["color"] => {
        switch (alertLevel) {
            case AlertLevel.Ok:
                return "green.400";
            case AlertLevel.Warning:
                return "gray.600";
            case AlertLevel.Alert:
                return "error.400";
            default:
                return "gray.600";
        }
    };

    const getBG = (): ComponentProps<typeof Box>["bg"] => {
        switch (alertLevel) {
            case AlertLevel.Ok:
                return "green.400";
            case AlertLevel.Warning:
                return "warning.400";
            case AlertLevel.Alert:
                return "error.400";
            default:
                return "gray.100";
        }
    };

    return (
        <HStack space={0.5} width="full" alignItems="flex-end">
            {[...Array<never>(length)].map((_, i) => (
                <Box key={i} flex={1}>
                    {i === selectedIndex && (
                        <Text
                            color={getColor()}
                            numberOfLines={1}
                            textAlign="center"
                            fontSize="2xs"
                            mb={2}
                        >
                            {forHumans(alertLevel)}
                        </Text>
                    )}
                    <Box
                        bg={i === selectedIndex ? getBG() : "gray.100"}
                        height="3"
                        borderLeftRadius={i === 0 ? "xs" : "none"}
                        borderRightRadius={i === length - 1 ? "xs" : "none"}
                    ></Box>
                </Box>
            ))}
        </HStack>
    );
};

export default RangeChart;
