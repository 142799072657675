import type { HeartRateVariability } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import { ceil2, floor2 } from "@madmedical/utils";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<HeartRateVariability>;

const HeartRateVariabilityChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(
        MetricType.HeartRateVariability,
        widget
    );

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "line-single",
            unit: "ms",
            limits: {
                yMax: max ? ceil2(max.ms + 1) : 40,
                yMin: min ? floor2(min.ms - 1) : 20,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.ms,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.HeartRateVariability}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default HeartRateVariabilityChart;
