import type {
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { retry } from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";
import { createApi } from "./context";
import { invalidateTokens, updateTokens } from "./auth/slice";
import type { TokenResponse } from "./auth/model";
import fetchBaseQuery from "./fetchBaseQuery";
import { getRefreshToken } from "./auth/refreshTokenStorage";

export interface BaseQueryExtraOptions {
    readonly withoutAuth?: boolean;
}

interface ThunkExtraArgument {
    readonly hostnameBackend: string;
}

type Assert = (condition: boolean) => asserts condition;

const getResponseCode = (error: FetchBaseQueryError | undefined) => {
    if (!error) {
        return undefined;
    }

    if (error.status === "PARSING_ERROR") {
        return error.originalStatus;
    }

    return error.status;
};

const mutex = new Mutex();

const baseQuery = retry(
    async (
        args: string | FetchArgs,
        api,
        extraOptions?: BaseQueryExtraOptions
    ) => {
        const { dispatch, endpoint } = api;
        const isTokenRefresh = endpoint === "tokenRefresh";

        const baseQuery = fetchBaseQuery({
            withoutAuth: !!extraOptions?.withoutAuth,
            hostnameBackend: (api.extra as ThunkExtraArgument).hostnameBackend,
        });

        if (!isTokenRefresh) {
            // Wait for a possible concurrent token refresh
            await mutex.waitForUnlock();
        }

        const result = await baseQuery(args, api, extraOptions ?? {});

        // 401 suggests token has expired, so we try to fetch a new one – or clear auth state if it fails
        // If mutex is locked then another request is already refreshing so we let this one fail and go for retry
        // This be tightly coupled with retry options below – backoff and retryCondition
        if (
            getResponseCode(result.error) === 401 &&
            !mutex.isLocked() &&
            !isTokenRefresh
        ) {
            // Lock and attempt token refresh
            const release = await mutex.acquire();
            try {
                const giveUp = () => {
                    dispatch(invalidateTokens());
                    retry.fail(result.error);
                };

                const assert: Assert = (condition) => {
                    if (condition) {
                        return;
                    }

                    giveUp();
                };

                const refreshToken = await getRefreshToken();
                assert(!!refreshToken);

                try {
                    // Refresh token
                    await dispatch(
                        baseApi.endpoints.tokenRefresh.initiate(refreshToken)
                    ).unwrap();
                } catch {
                    giveUp();
                }
            } finally {
                release();
            }
        }

        return result;
    },
    {
        backoff: async (attempt, maxRetries) => {
            if (attempt === 1) {
                // No waiting for first retry to allow instant recovery after token refresh
                return;
            }

            // Below is the defaultBackoff copied from RTK as it is not exported

            const attempts = Math.min(attempt, maxRetries) - 1;

            const timeout = ~~((Math.random() + 0.4) * (300 << attempts));
            await new Promise((resolve) =>
                setTimeout((res) => resolve(res), timeout)
            );
        },
        retryCondition: (error, args, { attempt }) => {
            // bail out of re-tries for client-side errors like validation
            // Except unauth where we rely on automatic refresh and retry
            const responseCode = getResponseCode(error) as number;

            const shouldRetry =
                !responseCode ||
                responseCode === 401 ||
                responseCode < 400 ||
                responseCode >= 500;

            return shouldRetry && attempt < 3;
        },
    }
);

const baseApi = createApi({
    baseQuery,
    refetchOnMountOrArgChange: 60,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        tokenRefresh: build.mutation<TokenResponse, string>({
            query: (payload) => ({
                url: "security/refresh",
                method: "POST",
                body: { refreshToken: payload },
            }),
            extraOptions: { withoutAuth: true },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                // TODO: invalidate tokens

                dispatch(updateTokens(data));
            },
        }),
    }),
    tagTypes: [],
});

export const { useTokenRefreshMutation } = baseApi;

export default baseApi;
