import { ManualDataRecordModalForm, Modal, usePregnancy } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { usePatientId } from "@madmedical/user";
import { MetricType, type MetricTypeManualAllowed } from "@madmedical/medical";
import { useCreateManualMutation } from "../../measurement/api";

interface Props {
    metricType: MetricTypeManualAllowed;
    open: boolean;
    onClose: () => void;
}
const PregnancyMeasurementModalForm = ({
    metricType,
    open,
    onClose,
}: Props) => {
    const { patientId } = usePatientId();
    const [createManual] = useCreateManualMutation();

    const { refetchBMI } = usePregnancy();

    return (
        <Modal open={open} onClose={onClose} size="xs">
            <ApiFormWrapper
                defaultValues={{
                    userId: patientId,
                    measuredAt: new Date().toISOString(),
                    metric: { type: metricType },
                }}
                mutation={createManual}
                resetOnSuccess
                onSuccess={
                    metricType === MetricType.BodyWeight
                        ? () => {
                              setTimeout(() => {
                                  refetchBMI?.();
                              }, 3000);
                          }
                        : undefined
                }
            >
                {({ submitForm, isSuccess }) => (
                    <ManualDataRecordModalForm
                        metricType={metricType}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default PregnancyMeasurementModalForm;
