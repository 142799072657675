import type { ElementRef } from "react";
import { useRef, useState } from "react";
import { invariant } from "@madmedical/utils";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { extractSelected } from "../utils";
import Button from "../../../atoms/Button";
import ActionSheet from "../../../molecules/ActionSheet";
import { useEvaluations } from "../../../providers/evaluation";
import MobileFilterList from "./MobileFilterList";

interface Selected {
    metricTypes: MetricType[];
    alertLevels: AlertLevel[];
    doctors: string[];
}

const emptySelected: Selected = {
    metricTypes: [],
    alertLevels: [],
    doctors: [],
};

const EvaluationsMobileFilter = () => {
    const { filterChoices, onFilterChange } = useEvaluations();
    invariant(filterChoices);
    invariant(onFilterChange);
    const actionSheetRef = useRef<ElementRef<typeof ActionSheet>>(null);
    const [selected, setSelected] = useState({
        metricTypes: extractSelected(filterChoices, "metricTypes"),
        alertLevels: extractSelected(filterChoices, "alertLevels"),
        doctors: extractSelected(filterChoices, "doctors"),
    });
    const { t } = useTranslation();
    const handleTogglePress = () => {
        actionSheetRef.current?.toggle();
    };

    const handleResetPress = () => {
        setSelected(emptySelected);
    };

    const handleSubmitPress = () => {
        onFilterChange?.("metricTypes", selected.metricTypes);
        onFilterChange?.("alertLevels", selected.alertLevels);
        onFilterChange?.("doctors", selected.doctors);
        actionSheetRef.current?.toggle();
    };

    const handleChange =
        <
            TFilterKey extends keyof typeof selected,
            TValues extends (typeof selected)[TFilterKey]
        >(
            filterKey: TFilterKey
        ) =>
        (newSelected: TValues) => {
            setSelected((prevSelected) => ({
                ...prevSelected,
                [filterKey]: newSelected,
            }));
        };

    return (
        <>
            <ActionSheet
                title={t("filter")}
                footer={
                    <Button onPress={handleSubmitPress} size="xl">
                        {t("showResults")}
                    </Button>
                }
                rightContent={
                    <Button
                        position="absolute"
                        right={0}
                        top={0}
                        onPress={handleResetPress}
                        size="xs"
                        variant="link"
                        p={0}
                    >
                        {t("setback")}
                    </Button>
                }
                ref={actionSheetRef}
            >
                <>
                    <MobileFilterList
                        filterChoices={filterChoices.metricTypes}
                        selected={selected.metricTypes}
                        isMultiSelect={true}
                        title={t("measures:type")}
                        onChange={handleChange("metricTypes")}
                    />
                    <MobileFilterList
                        filterChoices={filterChoices.alertLevels}
                        selected={selected.alertLevels}
                        isMultiSelect={false}
                        title={t("alertLevel")}
                        onChange={handleChange("alertLevels")}
                    />
                    <MobileFilterList
                        filterChoices={filterChoices.doctors}
                        selected={selected.doctors}
                        isMultiSelect={true}
                        title={t("doctor:doctor")}
                        onChange={handleChange("doctors")}
                    />
                </>
            </ActionSheet>
            <Button
                variant="outlined"
                size="xs"
                onPress={handleTogglePress}
                leftIconName="filter"
            >
                {t("filter")}
            </Button>
        </>
    );
};

export default EvaluationsMobileFilter;
