import type { ComponentProps, ReactNode } from "react";
import { useEffect, useState } from "react";
import { Box, Pressable, Stack, Text } from "native-base";

interface TabProps extends ComponentProps<typeof Pressable> {
    badge?: number | string;
    tabState: {
        tabIndex: number;
        active: number;
    };
    children: ReactNode;
    setActiveTab: (i: number) => void;
    size?: keyof typeof tabSizes;
    variant?: keyof typeof tabVariants;
    _tab?: Partial<ComponentProps<typeof Pressable>>;
}

interface TabContent {
    badge?: string | number | undefined;
    title: string;
    content?: ReactNode;
}

interface TabsProps extends ComponentProps<typeof Stack> {
    tabs: TabContent[];
    setTitle?: (title: string) => void;
    size?: keyof typeof tabSizes;
    variant?: keyof typeof tabVariants;
    rightContent?: ReactNode;
    _tab?: Partial<TabProps>;
    currentIndex?: number;
    chooseTab?: (i: number) => void;
}

const tabSizes = {
    sm: {
        tab: {
            p: 2,
        },
        text: {
            fontSize: "sm",
        },
    },
    md: {
        tab: {
            p: 2,
        },
        text: {
            fontSize: "md",
        },
    },
    lg: {
        tab: {
            p: 2,
        },
        text: {
            fontSize: "lg",
        },
    },
} as const;

const tabVariants = {
    default: {
        badge: {
            backgroundColor: "gray.400",
            text: {
                color: "white",
            },
        },
        tab: {
            _hover: {
                borderBottomColor: "gray.200",
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
            },
            py: 4,
        },
        text: {
            color: "gray.600",
        },
    },
    primary: {
        badge: {
            backgroundColor: "green.50",
            text: {
                color: "green.600",
            },
        },
        tab: {
            _hover: {
                borderBottomColor: "green.600",
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
            },
        },
        text: {
            color: "green.600",
        },
    },
} as const;

export const Tab = ({
    tabState,
    children,
    setActiveTab,
    size = "sm",
    variant = "default",
    _tab,
    badge,
}: TabProps) => (
    <Pressable
        {...tabVariants[variant].tab}
        {...tabSizes[size].tab}
        {...(tabState.active === tabState.tabIndex
            ? {
                  borderBottomColor: "green.600",
                  borderBottomStyle: "solid",
                  borderBottomWidth: 2,
              }
            : undefined)}
        onPress={() => setActiveTab(tabState.tabIndex)}
        {..._tab}
    >
        <Stack direction="row" alignItems="center">
            <Text
                fontWeight="medium"
                {...tabSizes[size].text}
                {...tabVariants[variant].text}
                color={
                    tabState.active === tabState.tabIndex
                        ? "green.600"
                        : tabVariants[variant].text.color
                }
            >
                {children}
            </Text>
            {badge && (
                <Box
                    justifyContent="center"
                    alignItems="center"
                    minWidth={4}
                    height={4}
                    borderRadius="full"
                    ml={1.5}
                    _text={{
                        fontSize: "2xs",
                        ...tabVariants[
                            tabState.active === tabState.tabIndex
                                ? "primary"
                                : "default"
                        ].badge.text,
                    }}
                    {...tabVariants[
                        tabState.active === tabState.tabIndex
                            ? "primary"
                            : "default"
                    ].badge}
                >
                    {badge}
                </Box>
            )}
        </Stack>
    </Pressable>
);

const Tabs = ({
    tabs,
    setTitle,
    size = "sm",
    variant = "default",
    _tab,
    rightContent,
    currentIndex = 0,
    chooseTab,
    ...rest
}: TabsProps) => {
    const [activeTab, setActiveTab] = useState(currentIndex);

    const chooseTabIndex = (i: number) => {
        setActiveTab(i);
        chooseTab?.(i);
    };

    useEffect(() => {
        if (!tabs.length) {
            return;
        }

        setTitle?.(tabs[activeTab].title);
    }, [setTitle, tabs, activeTab]);

    if (!tabs.length) {
        return null;
    }

    return (
        <Box testID="tabs" flex={1}>
            <Stack
                direction="row"
                space={2}
                width="full"
                alignItems="center"
                {...rest}
            >
                {tabs.map((tab, tabIndex) => (
                    <Tab
                        tabState={{ tabIndex, active: activeTab }}
                        size={size}
                        variant={variant}
                        key={`tab-item-${tabIndex}`}
                        setActiveTab={chooseTabIndex}
                        _tab={_tab}
                        badge={tab.badge}
                    >
                        {tab.title}
                    </Tab>
                ))}
                {rightContent && <Box ml="auto">{rightContent}</Box>}
            </Stack>
            <Box>{tabs[activeTab].content}</Box>
        </Box>
    );
};

export default Tabs;
