import { formatDate } from "@madmedical/utils";
import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import type { PatientDoctor } from "../../providers/patient";
import { usePreOp } from "../../providers/preop";
import { useRouteNavigate } from "../../providers/routing";
import Alert from "../../molecules/Alert";
import { useCurrentPatient } from "../../providers/currentPatient";

const PreOpAlert = () => {
    const { questionnaire } = usePreOp();
    const { patient } = useCurrentPatient<PatientDoctor>();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    if (!questionnaire) {
        return null;
    }

    {
        return (
            <Box mb={3}>
                <Alert
                    title={t("preop:anesthesiaQuestionare")}
                    description={
                        t("preop:questionnaireWarning", {
                            count: questionnaire.badAnswersCount,
                            name: patient.fullName,
                        }) +
                        " " +
                        formatDate(questionnaire.createdAt)
                    }
                    links={[
                        {
                            text: t("view"),
                            onPress: () => {
                                navigate("preop", {
                                    patientId: patient.userId,
                                });
                            },
                        },
                    ]}
                    status={questionnaire.badAnswersCount ? "error" : "success"}
                />
            </Box>
        );
    }
};
export default PreOpAlert;
