import Svg, { Path, Rect } from "react-native-svg";

const IcicleRaddish = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M85.4056 31.5884C85.4056 31.5884 82.2064 28.064 78.6818 30.1786C76.6394 31.4041 76.8732 33.3396 77.4308 34.7516C77.6331 35.2511 77.715 35.7911 77.6699 36.3282C77.6248 36.8652 77.4539 37.384 77.1711 37.8428C76.4405 39.0009 76.0698 40.8325 78.465 42.9755C79.0604 43.4824 79.5168 44.1327 79.791 44.865C80.0653 45.5973 80.1483 46.3874 80.0323 47.1607C80.0323 47.1607 89.0927 41.5652 85.4056 31.5884Z"
            fill="#7B9791"
        />
        <Path
            d="M102.014 34.0508C103.416 34.2205 104.754 34.7332 105.911 35.5436C112.096 39.758 108.868 46.651 108.868 46.651C108.868 46.651 107.166 52.0565 100.387 48.6022C100.387 48.6022 99.3395 53.4457 92.4217 51.681C90.8785 51.276 89.2557 51.2872 87.7182 51.7132C86.1808 52.1392 84.7836 52.9649 83.669 54.1062C83.2615 54.5278 82.8423 54.9694 82.4199 55.4254C79.4729 53.4963 83.367 48.3124 90.4293 41.6208L102.014 34.0508Z"
            fill="#B6D2C6"
        />
        <Path
            d="M98.6217 44.0179C98.5745 43.7797 98.4386 43.5682 98.2414 43.4265C98.0442 43.2847 97.8005 43.2232 97.5596 43.2543C94.6793 43.5947 91.8795 44.4308 89.2842 45.7256C89.2262 45.755 89.1594 45.7621 89.0965 45.7455C89.0336 45.7289 88.979 45.6898 88.943 45.6355C88.9071 45.5813 88.8923 45.5158 88.9015 45.4514C88.9106 45.387 88.9431 45.3282 88.9928 45.2862C90.8353 43.6931 92.8578 42.321 95.0191 41.1976C100.503 38.4105 101.739 35.2108 102.013 34.0541C97.7617 33.567 94.3883 36.5914 94.3883 36.5914C79.1001 46.1245 75.652 57.3233 75.652 57.3233L78.4699 60.1412C79.6958 58.4955 81.0149 56.9212 82.4205 55.4259C82.416 55.1525 82.4336 54.8791 82.473 54.6085C82.7062 53.1277 83.4947 51.7914 84.678 50.8711C88.8364 47.5478 93.2381 45.6039 97.8137 45.0878C97.9383 45.0737 98.0587 45.0343 98.1676 44.9722C98.2765 44.91 98.3717 44.8263 98.4473 44.7263C98.5228 44.6262 98.5773 44.5118 98.6073 44.39C98.6372 44.2683 98.6421 44.1417 98.6217 44.0179Z"
            fill="#7B9791"
        />
        <Path
            d="M97.6557 25.6062C97.6557 25.6062 97.0563 19.9704 91.4202 20.4498C91.4202 20.4498 85.0343 21.2592 87.0884 28.8286C87.0884 28.8286 82.8466 30.5826 84.4055 36.578C84.4539 36.9789 84.4714 37.3829 84.4577 37.7864C84.8135 39.5723 84.3184 41.4286 82.247 43.413L76.2516 49.4085C75.9157 49.7334 75.5994 50.0781 75.3045 50.4408C75.3842 50.9305 75.5742 51.3957 75.8602 51.8012C76.1461 52.2067 76.5205 52.5419 76.9551 52.7814C78.4542 53.6202 80.7791 50.7598 80.7791 50.7598C83.7487 47.174 86.9536 43.7898 90.3725 40.6295C91.7998 39.3281 93.4318 38.271 95.2031 37.5007C97.2943 36.6261 99.0755 35.1451 100.317 33.2487C100.864 32.4076 101.159 31.4281 101.168 30.4252C101.177 29.4223 100.899 28.4377 100.368 27.5869C99.7444 26.6099 98.7762 25.903 97.6557 25.6062Z"
            fill="#B6D2C6"
        />
        <Path
            d="M102.342 44.4603C102.861 44.4603 103.283 44.039 103.283 43.5192C103.283 42.9995 102.861 42.5781 102.342 42.5781C101.822 42.5781 101.401 42.9995 101.401 43.5192C101.401 44.039 101.822 44.4603 102.342 44.4603Z"
            fill="#7B9791"
        />
        <Path
            d="M82.2473 43.4137C87.5582 39.5173 90.5592 33.7635 91.8567 31.1996C91.9009 31.1125 91.9116 31.0122 91.8866 30.9177C91.8617 30.8233 91.8029 30.7413 91.7215 30.6873C91.64 30.6334 91.5416 30.6113 91.4449 30.6251C91.3482 30.639 91.26 30.688 91.197 30.7626C89.5424 32.7139 86.9328 35.6416 84.4577 37.7871C84.3898 41.0434 82.2473 43.4137 82.2473 43.4137Z"
            fill="#7B9791"
        />
        <Path
            d="M75.3056 50.4424C74.3004 51.6687 72.9618 53.7628 73.7933 55.4653L75.652 57.324C75.652 57.324 77.6375 54.5191 80.7804 50.7611C80.7753 50.7671 78.238 53.3342 75.3056 50.4424Z"
            fill="#7B9791"
        />
        <Path
            d="M21.6691 104.962C21.6405 104.934 21.614 104.903 21.5895 104.871C18.8504 101.314 21.4542 97.4059 23.2347 95.8162C24.2177 94.8237 24.422 93.8487 23.8598 92.8359C23.8008 92.7297 23.7633 92.613 23.7495 92.4923C23.7356 92.3716 23.7457 92.2494 23.7791 92.1326C23.8125 92.0158 23.8685 91.9067 23.9441 91.8116C24.0196 91.7165 24.1132 91.6372 24.2194 91.5782C24.3256 91.5193 24.4423 91.4818 24.563 91.4679C24.6837 91.4541 24.8059 91.4642 24.9227 91.4976C25.0395 91.5309 25.1485 91.587 25.2437 91.6626C25.3388 91.7381 25.4181 91.8316 25.4771 91.9378C26.0439 92.959 26.6739 94.993 24.5246 97.1423C24.5112 97.1557 24.4977 97.1684 24.484 97.1805C24.3287 97.3193 20.672 100.648 23.055 103.743C23.1961 103.929 23.2615 104.162 23.2379 104.395C23.2144 104.627 23.1037 104.842 22.9282 104.996C22.7526 105.151 22.5252 105.233 22.2916 105.226C22.0581 105.219 21.8356 105.125 21.6691 104.961V104.962Z"
            fill="#E1BB9C"
        />
        <Path
            d="M76.7706 56.7217C76.7706 56.7217 70.2851 49.1182 60.4081 56.5664C60.056 57.112 59.5408 57.5326 58.9359 57.7684C53.9269 61.996 49.1911 66.537 44.757 71.3641C44.4789 72.0806 44.0998 72.6153 43.5026 72.759C42.9129 73.4246 42.3259 74.1024 41.7416 74.7924C41.6532 75.1006 41.5017 75.3871 41.2967 75.6337C41.0918 75.8803 40.8378 76.0816 40.551 76.2249C39.5368 77.4669 38.5449 78.74 37.5927 80.0356C34.4463 85.5388 32.2044 91.1094 35.3722 97.0827C38.6599 99.9996 42.2904 101.508 46.5248 100.536C46.7261 100.366 46.9289 100.192 47.1331 100.016C47.1331 100.016 48.367 98.9579 50.4002 97.1679C50.5403 96.5228 51.0937 96.1732 51.7988 95.9319C52.2929 95.4939 52.8169 95.0276 53.3707 94.5329C53.7353 94.0192 54.2103 93.5934 54.7607 93.2869C58.7743 89.6772 63.8809 84.9798 68.8375 80.1279C68.9407 79.8186 69.1156 79.5381 69.3479 79.3093C69.5803 79.0806 69.8634 78.91 70.1742 78.8115C72.919 76.0926 75.5822 73.3516 77.9479 70.7501C78.2004 70.1367 78.6045 69.5974 79.1223 69.1829C81.6036 65.032 79.9646 60.1314 76.7706 56.722V56.7217Z"
            fill="#F6DCB6"
        />
        <Path
            d="M46.5859 91.4926C47.1057 91.4926 47.527 91.0712 47.527 90.5515C47.527 90.0317 47.1057 89.6104 46.5859 89.6104C46.0662 89.6104 45.6448 90.0317 45.6448 90.5515C45.6448 91.0712 46.0662 91.4926 46.5859 91.4926Z"
            fill="#E1BB9C"
        />
        <Path
            d="M46.5226 100.534C44.4321 100.153 41.7873 98.7713 38.7297 95.2309C33.2524 88.8837 37.5916 80.0371 37.5916 80.0371C32.8774 86.453 29.1411 93.4228 28.5623 99.8845C28.5035 100.598 28.198 101.269 27.6983 101.782C27.076 102.409 26.1046 102.849 24.803 101.836C24.803 101.836 22.3071 99.6104 23.5215 95.6979C23.5215 95.6979 18.6488 99.5222 20.8684 104.731C21.5074 106.2 22.569 107.445 23.9181 108.308C25.2671 109.171 26.8425 109.613 28.4435 109.577C32.1823 109.505 38.0765 107.685 46.5226 100.534Z"
            fill="#E1BB9C"
        />
        <Path
            d="M64.8861 60.1879C63.6942 59.1596 61.8512 57.6814 60.4079 56.5674C60.3443 56.6153 60.2815 56.6607 60.2185 56.71C60.2185 56.71 59.753 57.081 58.9351 57.768C60.3903 58.8806 62.4102 60.4942 63.6782 61.5884C63.8639 61.7487 64.1057 61.8286 64.3503 61.8106C64.595 61.7925 64.8225 61.6781 64.9827 61.4924C65.143 61.3066 65.2229 61.0649 65.2048 60.8202C65.1868 60.5756 65.0724 60.3481 64.8866 60.1879H64.8861Z"
            fill="#E1BB9C"
        />
        <Path
            d="M72.9905 62.7099C72.8981 62.7888 72.8223 62.8851 72.7671 62.9933C72.712 63.1016 72.6788 63.2196 72.6693 63.3407C72.6598 63.4618 72.6742 63.5835 72.7118 63.699C72.7493 63.8145 72.8093 63.9215 72.8882 64.0138C74.6907 66.1692 76.379 68.4174 77.9463 70.7493L77.9734 70.7198C78.4051 70.246 78.7884 69.7302 79.1177 69.1802C77.7918 67.2417 75.9101 64.7038 74.2942 62.8122C74.1345 62.6263 73.9077 62.5113 73.6634 62.4921C73.4191 62.4729 73.1772 62.5512 72.9905 62.7099Z"
            fill="#E1BB9C"
        />
        <Path
            d="M70.1729 78.811C69.3113 77.5842 68.1854 76.0654 67.3934 75.0712C67.3184 74.9742 67.2249 74.8931 67.1183 74.8326C67.0117 74.772 66.8942 74.7333 66.7724 74.7186C66.6507 74.7039 66.5273 74.7135 66.4094 74.747C66.2914 74.7804 66.1813 74.8369 66.0854 74.9133C65.9895 74.9897 65.9098 75.0843 65.8508 75.1918C65.7918 75.2993 65.7548 75.4174 65.7418 75.5393C65.7289 75.6612 65.7404 75.7845 65.7755 75.9019C65.8106 76.0193 65.8688 76.1286 65.9465 76.2234C66.7602 77.2452 67.9827 78.9003 68.8362 80.1275C69.2832 79.6896 69.7287 79.2508 70.1729 78.811Z"
            fill="#E1BB9C"
        />
        <Path
            d="M50.4061 75.8812C48.6202 74.2425 46.9274 72.7497 44.7562 71.3643C44.3379 71.8226 43.9197 72.2876 43.5018 72.7591C45.6805 74.1153 47.366 75.6028 49.1557 77.2444C49.3365 77.4102 49.5757 77.4974 49.8208 77.4868C50.0659 77.4763 50.2967 77.3688 50.4625 77.188C50.6283 77.0072 50.7155 76.768 50.705 76.5229C50.6944 76.2778 50.5869 76.047 50.4061 75.8812Z"
            fill="#E1BB9C"
        />
        <Path
            d="M40.5502 76.225C41.3034 76.7494 41.9993 77.3514 42.6267 78.0212C42.8002 78.1946 43.0354 78.2921 43.2807 78.2921C43.526 78.2921 43.7613 78.1946 43.9347 78.0212C44.1082 77.8477 44.2056 77.6125 44.2056 77.3672C44.2056 77.1219 44.1082 76.8866 43.9347 76.7132C43.2647 76.0058 42.5301 75.3627 41.7405 74.792C41.3411 75.2644 40.9444 75.7421 40.5502 76.225Z"
            fill="#E1BB9C"
        />
        <Path
            d="M54.7593 93.2865C53.0743 90.8194 51.2379 88.4591 49.2607 86.2193C49.1796 86.1289 49.0814 86.0553 48.9719 86.0029C48.8624 85.9504 48.7435 85.92 48.6223 85.9135C48.3773 85.9002 48.1371 85.9848 47.9546 86.1487C47.772 86.3125 47.662 86.5421 47.6488 86.7871C47.6355 87.032 47.7201 87.2722 47.8839 87.4548C49.8617 89.6944 51.6939 92.0585 53.3693 94.5326C53.8156 94.1337 54.2789 93.7184 54.7593 93.2865Z"
            fill="#E1BB9C"
        />
        <Path
            d="M51.7975 95.9316C51.2441 95.1057 50.4732 93.9863 49.9134 93.2241C49.8417 93.1257 49.7513 93.0424 49.6473 92.979C49.5434 92.9156 49.4279 92.8734 49.3076 92.8547C49.1873 92.836 49.0645 92.8413 48.9462 92.8702C48.8279 92.8991 48.7165 92.9511 48.6184 93.0231C48.5203 93.0952 48.4373 93.1859 48.3743 93.2901C48.3113 93.3943 48.2695 93.5099 48.2513 93.6303C48.2331 93.7507 48.2389 93.8735 48.2682 93.9916C48.2976 94.1098 48.35 94.221 48.4224 94.3189C48.9988 95.1036 49.8511 96.3448 50.3977 97.1676C50.8301 96.7873 51.2967 96.3753 51.7975 95.9316Z"
            fill="#E1BB9C"
        />
    </Svg>
);

export default IcicleRaddish;
