import { Text } from "native-base";
import Week01 from "./BodyWeekTexts/Week01";
import Week09 from "./BodyWeekTexts/Week09";
import Week10 from "./BodyWeekTexts/Week10";
import Week11 from "./BodyWeekTexts/Week11";
import Week12 from "./BodyWeekTexts/Week12";
import Week13 from "./BodyWeekTexts/Week13";
import Week14 from "./BodyWeekTexts/Week14";
import Week15 from "./BodyWeekTexts/Week15";
import Week16 from "./BodyWeekTexts/Week16";
import Week17 from "./BodyWeekTexts/Week17";
import Week18 from "./BodyWeekTexts/Week18";
import Week19 from "./BodyWeekTexts/Week19";
import Week20 from "./BodyWeekTexts/Week20";
import Week21 from "./BodyWeekTexts/Week21";
import Week22 from "./BodyWeekTexts/Week22";
import Week23 from "./BodyWeekTexts/Week23";
import Week24 from "./BodyWeekTexts/Week24";
import Week25 from "./BodyWeekTexts/Week25";
import Week26 from "./BodyWeekTexts/Week26";
import Week27 from "./BodyWeekTexts/Week27";
import Week28 from "./BodyWeekTexts/Week28";
import Week29 from "./BodyWeekTexts/Week29";
import Week30 from "./BodyWeekTexts/Week30";
import Week31 from "./BodyWeekTexts/Week31";
import Week32 from "./BodyWeekTexts/Week32";
import Week33 from "./BodyWeekTexts/Week33";
import Week34 from "./BodyWeekTexts/Week34";
import Week35 from "./BodyWeekTexts/Week35";
import Week36 from "./BodyWeekTexts/Week36";
import Week37 from "./BodyWeekTexts/Week37";
import Week38 from "./BodyWeekTexts/Week38";
import Week39 from "./BodyWeekTexts/Week39";
import Week40 from "./BodyWeekTexts/Week40";
import Week41 from "./BodyWeekTexts/Week41";
import Week02 from "./BodyWeekTexts/Week02";
import Week03 from "./BodyWeekTexts/Week03";
import Week04 from "./BodyWeekTexts/Week04";
import Week05 from "./BodyWeekTexts/Week05";
import Week06 from "./BodyWeekTexts/Week06";
import Week07 from "./BodyWeekTexts/Week07";
import Week08 from "./BodyWeekTexts/Week08";

interface Props {
    weekNr: number;
}

const PregnancyBodyWeekTexts = ({ weekNr }: Props) => {
    switch (weekNr) {
        case 1:
            return <Week01 />;
        case 2:
            return <Week02 />;
        case 3:
            return <Week03 />;
        case 4:
            return <Week04 />;
        case 5:
            return <Week05 />;
        case 6:
            return <Week06 />;
        case 7:
            return <Week07 />;
        case 8:
            return <Week08 />;
        case 9:
            return <Week09 />;
        case 10:
            return <Week10 />;
        case 11:
            return <Week11 />;
        case 12:
            return <Week12 />;
        case 13:
            return <Week13 />;
        case 14:
            return <Week14 />;
        case 15:
            return <Week15 />;
        case 16:
            return <Week16 />;
        case 17:
            return <Week17 />;
        case 18:
            return <Week18 />;
        case 19:
            return <Week19 />;
        case 20:
            return <Week20 />;
        case 21:
            return <Week21 />;
        case 22:
            return <Week22 />;
        case 23:
            return <Week23 />;
        case 24:
            return <Week24 />;
        case 25:
            return <Week25 />;
        case 26:
            return <Week26 />;
        case 27:
            return <Week27 />;
        case 28:
            return <Week28 />;
        case 29:
            return <Week29 />;
        case 30:
            return <Week30 />;
        case 31:
            return <Week31 />;
        case 32:
            return <Week32 />;
        case 33:
            return <Week33 />;
        case 34:
            return <Week34 />;
        case 35:
            return <Week35 />;
        case 36:
            return <Week36 />;
        case 37:
            return <Week37 />;
        case 38:
            return <Week38 />;
        case 39:
            return <Week39 />;
        case 40:
            return <Week40 />;
        case 41:
            return <Week41 />;
        default:
            return <Text>–</Text>;
    }
};

export default PregnancyBodyWeekTexts;
