import { Fragment, useMemo } from "react";
import { Box, Pressable, Text } from "native-base";
import type { PregnancyWellBeing } from "@madmedical/medical";
import { PregnancyWellBeingStatus } from "@madmedical/medical";
import PregnantMoodPics from "../molecules/Pregnancy/PregnantMoodPics";
import { commentDropSlot } from "./useStyles";
import type {
    CommentGroupType,
    GraphData,
    MoodGroupType,
    TimeData,
    TooltipType,
} from "./types";

export default function MoodDrop({
    graphData = {},
    timeData,
    moodGroup,
    setMoodGroup,
    setTooltip,
    setCommentState,
}: {
    graphData: GraphData;
    timeData: TimeData;
    moodGroup: MoodGroupType;
    setMoodGroup: (callback: (state: MoodGroupType) => MoodGroupType) => void;
    setTooltip: (callback: (state: TooltipType) => TooltipType) => void;
    setCommentState: (
        callback: (state: CommentGroupType) => CommentGroupType
    ) => void;
}) {
    const { limits, moods = [], randomIdFragment = "" } = graphData;

    const { timeStamps = [], timeCoords } = timeData;

    const memoMoods = useMemo(() => {
        const groupedMoods = Array.from(
            { length: timeStamps.length },
            (): PregnancyWellBeing[] => []
        );

        moods.forEach((mood) => {
            if (mood.msec) {
                const getMsec = mood.msec;
                const intervalIndex =
                    timeCoords.findIndex(({ msec }) => getMsec <= msec) - 1;

                groupedMoods[intervalIndex]?.push(
                    mood as unknown as PregnancyWellBeing
                );
            }
        });

        const mergedMoods = groupedMoods.map((group, groupIndex) => {
            if (group.length === 0) return null;

            const avatar = group.length === 1 ? group[0].status : "";

            return {
                status: groupedMoods[groupIndex],
                avatar,
                x: timeCoords[groupIndex].x,
            };
        });

        return {
            groupedMoods,
            mergedMoods,
        };
    }, [moods, timeCoords, timeStamps]);

    return (
        <>
            {!!memoMoods.mergedMoods.length &&
                memoMoods.mergedMoods.map((group, i) => {
                    const id = `${randomIdFragment}-mood-group-pos-${i}`;

                    const avatar = group?.avatar as PregnancyWellBeingStatus;
                    const firstAvatar = group?.status[0]
                        ?.status as PregnancyWellBeingStatus;

                    const colors = {
                        [PregnancyWellBeingStatus.BadTemper]: "#A786A9",
                        [PregnancyWellBeingStatus.Moody]: "#6793AC",
                        [PregnancyWellBeingStatus.Fluctuating]: "#7B9791",
                        [PregnancyWellBeingStatus.Calm]: "#EBA46A",
                        [PregnancyWellBeingStatus.Cheerful]: "#EBA388",
                    };

                    const dropBgColor = avatar ? colors[avatar] : "gray.800";
                    const activeColor = colors[firstAvatar];

                    return group ? (
                        <Box
                            position="absolute"
                            zIndex={2}
                            style={{
                                left: group.x,
                                bottom: limits?.yMax,
                                transform: [
                                    { translateY: 20 },
                                    {
                                        scale:
                                            moodGroup.onShow &&
                                            moodGroup.id === id
                                                ? 1.1
                                                : 1,
                                    },
                                ],
                            }}
                            key={id}
                        >
                            <Pressable
                                onPress={() => {
                                    setMoodGroup((state) => ({
                                        ...group,
                                        id,
                                        onShow:
                                            state.id === id
                                                ? !state.onShow
                                                : true,
                                    }));
                                    setTooltip((state) => ({
                                        ...state,
                                        onShow: false,
                                    }));
                                    setCommentState((state) => ({
                                        ...state,
                                        onShow: false,
                                    }));
                                }}
                            >
                                <Box
                                    nativeID={id}
                                    style={commentDropSlot}
                                    key={id}
                                    backgroundColor={
                                        moodGroup.onShow && moodGroup.id === id
                                            ? activeColor
                                            : dropBgColor
                                    }
                                >
                                    {avatar ? (
                                        <Box
                                            w={"16px"}
                                            h={"16px"}
                                            alignItems={"center"}
                                            rounded={"full"}
                                            overflow={"hidden"}
                                        >
                                            <PregnantMoodPics status={avatar} />
                                        </Box>
                                    ) : (
                                        <Box
                                            borderRadius="full"
                                            alignItems="center"
                                            justifyContent="center"
                                            w={"16px"}
                                            h={"16px"}
                                            rounded={"full"}
                                            bg={"white"}
                                        >
                                            <Text
                                                fontSize={"10px"}
                                                fontWeight={"medium"}
                                                color={
                                                    moodGroup.onShow &&
                                                    moodGroup.id === id
                                                        ? activeColor
                                                        : dropBgColor
                                                }
                                            >
                                                {group.status.length}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            </Pressable>
                        </Box>
                    ) : (
                        <Fragment key={id}></Fragment>
                    );
                })}
        </>
    );
}
