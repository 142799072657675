const baseStyle = () => ({
    _focusVisible: {
        _web: {
            style: {
                outlineWidth: "0px",
            },
        },
    },
});

const sizes = {
    lg: { _icon: { size: 5 }, _text: { fontSize: "lg" } },
    md: { _icon: { size: 4 }, _text: { fontSize: "md" } },
    sm: { _icon: { size: 3 }, _text: { fontSize: "sm" } },
};

const defaultProps = {
    defaultIsChecked: false,
    size: "sm",
    colorScheme: "primary",
};

export default {
    baseStyle,
    sizes,
    defaultProps,
};
