// Find defaults at https://github.com/GeekyAnts/NativeBase/blob/master/src/theme/base/colors.ts
import type { IColorHues } from "native-base/lib/typescript/theme/base/colors";

const colors = {
    contrastThreshold: 7,
    // Singleton colors
    white: "#ffffff",
    black: "#131517",
    lightText: "#ffffff",
    darkText: "#131517",
    navBgPrimary: "#1fba9c",
    navBgDark: "#323546",
    // Primary colors
    rose: {
        25: "#fef5f6",
        50: "#fdeaed",
        75: "#fce0e3",
        100: "#fbd5da",
        200: "#f8abb5",
        300: "#f48190",
        400: "#f1576b",
        500: "#ed2d46",
        600: "#be2135",
        700: "#8e1b2a",
        800: "#5f121c",
        900: "#2f090e",
    },
    violet: {
        50: "#f6e9fc",
        100: "#efdaf9",
        200: "#dfb5f3",
        300: "#d091ee",
        400: "#c06ce8",
        500: "#b047e2",
        600: "#8d39b5",
        700: "#6a2b88",
        800: "#461c5a",
        900: "#230e2d",
    },
    blue: {
        50: "#e6f3fd",
        100: "#d7e8fc",
        200: "#b3d1fa",
        300: "#8dbbfa",
        400: "#67a4fc",
        500: "#418dff",
        600: "#3471cc",
        700: "#275599",
        800: "#1a3866",
        900: "#0d1c33",
    },
    green: {
        15: "#f8fdfc",
        25: "#f4fcfa",
        50: "#e9f8f5",
        75: "#ddf4f0",
        100: "#d2f1eb",
        200: "#a5e3d7",
        300: "#79d6c4",
        400: "#4cc8b0",
        500: "#1fba9c",
        600: "#19957d",
        700: "#13705e",
        800: "#0c4a3e",
        900: "#06251f",
    },
    lime: {
        50: "#f6fdea",
        100: "#edfad5",
        200: "#dbf5ab",
        300: "#caef82",
        400: "#b8ea58",
        500: "#a6e52e",
        600: "#85b725",
        700: "#64891c",
        800: "#3e580e",
        900: "#212e09",
    },
    yellow: {
        25: "#fffcf3",
        50: "#fff9e8",
        75: "#fff6dd",
        100: "#fff3d1",
        200: "#ffe7a3",
        300: "#ffdb75",
        400: "#ffcf47",
        500: "#ffc319",
        600: "#d0a018",
        700: "#9b7711",
        800: "#674f0b",
        900: "#332705",
    },
    orange: {
        50: "#fff1ec",
        100: "#ffe2d8",
        200: "#ffc6b2",
        300: "#ffa98b",
        400: "#ff8d65",
        500: "#ff703e",
        600: "#cc5a32",
        700: "#994325",
        800: "#662d19",
        900: "#33160c",
    },
    gray: {
        15: "#fcfdfd",
        25: "#fafbfc",
        50: "#f4f7f8",
        75: "#eff3f4",
        100: "#e9eff1",
        150: "#dee4e7",
        200: "#d5dadd",
        300: "#bec7cb",
        400: "#a9b5bb",
        500: "#929ea7",
        600: "#768087",
        700: "#596166",
        800: "#3a3f43",
        900: "#1c1f21",
    },
    // Derived colors
    danger: {} as IColorHues,
    error: {} as IColorHues,
    success: {} as IColorHues,
    warning: {} as IColorHues,
    muted: {} as IColorHues,
    primary: {} as IColorHues,
    info: {} as IColorHues,
    secondary: {} as IColorHues,
    light: {} as IColorHues,
    tertiary: {} as IColorHues,
};

colors.primary = colors.green;
colors.secondary = colors.blue;
colors.success = colors.green;
colors.danger = colors.rose;
colors.error = colors.rose;
colors.warning = colors.yellow;
colors.info = colors.gray;

export type ColorScheme = keyof typeof colors;

export default colors;
