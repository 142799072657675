import { Box, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";
import FormControl from "../form/FormControl";
import ModalContent from "../molecules/ModalContent";
import SubmitButton from "../form/SubmitButton";
import FormError from "../form/FormError";

interface Props {
    mode: "invite" | "editInvite" | "editRepresentative";
    onSubmitPress: () => void;
    onClose: () => void;
    isSuccess: boolean;
}

const RepresentativeModalForm = ({
    mode,
    onSubmitPress,
    onClose,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={
                mode === "invite"
                    ? t("inviteNewAuthorizedPerson")
                    : t("editAuthorizedPerson")
            }
            footer={
                <>
                    <Button variant="outlined" onPress={onClose}>
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmitPress}
                        label={mode === "invite" ? t("sendInvite") : t("save")}
                    />
                </>
            }
        >
            <VStack space={3} testID="modal-form">
                <Box>
                    <FormControl
                        isDisabled={mode !== "invite"}
                        name={
                            mode === "editRepresentative" ? "fullName" : "email"
                        }
                        isRequired
                        label={
                            mode === "editRepresentative" ? t("name") : "Email"
                        }
                    />
                </Box>
                <Box>
                    <FormControl
                        name="note"
                        label={t("comment")}
                        hint={t("max20characters") + "."}
                        maxLength={20}
                    />
                </Box>
                <FormError />
            </VStack>
        </ModalContent>
    );
};

export default RepresentativeModalForm;
