import { Box } from "native-base";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { formatVerboseMonthDay } from "@madmedical/utils";
import InsulinMeasurementsTable from "../../organisms/InsulinMeasurementsTable";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import type InsulinMeasurementListItem from "../../molecules/ListItem/InsulinMeasurementListItem";
import type { InsulinAdministration } from "../../providers/insulinAdministration";
import InsulinMeasurementContentMobile from "./InsulinMeasurementContentMobile";

export type MeasurementData = Omit<
    ComponentProps<typeof InsulinMeasurementListItem>,
    "onDeletePress"
> & {
    id: Ulid;
    editable?: boolean;
} & InsulinAdministration;

type HeaderType = {
    title: string;
    badge: number;
};

export type SectionType = {
    header?: HeaderType;
    data: MeasurementData[];
};

type SectionGroupType = Record<string, MeasurementData[]>;

interface Props {
    data: MeasurementData[];
    onEndReached?: () => void | undefined;
    onDeletePress?: (id: Ulid) => void | undefined;
    onEditPress?: (measurement: InsulinAdministration) => void | undefined;
}

type DesktopLayoutProps = {
    sections: SectionType[];
    onEndReached?: Props["onEndReached"];
    onDeletePress?: Props["onDeletePress"];
    onEditPress?: Props["onEditPress"];
};

const DesktopLayout = ({
    onEndReached,
    sections,
    onDeletePress,
    onEditPress,
}: DesktopLayoutProps) => {
    const lastIndex = sections.length - 1;

    if (!sections.length) {
        return null;
    }

    return (
        <>
            {sections.map((section, index) => (
                <Box
                    width="full"
                    px={6}
                    py={4}
                    key={`measurement-table-section-${index}`}
                >
                    {section.header && (
                        <ListHeader
                            title={section.header.title}
                            badge={section.header.badge}
                            variant="inverted"
                            size="md"
                            px={0}
                            pt={0}
                            pb={0}
                        />
                    )}

                    <InsulinMeasurementsTable
                        data={section?.data}
                        onEndReached={
                            index === lastIndex ? onEndReached : undefined
                        }
                        onDeletePress={onDeletePress}
                        onEditPress={onEditPress}
                    />
                </Box>
            ))}
        </>
    );
};

const InsulinMeasurementList = ({
    data,
    onEndReached,
    onDeletePress,
    onEditPress,
}: Props) => {
    const { isSmallScreen } = useResponsive();

    const sections = useMemo(() => {
        const groups = data.reduce<SectionGroupType>((prev, measurement) => {
            const date = measurement.administeredAt
                ? formatVerboseMonthDay(measurement.administeredAt)
                : "";
            if (!prev[date]) {
                prev[date] = [];
            }
            prev[date].push(measurement);

            return prev;
        }, {});

        return Object.keys(groups).map<SectionType>((date) => ({
            header: {
                title: date,
                badge: groups[date].length,
            },
            data: groups[date],
        }));
    }, [data]);

    return (
        <Paper
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            width="full"
            borderWidth={0}
        >
            {isSmallScreen ? (
                <InsulinMeasurementContentMobile
                    sections={sections}
                    onEndReached={onEndReached}
                    onDeletePress={onDeletePress}
                    onEditPress={onEditPress}
                />
            ) : (
                <DesktopLayout
                    sections={sections}
                    onEndReached={onEndReached}
                    onDeletePress={onDeletePress}
                    onEditPress={onEditPress}
                />
            )}
        </Paper>
    );
};

export default InsulinMeasurementList;
