import { PregnancyProvider, Spinner } from "@madmedical/ui";
import type { ReactNode } from "react";
import type { Ulid } from "@madmedical/utils";
import { rangeToString } from "@madmedical/utils";
import type { PregnancyWellBeing } from "@madmedical/medical";
import { MetricType, UnderlyingCondition } from "@madmedical/medical";
import type { PatientMe } from "@madmedical/user";
import { usePatient } from "@madmedical/user";
import { useEffect, useState } from "react";
import { useGetMeasurementRangeQuery } from "../../measurement/api";
import {
    useGetPregnancyByPatientQuery,
    useGetPregnancyWellBeingsQuery,
} from "../api";

enum ModalState {
    Closed,
    Measure,
    Feeling,
    Edit,
    Delete,
}

interface Props {
    children: ReactNode;
    setModal: React.Dispatch<React.SetStateAction<ModalState>>;
}

const PregnancyProviderWrapper = ({ children, setModal }: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const [pregnancyWellBeingData, setPregnancyWellBeingData] = useState<
        PregnancyWellBeing | undefined
    >(undefined);
    const [deletePregncancyWellBeingId, setDeletePregncancyWellBeingId] =
        useState<Ulid>();

    // console.log("patient", patient);

    const typedPatient = patient as PatientMe;
    const isPregnant = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Pregnancy
    );

    const { data: pregnancyData, isLoading: isPregnancyLoading } =
        useGetPregnancyByPatientQuery(
            {
                patientId,
            },
            {
                skip: !isPregnant,
            }
        );

    const pregnancyStartDate =
        pregnancyData?.startedAt && new Date(pregnancyData?.startedAt);
    const pregnancyEndDate =
        pregnancyData?.expectedAt && new Date(pregnancyData?.expectedAt);
    const prePregnancyStart =
        pregnancyData?.startedAt &&
        pregnancyStartDate &&
        new Date(pregnancyStartDate.getTime() - 60 * 24 * 3600 * 1000); // 60 days before pregnancy start

    const dateRange = {
        from: (pregnancyStartDate ?? new Date()) as Date,
        to: (pregnancyEndDate ?? new Date()) as Date,
    };

    const prePregnancyRange = {
        from: (prePregnancyStart ?? new Date()) as Date,
        to: (pregnancyStartDate ?? new Date()) as Date,
    };

    const { data: weightData, isLoading: isWeightLoading } =
        useGetMeasurementRangeQuery({
            userId: patientId,
            metricType: MetricType.BodyWeight,
            queryRange: rangeToString(dateRange),
        });

    const {
        data: bmiData,
        isLoading: isBmiLoading,
        refetch: refetchBMI,
    } = useGetMeasurementRangeQuery({
        userId: patientId,
        metricType: MetricType.BodyMassIndex,
        queryRange: rangeToString(dateRange),
    });

    // previous pregnancy data
    const { data: previousWeightData } = useGetMeasurementRangeQuery(
        {
            userId: patientId,
            metricType: MetricType.BodyMassIndex,
            queryRange: rangeToString(prePregnancyRange),
        },
        {
            skip: !isPregnant,
        }
    );

    const { data: previousBmiData } = useGetMeasurementRangeQuery(
        {
            userId: patientId,
            metricType: MetricType.BodyMassIndex,
            queryRange: rangeToString(prePregnancyRange),
        },
        {
            skip: !isPregnant,
        }
    );

    // well beings

    const [wellBeingFilters, setWellBeingFilters] = useState<{
        status?: PregnancyWellBeing["status"];
        symptoms?: PregnancyWellBeing["symptoms"];
    }>();

    const adjustWellBeingFilters = (filters: {
        status?: PregnancyWellBeing["status"];
        symptoms?: PregnancyWellBeing["symptoms"];
    }) => {
        setWellBeingFilters((prevState) => ({
            ...prevState,
            ...filters,
        }));
    };

    const {
        data: wellBeingData,
        isLoading: isWellbeingLoading,
        isError: isWellbeingError,
        refetch: refetchWellBeings,
    } = useGetPregnancyWellBeingsQuery({
        patientId,
        status: wellBeingFilters?.status,
        symptoms: wellBeingFilters?.symptoms,
    });

    useEffect(() => {
        if (wellBeingFilters && !isWellbeingError) {
            void refetchWellBeings();
        }
    }, [wellBeingFilters, refetchWellBeings, isWellbeingError]);

    const onEditPress = (mood: PregnancyWellBeing) => {
        setPregnancyWellBeingData(mood);
        setModal(ModalState.Edit);
    };

    const onDeletePress = (id: Ulid) => {
        setDeletePregncancyWellBeingId(id);
        setModal(ModalState.Delete);
    };

    const { data: circumferenceData, isLoading: isCircumferenceLoading } =
        useGetMeasurementRangeQuery({
            userId: patientId,
            metricType: MetricType.AbdominalCircumference,
            queryRange: rangeToString(dateRange),
        });

    const refetchBmiWrapper = () => {
        void refetchBMI();
    };

    const previousBmi = previousBmiData?.items.length
        ? previousBmiData.items[previousBmiData.items.length - 1]
        : undefined;
    const firstMeasuredBmi = previousBmi
        ? +previousBmi?.metric.displayValue
        : bmiData?.items?.[0]?.metric.displayValue
        ? +bmiData?.items?.[0]?.metric.displayValue
        : 0;

    const previousWeight = previousWeightData?.items.length
        ? previousWeightData.items[previousWeightData.items.length - 1]
        : undefined;
    const firstMeasuredWeight = previousWeight
        ? +previousWeight?.metric.displayValue
        : weightData?.items?.[0]?.metric.displayValue
        ? +weightData?.items?.[0]?.metric.displayValue
        : 0;

    if (
        isWeightLoading ||
        isBmiLoading ||
        isPatientLoading ||
        isPregnancyLoading ||
        isWellbeingLoading ||
        isCircumferenceLoading
    ) {
        return <Spinner />;
    }

    return (
        <PregnancyProvider
            value={{
                selectedRange: dateRange,
                weightData,
                bmiData,
                circumferenceData,
                pregnancyData,
                pregnancyWellBeing: wellBeingData?.items ?? [],
                onEditPress,
                onDeletePress,
                pregnancyWellBeingData,
                deletePregncancyWellBeingId,
                setModal,
                adjustWellBeingFilters,
                patient: typedPatient,
                refetchBMI: refetchBmiWrapper,
                firstMeasuredBmi,
                firstMeasuredWeight,
            }}
        >
            {children}
        </PregnancyProvider>
    );
};

export default PregnancyProviderWrapper;
