import { MetricType, forHumans } from "@madmedical/medical";
import useResponsive from "../../util/useResponsive";
import DailyStepsMobile from "./Mobile/DailyStepsMobile";
import HeartRateMobile from "./Mobile/HeartRateMobile";
import BloodOxygen from "./Desktop/BloodOxygen";
import BloodPressure from "./Desktop/BloodPressure";
import BloodSugar from "./Desktop/BloodSugar";
import BodyTemperature from "./Desktop/BodyTemperature";
import BodyWeight from "./Desktop/BodyWeight";
import DailySteps from "./Desktop/DailySteps";
import HeartRate from "./Desktop/HeartRate";
import BodyMassIndexMobile from "./Mobile/BodyMassIndexMobile";
import BodyMassIndex from "./Desktop/BodyMassIndex";
import type { Props as BaseProps } from "./model";

interface Props extends BaseProps {
    metricType: MetricType;
}

const MeasurementProtocolModalForm = ({ metricType, ...restProps }: Props) => {
    const { isSmallScreen } = useResponsive();

    switch (metricType) {
        case MetricType.BloodOxygen:
            return <BloodOxygen {...restProps} />;
        case MetricType.BloodPressure:
            return <BloodPressure {...restProps} />;
        case MetricType.BloodSugar:
            return <BloodSugar {...restProps} />;
        case MetricType.BodyMassIndex:
            return isSmallScreen ? (
                <BodyMassIndexMobile {...restProps} />
            ) : (
                <BodyMassIndex {...restProps} />
            );
        case MetricType.BodyTemperature:
            return <BodyTemperature {...restProps} />;
        case MetricType.BodyWeight:
            return <BodyWeight {...restProps} />;
        case MetricType.DailySteps:
            return isSmallScreen ? (
                <DailyStepsMobile {...restProps} />
            ) : (
                <DailySteps {...restProps} />
            );
        case MetricType.RestingHeartRate:
            return isSmallScreen ? (
                <HeartRateMobile {...restProps} />
            ) : (
                <HeartRate {...restProps} />
            );
        default:
            throw `Threshold edit not supported for ${forHumans(metricType)}`;
    }
};

export default MeasurementProtocolModalForm;
