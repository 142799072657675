import { useTranslation } from "@madmedical/i18n";
import { Box, Heading, Stack } from "native-base";
import Paper from "../../../atoms/Paper";
import useResponsive from "../../../util/useResponsive";
import DateRangePicker from "../../../organisms/DateRangePicker";
import type { BodyChartDataList } from "../../../charts/InsulinBodyChart";
import InsulinBodyChart from "../../../charts/InsulinBodyChart";
import { useInsulinBodyChart } from "../../../providers/insulinBodyChartProvider";
import ListHeader from "../../..//molecules/ListHeader";

const InsulinBodyChartWrapper = () => {
    const { isSmallScreen } = useResponsive();

    const { administrations } = useInsulinBodyChart();

    const data = administrations?.map((admin) => ({
        insulinBodyLocation: {
            bodyPart: admin.bodyLocation.bodyPart,
            side: admin.bodyLocation.side,
        },
        units: admin.units ?? 0,
    }));
    const { t } = useTranslation();

    return (
        <Box width="full">
            {isSmallScreen && (
                <ListHeader
                    title={t("injectionLocation")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={4}
                />
            )}

            <Paper borderTopLeftRadius={0} borderTopRightRadius={0}>
                <Stack
                    alignItems={isSmallScreen ? "flex-start" : "center"}
                    direction={isSmallScreen ? "column" : "row"}
                    justifyContent={isSmallScreen ? "flex-start" : "between"}
                    space={6}
                    w="full"
                    borderBottomWidth={isSmallScreen ? 0 : 1}
                    borderBottomColor={"gray.100"}
                    mb={4}
                    py={2}
                >
                    {!isSmallScreen && (
                        <Box flex="1" p={6}>
                            <Heading
                                size="xs"
                                fontWeight="medium"
                                numberOfLines={1}
                                textTransform="uppercase"
                                color={"black"}
                            >
                                {t("injectionLocation")}
                            </Heading>
                        </Box>
                    )}

                    <Box p={4}>
                        <DateRangePicker periods={["day", "week"]} key="body" />
                    </Box>
                </Stack>

                <Box
                    p={6}
                    width="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <InsulinBodyChart
                        insulinBody={data as BodyChartDataList[]}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

export default InsulinBodyChartWrapper;
