import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Box, Text, useTheme } from "native-base";
import { isWeb } from "@madmedical/utils";
import Icon from "../icons/Icon";

interface Props extends Pick<ComponentProps<typeof Box>, "ml"> {
    type: "seen" | "evaluation" | "document" | "comment";
    count: number;
}

const EvaluationStatuses = ({ type, count, ...rest }: Props) => {
    const { colors } = useTheme();
    const icons = useMemo(
        () =>
            ({
                seen: "view",
                evaluation: "task",
                document: "documentAttachment",
                comment: "chat",
            } as const),
        []
    );

    if (!count) {
        return null;
    }

    return (
        <Box flex={1} flexDirection="row" alignItems="center" {...rest}>
            <Box flex={isWeb ? "0 0 5px" : null}>
                <Icon name={icons[type]} size={20} fill={colors.green[500]} />
            </Box>
            {count > 1 && (
                <Text lineHeight={1} color="gray.500" fontSize="xs">
                    ({count})
                </Text>
            )}
        </Box>
    );
};

export default EvaluationStatuses;
