import { Box } from "native-base";
import { invariant } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../../providers/routing";
import Headline from "../../molecules/Headline";
import Breadcrumb from "../../molecules/Breadcrumb";
import Tabs from "../../molecules/Tabs";
import type { InsulinReminder } from "../../providers/insulinReminder";
import { useInsulinReminder } from "../../providers/insulinReminder";
import Button from "../../atoms/Button";
import InsulinReminderList from "./InsulinReminderList";

interface Props {
    isPatient?: boolean;
    deleteReminder: (id: string) => void;
    editReminder?: (reminder: InsulinReminder) => void | undefined;
}

const InsulinReminderContent = ({
    isPatient,
    deleteReminder,
    editReminder,
}: Props) => {
    const { reminders } = useInsulinReminder();

    const { t } = useTranslation();
    const navigate = useRouteNavigate();

    invariant(reminders);

    const reminderData = reminders?.map((item) => ({
        ...item,
        editable: isPatient,
    }));

    return (
        <Box width="full">
            <Headline
                minHeight={12}
                px={6}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>{t("reminders")}</Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
                rightContent={
                    <Button
                        variant="outlined"
                        leftIconName="add"
                        onPress={() => {
                            navigate("insulin", {});
                        }}
                    >
                        {t("newReminder")}
                    </Button>
                }
            />
            <Box
                backgroundColor="white"
                borderColor="gray.100"
                borderBottomWidth={1}
                flexDirection={"row"}
            >
                <Tabs
                    tabs={[
                        {
                            title: t("insulin"),
                            content: (
                                <InsulinReminderList
                                    data={reminderData}
                                    onEndReached={() => console.log("end")}
                                    onDeletePress={deleteReminder}
                                    onEditPress={editReminder}
                                />
                            ),
                        },
                    ]}
                    px={4}
                    borderBottomColor={"gray.100"}
                    borderBottomWidth={1}
                />
            </Box>
        </Box>
    );
};

export default InsulinReminderContent;
