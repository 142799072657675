import { useParams } from "@madmedical/routing";
import { invariant } from "@madmedical/utils";
import {
    DoctorEvaluationDetails,
    EvaluationDetails,
    Spinner,
    useShowToast,
} from "@madmedical/ui";
import { useMe, usePatientId, useRouteNavigate } from "@madmedical/user";
import { useDateRange } from "@madmedical/store";
import { useEffect, useState } from "react";
import { EditThresholdModal } from "@madmedical/threshold";
import { useTranslation } from "@madmedical/i18n";
import { useGetOpinionQuery } from "../api";
import adaptOpinion from "../adaptOpinion";
import MeasurementProviders from "../../measurement/components/MeasurementProviders";
import DeleteOpinionDialog from "./DeleteOpinionDialog";

enum ModalState {
    Closed,
    Threshold,
    Delete,
}

const Opinion = () => {
    const { isSelfOrRepresented } = usePatientId();
    const {
        roles: { isDoctor, isAdmin },
    } = useMe();
    const navigate = useRouteNavigate();
    const { opinionId } = useParams<"opinionId">();
    invariant(opinionId);
    const { data: opinion, isLoading } = useGetOpinionQuery(opinionId);
    const { setDateRange } = useDateRange();
    const [modal, setModal] = useState<ModalState>(ModalState.Closed);
    const showToast = useShowToast();
    const { t } = useTranslation();
    useEffect(() => {
        if (!opinion) {
            return;
        }

        setDateRange(opinion.extendedRange);
    }, [opinion, setDateRange]);

    const handleDeletePress = () => {
        setModal(ModalState.Delete);
    };

    const handleEditThresholdPress = () => {
        setModal(ModalState.Threshold);
    };

    const handleModalClose = () => {
        setModal(ModalState.Closed);
    };

    // It could have been deleted
    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!opinion) {
            showToast(t("resultDeletedByThem"), { variant: "error" });
            navigate("opinions", {});
        }
    }, [isLoading, opinion, showToast, navigate, t]);

    if (!opinion) {
        return <Spinner />;
    }

    const evaluation = adaptOpinion(opinion);

    return (
        <MeasurementProviders
            metricType={opinion.metricType}
            statsRange={opinion.dateRange}
        >
            {isSelfOrRepresented ? (
                <EvaluationDetails evaluation={evaluation} />
            ) : (
                <>
                    <DoctorEvaluationDetails
                        evaluation={evaluation}
                        onEditThresholdPress={handleEditThresholdPress}
                        onDeletePress={handleDeletePress}
                        canEdit={isDoctor || isAdmin}
                    />
                    <EditThresholdModal
                        metricType={opinion.metricType}
                        open={modal === ModalState.Threshold}
                        onClose={handleModalClose}
                    />
                    <DeleteOpinionDialog
                        id={opinionId}
                        open={modal === ModalState.Delete}
                        onClose={handleModalClose}
                    />
                </>
            )}
        </MeasurementProviders>
    );
};

export default Opinion;
