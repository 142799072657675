import { Box, Divider, HStack, Text, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import FormControl from "../../../form/FormControl";
import MeasurementProtocolModalFooter from "../../../molecules/MeasurementProtocolModalFooter";
import Bar from "../../../atoms/Bar";
import MeasurementProtocolsOffsetSettingsTitle, {
    AlertStatusLegend,
} from "../../../molecules/MeasurementProtocolsOffsetSettingsTitle";
import ModalContent from "../../../molecules/ModalContent";
import ProtocolSection from "../ProtocolSection";
import type { Props } from "../model";

const DailyStepsMobile = ({
    protocols,
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("measures:dailyStepsSetup")}
            footer={
                <MeasurementProtocolModalFooter
                    onClose={onClose}
                    onSubmitPress={onSubmitPress}
                />
            }
        >
            <VStack space={4} p={4}>
                <ProtocolSection protocols={protocols} />
                <VStack mt={6} space={4}>
                    <MeasurementProtocolsOffsetSettingsTitle
                        hasLegend={false}
                    />
                    <HStack space={0.5}>
                        <VStack space={1} alignItems="stretch" height={32}>
                            <Bar
                                data={[{ color: "green.200", value: 1 }]}
                                flex={1}
                                width={1}
                                height="full"
                            />
                            <Bar
                                data={[{ color: "warning.200", value: 1 }]}
                                flex={1}
                                width={1}
                                height="full"
                            />
                            <Bar
                                data={[{ color: "error.200", value: 1 }]}
                                flex={1}
                                width={1}
                                height="full"
                            />
                        </VStack>
                        <VStack height={32}>
                            <Box flex={1} />
                            <Divider width={3} />
                            <Box flex={1} />
                            <Divider width={3} />
                            <Box flex={1} />
                        </VStack>
                        <VStack ml={1} height={32}>
                            <Box flex={3} />
                            <FormControl
                                name="threshold.warningBelow.steps"
                                maxWidth={40}
                                suffix={
                                    <Text px={2}>{t("measures:steps")}</Text>
                                }
                            />
                            <Box flex={1} />
                            <FormControl
                                name="threshold.alertBelow.steps"
                                maxWidth={40}
                                suffix={
                                    <Text px={2}>{t("measures:steps")}</Text>
                                }
                            />
                            <Box flex={3} />
                        </VStack>
                    </HStack>
                </VStack>
                <AlertStatusLegend justifyContent="flex-start" />
            </VStack>
        </ModalContent>
    );
};

export default DailyStepsMobile;
