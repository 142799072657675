import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Ulid } from "@madmedical/utils";
import { removeRefreshToken, setRefreshToken } from "./refreshTokenStorage";
import jwtDecode from "./jwtDecode";

interface TokenResponse {
    readonly token: string;
    readonly refreshToken: string;
}

export interface State {
    isLoading: boolean;
    userId: Ulid | null;
    token: string | null;
}

const initialState: State = {
    isLoading: true,
    userId: null,
    token: null,
};

const slice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        updateTokens(
            state,
            { payload: { token, refreshToken } }: PayloadAction<TokenResponse>
        ) {
            const { username } = jwtDecode(token);

            state.userId = username;
            state.token = token;
            state.isLoading = false;

            void setRefreshToken(refreshToken);
        },
        invalidateTokens(state) {
            state.userId = null;
            state.token = null;
            state.isLoading = false;

            void removeRefreshToken();
        },
    },
});

export const { updateTokens, invalidateTokens } = slice.actions;

export const authReducer = slice.reducer;
