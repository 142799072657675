import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import { useEnsureContext } from "@madmedical/utils";
import type { PatientMe } from "./model";

interface ContextValue {
    representedPatient: PatientMe | null;
    setRepresentedPatient: (patient: PatientMe) => void;
}

const RepresentedPatientContext = createContext<ContextValue | undefined>(
    undefined
);

export const RepresentedPatientProvider =
    RepresentedPatientContext.Provider as ReactProvider<ContextValue>;

export const useRepresentedPatient = () =>
    useEnsureContext(RepresentedPatientContext);
