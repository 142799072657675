import type { MetricTypeManualAllowed } from "@madmedical/medical";
import { MealSituation, MetricType, forHumans } from "@madmedical/medical";
import { Box, HStack, Text, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import FormRadioControl from "../form/FormRadioControl";
import DateTimeInput from "../form/DateTimeInput";
import Button from "../atoms/Button";
import FormControl from "../form/FormControl";
import ModalContent from "../molecules/ModalContent";

interface UnitProps {
    unit: string;
}

const Unit = ({ unit }: UnitProps) => (
    <Text fontSize="md" color="gray.600" pr={3}>
        {unit}
    </Text>
);

interface Props {
    metricType: MetricTypeManualAllowed;
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const ManualDataRecordModalForm = ({
    metricType,
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();
    const renderForm = () => {
        switch (metricType) {
            case MetricType.BodyWeight:
                return (
                    <FormControl
                        isRequired
                        label={t("measures:measuredValue")}
                        name="metric.kg"
                        type="number"
                        step={0.1}
                        InputRightElement={<Unit unit="kg" />}
                        mb={6}
                    />
                );
            case MetricType.BodyHeight:
                return (
                    <FormControl
                        isRequired
                        label={t("measures:measuredValue")}
                        name="metric.cm"
                        type="number"
                        InputRightElement={<Unit unit="cm" />}
                        mb={6}
                    />
                );
            case MetricType.BodyTemperature:
                return (
                    <FormControl
                        isRequired
                        label={t("measures:measuredValue")}
                        name="metric.celsius"
                        type="number"
                        step={0.1}
                        InputRightElement={<Unit unit="°C" />}
                        mb={6}
                    />
                );
            case MetricType.BloodPressure:
                return (
                    <HStack space={2}>
                        <Box flex="1">
                            <FormControl
                                isRequired
                                label="Szisztolés"
                                name="metric.systolic"
                                type="number"
                                InputRightElement={<Unit unit="Hgmm" />}
                                mb={6}
                            />
                        </Box>
                        <Box flex="1">
                            <FormControl
                                isRequired
                                label="Diasztolés"
                                name="metric.diastolic"
                                type="number"
                                InputRightElement={<Unit unit="Hgmm" />}
                                mb={6}
                            />
                        </Box>
                    </HStack>
                );
            case MetricType.BloodSugar:
                return (
                    <>
                        <FormControl
                            isRequired
                            label={t("measures:measuredValue")}
                            name="metric.mmolPerL"
                            type="number"
                            step={0.1}
                            InputRightElement={<Unit unit="mmol/l" />}
                            mb={6}
                        />
                        <FormRadioControl
                            isRequired
                            name="metric.mealSituation"
                            size="sm"
                            space={3}
                            choices={Object.values(MealSituation).map(
                                (mealSituation) => ({
                                    value: mealSituation,
                                    label: forHumans(mealSituation),
                                })
                            )}
                        />
                    </>
                );
            case MetricType.BloodOxygen:
                return (
                    <FormControl
                        isRequired
                        name="metric.spO2"
                        type="number"
                        label={t("measures:measuredValue")}
                        InputRightElement={<Unit unit="%" />}
                        mb={6}
                    />
                );
            case MetricType.AbdominalCircumference:
                return (
                    <FormControl
                        isRequired
                        label={t("measures:measuredValue")}
                        name="metric.circumference"
                        type="number"
                        InputRightElement={<Unit unit="cm" />}
                        mb={6}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <ModalContent
            header={t("measures:addManualMeasurement2")}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton onPress={onSubmitPress} label={t("save")} />
                </>
            }
        >
            <VStack overflowY="clip">
                <DateTimeInput
                    name="measuredAt"
                    isRequired
                    label={t("appointment")}
                />
                {renderForm()}
            </VStack>
        </ModalContent>
    );
};

export default ManualDataRecordModalForm;
