import { Fragment } from "react";
import { Text } from "native-base";
import type { GraphData } from "./types";
import { boxShadow, tag, tagThreshold, tagYaxis } from "./useStyles";

export default function Tags({ graphData = {} }: { graphData: GraphData }) {
    const {
        randomIdFragment = "",
        coords = [],
        limits,
        paddings = { x: 0, y: 0 },
        thresholds = [],
        slot,
    } = graphData;

    const yMax = limits?.yMax;
    const yMin = limits?.yMin;
    const yMaxTitle = limits?.yMaxTitle;
    const yMinTitle = limits?.yMinTitle;

    const minWidthThreshold =
        Math.max(0, ...thresholds.map(({ title }) => Number(title?.length))) *
        7;

    const transformStyle = [{ translateX: -3 }, { translateY: 10 }];

    return coords.length ? (
        <>
            {thresholds.length ? (
                thresholds.map(({ limit, title = "" }, i) => (
                    <Fragment
                        key={`tag-threshold-${
                            limit ?? ""
                        }-${i}-${randomIdFragment}`}
                    >
                        {!!title.length &&
                        limit &&
                        slot &&
                        limit < (slot?.y ?? yMax) ? (
                            <Text
                                style={[
                                    boxShadow,
                                    tag,
                                    tagThreshold,
                                    {
                                        bottom: limit,
                                        left: coords[0].x,
                                        transform: [
                                            { translateX: 0 },
                                            { translateY: 10 },
                                        ],
                                    },
                                ]}
                                minWidth={`${minWidthThreshold}px`}
                            >
                                {title}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </Fragment>
                ))
            ) : (
                <></>
            )}

            {yMaxTitle && yMax && (
                <Text
                    nativeID={
                        "tag-y-max" +
                        (randomIdFragment ? `-${randomIdFragment}` : "")
                    }
                    style={[
                        boxShadow,
                        tag,
                        tagYaxis,
                        {
                            bottom: yMax,
                            right: paddings.x,
                            transform: transformStyle,
                        },
                    ]}
                >
                    {yMaxTitle}
                </Text>
            )}

            {yMinTitle && yMaxTitle && yMax && yMin && (
                <Text
                    nativeID={
                        "tag-y-mid" +
                        (randomIdFragment ? `-${randomIdFragment}` : "")
                    }
                    style={[
                        boxShadow,
                        tag,
                        tagYaxis,
                        {
                            bottom: (yMin + yMax) / 2,
                            right: paddings.x,
                            transform: transformStyle,
                        },
                    ]}
                >
                    {(Number(yMinTitle) + Number(yMaxTitle)) / 2}
                </Text>
            )}

            {yMinTitle && yMin && (
                <Text
                    nativeID={
                        "tag-y-min" +
                        (randomIdFragment ? `-${randomIdFragment}` : "")
                    }
                    style={[
                        boxShadow,
                        tag,
                        tagYaxis,
                        {
                            bottom: yMin,
                            right: paddings.x,
                            transform: transformStyle,
                        },
                    ]}
                >
                    {yMinTitle}
                </Text>
            )}
        </>
    ) : (
        <></>
    );
}
