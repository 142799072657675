import type { ReactNode } from "react";
import { Breadcrumb as BaseBreadcrumb, Box, Text } from "native-base";
import BreadcrumbLink from "../atoms/BreadcrumbLink";

interface Props {
    children: ReactNode;
    separator?: string | JSX.Element;
}

const Breadcrumb = ({ children, separator }: Props) => (
    <Box maxWidth={"full"}>
        <BaseBreadcrumb
            flexWrap={"wrap"}
            separator={separator ?? <Text color="gray.500">/</Text>}
        >
            {children}
        </BaseBreadcrumb>
    </Box>
);

Breadcrumb.Item = BaseBreadcrumb.Item;
Breadcrumb.Link = BreadcrumbLink;
Breadcrumb.Text = BaseBreadcrumb.Text; //TODO: wrap into <Text>
Breadcrumb.Icon = BaseBreadcrumb.Icon;
export default Breadcrumb;
