import { useState } from "react";
import { Box, HStack } from "native-base";
import { type Ulid, invariant, isWeb } from "@madmedical/utils";
import type { Pregnancy } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import Button from "../../atoms/Button";
import ButtonLink from "../../atoms/ButtonLink";
import { usePregnancyData } from "../../providers";
import PregnancyEditor from "./PregnancyEditor";

interface EditedPregnancyData extends Pregnancy {
    patientId: Ulid;
}
interface Props {
    uploadDocuments: () => void;
    handleDeletePress: (id: Ulid) => void;
    triggerEdit: (editData: EditedPregnancyData) => void;
}

const PregnancyDoctorEdit = ({
    uploadDocuments,
    handleDeletePress,
    triggerEdit,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { patient, pregnancyData } = usePregnancyData();

    const [editedPregnancyData, setEditedPregnancyData] = useState({
        ...pregnancyData,
        patientId: patient.userId,
        genderMethod: pregnancyData.genderMethod ?? "genetic",
    });

    const editPregnancyData = (
        key: string,
        value: unknown,
        infant: number | undefined
    ) => {
        setEditedPregnancyData((prevState) => {
            const updatedInfants = prevState.infants.map((item, index) => {
                if (infant !== undefined && infant === index) {
                    return {
                        ...item,
                        [key]: value,
                    };
                }

                return item;
            });

            const changedValue = !infant && {
                [key]: value,
            };

            return {
                ...prevState,
                ...changedValue,
                infants: updatedInfants,
            };
        });
    };

    invariant(editPregnancyData);
    const { t } = useTranslation();

    return (
        <Box
            flex={{
                base: isWeb ? "initial" : 1,
                md: 1,
            }}
        >
            {isSmallScreen && (
                <ListHeader
                    title={t("pregnancy:informationConnectedToPregnancy")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={4}
                />
            )}
            <Paper borderRadius="lg" flex={isWeb ? 1 : 0}>
                {!isSmallScreen && (
                    <Headline
                        title={t("pregnancy:informationConnectedToPregnancy")}
                        size="xs"
                    />
                )}
                <Box width={"100%"}>
                    <PregnancyEditor
                        pregnancyData={editedPregnancyData}
                        editPregnancyData={editPregnancyData}
                        uploadDocuments={uploadDocuments}
                        handleDeletePress={handleDeletePress}
                        hasPatientEdited={
                            pregnancyData?.createdBy?.role === "patient"
                        }
                    />
                    <HStack
                        justifyContent={"space-between"}
                        width={"full"}
                        borderTopWidth={1}
                        borderTopColor="gray.100"
                        borderTopStyle="solid"
                        mt={4}
                        p={6}
                    >
                        <ButtonLink
                            route="patient"
                            params={{ patientId: patient.userId }}
                            variant="outlined"
                        >
                            Vissza
                        </ButtonLink>
                        <Button
                            onPress={() => triggerEdit(editedPregnancyData)}
                        >
                            {t("save")}
                        </Button>
                    </HStack>
                </Box>
            </Paper>
        </Box>
    );
};

export default PregnancyDoctorEdit;
