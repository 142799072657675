import styled, { css } from "styled-components";

export default styled.table<{ scrollX?: boolean }>`
    ${({ scrollX }) =>
        scrollX
            ? css`
                  min-width: 100%;
              `
            : css`
                  width: 100%;
              `};
    border-spacing: 0;
    font-family: ${({ theme }) => theme.fonts.body};
    // table-layout: fixed;
`;

export const Thead = styled.thead``;

export const Tfoot = styled.tfoot``;

export const Tbody = styled.tbody``;

interface TrProps {
    hasOnHover?: boolean;
}

export const Tr = styled.tr`
    :hover {
        cursor: ${({ hasOnHover }: TrProps) =>
            hasOnHover ? "pointer" : "default"};
    }

    :last-child {
        td {
            border-bottom: 0;
        }
    }
`;

export const Th = styled.th`
    padding: ${({ theme }) => theme.space[2]}px
        ${({ theme }) => theme.space[4]}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    background: ${({ theme }) => theme.colors.gray[50]};
    color: ${({ theme }) => theme.colors.gray[600]};
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.sm}px;

    /*     [class*="css-text"] {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    } */

    :first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.gray[100]};
        border-top-left-radius: ${({ theme }) => theme.radii.md}px;
        border-bottom-left-radius: ${({ theme }) => theme.radii.md}px;
    }

    :last-child {
        border-right: 1px solid ${({ theme }) => theme.colors.gray[100]};
        border-top-right-radius: ${({ theme }) => theme.radii.md}px;
        border-bottom-right-radius: ${({ theme }) => theme.radii.md}px;
    }
`;

export const Td = styled.td`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
    padding: ${({ theme }) => theme.space[4]}px;
    font-size: ${({ theme }) => theme.fontSizes.sm}px;

    > [class*="css-view"] {
        width: 100%;
        white-space: nowrap;
    }
`;
