import type { ComponentProps, ReactNode } from "react";
import { Stack, Text } from "native-base";

interface Props extends ComponentProps<typeof Stack> {
    label: string;
    content?: ReactNode;
}

const HorizontalListItem = ({ label, content, ...rest }: Props) => (
    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgColor="white"
        p={4}
        minWidth="full"
        {...rest}
    >
        <Text color="gray.600" fontSize="xs">
            {label}
        </Text>
        <Text fontSize="xs" fontWeight="medium" textAlign="right">
            {content ?? "-"}
        </Text>
    </Stack>
);

export default HorizontalListItem;
