import { useCallback, useMemo, useState } from "react";
import useShowToast from "../../atoms/useShowToast";
import type { DocumentService, FileToUpload } from "./model";

const MAX_SIZE = 50 * 1024 * 1024;

const doConcat = (files: FileToUpload[], newFiles: File[]) =>
    files.concat(newFiles.map((file) => ({ file, note: null })));

export default () => {
    const [files, setFiles] = useState<FileToUpload[]>([]);
    const showToast = useShowToast();

    const handleDeletePress = useCallback((index: number) => {
        setFiles((previous) => previous.filter((_, i) => i !== index));
    }, []);

    const handleChangeNote = useCallback((index: number, note: string) => {
        setFiles((previous) => {
            const files = previous.slice();
            files[index] = { ...previous[index], note };

            return files;
        });
    }, []);

    const handleServiceChange = useCallback(
        (index: number, service: DocumentService) => {
            setFiles((previous) => {
                const files = previous.slice();
                files[index] = { ...previous[index], service };

                return files;
            });
        },
        []
    );

    const addFiles = useCallback(
        (newFiles: File[]) => {
            const totalSize = doConcat(files, newFiles).reduce(
                (carry, current) => carry + current.file.size,
                0
            );
            if (totalSize > MAX_SIZE) {
                showToast("Egyszerre legfeljebb 50MB tölthető fel.", {
                    variant: "error",
                });

                return;
            }

            setFiles((previous) => doConcat(previous, newFiles));
        },
        [files, showToast]
    );

    const resetFiles = useCallback(() => {
        setFiles([]);
    }, []);

    const formProps = useMemo(
        () => ({
            onDeletePress: handleDeletePress,
            onChangeNote: handleChangeNote,
            onChangeService: handleServiceChange,
        }),
        [handleChangeNote, handleDeletePress, handleServiceChange]
    );

    return { files, addFiles, resetFiles, formProps };
};
