import { Box, ScrollView } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import ConnectedDevicesList from "../organisms/ConnectedDevicesList";
import Headline from "../molecules/Headline";
import Badge from "../atoms/Badge";
import ButtonLink from "../atoms/ButtonLink";
import Paper from "../atoms/Paper";
import AppDownloadAlert from "../organisms/AppDownloadAlert";
import { useDevices } from "../providers/device";
import useResponsive from "../util/useResponsive";

interface Props {
    maxDeviceCount: number;
    showAppDownload: boolean;
    onDismissAppDownload: () => void;
}

const ConnectedDevices = ({
    maxDeviceCount,
    showAppDownload,
    onDismissAppDownload,
}: Props) => {
    const { devices } = useDevices();
    const deviceCount = devices.filter((device) => device.isConnected).length;
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return (
        <ScrollView>
            <Paper>
                <Headline
                    size="lg"
                    title={
                        !isSmallScreen ? t("connectedDevices") : t("devices")
                    }
                    info={t("howManyDevicesBasedOnSubscription") + "."}
                    accessory={
                        <Badge
                            variant="ghostSuccess"
                            size="xs"
                            hasIndicator={false}
                        >
                            {`${deviceCount}/${
                                maxDeviceCount === null ? "∞" : maxDeviceCount
                            }`}
                        </Badge>
                    }
                    rightContent={
                        <ButtonLink
                            variant="outlined"
                            size="md"
                            leftIconName="devices"
                            route="devices_all"
                            params={{}}
                        >
                            {t("allDevices")}
                        </ButtonLink>
                    }
                />
                {showAppDownload && (
                    <AppDownloadAlert
                        onDismiss={onDismissAppDownload}
                        px={{ base: 4, md: 6 }}
                        mt={{ base: 0, md: 4 }}
                        mb={{ base: 4, md: 0 }}
                        width="full"
                    />
                )}
                <ConnectedDevicesList />
            </Paper>
            <Box w={1} h={120} />
        </ScrollView>
    );
};

export default ConnectedDevices;
