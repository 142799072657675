import { Box, FlatList, ScrollView } from "native-base";
import { isWeb } from "@madmedical/utils";
import { useAppConfig } from "@madmedical/config";
import { useTranslation } from "@madmedical/i18n";
import Badge from "../atoms/Badge";
import Button from "../atoms/Button";
import Breadcrumb from "../molecules/Breadcrumb";
import Headline from "../molecules/Headline";
import IconButton from "../atoms/IconButton";
import ListHeader from "../molecules/ListHeader";
import Paper from "../atoms/Paper";
import type { RepresentativeData } from "../molecules/ListItem/RepresentativeListItem";
import RepresentativeListItem from "../molecules/ListItem/RepresentativeListItem";
import RepresentativesTable from "../organisms/RepresentativesTable";
import Alert from "../molecules/Alert";
import Tooltip from "../atoms/Tooltip";
import EmptyState from "../atoms/EmptyState";
import useResponsive from "../util/useResponsive";

interface Props {
    data: RepresentativeData[];
    maxRepresentatives: number;
    info?: string;
    onInvitePress: () => void;
    onResendPress: (item: RepresentativeData) => void;
    onEditPress: (item: RepresentativeData) => void;
    onDeletePress: (item: RepresentativeData) => void;
}

const Representatives = ({
    data,
    maxRepresentatives,
    info,
    onInvitePress,
    onResendPress,
    onEditPress,
    onDeletePress,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { onManageSubscriptionPress } = useAppConfig();
    const { t } = useTranslation();
    const representativeCount = data.length;
    const canAddRepresentative =
        maxRepresentatives === null || maxRepresentatives > representativeCount;

    return (
        <ScrollView>
            <Paper>
                {isWeb && (
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        leftContent={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {t("settings")}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {t("authorizedPersons")}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    />
                )}
                <Headline
                    borderBottomWidth={0}
                    title={t("authorizedPersons")}
                    info={info}
                    accessory={
                        <Badge variant="ghostSuccess">
                            {`${representativeCount}/${
                                maxRepresentatives === null
                                    ? "∞"
                                    : maxRepresentatives
                            }`}
                        </Badge>
                    }
                    rightContent={
                        <Tooltip
                            label={t("editSubscriptionForNewAuthPerson")}
                            variant="primary"
                            hasArrow
                            isRenderable={!canAddRepresentative}
                            placement="bottom right"
                        >
                            {isSmallScreen ? (
                                <IconButton
                                    iconName="add"
                                    variant="outlined"
                                    onPress={onInvitePress}
                                    isDisabled={!canAddRepresentative}
                                />
                            ) : (
                                <Button
                                    leftIconName="add"
                                    variant="outlined"
                                    onPress={onInvitePress}
                                    isDisabled={!canAddRepresentative}
                                >
                                    {t("inviteNew")}
                                </Button>
                            )}
                        </Tooltip>
                    }
                />

                {!canAddRepresentative && (
                    <Box px={6} mb={6} width="full">
                        <Alert
                            title={t("inviteNewAuthorizedPerson")}
                            description={t("editSubscriptionForNewAuthPerson")}
                            links={
                                onManageSubscriptionPress
                                    ? [
                                          {
                                              text: t("subscriptionSettings"),
                                              onPress:
                                                  onManageSubscriptionPress,
                                          },
                                      ]
                                    : []
                            }
                        />
                    </Box>
                )}

                {isSmallScreen ? (
                    data.length ? (
                        <>
                            <ListHeader title={t("authoziredPerson")} />
                            <FlatList
                                minWidth="full"
                                showsVerticalScrollIndicator={false}
                                data={data}
                                renderItem={({ item }) => (
                                    <RepresentativeListItem
                                        {...item}
                                        onResendPress={onResendPress}
                                        onEditPress={onEditPress}
                                        onDeletePress={onDeletePress}
                                    />
                                )}
                                keyExtractor={({ id }) => id}
                                overflow="hidden"
                            />
                        </>
                    ) : (
                        <EmptyState
                            title={t("noAuthorizedPersonsAktive")}
                            message={t("tryAgainOrClickOnAddNewAuthPerson")}
                            my={4}
                            mx={10}
                        />
                    )
                ) : (
                    <Box px={6} pb={1} width="full">
                        <RepresentativesTable
                            data={data}
                            onResendPress={onResendPress}
                            onEditPress={onEditPress}
                            onDeletePress={onDeletePress}
                        />
                    </Box>
                )}
            </Paper>
        </ScrollView>
    );
};

export default Representatives;
