import type {
    BloodSugar,
    BodyMassIndex,
    BodyWeight,
    MealSituation,
    Metric,
    MetricType,
} from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";

export enum ThresholdType {
    ValueRange = "value_range",
    BodyMassIndexCategory = "bmi_category",
    DeviationFromAverageRangePercentage = "deviation_average_percentage",
    DeviationFromEarlierValue = "deviation_earlier",
    PregnancyWeightGrowth = "pregnancy_weight",
    BloodSugar = "glucose",
}

interface BaseThreshold<TMetric extends Metric> {
    type: ThresholdType;
    metricType: TMetric["type"];
    warningForHumans: string;
    alertForHumans: string;
}

interface BloodSugarThreshold extends BaseThreshold<BloodSugar> {
    type: ThresholdType.BloodSugar;
    metricType: MetricType.BloodSugar;
    limitsByMealSitu: Partial<
        Record<
            MealSituation,
            { warningAbove: number | null; alertAbove: number | null }
        >
    >;
}

interface DeviationFromEarlierValue<TMetric extends Metric>
    extends BaseThreshold<TMetric> {
    type: ThresholdType.DeviationFromEarlierValue;
    alertBelow: number | null;
    warningBelow: number | null;
    warningAbove: number | null;
    alertAbove: number | null;
}

interface BodyMassIndexThreshold extends BaseThreshold<BodyMassIndex> {
    type: ThresholdType.BodyMassIndexCategory;
    metricType: MetricType.BodyMassIndex;
}

interface ValueRange<TMetric extends Metric> extends BaseThreshold<TMetric> {
    type: ThresholdType.ValueRange;
    metricType: TMetric["type"];
    alertBelow: TMetric;
    warningBelow: TMetric;
    warningAbove: TMetric;
    alertAbove: TMetric;
}

interface DeviationFromAverageRangePercentage<TMetric extends Metric>
    extends BaseThreshold<TMetric> {
    type: ThresholdType.DeviationFromAverageRangePercentage;
    alertBelow: number | null;
    warningBelow: number | null;
    warningAbove: number | null;
    alertAbove: number | null;
}

interface PregnancyWeightGrowth extends BaseThreshold<BodyWeight> {
    type: ThresholdType.PregnancyWeightGrowth;
    metricType: MetricType.BodyWeight;
}

// TODO: These generics do not feel to be sufficient
export type Threshold<TMetric extends Metric> =
    | BloodSugarThreshold
    | DeviationFromEarlierValue<TMetric>
    | BodyMassIndexThreshold
    | ValueRange<TMetric>
    | DeviationFromAverageRangePercentage<TMetric>
    | PregnancyWeightGrowth;

export interface ThresholdsProtocol<TMetric extends Metric> {
    metricType: TMetric["type"];
    protocol: string | null;
    thresholds: Threshold<TMetric>[];
}

export type ThresholdsProtocolResponse = ThresholdsProtocol<Metric>[];

export interface ThresholdForChart {
    warningThreshold: number;
    alertThreshold: number;
    title?: string | null;
}

export interface Protocol {
    id: Ulid;
    metricType: MetricType;
    instructions: string;
}

export interface PatientIdRequest {
    readonly patientId: Ulid;
}

export interface MetricTypeRequest {
    readonly metricType: MetricType;
}

export interface UpdateThresholdProtocolRequest {
    threshold: Threshold<Metric>;
    instructions: string;
}
