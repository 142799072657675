import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const Sesame = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <G clip-path="url(#clip0_2485_101098)">
            <Path
                d="M49.1042 61.1075C48.9735 61.169 48.8453 61.2826 48.7229 61.4641C48.2318 62.1937 48.9957 63.1188 48.9887 63.8941C48.9817 64.6803 48.8859 65.3845 49.0813 66.1567C49.266 66.887 49.5629 67.5963 50.0235 68.1966C50.6669 69.0353 51.6643 69.6432 52.7214 69.6445C54.8202 69.647 55.9483 67.3984 55.345 65.6517C54.7993 64.0712 53.1147 62.4951 51.4448 62.0383C51.0317 61.9253 50.6574 61.8498 50.3287 61.5637C49.9474 61.2312 49.5115 60.9171 49.1042 61.1075Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M55.3443 65.6517C54.798 64.0712 53.1141 62.4951 51.4441 62.0383C51.0311 61.9253 50.6567 61.8498 50.328 61.5637C49.9467 61.2312 49.5108 60.9171 49.1035 61.1075C49.0483 61.1335 48.9944 61.1709 48.9404 61.216C49.8687 61.1081 50.4886 61.9653 50.8045 62.7121C51.2347 63.7292 51.7442 64.6441 51.8908 65.7551C52.0481 66.9485 51.8698 68.2086 51.3197 69.2847C51.755 69.5087 52.2302 69.6438 52.7207 69.6445C54.8195 69.647 55.947 67.3984 55.3443 65.6517Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M55.3445 65.6514C54.9727 64.5766 54.0742 63.5043 53.0121 62.7842C54.0362 64.3761 54.7252 66.211 53.6244 68.0453C53.1638 68.7363 52.5845 69.1906 51.9487 69.5319C52.1994 69.603 52.4576 69.6442 52.7209 69.6448C54.8198 69.6474 55.9472 67.3988 55.3445 65.652V65.6514Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M55.1568 69.1986C55.2145 69.3312 55.3243 69.4625 55.5032 69.5894C56.2196 70.1002 57.1649 69.3623 57.9396 69.3908C58.7258 69.4194 59.4269 69.5342 60.2035 69.3604C60.9388 69.1954 61.6558 68.9188 62.2687 68.4746C63.1246 67.8541 63.7598 66.8745 63.7896 65.8174C63.8498 63.7192 61.6323 62.5302 59.8704 63.0853C58.2753 63.5878 56.6542 65.228 56.1517 66.8846C56.0273 67.2939 55.9417 67.6669 55.6466 67.9873C55.304 68.3598 54.9779 68.7862 55.1574 69.1992L55.1568 69.1986Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M59.8704 63.0853C58.2753 63.5878 56.6542 65.228 56.1517 66.8846C56.0273 67.2938 55.9417 67.6669 55.6466 67.9873C55.304 68.3598 54.9779 68.7862 55.1574 69.1992C55.1815 69.255 55.2177 69.3102 55.2615 69.3654C55.179 68.4346 56.0533 67.8389 56.8084 67.5432C57.8369 67.1409 58.7657 66.6568 59.8799 66.5407C61.0772 66.4164 62.3322 66.6289 63.3924 67.2082C63.6284 66.7793 63.7762 66.3079 63.7908 65.8174C63.8511 63.7192 61.6336 62.5302 59.8716 63.0853H59.8704Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M59.8705 63.085C58.7855 63.427 57.6891 64.2956 56.9404 65.3374C58.5596 64.3578 60.413 63.7188 62.2168 64.8691C62.895 65.3488 63.3335 65.9402 63.6564 66.5848C63.7338 66.3361 63.7827 66.0791 63.7903 65.8164C63.8506 63.7182 61.6331 62.5292 59.8711 63.0843L59.8705 63.085Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M68.2493 70.2575C68.1744 70.1338 68.0481 70.0183 67.8546 69.9155C67.0774 69.5031 66.2367 70.359 65.4652 70.4326C64.6822 70.5075 63.9722 70.4853 63.2248 70.76C62.518 71.0201 61.8435 71.3888 61.2941 71.9097C60.527 72.6368 60.0264 73.692 60.1349 74.7433C60.3506 76.8307 62.7045 77.719 64.3789 76.9373C65.8941 76.2299 67.2861 74.3912 67.5672 72.6831C67.6364 72.2606 67.6732 71.8805 67.9238 71.5239C68.215 71.1096 68.4821 70.6439 68.2499 70.2588L68.2493 70.2575Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M68.2493 70.2575C68.1744 70.1338 68.0481 70.0183 67.8546 69.9155C67.0774 69.5031 66.2367 70.359 65.4652 70.4326C64.6822 70.5075 63.9722 70.4853 63.2248 70.76C62.518 71.0201 61.8435 71.3888 61.2941 71.9097C60.527 72.6368 60.0264 73.692 60.1349 74.7433C60.1463 74.8562 60.1672 74.9635 60.1907 75.0694C61.6056 72.1013 66.0444 72.5492 68.2353 70.9954C68.3476 70.7397 68.3863 70.4853 68.2499 70.2581L68.2493 70.2575Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M67.8548 69.9155C67.0775 69.5031 66.2369 70.359 65.4653 70.4326C64.6824 70.5075 63.9724 70.4853 63.225 70.76C62.5182 71.0201 61.8437 71.3888 61.2942 71.9097C60.9256 72.2593 60.6211 72.6857 60.4136 73.1495C62.5321 71.0982 65.714 71.5595 68.3243 70.4732C68.3122 70.3996 68.2913 70.3273 68.2501 70.2588C68.1752 70.135 68.0489 70.0196 67.8554 69.9168L67.8548 69.9155Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M69.823 60.5727C69.9321 60.4775 70.0241 60.3335 70.092 60.1247C70.3642 59.2885 69.3763 58.6077 69.1707 57.8603C68.962 57.1021 68.8611 56.3991 68.462 55.71C68.0839 55.0584 67.6048 54.4575 66.997 54.0064C66.1481 53.3764 65.0225 53.0649 64.0054 53.3535C61.9859 53.9259 61.5176 56.3978 62.5766 57.9123C63.5346 59.2828 65.5859 60.3367 67.3174 60.3189C67.7451 60.3145 68.1264 60.2846 68.521 60.4699C68.9791 60.685 69.4842 60.8684 69.8236 60.5727H69.823Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M62.5764 57.9118C63.5345 59.2823 65.5864 60.3362 67.3173 60.3184C67.7455 60.314 68.1262 60.2842 68.5209 60.4694C68.979 60.6845 69.484 60.8679 69.8235 60.5722C69.8692 60.5323 69.911 60.4815 69.9504 60.4231C69.0875 60.7816 68.2563 60.1262 67.7481 59.4949C67.0552 58.6345 66.3148 57.8941 65.87 56.8662C65.3922 55.7616 65.2178 54.5009 65.4519 53.315C64.9716 53.2186 64.4773 53.2192 64.0059 53.3531C61.9863 53.9254 61.5181 56.3973 62.577 57.9118H62.5764Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M62.5766 57.9112C63.2282 58.8433 64.3861 59.6281 65.6049 60.0297C64.1843 58.7792 63.0182 57.2038 63.5746 55.1372C63.8284 54.346 64.2605 53.7509 64.7788 53.249C64.5187 53.249 64.2586 53.2801 64.0054 53.3518C61.9859 53.9241 61.5176 56.3961 62.5766 57.9106V57.9112Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M58.3828 52.8069C58.3993 52.6636 58.3701 52.4948 58.28 52.2949C57.9171 51.4936 56.7287 51.6566 56.0733 51.2429C55.4084 50.8236 54.8595 50.3718 54.1001 50.1326C53.3818 49.9061 52.6224 49.7855 51.8692 49.8617C50.8173 49.9683 49.7761 50.4968 49.2196 51.3959C48.1144 53.18 49.4341 55.3214 51.2373 55.7262C52.8685 56.0923 55.0949 55.4883 56.3614 54.3081C56.6742 54.0163 56.9356 53.7371 57.3518 53.6083C57.8353 53.4586 58.3314 53.253 58.3835 52.8063L58.3828 52.8069Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M51.2366 55.7267C52.8679 56.0928 55.0943 55.4887 56.3607 54.3086C56.6735 54.0167 56.9349 53.7376 57.3512 53.6088C57.8346 53.459 58.3308 53.2535 58.3828 52.8068C58.3898 52.7465 58.3866 52.6805 58.3765 52.6107C57.9806 53.4571 56.9254 53.5333 56.1234 53.4095C55.0321 53.2408 53.9859 53.1932 52.9643 52.7338C51.8667 52.2402 50.8883 51.4262 50.2621 50.3926C49.8427 50.6451 49.4779 50.9782 49.2196 51.3951C48.1144 53.1792 49.4341 55.3206 51.2373 55.7254L51.2366 55.7267Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M51.2368 55.7269C52.3465 55.9762 53.7309 55.7751 54.9022 55.2504C53.0095 55.2846 51.0864 54.9065 50.1042 53.0056C49.7584 52.2505 49.6766 51.519 49.721 50.7988C49.5288 50.9746 49.3581 51.1725 49.2191 51.3965C48.1139 53.1807 49.4336 55.3221 51.2368 55.7269Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M28.3994 51.8356C28.2795 51.9162 28.1704 52.0482 28.0771 52.2468C27.704 53.0437 28.6006 53.8406 28.7122 54.6077C28.8258 55.3856 28.8391 56.0962 29.1513 56.829C29.4463 57.5225 29.8479 58.1773 30.3955 58.7001C31.1601 59.4304 32.2387 59.8777 33.2837 59.7166C35.3578 59.3968 36.1274 57.0016 35.2633 55.3684C34.4809 53.8907 32.575 52.5919 30.8549 52.3965C30.4298 52.3483 30.0478 52.3312 29.6792 52.0983C29.2509 51.828 28.7725 51.5844 28.3994 51.8356Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M35.2634 55.3684C34.4811 53.8907 32.5751 52.5919 30.855 52.3965C30.4299 52.3483 30.0479 52.3312 29.6793 52.0983C29.251 51.828 28.7726 51.5844 28.3995 51.8356C28.3494 51.8693 28.3012 51.915 28.2549 51.9683C29.1552 51.7189 29.8995 52.4714 30.3265 53.1611C30.9077 54.1001 31.5517 54.9262 31.8664 56.001C32.2045 57.1564 32.2223 58.4286 31.8435 59.5763C32.308 59.7311 32.7984 59.7914 33.2832 59.7172C35.3573 59.3974 36.1269 57.0022 35.2627 55.3691L35.2634 55.3684Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M35.2634 55.3683C34.7311 54.3627 33.6791 53.4414 32.5186 52.8926C33.7749 54.3087 34.7374 56.0155 33.931 57.9976C33.582 58.7514 33.0795 59.2894 32.5034 59.7234C32.7617 59.7551 33.0237 59.7564 33.2838 59.7164C35.358 59.3967 36.1276 57.0015 35.2634 55.3683Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M36.8637 56.0585C36.9659 56.1607 37.1162 56.2425 37.3294 56.2958C38.1828 56.509 38.7925 55.4761 39.5241 55.2185C40.2658 54.9571 40.9606 54.8073 41.6198 54.3607C42.2435 53.9381 42.8088 53.4178 43.2168 52.7795C43.7859 51.8887 44.0175 50.7441 43.659 49.7499C42.9465 47.776 40.4479 47.4816 39.0114 48.644C37.7114 49.696 36.8034 51.8164 36.943 53.5416C36.9773 53.9679 37.0338 54.3461 36.8764 54.7528C36.6937 55.2248 36.5465 55.7413 36.865 56.0592L36.8637 56.0585Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M36.8635 56.0585C36.9657 56.1607 37.116 56.2425 37.3286 56.2958C37.6604 56.3783 37.9548 56.2724 38.234 56.0979C37.6744 53.9337 38.6756 50.9269 41.3614 50.6236C42.2516 50.5233 43.0929 50.7898 43.7864 51.2822C43.8663 50.767 43.834 50.2403 43.6569 49.7499C42.9444 47.776 40.4458 47.4816 39.0093 48.644C37.7093 49.6953 36.8013 51.8158 36.9409 53.5416C36.9752 53.9679 37.0317 54.3461 36.8749 54.7528C36.6922 55.2248 36.545 55.7413 36.8635 56.0592V56.0585Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M36.8634 56.0589C36.9655 56.161 37.1159 56.2429 37.3285 56.2962C37.3608 56.3044 37.3913 56.3025 37.423 56.3076C38.0251 53.7361 36.7879 49.1284 40.867 48.888C41.9488 48.8886 42.9316 49.2775 43.6987 49.8968C43.6841 49.8479 43.6746 49.7984 43.6574 49.7509C42.9449 47.777 40.4463 47.4826 39.0098 48.645C37.7098 49.6963 36.8018 51.8167 36.9414 53.5425C36.9757 53.9689 37.0322 54.3471 36.8754 54.7538C36.6927 55.2258 36.5455 55.7423 36.864 56.0602L36.8634 56.0589Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M32.4968 33.5952C32.401 33.7037 32.3286 33.8585 32.2887 34.0742C32.1288 34.939 33.1972 35.4841 33.4993 36.1985C33.8057 36.9224 33.998 37.6064 34.484 38.2371C34.944 38.8335 35.4985 39.3665 36.1603 39.7338C37.0847 40.2471 38.2408 40.4083 39.2115 39.9889C41.1384 39.1564 41.2787 36.6445 40.03 35.2817C38.9006 34.0489 36.7281 33.2729 35.0144 33.5178C34.5906 33.5781 34.2169 33.658 33.8013 33.526C33.3191 33.3725 32.7944 33.2577 32.4961 33.5946L32.4968 33.5952Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M32.4968 33.5952C32.401 33.7037 32.3286 33.8585 32.2887 34.0743C32.1288 34.9391 33.1972 35.4841 33.4993 36.1985C33.8057 36.9231 33.998 37.6064 34.484 38.2371C34.944 38.8335 35.4985 39.3665 36.1603 39.7339C37.0841 40.2471 38.2408 40.4083 39.2115 39.9889C39.3156 39.9439 39.412 39.8918 39.5059 39.8373C36.2497 39.3785 35.3443 35.0108 33.2042 33.3871C32.927 33.3567 32.6719 33.396 32.4968 33.5946V33.5952Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M32.2887 34.0738C32.1288 34.9386 33.1972 35.4836 33.4993 36.198C33.8057 36.9226 33.998 37.606 34.484 38.2366C34.944 38.8331 35.4985 39.366 36.1603 39.7334C36.6044 39.9802 37.1025 40.1426 37.6075 40.201C35.015 38.7956 34.4992 35.6226 32.6795 33.459C32.6129 33.4926 32.5501 33.5339 32.4968 33.5948C32.401 33.7033 32.3286 33.8581 32.2887 34.0738Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M51.5125 58.8477C51.5081 58.9924 51.5512 59.158 51.6578 59.3496C52.0861 60.118 53.2567 59.8559 53.9451 60.2131C54.6431 60.5754 55.2274 60.9796 56.004 61.1553C56.7388 61.3209 57.5052 61.378 58.2495 61.2391C59.2887 61.0449 60.2823 60.4314 60.762 59.4892C61.715 57.6187 60.2208 55.5954 58.3903 55.3422C56.7337 55.1132 54.5663 55.9012 53.4026 57.1828C53.1152 57.4994 52.8779 57.7996 52.4738 57.9626C52.0042 58.1523 51.5271 58.3985 51.5125 58.8477Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M51.5125 58.8477C51.5081 58.9924 51.5512 59.158 51.6578 59.3496C51.824 59.6478 52.1032 59.7906 52.4217 59.8743C53.6171 57.9861 56.4964 56.6594 58.554 58.4118C59.2361 58.9924 59.6168 59.7887 59.7322 60.6319C60.1624 60.3381 60.5247 59.9543 60.762 59.4892C61.715 57.6187 60.2208 55.5954 58.3903 55.3422C56.7343 55.1132 54.5663 55.9012 53.4026 57.1828C53.1152 57.4994 52.8779 57.7996 52.4738 57.9626C52.0042 58.1523 51.5271 58.3985 51.5125 58.8477Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M51.5124 58.8477C51.5079 58.9924 51.5511 59.158 51.6576 59.3496C51.6741 59.3788 51.6957 59.4004 51.7141 59.4264C54.0021 58.1066 56.5172 54.0523 59.4821 56.8637C60.2219 57.6536 60.6102 58.6364 60.6832 59.6192C60.7085 59.5755 60.7384 59.5342 60.7612 59.4892C61.7142 57.6187 60.22 55.5954 58.3895 55.3422C56.7335 55.1132 54.5655 55.9012 53.4018 57.1828C53.1144 57.4994 52.8771 57.7996 52.473 57.9626C52.0034 58.1523 51.5263 58.3985 51.5117 58.8477H51.5124Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M50.7521 51.7126C50.8961 51.7272 51.0643 51.6955 51.2629 51.6029C52.0591 51.2292 51.8802 50.0433 52.285 49.3822C52.6955 48.7115 53.1397 48.157 53.3687 47.3943C53.5857 46.6729 53.6961 45.9122 53.6092 45.1603C53.4886 44.1103 52.9455 43.0761 52.0395 42.5317C50.2407 41.4505 48.1171 42.7988 47.7364 44.6071C47.3919 46.2434 48.0264 48.4609 49.223 49.7115C49.5187 50.0205 49.8016 50.2781 49.9362 50.6924C50.0922 51.174 50.3042 51.6676 50.7521 51.7133V51.7126Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M47.7364 44.6061C47.3919 46.2424 48.0264 48.4599 49.223 49.7105C49.5187 50.0195 49.8016 50.2771 49.9362 50.6914C50.0922 51.173 50.3042 51.6666 50.7521 51.7123C50.8124 51.7186 50.8784 51.7142 50.9475 51.7028C50.096 51.3183 50.0053 50.2638 50.1183 49.4612C50.2724 48.3673 50.3054 47.321 50.7515 46.2932C51.2305 45.1885 52.0312 44.1994 53.0559 43.5598C52.7977 43.1436 52.4595 42.7832 52.0395 42.5307C50.2407 41.4495 48.1171 42.7978 47.7364 44.6061Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M47.7366 44.6061C47.5025 45.719 47.722 47.1009 48.2626 48.2645C48.2029 46.3725 48.5551 44.4443 50.4427 43.4374C51.1933 43.0814 51.9235 42.9894 52.6443 43.0243C52.466 42.8346 52.2655 42.6665 52.0403 42.5307C50.2415 41.4495 48.1179 42.7978 47.7372 44.6061H47.7366Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M38.8055 79.4246C38.8962 79.5376 39.0364 79.6353 39.242 79.7108C40.0675 80.0153 40.7857 79.0554 41.5407 78.879C42.3066 78.7 43.0134 78.6271 43.717 78.2546C44.3832 77.9025 45.0018 77.4469 45.4771 76.8569C46.1401 76.0333 46.4948 74.9211 46.2467 73.8932C45.7537 71.8533 43.3021 71.288 41.7469 72.2873C40.3403 73.1908 39.2065 75.1996 39.157 76.9305C39.1449 77.3581 39.1595 77.7401 38.959 78.1271C38.7262 78.5763 38.5238 79.0738 38.8049 79.4246H38.8055Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M41.7474 72.2883C40.3408 73.1918 39.207 75.2012 39.1575 76.9314C39.1454 77.3591 39.16 77.741 38.9595 78.1281C38.7267 78.5773 38.5243 79.0747 38.8053 79.4256C38.8434 79.4732 38.8923 79.517 38.9494 79.5582C38.6251 78.682 39.3123 77.8768 39.9633 77.3934C40.8503 76.7348 41.6186 76.0235 42.6636 75.6193C43.786 75.1847 45.0525 75.0604 46.2282 75.3408C46.343 74.8649 46.362 74.3707 46.2472 73.8942C45.7542 71.8543 43.3026 71.289 41.7474 72.2883Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M41.7475 72.2877C40.7907 72.9025 39.9608 74.0287 39.5122 75.231C40.8173 73.8599 42.4372 72.7572 44.4802 73.3936C45.2606 73.6778 45.8386 74.1334 46.3202 74.6708C46.3297 74.4106 46.3094 74.1499 46.2472 73.8942C45.7542 71.8543 43.3026 71.289 41.7475 72.2883V72.2877Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M46.1768 74.8683C46.272 74.9768 46.4166 75.0688 46.6254 75.1361C47.4629 75.4051 48.1399 74.4153 48.8867 74.2066C49.6443 73.9953 50.3473 73.8919 51.0344 73.4902C51.6848 73.1102 52.2837 72.6286 52.7329 72.0189C53.3604 71.168 53.6675 70.0412 53.3757 69.0254C52.7957 67.0084 50.3225 66.5484 48.8118 67.6124C47.4451 68.5755 46.3976 70.6306 46.4224 72.3615C46.4281 72.7891 46.4598 73.1705 46.2758 73.5657C46.062 74.0245 45.8811 74.5302 46.1774 74.869L46.1768 74.8683Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M46.1768 74.8683C46.272 74.9775 46.4166 75.0688 46.6254 75.1361C46.9509 75.2408 47.2516 75.1545 47.5422 74.9984C47.1273 72.8018 48.3264 69.868 51.0268 69.7443C51.9221 69.703 52.7431 70.0253 53.4029 70.5627C53.5171 70.0539 53.5197 69.526 53.3757 69.0254C52.7957 67.0084 50.3225 66.5484 48.8118 67.6124C47.4451 68.5755 46.3982 70.6306 46.4224 72.3615C46.4281 72.7891 46.4598 73.1705 46.2758 73.5651C46.062 74.0238 45.8811 74.5295 46.1774 74.8683H46.1768Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M46.1768 74.8683C46.272 74.9775 46.4166 75.0688 46.6254 75.1361C46.6571 75.1462 46.6876 75.1469 46.7193 75.1532C47.4915 72.6274 46.5626 67.9474 50.6486 67.9791C51.7285 68.0521 52.6828 68.5051 53.4074 69.1739C53.396 69.1244 53.3896 69.0742 53.3757 69.0254C52.7957 67.0084 50.3225 66.5484 48.8118 67.6124C47.4451 68.5755 46.3982 70.6306 46.4224 72.3615C46.4281 72.7891 46.4598 73.1705 46.2758 73.5651C46.062 74.0238 45.8811 74.5295 46.1774 74.8683H46.1768Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M59.5466 65.9779C59.4058 65.9443 59.2351 65.9538 59.0257 66.0198C58.1869 66.2856 58.2091 67.4848 57.7212 68.0876C57.2263 68.6986 56.7137 69.1903 56.3863 69.9162C56.0766 70.6027 55.8673 71.3425 55.8546 72.0994C55.8368 73.1565 56.2391 74.2528 57.0658 74.9114C58.7072 76.2197 60.9894 75.1608 61.6042 73.4179C62.1601 71.8412 61.8225 69.5589 60.7997 68.1624C60.5472 67.8173 60.3004 67.5248 60.2217 67.0965C60.1297 66.5991 59.9844 66.082 59.5466 65.9773V65.9779Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M61.6044 73.4184C62.1602 71.8417 61.8226 69.5595 60.7999 68.163C60.5467 67.8179 60.3005 67.5254 60.2218 67.0971C60.1298 66.5996 59.9845 66.0825 59.5467 65.9779C59.4877 65.9639 59.4218 65.9595 59.3513 65.9614C60.1451 66.4543 60.0968 67.5114 59.8792 68.2924C59.5829 69.3565 59.4129 70.39 58.8361 71.35C58.2162 72.3823 57.2931 73.2579 56.1929 73.7579C56.394 74.2039 56.6821 74.6062 57.0659 74.9114C58.7073 76.2197 60.9896 75.1607 61.6044 73.4178V73.4184Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M61.6043 73.4185C61.9824 72.3456 61.9463 70.9472 61.563 69.7227C61.374 71.6058 60.7718 73.4712 58.7688 74.2224C57.9782 74.4768 57.2422 74.4724 56.5322 74.343C56.6845 74.5542 56.8603 74.7478 57.0665 74.9115C58.7079 76.2198 60.9901 75.1608 61.6049 73.4179L61.6043 73.4185Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M53.9781 88.7678C53.8734 88.6682 53.7211 88.5895 53.5073 88.5413C52.6495 88.3478 52.0632 89.394 51.3374 89.6675C50.602 89.9454 49.9104 90.1104 49.2613 90.5723C48.6472 91.0088 48.0932 91.5418 47.6999 92.1883C47.1504 93.0918 46.9448 94.2409 47.3255 95.2268C48.0825 97.1849 50.5868 97.4228 51.9966 96.2287C53.2725 95.1482 54.1329 93.0081 53.9552 91.2861C53.9115 90.8603 53.8461 90.4834 53.9946 90.0736C54.1665 89.5977 54.3023 89.0781 53.9768 88.7672L53.9781 88.7678Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M53.9781 88.7679C53.8734 88.6677 53.7211 88.5896 53.5073 88.5414C52.6495 88.3473 52.0632 89.3942 51.3374 89.6676C50.6014 89.9455 49.9104 90.1105 49.2613 90.5718C48.6472 91.0083 48.0932 91.5413 47.6999 92.1884C47.1504 93.0919 46.9448 94.241 47.3255 95.227C47.3661 95.3329 47.4144 95.4313 47.4651 95.5271C48.0507 92.2919 52.4509 91.5578 54.1564 89.4824C54.1976 89.2064 54.1678 88.95 53.9768 88.7673L53.9781 88.7679Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M53.5073 88.5414C52.6495 88.3473 52.0632 89.3942 51.3374 89.6676C50.6014 89.9455 49.9104 90.1105 49.2613 90.5718C48.6471 91.0083 48.0932 91.5413 47.6999 92.1884C47.4359 92.6224 47.2538 93.1135 47.1758 93.616C48.6814 91.0806 51.8722 90.6892 54.1056 88.9551C54.0745 88.8872 54.0358 88.8231 53.9774 88.7673C53.8727 88.6671 53.7205 88.589 53.5067 88.5408L53.5073 88.5414Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M49.5718 86.4015C49.667 86.51 49.8117 86.602 50.0204 86.6693C50.8579 86.9383 51.5349 85.9485 52.2817 85.7398C53.0393 85.5285 53.7423 85.4251 54.4294 85.0234C55.0798 84.6434 55.6787 84.1618 56.1279 83.5521C56.7554 82.7012 57.0625 81.5744 56.7707 80.5586C56.1908 78.5416 53.7175 78.0816 52.2068 79.1456C50.8402 80.1087 49.7926 82.1638 49.8174 83.8947C49.8231 84.3223 49.8548 84.7037 49.6708 85.099C49.457 85.5577 49.2762 86.0634 49.5725 86.4022L49.5718 86.4015Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M49.5723 86.4015C49.6675 86.5107 49.8122 86.602 50.0209 86.6693C50.3464 86.774 50.6471 86.6877 50.9377 86.5316C50.5228 84.335 51.7219 81.4012 54.4223 81.2775C55.3176 81.2362 56.1386 81.5585 56.7984 82.0959C56.9127 81.5871 56.9152 81.0592 56.7712 80.5586C56.1912 78.5416 53.718 78.0816 52.2073 79.1456C50.8407 80.1087 49.7938 82.1638 49.8179 83.8947C49.8236 84.3223 49.8553 84.7037 49.6713 85.0983C49.4575 85.557 49.2767 86.0627 49.573 86.4015H49.5723Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M49.5723 86.4015C49.6675 86.5107 49.8122 86.602 50.0209 86.6693C50.0526 86.6795 50.0831 86.6801 50.1148 86.6864C50.887 84.1606 49.9581 79.4806 54.0442 79.5123C55.124 79.5853 56.0783 80.0383 56.8029 80.7071C56.7915 80.6576 56.7851 80.6074 56.7712 80.5586C56.1912 78.5416 53.718 78.0816 52.2073 79.1456C50.8407 80.1087 49.7938 82.1638 49.8179 83.8947C49.8236 84.3223 49.8553 84.7037 49.6713 85.0983C49.4575 85.557 49.2767 86.0627 49.573 86.4015H49.5723Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M49.709 35.0484C49.5954 34.9589 49.4362 34.8955 49.2185 34.8682C48.3461 34.7572 47.8626 35.8555 47.1666 36.1974C46.4611 36.5445 45.7891 36.7748 45.187 37.2964C44.6179 37.7894 44.1173 38.3731 43.788 39.0545C43.328 40.0062 43.2328 41.1699 43.7068 42.1153C44.6477 43.9914 47.1634 43.9889 48.4527 42.6654C49.6195 41.4675 50.2705 39.255 49.9291 37.5578C49.8448 37.1384 49.7439 36.7697 49.8524 36.3472C49.978 35.8567 50.063 35.3263 49.7096 35.0484H49.709Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M49.7088 35.0484C49.5952 34.9589 49.436 34.8954 49.219 34.8682C48.8802 34.825 48.5997 34.9652 48.3434 35.1721C49.1549 37.2551 48.5153 40.359 45.8841 40.977C45.0117 41.1819 44.145 41.0157 43.3982 40.609C43.3798 41.1299 43.4737 41.6495 43.7072 42.1152C44.6482 43.9914 47.1639 43.9889 48.4532 42.6653C49.62 41.4674 50.271 39.255 49.9296 37.5577C49.8452 37.1383 49.7443 36.7697 49.8522 36.3471C49.9778 35.8567 50.0628 35.3263 49.7094 35.0484H49.7088Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M49.709 35.0482C49.5954 34.9588 49.4362 34.8953 49.2192 34.8681C49.1862 34.8636 49.1557 34.8687 49.124 34.8681C48.8296 37.4929 50.6024 41.9222 46.5797 42.6424C45.5049 42.7693 44.4834 42.499 43.6484 41.9749C43.6687 42.0212 43.684 42.0694 43.7068 42.1151C44.6477 43.9913 47.1635 43.9887 48.4527 42.6652C49.6196 41.4673 50.2705 39.2549 49.9292 37.5576C49.8448 37.1382 49.7439 36.7696 49.8518 36.347C49.9774 35.8566 50.0624 35.3261 49.709 35.0482Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M61.5885 41.9941C61.4914 42.1013 61.4172 42.2555 61.3741 42.4706C61.2028 43.3335 62.2636 43.8924 62.5561 44.6107C62.8524 45.3391 63.0358 46.0256 63.5142 46.6626C63.9666 47.2653 64.5135 47.8053 65.1702 48.1815C66.0876 48.7075 67.2418 48.8833 68.2176 48.4772C70.1553 47.6701 70.3285 45.1608 69.0989 43.7814C67.986 42.5334 65.8243 41.7288 64.1074 41.9509C63.6829 42.0061 63.308 42.0804 62.8943 41.9433C62.414 41.7834 61.8912 41.6616 61.5885 41.9947V41.9941Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M69.0989 43.7804C67.986 42.5324 65.8243 41.7279 64.1074 41.9499C63.6829 42.0051 63.3079 42.0794 62.8942 41.9423C62.4139 41.7824 61.8911 41.6606 61.5885 41.9937C61.5479 42.0388 61.5117 42.094 61.48 42.1568C62.2947 41.6993 63.1969 42.2513 63.7768 42.8185C64.5661 43.5913 65.389 44.2385 65.9518 45.2074C66.5571 46.2479 66.8788 47.4795 66.7862 48.6843C67.2741 48.723 67.7652 48.6647 68.2176 48.4762C70.1553 47.6692 70.3285 45.1598 69.0982 43.7804H69.0989Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M69.0987 43.7809C68.3412 42.932 67.0989 42.2892 65.8413 42.0342C67.3996 43.1084 68.7434 44.5353 68.4351 46.6526C68.2765 47.4679 67.9173 48.1106 67.4618 48.6702C67.7206 48.6391 67.9744 48.5776 68.2174 48.4767C70.1552 47.6697 70.3284 45.1603 69.0981 43.7809H69.0987Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M49.3827 32.9687C49.3225 33.1 49.2983 33.2694 49.3231 33.4877C49.4214 34.362 50.6022 34.572 51.099 35.1678C51.6028 35.7718 51.9854 36.3701 52.6345 36.832C53.248 37.2685 53.9332 37.6169 54.6737 37.7761C55.7073 37.9982 56.8607 37.8161 57.6672 37.1321C59.268 35.775 58.6716 33.3309 57.0809 32.3906C55.6413 31.5398 53.3375 31.4294 51.769 32.1622C51.3814 32.3437 51.047 32.5283 50.6111 32.5233C50.1048 32.5169 49.5693 32.5594 49.3827 32.9687Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M49.3827 32.9687C49.3225 33.1 49.2983 33.2694 49.3231 33.4877C49.3612 33.8271 49.5636 34.0663 49.825 34.2668C51.6574 32.9864 54.8247 32.8748 56.0467 35.2858C56.4515 36.0852 56.4953 36.9665 56.2764 37.7882C56.7871 37.6835 57.2694 37.469 57.6672 37.1321C59.268 35.775 58.6716 33.3309 57.0809 32.3906C55.6413 31.5398 53.3375 31.4294 51.769 32.1622C51.3814 32.3437 51.047 32.5283 50.6111 32.5233C50.1048 32.5169 49.5693 32.5594 49.3827 32.9687Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M49.3827 32.9687C49.3225 33.1 49.2983 33.2694 49.3231 33.4877C49.3269 33.5207 49.339 33.5492 49.3459 33.5803C51.9663 33.2466 55.8513 30.4783 57.5009 34.2167C57.8778 35.2312 57.8569 36.2876 57.5447 37.2222C57.5853 37.1918 57.6285 37.1651 57.6672 37.1321C59.268 35.775 58.6716 33.3309 57.0809 32.3906C55.6413 31.5398 53.3375 31.4294 51.769 32.1622C51.3814 32.3437 51.047 32.5283 50.6111 32.5233C50.1048 32.5169 49.5693 32.5594 49.3827 32.9687Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M52.5535 20.2602C52.4107 20.2843 52.2572 20.3598 52.0909 20.5026C51.4228 21.0749 51.912 22.17 51.6988 22.9155C51.4825 23.6712 51.202 24.3247 51.1849 25.121C51.1684 25.8741 51.2648 26.6367 51.5491 27.3385C51.9456 28.3188 52.7444 29.1702 53.7634 29.4532C55.7855 30.0154 57.4726 28.1487 57.3565 26.3043C57.2518 24.6356 56.0488 22.6674 54.5616 21.7817C54.1936 21.5628 53.8529 21.3896 53.6124 21.0266C53.3332 20.6047 52.9976 20.1853 52.5541 20.2608L52.5535 20.2602Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M52.5535 20.2602C52.4107 20.2843 52.2572 20.3598 52.091 20.5026C51.8315 20.7253 51.7477 21.0266 51.728 21.3559C53.8167 22.1522 55.6885 24.7105 54.3802 27.0758C53.9468 27.86 53.2419 28.3911 52.4386 28.6715C52.8124 29.0351 53.2603 29.3136 53.7628 29.4532C55.7849 30.0154 57.472 28.1487 57.3559 26.3043C57.2512 24.6356 56.0482 22.6674 54.561 21.7817C54.193 21.5628 53.8523 21.3896 53.6118 21.0266C53.3326 20.6047 52.997 20.1853 52.5535 20.2608V20.2602Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M52.5535 20.2602C52.4107 20.2843 52.2572 20.3598 52.0909 20.5026C52.0656 20.5241 52.0491 20.5501 52.0269 20.5736C53.7749 22.5538 58.248 24.213 56.0818 27.6779C55.4543 28.5599 54.5686 29.136 53.62 29.4025C53.6683 29.4189 53.7139 29.4399 53.7634 29.4532C55.7855 30.0154 57.4726 28.1487 57.3565 26.3043C57.2518 24.6356 56.0488 22.6674 54.5616 21.7817C54.1936 21.5628 53.8529 21.3896 53.6124 21.0266C53.3333 20.6047 52.9976 20.1853 52.5541 20.2608L52.5535 20.2602Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M42.8032 53.5825C42.7004 53.684 42.6179 53.8344 42.564 54.0469C42.347 54.8997 43.3768 55.5139 43.6306 56.2467C43.8882 56.9897 44.0341 57.6851 44.4776 58.3462C44.897 58.9718 45.4147 59.5403 46.0505 59.9508C46.9381 60.5244 48.0815 60.7623 49.0776 60.4083C51.0553 59.7053 51.3624 57.2086 50.207 55.7664C49.162 54.4612 47.046 53.5425 45.3202 53.6732C44.8932 53.7056 44.515 53.7601 44.1096 53.6009C43.6388 53.4156 43.1223 53.2659 42.8032 53.5825Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M42.8032 53.5825C42.7004 53.684 42.6179 53.8344 42.564 54.0469C42.347 54.8997 43.3768 55.5138 43.6306 56.2467C43.8882 56.9896 44.0341 57.685 44.4776 58.3462C44.897 58.9718 45.4147 59.5403 46.0505 59.9514C46.9381 60.525 48.0815 60.7629 49.0776 60.4089C49.1842 60.3708 49.2844 60.3251 49.3815 60.2775C46.1634 59.6031 45.5505 55.1845 43.5227 53.4226C43.248 53.3737 42.991 53.3966 42.8032 53.5825Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M42.564 54.0465C42.347 54.8993 43.3768 55.5134 43.6306 56.2463C43.8882 56.9892 44.0341 57.6846 44.4776 58.3458C44.897 58.9714 45.4147 59.5399 46.0505 59.951C46.4769 60.227 46.9635 60.4218 47.4628 60.5138C44.9693 58.939 44.666 55.7387 42.9942 53.459C42.9257 53.4882 42.8603 53.5256 42.8032 53.5821C42.7004 53.6836 42.6179 53.834 42.564 54.0465Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M32.9199 43.6469C32.8171 43.7485 32.7346 43.8988 32.6807 44.1114C32.4637 44.9641 33.4935 45.5783 33.7473 46.3111C34.0049 47.0541 34.1508 47.7495 34.5943 48.4106C35.0137 49.0362 35.5314 49.6047 36.1672 50.0152C37.0555 50.5888 38.1982 50.8268 39.1949 50.4727C41.1726 49.7697 41.4797 47.273 40.3243 45.8308C39.2793 44.5257 37.1633 43.607 35.4375 43.7377C35.0105 43.77 34.6324 43.8246 34.2269 43.6653C33.7562 43.4801 33.2397 43.3303 32.9205 43.6469H32.9199Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M32.9199 43.6469C32.8171 43.7484 32.7346 43.8988 32.6807 44.1114C32.4637 44.9641 33.4935 45.5783 33.7473 46.3111C34.0049 47.0541 34.1508 47.7495 34.5943 48.4106C35.0137 49.0362 35.5314 49.6047 36.1672 50.0159C37.0548 50.5894 38.1982 50.8274 39.1943 50.4733C39.3009 50.4353 39.4011 50.3896 39.4982 50.342C36.2801 49.6675 35.6672 45.249 33.6394 43.487C33.3647 43.4382 33.1077 43.461 32.9199 43.6469Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M32.6807 44.111C32.4637 44.9637 33.4935 45.5779 33.7473 46.3107C34.0049 47.0537 34.1508 47.7491 34.5943 48.4102C35.0137 49.0358 35.5314 49.6043 36.1672 50.0155C36.5936 50.2915 37.0802 50.4863 37.5795 50.5783C35.086 49.0035 34.7827 45.8031 33.1109 43.5234C33.0424 43.5526 32.977 43.5901 32.9199 43.6465C32.8171 43.748 32.7346 43.8984 32.6807 44.111Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M79.2488 93.4235C79.3865 93.379 79.5274 93.282 79.6714 93.1164C80.2488 92.4527 79.606 91.4407 79.7088 90.6717C79.8135 89.8926 79.9956 89.2054 79.8973 88.4155C79.804 87.6681 79.5978 86.9276 79.2152 86.2747C78.6803 85.363 77.7667 84.6365 76.7172 84.5039C74.6349 84.2412 73.2371 86.3331 73.6191 88.1408C73.9649 89.7765 75.4413 91.5492 77.0415 92.2097C77.4367 92.3728 77.7997 92.4946 78.0903 92.8194C78.4278 93.1963 78.8212 93.5624 79.2488 93.4235Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M73.6195 88.1411C73.9653 89.7768 75.4418 91.5495 77.0419 92.21C77.4379 92.3731 77.8002 92.4949 78.0907 92.8198C78.4283 93.1967 78.8217 93.5628 79.2493 93.4238C79.307 93.4048 79.3654 93.3749 79.4244 93.3362C78.4898 93.3286 77.981 92.401 77.7602 91.6206C77.4594 90.5578 77.0667 89.5871 77.0597 88.4666C77.0515 87.263 77.3846 86.0346 78.0635 85.0353C77.6593 84.7593 77.2044 84.5658 76.7177 84.5049C74.6354 84.2422 73.2376 86.3341 73.6195 88.1417V88.1411Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M73.6196 88.1408C73.855 89.2536 74.6138 90.4287 75.5782 91.2751C74.7598 89.5683 74.3029 87.6624 75.6227 85.9784C76.1658 85.3497 76.7964 84.9703 77.4696 84.7114C77.2298 84.6099 76.9785 84.5369 76.7178 84.5039C74.6354 84.2412 73.2376 86.3331 73.6202 88.1408H73.6196Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M74.0655 84.1567C74.2012 84.1059 74.3377 84.0038 74.4747 83.8318C75.0235 83.1441 74.3377 82.16 74.4081 81.3878C74.4791 80.6049 74.6321 79.9107 74.5001 79.1252C74.3751 78.3823 74.1378 77.6513 73.7273 77.015C73.1543 76.1267 72.2096 75.4395 71.1564 75.3526C69.0645 75.1794 67.7574 77.3284 68.2162 79.1183C68.6318 80.7381 70.1818 82.4461 71.8086 83.0375C72.2109 83.1834 72.5782 83.29 72.8822 83.6015C73.2356 83.9638 73.6442 84.3128 74.0655 84.1561V84.1567Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M74.0655 84.1564C74.2012 84.1063 74.3377 84.0035 74.4747 83.8322C74.6879 83.5651 74.7139 83.2529 74.6714 82.9261C72.471 82.5347 70.1532 80.373 70.9952 77.8046C71.2744 76.9531 71.867 76.2996 72.6036 75.8739C72.1684 75.5871 71.676 75.3967 71.1564 75.3536C69.0645 75.1804 67.7574 77.3294 68.2162 79.1192C68.6318 80.7391 70.1818 82.4471 71.8086 83.0384C72.2109 83.1844 72.5782 83.2903 72.8822 83.6025C73.2356 83.9648 73.6442 84.3137 74.0655 84.157V84.1564Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M74.0656 84.1564C74.2014 84.1063 74.3378 84.0035 74.4749 83.8322C74.4958 83.8062 74.5072 83.7776 74.5243 83.7503C72.4363 82.1324 67.7316 81.3406 69.2106 77.5318C69.6617 76.5483 70.4243 75.8161 71.3062 75.3764C71.2561 75.3694 71.2066 75.3574 71.1559 75.3536C69.064 75.1804 67.7569 77.3294 68.2157 79.1192C68.6313 80.7391 70.1813 82.4471 71.8081 83.0384C72.2104 83.1844 72.5778 83.2903 72.8817 83.6025C73.2351 83.9648 73.6437 84.3137 74.065 84.157L74.0656 84.1564Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M64.2526 91.7386C64.3814 91.6733 64.5064 91.5559 64.6231 91.3706C65.092 90.6264 64.3014 89.7248 64.2849 88.9494C64.2684 88.1633 64.3433 87.4565 64.125 86.6907C63.9182 85.9661 63.6009 85.2662 63.1225 84.6794C62.4544 83.8602 61.4393 83.2822 60.3828 83.3127C58.2846 83.3729 57.2244 85.6539 57.8798 87.3822C58.4724 88.9456 60.2033 90.4709 61.8859 90.8776C62.3022 90.9785 62.679 91.0426 63.0153 91.3192C63.4068 91.6403 63.8516 91.9416 64.2532 91.7386H64.2526Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M57.8792 87.3832C58.4718 88.9465 60.2027 90.4718 61.8853 90.8785C62.3015 90.9794 62.6778 91.0435 63.0147 91.3201C63.4062 91.6412 63.8509 91.9426 64.2526 91.7395C64.3065 91.7122 64.3598 91.6729 64.4125 91.6259C63.488 91.7617 62.8427 90.9229 62.5046 90.1857C62.0439 89.1819 61.5072 88.2822 61.3282 87.1763C61.1353 85.9879 61.2756 84.7234 61.7939 83.6315C61.3523 83.4208 60.8727 83.2996 60.3828 83.3136C58.2846 83.3739 57.2244 85.6548 57.8798 87.3832H57.8792Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M57.8792 87.3825C58.2827 88.4459 59.2129 89.4909 60.2959 90.1793C59.2249 88.6185 58.4813 86.8051 59.5269 84.9385C59.9666 84.2342 60.532 83.7621 61.1576 83.403C60.905 83.3396 60.6455 83.3059 60.3828 83.3136C58.2846 83.3738 57.2244 85.6548 57.8798 87.3831L57.8792 87.3825Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M76.6795 75.0776C76.7708 75.1899 76.9123 75.2864 77.1185 75.3606C77.9459 75.6594 78.6571 74.6938 79.4109 74.5117C80.1755 74.327 80.8816 74.249 81.5827 73.8715C82.2458 73.5143 82.8619 73.0542 83.332 72.461C83.9887 71.6324 84.3358 70.5176 84.0794 69.4916C83.5712 67.4549 81.1158 66.9087 79.5683 67.9187C78.168 68.833 77.0494 70.8501 77.0126 72.5803C77.0037 73.0079 77.0214 73.3899 76.8235 73.7782C76.5938 74.2293 76.3952 74.728 76.6795 75.077V75.0776Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M76.6793 75.0774C76.7707 75.1898 76.9121 75.2862 77.1184 75.3604C77.9457 75.6593 78.657 74.6936 79.4107 74.5115C80.1753 74.3269 80.8815 74.2488 81.5826 73.8713C82.2456 73.5141 82.8617 73.0541 83.3318 72.4608C83.9885 71.6328 84.3356 70.5174 84.0793 69.4921C84.052 69.3823 84.0158 69.2783 83.9777 69.1768C82.9956 72.3149 78.5383 72.4976 76.5892 74.3453C76.5137 74.6143 76.5118 74.8719 76.6793 75.0768V75.0774Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M77.1183 75.3604C77.9457 75.6593 78.6569 74.6936 79.4107 74.5115C80.1753 74.3268 80.8814 74.2488 81.5825 73.8713C82.2456 73.5141 82.8616 73.0541 83.3318 72.4608C83.6471 72.063 83.8889 71.5979 84.0291 71.1094C82.2208 73.4392 79.0059 73.4316 76.5752 74.8757C76.5974 74.9467 76.6285 75.0153 76.6793 75.0781C76.7706 75.1904 76.9121 75.2868 77.1183 75.361V75.3604Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M82.7048 80.217C82.6235 80.097 82.4916 79.9885 82.293 79.8959C81.4954 79.5247 80.7011 80.4238 79.934 80.538C79.1561 80.6535 78.4461 80.6693 77.7139 80.9828C77.0217 81.2797 76.3675 81.6832 75.846 82.2321C75.1182 82.9985 74.6735 84.0784 74.8378 85.1228C75.1633 87.1963 77.5604 87.9589 79.1916 87.0903C80.6674 86.3042 81.9611 84.3944 82.1515 82.6737C82.1984 82.2486 82.2149 81.8666 82.4465 81.4973C82.7155 81.0684 82.9579 80.5894 82.7054 80.2163L82.7048 80.217Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M82.7049 80.2165C82.6237 80.0966 82.4918 79.9881 82.2932 79.8955C81.9835 79.7515 81.6739 79.7997 81.3662 79.9183C81.5058 82.1492 79.9519 84.9117 77.2573 84.6998C76.3639 84.6294 75.5892 84.2081 75.0011 83.5933C74.8247 84.0837 74.7568 84.6072 74.8374 85.1224C75.1628 87.1958 77.5599 87.9585 79.1912 87.0899C80.667 86.3038 81.9607 84.394 82.151 82.6733C82.198 82.2482 82.2145 81.8662 82.4454 81.4969C82.7145 81.068 82.9568 80.589 82.7043 80.2159L82.7049 80.2165Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M82.705 80.2174C82.6238 80.0975 82.4918 79.989 82.2932 79.8964C82.2628 79.8824 82.2323 79.878 82.2025 79.8672C81.1239 82.2782 81.4646 87.0368 77.414 86.4988C76.3519 86.2926 75.4605 85.7247 74.8247 84.9716C74.8298 85.0224 74.8298 85.0725 74.838 85.1232C75.1635 87.1967 77.5606 87.9594 79.1919 87.0908C80.6677 86.3047 81.9614 84.3949 82.1517 82.6741C82.1987 82.249 82.2152 81.8671 82.4461 81.4978C82.7151 81.0689 82.9575 80.5899 82.705 80.2168V80.2174Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M93.1813 89.4233C93.1191 89.2925 93.0049 89.165 92.8228 89.0438C92.0907 88.5565 91.1694 89.3243 90.394 89.3211C89.6079 89.3179 88.903 89.2253 88.1321 89.4245C87.4025 89.613 86.695 89.9131 86.0967 90.3762C85.2611 91.024 84.6583 92.024 84.6621 93.081C84.6697 95.1799 86.9234 96.2972 88.6676 95.6856C90.2456 95.1323 91.8134 93.4402 92.2626 91.7689C92.3736 91.3559 92.4472 90.9803 92.7321 90.651C93.0627 90.2677 93.3748 89.8306 93.1826 89.4245L93.1813 89.4233Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M88.6661 95.6859C90.2441 95.1326 91.8119 93.4404 92.2611 91.7686C92.3722 91.3555 92.4458 90.9799 92.7306 90.6506C93.0612 90.2674 93.3734 89.8302 93.1811 89.4241C93.1551 89.3696 93.1177 89.315 93.072 89.2617C93.1843 90.1893 92.3296 90.813 91.5841 91.1328C90.569 91.5681 89.6566 92.082 88.5462 92.233C87.3534 92.3954 86.0927 92.2235 85.0141 91.6791C84.792 92.1156 84.6594 92.5915 84.6607 93.0819C84.6683 95.1808 86.922 96.2981 88.6661 95.6865V95.6859Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M88.6661 95.6853C89.7397 95.3084 90.8075 94.4049 91.5226 93.3396C89.9357 94.3713 88.104 95.0692 86.264 93.9773C85.5705 93.5198 85.1137 92.9431 84.7698 92.3086C84.7 92.5598 84.66 92.8181 84.6607 93.0814C84.6683 95.1803 86.9219 96.2976 88.6661 95.6859V95.6853Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M84.2537 73.7651C84.123 73.8273 83.9961 73.9421 83.8749 74.1248C83.3895 74.8583 84.1598 75.7777 84.1585 76.553C84.1572 77.3391 84.0665 78.044 84.2676 78.8149C84.458 79.544 84.76 80.2508 85.2251 80.8478C85.8748 81.6815 86.8766 82.2824 87.9337 82.2754C90.0325 82.2627 91.1435 80.0052 90.5274 78.2629C89.9697 76.6869 88.2737 75.1229 86.6012 74.6788C86.1876 74.569 85.8126 74.496 85.482 74.2118C85.0982 73.8818 84.6604 73.5709 84.2543 73.7651H84.2537Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M84.2537 73.7652C84.123 73.8274 83.9961 73.9422 83.8749 74.125C83.3895 74.8584 84.1598 75.7778 84.1585 76.5531C84.1572 77.3393 84.0665 78.0442 84.2676 78.8151C84.458 79.5441 84.76 80.2509 85.2251 80.8479C85.8748 81.6817 86.8766 82.2819 87.9337 82.2755C88.0472 82.2749 88.1564 82.2647 88.2636 82.2514C85.4465 80.5561 86.3208 76.182 84.9859 73.8509C84.7422 73.7145 84.4922 73.651 84.2537 73.7652Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M83.8749 74.125C83.3895 74.8584 84.1598 75.7778 84.1585 76.5531C84.1572 77.3393 84.0665 78.0442 84.2676 78.8151C84.458 79.5441 84.76 80.2509 85.2251 80.8479C85.5372 81.2489 85.9319 81.5928 86.3741 81.8441C84.5373 79.5371 85.3037 76.4148 84.4745 73.7119C84.4002 73.717 84.326 73.7303 84.2537 73.7652C84.123 73.8274 83.9961 73.9422 83.8749 74.125Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M77.0064 71.8237C77.1422 71.7729 77.2786 71.6708 77.4156 71.4988C77.9644 70.811 77.2786 69.827 77.349 69.0548C77.4201 68.2718 77.573 67.5777 77.441 66.7922C77.316 66.0493 77.0787 65.3183 76.6682 64.6819C76.0953 63.7937 75.1505 63.1065 74.0973 63.0196C72.0054 62.8464 70.6984 64.9954 71.1571 66.7853C71.5727 68.4051 73.1227 70.1131 74.7495 70.7045C75.1518 70.8504 75.5192 70.957 75.8231 71.2685C76.1765 71.6308 76.5851 71.9798 77.0064 71.823V71.8237Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M77.0064 71.824C77.1422 71.7739 77.2786 71.6711 77.4156 71.4998C77.6288 71.2327 77.6548 70.9205 77.6123 70.5938C75.4119 70.2023 73.0942 68.0406 73.9361 65.4722C74.2153 64.6207 74.8079 63.9672 75.5445 63.5409C75.1093 63.2541 74.6169 63.0637 74.0973 63.0206C72.0054 62.8474 70.6984 64.9964 71.1571 66.7862C71.5727 68.4061 73.1227 70.1141 74.7495 70.7054C75.1518 70.8514 75.5192 70.9573 75.8231 71.2695C76.1765 71.6318 76.5851 71.9807 77.0064 71.8234V71.824Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M77.0065 71.8234C77.1423 71.7733 77.2787 71.6705 77.4158 71.4992C77.4367 71.4732 77.4481 71.4446 77.4653 71.4173C75.3772 69.7994 70.6725 69.0076 72.1515 65.1988C72.6026 64.2153 73.3652 63.4831 74.2472 63.0434C74.197 63.0364 74.1475 63.0244 74.0968 63.0206C72.0049 62.8474 70.6979 64.9964 71.1566 66.7862C71.5722 68.4061 73.1222 70.1141 74.749 70.7054C75.1513 70.8514 75.5187 70.9573 75.8226 71.2695C76.176 71.6318 76.5846 71.9807 77.0059 71.8234H77.0065Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M74.6356 69.5281C74.595 69.3891 74.5017 69.2457 74.3405 69.0973C73.6934 68.5015 72.6636 69.1169 71.8978 68.9926C71.1218 68.8669 70.4404 68.6658 69.6473 68.7426C68.8973 68.8149 68.1518 69.0008 67.4881 69.3656C66.5618 69.8751 65.8105 70.7691 65.6494 71.8141C65.3296 73.8882 67.3822 75.3431 69.1999 75.0106C70.8445 74.7099 72.6572 73.2829 73.3609 71.7012C73.5347 71.3103 73.6661 70.9512 73.9992 70.6695C74.3856 70.3427 74.7618 69.9595 74.6349 69.5281H74.6356Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M69.2013 75.0098C70.8458 74.7091 72.6586 73.2821 73.3622 71.7004C73.5361 71.3095 73.6674 70.9504 74.0005 70.6687C74.3869 70.3419 74.7631 69.9587 74.6362 69.5273C74.6191 69.4689 74.5906 69.4099 74.5538 69.3496C74.5201 70.2836 73.5792 70.7664 72.7931 70.9663C71.7227 71.2378 70.7412 71.6033 69.6213 71.5805C68.4177 71.5551 67.1988 71.189 66.2186 70.4828C65.9311 70.8793 65.7256 71.3286 65.6513 71.8133C65.3316 73.8874 67.3841 75.3423 69.2019 75.0098H69.2013Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M69.2014 75.0097C70.32 74.8048 71.516 74.0789 72.3884 73.1374C70.6601 73.9089 68.7421 74.3131 67.0949 72.9476C66.4814 72.388 66.1197 71.7466 65.8786 71.0664C65.7708 71.3037 65.6908 71.5524 65.6509 71.8126C65.3311 73.8867 67.3836 75.3416 69.2014 75.0091V75.0097Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M77.8403 65.3716C77.9316 65.4839 78.0731 65.5803 78.2793 65.6545C79.1067 65.9534 79.818 64.9877 80.5717 64.8056C81.3363 64.621 82.0424 64.5429 82.7436 64.1654C83.4066 63.8082 84.0227 63.3482 84.4928 62.755C85.1495 61.9263 85.4966 60.8115 85.2402 59.7856C84.732 57.7489 82.2766 57.2026 80.7291 58.2127C79.3288 59.127 78.2102 61.144 78.1734 62.8742C78.1645 63.3019 78.1823 63.6838 77.9843 64.0721C77.7546 64.5233 77.556 65.022 77.8396 65.3709L77.8403 65.3716Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M77.8404 65.3714C77.9318 65.4837 78.0733 65.5801 78.2795 65.6544C79.1069 65.9532 79.8181 64.9875 80.5719 64.8054C81.3364 64.6208 82.0426 64.5428 82.7437 64.1652C83.4067 63.808 84.0228 63.348 84.493 62.7548C85.1497 61.9268 85.4967 60.8114 85.2404 59.786C85.2131 59.6763 85.1769 59.5722 85.1389 59.4707C84.1567 62.6089 79.6995 62.7916 77.7503 64.6392C77.6748 64.9082 77.6729 65.1658 77.8404 65.3708V65.3714Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M78.2794 65.6553C79.1068 65.9542 79.8181 64.9885 80.5718 64.8064C81.3364 64.6218 82.0426 64.5437 82.7437 64.1662C83.4067 63.809 84.0228 63.349 84.4929 62.7557C84.8083 62.3579 85.05 61.8928 85.1902 61.4043C83.382 63.7341 80.167 63.7265 77.7363 65.1706C77.7585 65.2416 77.7896 65.3102 77.8404 65.373C77.9317 65.4853 78.0732 65.5817 78.2794 65.656V65.6553Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M67.0389 86.8479C66.8962 86.8257 66.7261 86.8479 66.5231 86.9311C65.7084 87.2623 65.8252 88.4563 65.3867 89.0953C64.942 89.7437 64.4693 90.2741 64.2003 91.0241C63.9458 91.7335 63.7961 92.4872 63.843 93.2429C63.9084 94.298 64.3963 95.3589 65.2732 95.9502C67.0129 97.124 69.2044 95.888 69.679 94.1026C70.1086 92.4866 69.5915 90.2386 68.4614 88.9271C68.1823 88.6029 67.9132 88.3307 67.8009 87.9101C67.6702 87.4215 67.4843 86.9171 67.0396 86.8479H67.0389Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M67.0389 86.8479C66.8962 86.8257 66.7261 86.8479 66.5231 86.9311C65.7084 87.2623 65.8252 88.4563 65.3867 89.0953C64.942 89.7437 64.4693 90.2741 64.2003 91.0241C63.9458 91.7335 63.7961 92.4872 63.843 93.2429C63.9084 94.298 64.3963 95.3589 65.2725 95.9502C65.3664 96.0137 65.4622 96.067 65.5587 96.1165C64.1901 93.1268 67.3809 90.0096 67.5947 87.332C67.471 87.0821 67.3003 86.8885 67.0383 86.8479H67.0389Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M66.5231 86.9311C65.7084 87.2623 65.8252 88.4563 65.3867 89.0953C64.942 89.7437 64.4693 90.2741 64.2003 91.0241C63.9458 91.7335 63.7961 92.4872 63.843 93.2429C63.8748 93.7498 64.0061 94.2568 64.2288 94.7136C64.015 91.7721 66.4102 89.6276 67.2515 86.9285C67.1874 86.8904 67.1189 86.86 67.0389 86.8479C66.8962 86.8257 66.7261 86.8479 66.5231 86.9311Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M78.2222 102.341C78.363 102.308 78.5115 102.224 78.6688 102.071C79.3001 101.458 78.7443 100.395 78.9112 99.6384C79.0806 98.8706 79.3198 98.2012 79.2881 97.4056C79.2583 96.6531 79.1149 95.8974 78.7881 95.2147C78.3319 94.2611 77.4817 93.4604 76.4475 93.2402C74.3943 92.8037 72.8259 94.7706 73.0556 96.6043C73.263 98.2634 74.5847 100.154 76.1239 100.946C76.5046 101.142 76.8555 101.294 77.1175 101.641C77.4221 102.046 77.7831 102.443 78.2215 102.341L78.2222 102.341Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M78.222 102.342C78.3629 102.309 78.5113 102.224 78.6687 102.071C79.3 101.458 78.7442 100.396 78.9111 99.6388C79.0805 98.8711 79.3197 98.2017 79.2879 97.406C79.2581 96.6535 79.1147 95.8979 78.788 95.2152C78.3318 94.2615 77.4816 93.4608 76.448 93.2413C76.337 93.2178 76.2285 93.2045 76.1206 93.1943C78.5101 95.4531 76.7196 99.5392 77.5247 102.102C77.7335 102.287 77.9638 102.403 78.222 102.342V102.342Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M78.6693 102.071C79.3006 101.458 78.7448 100.395 78.9117 99.6382C79.0811 98.8705 79.3203 98.2011 79.2886 97.4055C79.2588 96.653 79.1154 95.8973 78.7886 95.2146C78.5691 94.7565 78.2575 94.3358 77.8794 93.9951C79.1801 96.6415 77.7633 99.5278 77.9949 102.346C78.0685 102.357 78.1433 102.359 78.222 102.34C78.3629 102.307 78.5113 102.223 78.6687 102.07L78.6693 102.071Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M53.8923 102.969C53.8675 103.112 53.8878 103.282 53.9665 103.486C54.2844 104.307 55.4797 104.21 56.1117 104.659C56.7525 105.114 57.2753 105.596 58.0202 105.877C58.7251 106.143 59.4763 106.306 60.2326 106.271C61.2891 106.223 62.3575 105.753 62.9635 104.886C64.1664 103.166 62.9673 100.955 61.1894 100.451C59.581 99.9946 57.3248 100.474 55.9949 101.582C55.6663 101.856 55.3896 102.12 54.9671 102.225C54.476 102.348 53.969 102.526 53.8923 102.969Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M61.1895 100.451C59.5811 99.9946 57.3249 100.474 55.995 101.582C55.6664 101.856 55.3897 102.12 54.9672 102.225C54.4761 102.348 53.9691 102.526 53.8923 102.969C53.8822 103.029 53.8816 103.095 53.8879 103.165C54.3301 102.342 55.3885 102.324 56.1816 102.493C57.2621 102.722 58.3039 102.827 59.2988 103.343C60.3672 103.897 61.2993 104.763 61.8672 105.83C62.2999 105.601 62.6831 105.289 62.9642 104.886C64.1672 103.166 62.968 100.955 61.1902 100.451H61.1895Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M61.1896 100.45C60.0951 100.14 58.7018 100.264 57.5039 100.723C59.3953 100.794 61.2943 101.278 62.1693 103.23C62.4725 104.004 62.5138 104.738 62.4294 105.455C62.6312 105.29 62.8126 105.102 62.9636 104.886C64.1666 103.166 62.9674 100.955 61.1896 100.45Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M44.4637 93.7423C44.4707 93.5976 44.4301 93.4314 44.326 93.2385C43.9098 92.4638 42.7354 92.7075 42.0527 92.3388C41.3605 91.9658 40.7824 91.5521 40.0084 91.3649C39.2762 91.1872 38.5104 91.1187 37.7642 91.2463C36.7217 91.4239 35.7193 92.0222 35.2244 92.9568C34.2428 94.812 35.7047 96.8589 37.5313 97.1406C39.1842 97.3957 41.3636 96.6413 42.5469 95.378C42.8394 95.0658 43.0812 94.7695 43.4879 94.6128C43.9599 94.4307 44.4415 94.1922 44.4624 93.7429L44.4637 93.7423Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M37.5328 97.1405C39.1856 97.3956 41.3651 96.6412 42.5484 95.3779C42.8409 95.0658 43.0826 94.7695 43.4893 94.6127C43.9614 94.4307 44.443 94.1921 44.4639 93.7429C44.4671 93.682 44.4588 93.6166 44.4442 93.5481C44.1067 94.4192 43.0592 94.5671 42.2508 94.4979C41.1506 94.404 40.1037 94.4275 39.053 94.0379C37.9243 93.6198 36.8926 92.8743 36.1979 91.8857C35.7969 92.1662 35.4549 92.5234 35.2258 92.9567C34.2443 94.812 35.7061 96.8588 37.5328 97.1405Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M37.5328 97.141C38.6571 97.3142 40.0244 97.0198 41.157 96.417C39.2713 96.5795 37.3266 96.3326 36.2182 94.5028C35.8222 93.7725 35.6903 93.0486 35.6858 92.3271C35.5063 92.5156 35.3489 92.7243 35.2258 92.9572C34.2443 94.8124 35.7061 96.8593 37.5328 97.141Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M40.2554 89.3716C40.2674 89.5157 40.3296 89.6749 40.4578 89.8532C40.9723 90.567 42.1049 90.1711 42.8294 90.4465C43.5648 90.7256 44.1917 91.0594 44.9835 91.1437C45.7328 91.2237 46.5006 91.1913 47.2239 90.9667C48.234 90.6539 49.1495 89.9293 49.5169 88.9376C50.2466 86.9695 48.5284 85.1327 46.6808 85.0927C45.0089 85.0572 42.9468 86.0907 41.9393 87.498C41.6899 87.8463 41.4894 88.1712 41.1068 88.3799C40.6627 88.6223 40.2166 88.9224 40.2547 89.3704L40.2554 89.3716Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M40.2551 89.3717C40.2672 89.5157 40.3294 89.675 40.4575 89.8533C40.9721 90.567 42.1047 90.1711 42.8292 90.4465C43.5646 90.7257 44.1915 91.0594 44.9833 91.1438C45.7326 91.2237 46.5004 91.1914 47.2237 90.9668C48.2338 90.654 49.1493 89.9294 49.5167 88.9383C49.556 88.8317 49.5852 88.7264 49.6106 88.6211C47.0289 90.6571 43.2461 88.2924 40.594 88.7169C40.3801 88.8965 40.2329 89.1077 40.2551 89.3717Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M40.4577 89.8529C40.9722 90.5667 42.1048 90.1708 42.8294 90.4462C43.5647 90.7254 44.1916 91.0591 44.9834 91.1435C45.7328 91.2234 46.5005 91.1911 47.2238 90.9665C47.7092 90.8161 48.1705 90.5686 48.5619 90.2444C45.7543 91.1467 43.1047 89.3257 40.2832 89.1455C40.2616 89.2166 40.2483 89.2908 40.2553 89.3707C40.2673 89.5148 40.3295 89.674 40.4577 89.8523V89.8529Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M29.0516 93.5817C29.1829 93.642 29.3523 93.6668 29.5706 93.6427C30.4449 93.5462 30.6581 92.3654 31.2539 91.8699C31.8585 91.3674 32.4581 90.9854 32.9213 90.3376C33.3591 89.7247 33.7093 89.0401 33.8698 88.3003C34.0945 87.2674 33.9143 86.1132 33.2322 85.3056C31.8782 83.7016 29.4329 84.2936 28.4894 85.8823C27.6354 87.32 27.5206 89.6238 28.2502 91.1935C28.4304 91.5819 28.6151 91.9169 28.6087 92.3521C28.6011 92.8584 28.643 93.3939 29.0516 93.5811V93.5817Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M29.0514 93.5817C29.1827 93.642 29.3521 93.6668 29.5704 93.6427C29.9098 93.6052 30.1497 93.4035 30.3508 93.142C29.0742 91.3071 28.9689 88.1398 31.3825 86.9222C32.1825 86.5187 33.0638 86.4768 33.8855 86.6976C33.7814 86.1868 33.5683 85.704 33.232 85.3056C31.878 83.7016 29.4327 84.2936 28.4892 85.8823C27.6358 87.32 27.5204 89.6238 28.25 91.1935C28.4302 91.5819 28.6149 91.9162 28.6085 92.3521C28.6009 92.8584 28.6428 93.3939 29.0514 93.5811V93.5817Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M29.0514 93.5821C29.1827 93.6424 29.3521 93.6671 29.5704 93.643C29.6034 93.6392 29.6319 93.6271 29.663 93.6208C29.3344 90.9997 26.5737 87.1091 30.3153 85.467C31.3304 85.0921 32.3869 85.1155 33.3214 85.429C33.291 85.3884 33.2643 85.3452 33.232 85.3065C31.878 83.7026 29.4327 84.2945 28.4892 85.8833C27.6358 87.321 27.5204 89.6248 28.25 91.1945C28.4302 91.5828 28.6149 91.9172 28.6085 92.3531C28.6009 92.8594 28.6428 93.3949 29.0514 93.5821Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M73.041 57.7018C73.1818 57.7342 73.3525 57.7234 73.5613 57.6555C74.3975 57.3827 74.3651 56.1835 74.8486 55.5769C75.3384 54.9615 75.8473 54.4659 76.1683 53.7369C76.4723 53.0479 76.6753 52.3062 76.6816 51.5492C76.6912 50.4922 76.2794 49.3996 75.4469 48.7474C73.7947 47.453 71.5214 48.531 70.9212 50.2784C70.3781 51.8601 70.7353 54.1386 71.7695 55.5268C72.0252 55.8701 72.2739 56.1606 72.3564 56.5883C72.4522 57.0851 72.6019 57.6009 73.0403 57.7018H73.041Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M73.041 57.7023C73.1818 57.7346 73.3525 57.7238 73.5613 57.656C73.8861 57.55 74.0796 57.3032 74.223 57.0069C72.5994 55.4708 71.8507 52.391 73.9654 50.7077C74.6665 50.15 75.5205 49.9292 76.3701 49.9781C76.1645 49.499 75.8574 49.0701 75.4469 48.7485C73.7947 47.4541 71.5214 48.5315 70.9212 50.2795C70.3781 51.8606 70.7353 54.1397 71.7695 55.5279C72.0252 55.8712 72.2739 56.1611 72.3564 56.5894C72.4522 57.0862 72.6019 57.602 73.0403 57.7029L73.041 57.7023Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M73.041 57.7023C73.1818 57.7346 73.3525 57.7238 73.5613 57.656C73.593 57.6458 73.6184 57.628 73.6475 57.6154C72.7916 55.1168 69.2963 51.8695 72.6248 49.4997C73.5422 48.9255 74.5815 48.7332 75.5599 48.85C75.5218 48.8163 75.4869 48.7795 75.4469 48.7485C73.7947 47.4541 71.5214 48.5315 70.9212 50.2795C70.3781 51.8606 70.7353 54.1397 71.7695 55.5279C72.0252 55.8712 72.2739 56.1611 72.3564 56.5894C72.4522 57.0862 72.6019 57.602 73.0403 57.7029L73.041 57.7023Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M67.9625 46.2257C67.8216 46.1914 67.6509 46.2003 67.4415 46.2657C66.6015 46.5277 66.6186 47.7269 66.1282 48.3277C65.6307 48.9368 65.1155 49.426 64.785 50.1506C64.4722 50.8358 64.2596 51.5744 64.2431 52.3313C64.2203 53.3884 64.6175 54.486 65.4417 55.1491C67.0774 56.4643 69.364 55.4162 69.9865 53.6764C70.5499 52.1023 70.2219 49.8188 69.2054 48.4178C68.9542 48.0714 68.7092 47.7776 68.6318 47.3487C68.5424 46.8507 68.399 46.3329 67.9618 46.227L67.9625 46.2257Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M69.9866 53.676C70.5501 52.1018 70.222 49.8183 69.2056 48.4174C68.9543 48.071 68.7094 47.7772 68.632 47.3483C68.5426 46.8502 68.3992 46.3325 67.962 46.2265C67.903 46.2119 67.837 46.2075 67.7666 46.2094C68.5584 46.7062 68.5051 47.7626 68.2843 48.543C67.9836 49.6058 67.8085 50.6381 67.2273 51.5962C66.603 52.6253 65.676 53.4971 64.5732 53.992C64.7725 54.4393 65.0586 54.8422 65.4412 55.1493C67.0769 56.4645 69.3636 55.4164 69.986 53.6766L69.9866 53.676Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M69.9867 53.6754C70.3699 52.6043 70.3394 51.2059 69.9619 49.9795C69.7646 51.862 69.1542 53.7242 67.1473 54.4666C66.3555 54.7172 65.6195 54.7096 64.9102 54.577C65.0612 54.7889 65.2369 54.983 65.4419 55.148C67.0775 56.4633 69.3642 55.4151 69.9867 53.6754Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M104.069 41.6265C103.943 41.5548 103.777 41.5154 103.558 41.5199C102.678 41.5383 102.361 42.6949 101.723 43.1353C101.076 43.5819 100.445 43.9087 99.9259 44.5127C99.4348 45.0844 99.0256 45.7347 98.7997 46.4574C98.4844 47.4662 98.5612 48.6318 99.1684 49.4972C100.374 51.2154 102.862 50.8436 103.943 49.3449C104.922 47.9891 105.242 45.7049 104.655 44.0762C104.509 43.6739 104.356 43.3237 104.401 42.8904C104.454 42.3872 104.46 41.8498 104.069 41.6265Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M104.069 41.6265C103.943 41.5548 103.777 41.5154 103.558 41.5199C103.216 41.5269 102.959 41.707 102.736 41.9488C103.844 43.8897 103.667 47.0545 101.155 48.0519C100.322 48.3824 99.4404 48.3456 98.6416 48.0525C98.6993 48.5702 98.8687 49.0702 99.1682 49.4972C100.374 51.2154 102.862 50.8436 103.943 49.3449C104.922 47.9891 105.241 45.7049 104.655 44.0762C104.509 43.6739 104.356 43.3237 104.401 42.8904C104.453 42.3872 104.46 41.8498 104.069 41.6265Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M104.069 41.6265C103.944 41.5548 103.777 41.5154 103.558 41.5199C103.524 41.5205 103.495 41.53 103.463 41.5338C103.557 44.1733 105.96 48.2949 102.087 49.5968C101.043 49.8798 99.9921 49.7624 99.0898 49.3665C99.1165 49.4097 99.1387 49.4547 99.1685 49.4966C100.374 51.2148 102.862 50.843 103.944 49.3443C104.922 47.9884 105.242 45.7043 104.655 44.0756C104.51 43.6733 104.356 43.3231 104.401 42.8897C104.454 42.3866 104.46 41.8492 104.069 41.6258V41.6265Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M105.732 84.8799C105.833 84.7765 105.912 84.6249 105.963 84.411C106.164 83.5545 105.122 82.9594 104.854 82.2322C104.583 81.4943 104.423 80.8021 103.967 80.1492C103.536 79.5319 103.008 78.9735 102.364 78.5744C101.465 78.018 100.318 77.8023 99.3285 78.1754C97.3647 78.9158 97.1052 81.4182 98.2879 82.8382C99.3577 84.123 101.491 85.0011 103.214 84.8381C103.64 84.7975 104.017 84.7359 104.426 84.8875C104.9 85.0633 105.419 85.2035 105.732 84.8806V84.8799Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M105.732 84.8802C105.833 84.7768 105.913 84.6251 105.963 84.4113C106.041 84.0788 105.93 83.7857 105.751 83.5084C103.596 84.0985 100.575 83.1398 100.234 80.4585C100.121 79.5696 100.376 78.7251 100.858 78.0246C100.343 77.9516 99.8159 77.9916 99.328 78.1756C97.3642 78.9161 97.1048 81.4185 98.2874 82.8384C99.3572 84.1233 101.49 85.0014 103.214 84.8383C103.639 84.7977 104.017 84.7362 104.425 84.8878C104.9 85.0636 105.418 85.2038 105.732 84.8808L105.732 84.8802Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M105.732 84.8798C105.833 84.7764 105.912 84.6248 105.963 84.411C105.97 84.3786 105.968 84.3475 105.972 84.3158C103.392 83.7498 98.8025 85.0518 98.5049 80.9765C98.4903 79.8947 98.8659 78.9062 99.4738 78.1309C99.4255 78.1461 99.3761 78.1562 99.3285 78.1746C97.3647 78.9151 97.1052 81.4175 98.2879 82.8375C99.3577 84.1223 101.491 85.0004 103.214 84.8373C103.64 84.7967 104.017 84.7352 104.426 84.8868C104.9 85.0626 105.419 85.2028 105.732 84.8798Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M73.4956 27.8865C73.5591 28.0166 73.6752 28.1422 73.8592 28.2615C74.5977 28.7399 75.5088 27.9601 76.2842 27.9538C77.0703 27.9475 77.7758 28.0306 78.5448 27.8218C79.2719 27.6245 79.9756 27.3155 80.5682 26.8441C81.3956 26.1861 81.9856 25.1786 81.9685 24.1215C81.9342 22.0227 79.6666 20.9345 77.9306 21.5684C76.3597 22.1419 74.8134 23.8531 74.3858 25.5307C74.2798 25.9457 74.2107 26.3213 73.9302 26.655C73.6047 27.042 73.2976 27.483 73.4956 27.8872V27.8865Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M77.9306 21.5687C76.3597 22.1423 74.8134 23.8535 74.3858 25.5311C74.2798 25.946 74.2107 26.3216 73.9302 26.6554C73.6047 27.0424 73.2976 27.4834 73.4956 27.8875C73.5223 27.9421 73.561 27.9954 73.6073 28.0487C73.4829 27.1223 74.33 26.4879 75.071 26.1592C76.0805 25.7112 76.9865 25.1859 78.0943 25.0203C79.2853 24.8426 80.5479 24.9987 81.6335 25.5298C81.8498 25.0907 81.9767 24.613 81.9691 24.1225C81.9349 22.0236 79.6672 20.9355 77.9313 21.5693L77.9306 21.5687Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M77.9306 21.5687C76.8622 21.9589 75.8057 22.8757 75.104 23.9506C76.6782 22.8992 78.5004 22.1778 80.3537 23.2469C81.0529 23.6955 81.5174 24.2665 81.8689 24.8966C81.9355 24.6447 81.9723 24.3858 81.9678 24.1225C81.9336 22.0236 79.6659 20.9355 77.93 21.5693L77.9306 21.5687Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M82.8454 44.4146C82.9609 44.3271 83.0618 44.1894 83.1436 43.9857C83.471 43.1692 82.5307 42.4243 82.3753 41.6648C82.2179 40.8945 82.164 40.1858 81.8112 39.472C81.4775 38.7969 81.0391 38.1656 80.4623 37.6745C79.6572 36.9893 78.5551 36.6035 77.5209 36.8237C75.4677 37.2609 74.8357 39.696 75.7919 41.2771C76.6567 42.7085 78.6337 43.8969 80.3621 43.994C80.7891 44.0181 81.1717 44.0137 81.553 44.2249C81.9959 44.4698 82.4876 44.6862 82.8461 44.4146H82.8454Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M82.8453 44.4145C82.9608 44.327 83.0617 44.1893 83.1435 43.9856C83.2704 43.6684 83.2057 43.3619 83.0706 43.0618C80.8505 43.322 78.008 41.9198 78.0734 39.2175C78.0949 38.3216 78.4744 37.5253 79.0562 36.9048C78.5568 36.7557 78.0302 36.7157 77.5207 36.8242C75.4682 37.2614 74.8356 39.6965 75.7918 41.2777C76.6566 42.7084 78.6336 43.8968 80.362 43.9945C80.789 44.0186 81.1716 44.0142 81.5529 44.2255C81.9957 44.471 82.4875 44.6867 82.846 44.4152L82.8453 44.4145Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M82.8457 44.4146C82.9611 44.3271 83.062 44.1894 83.1439 43.9857C83.1566 43.9546 83.1591 43.9242 83.168 43.8931C80.7024 42.9458 75.9691 43.5441 76.287 39.4707C76.4355 38.3991 76.9545 37.4785 77.6721 36.8027C77.622 36.8103 77.5712 36.8135 77.5217 36.8237C75.4692 37.2608 74.8366 39.696 75.7927 41.2771C76.6575 42.7079 78.6346 43.8963 80.3629 43.994C80.7899 44.0181 81.1725 44.0136 81.5539 44.2249C81.9967 44.4705 82.4884 44.6862 82.8469 44.4146H82.8457Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M79.1827 44.6088C79.1034 44.4876 78.9734 44.3772 78.776 44.2814C77.9842 43.8975 77.1759 44.7839 76.4069 44.8854C75.6271 44.9888 74.9171 44.9926 74.1798 45.2947C73.4825 45.5802 72.822 45.9736 72.2923 46.5141C71.5524 47.2692 71.0905 48.3414 71.2377 49.3883C71.5296 51.4669 73.9146 52.2676 75.5592 51.425C77.0471 50.6624 78.3712 48.7735 78.5895 47.0566C78.6434 46.6321 78.6656 46.2502 78.9029 45.8847C79.1783 45.4603 79.4289 44.985 79.1827 44.6081V44.6088Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M75.5598 51.4257C77.0477 50.6631 78.3718 48.7742 78.5901 47.0573C78.644 46.6328 78.6662 46.2509 78.9035 45.8854C79.1789 45.4609 79.4295 44.9857 79.1833 44.6088C79.1503 44.5581 79.1053 44.5092 79.0526 44.4629C79.2899 45.367 78.5279 46.1005 77.8325 46.5186C76.8859 47.0877 76.0515 47.7203 74.9723 48.0211C73.8124 48.344 72.5403 48.3447 71.3976 47.9513C71.2371 48.4138 71.1698 48.9036 71.2377 49.389C71.5296 51.4676 73.9146 52.2683 75.5592 51.4257H75.5598Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M75.5597 51.4255C76.5717 50.9065 77.5076 49.8666 78.0717 48.7137C76.639 49.951 74.9195 50.8913 72.9482 50.0588C72.1995 49.6997 71.6678 49.1902 71.2415 48.6084C71.2066 48.8666 71.2015 49.128 71.2383 49.3888C71.5301 51.4674 73.9152 52.2681 75.5597 51.4255Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M73.8854 29.7758C73.8061 29.6546 73.676 29.5442 73.4787 29.4484C72.6868 29.0645 71.8785 29.9509 71.1095 30.0524C70.3297 30.1558 69.6197 30.1596 68.8825 30.4617C68.1852 30.7472 67.5247 31.1405 66.9949 31.6811C66.2551 32.4362 65.7932 33.5084 65.9404 34.5553C66.2322 36.6339 68.6173 37.4346 70.2618 36.592C71.7497 35.8294 73.0739 33.9405 73.2921 32.2236C73.3461 31.7991 73.3683 31.4172 73.6056 31.0517C73.8809 30.6273 74.1309 30.152 73.8854 29.7751V29.7758Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M70.2624 36.5927C71.7503 35.83 73.0745 33.9412 73.2927 32.2243C73.3467 31.7998 73.3689 31.4179 73.6062 31.0524C73.8815 30.6279 74.1322 30.1527 73.886 29.7758C73.853 29.7251 73.8079 29.6762 73.7553 29.6299C73.9926 30.534 73.2306 31.2675 72.5352 31.6856C71.5885 32.2547 70.7542 32.8873 69.6749 33.1881C68.5151 33.511 67.2429 33.5116 66.1002 33.1183C65.9397 33.5808 65.8725 34.0706 65.9403 34.556C66.2322 36.6346 68.6172 37.4353 70.2618 36.5927H70.2624Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M70.2624 36.5925C71.2744 36.0735 72.2102 35.0336 72.7743 33.8807C71.3416 35.118 69.6222 36.0583 67.6508 35.2258C66.9022 34.8667 66.3705 34.3572 65.9441 33.7754C65.9092 34.0336 65.9041 34.295 65.9409 34.5558C66.2328 36.6344 68.6178 37.4351 70.2624 36.5925Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M94.0751 61.4187C94.2178 61.4403 94.3879 61.4168 94.5903 61.3331C95.4037 60.9975 95.2799 59.804 95.7152 59.1625C96.1568 58.5115 96.6263 57.9786 96.8909 57.2274C97.1415 56.5167 97.2874 55.7623 97.236 55.0067C97.165 53.9522 96.6714 52.8938 95.7913 52.3069C94.0452 51.1427 91.8607 52.3901 91.3956 54.1787C90.975 55.7972 91.5041 58.042 92.6411 59.3472C92.9222 59.6701 93.1925 59.9404 93.3073 60.3604C93.4406 60.8483 93.6297 61.3515 94.0744 61.4181L94.0751 61.4187Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M94.0749 61.4184C94.2177 61.4399 94.3877 61.4165 94.5901 61.3327C95.4036 60.9971 95.2798 59.8036 95.7151 59.1622C96.1567 58.5112 96.6262 57.9782 96.8908 57.227C97.1414 56.5164 97.2873 55.762 97.2359 55.0063C97.1649 53.9518 96.6712 52.8935 95.7919 52.3066C95.6973 52.2438 95.6015 52.1911 95.5051 52.1416C96.8895 55.1237 93.7152 58.258 93.5166 60.9368C93.6416 61.1862 93.8135 61.3784 94.0756 61.4177L94.0749 61.4184Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M94.5903 61.3333C95.4038 60.9977 95.28 59.8042 95.7153 59.1628C96.1569 58.5118 96.6264 57.9788 96.891 57.2276C97.1416 56.517 97.2875 55.7626 97.2361 55.0069C97.2019 54.5 97.0674 53.9936 96.8421 53.5381C97.0718 56.4783 94.6887 58.6355 93.8613 61.339C93.9254 61.3765 93.9946 61.4063 94.0745 61.4184C94.2173 61.4399 94.3873 61.4165 94.5897 61.3327L94.5903 61.3333Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M83.3943 42.0271C83.5021 42.1235 83.6563 42.1971 83.872 42.2383C84.7362 42.4046 85.2882 41.3399 86.0045 41.043C86.731 40.7416 87.4162 40.5544 88.0501 40.0722C88.6497 39.616 89.1865 39.0659 89.5589 38.4067C90.0785 37.4861 90.2479 36.3307 89.8355 35.3574C89.0164 33.4247 86.5058 33.2668 85.1347 34.5059C83.8936 35.627 83.1024 37.7932 83.3359 39.5088C83.3936 39.9326 83.4704 40.3076 83.3359 40.7219C83.1792 41.2029 83.0605 41.727 83.3955 42.0271H83.3943Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M83.3942 42.0269C83.502 42.1233 83.6562 42.1969 83.8719 42.2382C84.7361 42.4044 85.2881 41.3397 86.0044 41.0428C86.7309 40.7414 87.4161 40.5542 88.05 40.072C88.6496 39.6158 89.1863 39.0657 89.5588 38.4065C90.0791 37.4859 90.2478 36.3305 89.8354 35.3572C89.791 35.2525 89.7396 35.1561 89.6863 35.0615C89.2047 38.3139 84.8306 39.1888 83.1924 41.3175C83.16 41.5948 83.1981 41.8499 83.3948 42.0262L83.3942 42.0269Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M83.872 42.238C84.7362 42.4042 85.2882 41.3396 86.0045 41.0426C86.731 40.7412 87.4163 40.5541 88.0501 40.0719C88.6497 39.6157 89.1865 39.0656 89.5589 38.4063C89.8089 37.9641 89.9745 37.4673 90.0367 36.9629C88.6129 39.5459 85.436 40.0389 83.2598 41.8433C83.2928 41.91 83.334 41.9728 83.3943 42.0267C83.5021 42.1231 83.6563 42.1967 83.872 42.238Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M96.686 48.3026C96.6695 48.1592 96.6022 48.0018 96.4683 47.828C95.9309 47.1313 94.8123 47.5628 94.0789 47.3109C93.3353 47.0552 92.6976 46.7418 91.9032 46.6827C91.152 46.6269 90.3855 46.684 89.6698 46.9315C88.6705 47.2766 87.7785 48.0304 87.4428 49.0329C86.7766 51.0232 88.5525 52.8042 90.4008 52.7846C92.0726 52.7668 94.1004 51.6679 95.0623 50.2282C95.3002 49.8723 95.4906 49.5411 95.8662 49.3197C96.3021 49.0627 96.7386 48.7493 96.686 48.3026Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M96.6858 48.3026C96.6693 48.1592 96.602 48.0018 96.4682 47.828C95.9314 47.1313 94.8122 47.5628 94.0787 47.3109C93.3351 47.0552 92.6974 46.7418 91.9031 46.6827C91.1518 46.6269 90.3854 46.684 89.6697 46.9315C88.6704 47.2766 87.7783 48.0304 87.4426 49.0329C87.4065 49.1407 87.3811 49.2467 87.3589 49.3533C89.8746 47.2354 93.7304 49.4776 96.3679 48.9682C96.576 48.7816 96.7162 48.5659 96.6858 48.3026Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M96.4681 47.827C95.9313 47.1303 94.8121 47.5618 94.0786 47.3099C93.335 47.0542 92.6974 46.7408 91.903 46.6818C91.1518 46.6259 90.3853 46.683 89.6696 46.9305C89.1893 47.0961 88.7357 47.3588 88.355 47.695C91.1321 46.7033 93.8388 48.438 96.6648 48.5275C96.6845 48.4558 96.6953 48.3809 96.6857 48.301C96.6692 48.1576 96.602 48.0002 96.4681 47.8264V47.827Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M91.5588 66.4864C91.4211 66.5314 91.2809 66.6291 91.1375 66.7947C90.5626 67.4609 91.2098 68.4704 91.1102 69.2394C91.0093 70.0192 90.8298 70.707 90.9313 71.4969C91.0277 72.2443 91.2371 72.9835 91.6229 73.6351C92.1615 74.5449 93.079 75.2676 94.1284 75.3952C96.2121 75.6489 97.601 73.5513 97.2107 71.745C96.858 70.1105 95.3739 68.3441 93.7712 67.6913C93.3747 67.5295 93.0117 67.4096 92.7199 67.086C92.3811 66.7104 91.9858 66.3462 91.5588 66.487V66.4864Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M91.5588 66.4867C91.4212 66.5318 91.2809 66.6295 91.1376 66.7951C90.9142 67.0539 90.8761 67.3648 90.9066 67.6929C93.0905 68.1681 95.3239 70.418 94.3836 72.9521C94.072 73.7922 93.4547 74.4222 92.7022 74.82C93.126 75.1233 93.6108 75.332 94.1285 75.3955C96.2121 75.6493 97.601 73.5517 97.2108 71.7453C96.858 70.1109 95.374 68.3451 93.7713 67.6916C93.3747 67.5298 93.0118 67.4099 92.72 67.0869C92.3811 66.7113 91.9859 66.3471 91.5588 66.488V66.4867Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M91.5589 66.487C91.4212 66.5321 91.281 66.6298 91.1376 66.7954C91.116 66.8208 91.1034 66.8487 91.085 66.8747C93.109 68.5713 97.78 69.5433 96.1564 73.2925C95.6678 74.2582 94.8779 74.9599 93.9795 75.366C94.0296 75.3748 94.0778 75.3888 94.1286 75.3945C96.2122 75.6483 97.6011 73.5507 97.2109 71.7443C96.8581 70.1099 95.3741 68.3441 93.7714 67.6906C93.3748 67.5288 93.0119 67.4089 92.72 67.086C92.3812 66.7104 91.9859 66.3462 91.5589 66.487Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M101.746 76.0967C101.888 76.0694 102.041 75.9914 102.204 75.8448C102.86 75.2585 102.348 74.1736 102.546 73.4243C102.747 72.6642 103.013 72.0056 103.014 71.2086C103.015 70.4555 102.902 69.6948 102.604 68.9994C102.187 68.028 101.371 67.193 100.347 66.931C98.3131 66.4107 96.6654 68.3116 96.8196 70.1535C96.9585 71.8197 98.2021 73.7624 99.7071 74.6171C100.08 74.8284 100.423 74.994 100.672 75.3525C100.959 75.7687 101.304 76.1811 101.746 76.0967H101.746Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M101.746 76.0967C101.888 76.0694 102.041 75.9914 102.204 75.8448C102.459 75.617 102.536 75.3137 102.549 74.9845C100.444 74.2313 98.5205 71.7124 99.7793 69.3211C100.197 68.528 100.89 67.9829 101.687 67.6854C101.306 67.3294 100.852 67.0604 100.347 66.931C98.3136 66.4107 96.6659 68.3116 96.8201 70.1535C96.959 71.8197 98.2026 73.7624 99.7082 74.6171C100.081 74.8284 100.425 74.994 100.673 75.3525C100.96 75.7687 101.305 76.1811 101.747 76.0967H101.746Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M101.746 76.0967C101.888 76.0694 102.041 75.9914 102.204 75.8448C102.228 75.8226 102.244 75.7966 102.266 75.7725C100.478 73.8291 95.9718 72.2625 98.0656 68.7538C98.6747 67.8592 99.5483 67.2653 100.491 66.9785C100.443 66.9633 100.396 66.9437 100.347 66.931C98.3136 66.4107 96.6659 68.3116 96.8201 70.1535C96.959 71.8197 98.2026 73.7624 99.7082 74.6171C100.081 74.8284 100.425 74.994 100.673 75.3525C100.96 75.7687 101.305 76.1811 101.747 76.0967H101.746Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M36.0412 77.6189C36.1827 77.5891 36.3337 77.5079 36.4942 77.3594C37.1395 76.7618 36.6084 75.6863 36.7937 74.9332C36.9808 74.1693 37.2359 73.5062 37.2226 72.7099C37.2105 71.9568 37.0843 71.1986 36.7734 70.5083C36.3394 69.5445 35.5082 68.7241 34.4797 68.4799C32.4373 67.9957 30.8238 69.9258 31.0097 71.7639C31.1785 73.4275 32.4557 75.3481 33.9759 76.1761C34.3515 76.3811 34.6992 76.5403 34.953 76.8944C35.2481 77.3055 35.6002 77.7116 36.0399 77.6189H36.0412Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M36.0412 77.6189C36.1827 77.5891 36.3337 77.5079 36.4942 77.3594C36.7448 77.1272 36.8171 76.8227 36.8241 76.4934C34.7068 75.7777 32.738 73.2937 33.9544 70.8795C34.3579 70.0794 35.0412 69.5217 35.8331 69.2101C35.446 68.8612 34.9873 68.5998 34.4797 68.4799C32.4373 67.9957 30.8238 69.9258 31.0097 71.7639C31.1785 73.4275 32.4557 75.3481 33.9759 76.1761C34.3515 76.3811 34.6992 76.5403 34.953 76.8944C35.2481 77.3055 35.6002 77.7116 36.0399 77.6189H36.0412Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M36.0412 77.6193C36.1827 77.5895 36.3337 77.5082 36.4942 77.3598C36.5189 77.3369 36.5342 77.3109 36.5557 77.2868C34.7329 75.3751 30.1995 73.8892 32.2311 70.3437C32.8243 69.4383 33.6872 68.8292 34.625 68.5259C34.5761 68.5113 34.5292 68.4922 34.4797 68.4808C32.4373 67.9967 30.8238 69.9268 31.0097 71.7649C31.1785 73.4285 32.4557 75.3491 33.9759 76.1771C34.3515 76.382 34.6992 76.5413 34.953 76.8953C35.2481 77.3065 35.6002 77.7126 36.0399 77.6199L36.0412 77.6193Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M50.0713 59.4421C50.0008 59.3159 49.8784 59.196 49.6887 59.0868C48.926 58.6484 48.0568 59.4745 47.2827 59.5221C46.4979 59.5703 45.7891 59.524 45.0322 59.7727C44.3165 60.0081 43.63 60.3539 43.0634 60.8558C42.2716 61.5562 41.7354 62.5936 41.8084 63.6488C41.9531 65.7425 44.2753 66.7101 45.975 65.9862C47.5137 65.3308 48.9673 63.5403 49.3061 61.843C49.3898 61.4236 49.4393 61.0442 49.7014 60.6965C50.0065 60.2923 50.2895 59.8361 50.0706 59.4428L50.0713 59.4421Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M50.0711 59.4418C50.0007 59.3155 49.8782 59.1956 49.6885 59.0865C49.3922 58.9158 49.08 58.9374 48.7634 59.0294C48.7088 61.264 46.9221 63.8819 44.256 63.4371C43.3722 63.2899 42.6368 62.8027 42.1039 62.139C41.8856 62.6123 41.7727 63.1282 41.8082 63.6484C41.9529 65.7422 44.2751 66.7098 45.9749 65.9859C47.5128 65.3304 48.9671 63.5399 49.3059 61.8427C49.3896 61.4233 49.4391 61.0439 49.7012 60.6962C50.0064 60.292 50.2893 59.8358 50.0704 59.4424L50.0711 59.4418Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M50.0711 59.4419C50.0006 59.3157 49.8782 59.1957 49.6885 59.0866C49.6593 59.0701 49.6295 59.0631 49.6003 59.0498C48.3167 61.3581 48.2444 66.1287 44.2554 65.2424C43.2148 64.9454 42.3761 64.3021 41.8082 63.4963C41.8088 63.547 41.8044 63.5971 41.8082 63.6485C41.9529 65.7423 44.2751 66.7099 45.9748 65.986C47.5128 65.3305 48.9671 63.54 49.3059 61.8428C49.3896 61.4234 49.4391 61.044 49.7012 60.6963C50.0064 60.2921 50.2893 59.8359 50.0704 59.4425L50.0711 59.4419Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M48.0105 74.2196C48.1323 74.1416 48.2446 74.0122 48.3417 73.8161C48.7325 73.0281 47.8544 72.2115 47.7592 71.4419C47.6634 70.6614 47.6653 69.9508 47.3703 69.211C47.0911 68.5112 46.7041 67.8475 46.1686 67.312C45.4205 66.5652 44.3521 66.0932 43.3039 66.2309C41.2228 66.5037 40.3999 68.8811 41.2272 70.5333C41.9759 72.0281 43.8527 73.3694 45.5677 73.6035C45.9916 73.6613 46.3735 73.6873 46.7364 73.9278C47.1584 74.2076 47.6317 74.462 48.0105 74.219V74.2196Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M48.0105 74.2206C48.1323 74.1426 48.2439 74.0131 48.3417 73.8171C48.7325 73.029 47.8544 72.2125 47.7592 71.4428C47.6634 70.6624 47.6653 69.9518 47.3703 69.212C47.0911 68.5122 46.7041 67.8485 46.1685 67.313C45.4205 66.5662 44.352 66.0941 43.3039 66.2318C43.1916 66.2464 43.0843 66.2705 42.979 66.2965C45.9845 67.6296 45.6591 72.0786 47.2725 74.2263C47.5314 74.3316 47.7871 74.3634 48.0098 74.2206H48.0105Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M48.3416 73.8163C48.7325 73.0283 47.8543 72.2117 47.7592 71.4421C47.6634 70.6617 47.6653 69.951 47.3702 69.2112C47.0911 68.5114 46.704 67.8477 46.1685 67.3122C45.8088 66.9531 45.3748 66.6606 44.9053 66.4658C47.0137 68.5279 46.64 71.7206 47.7979 74.3004C47.8708 74.2865 47.9425 74.2636 48.0104 74.2198C48.1323 74.1418 48.2439 74.0124 48.3416 73.8163Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M51.7451 70.5008C51.6169 70.5675 51.4938 70.6867 51.379 70.8739C50.9196 71.6239 51.7222 72.516 51.7482 73.2907C51.7749 74.0768 51.7089 74.7842 51.9373 75.5469C52.153 76.2683 52.4798 76.9643 52.9658 77.5449C53.6447 78.3551 54.6669 78.9198 55.7233 78.876C57.8202 78.7891 58.8513 76.4942 58.1736 74.7747C57.5607 73.219 55.8108 71.7165 54.1231 71.3307C53.7056 71.2356 53.3281 71.1759 52.988 70.9037C52.5927 70.5878 52.1441 70.2921 51.7451 70.5002V70.5008Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M51.7449 70.5018C51.6168 70.5684 51.4937 70.6877 51.3788 70.8743C51.2006 71.1655 51.2132 71.4789 51.2964 71.7974C53.5285 71.9129 56.0962 73.7713 55.5791 76.4241C55.4078 77.3035 54.9002 78.0255 54.2226 78.5394C54.6896 78.7704 55.2022 78.8979 55.7232 78.8763C57.8201 78.7894 58.8512 76.4945 58.1735 74.7751C57.5606 73.2193 55.8107 71.7168 54.123 71.3317C53.7055 71.2365 53.328 71.1769 52.9879 70.9047C52.5926 70.5887 52.144 70.2931 51.7449 70.5012V70.5018Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M51.7453 70.5018C51.6171 70.5684 51.494 70.6877 51.3792 70.8743C51.3614 70.9028 51.3538 70.9326 51.3398 70.9612C53.6119 72.3075 58.3788 72.5106 57.3833 76.4736C57.0578 77.5052 56.3916 78.3263 55.5712 78.8719C55.622 78.8719 55.6721 78.8783 55.7229 78.8763C57.8198 78.7894 58.8509 76.4945 58.1732 74.7751C57.5603 73.2193 55.8104 71.7168 54.1227 71.3317C53.7052 71.2365 53.3277 71.1769 52.9876 70.9047C52.5923 70.5887 52.1437 70.2931 51.7446 70.5012L51.7453 70.5018Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M64.1939 60.9206C64.0739 61.0012 63.9648 61.1332 63.8715 61.3318C63.4985 62.1287 64.395 62.9249 64.5067 63.6927C64.6202 64.4705 64.6336 65.1812 64.9457 65.914C65.2408 66.6075 65.6424 67.2623 66.1899 67.7851C66.9545 68.5154 68.0331 68.9627 69.0781 68.8015C71.1522 68.4817 71.9219 66.0866 71.0577 64.4534C70.2754 62.9757 68.3694 61.6769 66.6493 61.4815C66.2242 61.4333 65.8422 61.4161 65.4736 61.1833C65.0453 60.913 64.5669 60.6694 64.1939 60.9206Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M71.0578 64.4534C70.2755 62.9757 68.3695 61.6769 66.6494 61.4815C66.2243 61.4333 65.8424 61.4161 65.4737 61.1833C65.0455 60.913 64.5671 60.6694 64.194 60.9206C64.1439 60.9542 64.0956 60.9999 64.0493 61.0532C64.9496 60.8039 65.6939 61.5564 66.1209 62.246C66.7021 63.1851 67.3461 64.0112 67.6608 65.086C67.999 66.2414 68.0167 67.5135 67.638 68.6613C68.1024 68.8161 68.5928 68.8764 69.0776 68.8022C71.1517 68.4824 71.9213 66.0872 71.0572 64.454L71.0578 64.4534Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M71.0579 64.4533C70.5255 63.4476 69.4735 62.5264 68.3131 61.9775C69.5694 63.3937 70.5319 65.1005 69.7254 67.0826C69.3765 67.8364 68.874 68.3744 68.2979 68.8084C68.5561 68.8401 68.8181 68.8414 69.0783 68.8014C71.1524 68.4816 71.922 66.0865 71.0579 64.4533Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M57.2412 52.28C57.1213 52.3606 57.0122 52.4925 56.9189 52.6911C56.5458 53.488 57.4424 54.2843 57.554 55.052C57.6676 55.8299 57.6809 56.5405 57.9924 57.2734C58.2875 57.9668 58.6891 58.6216 59.2367 59.1445C60.0012 59.8747 61.0798 60.322 62.1248 60.1609C64.199 59.8411 64.9686 57.4459 64.1044 55.8128C63.3221 54.3351 61.4161 53.0363 59.696 52.8409C59.2709 52.7926 58.889 52.7755 58.5203 52.5427C58.0921 52.2724 57.6137 52.0287 57.2406 52.28H57.2412Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M64.1052 55.8128C63.3229 54.3351 61.4169 53.0363 59.6968 52.8409C59.2717 52.7926 58.8897 52.7755 58.5211 52.5427C58.0928 52.2724 57.6144 52.0287 57.2413 52.28C57.1912 52.3136 57.143 52.3593 57.0967 52.4126C57.997 52.1632 58.7413 52.9157 59.1683 53.6054C59.7495 54.5444 60.3935 55.3705 60.7082 56.4454C61.0463 57.6008 61.0641 58.8729 60.6853 60.0207C61.1498 60.1755 61.6402 60.2358 62.125 60.1615C64.1991 59.8417 64.9687 57.4466 64.1045 55.8134L64.1052 55.8128Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M64.1052 55.8127C63.5729 54.807 62.5209 53.8857 61.3604 53.3369C62.6167 54.7531 63.5792 56.4598 62.7728 58.442C62.4238 59.1957 61.9213 59.7338 61.3452 60.1678C61.6034 60.1995 61.8655 60.2007 62.1256 60.1608C64.1998 59.841 64.9694 57.4458 64.1052 55.8127Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M60.2717 67.145C60.3808 67.0498 60.4728 66.9058 60.5407 66.697C60.8129 65.8608 59.825 65.18 59.6195 64.4325C59.4107 63.6743 59.3098 62.9713 58.9108 62.2823C58.5326 61.6307 58.0536 61.0298 57.4457 60.5787C56.5968 59.9487 55.4712 59.6371 54.4541 59.9258C52.4346 60.4981 51.9663 62.9701 53.0253 64.4846C53.9834 65.8551 56.0346 66.9089 57.7661 66.8912C58.1938 66.8867 58.5751 66.8569 58.9698 67.0422C59.4279 67.2573 59.9329 67.4406 60.2724 67.145H60.2717Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M53.0251 64.4841C53.9832 65.8546 56.0351 66.9085 57.766 66.8907C58.1943 66.8863 58.575 66.8564 58.9696 67.0417C59.4277 67.2568 59.9328 67.4402 60.2722 67.1445C60.3179 67.1045 60.3598 67.0538 60.3991 66.9954C59.5362 67.3539 58.705 66.6985 58.1968 66.0671C57.504 65.2068 56.7635 64.4663 56.3187 63.4385C55.841 62.3338 55.6665 61.0731 55.9006 59.8873C55.4203 59.7908 54.9261 59.7915 54.4546 59.9254C52.4351 60.4977 51.9668 62.9696 53.0258 64.4841H53.0251Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M53.0253 64.4835C53.6769 65.4155 54.8348 66.2004 56.0537 66.602C54.6331 65.3514 53.4669 63.776 54.0233 61.7095C54.2771 60.9183 54.7092 60.3232 55.2276 59.8213C54.9674 59.8213 54.7073 59.8524 54.4541 59.9241C52.4346 60.4964 51.9663 62.9683 53.0253 64.4828V64.4835Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M66.9016 47.7661C66.8058 47.8746 66.7334 48.0294 66.6935 48.2451C66.5336 49.1099 67.602 49.655 67.904 50.3694C68.2105 51.0933 68.4028 51.7773 68.8888 52.408C69.3488 53.0044 69.9033 53.5374 70.5651 53.9047C71.4895 54.418 72.6455 54.5792 73.6163 54.1598C75.5432 53.3273 75.6828 50.8154 74.4348 49.4526C73.3054 48.2198 71.1329 47.4438 69.4192 47.6887C68.9954 47.749 68.6216 47.8289 68.2061 47.6969C67.7239 47.5434 67.1991 47.4286 66.9009 47.7655L66.9016 47.7661Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M66.9016 47.7661C66.8058 47.8746 66.7334 48.0294 66.6935 48.2452C66.5336 49.11 67.602 49.655 67.904 50.3694C68.2105 51.094 68.4028 51.7773 68.8888 52.408C69.3488 53.0044 69.9033 53.5374 70.5651 53.9048C71.4889 54.418 72.6455 54.5792 73.6163 54.1598C73.7204 54.1148 73.8168 54.0627 73.9107 54.0082C70.6545 53.5494 69.7491 49.1817 67.609 47.558C67.3317 47.5276 67.0767 47.5669 66.9016 47.7655V47.7661Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M66.6935 48.2447C66.5336 49.1095 67.602 49.6545 67.904 50.3689C68.2105 51.0935 68.4028 51.7769 68.8888 52.4075C69.3488 53.004 69.9033 53.5369 70.5651 53.9043C71.0092 54.1511 71.5073 54.3135 72.0123 54.3719C69.4198 52.9665 68.904 49.7935 67.0843 47.6299C67.0177 47.6635 66.9555 47.7048 66.9016 47.7657C66.8058 47.8742 66.7334 48.029 66.6935 48.2447Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M31.4831 49.6645C31.5923 49.5693 31.6843 49.4253 31.7522 49.2165C32.0244 48.3803 31.0365 47.6995 30.8309 46.9521C30.6221 46.1939 30.5213 45.4909 30.1222 44.8018C29.744 44.1502 29.265 43.5493 28.6572 43.0982C27.8082 42.4682 26.6826 42.1567 25.6656 42.4453C23.646 43.0176 23.1778 45.4896 24.2367 47.0041C25.1948 48.3746 27.2461 49.4285 28.9776 49.4107C29.4052 49.4063 29.7865 49.3764 30.1812 49.5617C30.6393 49.7768 31.1443 49.9602 31.4838 49.6645H31.4831Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M24.2366 47.004C25.1946 48.3745 27.2466 49.4283 28.9774 49.4106C29.4057 49.4061 29.7864 49.3763 30.181 49.5616C30.6391 49.7767 31.1442 49.96 31.4836 49.6644C31.5293 49.6244 31.5712 49.5736 31.6105 49.5153C30.7476 49.8738 29.9165 49.2183 29.4082 48.587C28.7154 47.7267 27.9749 46.9862 27.5302 45.9584C27.0524 44.8537 26.8779 43.593 27.112 42.4078C26.6317 42.3113 26.1375 42.312 25.6661 42.4459C23.6465 43.0182 23.1783 45.4901 24.2372 47.0046L24.2366 47.004Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M24.2367 47.004C24.8883 47.936 26.0463 48.7209 27.2651 49.1225C25.8445 47.8719 24.6783 46.2965 25.2348 44.23C25.4885 43.4388 25.9206 42.8437 26.439 42.3418C26.1789 42.3418 25.9187 42.3729 25.6656 42.4446C23.646 43.0169 23.1778 45.4888 24.2367 47.0033V47.004Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M35.8565 81.5794C35.7505 81.6778 35.663 81.825 35.6014 82.035C35.3552 82.8795 36.3634 83.5292 36.5925 84.2696C36.8247 85.0209 36.9472 85.7207 37.3678 86.3971C37.7656 87.0366 38.2637 87.6223 38.8849 88.0543C39.7528 88.6577 40.8873 88.9344 41.8949 88.6146C43.8954 87.9795 44.2869 85.4948 43.1822 84.014C42.1823 82.6739 40.0986 81.6835 38.369 81.7552C37.9414 81.773 37.5613 81.8148 37.1616 81.6416C36.6972 81.4405 36.1864 81.2736 35.8565 81.5788V81.5794Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M35.8564 81.5794C35.7505 81.6778 35.6629 81.825 35.6014 82.035C35.5056 82.363 35.6001 82.6619 35.7638 82.9474C37.9477 82.4728 40.9139 83.5907 41.1112 86.2867C41.1766 87.18 40.8771 88.0099 40.3581 88.6844C40.8695 88.7846 41.3974 88.7726 41.8942 88.6152C43.8947 87.9801 44.2862 85.4955 43.1815 84.014C42.1816 82.6739 40.0979 81.6841 38.3683 81.7552C37.9407 81.773 37.5606 81.8148 37.1609 81.6416C36.6965 81.4405 36.1857 81.2736 35.8558 81.5788L35.8564 81.5794Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M35.8566 81.5798C35.7506 81.6781 35.6631 81.8253 35.6015 82.0353C35.592 82.0677 35.5926 82.0982 35.5869 82.1299C38.1331 82.8322 42.7858 81.7758 42.8663 85.8613C42.8232 86.9424 42.3962 87.9094 41.7477 88.6517C41.7966 88.639 41.8467 88.6314 41.8956 88.6162C43.8961 87.9811 44.2876 85.4964 43.1829 84.0149C42.183 82.6749 40.0994 81.6851 38.3698 81.7562C37.9421 81.7739 37.5621 81.8158 37.1623 81.6426C36.6979 81.4415 36.1871 81.2746 35.8572 81.5798H35.8566Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M51.7657 77.0071C51.661 76.9074 51.5087 76.8288 51.2949 76.7806C50.4371 76.587 49.8508 77.6333 49.125 77.9068C48.3896 78.1847 47.698 78.3496 47.0489 78.8115C46.4347 79.2481 45.8808 79.781 45.4875 80.4276C44.938 81.3311 44.7324 82.4801 45.1131 83.4661C45.8701 85.4241 48.3744 85.662 49.7842 84.4679C51.0601 83.3874 51.9205 81.2473 51.7428 79.5253C51.6991 79.0996 51.6337 78.7227 51.7822 78.3128C51.9541 77.837 52.0899 77.3173 51.7644 77.0064L51.7657 77.0071Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M51.7164 77.0071C51.6117 76.9068 51.4594 76.8288 51.2456 76.7806C50.3878 76.587 49.8015 77.6333 49.0756 77.9068C48.3396 78.1847 47.6487 78.3496 46.9996 78.8115C46.3854 79.2481 45.8315 79.781 45.4382 80.4282C44.8887 81.3317 44.6831 82.4807 45.0638 83.4667C45.1044 83.5727 45.1526 83.671 45.2034 83.7668C45.789 80.5316 50.1892 79.7975 51.8946 77.7221C51.9359 77.4461 51.9061 77.1898 51.7151 77.0071H51.7164Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M51.2461 76.7806C50.3882 76.587 49.802 77.6333 49.0761 77.9068C48.3401 78.1847 47.6492 78.3496 47.0001 78.8115C46.3859 79.2481 45.832 79.781 45.4386 80.4282C45.1747 80.8622 44.9926 81.3533 44.9146 81.8558C46.4202 79.3204 49.611 78.9289 51.8444 77.1949C51.8133 77.127 51.7746 77.0629 51.7162 77.0071C51.6115 76.9068 51.4592 76.8288 51.2454 76.7806H51.2461Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M54.0058 82.2626C53.8776 82.3292 53.7545 82.4485 53.6397 82.6356C53.1803 83.3856 53.983 84.2777 54.009 85.0524C54.0356 85.8385 53.9696 86.5459 54.198 87.3086C54.4138 88.03 54.7405 88.726 55.2265 89.3066C55.9054 90.1168 56.9276 90.6815 57.984 90.6377C60.081 90.5508 61.112 88.2559 60.4344 86.5364C59.8215 84.9807 58.0716 83.4782 56.3838 83.0925C55.9663 82.9973 55.5888 82.9376 55.2487 82.6654C54.8535 82.3495 54.4049 82.0538 54.0058 82.2619V82.2626Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M54.0057 82.2626C53.8775 82.3292 53.7544 82.4485 53.6396 82.635C53.4613 82.9262 53.474 83.2397 53.5571 83.5582C55.7892 83.6736 58.357 85.532 57.8399 88.1848C57.6686 89.0642 57.161 89.7862 56.4833 90.3002C56.9503 90.5311 57.463 90.6587 57.9839 90.6371C60.0809 90.5502 61.1119 88.2552 60.4343 86.5358C59.8214 84.98 58.0715 83.4776 56.3837 83.0925C55.9662 82.9973 55.5887 82.9376 55.2486 82.6654C54.8534 82.3495 54.4048 82.0538 54.0057 82.2619V82.2626Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M54.006 82.2626C53.8779 82.3292 53.7548 82.4485 53.6399 82.635C53.6222 82.6635 53.6145 82.6934 53.6006 82.7219C55.8727 84.0683 60.6395 84.2713 59.644 88.2343C59.3185 89.266 58.6523 90.087 57.832 90.6326C57.8827 90.6326 57.9328 90.639 57.9836 90.6371C60.0806 90.5502 61.1116 88.2552 60.434 86.5358C59.8211 84.98 58.0712 83.4776 56.3834 83.0925C55.9659 82.9973 55.5884 82.9376 55.2483 82.6654C54.8531 82.3495 54.4045 82.0538 54.0054 82.2619L54.006 82.2626Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M64.6443 29.3693C64.5345 29.2754 64.3785 29.205 64.1621 29.1682C63.2948 29.0203 62.765 30.0964 62.0556 30.4086C61.3355 30.7252 60.6547 30.9269 60.031 31.4225C59.4409 31.8907 58.9162 32.4529 58.5583 33.1197C58.0577 34.0511 57.9137 35.2097 58.3464 36.1741C59.2061 38.089 61.72 38.1937 63.0644 36.926C64.2814 35.7788 65.0263 33.5962 64.7572 31.8863C64.6906 31.4637 64.6056 31.0906 64.7319 30.6738C64.8784 30.189 64.9857 29.6631 64.6449 29.3699L64.6443 29.3693Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M63.0646 36.9257C64.2815 35.7786 65.0264 33.596 64.7574 31.886C64.6908 31.4635 64.6058 31.0904 64.732 30.6735C64.8786 30.1894 64.9858 29.6628 64.6451 29.3697C64.5988 29.3303 64.543 29.2961 64.4789 29.2656C64.9585 30.0676 64.4313 30.9851 63.8799 31.5796C63.1293 32.3898 62.5044 33.2299 61.552 33.8193C60.5286 34.4525 59.3066 34.8078 58.0991 34.7482C58.0738 35.2374 58.1455 35.7259 58.3466 36.1732C59.2063 38.0881 61.7201 38.1928 63.0646 36.9251V36.9257Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M63.0643 36.9258C63.8917 36.1454 64.5008 34.886 64.7209 33.6221C63.6899 35.2095 62.3004 36.5914 60.1755 36.3415C59.3564 36.205 58.7041 35.8637 58.1318 35.424C58.1699 35.6816 58.2384 35.9341 58.3463 36.174C59.206 38.0888 61.7198 38.1935 63.0643 36.9258Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M62.6226 27.8421C62.7438 27.9214 62.9075 27.9703 63.127 27.9785C64.0064 28.0115 64.3903 26.8752 65.0527 26.4723C65.7246 26.0643 66.3736 25.7743 66.9269 25.2014C67.4504 24.6596 67.897 24.034 68.1642 23.3259C68.5379 22.3367 68.529 21.1693 67.9732 20.2702C66.8698 18.4848 64.3642 18.7113 63.1974 20.1439C62.1417 21.4408 61.6893 23.7028 62.181 25.3626C62.3022 25.7731 62.4354 26.1316 62.365 26.5611C62.2831 27.0604 62.2457 27.5966 62.6226 27.8421Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M62.6228 27.8419C62.744 27.9212 62.9077 27.9701 63.1272 27.9783C64.0066 28.0113 64.3905 26.875 65.0529 26.4721C65.7248 26.0641 66.3739 25.7741 66.9271 25.2012C67.4506 24.6593 67.8973 24.0337 68.1644 23.3257C68.5381 22.3365 68.5292 21.1691 67.9734 20.27C67.9138 20.1736 67.8484 20.0854 67.7812 20.001C67.8002 23.2889 63.6101 24.8192 62.3145 27.1725C62.3246 27.4517 62.4007 27.6979 62.6228 27.8425V27.8419Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M63.127 27.9781C64.0064 28.0111 64.3903 26.8748 65.0527 26.4719C65.7246 26.0639 66.3736 25.7739 66.9269 25.201C67.4504 24.6591 67.897 24.0335 68.1642 23.3255C68.3437 22.8502 68.4325 22.3338 68.4167 21.8262C67.4028 24.5957 64.3382 25.5664 62.4614 27.6812C62.5039 27.7421 62.5547 27.7979 62.6219 27.8423C62.7431 27.9216 62.9068 27.9705 63.1264 27.9788L63.127 27.9781Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M58.999 39.4185C58.8854 39.329 58.7262 39.2656 58.5086 39.2383C57.6361 39.1273 57.1527 40.2256 56.4566 40.5676C55.7511 40.9146 55.0792 41.1456 54.4771 41.6665C53.9079 42.1595 53.4073 42.7432 53.078 43.4246C52.618 44.3764 52.5229 45.54 52.9968 46.4854C53.9377 48.3615 56.4535 48.359 57.7427 47.0355C58.9096 45.8376 59.5605 43.6251 59.2192 41.9279C59.1348 41.5085 59.0339 41.1392 59.1424 40.7173C59.268 40.2268 59.3531 39.6964 58.9997 39.4185H58.999Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M58.9988 39.4185C58.8853 39.329 58.726 39.2656 58.509 39.2383C58.1702 39.1951 57.8898 39.3354 57.6334 39.5422C58.4449 41.6252 57.8054 44.7291 55.1742 45.3471C54.3018 45.552 53.4351 45.3858 52.6883 44.9791C52.6699 45.5 52.7638 46.0196 52.9973 46.4853C53.9382 48.3615 56.4539 48.359 57.7432 47.0354C58.91 45.8375 59.561 43.6251 59.2196 41.9279C59.1353 41.5085 59.0344 41.1398 59.1422 40.7173C59.2679 40.2268 59.3529 39.6964 58.9995 39.4185H58.9988Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M58.9991 39.4184C58.8855 39.3289 58.7262 39.2655 58.5092 39.2382C58.4762 39.2337 58.4458 39.2388 58.4141 39.2382C58.1197 41.863 59.8924 46.2923 55.8698 47.0125C54.795 47.1394 53.7735 46.8691 52.9385 46.345C52.9588 46.3913 52.974 46.4395 52.9968 46.4852C53.9378 48.3614 56.4535 48.3589 57.7428 47.0353C58.9096 45.8374 59.5606 43.625 59.2192 41.9277C59.1348 41.5083 59.034 41.1397 59.1418 40.7171C59.2674 40.2267 59.3525 39.6963 58.9991 39.4184Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M49.1401 30.6307C49.1883 30.4943 49.1972 30.3236 49.1534 30.1085C48.9776 29.2462 47.7829 29.1416 47.2354 28.5927C46.6802 28.0363 46.2456 27.4741 45.5578 27.0719C44.9074 26.6912 44.1943 26.405 43.443 26.3124C42.3936 26.183 41.2611 26.4672 40.5187 27.2197C39.0448 28.7139 39.8557 31.0951 41.5237 31.8901C43.0332 32.6096 45.3376 32.5151 46.8344 31.6459C47.2043 31.4308 47.5215 31.2169 47.9555 31.184C48.4599 31.1452 48.9897 31.0552 49.1394 30.6313L49.1401 30.6307Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M49.1393 30.6307C49.1875 30.4943 49.1964 30.3236 49.1526 30.1085C48.9769 29.2462 47.7822 29.1416 47.2346 28.5927C46.6794 28.0363 46.2448 27.4741 45.5577 27.0719C44.9073 26.6912 44.1942 26.405 43.4429 26.3124C42.3935 26.183 41.2609 26.4672 40.5186 27.2197C40.4387 27.3003 40.3689 27.3847 40.3022 27.4703C43.4931 26.6753 45.9694 30.3858 48.5619 31.0888C48.831 31.0133 49.0518 30.8807 49.14 30.6313L49.1393 30.6307Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M49.1525 30.1089C48.9768 29.2467 47.7821 29.142 47.2345 28.5931C46.6793 28.0367 46.2447 27.4746 45.5576 27.0723C44.9072 26.6916 44.1941 26.4055 43.4428 26.3128C42.9384 26.2506 42.4162 26.2862 41.9258 26.4213C44.8558 26.7525 46.5232 29.5011 49.0212 30.8253C49.0701 30.7694 49.1126 30.7072 49.1399 30.6311C49.1881 30.4947 49.197 30.324 49.1532 30.1089H49.1525Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M45.0049 43.452C44.8603 43.4551 44.6972 43.5078 44.5119 43.6245C43.7683 44.0941 44.0938 45.2488 43.774 45.9556C43.4505 46.672 43.0786 47.2779 42.946 48.0628C42.8204 48.8057 42.8058 49.5741 42.9854 50.3095C43.236 51.3367 43.9028 52.2948 44.8698 52.7224C46.7891 53.5713 48.7287 51.9693 48.881 50.1274C49.0193 48.4612 48.1139 46.3395 46.7707 45.2476C46.4389 44.9779 46.1261 44.7571 45.9414 44.3625C45.7263 43.9044 45.4548 43.4412 45.0049 43.4513V43.452Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M45.0048 43.4523C44.8601 43.4555 44.6971 43.5082 44.5118 43.6249C43.7682 44.0944 44.0937 45.2492 43.7745 45.956C43.4509 46.6723 43.0791 47.2783 42.9465 48.0631C42.8209 48.8061 42.8063 49.5745 42.9859 50.3098C43.2365 51.337 43.9033 52.2951 44.8703 52.7228C44.9743 52.7684 45.0778 52.804 45.1812 52.8363C43.3075 50.1347 45.8994 46.5042 45.638 43.8305C45.4718 43.6059 45.27 43.446 45.0054 43.4523H45.0048Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M44.5118 43.6249C43.7682 44.0944 44.0937 45.2491 43.7745 45.956C43.4509 46.6723 43.0791 47.2782 42.9465 48.0631C42.8209 48.8061 42.8063 49.5744 42.9859 50.3098C43.1064 50.8034 43.3253 51.2793 43.6248 51.6898C42.8964 48.8321 44.876 46.2992 45.2288 43.4942C45.159 43.4681 45.086 43.4504 45.0054 43.4523C44.8608 43.4555 44.6977 43.5081 44.5124 43.6249H44.5118Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M81.5676 80.494C81.4369 80.5561 81.31 80.671 81.1888 80.8537C80.7035 81.5872 81.4737 82.5065 81.4725 83.2819C81.4712 84.068 81.3805 84.7729 81.5816 85.5438C81.7719 86.2728 82.0739 86.9796 82.539 87.5767C83.1887 88.4104 84.1906 89.0112 85.2476 89.0043C87.3465 88.9916 88.4575 86.7341 87.8414 84.9918C87.2837 83.4157 85.5877 81.8517 83.9152 81.4076C83.5015 81.2978 83.1266 81.2249 82.796 80.9413C82.4121 80.6113 81.9743 80.3004 81.5683 80.4946L81.5676 80.494Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M81.5676 80.4944C81.4369 80.5565 81.31 80.6714 81.1888 80.8541C80.7035 81.5876 81.4737 82.5069 81.4725 83.2823C81.4712 84.0684 81.3805 84.7733 81.5816 85.5436C81.7719 86.2726 82.0739 86.9794 82.539 87.5765C83.1887 88.4102 84.1906 89.011 85.2476 89.004C85.3612 89.0034 85.4703 88.9933 85.5776 88.9799C82.7605 87.2846 83.6348 82.9105 82.2998 80.5794C82.0562 80.443 81.8062 80.3795 81.5676 80.4937V80.4944Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M81.1888 80.8541C80.7035 81.5876 81.4737 82.5069 81.4725 83.2823C81.4712 84.0684 81.3805 84.7733 81.5816 85.5436C81.7719 86.2726 82.0739 86.9794 82.539 87.5765C82.8512 87.9774 83.2458 88.3213 83.6881 88.5726C81.8512 86.2656 82.6177 83.1433 81.7884 80.4404C81.7142 80.4455 81.64 80.4588 81.5676 80.4937C81.4369 80.5559 81.31 80.6707 81.1888 80.8535V80.8541Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M74.2459 84.6443C74.2053 84.5053 74.112 84.3619 73.9509 84.2135C73.3037 83.6177 72.2739 84.2331 71.5081 84.1088C70.7321 83.9831 70.0507 83.782 69.2576 83.8588C68.5077 83.9311 67.7621 84.117 67.0985 84.4819C66.1721 84.9913 65.4209 85.8853 65.2597 86.9303C64.94 89.0044 66.9925 90.4593 68.8103 90.1268C70.4549 89.8261 72.2676 88.3991 72.9712 86.8174C73.1451 86.4265 73.2764 86.0674 73.6095 85.7857C73.9959 85.4589 74.3722 85.0757 74.2453 84.6443H74.2459Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M68.8111 90.127C70.4557 89.8263 72.2684 88.3993 72.9721 86.8176C73.1459 86.4267 73.2773 86.0676 73.6104 85.7859C73.9968 85.4591 74.373 85.0759 74.2461 84.6444C74.229 84.5861 74.2004 84.5271 74.1636 84.4668C74.13 85.4008 73.1891 85.8836 72.4029 86.0835C71.3326 86.355 70.351 86.7205 69.2312 86.6976C68.0275 86.6723 66.8087 86.3062 65.8284 85.6C65.541 85.9965 65.3354 86.4457 65.2612 86.9305C64.9414 89.0046 66.994 90.4595 68.8118 90.127H68.8111Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M68.8108 90.1269C69.9294 89.922 71.1254 89.1961 71.9978 88.2545C70.2695 89.0261 68.3514 89.4302 66.7043 88.0648C66.0908 87.5052 65.7291 86.8638 65.488 86.1836C65.3801 86.4209 65.3002 86.6696 65.2602 86.9297C64.9404 89.0039 66.993 90.4587 68.8108 90.1263V90.1269Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M62.3992 84.2009C62.4582 84.3329 62.5686 84.4636 62.7482 84.5892C63.4683 85.0949 64.408 84.35 65.1833 84.3722C65.9694 84.395 66.6712 84.5048 67.4471 84.3246C68.1812 84.1546 68.8957 83.8722 69.5054 83.4237C70.3569 82.7968 70.9844 81.8121 71.0066 80.755C71.051 78.6568 68.8252 77.4836 67.0671 78.0521C65.4758 78.5661 63.8668 80.2189 63.3763 81.8787C63.2551 82.2892 63.172 82.6623 62.8789 82.9852C62.5388 83.3602 62.2158 83.7891 62.3986 84.2003L62.3992 84.2009Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M62.3993 84.1684C62.4583 84.3004 62.5687 84.4311 62.7483 84.5567C63.4684 85.0624 64.4081 84.3175 65.1834 84.3397C65.9696 84.3625 66.6713 84.4723 67.4473 84.2927C68.1814 84.1227 68.8958 83.8404 69.5055 83.3911C70.357 82.7643 70.9845 81.7796 71.0067 80.7231C71.0093 80.6096 71.0016 80.5004 70.9915 80.3926C69.2194 83.1621 64.8706 82.1679 62.504 83.4387C62.3606 83.6786 62.2908 83.9266 62.3987 84.1684H62.3993Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M62.7481 84.5572C63.4683 85.0629 64.4079 84.318 65.1833 84.3402C65.9694 84.363 66.6711 84.4728 67.4471 84.2932C68.1812 84.1232 68.8956 83.8409 69.5054 83.3916C69.9146 83.0903 70.2693 82.7051 70.5332 82.2705C68.1767 84.0433 65.0767 83.1918 62.3516 83.9462C62.3541 84.0204 62.3662 84.0946 62.3991 84.1682C62.4582 84.3002 62.5686 84.4309 62.7481 84.5565V84.5572Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M78.4627 81.4733C78.6004 81.4289 78.7412 81.3318 78.8853 81.1662C79.4626 80.5025 78.8199 79.4905 78.9227 78.7215C79.0274 77.9424 79.2095 77.2552 79.1111 76.4653C79.0179 75.7179 78.8117 74.9774 78.4291 74.3246C77.8942 73.4128 76.9799 72.6857 75.9311 72.5537C73.8487 72.291 72.451 74.3829 72.8329 76.1906C73.1787 77.8263 74.6552 79.599 76.2553 80.2595C76.6506 80.4226 77.0135 80.5444 77.3041 80.8692C77.6417 81.2461 78.035 81.6122 78.4627 81.4733Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M72.8335 76.1909C73.1792 77.8266 74.6557 79.5993 76.2559 80.2598C76.6518 80.4229 77.0141 80.5447 77.3047 80.8696C77.6422 81.2465 78.0356 81.6126 78.4632 81.4736C78.521 81.4546 78.5793 81.4248 78.6383 81.3861C77.7037 81.3784 77.1949 80.4508 76.9741 79.6704C76.6733 78.6077 76.2806 77.6369 76.2736 76.5164C76.2654 75.3128 76.5985 74.0844 77.2774 73.0851C76.8732 72.8091 76.4183 72.6156 75.9316 72.5547C73.8493 72.292 72.4515 74.3839 72.8341 76.1915L72.8335 76.1909Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M72.8335 76.1906C73.0688 77.3034 73.8277 78.4785 74.7921 79.3249C73.9736 77.6182 73.5168 75.7122 74.8365 74.0282C75.3796 73.3995 76.0103 73.0201 76.6835 72.7612C76.4437 72.6597 76.1924 72.5867 75.9316 72.5537C73.8493 72.291 72.4515 74.3829 72.8341 76.1906H72.8335Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M83.8229 61.3595C83.7417 61.2396 83.6097 61.1311 83.4111 61.0385C82.6136 60.6673 81.8192 61.5664 81.0521 61.6806C80.2743 61.7961 79.5643 61.8119 78.8321 62.1254C78.1399 62.4223 77.4857 62.8258 76.9642 63.3747C76.2364 64.1411 75.7916 65.221 75.956 66.2654C76.2815 68.3388 78.6785 69.1015 80.3098 68.2329C81.7856 67.4468 83.0793 65.537 83.2697 63.8163C83.3166 63.3911 83.3331 63.0092 83.5647 62.6399C83.8337 62.211 84.0761 61.732 83.8236 61.3589L83.8229 61.3595Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M83.8231 61.3591C83.7419 61.2392 83.6099 61.1307 83.4113 61.0381C83.1017 60.894 82.7921 60.9423 82.4843 61.0609C82.6239 63.2917 81.0701 66.0543 78.3754 65.8424C77.4821 65.7719 76.7074 65.3506 76.1192 64.7358C75.9428 65.2263 75.8749 65.7497 75.9555 66.2649C76.281 68.3384 78.6781 69.1011 80.3093 68.2325C81.7851 67.4463 83.0789 65.5366 83.2692 63.8158C83.3162 63.3907 83.3327 63.0088 83.5636 62.6395C83.8326 62.2106 84.075 61.7316 83.8225 61.3591H83.8231Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M83.8231 61.36C83.7419 61.2401 83.61 61.1316 83.4114 61.039C83.3809 61.025 83.3505 61.0206 83.3206 61.0098C82.242 63.4208 82.5827 68.1794 78.5322 67.6414C77.4701 67.4352 76.5786 66.8673 75.9429 66.1142C75.9479 66.1649 75.9479 66.2151 75.9562 66.2658C76.2817 68.3393 78.6788 69.102 80.31 68.2334C81.7858 67.4472 83.0795 65.5374 83.2699 63.8167C83.3168 63.3916 83.3333 63.0097 83.5643 62.6404C83.8333 62.2115 84.0757 61.7324 83.8231 61.36Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M88.9072 103.943C89.0221 103.855 89.1223 103.716 89.2023 103.512C89.5233 102.693 88.578 101.956 88.4168 101.197C88.2537 100.428 88.1941 99.7199 87.8362 99.0087C87.4974 98.3355 87.0546 97.708 86.4746 97.2213C85.6644 96.5418 84.5598 96.1649 83.5275 96.3927C81.4781 96.8451 80.8639 99.2847 81.8315 100.859C82.7071 102.283 84.6924 103.457 86.4214 103.541C86.849 103.562 87.2309 103.555 87.6135 103.763C88.0583 104.005 88.5513 104.218 88.9079 103.944L88.9072 103.943Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M88.9073 103.943C89.0221 103.855 89.1224 103.716 89.2023 103.512C89.5234 102.693 88.578 101.956 88.4168 101.197C88.2538 100.428 88.1941 99.7199 87.8363 99.0087C87.4974 98.3355 87.0546 97.708 86.474 97.2213C85.6638 96.5418 84.5592 96.1649 83.5269 96.3927C83.4158 96.4168 83.3118 96.4498 83.209 96.4853C86.3186 97.5532 86.3795 102.014 88.1732 104.013C88.4397 104.096 88.6973 104.105 88.9073 103.944V103.943Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M89.2021 103.512C89.5232 102.693 88.5778 101.956 88.4167 101.197C88.2536 100.428 88.1939 99.72 87.8361 99.0087C87.4973 98.3356 87.0544 97.708 86.4739 97.2214C86.0849 96.8946 85.6262 96.6408 85.1421 96.4873C87.4212 98.3584 87.3253 101.572 88.7022 104.041C88.7739 104.021 88.843 103.992 88.9071 103.943C89.0219 103.855 89.1222 103.716 89.2021 103.512Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M35.0613 64.806C35.1679 64.9037 35.3221 64.9786 35.5372 65.0218C36.3995 65.1962 36.9623 64.1367 37.6811 63.8467C38.4102 63.5523 39.0973 63.3715 39.7362 62.8956C40.3402 62.4458 40.8821 61.9001 41.2609 61.2447C41.7894 60.3291 41.9696 59.1756 41.5667 58.1985C40.766 56.2583 38.2572 56.0762 36.8741 57.302C35.6229 58.4111 34.8107 60.5696 35.0271 62.2871C35.0804 62.7116 35.154 63.0872 35.015 63.4996C34.8539 63.9793 34.7302 64.5021 35.062 64.8054L35.0613 64.806Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M36.8736 57.302C35.6224 58.4111 34.8102 60.5702 35.0266 62.2871C35.0799 62.7116 35.1535 63.0872 35.0145 63.4996C34.8534 63.9793 34.7297 64.5021 35.0615 64.8054C35.1065 64.8466 35.1617 64.8822 35.2239 64.9145C34.769 64.0979 35.3242 63.1976 35.8939 62.6202C36.6693 61.8335 37.3196 61.0131 38.2897 60.4528C39.3322 59.8514 40.5644 59.5335 41.7692 59.6306C41.8099 59.1426 41.7528 58.6515 41.5656 58.1985C40.7649 56.2583 38.2561 56.0762 36.8729 57.302H36.8736Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M36.8737 57.3023C36.0223 58.0567 35.3757 59.2972 35.1162 60.5541C36.1955 58.999 37.6269 57.6602 39.7435 57.9762C40.5582 58.1373 41.1996 58.499 41.758 58.9558C41.7275 58.6969 41.6672 58.4425 41.567 58.1995C40.7663 56.2593 38.2575 56.0772 36.8744 57.303L36.8737 57.3023Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M33.4345 59.465C33.3165 59.3812 33.1547 59.326 32.9358 59.3095C32.059 59.2423 31.6307 60.3628 30.9531 60.7397C30.2653 61.1216 29.6061 61.3856 29.0306 61.9363C28.4868 62.4572 28.0161 63.065 27.721 63.7623C27.3092 64.7356 27.2724 65.9031 27.7927 66.8231C28.8263 68.6498 31.3382 68.5216 32.5602 67.1353C33.6655 65.8809 34.2054 63.638 33.7791 61.9604C33.6737 61.5455 33.5545 61.1825 33.6414 60.7555C33.7423 60.2594 33.8006 59.7258 33.4339 59.4656L33.4345 59.465Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M32.5198 67.0879C33.6251 65.8335 34.165 63.5906 33.7387 61.9131C33.6333 61.4981 33.5141 61.1352 33.601 60.7082C33.7019 60.212 33.7602 59.6784 33.3935 59.4183C33.344 59.3834 33.2844 59.3542 33.2184 59.3301C33.7704 60.0838 33.3301 61.0464 32.8364 61.6897C32.1639 62.5659 31.6195 63.4606 30.7255 64.1357C29.7649 64.8609 28.581 65.3278 27.3735 65.3805C27.3932 65.8697 27.5106 66.35 27.7517 66.777C28.7853 68.6037 31.2972 68.4755 32.5192 67.0892L32.5198 67.0879Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M32.5197 67.0879C33.2716 66.2345 33.7608 64.9236 33.8636 63.6445C32.9842 65.3208 31.7279 66.8252 29.589 66.7732C28.7604 66.7135 28.0796 66.4337 27.4692 66.0486C27.5314 66.3017 27.6221 66.5467 27.7522 66.7757C28.7858 68.6024 31.2977 68.4742 32.5197 67.0879Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M20.2177 63.0449C20.3458 63.1121 20.5133 63.1458 20.7322 63.1337C21.6104 63.0849 21.8876 61.918 22.5107 61.4561C23.142 60.9873 23.7613 60.6389 24.2593 60.0178C24.7301 59.4296 25.1172 58.7653 25.3183 58.0356C25.5987 57.0167 25.482 55.8543 24.8456 55.0104C23.5817 53.3348 21.1079 53.7916 20.0787 55.3264C19.1473 56.7153 18.9062 59.0089 19.5489 60.6167C19.7076 61.0139 19.8738 61.3584 19.8433 61.793C19.8084 62.2981 19.8205 62.8349 20.2183 63.0449H20.2177Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M20.2178 63.0452C20.3459 63.1124 20.5134 63.1461 20.7323 63.134C21.6104 63.0852 21.8877 61.9183 22.5101 61.4564C23.1415 60.9876 23.7607 60.6392 24.2588 60.0181C24.7296 59.4299 25.1166 58.7656 25.3177 58.0359C25.5982 57.017 25.4814 55.8546 24.845 55.0107C24.7765 54.92 24.7035 54.8388 24.6287 54.7607C24.9516 58.0328 20.9214 59.9445 19.8491 62.4069C19.8853 62.6835 19.9836 62.9221 20.2178 63.0452Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M20.7322 63.134C21.6103 63.0852 21.8876 61.9183 22.51 61.4564C23.1413 60.9876 23.7605 60.6392 24.2586 60.0181C24.7294 59.4299 25.1164 58.7656 25.3176 58.0359C25.4527 57.5461 25.4927 57.0239 25.4305 56.5195C24.6774 59.3709 21.7156 60.6208 20.0425 62.9005C20.0907 62.957 20.1459 63.0084 20.217 63.0458C20.3451 63.1131 20.5126 63.1467 20.7315 63.1346L20.7322 63.134Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M49.0256 103.725C49.0941 103.598 49.129 103.43 49.1189 103.212C49.0776 102.333 47.9127 102.046 47.4559 101.42C46.9927 100.785 46.6488 100.162 46.0315 99.6597C45.4471 99.1838 44.786 98.7917 44.0582 98.5849C43.0412 98.2962 41.8788 98.4034 41.0292 99.0328C39.3434 100.283 39.7806 102.761 41.3065 103.802C42.6878 104.744 44.9795 105.005 46.5924 104.375C46.9908 104.219 47.3366 104.056 47.7712 104.09C48.2757 104.129 48.8131 104.121 49.0262 103.725L49.0256 103.725Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M49.0258 103.725C49.0943 103.598 49.1292 103.43 49.119 103.212C49.1032 102.87 48.9166 102.618 48.6685 102.401C46.7569 103.56 43.5889 103.466 42.5261 100.981C42.174 100.157 42.1879 99.2745 42.4595 98.4688C41.943 98.5404 41.4481 98.7225 41.0287 99.0334C39.3429 100.284 39.7801 102.761 41.306 103.803C42.6873 104.745 44.979 105.005 46.5919 104.375C46.9903 104.22 47.3361 104.057 47.7708 104.09C48.2752 104.13 48.8126 104.122 49.0258 103.725Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M49.0256 103.726C49.0941 103.598 49.129 103.431 49.1189 103.212C49.1176 103.178 49.1068 103.15 49.1024 103.118C46.4661 103.281 42.4092 105.792 41.0058 101.954C40.6955 100.917 40.785 99.8636 41.1574 98.9512C41.1149 98.9791 41.0705 99.0026 41.0292 99.033C39.3434 100.284 39.7806 102.761 41.3065 103.802C42.6878 104.745 44.9795 105.005 46.5924 104.375C46.9908 104.219 47.3366 104.056 47.7712 104.09C48.2757 104.129 48.8131 104.122 49.0262 103.725L49.0256 103.726Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M53.2534 91.2595C53.1094 91.2715 52.9502 91.3337 52.7719 91.4625C52.0587 91.9777 52.4553 93.1096 52.1799 93.8348C51.9014 94.5702 51.5676 95.1977 51.4839 95.9895C51.4046 96.7389 51.4375 97.5066 51.6622 98.2299C51.9756 99.2393 52.7008 100.155 53.6925 100.522C55.6607 101.25 57.4969 99.5306 57.5356 97.683C57.5705 96.0111 56.535 93.9497 55.1271 92.9434C54.7787 92.6947 54.4532 92.4942 54.2445 92.1116C54.0015 91.6674 53.7014 91.222 53.2534 91.2601V91.2595Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M57.536 97.682C57.5703 96.0101 56.5354 93.9487 55.1275 92.9424C54.7792 92.6937 54.4537 92.4932 54.2449 92.1106C54.0019 91.6665 53.7018 91.221 53.2539 91.2591C53.1936 91.2642 53.1295 91.2807 53.0635 91.3048C53.9721 91.5243 54.2563 92.5433 54.2944 93.3535C54.3464 94.4569 54.5076 95.4918 54.2608 96.5843C53.9949 97.7588 53.3922 98.8793 52.5039 99.6984C52.8351 100.059 53.2342 100.351 53.6942 100.521C55.6624 101.249 57.4986 99.5296 57.5373 97.682H57.536Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M57.536 97.6818C57.5594 96.5448 57.0874 95.2277 56.34 94.1846C56.7498 96.0328 56.7619 97.9921 55.0945 99.3328C54.4232 99.822 53.7227 100.048 53.0083 100.147C53.2189 100.3 53.4467 100.429 53.6935 100.521C55.6617 101.249 57.4979 99.5295 57.5366 97.6818H57.536Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M60.2891 97.7638C60.258 97.9053 60.2701 98.0759 60.3405 98.2834C60.6209 99.1171 61.8201 99.074 62.4311 99.5511C63.051 100.035 63.5516 100.539 64.2832 100.854C64.9754 101.151 65.7184 101.347 66.4753 101.347C67.5324 101.346 68.6211 100.924 69.2658 100.086C70.5442 98.4217 69.4453 96.1585 67.6922 95.5748C66.106 95.0469 63.8301 95.4251 62.4521 96.472C62.1113 96.7308 61.8233 96.9821 61.3963 97.069C60.9001 97.1699 60.3855 97.3241 60.2897 97.7638H60.2891Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M60.2891 97.7638C60.258 97.9053 60.2701 98.0759 60.3405 98.2834C60.4496 98.6076 60.6977 98.798 60.9959 98.9395C62.5168 97.3012 65.5896 96.524 67.2925 98.6229C67.8572 99.3189 68.0856 100.171 68.045 101.021C68.5222 100.811 68.9485 100.499 69.2658 100.086C70.5442 98.4217 69.446 96.1585 67.6922 95.5748C66.106 95.0469 63.8301 95.4251 62.4521 96.472C62.1113 96.7308 61.8233 96.9821 61.3963 97.069C60.9001 97.1699 60.3855 97.3241 60.2897 97.7638H60.2891Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M60.2891 97.7634C60.258 97.9049 60.2701 98.0756 60.3405 98.2831C60.3513 98.3148 60.369 98.3395 60.3824 98.3687C62.8733 97.49 66.087 93.9641 68.4879 97.2698C69.0703 98.1815 69.2727 99.2189 69.1649 100.199C69.1979 100.16 69.2347 100.125 69.2658 100.085C70.5442 98.4207 69.446 96.1575 67.6922 95.5738C66.106 95.0459 63.8301 95.4241 62.4521 96.471C62.1113 96.7298 61.8233 96.9811 61.3963 97.068C60.9001 97.1689 60.3855 97.3231 60.2897 97.7628L60.2891 97.7634Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M65.5405 110.537C65.6134 110.662 65.7384 110.779 65.9313 110.883C66.7035 111.305 67.5537 110.458 68.3265 110.393C69.11 110.327 69.82 110.358 70.5706 110.091C71.2806 109.84 71.9595 109.479 72.5147 108.964C73.29 108.245 73.8027 107.196 73.7062 106.144C73.514 104.054 71.1702 103.139 69.4876 103.902C67.9648 104.592 66.5518 106.415 66.2511 108.12C66.1768 108.541 66.1362 108.921 65.8818 109.276C65.5861 109.686 65.3133 110.149 65.5411 110.537H65.5405Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M65.5406 110.537C65.6136 110.662 65.7386 110.779 65.9315 110.884C66.7036 111.305 67.5538 110.459 68.3266 110.394C69.1102 110.328 69.8202 110.358 70.5708 110.092C71.2808 109.84 71.9597 109.479 72.5149 108.964C73.2902 108.246 73.8029 107.197 73.7064 106.144C73.6963 106.031 73.6766 105.923 73.6538 105.817C72.2052 108.769 67.7721 108.271 65.5635 109.8C65.448 110.054 65.4068 110.309 65.5406 110.537V110.537Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M65.9308 110.883C66.703 111.305 67.5532 110.458 68.326 110.393C69.1096 110.328 69.8195 110.358 70.5701 110.091C71.2801 109.84 71.959 109.479 72.5142 108.964C72.8866 108.619 73.1963 108.196 73.4088 107.734C71.2668 109.762 68.0906 109.264 65.4683 110.321C65.4797 110.395 65.4994 110.467 65.5406 110.537C65.6136 110.662 65.7386 110.779 65.9314 110.883H65.9308Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M88.3886 72.5743C88.5333 72.5686 88.695 72.5134 88.8784 72.3929C89.6138 71.91 89.2673 70.7616 89.5744 70.0491C89.8853 69.327 90.2464 68.7148 90.3644 67.9274C90.4767 67.1825 90.4773 66.4135 90.2851 65.6819C90.016 64.6598 89.3321 63.7131 88.3575 63.3026C86.423 62.4879 84.5132 64.1249 84.3932 65.9687C84.2848 67.6374 85.2282 69.7426 86.5905 70.8098C86.9274 71.0738 87.2433 71.2889 87.4356 71.6797C87.6589 72.134 87.9387 72.5921 88.388 72.5743H88.3886Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M88.3884 72.574C88.5331 72.5683 88.6949 72.5131 88.8783 72.3925C89.1638 72.2047 89.2856 71.916 89.3465 71.5924C87.3764 70.5366 85.8448 67.7621 87.4431 65.582C87.9729 64.8593 88.7393 64.4221 89.5718 64.2458C89.2475 63.8378 88.8383 63.504 88.358 63.3016C86.4235 62.487 84.5137 64.1239 84.3937 65.9677C84.2852 67.6364 85.2287 69.741 86.5909 70.8088C86.9279 71.0728 87.2438 71.2879 87.4361 71.6787C87.6594 72.133 87.9392 72.5911 88.3884 72.5733V72.574Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M88.3886 72.5743C88.5333 72.5686 88.695 72.5134 88.8784 72.3929C88.9063 72.3745 88.926 72.351 88.9507 72.3307C87.4686 70.1442 83.2429 67.9299 85.8316 64.7689C86.5657 63.9739 87.5181 63.5152 88.4933 63.3711C88.4476 63.3489 88.4045 63.3223 88.3575 63.3026C86.423 62.4879 84.5132 64.1249 84.3932 65.9687C84.2848 67.6374 85.2282 69.742 86.5905 70.8098C86.9274 71.0738 87.2433 71.2889 87.4356 71.6797C87.6589 72.134 87.9387 72.5921 88.388 72.5743H88.3886Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M97.0131 87.5463C96.8697 87.5266 96.7009 87.5526 96.4992 87.6389C95.6902 87.9847 95.8292 89.1762 95.4015 89.8234C94.9682 90.4795 94.5056 91.0188 94.2499 91.7732C94.0082 92.487 93.8724 93.2433 93.9327 93.9977C94.0177 95.0516 94.5247 96.1035 95.411 96.679C97.1717 97.8211 99.3404 96.5464 99.7826 94.7521C100.183 93.1284 99.6253 90.8906 98.4718 89.6001C98.1869 89.2809 97.9128 89.0138 97.7929 88.5951C97.6533 88.1084 97.4585 87.6078 97.0125 87.5469L97.0131 87.5463Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M97.013 87.5463C96.8696 87.5266 96.7008 87.5526 96.499 87.6389C95.6901 87.9847 95.829 89.1762 95.402 89.8234C94.9687 90.4795 94.5061 91.0188 94.2504 91.7732C94.0087 92.487 93.8729 93.2433 93.9332 93.9977C94.0176 95.0516 94.5245 96.1035 95.4115 96.679C95.5067 96.7405 95.6031 96.7919 95.7009 96.8402C94.2783 93.8759 97.4127 90.7015 97.5777 88.0202C97.4495 87.7721 97.275 87.5824 97.013 87.5463Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M96.499 87.6379C95.6901 87.9837 95.829 89.1753 95.402 89.8224C94.9687 90.4785 94.5061 91.0178 94.2504 91.7722C94.0087 92.486 93.8729 93.2423 93.9332 93.9967C93.9738 94.503 94.1147 95.0074 94.3456 95.4604C94.0785 92.5234 96.4343 90.3357 97.2268 87.6221C97.1621 87.5853 97.0929 87.5561 97.013 87.5453C96.8696 87.5256 96.7008 87.5516 96.499 87.6379Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M73.9362 51.3193C73.9508 51.4633 74.0162 51.6213 74.1475 51.7971C74.6748 52.5013 75.7997 52.0851 76.5294 52.3478C77.2692 52.6137 77.9024 52.936 78.6961 53.0064C79.4467 53.0724 80.2138 53.0261 80.9327 52.7894C81.9364 52.4582 82.8393 51.7171 83.1889 50.7197C83.883 48.7389 82.1318 46.9331 80.2842 46.9268C78.6124 46.9211 76.5693 47.9921 75.5872 49.4178C75.3441 49.7699 75.1494 50.0992 74.7712 50.3149C74.3315 50.5655 73.8912 50.8733 73.9369 51.3206L73.9362 51.3193Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M73.9355 51.3198C73.95 51.4638 74.0154 51.6218 74.1467 51.7975C74.674 52.5018 75.7989 52.0856 76.5286 52.3476C77.2684 52.6135 77.9016 52.9358 78.6947 53.0062C79.4453 53.0722 80.2124 53.0259 80.9313 52.7892C81.935 52.458 82.8379 51.7169 83.1875 50.7195C83.2249 50.6123 83.2522 50.5063 83.2757 50.4004C80.7308 52.4828 76.9061 50.1866 74.2622 50.6593C74.0516 50.8426 73.9082 51.0564 73.9355 51.3198Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M74.1467 51.7974C74.6739 52.5017 75.7989 52.0854 76.5285 52.3475C77.2683 52.6133 77.9015 52.9357 78.6946 53.0061C79.4452 53.0721 80.2123 53.0257 80.9312 52.7891C81.4134 52.6298 81.8709 52.3741 82.256 52.0429C79.4655 52.9959 76.7829 51.2232 73.9589 51.0938C73.9386 51.1654 73.9265 51.2397 73.9348 51.3196C73.9493 51.4637 74.0147 51.6216 74.146 51.7974H74.1467Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M79.0998 57.4289C79.0979 57.2843 79.0471 57.1206 78.9317 56.9347C78.4691 56.1866 77.3112 56.5007 76.6082 56.1746C75.895 55.844 75.2929 55.4665 74.5087 55.3263C73.767 55.1937 72.9986 55.1715 72.2614 55.344C71.2322 55.5845 70.2672 56.2425 69.83 57.2056C68.9627 59.1167 70.5463 61.0715 72.3863 61.2416C74.0512 61.3957 76.1812 60.5106 77.2858 59.1776C77.5586 58.8483 77.7826 58.538 78.1785 58.3566C78.6385 58.1459 79.1049 57.8788 79.0985 57.4289H79.0998Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M79.0998 57.429C79.0979 57.2843 79.0471 57.1207 78.9317 56.9347C78.7521 56.6442 78.4666 56.5147 78.1449 56.4449C77.0358 58.3852 74.2194 59.84 72.0843 58.1828C71.3769 57.6333 70.9607 56.8554 70.8071 56.0186C70.3903 56.3314 70.0457 56.7317 69.83 57.2063C68.9627 59.1174 70.5463 61.0722 72.3863 61.2423C74.0512 61.3964 76.1812 60.5113 77.2858 59.1783C77.5586 58.849 77.782 58.5387 78.1785 58.3573C78.6385 58.1466 79.1049 57.8795 79.0985 57.4296L79.0998 57.429Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M79.0997 57.4288C79.0978 57.2842 79.047 57.1205 78.9315 56.9346C78.9138 56.906 78.8909 56.8857 78.8712 56.8604C76.6448 58.2816 74.3157 62.4451 71.227 59.7707C70.4529 59.0151 70.0202 58.0506 69.9028 57.0723C69.8794 57.1173 69.8514 57.1598 69.8305 57.2061C68.9632 59.1172 70.5468 61.0721 72.3868 61.2421C74.0517 61.3963 76.1817 60.5112 77.2863 59.1781C77.5591 58.8488 77.7825 58.5386 78.179 58.3571C78.639 58.1464 79.1054 57.8793 79.099 57.4295L79.0997 57.4288Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M59.19 42.7605C59.131 42.8925 59.1088 43.0625 59.1348 43.2801C59.2407 44.1532 60.4234 44.3537 60.9259 44.9444C61.4348 45.544 61.8231 46.1385 62.476 46.5947C63.0933 47.0261 63.7817 47.3681 64.5234 47.521C65.5589 47.7342 66.7105 47.5413 67.5112 46.851C69.1 45.4799 68.482 43.0409 66.8831 42.1152C65.4365 41.2771 63.1314 41.187 61.5699 41.9338C61.1842 42.1184 60.8511 42.3062 60.4152 42.3049C59.9089 42.303 59.374 42.3506 59.1906 42.7611L59.19 42.7605Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M66.8763 42.1133C65.4297 41.2751 63.1246 41.185 61.5632 41.9318C61.1768 42.1164 60.8443 42.3042 60.4084 42.303C59.9021 42.3011 59.3672 42.3487 59.1839 42.7592C59.1591 42.8144 59.1426 42.8785 59.1318 42.9482C59.7631 42.2592 60.7929 42.5022 61.5207 42.8607C62.5117 43.3486 63.4958 43.7071 64.3327 44.4513C65.2324 45.2514 65.9221 46.3205 66.2101 47.4943C66.686 47.3788 67.1339 47.1701 67.5051 46.8497C69.0939 45.4786 68.4759 43.0396 66.877 42.1139L66.8763 42.1133Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M66.8762 42.1131C65.8921 41.5434 64.5109 41.32 63.2368 41.4704C65.0527 42.0046 66.7747 42.9411 67.1421 45.0489C67.2455 45.8731 67.1053 46.5958 66.847 47.2696C67.0831 47.1592 67.3051 47.0215 67.5043 46.8496C69.0931 45.4784 68.4751 43.0395 66.8762 42.1138V42.1131Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M103.02 69.1895C103.104 69.3075 103.239 69.4128 103.44 69.5004C104.246 69.8519 105.018 68.9344 105.782 68.8018C106.557 68.6673 107.267 68.6343 107.991 68.3031C108.676 67.9897 109.32 67.5703 109.828 67.0088C110.537 66.2246 110.955 65.1345 110.766 64.0946C110.391 62.0294 107.976 61.3251 106.366 62.233C104.91 63.0547 103.663 64.9949 103.514 66.7195C103.477 67.1458 103.47 67.5278 103.248 67.9028C102.989 68.338 102.758 68.8228 103.02 69.1895H103.02Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M103.021 69.1892C103.104 69.3072 103.24 69.4125 103.44 69.5001C103.753 69.6365 104.061 69.5806 104.366 69.4544C104.172 67.2274 105.659 64.428 108.358 64.5746C109.253 64.6234 110.037 65.0257 110.64 65.6266C110.804 65.1317 110.86 64.6069 110.767 64.0936C110.391 62.0284 107.977 61.3241 106.367 62.2321C104.911 63.0537 103.664 64.994 103.515 66.7191C103.478 67.1455 103.471 67.5275 103.249 67.9024C102.991 68.3377 102.76 68.8224 103.021 69.1892H103.021Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M103.02 69.1895C103.104 69.3075 103.239 69.4128 103.44 69.5004C103.47 69.5137 103.501 69.5175 103.531 69.5271C104.551 67.0906 104.095 62.3415 108.157 62.7812C109.224 62.9614 110.128 63.5077 110.783 64.2456C110.776 64.1955 110.775 64.1447 110.766 64.0946C110.39 62.0294 107.976 61.3251 106.366 62.233C104.91 63.0547 103.663 64.9949 103.514 66.7201C103.477 67.1465 103.47 67.5284 103.248 67.9034C102.99 68.3387 102.759 68.8234 103.02 69.1901V69.1895Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M75.3096 30.7664C75.2538 30.8997 75.2348 31.0697 75.2659 31.2867C75.3909 32.1572 76.5773 32.3317 77.0919 32.9122C77.6134 33.5004 78.0144 34.0873 78.6775 34.5289C79.3043 34.947 79.9997 35.2744 80.744 35.4108C81.7839 35.6018 82.9311 35.3842 83.7165 34.6767C85.2755 33.2714 84.6048 30.847 82.9862 29.9555C81.5219 29.1485 79.2155 29.1085 77.6699 29.8883C77.288 30.0812 76.9593 30.276 76.5234 30.2842C76.0171 30.2931 75.4835 30.3521 75.309 30.7664H75.3096Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M75.3096 30.7672C75.2538 30.9004 75.2348 31.0704 75.2659 31.2874C75.3141 31.6256 75.5241 31.8585 75.7912 32.0514C77.5836 30.7158 80.7465 30.5083 82.0409 32.8813C82.4698 33.668 82.5402 34.5474 82.3461 35.3754C82.853 35.2549 83.3289 35.0264 83.7165 34.6775C85.2755 33.2721 84.6054 30.8477 82.9862 29.9563C81.5219 29.1499 79.2155 29.1093 77.6699 29.889C77.288 30.0819 76.9593 30.2767 76.5234 30.285C76.0171 30.2938 75.4835 30.3528 75.309 30.7672H75.3096Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M75.3096 30.7668C75.2538 30.9001 75.2348 31.0701 75.2659 31.2871C75.2703 31.3201 75.2836 31.348 75.2912 31.3791C77.9002 30.966 81.6995 28.0811 83.4615 31.7674C83.8694 32.7699 83.8802 33.8269 83.5966 34.7704C83.636 34.7387 83.6785 34.7108 83.7165 34.6765C85.2755 33.2711 84.6054 30.8468 82.9862 29.9553C81.5219 29.1489 79.2155 29.1083 77.6699 29.8881C77.288 30.0809 76.9593 30.2757 76.5234 30.284C76.0171 30.2929 75.4835 30.3519 75.309 30.7662L75.3096 30.7668Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M96.2011 34.8211C96.3122 34.7284 96.4073 34.5863 96.4797 34.3795C96.7703 33.5489 95.797 32.8472 95.6079 32.0953C95.4156 31.3327 95.33 30.6278 94.9461 29.9298C94.5826 29.27 94.1162 28.659 93.5185 28.1945C92.6836 27.5461 91.565 27.2104 90.5422 27.4763C88.5112 28.0048 87.989 30.466 89.0143 32.0033C89.9426 33.3941 91.9704 34.493 93.7013 34.5121C94.1289 34.5171 94.5109 34.4956 94.9011 34.6891C95.3541 34.9143 95.8553 35.1078 96.2011 34.8204V34.8211Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M89.0134 32.0043C89.9416 33.3951 91.9694 34.494 93.7009 34.513C94.1286 34.5181 94.5105 34.4965 94.9007 34.69C95.3538 34.9153 95.855 35.1088 96.2008 34.8214C96.2477 34.7827 96.2902 34.7326 96.3309 34.6748C95.4603 35.0143 94.6438 34.3411 94.1489 33.699C93.475 32.824 92.7505 32.0677 92.3285 31.0297C91.8749 29.9149 91.7277 28.651 91.9878 27.4709C91.5101 27.3643 91.0158 27.3535 90.5412 27.4772C88.5102 28.0058 87.988 30.4669 89.0134 32.0043Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M89.0135 32.0036C89.6448 32.9496 90.7856 33.7599 91.9949 34.1875C90.6016 32.9065 89.4703 31.3057 90.0712 29.2525C90.3421 28.467 90.7875 27.8814 91.3161 27.3903C91.0559 27.3852 90.7951 27.41 90.5407 27.4766C88.5097 28.0051 87.9876 30.4663 89.0129 32.0036H89.0135Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M87.9588 58.8102C88.0432 58.6928 88.099 58.531 88.1168 58.3121C88.1885 57.4353 87.0699 57.0019 86.6968 56.3218C86.3186 55.6321 86.0579 54.9716 85.5097 54.3936C84.9913 53.8466 84.386 53.3733 83.69 53.0745C82.7186 52.6576 81.5518 52.6151 80.6286 53.1309C78.7962 54.155 78.9123 56.6682 80.2923 57.8965C81.541 59.0082 83.7813 59.5595 85.4608 59.142C85.8764 59.0386 86.24 58.9219 86.6663 59.0107C87.1619 59.1141 87.6955 59.1757 87.9575 58.8096L87.9588 58.8102Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M87.959 58.8102C88.0434 58.6928 88.0992 58.531 88.117 58.3121C88.1887 57.4353 87.0701 57.0019 86.697 56.3218C86.3189 55.6321 86.0581 54.9716 85.5099 54.3936C84.9915 53.8466 84.3862 53.3733 83.6902 53.0745C82.7188 52.6576 81.552 52.6151 80.6288 53.1309C80.5299 53.1861 80.4391 53.2477 80.3516 53.3118C83.6375 53.4374 84.9814 57.691 87.2757 59.0887C87.5548 59.0906 87.8042 59.0253 87.9584 58.8102H87.959Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M88.1167 58.312C88.1883 57.4352 87.0698 57.0018 86.6967 56.3216C86.3185 55.632 86.0578 54.9715 85.5096 54.3935C84.9912 53.8465 84.3859 53.3732 83.6899 53.0744C83.2229 52.8739 82.7109 52.7628 82.2026 52.7559C84.9246 53.8903 85.7595 56.9948 87.7899 58.963C87.8527 58.923 87.9104 58.8754 87.9574 58.8095C88.0418 58.6921 88.0976 58.5303 88.1154 58.3114L88.1167 58.312Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M101.456 53.7941C101.316 53.7598 101.145 53.7687 100.936 53.834C100.096 54.0961 100.113 55.2959 99.6222 55.8961C99.1247 56.5052 98.6095 56.9944 98.279 57.719C97.9662 58.4042 97.7542 59.1434 97.7377 59.8997C97.7149 60.9567 98.1127 62.0544 98.9369 62.7168C100.573 64.0321 102.859 62.9833 103.482 61.2429C104.045 59.6681 103.716 57.3852 102.7 55.9843C102.449 55.6379 102.204 55.3441 102.126 54.9158C102.037 54.4178 101.894 53.9 101.456 53.7941Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M101.457 53.7941C101.316 53.7598 101.145 53.7687 100.936 53.834C100.61 53.9362 100.413 54.1798 100.266 54.4742C101.87 56.0306 102.579 59.1199 100.443 60.7765C99.7353 61.3254 98.8781 61.5354 98.0298 61.4757C98.2296 61.9573 98.531 62.3907 98.9377 62.7168C100.573 64.0321 102.86 62.9833 103.483 61.2429C104.045 59.6687 103.717 57.3852 102.701 55.9843C102.45 55.6379 102.205 55.3447 102.127 54.9158C102.038 54.4178 101.894 53.9 101.457 53.7941H101.457Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M101.456 53.7941C101.316 53.7598 101.145 53.7687 100.935 53.834C100.904 53.8442 100.878 53.8613 100.849 53.874C101.672 56.3834 105.127 59.6744 101.769 62.0017C100.844 62.5639 99.8023 62.7434 98.8252 62.614C98.8633 62.6476 98.8969 62.6851 98.9369 62.7174C100.573 64.0327 102.859 62.9839 103.482 61.2435C104.044 59.6694 103.716 57.3859 102.7 55.9849C102.449 55.6385 102.204 55.3454 102.126 54.9165C102.037 54.4184 101.894 53.9006 101.456 53.7947V53.7941Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M96.3265 46.3962C96.4527 46.3251 96.5714 46.2027 96.6799 46.0117C97.1151 45.2471 96.2846 44.3817 96.2339 43.6083C96.1825 42.8234 96.225 42.1147 95.9724 41.359C95.7332 40.6446 95.3849 39.9594 94.8805 39.3953C94.1762 38.6073 93.1363 38.0756 92.0818 38.153C89.9886 38.3072 89.0318 40.6338 89.764 42.3304C90.4264 43.8659 92.2239 45.3112 93.9231 45.6424C94.3431 45.7243 94.7225 45.7718 95.0715 46.0326C95.4769 46.3359 95.9344 46.6163 96.3265 46.3962Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M96.3265 46.3958C96.4527 46.3247 96.572 46.2022 96.6799 46.0113C96.8487 45.7143 96.8258 45.4022 96.7332 45.0855C94.4985 45.0418 91.8724 43.2665 92.3045 40.5985C92.4479 39.714 92.9314 38.9761 93.5925 38.4406C93.1179 38.2249 92.6021 38.1138 92.0818 38.1519C89.9886 38.3061 89.0318 40.6327 89.764 42.3294C90.4264 43.8648 92.2239 45.3102 93.9231 45.6414C94.3431 45.7232 94.7225 45.7708 95.0715 46.0316C95.4769 46.3348 95.9344 46.6153 96.3265 46.3951V46.3958Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M96.3265 46.3954C96.4527 46.3243 96.572 46.2019 96.6799 46.0109C96.6964 45.9817 96.7034 45.9519 96.7161 45.9227C94.4015 44.6499 89.6314 44.5998 90.4994 40.607C90.7919 39.5652 91.4315 38.7239 92.2341 38.1516C92.1833 38.1528 92.1332 38.1484 92.0818 38.1516C89.9886 38.3057 89.0318 40.6324 89.764 42.329C90.4264 43.8644 92.2239 45.3098 93.9231 45.641C94.3431 45.7228 94.7225 45.7704 95.0715 46.0312C95.4769 46.3345 95.9344 46.6149 96.3265 46.3948V46.3954Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M95.6755 86.0215C95.8177 85.9942 95.9699 85.9162 96.133 85.7696C96.7891 85.1834 96.277 84.0984 96.475 83.3491C96.6761 82.589 96.942 81.9304 96.9432 81.1335C96.9445 80.3803 96.8316 79.6196 96.5334 78.9242C96.1165 77.9528 95.2999 77.1178 94.2759 76.8558C92.2423 76.3355 90.5946 78.2364 90.7488 80.0783C90.8877 81.7445 92.1313 83.6872 93.6363 84.5419C94.0088 84.7532 94.3526 84.9188 94.6007 85.2773C94.8881 85.6935 95.2333 86.1059 95.6749 86.0215H95.6755Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M95.6754 86.0215C95.8175 85.9942 95.9698 85.9162 96.1328 85.7696C96.3879 85.5418 96.4653 85.2386 96.478 84.9093C94.3734 84.1561 92.4497 81.6372 93.7085 79.2459C94.126 78.4528 94.8195 77.9077 95.6164 77.6102C95.2351 77.2542 94.7814 76.9852 94.2764 76.8558C92.2428 76.3355 90.5951 78.2364 90.7493 80.0783C90.8882 81.7445 92.1318 83.6872 93.6374 84.5419C94.0099 84.7532 94.3538 84.9188 94.6018 85.2773C94.8893 85.6935 95.2344 86.1059 95.676 86.0215H95.6754Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M95.6754 86.0215C95.8175 85.9942 95.9698 85.9162 96.1329 85.7696C96.1576 85.7474 96.1735 85.7214 96.1957 85.6973C94.4071 83.7539 89.901 82.1873 91.9948 78.6786C92.6039 77.784 93.4775 77.1901 94.4204 76.9034C94.3722 76.8881 94.3252 76.8678 94.2764 76.8558C92.2428 76.3355 90.5951 78.2364 90.7493 80.0783C90.8882 81.7445 92.1318 83.6872 93.6374 84.5419C94.0099 84.7532 94.3538 84.9188 94.6018 85.2773C94.8893 85.6935 95.2344 86.1059 95.676 86.0215H95.6754Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M32.404 77.9497C32.3844 77.8063 32.3139 77.6502 32.1769 77.4795C31.6255 76.7936 30.5158 77.2479 29.7773 77.0113C29.0286 76.7708 28.3846 76.47 27.5896 76.4275C26.8371 76.3869 26.0719 76.4593 25.3619 76.7213C24.3696 77.0868 23.4934 77.8583 23.178 78.8671C22.5518 80.8702 24.3639 82.6156 26.2109 82.5585C27.8821 82.5071 29.8877 81.367 30.8197 79.9083C31.0501 79.5479 31.2341 79.2123 31.6046 78.9839C32.0354 78.718 32.465 78.3957 32.404 77.9503V77.9497Z"
                fill="#F6DCB6"
            />
            <G opacity="0.4">
                <Path
                    d="M26.211 82.5574C27.8822 82.5054 29.8878 81.3659 30.8199 79.9072C31.0502 79.5468 31.2342 79.2112 31.6047 78.9828C32.0356 78.7175 32.4651 78.3946 32.4042 77.9492C32.3959 77.8889 32.3763 77.8261 32.349 77.7614C32.1764 78.6795 31.1733 79.017 30.3662 79.0963C29.2673 79.2048 28.242 79.4199 27.138 79.2296C25.9515 79.0246 24.8012 78.4809 23.9377 77.6357C23.5944 77.9847 23.3241 78.3984 23.1775 78.8666C22.5513 80.8697 24.3634 82.6152 26.2104 82.5581L26.211 82.5574Z"
                    fill="#F6DCB6"
                />
            </G>
            <G opacity="0.4">
                <Path
                    d="M26.211 82.5581C27.348 82.5226 28.6385 81.9833 29.6417 81.1832C27.8175 81.6876 25.8608 81.8012 24.4357 80.2049C23.9129 79.5596 23.6509 78.8718 23.5145 78.1631C23.3723 78.3813 23.2562 78.6155 23.1775 78.8667C22.5513 80.8698 24.3634 82.6152 26.2104 82.5581H26.211Z"
                    fill="#F6DCB6"
                />
            </G>
            <Path
                d="M20.0871 73.385C20.1251 73.5246 20.2159 73.6699 20.3745 73.8215C21.0109 74.4287 22.0514 73.833 22.8147 73.9713C23.5888 74.1115 24.2664 74.3247 25.0601 74.2631C25.8114 74.2048 26.56 74.0322 27.2301 73.6794C28.1653 73.1871 28.933 72.307 29.1132 71.2652C29.4711 69.1968 27.4458 67.7051 25.6216 68.004C23.9714 68.2743 22.1333 69.6676 21.4004 71.236C21.219 71.6237 21.0813 71.9803 20.7437 72.2556C20.3516 72.5754 19.9678 72.9517 20.0871 73.385Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M20.0869 73.385C20.125 73.5246 20.2157 73.6699 20.3744 73.8215C21.0108 74.4287 22.0513 73.8329 22.8146 73.9713C23.588 74.1115 24.2663 74.3247 25.06 74.2631C25.8113 74.2047 26.5599 74.0322 27.23 73.6794C28.1652 73.187 28.9329 72.307 29.1131 71.2658C29.1321 71.1542 29.1417 71.045 29.1474 70.9365C26.9812 73.4104 22.8298 71.7772 20.3001 72.6801C20.1225 72.8958 20.0165 73.1306 20.0869 73.3856V73.385Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M20.3745 73.8216C21.0109 74.4288 22.0515 73.8331 22.8147 73.9714C23.5882 74.1116 24.2664 74.3248 25.0602 74.2632C25.8114 74.2049 26.5601 74.0323 27.2301 73.6795C27.68 73.4428 28.0886 73.1148 28.4134 72.7246C25.8184 74.1249 22.8801 72.8198 20.0738 73.1586C20.0655 73.2328 20.0662 73.3077 20.0871 73.3857C20.1252 73.5253 20.2159 73.6706 20.3745 73.8223V73.8216Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M57.4175 80.9603C57.4753 81.0929 57.585 81.2242 57.764 81.3511C58.4803 81.8619 59.4257 81.124 60.2004 81.1525C60.9865 81.1811 61.6876 81.2959 62.4642 81.1221C63.1996 80.9571 63.9165 80.6805 64.5295 80.2363C65.3854 79.6158 66.0205 78.6362 66.0503 77.5791C66.1106 75.4809 63.8931 74.2919 62.1311 74.847C60.536 75.3496 58.9149 76.9897 58.4124 78.6463C58.2881 79.0556 58.2024 79.4286 57.9074 79.7491C57.5647 80.1215 57.2386 80.5479 57.4182 80.9609L57.4175 80.9603Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M62.1311 74.847C60.536 75.3496 58.9149 76.9897 58.4124 78.6463C58.2881 79.0556 58.2024 79.4286 57.9074 79.7491C57.5647 80.1215 57.2386 80.5479 57.4182 80.9609C57.4423 81.0168 57.4784 81.072 57.5222 81.1272C57.4397 80.1964 58.3141 79.6006 59.0691 79.3049C60.0976 78.9027 61.0265 78.4185 62.1406 78.3024C63.3379 78.1781 64.5929 78.3906 65.6531 78.9699C65.8892 78.541 66.037 78.0696 66.0516 77.5791C66.1119 75.4809 63.8943 74.2919 62.1324 74.847H62.1311Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M62.1312 74.8467C61.0462 75.1887 59.9499 76.0573 59.2012 77.0991C60.8204 76.1195 62.6737 75.4806 64.4775 76.6309C65.1558 77.1105 65.5942 77.7019 65.9172 78.3465C65.9946 78.0978 66.0434 77.8408 66.051 77.5782C66.1113 75.4799 63.8938 74.2909 62.1318 74.8461L62.1312 74.8467Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M57.8265 96.2124C57.9655 96.253 58.1362 96.253 58.3487 96.1978C59.2002 95.9763 59.2408 94.7772 59.7598 94.2011C60.2858 93.6167 60.8238 93.1529 61.1887 92.4448C61.5338 91.7754 61.7813 91.0477 61.8339 90.292C61.9075 89.2375 61.563 88.1214 60.7718 87.4203C59.2008 86.0283 56.8666 86.9661 56.1616 88.6741C55.524 90.2197 55.7416 92.5159 56.6895 93.9644C56.9237 94.3222 57.1546 94.6274 57.2111 95.0595C57.2764 95.5614 57.3951 96.0855 57.8265 96.2124Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M56.1616 88.6741C55.524 90.2197 55.7416 92.5159 56.6895 93.9644C56.9243 94.3222 57.1546 94.6274 57.2111 95.0595C57.2764 95.5614 57.3951 96.0855 57.8265 96.2124C57.8849 96.2295 57.9503 96.2377 58.0207 96.239C57.2536 95.7054 57.3576 94.6522 57.6152 93.8832C57.9668 92.8363 58.1907 91.8135 58.817 90.884C59.4901 89.8859 60.4577 89.0598 61.5827 88.6182C61.405 88.1621 61.1385 87.7458 60.7712 87.4203C59.2002 86.0283 56.8659 86.9661 56.161 88.6741H56.1616Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M56.1616 88.6737C55.7276 89.7251 55.6908 91.1235 56.0093 92.3664C56.2968 90.4953 56.9953 88.6642 59.0352 88.019C59.8384 87.8064 60.5732 87.8495 61.2749 88.0151C61.1341 87.7962 60.9685 87.5938 60.7711 87.4194C59.2002 86.0273 56.8659 86.9651 56.161 88.6731L56.1616 88.6737Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M50.6658 85.9853C50.7876 85.9072 50.8999 85.7778 50.997 85.5817C51.3878 84.7937 50.5097 83.9771 50.4145 83.2075C50.3187 82.4271 50.3206 81.7165 50.0256 80.9766C49.7464 80.2768 49.3594 79.6131 48.8239 79.0776C48.0758 78.3309 47.0073 77.8588 45.9592 77.9965C43.8781 78.2693 43.0551 80.6467 43.8825 82.2989C44.6312 83.7937 46.508 85.135 48.223 85.3692C48.6468 85.4269 49.0288 85.4529 49.3917 85.6934C49.8137 85.9732 50.287 86.2276 50.6658 85.9846V85.9853Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M50.6657 85.9853C50.7875 85.9072 50.8992 85.7778 50.9969 85.5817C51.3878 84.7937 50.5096 83.9771 50.4145 83.2075C50.3187 82.4271 50.3206 81.7164 50.0255 80.9766C49.7464 80.2768 49.3593 79.6131 48.8238 79.0776C48.0758 78.3309 47.0073 77.8588 45.9591 77.9965C45.8468 78.0111 45.7396 78.0352 45.6343 78.0612C48.6398 79.3942 48.3143 83.8432 49.9278 85.991C50.1867 86.0963 50.4424 86.128 50.6651 85.9853H50.6657Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M50.9974 85.5819C51.3882 84.7939 50.5101 83.9773 50.4149 83.2077C50.3191 82.4273 50.321 81.7167 50.026 80.9768C49.7468 80.277 49.3598 79.6133 48.8243 79.0778C48.4645 78.7187 48.0306 78.4262 47.561 78.2314C49.6694 80.2935 49.2957 83.4862 50.4536 86.066C50.5266 86.0521 50.5983 86.0292 50.6662 85.9855C50.788 85.9074 50.8997 85.778 50.9974 85.5819Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M45.2392 71.6324C45.3547 71.5449 45.4556 71.4072 45.5374 71.2035C45.8648 70.3869 44.9245 69.642 44.7691 68.8826C44.6111 68.1123 44.5578 67.4036 44.2044 66.6898C43.8706 66.0147 43.4322 65.3834 42.8555 64.8923C42.0503 64.2071 40.9482 63.8213 39.914 64.0415C37.8615 64.4786 37.2295 66.9138 38.185 68.4949C39.0498 69.9263 41.0269 71.114 42.7552 71.2118C43.1822 71.2359 43.5648 71.2314 43.9461 71.4427C44.389 71.6876 44.8807 71.904 45.2392 71.6324Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M38.1859 68.4964C39.0507 69.9271 41.0277 71.1155 42.7561 71.2126C43.1831 71.2367 43.5657 71.2322 43.947 71.4435C44.3899 71.6884 44.8816 71.9048 45.2401 71.6332C45.2883 71.5964 45.334 71.5489 45.3765 71.493C44.4914 71.7931 43.7059 71.0844 43.2408 70.4201C42.607 69.5153 41.9173 68.7279 41.5417 67.6722C41.1381 66.5377 41.048 65.2687 41.3608 64.1013C40.8881 63.9731 40.3951 63.9408 39.9155 64.0429C37.8629 64.4801 37.231 66.9152 38.1865 68.4964H38.1859Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M38.1855 68.4965C38.7737 69.4698 39.8771 70.3301 41.0661 70.8117C39.7318 69.4691 38.6735 67.8201 39.3657 65.7955C39.6715 65.0233 40.1423 64.4573 40.693 63.991C40.4329 63.9745 40.1721 63.9878 39.9145 64.0424C37.8619 64.4795 37.23 66.9147 38.1855 68.4958V68.4965Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M72.0908 63.4355C72.1929 63.5377 72.3433 63.6195 72.5565 63.6728C73.4099 63.886 74.0196 62.853 74.7512 62.5954C75.4929 62.334 76.1876 62.1843 76.8469 61.7376C77.4706 61.3151 78.0359 60.7948 78.4438 60.1565C79.013 59.2657 79.2446 58.1211 78.8861 57.1268C78.1736 55.153 75.675 54.8586 74.2385 56.0209C72.9384 57.0729 72.0305 59.1927 72.1701 60.9185C72.2043 61.3449 72.2608 61.723 72.1035 62.1297C71.9207 62.6018 71.7735 63.1183 72.092 63.4361L72.0908 63.4355Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M72.0906 63.4355C72.1927 63.5377 72.3431 63.6195 72.5557 63.6728C72.8875 63.7553 73.1819 63.6493 73.4611 63.4748C72.9014 61.3106 73.9027 58.3038 76.5884 58.0005C77.4786 57.9003 78.3199 58.1674 79.0134 58.6591C79.0934 58.1439 79.061 57.6173 78.884 57.1268C78.1715 55.153 75.6729 54.8586 74.2364 56.0209C72.9363 57.0723 72.0284 59.1927 72.168 60.9185C72.2022 61.3449 72.2587 61.723 72.102 62.1297C71.9193 62.6018 71.7721 63.1183 72.0906 63.4361V63.4355Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M72.0904 63.4358C72.1926 63.538 72.343 63.6198 72.5555 63.6731C72.5879 63.6814 72.6183 63.6795 72.65 63.6846C73.2522 61.113 72.0149 56.5054 76.094 56.2649C77.1758 56.2656 78.1586 56.6545 78.9257 57.2737C78.9111 57.2249 78.9016 57.1754 78.8845 57.1278C78.1719 55.1539 75.6734 54.8595 74.2369 56.0219C72.9368 57.0733 72.0289 59.1937 72.1685 60.9195C72.2027 61.3459 72.2592 61.724 72.1025 62.1307C71.9198 62.6028 71.7726 63.1192 72.0911 63.4371L72.0904 63.4358Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M59.3946 44.3447C59.2499 44.3491 59.0875 44.4024 58.9029 44.5204C58.1624 44.995 58.4955 46.1472 58.1815 46.8559C57.8623 47.5748 57.495 48.1827 57.3681 48.9688C57.2475 49.7124 57.238 50.4807 57.4226 51.2155C57.6802 52.2408 58.3541 53.1944 59.3235 53.6157C61.2486 54.452 63.1768 52.8359 63.317 50.9934C63.4439 49.326 62.5239 47.2106 61.1731 46.1282C60.8393 45.8604 60.5252 45.6422 60.3374 45.2488C60.1192 44.792 59.8444 44.3307 59.3946 44.344V44.3447Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M59.3946 44.345C59.2499 44.3494 59.0875 44.4027 58.9029 44.5207C58.1624 44.9953 58.4955 46.1476 58.1815 46.8569C57.8623 47.5758 57.495 48.1836 57.3681 48.9697C57.2475 49.7133 57.238 50.4817 57.4226 51.2164C57.6802 52.2418 58.3541 53.1954 59.3235 53.6167C59.4276 53.6617 59.5316 53.6966 59.6351 53.7277C57.743 51.0388 60.3102 47.3905 60.0304 44.7193C59.8629 44.496 59.6598 44.3374 59.3952 44.345H59.3946Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M58.9029 44.5206C58.1624 44.9952 58.4955 46.1474 58.1815 46.8568C57.8623 47.5757 57.495 48.1835 57.3681 48.9696C57.2475 49.7132 57.238 50.4816 57.4226 51.2163C57.5464 51.7093 57.7684 52.1833 58.0711 52.5919C57.323 49.7392 59.2855 47.1931 59.6186 44.3855C59.5488 44.3601 59.4752 44.343 59.3946 44.3449C59.2499 44.3493 59.0875 44.4026 58.9029 44.5206Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M68.7534 62.4727C68.7508 62.328 68.6994 62.1649 68.5833 61.979C68.117 61.2329 66.961 61.5527 66.256 61.2303C65.541 60.9029 64.9369 60.5286 64.1527 60.3922C63.4104 60.2634 62.642 60.2444 61.906 60.4207C60.8775 60.6663 59.9163 61.3287 59.4836 62.2937C58.6251 64.2092 60.2183 66.1558 62.0589 66.3176C63.7245 66.4636 65.8506 65.5689 66.9489 64.2308C67.2205 63.9002 67.4425 63.5887 67.8378 63.4053C68.2972 63.1928 68.7616 62.9231 68.7534 62.4733V62.4727Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M62.0591 66.3166C63.7246 66.4625 65.8507 65.5679 66.949 64.2298C67.2206 63.8992 67.4427 63.5877 67.8379 63.4043C68.2973 63.1918 68.7618 62.9221 68.7535 62.4723C68.7522 62.4114 68.7402 62.3466 68.7211 62.2794C68.4413 63.1708 67.4052 63.3872 66.5944 63.3707C65.4904 63.3485 64.4473 63.4405 63.3731 63.1213C62.2196 62.7781 61.1416 62.1017 60.3834 61.1602C60.0015 61.466 59.6836 61.8454 59.4831 62.2927C58.6246 64.2082 60.2178 66.1548 62.0584 66.3166H62.0591Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M62.0591 66.3159C63.1923 66.4155 64.5374 66.0323 65.628 65.356C63.757 65.6415 61.8008 65.5228 60.5744 63.7691C60.1315 63.0667 59.9526 62.3523 59.9006 61.6328C59.7337 61.8327 59.5903 62.0516 59.4831 62.2914C58.6246 64.2069 60.2178 66.1535 62.0584 66.3153L62.0591 66.3159Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M86.0159 59.6525C85.9169 59.7578 85.8402 59.9107 85.7939 60.1251C85.6079 60.9849 86.6599 61.5616 86.9404 62.2843C87.2246 63.0171 87.3966 63.7068 87.8642 64.3514C88.3064 64.9612 88.8444 65.5106 89.4948 65.8977C90.4034 66.4383 91.5543 66.6337 92.5371 66.2435C94.4875 65.4688 94.7026 62.9619 93.4958 61.5629C92.4033 60.2971 90.2555 59.4564 88.5354 59.6499C88.1103 59.6975 87.7341 59.766 87.323 59.622C86.8452 59.4545 86.3243 59.3238 86.0166 59.6518L86.0159 59.6525Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M93.4951 61.5629C92.4025 60.2971 90.2548 59.4564 88.5347 59.6499C88.1096 59.6975 87.7334 59.766 87.3222 59.622C86.8445 59.4545 86.3235 59.3238 86.0158 59.6518C85.9746 59.6962 85.9378 59.7508 85.9048 59.813C86.7271 59.3688 87.6204 59.9361 88.1902 60.5128C88.9668 61.2983 89.7783 61.9594 90.3259 62.9372C90.9134 63.9879 91.2148 65.2239 91.1025 66.4275C91.5897 66.4744 92.0815 66.4243 92.537 66.2428C94.4874 65.4681 94.7025 62.9619 93.4957 61.5623L93.4951 61.5629Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M93.4953 61.5627C92.7524 60.7017 91.5208 60.0381 90.2671 59.7627C91.8076 60.8623 93.1273 62.3114 92.7841 64.4236C92.6121 65.2364 92.2422 65.8728 91.7778 66.4248C92.0367 66.3975 92.2917 66.341 92.5366 66.2439C94.487 65.4692 94.7021 62.963 93.4953 61.5634V61.5627Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M84.4091 54.4772C84.55 54.5096 84.7207 54.4988 84.9294 54.4309C85.7657 54.1581 85.7333 52.9589 86.2168 52.3523C86.7066 51.7369 87.2155 51.2413 87.5365 50.5123C87.8404 49.8233 88.0435 49.0816 88.0498 48.3246C88.0593 47.2676 87.6475 46.175 86.8151 45.5227C85.1629 44.2284 82.8896 45.3064 82.2893 47.0538C81.7462 48.6355 82.1034 50.9139 83.1376 52.3022C83.3933 52.6454 83.6421 52.936 83.7245 53.3637C83.8203 53.8605 83.9701 54.3763 84.4085 54.4772H84.4091Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M84.4093 54.4775C84.5502 54.5099 84.7209 54.4991 84.9296 54.4312C85.2545 54.3253 85.448 54.0785 85.5914 53.7821C83.9677 52.2461 83.2191 49.1663 85.3338 47.483C86.0349 46.9253 86.8889 46.7045 87.7385 46.7534C87.5329 46.2743 87.2258 45.8454 86.8153 45.5237C85.1631 44.2294 82.8898 45.3074 82.2895 47.0547C81.7471 48.6359 82.1036 50.9149 83.1378 52.3032C83.3935 52.6464 83.6423 52.9364 83.7247 53.3647C83.8205 53.8615 83.9703 54.3773 84.4087 54.4782L84.4093 54.4775Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M84.4093 54.4775C84.5502 54.5099 84.7209 54.4991 84.9296 54.4312C84.9613 54.4211 84.9867 54.4033 85.0159 54.3906C84.16 51.892 80.6646 48.6447 83.9931 46.2749C84.9106 45.7007 85.9499 45.5085 86.9282 45.6252C86.8902 45.5916 86.8553 45.5548 86.8153 45.5237C85.1631 44.2294 82.8898 45.3074 82.2895 47.0547C81.7471 48.6359 82.1036 50.9149 83.1378 52.3032C83.3935 52.6464 83.6423 52.9364 83.7247 53.3647C83.8205 53.8615 83.9703 54.3773 84.4087 54.4782L84.4093 54.4775Z"
                    fill="#9498AF"
                />
            </G>
            <Path
                d="M66.0976 45.7577C66.1921 45.8674 66.3355 45.9601 66.5436 46.0292C67.3786 46.3059 68.0645 45.3212 68.8125 45.1194C69.572 44.9145 70.2757 44.8174 70.9666 44.4215C71.6201 44.0471 72.2235 43.5706 72.6778 42.9647C73.3123 42.1189 73.6295 40.9953 73.3459 39.9769C72.7838 37.9548 70.3144 37.4739 68.7941 38.5246C67.4192 39.4757 66.3546 41.5219 66.3634 43.2527C66.366 43.6804 66.3939 44.0617 66.2061 44.4551C65.9885 44.9119 65.8032 45.4163 66.0963 45.757L66.0976 45.7577Z"
                fill="#9498AF"
            />
            <G opacity="0.5">
                <Path
                    d="M66.0974 45.758C66.1919 45.8677 66.3353 45.9604 66.5434 46.0295C67.3784 46.3062 68.0642 45.3215 68.8123 45.1197C69.5718 44.9147 70.2754 44.8177 70.9664 44.4218C71.6199 44.0474 72.2233 43.5709 72.6776 42.965C73.3121 42.1192 73.6293 40.9955 73.3457 39.9772C73.3152 39.8681 73.2772 39.7653 73.2359 39.665C72.3375 42.8279 67.8872 43.1299 65.9876 45.029C65.9197 45.2999 65.9241 45.5575 66.0967 45.758H66.0974Z"
                    fill="#9498AF"
                />
            </G>
            <G opacity="0.5">
                <Path
                    d="M66.5436 46.0282C67.3786 46.3048 68.0645 45.3201 68.8125 45.1183C69.572 44.9134 70.2756 44.8163 70.9666 44.4204C71.6201 44.046 72.2235 43.5695 72.6778 42.9636C72.983 42.5575 73.2114 42.0861 73.3383 41.5938C71.5928 43.9705 68.3792 44.0492 65.9878 45.5574C66.0119 45.6278 66.0449 45.6957 66.0969 45.7566C66.1915 45.8664 66.3349 45.959 66.543 46.0282H66.5436Z"
                    fill="#9498AF"
                />
            </G>
        </G>
        <Defs>
            <ClipPath id="clip0_2485_101098">
                <Rect
                    width="91.6244"
                    height="92"
                    fill="white"
                    transform="translate(19.188 19)"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default Sesame;
