import { formatIsoWithTz } from "@madmedical/utils";

function jsonReplacer<TValue>(
    this: Record<string, unknown>,
    key: string,
    value: TValue
): TValue | number | string {
    // Preserve time zone in dates
    if (this && typeof this === "object") {
        const thisValue = this[key];
        if (thisValue instanceof Date) {
            return formatIsoWithTz(thisValue);
        }
    }

    if (typeof value !== "string") {
        return value;
    }

    // Turn decimal commas into dots
    const commaReplaced = value.replace(",", ".");
    if (isNaN(commaReplaced as unknown as number)) {
        // type coercion takes into account the whole string
        return value;
    }

    // send numbers instead of strings
    const maybeNumber = parseFloat(commaReplaced);
    if (isNaN(maybeNumber)) {
        // And this takes care of whitespaces
        return value;
    }

    // Now we are sure this is a number
    return maybeNumber;
}

export default jsonReplacer;
