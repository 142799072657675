import { Box, Text } from "native-base";
import type { TimeData } from "./types";
import { timeGridStamp, timeGridStamps } from "./useStyles";

export default function TimeStamps({
    timeData,
    slot = { x: 0 },
}: {
    timeData: TimeData;
    slot: { x: number; y?: number };
}) {
    const { timeCoords = [{ x: 0, text: "" }] } = timeData;

    return (
        <Box width={slot.x} style={timeGridStamps}>
            {timeCoords.map(({ x, text }, i) => (
                <Text
                    left={`${x}px`}
                    style={timeGridStamp}
                    key={`${i}` + `${x}` + `${text}`}
                >
                    {text}
                </Text>
            ))}
        </Box>
    );
}
