import type { ComponentProps, ReactNode } from "react";
import { Box } from "native-base";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import PressableLink from "./PressableLink";

interface Props<TRouteId extends RouteId>
    extends Pick<
        ComponentProps<typeof Box>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
    link?: {
        route: TRouteId;
        params: AppRoutes[TRouteId];
        search?: Record<string, string>;
    };
}

const ListItem = <TRouteId extends RouteId>({
    link,
    children,
    ...rest
}: Props<TRouteId>) => {
    const inner = (
        <Box
            p={4}
            bgColor="white"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            borderBottomColor="gray.100"
            flexDirection="row"
            alignItems="center"
            {...rest}
        >
            {children}
        </Box>
    );

    return link ? (
        <PressableLink
            route={link.route}
            params={link.params}
            search={link.search}
        >
            {inner}
        </PressableLink>
    ) : (
        <>{inner}</>
    );
};

export default ListItem;
