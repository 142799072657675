import { Provider } from "react-redux";
import type { ReactNode } from "react";
import { useMemo } from "react";
import type { AppConfig } from "@madmedical/config";
import { getStore } from "./store";
import { storeContext } from "./context";

interface Props {
    config: Pick<
        AppConfig,
        "integrator" | "isProduction" | "testingHostnameBackend"
    >;
    children: ReactNode;
}

const StoreProvider = ({
    config: { integrator, isProduction, testingHostnameBackend },
    children,
}: Props) => {
    const store = useMemo(
        () => getStore({ integrator, isProduction, testingHostnameBackend }),
        [integrator, isProduction, testingHostnameBackend]
    );

    return (
        <Provider store={store} context={storeContext}>
            {children}
        </Provider>
    );
};

export default StoreProvider;
