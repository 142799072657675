import { AlertLevel, MealSituation, MetricType } from "./types";

const FOR_HUMANS = {
    [MetricType.BloodOxygen]: "bloodOxygen",
    [MetricType.BloodPressure]: "bloodPressure",
    [MetricType.BloodSugar]: "bloodSugar",
    [MetricType.BodyHeight]: "bodyHeight",
    [MetricType.BodyMassIndex]: "bodyMassIndex",
    [MetricType.BodyTemperature]: "bodyTemperature",
    [MetricType.BodyWeight]: "bodyWeight",
    [MetricType.DailySteps]: "dailySteps",
    [MetricType.FoetalHeartRate]: "foetalHeartRate",
    [MetricType.FoetalHeartRateVariability]: "foetalHeartRateVariability",
    [MetricType.HeartRateVariability]: "heartRateVariability",
    [MetricType.RestingHeartRate]: "restingHeartRate",
    [MetricType.SleepTime]: "sleepTime",
    [MetricType.UterineActivity]: "uterineActivity",
    [MetricType.Arrhythmia]: "arrhythmia",
    [MetricType.AbdominalCircumference]: "abdominalCircumference",
    [AlertLevel.Unevaluated]: "unevaluated",
    [AlertLevel.Ok]: "ok",
    [AlertLevel.Warning]: "warning",
    [AlertLevel.Alert]: "alert",
    [MealSituation.Fasting]: "fasting",
    [MealSituation.BeforeMeal]: "beforeMeal",
    [MealSituation.AfterMeal]: "afterMeal",
    [MealSituation.Random]: "random",
    insulin: "insulin",
} as const;

export const forHumans = (
    type: MetricType | AlertLevel | MealSituation | "insulin"
): string => FOR_HUMANS[type];

export const getMetricTypeChoices = (selected: MetricType[] | undefined) =>
    Object.values(MetricType)
        .map((metricType) => ({
            key: metricType,
            text: forHumans(metricType),
            isSelected: !!selected?.includes(metricType),
        }))
        .sort(({ text: a }, { text: b }) => a.localeCompare(b));

export const getAlertLevelChoices = (selected: AlertLevel | undefined) =>
    [AlertLevel.Ok, AlertLevel.Warning, AlertLevel.Alert].map((alertLevel) => ({
        key: alertLevel,
        text: forHumans(alertLevel),
        isSelected: alertLevel === selected,
    }));
