import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { skipToken } from "@madmedical/store";
import { useTranslation } from "@madmedical/i18n";
import { useDeleteCommentMutation, useGetCommentQuery } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const DeleteCommentDialog = ({ id, open, onClose }: Props) => {
    const { data: comment } = useGetCommentQuery(id ?? skipToken, {
        skip: !open,
    });
    const [deleteComment, { isLoading: isSubmitting }] =
        useDeleteCommentMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!id || !comment) {
        return null;
    }

    const handleConfirm = () => {
        void deleteComment(id)
            .unwrap()
            .then(() => {
                showToast(t("commentDeleted") + ".", { variant: "success" });
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            onClose={onClose}
        >
            <Text>{t("sureToDeleteComment")}</Text>
        </Dialog>
    );
};

export default DeleteCommentDialog;
