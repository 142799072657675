import "./bootstrap";
import type { ReactNode } from "react";
import { NativeBaseProvider } from "native-base";
import { ThemeProvider as StyledComponentsProvider } from "styled-components";
import theme from "./theme";

interface Props {
    children: ReactNode;
}

const UiProvider = ({ children }: Props) => (
    <NativeBaseProvider
        theme={theme}
        config={{
            strictMode: "error",
        }}
    >
        <StyledComponentsProvider theme={theme}>
            {children}
        </StyledComponentsProvider>
    </NativeBaseProvider>
);
export default UiProvider;
