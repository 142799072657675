import { Box, HStack, VStack } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { formatDateRange, invariant } from "@madmedical/utils";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import ThresholdsProtocolsTable from "../organisms/ThresholdsProtocolsTable";
import { useCurrentPatient } from "../providers/currentPatient";
import { useChart } from "../providers/chart";
import { useRouteNavigate } from "../providers/routing";
import type { Evaluation } from "../providers/evaluation";
import Headline from "../molecules/Headline";
import Paper from "../atoms/Paper";
import Metrics from "../molecules/Metrics";
import StatusBadge from "../atoms/StatusBadge";
import IconButton from "../atoms/IconButton";
import DeviceSection from "../organisms/DeviceSection";
import MedicalAdviceSection from "../organisms/EvaluationDetails/MedicalAdviceSection";
import AttachmentSection from "../organisms/EvaluationDetails/AttachmentSection";
import Breadcrumb from "../molecules/Breadcrumb";
import RoutableTabs from "../molecules/RoutableTabs";
import DetailsSection from "../organisms/EvaluationDetails/DetailsSection";
import PatientDetailsSection from "../organisms/Patient/PatientDetailsSection";
import type { PatientDoctor } from "../providers/patient";
import ActionButton from "../molecules/ActionButton";

interface Props {
    evaluation: Evaluation;
    onDeletePress: () => unknown;
    onEditThresholdPress: (metricType: MetricType) => void;
    canEdit: boolean;
}

const DoctorEvaluationDetails = ({
    evaluation,
    onDeletePress,
    onEditThresholdPress,
    canEdit,
}: Props) => {
    const navigate = useRouteNavigate();
    const { chart, displayRange, alertLevel } = useChart();
    const { patient } = useCurrentPatient<PatientDoctor>();
    const { t } = useTranslation();
    const handleClosePress = () => {
        navigate("opinions", {});
    };

    invariant(patient, "Patient is not defined");

    return (
        <VStack
            space={3}
            mb={{
                base: 24,
                md: 6,
            }}
        >
            <Paper bgColor="transparent" overflow="visible">
                <Box opacity={0.4} width="full" bgColor="white">
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        leftContent={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patients"
                                        params={{}}
                                    >
                                        {t("patients")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patient"
                                        params={{ patientId: patient.userId }}
                                    >
                                        {patient.fullName}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {forHumans(evaluation.metricType)}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    />

                    <Box px="4">
                        <RoutableTabs
                            tabs={[
                                {
                                    title: forHumans(evaluation.metricType),
                                    route: "measurements",
                                    params: {
                                        metricType: evaluation.metricType,
                                    },
                                },
                                {
                                    title: t("measures:title"),
                                    route: "measurements_list",
                                    params: {
                                        metricType: evaluation.metricType,
                                    },
                                },
                            ]}
                            _tab={{ px: 2, py: 4 }}
                        />
                    </Box>
                </Box>

                <Box
                    bgColor="white"
                    borderRadius="xl"
                    borderColor="gray.600"
                    borderStyle="dashed"
                    borderWidth="1"
                    shadow="4"
                    width="full"
                >
                    <Headline
                        bgColor="transparent"
                        leftContent={
                            <>
                                <VStack>
                                    <Box>
                                        {formatDateRange(
                                            evaluation.dateRange,
                                            "long"
                                        )}
                                    </Box>
                                    <HStack alignItems="center">
                                        {displayRange && (
                                            <Metrics
                                                displayValue={[displayRange]}
                                                valueFontSize="5xl"
                                            />
                                        )}
                                        {alertLevel ? (
                                            <Box pt={3.5}>
                                                <StatusBadge
                                                    level={alertLevel}
                                                    size="lg"
                                                />
                                            </Box>
                                        ) : null}
                                    </HStack>
                                </VStack>
                            </>
                        }
                        rightContent={
                            <>
                                <ActionButton
                                    actions={
                                        canEdit
                                            ? [
                                                  {
                                                      iconName: "edit",
                                                      text: t("edit"),
                                                      onPress: () => {
                                                          navigate(
                                                              "opinion_edit",
                                                              {
                                                                  opinionId:
                                                                      evaluation.id,
                                                              }
                                                          );
                                                      },
                                                  },
                                                  {
                                                      iconName: "trashCan",
                                                      text: t("delete"),
                                                      onPress: onDeletePress,
                                                  },
                                              ]
                                            : []
                                    }
                                />
                                <IconButton
                                    variant="ghost"
                                    iconName="close"
                                    onPress={handleClosePress}
                                />
                            </>
                        }
                    />

                    <Box mt={6} width="100%">
                        {chart}
                    </Box>

                    <VStack
                        width="full"
                        space={3}
                        p={{
                            base: 4,
                            md: 6,
                        }}
                    >
                        <MedicalAdviceSection data={evaluation.text} />
                        <AttachmentSection documents={evaluation.documents} />
                        <DetailsSection
                            evaluation={evaluation}
                            measurementRange={displayRange}
                            alertLevel={alertLevel}
                        />
                    </VStack>
                </Box>
            </Paper>

            <VStack opacity="0.4" space={3} width="full">
                <Box px={{ base: 4, md: 0 }}>
                    <PatientDetailsSection borderRadius="lg" isDoctor={true} />
                </Box>

                <ThresholdsProtocolsTable
                    title={t("measures:measureDetails")}
                    onEditPress={onEditThresholdPress}
                />

                <DeviceSection />
            </VStack>
        </VStack>
    );
};

export default DoctorEvaluationDetails;
