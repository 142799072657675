import { Line, Svg } from "react-native-svg";
import { Text } from "native-base";
import { isWeb } from "@madmedical/utils";
import type { GraphData } from "./types";
import {
    boxShadow,
    colors,
    crosshairTag,
    svgNative,
    svgWeb,
    tag,
} from "./useStyles";

export default function CrosshairCommon({
    crosshairX,
    graphData = {},
    xMsecRatio,
}: {
    crosshairX: number;
    graphData?: GraphData;
    xMsecRatio: number;
}) {
    const { limits, coords = [] } = graphData;

    // ###########################

    const x =
        crosshairX > coords[0].x && crosshairX < coords[coords.length - 1].x
            ? crosshairX - coords[0].x
            : 0;

    const aDayInMsec = 24 * 3600 * 1000;
    const days = Math.round(
        (coords[coords.length - 1].msec - coords[0].msec) / aDayInMsec
    );

    const time = new Date(coords[0].msec + x * xMsecRatio);

    const mins = ("0" + `${time.getMinutes()}`).slice(-2);

    const hours = time.getHours();

    const monthDay = time.getDate();
    const month = time.getMonth() + 1;

    const shiftToCenter = days === 1 ? 20 : days <= 7 ? 16 : 32;

    // ###########################

    const CrosshairLine = () => (
        <Line
            x1={crosshairX}
            y1={limits?.yMin}
            x2={crosshairX}
            y2="100%"
            stroke={colors.green300}
            strokeWidth="2px"
        />
    );

    // ###########################

    const CrosshairTop = () => (
        <Text
            style={[
                boxShadow,
                tag,
                crosshairTag,
                {
                    transform: [
                        { translateX: crosshairX - shiftToCenter },
                        { translateY: -2 },
                    ],
                },
            ]}
        >
            {days === 1
                ? `${hours}:${mins}`
                : days <= 7
                ? `${hours}h`
                : `${month}.${monthDay} | ${hours}h`}
        </Text>
    );

    // ###########################

    return x > 0 ? (
        <>
            {isWeb ? (
                <Svg style={svgWeb}>
                    <CrosshairLine />
                </Svg>
            ) : (
                <Svg style={svgNative} transform={[{ scaleY: -1 }]}>
                    <CrosshairLine />
                </Svg>
            )}

            <CrosshairTop />
        </>
    ) : (
        <></>
    );
}
