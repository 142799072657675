import type { BodyTemperature } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<BodyTemperature>;

const BodyTemperatureChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BodyTemperature, widget);

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "dot",
            unit: "°C",
            limits: {
                yMax: max ? Math.ceil(max.celsius + 0.1) : 40,
                yMin: min ? Math.floor(min.celsius - 0.1) : 35,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.celsius,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.BodyTemperature}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BodyTemperatureChart;
