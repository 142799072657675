import type { ComponentProps } from "react";
import { Box, Pressable, Stack, Text } from "native-base";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import { useLinking } from "../providers/routing";
import Badge from "../atoms/Badge";

export interface TabContent<TRouteId extends RouteId> {
    route: TRouteId;
    params: AppRoutes[TRouteId];
    title: string;
    badge?: string | number; // TODO: Implement
}

interface TabProps<TRouteId extends RouteId>
    extends ComponentProps<typeof Pressable>,
        TabContent<TRouteId> {
    _tab?: Partial<ComponentProps<typeof Pressable>>;
}

export interface TabsProps extends ComponentProps<typeof Stack> {
    tabs: TabContent<RouteId>[];
    _tab?: Partial<ComponentProps<typeof Pressable>>;
}

export const Tab = <TRouteId extends RouteId>({
    route,
    params,
    title,
    badge,
    _tab,
}: TabProps<TRouteId>) => {
    const { isActive, ...linkProps } = useLinking(route, params);

    return (
        <Pressable
            {...(isActive
                ? {
                      borderBottomColor: "green.600",
                      borderBottomStyle: "solid",
                      borderBottomWidth: 2,
                  }
                : undefined)}
            p={2}
            fontSize="md"
            {..._tab}
            {...linkProps}
        >
            <Stack direction="row" alignItems="center" space={1}>
                <Text
                    fontWeight="medium"
                    color={isActive ? "green.600" : "gray.600"}
                >
                    {title}
                </Text>
                {badge && (
                    <Badge
                        variant={isActive ? "ghostSuccess" : "ghost"}
                        hasIndicator={false}
                    >
                        {badge}
                    </Badge>
                )}
            </Stack>
        </Pressable>
    );
};

const RoutableTabsBreaking = ({
    tabs,
    size = "sm",
    _tab,
    ...rest
}: TabsProps) => {
    if (!tabs.length) {
        return null;
    }

    return (
        <Box testID="tabs">
            <Stack
                direction="row"
                //space={2}
                width="full"
                flexWrap={"wrap"}
                justifyContent="flex-start"
                {...rest}
            >
                {tabs.map((tab, tabIndex) => (
                    <Tab
                        size={size}
                        key={`tab-item-${tabIndex}`}
                        title={tab.title}
                        route={tab.route}
                        params={tab.params}
                        badge={tab.badge}
                        _tab={_tab}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default RoutableTabsBreaking;
