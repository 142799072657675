import { useState } from "react";
import type { ComponentProps, ReactElement } from "react";
import type { GestureResponderEvent } from "react-native";
import { Animated, Dimensions, Platform, StyleSheet } from "react-native";
import {
    Box,
    FlatList,
    Popover,
    Pressable,
    Stack,
    Text,
    useTheme,
} from "native-base";
import { isWeb } from "@madmedical/utils";
import type Avatar from "../atoms/Avatar/Avatar";
import Icon from "../icons/Icon";

type IconName = ComponentProps<typeof Icon>["name"];

type AvatarProps = ComponentProps<typeof Avatar>;

type SubmenuType<TKey extends string | number> = {
    key?: TKey;
    text: string;
    iconName?: IconName;
    iconColor?: string;
    onPress?: (value: unknown) => void;
    disabled?: boolean;
};

type DataType<TKey extends string | number> = {
    key?: TKey;
    text: string;
    subtext?: string;
    formControl?: ReactElement;
    iconName?: IconName;
    iconColor?: string;
    avatar?: ReactElement<AvatarProps>;
    onPress?: (value: unknown) => void;
    disabled?: boolean;
    subSubmenu?: SubmenuType<TKey>[];
};

interface Props<TKey extends string | number>
    extends ComponentProps<typeof Box> {
    list: DataType<TKey>[];
    maxListHeight?: number;
    triggerClose?: () => void;
}

const useStyles = () => {
    const { colors, space } = useTheme();

    const styles = StyleSheet.create({
        card: {
            shadowColor: colors.black,
            shadowOffset: {
                width: 4,
                height: 2,
            },
            shadowOpacity: 0.05,
            shadowRadius: 4,
            ...Platform.select({
                android: {
                    elevation: 2,
                },
            }),
        },
        indicator: {
            backgroundColor: colors.gray[100],
            position: "absolute",
            right: space[1],
            width: space[1],
        },
    });

    return styles;
};

const DropdownSubmenu = <TKey extends string | number>({
    list,
    maxListHeight = 200,
    triggerClose,
    ...rest
}: Props<TKey>) => {
    const { colors } = useTheme();
    const styles = useStyles();

    const [layout, setLayout] = useState({
        indicator: new Animated.Value(0),
        wholeHeight: 1,
        visibleHeight: 0,
    });

    const [submenuVisible, setSubmenuVisible] = useState<boolean>(false);
    const [submenuItems, setSubmenuItems] = useState<SubmenuType<TKey>[]>([]);

    // used for mobile submenu
    const [subMenuPlace, setSubMenuPlace] = useState(0);
    const windowWidth = Dimensions.get("window").width;

    const indicatorSize =
        layout.wholeHeight > layout.visibleHeight
            ? (layout.visibleHeight * layout.visibleHeight) / layout.wholeHeight
            : layout.visibleHeight;
    const diff =
        layout.visibleHeight > indicatorSize
            ? layout.visibleHeight - indicatorSize
            : 1;

    return (
        <Box bgColor="white" borderRadius="md" style={styles.card} {...rest}>
            <Stack direction="column">
                <Box>
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        onContentSizeChange={(width, height) => {
                            setLayout((prevState) => ({
                                ...prevState,
                                wholeHeight: height,
                            }));
                        }}
                        onLayout={({ nativeEvent }) =>
                            setLayout((prevState) => ({
                                ...prevState,
                                visibleHeight: nativeEvent.layout.height,
                            }))
                        }
                        scrollEventThrottle={16}
                        onScroll={Animated.event(
                            [
                                {
                                    nativeEvent: {
                                        contentOffset: { y: layout.indicator },
                                    },
                                },
                            ],
                            {
                                useNativeDriver: false,
                            }
                        )}
                        maxHeight={maxListHeight}
                        data={list}
                        renderItem={({ item }) => {
                            const triggerPress = () => {
                                if (item.onPress) item.onPress(item.key);
                                if (triggerClose) triggerClose();
                            };

                            const openSubmenu = (e: GestureResponderEvent) => {
                                if (item.subSubmenu) {
                                    setSubmenuVisible(!submenuVisible);
                                    setSubmenuItems(item.subSubmenu);
                                    setSubMenuPlace(e.nativeEvent.pageY);
                                }
                            };

                            if (item.subSubmenu) {
                                return (
                                    <Popover
                                        placement="left top"
                                        trigger={(triggerProps) => (
                                            <Pressable
                                                onPress={(e) => openSubmenu(e)}
                                                _hover={{
                                                    backgroundColor:
                                                        "blueGray.50",
                                                }}
                                                _pressed={{
                                                    backgroundColor:
                                                        "blueGray.100",
                                                }}
                                                position={"relative"}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    px={3}
                                                    py={2.5}
                                                    {...triggerProps}
                                                >
                                                    {item.formControl && (
                                                        <Box mr={3}>
                                                            {item.formControl}
                                                        </Box>
                                                    )}
                                                    {item.avatar && item.avatar}
                                                    {item.iconName && (
                                                        <Box mr={2}>
                                                            <Icon
                                                                name={
                                                                    item.iconName
                                                                }
                                                                fill={
                                                                    item.iconColor ??
                                                                    colors
                                                                        .gray[600]
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                    <Text
                                                        fontSize="sm"
                                                        color="black"
                                                        alignSelf="flex-start"
                                                        mr={
                                                            item.subtext ? 1 : 0
                                                        }
                                                    >
                                                        {item.text}
                                                    </Text>
                                                    {item.subtext && (
                                                        <Text
                                                            fontSize="sm"
                                                            color="gray.500"
                                                            alignSelf="flex-start"
                                                        >
                                                            {item.subtext}
                                                        </Text>
                                                    )}

                                                    <Box ml={4}>
                                                        <Icon
                                                            name="chevronRight"
                                                            fill={
                                                                colors.gray[600]
                                                            }
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Pressable>
                                        )}
                                        isOpen={submenuVisible}
                                        onClose={() =>
                                            setSubmenuVisible(
                                                (prevState) => !prevState
                                            )
                                        }
                                    >
                                        {isWeb ? (
                                            <Popover.Content
                                                borderColor="gray.100"
                                                bgColor="transparent"
                                                overflow={"hidden"}
                                            >
                                                <DropdownSubmenu
                                                    maxWidth={"auto"}
                                                    list={submenuItems}
                                                    triggerClose={triggerClose}
                                                />
                                            </Popover.Content>
                                        ) : (
                                            <Box
                                                top={subMenuPlace + 20}
                                                right={-windowWidth / 2 + 35}
                                                width={windowWidth / 2}
                                            >
                                                <DropdownSubmenu
                                                    list={submenuItems}
                                                    triggerClose={triggerClose}
                                                />
                                            </Box>
                                        )}
                                    </Popover>
                                );
                            }

                            return (
                                <Pressable
                                    onPress={triggerPress}
                                    px={3}
                                    py={2.5}
                                    _hover={{
                                        backgroundColor: "blueGray.50",
                                    }}
                                    _pressed={{
                                        backgroundColor: "blueGray.100",
                                    }}
                                    disabled={item.disabled}
                                >
                                    <Stack direction="row" alignItems="center">
                                        {item.formControl && (
                                            <Box mr={3}>{item.formControl}</Box>
                                        )}
                                        {item.avatar && item.avatar}
                                        {item.iconName && (
                                            <Box mr={2}>
                                                <Icon
                                                    name={item.iconName}
                                                    fill={
                                                        item.iconColor ??
                                                        colors.gray[600]
                                                    }
                                                />
                                            </Box>
                                        )}
                                        <Text
                                            fontSize="sm"
                                            color="black"
                                            alignSelf="flex-start"
                                            mr={item.subtext ? 1 : 0}
                                        >
                                            {item.text}
                                        </Text>
                                        <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            alignSelf="flex-start"
                                        >
                                            {item.subtext}
                                        </Text>
                                    </Stack>
                                </Pressable>
                            );
                        }}
                        keyExtractor={({ key }, index) =>
                            key ? String(key) : index.toString()
                        }
                    />

                    {layout.wholeHeight > layout.visibleHeight && (
                        <Animated.View
                            style={[
                                styles.indicator,
                                {
                                    height: indicatorSize,
                                    transform: [
                                        {
                                            translateY: Animated.multiply(
                                                layout.indicator,
                                                layout.visibleHeight /
                                                    layout.wholeHeight
                                            ).interpolate({
                                                inputRange: [0, diff],
                                                outputRange: [0, diff],
                                                extrapolate: "clamp",
                                            }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </Box>
            </Stack>
        </Box>
    );
};

export default DropdownSubmenu;
