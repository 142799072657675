import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Box, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Paper from "../../atoms/Paper";
import IconButton from "../../atoms/IconButton";
import useResponsive from "../../util/useResponsive";
import { getBabyStats } from "../../util/getBabyStats";
import PregnancyDashDatas from "./PregnancyDashDatas";

interface Props {
    weekNr: number;
    dayNr: number;
    children: ReactNode;
    setWeekNr: (weekNr: number) => void;
    isBaby?: boolean;
}

const PregnancyWeekInfosImage = ({
    weekNr,
    dayNr,
    isBaby = false,
    children,
    setWeekNr,
}: Props) => {
    const calculatedToEnd = 280 - dayNr;
    const { t } = useTranslation();
    const { isSmallScreen } = useResponsive();

    const [heightText, setHeightText] = useState("-");
    const [weightText, setWeightText] = useState("-");

    useEffect(() => {
        const { height, weight } = getBabyStats(weekNr);
        setHeightText(height);
        setWeightText(weight);
    }, [weekNr]);

    return (
        <Paper
            bgColor={"#EDF5F3"}
            p={4}
            alignItems="center"
            width={isSmallScreen ? "100%" : 330}
            height={260}
        >
            <VStack width={"100%"}>
                <Box mb={4} width={"full"}>
                    <Text color={"gray.600"} textAlign={"center"}>
                        {t("pregnancy:weekNumber", { weekNr: weekNr })}
                    </Text>
                </Box>
                <Box
                    flexDirection={"row"}
                    alignItems={"center"}
                    width={"full"}
                    justifyContent={"space-between"}
                >
                    <IconButton
                        variant="lightGray"
                        iconName={"chevronLeft"}
                        size={"sm"}
                        onPress={() => {
                            setWeekNr(weekNr - 1);
                        }}
                        isDisabled={weekNr === 1}
                    />
                    {children}
                    <IconButton
                        variant="lightGray"
                        iconName={"chevronRight"}
                        size={"sm"}
                        onPress={() => {
                            if (weekNr < 41) {
                                setWeekNr(weekNr + 1);
                            }
                        }}
                    />
                </Box>
                <Box my={4} width={"full"} alignItems={"center"}>
                    {isBaby ? (
                        <PregnancyDashDatas
                            weight={weightText}
                            height={heightText}
                        />
                    ) : (
                        <Text fontSize={"md"} textAlign={"center"}>
                            {t("pregnancy:daysTillDelivery", {
                                days: calculatedToEnd,
                            })}
                        </Text>
                    )}
                </Box>
            </VStack>
        </Paper>
    );
};

export default PregnancyWeekInfosImage;
