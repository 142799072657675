import Svg, { Path, Rect } from "react-native-svg";

const Pomelo = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M22.7439 75.6979C22.5733 75.1756 19.9787 69.1048 21.635 60.4999C23.2912 51.895 25.8787 47.3137 25.8787 47.3137L33.2573 37.9746C33.2573 37.9746 37.1314 32.8498 39.3421 28.8191C41.5528 24.7884 45.2421 21.8589 45.2421 21.8589C45.2421 21.8589 49.8555 18.9294 53.5377 19.2965C57.227 19.6635 59.068 22.0424 59.068 22.0424L64.2288 31.198L67.7332 34.8616C67.7332 34.8616 73.2636 39.6193 77.1377 44.9348C81.0118 50.2431 82.8529 55.008 82.8529 55.008C82.8529 55.008 86.3574 60.6834 85.8029 68.9283C85.2485 77.1662 83.2226 83.2087 83.2226 83.2087C83.2226 83.2087 80.0877 89.9853 75.4743 94.016C70.861 98.0467 66.6243 99.3244 66.6243 99.3244L53.1609 100.059C53.1609 100.059 43.9413 99.3244 35.6457 93.4654C27.3501 87.6065 24.4073 80.8228 22.7439 75.6979Z"
            fill="#B6D2C6"
        />
        <Path
            d="M54.4902 35.857C54.3196 36.2876 54.1205 36.7394 53.7509 37.0217C53.4097 37.2759 52.969 37.3606 52.5496 37.4241C50.154 37.8053 47.73 38.0029 45.299 38.01C47.8082 38.13 50.3104 38.2429 52.8197 38.3629C53.3315 38.3841 53.8789 38.4194 54.3054 38.7088C54.7248 38.9912 54.9522 39.4712 55.1655 39.93C56.8928 43.6784 58.6486 47.6032 58.4993 51.7256C58.4354 53.3704 58.0657 54.9869 57.7032 56.5963C55.8977 64.4954 53.8149 72.8885 47.8722 78.444C46.7349 79.5099 45.4127 80.4699 43.8773 80.7452C41.7021 81.1334 38.745 79.9122 36.7688 79.1287C34.9917 78.4228 33.6411 76.171 31.5228 76.418C30.2362 76.5733 29.2836 77.8086 29.0775 79.0793C28.8784 80.3569 29.2694 81.6417 29.7599 82.8417C31.6294 87.4159 35.077 91.2702 39.2781 93.8749C43.7138 96.6138 48.4835 97.0374 53.6087 97.1715C61.0015 97.3691 70.4629 96.4868 75.6947 90.5078C78.012 87.8536 79.384 84.5147 80.3223 81.1264C82.2202 74.3003 82.5188 67.0719 81.5236 60.0553C80.1588 50.3985 74.273 43.2336 67.1432 36.9229C65.3164 35.3134 63.4468 33.7181 62.0038 31.7628C59.5372 28.4168 59.4377 21.9366 54.675 20.666C55.2366 21.3084 54.9878 22.3672 54.3551 22.9461C53.7225 23.5249 52.8481 23.7226 52.0022 23.8637C50.2962 24.139 48.2063 24.0685 47.2609 22.6284C47.5737 23.899 48.974 24.5908 50.2678 24.8379C51.5615 25.085 52.9476 25.0638 54.1134 25.6779C57.3478 27.3791 55.4569 33.4075 54.4831 35.8782L54.4902 35.857Z"
            fill="#7B9791"
        />
        <Path
            opacity="0.46"
            d="M39.5696 41.1441C36.2642 39.3935 33.2289 42.4077 30.6769 44.3136C29.1913 45.4219 28.7932 46.7913 27.9117 48.3513C25.9356 51.8173 24.3717 55.4598 24.1016 59.4834C23.8812 62.7023 24.4712 65.9071 25.1181 69.0695C25.701 71.9284 26.3408 74.8085 27.5776 77.4627C29.2552 81.0769 31.9564 84.1123 34.615 87.0841C35.731 88.3335 36.9252 89.6324 38.5389 90.1054C40.9131 90.8113 43.7138 89.1806 44.2612 86.7876C44.645 85.1146 44.0479 83.3993 43.4935 81.7757C42.221 78.0627 41.105 74.1944 41.4107 70.2837C41.695 66.6412 43.1807 63.2176 44.3536 59.7587C45.5265 56.2998 46.4008 52.5373 45.4198 49.022C44.8298 46.9043 43.6001 45.0124 42.1571 43.3536C41.4178 42.4994 40.5932 41.6806 39.5909 41.1512L39.5696 41.1441Z"
            fill="#DCEAE4"
        />
        <Path
            opacity="0.46"
            d="M48.5831 26.0309C45.7965 25.2545 44.4673 25.6003 42.5053 27.5345C40.8135 29.2004 39.2852 30.9934 39.5909 33.5347C39.7259 34.6217 40.2875 35.7018 41.2542 36.2383C42.1428 36.7324 43.2233 36.7112 44.2398 36.6689C45.2776 36.6265 46.3084 36.5912 47.3462 36.5489C48.5902 36.4994 49.9052 36.4289 50.936 35.73C52.1799 34.89 52.6917 33.337 53.0827 31.897C53.4168 30.6475 53.694 29.2146 52.9619 28.1345C52.5069 27.4639 51.7463 27.0757 50.9928 26.7863C50.2038 26.4827 49.3934 26.2568 48.5831 26.0309Z"
            fill="#DCEAE4"
        />
        <Path
            d="M70.8894 38.2425C70.7757 37.4166 69.3042 37.7202 69.1549 38.539C69.0057 39.3578 69.617 40.1061 70.1572 40.7414C75.4388 47.0169 77.1377 55.4947 77.8486 63.6408C78.3462 69.3304 78.4386 75.1823 76.6188 80.5965C75.1474 84.959 72.439 88.8979 68.8848 91.8486C67.7404 92.7945 66.4608 93.7122 65.9064 95.0746C65.3448 96.444 65.9632 98.3994 67.4347 98.597C68.0816 98.6817 68.7213 98.4205 69.3042 98.1311C72.3608 96.6275 74.9839 94.3545 77.1946 91.7709C85.22 82.4107 87.6653 68.6527 83.3576 57.1324C82.0497 53.6312 80.1872 50.3699 78.3177 47.1298C76.5477 44.0591 74.664 40.8755 71.6784 38.9484C71.0955 38.5672 70.2639 38.2637 69.752 38.7367L70.8894 38.2355V38.2425Z"
            fill="black"
        />
        <Path
            d="M105.543 91.9974C106.055 91.4115 104.249 97.3058 97.2475 102.805C90.2386 108.297 87.2886 109.031 87.2886 109.031C87.2886 109.031 77.3296 111.593 68.8493 109.765C60.3689 107.937 52.8055 100.793 52.8055 100.793C52.8055 100.793 45.9814 93.6492 43.5858 85.7784C41.1903 77.9076 43.0314 69.6627 43.0314 69.6627L44.8725 64.3544L47.268 61.6084C47.268 61.6084 43.3939 66.7332 45.6117 74.9782C47.8225 83.216 53.3599 87.0632 53.3599 87.0632L61.4707 93.6563L69.7662 98.2376C69.7662 98.2376 74.7422 100.433 82.6752 100.249C90.6011 100.066 96.1386 98.054 96.1386 98.054C96.1386 98.054 102.963 94.941 105.543 92.0115V91.9974Z"
            fill="#B6D2C6"
        />
        <Path
            d="M107.939 87.2326C107.939 87.2326 111.628 82.1078 104.434 72.2181C97.2404 62.3284 89.8689 59.9495 89.8689 59.9495C89.8689 59.9495 84.1537 55.9189 72.1689 53.7235C60.184 51.5282 51.7037 56.653 51.7037 56.653C51.7037 56.653 47.0903 60.6837 45.8037 63.2461C44.517 65.8085 44.8796 72.0346 44.8796 72.0346C44.8796 72.0346 46.7206 80.6395 52.6278 86.4985C58.5278 92.3574 62.5867 94.5528 62.5867 94.5528C62.5867 94.5528 67.9323 98.767 77.7064 100.045C87.4805 101.329 97.9939 97.4823 97.9939 97.4823C97.9939 97.4823 105.92 93.268 107.953 87.2255L107.939 87.2326Z"
            fill="#FFFAD0"
        />
        <Path
            d="M106.254 86.661C106.254 86.661 109.744 81.8114 102.941 72.4653C96.1386 63.1121 89.1652 60.8603 89.1652 60.8603C89.1652 60.8603 83.7628 57.0484 72.4248 54.9731C61.0939 52.8977 53.0685 57.7402 53.0685 57.7402C53.0685 57.7402 48.711 61.5521 47.4883 63.9733C46.2657 66.3946 46.614 72.2818 46.614 72.2818C46.614 72.2818 48.3556 80.4208 53.9357 85.9621C59.5158 91.5034 63.3544 93.5788 63.3544 93.5788C63.3544 93.5788 68.4085 97.5601 77.6566 98.7742C86.8976 99.9884 96.8352 96.353 96.8352 96.353C96.8352 96.353 104.335 92.3717 106.254 86.6539V86.661Z"
            fill="#FFFAD0"
        />
        <Path
            d="M59.26 59.0322C59.26 59.0322 55.5707 58.8486 53.1751 61.7781C50.7796 64.7076 53.7296 67.27 53.7296 67.27C53.7296 67.27 51.7037 68.0042 51.7037 69.0983C51.7037 70.1925 53.5448 71.2937 53.5448 71.2937C53.5448 71.2937 52.9903 72.9384 53.5448 73.6726C54.0992 74.4067 55.0233 74.5902 55.0233 74.5902C55.0233 74.5902 52.6278 74.9573 53.9144 77.3362C55.2081 79.7151 57.9733 83.0116 57.9733 83.0116L60.3689 85.9411C60.3689 85.9411 62.9492 86.6752 64.6126 86.1246C66.2689 85.574 66.8233 84.6564 66.8233 84.6564C66.8233 84.6564 67.5626 87.0353 69.5885 87.5859C71.6144 88.1365 72.7234 87.5859 72.7234 87.5859C72.7234 87.5859 71.6144 90.3318 72.7234 91.9836C73.8323 93.6354 75.4885 93.9954 75.4885 93.9954L79.7323 94.7296C79.7323 94.7296 82.1278 94.546 82.1278 93.4448C82.1278 92.3436 81.943 90.5153 81.943 90.5153L84.893 92.8942C84.893 92.8942 86.9189 93.9954 87.6582 93.2613C88.3975 92.5272 88.2127 91.2495 88.2127 91.2495C88.2127 91.2495 91.1627 93.8119 92.4564 92.7178C93.7501 91.6165 93.7501 89.9718 93.7501 89.9718C93.7501 89.9718 95.9609 92.9013 97.8091 92.5342C99.6502 92.1671 100.759 90.3389 100.759 90.3389V88.8706C100.759 88.8706 102.97 88.32 103.155 85.7576C103.339 83.1952 102.785 81.9104 102.785 81.9104L100.944 78.7974C100.944 78.7974 100.574 76.4185 99.2876 74.9502L97.9939 73.482C97.9939 73.482 96.7001 69.0842 93.935 67.2559C91.1698 65.4276 88.959 65.6112 88.959 65.6112C88.959 65.6112 88.035 63.9664 86.5635 63.4158C85.0849 62.8652 84.3528 63.0488 84.3528 63.0488C84.3528 63.0488 82.5117 60.3028 78.8224 59.2016C75.1331 58.1004 74.7635 59.5687 74.7635 59.5687C74.7635 59.5687 72.9224 56.2721 69.6027 56.8227C66.2831 57.3733 67.0224 57.5569 67.0224 57.5569C67.0224 57.5569 61.8616 54.8109 59.2742 59.0251L59.26 59.0322Z"
            fill="#FACD8A"
        />
        <Path
            d="M75.8511 70.0511C74.8843 70.0017 73.9176 69.9453 72.9437 69.8958C72.7802 69.8888 72.6096 69.8817 72.4461 69.8958C71.8561 69.9806 71.4083 70.4535 71.0173 70.8982C70.6406 71.3288 70.2425 71.7877 70.1359 72.3453C70.0293 72.91 70.3349 73.5877 70.9036 73.6795C71.1737 73.7218 71.4723 73.6301 71.714 73.7501C71.9485 73.863 72.0623 74.1242 72.1334 74.3642C72.3537 75.0772 72.4035 75.8466 72.2755 76.5807C74.3583 75.7548 77.0169 77.1313 77.5358 79.2914C77.5216 78.3172 78.403 77.4913 79.3626 77.3149C80.3223 77.1313 81.3175 77.4702 82.1563 77.9855C81.6302 77.1949 81.3459 76.2419 81.3601 75.296C81.3601 74.7454 81.4668 74.1948 81.3601 73.6583C81.2535 73.1218 80.8625 72.5783 80.3152 72.5359C79.9598 72.5077 79.6257 72.6842 79.2702 72.7406C78.2324 72.8889 77.3865 71.95 76.8249 71.0747C76.5335 70.6229 76.0928 70.0864 75.5667 70.2347L75.844 70.0511H75.8511Z"
            fill="#DD9051"
        />
        <Path
            d="M65.1599 108.473C65.1599 108.473 72.7233 106.461 75.4885 103.892C78.2537 101.322 78.4385 100.412 78.4385 100.412C78.4385 100.412 88.0278 100.779 94.2975 98.7669C100.567 96.7551 105.543 91.9902 105.543 91.9902C105.543 91.9902 101.299 101.88 91.71 106.821C82.1207 111.763 78.2466 110.485 78.2466 110.485C78.2466 110.485 68.8421 110.852 65.1528 108.473H65.1599Z"
            fill="#7B9791"
        />
        <Path
            opacity="0.46"
            d="M68.1882 98.3926C64.5771 97.2561 61.0584 95.2866 57.6464 93.6207C56.3242 92.9783 54.2841 91.3406 52.7557 92.4771C51.7535 93.2254 52.1373 94.7925 52.6918 95.9078C55.2721 101.117 59.5514 105.565 64.8899 107.943C70.2283 110.315 76.6117 110.492 81.8933 108C82.9311 107.506 84.0116 106.821 84.3386 105.727C84.6869 104.583 84.0685 103.32 83.1301 102.564C82.1989 101.809 81.0047 101.463 79.8389 101.167C75.9648 100.186 71.9912 99.5926 68.1811 98.3926H68.1882Z"
            fill="#DCEAE4"
        />
        <Path
            d="M63.0203 63.034C60.774 61.234 56.9923 58.6574 54.5613 61.7987C54.2343 62.2152 53.9713 62.7022 53.9357 63.2316C53.8575 64.5517 55.1939 65.4694 56.3952 66.0341C57.8098 66.7047 59.267 67.2765 60.7598 67.7494C61.3569 67.94 62.0038 68.1094 62.608 67.94C63.2407 67.7635 63.7169 67.2412 64.3354 67.0082C65.1955 66.6835 66.1622 66.9729 66.9939 67.3682C67.8256 67.7635 68.6289 68.2718 69.5388 68.3988C67.3849 66.5776 65.2097 64.7917 63.0132 63.0269L63.0203 63.034Z"
            fill="#FACD8A"
        />
        <Path
            d="M61.8119 58.9829C62.6365 58.4817 63.6459 58.397 64.6126 58.3335C65.0889 58.2982 65.5865 58.277 66.0059 58.5029C66.4182 58.7288 66.667 59.1523 66.8944 59.5618C67.9678 61.503 68.9843 63.4866 69.9368 65.4913C69.1336 64.4819 67.8541 64.0089 66.7096 63.4019C64.7548 62.3712 63.0701 60.8465 61.8474 59.0182L61.8119 58.99V58.9829Z"
            fill="#FACD8A"
        />
        <Path
            d="M69.7947 59.3849C71.0742 61.2484 72.112 63.2814 72.8798 65.4062C72.5528 64.4603 73.6759 63.5567 74.6853 63.592C74.8346 63.592 74.9838 63.6203 75.1047 63.705C75.2398 63.8109 75.2966 63.9873 75.3464 64.1497C75.7231 65.4132 76.0928 66.6768 76.4695 67.9404C76.2918 66.6556 76.2065 65.3568 76.2207 64.0579C77.2088 64.7779 78.3604 65.2932 79.5617 65.5403C79.8318 65.5968 80.1517 65.6815 80.2441 65.9356C80.2939 66.0768 80.2512 66.2321 80.2157 66.3733C79.9669 67.3051 79.711 68.2439 79.4622 69.1757C80.237 67.6721 81.0189 66.1686 81.7937 64.665C82.1918 63.8885 82.6041 63.0202 82.3553 62.1873C81.9715 60.8955 80.3365 60.5355 78.9859 60.3943C77.6922 60.2602 76.3345 60.1331 75.1331 60.6202C74.927 60.7049 74.7137 60.8037 74.4934 60.8178C73.9318 60.839 73.5337 60.3096 73.1712 59.879C72.7447 59.3637 72.2187 58.926 71.6429 58.5872C71.1311 58.2907 70.47 58.0719 69.9653 58.3754C69.4606 58.679 69.553 59.6813 70.1501 59.6602L69.8018 59.3707L69.7947 59.3849Z"
            fill="#FACD8A"
        />
        <Path
            d="M84.4168 64.5092C84.0613 66.8669 81.1042 68.3705 81.1966 70.7494C83.1301 69.8105 84.8859 68.5117 86.3432 66.9375C86.9403 66.2951 87.4876 65.2575 86.8834 64.6222C86.528 64.248 85.8882 64.1986 85.6252 63.7539C85.2058 64.2692 84.7153 64.728 84.168 65.1092L84.4097 64.5092H84.4168Z"
            fill="#FACD8A"
        />
        <Path
            d="M84.5092 71.5615C87.7578 71.6462 91.1556 70.1991 94.1554 71.4556C94.5605 70.8697 94.1198 70.0861 93.6436 69.5567C92.8332 68.639 91.9162 67.8202 90.9068 67.1284C90.7078 66.9872 90.4803 66.8531 90.2386 66.8672C90.0396 66.8814 89.8619 66.9943 89.6913 67.1072C87.7293 68.4414 85.8385 69.8814 84.04 71.4274C84.3386 71.6532 84.758 71.6885 85.0921 71.5191L84.5163 71.5685L84.5092 71.5615Z"
            fill="#FACD8A"
        />
        <Path
            d="M63.4824 71.0184C62.281 70.7925 61.0939 70.4749 59.9068 70.1572C59.3808 70.016 58.8477 69.8749 58.3216 69.7337C56.836 69.3384 55.3503 68.936 53.8433 68.6395C53.7011 68.6113 53.5447 68.5831 53.4097 68.6325C53.0969 68.7454 53.0401 69.1972 53.2178 69.4725C53.4026 69.7478 53.7296 69.8819 54.0352 70.0019C54.7603 70.2702 55.4996 70.5031 56.246 70.7008C55.8337 70.9266 55.4143 71.1666 55.1299 71.5408C54.8456 71.9149 54.7319 72.4584 54.9878 72.8467C55.3005 73.3267 55.9829 73.3691 56.5658 73.362C58.9258 73.3267 61.2787 73.2985 63.6387 73.2632C63.0345 73.0161 62.4303 72.769 61.8261 72.522C61.7977 72.2184 62.146 72.0208 62.4445 71.9714C62.7431 71.922 63.0843 71.9361 63.3189 71.7314C63.5534 71.5267 63.4468 71.0255 63.1483 71.0819L63.4753 71.0184H63.4824Z"
            fill="#FACD8A"
        />
        <Path
            d="M57.1203 75.5078C56.4024 75.9384 56.1607 76.9902 56.6156 77.689C57.0706 78.3879 58.144 78.5926 58.8264 78.1126C59.0609 78.2891 58.5989 78.5079 58.3856 78.7055C58.1724 78.9032 58.2364 79.2491 58.3217 79.5244C58.9756 81.4868 60.4329 83.1809 62.2811 84.1269C62.5867 84.2822 63.0275 84.388 63.2052 84.0916C63.3118 83.9151 63.2478 83.6963 63.2549 83.4915C63.2549 82.8209 63.9302 82.3762 64.5131 82.0303C66.3613 80.9362 68.0531 79.595 69.5459 78.0561C67.7617 78.7691 66.0059 79.5667 64.2999 80.4491C64.1364 79.9691 63.98 79.4891 63.8165 79.0091C64.3994 79.115 65.032 78.8114 65.3022 78.2961C64.6695 78.3526 63.7667 78.1691 63.8307 77.5408C63.8947 76.969 64.7975 76.722 64.7548 76.1502L59.3808 75.6984C58.1795 75.5996 56.9071 75.5078 55.8266 76.0302L57.1061 75.5078H57.1203Z"
            fill="#FACD8A"
        />
        <Path
            d="M71.586 79.3333C71.0884 80.1522 70.3136 80.7522 69.6312 81.4298C68.9488 82.1075 68.3374 82.9475 68.3161 83.9005C68.3019 84.8111 68.9061 85.7076 69.7662 86.0182C70.6263 86.3359 71.6784 86.0394 72.24 85.3194C72.5101 84.9664 72.6665 84.5499 72.8157 84.1334C73.0645 83.4487 73.3062 82.764 73.555 82.0863C73.2494 82.4181 72.9153 82.7711 72.4745 82.8699C72.0338 82.9687 71.4723 82.6793 71.4865 82.2275C71.4865 82.1005 71.5362 81.9805 71.5789 81.8604C72.0054 80.7239 72.439 79.5874 72.8655 78.458C72.2826 78.9027 71.6997 79.3545 71.1168 79.7992L71.586 79.3333Z"
            fill="#FACD8A"
        />
        <Path
            d="M75.0833 84.7404C74.4009 86.6322 73.7114 88.5523 73.6403 90.557C74.0171 90.0911 74.7706 90.5712 75.0407 91.1006C75.3108 91.63 75.5738 92.3288 76.1709 92.3712C76.5548 92.3994 76.9102 92.1241 77.2941 92.1453C77.5713 92.1594 77.813 92.3288 78.0689 92.4206C78.6731 92.6394 79.4124 92.4206 79.7962 91.9053C80.1303 91.2135 79.6896 90.4159 79.3413 89.7311C77.8201 86.6887 78.1115 82.9686 76.4908 79.9756C76.7965 81.8109 76.9386 83.6816 76.9031 85.5451C76.896 85.9828 76.8747 86.4416 76.6401 86.8087C76.4055 87.1758 75.8937 87.4158 75.5169 87.1969C75.1402 86.3428 74.9341 85.411 74.9127 84.4792L75.0691 84.7334L75.0833 84.7404Z"
            fill="#FACD8A"
        />
        <Path
            d="M79.9313 81.9098C80.5497 83.9993 81.1753 86.0817 81.7937 88.1712C81.9501 88.6935 82.1207 89.2371 82.5259 89.6041C82.9311 89.9641 83.649 90.0418 83.9831 89.6112C84.1324 90.0983 84.303 90.6136 84.694 90.9453C85.0849 91.2701 85.7815 91.2983 86.0446 90.8606C86.3147 90.423 86.0446 89.7736 86.3858 89.3994C86.7554 88.9971 87.4165 89.2935 87.8714 89.6041C88.4899 90.0277 89.1083 90.4583 89.7267 90.8818C90.0395 91.0936 90.4802 91.3054 90.7575 91.0583C90.8783 90.9524 90.9281 90.79 90.9565 90.6418C91.2977 89.0182 90.3167 87.437 89.3855 86.0605C88.4472 84.6769 87.5089 83.2863 86.5706 81.9028C86.1725 81.3169 85.7247 80.6957 85.0423 80.5051C85.3124 80.858 85.5896 81.2392 85.6181 81.6769C85.6465 82.1145 85.2911 82.6087 84.8503 82.5522C84.6726 82.531 84.4878 82.4251 84.3243 82.4957C84.1395 82.5804 84.1111 82.8204 84.1182 83.0181C84.1466 83.7381 84.2959 84.4581 84.5518 85.1358C82.6609 83.9993 82.0567 81.3027 80.0379 80.4062C80.1517 81.1333 80.2654 81.8675 80.3791 82.5945L79.91 81.9239L79.9313 81.9098Z"
            fill="#FACD8A"
        />
        <Path
            d="M91.6603 82.9546C92.9398 85.5453 94.4823 88.0089 96.2523 90.296C96.4727 89.5336 97.3684 89.0324 98.1361 89.2442C98.2711 88.8065 97.5248 88.6583 97.2689 88.2771C96.9134 87.7547 97.6456 87.1265 98.2783 87.0841C98.9109 87.0418 99.6431 87.1971 100.119 86.7806C100.489 86.4629 100.553 85.9194 100.581 85.4323C100.667 84.0982 100.695 82.757 100.681 81.4157C100.681 81.091 100.66 80.7381 100.439 80.4981C100.24 80.2863 99.9345 80.2157 99.6431 80.1734C97.8944 79.891 96.1031 79.8839 94.3544 80.1451C94.7169 80.2581 95.0226 80.5404 95.1576 80.8863C92.3853 80.7451 89.6344 80.1734 87.0398 79.1992C89.0941 80.5334 90.8855 82.2769 92.2716 84.2817L91.6532 82.9475L91.6603 82.9546Z"
            fill="#FACD8A"
        />
        <Path
            d="M85.6465 74.2014C88.7316 74.3779 91.8096 74.5473 94.8946 74.7238C95.4917 74.759 96.1102 74.7943 96.6291 75.0838C97.148 75.3732 97.539 75.9803 97.3684 76.552C95.9325 76.6862 94.5321 77.145 93.3023 77.8932C92.5275 78.105 91.7029 78.1474 90.9068 78.0203C90.2955 77.9215 89.6984 77.7238 89.1155 77.5262C87.708 77.0532 86.3076 76.5873 84.9002 76.1144C85.6536 76.0932 86.4071 75.8179 86.99 75.3449C86.0375 75.0061 85.1063 74.6108 84.2106 74.152C85.1774 74.4343 86.4853 74.5896 86.9616 73.7002L85.6465 74.2014Z"
            fill="#FACD8A"
        />
        <Path
            d="M72.6807 74.1733C73.5977 73.4392 75.1403 73.7145 75.7374 74.7239C75.8298 74.8863 75.9151 75.0698 76.0857 75.1545C76.633 75.4369 77.0738 74.3851 77.6922 74.4415C77.9765 74.4627 78.1756 74.7239 78.4386 74.8439C78.8224 75.0204 79.3342 74.8439 79.5191 74.4627C79.7039 74.0815 79.5333 73.5733 79.1565 73.3827C79.7394 73.178 80.3294 72.9733 80.9123 72.7686C80.8554 74.5545 81.154 76.3545 81.7867 78.0275C81.2677 77.5263 80.4503 77.4487 79.7536 77.6463C79.057 77.844 78.4599 78.2746 77.877 78.6981C77.2088 78.1899 76.5335 77.6816 75.8653 77.1734C75.3962 76.8204 74.9057 76.4534 74.3299 76.3122C73.7541 76.171 73.0646 76.3122 72.7447 76.8134C72.9153 75.9522 72.8656 75.0486 72.6025 74.2157L72.6807 74.1804V74.1733Z"
            fill="#DD9051"
        />
        <Path
            d="M59.2671 83.5829C60.9944 81.8817 62.7218 80.1875 64.4491 78.4863C65.3661 77.5827 66.5035 76.6086 67.7688 76.8486C71.1382 73.8485 75.4672 71.9285 79.974 71.4485C82.1776 71.2155 84.893 71.0249 85.8242 69.0272C86.336 67.926 86.073 66.5989 85.4404 65.5612C84.8077 64.5236 83.8694 63.7189 82.9382 62.9353C81.3317 61.58 79.647 60.1823 77.6069 59.6599C76.7538 59.4411 75.8653 59.3917 74.998 59.2293C73.9815 59.0387 73.0006 58.6999 71.9983 58.4317C68.6147 57.5281 64.9965 57.4857 61.5915 58.3117C59.4448 58.834 57.3478 59.7164 55.7199 61.2058C54.5684 62.2576 53.6443 63.8671 54.1774 65.3283C54.3693 65.8577 54.7461 66.3942 54.5613 66.9236C54.3267 67.6154 53.3244 67.7354 52.9547 68.3637C52.6278 68.9143 52.905 69.6131 53.1893 70.192C53.9286 71.7238 54.675 73.2485 55.4143 74.7803C55.5564 75.0697 55.6986 75.3874 55.6346 75.705C55.5635 76.0509 55.265 76.298 55.1015 76.6086C54.6892 77.378 55.1584 78.3098 55.6275 79.0439C56.7791 80.8722 57.9662 82.6793 59.1889 84.4582L59.2671 83.5899V83.5829Z"
            fill="black"
        />
        <Path
            d="M58.9969 67.8983C59.7504 68.6254 61.1863 69.2254 62.2384 68.8442C62.345 68.8089 62.3663 68.6325 62.2384 68.6042C61.7266 68.5054 61.2077 68.5548 60.6959 68.4207C60.1414 68.2795 59.6083 68.0183 59.1178 67.743C59.0254 67.6936 58.9259 67.8136 59.004 67.8913L58.9969 67.8983Z"
            fill="#FDEACD"
        />
        <Path
            d="M60.6888 61.4465C61.0015 61.7501 61.2361 62.0042 61.6129 62.216C61.9967 62.4348 62.2882 62.7948 62.6862 62.9854C62.7786 63.0277 62.8639 62.9219 62.7929 62.8442C62.5014 62.4913 62.0607 62.2866 61.755 61.9477C61.4849 61.6442 61.1579 61.4959 60.8096 61.2771C60.703 61.2136 60.5892 61.3618 60.6745 61.4465H60.6888Z"
            fill="#FDEACD"
        />
        <Path
            d="M65.4443 65.7798C66.3897 66.2951 67.1432 67.0222 67.9323 67.7422C68.5294 68.2858 69.1833 69.0129 69.9724 69.2317C70.3918 69.3517 70.6548 68.907 70.4913 68.5611C70.1359 67.7987 69.1336 67.2411 68.4014 66.8669C67.4773 66.3869 66.475 66.0622 65.5438 65.5963C65.423 65.5398 65.3163 65.7093 65.4372 65.7728L65.4443 65.7798Z"
            fill="#FDEACD"
        />
        <Path
            d="M67.8612 61.4814C67.9251 61.545 67.9891 61.6014 68.0602 61.6508C68.1384 61.7144 68.2308 61.6226 68.1668 61.545C68.1171 61.4744 68.0602 61.4108 67.9962 61.3473C67.9109 61.2626 67.7759 61.3967 67.8612 61.4814Z"
            fill="#FDEACD"
        />
        <Path
            d="M72.0623 61.8418C72.2542 62.23 72.4106 62.6183 72.503 63.0418C72.5741 63.3736 72.6025 63.7618 72.7874 64.0513C72.8584 64.1642 73.0361 64.1501 73.0717 64.016C73.2565 63.303 72.6452 62.3006 72.2969 61.7006C72.2116 61.5453 71.9841 61.6794 72.0552 61.8418H72.0623Z"
            fill="#FDEACD"
        />
        <Path
            d="M75.4957 64.7563C75.6592 65.6316 75.7018 66.7399 76.1496 67.5163C76.2278 67.6505 76.4127 67.5799 76.4269 67.4457C76.4837 66.5422 76.0217 65.5398 75.7729 64.6857C75.7231 64.5092 75.4672 64.5869 75.4957 64.7633V64.7563Z"
            fill="#FDEACD"
        />
        <Path
            d="M80.8981 67.4884C81.0331 67.2555 81.0687 66.9873 81.09 66.719C81.09 66.6555 80.9905 66.6273 80.9692 66.6837C80.8625 66.9308 80.763 67.1778 80.7701 67.4461C80.7701 67.5167 80.8697 67.5449 80.9052 67.4814L80.8981 67.4884Z"
            fill="#FDEACD"
        />
        <Path
            d="M79.6754 69.3664C80.6635 67.6016 81.68 65.8439 82.6538 64.0721C82.8102 63.7898 82.3908 63.5498 82.2202 63.818C81.1184 65.5122 80.1801 67.3899 79.4124 69.2534C79.3484 69.4017 79.5972 69.5005 79.6754 69.3593V69.3664Z"
            fill="#FDEACD"
        />
        <Path
            d="M93.0393 71.272C90.068 70.8555 86.3716 70.799 83.514 71.8861C83.2865 71.9708 83.3007 72.3379 83.578 72.3308C85.1489 72.3096 86.6986 71.9073 88.2696 71.8084C89.8476 71.7096 91.4257 71.6178 93.0038 71.519C93.1388 71.512 93.1886 71.2931 93.0393 71.272Z"
            fill="#FDEACD"
        />
        <Path
            d="M86.5067 80.8725C87.3597 81.7195 88.2198 82.6019 89.0017 83.5055C89.7197 84.3314 90.3879 85.2843 91.2835 85.9408C91.5252 86.1173 91.7882 85.7855 91.6674 85.5596C91.1414 84.5926 90.2173 83.8302 89.3998 83.1102C88.4828 82.2984 87.5729 81.5219 86.6133 80.7666C86.5351 80.7101 86.4356 80.8019 86.5067 80.8725Z"
            fill="#FDEACD"
        />
        <Path
            d="M73.8749 85.7999C74.0598 85.3693 74.2801 84.974 74.472 84.5504C74.6213 84.2187 74.799 83.901 74.7422 83.5339C74.7208 83.3998 74.5431 83.3857 74.4649 83.4633C74.1877 83.7175 74.1308 84.1057 74.0455 84.4587C73.9318 84.8893 73.8607 85.3199 73.7399 85.7434C73.7185 85.821 73.8465 85.8846 73.882 85.7999H73.8749Z"
            fill="#FDEACD"
        />
        <Path
            d="M77.3154 87.974C77.4291 87.4799 77.5215 87.014 77.5926 86.5128C77.6495 86.0752 77.7845 85.6022 77.6353 85.1787C77.5997 85.0657 77.4291 85.0234 77.3652 85.1434C77.1661 85.5246 77.2088 85.9552 77.2017 86.3787C77.2017 86.9152 77.2088 87.4234 77.2514 87.9599C77.2514 87.9952 77.3083 88.0093 77.3154 87.967V87.974Z"
            fill="#FDEACD"
        />
        <Path
            d="M77.2657 81.9591C77.3652 81.3661 77.4291 80.8438 77.4363 80.2367C77.4363 79.6932 77.5002 79.1778 77.2585 78.6767C77.1732 78.5002 76.8605 78.5355 76.8249 78.7331C76.7183 79.2484 76.8747 79.7143 76.9102 80.2367C76.9458 80.7944 76.9813 81.3661 76.9884 81.9238C76.9884 82.0791 77.2443 82.1215 77.2657 81.9591Z"
            fill="#FDEACD"
        />
        <Path
            d="M69.4819 81.4586C69.7378 81.2256 70.0932 81.035 70.2639 80.7315C70.3207 80.6256 70.207 80.5197 70.1004 80.5691C69.7947 80.7174 69.5743 81.0703 69.3326 81.3103C69.2402 81.4092 69.3824 81.5433 69.4819 81.4586Z"
            fill="#FDEACD"
        />
        <Path
            d="M71.9556 79.087C72.0836 78.8328 72.24 78.5787 72.3181 78.3034C72.3537 78.1693 72.1689 78.0422 72.0694 78.1622C71.8916 78.3881 71.7779 78.6564 71.65 78.9105C71.5504 79.1081 71.849 79.2846 71.9556 79.087Z"
            fill="#FDEACD"
        />
        <Path
            d="M57.7814 75.225C58.2079 75.2603 58.677 75.345 59.0964 75.2391C59.1889 75.2179 59.1889 75.0838 59.0964 75.0556C58.677 74.9426 58.2079 75.0344 57.7814 75.0697C57.6748 75.0767 57.6748 75.225 57.7814 75.232V75.225Z"
            fill="#FDEACD"
        />
        <Path
            d="M61.4209 74.9569C63.8662 75.4087 66.8447 75.8393 69.2544 75.0628C69.4179 75.0134 69.3824 74.7875 69.2118 74.7734C66.6172 74.6181 64.1577 74.9357 61.5489 74.491C61.2432 74.4416 61.1081 74.9004 61.4209 74.9569Z"
            fill="#FDEACD"
        />
        <Path
            d="M59.8144 72.0906C60.0419 72.0906 60.248 72.0906 60.4471 71.9776C60.5466 71.9212 60.5466 71.7729 60.4471 71.7165C60.248 71.6035 60.049 71.6035 59.8144 71.6035C59.5016 71.6106 59.5016 72.0765 59.8144 72.0835V72.0906Z"
            fill="#FDEACD"
        />
        <Path
            d="M90.9708 78.5361C92.2858 78.5502 93.8355 78.5855 95.1008 78.169C95.3709 78.0772 95.314 77.7102 95.0368 77.689C93.7146 77.569 92.2147 77.9431 90.9281 78.2537C90.7646 78.2961 90.8002 78.5361 90.9708 78.5431V78.5361Z"
            fill="#FDEACD"
        />
        <Path
            d="M56.1749 76.2063C56.1535 76.2698 56.054 76.291 55.9829 76.3757C55.855 76.5098 55.7981 76.7216 55.7768 76.898C55.7199 77.2651 55.7128 77.6604 56.0398 77.8863C56.1322 77.9569 56.2602 77.8934 56.317 77.8157C56.4947 77.5616 56.4379 77.1945 56.445 76.898C56.445 76.7639 56.4734 76.5592 56.4237 76.4321C56.4094 76.3898 56.3952 76.3545 56.3597 76.3263C56.2886 76.291 56.2957 76.2698 56.381 76.2627C56.4237 76.1357 56.2246 76.0863 56.182 76.2063H56.1749Z"
            fill="#FDEACD"
        />
        <Path
            d="M57.1701 79.3686C57.7174 80.8086 58.4212 82.1357 59.4234 83.3216C60.248 84.2957 61.7977 85.7993 63.1767 85.4746C63.5677 85.3828 63.7667 84.8887 63.5535 84.5499C62.9492 83.5898 61.3712 83.1945 60.4186 82.5733C59.1462 81.7474 58.1013 80.6109 57.4046 79.2627C57.3336 79.1286 57.1061 79.2203 57.163 79.3615L57.1701 79.3686Z"
            fill="#FDEACD"
        />
        <Path
            d="M75.0621 92.0396C75.3464 92.2938 75.5028 92.5691 75.8511 92.7597C76.1426 92.922 76.4766 93.0561 76.8036 93.1338C77.3865 93.275 78.1613 93.4232 78.5523 92.8867C78.6376 92.7667 78.6021 92.5691 78.4954 92.4773C78.1045 92.1173 77.5287 92.2655 77.0311 92.2232C76.7326 92.1949 76.434 92.1455 76.1425 92.0749C75.8653 92.0114 75.453 91.9832 75.1971 91.8632C75.0905 91.8138 74.9625 91.9479 75.0621 92.0326V92.0396Z"
            fill="#FDEACD"
        />
        <Path
            d="M85.4475 92.4635C85.7887 92.4424 86.0872 92.4 86.3645 92.1882C86.5066 92.0753 86.4355 91.7929 86.2507 91.7647C85.9024 91.7153 85.6252 91.8282 85.3124 91.9765C85.0778 92.0894 85.1916 92.4847 85.4475 92.4706V92.4635Z"
            fill="#FDEACD"
        />
        <Path
            d="M90.0538 91.3975C90.331 91.4328 90.5869 91.4822 90.7717 91.2422C90.857 91.1363 90.857 91.0233 90.7717 90.9174C90.5869 90.6774 90.331 90.7269 90.0538 90.7622C89.6486 90.8116 89.6415 91.341 90.0538 91.3975Z"
            fill="#FDEACD"
        />
        <Path
            d="M95.7689 91.4259C96.5651 91.3977 97.212 91.3059 97.9583 90.9953C98.5341 90.7553 99.4156 90.4518 99.6004 89.8023C99.643 89.6541 99.5222 89.5059 99.3729 89.5059C98.8043 89.5059 98.3067 90.0282 97.8091 90.2824C97.1693 90.6071 96.4229 90.9106 95.7263 91.1012C95.5486 91.1506 95.577 91.433 95.7689 91.4259Z"
            fill="#FDEACD"
        />
        <Path
            d="M101.562 85.4259C102.195 83.4423 102.031 80.2869 100.517 78.6916C100.233 78.388 99.7639 78.7057 99.8492 79.0798C100.084 80.0822 100.66 80.9716 100.901 81.9881C101.171 83.1035 101.242 84.2611 101.314 85.3976C101.321 85.5318 101.513 85.567 101.555 85.4329L101.562 85.4259Z"
            fill="#FDEACD"
        />
        <Path
            d="M55.4285 73.3268C55.3005 73.1291 55.0802 73.0303 54.9664 72.8256C54.8598 72.635 54.9309 72.4091 54.7887 72.2256C54.6963 72.1056 54.5399 72.0067 54.3836 72.0632C53.3173 72.4161 54.4333 74.4209 55.3858 73.6515C55.4711 73.5809 55.4854 73.4185 55.4285 73.3338V73.3268Z"
            fill="#FDEACD"
        />
        <Path
            d="M53.4736 69.8387C53.4523 69.5634 53.3955 69.3093 53.4594 69.034C53.5234 68.7658 53.694 68.5469 53.8006 68.2928C53.9428 67.954 53.6443 67.601 53.2817 67.7775C52.5353 68.1446 52.3363 69.3658 52.6633 70.0576C52.8481 70.4387 53.5021 70.2552 53.4665 69.8387H53.4736Z"
            fill="#FDEACD"
        />
        <Path
            d="M54.0779 65.4909C54.2983 64.6862 54.1206 64.065 54.6181 63.3097C55.0162 62.7097 55.528 62.265 56.1251 61.8767C56.708 61.4956 57.3051 61.1708 57.9804 60.9944C58.606 60.832 59.1747 60.9026 59.6651 60.4508C59.8784 60.2532 59.8997 59.8367 59.6651 59.6461C58.4141 58.6155 56.1749 60.0555 55.3148 61.0791C54.8456 61.6367 54.4333 62.392 54.2272 63.0838C53.9926 63.8603 53.559 64.6862 53.7865 65.498C53.8291 65.6462 54.0495 65.6462 54.0921 65.498L54.0779 65.4909Z"
            fill="#FDEACD"
        />
        <Path
            d="M63.6459 58.1004C64.2216 58.2487 64.7903 58.3051 65.3874 58.2628C65.885 58.2275 66.475 58.2981 66.8375 57.924C66.9797 57.7757 66.93 57.5851 66.7807 57.4722C66.3471 57.1545 65.6647 57.3381 65.16 57.4016C64.6197 57.4651 64.115 57.6204 63.6103 57.8392C63.4895 57.8887 63.5321 58.0793 63.6459 58.1075V58.1004Z"
            fill="#FDEACD"
        />
        <Path
            d="M59.9424 74.6391C58.8192 74.6038 57.7032 74.512 56.573 74.4838C55.8692 74.4626 54.9665 74.2862 54.2983 74.6109C53.2391 75.1262 53.1894 76.4462 53.5306 77.4133C54.0424 78.8604 55.1868 80.2581 56.118 81.4652C57.2483 82.9264 58.5136 84.4582 60.0277 85.5311C60.177 85.637 60.3262 85.4111 60.2196 85.2911C58.4709 83.2723 56.6014 81.5005 55.1229 79.2416C54.8172 78.7686 54.4902 78.2957 54.2414 77.7874C53.9997 77.2933 53.6088 76.4744 53.8576 75.9309C54.1845 75.2109 54.8812 74.8509 55.6062 74.8015C57.035 74.7026 58.4994 74.6815 59.9353 74.6462L59.9424 74.6391Z"
            fill="#DD9051"
        />
        <Path
            d="M59.9281 85.5663C61.6626 87.324 65.5083 87.1899 66.7878 84.9028C66.9157 84.6769 66.6243 84.4722 66.4395 84.6345C64.4989 86.2863 62.3734 86.7593 60.1129 85.3334C59.9708 85.2416 59.8144 85.4463 59.9281 85.5663Z"
            fill="#DD9051"
        />
        <Path
            d="M68.2166 82.0513C66.7309 82.6725 66.3329 84.5361 66.9726 85.9196C67.7546 87.6138 70.1359 88.4962 71.8846 87.9244C72.1262 87.8468 72.0836 87.4938 71.8277 87.4867C70.2994 87.4514 68.7497 87.4726 67.719 86.1314C66.7878 84.9173 67.065 83.1455 68.3019 82.249C68.3943 82.1854 68.3446 82.0019 68.2166 82.0513Z"
            fill="#DD9051"
        />
        <Path
            d="M73.5621 84.7969C72.6452 86.5193 71.6926 88.9829 72.1191 90.9665C72.5172 92.8301 74.1735 94.023 75.9079 94.5666C77.0026 94.9125 78.1968 95.0748 79.3413 95.1666C80.1517 95.2372 80.9407 95.2442 81.5876 94.6936C82.6538 93.7901 82.8031 91.9547 82.2344 90.7547C82.142 90.5641 81.8435 90.6629 81.8435 90.8606C81.8506 92.0818 82.1491 93.9877 80.6208 94.4819C79.8105 94.743 78.4456 94.4325 77.6282 94.2983C76.7965 94.1642 75.9435 93.9736 75.1829 93.6136C73.619 92.8795 72.567 91.5947 72.5527 89.83C72.5385 88.1076 73.484 86.5617 73.818 84.9099C73.8465 84.7687 73.6474 84.6628 73.5693 84.804L73.5621 84.7969Z"
            fill="#DD9051"
        />
        <Path
            d="M81.4312 89.8798C82.1563 91.8563 84.1111 93.4799 86.2934 93.5858C87.8501 93.6634 89.7836 91.9763 88.1913 90.5998C87.9283 90.3668 87.5374 90.7551 87.8004 90.988C88.3975 91.5104 88.0492 92.4492 87.516 92.8375C87.2317 93.0422 86.6488 93.0704 86.3005 93.0634C85.5897 93.0493 84.8077 92.7457 84.2035 92.414C83.1159 91.8069 82.2984 90.8469 81.6942 89.7809C81.6231 89.6468 81.3886 89.7386 81.4454 89.8868L81.4312 89.8798Z"
            fill="#DD9051"
        />
        <Path
            d="M87.971 91.2065C89.0444 91.8701 90.1888 93.3101 91.5892 93.2042C92.6839 93.1195 93.4445 92.1312 93.6364 91.1218C93.9563 89.4065 92.7265 87.6417 91.8025 86.3217C90.8002 84.8887 89.613 83.3993 88.1487 82.411C87.9355 82.2628 87.7293 82.5733 87.8857 82.7498C89.6273 84.691 91.5252 86.4346 92.6341 88.8559C92.9753 89.6041 93.2597 90.55 92.9682 91.3689C92.5915 92.4277 91.511 92.8795 90.4945 92.3642C89.6415 91.9336 89.0231 91.2065 88.1132 90.8747C87.9212 90.8041 87.8146 91.0936 87.9781 91.1994L87.971 91.2065Z"
            fill="#DD9051"
        />
        <Path
            d="M93.7573 90.3812C95.1008 92.8165 97.4252 93.4871 99.6431 91.6235C100.333 91.0447 101.456 90.0494 101.107 89.0117C100.823 88.1576 99.6573 87.8117 98.854 87.854C98.6408 87.8682 98.5981 88.1505 98.8043 88.2211C99.0531 88.3058 99.3018 88.3482 99.5506 88.4329C99.7781 88.4893 99.9914 88.574 100.198 88.7011C100.176 89.2658 100.155 89.8306 100.134 90.4023C99.8847 90.9671 98.8327 91.6165 98.3138 91.8777C96.3803 92.8589 95.2003 91.4894 94.0487 90.1623C93.9065 90 93.6577 90.2047 93.7573 90.3882V90.3812Z"
            fill="#DD9051"
        />
        <Path
            d="M100.681 89.2938C105.245 88.5385 103.503 81.3312 101.349 79.0441C101.178 78.8676 100.937 79.0935 101.029 79.2912C101.726 80.7877 102.55 82.1077 102.842 83.7807C103.197 85.849 102.856 88.2138 100.603 88.9973C100.411 89.0609 100.489 89.3291 100.688 89.2938H100.681Z"
            fill="#DD9051"
        />
        <Path
            d="M94.333 78.9386C95.4419 78.7056 96.5082 78.4656 97.6455 78.5503C98.8042 78.6421 99.7497 79.1009 100.851 79.3833C101.015 79.4256 101.171 79.1998 101.029 79.0868C99.3089 77.7032 96.1457 77.7527 94.2833 78.8044C94.2122 78.8468 94.2619 78.9527 94.3401 78.9386H94.333Z"
            fill="#DD9051"
        />
        <Path
            d="M93.7359 73.6583C95.4349 73.2066 97.3186 73.7642 98.6123 74.9078C99.7639 75.9243 100.148 77.1525 100.631 78.5432C100.709 78.769 101.058 78.7408 101.058 78.4867C101.072 74.8937 96.9205 72.3595 93.6933 73.503C93.5938 73.5383 93.6364 73.6866 93.7359 73.6583Z"
            fill="#DD9051"
        />
        <Path
            d="M55.3858 74.491C54.0068 74.124 53.232 72.7122 53.9002 71.3992C53.957 71.2933 53.9144 71.1521 53.7864 71.1239C51.5686 70.5521 51.924 68.2438 53.6514 67.4391C53.8646 67.3403 53.7082 67.0438 53.5021 67.0861C52.5709 67.2767 51.8672 68.0673 51.7463 69.0132C51.597 70.2062 52.3576 70.6651 53.1254 71.3286C53.495 71.6463 53.3528 71.4768 53.2746 72.0769C53.2178 72.5004 53.2889 72.9239 53.4381 73.3192C53.7509 74.1522 54.3764 74.7663 55.2579 74.9569C55.5636 75.0205 55.6915 74.5687 55.3858 74.491Z"
            fill="#DD9051"
        />
        <Path
            d="M53.7438 67.1356C52.6775 65.2296 52.3932 63.1896 53.9144 61.3825C55.3574 59.6671 57.5895 59.1871 59.722 59.0601C60.0348 59.0389 60.0348 58.5871 59.722 58.5801C57.2554 58.5306 54.4191 59.4271 53.0614 61.6154C52.0449 63.2461 51.7605 66.105 53.5661 67.3191C53.6798 67.3967 53.8078 67.2485 53.7438 67.1426V67.1356Z"
            fill="#DD9051"
        />
        <Path
            d="M59.1747 58.6511C60.4257 57.8676 61.7124 57.3452 63.2052 57.2182C64.4705 57.1123 65.5936 57.4793 66.8234 57.6205C67.0224 57.6417 67.065 57.3593 66.9158 57.2676C64.634 55.8417 60.9589 56.7946 58.9685 58.3053C58.7695 58.4535 58.9472 58.7923 59.1747 58.6511Z"
            fill="#DD9051"
        />
        <Path
            d="M66.5746 57.6197C66.667 57.7891 66.7452 57.9585 66.8518 58.1138C66.8731 58.1421 66.9229 58.1279 66.9087 58.0856C66.8447 57.902 66.7523 57.7326 66.6741 57.5562C66.6385 57.4856 66.5319 57.5491 66.5675 57.6197H66.5746Z"
            fill="#DD9051"
        />
        <Path
            d="M66.93 58.0292C68.0247 59.4621 68.7426 61.2339 69.5459 62.8363C70.4629 64.6717 71.3017 66.5988 72.3679 68.3494C72.4603 68.5047 72.7589 68.4059 72.6807 68.2153C71.8846 66.3094 70.8183 64.4811 69.8658 62.6457C69.0127 60.9939 68.2024 59.4057 67.0295 57.9444C66.9868 57.888 66.8873 57.9656 66.93 58.0221V58.0292Z"
            fill="#DD9051"
        />
        <Path
            d="M67.1432 57.7264C68.4938 57.3241 69.816 57.1052 71.2164 57.4158C72.5528 57.7123 73.4769 58.4323 74.5644 59.2018C74.7564 59.3359 75.0265 59.0676 74.8914 58.877C73.2423 56.6464 69.3682 56.1099 67.0153 57.4158C66.8447 57.5076 66.9584 57.7829 67.1503 57.7264H67.1432Z"
            fill="#DD9051"
        />
        <Path
            d="M74.3156 59.4979C77.7704 59.4415 82.661 59.2721 83.9547 63.2745C84.04 63.5357 84.4949 63.5075 84.4594 63.2039C84.0258 59.025 77.351 58.425 74.273 59.1591C74.0811 59.2015 74.1237 59.505 74.3228 59.4979H74.3156Z"
            fill="#DD9051"
        />
        <Path
            d="M84.2675 63.3945C85.3266 63.5004 86.2934 63.6133 87.2175 64.1921C87.9994 64.6792 88.3904 65.4698 89.0941 65.9992C89.2007 66.084 89.3855 65.971 89.3713 65.8369C89.2789 64.8698 88.1913 64.051 87.3881 63.6133C86.4426 63.0909 85.2769 62.7803 84.2106 63.0486C84.0044 63.098 84.0684 63.3733 84.2603 63.3945H84.2675Z"
            fill="#DD9051"
        />
        <Path
            d="M88.6036 66.0416C90.8357 66.3733 92.8047 66.8534 94.5108 68.4416C95.2571 69.1405 95.8827 69.9734 96.3305 70.8982C96.7215 71.71 96.7002 72.8747 97.347 73.5029C97.475 73.6229 97.6598 73.6441 97.8091 73.5594C98.0934 73.4041 98.129 73.3124 98.1716 72.9876C98.1929 72.8112 98.0152 72.7053 97.8659 72.6841L97.7593 72.67C97.6029 72.6488 97.539 72.8182 97.5532 72.9382C97.5105 72.6417 97.6456 72.4159 97.5532 72.077C97.4821 71.7947 97.3897 71.5194 97.2831 71.2441C97.1053 70.7782 96.8779 70.3334 96.622 69.9028C96.0462 68.964 95.2927 68.1381 94.4183 67.4675C92.8474 66.2533 90.6224 65.3145 88.6036 65.738C88.4615 65.7663 88.4544 66.0063 88.6036 66.0345V66.0416Z"
            fill="#DD9051"
        />
        <Path
            d="M72.3466 69.6976C73.4128 70.5941 75.2753 70.947 76.4695 70.0576C76.6045 69.9517 76.5335 69.7329 76.3486 69.7752C74.9554 70.0788 73.8465 70.1635 72.5456 69.4364C72.3821 69.3446 72.1902 69.5705 72.3395 69.6976H72.3466Z"
            fill="#DD9051"
        />
        <Path
            d="M76.3558 69.7614C76.043 70.9473 76.8392 72.1756 77.8983 72.698C78.9219 73.2062 80.5995 73.1427 81.2393 72.0768C81.3033 71.9709 81.1967 71.8156 81.0758 71.865C80.0877 72.225 79.2205 72.7121 78.1613 72.1968C77.1662 71.7168 76.825 70.7638 76.5975 69.7544C76.5691 69.6344 76.3842 69.6414 76.3558 69.7544V69.7614Z"
            fill="#DD9051"
        />
        <Path
            d="M81.3814 72.3236C80.7701 74.0107 80.9905 76.446 82.0852 77.9072C82.2344 78.1119 82.6254 78.0061 82.5259 77.7237C82.2487 76.8907 81.8719 76.1284 81.7155 75.2531C81.5449 74.2789 81.6231 73.3471 81.6871 72.3659C81.7013 72.1824 81.4383 72.1683 81.3814 72.3236Z"
            fill="#DD9051"
        />
        <Path
            d="M82.3908 78.3312C81.879 77.343 80.3081 77.1312 79.3342 77.4347C78.2964 77.7594 77.2585 78.8395 77.3154 79.9689C77.3296 80.2513 77.6922 80.2866 77.7917 80.0324C78.1613 79.0795 78.5096 78.2324 79.5972 77.9147C80.6351 77.6112 81.3032 78.1265 82.1776 78.5359C82.3126 78.5995 82.4548 78.4724 82.3837 78.3312H82.3908Z"
            fill="#DD9051"
        />
        <Path
            d="M70.1998 73.8488C70.996 73.75 71.7992 73.9335 72.2329 74.6677C72.6665 75.3947 72.4319 76.1359 71.9983 76.7924C71.8206 77.0607 72.2115 77.343 72.439 77.1312C74.0384 75.5994 72.2471 72.7547 70.1572 73.517C69.9866 73.5806 69.9937 73.8841 70.1998 73.8559V73.8488Z"
            fill="#DD9051"
        />
        <Path
            d="M70.9036 74.0111C70.2354 73.037 70.3989 71.851 71.2519 71.0322C71.9485 70.3686 72.9295 70.1781 73.619 69.5498C73.7256 69.451 73.6474 69.2392 73.491 69.2463C71.5149 69.3663 68.3232 72.5005 70.5624 74.2652C70.733 74.3993 71.0457 74.2229 70.8965 74.0111H70.9036Z"
            fill="#DD9051"
        />
        <Path
            d="M72.894 77.1948C74.273 76.6724 75.9861 76.7924 76.9031 78.0983C77.2657 78.6137 77.6424 79.9054 78.4954 79.3195C78.7229 79.1642 78.6376 78.8325 78.3532 78.8113C77.8983 78.776 77.4505 77.5972 77.1164 77.2654C76.7183 76.8771 76.2136 76.6018 75.6805 76.4324C74.6569 76.1148 73.5622 76.2206 72.7447 76.9407C72.6309 77.0395 72.7447 77.2513 72.894 77.1948Z"
            fill="#DD9051"
        />
        <Path
            d="M56.0612 68.3639C56.3242 68.5192 56.5517 68.7239 56.8147 68.8863C57.0777 69.0486 57.298 69.1122 57.6037 69.1686C57.7032 69.1898 57.8098 69.0557 57.7174 68.978C57.49 68.7875 57.3123 68.6463 57.0279 68.5404C56.7223 68.4274 56.4024 68.371 56.1038 68.258C56.047 68.2368 56.0114 68.3286 56.0612 68.3569V68.3639Z"
            fill="#DD9051"
        />
        <Path
            d="M58.5775 69.1753C59.9992 69.9942 61.5488 69.7753 63.134 69.9659C64.1861 70.093 65.2239 70.2977 66.2333 70.6012C67.2711 70.9118 68.2237 71.4342 69.2544 71.7307C69.5032 71.8013 69.6027 71.4766 69.4179 71.3424C68.046 70.3259 66.0058 69.8812 64.3496 69.613C62.5725 69.3236 60.3049 69.7895 58.6841 68.9918C58.5633 68.9353 58.4567 69.1047 58.5775 69.1753Z"
            fill="#DD9051"
        />
        <Path
            d="M60.1485 59.1941C60.6461 59.5964 61.1152 60.1188 61.5702 60.5706C62.0393 61.0365 62.409 61.5941 62.8852 62.053C63.0132 62.173 63.198 62.0247 63.1198 61.8694C62.8213 61.2835 62.2952 60.7682 61.8261 60.3094C61.5773 60.0694 61.3214 59.8364 61.0441 59.6176C60.774 59.4129 60.4826 59.2999 60.2053 59.1164C60.1627 59.0882 60.1129 59.1517 60.1485 59.187V59.1941Z"
            fill="#DD9051"
        />
        <Path
            d="M81.2606 69.0905C81.7653 68.2717 82.1207 67.354 82.4833 66.4646C82.8955 65.4481 83.4216 64.481 83.8125 63.4645C83.841 63.3868 83.713 63.3374 83.6775 63.408C83.1443 64.368 82.7392 65.3916 82.2131 66.3516C81.7511 67.1916 81.2819 68.0599 80.9692 68.9705C80.9123 69.147 81.1682 69.2388 81.2606 69.0905Z"
            fill="#DD9051"
        />
        <Path
            d="M86.8763 67.8206C87.6156 67.0511 88.4473 66.2252 89.0302 65.3287C89.1012 65.2229 88.952 65.117 88.8595 65.2017C88.0634 65.9146 87.3881 66.8676 86.7483 67.7217C86.6915 67.7994 86.8123 67.8912 86.8834 67.8206H86.8763Z"
            fill="#DD9051"
        />
        <Path
            d="M77.287 79.7286C77.55 80.5545 77.9694 81.3169 78.3532 82.0934C78.73 82.8557 79.0427 83.7028 79.583 84.3734C79.6683 84.4793 79.8247 84.3664 79.7962 84.2534C79.6043 83.4769 79.1423 82.771 78.7797 82.0581C78.3674 81.2463 77.9907 80.4063 77.4576 79.658C77.4007 79.5804 77.2514 79.6227 77.287 79.7286Z"
            fill="#DD9051"
        />
        <Path
            d="M64.044 74.9217C64.2146 74.9217 64.3852 74.9217 64.5558 74.9217C64.5913 74.9217 64.5913 74.8652 64.5558 74.8652C64.3852 74.8652 64.2146 74.8652 64.044 74.8652C64.0013 74.8652 64.0013 74.9288 64.044 74.9288V74.9217Z"
            fill="#DD9051"
        />
        <Path
            d="M64.051 74.9785C66.7665 74.6256 69.5814 74.8515 72.2755 74.4067C72.4461 74.3785 72.3821 74.1456 72.24 74.1314C69.5388 73.835 66.7025 74.5267 64.0439 74.915C64.0084 74.915 64.0155 74.9856 64.051 74.9785Z"
            fill="#DD9051"
        />
        <Path
            d="M88.6179 71.5266C87.3028 71.5901 86.0872 71.8725 84.8006 72.1195C83.4002 72.3948 81.9146 72.4654 80.564 72.9384C80.3294 73.0231 80.3436 73.4325 80.628 73.3972C81.9075 73.2419 83.1586 72.8043 84.4239 72.536C85.8242 72.2325 87.253 72.0772 88.6463 71.7031C88.7458 71.6748 88.7316 71.5195 88.625 71.5195L88.6179 71.5266Z"
            fill="#DD9051"
        />
    </Svg>
);

export default Pomelo;
