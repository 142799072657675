import { Pressable } from "react-native";
import { Box } from "native-base";
import { coordIdFactory } from "./utils";
import type { Coord, GraphData, InsulinGraph, OnTooltipClick } from "./types";

export default function TootlipTouchAreas({
    graphData = {},
    onTooltipClick,
}: {
    graphData?: GraphData;
    onTooltipClick?: (props: OnTooltipClick) => void;
}) {
    const {
        slot,
        type,
        limits,
        coords = [],
        randomIdFragment = "",
        manualMeasures = [],
        dashboardThumbnail: {
            isDashboardThumbnail = false,
            showLineGraphNodes = true,
        } = {},
        insulin,
    } = graphData;

    const yMin = limits?.yMin ?? 0;

    return isDashboardThumbnail ? (
        <></>
    ) : (
        <>
            {manualMeasures.length > 0 &&
                manualMeasures.map(({ x, yHigh, yLow }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: manualMeasures[i],
                    });

                    return (
                        <CoordTouchAreaOptions
                            showLineGraphNodes={showLineGraphNodes}
                            type={type}
                            coords={manualMeasures}
                            i={i}
                            x={x}
                            yHigh={yHigh}
                            yLow={yLow}
                            yMin={yMin}
                            id={id}
                            isManual={true}
                            onTooltipClick={onTooltipClick}
                            key={id}
                            slot={slot}
                        />
                    );
                })}

            {coords.length > 0 &&
                coords.map(({ x, yHigh, yLow }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <CoordTouchAreaOptions
                            showLineGraphNodes={showLineGraphNodes}
                            type={type}
                            coords={coords}
                            i={i}
                            x={x}
                            yHigh={yHigh}
                            yLow={yLow}
                            yMin={yMin}
                            id={id}
                            isManual={false}
                            onTooltipClick={onTooltipClick}
                            key={id}
                            slot={slot}
                        />
                    );
                })}

            {insulin &&
                insulin.length > 0 &&
                insulin.map(({ x }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: insulin[i],
                    });

                    return (
                        <InsulinTooltipTouchArea
                            coords={insulin}
                            i={i}
                            x={x}
                            yHigh={150}
                            yLow={150}
                            yMin={yMin}
                            id={id}
                            isManual={false}
                            onTooltipClick={onTooltipClick}
                            key={`insulin-${id}-${i}`}
                            slot={slot}
                        />
                    );
                })}
        </>
    );
}

const CoordTouchAreaOptions = ({
    showLineGraphNodes,
    type,
    coords,
    i,
    x,
    yHigh,
    yLow,
    yMin,
    id,
    isManual = false,
    onTooltipClick,
    slot,
}: {
    showLineGraphNodes?: boolean;
    type?: string;
    coords: Coord[];
    i: number;
    x: number;
    yHigh?: number;
    yLow?: number;
    yMin: number;
    id: string;
    isManual: boolean;
    onTooltipClick?: (props: OnTooltipClick) => void;
    slot?: { x: number; y: number };
}) =>
    showLineGraphNodes || showLineGraphNodes === undefined ? (
        <>
            {type !== "column" && yHigh && (
                <Box
                    position="absolute"
                    left={`${x - 10}px`}
                    bottom={`${yHigh - 10}px`}
                    zIndex={1}
                >
                    <Pressable
                        onPress={() =>
                            onTooltipClick?.({
                                coord: coords[i],
                                id,
                                isManual,
                                slot,
                            })
                        }
                    >
                        <Box width="20px" height="20px" borderRadius="10px" />
                    </Pressable>
                </Box>
            )}

            {type === "line-paired" && yLow && (
                <Box
                    position="absolute"
                    left={`${x - 10}px`}
                    bottom={`${yLow - 10}px`}
                    zIndex={1}
                >
                    <Pressable
                        onPress={() =>
                            onTooltipClick?.({
                                coord: coords[i],
                                id,
                                isManual,
                                slot,
                            })
                        }
                    >
                        <Box width="20px" height="20px" borderRadius="10px" />
                    </Pressable>
                </Box>
            )}

            {type === "column" && yHigh && (
                <Box
                    position="absolute"
                    left={`${x - 10}px`}
                    bottom={`${yMin}px`}
                    zIndex={1}
                >
                    <Pressable
                        onPress={() =>
                            onTooltipClick?.({
                                coord: coords[i],
                                id,
                                isManual,
                                slot,
                            })
                        }
                    >
                        <Box width="20px" height={`${yHigh - yMin}px`} />
                    </Pressable>
                </Box>
            )}
        </>
    ) : (
        <></>
    );

const InsulinTooltipTouchArea = ({
    coords,
    i,
    x,
    yHigh,
    yMin,
    id,
    isManual = false,
    onTooltipClick,
    slot,
}: {
    coords: InsulinGraph[];
    i: number;
    x: number;
    yHigh?: number;
    yLow?: number;
    yMin: number;
    id: string;
    isManual: boolean;
    onTooltipClick?: (props: OnTooltipClick) => void;
    slot?: { x: number; y: number };
}) =>
    yHigh ? (
        <Box
            position="absolute"
            left={`${x - 10}px`}
            bottom={`${yMin}px`}
            zIndex={1}
        >
            <Pressable
                onPress={() =>
                    onTooltipClick?.({
                        coord: coords[i],
                        id,
                        isManual,
                        slot,
                    })
                }
            >
                <Box width="20px" height={`${yHigh - yMin}px`} />
            </Pressable>
        </Box>
    ) : (
        <></>
    );
