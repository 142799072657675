import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const Week28 = () => {
    const { t } = useTranslation();

    return (
        <VStack space={2}>
            <Text>{t("pregnancy:week28")}</Text>
        </VStack>
    );
};

export default Week28;
