import type { Metric } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import type { ThresholdsProtocol } from "./model";
import { ThresholdType } from "./model";

// Current UI/X only supports editing thresholds when there is a single one for the metric type
// and is of a specific type that we have a supported modal form for
export default ({ metricType, thresholds }: ThresholdsProtocol<Metric>) => {
    if (thresholds.length !== 1) {
        return false;
    }

    const threshold = thresholds[0];

    switch (metricType) {
        case MetricType.BloodOxygen:
        case MetricType.BloodPressure:
        case MetricType.BodyTemperature:
        case MetricType.DailySteps:
        case MetricType.RestingHeartRate:
            return threshold.type === ThresholdType.ValueRange;
        case MetricType.BloodSugar:
            return (
                threshold.type === ThresholdType.BloodSugar &&
                Object.keys(threshold.limitsByMealSitu).length === 2 &&
                "before_meal" in threshold.limitsByMealSitu &&
                "after_meal" in threshold.limitsByMealSitu
            );
        case MetricType.BodyMassIndex:
            return threshold.type === ThresholdType.BodyMassIndexCategory;
        case MetricType.BodyWeight:
            return (
                threshold.type ===
                ThresholdType.DeviationFromAverageRangePercentage
            );
        default:
            return false;
    }
};
