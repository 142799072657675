import { Box } from "native-base";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { formatVerboseMonthDay } from "@madmedical/utils";
import type { MetricType } from "@madmedical/medical";
import { UnderlyingCondition, forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../../providers/currentPatient";
import useResponsive from "../../util/useResponsive";
import Headline from "../../molecules/Headline";
import Breadcrumb from "../../molecules/Breadcrumb";
import MeasurementsTabs from "../../organisms/MeasurementsTabs";
import type MeasurementListItem from "../../molecules/ListItem/MeasurementListItem";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import MeasurementsTable from "../../organisms/MeasurementsTable";
import MeasurementContentMobile from "./MeasurementContentMobile";

export type MeasurementData = Omit<
    ComponentProps<typeof MeasurementListItem>,
    "metricType" | "onDeletePress"
> & { id: Ulid };

type HeaderType = {
    title: string;
    badge: number;
};

export type SectionType = {
    header: HeaderType;
    data: MeasurementData[];
};

type SectionGroupType = Record<string, MeasurementData[]>;

interface Props {
    metricType: MetricType;
    data: MeasurementData[];
    onEndReached: () => void;
    onDeletePress: (id: Ulid) => void;
    isPatient: boolean;
}

type DesktopLayoutProps = {
    sections: SectionType[];
    onEndReached: Props["onEndReached"];
    onDeletePress: Props["onDeletePress"];
    metricType: MetricType;
    isPatient?: boolean;
};

const DesktopLayout = ({
    onEndReached,
    sections,
    metricType,
    onDeletePress,
    isPatient = true,
}: DesktopLayoutProps) => {
    const lastIndex = sections.length - 1;

    return (
        <>
            {sections.map((section, index) => (
                <Box
                    width="full"
                    px={6}
                    py={4}
                    key={`measurement-table-section-${index}`}
                >
                    <ListHeader
                        title={section.header.title}
                        badge={section.header.badge}
                        variant="inverted"
                        size="md"
                        px={0}
                        pt={0}
                        pb={0}
                    />

                    <MeasurementsTable
                        data={section.data}
                        isPatient={isPatient}
                        onEndReached={
                            index === lastIndex ? onEndReached : undefined
                        }
                        metricType={metricType}
                        onDeletePress={onDeletePress}
                    />
                </Box>
            ))}
        </>
    );
};

const MeasurementList = ({
    metricType,
    data,
    onEndReached,
    isPatient,
    onDeletePress,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient();

    const sections = useMemo(() => {
        const groups = data.reduce<SectionGroupType>((prev, measurement) => {
            const date = formatVerboseMonthDay(measurement.measuredAt);
            if (!prev[date]) {
                prev[date] = [];
            }
            prev[date].push(measurement);

            return prev;
        }, {});

        return Object.keys(groups).map<SectionType>((date) => ({
            header: {
                title: date,
                badge: groups[date].length,
            },
            data: groups[date],
        }));
    }, [data]);
    const { t } = useTranslation();

    const insulinUser =
        patient?.underlyingConditions?.includes(UnderlyingCondition.Insulin) &&
        patient?.packages?.some((p) => p.type === "diabetes");

    return (
        <Box>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        {isPatient ? (
                            <>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="dashboard"
                                        params={{}}
                                    >
                                        {t("health")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                            </>
                        ) : (
                            <>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patients"
                                        params={{}}
                                    >
                                        {t("patients")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patient"
                                        params={{ patientId: patient.userId }}
                                    >
                                        {patient.fullName}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                            </>
                        )}
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>
                                {t(`measures:${forHumans(metricType)}`)}
                            </Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <MeasurementsTabs
                metricType={metricType}
                isPatient={isPatient}
                insulinUser={insulinUser}
            />

            <Paper
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
                width="full"
            >
                {isSmallScreen ? (
                    <MeasurementContentMobile
                        sections={sections}
                        onEndReached={onEndReached}
                        metricType={metricType}
                        onDeletePress={onDeletePress}
                    />
                ) : (
                    <DesktopLayout
                        isPatient={isPatient}
                        sections={sections}
                        onEndReached={onEndReached}
                        metricType={metricType}
                        onDeletePress={onDeletePress}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default MeasurementList;
