import {
    Box,
    Divider,
    Heading,
    ScrollView,
    Stack,
    Text,
    VStack,
} from "native-base";
import { isIos, isNative } from "@madmedical/utils";
import type { MetricType } from "@madmedical/medical";
import { ProviderConnectionStatus, forHumans } from "@madmedical/medical";
import { useAppConfig } from "@madmedical/config";
import { useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import type { Device as DeviceModel } from "../providers/device";
import Headline from "../molecules/Headline";
import ListHeader from "../molecules/ListHeader";
import Breadcrumb from "../molecules/Breadcrumb";
import AvatarTexts from "../molecules/AvatarTexts";
import Button from "../atoms/Button";
import Paper from "../atoms/Paper";
import getImage from "../organisms/DeviceItem/getImage";
import ProtocolCard from "../molecules/ProtocolCard";
import AppDownloadAlert from "../organisms/AppDownloadAlert";
import DeviceDetails from "../organisms/DeviceDetails";
import Alert from "../molecules/Alert";
import useResponsive from "../util/useResponsive";
import DeviceConnectActionsheet from "../organisms/DeviceConnectActionsheet";

interface Props {
    device: DeviceModel;
    protocols: {
        metricType: MetricType;
        instructions: string;
    }[];
    showAppDownload: boolean;
    canAddDevice: boolean;
    isConnectLoading: boolean;
    onConnectPress: () => unknown;
    onDisconnectPress: () => unknown;
    onDismissAppDownload: () => void;
}

const Device = ({
    device,
    onConnectPress,
    onDisconnectPress,
    protocols,
    showAppDownload,
    canAddDevice,
    isConnectLoading,
    onDismissAppDownload,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { isProduction, integrator, onManageSubscriptionPress } =
        useAppConfig();
    const [shoeACIModal, setACIModal] = useState(false);
    const { t } = useTranslation();
    const { provider, manufacturer, deviceName, status, isConnected, type } =
        device;
    const isAccuChek = deviceName.toLowerCase() === "accu-chek instant";
    const isConnectAvailable =
        (type === "cloud" && (isNative || !isProduction || !integrator)) ||
        (type === "ble" && isNative) ||
        (type === "apple" && isIos);

    const handleConnectPress = () => {
        if (isAccuChek) {
            setACIModal(true);
        } else {
            onConnectPress();
        }
    };

    const handleACIConnect = () => {
        onConnectPress();
        setACIModal(false);
    };

    return (
        <ScrollView>
            <Box
                borderRadius={!isSmallScreen ? "lg" : undefined}
                borderColor="gray.100"
                borderWidth={1}
                borderStyle="solid"
                bgColor="white"
                mb={{ base: 0, md: 3 }}
            >
                <Box>
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        leftContent={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="devices_connected"
                                        params={{}}
                                    >
                                        {t("devices")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {deviceName}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    />

                    <Box
                        px={{
                            base: 4,
                            md: 6,
                        }}
                        py={4}
                    >
                        <AvatarTexts
                            source={getImage(provider)}
                            hasStatusBadge
                            isCircle={false}
                            label={t(`backend:${manufacturer}`)}
                            subText={deviceName}
                            status={
                                status === ProviderConnectionStatus.Off
                                    ? undefined
                                    : status
                            }
                            size="3xl"
                        />
                    </Box>
                    {showAppDownload && (
                        <AppDownloadAlert
                            onDismiss={onDismissAppDownload}
                            width="full"
                            px={{ base: 4, md: 6 }}
                            mt={{ base: 4, md: 4 }}
                            mb={canAddDevice ? 4 : 0}
                        />
                    )}
                    {!status && !canAddDevice && (
                        <Box px={{ base: 4, md: 6 }} mt={4} mb={4}>
                            <Alert
                                title={"connectNewDevice"}
                                description={t(
                                    "modifySubscriptionForNewDevice"
                                )}
                                links={
                                    onManageSubscriptionPress
                                        ? [
                                              {
                                                  text: t(
                                                      "subscriptionSettings"
                                                  ),
                                                  onPress:
                                                      onManageSubscriptionPress,
                                              },
                                          ]
                                        : []
                                }
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Stack
                direction={{
                    base: "column",
                    md: "row",
                }}
                space={{
                    base: 0,
                    md: 2,
                }}
                mb={{
                    base: 0,
                    md: 3,
                }}
                bgColor="gray.50"
            >
                {!!protocols.length &&
                    (isSmallScreen ? (
                        <>
                            <ListHeader
                                title={t("measures:measureProtocol")}
                                bgColor="none"
                                borderBottomWidth={0}
                                pb={0}
                                minHeight={0}
                                size="xs"
                            />
                            <Box p={4}>
                                <ProtocolCard protocols={protocols} />
                            </Box>
                        </>
                    ) : (
                        <Paper
                            minWidth="auto"
                            flex={1}
                            justifyContent="flex-start"
                        >
                            <Headline
                                size="xs"
                                title={t("measures:measureProtocol")}
                            />
                            <VStack
                                px={6}
                                py={4}
                                divider={<Divider marginY={4} />}
                            >
                                {protocols.map(
                                    ({ metricType, instructions }) => (
                                        <Box key={metricType}>
                                            <Heading
                                                size="sm"
                                                fontWeight="medium"
                                                mb={1}
                                            >
                                                {t(
                                                    `measures:${forHumans(
                                                        metricType
                                                    )}`
                                                )}
                                            </Heading>
                                            <Text
                                                fontSize="xs"
                                                color="gray.600"
                                            >
                                                {t(`backend:${instructions}`)}
                                            </Text>
                                        </Box>
                                    )
                                )}
                            </VStack>
                        </Paper>
                    ))}

                {isSmallScreen ? (
                    <>
                        <ListHeader
                            title={t("details")}
                            bgColor="none"
                            borderBottomWidth={0}
                            pb={0}
                            minHeight={0}
                        />
                        <Box p={4}>
                            <Box
                                borderRadius="2xl"
                                bgColor="white"
                                borderColor="gray.100"
                                borderWidth="1"
                                borderStyle="solid"
                                width="full"
                                overflow="hidden"
                            >
                                <DeviceDetails device={device} />
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Paper minWidth="auto" flex={1}>
                        <Headline size="xs" title={t("details")} />
                        <Box px={2} pb={2} width="full">
                            <DeviceDetails device={device} />
                        </Box>
                    </Paper>
                )}
            </Stack>
            <Box
                width="full"
                px={{
                    base: 4,
                    md: 0,
                }}
                mb={{
                    base: 24,
                    md: 4,
                }}
            >
                {isConnectAvailable && isConnected && (
                    <Button
                        variant="destructive"
                        onPress={onDisconnectPress}
                        isLoading={isConnectLoading}
                        isLoadingText={t("removeDevice") + "..."}
                    >
                        {t("removeDevice")}
                    </Button>
                )}
                {isConnectAvailable && !isConnected && canAddDevice && (
                    <Button
                        variant="primary"
                        onPress={handleConnectPress}
                        isLoading={isConnectLoading}
                        isLoadingText={t("connectDevice") + "..."}
                    >
                        {t("connectDevice")}
                    </Button>
                )}
            </Box>

            {isAccuChek && (
                <DeviceConnectActionsheet
                    isOpen={shoeACIModal}
                    isConnectLoading={isConnectLoading}
                    onClose={() => setACIModal(false)}
                    onConnectPress={handleACIConnect}
                />
            )}
        </ScrollView>
    );
};

export default Device;
