import Svg, { Circle, Path } from "react-native-svg";

const BabyWeight = () => (
    <Svg width="146" height="130" viewBox="0 0 146 130" fill="none">
        <Circle cx="71.9991" cy="65" r="65" fill="white" fillOpacity="0.83" />
        <Path
            d="M38.0964 95.614L43.479 63.1928C43.7623 61.4867 44.6419 59.9366 45.9612 58.8182C47.2804 57.6999 48.9537 57.0861 50.6832 57.0859H91.3168C93.0463 57.0861 94.7196 57.6999 96.0388 58.8182C97.3581 59.9366 98.2376 61.4867 98.521 63.1928L103.904 95.614H38.0964Z"
            fill="#A3CDBB"
        />
        <Path
            d="M42.4855 95.6143H99.5144V101.404H42.4855V95.6143Z"
            fill="#7B9791"
        />
        <Path
            d="M38.0964 35.5967V38.2914C38.0964 41.0344 39.186 43.6651 41.1257 45.6047C43.0653 47.5444 45.696 48.634 48.439 48.634H93.561C96.304 48.634 98.9347 47.5444 100.874 45.6047C102.814 43.6651 103.904 41.0344 103.904 38.2914V35.5967H38.0964Z"
            fill="#A3CDBB"
        />
        <Path
            d="M66.2921 48.6338H75.7073V57.0858H66.2921V48.6338Z"
            fill="#7B9791"
        />
        <Path
            d="M71 90.0001C78.5389 90.0001 84.6504 83.8886 84.6504 76.3496C84.6504 68.8107 78.5389 62.6992 71 62.6992C63.4611 62.6992 57.3495 68.8107 57.3495 76.3496C57.3495 83.8886 63.4611 90.0001 71 90.0001Z"
            fill="#EDF5F3"
        />
        <Path
            d="M71.998 62.7401V65.4092C71.998 65.6739 71.8928 65.9277 71.7057 66.1149C71.5185 66.3021 71.2646 66.4072 70.9999 66.4072C70.7352 66.4072 70.4814 66.3021 70.2942 66.1149C70.107 65.9277 70.0019 65.6739 70.0019 65.4092V62.7401C70.3327 62.7116 70.6635 62.7002 70.9999 62.7002C71.3364 62.7002 71.6672 62.7116 71.998 62.7401Z"
            fill="#7B9791"
        />
        <Path
            d="M71.998 87.292V89.9611C71.6672 89.9896 71.3364 90.001 70.9999 90.001C70.6635 90.001 70.3327 89.9896 70.0019 89.9611V87.292C70.0019 87.0273 70.107 86.7734 70.2942 86.5863C70.4814 86.3991 70.7352 86.2939 70.9999 86.2939C71.2646 86.2939 71.5185 86.3991 71.7057 86.5863C71.8928 86.7734 71.998 87.0273 71.998 87.292Z"
            fill="#7B9791"
        />
        <Path
            d="M84.6532 76.3477C84.6532 76.6841 84.6418 77.0149 84.6133 77.3457H81.9385C81.6738 77.3457 81.42 77.2406 81.2328 77.0534C81.0456 76.8662 80.9405 76.6124 80.9405 76.3477C80.9405 76.083 81.0456 75.8291 81.2328 75.6419C81.42 75.4548 81.6738 75.3496 81.9385 75.3496H84.6133C84.6418 75.6804 84.6532 76.0112 84.6532 76.3477Z"
            fill="#7B9791"
        />
        <Path
            d="M61.0594 76.3477C61.0597 76.4788 61.0341 76.6087 60.9841 76.7299C60.934 76.8512 60.8605 76.9613 60.7678 77.0541C60.675 77.1468 60.5649 77.2203 60.4437 77.2703C60.3224 77.3204 60.1925 77.346 60.0614 77.3457H57.3866C57.3581 77.0149 57.3467 76.6841 57.3467 76.3477C57.3467 76.0112 57.3581 75.6804 57.3866 75.3496H60.0614C60.326 75.3499 60.5797 75.4552 60.7668 75.6423C60.9539 75.8294 61.0591 76.0831 61.0594 76.3477Z"
            fill="#7B9791"
        />
        <Path
            d="M77.6469 69.7027C77.5544 69.61 77.4444 69.5364 77.3233 69.4862C77.2023 69.436 77.0725 69.4102 76.9415 69.4102C76.8104 69.4102 76.6807 69.436 76.5596 69.4862C76.4385 69.5364 76.3286 69.61 76.236 69.7027L72.3048 73.6327C71.8986 73.4332 71.4525 73.3283 71 73.3259C70.4021 73.3259 69.8177 73.5032 69.3207 73.8353C68.8236 74.1674 68.4362 74.6395 68.2074 75.1918C67.9786 75.7442 67.9187 76.3519 68.0354 76.9382C68.152 77.5246 68.4399 78.0632 68.8626 78.4859C69.2853 78.9086 69.8239 79.1965 70.4103 79.3131C70.9966 79.4298 71.6044 79.3699 72.1567 79.1411C72.709 78.9123 73.1811 78.5249 73.5132 78.0278C73.8453 77.5308 74.0226 76.9464 74.0226 76.3486C74.0202 75.8961 73.9148 75.45 73.7146 75.0442L77.6458 71.1125C77.8329 70.9257 77.9381 70.6722 77.9383 70.4078C77.9386 70.1435 77.8337 69.8898 77.6469 69.7027ZM71 77.3774C70.7969 77.3774 70.5984 77.3172 70.4296 77.2044C70.2608 77.0916 70.1292 76.9313 70.0515 76.7437C69.9738 76.5561 69.9535 76.3497 69.9931 76.1506C70.0327 75.9514 70.1305 75.7685 70.2741 75.6249C70.4176 75.4814 70.6005 75.3836 70.7997 75.344C70.9988 75.3044 71.2052 75.3247 71.3928 75.4024C71.5804 75.4801 71.7407 75.6117 71.8535 75.7805C71.9663 75.9493 72.0265 76.1478 72.0265 76.3508C72.0265 76.6231 71.9184 76.8842 71.7258 77.0767C71.5333 77.2692 71.2722 77.3774 71 77.3774Z"
            fill="#7B9791"
        />
        <Path
            d="M57.349 40.8467H46.9693C46.8382 40.8467 46.7085 40.8209 46.5874 40.7707C46.4663 40.7206 46.3563 40.647 46.2636 40.5544C46.1709 40.4617 46.0974 40.3517 46.0472 40.2306C45.9971 40.1095 45.9713 39.9797 45.9713 39.8486C45.9713 39.7176 45.9971 39.5878 46.0472 39.4667C46.0974 39.3456 46.1709 39.2356 46.2636 39.1429C46.3563 39.0502 46.4663 38.9767 46.5874 38.9266C46.7085 38.8764 46.8382 38.8506 46.9693 38.8506H57.349C57.6137 38.8506 57.8675 38.9557 58.0547 39.1429C58.2419 39.3301 58.347 39.5839 58.347 39.8486C58.347 40.1133 58.2419 40.3672 58.0547 40.5544C57.8675 40.7415 57.6137 40.8467 57.349 40.8467Z"
            fill="#FCFDFD"
        />
        <Path
            d="M64.6661 40.8467H61.6127C61.348 40.8467 61.0941 40.7415 60.9069 40.5544C60.7198 40.3672 60.6146 40.1133 60.6146 39.8486C60.6146 39.5839 60.7198 39.3301 60.9069 39.1429C61.0941 38.9557 61.348 38.8506 61.6127 38.8506H64.6661C64.7972 38.8506 64.927 38.8764 65.0481 38.9266C65.1691 38.9767 65.2792 39.0502 65.3718 39.1429C65.4645 39.2356 65.538 39.3456 65.5882 39.4667C65.6384 39.5878 65.6642 39.7176 65.6642 39.8486C65.6642 39.9797 65.6384 40.1095 65.5882 40.2306C65.538 40.3517 65.4645 40.4617 65.3718 40.5544C65.2792 40.647 65.1691 40.7206 65.0481 40.7707C64.927 40.8209 64.7972 40.8467 64.6661 40.8467Z"
            fill="#FCFDFD"
        />
    </Svg>
);

export default BabyWeight;
