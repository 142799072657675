import { useEffect, useState } from "react";
import { getStorageValue, setStorageValue } from "./storage";

export default (key: string) => {
    const [visible, setVisible] = useState(false);

    const storageKey = `${key}_dismissed`;

    useEffect(() => {
        void (async () => {
            const dismissed = await getStorageValue<boolean>(storageKey);

            setVisible(!dismissed);
        })();
    }, [storageKey]);

    const dismiss = () => {
        void setStorageValue(storageKey, true);
        setVisible(false);
    };

    return { visible, dismiss };
};
