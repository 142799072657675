const baseStyle = {
    width: "auto",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    spacing: "2",
    color: "gray.500",
    fontSize: "sm",
    style: {
        fontWeight: "500",
    },
};
const defaultProps = {
    direction: "row",
    wrap: "wrap",
};

export const Breadcrumb = {
    baseStyle,
    defaultProps,
};

export const BreadcrumbText = {
    baseStyle: {
        ...baseStyle,
        _current: {
            _text: {
                fontWeight: "bold",
                color: "gray.700",
            },
        },
    },
    defaultProps,
};

export const BreadcrumbIcon = {
    baseStyle: { ...baseStyle },
    defaultProps,
};
