import Svg, { Path, Rect } from "react-native-svg";

const CalifornianPaprika = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M67.1136 48.2403C67.1136 45.4164 67.8412 38.0789 60.8221 32.9063C53.677 27.6409 44.7221 33.6301 44.7221 33.6301C44.7221 33.6301 36.2257 25.0914 29.1428 31.8924C22.808 37.9744 22.3318 45.4164 22.3318 48.2403C22.3318 50.8406 21.3912 69.118 29.636 75.3407C30.8051 76.2534 32.2861 76.6699 33.7595 76.5003C35.2329 76.3307 36.5806 75.5887 37.5118 74.4343C37.5729 74.3592 37.6334 74.2818 37.6933 74.2021H51.752C51.8121 74.2818 51.8726 74.3592 51.9336 74.4343C52.8648 75.5887 54.2124 76.3307 55.6858 76.5003C57.1592 76.6699 58.6403 76.2534 59.8093 75.3407C68.0544 69.118 67.1136 50.8406 67.1136 48.2403Z"
            fill="#7B9791"
        />
        <Path
            d="M46.1381 23.0146L47.822 24.5243C48.2045 24.8653 48.4873 25.3036 48.6405 25.7926C48.7937 26.2816 48.8115 26.803 48.6919 27.3013C47.9133 30.6535 47.9332 34.1418 48.7501 37.4849H42.5336C42.5336 37.4849 41.3322 26.5147 46.1381 23.0146Z"
            fill="#7B9791"
        />
        <Path
            d="M56.4571 38.7619C54.274 35.5657 50.2526 34.4055 46.9073 36.1437C46.878 36.1589 46.8494 36.1743 46.8207 36.1894C46.1763 36.5367 45.4557 36.7185 44.7236 36.7185C43.9916 36.7185 43.271 36.5367 42.6266 36.1894C42.5979 36.174 42.5687 36.1586 42.5394 36.1434C39.192 34.4054 35.1689 35.5679 32.9877 38.7681C31.5536 40.8682 30.179 44.194 30.2731 49.1728V60.3941C30.256 64.197 30.9565 67.969 32.3377 71.5123C33.5424 74.5567 35.3889 77.8311 38.1586 79.9454C39.181 80.7256 40.4317 81.1475 41.7177 81.146H47.7328C49.0188 81.1475 50.2696 80.7256 51.2919 79.9454C54.0616 77.8311 55.9081 74.5567 57.1128 71.5123C58.494 67.969 59.1945 64.197 59.1774 60.3941V49.1728C59.2694 44.1891 57.8913 40.8633 56.4571 38.7619Z"
            fill="#B6D2C6"
        />
        <Path
            d="M80.3272 58.8578C80.3272 58.8578 77.7991 47.7777 86.1476 44.933C94.7923 41.9872 97.4793 52.4264 97.4793 52.4264L80.3272 58.8578Z"
            fill="#B24747"
        />
        <Path
            d="M107.66 69.0318C107.66 65.7747 108.548 57.3115 99.9794 51.3458C91.2573 45.2728 80.3272 52.1803 80.3272 52.1803C80.3272 52.1803 69.9555 42.3309 61.3086 50.1761C58.671 52.5546 56.5666 55.4644 55.1336 58.7142C53.7006 61.9639 52.9715 65.4802 52.9942 69.0318C52.9942 72.0307 51.8459 93.1117 61.9109 100.289C64.9253 102.438 69.1053 102.055 71.5252 99.2435C71.5995 99.1569 71.6733 99.0675 71.7465 98.9753H88.9075C88.9806 99.0677 89.0544 99.1571 89.1289 99.2435C91.549 102.055 95.7288 102.438 98.7434 100.289C108.808 93.1141 107.66 72.0318 107.66 69.0318Z"
            fill="#B24747"
        />
        <Path
            d="M85.2174 33.0596L86.2488 34.8233C86.4832 35.223 86.5982 35.6815 86.5803 36.1446C86.5624 36.6077 86.4122 37.0559 86.1476 37.4363C84.5782 39.692 81.8361 45.4628 83.9997 56.4511L76.9982 56.8029C76.9982 56.8029 74.6847 38.6607 85.2174 33.0596Z"
            fill="#7B9791"
        />
        <Path
            d="M94.6513 58.1001C91.9863 54.4139 87.0773 53.0769 82.9936 55.0804L82.8875 55.1331C82.0937 55.5345 81.2167 55.7436 80.3272 55.7436C79.4377 55.7436 78.5607 55.5345 77.7669 55.1331L77.6603 55.079C73.5744 53.0745 68.6629 54.4153 65.9979 58.1053C64.2488 60.5276 62.5693 64.3637 62.684 70.1061V83.0484C62.6658 87.4478 63.5226 91.8069 65.2046 95.8721C66.6752 99.3835 68.9292 103.16 72.3105 105.599C73.5775 106.505 75.0974 106.989 76.655 106.984H83.998C85.5557 106.989 87.0757 106.505 88.3428 105.599C91.7252 103.16 93.9778 99.3835 95.4485 95.8721C97.1304 91.8069 97.9872 87.4478 97.969 83.0484V70.1061C98.0845 64.3607 96.402 60.5232 94.6513 58.1001Z"
            fill="#C65151"
        />
        <Path
            d="M77.6134 67.6922C78.5557 64.6959 77.3984 61.6628 75.0287 60.9176C72.6589 60.1723 69.974 61.9973 69.0318 64.9936C68.0895 67.9899 69.2468 71.0231 71.6165 71.7683C73.9863 72.5135 76.6712 70.6886 77.6134 67.6922Z"
            fill="#FFF3F0"
        />
        <Path
            d="M43.3629 47.5751C44.1688 45.0123 43.1791 42.418 41.1522 41.7806C39.1253 41.1432 36.8289 42.7041 36.0229 45.2669C35.217 47.8297 36.2068 50.4239 38.2337 51.0613C40.2606 51.6987 42.557 50.1378 43.3629 47.5751Z"
            fill="#DCEAE4"
        />
    </Svg>
);

export default CalifornianPaprika;
