import { useEffect } from "react";
import { useSearchParams } from "@madmedical/routing";
import { invariant } from "@madmedical/utils";
import { useGenerateDeepLinkUrl } from "@madmedical/config";
import { Box, Text } from "@madmedical/ui";

const OAuthResult = () => {
    const [searchParams] = useSearchParams();
    const generateDeepLinkUrl = useGenerateDeepLinkUrl();

    useEffect(() => {
        const opener = window.opener as WindowProxy | undefined;
        const result = searchParams.get("result");
        invariant(result);

        if (opener) {
            // For web - handled in openAuth.ts
            opener.postMessage({ type: "oauthResult", result });

            window.close();
        } else {
            // For mobile - This will be parsed manually in openAuth.native.ts
            window.location.href = generateDeepLinkUrl(`/?result=${result}`);
        }
    }, [searchParams, generateDeepLinkUrl]);

    return (
        <Box>
            <Text>Please wait...</Text>
        </Box>
    );
};

export default OAuthResult;
