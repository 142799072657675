import { createContext } from "react";
import type { useAppleHealth } from "@madmedical/applehealth";

export interface ContextValue {
    connect: ReturnType<typeof useAppleHealth>["connect"] | undefined;
    disconnect: ReturnType<typeof useAppleHealth>["disconnect"] | undefined;
}

const emptyValue = {
    connect: undefined,
    disconnect: undefined,
};

const AppleContext = createContext<ContextValue>(emptyValue);

export default AppleContext;
