import { InsulinAdministrationModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { usePatientId } from "@madmedical/user";
import { useCreateInsulinAdministrationMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}

const CreateInsulinAdministrationModalForm = ({ open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const [createAdministration] = useCreateInsulinAdministrationMutation();

    return (
        <Modal open={open} onClose={onClose} size="xs">
            <ApiFormWrapper
                defaultValues={{
                    administeredAt: new Date().toISOString(),
                    patientId,
                    comment: null,
                }}
                mutation={createAdministration}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <InsulinAdministrationModalForm
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default CreateInsulinAdministrationModalForm;
