import type { ReactNode } from "react";
import AppConfigContext from "./AppConfigContext";
import type { AppConfig } from "./model";

interface Props {
    appConfig: AppConfig;
    children: ReactNode;
}

const AppConfigProvider = ({ appConfig, children }: Props) => (
    <AppConfigContext.Provider value={appConfig}>
        {children}
    </AppConfigContext.Provider>
);

export default AppConfigProvider;
