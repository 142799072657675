import { useMemo, useState } from "react";
import { Box, SectionList } from "native-base";
import {
    firstToUpper,
    formatMonth,
    formatVerboseMonthDay,
    formatWeekOfYear,
} from "@madmedical/utils";
import { useWindowDimensions } from "react-native";
import { useTranslation } from "@madmedical/i18n";
import type { Period } from "../molecules/DatePeriodStepper";
import DatePeriodStepper from "../molecules/DatePeriodStepper";
import type { PatientMeasurements as PatientMeasurementsType } from "../providers/patientMeasurements";
import { usePatientMeasurements } from "../providers/patientMeasurements";
import Headline from "../molecules/Headline";
import Paper from "../atoms/Paper";
import PatientMeasurementsTable from "../organisms/PatientMeasurementsTable";
import PatientMeasurementListItem from "../molecules/ListItem/PatientMeasurementListItem";
import PatientMeasurementsMobileFilter from "../organisms/filters/mobile/PatientMeasurementsMobileFilter";
import PatientMeasurementsDesktopFilter from "../organisms/filters/desktop/PatientMeasurementsDesktopFilter";
import SearchInput from "../molecules/Form/SearchInput";
import useResponsive from "../util/useResponsive";
import ListHeader from "../molecules/ListHeader";

interface Props {
    period: Period;
    onEndReached: () => unknown;
    onPeriodChange: (v: Period) => unknown;
}

type SectionGroupType = Record<string, PatientMeasurementsType[]>;

type HeaderType = {
    title: string;
    badge: number;
};

export type SectionType = {
    header: HeaderType;
    data: PatientMeasurementsType[];
};

const PatientMeasurements = ({
    period,
    onPeriodChange,
    onEndReached,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;
    const [search, setSearch] = useState("");
    const { patientMeasurements, onSearchChange } = usePatientMeasurements();
    const { t } = useTranslation();
    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange?.(search);
    };

    const sections = useMemo(() => {
        const groups = patientMeasurements.reduce<SectionGroupType>(
            (prev, measurement) => {
                const { dateTrunc } = measurement;

                const date =
                    period === "day"
                        ? firstToUpper(formatVerboseMonthDay(dateTrunc))
                        : period === "week"
                        ? formatWeekOfYear(dateTrunc)
                        : firstToUpper(formatMonth(dateTrunc, "long"));

                prev[date] ??= [];
                prev[date].push(measurement);

                return prev;
            },
            {}
        );

        return Object.keys(groups).map<SectionType>((date) => ({
            header: {
                title: date,
                badge: groups[date].length,
            },
            data: groups[date],
        }));
    }, [patientMeasurements, period]);

    return (
        <Paper>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                title={t("measures:title")}
                rightContent={
                    !isSmallScreen && (
                        <DatePeriodStepper
                            period={period}
                            size="xs"
                            onPeriodChange={onPeriodChange}
                        />
                    )
                }
            />

            {isSmallScreen && (
                <Headline
                    leftContent={
                        <DatePeriodStepper
                            period={period}
                            size="xs"
                            onPeriodChange={onPeriodChange}
                        />
                    }
                />
            )}

            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    isSmallScreen ? (
                        <SearchInput
                            ml="auto"
                            size="sm"
                            value={search}
                            onChangeText={handleSearchChange}
                        />
                    ) : (
                        <PatientMeasurementsDesktopFilter />
                    )
                }
                rightContent={
                    isSmallScreen ? (
                        <PatientMeasurementsMobileFilter />
                    ) : (
                        <SearchInput
                            ml="auto"
                            size="sm"
                            value={search}
                            onChangeText={handleSearchChange}
                        />
                    )
                }
            />

            {isSmallScreen ? (
                <>
                    <SectionList
                        onLayout={(event) => {
                            setFlatListPosition(event.nativeEvent.layout.y);
                        }}
                        maxHeight={screenHeight - flatListPosition - 4}
                        minWidth="full"
                        showsVerticalScrollIndicator={false}
                        sections={sections}
                        renderSectionHeader={({ section: { header } }) => {
                            const { title, badge } = header;

                            return <ListHeader title={title} badge={badge} />;
                        }}
                        renderItem={({ item }) => (
                            <PatientMeasurementListItem
                                data={item}
                                period={period}
                            />
                        )}
                        keyExtractor={(item, index) => index.toString()}
                        overflow="hidden"
                        onEndReached={onEndReached}
                    />
                </>
            ) : (
                <Box px={6} py={4} width="full">
                    {sections.map((section, index) => (
                        <Box
                            key={`patient-measurement-section-${index}`}
                            mb={3}
                            px="1"
                        >
                            <ListHeader
                                size="sm"
                                title={section.header.title}
                                badge={section.header.badge}
                                bgColor="white"
                                borderBottomWidth={0}
                            />
                            <PatientMeasurementsTable
                                period={period}
                                data={section.data}
                                onEndReached={
                                    index === sections.length - 1
                                        ? onEndReached
                                        : undefined
                                }
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Paper>
    );
};

export default PatientMeasurements;
