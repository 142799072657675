import { Box, Heading, ScrollView, Text, VStack, useTheme } from "native-base";
import { forHumans } from "@madmedical/medical";
import { formatDateTime } from "@madmedical/utils";
import { useCallback } from "react";
import { useTranslation } from "@madmedical/i18n";
import Headline from "../molecules/Headline";
import Breadcrumb from "../molecules/Breadcrumb";
import Button from "../atoms/Button";
import SimpleListItem from "../molecules/ListItem/SimpleListItem";
import Icon from "../icons/Icon";
import useResponsive from "../util/useResponsive";
import Paper from "../atoms/Paper";
import type { Document } from "../providers/document";
import useDownloadPreview from "../util/useDownloadPreview";
import { cropFilename } from "../util/strings";
import Spinner from "../atoms/Spinner";
import Tag from "../atoms/Tag";
import { DocumentService } from "../form/upload/model";

interface Props {
    document: Document;
    onDeletePress?: () => void;
}

const DocumentPreview = ({ document, onDeletePress }: Props) => {
    const { colors } = useTheme();
    const { isSmallScreen } = useResponsive();
    const { download, preview, isDownloading } = useDownloadPreview();
    const { t } = useTranslation();
    const hasOpinion = "doctor" in document;

    const handlePreviewPress = useCallback(() => {
        void preview(document);
    }, [preview, document]);

    const handleDownloadPress = useCallback(() => {
        void download(document);
    }, [download, document]);

    const getTagVariant = useCallback((service: DocumentService) => {
        switch (service) {
            case DocumentService.Pregnancy:
                return "violet";
            case DocumentService.PrePostOp:
                return "blue";
            default:
                return "lime";
        }
    }, []);

    if (isDownloading) {
        return <Spinner />;
    }

    return (
        <ScrollView>
            <VStack
                space={3}
                mb={{
                    base: 24,
                    md: 6,
                }}
            >
                <Paper>
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        borderTopRadius="lg"
                        leftContent={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route={
                                            hasOpinion
                                                ? "documents_opinion"
                                                : "documents_general"
                                        }
                                        params={{}}
                                    >
                                        {t("documents")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {cropFilename(
                                            document.filename,
                                            isSmallScreen ? 20 : 40
                                        )}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    />

                    <Headline
                        size="lg"
                        leftContent={
                            <Heading size="lg">
                                {cropFilename(
                                    document.filename,
                                    isSmallScreen ? 20 : 40
                                )}
                            </Heading>
                        }
                        borderBottomRadius="lg"
                        rightContent={
                            !!onDeletePress && (
                                <Button
                                    variant="outlined"
                                    size="xs"
                                    forceIcon={true}
                                    leftIconName="trashCan"
                                    onPress={onDeletePress}
                                >
                                    {!isSmallScreen ? t("delete") : ""}
                                </Button>
                            )
                        }
                    />
                    <Box
                        width="full"
                        flexDirection={isSmallScreen ? "column" : "row"}
                        bgColor={isSmallScreen ? "gray.50" : "gray.50"}
                    >
                        <Box
                            width={isSmallScreen ? "full" : null}
                            borderRadius="lg"
                            flex={1}
                        >
                            {isSmallScreen ? (
                                <Text
                                    fontSize={12}
                                    ml={4}
                                    mt={8}
                                    mb={0}
                                    fontWeight={"500"}
                                >
                                    {t("preview").toUpperCase()}
                                </Text>
                            ) : (
                                <Headline
                                    mt={2}
                                    size="sm"
                                    mr={2}
                                    borderTopRadius="lg"
                                    leftContent={
                                        <Heading size="sm">
                                            <Text>
                                                {t("preview").toUpperCase()}
                                            </Text>
                                        </Heading>
                                    }
                                />
                            )}
                            <Box
                                flex={1}
                                mt={0}
                                borderBottomRadius="lg"
                                justifyContent="center"
                                alignItems="center"
                                bgColor={isSmallScreen ? "gray.50" : "white"}
                                p={4}
                                minHeight={200}
                            >
                                <Box
                                    flex={1}
                                    mt={isSmallScreen ? 0 : 4}
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgColor={
                                        isSmallScreen ? "white" : "gray.50"
                                    }
                                    minHeight={200}
                                >
                                    <Box
                                        borderRadius={12}
                                        w={"56px"}
                                        h={"56px"}
                                        bgColor="green.75"
                                        justifyContent="center"
                                        alignItems="center"
                                        mb={2}
                                    >
                                        <Icon
                                            name="documentAttachment"
                                            size={28}
                                            fill={colors.green[500]}
                                        />
                                    </Box>
                                    <Text
                                        mb={10}
                                        maxW={"90%"}
                                        fontWeight={"500"}
                                    >
                                        {cropFilename(
                                            document.filename,
                                            isSmallScreen ? 20 : 40
                                        )}
                                    </Text>
                                </Box>
                                <Box
                                    flexDirection="row"
                                    alignSelf={"center"}
                                    position={"absolute"}
                                    bottom={8}
                                    zIndex={2}
                                >
                                    <Button
                                        leftIconName="documentView"
                                        variant="outlined"
                                        size="xs"
                                        mr={2}
                                        onPress={handlePreviewPress}
                                    >
                                        {t("view")}
                                    </Button>
                                    <Button
                                        leftIconName="documentDownload"
                                        variant="outlined"
                                        size="xs"
                                        onPress={handleDownloadPress}
                                    >
                                        {t("download")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        {isSmallScreen && document.note && (
                            <Box>
                                <Text
                                    fontSize={12}
                                    ml={4}
                                    mt={6}
                                    mb={0}
                                    fontWeight={"500"}
                                >
                                    {t("comment").toUpperCase()}
                                </Text>

                                <Box mt={0} bgColor={"gray.50"} p={4}>
                                    <Box
                                        borderRadius={"lg"}
                                        p={2}
                                        pt={3}
                                        pb={3}
                                        width="100%"
                                        bgColor={"white"}
                                    >
                                        <Text>{document.note}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        <Box flex={1}>
                            {isSmallScreen ? (
                                <Text
                                    fontSize={12}
                                    ml={4}
                                    mt={6}
                                    mb={0}
                                    fontWeight={"500"}
                                >
                                    {t("details").toUpperCase()}
                                </Text>
                            ) : (
                                <Headline
                                    mt={2}
                                    size="sm"
                                    ml={2}
                                    borderTopRadius="lg"
                                    leftContent={
                                        <Heading size="sm">
                                            <Text>{t("details")}</Text>
                                        </Heading>
                                    }
                                />
                            )}
                            <Box
                                flex={isSmallScreen ? undefined : 1}
                                mt={0}
                                borderBottomRadius="lg"
                                justifyContent="center"
                                alignItems="center"
                                bgColor={isSmallScreen ? "gray.50" : "white"}
                                p={4}
                                ml={isSmallScreen ? 0 : 2}
                                minHeight={200}
                            >
                                <Box
                                    flex={1}
                                    mt={isSmallScreen ? 0 : 4}
                                    width="100%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    borderRadius="lg"
                                    px={isSmallScreen ? 4 : 0}
                                    bg="white"
                                    minHeight={200}
                                >
                                    {!isSmallScreen && document.note && (
                                        <SimpleListItem
                                            borderBottomWidth={0}
                                            px={0}
                                            pt={0}
                                            label={t("comment")}
                                            content={document.note}
                                        />
                                    )}
                                    {hasOpinion && (
                                        <SimpleListItem
                                            borderBottomWidth={
                                                isSmallScreen ? 1 : 0
                                            }
                                            px={0}
                                            label={t("measures:measure")}
                                            withTag
                                            content={forHumans(
                                                document.metricType
                                            )}
                                        />
                                    )}
                                    {document?.service && (
                                        <SimpleListItem
                                            borderBottomWidth={
                                                isSmallScreen ? 1 : 0
                                            }
                                            px={0}
                                            label={t("package")}
                                            content={
                                                <Tag
                                                    variant={getTagVariant(
                                                        document.service.value
                                                    )}
                                                    size="sm"
                                                >
                                                    {
                                                        document.service
                                                            ?.forHumans
                                                    }
                                                </Tag>
                                            }
                                        />
                                    )}

                                    {hasOpinion && (
                                        <SimpleListItem
                                            borderBottomWidth={
                                                isSmallScreen ? 1 : 0
                                            }
                                            px={0}
                                            label={t("patient")}
                                            content={document.patient.fullName}
                                        />
                                    )}
                                    {hasOpinion && (
                                        <SimpleListItem
                                            borderBottomWidth={
                                                isSmallScreen ? 1 : 0
                                            }
                                            px={0}
                                            label={t("evaluatingDoctor")}
                                            content={document.doctor.fullName}
                                        />
                                    )}
                                    <SimpleListItem
                                        borderBottomWidth={0}
                                        px={0}
                                        label={t("uploadDate")}
                                        content={
                                            <>
                                                {formatDateTime(
                                                    document.createdAt
                                                )}
                                            </>
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </VStack>
        </ScrollView>
    );
};

export default DocumentPreview;
