import type { DateTimeString } from "@madmedical/utils";
import type { LogisticsDevice } from "./model";

const createDate = (date: DateTimeString | null) =>
    date ? new Date(date) : null;

export default ({
    deliveredAt,
    returnedAt,
    warrantyExpiresAt,
    ...rest
}: LogisticsDevice) => ({
    deliveredAt: createDate(deliveredAt),
    returnedAt: createDate(returnedAt),
    warrantyExpiresAt: createDate(warrantyExpiresAt),
    ...rest,
});
