import { useParams } from "@madmedical/routing";
import type { MetricType } from "@madmedical/medical";
import { invariant } from "@madmedical/utils";
import { MeasurementType, PatientMeasurementDetails } from "@madmedical/ui";
import { useMe, usePatientId } from "@madmedical/user";
import { useState } from "react";
import { isManualAllowed } from "@madmedical/medical";
import { EditThresholdModal } from "@madmedical/threshold";
import AddCommentButtonForm from "../../comment/components/AddCommentButtonForm";
import MeasurementProviders from "./MeasurementProviders";
import ManualMeasurementModalForm from "./ManualMeasurementModalForm";

enum ModalState {
    Closed,
    Manual,
    Threshold,
}

const Measurements = () => {
    const { isSelfOrRepresented } = usePatientId();
    const {
        roles: { isDoctor },
    } = useMe();
    const { metricType } = useParams<{ metricType: MetricType }>();
    invariant(metricType);
    const [modal, setModal] = useState<ModalState>(ModalState.Closed);

    const handleEditThresholdPress = () => {
        setModal(ModalState.Threshold);
    };

    const handleManualPress = () => {
        setModal(ModalState.Manual);
    };

    const handleModalClose = () => {
        setModal(ModalState.Closed);
    };

    const canAddManual = isManualAllowed(metricType);

    return (
        <MeasurementProviders metricType={metricType}>
            {isSelfOrRepresented ? (
                <>
                    <MeasurementType
                        metricType={metricType}
                        addCommentButton={<AddCommentButtonForm />}
                        onManualPress={canAddManual ? handleManualPress : null}
                    />
                    {canAddManual && (
                        <ManualMeasurementModalForm
                            metricType={metricType}
                            open={modal === ModalState.Manual}
                            onClose={handleModalClose}
                        />
                    )}
                </>
            ) : (
                <>
                    <PatientMeasurementDetails
                        metricType={metricType}
                        addCommentButton={<AddCommentButtonForm />}
                        canWriteEvaluation={isDoctor}
                        onEditThresholdPress={handleEditThresholdPress}
                    />
                    <EditThresholdModal
                        metricType={metricType}
                        open={modal === ModalState.Threshold}
                        onClose={handleModalClose}
                    />
                </>
            )}
        </MeasurementProviders>
    );
};

export default Measurements;
