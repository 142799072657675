import type { ReactNode } from "react";
import { useAuth } from "@madmedical/store";
import { usePersistentState } from "@madmedical/storage";
import { useEffect } from "react";
import {
    useIsCurrentRouteNha,
    useIsCurrentRouteOpen,
} from "@madmedical/config";
import { useGetMeQuery } from "./api";
import { RepresentedPatientProvider } from "./representedPatient";
import useRouteNavigate from "./routing/useRouteNavigate";
import type { PatientMe } from "./model";
import { NonDetachedPatientIdProvider } from "./detachedPatientId";

const REPRESENTED_PATIENT_KEY = "representedPatient";

interface Props {
    readonly children: ReactNode;
}

/**
 * Preloading current user and provides represented patient. Goes under AuthWrapper
 */
const UserWrapper = ({ children }: Props) => {
    const { isTokenAvailable } = useAuth();
    const { data: meData, isLoading: isMeLoading } = useGetMeQuery(undefined, {
        skip: !isTokenAvailable,
    });
    const isOpenRoute = useIsCurrentRouteOpen();
    const isNhaRoute = useIsCurrentRouteNha();
    const [
        representedPatient,
        setRepresentedPatient,
        { isLoading: isRepresentedLoading },
    ] = usePersistentState<PatientMe | null>(REPRESENTED_PATIENT_KEY, null);
    const navigate = useRouteNavigate();
    const [
        onboardingShown,
        setOnboardingShown,
        { isLoading: isOnboardingLoading },
    ] = usePersistentState<boolean>("onboardingDismissed", false);

    const isLoading = isMeLoading || isRepresentedLoading;

    const needsRepresentatedSelect =
        isNhaRoute &&
        meData?.roles.isRepresentative &&
        !isRepresentedLoading &&
        !representedPatient;

    // Select the latest representative
    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!needsRepresentatedSelect) {
            return;
        }

        const defaultRepresented =
            meData.patient ??
            meData.representative?.representedPatients[
                meData.representative.representedPatients.length - 1
            ];
        if (!defaultRepresented) {
            return;
        }

        setRepresentedPatient(defaultRepresented);
    }, [isLoading, needsRepresentatedSelect, meData, setRepresentedPatient]);

    // Redirect new patient users to onboarding
    useEffect(() => {
        if (isOpenRoute) {
            // Do not enforce on public routes
            return;
        }
        if (needsRepresentatedSelect) {
            // Wait until patient is selected
            return;
        }
        if (!isNhaRoute && (isOnboardingLoading || onboardingShown)) {
            // Do not enforce on non-NHA route if it was already shown once
            return;
        }

        const currentPatient = meData?.roles.isRepresentative
            ? representedPatient
            : meData?.patient;
        if (!currentPatient) {
            return;
        }

        if (!currentPatient?.onboarded) {
            setOnboardingShown(true);
            navigate("onboarding", {});
        }
    }, [
        isOpenRoute,
        needsRepresentatedSelect,
        isNhaRoute,
        isOnboardingLoading,
        onboardingShown,
        representedPatient,
        meData,
        setOnboardingShown,
        navigate,
    ]);

    // Clean up on logout
    useEffect(() => {
        if (isTokenAvailable) {
            return;
        }

        setRepresentedPatient(null);
        setOnboardingShown(false);
    }, [isTokenAvailable, setRepresentedPatient, setOnboardingShown]);

    if ((isLoading || needsRepresentatedSelect) && isNhaRoute && !isOpenRoute) {
        return null;
    }

    return (
        <NonDetachedPatientIdProvider>
            <RepresentedPatientProvider
                value={{
                    representedPatient,
                    setRepresentedPatient,
                }}
            >
                {children}
            </RepresentedPatientProvider>
        </NonDetachedPatientIdProvider>
    );
};

export default UserWrapper;
