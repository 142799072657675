import type { ComponentProps } from "react";
import { useState } from "react";
import { Box, Popover } from "native-base";
import { toggleInArray } from "@madmedical/utils";
import Badge from "../atoms/Badge";
import Button from "../atoms/Button";
import Dropdown from "../molecules/Dropdown";
import Checkbox from "../atoms/Checkbox";

interface Item<TKey extends string | number> {
    key: TKey;
    text: string;
    isSelected: boolean;
}

interface Props<TItem extends Item<string | number>> {
    dropdownPosition?: ComponentProps<typeof Popover>["placement"];
    items: TItem[];
    isSearchable?: boolean;
    title: ComponentProps<typeof Dropdown>["title"];
    isMultiselect?: boolean;
    onChange: (selected: TItem["key"][]) => void;
}

const FilterButton = <TItem extends Item<string | number>>({
    dropdownPosition = "bottom left",
    items,
    isSearchable = false,
    isMultiselect,
    onChange,
    title,
}: Props<TItem>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOpenPress = () => {
        setIsOpen((prevState) => !prevState);
    };

    const selectedKeys = items
        .filter(({ isSelected }) => isSelected)
        .map(({ key }) => key);

    const handleItemChange = (key: TItem["key"]) => () => {
        onChange(isMultiselect ? toggleInArray(selectedKeys, key) : [key]);
    };

    const handleReset = () => {
        onChange([]);
    };

    const filterable = items.map(({ key, text, isSelected }) => ({
        key,
        text,
        formControl: (
            <Checkbox
                value={key as string}
                isChecked={isSelected}
                onChange={handleItemChange(key)}
                borderRadius={isMultiselect ? 4 : 100}
            />
        ),
    }));

    return (
        <Popover
            useRNModal
            placement={dropdownPosition}
            trigger={(triggerProps) => (
                <Box position="relative">
                    <Button
                        {...triggerProps}
                        size="xs"
                        variant={
                            isOpen && selectedKeys.length >= 0
                                ? "ghostSuccess"
                                : "outlined"
                        }
                        rightIconName={`chevron${isOpen ? "Up" : "Down"}`}
                        onPress={handleOpenPress}
                        borderColor={`${isOpen ? "green" : "gray"}.300`}
                        borderWidth={isOpen ? 1 : undefined}
                        bgColor={isOpen ? "white" : undefined}
                    >
                        {title}
                    </Button>
                    {isMultiselect && selectedKeys.length ? (
                        <Badge
                            size="xs"
                            position="absolute"
                            top={-4}
                            right={-4}
                            variant="ghostSuccess"
                            hasIndicator={false}
                            px={1}
                            borderColor={`${isOpen ? "green" : "gray"}.300`}
                            borderWidth={isOpen ? 1 : undefined}
                            bgColor={isOpen ? "white" : undefined}
                        >
                            {selectedKeys.length}
                        </Badge>
                    ) : null}
                </Box>
            )}
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
        >
            <Popover.Content
                borderColor="gray.100"
                bgColor="transparent"
                overflow="hidden"
            >
                <Dropdown
                    hasSearch={isSearchable}
                    title={title}
                    list={filterable}
                    minWidth={72}
                    maxWidth={80}
                    onReset={handleReset}
                />
            </Popover.Content>
        </Popover>
    );
};

export default FilterButton;
