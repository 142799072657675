import type { ReactNode } from "react";
import {
    ChartProvider,
    CurrentPatientProvider,
    InsulinBodyProvider,
    Spinner,
} from "@madmedical/ui";
import { AlertLevel, MetricType } from "@madmedical/medical";
import { invariant, rangeToString } from "@madmedical/utils";
import { adaptPatient, usePatient } from "@madmedical/user";
import { useBodyDateRange } from "@madmedical/store";
import { useGetInsulinAdministrationRangeQuery } from "../api";
import adaptAdministration from "../adaptAdministration";

interface Props {
    children: ReactNode;
}

const InsulinPortioningProvider = ({ children }: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();

    const metricType = MetricType.BloodSugar;

    const { dateRange, setDateRange } = useBodyDateRange();

    const { data: insulinAdministrations, isLoading: isInsulinLoading } =
        useGetInsulinAdministrationRangeQuery({
            patientId,
            dateRange: rangeToString(dateRange),
        });

    if (isPatientLoading || isInsulinLoading) {
        return <Spinner />;
    }

    invariant(patient);
    invariant(insulinAdministrations);

    return (
        <ChartProvider
            value={{
                chart: <></>,
                metricType,
                dateRange,
                displayRange: null,
                alertLevel: AlertLevel.Ok,
                setDateRange,
            }}
        >
            <InsulinBodyProvider
                value={{
                    administrations:
                        insulinAdministrations?.map(adaptAdministration) ?? [],
                }}
            >
                <CurrentPatientProvider
                    value={{ patient: adaptPatient(patient) }}
                >
                    {children}
                </CurrentPatientProvider>
            </InsulinBodyProvider>
        </ChartProvider>
    );
};

export default InsulinPortioningProvider;
