import { CalculateBirthTimeModal, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import type { MutationActionCreatorResult } from "@madmedical/store";

interface Props {
    open: boolean;
    onClose: () => void;
    transmittedValues: (
        lastMenstruationDate: Date,
        menstruationCycleLength: number
    ) => void;
}

const CalculateBirthTimeModalForm = ({
    open,
    onClose,
    transmittedValues,
}: Props) => {
    const lastMenstruationDate = new Date();

    return (
        <Modal open={open} size="xs" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{
                    lastMenstruationDate,
                    menstruationCycleLength: 28,
                }}
                mutation={({ lastMenstruationDate, menstruationCycleLength }) =>
                    transmittedValues(
                        lastMenstruationDate,
                        menstruationCycleLength
                    ) as unknown as MutationActionCreatorResult<unknown>
                } // ugly type hack ???
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <CalculateBirthTimeModal
                        isSuccess={isSuccess}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default CalculateBirthTimeModalForm;
