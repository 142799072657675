import type { ComponentProps, ReactNode } from "react";
import { Box, Stack } from "native-base";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";

interface Props
    extends Pick<
        ComponentProps<typeof Stack>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
        | "width"
        | "minWidth"
        | "maxWidth"
        | "height"
        | "minHeight"
        | "maxHeight"
        | "borderWidth"
        | "shadow"
        | "flex"
    > {
    children?: ReactNode;
    header?: ReactNode;
    _header?: object;
    footer?: ReactNode;
    _footer?: object;
    _content?: object;
}

const Card = ({
    header,
    _header,
    footer,
    _footer,
    _content,
    children,
    ...rest
}: Props) => (
    <Stack
        direction="column"
        justifyContent="space-between"
        overflow="hidden"
        borderRadius="lg"
        borderColor="gray.100"
        borderWidth={1}
        borderStyle="solid"
        bgColor="white"
        flex={1}
        px={4}
        py={4}
        {...rest}
    >
        {header && <CardHeader {..._header}>{header}</CardHeader>}
        <Box flex={1} {..._content}>
            {children}
        </Box>
        {footer && <CardFooter {..._footer}>{footer}</CardFooter>}
    </Stack>
);

export default Card;
