import { Outlet } from "@madmedical/routing";
import { MainLayout } from "@madmedical/ui";
import { PatientSelect, useIsActive, useMe } from "@madmedical/user";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useAppConfig } from "@madmedical/config";
import { UnderlyingCondition } from "@madmedical/medical";
import TestMenu from "./TestMenu";
import RoutingProvider from "./RoutingProvider";

const Main = () => {
    const { integrator } = useAppConfig();
    const isActive = useIsActive();
    const {
        patient,
        roles: { isPatient, isRepresentative },
    } = useMe();

    const bottomNavigation = useMemo((): ComponentProps<
        typeof MainLayout
    >["bottomNavigation"] => {
        if ((!isPatient && !isRepresentative) || isActive("preop", {})) {
            return undefined;
        }

        if (!isPatient && isRepresentative) {
            return [
                {
                    iconName: "heart",
                    route: "dashboard",
                    params: {},
                } as const,
                {
                    iconName: "reportData",
                    route: "opinions",
                    params: {},
                } as const,
                {
                    iconName: "documentView",
                    route: "documents_general",
                    params: {},
                } as const,
            ];
        }

        const onlyBasicPackage = patient?.packages?.every(
            (p) => p.type === "basic"
        );

        const isInsulin =
            patient?.underlyingConditions.includes(
                UnderlyingCondition.Insulin
            ) && patient?.packages?.some((p) => p.type === "diabetes");

        if (onlyBasicPackage) {
            return [
                {
                    iconName: "heart",
                    route: "dashboard",
                    params: {},
                } as const,
                {
                    iconName: "devices",
                    route: "devices_connected",
                    params: {},
                } as const,
            ];
        }

        return [
            {
                iconName: "heart",
                route: "dashboard",
                params: {},
            } as const,
            {
                iconName: "devices",
                route: "devices_connected",
                params: {},
            } as const,
            {
                iconName: "reportData",
                route: "opinions",
                params: {},
            } as const,
            {
                iconName: "userAdmin",
                route: "representatives",
                params: {},
            } as const,
            ...(isInsulin
                ? [
                      {
                          iconName: "alarm",
                          route: "insulin_reminders",
                          params: {},
                      } as const,
                  ]
                : []),
            {
                iconName: "documentView",
                route: "documents_general",
                params: {},
            } as const,
        ];
    }, [
        isActive,
        isPatient,
        isRepresentative,
        patient?.packages,
        patient?.underlyingConditions,
    ]);

    return (
        <RoutingProvider>
            <MainLayout
                bottomNavigation={bottomNavigation}
                sidebar={integrator ? undefined : <TestMenu />}
                isRepresentative={isRepresentative}
            >
                <PatientSelect />
                <Outlet />
            </MainLayout>
        </RoutingProvider>
    );
};

export default Main;
