import { useEffect, useRef, useState } from "react";

interface Props {
    element: HTMLElement | null;
    onReached?: () => unknown;
}

/**
 * Only for web! On mobile, use FlatList.onEndReached
 */
const useInView = ({ element, onReached }: Props) => {
    const observer = useRef<IntersectionObserver>();
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }

        if (!element || !onReached) {
            return;
        }

        observer.current = new IntersectionObserver((entries) => {
            if (isFetching) {
                return;
            }
            if (!entries[0].isIntersecting) {
                return;
            }

            void (async () => {
                setFetching(true);
                try {
                    await onReached(); // This await makes no sense TODO
                } finally {
                    setFetching(false);
                }
            })();
        });
        observer.current.observe(element);
    }, [element, isFetching, onReached]);

    return isFetching;
};

export default useInView;
