import { Dimensions } from "react-native";

const sizes = {
    xs: {
        contentSize: {
            width: `${Dimensions.get("window").width - 32}px`,
            maxWidth: "400",
        },
    },
};

export const AlertDialog = {
    baseStyle: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        _web: { pointerEvents: "box-none" },
        _backdropFade: { exitDuration: 150, entryDuration: 200 },
        _fade: { exitDuration: 100, entryDuration: 200 },
        _slide: { duration: 200, overlay: false },
    },
    sizes,
    defaultProps: {},
};

export const AlertDialogContent = {
    baseStyle: () => ({
        shadow: 1,
        rounded: "lg",
        maxHeight: `${Dimensions.get("window").height - 150}px`,
        overflow: "hidden",
        bg: "muted.50",
        _text: {
            color: "text.900",
        },
    }),
};
export const AlertDialogCloseButton = {
    baseStyle: () => ({
        position: "absolute",
        right: 5,
        top: 5,
        zIndex: 1,
        p: 2,
        bg: "transparent",
        _web: {
            outlineWidth: 0,
            cursor: "pointer",
        },
        _icon: {
            color: "gray.800",
            size: 3,
        },
        _hover: {
            bg: "none",
            bgColor: "none",
        },
        _pressed: {
            bg: "none",
            bgColor: "none",
        },
    }),
};
export const AlertDialogHeader = {
    baseStyle: () => ({
        p: 6,
        pb: 0,
        borderBottomWidth: 0,
        _text: {
            color: "text.900",
            fontSize: "lg",
            fontWeight: "medium",
            lineHeight: "sm",
        },
        bg: "muted.50",
        borderColor: "muted.300",
    }),
};
export const AlertDialogBody = {
    baseStyle: () => ({
        p: 6,

        bg: "muted.50",
        _text: {
            color: "text.900",
        },
    }),
};
export const AlertDialogFooter = {
    baseStyle: () => ({
        p: 6,
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        borderTopWidth: 1,
        bg: "muted.50",
        borderColor: "muted.300",
    }),
};
export const AlertDialogOverlay = {
    baseStyle: {
        opacity: 80,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
};
