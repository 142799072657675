import { useFormContext } from "@madmedical/form";
import BaseFormError from "../molecules/Form/FormError";

const FormError = () => {
    const {
        formState: { errors },
    } = useFormContext();

    const formError = errors.root?.formError?.message;

    if (!formError) {
        return null;
    }

    return <BaseFormError error={formError} />;
};

export default FormError;
