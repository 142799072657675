import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    Invite,
    InviteRequest,
    PatientRepresentative,
    UpdateInviteRequest,
    UpdateRepresentativeRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["invites", "representatives"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getInvites: build.query<Invite[], void>({
                query: () => "security/invites",
                providesTags: ["invites"],
            }),
            invite: build.mutation<Invite, InviteRequest>({
                query: (body) => ({
                    url: `security/invites`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["invites"],
            }),
            reInvite: build.mutation<Invite, Ulid>({
                query: (id) => ({
                    url: `security/invites/${id}`,
                    method: "POST",
                }),
                invalidatesTags: ["invites"],
            }),
            updateInvite: build.mutation<Invite, UpdateInviteRequest>({
                query: ({ id, ...body }) => ({
                    url: `security/invites/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["invites"],
            }),
            deleteInvite: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `security/invites/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["invites"],
            }),

            getRepresentatives: build.query<PatientRepresentative[], void>({
                query: () => "medical/representatives",
                providesTags: ["representatives"],
            }),
            updateRepresentative: build.mutation<
                PatientRepresentative,
                UpdateRepresentativeRequest
            >({
                query: ({ userId, ...body }) => ({
                    url: `medical/representatives/${userId}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["representatives"],
            }),
            deleteRepresentative: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `medical/representatives/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["representatives"],
            }),
        }),
    });

export const {
    useGetInvitesQuery,
    useGetRepresentativesQuery,
    useInviteMutation,
    useReInviteMutation,
    useUpdateInviteMutation,
    useUpdateRepresentativeMutation,
    useDeleteInviteMutation,
    useDeleteRepresentativeMutation,
} = api;
