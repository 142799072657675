import { useParams } from "@madmedical/routing";
import { invariant, isWeb } from "@madmedical/utils";
import { useDismissPersist } from "@madmedical/storage";
import { useMe } from "@madmedical/user";
import useDevice from "../useDevice";
import CloudDevice from "./CloudDevice";
import BleDevice from "./BleDevice";
import AppleDevice from "./AppleDevice";

const Device = () => {
    const { provider } = useParams<"provider">();
    const { device, isLoading, connectedDeviceCount } = useDevice(provider);
    const { patient } = useMe();
    const { visible: appDownloadVisible, dismiss: dismissAppDownload } =
        useDismissPersist("appDownloadAlert");

    if (isLoading) {
        return null;
    }

    invariant(device);
    invariant(patient);

    const { maxDeviceCount } = patient;
    const canAddDevice =
        maxDeviceCount === null || connectedDeviceCount < maxDeviceCount;

    switch (device.type) {
        case "cloud":
            return (
                <CloudDevice
                    device={device}
                    canAddDevice={canAddDevice}
                    showAppDownload={isWeb && appDownloadVisible}
                    onDismissAppDownload={dismissAppDownload}
                />
            );
        case "ble":
            return (
                <BleDevice
                    device={device}
                    canAddDevice={canAddDevice}
                    showAppDownload={isWeb && appDownloadVisible}
                    onDismissAppDownload={dismissAppDownload}
                />
            );
        case "apple":
            return (
                <AppleDevice
                    device={device}
                    canAddDevice={canAddDevice}
                    showAppDownload={isWeb && appDownloadVisible}
                    onDismissAppDownload={dismissAppDownload}
                />
            );
    }
};

export default Device;
