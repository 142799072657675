import { ApiFormWrapper } from "@madmedical/form";
import { preOpQuestions } from "@madmedical/medical";
import type { PatientMe } from "@madmedical/user";
import { adaptPatient, usePatient, useRouteNavigate } from "@madmedical/user";
import { useState } from "react";
import {
    CurrentPatientProvider,
    PreOpFormExit,
    PreOpFormPersonalData,
    PreOpFormStatement,
    PreOpFormSurvey1,
    PreOpFormSurvey2,
    PreOpFormSurvey3,
    PreOpFormWelcome,
    Spinner,
} from "@madmedical/ui";
import { invariant } from "@madmedical/utils";
import { useSubmitQuestionnaireMutation } from "../api";

enum Step {
    Welcome,
    PersonalData,
    Survey1,
    Survey2,
    Survey3,
    Statement,
    Exit,
}

const PreOpForm = () => {
    const {
        patientId,
        patient,
        isLoading: isPatientLoading,
    } = usePatient<PatientMe>();
    const [submit] = useSubmitQuestionnaireMutation();
    const [step, setStep] = useState(Step.Welcome);
    const navigate = useRouteNavigate();

    const handleStep = (step: Step) => () => {
        setStep(step);
    };

    const handleSuccess = () => {
        setStep(Step.Exit);
    };

    const handleExit = () => {
        navigate("dashboard", {});
    };

    if (isPatientLoading) {
        return <Spinner />;
    }

    invariant(patient);

    return (
        <CurrentPatientProvider value={{ patient: adaptPatient(patient) }}>
            <ApiFormWrapper
                defaultValues={{
                    patientId,
                    questions: preOpQuestions,
                    answers: Object.keys(preOpQuestions).reduce<
                        Record<string, false>
                    >((acc, key) => {
                        acc[key] = false;

                        return acc;
                    }, {}),
                }}
                mutation={submit}
                onSuccess={handleSuccess}
            >
                {({ submitForm }) => (
                    <>
                        {step === Step.Welcome && (
                            <PreOpFormWelcome
                                onNextPress={handleStep(Step.PersonalData)}
                            />
                        )}
                        {step === Step.PersonalData && (
                            <PreOpFormPersonalData
                                onPreviousPress={handleStep(Step.Welcome)}
                                onNextPress={handleStep(Step.Survey1)}
                            />
                        )}
                        {step === Step.Survey1 && (
                            <PreOpFormSurvey1
                                onPreviousPress={handleStep(Step.PersonalData)}
                                onNextPress={handleStep(Step.Survey2)}
                            />
                        )}
                        {step === Step.Survey2 && (
                            <PreOpFormSurvey2
                                onPreviousPress={handleStep(Step.Survey1)}
                                onNextPress={handleStep(Step.Survey3)}
                            />
                        )}
                        {step === Step.Survey3 && (
                            <PreOpFormSurvey3
                                onPreviousPress={handleStep(Step.Survey2)}
                                onNextPress={handleStep(Step.Statement)}
                            />
                        )}
                        {step === Step.Statement && (
                            <PreOpFormStatement
                                onPreviousPress={handleStep(Step.Survey3)}
                                onNextPress={submitForm}
                            />
                        )}
                        {step === Step.Exit && (
                            <PreOpFormExit onNextPress={handleExit} />
                        )}
                    </>
                )}
            </ApiFormWrapper>
        </CurrentPatientProvider>
    );
};

export default PreOpForm;
