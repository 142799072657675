import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import FormControlWrapper from "../molecules/Form/FormControlWrapper";
import DateInput from "../molecules/Form/DateInput";

type Props = Omit<ComponentProps<typeof FormControlWrapper>, "children"> &
    Omit<ComponentProps<typeof DateInput>, "onChange" | "value"> & {
        name: string;
        isRequired?: boolean;
    };

const FormDateInput = ({
    name,
    isRequired,
    hint,
    label,
    labelProps,
    ...rest
}: Props) => {
    const { control, isError, error } = useField(name);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => (
                <FormControlWrapper
                    isInvalid={isError}
                    isRequired={isRequired}
                    error={error}
                    hint={hint}
                    label={label}
                    labelProps={labelProps}
                >
                    <DateInput
                        {...rest}
                        onChange={(value) => {
                            onChange(value.toISOString());
                        }}
                        value={value ? new Date(value as string) : undefined}
                    />
                </FormControlWrapper>
            )}
        />
    );
};

export default FormDateInput;
