import { PressableLink, Text } from "@madmedical/ui";
import { MetricType, forHumans } from "@madmedical/medical";
import { Fragment } from "react";
import { useMe } from "@madmedical/user";
import { useTranslation } from "@madmedical/i18n";

const TestMenu = () => {
    const {
        roles: {
            isPatient,
            isDoctor,
            isRepresentative,
            isHotline,
            isAdmin,
            isCallCenter,
        },
    } = useMe();
    const { t } = useTranslation();

    return (
        <>
            {(isPatient || isRepresentative) && (
                <>
                    <PressableLink
                        route="dashboard"
                        params={{}}
                        search={{
                            language: "en",
                        }}
                    >
                        <Text>EN</Text>
                    </PressableLink>
                    <PressableLink
                        route="dashboard"
                        params={{}}
                        search={{
                            language: "hu",
                        }}
                    >
                        <Text>HU</Text>
                    </PressableLink>
                    <PressableLink route="dashboard" params={{}}>
                        <Text>{t("dashboard")}</Text>
                    </PressableLink>
                    <Text mt={2}>= = = {t("measures:title")} = = =</Text>
                    {Object.values(MetricType).map((metricType) => (
                        <Fragment key={metricType}>
                            <PressableLink
                                route="measurements"
                                params={{ metricType }}
                            >
                                <Text>{`${t("measures:title")} / ${t(
                                    `measures:${forHumans(metricType)}`
                                )}`}</Text>
                            </PressableLink>
                            <PressableLink
                                route="measurements_list"
                                params={{ metricType }}
                            >
                                <Text>{`${t("measures:title")} / ${t(
                                    `measures:${forHumans(metricType)}`
                                )} / ${t("list")}`}</Text>
                            </PressableLink>
                        </Fragment>
                    ))}
                    <Text mt={2}>= = = {t("insulin")} = = =</Text>
                    <PressableLink route="insulin" params={{}}>
                        <Text>{t("insulin")}</Text>
                    </PressableLink>
                    <PressableLink route="insulin_administrations" params={{}}>
                        <Text>{t("measures:insulinInsertions")}</Text>
                    </PressableLink>
                    <PressableLink route="insulin_reminders" params={{}}>
                        <Text>{t("insulinReminders")}</Text>
                    </PressableLink>
                    <Text mt={2}>= = = {t("pregnancy:pregnancy")} = = =</Text>
                    <PressableLink route="pregnancy_body" params={{}}>
                        <Text>{t("pregnancy:bodyChanges")}</Text>
                    </PressableLink>
                    <PressableLink route="pregnancy_baby" params={{}}>
                        <Text>{t("pregnancy:babyDevelopment")}</Text>
                    </PressableLink>
                    <PressableLink route="pregnancy_measurement" params={{}}>
                        <Text>{t("measures:title")}</Text>
                    </PressableLink>
                    <PressableLink route="pregnancy_feeling" params={{}}>
                        <Text>{t("pregnancy:feelingAndSymptoms")}</Text>
                    </PressableLink>
                    <Text mt={2}>= = = {t("other")} = = =</Text>
                    {isPatient && !isRepresentative && (
                        <PressableLink route="devices_connected" params={{}}>
                            <Text>{t("devices")}</Text>
                        </PressableLink>
                    )}
                    <PressableLink route="opinions" params={{}}>
                        <Text>{t("results")}</Text>
                    </PressableLink>
                    <PressableLink route="documents_opinion" params={{}}>
                        <Text>{t("documents")}</Text>
                    </PressableLink>
                    {isPatient && !isRepresentative && (
                        <PressableLink route="representatives" params={{}}>
                            <Text>{t("authorizedPersons")}</Text>
                        </PressableLink>
                    )}
                </>
            )}
            {(isDoctor || isHotline || isAdmin) && (
                <>
                    <PressableLink route="patients" params={{}}>
                        <Text>{t("patients")}</Text>
                    </PressableLink>
                    <PressableLink
                        route="patients_measurements"
                        params={{ byPeriod: "day" }}
                    >
                        <Text>{t("measures:title")}</Text>
                    </PressableLink>
                </>
            )}
            {isCallCenter && (
                <>
                    <PressableLink route="patients" params={{}}>
                        <Text>{t("patients")}</Text>
                    </PressableLink>
                    <PressableLink route="callcenter_devices" params={{}}>
                        <Text>{t("devices")}</Text>
                    </PressableLink>
                </>
            )}
        </>
    );
};

export default TestMenu;
