import type { BloodOxygen } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import { floor2 } from "@madmedical/utils";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<BloodOxygen>;

const BloodOxygenChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BloodOxygen, widget);

    const graphProps = useMemo(() => {
        const { min } = data.stats;

        return {
            type: "dot",
            unit: "%",
            limits: {
                yMax: 100,
                yMin: min ? floor2(min.spO2 - 0.5) : 80,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.spO2,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.BloodOxygen}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BloodOxygenChart;
