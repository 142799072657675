import { useMemo } from "react";
import { usePatientId } from "@madmedical/user";
import type { DeviceOwnership } from "@madmedical/medical";
import { useGetPatientLogisticsDevicesQuery } from "@madmedical/callcenter";
import { useGetDevicesQuery } from "./api";
import type { DeviceResponse, ResolvedDevice } from "./model";

type UseDevices = () => {
    isLoading: boolean;
    isFetching: boolean;
    refetch: () => Promise<unknown>;
    devices: ResolvedDevice[];
    connectedDeviceCount: number;
};

interface LogisticsDevice {
    serialNumber: string;
    ownership: DeviceOwnership | null;
}

const resolveDevice = (
    device: DeviceResponse,
    logisticsDevice: LogisticsDevice | undefined
) => {
    const {
        provider,
        forHumans,
        connectedAt: connectedAtString,
        lastRecordedAt: lastRecordedAtString,
        ...rest
    } = device;

    const connectedAt = connectedAtString ? new Date(connectedAtString) : null;
    const lastRecordedAt = lastRecordedAtString
        ? new Date(lastRecordedAtString)
        : null;

    return {
        provider,
        deviceName: forHumans,
        connectedAt,
        lastRecordedAt,
        serialNumber: logisticsDevice?.serialNumber,
        ownership: logisticsDevice?.ownership,
        ...rest,
    } as const;
};

const useDevices: UseDevices = () => {
    const { patientId } = usePatientId();
    const {
        data: apiDevices,
        isLoading: isApiLoading,
        isFetching,
        refetch,
    } = useGetDevicesQuery(patientId);
    const {
        data: logisticsDevices,
        isLoading: isLogisticsApiLoading,
        isFetching: isLogisticsFetching,
    } = useGetPatientLogisticsDevicesQuery(patientId);

    const resolvedDevices = useMemo(
        () =>
            apiDevices?.map((device) =>
                resolveDevice(
                    device,
                    device.type === "ble"
                        ? logisticsDevices?.find(
                              (logisticsDevice) =>
                                  logisticsDevice.deviceType.provider ===
                                  device.provider
                          )
                        : undefined
                )
            ) ?? [],
        [apiDevices, logisticsDevices]
    );

    return {
        isLoading: isApiLoading || isLogisticsApiLoading,
        isFetching: isFetching || isLogisticsFetching,
        refetch,
        devices: resolvedDevices as ResolvedDevice[],
        connectedDeviceCount: resolvedDevices.filter(
            ({ isConnected }) => isConnected
        ).length,
    };
};

export default useDevices;
