import type { CellProps, Column } from "react-table";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import { isDaily } from "@madmedical/medical";
import { useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { formatTime } from "@madmedical/utils";
import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import Badge from "../atoms/Badge";
import StatusBadge from "../atoms/StatusBadge";

interface Data {
    id: Ulid;
    displayValue: string;
    measuredAt: Date;
    manual: boolean;
    alertLevel: AlertLevel | null;
    deleted: boolean;
}

interface Props {
    data: Data[];
    readonly onEndReached?: () => unknown;
    onDeletePress: (id: Ulid) => void;
    metricType: MetricType;
    isPatient?: boolean;
}

const MeasurementsTable = ({
    data,
    onEndReached,
    onDeletePress,
    metricType,
    isPatient = true,
}: Props) => {
    const { t } = useTranslation();
    let columns = useMemo<Column<Data>[]>(
        () => [
            {
                Header: t("appointment"),
                accessor: "measuredAt",
                width: "30%",
                Cell: ({ row: { original: measurement } }) => (
                    <>
                        {formatTime(measurement.measuredAt)}
                        {measurement.manual && !measurement.deleted && (
                            <Badge variant="base" ml={2} hasIndicator={false}>
                                {t("measures:manual")}
                            </Badge>
                        )}
                        {measurement.deleted && (
                            <Badge variant="base" ml={2} hasIndicator={false}>
                                {t("measures:deleted")}
                            </Badge>
                        )}
                    </>
                ),
            },
            {
                Header: t("value"),
                accessor: "displayValue",
                width: "auto",
            },
            {
                Header: t("measures:alert"),
                accessor: "alertLevel",
                width: "30%",
                Cell: ({ value: alertLevel }) => (
                    <StatusBadge level={alertLevel} />
                ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({ row: { original: measurement } }: CellProps<Data>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            size="xs"
                            actions={
                                measurement.manual && !measurement.deleted
                                    ? [
                                          {
                                              text: t("delete"),
                                              iconName: "trashCan",
                                              onPress: () => {
                                                  onDeletePress(measurement.id);
                                              },
                                          },
                                      ]
                                    : []
                            }
                        />
                    </Box>
                ),
            },
        ],
        [onDeletePress, t]
    );

    if (!isPatient) {
        columns = columns.filter((column) => column.id !== "action");
    }

    if (isDaily(metricType)) {
        columns.shift();
    }

    return (
        <ReactTable columns={columns} data={data} onEndReached={onEndReached} />
    );
};

export default MeasurementsTable;
