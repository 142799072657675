import Svg, { Path, Rect } from "react-native-svg";

const BlandMood = () => (
    <Svg width="100%" height="100%" viewBox="0 0 65 64" fill="none">
        <Rect x="0.699951" width="64" height="64" rx="4" fill="#D4E5EF" />
        <Path
            d="M25.3222 41.5371H40.2928C41.2856 41.5371 42.0879 42.7798 42.0879 44.3112C42.0879 45.8426 41.2833 47.0853 40.2928 47.0853H25.3222C24.3309 47.0853 23.5264 45.8426 23.5264 44.3112C23.5264 42.7798 24.3317 41.5371 25.3222 41.5371Z"
            fill="#6793AC"
        />
        <Path
            d="M28.6602 18.9048C28.6602 22.5596 25.6857 25.5341 22.0294 25.5341C18.3745 25.5341 15.4 22.5604 15.4 18.9048C15.4 17.8526 16.2526 17 17.3048 17C18.357 17 19.2095 17.8526 19.2095 18.9048C19.2095 20.4598 20.4743 21.7246 22.0294 21.7246C23.5844 21.7246 24.8507 20.4598 24.8507 18.9048C24.8507 17.8526 25.7033 17 26.7555 17C27.8076 17 28.6602 17.8534 28.6602 18.9048ZM49.7817 18.9254C49.7817 22.5802 46.8073 25.5547 43.1509 25.5547C39.496 25.5547 36.5215 22.581 36.5215 18.9254C36.5215 17.8732 37.3741 17.0206 38.4263 17.0206C39.4785 17.0206 40.3311 17.8732 40.3311 18.9254C40.3311 20.4804 41.5958 21.7452 43.1509 21.7452C44.7067 21.7452 45.9722 20.4804 45.9722 18.9254C45.9722 17.8732 46.8248 17.0206 47.877 17.0206C48.9292 17.0206 49.7817 17.8732 49.7817 18.9254Z"
            fill="#6793AC"
        />
    </Svg>
);

export default BlandMood;
