import {
    Box,
    FormControl,
    HStack,
    Popover,
    Pressable,
    ScrollView,
    Text,
    VStack,
} from "native-base";
import type { ComponentProps } from "react";
import { useEffect, useState } from "react";
import { isWeb } from "@madmedical/utils";
import Tag from "../../atoms/Tag";

interface Item {
    key: string;
    text: string;
}

interface Props extends ComponentProps<typeof Pressable> {
    list: Item[];
    values?: string[];
    onChange: (values: string[]) => void;
    size?: "sm" | "md";
    isRequired?: boolean;
    isInvalid?: boolean;
    error?: string;
    hint?: string;
    label?: string;
    maxItems?: number;
}

const MultiSelect = ({
    list,
    onChange,
    values = [],
    isRequired = false,
    isInvalid = false,
    error,
    hint,
    label,
    size = "sm",
    maxItems,
    ...rest
}: Props) => {
    const [selected, setSelected] = useState<string[]>(values);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const disableSelect =
        maxItems && selected.length >= maxItems ? true : false;

    useEffect(() => {
        onChange(selected);
    }, [selected, onChange]);

    const getHeight = () => {
        switch (size) {
            case "sm":
                return "8";
            case "md":
                return "11";
        }
    };

    const handleSelectPress = (key: string) => () => {
        if (disableSelect) {
            return;
        }
        setSelected((ps) => [...ps, key]);
        if (!isWeb) {
            setIsOpen(false);
        }
    };

    const handleRemovePress = (key: string) => () => {
        setSelected((previous) => previous.filter((s) => s !== key));
    };

    return (
        <FormControl flex={1} isInvalid={isInvalid} isRequired={isRequired}>
            {label && (
                <FormControl.Label
                    testID="base-form-control-label"
                    fontWeight="medium"
                >
                    {label}
                </FormControl.Label>
            )}
            <Popover
                useRNModal
                placement="top left"
                trigger={(triggerProps) => (
                    <Pressable
                        {...triggerProps}
                        _focus={{
                            borderColor: disableSelect
                                ? "gray.200"
                                : "primary.500",
                        }}
                        _hover={{
                            borderColor: disableSelect
                                ? "gray.200"
                                : "primary.500",
                        }}
                        borderWidth="1"
                        borderColor="gray.200"
                        minHeight={getHeight()}
                        px="3"
                        py="2"
                        pt={1}
                        width="64"
                        borderRadius="md"
                        bgColor="white"
                        alignItems="center"
                        {...rest}
                        onPress={() =>
                            disableSelect || isOpen
                                ? undefined
                                : setIsOpen(true)
                        }
                        {...(disableSelect || isOpen
                            ? {
                                  onPress: (e) => {
                                      e.preventDefault();
                                  },
                              }
                            : () => setIsOpen(!isOpen))}
                    >
                        <HStack width="full" zIndex={1}>
                            <HStack flex="1" flexWrap="wrap" space="1">
                                {list
                                    .filter(({ key }) => selected.includes(key))
                                    .map(({ key, text }) => (
                                        <Tag
                                            key={key}
                                            hasClose
                                            onPress={handleRemovePress(key)}
                                            mt={1}
                                        >
                                            <Text>{text}</Text>
                                        </Tag>
                                    ))}
                            </HStack>
                        </HStack>
                    </Pressable>
                )}
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
            >
                <Popover.Content
                    zIndex={50}
                    background="white"
                    borderWidth="0"
                    shadow="2"
                    maxHeight="56"
                    overflowY="auto"
                    p="0.5"
                    flex={1}
                    width={64}
                    display={disableSelect ? "none" : "flex"}
                >
                    <ScrollView>
                        <VStack>
                            {list
                                .filter(({ key }) => !selected.includes(key))
                                .map(({ key, text }) => (
                                    <Pressable
                                        key={key}
                                        onPress={handleSelectPress(key)}
                                        _hover={{
                                            backgroundColor: "blueGray.50",
                                        }}
                                    >
                                        <Box px="6" py="2">
                                            <Text>{text}</Text>
                                        </Box>
                                    </Pressable>
                                ))}
                        </VStack>
                    </ScrollView>
                </Popover.Content>
            </Popover>
            {hint && !error && (
                <Text mt={1} fontSize="sm" color="gray.600">
                    {hint}
                </Text>
            )}
            {error && (
                <FormControl.ErrorMessage mt={1} _text={{ fontSize: "sm" }}>
                    {error}
                </FormControl.ErrorMessage>
            )}
        </FormControl>
    );
};

export default MultiSelect;
