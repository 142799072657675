import { Divider, HStack, Text } from "native-base";
import useResponsive from "../../util/useResponsive";

interface Props {
    weight: string;
    height: string;
}

const PregnancyDashDatas = ({ weight, height }: Props) => {
    const { isSmallScreen } = useResponsive();

    return (
        <HStack
            divider={<Divider />}
            direction={"row"}
            space={isSmallScreen ? 1 : 2}
        >
            <Text>
                <Text
                    fontSize="md"
                    fontWeight="medium"
                    mr={1}
                    numberOfLines={1}
                >
                    {weight}
                </Text>
                gr
            </Text>
            <Text>
                <Text
                    fontSize="md"
                    fontWeight="medium"
                    mr={1}
                    numberOfLines={1}
                >
                    {height}
                </Text>
                cm
            </Text>
        </HStack>
    );
};

export default PregnancyDashDatas;
