import { Box } from "native-base";
import { MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import MeasurementsTabs from "../../organisms/MeasurementsTabs";
import type { InsulinAdministration } from "../../providers/insulinAdministration";
import { useInsulinAdministration } from "../../providers/insulinAdministration";
import downloadInsulinData from "../../util/pdf/downloadInsulinData";
import InsulinMeasurementList from "./InsulinMeasurementList";

interface Props {
    isPatient?: boolean;
    deleteAdministration?: (id: string) => void;
    editAdministration?: (
        measurement: InsulinAdministration
    ) => void | undefined;
    patientName?: string;
    insulinUser?: boolean;
}

const InsulinAdministrationContent = ({
    isPatient = true,
    deleteAdministration,
    editAdministration,
    patientName,
    insulinUser = false,
}: Props) => {
    const { allAdministrations, incrementPage } = useInsulinAdministration();
    const { t } = useTranslation();

    const handleDownload = () => {
        downloadInsulinData(allAdministrations ?? [], patientName ?? "");
    };

    const measurementData = allAdministrations?.map((item) => ({
        ...item,
        editable: isPatient,
    }));
    if (!measurementData) {
        return null;
    }

    return (
        <Box maxHeight="full">
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link route="dashboard" params={{}}>
                                {t("health")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>{t("insulin")}</Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <MeasurementsTabs
                metricType={MetricType.BloodSugar}
                isPatient
                downloadPressed={handleDownload}
                insulinUser={insulinUser}
            />

            <InsulinMeasurementList
                data={measurementData}
                onEndReached={incrementPage}
                onDeletePress={deleteAdministration}
                onEditPress={editAdministration}
            />
        </Box>
    );
};

export default InsulinAdministrationContent;
