import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { DateTimeString, Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type {
    MetricType,
    Pregnancy,
    Profile,
    ProfileRole,
    Service,
    UnderlyingCondition,
} from "@madmedical/medical";
import type { DocumentService } from "../form/upload/model";

type BasePatient = Profile & {
    userId: Ulid;
    role: ProfileRole.Patient;
    birthDate: DateTimeString | null;
};

type PatientMe = BasePatient & {
    onboarded: boolean;
    underlyingConditions: UnderlyingCondition[];
    services: Service[];
    maxDeviceCount: number;
    maxRepresentativeCount: number;
    gender?: "male" | "female";
};

interface Document {
    readonly id: Ulid;
    readonly createdAt: DateTimeString;
    readonly previewUrl: string;
    readonly downloadUrl: string;
    readonly mimeType: string;
    readonly filename: string;
    readonly size: number;
    readonly note: string | null;
}
interface DocumentExpanded extends Document {
    readonly patient: BasePatient;
    readonly opinion: null | {
        metricType: MetricType;
        text: string;
        doctor: {
            fullName: string;
            avatarUrl?: string;
            initials: string;
        };
    };
}
interface DocumentWithService extends DocumentExpanded {
    readonly service: null | {
        readonly value: DocumentService;
        readonly forHumans: string;
    };
}
interface ContextValue {
    pregnancyData: Pregnancy;
    patient: PatientMe;
    pregnancyDocuments: DocumentWithService[];
}

const PregnancyDataContext = createContext<ContextValue | undefined>(undefined);

export const PregnancyDataProvider =
    PregnancyDataContext.Provider as ReactProvider<ContextValue>;

export const usePregnancyData = () => useEnsureContext(PregnancyDataContext);
