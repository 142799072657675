import Svg, { Path, Rect } from "react-native-svg";

const Artichoke = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M49.7524 38.3279C49.7524 38.3279 54.5435 23.2637 64.545 23.2637C74.5464 23.2637 79.0718 34.899 79.0718 34.899L75.1201 46.113H55.4903L49.7524 38.3279Z"
            fill="#7B9791"
        />
        <Path
            d="M77.0052 28.7078C77.4381 32.0436 76.9389 36.7118 73.3292 42.2102L78.9658 56.9964L90.4082 48.2454C90.4082 48.2454 90.7194 34.7557 80.5453 26.5325C78.9748 25.2632 76.737 26.6427 77.0052 28.7078Z"
            fill="#7B9791"
        />
        <Path
            d="M52.9948 28.7078C52.8776 29.6174 52.8388 30.5353 52.879 31.4516L49.7523 48.5628C45.0419 45.6166 41.9296 41.876 42.0676 36.5202C43.6652 32.6308 46.2035 29.1989 49.4547 26.5325C51.0252 25.2632 53.263 26.6427 52.9948 28.7078Z"
            fill="#B6D2C6"
        />
        <Path
            d="M50.1989 36.9855C49.7824 37.98 49.0161 38.7875 48.0447 39.2554C47.0733 39.7233 45.9642 39.8192 44.927 39.525C43.7418 39.1686 42.6348 38.3133 42.0666 36.5215C40.5001 40.2355 39.66 44.2153 39.5918 48.2455L51.0344 56.9969L56.6711 42.2106C54.0114 38.1594 53.0414 34.5589 52.8877 31.6111C52.734 28.6633 51.328 34.363 50.1989 36.9855Z"
            fill="#7B9791"
        />
        <Path
            d="M90.3904 38.9853C89.2792 42.2706 87.6246 45.3461 85.4955 48.0838L89.833 70.3778C89.833 70.3778 105.965 57.0498 94.1354 38.5258C93.922 38.1947 93.6197 37.9305 93.263 37.7634C92.9063 37.5963 92.5097 37.5331 92.1188 37.581C91.7278 37.629 91.3583 37.7862 91.0526 38.0346C90.7469 38.2829 90.5174 38.6124 90.3904 38.9853Z"
            fill="#7B9791"
        />
        <Path
            d="M60.9682 32.7673C58.6758 35.0375 55.373 39.0179 53.4811 44.5271C52.7253 48.4341 53.2768 52.1374 55.7681 55.5376L75.2328 55.8325L76.4207 43.7608C75.0253 40.2125 72.9547 36.9687 70.3238 34.209C69.9659 33.8252 69.5059 33.5515 68.9978 33.4201C68.4898 33.2886 67.9548 33.3048 67.4556 33.4668L66.5932 33.7482C66.3403 33.8306 66.0671 33.8267 65.8167 33.7369C65.5662 33.6472 65.3527 33.4768 65.2097 33.2525C64.9902 32.907 64.6963 32.615 64.3494 32.3978C64.0025 32.1806 63.6114 32.0438 63.2048 31.9973C62.7982 31.9508 62.3863 31.9958 61.9993 32.129C61.6123 32.2622 61.26 32.4803 60.9682 32.7673Z"
            fill="#B6D2C6"
        />
        <Path
            d="M73.7275 51.2054C73.0611 49.87 72.1904 48.6466 71.1469 47.5796C70.7938 47.2229 70.3434 46.9782 69.852 46.8763C69.3606 46.7744 68.85 46.8196 68.3842 47.0064L67.0854 47.5233C66.7038 47.6751 66.2857 47.7102 65.8841 47.6242C65.4825 47.5382 65.1155 47.335 64.8295 47.0403L64.0651 46.252C63.6136 45.7894 63.0044 45.5138 62.3589 45.4802C61.7133 45.4466 61.0788 45.6575 60.5818 46.0708C57.9882 48.2098 56.2332 51.341 56.2332 51.341C54.4535 49.3224 53.7516 46.5954 53.4805 44.5265C51.9941 48.854 51.3785 54.1237 52.8083 60.2477H77.191C77.191 60.2477 79.8885 52.7654 76.418 43.7607C76.0262 47.8553 73.7275 51.2054 73.7275 51.2054Z"
            fill="#7B9791"
        />
        <Path
            d="M50.4458 93.0293C50.4458 93.0293 55.587 96.5911 56.6964 102.32C56.8629 103.167 57.2512 103.955 57.8217 104.602C58.3922 105.25 59.1243 105.735 59.9434 106.007C62.1991 106.758 65.6355 107.241 69.9974 105.853C70.8766 105.58 71.6611 105.064 72.2615 104.366C72.8618 103.668 73.254 102.815 73.393 101.905C73.6095 100.29 74.2003 98.7478 75.1184 97.4015C76.0364 96.0552 77.2564 94.942 78.6809 94.1506C78.6809 94.1506 64.1046 88.8501 50.4458 93.0293Z"
            fill="#7B9791"
        />
        <Path
            d="M39.6097 38.9853C39.8532 39.6956 40.1463 40.4708 40.4972 41.2891C42.1243 46.5187 40.2164 53.5516 38.0273 60.7276C35.1943 58.7431 32.8964 56.2918 31.547 53.0134C31.3752 48.7419 32.467 43.8483 35.8648 38.5258C36.0781 38.1947 36.3805 37.9305 36.7372 37.7634C37.0938 37.5963 37.4904 37.5331 37.8813 37.581C38.2723 37.629 38.6418 37.7862 38.9475 38.0346C39.2532 38.2829 39.4827 38.6124 39.6097 38.9853Z"
            fill="#B6D2C6"
        />
        <Path
            d="M37.7582 52.6279C37.673 53.2274 37.4132 53.7885 37.0113 54.2414C36.6093 54.6943 36.083 55.0189 35.4979 55.1747C34.9127 55.3304 34.2947 55.3106 33.7208 55.1175C33.1469 54.9245 32.6424 54.5668 32.2704 54.0891C32.0066 53.747 31.7658 53.3879 31.5496 53.014C31.9768 63.6084 40.167 70.3785 40.167 70.3785L44.5045 48.0845C42.8772 46.0043 41.5303 43.7193 40.4985 41.2881C39.2176 44.9697 38.2998 48.7677 37.7582 52.6279Z"
            fill="#7B9791"
        />
        <Path
            d="M80.118 44.4429C79.0497 47.8309 77.6212 51.0945 75.8568 54.1778L77.8156 68.2062C83.837 67.5322 89.4831 66.2052 90.4415 56.7124C89.9252 51.9257 88.145 47.3623 85.2836 43.4904C84.9597 43.0475 84.5175 42.705 84.0077 42.5023C83.4979 42.2995 82.9412 42.2447 82.4016 42.3442C81.8621 42.4437 81.3616 42.6934 80.9576 43.0647C80.5536 43.4359 80.2626 43.9136 80.118 44.4429Z"
            fill="#B6D2C6"
        />
        <Path
            d="M49.8819 44.4429C50.9974 47.9753 52.5021 51.3727 54.3682 54.5726L54.3217 65.3631L41.107 64.3376L39.8352 54.8348C40.5602 50.7259 42.2313 46.842 44.7163 43.4904C45.0402 43.0475 45.4824 42.705 45.9922 42.5023C46.502 42.2995 47.0587 42.2447 47.5983 42.3442C48.1378 42.4437 48.6383 42.6934 49.0423 43.0647C49.4463 43.4359 49.7373 43.9136 49.8819 44.4429Z"
            fill="#B6D2C6"
        />
        <Path
            d="M40.9244 70.3776H54.9861C57.4565 66.1564 57.0309 61.6124 56.493 59.1052C56.2568 58.0289 55.8388 57.0008 55.2569 56.0651C54.946 55.5599 54.6521 55.0639 54.3697 54.5723C53.31 56.6214 52.2347 59.3 51.999 62.054C51.999 62.054 49.5594 58.0907 45.0831 59.3557C45.0831 59.3557 43.1854 60.0371 42.2608 61.8373C42.2608 61.8373 39.8469 59.2142 39.8369 54.8355C38.9356 60.0279 39.3088 65.3614 40.9244 70.3776Z"
            fill="#7B9791"
        />
        <Path
            d="M88.1723 61.8375C87.2477 60.0373 85.3501 59.356 85.3501 59.356C80.8737 58.091 78.4341 62.0543 78.4341 62.0543C78.183 59.1198 76.9786 56.2707 75.8554 54.1777C75.5066 54.7955 75.1378 55.4241 74.743 56.0654C74.1612 57.0011 73.7431 58.0292 73.507 59.1054C72.969 61.6127 72.5434 66.1561 75.0139 70.3779H89.0755C90.4977 65.972 90.963 61.3133 90.4404 56.7131C90.1555 58.5932 89.3723 60.3626 88.1723 61.8375Z"
            fill="#7B9791"
        />
        <Path
            d="M66.9283 73.3485C56.5786 73.9029 55.4343 67.1674 54.9978 59.871C56.1048 55.6349 58.5068 52.3197 60.9992 49.8628C61.1718 49.6923 61.3763 49.5575 61.6011 49.4663C61.8259 49.3751 62.0665 49.3291 62.3091 49.3311C62.5517 49.3331 62.7915 49.383 63.0147 49.4779C63.238 49.5728 63.4403 49.7108 63.6101 49.8841L64.2054 50.4919C64.5344 50.8279 64.9692 51.0403 65.4365 51.0933C65.9039 51.1463 66.3752 51.0366 66.7711 50.7828C67.3354 50.4201 68.0106 50.271 68.6751 50.3622C69.3396 50.4534 69.9496 50.7789 70.3954 51.2801C72.6096 53.7576 74.2682 56.6801 75.2597 59.8515C76.0401 66.4482 74.6225 71.7477 66.9283 73.3485Z"
            fill="#B6D2C6"
        />
        <Path
            d="M29.7694 63.414C29.7524 62.951 29.8572 62.4916 30.0733 62.0818C30.2894 61.672 30.6092 61.3261 31.0008 61.0785C31.3925 60.831 31.8422 60.6906 32.3051 60.6713C32.768 60.6519 33.2279 60.7544 33.6388 60.9684C35.8327 62.1063 37.8731 63.5183 39.711 65.1706C42.7911 69.0447 43.4411 73.9204 42.5157 79.4455C37.8586 80.8764 34.2511 78.259 31.3166 73.0477C30.3722 69.9202 29.8518 66.68 29.7694 63.414Z"
            fill="#B6D2C6"
        />
        <Path
            d="M75.259 59.8516C75.4774 65.0739 73.1539 67.3179 71.7143 68.2019C71.439 68.3664 71.1333 68.4734 70.8155 68.5164C70.4977 68.5594 70.1745 68.5376 69.8654 68.4522C69.6559 68.3789 69.4328 68.3529 69.2121 68.3763C68.9914 68.3997 68.7787 68.4719 68.5893 68.5875L67.0115 69.6295C66.7094 69.8289 66.3412 69.9018 65.986 69.8325C65.6307 69.7631 65.3169 69.5572 65.112 69.2588L64.09 67.7706C63.8456 67.4117 63.4719 67.1611 63.047 67.0712C62.6222 66.9813 62.1791 67.0592 61.8103 67.2884C61.0714 67.7573 60.36 68.2681 59.6796 68.8183C54.8458 66.5153 54.9882 60.281 55.0028 59.8721C54.8808 60.3386 54.7863 60.8162 54.6811 61.3019C52.3581 72.0545 60.4495 76.2811 60.4495 76.2811L72.4095 77.5529C73.343 76.0249 78.1914 69.0589 75.259 59.8516Z"
            fill="#7B9791"
        />
        <Path
            d="M39.7105 65.1709C39.7127 65.1836 40.7198 70.5794 40.4633 75.7625C40.4463 76.073 40.3558 76.3751 40.1993 76.6437C40.0427 76.9124 39.8245 77.1399 39.5627 77.3077C39.3009 77.4755 39.0029 77.5786 38.6934 77.6086C38.3839 77.6386 38.0717 77.5946 37.7825 77.4802C35.3099 76.5294 33.0953 75.0108 31.3174 73.0469C35.9174 87.6208 48.9432 87.43 48.9432 87.43L45.6853 74.7408C44.6439 70.6684 42.2933 67.5264 39.7105 65.1709Z"
            fill="#7B9791"
        />
        <Path
            d="M43.6532 79.8033C43.2956 78.235 43.1746 76.6221 43.2941 75.018C43.5052 71.4522 43.5309 68.5509 43.4459 66.2006C43.3195 62.7046 47.4249 60.7561 50.0772 63.0371C52.8155 65.3551 55.3476 67.9062 57.645 70.6618C58.683 75.2561 56.965 78.9916 53.8947 82.3052C49.0647 84.1327 45.8325 82.9573 43.6532 79.8033Z"
            fill="#B6D2C6"
        />
        <Path
            d="M58.5268 87.1537L62.3602 78.5489C61.404 75.852 59.6906 73.1729 57.6452 70.6621C55.0407 73.3526 53.0493 76.5748 51.8076 80.1075C51.6223 80.6319 51.2643 81.0777 50.7923 81.3718C50.3202 81.6658 49.7622 81.7906 49.2099 81.7257C47.2406 81.4962 45.3451 80.8401 43.6553 79.8031C46.0551 89.6238 58.5268 87.1537 58.5268 87.1537Z"
            fill="#7B9791"
        />
        <Path
            d="M43.2576 83.8819C40.264 82.9788 37.4415 81.5841 34.9056 79.7549C34.6324 79.5541 34.3015 79.4473 33.9625 79.4503C33.6235 79.4533 33.2946 79.5659 33.025 79.7715C32.7553 79.977 32.5595 80.2643 32.4668 80.5904C32.3741 80.9165 32.3895 81.2639 32.5106 81.5805C34.4356 86.4809 38.6173 93.163 47.3221 94.5871C54.3682 87.0164 43.2576 83.8819 43.2576 83.8819Z"
            fill="#B6D2C6"
        />
        <Path
            d="M100.232 63.4134C100.249 62.9505 100.144 62.4912 99.9279 62.0815C99.7117 61.6717 99.3919 61.3259 99.0003 61.0784C98.6087 60.8309 98.159 60.6905 97.6961 60.6712C97.2333 60.6519 96.7735 60.7544 96.3626 60.9684C92.2829 63.1055 86.1871 67.4227 84.3146 74.7408L81.0568 87.4314C81.0568 87.4314 99.3174 87.6985 100.232 63.4134Z"
            fill="#7B9791"
        />
        <Path
            d="M86.2071 80.3234C86.6776 78.5969 86.8464 76.802 86.7058 75.018C86.4947 71.4522 86.469 68.5509 86.554 66.2006C86.6803 62.7046 82.575 60.7561 79.9227 63.0371C77.235 65.314 74.7448 67.814 72.4785 70.5105C73.3907 75.189 74.9157 79.3939 77.6151 82.6924C81.4748 82.9698 84.4434 82.2922 86.2071 80.3234Z"
            fill="#B6D2C6"
        />
        <Path
            d="M78.1577 79.8554C76.9466 76.3745 75.0113 73.1899 72.4794 70.5117C70.3793 73.0658 68.6154 75.7979 67.6399 78.5482L71.4733 87.1529C71.4733 87.1529 83.4991 89.5341 86.205 80.3224C84.4361 80.9856 82.5816 81.3928 80.6977 81.5317C80.1478 81.5769 79.5986 81.4362 79.1381 81.1323C78.6776 80.8284 78.3324 80.3788 78.1577 79.8554Z"
            fill="#7B9791"
        />
        <Path
            d="M71.0255 93.6472L73.4608 84.9343C83.8641 86.1674 91.0515 82.6825 95.093 79.7539C95.3662 79.5531 95.6971 79.4463 96.0361 79.4493C96.3751 79.4523 96.704 79.565 96.9736 79.7705C97.2433 79.976 97.4391 80.2633 97.5318 80.5894C97.6245 80.9155 97.6091 81.2629 97.488 81.5795C94.8511 88.2974 87.9746 98.3584 71.0255 93.6472Z"
            fill="#7B9791"
        />
        <Path
            d="M73.4621 84.9355L71.0269 93.6485C73.1582 94.2576 75.3465 94.6457 77.5572 94.8069C79.4481 91.7279 79.6232 88.0543 79.3239 85.1493C77.3674 85.2415 75.4067 85.17 73.4621 84.9355Z"
            fill="#7B9791"
        />
        <Path
            d="M43.2581 83.8809C43.2581 83.8809 50.2785 86.3535 47.3234 94.5851C50.5485 95.113 54.393 94.9206 58.9754 93.6469L56.5402 84.934C51.298 85.5561 46.875 84.9797 43.2581 83.8809Z"
            fill="#7B9791"
        />
        <Path
            d="M63.986 72.5691L64.9999 73.6133C65.131 73.7482 65.2877 73.8558 65.4607 73.9297C65.6338 74.0036 65.8198 74.0424 66.008 74.0437C66.1962 74.0451 66.3828 74.009 66.5569 73.9376C66.731 73.8662 66.8892 73.7609 67.0223 73.6279C67.4181 73.233 67.9501 73.0046 68.509 72.9895C69.0679 72.9744 69.6114 73.1738 70.028 73.5467C71.9769 75.2754 73.5329 77.4014 74.5913 79.7818C75.6497 82.1622 76.186 84.7417 76.1641 87.3467C76.1641 97.671 65.0205 97.9169 65.0205 97.9169C65.0205 97.9169 55.5155 98.982 54.0408 88.0843C52.9335 79.9028 58.387 74.5341 61.1748 72.3656C61.5864 72.0477 62.1007 71.8928 62.6193 71.9303C63.1379 71.9679 63.6245 72.1953 63.986 72.5691Z"
            fill="#B6D2C6"
        />
        <Path
            d="M57.655 86.0708C57.6959 86.3127 57.791 86.5422 57.9331 86.7422C58.0751 86.9422 58.2606 87.1075 58.4755 87.2258C58.6904 87.3441 58.9293 87.4123 59.1743 87.4253C59.4193 87.4383 59.6641 87.3958 59.8903 87.3009C61.4836 86.6244 63.5947 85.078 63.8236 81.6147C63.918 80.1882 63.3617 78.8986 62.7713 77.9556C62.6343 77.7351 62.4469 77.5502 62.2245 77.4162C62.0022 77.2822 61.7512 77.2028 61.4922 77.1847C61.2332 77.1665 60.9737 77.2101 60.7348 77.3118C60.4959 77.4135 60.2846 77.5704 60.1182 77.7697C58.7744 79.3705 57.0286 82.2715 57.655 86.0708Z"
            fill="#DCEAE4"
        />
        <Path
            d="M61.1288 53.7161C61.3034 53.535 61.5217 53.4018 61.7627 53.3295C62.0037 53.2571 62.2593 53.248 62.5048 53.3031C62.7503 53.3581 62.9775 53.4755 63.1646 53.6438C63.3516 53.8121 63.4922 54.0257 63.5727 54.2641C63.8896 55.1935 64.0262 56.4997 63.4775 58.1789C62.7702 60.3436 60.9786 60.759 59.5975 60.7252C59.3695 60.7176 59.1465 60.6567 58.9463 60.5474C58.746 60.4381 58.5741 60.2835 58.4444 60.0958C58.3147 59.9082 58.2306 59.6928 58.1991 59.4669C58.1676 59.241 58.1893 59.0108 58.2627 58.7948C58.7362 57.3653 59.6251 55.2855 61.1288 53.7161Z"
            fill="#DCEAE4"
        />
        <Path
            d="M60.7987 36.852C60.9571 36.6614 61.1635 36.5166 61.3965 36.4325C61.6295 36.3483 61.8807 36.3278 62.1243 36.3731C62.3679 36.4184 62.595 36.5278 62.7821 36.6902C62.9693 36.8525 63.1098 37.0618 63.1891 37.2965C63.539 38.3916 63.6575 39.8254 62.7321 41.1824C61.9945 42.2207 60.9148 42.966 59.6825 43.2876C59.4258 43.3601 59.1537 43.3574 58.8985 43.2798C58.6432 43.2021 58.4157 43.0528 58.2428 42.8496C58.07 42.6465 57.9591 42.3979 57.9234 42.1335C57.8877 41.8692 57.9287 41.6001 58.0414 41.3583C58.7639 39.7444 59.6906 38.2299 60.7987 36.852Z"
            fill="#DCEAE4"
        />
        <Path
            d="M49.2648 70.9766C48.7862 71.8377 48.2326 72.655 47.6105 73.4189C47.4922 73.5637 47.3307 73.6669 47.1496 73.7134C46.9685 73.7599 46.7773 73.7472 46.6039 73.6772C46.4305 73.6072 46.284 73.4836 46.1859 73.3244C46.0878 73.1652 46.0432 72.9788 46.0586 72.7925C46.2297 70.8894 46.3834 68.285 46.1398 66.7389C46.1008 66.4966 46.1376 66.2481 46.2453 66.0275C46.3529 65.8069 46.5261 65.625 46.7411 65.5066L46.7662 65.4931C46.9985 65.3766 47.2624 65.3389 47.518 65.3859C47.7736 65.4329 48.0069 65.5619 48.1825 65.7534C48.6675 66.2702 49.0717 66.8573 49.3812 67.4949C49.6439 68.0417 49.7705 68.6438 49.7502 69.25C49.73 69.8563 49.5634 70.4486 49.2648 70.9766Z"
            fill="#DCEAE4"
        />
        <Path
            d="M46.6319 46.9896C46.8282 48.145 46.8434 49.3239 46.6771 50.484C46.4927 51.5292 45.9038 52.4596 45.0381 53.0735C44.6185 53.3772 44.22 53.7089 43.8454 54.0666C43.7298 54.1782 43.5827 54.2516 43.4241 54.2769C43.2655 54.3023 43.1029 54.2783 42.9583 54.2083C42.8137 54.1383 42.6941 54.0256 42.6156 53.8854C42.5372 53.7452 42.5036 53.5844 42.5195 53.4245C42.7143 51.4546 43.3494 48.6648 45.2732 46.5848C45.3729 46.4752 45.5014 46.3958 45.6441 46.356C45.7868 46.3163 45.9379 46.3176 46.0799 46.3599C46.2219 46.4022 46.349 46.4838 46.4467 46.5952C46.5443 46.7066 46.6085 46.8433 46.6319 46.9896Z"
            fill="#DCEAE4"
        />
    </Svg>
);

export default Artichoke;
