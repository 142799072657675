import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import { useEnsureContext } from "@madmedical/utils";
import type { InsulinAdministration } from "./insulinAdministration";

interface InsulinAdministrationContext {
    administrations: InsulinAdministration[];
}

const InsulinAdministrationContext = createContext<
    InsulinAdministrationContext | undefined
>(undefined);

export const InsulinBodyProvider =
    InsulinAdministrationContext.Provider as ReactProvider<InsulinAdministrationContext>;

export const useInsulinBodyChart = () =>
    useEnsureContext(InsulinAdministrationContext);
