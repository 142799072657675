import { AlertLevel, MetricType } from "@madmedical/medical";
import { HStack, Text, VStack } from "native-base";
import { formatDate } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../../atoms/PressableLink";
import {
    thumbnailRoot,
    thumbnailUnit,
    thumbnailValue,
    thumbnailValueSlot,
} from "../useStyles";
import RangeChart from "./RangeChart";

interface Props {
    value: [string, string];
    unit: [string, string];
    alertLevel: AlertLevel | null;
    measuredAt: Date;
}

const SleepTimeWidget = ({ alertLevel, measuredAt, value, unit }: Props) => {
    const getIndex = () => {
        switch (alertLevel) {
            case AlertLevel.Alert:
                return 0;
            case AlertLevel.Warning:
                return 1;
            case AlertLevel.Ok:
                return 2;
            default:
                return 0;
        }
    };
    const { t } = useTranslation();

    return (
        <VStack style={thumbnailRoot}>
            <PressableLink
                route="measurements"
                params={{ metricType: MetricType.SleepTime }}
                flex={1}
            >
                <Text fontSize="sm" fontWeight="medium" color="black">
                    {t("measures:sleep")[0].toUpperCase() +
                        t("measures:sleep").slice(1)}
                </Text>
                <HStack style={thumbnailValueSlot} space={1}>
                    <Text numberOfLines={1} style={thumbnailValue}>
                        {value[0]}
                    </Text>
                    <Text style={thumbnailUnit}>{unit[0]}</Text>
                    <Text numberOfLines={1} style={thumbnailValue}>
                        {value[1]}
                    </Text>
                    <Text style={thumbnailUnit}>{unit[1]}</Text>
                </HStack>

                <VStack flex={1} justifyContent="flex-end" mb={2} minH="10">
                    {!!alertLevel && (
                        <RangeChart
                            length={3}
                            selectedIndex={getIndex()}
                            alertLevel={alertLevel}
                        />
                    )}
                </VStack>

                <Text
                    fontSize="xs"
                    color="gray.600"
                    textAlign="right"
                    textTransform="capitalize"
                >
                    {formatDate(measuredAt)}
                </Text>
            </PressableLink>
        </VStack>
    );
};

export default SleepTimeWidget;
