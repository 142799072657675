import space from "../base/space";
import typography from "../base/typography";

const sizes = {
    "2xs": {
        width: 4,
        height: 4,
        _text: {
            fontSize: typography.fontSizes["2xs"],
            fontWeight: typography.fontWeights.medium,
        },
        _badgeSize: 1,
        mr: 2,
        style: {
            borderRadius: space["1.5"],
        },
    },
    xs: {
        width: 5,
        height: 5,
        _text: {
            fontSize: typography.fontSizes["2xs"],
            fontWeight: typography.fontWeights.medium,
        },
        _badgeSize: 1,
        mr: 2,
        style: {
            borderRadius: space["1.5"],
        },
    },
    sm: {
        width: 6,
        height: 6,
        _text: {
            fontSize: typography.fontSizes["2xs"],
            fontWeight: typography.fontWeights.medium,
        },
        _badgeSize: 1,
        mr: 2,
        style: {
            borderRadius: space["1.5"],
        },
    },
    md: {
        width: 7,
        height: 7,
        _text: {
            fontSize: typography.fontSizes["xs"],
            fontWeight: typography.fontWeights.medium,
        },
        _badgeSize: 1.5,
        mr: 2,
        style: {
            borderRadius: space["2"],
        },
    },
    lg: {
        width: 8,
        height: 8,
        _text: {
            fontSize: typography.fontSizes["xs"],
            fontWeight: typography.fontWeights.medium,
            color: "text.50",
        },
        _badgeSize: 2,
        mr: 3,
        style: {
            borderRadius: space["2"],
        },
    },
    xl: {
        width: 10,
        height: 10,
        _text: {
            fontSize: typography.fontSizes["xs"],
            fontWeight: typography.fontWeights.medium,
            color: "text.50",
        },
        _badgeSize: 2.5,
        mr: 3,
        style: {
            borderRadius: space["2.5"],
        },
    },
    "2xl": {
        width: 12,
        height: 12,
        _text: {
            fontSize: typography.fontSizes["2xl"],
            fontWeight: typography.fontWeights.medium,
            color: "text.50",
        },
        _badgeSize: 3,
        mr: 3,
        style: {
            borderRadius: space["4"],
        },
    },
    "3xl": {
        width: 22,
        height: 22,
        _text: {
            fontSize: typography.fontSizes["2xl"],
            fontWeight: typography.fontWeights.medium,
            color: "text.50",
        },
        _badgeSize: 3,
        mr: 4,
        style: {
            borderRadius: space["4"],
        },
    },
};

const defaultProps = {
    size: "md",
};

export default {
    sizes,
    defaultProps,
};
