export interface FileToUpload {
    file: File;
    note: string | null;
    service?: DocumentService;
}

export enum DocumentService {
    Pregnancy = "pregnancy",
    Manager = "manager",
    PrePostOp = "pre_post_op",
    Basic = "basic",
}

export const DOCUMENT_SERVICE_FOR_HUMANS = {
    [DocumentService.Pregnancy]: "pregnantPackage",
    [DocumentService.Manager]: "managerPackage",
    [DocumentService.PrePostOp]: "surgeryPackage",
    [DocumentService.Basic]: "basicPackage",
};
