import type { CellProps, Column } from "react-table";
import { useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { formatDateTime } from "@madmedical/utils";
import { Box, HStack, Text } from "native-base";
import type { PregnancyWellBeing } from "@madmedical/medical";
import { PREGNANCY_SYMPTOMS_FOR_HUMANS } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import ReactTable from "../../molecules/ReactTable";
import ActionButton from "../../molecules/ActionButton";
import PregnantMoodPics from "../../molecules/Pregnancy/PregnantMoodPics";
import Tag from "../../atoms/Tag";
import { usePregnancy } from "../../providers";

export type MeasurementData = Omit<PregnancyWellBeing, "onDeletePress"> & {
    id: Ulid;
    editable?: boolean;
};

interface Data {
    manual?: boolean;
    deleted?: boolean;
    editable?: boolean;
}

interface ExtendedData extends PregnancyWellBeing, Data {}

interface Props {
    data: ExtendedData[];
    onEndReached?: () => unknown;
    isDoctor?: boolean;
}

const PregnancyFeelingTable = ({
    data,
    onEndReached,
    isDoctor = false,
}: Props) => {
    const { onEditPress, onDeletePress } = usePregnancy();
    const { t } = useTranslation();
    const columns = useMemo<Column<ExtendedData>[]>(
        () => [
            {
                Header: t("appointment"),
                accessor: "createdAt",
                width: "120px",
                minWidth: 120,
                Cell: ({ value }) =>
                    value ? <Box>{formatDateTime(value)}</Box> : <Box>-</Box>,
            },
            {
                Header: t("feeling"),
                accessor: "status",
                width: "60px",
                maxWidth: 60,
                Cell: ({ value }) => (
                    <Box w={"64px"} h={"64px"}>
                        <PregnantMoodPics status={value} />
                    </Box>
                ),
            },
            {
                Header: t("symptoms"),
                accessor: "symptoms",
                width: "25%",
                maxWidth: 120,
                Cell: ({ value }) => (
                    <HStack space={1} flexWrap={"wrap"} width={"full"}>
                        {value?.map((symptom) => (
                            <Tag key={symptom} my={1}>
                                {t(
                                    `pregnancy:${PREGNANCY_SYMPTOMS_FOR_HUMANS[symptom]}`
                                )}
                            </Tag>
                        ))}
                    </HStack>
                ),
            },
            {
                Header: "Bejegyzés",
                accessor: "comment",
                Cell: ({ value }) =>
                    value ? (
                        <Text isTruncated noOfLines={6} maxWidth="95%">
                            {value}
                        </Text>
                    ) : (
                        <Box>-</Box>
                    ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({
                    row: { original: measurement },
                }: CellProps<ExtendedData>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            size="xs"
                            actions={
                                measurement?.editable && !isDoctor
                                    ? [
                                          {
                                              text: t("edit"),
                                              iconName: "edit",
                                              onPress: () =>
                                                  onEditPress?.(
                                                      measurement as PregnancyWellBeing
                                                  ),
                                          },
                                          {
                                              text: t("delete"),
                                              iconName: "trashCan",
                                              onPress: () => {
                                                  onDeletePress?.(
                                                      measurement.id
                                                  );
                                              },
                                          },
                                      ]
                                    : []
                            }
                        />
                    </Box>
                ),
            },
        ],
        [onDeletePress, onEditPress, isDoctor, t]
    );

    return (
        <ReactTable columns={columns} data={data} onEndReached={onEndReached} />
    );
};

export default PregnancyFeelingTable;
