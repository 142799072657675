import { Box, Divider, Stack, Text } from "native-base";
import type { ComponentProps, PropsWithChildren } from "react";
import useResponsive from "../util/useResponsive";

interface Props extends ComponentProps<typeof Box> {
    title: string;
}

const SimpleCard = ({
    children,
    title,
    ...boxProps
}: PropsWithChildren<Props>) => {
    const { isSmallScreen } = useResponsive();

    return isSmallScreen ? (
        <Box>
            <Text
                fontSize="xs"
                textTransform="uppercase"
                color="gray.800"
                fontWeight="semibold"
                mb={3}
            >
                {title}
            </Text>
            <Box
                p={4}
                borderRadius={"lg"}
                borderColor="gray.100"
                borderWidth={1}
                borderStyle="solid"
                bgColor="white"
                {...boxProps}
            >
                {children}
            </Box>
        </Box>
    ) : (
        <Stack
            divider={<Divider />}
            borderRadius={"lg"}
            borderColor="gray.100"
            borderWidth={1}
            borderStyle="solid"
            bgColor="white"
            flex={1}
        >
            <Text
                fontSize="xs"
                textTransform="uppercase"
                p={6}
                color="black"
                fontWeight="bold"
            >
                {title}
            </Text>
            <Box p={6} {...boxProps}>
                {children}
            </Box>
        </Stack>
    );
};

export default SimpleCard;
