import { useCallback, useEffect, useMemo, useState } from "react";
import type { EnumForHumans, Ulid } from "@madmedical/utils";
import { useDebounce } from "@madmedical/utils";
import type { Gender, Service, UnderlyingCondition } from "@madmedical/medical";
import {
    getGenderChoices,
    getServiceChoices,
    getUnderlyingConditionChoices,
} from "@madmedical/medical";
import type {
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "@madmedical/ui";
import adaptPatient from "./adaptPatient";
import { useGetPatientsQuery } from "./api";
import type { Patient, PatientFilter } from "./model";

interface DeviceTypeChoice {
    id: Ulid;
    manufacturer: EnumForHumans<string>;
    name: string;
}

interface Arg {
    deviceTypes?: DeviceTypeChoice[];
}

export default <TPatient extends Patient>(arg?: Arg) => {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<PatientFilter>({});
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300, () => {
        setPage(1);
    });
    const { data, isLoading } = useGetPatientsQuery({ ...filter, page });

    const filterChoices = useMemo(
        () => ({
            services: getServiceChoices(filter.services),
            genders: getGenderChoices(filter.gender),
            underlyingConditions: getUnderlyingConditionChoices(
                filter.underlyingConditions
            ),
            deviceTypes:
                arg?.deviceTypes?.map(({ id, name, manufacturer }) => ({
                    key: id,
                    text: `${manufacturer.forHumans} ${name}`,
                    isSelected: !!filter.deviceTypeIds?.includes(id),
                })) ?? [],
        }),
        [filter, arg?.deviceTypes]
    );

    const handleFilterChange = useCallback<
        OnFilterChange<typeof filterChoices>
    >((key, selected) => {
        setPage(1);

        // TODO: Why does type inference fail me?
        const diff =
            key === "services"
                ? { services: selected as Service[] }
                : key === "genders"
                ? { gender: selected[0] as Gender }
                : key === "underlyingConditions"
                ? { underlyingConditions: selected as UnderlyingCondition[] }
                : key === "deviceTypes"
                ? { deviceTypeIds: selected as Ulid[] }
                : {};

        setFilter((prevFilter) => ({
            ...prevFilter,
            ...diff,
        }));
    }, []);

    const handleSearchChange: OnSearchChange = (search) => {
        setSearch(search);
    };

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            search: debouncedSearch,
        }));
    }, [debouncedSearch]);

    const handleSortChange = useCallback<OnSortChange>((sort, order) => {
        setPage(1);

        if (!sort) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                sort: undefined,
                order: undefined,
            }));

            return;
        }

        setFilter((prevFilter) => ({
            ...prevFilter,
            sort: sort as PatientFilter["sort"], // TODO: Better types
            order,
        }));
    }, []);

    const handleEndReached = useCallback(() => {
        if (!data) {
            return;
        }

        const { currentPage, pageCount } = data.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [data]);

    return {
        isLoading,
        patients: (data?.items as TPatient[] | undefined)?.map(
            adaptPatient<TPatient>
        ),
        pagination: data?.pagination,
        handleSortChange,
        handleSearchChange,
        filterChoices,
        handleFilterChange,
        handleEndReached,
    };
};
