import { Fragment } from "react";
import { Circle } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import Thresholds from "./Thresholds";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

export default function GraphDot({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const {
        coords = [],
        randomIdFragment,
        dashboardThumbnail: { isDashboardThumbnail = false } = {},
    } = graphData;

    return (
        <>
            {!isDashboardThumbnail && <Thresholds graphData={graphData} />}

            {coords.length > 0 &&
                coords.map(({ x, yHigh: y }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <Fragment key={id}>
                            {y &&
                                (isWeb ? (
                                    <Circle
                                        fill={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        cx={x}
                                        cy={y}
                                        r="3px"
                                    />
                                ) : (
                                    <Circle
                                        scaleY={-1}
                                        fill={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        cx={x}
                                        cy={y}
                                        r="3px"
                                    />
                                ))}
                        </Fragment>
                    );
                })}
        </>
    );
}
