import { Divider, VStack } from "native-base";
import { formatDateTime } from "@madmedical/utils";
import {
    ProviderConnectionStatus,
    logisticsForHumans,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import type { Device } from "../providers/device";
import Card from "../atoms/Card/Card";
import AvatarTexts from "../molecules/AvatarTexts";
import HorizontalListItem from "../molecules/ListItem/HorizontalListItem";
import useResponsive from "../util/useResponsive";
import getImage from "./DeviceItem/getImage";

interface Props {
    device: Device;
}

const DeviceCard = ({ device }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return isSmallScreen ? (
        <AvatarTexts
            source={getImage(device.provider)}
            label={device.deviceName}
            subText={t(`backend:${device.manufacturer}`)}
            status={
                device.status === ProviderConnectionStatus.Off
                    ? undefined
                    : device.status
            }
            isCircle={false}
            size="2xl"
        />
    ) : (
        <Card
            header={
                <AvatarTexts
                    source={getImage(device.provider)}
                    label={device.deviceName}
                    subText={t(`backend:${device.manufacturer}`)}
                    status={
                        device.status === ProviderConnectionStatus.Off
                            ? undefined
                            : device.status
                    }
                    isCircle={false}
                    size="2xl"
                />
            }
            maxWidth="full"
        >
            <VStack mt={6} space={5} divider={<Divider bg="gray.100" />}>
                {device.ownership && (
                    <HorizontalListItem
                        p={0}
                        label={t("type")}
                        content={logisticsForHumans(device.ownership)}
                    />
                )}
                {device.lastRecordedAt && (
                    <HorizontalListItem
                        p={0}
                        label={t("lastConnection")}
                        content={formatDateTime(device.lastRecordedAt)}
                    />
                )}
            </VStack>
        </Card>
    );
};

export default DeviceCard;
