/**
 * If questions ever change, do leave possibly removed ones!
 */

export const preOpQuestions = {
    drugAllergy: "drugAllergy",
    takingMedicine: "takingMedicine",
    diseases: "diseases",
    heartDisease: "heartDisease",
    highBloodPressure: "highBloodPressure",
    arrhythmia: "arrhythmia",
    vascularIssues: "vascularIssues",
    asthma: "asthma",
    breathlessness: "breathlessness",
    stroke: "stroke",
    epilepsy: "epilepsy",
    diabetes: "diabetes",
    thyroid: "thyroid",
    bleedingDisorder: "bleedingDisorder",
    hepatitis: "hepatitis",
    acidReflux: "acidReflux",
    muscleWeakness: "muscleWeakness",
    sleepDisorder: "sleepDisorder",
    eyeDisease: "eyeDisease",
    otherDisease: "otherDisease",
    howManyFloors: "howManyFloors",
    legCramps: "legCramps",
    smoking: "smoking",
    alcohol: "alcohol",
    drugs: "drugs",
    dentures: "dentures",
    looseTooth: "looseTooth",
    pacemaker: "pacemaker",
    contactLenses: "contactLenses",
    jaundice: "jaundice",
    jointProsthesis: "jointProsthesis",
    otherProsthesis: "otherProsthesis",
    piercing: "piercing",
    receivedBlood: "receivedBlood",
    bloodComplications: "bloodComplications",
    hadSurgery: "hadSurgery",
    anesthesia: "anesthesia",
    anesthesiaComplications: "anesthesiaComplications",
    cold: "cold",
    feverInfection: "feverInfection",
    antibiotics: "antibiotics",
    pregnant: "pregnant",
    covidVaccine: "covidVaccine",
    hadCovid: "hadCovid",
} as const;

export type PreOpQuestionKey = keyof typeof preOpQuestions;

export const getQuestion = (key: PreOpQuestionKey) => preOpQuestions[key];
