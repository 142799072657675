import { skipToken } from "@madmedical/store";
import { CallCenterDeviceModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import type { PatientForCallCenter } from "@madmedical/user";
import {
    adaptPatient,
    useGetInstitutionsQuery,
    useGetPatientsQuery,
} from "@madmedical/user";
import { DeviceOperation } from "@madmedical/medical";
import { useGetLogisticsDeviceQuery, useUpdateDeviceMutation } from "./api";
import resolveValidOperations from "./resolveValidOperations";
import type { UpdateDeviceRequest } from "./model";

interface Props {
    id: string;
    open: boolean;
    onClose: () => void;
}

const UpdateDeviceForm = ({ id, open, onClose }: Props) => {
    const { data: device } = useGetLogisticsDeviceQuery(open ? id : skipToken);
    const [updateDevice, { isLoading: isSubmitting }] =
        useUpdateDeviceMutation();
    const { data: patientData } = useGetPatientsQuery({ pageSize: 5000 }); // TODO: Autocomplete
    const { data: institutions } = useGetInstitutionsQuery();

    if (!device) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ApiFormWrapper
                defaultValues={device}
                mutation={(value: UpdateDeviceRequest) =>
                    updateDevice({
                        ...value,
                        serialNumber: `${value.serialNumber}\u200B`,
                    })
                }
            >
                {({ submitForm, isSuccess }) => (
                    <CallCenterDeviceModalForm
                        operations={
                            device
                                ? resolveValidOperations(device.status)
                                : [DeviceOperation.Update]
                        }
                        patients={(
                            patientData?.items as
                                | PatientForCallCenter[]
                                | undefined
                        )?.map(adaptPatient)}
                        institutions={institutions}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                        isSubmitting={isSubmitting}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default UpdateDeviceForm;
