import type { CellProps, Column } from "react-table";
import type { ComponentProps, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { formatDateTime } from "@madmedical/utils";
import { Box, Text } from "native-base";
import type { InsulinType } from "@madmedical/medical";
import { Pressable } from "react-native";
import { useTranslation } from "@madmedical/i18n";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import { formatInsertPoint, formatType } from "../util/insulinFormatters";
import { InsulinQuantityListItem } from "../molecules/ListItem/InsulinQuantityListItem";
import type { InsulinAdministration } from "../providers/insulinAdministration";
import type InsulinMeasurementListItem from "../molecules/ListItem/InsulinMeasurementListItem";

export type MeasurementData = Omit<
    ComponentProps<typeof InsulinMeasurementListItem>,
    "onDeletePress"
> & {
    id: Ulid;
    editable?: boolean;
} & InsulinAdministration;

interface Data {
    id: Ulid;
    manual?: boolean;
    deleted?: boolean;
    editable?: boolean;
}

interface ExtendedData extends InsulinAdministration, Data {}

interface Props {
    data: ExtendedData[];
    readonly onEndReached?: () => unknown;
    onDeletePress?: (id: Ulid) => void | undefined;
    onEditPress?: (measurement: InsulinAdministration) => void | undefined;
    pressLink?: () => void;
}

const pressableStyle = {
    maxWidth: "100%",
};

const InsulinMeasurementsTable = ({
    data,
    onEndReached,
    onDeletePress,
    onEditPress,
    pressLink,
}: Props) => {
    const LinkWrapper = useCallback(
        ({ children }: { children: ReactNode }) =>
            pressLink ? (
                <Pressable onPress={pressLink} style={pressableStyle}>
                    {children}
                </Pressable>
            ) : (
                <>{children}</>
            ),
        [pressLink]
    );
    const { t } = useTranslation();
    const columns = useMemo<Column<ExtendedData>[]>(
        () => [
            {
                Header: t("appointment"),
                accessor: "administeredAt",
                width: "100px",
                minWidth: 90,
                Cell: ({ value }) =>
                    value ? (
                        <LinkWrapper>
                            <Text>{formatDateTime(value)}</Text>
                        </LinkWrapper>
                    ) : (
                        <LinkWrapper>
                            <Box>-</Box>
                        </LinkWrapper>
                    ),
            },
            {
                Header: t("units"),
                accessor: "units",
                width: "60px",
                Cell: ({ value, row }) =>
                    value && row.original?.product?.type ? (
                        <LinkWrapper>
                            <InsulinQuantityListItem
                                units={value}
                                type={row.original?.product?.type}
                            />
                        </LinkWrapper>
                    ) : (
                        <LinkWrapper>
                            <Box>-</Box>
                        </LinkWrapper>
                    ),
            },
            {
                Header: t("type"),
                accessor: (data) => data.product.type,
                width: "100px",
                minWidth: 25,
                Cell: ({ value }: { value: InsulinType }) =>
                    value ? (
                        <LinkWrapper>
                            <Text isTruncated>{t(`${formatType(value)}`)}</Text>
                        </LinkWrapper>
                    ) : (
                        <LinkWrapper>
                            <Box>-</Box>
                        </LinkWrapper>
                    ),
            },
            {
                Header: t("productName"),
                accessor: (data) => data.product.name,
                width: "200px",
                minWidth: 70,
                Cell: ({ value }: { value: string }) => (
                    <LinkWrapper>
                        <Text>{value}</Text>
                    </LinkWrapper>
                ),
            },
            {
                Header: t("injectionLocation"),
                accessor: "bodyLocation",
                width: "15%",
                minWidth: 70,
                Cell: ({ value }) => {
                    if (value) {
                        const { bodyPart, side } = value;
                        if (!bodyPart || !side)
                            return (
                                <LinkWrapper>
                                    <Box>-</Box>
                                </LinkWrapper>
                            );

                        return (
                            <LinkWrapper>
                                <Text>
                                    {t(formatInsertPoint(bodyPart)) +
                                        `(${t(side)})`}
                                </Text>
                            </LinkWrapper>
                        );
                    }

                    return (
                        <LinkWrapper>
                            <Box>-</Box>
                        </LinkWrapper>
                    );
                },
            },
            {
                Header: t("comment"),
                accessor: "comment",
                width: "20%",
                Cell: ({ value }) => (
                    <LinkWrapper>
                        <Text>{value}</Text>
                    </LinkWrapper>
                ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({
                    row: { original: measurement },
                }: CellProps<ExtendedData>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            size="xs"
                            actions={
                                measurement?.editable
                                    ? [
                                          {
                                              text: t("edit"),
                                              iconName: "edit",
                                              onPress: () => {
                                                  onEditPress?.(measurement);
                                              },
                                          },
                                          {
                                              text: t("delete"),
                                              iconName: "trashCan",
                                              onPress: () => {
                                                  onDeletePress?.(
                                                      measurement.id
                                                  );
                                              },
                                          },
                                      ]
                                    : []
                            }
                        />
                    </Box>
                ),
            },
        ],
        [onEditPress, onDeletePress, LinkWrapper, t]
    );

    return (
        <ReactTable columns={columns} data={data} onEndReached={onEndReached} />
    );
};

export default InsulinMeasurementsTable;
