import { Text } from "react-native";
import Tooltip from "./Tooltip";
import { colors, tooltipDragSelectText } from "./useStyles";
import type { TooltipType } from "./types";

export default function TooltipDragSelect({
    tooltip = {},
    transform,
}: {
    tooltip: TooltipType;
    transform?: [{ translateX: number }, { translateY: number }];
}) {
    return (
        <Tooltip
            tooltip={tooltip}
            styleArrow={{ borderTopColor: colors.green600 }}
            styleContentBox={{ backgroundColor: colors.green600 }}
            transform={transform}
        >
            <Text style={tooltipDragSelectText}>
                Jelölje ki a vizsgálni kívánt intervallumot!
            </Text>
        </Tooltip>
    );
}
