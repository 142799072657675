import type { ReactNode } from "react";
import { Box, Text } from "native-base";
import { isWeb } from "@madmedical/utils";
import { Pressable } from "react-native";
import type { AppRoutes } from "@madmedical/routing";
import Paper from "../../atoms/Paper";
import useResponsive from "../../util/useResponsive";
import { useRouteNavigate } from "../../providers/routing";

interface Props {
    image: ReactNode;
    text: ReactNode | string;
    link?: keyof AppRoutes;
}

const PregnancyDasboardBlock = ({ image, text, link }: Props) => {
    const { isSmallScreen } = useResponsive();
    const navigate = useRouteNavigate();

    return (
        <Paper
            bgColor={"#FFFFFF99"}
            p={4}
            alignItems="center"
            direction="column"
            width={!isWeb && isSmallScreen ? "49%" : "100%"}
            my={!isWeb && isSmallScreen ? "1%" : "0"}
        >
            <Pressable
                onPress={() => link && navigate(link, {})}
                disabled={!link}
            >
                <Box alignItems="center" flexDirection="column">
                    {image}
                    <Box
                        alignItems="center"
                        mt={3}
                        w="full"
                        textAlign={"center"}
                        justifyContent={"center"}
                        h={42}
                    >
                        {typeof text === "string" ? (
                            <Text textAlign={"center"}>{text}</Text>
                        ) : (
                            text
                        )}
                    </Box>
                </Box>
            </Pressable>
        </Paper>
    );
};

export default PregnancyDasboardBlock;
