import { useMemo, useState } from "react";
import type { ComponentProps } from "react";
import {
    Alert as BaseAlert,
    Box,
    Button,
    HStack,
    IconButton,
    PresenceTransition,
    Stack,
    Text,
    VStack,
    useTheme,
} from "native-base";
import Icon from "../icons/Icon";

interface Links {
    text: string;
    onPress: () => void;
}

export interface Props {
    status?: "success" | "error" | "warning" | "info";
    title: string;
    description?: string;
    iconName?: ComponentProps<typeof Icon>["name"];
    links?: Links[];
    linkSeparator?: JSX.Element;
    onDismiss?: () => void;
    showCloseButton?: boolean;
}

const Alert = ({
    status = "info",
    title,
    description = "",
    links = [],
    linkSeparator,
    iconName = "information",
    onDismiss,
    showCloseButton = true,
}: Props) => {
    const [show, setShow] = useState(true);
    const [visibleClosed, setVisibleClosed] = useState(false);
    const { colors } = useTheme();

    const statuses = useMemo(
        () => ({
            success: {
                iconColor: colors.green["500"],
                titleColor: "green.800",
                descriptionColor: "green.800",
                buttonColorScheme: "success",
                backgroundColor: colors.green["25"],
                borderColor: colors.green["200"],
            },
            error: {
                iconColor: colors.rose["500"],
                titleColor: "rose.800",
                descriptionColor: "rose.800",
                buttonColorScheme: "error",
                backgroundColor: colors.rose["25"],
                borderColor: colors.rose["200"],
            },
            warning: {
                iconColor: colors.yellow["600"],
                titleColor: "yellow.800",
                descriptionColor: "yellow.800",
                buttonColorScheme: "warning",
                backgroundColor: colors.yellow["25"],
                borderColor: colors.yellow["200"],
            },
            info: {
                iconColor: colors.gray["500"],
                titleColor: "gray.800",
                descriptionColor: "gray.800",
                buttonColorScheme: "info",
                backgroundColor: colors.gray["25"],
                borderColor: colors.gray["200"],
            },
        }),
        [colors]
    );

    if (visibleClosed) return null;
    const handleDismissPress = () => {
        setShow(false);
        onDismiss?.();
    };

    return show ? (
        <PresenceTransition
            visible={show}
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
                transition: {
                    duration: 250,
                },
            }}
            onTransitionComplete={() => {
                setTimeout(() => {
                    setVisibleClosed(!show);
                }, 250);
            }}
        >
            <BaseAlert
                status={status}
                accessibilityRole="alert"
                variant="solid"
                alignItems="flex-start"
                p={4}
                bgColor={statuses[status].backgroundColor}
                borderColor={statuses[status].borderColor}
                position="relative"
            >
                {showCloseButton && (
                    <IconButton
                        zIndex={99}
                        position="absolute"
                        p={2}
                        top={1}
                        right={1}
                        variant="unstyled"
                        _focus={{
                            borderWidth: 0,
                        }}
                        icon={<Icon name="close" size={20} fill="black" />}
                        onPress={handleDismissPress}
                    />
                )}

                <Stack
                    direction={{
                        sm: "column",
                        md: "row",
                    }}
                >
                    <Box
                        mr={3.5}
                        mb={{
                            sm: 3,
                            md: 0,
                        }}
                    >
                        <Icon
                            name={iconName}
                            size={20}
                            fill={statuses[status].iconColor}
                        />
                    </Box>

                    <VStack>
                        <Box>
                            <Text
                                mb={1}
                                fontSize="sm"
                                fontWeight="medium"
                                color={statuses[status].titleColor}
                            >
                                {title}
                            </Text>

                            {!!description && (
                                <Text
                                    fontSize="sm"
                                    fontWeight="normal"
                                    color={statuses[status].descriptionColor}
                                >
                                    {description}
                                </Text>
                            )}
                        </Box>

                        {links.length ? (
                            <HStack
                                alignItems="flex-start"
                                flexWrap="wrap"
                                space={1}
                                divider={linkSeparator}
                            >
                                {links.map(({ text, onPress }, index) => (
                                    <Button
                                        key={index}
                                        mt={2}
                                        mr={linkSeparator ? 0 : 3}
                                        p={0}
                                        size="xs"
                                        variant="link"
                                        colorScheme={
                                            statuses[status].buttonColorScheme
                                        }
                                        onPress={onPress}
                                        _hover={{
                                            _text: {
                                                color: statuses[status]
                                                    .titleColor,
                                            },
                                        }}
                                    >
                                        {text}
                                    </Button>
                                ))}
                            </HStack>
                        ) : null}
                    </VStack>
                </Stack>
            </BaseAlert>
        </PresenceTransition>
    ) : null;
};

export default Alert;
