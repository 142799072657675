import { StyleSheet, Text, View } from "react-native";
import { Path, Svg } from "react-native-svg";
import colors from "../theme/base/colors";

const ErrorPage = () => (
    <View style={styles.wrapper}>
        <View style={styles.textContent}>
            <Svg
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
                style={styles.icon}
            >
                <Path
                    d="M119.666 20.5261V111.998H96.1449L96.028 111.965L118.347 35.094L68.1777 20.5261H119.666Z"
                    fill="#F48190"
                />
                <Path
                    d="M118.347 35.0941L92.8441 122.937L5 97.4337L30.5034 9.5896L118.347 35.0941Z"
                    fill="#F8ABB5"
                />
                <Path
                    d="M54.2772 77.0704L58.9276 78.4805L57.5176 83.1308L52.8672 81.7208L54.2772 77.0704ZM59.9173 58.469L64.5677 59.879L59.9173 75.4375L55.267 74.0275L59.9173 58.469ZM65.7443 47.541C52.9093 43.6493 39.3573 50.9148 35.4656 63.7498C31.5739 76.5848 38.8091 90.153 51.6441 94.0447C64.5024 97.9434 78.0777 90.685 81.9693 77.85C85.861 65.015 78.6026 51.4397 65.7443 47.541ZM53.0774 89.4014C42.8001 86.2852 36.9998 75.4371 40.116 65.1598C43.2321 54.8825 54.0802 49.0823 64.3575 52.1984C74.6349 55.3146 80.4351 66.1627 77.3189 76.44C74.2028 86.7173 63.3547 92.5175 53.0774 89.4014Z"
                    fill="white"
                />
                <Path
                    d="M71.8197 2.87855C70.6187 1.67759 68.9904 1.002 67.292 1C65.5936 1.002 63.9653 1.67759 62.7643 2.87856C61.5633 4.07953 60.8877 5.70782 60.8857 7.40625L60.8857 27.9063C60.8857 30.2849 61.8307 32.5662 63.5126 34.2481C65.1946 35.9301 67.4758 36.875 69.8545 36.875C72.2332 36.875 74.5144 35.9301 76.1964 34.2481C77.8783 32.5661 78.8232 30.2849 78.8232 27.9063L78.8232 7.40625C78.8232 7.06644 78.6883 6.74055 78.448 6.50027C78.2077 6.25999 77.8818 6.125 77.542 6.125C77.2022 6.125 76.8763 6.25999 76.636 6.50027C76.3957 6.74055 76.2607 7.06644 76.2607 7.40625L76.2607 27.9063C76.2607 29.6053 75.5858 31.2348 74.3844 32.4362C73.183 33.6376 71.5535 34.3125 69.8545 34.3125C68.1554 34.3125 66.526 33.6376 65.3246 32.4362C64.1232 31.2348 63.4482 29.6053 63.4482 27.9063L63.4482 7.40625C63.4482 6.38683 63.8532 5.40915 64.574 4.68831C65.2949 3.96746 66.2726 3.5625 67.292 3.5625C68.3114 3.5625 69.2891 3.96746 70.0099 4.68831C70.7308 5.40915 71.1357 6.38683 71.1357 7.40625L71.1357 20.4375H73.6982L73.6982 7.40625C73.6962 5.70782 73.0207 4.07953 71.8197 2.87855Z"
                    fill="#768087"
                />
                <Path
                    d="M66.0107 12.5313L66.0107 19.9107L67.8545 20.4375H68.5732L68.5732 12.5313C68.5732 12.1914 68.4383 11.8656 68.198 11.6253C67.9577 11.385 67.6318 11.25 67.292 11.25C66.9522 11.25 66.6263 11.385 66.386 11.6253C66.1457 11.8656 66.0107 12.1914 66.0107 12.5313Z"
                    fill="#768087"
                />
            </Svg>
            <Text style={styles.title}>Hiba történt</Text>
            <Text style={styles.bodyText}>
                Úgy tűnik, hogy valami hiba történt.
            </Text>
            <Text style={styles.bodyText}>Kérjük, próbálja újra később.</Text>
        </View>
    </View>
);

const styles = StyleSheet.create({
    bodyText: {
        color: colors.black,
        fontFamily: "Inter",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: 24,
        textAlign: "center",
    },
    icon: {
        height: 123,
        marginBottom: 40,
        width: 123,
    },
    textContent: {
        alignItems: "center",
        flexDirection: "column",
        maxWidth: 400,
    },
    title: {
        color: colors.black,
        fontFamily: "Inter",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: "600",
        marginBottom: 16,
        textAlign: "center",
    },
    wrapper: {
        alignItems: "center",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        padding: 40,
    },
});

export default ErrorPage;
