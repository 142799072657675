import { Fragment, useMemo } from "react";
import {
    Circle,
    Defs,
    G,
    Line,
    LinearGradient,
    Polygon,
    Rect,
    Stop,
    Text,
} from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import { InsulinType } from "@madmedical/medical";
import Thresholds from "./Thresholds";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

function containsNumbers(str: string) {
    return /\d/.test(str);
}

export default function GraphLineSingleDot({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const yMin = graphData.limits?.yMin;

    const {
        coords = [],
        randomIdFragment = "",
        dashboardThumbnail: {
            isDashboardThumbnail = false,
            showLineGraphNodes = true,
        } = {},
        insulin,
    } = graphData;

    const thumbnailGradient = useMemo(() => {
        const limits: {
            start?: null | { x?: number; y?: number };
            end?: null | { x?: number; y?: number };
        } = { start: null, end: null };

        const middles =
            isDashboardThumbnail && showLineGraphNodes && coords.length
                ? coords
                      .map(({ x = 0, yHigh }) => {
                          limits.start =
                              yHigh && limits.start === null
                                  ? { x, y: yMin }
                                  : limits.start;

                          limits.end = yHigh ? { x, y: yMin } : limits.end;

                          return yHigh && `${x},${yHigh}`;
                      })
                      .join(" ")
                : "";

        const { start = {}, end = {} } = limits;

        return (
            `${start?.x ?? ""},${start?.y ?? ""} ` +
            middles +
            ` ${end?.x ?? ""},${end?.y ?? ""}` +
            ` ${start?.x ?? ""},${start?.y ?? ""}`
        );
    }, [coords, isDashboardThumbnail, showLineGraphNodes, yMin]);

    const hasThumbnailGradientData = containsNumbers(thumbnailGradient);

    return (
        <>
            <Defs>
                <LinearGradient
                    id={`${randomIdFragment}-bg-gradient`}
                    gradientTransform="rotate(90)"
                >
                    <Stop
                        offset="0%"
                        stopColor={`rgba(${colors.blue400Rgb}, 0)`}
                    />
                    <Stop
                        offset="50%"
                        stopColor={`rgba(${colors.blue400Rgb}, 0.2)`}
                    />
                </LinearGradient>
            </Defs>

            {!isDashboardThumbnail && <Thresholds graphData={graphData} />}

            {hasThumbnailGradientData &&
                isDashboardThumbnail &&
                showLineGraphNodes &&
                coords.length > 0 &&
                (isWeb ? (
                    <Polygon
                        fill={
                            isDashboardThumbnail
                                ? `url(#${randomIdFragment}-bg-gradient)`
                                : `rgba(${colors.blue400Rgb}, 0.2)`
                        }
                        points={thumbnailGradient}
                    />
                ) : (
                    <Polygon
                        scaleY={-1}
                        fill={
                            isDashboardThumbnail
                                ? `url(#${randomIdFragment}-bg-gradient)`
                                : `rgba(${colors.blue400Rgb}, 0.2)`
                        }
                        points={thumbnailGradient}
                    />
                ))}

            {coords.length > 0 &&
                coords.map(({ x = 0, yHigh }, i, coords) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <Fragment key={id}>
                            {i < coords.length - 1 &&
                                yHigh &&
                                coords[i + 1].yHigh && (
                                    <>
                                        {isWeb ? (
                                            <>
                                                {yMin ? (
                                                    <Polygon
                                                        fill={
                                                            isDashboardThumbnail
                                                                ? `url(#${randomIdFragment}-bg-gradient)`
                                                                : `rgba(${colors.blue400Rgb}, 0.2)`
                                                        }
                                                        id={`${id}-area`}
                                                        points={`${x},${yMin} ${x},${yHigh} ${
                                                            coords[i + 1].x ??
                                                            ""
                                                        },${
                                                            coords[i + 1]
                                                                .yHigh ?? ""
                                                        } ${
                                                            coords[i + 1].x ??
                                                            ""
                                                        },${yMin}`}
                                                    />
                                                ) : (
                                                    <></>
                                                )}

                                                <Line
                                                    fill="none"
                                                    stroke={colors.blue400}
                                                    strokeWidth={2}
                                                    id={`${id}-line`}
                                                    x1={x}
                                                    y1={yHigh}
                                                    x2={coords[i + 1].x}
                                                    y2={coords[i + 1].yHigh}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {yMin ? (
                                                    <Polygon
                                                        scaleY={-1}
                                                        fill={
                                                            isDashboardThumbnail
                                                                ? `url(#${randomIdFragment}-bg-gradient)`
                                                                : `rgba(${colors.blue400Rgb}, 0.2)`
                                                        }
                                                        id={`${id}-area`}
                                                        points={`${x},${yMin} ${x},${yHigh} ${
                                                            coords[i + 1].x ??
                                                            ""
                                                        },${
                                                            coords[i + 1]
                                                                .yHigh ?? ""
                                                        } ${
                                                            coords[i + 1].x ??
                                                            ""
                                                        },${yMin}`}
                                                    />
                                                ) : (
                                                    <></>
                                                )}

                                                <Line
                                                    scaleY={-1}
                                                    fill="none"
                                                    stroke={colors.blue400}
                                                    strokeWidth={2}
                                                    id={`${id}-line`}
                                                    x1={x}
                                                    y1={yHigh}
                                                    x2={coords[i + 1].x}
                                                    y2={coords[i + 1].yHigh}
                                                />
                                            </>
                                        )}
                                    </>
                                )}

                            {showLineGraphNodes &&
                                yHigh &&
                                (isWeb ? (
                                    <Circle
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        cx={x}
                                        cy={yHigh}
                                        r="3px"
                                    />
                                ) : (
                                    <Circle
                                        scaleY={-1}
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={id}
                                        cx={x}
                                        cy={yHigh}
                                        r="3px"
                                    />
                                ))}
                        </Fragment>
                    );
                })}

            {insulin &&
                insulin.length > 0 && // DOTS
                insulin.map(
                    (
                        { x, yHigh, tooltip: tipData, insulinType },
                        i,
                        insulin
                    ) => {
                        const id = coordIdFactory({
                            randomIdFragment,
                            coord: insulin[i],
                        });

                        if (tipData.isGrouped) {
                            return (
                                <Fragment key={`${id}-${i}`}>
                                    {x &&
                                        (isWeb ? (
                                            <G
                                                x={x - 14}
                                                y={yHigh + 14}
                                                scaleY={-1}
                                            >
                                                <Rect
                                                    x="0"
                                                    y="0"
                                                    width="28px"
                                                    height="14px"
                                                    fill="white"
                                                    rx="7px"
                                                />
                                                <Circle
                                                    cx="7px"
                                                    cy="7px"
                                                    r="5px"
                                                    fill={
                                                        insulinType ===
                                                        InsulinType.Rapid
                                                            ? colors.blue500
                                                            : insulinType ===
                                                              InsulinType.Other
                                                            ? colors.grey400
                                                            : colors.blue300
                                                    }
                                                    strokeWidth="2px"
                                                    stroke={
                                                        tooltip.onShow &&
                                                        tooltip.id === id
                                                            ? colors.grey700
                                                            : colors.transparent
                                                    }
                                                    id={id}
                                                />
                                                <Text
                                                    fontFamily="Inter"
                                                    fontSize="10px"
                                                    fontWeight="600"
                                                    fill={
                                                        tooltip.onShow &&
                                                        tooltip.id === id
                                                            ? colors.grey800
                                                            : colors.grey600
                                                    }
                                                    x="16"
                                                    y="11"
                                                >
                                                    {tipData?.group?.length}
                                                </Text>
                                            </G>
                                        ) : (
                                            <Fragment>
                                                <Rect
                                                    id={id}
                                                    x={x - 8}
                                                    y={yHigh - 6}
                                                    width="28px"
                                                    height="14px"
                                                    fill="white"
                                                    rx="7px"
                                                    scaleY={-1}
                                                />
                                                <Circle
                                                    id={id}
                                                    cx={x}
                                                    cy={yHigh}
                                                    r="5px"
                                                    scaleY={-1}
                                                    fill={
                                                        insulinType ===
                                                        InsulinType.Rapid
                                                            ? colors.blue500
                                                            : insulinType ===
                                                              InsulinType.Other
                                                            ? colors.grey400
                                                            : colors.blue300
                                                    }
                                                    strokeWidth="2px"
                                                    stroke={
                                                        tooltip.onShow &&
                                                        tooltip.id === id
                                                            ? colors.grey800
                                                            : colors.transparent
                                                    }
                                                />
                                                <G
                                                    id={id}
                                                    x={x + 13}
                                                    y={yHigh - 123}
                                                    scale={1}
                                                    fill={
                                                        tooltip.onShow &&
                                                        tooltip.id === id
                                                            ? colors.grey800
                                                            : colors.grey600
                                                    }
                                                    stroke="none"
                                                >
                                                    <Text
                                                        fontFamily="Inter"
                                                        fontSize="10px"
                                                        fontWeight="600"
                                                        fill={
                                                            tooltip.onShow &&
                                                            tooltip.id === id
                                                                ? colors.grey800
                                                                : colors.grey600
                                                        }
                                                        textAnchor="middle"
                                                    >
                                                        {tipData?.group?.length}
                                                    </Text>
                                                </G>
                                            </Fragment>
                                        ))}
                                </Fragment>
                            );
                        }

                        return (
                            <Fragment key={id}>
                                {x &&
                                    (isWeb ? (
                                        <Circle
                                            fill={
                                                insulinType ===
                                                InsulinType.Rapid
                                                    ? colors.blue500
                                                    : insulinType ===
                                                      InsulinType.Other
                                                    ? colors.grey400
                                                    : colors.blue300
                                            }
                                            strokeWidth="2px"
                                            stroke={
                                                tooltip.onShow &&
                                                tooltip.id === id
                                                    ? colors.grey700
                                                    : colors.transparent
                                            }
                                            id={id}
                                            cx={x}
                                            cy={yHigh}
                                            r="5px"
                                        />
                                    ) : (
                                        <Circle
                                            scaleY={-1}
                                            fill={
                                                insulinType ===
                                                InsulinType.Rapid
                                                    ? colors.blue500
                                                    : insulinType ===
                                                      InsulinType.Other
                                                    ? colors.grey400
                                                    : colors.blue300
                                            }
                                            id={id}
                                            cx={x}
                                            cy={yHigh}
                                            r="5px"
                                        />
                                    ))}
                            </Fragment>
                        );
                    }
                )}
        </>
    );
}
