import { VStack } from "native-base";
import { useCallback } from "react";
import type { PreOpQuestionKey } from "@madmedical/medical";
import PreOpSurveyFormRow from "../../../molecules/PreOpSurveyFormRow";
import FormControl from "../../../form/FormControl";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Survey1 = ({ onPreviousPress, onNextPress }: Props) => {
    const validate = useCallback(
        ({
            answers,
            subAnswers,
        }: {
            answers?: Record<PreOpQuestionKey, boolean>;
            subAnswers?: {
                drugAllergy?: [string | undefined];
                takingMedicine?: [string | undefined];
                otherDisease?: [string | undefined];
            };
        }) =>
            (!answers?.drugAllergy ||
                (subAnswers?.drugAllergy?.[0] ?? "").length > 3) &&
            (!answers?.takingMedicine ||
                (subAnswers?.takingMedicine?.[0] ?? "").length > 3) &&
            (!answers?.otherDisease ||
                (subAnswers?.otherDisease?.[0] ?? "").length > 3),
        []
    );

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            validate={validate}
            width="full"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <VStack>
                <PreOpSurveyFormRow
                    name="drugAllergy"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.drugAllergy.0"
                            size="md"
                            placeholder="Adja meg milyen gyógyszerekre érzékeny."
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow
                    name="takingMedicine"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.takingMedicine.0"
                            size="md"
                            placeholder="Adja meg milyen gyógyszert szed."
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow name="diseases" hasOptionalAnswer={false} />
                <PreOpSurveyFormRow
                    name="heartDisease"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="highBloodPressure"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="arrhythmia"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="vascularIssues"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="asthma"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="breathlessness"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="stroke"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="epilepsy"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="diabetes"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="thyroid"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="bleedingDisorder"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="hepatitis"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="acidReflux"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="muscleWeakness"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="sleepDisorder"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="eyeDisease"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                />
                <PreOpSurveyFormRow
                    name="otherDisease"
                    pl={{
                        base: 8,
                        md: 10,
                    }}
                    hasDivider={false}
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.otherDisease.0"
                            size="md"
                            placeholder="Kérjük részletezze"
                            isRequired
                        />
                    }
                />
            </VStack>
        </PreOpWrapper>
    );
};

export default Survey1;
