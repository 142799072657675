import { InsulinReminderModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { usePatientId } from "@madmedical/user";
import { useCreateInsulinReminderMutation } from "../api";
import type { CreateInsulinReminder } from "../model";

interface Props {
    open: boolean;
    onClose: () => void;
}

interface CreateInsulinReminderForm extends CreateInsulinReminder {
    patientId: string;
}

const CreateInsulinReminderModalForm = ({ open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const [createReminder] = useCreateInsulinReminderMutation();

    const formatFirst = (value: string) => {
        const now = new Date(new Date().setHours(0, 0, 0, 0));
        const hour = value.split(":")[0];
        const min = value.split(":")[1];
        now.setHours(Number(hour));
        now.setMinutes(Number(min));
        const offsetDate = new Date(now);

        return offsetDate;
    };

    return (
        <Modal open={open} size="xs" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{
                    patientId,
                    firstOccurrence: new Date(),
                    comment: null,
                }}
                mutation={(value: CreateInsulinReminderForm) =>
                    createReminder({
                        ...value,
                        firstOccurrence: formatFirst(
                            value.firstOccurrence.toString()
                        ),
                    })
                }
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <InsulinReminderModalForm
                        isSuccess={isSuccess}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default CreateInsulinReminderModalForm;
