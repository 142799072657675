import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta12 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M102.595 84.6586C102.736 85.0833 102.823 85.5189 102.834 85.9653C102.867 87.3482 102.29 88.6875 101.19 89.5368C100.33 90.201 99.23 90.5277 98.1411 90.4624C98.0758 90.6475 98.0322 90.8326 97.9669 91.0177C96.3009 95.7979 92.6532 99.7614 88.2432 102.157C83.1037 104.955 76.995 105.598 71.2784 104.629C65.5509 103.66 60.2045 101.057 55.5223 97.6817C50.8619 94.317 46.6914 90.1466 43.6426 85.2466C40.6699 80.4773 38.797 74.9676 38.601 69.3272C38.3179 68.663 37.9368 68.0532 37.4686 67.4979C36.3144 66.1259 34.7791 65.1568 33.2982 64.1985C31.7955 63.2294 30.3582 62.2168 29.1496 60.8883C26.8629 58.373 25.4474 55.139 24.9574 51.7853C24.4456 48.1484 24.9791 44.37 26.3402 40.9727C27.4618 38.1525 29.1604 35.55 31.2946 33.3832C33.418 31.2163 35.966 29.4414 38.7753 28.2981C41.6608 27.1221 44.7859 26.6539 47.8892 26.8499C51.0034 27.0568 54.085 27.9496 56.8072 29.4741C59.5076 30.9876 61.8378 33.1327 63.5038 35.7569C65.148 38.3485 66.1716 41.3429 66.4764 44.4027C66.6615 46.3409 66.5635 48.3009 66.1498 50.1955C66.0191 50.8815 65.8558 51.5566 65.6489 52.2208C66.5962 53.0048 67.5544 54.0393 67.1515 55.3568C66.9447 56.0101 66.4764 56.5655 66.0191 57.0555C65.7469 57.3495 65.4529 57.6108 65.1371 57.8612C65.4964 58.7215 65.638 59.6688 65.5618 60.5943C65.932 60.6814 66.2913 60.7794 66.6507 60.9101C68.6978 61.6397 70.4509 63.1097 71.518 64.9934C72.596 64.6994 73.7937 64.7974 74.7955 65.3201C75.7537 65.821 76.4833 66.6485 76.897 67.6394C77.3108 67.3019 77.7681 67.0079 78.2472 66.7574C80.6319 65.5379 83.4412 65.4834 85.9674 66.2565C88.6352 67.0732 90.9545 68.6956 93.0887 70.4705C93.1976 70.2527 93.3392 70.0458 93.5025 69.8607C94.0796 69.2401 94.8854 68.9352 95.7347 68.957C97.4116 68.9896 98.74 70.1112 99.72 71.3743C100.798 72.768 101.68 74.3251 102.399 75.9258C102.769 76.7425 103.096 77.57 103.368 78.4193C103.662 79.3122 103.923 80.2269 103.988 81.1633C104.076 82.47 103.673 83.8855 102.616 84.6804L102.595 84.6586Z"
            fill="#FFDCD0"
        />
        <Path
            d="M65.6686 60.9099C65.7666 60.6486 65.3746 60.4744 65.2766 60.7357C64.7757 62.0859 63.861 63.2293 62.6524 64.0024C62.6306 64.0133 62.6088 64.0459 62.5979 64.0677C59.6688 63.6321 56.7942 62.8808 53.8215 62.8155C50.6093 62.7502 47.4189 63.643 44.6749 65.3199C43.1396 66.2564 41.7567 67.4432 40.5916 68.8152C40.4065 69.033 40.7441 69.3052 40.9292 69.0874C42.9545 66.6919 45.6549 64.9279 48.6384 63.9915C50.152 63.5124 51.72 63.2619 53.3098 63.251C55.1717 63.2293 57.0119 63.5233 58.8413 63.8608C60.8884 64.231 62.9464 64.6557 65.0261 64.7755C65.2983 64.7864 65.3746 64.3617 65.0915 64.3508C64.4817 64.3181 63.8719 64.2528 63.273 64.1766C63.2948 64.1439 63.3057 64.1221 63.3057 64.0895C64.3728 63.2837 65.2112 62.1839 65.6686 60.9317V60.9099Z"
            fill="#DD8F8C"
        />
        <Path
            d="M42.6507 62.3576C41.3985 63.0654 39.8414 62.9783 38.5783 62.3576C37.3587 61.7588 36.3896 60.7134 35.7036 59.5483C34.9632 58.2961 34.5494 56.8261 34.6474 55.367C34.7454 53.9624 35.2898 52.5033 36.357 51.545C37.4241 50.5977 39.1118 50.2819 40.2007 51.3599C40.3967 51.5559 40.6689 51.2184 40.4729 51.0224C39.4603 50.0206 37.8596 50.0533 36.6945 50.7502C35.3987 51.5342 34.6039 53.015 34.3207 54.4633C33.7219 57.523 35.3334 60.9639 38.0121 62.5428C39.4929 63.4139 41.3767 63.5772 42.8902 62.7061C43.1298 62.5645 42.8794 62.2161 42.6398 62.3576H42.6507Z"
            fill="#DD8F8C"
        />
        <Path
            d="M71.6696 65.0587C71.5607 64.7973 71.1578 64.9607 71.2667 65.2111C72.3447 67.8135 71.8656 71.1346 69.7314 73.0728C67.2705 75.3159 63.6446 75.2179 60.5412 75.4139C58.6248 75.5337 56.6648 75.7733 54.9335 76.6553C54.683 76.775 54.8464 77.1888 55.0859 77.0581C58.309 75.4248 62.0439 76.0564 65.4957 75.4248C67.0745 75.1308 68.6425 74.5864 69.873 73.5302C70.9727 72.5828 71.7023 71.2871 72.0398 69.8824C72.4318 68.2926 72.3012 66.5722 71.6696 65.0587Z"
            fill="#DD8F8C"
        />
        <Path
            d="M99.6569 79.0069C98.2849 75.5334 95.9983 72.4518 93.0801 70.1325C92.8623 69.9583 92.5901 70.2959 92.8078 70.4701C95.6716 72.7458 97.9147 75.762 99.254 79.1703C99.352 79.4316 99.7549 79.2683 99.6569 79.0178V79.0069Z"
            fill="#DD8F8C"
        />
        <Path
            d="M102.193 84.6369C101.181 85.7366 99.493 86.0633 98.121 85.4862C97.8052 83.9509 97.2499 82.4591 96.4441 81.098C96.3026 80.8584 95.9541 81.1089 96.0957 81.3484C96.8579 82.6551 97.3915 84.0815 97.6963 85.5624C97.6746 85.6386 97.6746 85.7257 97.7399 85.7802C98.0992 87.6966 98.0557 89.7002 97.5983 91.6057C97.533 91.8779 97.9577 91.9433 98.023 91.671C98.4695 89.809 98.5239 87.8599 98.2081 85.9871C99.6999 86.4988 101.442 86.0742 102.52 84.9091C102.705 84.7022 102.379 84.43 102.183 84.6369H102.193Z"
            fill="#DD8F8C"
        />
        <Path
            d="M59.4873 50.9137C58.5509 51.6324 57.3531 51.9372 56.188 51.7304C55.9158 51.6868 55.8504 52.1115 56.1227 52.155C57.3858 52.3728 58.7251 52.0461 59.7377 51.2621C59.9555 51.0879 59.7051 50.7395 59.4873 50.9137Z"
            fill="#5D6982"
        />
        <Path
            d="M83.1467 88.5026C81.9598 87.2613 80.8274 85.9764 79.8038 84.5936C79.8038 84.5936 79.8038 84.5718 79.7929 84.5718C79.4989 85.5409 79.3356 86.5535 79.2812 87.5662C79.2703 87.8493 78.8347 87.7731 78.8565 87.5009C78.9109 86.3467 79.1287 85.2142 79.4772 84.1144C78.6169 82.9058 77.8547 81.6318 77.2232 80.2816C76.9509 79.6936 76.7005 79.0838 76.4827 78.474C76.2541 77.8207 76.0581 77.1456 75.9165 76.4705C75.6661 75.3163 75.5354 74.1294 75.5354 72.9425C74.8058 73.3018 73.9892 73.4434 73.1834 73.3454C73.0745 73.3454 72.9765 73.3236 72.8676 73.3018C72.443 73.2147 71.9312 73.0405 71.5392 72.7465C71.1472 72.4525 70.875 72.0496 70.9621 71.5052C71.0056 71.233 71.4303 71.2983 71.3868 71.5705C71.3432 71.8754 71.463 72.115 71.6699 72.311C72.051 72.6703 72.7261 72.8445 73.1399 72.9098C73.1943 72.9098 73.2379 72.9207 73.2923 72.9316C74.0327 73.0187 74.795 72.8663 75.4483 72.507C75.481 72.4961 75.5027 72.4852 75.5354 72.4852C75.6007 70.7865 75.9601 69.1096 76.6896 67.5525C76.7985 67.3021 77.2014 67.4763 77.0816 67.7268C76.3738 69.2512 76.0145 70.9172 75.9601 72.5941C75.8947 74.6738 76.2758 76.6665 76.9618 78.5503C77.1687 79.1383 77.4192 79.7045 77.6914 80.2816C79.096 83.2107 81.1867 85.8567 83.4189 88.176C83.604 88.372 83.3318 88.7095 83.1467 88.5135V88.5026Z"
            fill="#DD8F8C"
        />
        <Mask
            id="mask0_1024_101388"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101388)">
            <Path
                d="M74.9983 74.4624L74.9608 72.9629L71.9618 73.0379L71.9992 74.5374L74.9983 74.4624ZM83.4988 50L82.3108 50.9158L83.4988 50ZM88.4988 28.0002L89.1 29.3744L88.4988 28.0002ZM112.911 30.9422C113.708 30.7146 114.169 29.8843 113.941 29.0878C113.714 28.2912 112.884 27.83 112.087 28.0576L112.911 30.9422ZM71.9992 74.5374C72.1307 79.7967 74.1275 83.2156 77.5595 83.951C80.7536 84.6354 84.2363 82.7179 86.7698 79.7176C92.0018 73.5218 94.3427 61.6106 84.6868 49.0842L82.3108 50.9158C91.1549 62.3891 88.7457 72.7278 84.4777 77.7821C82.2613 80.4068 79.8065 81.3644 78.1881 81.0176C76.8075 80.7217 75.1168 79.2031 74.9983 74.4624L71.9992 74.5374ZM84.6868 49.0842C82.8234 46.6669 82.0732 42.5172 82.8231 38.4425C83.5693 34.3876 85.713 30.8564 89.1 29.3744L87.8975 26.626C83.2846 28.6443 80.727 33.2573 79.8726 37.8995C79.0219 42.5218 79.7717 47.6218 82.3108 50.9158L84.6868 49.0842ZM89.1 29.3744C92.6266 27.8314 97.3169 28.3391 101.889 29.2796C103.009 29.51 104.098 29.7603 105.142 30.0019C106.178 30.2417 107.181 30.4754 108.097 30.6638C109.011 30.8515 109.882 31.0037 110.659 31.0729C111.41 31.1399 112.207 31.1433 112.911 30.9422L112.087 28.0576C111.916 28.1065 111.557 28.1411 110.925 28.0847C110.317 28.0306 109.576 27.9051 108.701 27.7252C107.83 27.546 106.869 27.3221 105.818 27.0791C104.775 26.8378 103.654 26.5798 102.494 26.3411C97.9439 25.4053 92.3839 24.663 87.8975 26.626L89.1 29.3744Z"
                fill="#DD8F8C"
            />
        </G>
    </Svg>
);

export default Placenta12;
