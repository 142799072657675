export enum LogisticsStatus {
    Storage = "storage",
    Ordered = "ordered",
    Processing = "processing",
    Delivery = "delivery",
    Patient = "patient",
    Repairs = "repairs",
    Discarded = "discarded",
}

export enum DeviceOwnership {
    Purchased = "purchased",
    Rented = "rented",
}

export enum DeviceOperation {
    Create = "create",
    ToStorage = "toStorage",
    Ordered = "ordered",
    Processing = "processing",
    Delivery = "delivery",
    ToPatient = "toPatient",
    Discard = "discard",
    ToRepairs = "toRepairs",
    Update = "update",
}

const FOR_HUMANS = {
    [LogisticsStatus.Storage]: "Raktárban",
    [LogisticsStatus.Ordered]: "Megrendelve",
    [LogisticsStatus.Processing]: "Feldolgozás",
    [LogisticsStatus.Delivery]: "Kiszállítás",
    [LogisticsStatus.Patient]: "Páciensnél",
    [LogisticsStatus.Repairs]: "Szervizben",
    [LogisticsStatus.Discarded]: "Kivonva",
    [DeviceOwnership.Purchased]: "Vásárolt eszköz",
    [DeviceOwnership.Rented]: "Bérelt eszköz",
    [DeviceOperation.Create]: "Létrehozás",
    [DeviceOperation.ToStorage]: "Raktárba",
    [DeviceOperation.Ordered]: "Megrendelés",
    [DeviceOperation.Processing]: "Feldolgozás",
    [DeviceOperation.Delivery]: "Szállítás",
    [DeviceOperation.ToPatient]: "Páciensnél",
    [DeviceOperation.Discard]: "Selejtezés",
    [DeviceOperation.ToRepairs]: "Szervizbe",
    [DeviceOperation.Update]: "Adatmódosítás",
};

export const getLogisticsStatusChoices = (
    selected: LogisticsStatus[] | undefined
) =>
    Object.values(LogisticsStatus).map((status) => ({
        key: status,
        text: FOR_HUMANS[status],
        isSelected: !!selected?.includes(status),
    }));

export const getOwnershipChoices = (selected: DeviceOwnership[] | undefined) =>
    Object.values(DeviceOwnership).map((ownership) => ({
        key: ownership,
        text: FOR_HUMANS[ownership],
        isSelected: !!selected?.includes(ownership),
    }));

export const logisticsForHumans = (
    value: LogisticsStatus | DeviceOwnership | DeviceOperation
) => FOR_HUMANS[value];
