import type { ReactNode } from "react";
import { Breadcrumb as BaseBreadcrumb, Text } from "native-base";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import { useLinking } from "../providers/routing";

export interface Props<TRouteId extends RouteId> {
    route: TRouteId;
    params: AppRoutes[TRouteId];
    children: ReactNode;
}

const BreadcrumbLink = <TRouteId extends RouteId>({
    route,
    params,
    children,
}: Props<TRouteId>) => {
    const linkProps = useLinking(route, params);

    return (
        <BaseBreadcrumb.Link
            _text={{
                style: {
                    fontWeight: "500",
                },
                textDecoration: "none",
                color: "gray.700",
            }}
            _hover={{
                _text: {
                    color: "gray.800",
                },
            }}
        >
            <Text {...linkProps}>{children}</Text>
        </BaseBreadcrumb.Link>
    );
};

export default BreadcrumbLink;
