import type { OnFilterChange, OnSearchChange } from "@madmedical/ui";
import {
    CallCenterDeviceProvider,
    CallCenterDevices,
    Spinner,
} from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { invariant, useDebounce } from "@madmedical/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import type {
    DeviceOwnership,
    LogisticsStatus,
    MetricType,
    ProviderConnectionStatus,
} from "@madmedical/medical";
import {
    getConnectionStatusChoices,
    getLogisticsStatusChoices,
    getMetricTypeChoices,
    getOwnershipChoices,
} from "@madmedical/medical";
import type { LogisticsDeviceFilter } from "./model";
import { useGetDeviceTypesQuery, useGetLogisticsDevicesQuery } from "./api";
import adaptDevice from "./adaptDevice";
import UpdateDeviceForm from "./UpdateDeviceForm";
import CreateDeviceForm from "./CreateDeviceForm";

enum Modal {
    Closed,
    Create,
    Update,
}

const Devices = () => {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<LogisticsDeviceFilter>({});
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300, () => {
        setPage(1);
    });
    const { data: deviceTypes } = useGetDeviceTypesQuery();
    const { data, isLoading } = useGetLogisticsDevicesQuery({
        ...filter,
        page,
    });
    const [modal, setModal] = useState(Modal.Closed);
    const [idForModal, setIdForModal] = useState<string>();

    const filterChoices = useMemo(
        () => ({
            deviceTypes:
                deviceTypes?.map(({ id, name, manufacturer }) => ({
                    key: id,
                    text: `${manufacturer.forHumans} ${name}`,
                    isSelected: !!filter.deviceTypeIds?.includes(id),
                })) ?? [],
            metricTypes: getMetricTypeChoices(filter.metricTypes),
            statuses: getLogisticsStatusChoices(filter.statuses),
            connectionStatuses: getConnectionStatusChoices(
                filter.connectionStatuses
            ),
            ownerships: getOwnershipChoices(filter.ownerships),
        }),
        [deviceTypes, filter]
    );

    const handleFilterChange = useCallback<
        OnFilterChange<typeof filterChoices>
    >((key, selected) => {
        setPage(1);

        // TODO: Why does type inference fail me?
        const diff =
            key === "deviceTypes"
                ? { deviceTypeIds: selected as Ulid[] }
                : key === "metricTypes"
                ? { metricTypes: selected as MetricType[] }
                : key === "statuses"
                ? { statuses: selected as LogisticsStatus[] }
                : key === "connectionStatuses"
                ? { connectionStatuses: selected as ProviderConnectionStatus[] }
                : key === "ownerships"
                ? { ownerships: selected as DeviceOwnership[] }
                : {};

        setFilter((prevFilter) => ({
            ...prevFilter,
            ...diff,
        }));
    }, []);

    const handleSearchChange: OnSearchChange = (search) => {
        setSearch(search);
    };

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            search: debouncedSearch,
        }));
    }, [debouncedSearch]);

    const handleEndReached = useCallback(() => {
        if (!data) {
            return;
        }

        const { currentPage, pageCount } = data.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [data]);

    const handleAddPress = () => {
        setModal(Modal.Create);
    };

    const handleEditPress = (id: string) => {
        setIdForModal(id);
        setModal(Modal.Update);
    };

    const handleModalClose = () => {
        setIdForModal(undefined);
        setModal(Modal.Closed);
    };

    if (isLoading) {
        return <Spinner />;
    }

    invariant(data, "No devices in sight");

    const { items: devices, pagination } = data;

    return (
        <>
            <CallCenterDeviceProvider
                value={{
                    devices: devices.map(adaptDevice),
                    pagination,
                    onSearchChange: handleSearchChange,
                    filterChoices,
                    onFilterChange: handleFilterChange,
                }}
            >
                <CallCenterDevices
                    onEndReached={handleEndReached}
                    onAddPress={handleAddPress}
                    onEditPress={handleEditPress}
                />
            </CallCenterDeviceProvider>
            <CreateDeviceForm
                open={modal === Modal.Create}
                onClose={handleModalClose}
            />
            {idForModal && (
                <UpdateDeviceForm
                    id={idForModal}
                    open={modal === Modal.Update}
                    onClose={handleModalClose}
                />
            )}
        </>
    );
};

export default Devices;
