import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import type { AppConfig } from "@madmedical/config";
import { resolveBackendHostname } from "@madmedical/config";
import { authReducer } from "./auth/slice";
import api from "./api";
import { settingsReducer } from "./settings/slice";

type Config = Pick<
    AppConfig,
    "integrator" | "isProduction" | "testingHostnameBackend"
>;

const rootReducer = combineReducers({
    auth: authReducer,
    settings: settingsReducer,
    [api.reducerPath]: api.reducer,
});

export const getStore = (config: Config) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: {
                        hostnameBackend: resolveBackendHostname(config),
                    },
                },
            }).concat(api.middleware),
        devTools: {
            name: "nexthealth",
        },
    });

    setupListeners(store.dispatch);

    return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof getStore>["dispatch"];
