import type { InsulinType } from "@madmedical/medical";
import { Box, Text } from "native-base";

interface Props {
    units?: number | undefined;
    type: InsulinType;
}

export const InsulinQuantityListItem = ({ units, type }: Props) => {
    switch (type) {
        case "other":
            return (
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box
                        bgColor={"gray.400"}
                        width="16px"
                        height="16px"
                        rounded="full"
                        mr={2}
                    />
                    <Text>{units} E</Text>
                </Box>
            );
        case "rapid":
            return (
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box
                        bgColor={"blue.300"}
                        width="16px"
                        height="16px"
                        rounded="full"
                        mr={2}
                    />
                    <Text>{units} E</Text>
                </Box>
            );
        case "basal":
            return (
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box
                        bgColor={"blue.500"}
                        width="16px"
                        height="16px"
                        rounded="full"
                        mr={2}
                    />
                    <Text>{units} E</Text>
                </Box>
            );
        default:
            return <Box>-</Box>;
    }
};

export const InsulinQuantityBubbleMobile = ({ type }: Props) => {
    switch (type) {
        case "other":
            return (
                <Box
                    bgColor={"gray.400"}
                    width="8px"
                    height="8px"
                    rounded="full"
                />
            );
        case "basal":
            return (
                <Box
                    bgColor={"blue.300"}
                    width="8px"
                    height="8px"
                    rounded="full"
                />
            );
        case "rapid":
            return (
                <Box
                    bgColor={"blue.500"}
                    width="8px"
                    height="8px"
                    rounded="full"
                />
            );
        default:
            return (
                <Box
                    bgColor={"gray.400"}
                    width="16px"
                    height="16px"
                    rounded="full"
                />
            );
    }
};
