import { useMemo } from "react";
import { Avatar as BaseAvatar, useTheme } from "native-base";
import type { ImageSourcePropType } from "react-native";
import resolveImage from "../../util/resolveImage";
import placeholder from "./default.png";

interface Props {
    size: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
    source?: ImageSourcePropType | string;
    initials?: string;
    isCircle?: boolean;
    status?: "active" | "inactive";
}

const Avatar = ({
    source,
    size = "md",
    initials,
    isCircle = true,
    status,
}: Props) => {
    const { radii } = useTheme();
    const imageSource = useMemo(
        () => (source ? source : initials ? undefined : placeholder),
        [source, initials]
    );

    return (
        <BaseAvatar
            source={resolveImage(imageSource)}
            size={size}
            style={{ borderRadius: isCircle ? radii.full : radii[size] }}
            _image={{
                borderWidth: "1",
                borderColor: "gray.100",
                borderRadius: isCircle ? radii.full : radii[size],
            }}
        >
            {initials}
            {status && (
                <BaseAvatar.Badge
                    bg={`${status === "active" ? "green" : "rose"}.500`}
                />
            )}
        </BaseAvatar>
    );
};

export default Avatar;
