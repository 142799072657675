import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import { useEnsureContext } from "@madmedical/utils";
import type { Pregnancy, PregnancyWellBeing } from "@madmedical/medical";

interface ContextValue {
    pregnancyData?: Pregnancy;
    pregnancyWellBeing: PregnancyWellBeing[];
}

const PregnancyMoodContext = createContext<ContextValue | undefined>(undefined);

export const PregnancyMoodProvider =
    PregnancyMoodContext.Provider as ReactProvider<ContextValue>;

export const usePregnancyMood = () => useEnsureContext(PregnancyMoodContext);
