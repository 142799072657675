import { enUS, format, getDay, hu } from "@madmedical/utils";
import type { Locale } from "@madmedical/utils";
import { getLanguage } from "@madmedical/i18n";

const locales: Record<string, Locale> = {
    en: enUS,
    hu: hu,

    // add other language mappings here
};

export type CalendarDay = {
    day: number;
    monthOffset: "prev" | "actual" | "next";
    weekday: number;
};

export const createCalendar = (
    date: Date | null | undefined,
    type?: "year"
) => {
    const dateOrNow = date ?? new Date();
    const year = dateOrNow.getFullYear();
    const month = dateOrNow.getMonth();
    const calendar: Record<number, Record<number, CalendarDay[]>> = {};
    calendar[year] = {};
    if (type === "year") {
        Array.from(Array(12).keys()).map(
            (monthNumber) =>
                (calendar[year][monthNumber] = getMonths(monthNumber, year))
        );
    } else {
        calendar[year][month] = getMonths(month, year);
    }

    return calendar;
};

export const _getFirstDay = (year: number, month: number, day: number) => {
    let _day: number = getDay(new Date(year, month, day));
    if (_day === 0) _day = 7;

    return _day;
};

export const daysInMonth = function (month: number, year: number) {
    return new Date(year, month + 1, 0).getDate();
};

export const getMonths = function (month: number, year: number): CalendarDay[] {
    const days = daysInMonth(month, year);
    const monthJSON: CalendarDay[] = [];
    for (let i = 1; i <= days; i++) {
        const firstDay = _getFirstDay(year, month, i);
        if (i === 1) {
            for (let f = firstDay - 1; f >= 1; f--) {
                monthJSON.push({
                    day:
                        daysInMonth(
                            month - 1 < 0 ? 11 : month - 1,
                            month - 1 < 0 ? year - 1 : year
                        ) -
                        f +
                        1,
                    monthOffset: "prev",
                    weekday: _getFirstDay(
                        month - 1 < 0 ? year - 1 : year,
                        month - 1 < 0 ? 11 : month - 1,
                        daysInMonth(
                            month - 1 < 0 ? 11 : month - 1,
                            month - 1 < 0 ? year - 1 : year
                        ) -
                            f +
                            1
                    ),
                });
            }
        }
        monthJSON.push({
            day: i,
            monthOffset: "actual",
            weekday: firstDay,
        });
        if (i === days) {
            for (let j = 1; j <= 7 - firstDay; j++) {
                monthJSON.push({
                    day: j,
                    monthOffset: "next",
                    weekday: _getFirstDay(
                        month + 1 > 11 ? year + 1 : year,
                        month + 1 > 11 ? 1 : month + 1,
                        j
                    ),
                });
            }
        }
    }

    return monthJSON;
};

export const getDayList = () =>
    Array.from({ length: 7 }).map((_, i) =>
        format(new Date(2021, 0, i + 4), "EEEEEE", {
            locale: locales[getLanguage()],
        })
    );

export const getDayListLong = () =>
    Array.from({ length: 7 }).map((_, i) =>
        format(new Date(2021, 0, i + 4), "EEE", {
            locale: locales[getLanguage()],
        })
    );

export const getDayListFull = () =>
    Array.from({ length: 7 }).map((_, i) =>
        format(new Date(2021, 0, i + 4), "EEEE", {
            locale: locales[getLanguage()],
        })
    );

export const getMonthList = () =>
    Array.from({ length: 12 }).map((_, i) =>
        format(new Date(2021, i, 1), "MMM", { locale: locales[getLanguage()] })
    );
export const dayList = getDayList();
export const dayListLong = getDayListLong();
export const dayListFull = getDayListFull();
export const monthList = getMonthList();
