import { Box, HStack, Stack, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Button from "../../atoms/Button";
import Paper from "../../atoms/Paper";
import useResponsive from "../../util/useResponsive";
import PregnancyBodyGraph from "../../charts/PregnancyBodyGraph";
import type { PregnancyPointData } from "../../charts/types";

interface Props {
    onMeasurePress: (arg: "circumference") => void;
    circumferencePointData: PregnancyPointData[];
    ranges: {
        circumference: {
            min: number;
            max: number;
        };
    };
    isDoctor?: boolean;
    currentWeek?: number;
}

const PregnancyCircumferenceMeasure = ({
    onMeasurePress,
    circumferencePointData,
    ranges = {
        circumference: {
            min: 0,
            max: 10,
        },
    },
    isDoctor = false,
    currentWeek = 0,
}: Props) => {
    const { isSmallScreen } = useResponsive();

    const lastCircumference =
        circumferencePointData[circumferencePointData.length - 1]
            ?.valueCircumference;
    const { t } = useTranslation();

    return (
        <Paper mt={4}>
            <Box p={isSmallScreen ? 4 : 6} width="full">
                <Stack alignItems="flex-start" direction={"row"} space={6}>
                    <Box flex="1">
                        {circumferencePointData?.length > 0 ? (
                            <>
                                <HStack space={2}>
                                    <Text>
                                        {t("measures:abdominalCircumference")}
                                    </Text>
                                    <Text color="gray.600">
                                        {t("pregnancy:weekNumber", {
                                            weekNr: currentWeek,
                                        })}
                                    </Text>
                                </HStack>

                                <HStack alignItems="center" space={4} mt={-1}>
                                    <HStack space={1} alignItems={"baseline"}>
                                        <Text
                                            fontSize="3xl"
                                            fontWeight={"medium"}
                                            mr={1}
                                        >
                                            {lastCircumference}
                                        </Text>
                                        <Text color="gray.600">cm</Text>
                                    </HStack>
                                </HStack>
                            </>
                        ) : (
                            <VStack space={2} pb={10}>
                                <Text>
                                    {t("measures:abdominalCircumference")}
                                </Text>
                                <Text color="gray.600">{t("noData")}</Text>
                            </VStack>
                        )}
                    </Box>

                    {!isDoctor && !isSmallScreen && (
                        <Box>
                            <Button
                                onPress={() => onMeasurePress("circumference")}
                                variant="outlined"
                                leftIconName="chartCustom"
                                forceIcon
                            >
                                {t("measures:addManualMeasurement")}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Box>
            <Box width="100%" p={isSmallScreen ? 0 : 4}>
                {circumferencePointData?.length > 0 && (
                    <PregnancyBodyGraph
                        graphType="circumference"
                        pointData={circumferencePointData}
                        ranges={ranges}
                    />
                )}
            </Box>
            {!isDoctor && isSmallScreen && (
                <Box width={"full"} px={4} mt={-10} mb={16}>
                    <Button
                        onPress={() => onMeasurePress("circumference")}
                        variant="outlined"
                        leftIconName="chartCustom"
                        forceIcon
                    >
                        {t("measures:addManualMeasurement")}
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

export default PregnancyCircumferenceMeasure;
