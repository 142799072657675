import type { IFont } from "native-base/lib/typescript/theme/base/typography";

export const fontConfig = {
    100: {
        normal: "Inter",
        italic: "Inter",
    },
    200: {
        normal: "Inter",
        italic: "Inter",
    },
    300: {
        normal: "Inter",
        italic: "Inter",
    },
    400: {
        normal: "Inter",
        italic: "Inter",
    },
    500: {
        normal: "Inter",
    },
    600: {
        normal: "Inter",
        italic: "Inter",
    },
    700: {
        normal: "Inter",
        italic: "Inter",
    },
    800: {
        normal: "Inter",
        italic: "Inter",
    },
    900: {
        normal: "Inter",
        italic: "Inter",
    },
};

// Make sure values below matches any of the keys in `fontConfig`
const fonts: IFont = {
    heading: "Inter",
    body: "Inter",
    mono: "Inter",
};

export default fonts;
