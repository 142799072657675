import type { CellProps, Column } from "react-table";
import { Box, Text } from "native-base";
import { useCallback, useMemo } from "react";
import { forHumans } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { DocumentEvaluation } from "../providers/document";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import Tag from "../atoms/Tag";
import AvatarTexts from "../molecules/AvatarTexts";
import { useDocuments } from "../providers/document";
import useDownloadPreview from "../util/useDownloadPreview";
import { cropFilename } from "../util/strings";
import useResponsive from "../util/useResponsive";

interface Props {
    readonly onEndReached: () => unknown;
}

const DocumentsTableEvaluation = ({ onEndReached }: Props) => {
    const { documents, onSortChange } = useDocuments("evaluations");
    const navigate = useRouteNavigate();
    const { download, isDownloading } = useDownloadPreview();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const handleRowPress = useCallback(
        ({ id }: DocumentEvaluation) => {
            navigate("document", { documentId: id });
        },
        [navigate]
    );

    const handleViewPress = useCallback(
        (documentId: Ulid) => () => {
            navigate("document", { documentId });
        },
        [navigate]
    );

    const columns = useMemo<Column<DocumentEvaluation>[]>(
        () => [
            {
                Header: t("documentName"),
                accessor: "filename",
                width: "auto",
                defaultCanSort: !!onSortChange,
                Cell: ({ value }) => (
                    <Text numberOfLines={1}>
                        {cropFilename(value, isSmallScreen ? 20 : 40)}
                    </Text>
                ),
            },
            {
                Header: t("measures:type"),
                accessor: "metricType",
                Cell: ({ value: metricType }) => (
                    <Tag>{t(`measures:${forHumans(metricType)}`)}</Tag>
                ),
                disableSortBy: true,
            },
            {
                Header: t("evaluatingDoctor"),
                accessor: "doctor",
                Cell: ({ value: doctor }) => (
                    <AvatarTexts
                        source={doctor.avatarUrl}
                        label={doctor.fullName}
                        initials={doctor.initials}
                    />
                ),
                defaultCanSort: !!onSortChange,
            },
            {
                Header: t("uploadDate"),
                accessor: "createdAt",
                defaultCanSort: !!onSortChange,
                Cell: ({ value: createdAt }) => (
                    <>{formatDateTime(createdAt)}</>
                ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({
                    row: { original: document },
                }: CellProps<DocumentEvaluation>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            actions={[
                                {
                                    text: t("view"),
                                    iconName: "documentView",
                                    onPress: handleViewPress(document.id),
                                },
                                {
                                    text: t("download"),
                                    iconName: "documentDownload",
                                    onPress: () => {
                                        void download(document);
                                    },
                                },
                            ]}
                        />
                    </Box>
                ),
            },
        ],
        [onSortChange, isSmallScreen, handleViewPress, download, t]
    );

    if (isDownloading) {
        // TODO: Spinner
    }

    return (
        <ReactTable
            columns={columns}
            data={documents}
            onSortChange={onSortChange}
            onEndReached={onEndReached}
            onPress={handleRowPress}
        />
    );
};

export default DocumentsTableEvaluation;
