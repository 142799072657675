import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    CreateDeviceRequest,
    LogisticsDevice,
    LogisticsDeviceFilter,
    LogisticsDeviceType,
    UpdateDeviceRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["logisticsDevices"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getDeviceTypes: build.query<LogisticsDeviceType[], void>({
                query: () => "logistics/devicetypes",
            }),
            getLogisticsDevice: build.query<LogisticsDevice, Ulid>({
                query: (id) => `logistics/devices/${id}`,
                providesTags: ["logisticsDevices"],
            }),
            getPatientLogisticsDevices: build.query<LogisticsDevice[], Ulid>({
                query: (patientId) => `logistics/patients/${patientId}/devices`,
                providesTags: ["logisticsDevices"],
            }),
            getLogisticsDevices: build.query<
                Paginated<LogisticsDevice>,
                LogisticsDeviceFilter & PageQuery
            >({
                query: (params) => ({
                    url: "logistics/devices",
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["logisticsDevices"],
            }),
            createDevice: build.mutation<LogisticsDevice, CreateDeviceRequest>({
                query: (body) => ({
                    url: "logistics/devices",
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["logisticsDevices"],
            }),
            updateDevice: build.mutation<LogisticsDevice, UpdateDeviceRequest>({
                query: ({ id, ...body }) => ({
                    url: `logistics/devices/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["logisticsDevices"],
            }),
        }),
    });

export const {
    useGetDeviceTypesQuery,
    useGetLogisticsDeviceQuery,
    useGetPatientLogisticsDevicesQuery,
    useGetLogisticsDevicesQuery,
    useCreateDeviceMutation,
    useUpdateDeviceMutation,
} = api;
