import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import type { DateRange, Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type { FilterItem, OnFilterChange, OnSearchChange } from "./model";

export interface PatientMeasurements {
    dateTrunc: Date;
    metricType: MetricType;
    dateRange: DateRange;
    measurementCount: number;
    displayRange: string;
    worstAlertLevel: AlertLevel | null;
    alertLevelCounts: {
        [AlertLevel.Ok]: number;
        [AlertLevel.Warning]: number;
        [AlertLevel.Alert]: number;
    };
    patient: {
        userId: Ulid;
        fullName: string;
        avatarUrl?: string;
        initials: string;
    };
    evaluationCount: number;
    documentCount: number;
    commentCount: number;
}

export type FilterChoices = {
    readonly patients: FilterItem<Ulid>[];
    readonly metricTypes: FilterItem<MetricType>[];
    readonly alertLevels: FilterItem<AlertLevel>[];
};

interface ContextValue {
    patientMeasurements: PatientMeasurements[];
    onSearchChange: OnSearchChange | undefined;
    filterChoices: FilterChoices;
    onFilterChange: OnFilterChange<FilterChoices> | undefined;
}

const PatientMeasurementsContext = createContext<ContextValue | undefined>(
    undefined
);

export const PatientMeasurementsProvider =
    PatientMeasurementsContext.Provider as ReactProvider<ContextValue>;

export const usePatientMeasurements = () =>
    useEnsureContext(PatientMeasurementsContext);
