import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const Week12 = () => {
    const { t } = useTranslation();

    return (
        <VStack space={2}>
            <Text>{t("pregnancy:week12Text1")}</Text>
            <Text>{t("pregnancy:week12Text2")}</Text>
        </VStack>
    );
};

export default Week12;
