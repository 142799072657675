import { ManualDataRecordModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import type { MetricTypeManualAllowed } from "@madmedical/medical";
import { usePatientId } from "@madmedical/user";
import { useCreateManualMutation } from "../api";

interface Props {
    metricType: MetricTypeManualAllowed;
    open: boolean;
    onClose: () => void;
}

const ManualMeasurementModalForm = ({ metricType, open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const [createManual] = useCreateManualMutation();

    return (
        <Modal open={open} onClose={onClose} size="xs">
            <ApiFormWrapper
                defaultValues={{
                    userId: patientId,
                    measuredAt: new Date().toISOString(),
                    metric: { type: metricType },
                }}
                mutation={createManual}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <ManualDataRecordModalForm
                        metricType={metricType}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default ManualMeasurementModalForm;
