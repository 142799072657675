import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta11 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M93.9793 77.8041C93.8843 79.6212 93.053 81.367 92.0673 82.8753C91.2122 84.1816 90.1433 85.3574 88.9319 86.3431C88.9794 86.8776 88.9676 87.4001 88.9082 87.9345C88.6825 89.6566 87.875 91.2718 86.5804 92.4475C85.4759 93.457 84.0746 94.1221 82.59 94.3358C82.59 94.4902 82.6019 94.6446 82.6019 94.799C82.5425 97.3643 81.4736 99.8464 79.8466 101.806C78.0889 103.92 75.6899 105.345 73.0296 106.034C70.2031 106.758 67.1984 106.663 64.3837 105.963C61.5334 105.25 58.8613 103.908 56.4385 102.257C53.992 100.583 51.7593 98.5875 49.6928 96.4617C47.662 94.3715 45.7974 92.115 44.1822 89.6803C40.9756 84.8942 38.731 79.4074 38.256 73.6355C37.8284 68.4219 38.8023 63.0419 41.4388 58.5052C41.1656 58.137 40.8925 57.7689 40.6431 57.3888C37.7572 52.9115 36.7833 47.2821 38.1491 42.1278C39.4792 37.116 42.8164 32.71 47.3651 30.2041C52.0562 27.615 57.733 27.0331 62.8161 28.7789C67.8753 30.5247 72.032 34.5508 73.4572 39.7763C74.6686 44.218 73.6472 49.1111 70.6306 52.5671C71.2363 53.5053 71.6876 54.586 71.2007 55.6905C70.7138 56.7712 69.6687 57.3176 68.5998 57.7095C68.8136 59.4315 68.2316 61.2486 66.9965 62.4837C66.6758 62.7925 66.3195 63.0657 65.9395 63.2913C66.3789 63.6476 66.7708 64.087 67.044 64.5739C68.5642 63.0894 70.31 61.7711 72.4833 61.6286C74.431 61.498 76.4856 62.5669 77.0082 64.5502C77.1507 65.0846 77.222 65.6547 77.5545 66.1179C77.8276 66.4979 78.2196 66.7829 78.6115 67.0323C79.4072 67.5549 80.191 68.0418 80.6779 68.8969C81.5568 70.4646 81.343 72.5785 79.9891 73.8137C78.7896 74.9063 76.9369 75.1082 75.4999 74.4075C75.5236 74.645 75.5474 74.8825 75.5712 75.1201C76.0818 75.025 76.5806 74.9657 77.0913 74.9182C80.4167 74.6213 83.8251 75.2032 86.8536 76.6046C86.8892 75.3338 87.1743 74.0512 88.3025 73.3386C89.5376 72.5548 91.2122 72.7448 92.3285 73.6355C93.5637 74.645 94.0744 76.2839 93.9912 77.8278L93.9793 77.8041Z"
            fill="#FFDCD0"
        />
        <Path
            d="M65.4028 63.0183C63.4194 63.7072 61.2104 63.7309 59.2271 63.0183C57.4576 62.3889 55.9136 61.225 54.7973 59.7167C54.6191 59.4673 54.2391 59.7524 54.4172 59.9899C55.593 61.5932 57.2438 62.8283 59.1202 63.4815C61.2104 64.1941 63.4907 64.1822 65.569 63.4577C65.8541 63.3627 65.6878 62.9233 65.4028 63.0183Z"
            fill="#DD8F8C"
        />
        <Path
            d="M54.7153 44.6922C53.6939 44.3478 52.5894 44.6091 51.7343 45.2267C50.7724 45.9155 50.1904 46.9844 49.9173 48.1245C49.6322 49.2765 49.5966 50.6066 49.9529 51.7467C50.3211 52.9106 51.3068 53.932 52.5775 53.9914C52.8744 54.0032 52.9576 53.5401 52.6488 53.5282C51.6749 53.4807 50.8792 52.78 50.5111 51.913C50.0954 50.951 50.1073 49.8228 50.2617 48.8014C50.4161 47.7801 50.7961 46.7706 51.5324 46.0224C52.1737 45.3692 53.112 44.9179 54.0502 45.0248C55.0478 45.1435 55.9742 45.9155 56.0929 46.9369C56.1285 47.2338 56.5917 47.1863 56.5561 46.8894C56.4373 45.868 55.6654 45.0248 54.7034 44.7041L54.7153 44.6922Z"
            fill="#DD8F8C"
        />
        <Path
            d="M76.9034 65.5122C76.8084 65.2271 76.3689 65.3934 76.4639 65.6784C76.749 66.4979 77.129 67.7093 76.2977 68.3506C75.7039 68.8138 74.8013 68.6712 74.1006 68.7425C73.7443 68.7781 73.3761 68.8494 73.0555 69.0275C72.7229 69.2057 72.4735 69.5026 72.2122 69.7757C71.8797 70.1202 71.5353 70.3696 71.0246 70.322C70.5614 70.2745 70.1339 70.0608 69.7182 69.847C69.7301 69.5738 69.3026 69.5145 69.2551 69.7995C68.9938 71.379 68.1149 72.7329 67.0698 73.9206C66.5473 74.5144 65.9891 75.0725 65.4072 75.607C64.8371 76.1414 64.2433 76.6758 63.5663 77.0796C60.2647 79.0036 56.975 75.9158 54.196 74.4787C52.6045 73.6593 50.7043 73.1605 48.9704 73.8137C48.6854 73.9206 48.8517 74.36 49.1367 74.2531C50.7994 73.6355 52.5927 74.17 54.0891 74.9538C55.6567 75.7732 57.07 76.9015 58.7208 77.5666C60.2766 78.196 62.0462 78.3979 63.5663 77.5903C64.2789 77.2103 64.8965 76.6877 65.4903 76.1414C66.0722 75.5951 66.6542 75.0488 67.1886 74.455C68.2812 73.2555 69.2194 71.8897 69.5995 70.2983C70.2645 70.6308 71.0365 70.9515 71.761 70.6427C72.426 70.3696 72.7229 69.6332 73.3761 69.3482C74.5637 68.8256 76.2502 69.562 76.9984 68.1606C77.4616 67.3055 77.1646 66.3316 76.8677 65.4765L76.9034 65.5122Z"
            fill="#DD8F8C"
        />
        <Path
            d="M66.9595 64.5269C65.2968 66.1302 63.646 67.7216 61.9834 69.3249C60.677 67.2466 59.0618 65.3464 57.2091 63.7193C55.4514 62.1754 53.2899 60.6434 50.8316 60.8809C49.6677 60.9997 48.5513 61.5104 47.8269 62.4367C47.6369 62.6742 48.005 62.9711 48.195 62.7336C49.5608 60.964 52.0904 61.0828 53.9194 61.9735C54.9883 62.4961 55.9384 63.2324 56.8291 64.0162C57.7317 64.8001 58.5749 65.6433 59.3587 66.5578C60.2376 67.5791 61.0333 68.6836 61.7458 69.8237C61.829 69.9662 62.019 69.9306 62.114 69.8237L67.3158 64.812C67.5296 64.5982 67.1614 64.3013 66.9476 64.515L66.9595 64.5269Z"
            fill="#DD8F8C"
        />
        <Path
            d="M75.3571 73.9918C74.9534 73.9443 74.9177 73.4099 74.894 73.0892C74.8583 72.6379 74.7752 72.2223 74.407 71.9135C74.0983 71.6522 73.6945 71.5216 73.5163 71.1415C73.3619 70.809 73.3619 70.3933 73.552 70.0727L73.5995 69.9895C73.7301 69.7639 73.3501 69.5145 73.2075 69.7401C73.2075 69.752 73.1957 69.7758 73.1838 69.7995C73.1482 69.847 73.1244 69.9064 73.0888 69.9539C73.0769 69.9777 73.0769 70.0014 73.065 70.0133C72.9225 70.3458 72.8988 70.7021 72.9938 71.0584C73.1244 71.5691 73.4807 71.8422 73.9082 72.1273C74.1339 72.2817 74.312 72.436 74.3833 72.7211C74.4427 72.9823 74.4189 73.2555 74.4783 73.5168C74.5614 73.9562 74.8227 74.4075 75.2978 74.455C75.5947 74.4906 75.6778 74.0275 75.369 73.9918H75.3571Z"
            fill="#DD8F8C"
        />
        <Path
            d="M75.5697 75.0732C73.0281 75.2751 71.1042 77.3653 70.2016 79.6218C69.6553 80.9876 69.4059 82.4365 69.2277 83.8854C69.0377 85.4649 68.8833 87.0563 68.7171 88.6478C68.5152 90.5242 68.3251 92.3888 68.1232 94.2652C68.0876 94.5621 68.5627 94.6453 68.5864 94.3365C68.919 91.1893 69.2396 88.0421 69.584 84.9067C69.8691 82.2702 70.261 79.4199 72.1137 77.3772C73.0281 76.3796 74.2395 75.6551 75.6053 75.5364C75.9022 75.5126 75.8547 75.0376 75.5578 75.0732H75.5697Z"
            fill="#DD8F8C"
        />
        <Path
            d="M86.3539 82.5903C84.5962 82.1746 83.2661 83.6948 82.4941 85.0487C82.1972 85.5712 81.9122 86.1176 81.5915 86.6282C81.7815 85.8563 81.8291 85.0606 81.7578 84.2767C81.7222 83.9798 81.259 84.0273 81.2946 84.3242C81.3896 85.2862 81.259 86.2601 80.9383 87.1745C80.4039 85.975 79.7626 84.8112 78.9906 83.7423C78.8125 83.4929 78.4324 83.7779 78.6106 84.0155C80.7721 87.0439 81.9953 90.678 82.126 94.3953C82.126 94.7041 82.601 94.6447 82.5891 94.3478C82.5179 92.1388 82.0428 89.9536 81.2352 87.9109C81.9122 87.1864 82.3516 86.2838 82.8267 85.4287C83.3017 84.5736 83.8836 83.6948 84.7862 83.2554C85.677 82.816 86.6271 83.006 87.3515 83.6592C88.1353 84.3599 88.4679 85.405 88.6698 86.4145C88.7292 86.7114 89.1923 86.6639 89.133 86.367C88.8242 84.7874 88.076 83.0297 86.3539 82.6141V82.5903Z"
            fill="#DD8F8C"
        />
        <Path
            d="M87.0923 76.9498C86.9854 76.9023 86.8192 76.9498 86.7835 77.0686C86.546 77.8524 86.4985 78.6838 86.6885 79.4795C86.7123 79.5983 86.8073 79.7051 86.9498 79.6933C87.0686 79.6814 87.1873 79.5626 87.1636 79.432C86.9973 78.7194 87.0092 77.9712 87.223 77.2705C87.2586 77.1517 87.223 77.0211 87.1042 76.9617L87.0923 76.9498Z"
            fill="#DD8F8C"
        />
        <Path
            d="M65.607 50.6906C64.5619 51.1182 63.3743 50.7738 62.7211 49.8593C62.543 49.6099 62.1629 49.8949 62.3411 50.1325C63.113 51.2132 64.55 51.6289 65.7852 51.1301C66.0702 51.0113 65.8921 50.5719 65.6189 50.6906H65.607Z"
            fill="#5D6982"
        />
        <Mask
            id="mask0_1024_101347"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101347)">
            <Path
                d="M72.6294 75.1133L73.2427 76.4822L75.9805 75.2555L75.3671 73.8867L72.6294 75.1133ZM107.001 60L108.385 60.5771L107.001 60ZM92.4992 39.5L93.7854 40.2717L92.4992 39.5ZM112.911 30.9423C113.708 30.7147 114.169 29.8844 113.941 29.0879C113.714 28.2913 112.884 27.8301 112.087 28.0577L112.911 30.9423ZM75.3671 73.8867C74.6397 72.2632 74.6156 71.2123 74.8306 70.5266C75.0397 69.8593 75.5679 69.2441 76.6046 68.6862C78.7832 67.5137 82.4419 66.9919 86.7981 66.6358C90.9951 66.2927 95.761 66.1068 99.6215 65.4496C101.566 65.1186 103.4 64.6516 104.904 63.9283C106.411 63.2037 107.73 62.1501 108.385 60.5771L105.616 59.4229C105.347 60.0688 104.738 60.6793 103.604 61.2248C102.467 61.7716 100.949 62.1806 99.1181 62.4922C95.4286 63.1202 90.9569 63.2858 86.5536 63.6458C82.3095 63.9927 78.0051 64.5256 75.1829 66.0444C73.7191 66.8322 72.4875 67.9718 71.9679 69.6292C71.4542 71.2682 71.7325 73.1118 72.6294 75.1133L75.3671 73.8867ZM108.385 60.5771C109.097 58.8701 109.318 57.2793 109.069 55.7984C108.821 54.3178 108.123 53.074 107.207 52.0259C105.432 49.9966 102.679 48.5441 100.255 47.3214C98.9943 46.6855 97.8119 46.1095 96.7409 45.5102C95.669 44.9103 94.8089 44.3405 94.1959 43.7618C93.5889 43.1888 93.3159 42.6941 93.2391 42.2464C93.1653 41.8162 93.2324 41.1935 93.7854 40.2717L91.213 38.7283C90.4017 40.0803 90.0551 41.4285 90.2823 42.7535C90.5065 44.061 91.2492 45.1057 92.1366 45.9434C93.0181 46.7755 94.1314 47.4876 95.2758 48.1281C96.4211 48.769 97.698 49.3917 98.9038 49.9999C101.413 51.2654 103.621 52.4825 104.949 54.0009C105.583 54.7266 105.973 55.4761 106.111 56.2954C106.248 57.1143 106.155 58.1299 105.616 59.4229L108.385 60.5771ZM93.7854 40.2717C95.7646 36.9731 99.483 34.8752 103.381 33.4742C105.305 32.7828 107.213 32.2822 108.875 31.8896C110.473 31.5123 111.996 31.2038 112.911 30.9423L112.087 28.0577C111.252 28.2962 109.963 28.5503 108.186 28.9699C106.473 29.3742 104.443 29.9048 102.367 30.6509C98.2654 32.1249 93.7338 34.5269 91.213 38.7283L93.7854 40.2717Z"
                fill="#DD8F8C"
            />
        </G>
    </Svg>
);

export default Placenta11;
