import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta07 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M46.2871 91.3749C49.2337 94.8917 52.7109 98.0747 56.842 100.146C60.9491 102.151 65.4968 102.702 69.9333 101.639C74.9234 100.45 79.2854 97.4606 82.8482 93.8538C86.2151 90.4299 89.1479 86.3843 90.8392 81.844C92.3999 77.6053 92.7605 72.9242 91.3038 68.6C90.6352 66.6034 89.6016 64.6114 88.0989 63.1031C86.907 61.9167 84.823 61.0375 83.5337 62.566C82.2967 64.0531 83.2421 66.2566 83.4435 67.9129C83.6844 69.9067 83.4627 71.9254 82.8732 73.8373C81.8542 77.1095 79.7288 79.9503 76.8797 81.8024C77.0986 80.7504 77.1786 79.6661 76.8273 78.6103C76.3675 77.1296 75.2051 76.2321 73.7686 75.7777C72.2557 75.2986 70.5948 75.1348 69.0102 74.996C67.8009 74.9012 66.5492 74.8966 65.3528 74.9619C64.6915 72.9028 63.7029 70.9319 62.4697 69.1542C62.8164 68.767 63.1079 68.31 63.2643 67.7894C63.5797 66.8597 63.3885 65.791 62.9038 64.9256C66.4436 64.3435 70.1802 63.4358 72.3488 60.3624C73.4505 58.8118 73.8138 56.9366 73.9684 55.0844C74.1339 53.1384 74.2057 51.1814 74.1974 49.2308C74.1865 42.3186 72.4493 34.5852 66.0485 30.7888C60.4165 27.4632 52.6877 27.9487 46.9913 30.829C40.9077 33.9015 37.6612 40.2085 36.9337 46.7961C36.2945 52.6331 37.5682 58.3783 39.1416 64.0002C39.0175 64.3818 38.8758 64.7773 38.7692 65.1451C36.0901 74.5781 40.2036 84.2079 46.2558 91.3712L46.2871 91.3749Z"
            fill="#FFDCD0"
        />
        <Path
            d="M65.1118 76.4011L65.112 76.4012C65.4629 76.469 65.7101 76.2302 65.7765 75.9353C65.8094 75.7888 65.7942 75.6382 65.7283 75.518C65.6639 75.4005 65.5469 75.3038 65.3576 75.2677C61.9465 74.6173 58.3195 75.7953 55.9568 78.3462L55.9708 78.3639L55.9128 78.4267C55.7871 78.5627 55.7495 78.7093 55.7659 78.8448C55.7826 78.9831 55.8566 79.1166 55.9659 79.2175C56.0752 79.3184 56.2142 79.3816 56.3545 79.3867C56.4918 79.3917 56.6377 79.3414 56.7681 79.2017C58.8332 76.912 62.0704 75.8189 65.1118 76.4011Z"
            fill="#DD8F8C"
            stroke="#FFDCD0"
            strokeWidth="0.2"
        />
        <Path
            d="M69.2078 88.6362L69.2058 88.6332C69.0493 88.7228 68.9739 88.8439 68.9509 88.9681C68.9263 89.1004 68.9597 89.2467 69.0389 89.3748C69.1978 89.6317 69.5179 89.7856 69.8492 89.609C71.5204 88.6849 73.0713 87.5735 74.471 86.2709C74.5022 86.2414 74.5336 86.2117 74.5653 86.1818C75.0877 85.6883 75.6676 85.1407 76.1137 84.5281C76.5859 83.8796 76.8997 83.1694 76.8402 82.3872C76.8021 82.0198 76.4997 81.8417 76.1989 81.8536C76.049 81.8595 75.9086 81.9134 75.8109 82.0081C75.7155 82.1006 75.6538 82.2386 75.6691 82.4304C75.728 83.0818 75.3737 83.6249 74.9853 84.0746C74.4841 84.6702 73.9244 85.2261 73.3379 85.7461C72.0725 86.8876 70.6864 87.8392 69.2078 88.6362Z"
            fill="#DD8F8C"
            stroke="#FFDCD0"
            strokeWidth="0.2"
        />
        <Path
            d="M41.8977 77.2274L41.8882 77.2318C43.8438 87.1043 50.917 95.7004 60.2415 99.4971C60.5925 99.6331 60.9018 99.4487 61.0399 99.183C61.1087 99.0505 61.1299 98.9058 61.0957 98.7808C61.0624 98.6592 60.974 98.5453 60.8001 98.4731C51.7076 94.7788 44.8889 86.5019 43.0009 76.864L43.0009 76.864C42.9643 76.6768 42.8696 76.5685 42.7574 76.5137C42.6419 76.4573 42.496 76.452 42.3526 76.4963C42.0645 76.5854 41.8256 76.8592 41.8977 77.2274Z"
            fill="#DD8F8C"
            stroke="#FFDCD0"
            strokeWidth="0.2"
        />
        <Path
            d="M88.2919 69.3122L88.2919 69.3122L88.292 69.3129C89.3765 76.4004 87.0825 83.7537 82.0768 88.9044L82.0907 88.922L82.0291 88.9848C81.8958 89.121 81.8551 89.266 81.8692 89.3983C81.8836 89.5336 81.9565 89.665 82.0667 89.7651C82.2876 89.9657 82.6288 90.0205 82.8885 89.7555C88.096 84.4016 90.601 76.6611 89.4647 69.2828C89.4061 68.9179 89.0858 68.7419 88.78 68.7554C88.6281 68.7621 88.4913 68.8161 88.4014 68.9072C88.3143 68.9953 88.2623 69.1268 88.2919 69.3122Z"
            fill="#DD8F8C"
            stroke="#FFDCD0"
            strokeWidth="0.2"
        />
        <Path
            d="M57.5005 60C57.667 60.3332 58.3001 61.1998 59.5005 62C61.0009 63.0003 63.7407 65.2793 63.0005 67.5C62.5005 69 60.5005 71 53.5005 69"
            stroke="#DD8F8C"
            strokeLinecap="round"
        />
        <Path
            d="M59.126 47.6308C59.2584 47.0441 59.7275 46.5603 60.3059 46.3589C60.8669 46.1713 61.5475 46.3148 61.9687 46.7765C62.148 47.0036 62.3411 47.2482 62.4018 47.5407C62.48 47.8194 62.5058 48.1394 62.4101 48.4135C62.3604 48.5661 62.3108 48.7188 62.2335 48.8365C62.1002 49.0269 61.988 49.1722 61.782 49.3064C61.6597 49.403 61.4923 49.4784 61.3598 49.5262C61.2624 49.5465 61.1649 49.5667 61.0536 49.5695C60.5699 49.6393 60.0668 49.4692 59.7072 49.1574C59.2437 48.7859 59.0147 48.1725 59.161 47.6032L59.126 47.6308Z"
            fill="#5D6982"
        />
        <Mask
            id="mask0_1024_101189"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101189)">
            <Path
                d="M70 78.5C80 72.5 71.6837 60.0518 80.5 57C93.5 52.5 89.5155 42.4823 96.5 34.5C100 30.5 109 30.5001 112.5 29.5"
                stroke="#DD8F8C"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </G>
    </Svg>
);

export default Placenta07;
