import type { ImageSourcePropType } from "react-native";
import { Image } from "react-native";

export default (source: ImageSourcePropType | string | undefined) => {
    if (!source) {
        return undefined;
    }

    return typeof source === "string"
        ? { uri: source }
        : Image.resolveAssetSource(source);
};
