import type { ComponentProps, ReactElement, ReactNode } from "react";
import {
    FormControl as BaseFormControl,
    Box,
    Divider,
    HStack,
    Radio,
    Text,
    VStack,
} from "native-base";
import type { PreOpQuestionKey } from "@madmedical/medical";
import { getQuestion } from "@madmedical/medical";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import FormYesNoControl from "../form/FormYesNoControl";

type RadioProps = Omit<ComponentProps<typeof Radio>, "value"> &
    Omit<
        ComponentProps<typeof Radio.Group>,
        "value" | "onChange" | "defaultValue"
    >;

interface Props
    extends RadioProps,
        Pick<
            ComponentProps<typeof VStack>,
            | "m"
            | "ml"
            | "mr"
            | "mt"
            | "mb"
            | "mx"
            | "my"
            | "p"
            | "pl"
            | "pr"
            | "pt"
            | "pb"
            | "px"
            | "py"
        > {
    name: PreOpQuestionKey;
    answer?: ReactNode;
    answerInput?: ReactElement;
    optionalAnswerInput?: ReactNode;
    hasOptionalAnswer?: boolean;
    hasDivider?: boolean;
    isDoctorSurveyRow?: boolean;
    isYes: boolean;
    isRequired?: boolean;
}

const PreOpSurveyRow = ({
    name,
    answer,
    answerInput,
    optionalAnswerInput,
    hasOptionalAnswer = true,
    hasDivider = true,
    isReadOnly = false,
    isYes,
    isRequired = false,
    ...rest
}: Props) => {
    const resolvedName = `answers.${name}`;
    const { t } = useTranslation();

    return (
        <>
            <VStack
                width="full"
                space={3}
                p={{
                    base: 4,
                    md: 6,
                }}
                borderLeftWidth={2}
                borderLeftColor={isYes ? "error.300" : "transparent"}
                {...rest}
            >
                <HStack
                    width="full"
                    space={3}
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexDirection={{ base: "column", md: "row" }}
                >
                    <Box mb={1} flex={1}>
                        <Text fontSize="md" numberOfLines={99}>
                            {t(`preop:${getQuestion(name)}`)}{" "}
                            {isRequired && <Text color="error.500">*</Text>}
                        </Text>
                    </Box>

                    {hasOptionalAnswer && (
                        <HStack space={4}>
                            {isReadOnly ? (
                                <BaseFormControl flex={isWeb ? 1 : undefined}>
                                    <Radio.Group
                                        name={name}
                                        value={isYes ? "yes" : "no"}
                                        accessibilityLabel=""
                                    >
                                        <HStack space={7}>
                                            <Radio
                                                accessibilityLabel="Igen"
                                                key="yes"
                                                value="yes"
                                                borderWidth={1}
                                            >
                                                <Text>{t("yes")}</Text>
                                            </Radio>
                                            <Radio
                                                accessibilityLabel="Nem"
                                                key="no"
                                                value="no"
                                                borderWidth={1}
                                            >
                                                <Text>{t("no")}</Text>
                                            </Radio>
                                        </HStack>
                                    </Radio.Group>
                                </BaseFormControl>
                            ) : (
                                <FormYesNoControl
                                    flexDirection="row"
                                    space={4}
                                    name={resolvedName}
                                    isRequired
                                    isReadOnly={isReadOnly}
                                    defaultValue={isYes}
                                />
                            )}
                        </HStack>
                    )}
                </HStack>

                {optionalAnswerInput && isYes && (
                    <HStack width="full" justifyContent="flex-end">
                        <Box
                            width={{ base: "full", md: "1/2" }}
                            justifyContent="flex-end"
                        >
                            {optionalAnswerInput}
                        </Box>
                    </HStack>
                )}

                {answerInput && (
                    <HStack width="full" justifyContent="flex-start">
                        <Box width={{ base: "full", md: "1/2" }}>
                            {answerInput}
                        </Box>
                    </HStack>
                )}

                {answer && (
                    <HStack width="full" justifyContent="flex-start">
                        <Text color="gray.800">{answer}</Text>
                    </HStack>
                )}
            </VStack>
            {hasDivider && (
                <Divider
                    bgColor="gray.100"
                    mx={{
                        base: 0,
                        md: 6,
                    }}
                />
            )}
        </>
    );
};

export default PreOpSurveyRow;
