import type { PatientDoctor } from "@madmedical/ui";
import {
    CurrentPatientProvider,
    DashboardInsulinMeasurementList,
    DeviceProvider,
    DoctorPatient as DoctorPatientUi,
    DocumentProvider,
    EvaluationProvider,
    PreOpProvider,
    PredictionProvider,
    PregnancyDoctorInfos,
    Spinner,
    ThresholdsProtocolsProvider,
} from "@madmedical/ui";
import {
    DashboardWidgets,
    adaptOpinion,
    adaptPrediction,
    useGetInsulinAdministrationsQuery,
    useGetOpinionsQuery,
    useGetPredictionsQuery,
    useGetPregnancyByPatientQuery,
} from "@madmedical/measurement";
import type { PatientMe } from "@madmedical/user";
import {
    EditPatientDetailsModal,
    adaptPatient,
    usePatient,
    useRouteNavigate,
} from "@madmedical/user";
import { useMemo, useState } from "react";
import { invariant } from "@madmedical/utils";
import {
    UploadModal,
    adaptDocument,
    useGetDocumentsQuery,
    useGetPregnancyDocumentsQuery,
} from "@madmedical/document";
import { useDevices } from "@madmedical/device";
import {
    EditThresholdModal,
    adaptThresholdsProtocol,
    useGetThresholdsProtocolsQuery,
} from "@madmedical/threshold";
import type { MetricType } from "@madmedical/medical";
import { UnderlyingCondition } from "@madmedical/medical";
import {
    adaptQuestionnaire,
    useGetLatestQuestionnaireQuery,
} from "@madmedical/preop";

enum ModalState {
    Closed,
    Details,
    Threshold,
    Upload,
}

const DoctorPatient = () => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const [modal, setModal] = useState(ModalState.Closed);
    const [metricTypeForModal, setMetricTypeForModal] = useState<MetricType>();
    const {
        data: thresholdsProtocols,
        isLoading: isThresholdsProtocolsLoading,
    } = useGetThresholdsProtocolsQuery(patientId);
    const { data: opinionsData, isLoading: isOpinionsLoading } =
        useGetOpinionsQuery({
            patientId,
            sort: "dateRange",
            order: "DESC",
            page: 1,
            pageSize: 5,
        });
    const opinions = useMemo(
        () => opinionsData?.items.map(adaptOpinion) ?? [],
        [opinionsData?.items]
    );
    const { data: documentsData, isLoading: isDocumentsLoading } =
        useGetDocumentsQuery({
            patientId,
            page: 1,
            pageSize: 5,
        });
    const { data: predictionsData, isLoading: isPredictionsLoading } =
        useGetPredictionsQuery({ patientId, page: 1, pageSize: 5 });
    const { devices, isLoading: isDevicesLoading } = useDevices();
    const { data: questionnaire } = useGetLatestQuestionnaireQuery({
        patientId,
    });

    const typedPatient = patient as PatientMe;

    // inzulin checks
    const isInsulin =
        typedPatient?.underlyingConditions?.includes(
            UnderlyingCondition.Insulin
        ) && typedPatient?.packages?.some((p) => p.type === "diabetes");

    const { data: insulinList, isLoading: isInsulinLoading } =
        useGetInsulinAdministrationsQuery(
            {
                patientId,
            },
            {
                skip: !isInsulin,
            }
        );

    // pregnancy checks
    const isPregnant = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Pregnancy
    );

    const { data: pregnancyData, isLoading: isPregnancyLoading } =
        useGetPregnancyByPatientQuery(
            {
                patientId,
            },
            {
                skip: !isPregnant,
            }
        );

    const { data: pregnancyDocuments, isLoading: isPregnancyDocumentsLoading } =
        useGetPregnancyDocumentsQuery(
            {
                patientId,
                page: 1,
                pageSize: 100,
            },
            {
                skip: !isPregnant,
            }
        );

    const navigate = useRouteNavigate();

    const onEditPregnancy = () => {
        navigate(`doctor_edit_pregnancy`, {});
    };

    const handleEditPress = () => {
        setModal(ModalState.Details);
    };

    const handleEditThresholdPress = (metricType: MetricType) => {
        setMetricTypeForModal(metricType);
        setModal(ModalState.Threshold);
    };

    const handleModalClose = () => {
        setModal(ModalState.Closed);
    };

    const handleUploadPress = () => {
        setModal(ModalState.Upload);
    };

    if (
        isPatientLoading ||
        isThresholdsProtocolsLoading ||
        isOpinionsLoading ||
        isDocumentsLoading ||
        isPredictionsLoading ||
        isDevicesLoading ||
        isPregnancyLoading ||
        isPregnancyDocumentsLoading ||
        isInsulinLoading
    ) {
        return <Spinner />;
    }

    invariant(patient);
    invariant(opinionsData);
    invariant(documentsData);
    invariant(predictionsData);
    invariant(thresholdsProtocols);

    return (
        <CurrentPatientProvider value={{ patient: patient as PatientDoctor }}>
            <ThresholdsProtocolsProvider
                value={{
                    thresholdsProtocols: thresholdsProtocols.map(
                        adaptThresholdsProtocol
                    ),
                }}
            >
                <EvaluationProvider
                    value={{
                        evaluations: opinions,
                        pagination: opinionsData.pagination,
                        onSortChange: undefined,
                        onSearchChange: undefined,
                        filterChoices: {
                            metricTypes: [],
                            alertLevels: [],
                            doctors: [],
                        },
                        onFilterChange: undefined,
                    }}
                >
                    <DocumentProvider
                        value={{
                            documents: documentsData.items.map(adaptDocument),
                            onSortChange: undefined,
                            onSearchChange: undefined,
                            filterChoices: {
                                metricTypes: [],
                                doctors: [],
                            },
                            onFilterChange: undefined,
                            stats: documentsData.stats,
                            pregnancyDocuments:
                                pregnancyDocuments?.items.map(adaptDocument),
                        }}
                    >
                        <PredictionProvider
                            value={{
                                predictions:
                                    predictionsData.items.map(adaptPrediction),
                                pagination: predictionsData.pagination,
                            }}
                        >
                            <DeviceProvider
                                value={{
                                    devices: devices.filter(
                                        (device) => device.isConnected
                                    ),
                                }}
                            >
                                <PreOpProvider
                                    value={{
                                        questionnaire: questionnaire
                                            ? adaptQuestionnaire(questionnaire)
                                            : null,
                                    }}
                                >
                                    <CurrentPatientProvider
                                        value={{
                                            patient: adaptPatient(patient),
                                        }}
                                    >
                                        <DoctorPatientUi
                                            charts={
                                                <DashboardWidgets
                                                    isInsulin={isInsulin}
                                                />
                                            }
                                            onEditPress={handleEditPress}
                                            onEditThresholdPress={
                                                handleEditThresholdPress
                                            }
                                            insulinList={
                                                isInsulin &&
                                                !isInsulinLoading && (
                                                    <DashboardInsulinMeasurementList
                                                        data={
                                                            insulinList?.items ??
                                                            []
                                                        }
                                                        isPatient={false}
                                                    />
                                                )
                                            }
                                            pregnancyList={
                                                isPregnant &&
                                                !isPregnancyLoading && (
                                                    <PregnancyDoctorInfos
                                                        data={pregnancyData}
                                                        onEditPregnancy={
                                                            onEditPregnancy
                                                        }
                                                    />
                                                )
                                            }
                                            handleUploadPress={
                                                handleUploadPress
                                            }
                                        />
                                    </CurrentPatientProvider>
                                </PreOpProvider>
                                <EditPatientDetailsModal
                                    open={modal === ModalState.Details}
                                    onClose={handleModalClose}
                                />
                                {metricTypeForModal && (
                                    <EditThresholdModal
                                        metricType={metricTypeForModal}
                                        open={modal === ModalState.Threshold}
                                        onClose={handleModalClose}
                                    />
                                )}
                            </DeviceProvider>
                        </PredictionProvider>
                        <UploadModal
                            open={modal === ModalState.Upload}
                            onClose={handleModalClose}
                        />
                    </DocumentProvider>
                </EvaluationProvider>
            </ThresholdsProtocolsProvider>
        </CurrentPatientProvider>
    );
};

export default DoctorPatient;
