export const Actionsheet = {
    defaultProps: {
        size: "full",
        justifyContent: "flex-end",
        animationPreset: "slide",
    },
};

export const ActionsheetContent = {
    defaultProps: {
        _dragIndicator: {
            bg: "gray.150",
        },
    },

    baseStyle: () => ({
        alignItems: "center",
        px: 0,
        py: 0,
        borderRadius: "none",
        roundedTop: 0,
        justifyContent: "space-between",
        _dragIndicator: {
            height: 1,
            width: 10,
            borderRadius: 10,
        },
        _dragIndicatorWrapper: {
            pt: 3,
            pb: 3,
            mt: 0,
            width: "100%",
            alignItems: "center",
            collapsable: false,
        },
        _dragIndicatorWrapperOffSet: {
            py: 2,
            collapsable: false,
        },
    }),
};

export const ActionsheetItem = {
    baseStyle: () => ({
        width: "100%",
        justifyContent: "flex-start",
        p: 4,
        bg: "white",
        _stack: {
            space: 4,
        },
        _text: {
            color: "text.900",
            fontSize: "md",
            fontWeight: "normal",
        },
        _disabled: {
            opacity: 40,
        },
        _icon: {
            color: "gray.800",
        },
        _hover: {
            bg: "gray.200",
        },
        _pressed: {
            bg: "gray.400",
        },
        _focusVisible: {
            _web: {
                outlineWidth: "0",
                style: { boxShadow: `none` },
                bg: "gray.300",
            },
            bg: "gray.300",
        },
    }),
};
