import type { ComponentProps, ReactNode } from "react";
import { Box } from "native-base";

interface Props
    extends Pick<
        ComponentProps<typeof Box>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
}

const CardHeader = ({ children, ...rest }: Props) => (
    <Box {...rest}>{children}</Box>
);

export default CardHeader;
