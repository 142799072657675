import { PregnancyDataProvider, Spinner } from "@madmedical/ui";
import type { ReactNode } from "react";
import { UnderlyingCondition } from "@madmedical/medical";
import type { PatientMe } from "@madmedical/user";
import { usePatient } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import type { DocumentWithService } from "@madmedical/document";
import { useGetPregnancyDocumentsQuery } from "@madmedical/document";
import { useGetPregnancyByPatientQuery } from "../api";

interface Props {
    children: ReactNode;
}
const PregnancyDataProviderWrapper = ({ children }: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();

    invariant(patient);

    const typedPatient = patient as PatientMe;
    const isPregnant = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Pregnancy
    );

    const { data: pregnancyData, isLoading: isPregnancyLoading } =
        useGetPregnancyByPatientQuery(
            {
                patientId,
            },
            {
                skip: !isPregnant,
            }
        );

    invariant(pregnancyData);

    const { data: pregnancyDocuments, isLoading: isPregnancyDocumentsLoading } =
        useGetPregnancyDocumentsQuery(
            {
                patientId,
                page: 1,
                pageSize: 100,
            },
            {
                skip: !isPregnant,
            }
        );

    if (isPregnancyLoading || isPatientLoading || isPregnancyDocumentsLoading) {
        return <Spinner />;
    }

    return (
        <PregnancyDataProvider
            value={{
                pregnancyData,
                patient: typedPatient,
                pregnancyDocuments:
                    pregnancyDocuments?.items as DocumentWithService[],
            }}
        >
            {children}
        </PregnancyDataProvider>
    );
};

export default PregnancyDataProviderWrapper;
