import Svg, { Path, Rect } from "react-native-svg";

const Cauliflower = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M29.4 68.7023C29.4 68.7023 20.1746 53.6503 36.0038 46.1729C36.0038 46.1729 35.5486 37.3739 46.3697 36.2677H51.2498C51.2498 36.2677 53.4754 28.1103 64.2109 27.9163C69.2241 27.8254 72.3622 30.5304 74.2831 33.4398C74.2831 33.4398 82.9086 30.0526 88.2497 36.7532C89.5246 38.3995 90.4064 40.3153 90.8279 42.3543L91.2976 47.0469C91.2976 47.0469 103.302 50.7364 100.68 64.2346L98.3492 90.3572L89.512 95.309C64.6358 97.6428 45.7766 93.2728 32.4114 82.7808L29.4 68.7023Z"
            fill="#F0E6E5"
        />
        <Path
            d="M100.679 64.2358C101.986 57.507 99.6586 53.2163 96.9954 50.6043C98.5229 54.2843 97.8413 57.1309 96.9335 58.9344C96.6191 59.5336 96.1189 60.0147 95.5079 60.3053C94.8969 60.596 94.2081 60.6807 93.5449 60.5466C90.8796 60.0541 88.3361 61.1519 88.3361 61.1519C86.0734 59.7773 83.7196 59.9938 82.1377 60.4213C82.0347 60.4493 81.9441 60.5111 81.8805 60.5969C81.817 60.6826 81.7841 60.7873 81.7872 60.894C81.7903 61.0007 81.8293 61.1032 81.8978 61.1851C81.9662 61.267 82.0603 61.3234 82.1648 61.3453C82.9431 61.491 83.6852 61.7886 84.3484 62.2212C85.0117 62.6538 85.5833 63.2128 86.0304 63.8664C86.2023 64.1278 86.4399 64.3395 86.7194 64.4802C86.9989 64.6209 87.3104 64.6857 87.6228 64.6682C88.3051 64.6235 88.9903 64.6529 89.6663 64.7558C89.9039 64.7925 90.1298 64.8839 90.3261 65.0226C90.5225 65.1614 90.6839 65.3439 90.7978 65.5556C90.9117 65.7674 90.9749 66.0026 90.9825 66.243C90.9901 66.4833 90.9418 66.7221 90.8415 66.9406C89.1995 70.5554 89.4644 74.9613 90.0722 77.6261C92.4858 88.2083 78.84 91.3644 76.655 77.1263C74.8316 65.2453 64.208 61.7988 64.208 61.7988C64.208 61.7988 62.2262 62.1953 59.6448 65.3449C50.1742 60.9906 43.6438 64.9252 40.3026 68.0965C40.0142 68.3735 39.6665 68.5813 39.2859 68.7041C38.9053 68.827 38.5018 68.8616 38.1058 68.8055C37.7099 68.7494 37.3319 68.6039 37.0004 68.3802C36.669 68.1564 36.3927 67.8602 36.1926 67.5139C35.4224 66.1928 34.5469 64.9359 33.5747 63.7555C27.4608 56.6414 31.7764 48.842 31.8429 48.7275C21.9957 56.6195 29.399 68.7037 29.399 68.7037L32.4095 82.7846C45.7747 93.2756 64.6339 97.6456 89.5102 95.3128L98.347 90.3611L100.679 64.2358Z"
            fill="#C3B7B6"
        />
        <Path
            d="M63.1963 97.1287C63.1963 97.1287 67.9884 91.7375 81.899 93.8004C88.8545 94.8319 92.4014 92.3639 94.2095 89.6377C94.9525 88.4989 95.4538 87.2196 95.6822 85.8791C95.9106 84.5386 95.8613 83.1654 95.5372 81.8448C94.6218 78.0276 94.3109 74.4848 95.0772 71.7694C97.4684 63.3 103.184 61.953 105.907 61.8118C106.031 61.8042 106.154 61.832 106.262 61.8919C106.37 61.9519 106.459 62.0415 106.519 62.1502C106.578 62.2589 106.605 62.3823 106.596 62.5058C106.588 62.6293 106.545 62.7479 106.471 62.8476C103.316 67.0688 103.885 76.3148 104.2 79.5651C104.301 80.5463 104.318 81.5345 104.249 82.5185C103.116 97.415 90.6731 98.7644 83.5331 98.29C80.2334 98.0929 76.9277 98.559 73.8112 99.6608C67.8379 101.728 63.8503 101.141 63.8503 101.141L63.1963 97.1287Z"
            fill="#7B9791"
        />
        <Path
            d="M24.6401 60.9871C28.1526 62.7597 35.4926 68.024 34.3526 80.7225L57.2052 92.8505L63.8508 101.141C63.8508 101.141 58.4482 101.141 54.6094 99.2932C52.7693 98.4261 50.6911 98.2025 48.7087 98.6584C43.4566 99.8219 33.3452 100.903 27.8942 93.8441C21.4353 85.4813 24.4994 74.1377 24.4994 74.1377C24.4994 74.1377 25.9679 66.8613 23.7504 61.8392C23.6943 61.716 23.6778 61.5785 23.7033 61.4455C23.7288 61.3126 23.795 61.1909 23.8927 61.0972C23.9905 61.0036 24.115 60.9427 24.2489 60.923C24.3828 60.9033 24.5195 60.9257 24.6401 60.9871Z"
            fill="#7B9791"
        />
        <Path
            d="M57.5434 68.085C55.5787 67.0121 52.6793 66.2574 48.8456 67.3974C43.3297 69.0372 41.6829 72.5594 41.1912 74.5163C41.1912 74.5163 34.0747 72.498 32.1617 81.4244C31.2249 85.7966 32.5313 88.7923 34.1099 90.7108C34.1099 90.7108 39.1761 96.1496 47.4418 92.5903C48.9122 91.9571 51.1115 92.0818 52.672 92.4401C54.3748 92.8305 55.5007 92.9391 57.3307 90.6366L57.5434 68.085Z"
            fill="#F0E6E5"
        />
        <Path
            d="M52.4485 93.5031C54.151 93.8938 56.4732 93.8216 58.3035 91.5191C61.5976 87.3748 60.6413 70.6909 60.6413 70.6909C59.8024 69.6149 58.7465 68.7274 57.5423 68.0859C56.3744 69.533 50.957 76.699 50.3198 85.8682C50.2503 86.9119 49.9397 87.9253 49.4125 88.8287C48.8852 89.7322 48.1556 90.501 47.2811 91.0748C44.3776 92.9619 39.612 94.5884 34.1104 90.7085C35.0839 91.8996 36.3044 92.8652 37.6876 93.5383C40.8921 94.8976 44.4963 94.9741 47.7557 93.752C49.2609 93.207 50.894 93.1204 52.4485 93.5031Z"
            fill="#C3B7B6"
        />
        <Path
            d="M66.1148 100.664C65.3753 101.018 64.558 101.18 63.7391 101.133C62.9203 101.087 62.1265 100.834 61.4318 100.398C58.7332 98.7075 54.5461 94.8318 54.0198 86.8871C53.4159 77.7732 59.7779 69.9269 62.7409 66.7957C63.1504 66.365 63.7083 66.1061 64.3016 66.0714C64.8949 66.0367 65.4792 66.2287 65.9362 66.6087C68.9836 69.1219 74.9612 75.4404 74.5642 86.1024C74.2203 95.343 69.2022 99.1753 66.1148 100.664Z"
            fill="#7B9791"
        />
        <Path
            d="M41.1912 74.5154C41.9624 74.7672 42.6753 75.171 43.2878 75.7029C43.9003 76.2349 44.4 76.8842 44.7573 77.6126C44.8082 77.7185 44.8923 77.8048 44.9968 77.8584C45.1013 77.9121 45.2205 77.9301 45.3362 77.9096C45.4519 77.8892 45.5578 77.8315 45.6376 77.7454C45.7175 77.6592 45.7669 77.5493 45.7785 77.4324C45.9598 75.495 45.4914 72.7003 41.7494 71.4551L41.1912 74.5154Z"
            fill="#C3B7B6"
        />
        <Path
            d="M90.8255 42.3558C83.8968 41.9851 80.9969 47.826 80.9969 47.826L83.5404 49.8121C87.1019 44.9989 91.2966 47.0481 91.2966 47.0481C91.3155 45.4713 91.1575 43.8973 90.8255 42.3558Z"
            fill="#C3B7B6"
        />
        <Path
            d="M76.6645 45.0109C85.1188 44.3861 86.5708 49.8545 85.9684 54.5153C85.9436 54.7049 85.8526 54.8796 85.7116 55.0086C85.5705 55.1377 85.3885 55.2128 85.1975 55.2208C85.0065 55.2288 84.8188 55.1691 84.6675 55.0523C84.5162 54.9355 84.4109 54.769 84.3703 54.5822C83.5285 50.7569 81.1276 47.8567 76.5154 46.6085C76.3275 46.5612 76.1626 46.4483 76.0507 46.29C75.9387 46.1318 75.8871 45.9388 75.9051 45.7458C75.9231 45.5528 76.0096 45.3727 76.1489 45.2379C76.2882 45.1031 76.4711 45.0226 76.6645 45.0109Z"
            fill="#C3B7B6"
        />
        <Path
            d="M58.4823 43.1167C56.0924 42.2928 53.5058 42.2311 51.0793 42.94C50.7465 40.7905 50.7895 38.5996 51.2065 36.4648C51.2335 36.3363 51.2498 36.2678 51.2498 36.2678C49.6267 36.1158 47.9929 36.1165 46.37 36.2697C45.6491 39.5858 46.6465 42.6286 47.586 44.5665C45.9159 45.7904 44.7635 47.9042 43.9872 49.9661C43.9048 50.1891 43.9033 50.434 43.9829 50.658C44.0625 50.882 44.2182 51.071 44.4229 51.192C44.6275 51.3131 44.8681 51.3584 45.1028 51.3203C45.3374 51.2821 45.5513 51.1629 45.707 50.9832C49.071 47.0356 53.1631 45.1859 58.1725 45.1023C58.4147 45.1016 58.6486 45.0146 58.8324 44.8568C59.0161 44.6991 59.1375 44.481 59.1749 44.2417C59.2122 44.0025 59.163 43.7577 59.036 43.5515C58.9091 43.3453 58.7128 43.1911 58.4823 43.1167Z"
            fill="#C3B7B6"
        />
        <Path
            d="M24.4591 87.4889C24.2361 87.4886 24.0204 87.4095 23.8501 87.2655C23.6799 87.1215 23.5661 86.9219 23.5288 86.702C23.0328 83.7813 23.9531 80.8267 26.2637 77.9203C27.2615 76.6227 27.765 75.0123 27.684 73.3774C27.6568 73.1283 27.7297 72.8786 27.8867 72.6832C28.0436 72.4878 28.2718 72.3628 28.5209 72.3356C28.77 72.3085 29.0198 72.3814 29.2151 72.5384C29.4105 72.6953 29.5355 72.9234 29.5627 73.1726C29.7031 75.3034 29.0556 77.4115 27.7432 79.096C25.7809 81.5643 24.9897 84.0169 25.3916 86.3859C25.4142 86.5215 25.407 86.6603 25.3706 86.7928C25.3342 86.9253 25.2694 87.0483 25.1806 87.1532C25.0919 87.2582 24.9814 87.3426 24.8568 87.4005C24.7322 87.4585 24.5965 87.4886 24.4591 87.4889Z"
            fill="#6D6260"
        />
        <Path
            d="M69.0897 85.9077C68.9716 85.8693 68.8472 85.8546 68.7234 85.8643C68.5997 85.8741 68.4791 85.9081 68.3685 85.9645C68.2579 86.0209 68.1595 86.0985 68.079 86.193C67.9985 86.2874 67.9373 86.3968 67.8991 86.5149C67.7367 87.018 67.2497 88.0219 65.8194 89.0928C65.5813 89.2722 65.3588 89.4713 65.1542 89.6881V75.7033C65.1501 75.4555 65.0487 75.2193 64.872 75.0455C64.6952 74.8718 64.4573 74.7744 64.2095 74.7744C63.9617 74.7744 63.7237 74.8718 63.547 75.0455C63.3703 75.2193 63.2689 75.4555 63.2647 75.7033V80.9711C62.3293 79.6877 61.0796 78.6663 59.6356 78.0052C59.4037 77.9125 59.1445 77.9153 58.9147 78.0131C58.6849 78.1109 58.5031 78.2957 58.4092 78.5271C58.3152 78.7585 58.3167 79.0177 58.4133 79.248C58.51 79.4784 58.6938 79.6611 58.9248 79.7562C59.102 79.8295 63.2647 81.6157 63.2647 87.0726V101.141C63.2626 101.266 63.2855 101.391 63.332 101.507C63.3785 101.623 63.4478 101.73 63.5357 101.819C63.6237 101.908 63.7285 101.979 63.8442 102.028C63.9599 102.076 64.0841 102.101 64.2095 102.101C64.3349 102.101 64.4591 102.076 64.5748 102.028C64.6905 101.979 64.7953 101.908 64.8833 101.819C64.9712 101.73 65.0404 101.623 65.087 101.507C65.1335 101.391 65.1564 101.266 65.1542 101.141V94.8656C65.2059 93.7718 65.5025 91.6916 66.9523 90.6049C68.7065 89.2906 69.4128 87.9723 69.6963 87.0981C69.7737 86.8598 69.7532 86.6005 69.6395 86.3773C69.5257 86.1541 69.3279 85.9852 69.0897 85.9077Z"
            fill="#6D6260"
        />
        <Path
            d="M97.7068 96.185C97.5088 96.1852 97.3157 96.1232 97.1549 96.0077C96.994 95.8922 96.8735 95.7291 96.8104 95.5414C96.7473 95.3537 96.7447 95.1509 96.8031 94.9617C96.8614 94.7725 96.9777 94.6063 97.1356 94.4868C100.278 92.1075 101.02 87.7692 99.2238 82.2711C97.1651 75.9691 99.257 72.8649 99.3471 72.7359C99.4893 72.5295 99.7076 72.3881 99.954 72.3428C100.2 72.2974 100.455 72.3518 100.661 72.4939C100.867 72.6361 101.009 72.8544 101.054 73.1008C101.1 73.3473 101.045 73.6016 100.903 73.8079C100.862 73.8712 99.2949 76.4034 101.02 81.6845C103.085 88.0079 102.111 93.0895 98.2764 95.9932C98.1127 96.1177 97.9126 96.1851 97.7068 96.185Z"
            fill="#6D6260"
        />
        <Path
            d="M39.2676 58.1611C39.7986 58.1611 40.229 57.7307 40.229 57.1997C40.229 56.6687 39.7986 56.2383 39.2676 56.2383C38.7366 56.2383 38.3062 56.6687 38.3062 57.1997C38.3062 57.7307 38.7366 58.1611 39.2676 58.1611Z"
            fill="#C3B7B6"
        />
        <Path
            d="M44.8279 54.7929C45.3589 54.7929 45.7893 54.3625 45.7893 53.8315C45.7893 53.3006 45.3589 52.8701 44.8279 52.8701C44.2969 52.8701 43.8665 53.3006 43.8665 53.8315C43.8665 54.3625 44.2969 54.7929 44.8279 54.7929Z"
            fill="#C3B7B6"
        />
        <Path
            d="M44.6642 60.5166C45.1952 60.5166 45.6256 60.0861 45.6256 59.5552C45.6256 59.0242 45.1952 58.5938 44.6642 58.5938C44.1333 58.5938 43.7028 59.0242 43.7028 59.5552C43.7028 60.0861 44.1333 60.5166 44.6642 60.5166Z"
            fill="#C3B7B6"
        />
        <Path
            d="M69.8276 40.582C70.3585 40.582 70.789 40.1515 70.789 39.6206C70.789 39.0896 70.3585 38.6592 69.8276 38.6592C69.2966 38.6592 68.8662 39.0896 68.8662 39.6206C68.8662 40.1515 69.2966 40.582 69.8276 40.582Z"
            fill="#C3B7B6"
        />
        <Path
            d="M73.3964 46.0156C73.9273 46.0156 74.3578 45.5851 74.3578 45.0542C74.3578 44.5232 73.9273 44.0928 73.3964 44.0928C72.8654 44.0928 72.435 44.5232 72.435 45.0542C72.435 45.5851 72.8654 46.0156 73.3964 46.0156Z"
            fill="#C3B7B6"
        />
        <Path
            d="M67.6707 46.0605C68.2017 46.0605 68.6321 45.6301 68.6321 45.0991C68.6321 44.5681 68.2017 44.1377 67.6707 44.1377C67.1398 44.1377 66.7093 44.5681 66.7093 45.0991C66.7093 45.6301 67.1398 46.0605 67.6707 46.0605Z"
            fill="#C3B7B6"
        />
        <Path
            d="M79.5352 73.2763C80.0662 73.2763 80.4966 72.8459 80.4966 72.3149C80.4966 71.7839 80.0662 71.3535 79.5352 71.3535C79.0042 71.3535 78.5738 71.7839 78.5738 72.3149C78.5738 72.8459 79.0042 73.2763 79.5352 73.2763Z"
            fill="#C3B7B6"
        />
        <Path
            d="M85.6264 71.0039C86.1574 71.0039 86.5878 70.5734 86.5878 70.0425C86.5878 69.5115 86.1574 69.0811 85.6264 69.0811C85.0954 69.0811 84.665 69.5115 84.665 70.0425C84.665 70.5734 85.0954 71.0039 85.6264 71.0039Z"
            fill="#C3B7B6"
        />
        <Path
            d="M69.6127 83.9013C70.1437 83.9013 70.5741 83.4709 70.5741 82.9399C70.5741 82.4089 70.1437 81.9785 69.6127 81.9785C69.0817 81.9785 68.6513 82.4089 68.6513 82.9399C68.6513 83.4709 69.0817 83.9013 69.6127 83.9013Z"
            fill="#6D6260"
        />
        <Path
            d="M41.1068 83.8271C41.6378 83.8271 42.0682 83.3967 42.0682 82.8657C42.0682 82.3347 41.6378 81.9043 41.1068 81.9043C40.5758 81.9043 40.1454 82.3347 40.1454 82.8657C40.1454 83.3967 40.5758 83.8271 41.1068 83.8271Z"
            fill="#C3B7B6"
        />
        <Path
            d="M45.1488 87.0722C45.6798 87.0722 46.1102 86.6418 46.1102 86.1108C46.1102 85.5798 45.6798 85.1494 45.1488 85.1494C44.6179 85.1494 44.1874 85.5798 44.1874 86.1108C44.1874 86.6418 44.6179 87.0722 45.1488 87.0722Z"
            fill="#C3B7B6"
        />
    </Svg>
);

export default Cauliflower;
