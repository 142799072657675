import { Box } from "native-base";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { Ulid } from "@madmedical/utils";
import { getDayListFull } from "../../util/calendar";
import InsulinReminderTable from "../../organisms/InsulinReminderTable";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import type InsulinMeasurementListItem from "../../molecules/ListItem/InsulinMeasurementListItem";
import type { InsulinReminder } from "../../providers/insulinReminder";
import InsulinReminderContentMobile from "./InsulinReminderContentMobile";

export type MeasurementData = Omit<
    ComponentProps<typeof InsulinMeasurementListItem>,
    "onDeletePress"
> & {
    id: Ulid;
    editable?: boolean;
} & InsulinReminder;

type HeaderType = {
    title: string;
    badge: number;
};

export type SectionType = {
    header: HeaderType;
    data: MeasurementData[];
};

interface Props {
    data: InsulinReminder[];
    onEndReached?: () => void;
    onDeletePress: (id: Ulid) => void;
    onEditPress?: (reminder: InsulinReminder) => void;
}

type DesktopLayoutProps = {
    sections: SectionType[];
    onEndReached?: Props["onEndReached"];
    onDeletePress: Props["onDeletePress"];
    onEditPress?: Props["onEditPress"];
};

const DesktopLayout = ({
    sections,
    onDeletePress,
    onEditPress,
}: DesktopLayoutProps) => {
    const lastIndex = sections.length - 1;

    return (
        <>
            {sections.map((section, index) => (
                <Box
                    width="full"
                    px={6}
                    py={4}
                    key={`measurement-table-section-${index}`}
                    borderBottomColor={
                        index === lastIndex ? "transparent" : "gray.100"
                    }
                    borderBottomStyle="solid"
                    borderBottomWidth={1}
                    marginBottom={index === lastIndex ? 0 : 3}
                >
                    <ListHeader
                        title={section.header.title}
                        badge={section.header.badge}
                        variant="inverted"
                        size="md"
                        px={0}
                        pt={0}
                        pb={0}
                    />

                    <InsulinReminderTable
                        data={section?.data ?? []}
                        onDeletePress={onDeletePress}
                        onEditPress={onEditPress}
                    />
                </Box>
            ))}
        </>
    );
};

const InsulinReminderList = ({ data, onDeletePress, onEditPress }: Props) => {
    const { isSmallScreen } = useResponsive();
    // const { patient } = useCurrentPatient();
    const weekdays = getDayListFull();

    const sections = useMemo(() => {
        const groupByDay = weekdays.map((day, index) => {
            const date = data.filter((item) => {
                //console.log("index", index, item?.daysOfWeek);
                const days = item?.daysOfWeek;

                return days?.includes(index);
            });

            return {
                header: {
                    title: day,
                    badge: date.length,
                },
                data: date.sort((a, b) => {
                    const aDate = new Date(a.firstOccurrence);
                    const bDate = new Date(b.firstOccurrence);
                    const aCompare = aDate.getHours() * 60 + aDate.getMinutes();
                    const bCompare = bDate.getHours() * 60 + bDate.getMinutes();

                    return aCompare - bCompare;
                }),
            };
        });

        return [...groupByDay.slice(1), groupByDay[0]];
    }, [data, weekdays]);

    return (
        <Box width="full">
            <Paper
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
                width="full"
                borderWidth={0}
            >
                {isSmallScreen ? (
                    <InsulinReminderContentMobile
                        sections={sections}
                        onDeletePress={onDeletePress}
                        onEditPress={onEditPress}
                    />
                ) : (
                    <DesktopLayout
                        sections={sections}
                        onDeletePress={onDeletePress}
                        onEditPress={onEditPress}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default InsulinReminderList;
