import { VStack } from "native-base";
import type { PreOpQuestionKey } from "@madmedical/medical";
import { useCallback } from "react";
import FormSelect from "../../../form/FormSelect";
import PreOpSurveyFormRow from "../../../molecules/PreOpSurveyFormRow";
import FormControl from "../../../form/FormControl";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Survey2 = ({ onPreviousPress, onNextPress }: Props) => {
    const validate = useCallback(
        ({
            answers,
            subAnswers,
        }: {
            answers?: Record<PreOpQuestionKey, boolean>;
            subAnswers?: {
                smoking?: [string | undefined];
                alcohol?: [string | undefined];
                howManyFloors?: [string | undefined];
            };
        }) =>
            (!answers?.smoking ||
                (subAnswers?.smoking?.[0] ?? "").length > 1) &&
            (!answers?.alcohol ||
                (subAnswers?.alcohol?.[0] ?? "").length > 1) &&
            subAnswers?.howManyFloors?.[0] !== undefined,
        []
    );

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            validate={validate}
            width="full"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <VStack>
                <PreOpSurveyFormRow
                    name="howManyFloors"
                    hasOptionalAnswer={false}
                    isRequired={true}
                    answerInput={
                        <FormSelect
                            name="subAnswers.howManyFloors.0"
                            choices={Array.from(Array(15).keys()).map(
                                (item) => ({
                                    value: `${item}`,
                                    label: `${item}`,
                                })
                            )}
                            size="xl"
                            placeholder="Válasszon..."
                        />
                    }
                />
                <PreOpSurveyFormRow name="legCramps" />
                <PreOpSurveyFormRow
                    name="smoking"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.smoking.0"
                            size="md"
                            placeholder="Mennyit dohányzik?"
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow
                    name="alcohol"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.alcohol.0"
                            size="md"
                            placeholder="Mennyit fogyaszt?"
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow name="drugs" />
                <PreOpSurveyFormRow name="dentures" />
                <PreOpSurveyFormRow name="looseTooth" />
                <PreOpSurveyFormRow name="pacemaker" />
                <PreOpSurveyFormRow name="contactLenses" />
                <PreOpSurveyFormRow name="jaundice" />
                <PreOpSurveyFormRow name="jointProsthesis" />
                <PreOpSurveyFormRow name="otherProsthesis" />
                <PreOpSurveyFormRow name="piercing" hasDivider={false} />
            </VStack>
        </PreOpWrapper>
    );
};

export default Survey2;
