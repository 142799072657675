import type { Document, DocumentExpanded } from "./model";

export default (document: Document | DocumentExpanded) =>
    ({
        ...document,
        createdAt: new Date(document.createdAt),
        ...("opinion" in document && document.opinion
            ? {
                  metricType: document.opinion.metricType,
                  doctor: document.opinion.doctor,
              }
            : {}),
    } as const);
