import Svg, { Path, Rect } from "react-native-svg";

const Watermelon = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M45.9557 42.2562C45.9557 42.2562 45.2251 38.9398 48.193 35.6119C48.6522 35.1012 48.925 34.45 48.967 33.7645C49.0089 33.079 48.8174 32.3994 48.4238 31.8366C48.3583 31.7446 48.2885 31.6537 48.2154 31.566C47.8698 31.1579 47.4233 30.8473 46.9204 30.6653C46.4175 30.4832 45.8757 30.4361 45.3489 30.5285C44.8221 30.6208 44.3287 30.8496 43.9178 31.1919C43.5068 31.5342 43.1927 31.9781 43.0066 32.4795C42.1829 34.6442 40.1973 37.9874 35.8179 37.8166C29.3665 37.5652 30.6767 30.2653 30.6767 30.2653H32.3002C32.4891 30.2646 32.6762 30.3014 32.8506 30.3736C33.0251 30.4458 33.1835 30.552 33.3166 30.686C33.4497 30.8199 33.5548 30.979 33.6259 31.154C33.697 31.3289 33.7325 31.5163 33.7306 31.7051C33.7168 32.5358 33.9427 33.5221 34.9276 34.0738C35.3242 34.2887 35.7703 34.3957 36.2212 34.3839C36.6721 34.3721 37.112 34.242 37.4969 34.0067C38.2275 33.567 39.0947 32.7109 39.5682 31.0481C40.5353 27.6528 44.2903 25.5227 47.6997 26.4386C49.8541 27.0177 52.0429 28.8888 52.7342 31.1436C53.4397 33.4436 53.2787 35.8048 51.7428 37.6575C50.7194 38.8919 50.3579 40.0233 50.908 42.2575L45.9557 42.2562Z"
            fill="#7B9791"
        />
        <Path
            d="M69.7296 95.9012C66.2093 99.276 61.8831 101.693 57.1635 102.92C55.2713 102.723 53.36 103.007 51.6072 103.747C51.1923 103.765 50.7761 103.774 50.3585 103.774C49.7513 103.774 49.1483 103.754 48.5496 103.715C47.1268 103.041 45.4519 102.798 43.5618 102.922C38.0599 101.482 33.1075 98.4455 29.3289 94.1951C29.3289 94.1951 21.4407 83.8078 24.1788 67.3087C26.9169 50.8095 38.235 43.7962 38.235 43.7962C40.3647 42.7514 42.6202 41.9853 44.9456 41.5167L54.5853 41.3057C56.5168 41.6061 58.4107 42.1122 60.2347 42.8153C60.2347 42.8153 72.0142 48.0444 75.81 67.8374C79.6058 87.6304 69.7296 95.9012 69.7296 95.9012Z"
            fill="#B6D2C6"
        />
        <Path
            d="M21.1106 72.3775C21.1106 80.8561 24.2424 88.5462 29.3289 94.1961C28.997 92.7494 28.8582 91.2651 28.9163 89.782C29.057 86.3566 28.3334 84.2276 27.4448 82.9091C26.8945 82.0849 26.5321 81.1498 26.3832 80.1701C26.2344 79.1903 26.3028 78.1898 26.5835 77.2394C27.3141 74.7367 26.9052 72.2933 26.2805 70.4244C25.9316 69.3395 25.855 68.1854 26.0574 67.064C26.2599 65.9425 26.7351 64.888 27.4413 63.9936C29.9753 60.7105 30.9497 57.5969 31.3123 55.4514C31.8031 52.4826 33.0543 49.6915 34.9439 47.3497C35.966 46.098 37.0654 44.9114 38.2356 43.7969C28.1334 48.7432 21.1106 59.6796 21.1106 72.3775Z"
            fill="#7B9791"
        />
        <Path
            d="M60.2341 42.8174C61.8029 44.211 63.2587 45.7268 64.5879 47.3505C66.4775 49.6923 67.7286 52.4834 68.2192 55.4522C68.5821 57.5977 69.5564 60.7114 72.0905 63.9944C72.7967 64.8889 73.2719 65.9433 73.4744 67.0648C73.6768 68.1863 73.6002 69.3403 73.2513 70.4252C72.4966 72.6204 72.3913 74.9868 72.9483 77.2403C75.7378 87.991 69.7283 95.9008 69.7283 95.9008C75.7854 90.1481 79.6047 81.7429 79.6047 72.3783C79.6058 58.7586 71.5271 47.166 60.2341 42.8174Z"
            fill="#7B9791"
        />
        <Path
            d="M46.4918 49.6677C46.6812 46.8709 48.2236 43.1013 54.5851 41.3082C51.3848 40.8091 48.1215 40.8806 44.9462 41.5192C42.7012 43.5681 41.2906 46.3729 40.9842 49.3968C40.8011 51.0045 40.1619 52.5263 39.1421 53.7825C37.7035 55.5036 36.6919 57.5402 36.1897 59.7263C35.6875 61.9125 35.709 64.1864 36.2525 66.3627C36.7076 68.3029 36.6651 70.3266 36.1288 72.2459C35.0831 75.8679 35.4072 79.7481 37.0393 83.1465C38.0862 85.3653 38.5162 87.8252 38.284 90.2676C37.9864 93.7871 38.6014 98.9905 43.5627 102.922C45.1972 103.339 46.8668 103.604 48.5502 103.714C46.1289 101.652 42.2436 96.9692 43.2225 88.6354C43.5112 86.2956 42.9904 83.9276 41.7468 81.9248C40.4612 79.8773 39.4925 76.7322 41.5728 72.8926C42.8065 70.5912 43.0862 67.8971 42.3518 65.3913C41.589 62.7617 41.4502 59.1626 44.2429 55.6593C45.5839 53.937 46.3683 51.847 46.4918 49.6677Z"
            fill="#7B9791"
        />
        <Path
            d="M63.0082 64.9962C64.2023 60.3351 62.5715 56.9246 61.0175 54.9003C59.8895 53.4458 59.2137 51.6921 59.0738 49.8568C59.0468 49.4837 59.0037 49.1316 58.9529 48.8112C58.8566 48.2117 58.5864 47.6536 58.1758 47.2063C57.7652 46.7589 57.2322 46.442 56.6431 46.2948C56.6069 46.2856 56.5709 46.2766 56.5349 46.2677C56.3013 46.2131 56.0574 46.2203 55.8274 46.2886C55.5974 46.357 55.3892 46.4842 55.2234 46.6576C55.0575 46.831 54.9397 47.0446 54.8816 47.2774C54.8236 47.5102 54.8272 47.7542 54.8922 47.9851C55.1353 48.8117 55.2215 49.6766 55.1462 50.5349C54.9529 52.6389 55.4125 54.7514 56.4624 56.585C57.6866 58.6329 58.0776 61.0728 57.5547 63.4008C56.9056 66.3554 57.2381 69.4411 58.5018 72.1895C60.1145 75.7275 59.6077 78.5316 58.5876 80.5299C57.1323 83.3372 56.4791 86.4913 56.6997 89.6457C57.2249 96.6339 53.6001 101.582 51.608 103.743C53.4835 103.659 55.3453 103.383 57.164 102.918C61.3581 99.4269 61.9264 93.2711 61.7754 89.2084C61.6721 86.9512 62.2867 84.7193 63.531 82.8331C66.4471 78.5018 64.9045 73.2654 63.5085 70.1907C62.7555 68.5666 62.579 66.7342 63.0082 64.9962Z"
            fill="#7B9791"
        />
        <Path
            d="M94.6359 52.3223L93.1149 54.7576C87.4326 70.4323 79.2841 84.235 67.7132 95.4403L66.1316 97.9733C66.0119 98.1639 65.9326 98.3769 65.8984 98.5994C65.8642 98.8218 65.8759 99.0488 65.9328 99.2666C65.9897 99.4843 66.0906 99.6881 66.2292 99.8653C66.3678 100.043 66.5412 100.19 66.7388 100.297C73.1394 103.727 80.6045 104.596 87.6218 102.728C94.6391 100.86 100.684 96.3942 104.532 90.2358C108.38 84.0775 109.743 76.6867 108.346 69.5607C106.949 62.4347 102.895 56.1062 97.0062 51.8572C96.8225 51.7248 96.6136 51.6315 96.3924 51.5831C96.1712 51.5348 95.9424 51.5323 95.7202 51.5759C95.498 51.6195 95.2871 51.7082 95.1006 51.8366C94.9141 51.965 94.7559 52.1303 94.6359 52.3223Z"
            fill="#B6D2C6"
        />
        <Path
            d="M93.1065 54.7529L67.7131 95.4413C71.3206 97.697 75.4733 98.9299 79.7273 99.0082C84.976 99.1399 90.1229 97.545 94.3775 94.4685C98.6321 91.392 101.759 87.0039 103.279 81.9782C104.798 76.9524 104.626 71.5669 102.788 66.6486C100.95 61.7302 97.5491 57.5511 93.1065 54.7529Z"
            fill="#C65151"
        />
        <Path
            d="M78.0245 91.7647C77.9144 91.5452 77.7216 91.3784 77.4885 91.301C77.2554 91.2237 77.0011 91.2421 76.7816 91.3522C76.562 91.4623 76.3953 91.6552 76.3179 91.8883C76.2405 92.1213 76.2589 92.3756 76.3691 92.5952L76.9246 93.7024C77.0347 93.9219 77.2275 94.0887 77.4606 94.1661C77.6937 94.2434 77.948 94.225 78.1675 94.1149C78.3871 94.0048 78.5538 93.812 78.6312 93.5789C78.7086 93.3458 78.6902 93.0915 78.58 92.872L78.0245 91.7647Z"
            fill="#333333"
        />
        <Path
            d="M81.0078 87.0274C80.827 86.8623 80.5884 86.7756 80.3439 86.786C80.0994 86.7964 79.8689 86.9032 79.7029 87.0831C79.537 87.2629 79.4489 87.5011 79.458 87.7457C79.4672 87.9902 79.5727 88.2212 79.7517 88.3882L80.5929 89.1647C80.6822 89.248 80.7869 89.3128 80.9013 89.3554C81.0156 89.398 81.1372 89.4176 81.2592 89.413C81.3811 89.4085 81.5009 89.3798 81.6118 89.3288C81.7226 89.2778 81.8223 89.2054 81.905 89.1157C81.9878 89.0261 82.052 88.921 82.094 88.8064C82.136 88.6918 82.1549 88.5701 82.1497 88.4482C82.1445 88.3263 82.1153 88.2066 82.0637 88.0961C82.0121 87.9855 81.9391 87.8862 81.849 87.804L81.0078 87.0274Z"
            fill="#333333"
        />
        <Path
            d="M85.3753 82.4319L84.5042 81.7636C84.3094 81.6141 84.0632 81.5481 83.8197 81.5802C83.5762 81.6123 83.3554 81.7397 83.206 81.9346C83.0565 82.1294 82.9905 82.3757 83.0226 82.6191C83.0547 82.8626 83.1821 83.0834 83.377 83.2329L84.248 83.9012C84.3445 83.9752 84.4546 84.0295 84.572 84.061C84.6895 84.0925 84.812 84.1005 84.9326 84.0846C85.0531 84.0687 85.1694 84.0293 85.2747 83.9684C85.38 83.9076 85.4723 83.8267 85.5463 83.7302C85.6203 83.6337 85.6746 83.5236 85.706 83.4062C85.7375 83.2887 85.7455 83.1662 85.7297 83.0457C85.7138 82.9251 85.6743 82.8088 85.6135 82.7035C85.5527 82.5982 85.4717 82.5059 85.3753 82.4319Z"
            fill="#333333"
        />
        <Path
            d="M95.5251 67.0647C95.561 67.069 95.5971 67.0712 95.6333 67.0712C95.8695 67.0712 96.0968 66.9808 96.2686 66.8186C96.4404 66.6564 96.5437 66.4347 96.5574 66.1988C96.5711 65.9629 96.494 65.7308 96.3421 65.5498C96.1902 65.3689 95.9748 65.2529 95.7402 65.2255L94.6822 65.1011C94.4382 65.0724 94.1928 65.1418 93.9999 65.294C93.8071 65.4462 93.6826 65.6688 93.6539 65.9128C93.6252 66.1568 93.6946 66.4023 93.8469 66.5951C93.9991 66.7879 94.2217 66.9124 94.4657 66.9411L95.5251 67.0647Z"
            fill="#333333"
        />
        <Path
            d="M92.4569 72.0276C92.5766 72.0504 92.6998 72.0493 92.8191 72.0244C92.9385 71.9995 93.0517 71.9512 93.1524 71.8824C93.2531 71.8136 93.3391 71.7255 93.4057 71.6234C93.4722 71.5212 93.518 71.4069 93.5402 71.287C93.5625 71.1671 93.5608 71.044 93.5354 70.9247C93.5099 70.8055 93.4612 70.6924 93.3919 70.5921C93.3226 70.4917 93.2342 70.406 93.1318 70.3399C93.0293 70.2738 92.9148 70.2286 92.7948 70.2069L91.7493 70.0126C91.6288 69.9882 91.5047 69.988 91.3842 70.012C91.2637 70.0361 91.1491 70.0839 91.0473 70.1526C90.9454 70.2214 90.8582 70.3097 90.7908 70.4125C90.7234 70.5152 90.6772 70.6304 90.6547 70.7512C90.6323 70.8721 90.6341 70.9961 90.6601 71.1163C90.6861 71.2364 90.7357 71.3501 90.8061 71.4509C90.8765 71.5516 90.9663 71.6373 91.0701 71.703C91.174 71.7688 91.2898 71.8132 91.411 71.8336L92.4569 72.0276Z"
            fill="#333333"
        />
        <Path
            d="M89.2805 76.329L88.3007 75.791C88.1941 75.7325 88.077 75.6956 87.9561 75.6823C87.8352 75.669 87.7129 75.6797 87.5961 75.7137C87.4794 75.7477 87.3704 75.8043 87.2756 75.8804C87.1807 75.9565 87.1018 76.0505 87.0432 76.1572C86.9847 76.2638 86.9478 76.3808 86.9345 76.5017C86.9212 76.6226 86.9319 76.7449 86.9659 76.8617C86.9999 76.9784 87.0565 77.0874 87.1326 77.1822C87.2087 77.2771 87.3027 77.3561 87.4093 77.4146L88.3894 77.9525C88.496 78.011 88.6131 78.0479 88.734 78.0612C88.8549 78.0745 88.9772 78.0638 89.0939 78.0298C89.2107 77.9958 89.3196 77.9391 89.4144 77.863C89.5093 77.7869 89.5882 77.6929 89.6467 77.5863C89.7052 77.4797 89.7421 77.3626 89.7554 77.2417C89.7687 77.1208 89.758 76.9985 89.724 76.8818C89.69 76.765 89.6333 76.6561 89.5572 76.5613C89.4811 76.4664 89.3871 76.3875 89.2805 76.329Z"
            fill="#333333"
        />
        <Path
            d="M93.1921 82.7828L92.372 82.3528C92.2642 82.2964 92.1464 82.2617 92.0253 82.2508C91.9042 82.2399 91.7821 82.2529 91.6661 82.2892C91.55 82.3255 91.4422 82.3843 91.3488 82.4622C91.2555 82.5401 91.1784 82.6357 91.122 82.7434C91.0655 82.8511 91.0309 82.9689 91.02 83.09C91.0091 83.2111 91.0221 83.3332 91.0584 83.4493C91.0947 83.5654 91.1534 83.6731 91.2314 83.7665C91.3093 83.8599 91.4048 83.937 91.5126 83.9934L92.3327 84.4231C92.5499 84.5339 92.8021 84.5545 93.0344 84.4806C93.2668 84.4066 93.4606 84.244 93.5737 84.028C93.6869 83.812 93.7103 83.5601 93.6388 83.3269C93.5674 83.0938 93.4068 82.8983 93.1921 82.7828Z"
            fill="#333333"
        />
        <Path
            d="M88.6476 88.8527C88.5713 88.757 88.4768 88.6773 88.3695 88.6184C88.2622 88.5594 88.1443 88.5223 88.0226 88.5092C87.9009 88.4961 87.7778 88.5073 87.6605 88.5421C87.5431 88.5768 87.4338 88.6345 87.3389 88.7118C87.244 88.7891 87.1653 88.8844 87.1075 88.9923C87.0496 89.1002 87.0137 89.2185 87.0019 89.3403C86.9901 89.4621 87.0025 89.5851 87.0385 89.7021C87.0745 89.8191 87.1333 89.9278 87.2116 90.0219L87.6269 90.5322C87.782 90.7227 88.0063 90.8438 88.2506 90.8688C88.495 90.8939 88.7392 90.8208 88.9297 90.6658C89.1201 90.5107 89.2412 90.2864 89.2662 90.0421C89.2913 89.7977 89.2183 89.5535 89.0632 89.363L88.6476 88.8527Z"
            fill="#333333"
        />
        <Path
            d="M96.1515 75.7492L95.0692 75.6172C94.9485 75.6024 94.826 75.6116 94.7089 75.6442C94.5917 75.6768 94.4821 75.7321 94.3864 75.8071C94.1929 75.9584 94.0676 76.1804 94.0378 76.4242C94.0081 76.668 94.0764 76.9136 94.2277 77.107C94.3791 77.3004 94.6011 77.4258 94.8449 77.4555L95.9272 77.5876C95.9648 77.5922 96.0027 77.5946 96.0406 77.5946C96.2761 77.5939 96.5025 77.5034 96.6737 77.3417C96.8449 77.18 96.948 76.9591 96.9622 76.724C96.9763 76.4889 96.9004 76.2573 96.7498 76.0762C96.5992 75.8951 96.3853 75.7782 96.1515 75.7492Z"
            fill="#333333"
        />
        <Path
            d="M33.1275 63.8485C33.5233 61.6359 32.2587 59.5588 30.3031 59.209C28.3475 58.8592 26.4413 60.3692 26.0456 62.5818C25.6498 64.7943 26.9144 66.8714 28.87 67.2212C30.8256 67.571 32.7318 66.061 33.1275 63.8485Z"
            fill="#FFF3F0"
        />
    </Svg>
);

export default Watermelon;
