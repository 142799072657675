import type { ComponentProps, ReactNode } from "react";
import { useMemo, useState } from "react";
import { Tooltip as BaseTooltip, Pressable } from "native-base";

interface Props
    extends Pick<
        ComponentProps<typeof BaseTooltip>,
        | "hasArrow"
        | "label"
        | "placement"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
    variant?: "primary" | "light" | "dark";
    isRenderable?: boolean;
}

const ToolTip = ({
    variant = "dark",
    children,
    isRenderable = true,
    ...rest
}: Props) => {
    const [isOpen, setOpen] = useState(false);

    const variants = useMemo(
        () => ({
            primary: {
                bgColor: "green.600",
                _text: {
                    color: "white",
                },
            },
            dark: {
                bgColor: "gray.900",
                _text: {
                    color: "white",
                },
            },
            light: {
                bgColor: "white",
                _text: {
                    color: "gray.800",
                },
            },
        }),
        []
    );

    return isRenderable ? (
        <BaseTooltip {...variants[variant]} {...rest} isOpen={isOpen}>
            <Pressable
                onPress={() => setOpen((ps) => !ps)}
                onHoverIn={() => setOpen(true)}
                onHoverOut={() => setOpen(false)}
            >
                {children}
            </Pressable>
        </BaseTooltip>
    ) : (
        <>{children}</>
    );
};

export default ToolTip;
