import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import ActionButton from "../ActionButton";
import Badge from "../../atoms/Badge";

export interface RepresentativeData {
    id: string;
    nameOrEmail: string | null;
    note: string | null;
    status: boolean;
}

interface Props extends RepresentativeData {
    onResendPress: (item: RepresentativeData) => void;
    onEditPress: (item: RepresentativeData) => void;
    onDeletePress: (item: RepresentativeData) => void;
}

const RepresentativeListItem = ({
    onResendPress,
    onEditPress,
    onDeletePress,
    ...item
}: Props) => {
    const { t } = useTranslation();

    return (
        <HStack
            justifyContent="space-between"
            alignItems="center"
            bgColor="white"
            minWidth="full"
            borderBottomWidth={1}
            borderColor="gray.100"
            px={4}
            py={5}
        >
            <VStack>
                <HStack space={1.5} mb={1}>
                    <Text>{item.nameOrEmail}</Text>
                    <Badge
                        size="sm"
                        variant={`ghost${item.status ? "Success" : "Warning"}`}
                    >
                        {item.status ? t("active") : t("invited")}
                    </Badge>
                </HStack>
                {item.note && (
                    <Text color="gray.600" fontSize="xs">
                        {item.note}
                    </Text>
                )}
            </VStack>
            <Box ml="auto">
                <ActionButton
                    actions={[
                        ...(!item.status
                            ? ([
                                  {
                                      text: t("resendEmail"),
                                      iconName: "mailAll",
                                      onPress: () => {
                                          onResendPress(item);
                                      },
                                  },
                              ] as const)
                            : []),
                        {
                            text: t("edit"),
                            iconName: "edit",
                            onPress: () => {
                                onEditPress(item);
                            },
                        },
                        {
                            text: t("delete"),
                            iconName: "trashCan",
                            onPress: () => {
                                onDeletePress(item);
                            },
                        },
                    ]}
                />
            </Box>
        </HStack>
    );
};

export default RepresentativeListItem;
