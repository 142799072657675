import { useParams } from "@madmedical/routing";
import { useDetachedPatientId } from "./detachedPatientId";

// Admin here means doctor / callCenter / hotline / admin
export default () => {
    const { patientId: routePatientId } = useParams<"patientId">();
    const { patientId: detachedPatientId } = useDetachedPatientId();

    return routePatientId ?? detachedPatientId;
};
