import { jsPDF } from "jspdf";
import { formatDate } from "@madmedical/utils";
import type { Color } from "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { useTranslation } from "@madmedical/i18n";
import { Inter } from "./inter-normal";
import { InterBold } from "./inter-bold";

const headerCellStyle = {
    halign: "left" as const,
    fillColor: [244, 247, 248] as Color,
    textColor: [0, 0, 0] as Color,
    cellPadding: 2.5,
    font: "Inter",
};

const bodyStyles = {
    lineWidth: {
        top: 0,
        right: 0,
        bottom: 0.05,
        left: 0,
    },
    lineColor: 175,
    fillColor: [255, 255, 255] as Color,
    font: "Inter",
    cellPadding: 1.5,
};

export default (
    patientName: string,
    patientHeight: string,
    patientWeight: string,
    patientBirthDate: string,
    fillDate: string,
    operation: string,
    warningCount: string,
    preOpQuestionaire: { [key: string]: string }[]
) => {
    const doc = new jsPDF();
    const { t } = useTranslation();
    const pdfWidth = doc.internal.pageSize.getWidth();
    // const pdfHeight = doc.internal.pageSize.getHeight();
    // const pdfBottomContentMargin = pdfHeight / 4;

    doc.addFileToVFS("Inter-Regular.ttf", Inter);
    doc.addFileToVFS("Inter-SemiBold.ttf", InterBold);

    doc.addFont("Inter-Regular.ttf", "Inter", "normal", "normal");
    doc.addFont("Inter-SemiBold.ttf", "Inter", "normal", "bold");

    doc.setFont("Inter", "normal");

    const Header = () => {
        doc.setFont("Inter", "normal");
        doc.setFontSize(10);
        doc.setTextColor(75);

        doc.text(
            `Letöltés dátuma: ${formatDate(new Date())}`,
            pdfWidth - 15,
            15,
            {
                align: "right",
            }
        );
        doc.text(`${t("patientName")}: ${patientName}`, 15, 15);
        doc.text(`Kitöltés dátuma: ${fillDate}`, pdfWidth - 15, 20, {
            align: "right",
        });
        doc.text(
            `Születési dátum: ${patientBirthDate}, Testsúly: ${patientWeight}, Magasság: ${patientHeight}`,
            15,
            20
        );

        doc.setFont("Inter", "bold");
        doc.setTextColor(0);
        doc.setFontSize(14);
        doc.text(`Altatás előtti kérdőív`, 15, 30);

        doc.setFont("Inter", "normal");
        doc.setFontSize(12);
        doc.text(
            `Tervezett műtét: ${operation}, ${warningCount}`,
            pdfWidth - 15,
            30,
            {
                align: "right",
            }
        );

        doc.setDrawColor(175);
        doc.line(15, 33, pdfWidth - 15, 33);
    };

    Header();

    if (preOpQuestionaire.length === 0) {
        doc.setFont("Inter", "normal");
        doc.setTextColor(0);
        doc.setFontSize(12);
        doc.text(`Nincs adat`, 15, 43);
        doc.save("pre-op.pdf");

        return;
    }

    preOpQuestionaire.map((item, index) => {
        doc.setFontSize(10);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const finalY = ((doc as any).lastAutoTable.finalY || 30) as number;

        /*
        if (finalY > pdfHeight - pdfBottomContentMargin) {
            doc.addPage();
            Header();
            finalY = 33;
        }
        */

        autoTable(doc, {
            theme: "plain",
            head: [
                [
                    {
                        content: `Kérdéssor ${index + 1}/${
                            preOpQuestionaire.length
                        }`,
                        styles: headerCellStyle,
                    },
                    {
                        content: "",
                        styles: headerCellStyle,
                    },
                ],
            ],
            body: Object.entries(item).map(([key, value]) => [
                {
                    content: `${key}`,
                    styles: {
                        cellWidth: pdfWidth - 60,
                        styles: { halign: "start" },
                    },
                },
                {
                    content: `${value}`,
                    styles: {
                        halign: "right",
                    },
                },
            ]),
            startY: finalY + 8,
            tableWidth: "auto",
            bodyStyles,
            // pageBreak: "avoid",
        });
    });

    doc.save("pre-op.pdf");
};
