import type { ReactNode } from "react";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";

interface Props {
    charts: ReactNode;
}

const Charts = ({ charts }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return (
        <>
            {isSmallScreen && (
                <ListHeader
                    title={t("measures:title")}
                    borderBottomWidth={0}
                    px={0}
                />
            )}
            {charts}
        </>
    );
};

export default Charts;
