import type { ColumnWithStrictAccessor } from "react-table";
import { useMemo } from "react";
import { formatDateRange } from "@madmedical/utils";
import { forHumans } from "@madmedical/medical";
import { FlatList } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import ReactTable from "../molecules/ReactTable";
import Tag from "../atoms/Tag";
import StatusBadge from "../atoms/StatusBadge";
import AvatarTexts from "../molecules/AvatarTexts";
import EvaluationStatuses from "../atoms/EvaluationStatuses";
import type { Evaluation } from "../providers/evaluation";
import { useEvaluations } from "../providers/evaluation";
import useResponsive from "../util/useResponsive";
import EvaluationListItem from "../molecules/ListItem/EvaluationListItem";

interface Props {
    onEndReached?: () => unknown;
}

const HealthEvaluationTable = ({ onEndReached }: Props) => {
    const { evaluations, onSortChange } = useEvaluations();
    const navigate = useRouteNavigate();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const columns = useMemo<ColumnWithStrictAccessor<Evaluation>[]>(
        () => [
            {
                Header: t("measures:type"),
                accessor: "metricType",
                width: "auto",
                Cell: ({ value: metricType }) => (
                    <Tag>{t(`measures:${forHumans(metricType)}`)}</Tag>
                ),
                disableSortBy: true,
            },
            {
                Header: t("measures:measuredPeriod"),
                accessor: "dateRange",
                width: "20%",
                Cell: ({ value: dateRange }) => (
                    <>{formatDateRange(dateRange)}</>
                ),
                defaultCanSort: !!onSortChange,
                disableSortBy: !onSortChange,
            },
            {
                Header: t("measures:alert"),
                accessor: "alertLevel",
                width: "20%",
                Cell: ({ value: alertLevel }) =>
                    alertLevel ? <StatusBadge level={alertLevel} /> : null,
                defaultCanSort: !!onSortChange,
                disableSortBy: !onSortChange,
            },
            {
                Header: t("evaluatingDoctor"),
                accessor: "doctor",
                width: "20%",
                Cell: ({ value: doctor }) => (
                    <AvatarTexts
                        source={doctor.avatarUrl}
                        label={doctor.fullName}
                        initials={doctor.initials}
                    />
                ),
                defaultCanSort: !!onSortChange,
                disableSortBy: !onSortChange,
            },
            {
                Header: t("appendix"),
                accessor: "documentCount",
                width: "10%",
                Cell: ({ value: documentCount }) => (
                    <EvaluationStatuses type="document" count={documentCount} />
                ),
                disableSortBy: true,
            },
        ],
        [onSortChange, t]
    );

    const handlePress = ({ id }: Evaluation) => {
        navigate("opinion", { opinionId: id });
    };

    return isSmallScreen ? (
        <FlatList
            minWidth="full"
            overflow="hidden"
            showsVerticalScrollIndicator={false}
            data={evaluations}
            renderItem={({ item }) => <EvaluationListItem evaluation={item} />}
            keyExtractor={({ id }) => id}
            onEndReached={onEndReached}
        />
    ) : (
        <ReactTable
            columns={columns}
            data={evaluations}
            onSortChange={onSortChange}
            onEndReached={onEndReached}
            onPress={handlePress}
        />
    );
};

export default HealthEvaluationTable;
