import { Box, Stack, Text, VStack } from "native-base";
import { formatDate, isWeb } from "@madmedical/utils";
import { getQuestion } from "@madmedical/medical";
import type {
    BodyHeight,
    BodyWeight,
    PreOpQuestionKey,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Button from "../../../atoms/Button";
import EmptyState from "../../../atoms/EmptyState";
import Paper from "../../../atoms/Paper";
import Headline from "../../../molecules/Headline";
import Breadcrumb from "../../../molecules/Breadcrumb";
import SimpleListItem from "../../../molecules/ListItem/SimpleListItem";
import useResponsive from "../../../util/useResponsive";
import ListHeader from "../../../molecules/ListHeader";
import { useCurrentPatient } from "../../../providers/currentPatient";
import { usePreOp } from "../../../providers/preop";
import downloadPreOpResults from "../../../util/pdf/downloadPreOpResults";
import PreOpResultRow from "./PreOpResultRow";

interface Props {
    bodyWeight: BodyWeight | undefined;
    bodyHeight: BodyHeight | undefined;
}

const PreOpResults = ({ bodyWeight, bodyHeight }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient();
    const { questionnaire } = usePreOp();
    const { t } = useTranslation();
    if (!questionnaire) {
        return <EmptyState title="Nincs kitöltött kérdőív." />;
    }

    const handleExport = () => {
        const group1 = [
            "drugAllergy",
            "takingMedicine",
            "diseases",
            "heartDisease",
            "highBloodPressure",
            "arrhythmia",
            "asthma",
            "breathlessness",
            "stroke",
            "epilepsy",
            "diabetes",
            "thyroid",
            "bleedingDisorder",
            "hepatitis",
            "acidReflux",
            "muscleWeakness",
            "sleepDisorder",
            "eyeDisease",
            "otherDisease",
        ];
        const group2 = [
            "howManyFloors",
            "legCramps",
            "smoking",
            "alcohol",
            "drugs",
            "dentures",
            "looseTooth",
            "pacemaker",
            "contactLenses",
            "jaundice",
            "jointProsthesis",
            "otherProsthesis",
            "piercing",
            "receivedBlood",
            "hadSurgery",
            "anesthesia",
            "anesthesiaComplications",
            "cold",
            "feverInfection",
        ];

        const group3 = ["antibiotics", "pregnant", "covidVaccine", "hadCovid"];

        const groups = [group1, group2, group3];

        const patientName = patient.fullName ?? t("patient");
        const patientHeight = bodyHeight?.displayValue ?? "–";
        const patientWeight = bodyWeight?.displayValue ?? "–";
        const patientBirthDate = patient?.birthDate
            ? formatDate(patient?.birthDate)
            : "–";
        const fillDate = formatDate(questionnaire.createdAt);
        const operation = questionnaire.operation;
        const warningCount = `Figyelmeztetés (${questionnaire.badAnswersCount})`;

        const answers = [] as Record<string, string>[];
        const allData = {
            ...questionnaire.answers,
            ...questionnaire.subAnswers,
        };

        groups.forEach((group, index) => {
            const groupData = {} as Record<string, string>;
            let spacer = "";
            for (const key of group) {
                const keyKey = key as PreOpQuestionKey;
                const keyValue = allData[keyKey] as
                    | boolean
                    | string[]
                    | undefined;
                const question = `${spacer}${getQuestion(
                    key as PreOpQuestionKey
                )}`;
                const answer =
                    keyValue && Array.isArray(keyValue)
                        ? keyValue.toString()
                        : keyValue === true
                        ? t("yes")
                        : t("no");
                groupData[question] = answer;
                if (keyKey === "diseases") {
                    spacer = "– ";
                }
            }
            answers[index] = groupData;
        });

        downloadPreOpResults(
            patientName,
            patientHeight,
            patientWeight,
            patientBirthDate,
            fillDate,
            operation,
            warningCount,
            answers
        );
    };

    return (
        <VStack
            space={{
                base: 0,
                md: 3,
            }}
        >
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link
                                    route="patient"
                                    params={{ patientId: patient.userId }}
                                >
                                    {patient.fullName}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    Altatás előtti kérdőív
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <Headline
                    justifyContent="center"
                    alignItems="center"
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title="Altatás előtti kérdőív"
                    rightContent={
                        isWeb && (
                            <Button
                                variant="outlined"
                                leftIconName="documentDownload"
                                onPress={handleExport}
                            >
                                Exportálás
                            </Button>
                        )
                    }
                />
            </Paper>
            <Stack
                direction={{
                    base: "column",
                    md: "row",
                }}
                space={{
                    base: 0,
                    md: 3,
                }}
                p={{
                    base: 4,
                    md: 0,
                }}
            >
                <Box
                    width={{
                        base: "full",
                        md: "1/2",
                    }}
                >
                    {isSmallScreen && (
                        <ListHeader
                            title="Kérdőív"
                            borderBottomWidth={0}
                            mb={0}
                            pl={0}
                            pr={0}
                        />
                    )}
                    <Paper flex={1}>
                        {!isSmallScreen && (
                            <Headline title="Kérdőív" size="xs" />
                        )}
                        <VStack
                            space={4}
                            width="full"
                            py={{
                                base: 4,
                                md: 6,
                            }}
                        >
                            <Stack
                                direction={{
                                    base: "column",
                                    md: "row",
                                }}
                                width="full"
                                px={{
                                    base: 4,
                                    md: 6,
                                }}
                                space={3}
                            >
                                <SimpleListItem
                                    label="Kitöltés dátuma"
                                    content={formatDate(
                                        questionnaire.createdAt
                                    )}
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                                <SimpleListItem
                                    label="Eredmény"
                                    content={
                                        <Text color="error.500">
                                            {/* TODO: Different style and words if zero */}
                                            {`Figyelmeztetés (${questionnaire.badAnswersCount})`}
                                        </Text>
                                    }
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                            </Stack>
                        </VStack>
                    </Paper>
                </Box>

                <Box
                    width={{
                        base: "full",
                        md: "1/2",
                    }}
                >
                    {isSmallScreen && (
                        <ListHeader
                            title={t("personalData")}
                            borderBottomWidth={0}
                            mb={0}
                            pl={0}
                            pr={0}
                        />
                    )}
                    <Paper flex={1}>
                        {!isSmallScreen && (
                            <Headline title={t("personalData")} size="xs" />
                        )}
                        <VStack
                            space={4}
                            width="full"
                            py={{
                                base: 4,
                                md: 6,
                            }}
                        >
                            <Stack
                                direction={{
                                    base: "column",
                                    md: "row",
                                }}
                                width="full"
                                px={{
                                    base: 4,
                                    md: 6,
                                }}
                                space={3}
                            >
                                <SimpleListItem
                                    label={t("name")}
                                    content={patient.fullName}
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                            </Stack>
                            <Stack
                                direction={{
                                    base: "column",
                                    md: "row",
                                }}
                                width="full"
                                px={{
                                    base: 4,
                                    md: 6,
                                }}
                                space={3}
                            >
                                <SimpleListItem
                                    label={t("dateOfBirth")}
                                    content={
                                        patient.birthDate
                                            ? formatDate(patient.birthDate)
                                            : "–"
                                    }
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                                <SimpleListItem
                                    label={t("measures:bodyWeight")}
                                    content={bodyWeight?.displayValue ?? "–"}
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                            </Stack>
                            <Stack
                                direction={{
                                    base: "column",
                                    md: "row",
                                }}
                                width="full"
                                px={{
                                    base: 4,
                                    md: 6,
                                }}
                                space={3}
                            >
                                <SimpleListItem
                                    label={t("height")}
                                    content={bodyHeight?.displayValue ?? "–"}
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                                <SimpleListItem
                                    label={t("plannedSurgery")}
                                    content={questionnaire.operation}
                                    width="auto"
                                    flex={1}
                                    px={0}
                                    py={0}
                                    borderBottomWidth={0}
                                />
                            </Stack>
                        </VStack>
                    </Paper>
                </Box>
            </Stack>

            <Box>
                {isSmallScreen && (
                    <ListHeader
                        title="Kérdéssor 1/3"
                        borderBottomWidth={0}
                        mb={0}
                    />
                )}
                <Paper flex={1}>
                    {!isSmallScreen && (
                        <Headline title="Kérdéssor 1/3" size="xs" />
                    )}
                    <VStack width="full">
                        <PreOpResultRow questionkey="drugAllergy" />
                        <PreOpResultRow questionkey="takingMedicine" />
                        <PreOpResultRow
                            questionkey="diseases"
                            hasOptionalAnswer={false}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="highBloodPressure"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="arrhythmia"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="asthma"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="breathlessness"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="stroke"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="epilepsy"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="diabetes"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="thyroid"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="bleedingDisorder"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="hepatitis"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="acidReflux"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="muscleWeakness"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="sleepDisorder"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="eyeDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="otherDisease"
                            pl={{ base: 8, md: 10 }}
                            hasDivider={false}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                        <PreOpResultRow
                            questionkey="heartDisease"
                            pl={{ base: 8, md: 10 }}
                        />
                    </VStack>
                </Paper>
            </Box>

            <Box>
                {isSmallScreen && (
                    <ListHeader
                        title="Kérdéssor 2/3"
                        borderBottomWidth={0}
                        mb={0}
                    />
                )}
                <Paper flex={1}>
                    {!isSmallScreen && (
                        <Headline title="Kérdéssor 2/3" size="xs" />
                    )}
                    <VStack width="full">
                        <PreOpResultRow
                            questionkey="howManyFloors"
                            hasOptionalAnswer={false}
                        />
                        <PreOpResultRow questionkey="legCramps" />
                        <PreOpResultRow questionkey="smoking" />
                        <PreOpResultRow questionkey="alcohol" />
                        <PreOpResultRow questionkey="drugs" />
                        <PreOpResultRow questionkey="dentures" />
                        <PreOpResultRow questionkey="looseTooth" />
                        <PreOpResultRow questionkey="drugs" />
                        <PreOpResultRow questionkey="dentures" />
                        <PreOpResultRow questionkey="looseTooth" />
                        <PreOpResultRow questionkey="pacemaker" />
                        <PreOpResultRow questionkey="contactLenses" />
                        <PreOpResultRow questionkey="jaundice" />
                        <PreOpResultRow questionkey="jointProsthesis" />
                        <PreOpResultRow questionkey="otherProsthesis" />
                        <PreOpResultRow questionkey="piercing" />
                        <PreOpResultRow questionkey="receivedBlood" />
                        <PreOpResultRow questionkey="bloodComplications" />
                        <PreOpResultRow questionkey="hadSurgery" />
                        <PreOpResultRow questionkey="anesthesia" />
                        <PreOpResultRow questionkey="anesthesiaComplications" />
                        <PreOpResultRow questionkey="cold" />
                        <PreOpResultRow questionkey="feverInfection" />
                    </VStack>
                </Paper>
            </Box>

            <Box>
                {isSmallScreen && (
                    <ListHeader
                        title="Kérdéssor 3/3"
                        borderBottomWidth={0}
                        mb={0}
                    />
                )}
                <Paper flex={1}>
                    {!isSmallScreen && (
                        <Headline title="Kérdéssor 3/3" size="xs" />
                    )}
                    <VStack width="full">
                        <PreOpResultRow questionkey="antibiotics" />
                        <PreOpResultRow questionkey="pregnant" />
                        <PreOpResultRow questionkey="covidVaccine" />
                        <PreOpResultRow questionkey="hadCovid" />
                    </VStack>
                </Paper>
            </Box>
        </VStack>
    );
};

export default PreOpResults;
