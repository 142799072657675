import { Fragment } from "react";
import { Circle, Line, Polygon } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import Thresholds from "./Thresholds";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

export default function GraphLinePaired({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const {
        coords = [],
        randomIdFragment,
        dashboardThumbnail: {
            isDashboardThumbnail = false,
            showLineGraphNodes = true,
        } = {},
    } = graphData;

    return (
        <>
            {!isDashboardThumbnail && <Thresholds graphData={graphData} />}

            {coords.length > 0 &&
                coords.map(({ x = 0, yLow = 0, yHigh = 0 }, i, coords) => {
                    const idHigh = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    const idLow = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <Fragment key={idHigh}>
                            {showLineGraphNodes &&
                            i < coords.length - 1 &&
                            yLow &&
                            coords[i + 1].yLow &&
                            yHigh &&
                            coords[i + 1].yHigh ? (
                                isWeb ? (
                                    <Polygon
                                        fill={`rgba(${colors.blue400Rgb}, 0.2)`}
                                        id={`${idHigh}-area`}
                                        points={`${x},${yLow} ${x},${yHigh} ${
                                            coords[i + 1].x ?? ""
                                        },${coords[i + 1].yHigh ?? ""} ${
                                            coords[i + 1].x ?? ""
                                        },${coords[i + 1].yLow ?? ""}`}
                                    />
                                ) : (
                                    <Polygon
                                        scaleY={-1}
                                        fill={`rgba(${colors.blue400Rgb}, 0.2)`}
                                        id={`${idHigh}-area`}
                                        points={`${x},${yLow} ${x},${yHigh} ${
                                            coords[i + 1].x ?? ""
                                        },${coords[i + 1].yHigh ?? ""} ${
                                            coords[i + 1].x ?? ""
                                        },${coords[i + 1].yLow ?? ""}`}
                                    />
                                )
                            ) : null}

                            {i < coords.length - 1 &&
                            yHigh &&
                            coords[i + 1].yHigh ? (
                                isWeb ? (
                                    <Line
                                        fill="none"
                                        stroke={colors.blue400}
                                        strokeWidth="2px"
                                        id={`${idHigh}-line`}
                                        x1={x}
                                        y1={yHigh}
                                        x2={coords[i + 1].x}
                                        y2={coords[i + 1].yHigh}
                                    />
                                ) : (
                                    <Line
                                        scaleY={-1}
                                        fill="none"
                                        stroke={colors.blue400}
                                        strokeWidth="2px"
                                        id={`${idHigh}-line`}
                                        x1={x}
                                        y1={yHigh}
                                        x2={coords[i + 1].x}
                                        y2={coords[i + 1].yHigh}
                                    />
                                )
                            ) : null}

                            {i < coords.length - 1 &&
                            yLow &&
                            coords[i + 1].yLow ? (
                                isWeb ? (
                                    <Line
                                        fill="none"
                                        stroke={colors.blue400}
                                        strokeWidth="2px"
                                        id={`${idLow}-line`}
                                        x1={x}
                                        y1={yLow}
                                        x2={coords[i + 1].x}
                                        y2={coords[i + 1].yLow}
                                    />
                                ) : (
                                    <Line
                                        scaleY={-1}
                                        fill="none"
                                        stroke={colors.blue400}
                                        strokeWidth="2px"
                                        id={`${idLow}-line`}
                                        x1={x}
                                        y1={yLow}
                                        x2={coords[i + 1].x}
                                        y2={coords[i + 1].yLow}
                                    />
                                )
                            ) : null}

                            {showLineGraphNodes && yHigh ? (
                                isWeb ? (
                                    <Circle
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow &&
                                            tooltip.id === idHigh
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={idHigh}
                                        cx={x}
                                        cy={yHigh}
                                        r="3px"
                                    />
                                ) : (
                                    <Circle
                                        scaleY={-1}
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow &&
                                            tooltip.id === idHigh
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={idHigh}
                                        cx={x}
                                        cy={yHigh}
                                        r="3px"
                                    />
                                )
                            ) : null}

                            {showLineGraphNodes && yLow ? (
                                isWeb ? (
                                    <Circle
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow &&
                                            tooltip.id === idLow
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={idLow}
                                        cx={x}
                                        cy={yLow}
                                        r="3px"
                                    />
                                ) : (
                                    <Circle
                                        scaleY={-1}
                                        fill={colors.white}
                                        strokeWidth="2px"
                                        stroke={
                                            tooltip.onShow &&
                                            tooltip.id === idLow
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        id={idLow}
                                        cx={x}
                                        cy={yLow}
                                        r="3px"
                                    />
                                )
                            ) : null}
                        </Fragment>
                    );
                })}
        </>
    );
}
