import type { ElementRef } from "react";
import { useRef, useState } from "react";
import { FlatList, HStack, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Button from "../../../atoms/Button";
import ActionSheet from "../../../molecules/ActionSheet";
import Checkbox from "../../../atoms/Checkbox";
import ListHeader from "../../../molecules/ListHeader";
import { usePatients } from "../../../providers/patient";

const LIMIT = 3;

const EvaluationsMobileFilter = () => {
    const { filterChoices } = usePatients();
    const actionSheetRef = useRef<ElementRef<typeof ActionSheet>>(null);
    const [filters, setFilters] = useState(filterChoices);
    const [limits, setLimits] = useState({
        services: true,
        device: true,
    });
    const { t } = useTranslation();
    const handleTogglePress = () => {
        actionSheetRef.current?.toggle();
    };

    const handleResetPress = () => {
        setFilters((prev) => ({
            ...prev,
            services: [],
            deviceTypes: [],
        }));
    };

    const handleSubmitPress = () => {
        // TODO
        console.log("TODO");
    };

    if (!filterChoices) {
        return null;
    }

    return (
        <>
            <ActionSheet
                title={t("filter")}
                footer={
                    <Button onPress={handleSubmitPress} size="xl">
                        {t("showResults")}
                    </Button>
                }
                rightContent={
                    <Button
                        position="absolute"
                        right={0}
                        top={0}
                        onPress={handleResetPress}
                        size="xs"
                        variant="link"
                        p={0}
                    >
                        {t("setback")}
                    </Button>
                }
                ref={actionSheetRef}
            >
                <>
                    <FlatList
                        minWidth="full"
                        showsVerticalScrollIndicator={false}
                        data={filterChoices.services.slice(
                            0,
                            limits.services ? LIMIT : undefined
                        )}
                        ListHeaderComponent={
                            <ListHeader
                                title={t("package")}
                                badge={filters.services.length}
                                mb={2}
                            />
                        }
                        renderItem={({ item }) => (
                            <HStack px={4} py={1} space={2}>
                                <Checkbox
                                    value={item.key}
                                    isChecked={filters.services.some(
                                        ({ key }) => key === item.key
                                    )}
                                    onChange={(isSelected) => {
                                        const services = [...filters.services];
                                        if (isSelected) {
                                            services.push(item);
                                        } else {
                                            services.splice(
                                                filters.services.indexOf(item),
                                                1
                                            );
                                        }
                                        setFilters((prevState) => ({
                                            ...prevState,
                                            services,
                                        }));
                                    }}
                                />
                                <Text>{item.text}</Text>
                            </HStack>
                        )}
                        keyExtractor={({ key }) => `services-${key}`}
                    />
                    {filterChoices.services.length > LIMIT && (
                        <HStack justifyContent="flex-start" p={4}>
                            <Button
                                size="xs"
                                variant="link"
                                p={0}
                                onPress={() =>
                                    setLimits((prevState) => ({
                                        ...prevState,
                                        metricTypes: !prevState.services,
                                    }))
                                }
                            >
                                {`${
                                    limits.services ? "Több" : "Kevesebb"
                                } mutatása`}
                            </Button>
                        </HStack>
                    )}
                    <FlatList
                        minWidth="full"
                        showsVerticalScrollIndicator={false}
                        data={filterChoices.deviceTypes.slice(
                            0,
                            limits.device ? LIMIT : undefined
                        )}
                        ListHeaderComponent={
                            <ListHeader
                                title={t("device")}
                                badge={filters.deviceTypes.length}
                                my={2}
                            />
                        }
                        renderItem={({ item }) => (
                            <HStack px={4} py={1} space={2}>
                                <Checkbox
                                    value={item.key.toString()}
                                    isChecked={filters.deviceTypes.some(
                                        ({ key }) => key === item.key
                                    )}
                                    onChange={(isSelected) => {
                                        const device = [...filters.deviceTypes];
                                        if (isSelected) {
                                            device.push(item);
                                        } else {
                                            device.splice(
                                                filters.deviceTypes.indexOf(
                                                    item
                                                ),
                                                1
                                            );
                                        }
                                        setFilters((prevState) => ({
                                            ...prevState,
                                            device,
                                        }));
                                    }}
                                />
                                <Text>{item.text}</Text>
                            </HStack>
                        )}
                        keyExtractor={({ key }) => `device-${key}`}
                    />
                </>
            </ActionSheet>
            <Button
                variant="outlined"
                size="xs"
                onPress={handleTogglePress}
                leftIconName="filter"
            >
                {t("filter")}
            </Button>
        </>
    );
};

export default EvaluationsMobileFilter;
