import { ClipPath, Defs, G, Path, Rect, Svg } from "react-native-svg";

interface Props {
    width?: number;
    height?: number;
}

export const Syringe = (props: Props) => (
    <Svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" {...props}>
        <G clipPath="url(#clip0_1054_53250)">
            <Path
                d="M17.9972 6.26802L18.0679 6.33873L18.1386 6.26802L18.8646 5.54197L18.9353 5.47126L18.8646 5.40055L14.6434 1.17929L14.5727 1.10858L14.5019 1.17929L13.7759 1.9053L13.7052 1.97601L13.7759 2.04672L14.6756 2.94636L12.8587 4.76322L11.2109 3.11535L11.1402 3.04464L11.0694 3.11535L10.3434 3.84136L10.2727 3.91207L10.3434 3.98278L11.0445 4.68384L5.29206 10.4311C4.89782 10.825 4.67632 11.3594 4.67632 11.9167V13.1089L3.88666 13.9252C3.5145 14.31 3.49697 14.9084 3.8307 15.3133L1.13632 17.9436L1.06477 18.0135L1.13462 18.085L1.85187 18.8197L1.92173 18.8913L1.99328 18.8214L4.69837 16.1807C5.07094 16.4837 5.61918 16.4641 5.96915 16.1202L6.8473 15.2574H8.06594C8.62007 15.2574 9.15174 15.0383 9.54509 14.648L15.2991 8.93852L16.002 9.64146L16.0727 9.71217L16.1434 9.64146L16.8694 8.91545L16.9402 8.84474L16.8694 8.77403L15.2469 7.15144L17.0637 5.33458L17.9972 6.26802ZM6.79793 12.1103L6.86864 12.1811L6.93935 12.1103L7.66536 11.3843L7.73607 11.3136L7.66536 11.2429L6.94057 10.5181L7.52622 9.93299L8.25757 10.6643L8.32828 10.735L8.39899 10.6643L9.125 9.93832L9.19571 9.86761L9.125 9.7969L8.39406 9.06596L8.97127 8.48928L9.73319 9.2512L9.8039 9.32191L9.87461 9.2512L10.6006 8.52519L10.6713 8.45448L10.6006 8.38377L9.83907 7.62222L10.3488 7.11294L12.8631 9.62729L8.42563 14.0306H6.84768L5.90304 13.1002V11.5548L6.07276 11.3852L6.79793 12.1103ZM5.30165 15.0562L4.89387 14.6484L5.30024 14.2284L5.72169 14.6435L5.30165 15.0562ZM13.7341 8.76319L11.2168 6.24588L11.9119 5.55137L14.4316 8.07109L13.7341 8.76319ZM13.7261 5.63069L15.543 3.81382L16.1963 4.46711L14.3794 6.28398L13.7261 5.63069Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.2"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_1054_53250">
                <Rect width="20" height="20" fill="white" />
            </ClipPath>
        </Defs>
    </Svg>
);
