import type { BloodPressure } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import { ceil2, floor2 } from "@madmedical/utils";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<BloodPressure>;

const BloodPressureChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BloodPressure, widget);

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "line-paired",
            unit: "Hgmm",
            limits: {
                yMax: max ? ceil2(max.systolic + 1) : 200,
                yMin: min ? floor2(min.diastolic - 1) : 40,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yLow: metric.diastolic,
                yHigh: metric.systolic,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.BloodPressure}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BloodPressureChart;
