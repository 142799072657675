import {
    Box,
    HStack,
    Popover,
    Pressable,
    Text,
    useDisclose,
    useTheme,
} from "native-base";
import type { ComponentProps } from "react";
import {
    formatDate,
    formatDayOfMonth,
    formatYearMonth,
} from "@madmedical/utils";
import type { CalendarDay } from "../../util/calendar";
import { createCalendar } from "../../util/calendar";
import Icon from "../../icons/Icon";
import Input from "../../molecules/Form/Input";

interface Props {
    value?: Date;
    onChange?: (value: Date) => void;
    isReadOnly?: boolean;
    isInvalid?: boolean;
    size?: ComponentProps<typeof Input>["size"];
}
const DateInput = ({
    value,
    onChange,
    isReadOnly = false,
    isInvalid = false,
    size = "md",
}: Props) => {
    const { colors } = useTheme();
    const calendar = createCalendar(value);
    const { isOpen, onClose, onOpen } = useDisclose();

    const changeDate = (selected: CalendarDay) => {
        if (isReadOnly || !onChange) {
            return;
        }

        if (!value) value = new Date();

        const offset =
            selected.monthOffset === "actual"
                ? 0
                : selected.monthOffset === "prev"
                ? -1
                : 1;

        onChange(
            new Date(
                value.getFullYear(),
                value.getMonth() + offset,
                Number(selected.day)
            )
        );
        onClose();
    };

    const changeMonth = (type: "minus" | "plus") => {
        if (isReadOnly || !onChange || !value) {
            return;
        }

        onChange(
            type === "plus"
                ? new Date(value.setMonth(value.getMonth() + 1))
                : new Date(value.setMonth(value.getMonth() - 1))
        );
    };

    const valueWithFallback = value ?? new Date();

    return (
        <Popover
            placement="top left"
            isOpen={isOpen}
            onClose={onClose}
            useRNModal
            trigger={(triggerProps) => (
                <Pressable
                    width="full"
                    {...(!isReadOnly ? triggerProps : {})}
                    onPress={() => !isReadOnly && onOpen()}
                >
                    <Box width="full" flex="1">
                        <Input
                            flex={1}
                            fullSize
                            leftIconName="calendar"
                            size={size}
                            value={value ? formatDate(value) : ""}
                            isReadOnly={isReadOnly}
                            isInvalid={isInvalid}
                        />
                    </Box>
                </Pressable>
            )}
        >
            <Popover.Content width="310px" h="310px" maxWidth="full">
                <Box flex={1} p={4} bgColor="white">
                    <HStack
                        justifyContent="space-between"
                        alignItems="center"
                        px="4"
                        py="2"
                    >
                        <Pressable onPress={() => changeMonth("minus")}>
                            <Icon name="chevronLeft" />
                        </Pressable>
                        <Text fontSize="sm" fontWeight="bold">
                            {formatYearMonth(valueWithFallback)}
                        </Text>
                        <Pressable onPress={() => changeMonth("plus")}>
                            <Icon name="chevronRight" />
                        </Pressable>
                    </HStack>
                    <Box
                        flexDirection={"row"}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                        mt={4}
                    >
                        {Object.values(
                            calendar?.[valueWithFallback.getFullYear()]?.[
                                valueWithFallback.getMonth()
                            ] || {}
                        ).map((e, index) => {
                            const selected =
                                e.day ===
                                    Number(
                                        formatDayOfMonth(valueWithFallback)
                                    ) && e.monthOffset === "actual";

                            return (
                                <Pressable
                                    key={index}
                                    onPress={() => changeDate(e)}
                                    width={"39px"}
                                    height={"39px"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    bgColor={
                                        selected ? colors.green[500] : "white"
                                    }
                                    borderRadius={selected ? 4 : 0}
                                >
                                    <Text
                                        color={
                                            selected
                                                ? "white"
                                                : e.monthOffset === "actual"
                                                ? colors.gray[700]
                                                : colors.gray[500]
                                        }
                                        fontWeight={"400"}
                                    >
                                        {e.day}
                                    </Text>
                                </Pressable>
                            );
                        })}
                    </Box>
                </Box>
            </Popover.Content>
        </Popover>
    );
};

export default DateInput;
