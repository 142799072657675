import { Box, Stack } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import type { AppRoutes } from "@madmedical/routing";
import { useLocation } from "@madmedical/routing";
import { useTranslation } from "@madmedical/i18n";
import ConsultationButton from "../molecules/ConsultationButton";
import RoutableTabs from "../molecules/RoutableTabs";
import useResponsive from "../util/useResponsive";
import Button from "../atoms/Button";

interface Props {
    readonly metricType: MetricType;
    readonly isPatient: boolean;
    readonly insulinUser?: boolean;
    readonly downloadPressed?: () => void;
}

const MeasurementsTabs = ({
    metricType,
    isPatient,
    downloadPressed,
    insulinUser = false,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    const location = useLocation();

    const isAdministration = location.pathname.includes(
        "/insulin/administrations"
    );

    const addDiabetMenu = () => {
        if (insulinUser) {
            return [
                {
                    title: t("insulin"),
                    route: "insulin" as keyof AppRoutes,
                    params: {},
                },
                {
                    title: t("measures:insertions"),
                    route: "insulin_administrations" as keyof AppRoutes,
                    params: {},
                },
            ];
        }

        return [];
    };

    return (
        <Box
            testID="tabs"
            px={{
                base: 2,
                md: 4,
            }}
            backgroundColor="white"
            borderColor="gray.100"
            borderBottomWidth={1}
        >
            <Stack direction="row" space={2} width="full" alignItems="center">
                <RoutableTabs
                    tabs={[
                        {
                            title: t(`measures:${forHumans(metricType)}`),
                            route: "measurements",
                            params: { metricType },
                        },
                        {
                            title: t("measures:title"),
                            route: "measurements_list",
                            params: { metricType },
                        },
                        ...addDiabetMenu(),
                    ]}
                    _tab={{ px: 2, py: 4 }}
                />
                {!isSmallScreen && isPatient && !isAdministration && (
                    <Box ml="auto">
                        <ConsultationButton />
                    </Box>
                )}
                {!isSmallScreen && isAdministration && downloadPressed && (
                    <Box ml="auto">
                        <Button
                            variant="outlined"
                            leftIconName="documentDownload"
                            onPress={downloadPressed}
                        >
                            {t("downloadData")}
                        </Button>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default MeasurementsTabs;
