import { isWeb } from "@madmedical/utils";
import { HStack, Pressable, Text, useToast } from "native-base";
import type { ColorType } from "native-base/lib/typescript/components/types";
import useResponsive from "../util/useResponsive";

type Variant = "dark" | "light" | "success" | "error";

interface Options {
    readonly variant?: Variant;
    // TODO: buttons
}

const defaultOptions: Options = {
    variant: "dark",
};

export default () => {
    const toast = useToast();
    const { isSmallScreen } = useResponsive();

    return (message: string, { variant } = defaultOptions) => {
        let toastId: unknown = null;

        const close = () => {
            if (toastId) {
                toast.close(toastId);
            }
        };

        let bg: ColorType = "gray.800";
        let color: ColorType = "gray.800";

        switch (variant) {
            case "dark":
                bg = "gray.800";
                color = "gray.100";

                break;
            case "light":
                bg = "gray.50";
                color = "gray.800";

                break;
            case "success":
                bg = "green.500";
                color = "white";

                break;
            case "error":
                bg = "error.400";
                color = "white";

                break;

            default:
                break;
        }

        const render = () => (
            <HStack
                bg={bg}
                px={4}
                py={3}
                borderRadius="sm"
                space={6}
                alignItems="center"
                maxW={350}
            >
                <Text color={color} fontSize="sm" flex={isWeb ? 1 : undefined}>
                    {message}
                </Text>
                <Pressable onPress={close}>
                    <Text
                        fontSize="sm"
                        textTransform="uppercase"
                        color={
                            variant === "dark" || variant === "light"
                                ? "green.500"
                                : "white"
                        }
                    >
                        Ok
                    </Text>
                </Pressable>
            </HStack>
        );

        toastId = toast.show({
            render,
            placement: isSmallScreen ? "top" : "bottom",
        });
    };
};
