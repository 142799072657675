import { Box, Divider, HStack, Text, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import Bar from "../../../atoms/Bar";
import MeasurementProtocolModalFooter from "../../../molecules/MeasurementProtocolModalFooter";
import MeasurementProtocolsOffsetSettingsTitle from "../../../molecules/MeasurementProtocolsOffsetSettingsTitle";
import FormControl from "../../../form/FormControl";
import ModalContent from "../../../molecules/ModalContent";
import ProtocolSection from "../ProtocolSection";
import type { Props } from "../model";

const DailySteps = ({
    protocols,
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("measures:dailyStepsSetup")}
            footer={
                <MeasurementProtocolModalFooter
                    onClose={onClose}
                    onSubmitPress={onSubmitPress}
                />
            }
        >
            <ProtocolSection protocols={protocols} />
            <VStack mt={6} space={4}>
                <MeasurementProtocolsOffsetSettingsTitle />
                <VStack space={0.5}>
                    <HStack
                        width="full"
                        justifyContent="space-between"
                        alignItems="center"
                        space={1}
                    >
                        <Bar
                            data={[{ color: "error.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={3}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "green.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                    </HStack>
                    <HStack>
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={3} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                    </HStack>
                    <HStack>
                        <Box flex={1} />
                        <Box flex={2}>
                            <FormControl
                                name="threshold.alertBelow.steps"
                                type="number"
                                isRequired
                                maxWidth={40}
                                suffix={
                                    <Text px={2}>{t("measures:steps")}</Text>
                                }
                            />
                        </Box>
                        <Box flex={1} />
                        <Box flex={2}>
                            <FormControl
                                name="threshold.warningBelow.steps"
                                type="number"
                                isRequired
                                maxWidth={40}
                                suffix={
                                    <Text px={2}>{t("measures:steps")}</Text>
                                }
                            />
                        </Box>
                        <Box flex={1} />
                    </HStack>
                </VStack>
            </VStack>
        </ModalContent>
    );
};

export default DailySteps;
