import type { ComponentProps, ReactNode } from "react";
import { Heading, Stack } from "native-base";
import { useMemo } from "react";
import Badge from "../atoms/Badge";
import useResponsive from "../util/useResponsive";

interface Props
    extends Pick<
        ComponentProps<typeof Stack>,
        | "minHeight"
        | "borderBottomWidth"
        | "bgColor"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    rightContent?: ReactNode;
    title: string;
    badge?: string | number;
    variant?: "default" | "inverted";
    size?: "xs" | "sm" | "md";
}

const ListHeader = ({
    rightContent,
    title,
    badge,
    variant = "default",
    size = "xs",
    ...rest
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const variants = useMemo(
        () => ({
            default: {
                bgColor: "gray.50",
                borderBottomColor: "gray.100",
            },
            inverted: {
                bgColor: "white",
                borderBottomColor: "transparent",
            },
        }),
        []
    );

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            bgColor={variants[variant].bgColor}
            borderBottomWidth={1}
            borderBottomColor={variants[variant].borderBottomColor}
            borderBottomStyle="solid"
            borderTopRadius="lg"
            pt={6}
            pb={2}
            px={4}
            minHeight={14}
            width={"full"}
            {...rest}
        >
            {title && (
                <Stack direction="row" alignItems="center" space={2}>
                    <Heading
                        size={size}
                        fontWeight="medium"
                        textTransform={size === "xs" ? "uppercase" : "none"}
                    >
                        {title}
                    </Heading>

                    {typeof badge !== "undefined" && (
                        <Badge
                            hasIndicator={false}
                            size="sm"
                            variant={isSmallScreen ? "base" : "ghost"}
                        >
                            {badge}
                        </Badge>
                    )}
                </Stack>
            )}

            {rightContent && (
                <Stack direction="row" alignItems="center" space={4}>
                    {rightContent}
                </Stack>
            )}
        </Stack>
    );
};

export default ListHeader;
