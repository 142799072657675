import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import Button from "../atoms/Button";

interface Props {
    onClose: () => void;
    onSubmitPress?: () => void;
}

const MeasurementProtocolModalFooter = ({ onClose, onSubmitPress }: Props) => {
    const { t } = useTranslation();

    return (
        <HStack space={2}>
            <Button
                variant={onSubmitPress ? "outlined" : "primary"}
                size="md"
                onPress={onClose}
            >
                {onSubmitPress ? t("cancel") : t("close")}
            </Button>
            {onSubmitPress && (
                <SubmitButton onPress={onSubmitPress} label={t("save")} />
            )}
        </HStack>
    );
};

export default MeasurementProtocolModalFooter;
