import {
    getStorageValue,
    removeStorageValue,
    setStorageValue,
} from "@madmedical/storage";

const KEY_REFRESH_TOKEN = "refreshToken";

export const getRefreshToken = async () =>
    await getStorageValue<string>(KEY_REFRESH_TOKEN);

export const setRefreshToken = async (value: string) =>
    await setStorageValue(KEY_REFRESH_TOKEN, value);

export const removeRefreshToken = async () =>
    await removeStorageValue(KEY_REFRESH_TOKEN);
