import { Box } from "native-base";
import type { ReactNode } from "react";
import { useTranslation } from "@madmedical/i18n";
import ListHeader from "../../molecules/ListHeader";

interface Props {
    charts: ReactNode;
}

const Charts = ({ charts }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <ListHeader
                title={t("measures:title")}
                borderBottomWidth={0}
                px={0}
            />

            <Box mb={3} width="100%">
                {charts}
            </Box>
        </>
    );
};

export default Charts;
