import type { CellProps, Column, IdType } from "react-table";
import { useMemo } from "react";
import { Box, HStack, Text } from "native-base";
import { dateToRange, formatDateRange, rangeToString } from "@madmedical/utils";
import { AlertLevel, forHumans, isDaily } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { PatientMeasurements } from "../providers/patientMeasurements";
import ReactTable from "../molecules/ReactTable";
import StatusBadge from "../atoms/StatusBadge";
import AvatarTexts from "../molecules/AvatarTexts";
import Badge from "../atoms/Badge";
import Tag from "../atoms/Tag";
import type { Period } from "../molecules/DatePeriodStepper";
import Bar from "../atoms/Bar";
import EvaluationStatuses from "../atoms/EvaluationStatuses";

interface Props {
    period: Period;
    data: PatientMeasurements[];
    readonly onEndReached?: () => unknown;
}

const PatientMeasurementsTable = ({ period, data, onEndReached }: Props) => {
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const hiddenColumns = useMemo<IdType<PatientMeasurements>[]>(
        () => (period === "month" ? ["statuses"] : []),
        [period]
    );

    const handlePress = ({
        patient: { userId },
        metricType,
        dateTrunc,
    }: PatientMeasurements) => {
        navigate(
            "measurements",
            { patientId: userId, metricType },
            { search: rangeToString(dateToRange(dateTrunc, period)) }
        );
    };
    const columns = useMemo<Column<PatientMeasurements>[]>(
        () => [
            {
                Header: t("patientName"),
                accessor: "patient",
                width: "auto",
                Cell: ({ value: patient }) =>
                    patient ? (
                        <AvatarTexts
                            source={patient.avatarUrl}
                            label={patient.fullName}
                            initials={patient.initials}
                        />
                    ) : null,
            },
            {
                Header: t("measures:measureTime"),
                accessor: "dateRange",
                width: 150,
                Cell: ({ row }) => {
                    const { dateRange, measurementCount, metricType } =
                        row.original;

                    return (
                        <HStack alignItems="center">
                            {formatDateRange(
                                dateRange,
                                period === "day" && !isDaily(metricType)
                                    ? "time"
                                    : "long"
                            )}
                            <Badge
                                hasIndicator={false}
                                size="sm"
                                variant="ghost"
                                ml={2}
                                opacity={measurementCount ? 1 : 0}
                                pointerEvents={
                                    measurementCount ? "auto" : "none"
                                }
                            >
                                {measurementCount}
                            </Badge>
                        </HStack>
                    );
                },
            },
            {
                Header: t("measures:type"),
                accessor: "metricType",
                width: 200,
                Cell: ({ value: metricType }) => (
                    <Tag size="lg">
                        {t(`measures:${forHumans(metricType)}`)}
                    </Tag>
                ),
            },
            {
                Header: t("measures:measureResult"),
                width: 150,
                accessor: "displayRange",
            },
            {
                Header: t("measures:alert"),
                accessor: "worstAlertLevel",
                width: 150,
                Cell: ({ row }) => {
                    const { worstAlertLevel, alertLevelCounts } = row.original;

                    return period !== "month" ? (
                        <StatusBadge level={worstAlertLevel} />
                    ) : (
                        <HStack
                            justifyContent="flex-start"
                            alignItems="center"
                            flex={1}
                            space={2}
                            width="full"
                        >
                            <Bar
                                height={5}
                                width={36}
                                data={[
                                    {
                                        color: "green.300",
                                        value: alertLevelCounts[AlertLevel.Ok],
                                    },
                                    {
                                        color: "orange.300",
                                        value: alertLevelCounts[
                                            AlertLevel.Warning
                                        ],
                                    },
                                    {
                                        color: "rose.300",
                                        value: alertLevelCounts[
                                            AlertLevel.Alert
                                        ],
                                    },
                                ]}
                            />
                            <Text fontSize="xs" color="gray.500" flex={1}>
                                (
                                {Object.values(alertLevelCounts).reduce(
                                    (acc, value) => acc + value,
                                    0
                                )}
                                )
                            </Text>
                        </HStack>
                    );
                },
            },
            {
                Header: t("state"),
                id: "statuses",
                width: 60,
                Cell: ({ row }: CellProps<PatientMeasurements>) => {
                    const { evaluationCount, documentCount, commentCount } =
                        row.original;

                    return (
                        <HStack alignItems="center" space={1}>
                            <Box>
                                <EvaluationStatuses
                                    type="evaluation"
                                    count={evaluationCount}
                                />
                            </Box>
                            <Box>
                                <EvaluationStatuses
                                    type="document"
                                    count={documentCount}
                                />
                            </Box>
                            <Box>
                                <EvaluationStatuses
                                    type="comment"
                                    count={commentCount}
                                />
                            </Box>
                        </HStack>
                    );
                },
            },
        ],
        [period, t]
    );

    return (
        <ReactTable
            hiddenColumns={hiddenColumns}
            columns={columns}
            data={data}
            onEndReached={onEndReached}
            onPress={handlePress}
            scrollX
        />
    );
};

export default PatientMeasurementsTable;
