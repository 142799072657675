import type { ComponentProps } from "react";
import { Heading, Text } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Card from "../atoms/Card/Card";

interface Props
    extends Pick<
        ComponentProps<typeof Card>,
        | "borderWidth"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    protocols: {
        metricType: MetricType;
        instructions: string;
    }[];
}

const ProtocolCard = ({ protocols, ...rest }: Props) => {
    const { t } = useTranslation();
    if (!protocols.length) {
        return null;
    }

    return (
        <Card {...rest}>
            {protocols.map(({ metricType, instructions }) => (
                <>
                    <Heading size="sm" fontWeight="medium" mb={1}>
                        {t(`measures:${forHumans(metricType)}`)}
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                        {t(`backend:${instructions}`)}
                    </Text>
                </>
            ))}
        </Card>
    );
};

export default ProtocolCard;
