import type { ComponentProps } from "react";
import { Box, HStack, Stack, Text, VStack } from "native-base";
import { formatDate, isWeb } from "@madmedical/utils";
import type { UnderlyingCondition } from "@madmedical/medical";
import {
    genderForHumans,
    getServiceColour,
    serviceForHumans,
    underlyingForHumans,
    underlyingForHumansDoctor,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import type { PatientDoctor } from "../../providers/patient";
import { useCurrentPatient } from "../../providers/currentPatient";
import useResponsive from "../../util/useResponsive";
import Headline from "../../molecules/Headline";
import ListHeader from "../../molecules/ListHeader";
import SimpleListItem from "../../molecules/ListItem/SimpleListItem";
import Paper from "../../atoms/Paper";
import Tag from "../../atoms/Tag";
import Button from "../../atoms/Button";
import Tooltip from "../../atoms/Tooltip";
import Icon from "../../icons/Icon";

interface Props extends Pick<ComponentProps<typeof Paper>, "borderRadius"> {
    onEditPress?: () => unknown;
    isDoctor?: boolean;
}

const PatientDetailsSection = ({
    onEditPress,
    isDoctor = false,
    ...rest
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient<PatientDoctor>();

    const sortUnderlying = (underlying: UnderlyingCondition[]) => {
        if (!underlying?.length) {
            return [];
        }
        const sorted = underlying
            .map((item) =>
                isDoctor
                    ? t(underlyingForHumansDoctor(item))
                    : t(underlyingForHumans(item))
            )
            .sort((a, b) => a.localeCompare(b));

        return sorted;
    };
    const { t } = useTranslation();
    const conditions = patient.underlyingConditions
        ? sortUnderlying(patient.underlyingConditions)
        : // ? patient.underlyingConditions.filter((item) => isIllness(item))
          [];

    return (
        <Box
            flex={{
                base: isWeb ? "initial" : 1,
                md: 1,
            }}
        >
            {isSmallScreen && (
                <ListHeader
                    title={t("personalData")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}

            <Paper borderRadius="lg" flex={isWeb ? 1 : 0} {...rest}>
                {!isSmallScreen && (
                    <Headline
                        title={t("personalData")}
                        rightContent={
                            onEditPress && (
                                <Button
                                    p={0}
                                    size="xs"
                                    variant="link"
                                    onPress={onEditPress}
                                >
                                    {t("edit")}
                                </Button>
                            )
                        }
                        size="xs"
                    />
                )}

                <Box
                    px={{
                        base: 4,
                        md: 6,
                    }}
                    width="full"
                >
                    <Stack
                        width="full"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                    >
                        <VStack
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("name")}
                                content={patient.fullName}
                                borderBottomWidth={0}
                                px={0}
                            />
                            <SimpleListItem
                                label={t("dateOfBirth")}
                                content={
                                    patient.birthDate
                                        ? formatDate(patient.birthDate)
                                        : "–"
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </VStack>
                        <VStack
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("socialSecurityNumber")}
                                content={patient.socialSecurityNumber ?? "–"}
                                borderBottomWidth={0}
                                px={0}
                            />
                            <SimpleListItem
                                label={t("gender")}
                                content={
                                    patient.gender
                                        ? t(
                                              `${genderForHumans(
                                                  patient.gender
                                              )}`
                                          )
                                        : "–"
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                            <SimpleListItem
                                label={t("authorizedPersons")}
                                accessory={
                                    <Tooltip
                                        hasArrow
                                        placement="top"
                                        label={t("authorizedDescription")}
                                    >
                                        <Box>
                                            <Icon name="information" />
                                        </Box>
                                    </Tooltip>
                                }
                                content={
                                    <VStack space={2}>
                                        {patient.representatives.length ? (
                                            patient.representatives.map(
                                                ({ userId, fullName }) => (
                                                    <Text key={userId}>
                                                        {fullName}
                                                    </Text>
                                                )
                                            )
                                        ) : (
                                            <Text>–</Text>
                                        )}
                                    </VStack>
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </VStack>
                    </Stack>

                    <Stack
                        width="full"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        <Box
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("packages")}
                                accessory={
                                    <Tooltip
                                        hasArrow
                                        placement="top"
                                        label="A csomag(ok)nál szereplő címkék azt jelölik, hogy a páciens milyen előfizetéssel rendelkezik. Ez lehet várandósgondozás, műtét előtti vizsgálat, illetve menedzserszűrés."
                                    >
                                        <Box>
                                            <Icon name="information" />
                                        </Box>
                                    </Tooltip>
                                }
                                content={
                                    <>
                                        {!patient.services.length && (
                                            <Text>–</Text>
                                        )}
                                        <HStack space={2}>
                                            {patient.services.map((service) => (
                                                <Tag
                                                    key={service}
                                                    variant={getServiceColour(
                                                        service
                                                    )}
                                                >
                                                    {t(
                                                        `backend:${serviceForHumans(
                                                            service
                                                        )}`
                                                    ).toLowerCase()}
                                                </Tag>
                                            ))}
                                        </HStack>
                                    </>
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </Box>
                        <Box
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("illnesses")}
                                content={
                                    <HStack
                                        space={2}
                                        alignItems="flex-start"
                                        flexWrap="wrap"
                                        justifyContent={
                                            isSmallScreen
                                                ? "flex-end"
                                                : "flex-start"
                                        }
                                    >
                                        {!conditions.length && <Text>–</Text>}
                                        {conditions.map(
                                            (underlyingCondition) => (
                                                <Tag
                                                    key={underlyingCondition}
                                                    mb={1}
                                                >
                                                    {underlyingCondition}
                                                </Tag>
                                            )
                                        )}
                                    </HStack>
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </Box>
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default PatientDetailsSection;
