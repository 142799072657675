import { Box, FlatList, Stack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Paper from "../atoms/Paper";
import ListHeader from "../molecules/ListHeader";
import Headline from "../molecules/Headline";
import useResponsive from "../util/useResponsive";
import { useDevices } from "../providers/device";
import { DeviceItem } from "./DeviceItem";
import DeviceCard from "./DeviceCard";

const DeviceSection = () => {
    const { isSmallScreen } = useResponsive();
    const { devices } = useDevices();
    const { t } = useTranslation();
    if (!devices.length) return null;

    return isSmallScreen ? (
        <Box
            px={{
                base: 4,
                md: 0,
            }}
        >
            <ListHeader
                title={t("usedDevices")}
                size="xs"
                px={0}
                borderBottomWidth={0}
                bgColor="none"
            />
            <FlatList
                borderColor="gray.100"
                bg="amber.100"
                borderRadius="lg"
                overflow="hidden"
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                data={devices}
                renderItem={({ item }) => <DeviceItem device={item} />}
                keyExtractor={(item) => item.provider}
            />
        </Box>
    ) : (
        <Paper>
            <Headline title={t("usedDevices")} size="xs" py={6} />
            <Stack direction="row" px={4} py={2} width="full" flexWrap="wrap">
                {devices.map((item) => (
                    <Box key={item.provider} width="1/3" p={1}>
                        <DeviceCard device={item} />
                    </Box>
                ))}
            </Stack>
        </Paper>
    );
};

export default DeviceSection;
