import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import BaseMultiSelect from "../molecules/Form/MultiCheckboxSelect";

type Props = Omit<ComponentProps<typeof BaseMultiSelect>, "onChange"> & {
    name: string;
};

const MultiCheckboxSelect = (props: Props) => {
    const { control, isError, error } = useField(props.name);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{ required: props.isRequired }}
            render={({ field: { onBlur, onChange, value } }) => (
                <BaseMultiSelect
                    {...props}
                    onBlur={onBlur}
                    onChange={onChange}
                    values={value as string[] | undefined}
                    isInvalid={isError}
                    error={error}
                />
            )}
        />
    );
};

export default MultiCheckboxSelect;
