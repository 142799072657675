import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { hasActiveFilter } from "../utils";
import FilterButton from "../../../molecules/FilterButton";
import Button from "../../../atoms/Button";
import { useEvaluations } from "../../../providers/evaluation";

const EvaluationsDesktopFilter = () => {
    const { filterChoices, onFilterChange } = useEvaluations();
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof NonNullable<typeof filterChoices>>(
            key: TFilterKey
        ) =>
        (
            selected: NonNullable<typeof filterChoices>[TFilterKey][0]["key"][]
        ) => {
            onFilterChange?.(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange?.("metricTypes", []);
        onFilterChange?.("alertLevels", []);
        onFilterChange?.("doctors", []);
    };

    if (!filterChoices) {
        return null;
    }

    const translatedmetricTypes = filterChoices.metricTypes.map((item) => ({
        ...item,
        text: `${t(`measures:${item.text}`)}`,
    }));

    return (
        <HStack
            width="full"
            space={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <FilterButton
                isSearchable
                title={t("measures:type")}
                items={translatedmetricTypes}
                onChange={handleFilterChange("metricTypes")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("alertLevel")}
                items={filterChoices.alertLevels}
                onChange={handleFilterChange("alertLevels")}
                isMultiselect={false}
            />
            <FilterButton
                title={t("evaluatingDoctor")}
                items={filterChoices.doctors}
                onChange={handleFilterChange("doctors")}
                isMultiselect={true}
            />
            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default EvaluationsDesktopFilter;
