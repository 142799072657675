import {
    CurrentPatientProvider,
    Modal,
    PatientDataModalForm,
    useShowToast,
} from "@madmedical/ui";
import type { PatientDoctor } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { invariant } from "@madmedical/utils";
import { useUpdatePatientMutation } from "../api";
import usePatient from "../usePatient";

interface Props {
    open: boolean;
    onClose: () => void;
}

const EditPatientDetailsModal = ({ open, onClose }: Props) => {
    const { patient, refetch } = usePatient();
    const [updatePatient] = useUpdatePatientMutation();

    const showToast = useShowToast();

    const handleSuccess = () => {
        showToast("Mentés sikeres.", { variant: "success" });
        void refetch();
    };

    if (!patient) {
        return null;
    }

    invariant("underlyingConditions" in patient);

    return (
        <Modal open={open} onClose={onClose} size="sm">
            <CurrentPatientProvider
                value={{ patient: patient as PatientDoctor }}
            >
                <ApiFormWrapper
                    defaultValues={{
                        id: patient.userId,
                        underlyingConditions: patient.underlyingConditions,
                    }}
                    mutation={updatePatient}
                    onSuccess={handleSuccess}
                >
                    {({ submitForm, isSuccess }) => (
                        <PatientDataModalForm
                            onClose={onClose}
                            onSubmitPress={submitForm}
                            isSuccess={isSuccess}
                        />
                    )}
                </ApiFormWrapper>
            </CurrentPatientProvider>
        </Modal>
    );
};

export default EditPatientDetailsModal;
