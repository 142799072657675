import { useState } from "react";
import { Box, FlatList } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Badge from "../atoms/Badge";
import Headline from "../molecules/Headline";
import ListHeader from "../molecules/ListHeader";
import Paper from "../atoms/Paper";
import DoctorPatientsTable from "../organisms/DoctorPatientsTable";
import SearchInput from "../molecules/Form/SearchInput";
import useResponsive from "../util/useResponsive";
import PatientsMobileFilter from "../organisms/filters/mobile/PatientsMobileFilter";
import PatientsDesktopFilter from "../organisms/filters/desktop/PatientsDesktopFilter";
import SimpleAvatarListItem from "../molecules/ListItem/SimpleAvatarListItem";
import { usePatients } from "../providers/patient";
import PressableLink from "../atoms/PressableLink";

interface Props {
    onEndReached: () => unknown;
}

const DoctorPatients = ({ onEndReached }: Props) => {
    const { isSmallScreen } = useResponsive();
    const [search, setSearch] = useState("");
    const { patients, pagination, onSearchChange } = usePatients();
    const { t } = useTranslation();
    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange?.(search);
    };

    return (
        <>
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title={t("patients")}
                    accessory={
                        <Badge hasIndicator={false} variant="ghost">
                            {pagination.totalCount}
                        </Badge>
                    }
                />

                <Headline
                    mb={4}
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        isSmallScreen ? (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        ) : (
                            <PatientsDesktopFilter />
                        )
                    }
                    rightContent={
                        isSmallScreen ? (
                            <PatientsMobileFilter />
                        ) : (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        )
                    }
                />

                {isSmallScreen ? (
                    <>
                        <ListHeader title={t("patient")} />
                        <FlatList
                            minWidth="full"
                            showsVerticalScrollIndicator={false}
                            data={patients}
                            renderItem={({ item }) => (
                                <PressableLink
                                    route="patient"
                                    params={{ patientId: item.userId }}
                                >
                                    <SimpleAvatarListItem item={item} />
                                </PressableLink>
                            )}
                            keyExtractor={(item) => item.userId}
                            onEndReached={onEndReached}
                            overflow="hidden"
                        />
                    </>
                ) : (
                    <Box px={6} pb={1} width="full">
                        <DoctorPatientsTable onEndReached={onEndReached} />
                    </Box>
                )}
            </Paper>
        </>
    );
};

export default DoctorPatients;
