import type { ComponentProps } from "react";
import { useEffect, useState } from "react";
import { Box, Stack } from "native-base";

type DataType = {
    color: string; // theme color id
    value: number;
};

interface Props
    extends Pick<ComponentProps<typeof Stack>, "maxWidth" | "height" | "flex"> {
    data: DataType[];
    width?: number | string;
    height?: number | string;
    summary?: number;
}

const Bar = ({
    data,
    width = "full",
    height = 6,
    summary = 0,
    ...rest
}: Props) => {
    const [sum, setSum] = useState(summary);

    useEffect(() => {
        if (!summary) {
            const _sum = data.reduce((prev, { value }) => prev + value, 0);
            setSum(_sum);
        }
    }, [data, summary]);

    return (
        <Stack
            width={width}
            direction="row"
            borderRadius="sm"
            overflow="hidden"
            {...rest}
        >
            {data.map((item, index) => (
                <Box
                    key={`bar-data-item-${index}`}
                    height={height}
                    width={`${(item.value / sum) * 100}%`}
                    bgColor={item.color}
                />
            ))}
        </Stack>
    );
};

export default Bar;
