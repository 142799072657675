import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const Week07 = () => {
    const { t } = useTranslation();

    return (
        <VStack>
            <Text>{t("pregnancy:week7")}</Text>
        </VStack>
    );
};

export default Week07;
