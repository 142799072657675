import Svg, { Path, Rect } from "react-native-svg";

const Pommegranate = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M56.3068 41.9669L54.2602 31.6058C54.2602 31.6058 53.5136 27.2802 50.9609 25.1182C48.4095 27.2802 47.6615 31.6058 47.6615 31.6058C45.4041 25.1182 39.5672 27.8292 39.5672 27.8292C39.5672 27.8292 43.5653 30.4044 43.5653 36.2063V41.5066C34.3812 44.2558 19.9999 51.9402 20.7615 73.0675C21.1866 84.8594 27.0281 91.898 33.1826 95.8115C33.1826 95.8115 69.5439 111.968 76.9365 71.0007C80.3177 52.2622 65.4909 44.7161 56.3068 41.9669Z"
            fill="#CD5A5A"
        />
        <Path
            d="M51.0664 41.0131C50.9468 41.0123 50.8309 41.0539 50.7395 41.1305C50.648 41.2071 50.5869 41.3136 50.5673 41.4309C50.5476 41.5482 50.5707 41.6687 50.6322 41.7706C50.6938 41.8726 50.7899 41.9494 50.9032 41.9872C58.3409 44.4982 74.1707 52.9016 70.9334 78.46C70.9334 78.46 66.745 77.6199 64.103 82.2567C63.3581 83.5132 63.0133 84.9648 63.1138 86.4202C63.1889 87.2773 63.0672 88.1402 62.7579 88.9437C62.4486 89.7472 61.9597 90.4702 61.3283 91.0581C57.3066 94.7227 47.9727 100.697 33.1826 95.8115C36.4125 97.7444 39.938 99.1396 43.62 99.9418C44.8189 100.208 45.8832 100.891 46.622 101.868C47.7692 103.399 49.2504 104.773 51.9405 104.773C54.6603 104.773 55.6252 102.145 56.6851 100.274C65.2391 98.6995 80.4387 93.0867 81.1602 73.0678C81.9218 51.9405 67.5405 44.2561 58.3564 41.5069V36.2066C58.3411 34.5115 58.722 32.8361 59.469 31.3125C60.2161 29.789 61.3088 28.459 62.661 27.4276C62.661 27.4276 56.5184 25.1184 54.2613 31.6061V37.8356C54.2613 38.2529 54.1786 38.6662 54.0181 39.0518C53.8575 39.4374 53.6221 39.7877 53.3255 40.0828C53.0288 40.3779 52.6766 40.6119 52.2889 40.7715C51.9013 40.9311 51.4859 41.0132 51.0664 41.0131Z"
            fill="#AD4949"
        />
        <Path
            d="M100.085 59.2988L84.4944 72.4207C77.154 76.6235 71.8333 86.4426 66.835 97.1576C67.3153 97.2699 89.1707 105.564 98.0771 92.7822C106.984 80.0005 104.062 63.4172 100.085 59.2988Z"
            fill="#AD4949"
        />
        <Path
            d="M97.0631 69.645C99.6642 69.645 101.773 67.5471 101.773 64.9592C101.773 62.3713 99.6642 60.2734 97.0631 60.2734C94.4619 60.2734 92.3533 62.3713 92.3533 64.9592C92.3533 67.5471 94.4619 69.645 97.0631 69.645Z"
            fill="#AD4949"
        />
        <Path
            d="M91.9414 70.9204C92.4851 70.2393 93.2101 69.7236 94.0338 69.4321C94.8575 69.1405 95.7469 69.0847 96.6009 69.271C97.4549 69.4573 98.2392 69.8783 98.8647 70.4861C99.4901 71.0938 99.9316 71.864 100.139 72.709C99.8692 75.2756 90.4248 78.0864 91.9414 70.9204Z"
            fill="#CD5A5A"
        />
        <Path
            d="M69.2567 90.5347C68.6651 89.8196 68.3205 88.9341 68.2738 88.0091C68.2271 87.0842 68.4808 86.1688 68.9975 85.3983C69.5141 84.6279 70.2663 84.0434 71.1427 83.7311C72.0191 83.4189 72.9732 83.3955 73.864 83.6644C76.7894 87.345 73.5156 90.9319 69.2567 90.5347Z"
            fill="#CD5A5A"
        />
        <Path
            d="M69.2529 90.5298C69.6825 91.0499 70.2284 91.4628 70.8471 91.7353C71.4658 92.0079 72.1401 92.1325 72.8159 92.0994C73.4917 92.0662 74.1503 91.8761 74.739 91.5442C75.3277 91.2124 75.8302 90.748 76.2061 90.1883C76.5821 89.6286 76.8212 88.9892 76.9042 88.3211C76.9872 87.653 76.9119 86.9748 76.6842 86.3409C76.4565 85.707 76.0829 85.1349 75.5931 84.6704C75.1033 84.2059 74.5111 83.8619 73.8637 83.666C73.0078 84.8956 72.5902 86.3748 72.6774 87.868C72.6844 88.0443 72.6309 88.2176 72.5257 88.3597C72.4205 88.5017 72.2699 88.6039 72.0986 88.6494C70.9632 88.937 69.9597 89.6001 69.2529 90.5298Z"
            fill="#AD4949"
        />
        <Path
            d="M84.8928 77.5841C87.2657 77.5841 89.1892 75.6704 89.1892 73.3096C89.1892 70.9489 87.2657 69.0352 84.8928 69.0352C82.52 69.0352 80.5964 70.9489 80.5964 73.3096C80.5964 75.6704 82.52 77.5841 84.8928 77.5841Z"
            fill="#AD4949"
        />
        <Path
            d="M78.6591 92.0314C81.2607 92.0314 83.3697 89.9331 83.3697 87.3448C83.3697 84.7565 81.2607 82.6582 78.6591 82.6582C76.0575 82.6582 73.9485 84.7565 73.9485 87.3448C73.9485 89.9331 76.0575 92.0314 78.6591 92.0314Z"
            fill="#AD4949"
        />
        <Path
            d="M75.1598 98.7562C74.4069 98.9102 73.6275 98.8771 72.8905 98.6598C72.1536 98.4425 71.4819 98.0478 70.9349 97.5106C70.3879 96.9733 69.9825 96.3102 69.7546 95.5799C69.5267 94.8496 69.4834 94.0746 69.6284 93.3237C69.7734 92.5727 70.1023 91.8689 70.586 91.2746C71.0697 90.6803 71.6933 90.2139 72.4014 89.9166C73.1096 89.6193 73.8805 89.5004 74.6459 89.5703C75.4113 89.6402 76.1475 89.8968 76.7895 90.3174L75.1598 98.7562Z"
            fill="#CD5A5A"
        />
        <Path
            d="M76.7882 90.3184C76.0742 90.6884 75.45 91.2093 74.9595 91.8445C74.469 92.4797 74.124 93.2139 73.9485 93.9957C73.7729 94.828 73.7896 95.6892 73.9974 96.5142C74.2053 97.3392 74.5988 98.1065 75.1482 98.758C76.0551 98.5749 76.8869 98.128 77.5382 97.474C78.1895 96.82 78.6309 95.9883 78.8066 95.0844C78.9823 94.1805 78.8842 93.2451 78.5249 92.3967C78.1656 91.5483 77.5611 90.8251 76.7882 90.3189V90.3184Z"
            fill="#AD4949"
        />
        <Path
            d="M94.4472 83.3897C95.0113 84.7302 94.5965 86.8378 93.6006 87.7618C93.6006 87.7618 87.8972 88.9551 88.3514 85.247C88.8168 81.4558 94.4472 83.3897 94.4472 83.3897Z"
            fill="#CD5A5A"
        />
        <Path
            d="M94.4471 83.3898C94.096 82.5602 93.4746 81.8723 92.6827 81.4368C91.8909 81.0014 90.9748 80.8437 90.082 80.9893C89.1892 81.1348 88.3715 81.5751 87.7608 82.2392C87.15 82.9032 86.7817 83.7525 86.7151 84.6502C86.636 85.7452 87.1231 88.8665 90.7333 88.9542C91.2677 88.9609 91.7977 88.8585 92.2908 88.6533C92.7838 88.4481 93.2293 88.1444 93.5996 87.7611C92.738 87.1068 91.6943 86.7333 90.6112 86.6918C90.4155 86.6887 90.2269 86.6187 90.077 86.4937C89.9615 86.3868 89.8806 86.2481 89.8447 86.0953C89.8087 85.9425 89.8193 85.7825 89.8751 85.6357C89.9308 85.489 90.0293 85.362 90.1579 85.2711C90.2865 85.1802 90.4393 85.1295 90.597 85.1253C91.4905 85.0956 93.5942 84.8645 94.4471 83.3898Z"
            fill="#AD4949"
        />
        <Path
            d="M80.103 99.4187C82.4109 99.4187 84.2819 97.5573 84.2819 95.2611C84.2819 92.9649 82.4109 91.1035 80.103 91.1035C77.795 91.1035 75.9241 92.9649 75.9241 95.2611C75.9241 97.5573 77.795 99.4187 80.103 99.4187Z"
            fill="#CD5A5A"
        />
        <Path
            d="M90.5035 95.8111C92.6017 95.8111 94.3025 94.1189 94.3025 92.0315C94.3025 89.9441 92.6017 88.252 90.5035 88.252C88.4054 88.252 86.7046 89.9441 86.7046 92.0315C86.7046 94.1189 88.4054 95.8111 90.5035 95.8111Z"
            fill="#CD5A5A"
        />
        <Path
            d="M98.0764 92.7826C99.8166 90.2744 101.185 87.53 102.138 84.6338C100.309 84.6955 98.041 85.8918 95.2383 88.9543C85.4303 99.6728 72.367 94.9072 68.6973 93.2523C68.0692 94.5349 67.4482 95.8368 66.8342 97.158C67.3145 97.2703 89.17 105.564 98.0764 92.7826Z"
            fill="#AD4949"
        />
        <Path
            d="M99.1111 50.2871L97.0188 53.0038C96.395 53.8165 96.059 54.8117 96.0629 55.8342C96.0668 56.8567 96.4105 57.8493 97.0405 58.6572C102.401 65.5645 102.698 72.6012 101.587 78.0067C102.517 81.2946 101.472 83.9266 98.6596 85.9712C98.3601 86.5308 98.0323 87.0748 97.6772 87.6012C87.733 102.026 74.3705 98.283 67.9127 95.2334C67.202 94.8984 66.3972 94.8161 65.6328 95.0003C64.8684 95.1845 64.1907 95.6242 63.7129 96.2457L61.5447 99.0598C61.5447 99.0598 85.936 115.572 102.2 92.9784C119.739 68.6149 99.1111 50.2871 99.1111 50.2871Z"
            fill="#F8D0C5"
        />
        <Path
            d="M91.944 70.9222C91.4469 71.5399 91.1163 72.2735 90.9836 73.0537C90.8508 73.8338 90.9203 74.6348 91.1853 75.3808C91.4503 76.1269 91.9022 76.7933 92.4983 77.3172C93.0943 77.8411 93.8148 78.2051 94.5916 78.3748C95.3684 78.5445 96.1759 78.5143 96.9377 78.287C97.6996 78.0598 98.3905 77.643 98.9454 77.0761C99.5002 76.5091 99.9005 75.8108 100.108 75.047C100.316 74.2833 100.325 73.4794 100.134 72.7114C99.1326 72.4692 98.0822 72.521 97.1099 72.8606C96.1375 73.2002 95.285 73.8129 94.6556 74.6247C94.2984 74.3556 93.8895 74.1622 93.4542 74.0565C93.2227 73.9964 93.0175 73.8621 92.8705 73.6743C92.7234 73.4865 92.6427 73.2557 92.6408 73.0177C92.6488 72.182 92.8736 71.3625 93.2935 70.6387L91.944 70.9222Z"
            fill="#AD4949"
        />
        <Path
            d="M88.2446 84.7309C88.027 85.6517 87.4923 86.4677 86.7333 87.0373C85.9743 87.6069 85.0388 87.8943 84.0891 87.8495C83.1394 87.8048 82.2354 87.4307 81.5339 86.7922C80.8324 86.1537 80.3777 85.2911 80.2485 84.354C80.8453 82.176 81.9326 80.5952 84.2466 80.5078C86.5948 80.7362 87.5568 82.2423 88.2446 84.7309Z"
            fill="#CD5A5A"
        />
        <Path
            d="M84.2831 79.755C83.7007 79.7508 83.1243 79.8719 82.5933 80.11C82.0623 80.3481 81.5894 80.6975 81.2068 81.1344C80.8242 81.5712 80.541 82.0852 80.3767 82.6411C80.2123 83.197 80.1707 83.7816 80.2545 84.355C80.9641 84.3152 82.918 84.0609 84.3236 82.496C84.4045 82.4091 84.5043 82.3416 84.6153 82.2988C84.7264 82.2559 84.8458 82.2389 84.9645 82.2489C85.0832 82.2588 85.198 82.2956 85.3003 82.3564C85.4025 82.4172 85.4895 82.5004 85.5545 82.5997C86.2061 83.5708 87.1455 84.316 88.2422 84.7316C88.385 84.1373 88.3904 83.5184 88.2579 82.9217C88.1254 82.325 87.8585 81.766 87.4773 81.2868C87.0961 80.8076 86.6106 80.4208 86.0572 80.1555C85.5039 79.8901 84.8973 79.7532 84.2831 79.755Z"
            fill="#AD4949"
        />
        <Path
            d="M103.329 76.7686L101.586 78.0068C101.004 80.7887 100.018 83.4714 98.6592 85.9704C106.969 83.7302 103.329 76.7686 103.329 76.7686Z"
            fill="#FAB398"
        />
        <Path
            d="M87.2763 82.2415C86.5408 81.3667 86.1682 80.2458 86.2343 79.1071C86.3005 77.9685 86.8004 76.8978 87.6324 76.1132C88.4643 75.3285 89.5656 74.8889 90.712 74.8838C91.8584 74.8788 92.9635 75.3088 93.8024 76.0861C95.2087 81.5566 90.5036 83.8496 87.2763 82.2415Z"
            fill="#CD5A5A"
        />
        <Path
            d="M75.5826 79.6121C75.5581 79.4271 75.5459 79.2408 75.5459 79.0542C75.5459 78.1948 75.8098 77.356 76.3024 76.65C76.7949 75.9441 77.4924 75.4047 78.3016 75.1042C79.1109 74.8036 79.993 74.7562 80.8301 74.9684C81.6671 75.1805 82.419 75.6421 82.985 76.2912C84.1624 78.6957 83.7876 80.5375 81.1899 81.5742C78.4261 82.5288 76.4559 81.9933 75.5826 79.6121Z"
            fill="#CD5A5A"
        />
        <Path
            d="M87.2726 70.5706C86.5303 69.7094 86.1254 68.6105 86.1324 67.4759C86.1394 66.3414 86.5578 65.2475 87.3107 64.3954C88.0635 63.5432 89.1003 62.9901 90.2303 62.8376C91.3603 62.6852 92.5077 62.9437 93.4614 63.5657C93.887 63.9696 94.2279 64.4535 94.4643 64.9894C94.7008 65.5252 94.8281 66.1024 94.8389 66.6875C94.8497 67.2726 94.7438 67.854 94.5274 68.3982C94.3109 68.9423 93.9882 69.4384 93.5778 69.8577C91.9022 71.8998 88.7435 71.9682 87.2726 70.5706Z"
            fill="#CD5A5A"
        />
        <Path
            d="M37.289 66.7873C40.1329 66.7873 42.4383 64.3209 42.4383 61.2784C42.4383 58.236 40.1329 55.7695 37.289 55.7695C34.4451 55.7695 32.1396 58.236 32.1396 61.2784C32.1396 64.3209 34.4451 66.7873 37.289 66.7873Z"
            fill="#FFE9E9"
        />
        <Path
            d="M93.7988 76.0898C93.0014 76.8091 93.015 78.1636 93.1189 79.0171C93.1702 79.4043 93.1386 79.798 93.0261 80.1723C92.9136 80.5465 92.7228 80.8929 92.4662 81.1885C91.6695 82.0858 90.1337 82.9574 87.2769 82.2409C87.6709 82.7111 88.1577 83.096 88.7072 83.3718C89.2567 83.6476 89.8571 83.8083 90.4715 83.8441C91.0859 83.88 91.7011 83.7901 92.2792 83.5801C92.8573 83.3701 93.3859 83.0444 93.8325 82.6232C94.2792 82.2019 94.6342 81.6941 94.8758 81.131C95.1174 80.5678 95.2404 79.9614 95.2372 79.3491C95.234 78.7369 95.1046 78.1318 94.8572 77.5712C94.6097 77.0106 94.2493 76.5064 93.7983 76.0898H93.7988Z"
            fill="#AD4949"
        />
        <Path
            d="M95.5998 67.5052C95.5999 66.7238 95.4044 65.9546 95.0308 65.2673C94.6572 64.58 94.1174 63.9961 93.4601 63.5684C94.7623 65.4998 94.5391 68.2844 91.9762 70.0422C89.4197 71.7961 87.5017 70.7936 87.2754 70.5687C87.9043 71.2998 88.7432 71.8214 89.6793 72.0635C90.6153 72.3056 91.6034 72.2564 92.5105 71.9225C93.4175 71.5887 94.2 70.9863 94.7523 70.1965C95.3047 69.4066 95.6004 68.4674 95.5998 67.5052Z"
            fill="#AD4949"
        />
        <Path
            d="M102.469 79.2177C99.8155 85.7752 95.5245 80.9381 95.5245 80.9381C95.0724 80.2718 94.8111 79.4957 94.7686 78.6929C94.7261 77.89 94.904 77.0909 95.2832 76.381C95.6624 75.6711 96.2287 75.0773 96.9213 74.6632C97.6139 74.2491 98.4067 74.0303 99.2147 74.0303C99.2872 74.0303 99.3593 74.032 99.4309 74.0354C99.4309 74.0354 104.223 74.8856 102.469 79.2177Z"
            fill="#CD5A5A"
        />
        <Path
            d="M84.0214 79.0549C84.0218 78.0402 83.6526 77.0597 82.9822 76.2949C83.0301 76.4302 84.2091 79.8829 80.5964 81.2691C77.2375 82.558 75.6843 79.7934 75.5872 79.6129C75.7284 80.6735 76.2699 81.6409 77.1018 82.3189C77.9337 82.9969 78.9936 83.3348 80.0667 83.2638C81.1397 83.1929 82.1454 82.7186 82.8798 81.9371C83.6142 81.1555 84.0224 80.1254 84.0214 79.0555V79.0549Z"
            fill="#AD4949"
        />
        <Path
            d="M95.5247 80.938C95.9489 81.5642 96.527 82.0721 97.2041 82.4133C97.8812 82.7545 98.6347 82.9178 99.3931 82.8876C100.152 82.8574 100.89 82.6347 101.537 82.2407C102.185 81.8467 102.72 81.2945 103.093 80.6366C103.465 79.9786 103.662 79.2368 103.665 78.4817C103.669 77.7266 103.477 76.9832 103.11 76.3222C102.743 75.6613 102.212 75.1047 101.568 74.7054C100.924 74.3062 100.188 74.0774 99.4297 74.041C99.718 74.1303 103.283 75.3225 101.391 79.1516C98.8338 84.328 95.5372 80.9513 95.5247 80.938Z"
            fill="#AD4949"
        />
        <Path
            d="M89.1608 67.8908C89.8589 67.8908 90.4247 67.3279 90.4247 66.6334C90.4247 65.9389 89.8589 65.376 89.1608 65.376C88.4628 65.376 87.897 65.9389 87.897 66.6334C87.897 67.3279 88.4628 67.8908 89.1608 67.8908Z"
            fill="#FFE9E9"
        />
        <Path
            d="M78.8913 79.0251C79.5602 79.0251 80.1024 78.4856 80.1024 77.8202C80.1024 77.1547 79.5602 76.6152 78.8913 76.6152C78.2224 76.6152 77.6802 77.1547 77.6802 77.8202C77.6802 78.4856 78.2224 79.0251 78.8913 79.0251Z"
            fill="#FFE9E9"
        />
        <Path
            d="M79.1173 94.5379C79.7215 94.5379 80.2112 94.0507 80.2112 93.4496C80.2112 92.8486 79.7215 92.3613 79.1173 92.3613C78.5132 92.3613 78.0234 92.8486 78.0234 93.4496C78.0234 94.0507 78.5132 94.5379 79.1173 94.5379Z"
            fill="#FFE9E9"
        />
        <Path
            d="M89.441 92.097C90.0935 92.097 90.6225 91.5707 90.6225 90.9215C90.6225 90.2724 90.0935 89.7461 89.441 89.7461C88.7885 89.7461 88.2595 90.2724 88.2595 90.9215C88.2595 91.5707 88.7885 92.097 89.441 92.097Z"
            fill="#FFE9E9"
        />
        <Path
            d="M70.4343 87.6807C70.9848 87.6807 71.4311 87.2367 71.4311 86.689C71.4311 86.1413 70.9848 85.6973 70.4343 85.6973C69.8838 85.6973 69.4375 86.1413 69.4375 86.689C69.4375 87.2367 69.8838 87.6807 70.4343 87.6807Z"
            fill="#FFE9E9"
        />
        <Path
            d="M89.1894 79.7489C89.903 79.7489 90.4815 79.1733 90.4815 78.4633C90.4815 77.7533 89.903 77.1777 89.1894 77.1777C88.4757 77.1777 87.8972 77.7533 87.8972 78.4633C87.8972 79.1733 88.4757 79.7489 89.1894 79.7489Z"
            fill="#FFE9E9"
        />
        <Path
            d="M97.4479 79.0251C98.1168 79.0251 98.659 78.4856 98.659 77.8202C98.659 77.1547 98.1168 76.6152 97.4479 76.6152C96.779 76.6152 96.2368 77.1547 96.2368 77.8202C96.2368 78.4856 96.779 79.0251 97.4479 79.0251Z"
            fill="#FFE9E9"
        />
    </Svg>
);

export default Pommegranate;
