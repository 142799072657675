import type { ComponentProps, ReactNode } from "react";
import { FormControl as BaseFormControl, Text } from "native-base";
import { isWeb } from "@madmedical/utils";

type FormControlProps = ComponentProps<typeof BaseFormControl>;

interface Props extends Pick<FormControlProps, "isInvalid" | "isRequired"> {
    error?: string;
    hint?: string;
    label?: string;
    labelProps?: ComponentProps<typeof BaseFormControl.Label>;
    children: ReactNode;
}

const FormControl = ({
    isInvalid = false,
    isRequired = false,
    error,
    hint,
    label,
    labelProps,
    children,
}: Props) => (
    <BaseFormControl
        flex={isWeb ? 1 : undefined}
        isInvalid={isInvalid}
        isRequired={isRequired}
    >
        {label && (
            <BaseFormControl.Label
                testID="base-form-control-label"
                fontWeight="medium"
                {...labelProps}
            >
                {label}
            </BaseFormControl.Label>
        )}
        {children}
        {hint && !error && (
            <Text
                testID="base-form-control-hint"
                mt={1}
                fontSize="sm"
                color="gray.600"
            >
                {hint}
            </Text>
        )}
        {error && (
            <BaseFormControl.ErrorMessage
                testID="base-form-control-error"
                mt={1}
                _text={{ fontSize: "sm" }}
            >
                {error}
            </BaseFormControl.ErrorMessage>
        )}
    </BaseFormControl>
);

export default FormControl;
