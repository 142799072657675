import type { Ulid } from "@madmedical/utils";

export enum ProfileRole {
    Doctor = "doctor",
    Patient = "patient",
    Representative = "representative",
    Hotline = "hotline",
}

export type Profile = {
    role: ProfileRole;
    givenName: string;
    familyName: string;
    fullName: string;
    initials: string;
    avatarUrl?: string;
};

export type ProfileWithId = Profile & {
    userId: Ulid;
};
