import { Device, useShowToast } from "@madmedical/ui";
import { usePatientId } from "@madmedical/user";
import { useGetPatientProtocolsQuery } from "@madmedical/threshold";
import { useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import useBleContext from "../ble/useBleContext";
import type { ResolvedBleDevice } from "../model";
import useBlePersistence from "../ble/useBlePersistence";

interface Props {
    device: ResolvedBleDevice;
    canAddDevice: boolean;
    showAppDownload: boolean;
    onDismissAppDownload: () => void;
}

const BleDevice = ({
    device,
    canAddDevice,
    showAppDownload,
    onDismissAppDownload,
}: Props) => {
    const { patientId } = usePatientId();
    const [isLoading, setLoading] = useState(false);
    const { connect, disconnect } = useBleContext();
    const { addDevice, removeDevice } = useBlePersistence();
    const showToast = useShowToast();
    const { data: protocols } = useGetPatientProtocolsQuery({ patientId });
    const { t } = useTranslation();
    const handleConnectPress = async () => {
        if (!connect) {
            // We probably are on web
            return;
        }

        setLoading(true);
        try {
            const connectResponse = await connect(device.provider);

            if (connectResponse.status === "notFound") {
                showToast(t("deviceNotfound"), { variant: "error" });

                return;
            }

            await addDevice(connectResponse.device);

            showToast(t("successfulConnection"), { variant: "success" });
        } catch (err) {
            console.log("ERROR", err, JSON.stringify(err));
            showToast(t("unsuccessfulConnection"), { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnectPress = async () => {
        setLoading(true);
        try {
            await removeDevice(device.provider);

            disconnect?.(device.provider);

            showToast(t("deviceDisconnected"), { variant: "success" });
        } catch (e) {
            console.error(e);
            showToast(t("deviceDisconnectedUnsuccessful"), {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Device
            device={device}
            protocols={
                protocols?.filter(({ metricType }) =>
                    device.metricTypes.includes(metricType)
                ) ?? []
            }
            onConnectPress={handleConnectPress}
            onDisconnectPress={handleDisconnectPress}
            canAddDevice={canAddDevice}
            isConnectLoading={isLoading}
            showAppDownload={showAppDownload}
            onDismissAppDownload={onDismissAppDownload}
        />
    );
};

export default BleDevice;
