import { Device, useShowToast } from "@madmedical/ui";
import { useState } from "react";
import { AppleDeviceType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import type { ResolvedAppleDevice } from "../model";
import useAppleContext from "../apple/useAppleContext";
import { useBindDeviceMutation, useUnbindDeviceMutation } from "../api";

interface Props {
    device: ResolvedAppleDevice;
    canAddDevice: boolean;
    showAppDownload: boolean;
    onDismissAppDownload: () => void;
}

const AppleDevice = ({
    device,
    canAddDevice,
    showAppDownload,
    onDismissAppDownload,
}: Props) => {
    const { connect, disconnect } = useAppleContext();
    const [isLoading, setLoading] = useState(false);
    const [bind] = useBindDeviceMutation();
    const [unbind] = useUnbindDeviceMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    const handleConnectPress = async () => {
        if (!connect) {
            // Probably not iOS
            return;
        }

        setLoading(true);
        try {
            const connected = await connect();

            if (!connected) {
                showToast(t("unsuccessfulConnection"), { variant: "error" });

                return;
            }

            await bind({
                provider: AppleDeviceType.Apple,
                deviceId: "n/a",
            });
            showToast(t("successfulConnection"), { variant: "success" });
        } catch (e) {
            console.error(e);
            showToast(t("unsuccessfulConnection"), { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnectPress = async () => {
        if (!disconnect) {
            // Probably not iOS
            return;
        }

        setLoading(true);
        try {
            const disconnected = await disconnect();

            if (!disconnected) {
                showToast(t("deviceDisconnectedUnsuccessful"), {
                    variant: "error",
                });

                return;
            }

            await unbind({ provider: AppleDeviceType.Apple });
            showToast(t("deviceDisconnected"), { variant: "success" });
        } catch (e) {
            console.error(e);
            showToast(t("deviceDisconnectedUnsuccessful"), {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Device
            device={device}
            protocols={[]}
            onConnectPress={handleConnectPress}
            onDisconnectPress={handleDisconnectPress}
            canAddDevice={canAddDevice}
            isConnectLoading={isLoading}
            showAppDownload={showAppDownload}
            onDismissAppDownload={onDismissAppDownload}
        />
    );
};

export default AppleDevice;
