import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import BaseFormTextAreaControl from "../molecules/Form/FormTextAreaControl";

type Props = Omit<
    ComponentProps<typeof BaseFormTextAreaControl>,
    "onChange"
> & {
    name: string;
    textareaHeight?: number;
};

const FormControl = (props: Props) => {
    const { control, isError, error } = useField(props.name);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{ required: props.isRequired }}
            render={({ field: { onBlur, onChange, value } }) => (
                <BaseFormTextAreaControl
                    {...props}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value as string | undefined}
                    isInvalid={isError}
                    error={error}
                />
            )}
        />
    );
};

export default FormControl;
