import {
    DoctorPatientProvider,
    DoctorPatients as DoctorPatientsUi,
    Spinner,
} from "@madmedical/ui";
import { invariant } from "@madmedical/utils";
import type { PatientForDoctor } from "../model";
import useProvidePatients from "../useProvidePatients";

const DoctorPatients = () => {
    const {
        patients,
        pagination,
        handleSortChange,
        handleSearchChange,
        filterChoices,
        handleFilterChange,
        handleEndReached,
        isLoading,
    } = useProvidePatients<PatientForDoctor>();

    if (isLoading) {
        return <Spinner />;
    }

    invariant(patients, "No patients in sight");
    invariant(pagination, "No patients in sight");

    return (
        <DoctorPatientProvider
            value={{
                patients,
                pagination,
                onSortChange: handleSortChange,
                onSearchChange: handleSearchChange,
                filterChoices,
                onFilterChange: handleFilterChange,
            }}
        >
            <DoctorPatientsUi
                onEndReached={handleEndReached}
            ></DoctorPatientsUi>
        </DoctorPatientProvider>
    );
};

export default DoctorPatients;
