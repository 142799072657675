import { Box } from "native-base";
import Placenta01 from "../../pics/Pregnancy/placenta/Placenta01";
import Placenta03 from "../../pics/Pregnancy/placenta/Placenta03";
import Placenta05 from "../../pics/Pregnancy/placenta/Placenta05";
import Placenta06 from "../../pics/Pregnancy/placenta/Placenta06";
import Placenta07 from "../../pics/Pregnancy/placenta/Placenta07";
import Placenta08 from "../../pics/Pregnancy/placenta/Placenta08";
import Placenta09 from "../../pics/Pregnancy/placenta/Placenta09";
import Placenta10 from "../../pics/Pregnancy/placenta/Placenta10";
import Placenta11 from "../../pics/Pregnancy/placenta/Placenta11";
import Placenta12 from "../../pics/Pregnancy/placenta/Placenta12";
import Placenta13 from "../../pics/Pregnancy/placenta/Placenta13";
import Placenta14 from "../../pics/Pregnancy/placenta/Placenta14";
import Placenta15 from "../../pics/Pregnancy/placenta/Placenta15";
import Placenta16 from "../../pics/Pregnancy/placenta/Placenta16";
import Placenta17 from "../../pics/Pregnancy/placenta/Placenta17";

interface PregnancyPlacentaImageProps {
    week: number; // from 1 to 17
}

const PregnancyPlacentaImage = ({ week }: PregnancyPlacentaImageProps) => {
    switch (true) {
        case week === 1:
            return <Placenta01 />;
        case week === 2:
            // return <Placenta02 />;
            return <Placenta03 />;
        case week === 3:
            // return <Placenta04 />;
            return <Placenta05 />;
        case week === 4 || week === 5:
            return <Placenta06 />;
        case week === 6 || week === 7:
            return <Placenta07 />;
        case week >= 8 && week <= 10:
            return <Placenta08 />;
        case week >= 11 && week <= 13:
            return <Placenta09 />;
        case week >= 14 && week <= 16:
            return <Placenta10 />;
        case week >= 17 && week <= 19:
            return <Placenta11 />;
        case week >= 20 && week <= 23:
            return <Placenta12 />;
        case week >= 24 && week <= 27:
            return <Placenta13 />;
        case week >= 28 && week <= 31:
            return <Placenta14 />;
        case week === 32 || week === 33:
            return <Placenta15 />;
        case week === 34 || week === 35:
            return <Placenta16 />;
        case week >= 36:
            return <Placenta17 />;
        default:
            return (
                <Box
                    width={130}
                    height={130}
                    borderRadius={65}
                    bgColor="white"
                />
            );
    }
};

export default PregnancyPlacentaImage;
