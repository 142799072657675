import { InsulinPageContent, Spinner } from "@madmedical/ui";
import { useState } from "react";
import { useMe, usePatientId } from "@madmedical/user";
import { UnderlyingCondition } from "@madmedical/medical";
import AddCommentButtonForm from "../../comment/components/AddCommentButtonForm";
import { useGetInsulinProcolByPatientQuery } from "../api";
import CreateInsulinReminderModalForm from "./CreateInsulinReminderModalForm";
import CreateInsulinAdministrationModalForm from "./ManualInsulinAdministrationModalForm";
import UpdateInsulinProtocolModalForm from "./UpdateInsulinProtocolModalForm";
import InsulinBodyChartProvider from "./InsulinBodyChartProvider";
import InsulinPortioningProvider from "./InsulinPortioningProvider";
import InsulinBloodSugarProvider from "./InsulinBloodSugarProvider";

enum ModalState {
    Closed,
    AdministrationOpen,
    ReminderOpen,
    ProtocolOpen,
}

export const InsulinPage = () => {
    const {
        roles: { isPatient },
        patient,
    } = useMe();

    const { patientId } = usePatientId();

    const {
        data: insulinProtocol,
        isLoading,
        refetch: refetchProtocol,
    } = useGetInsulinProcolByPatientQuery({
        patientId,
    });

    const [insulinAdministrationModal, setInsulinAdministrationModal] =
        useState<ModalState>(ModalState.Closed);

    const [insulinReminderModal, setInsulinReminderModal] =
        useState<ModalState>(ModalState.Closed);

    const [insulinProtocolModal, setInsulinProtocolModal] =
        useState<ModalState>(ModalState.Closed);

    const [selectedProtocol, setSelectedProtocol] = useState<{
        id: string;
        instructions: string;
    }>({
        id: "",
        instructions: "",
    });

    const editSelectedProtocol = (id: string, instructions: string) => {
        setInsulinProtocolModal(ModalState.ProtocolOpen);
        setSelectedProtocol({
            id,
            instructions,
        });
    };

    if (isLoading) {
        return <Spinner />;
    }

    const insulinUser =
        patient?.underlyingConditions.includes(UnderlyingCondition.Insulin) &&
        patient?.packages?.some((p) => p.type === "diabetes");

    return (
        <InsulinBloodSugarProvider>
            <InsulinPageContent
                addCommentButton={<AddCommentButtonForm />}
                onAddInsulinAdministration={() =>
                    setInsulinAdministrationModal(ModalState.AdministrationOpen)
                }
                onAddInsulinReminder={() =>
                    setInsulinReminderModal(ModalState.ReminderOpen)
                }
                isPatient={isPatient}
                insulinProtocol={insulinProtocol}
                editInsulinProtocol={editSelectedProtocol}
                BodyChartWrapper={InsulinBodyChartProvider}
                PortioningWrapper={InsulinPortioningProvider}
                insulinUser={insulinUser}
            />
            <CreateInsulinAdministrationModalForm
                open={
                    insulinAdministrationModal === ModalState.AdministrationOpen
                }
                onClose={() => setInsulinAdministrationModal(ModalState.Closed)}
            />
            <CreateInsulinReminderModalForm
                open={insulinReminderModal === ModalState.ReminderOpen}
                onClose={() => setInsulinReminderModal(ModalState.Closed)}
            />
            <UpdateInsulinProtocolModalForm
                open={insulinProtocolModal === ModalState.ProtocolOpen}
                onClose={() => setInsulinProtocolModal(ModalState.Closed)}
                protocol={selectedProtocol}
                refetchProtocol={refetchProtocol as () => void}
            />
        </InsulinBloodSugarProvider>
    );
};
