import type { AdministrationSide } from "@madmedical/medical";
import {
    BODY_PARTS_FOR_HUMANS,
    InsulinBodyPart,
    InsulinType,
    SIDES_FOR_HUMANS,
} from "@madmedical/medical";

export const formatAdministrationSide = (side: AdministrationSide) => {
    switch (side) {
        case "left":
            return "left";
        case "right":
            return "right";
    }
};

export const formatInsertPoint = (part: InsulinBodyPart) => {
    switch (part) {
        case InsulinBodyPart.Arm:
            return "arm"; //"Kar";
        case InsulinBodyPart.Thigh:
            return "thigh"; //"Láb";
        case InsulinBodyPart.Abdomen:
            return "abdomen"; //Has";
        case InsulinBodyPart.Glutes:
            return "glutes"; //"Fenék";
        case InsulinBodyPart.Waist:
            return "waist"; //"Derék";
    }
};

export const formatType = (type: InsulinType) => {
    switch (type) {
        case InsulinType.Basal:
            return "measures:longEffect";
        case InsulinType.Rapid:
            return "measures:fastEffect";
        case InsulinType.Other:
            return "measures:other";
    }
};

const bodyParts = Object.values(InsulinBodyPart) as InsulinBodyPart[];

const side: AdministrationSide[] = ["left", "right"];

type InsulinBodySelectorOption = {
    value: `${InsulinBodyPart}-${AdministrationSide}`;
    label: string;
}[];

export const generateInsulinBodyLocations = (): InsulinBodySelectorOption => {
    const insulinBodyLocations: InsulinBodySelectorOption = [];

    bodyParts.forEach((bodyPart) => {
        side.forEach((side) => {
            insulinBodyLocations.push({
                value: `${bodyPart}-${side}`,
                label: `${BODY_PARTS_FOR_HUMANS[bodyPart]} (${SIDES_FOR_HUMANS[side]})`,
            });
        });
    });

    return insulinBodyLocations;
};
