import { FormControl as BaseFormControl } from "native-base";

interface Props {
    error: string;
}

const FormError = ({ error }: Props) => (
    <BaseFormControl flex={1} isInvalid>
        <BaseFormControl.ErrorMessage mt={1} _text={{ fontSize: "sm" }}>
            {error}
        </BaseFormControl.ErrorMessage>
    </BaseFormControl>
);

export default FormError;
