import { useState } from "react";
import { PregnancyMeasurementPageContent } from "@madmedical/ui";
import { MetricType } from "@madmedical/medical";
import { useMe } from "@madmedical/user";
import type { measureType } from "../model";
import PregnancyMeasurementModalForm from "./PregnancyMeasurementModalForm";
import PregnancyProviderWrapper from "./PregnancyProviderWrapper";

enum ModalState {
    Closed,
    Measure,
    Feeling,
    Edit,
    Delete,
}

const PregnancyMeasurementPage = () => {
    const {
        roles: { isDoctor },
    } = useMe();

    /*
    const { patientId } = usePatientId();
    */

    const [modal, setModal] = useState<ModalState>(ModalState.Closed);
    const [measurementType, setMeasurementType] =
        useState<measureType>("weight");

    const handleMeasurePress = (type: measureType) => {
        setModal(ModalState.Measure);
        setMeasurementType(type);
    };

    const handleModalClose = () => {
        setModal(ModalState.Closed);
    };

    return (
        <>
            <PregnancyProviderWrapper setModal={setModal}>
                <PregnancyMeasurementPageContent
                    onMeasurePress={handleMeasurePress}
                    isDoctor={isDoctor}
                />
                <PregnancyMeasurementModalForm
                    metricType={
                        measurementType === "weight"
                            ? MetricType.BodyWeight
                            : MetricType.AbdominalCircumference
                    }
                    open={modal === ModalState.Measure}
                    onClose={handleModalClose}
                />
            </PregnancyProviderWrapper>
        </>
    );
};

export default PregnancyMeasurementPage;
