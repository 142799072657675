import { useState } from "react";
import { useWindowDimensions } from "react-native";
import { Box, FlatList, useMediaQuery, useTheme } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../providers/currentPatient";
import { useDocuments } from "../providers/document";
import Headline from "../molecules/Headline";
import ListHeader from "../molecules/ListHeader";
import Paper from "../atoms/Paper";
import DocumentsTableGeneral from "../organisms/DocumentsTableGeneral";
import DocumentListItem from "../molecules/ListItem/DocumentListItem";
import Breadcrumb from "../molecules/Breadcrumb";
import Button from "../atoms/Button";

interface Props {
    readonly onEndReached: () => unknown;
    readonly onUploadPress: () => void;
    badge?: number;
}

const DocumentPatient = ({ onEndReached, onUploadPress, badge }: Props) => {
    const { breakpoints } = useTheme();
    const [isSmallScreen] = useMediaQuery({
        minWidth: breakpoints.base,
        maxWidth: breakpoints.md,
    });
    const { documents } = useDocuments("both");
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;
    const { patient } = useCurrentPatient(); // TODO: Extract breadcrumbing
    const { t } = useTranslation();

    return (
        <>
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link
                                    route="patient"
                                    params={{ patientId: patient.userId }}
                                >
                                    {patient.fullName}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("documents")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <Headline
                    minHeight={12}
                    mb={4}
                    title={t("documents")}
                    badge={badge}
                    rightContent={
                        <Button
                            leftIconName="documentAdd"
                            variant="outlined"
                            onPress={onUploadPress}
                        >
                            {t("newUpload")}
                        </Button>
                    }
                />
                {isSmallScreen ? (
                    <>
                        <ListHeader pt={8} title={t("document")} />
                        <FlatList
                            onLayout={(event) => {
                                setFlatListPosition(event.nativeEvent.layout.y);
                            }}
                            maxHeight={screenHeight - flatListPosition - 4}
                            minWidth="full"
                            showsVerticalScrollIndicator={false}
                            data={documents}
                            renderItem={({ item }) => (
                                <DocumentListItem
                                    document={item}
                                    withoutAction={true}
                                />
                            )}
                            keyExtractor={(item) => item.id}
                            overflow="hidden"
                            onEndReached={onEndReached}
                        />
                    </>
                ) : (
                    <Box px={6} w={"100%"} pb={1}>
                        <DocumentsTableGeneral
                            showOnlyGeneral={false}
                            withoutAction={true}
                            onEndReached={onEndReached}
                        />
                    </Box>
                )}
            </Paper>
        </>
    );
};

export default DocumentPatient;
