import {
    DocumentUploadModalForm,
    Modal,
    usePregnancyData,
    useShowToast,
} from "@madmedical/ui";
import { useMe, usePatientId } from "@madmedical/user";
import type { FileToUpload } from "@madmedical/ui";
import { useTranslation } from "@madmedical/i18n";
import { useAttachDocumentMutation, useUploadDocumentMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}

const UploadPregnancyDocModal = ({ open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const [upload, { isLoading: isUploadLoading }] =
        useUploadDocumentMutation();
    const [attach, { isLoading: isAttachLoading }] =
        useAttachDocumentMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    const { pregnancyData } = usePregnancyData();
    const pregnancyId = pregnancyData?.id;

    const { doctor } = useMe();
    const doctorId = doctor?.userId;

    const handleSubmit = async (files: FileToUpload[]) => {
        const data = new FormData();
        files.forEach(({ file }) => data.append("file[]", file));

        const documents = await upload(data).unwrap();
        const promises = documents.map((document, index) =>
            attach({
                id: document.id,
                patientId,
                note: files[index].note,
                service: files[index].service ?? undefined,
                pregnancyId,
                doctorId,
            }).unwrap()
        );
        await Promise.all(promises);

        showToast(t("successfulUpload"), { variant: "success" });
        onClose();
    };

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <DocumentUploadModalForm
                isUploading={isUploadLoading || isAttachLoading}
                onSubmit={handleSubmit}
                onClose={onClose}
            />
        </Modal>
    );
};

export default UploadPregnancyDocModal;
