import { useEffect, useMemo, useState } from "react";
import {
    Button as BaseButton,
    Box,
    HStack,
    Popover,
    Pressable,
    Text,
    useDisclose,
    useTheme,
} from "native-base";
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    formatDateRange,
    formatMonthDay,
    formatYear,
    formatYearMonth,
    invariant,
    isEqual,
    resolvePeriod,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useChart } from "../providers/chart";
import Button from "../atoms/Button";
import type { Period } from "../molecules/DatePeriodStepper";
import DatePeriodStepper from "../molecules/DatePeriodStepper";
import type { CalendarDay } from "../util/calendar";
import { createCalendar, dayList, monthList } from "../util/calendar";
import Icon from "../icons/Icon";
import IconButton from "../atoms/IconButton";
import useResponsive from "../util/useResponsive";

/**
 * This be a horribly overcomplicated abomination
 * with a hacky adapter on top as it never had an input or an output in the first place.
 * TODO: Eliminate / sow with salt / refactor / rewrite
 */

const DateRangePicker = ({ periods }: { periods?: Period[] }) => {
    const { isSmallScreen } = useResponsive();
    const { colors } = useTheme();
    const { dateRange, setDateRange } = useChart();
    const resolvedPeriod = resolvePeriod(dateRange);
    const [period, setPeriod] = useState(resolvedPeriod);
    const { isOpen, onClose, onOpen } = useDisclose();

    const [selectedDate, setSelectedDate] = useState(dateRange.from);
    const [calendarDate, setCalendarDate] = useState(dateRange.from);

    const calendar = createCalendar(calendarDate);
    const { t } = useTranslation();
    const resolvedDateRange = useMemo(() => {
        switch (period) {
            case "day":
                return {
                    from: startOfDay(selectedDate),
                    to: endOfDay(selectedDate),
                };
            case "week":
                return {
                    from: startOfWeek(selectedDate),
                    to: endOfWeek(selectedDate),
                };
            case "month":
                return {
                    from: startOfMonth(selectedDate),
                    to: endOfMonth(selectedDate),
                };
        }
    }, [period, selectedDate]);

    useEffect(() => {
        invariant(setDateRange); // Should not render picker if no callback (e.g. predictions)
        setDateRange(resolvedDateRange);
    }, [setDateRange, resolvedDateRange]);

    const handlePeriodChange = (period: "day" | "week" | "month") => {
        setPeriod(period);
    };

    const handleDayPress = (selected: CalendarDay) => () => {
        const offset =
            selected.monthOffset === "actual"
                ? 0
                : selected.monthOffset === "prev"
                ? -1
                : 1;

        const _date = new Date(
            calendarDate.getFullYear(),
            calendarDate.getMonth() + offset,
            Number(selected.day)
        );

        setSelectedDate(_date);
        onClose();
    };

    const isSelectedWeek = (selected: CalendarDay) => {
        const offset =
            selected.monthOffset === "actual"
                ? 0
                : selected.monthOffset === "prev"
                ? -1
                : 1;

        const date = new Date(
            calendarDate.getFullYear(),
            calendarDate.getMonth() + offset,
            selected.day
        );

        return (
            isEqual(startOfDay(date), startOfDay(startOfWeek(selectedDate))) ||
            isEqual(startOfDay(date), startOfDay(endOfWeek(selectedDate)))
        );
    };

    const handleMonthPress = (month: number | string) => () => {
        const _date = new Date(calendarDate.getFullYear(), Number(month), 1);

        setSelectedDate(_date);
        onClose();
    };

    const handlePagerPress = (direction: "plus" | "minus") => () => {
        const date = new Date(selectedDate);

        switch (period) {
            case "day":
                date.setDate(
                    selectedDate.getDate() + (direction === "minus" ? -1 : 1)
                );
                setSelectedDate(date);

                break;
            case "week":
                date.setDate(
                    selectedDate.getDate() + (direction === "minus" ? -7 : 7)
                );
                setSelectedDate(date);

                break;
            case "month":
                date.setMonth(
                    selectedDate.getMonth() + (direction === "minus" ? -1 : 1)
                );
                setSelectedDate(date);

                break;
        }
    };

    const handleCalendarPagerPress = (direction: "plus" | "minus") => {
        const date = new Date(calendarDate);

        switch (period) {
            case "day":
            case "week":
                date.setMonth(
                    calendarDate.getMonth() + (direction === "minus" ? -1 : 1)
                );
                setCalendarDate(date);

                break;
            case "month":
                date.setFullYear(
                    calendarDate.getFullYear() +
                        (direction === "minus" ? -1 : 1)
                );
                setCalendarDate(date);

                break;
        }
    };

    const selectedDateFormatted = useMemo(() => {
        switch (period) {
            case "day":
                return formatMonthDay(selectedDate);
            case "week":
                return formatDateRange({
                    from: startOfWeek(selectedDate),
                    to: endOfWeek(selectedDate),
                });
            case "month":
                return formatYearMonth(selectedDate);
        }
    }, [period, selectedDate]);

    return (
        <Box w="100%" alignItems="center">
            <Popover
                useRNModal
                isOpen={isOpen}
                onClose={onClose}
                trigger={(triggerProps) => (
                    <BaseButton.Group
                        isAttached
                        width={isSmallScreen ? "full" : "auto"}
                    >
                        <IconButton
                            onPress={handlePagerPress("minus")}
                            variant="outlined"
                            iconName="arrowLeft"
                        />
                        <Button
                            {...triggerProps}
                            onPress={onOpen}
                            variant="outlined"
                            leftIconName="calendar"
                            flex={isSmallScreen ? 1 : "initial"}
                        >
                            {selectedDateFormatted}
                        </Button>
                        <IconButton
                            onPress={handlePagerPress("plus")}
                            variant="outlined"
                            iconName="arrowRight"
                        />
                    </BaseButton.Group>
                )}
            >
                <Popover.Content
                    accessibilityLabel="Naptár"
                    w="310px"
                    h="380px"
                >
                    <Box flex={1} p={"16px"} bgColor="white">
                        <Box
                            borderBottomWidth={0.5}
                            borderBottomColor={colors.gray[200]}
                            pb={4}
                        >
                            <DatePeriodStepper
                                periods={periods}
                                period={period}
                                onPeriodChange={handlePeriodChange}
                            />
                        </Box>
                        <HStack
                            justifyContent="space-between"
                            alignItems="center"
                            px="4"
                            py="2"
                        >
                            <Pressable
                                onPress={() =>
                                    handleCalendarPagerPress("minus")
                                }
                            >
                                <Icon
                                    name="chevronLeft"
                                    size={16}
                                    fill="black"
                                />
                            </Pressable>
                            <Text fontSize="sm" fontWeight="bold">
                                {period !== "month"
                                    ? formatYearMonth(calendarDate)
                                    : formatYear(calendarDate)}
                            </Text>
                            <Pressable
                                onPress={() => handleCalendarPagerPress("plus")}
                            >
                                <Icon
                                    name="chevronRight"
                                    size={16}
                                    fill="black"
                                />
                            </Pressable>
                        </HStack>
                        {period !== "month" && (
                            <HStack mt={2}>
                                {dayList.map((day) => (
                                    <Box
                                        m={0}
                                        w={"39px"}
                                        justifyContent="center"
                                        alignItems="center"
                                        key={day}
                                    >
                                        <Text fontWeight={"500"}>{day}</Text>
                                    </Box>
                                ))}
                            </HStack>
                        )}

                        <Box
                            flexDirection={"row"}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                        >
                            {period === "month"
                                ? monthList.map((month, index) => {
                                      const selected =
                                          selectedDate.getMonth() === index &&
                                          selectedDate.getFullYear() ===
                                              calendarDate.getFullYear();

                                      return (
                                          <Pressable
                                              key={index}
                                              onPress={handleMonthPress(index)}
                                              width={"91px"}
                                              height={"30px"}
                                              mt={"30px"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              bgColor={
                                                  selected
                                                      ? colors.green[500]
                                                      : "white"
                                              }
                                              borderRadius={selected ? 4 : 0}
                                          >
                                              <Text
                                                  color={
                                                      selected
                                                          ? "white"
                                                          : colors.gray[700]
                                                  }
                                                  fontWeight={"400"}
                                              >
                                                  {t(`${month}`)}
                                              </Text>
                                          </Pressable>
                                      );
                                  })
                                : Object.values(
                                      calendar?.[calendarDate.getFullYear()]?.[
                                          calendarDate.getMonth()
                                      ] || {}
                                  ).map((e, index) => {
                                      let selected = false;
                                      if (period === "day") {
                                          selected =
                                              e.day ===
                                                  selectedDate.getDate() &&
                                              isEqual(
                                                  startOfMonth(selectedDate),
                                                  startOfMonth(calendarDate)
                                              ) &&
                                              e.monthOffset === "actual";
                                      }
                                      if (period === "week") {
                                          selected = isSelectedWeek(e);
                                      }

                                      return (
                                          <Pressable
                                              key={index}
                                              onPress={handleDayPress(e)}
                                              width={"39px"}
                                              height={"39px"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              bgColor={
                                                  selected
                                                      ? colors.green[500]
                                                      : e.monthOffset ===
                                                        "actual"
                                                      ? "white"
                                                      : colors.gray[50]
                                              }
                                              borderRadius={selected ? 4 : 0}
                                          >
                                              <Text
                                                  color={
                                                      selected
                                                          ? "white"
                                                          : e.monthOffset ===
                                                            "actual"
                                                          ? colors.gray[700]
                                                          : colors.gray[500]
                                                  }
                                                  fontWeight={"400"}
                                              >
                                                  {e.day}
                                              </Text>
                                          </Pressable>
                                      );
                                  })}
                        </Box>
                    </Box>
                </Popover.Content>
            </Popover>
        </Box>
    );
};

export default DateRangePicker;
