import { Box, Center, Divider } from "native-base";
import type { ComponentProps } from "react";
import type { Ulid } from "@madmedical/utils";
import { useNavigate } from "@madmedical/routing";
import { useTranslation } from "@madmedical/i18n";
import InsulinMeasurementsTable from "../../organisms/InsulinMeasurementsTable";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import type InsulinMeasurementListItem from "../../molecules/ListItem/InsulinMeasurementListItem";
import ButtonLink from "../../atoms/ButtonLink";
import type { InsulinAdministration } from "../../providers/insulinAdministration";
import DashboardInsulinMeasurementContentMobile from "./DashboardInsulinMeasurementContentMobile";

export type MeasurementData = Omit<
    ComponentProps<typeof InsulinMeasurementListItem>,
    "onDeletePress"
> & {
    id: Ulid;
    editable?: boolean;
} & InsulinAdministration;

type HeaderType = {
    title: string;
    badge: number;
};

export type SectionType = {
    header?: HeaderType;
    data: InsulinAdministration[];
};

interface Props {
    data: InsulinAdministration[];
    isPatient?: boolean;
}

type DesktopLayoutProps = {
    section: MeasurementData[];
};

const DesktopLayout = ({ section }: DesktopLayoutProps) => {
    const navigate = useNavigate();

    if (!section) {
        return null;
    }

    const handlePress = () => {
        navigate("insulin", {});
    };

    return (
        <>
            <Box width="full" px={6} py={4} key={`measurement-table-section`}>
                <InsulinMeasurementsTable
                    data={section}
                    pressLink={handlePress}
                />
            </Box>
        </>
    );
};

const DashboardInsulinMeasurementList = ({ data, isPatient = true }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const todaysItems = data
        ?.filter((item) => {
            const administeredAt = new Date(item.administeredAt);
            const today = new Date();

            const time1 = administeredAt.getTime();
            const time2 = today.getTime();

            const diff = Math.abs(time1 - time2);
            const hours = Math.floor(diff / (1000 * 60 * 60));

            return hours <= 24;
        })
        .slice(0, 5);

    return isSmallScreen ? (
        <>
            <ListHeader
                title={
                    isPatient ? t("latestInsulinInjection") : t("insulinDiary")
                }
                borderBottomWidth={0}
                px={0}
            />

            <Paper mb={3}>
                <DashboardInsulinMeasurementContentMobile
                    sections={todaysItems.slice(0, 3) as MeasurementData[]}
                />
                <Center my={4} width="full">
                    <ButtonLink
                        size="sm"
                        variant="outlined"
                        route="insulin_administrations"
                        params={{}}
                    >
                        {t("viewAll")}
                    </ButtonLink>
                </Center>
            </Paper>
        </>
    ) : (
        <Box marginBottom={4}>
            <Paper
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
                width="full"
                bgColor={"white"}
            >
                <ListHeader
                    title={
                        isPatient
                            ? t("latestInsulinInjection")
                            : t("insulinDiary")
                    }
                    borderBottomWidth={1}
                    px={8}
                    variant="inverted"
                />
                <Divider
                    _light={{
                        bg: "gray.100",
                    }}
                />

                <DesktopLayout section={todaysItems as MeasurementData[]} />
                <Center my={4} width="full">
                    <ButtonLink
                        size="sm"
                        variant="outlined"
                        route="insulin_administrations"
                        params={{}}
                    >
                        {t("viewAll")}
                    </ButtonLink>
                </Center>
            </Paper>
        </Box>
    );
};

export default DashboardInsulinMeasurementList;
