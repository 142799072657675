import { stringify } from "query-string";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import type { DateRange, DateRangeStrings } from "@madmedical/utils";
import {
    getTimeZone,
    isValidDateRange,
    rangeToString,
} from "@madmedical/utils";
import type { RootState } from "./store";
import jsonReplacer from "./jsonReplacer";

const paramsSerializer = (params: Record<string, unknown>) => {
    Object.keys(params).forEach((paramKey) => {
        if (!isValidDateRange(params[paramKey])) {
            return;
        }

        // Would be better to find a package that can do nested serialisation to ISO string
        const dateRange = rangeToString(
            params[paramKey] as DateRange | DateRangeStrings
        );
        params[`${paramKey}[from]`] = dateRange.from;
        params[`${paramKey}[to]`] = dateRange.to;
        delete params[paramKey];
    });

    return stringify(params, {
        arrayFormat: "bracket",
        sort: false,
        skipNull: true,
        skipEmptyString: true,
    });
};

const prepareCommonHeaders = (headers: Headers) => {
    headers.set("TimeZone", getTimeZone());

    return headers;
};

interface BaseQueryOptions {
    withoutAuth: boolean;
    hostnameBackend: string;
}

export default ({ withoutAuth, hostnameBackend }: BaseQueryOptions) =>
    fetchBaseQuery({
        baseUrl: `https://${hostnameBackend}/api/`,
        prepareHeaders: (headers, { getState }) => {
            if (!withoutAuth) {
                const token = (getState() as RootState).auth.token;

                if (token) {
                    headers.set("Authorization", `Bearer ${token}`);
                }
            }

            return prepareCommonHeaders(headers);
        },
        paramsSerializer,
        jsonReplacer,
    });
