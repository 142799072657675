import type { ComponentProps } from "react";
import { Button as BaseButton } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";

export type Period = "day" | "week" | "month";

interface Props {
    period: Period;
    size?: ComponentProps<typeof Button>["size"];
    onPeriodChange: (v: Period) => unknown;
    isWeekOnly?: boolean;
    periods?: Period[];
}

const DatePeriodStepper = ({
    period,
    onPeriodChange,
    size = "sm",
    periods,
}: Props) => {
    const { t } = useTranslation();

    const byPeriods = {
        day: t("days"),
        week: t("weeks"),
        month: t("months"),
    } as const;

    return (
        <BaseButton.Group
            width="full"
            isAttached
            colorScheme="green"
            mx={{
                base: "auto",
                md: 0,
            }}
            size={size}
        >
            {(periods ?? (["day", "week", "month"] as const)).map((_period) => (
                <Button
                    key={`date-scope-stepper-${_period}`}
                    flex={1}
                    onPress={() => onPeriodChange(_period)}
                    variant={_period === period ? "primary" : "outlined"}
                >
                    {byPeriods[_period]}
                </Button>
            ))}
        </BaseButton.Group>
    );
};

export default DatePeriodStepper;
