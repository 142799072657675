import { Box, HStack, VStack } from "native-base";
import { formatDate } from "@madmedical/utils";
import { useCallback } from "react";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../../util/useResponsive";
import { useCurrentPatient } from "../../../providers/currentPatient";
import FormControl from "../../../form/FormControl";
import FormDisplay from "../../../molecules/Form/FormControl";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Welcome = ({ onPreviousPress, onNextPress }: Props) => {
    const { patient } = useCurrentPatient();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const validate = useCallback(
        (values: {
            bodyWeightKg?: string;
            bodyHeightCm?: string;
            operation?: string;
        }) => {
            const bodyWeightKg = parseInt(values.bodyWeightKg ?? "0");
            const bodyHeightCm = parseInt(values.bodyHeightCm ?? "0");
            const operation = values.operation ?? "";

            return (
                bodyWeightKg > 1 &&
                bodyWeightKg < 500 &&
                bodyHeightCm > 30 &&
                bodyHeightCm < 250 &&
                operation.length > 3
            );
        },
        []
    );

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            validate={validate}
        >
            <VStack
                space={6}
                flex={1}
                width={{
                    base: "full",
                    md: "1/2",
                }}
                p={{
                    base: 4,
                    md: 6,
                }}
            >
                <HStack space={3}>
                    <FormDisplay
                        value={patient.fullName}
                        label={t("name")}
                        isReadOnly
                        size="md"
                    />
                </HStack>
                <Box width="full">
                    <FormDisplay
                        value={
                            patient.birthDate
                                ? formatDate(patient.birthDate)
                                : "–"
                        }
                        label={t("dateOfBirth")}
                        isReadOnly
                        size="md"
                    />
                </Box>
                <HStack space={3} direction={isSmallScreen ? "column" : "row"}>
                    <FormControl
                        name="bodyWeightKg"
                        type="number"
                        label={t("measures:bodyWeight")}
                        suffix={<Box px={3}>kg</Box>}
                        size="md"
                        isRequired
                    />
                    <FormControl
                        name="bodyHeightCm"
                        type="number"
                        label={t("height")}
                        suffix={<Box px={3}>cm</Box>}
                        size="md"
                        isRequired
                    />
                </HStack>
                <Box>
                    <FormControl
                        name="operation"
                        label={t("plannedSurgery")}
                        size="md"
                        isRequired
                    />
                </Box>
            </VStack>
        </PreOpWrapper>
    );
};

export default Welcome;
