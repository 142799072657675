import { Box } from "native-base";
import PregnantBelly01 from "../../pics/Pregnancy/belly/PregnantBelly01";
import PregnantBelly02 from "../../pics/Pregnancy/belly/PregnantBelly02";
import PregnantBelly03 from "../../pics/Pregnancy/belly/PregnantBelly03";
import PregnantBelly04 from "../../pics/Pregnancy/belly/PregnantBelly04";
import PregnantBelly05 from "../../pics/Pregnancy/belly/PregnantBelly05";
import PregnantBelly06 from "../../pics/Pregnancy/belly/PregnantBelly06";
import PregnantBelly07 from "../../pics/Pregnancy/belly/PregnantBelly07";
import PregnantBelly08 from "../../pics/Pregnancy/belly/PregnantBelly08";
import PregnantBelly09 from "../../pics/Pregnancy/belly/PregnantBelly09";

interface PregnancyWeek {
    week: number;
}

const PregnancyBodyImage = ({ week }: PregnancyWeek) => {
    switch (true) {
        case week > 0 && week <= 4:
            return <PregnantBelly01 />;
        case week >= 5 && week <= 8:
            return <PregnantBelly02 />;
        case week >= 9 && week <= 12:
            return <PregnantBelly03 />;
        case week >= 13 && week <= 16:
            return <PregnantBelly04 />;
        case week >= 17 && week <= 20:
            return <PregnantBelly05 />;
        case week >= 21 && week <= 26:
            return <PregnantBelly06 />;
        case week >= 27 && week <= 33:
            return <PregnantBelly07 />;
        case week >= 34 && week <= 35:
            return <PregnantBelly08 />;
        case week >= 36:
            return <PregnantBelly09 />;
        default:
            return (
                <Box
                    width={130}
                    height={130}
                    borderRadius={65}
                    bgColor="white"
                />
            );
    }
};

export default PregnancyBodyImage;
