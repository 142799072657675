import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const Placenta01 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#D67088"
        />
        <G clipPath="url(#clip0_1024_101174)">
            <Path
                d="M66.5001 114.529C94.5676 114.529 117.321 92.3487 117.321 64.9877C117.321 37.6267 94.5676 15.4463 66.5001 15.4463C38.4325 15.4463 15.6792 37.6267 15.6792 64.9877C15.6792 92.3487 38.4325 114.529 66.5001 114.529Z"
                fill="#C65D6F"
            />
            <Path
                d="M66.5 75C72.299 75 77 70.299 77 64.5C77 58.701 72.299 54 66.5 54C60.701 54 56 58.701 56 64.5C56 70.299 60.701 75 66.5 75Z"
                fill="#F7DECB"
            />
        </G>
        <Path
            d="M40.4563 30.1759C40.2235 26.9379 38.8357 23.6914 35.526 23.3442C33.9684 23.3297 32.9521 24.1694 31.1736 24.8467C28.8944 25.9479 25.7178 25.7116 24.0719 23.5813C21.7271 20.9941 22.3958 17.1633 21.7147 14.1458C21.1837 12.3114 19.6961 10.6728 17.6174 11.6896C16.0248 12.4871 13.0747 13.6493 11.8025 11.2579C10.8475 9.59927 11.7325 7.47062 10.4258 6.10411C8.81018 4.8792 7.37244 7.84805 5.62508 7.15072C5.06338 6.90377 4.59551 6.12797 5.054 5.6038C4.86335 5.84819 4.8898 6.19109 5.04078 6.44699C6.18805 8.03613 7.64669 6.02562 8.81402 5.44175C10.2778 4.63226 11.6413 5.89428 11.9897 7.28168C12.339 8.4183 12.2806 9.70079 12.8802 10.6254C14.4715 12.5716 16.9064 9.33231 18.963 9.42445C21.3701 9.26239 23.3073 11.319 24.0746 13.3589C25.2675 16.0745 24.4494 20.5007 27.4302 21.8096C28.1774 21.964 28.795 21.6808 29.5452 21.2957C30.466 20.8099 31.5016 20.0772 32.9278 19.4792C35.9751 18.1379 39.7701 19.1931 42.1346 21.4237C44.419 23.5528 45.5743 26.4799 46.0912 29.4082L40.4414 30.1678L40.4563 30.1759Z"
            fill="white"
        />
        <Path
            d="M46.4489 35.0767C49.307 33.1911 50.6397 30.1709 49.4257 28.3309C48.2118 26.4908 44.9108 26.5276 42.0528 28.4131C39.1948 30.2986 37.862 33.3188 39.076 35.1589C40.29 36.999 43.5909 36.9622 46.4489 35.0767Z"
            fill="white"
        />
        <Path
            d="M49.8285 40.2067C53.7436 37.6238 55.5647 33.4797 53.8962 30.9505C52.2276 28.4214 47.7012 28.465 43.7861 31.0479C39.8711 33.6308 38.0499 37.7749 39.7184 40.304C41.387 42.8332 45.9134 42.7896 49.8285 40.2067Z"
            fill="#F7DECB"
        />
        <Path
            d="M41.5054 47.0349C38.128 41.9352 41.7728 38.4251 46.8644 35.0627C51.9641 31.6852 56.6125 29.7442 59.975 34.8358C63.3375 39.9274 64.6391 50.9157 59.5476 54.2782C54.456 57.6407 44.8679 52.1265 41.5054 47.0349Z"
            fill="white"
        />
        <Defs>
            <ClipPath id="clip0_1024_101174">
                <Rect
                    width="114"
                    height="126"
                    fill="white"
                    transform="translate(7)"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default Placenta01;
