import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const Week39 = () => {
    const { t } = useTranslation();

    return (
        <VStack space={2}>
            <Text>{t("pregnancy:week39Text1")}</Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Text2")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom1")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom2")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom3")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom4")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom5")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom6")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom7")}
            </Text>
            <Text alignSelf="flex-start" justifyContent="flex-start" pl={4}>
                <Text ml={-4} mr={2}>
                    {"\u2022"}
                </Text>
                {t("pregnancy:week39Symptom8")}
            </Text>
        </VStack>
    );
};

export default Week39;
