import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta06 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Mask
            id="mask0_1024_101047"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101047)">
            <Path
                d="M74 74C78.5 72.8333 88.7 68.5 93.5 60.5C99.5 50.5 92.0155 45.9823 99 38C102.5 34 109 30.5001 112.5 29.5"
                stroke="#DD8F8C"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </G>
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M40.7673 78.9547C41.1101 81.2095 41.8204 83.3908 42.898 85.4004C45.445 90.2286 49.8533 93.9538 55.1188 95.3753C60.0905 96.7233 65.4784 96.1351 70.0337 93.7332C75.7645 96.2576 82.4505 96.1596 88.0589 93.3411C93.7162 90.4982 98.5899 84.8367 98.9083 78.293C98.9817 76.7245 98.7858 75.156 98.2225 73.71C97.7082 72.3375 96.8021 70.7935 95.4061 70.1808C94.1326 69.6416 92.5407 70.1808 92.1978 71.6022C92.0019 72.362 92.0509 73.1953 91.9039 73.9796C91.757 74.7638 91.5121 75.5236 91.1937 76.2588C89.9691 79.1753 87.5691 81.6752 84.5812 82.8025C83.0138 83.3907 81.177 83.6113 79.6096 82.9986C78.0911 82.4104 77.1115 81.0625 76.5972 79.5675C75.3727 75.9892 76.6952 72.1905 76.2543 68.5142C76.0829 67.1908 75.6176 65.8673 74.8829 64.7644C78.7279 60.3284 80.2463 54.1033 79.0708 48.3439C77.9442 42.8295 74.3196 38.0749 69.5684 35.1339C64.4743 31.9723 58.3516 31.0655 52.5228 32.3644C46.5471 33.7124 41.0367 37.1926 37.461 42.2168C34.5221 46.3832 32.9058 51.5545 33.665 56.5787C32.6853 57.706 32.3914 59.4461 32.171 60.8431C31.1179 68.0731 34.5956 75.205 40.7183 78.9547H40.7673Z"
            fill="#FFDCD0"
        />
        <Path
            d="M58.6639 61.8485C63.9784 58.6379 70.9827 60.1084 74.5829 65.1326C74.9502 65.6473 74.191 66.26 73.8237 65.7453C72.2807 63.5886 69.9786 61.9956 67.4071 61.3828C64.5662 60.6966 61.5538 61.2358 59.0557 62.7308C58.5169 63.0494 58.1251 62.1671 58.6639 61.8485Z"
            fill="#DD8F8C"
        />
        <Path
            d="M34.5473 59.5923C34.7432 59.0041 35.6494 59.3472 35.4535 59.9354C33.8616 65.0332 37.2903 69.8613 41.2333 72.7288C42.0415 73.317 43.168 73.8317 43.6579 74.763C44.1722 75.7923 43.7803 77.1403 43.8293 78.2431C43.9517 80.743 44.7599 83.2428 46.1804 85.3015C47.7233 87.5073 49.903 89.2229 52.4255 90.1787C53.0133 90.3993 52.646 91.3061 52.0827 91.0855C47.356 89.2474 43.8783 85.032 43.0456 80.0077C42.8252 78.7333 42.8497 77.4834 42.9231 76.1845C42.9721 75.4737 42.8007 75.0081 42.2374 74.5669C41.7476 74.1748 41.1843 73.8562 40.67 73.4885C38.6373 72.018 36.8004 70.1799 35.5759 67.9742C34.1554 65.4253 33.6901 62.3618 34.5473 59.5678V59.5923Z"
            fill="#DD8F8C"
        />
        <Path
            d="M57.7192 91.9684C59.6295 92.238 61.5642 92.091 63.401 91.5028C63.6459 91.4293 63.9398 91.5028 64.0378 91.7479C64.1358 91.9684 64.0378 92.3116 63.7929 92.3851C61.7847 93.0223 59.6785 93.2184 57.5967 92.9243C56.9845 92.8262 57.0824 91.8704 57.6947 91.9684H57.7192Z"
            fill="#DD8F8C"
        />
        <Path
            d="M85.9977 90.3013C91.4837 89.0759 95.4267 84.2232 95.5491 78.6108C95.5491 77.9736 96.5288 78.0962 96.5043 78.7089C96.3573 84.7624 92.0225 89.9582 86.1447 91.2571C85.5324 91.4042 85.3855 90.4238 85.9977 90.3013Z"
            fill="#DD8F8C"
        />
        <Path
            d="M67.9243 52.7557C65.0343 56.726 72.1612 59.0788 72.9449 54.6428C73.2632 52.094 69.3202 50.4029 67.9243 52.7557Z"
            fill="#5D6982"
        />
    </Svg>
);

export default Placenta06;
