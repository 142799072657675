import type {
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "@madmedical/ui";
import {
    CurrentPatientProvider,
    DocumentListEvaluation,
    DocumentListGeneral,
    DocumentPatient,
    DocumentProvider,
    Spinner,
} from "@madmedical/ui";
import {
    adaptPatient,
    useGetTreatingDoctorsQuery,
    useMe,
    usePatient,
    useRouteNavigate,
} from "@madmedical/user";
import type { Ulid } from "@madmedical/utils";
import { invariant, useDebounce } from "@madmedical/utils";
import type { MetricType } from "@madmedical/medical";
import { getMetricTypeChoices } from "@madmedical/medical";
import { useCallback, useMemo, useState } from "react";
import type { DocumentFilter } from "./model";
import { useGetDocumentsQuery } from "./api";
import DeleteDocumentDialog from "./modals/DeleteDocumentDialog";
import UploadModal from "./modals/UploadModal";
import adaptDocument from "./adaptDocument";

interface Props {
    readonly withOpinion: boolean;
}

enum ModalMode {
    Closed,
    Delete,
    Upload,
}

interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}

const Documents = ({ withOpinion }: Props) => {
    const {
        patientId,
        isSelfOrRepresented,
        patient,
        isLoading: isPatientLoading,
    } = usePatient();
    const navigate = useRouteNavigate();
    const { data: doctors } = useGetTreatingDoctorsQuery(patientId);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<DocumentFilter>({ withOpinion });
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300, () => {
        setPage(1);
    });
    const [modalMode, setModalMode] = useState(ModalMode.Closed);
    const [idForModal, setIdForModal] = useState<Ulid>();
    const { data, isLoading } = useGetDocumentsQuery({
        patientId,
        ...filter,
        withOpinion,
        search: debouncedSearch,
        page,
    });

    const {
        roles: { isDoctor },
    } = useMe();

    const { data: doctorDocumentsData, isLoading: isDoctorDocumentsLoading } =
        useGetDocumentsQuery(
            {
                patientId,
                page: 1,
                pageSize: 100,
            },
            {
                skip: !isDoctor,
            }
        );

    const filterChoices = useMemo(
        () => ({
            metricTypes: getMetricTypeChoices(
                "metricTypes" in filter ? filter.metricTypes : []
            ),
            doctors:
                doctors?.map(({ userId, fullName }) => ({
                    key: userId,
                    text: fullName,
                    isSelected:
                        "doctorIds" in filter &&
                        !!filter.doctorIds?.includes(userId),
                })) ?? [],
        }),
        [filter, doctors]
    );

    const handleFilterChange = useCallback<
        OnFilterChange<typeof filterChoices>
    >((key, selected) => {
        setPage(1);

        // TODO: Why does type inference fail me?
        const diff =
            key === "metricTypes"
                ? { metricTypes: selected as MetricType[] }
                : key === "doctors"
                ? { doctorIds: selected as Ulid[] }
                : {};

        setFilter((prevFilter) => ({
            ...prevFilter,
            ...diff,
        }));
    }, []);

    const handleSearchChange = useCallback<OnSearchChange>((search) => {
        setSearch(search);
    }, []);

    const handleSortChange = useCallback<OnSortChange>((sort, order) => {
        setPage(1);

        if (!sort) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                sort: undefined,
                order: undefined,
            }));

            return;
        }

        setFilter((prevFilter) => ({
            ...prevFilter,
            sort: sort as DocumentFilter["sort"], // TODO: Better types
            order,
        }));
    }, []);

    const handleEndReached = useCallback(() => {
        if (!data) {
            return;
        }

        const { currentPage, pageCount } = data.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [data]);

    if (isLoading || isPatientLoading || isDoctorDocumentsLoading) {
        return <Spinner />;
    }

    invariant(data);
    invariant(patient);

    const { items: documents, stats } = data;

    const handleUploadPress = () => {
        navigate("documents_general", {});
        setModalMode(ModalMode.Upload);
    };

    const handleDeletePress = (id: Ulid) => {
        setIdForModal(id);
        setModalMode(ModalMode.Delete);
    };

    const handleClose = () => {
        setModalMode(ModalMode.Closed);
        setIdForModal(undefined);
        setPage(1);
    };

    // Avoid switching layout components while refetching - trust the data rather than the prop
    const showWithOpinion = data?.items.length
        ? !!data?.items[0].opinion
        : withOpinion;

    const packages = (patient?.packages ?? []) as MyPackages[];

    if (patient && packages.some((item) => item?.type === "basic")) {
        return null;
    }

    return (
        <>
            <DocumentProvider
                value={{
                    documents:
                        isDoctor && doctorDocumentsData
                            ? doctorDocumentsData?.items?.map(adaptDocument)
                            : documents.map(adaptDocument),
                    onSortChange: handleSortChange,
                    onSearchChange: handleSearchChange,
                    filterChoices,
                    onFilterChange: handleFilterChange,
                    stats,
                }}
            >
                <CurrentPatientProvider
                    value={{ patient: adaptPatient(patient) }}
                >
                    {isSelfOrRepresented ? (
                        showWithOpinion ? (
                            <DocumentListEvaluation
                                onUploadPress={handleUploadPress}
                                onEndReached={handleEndReached}
                            />
                        ) : (
                            <DocumentListGeneral
                                onUploadPress={handleUploadPress}
                                onDeletePress={handleDeletePress}
                                onEndReached={handleEndReached}
                            />
                        )
                    ) : (
                        <DocumentPatient
                            onEndReached={handleEndReached}
                            onUploadPress={handleUploadPress}
                        />
                    )}
                </CurrentPatientProvider>
            </DocumentProvider>
            <DeleteDocumentDialog
                id={idForModal}
                open={modalMode === ModalMode.Delete}
                onClose={handleClose}
            />
            <UploadModal
                open={modalMode === ModalMode.Upload}
                onClose={handleClose}
            />
        </>
    );
};

export default Documents;
