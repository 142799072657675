import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type {
    DeviceOwnership,
    LogisticsStatus,
    MetricType,
    Provider,
    ProviderConnectionStatus,
} from "@madmedical/medical";
import type { EnumForHumans, Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type { Pagination } from "@madmedical/store";
import type { FilterItem, OnFilterChange, OnSearchChange } from "./model";

export interface LogisticsDeviceType {
    id: Ulid;
    manufacturer: EnumForHumans<string>;
    name: string;
    metricTypes: MetricType[];
    provider: Provider;
}

export interface LogisticsDevice {
    id: Ulid;
    deviceType: LogisticsDeviceType;
    serialNumber: string;
    status: LogisticsStatus;
    ownership: DeviceOwnership | null;
    connectionStatus: ProviderConnectionStatus;
    warrantyExpiresAt: Date | null;
    patientId: Ulid | null;
    institutionId: Ulid | null;
    deliveredAt: Date | null;
    returnedAt: Date | null;
    note: string | null;
}

export type FilterChoices = {
    deviceTypes: FilterItem<Ulid>[];
    metricTypes: FilterItem<MetricType>[];
    statuses: FilterItem<LogisticsStatus>[];
    connectionStatuses: FilterItem<ProviderConnectionStatus>[];
    ownerships: FilterItem<DeviceOwnership>[];
};

interface ContextValue {
    devices: LogisticsDevice[];
    pagination: Pagination;
    onSearchChange: OnSearchChange;
    filterChoices: FilterChoices;
    onFilterChange: OnFilterChange<FilterChoices>;
}

const CallCenterDeviceContext = createContext<ContextValue | undefined>(
    undefined
);

export const CallCenterDeviceProvider =
    CallCenterDeviceContext.Provider as ReactProvider<ContextValue>;

export const useCallCenterDevices = () =>
    useEnsureContext(CallCenterDeviceContext);
