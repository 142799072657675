import type { ComponentProps, ReactNode } from "react";
import { Modal as BaseModal, Stack } from "native-base";
import { isWeb } from "@madmedical/utils";

interface Props extends Pick<ComponentProps<typeof BaseModal>, "height"> {
    children: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
    shadow?: string;
    borderBottomWidth?: number;
    bodyProps?: ComponentProps<typeof BaseModal.Body>;
}

const ModalContent = ({
    children,
    header,
    footer,
    bodyProps,
    ...rest
}: Props) => (
    <>
        {header && (
            <BaseModal.Header bgColor="white" {...rest}>
                {header}
            </BaseModal.Header>
        )}
        <BaseModal.Body
            bgColor="white"
            _scrollview={{
                _contentContainerStyle: isWeb
                    ? {
                          style: {
                              height: "full",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                          },
                      }
                    : {},
            }}
            {...bodyProps}
        >
            {children}
        </BaseModal.Body>
        {footer && (
            <BaseModal.Footer bgColor="white">
                <Stack direction="row" space={2}>
                    {footer}
                </Stack>
            </BaseModal.Footer>
        )}
    </>
);

export default ModalContent;
