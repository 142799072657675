import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import {
    getRefreshToken,
    invalidateTokens,
    useAuth,
    useDispatch,
    useIntegratorLoginMutation,
    useTokenRefreshMutation,
} from "@madmedical/store";
import type { AppConfig } from "@madmedical/config";
import { useNavigate } from "@madmedical/routing";
import {
    useIsCurrentRouteNha,
    useIsCurrentRouteOpen,
} from "@madmedical/config";
import { setUser as setSentryUser } from "@madmedical/error";

interface Props {
    readonly config: Pick<
        AppConfig,
        "integrator" | "integratorToken" | "integratorAuthLoading"
    >;
    readonly children: ReactNode;
}

const AuthWrapper = ({
    config: { integrator, integratorToken, integratorAuthLoading },
    children,
}: Props) => {
    const [isFetching, setFetching] = useState(false);
    const [tokenRefresh] = useTokenRefreshMutation();
    const [integratorLogin] = useIntegratorLoginMutation();
    const { isLoading, isTokenAvailable, userId } = useAuth();
    const isOpenRoute = useIsCurrentRouteOpen();
    const isNhaRoute = useIsCurrentRouteNha();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = useCallback(() => {
        dispatch(invalidateTokens());
        if (!integrator && !isOpenRoute) {
            navigate("/login");
        }
    }, [dispatch, integrator, isOpenRoute, navigate]);

    // Fetch auth Token when missing from store
    // - If integrator token is gone, log out
    // - When refresh token is in storage, use that
    // - If we are in SDK context, log in via integrator
    // - Redirect to login otherwise
    // Happens at page refresh for example.
    useEffect(() => {
        if (isFetching) {
            return;
        }

        if (integrator && integratorAuthLoading) {
            return;
        }

        if (integrator && !integratorToken) {
            // Log out if integrator token is gone for good
            logout();

            return;
        }

        if (isTokenAvailable) {
            // Already have an access token
            return;
        }

        const refreshOrLogin = async () => {
            const refreshToken = await getRefreshToken();

            if (refreshToken) {
                // Just refresh
                try {
                    await tokenRefresh(refreshToken).unwrap();

                    return;
                } catch {
                    console.error("Token refresh failed. Moving on.");
                }
            }

            if (integrator && integratorToken) {
                // Fresh login via integrator
                await integratorLogin({
                    integrator,
                    token: integratorToken,
                }).unwrap();

                return;
            }

            // Back to base
            logout();
        };

        try {
            setFetching(true);
            void refreshOrLogin();
        } catch {
            logout();
        } finally {
            setFetching(false);
        }
    }, [
        isFetching,
        isTokenAvailable,
        integrator,
        integratorToken,
        integratorAuthLoading,
        logout,
        tokenRefresh,
        integratorLogin,
    ]);

    useEffect(() => {
        setSentryUser(
            userId
                ? {
                      id: userId,
                      ip_address: "{{auto}}",
                  }
                : null
        );
    }, [userId]);

    if (isLoading && isNhaRoute && !isOpenRoute) {
        return null;
    }

    return <>{children}</>;
};

export default AuthWrapper;
