import type { AppConfig } from "./model";
import getEnv from "./getEnv";

type Config = Pick<
    AppConfig,
    "integrator" | "isProduction" | "testingHostnameBackend"
>;

export default ({
    integrator,
    isProduction,
    testingHostnameBackend,
}: Config) => {
    switch (true) {
        case !integrator:
            // For standalone mode, we rely on injected fake env vars
            return getEnv("HOSTNAME_BACKEND");
        case isProduction:
            return "backend.nexthealth.app";
        case !!testingHostnameBackend:
            // Allow overriding for integrator
            return testingHostnameBackend;
        default:
            // Staging otherwise
            return "backend.nexthealthapp.com";
    }
};
