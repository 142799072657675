import { rangeToDate } from "@madmedical/utils";
import type { Prediction } from "./model";
import adaptTrend from "./adaptTrend";

export default ({ dateRange, extendedRange, trends, ...rest }: Prediction) => ({
    dateRange: rangeToDate(dateRange),
    extendedRange: rangeToDate(extendedRange),
    trends: trends.map(adaptTrend),
    ...rest,
});
