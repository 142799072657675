// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-var-requires */
import { useState } from "react";
import { Box, Image, Text } from "native-base";
import type { InterfaceImageProps } from "native-base/lib/typescript/components/primitives/Image/types";
import type { DateTimeString, Ulid } from "@madmedical/utils";
import {
    currentPregnancyWeek,
    differenceInDays,
    formatDate,
    formatDateTime,
} from "@madmedical/utils";
import type { Pregnancy } from "@madmedical/medical";
import { PregnancyStatus } from "@madmedical/medical";
import { ApiFormWrapper } from "@madmedical/form";
import type { MutationActionCreatorResult } from "@madmedical/store";
import { useTranslation } from "@madmedical/i18n";
import { getInfantsNames } from "../../util/getInfantsNames";
import BabyWeight from "../../pics/Pregnancy/birth/BabyWeight";
import BabyBirth from "../../pics/Pregnancy/birth/BabyBirth";
import BabyLength from "../../pics/Pregnancy/birth/BabyLength";
import BabyCap from "../../pics/Pregnancy/birth/BabyCap";
import BirthBlockText from "../../molecules/Pregnancy/BirthBlockText";
import Paper from "../../atoms/Paper";
import ActionButton from "../../molecules/ActionButton";
import PregnancyDasboardBlock from "../../molecules/Pregnancy/PregnancyDasboardBlock";
import useResponsive from "../../util/useResponsive";
import PregnancyPlacentaImage from "../../molecules/Pregnancy/PregnancyPlacentaImage";
import PregnancyDashDatas from "../../molecules/Pregnancy/PregnancyDashDatas";
import PregnancyPieGraph from "../../molecules/Pregnancy/PregnancyPieGraph";
import PregnancyDataImage from "../../molecules/Pregnancy/PregnancyDataImage";
import PregnancyBodyImage from "../../molecules/Pregnancy/PregnancyBodyImage";
import PregnancySoonborn from "../../molecules/Pregnancy/PregnancySoonborn";
import IconButton from "../../atoms/IconButton";
import PregnancyDashboardAlert from "../../molecules/Pregnancy/PregnancyDashboardAlert";
import PregnancyWidgetsWrapper from "../../charts/PregnancyWidgetsWrapper";
import Modal from "../../molecules/Modal";
import BirthAbortionModal from "../../organisms/Patient/BirthAbortionModal";
import BirthCongratulationsModal from "../../organisms/Patient/BirthCongratulationsModal";
import useShowToast from "../../atoms/useShowToast";
import { getBabyStats } from "../../util/getBabyStats";

enum ModalState {
    Closed,
    Born,
    Miscarried,
}
interface MiscarriedData {
    miscarriedAt: string;
    removePregnancyTracking: boolean | string;
    miscarriedAmount: number;
}

type BornInfant = {
    id?: Ulid;
    bornAt?: string | undefined;
    givenName: string;
    length: number;
    weight: number;
    headCircumference: number;
};
interface Props {
    isPrepaid?: boolean;
    patientId: Ulid;
    pregnancyData: Pregnancy;
    goToLink: (link: string) => void;
    createEmpathy: (arg: {
        patientId: Ulid;
        miscarriedAt: string;
        removePregnancyTracking: boolean;
        miscarriedAmount: number;
    }) => MutationActionCreatorResult<unknown>;
    createCongratulation: (arg: {
        patientId: Ulid;
        bornAt: string | DateTimeString;
        infants: BornInfant[];
    }) => MutationActionCreatorResult<unknown>;
    deletePregnancy: (
        pregnancyId: Ulid
    ) => MutationActionCreatorResult<unknown>;
}

const PregnancyDashboard = ({
    isPrepaid = true,
    patientId,
    pregnancyData,
    goToLink,
    createEmpathy,
    createCongratulation,
    deletePregnancy,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const showToast = useShowToast();
    const { t } = useTranslation();

    const [modalState, setModalState] = useState<ModalState>(ModalState.Closed);

    const {
        bornAt,
        infants,
        expectedAt,
        status,
        miscarriedAt,
        miscarriedAmount,
        id: pregnancyId,
        startedAt,
    } = pregnancyData;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const bgImage = isSmallScreen
        ? require("../../pics/Pregnancy/img/dashboard_background_mobile.png")
        : require("../../pics/Pregnancy/img/dashboard_background.png");

    const pregnancyEndDay = 280;
    const pregnancyEndWeek = 40;

    const now = new Date();
    // now.setDate(now.getDate() - 3);

    const pregnancyDay = differenceInDays(new Date(startedAt), now);
    const pregnancyWeek = Math.floor(pregnancyDay / 7);
    const pregnancyWeekDay = Math.abs(pregnancyWeek * 7 - pregnancyDay);
    const pregnancyCurrentWeek = currentPregnancyWeek(new Date(startedAt), now);
    const percentage = (pregnancyDay / pregnancyEndDay) * 100;
    const timeRemaining = {
        weeks: pregnancyEndWeek - pregnancyCurrentWeek,
        days: pregnancyWeekDay === 0 ? 0 : 7 - pregnancyWeekDay,
    };

    const closeModal = () => {
        setModalState(ModalState.Closed);
    };

    const sendCongratulation = async (formData: BornInfant) => {
        const bornAtDate = formData.bornAt
            ? new Date(formData.bornAt)
            : undefined;
        if (!bornAtDate) {
            showToast(t("fillInDateOfBirth") + "!", { variant: "error" });

            return;
        }
        try {
            const data = await createCongratulation({
                patientId,
                bornAt: bornAtDate.toISOString(),
                infants: [
                    {
                        id: formData.id,
                        givenName: formData.givenName,
                        length: formData.length,
                        weight: formData.weight,
                        headCircumference: formData.headCircumference,
                    },
                ],
            });
            if (data) {
                closeModal();
            }
        } catch (e) {
            showToast(t("saveUnsuccessful."), { variant: "error" });
        }
    };

    const sendMiscarried = async (formData: MiscarriedData) => {
        try {
            const data = await createEmpathy({
                patientId,
                miscarriedAt: formData.miscarriedAt,
                removePregnancyTracking:
                    formData.removePregnancyTracking &&
                    formData.removePregnancyTracking === "yes"
                        ? true
                        : false,
                miscarriedAmount: formData.miscarriedAmount,
            });
            if (data) {
                closeModal();
            }
        } catch (e) {
            showToast(t("saveUnsuccessful."), { variant: "error" });
        }
    };

    const closePregnancy = async () => {
        try {
            const data = await deletePregnancy(pregnancyId);
            if (data) {
                closeModal();
                showToast(t("deleteSuccessfull"), { variant: "success" });
            }
        } catch (e) {
            showToast(t("saveUnsuccessful."), { variant: "error" });
        }
    };

    if (!isPrepaid) {
        return <PregnancyDashboardAlert />;
    }

    return (
        <>
            <Box width="full" my={2} minHeight={250}>
                <Paper
                    backgroundColor="#D0ECE5"
                    // p={4}
                    // height="full"
                    position="relative"
                    overflow="hidden"
                >
                    <Image
                        source={bgImage as InterfaceImageProps["source"]}
                        width="full"
                        height="full"
                        alt="Pregnancy dashboard background"
                        position={"absolute"}
                    />

                    <Box
                        flexDir={"row"}
                        mb={2}
                        justifyContent="space-between"
                        width="full"
                        p={4}
                    >
                        {bornAt ? (
                            <Box px={2} flex={1} justifyContent={"center"}>
                                <Text
                                    fontSize="md"
                                    fontWeight="medium"
                                    color="#0E6369"
                                >
                                    {infants.length > 1
                                        ? `${t(
                                              `pregnancy:${getInfantsNames(
                                                  infants
                                              )}`
                                          )} ${t("pregnancy:births")}`
                                        : `${t(
                                              `pregnancy:${getInfantsNames(
                                                  infants
                                              )}`
                                          )} ${t("pregnancy:birth")}`}
                                </Text>
                            </Box>
                        ) : (
                            <Box px={2} flex={1}>
                                <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="#0E6369"
                                >
                                    {t(`pregnancy:${getInfantsNames(infants)}`)}{" "}
                                    {pregnancyWeek}{" "}
                                    {t("pregnancy:dateOfConception", {
                                        weekDay: pregnancyWeekDay.toString(),
                                    })}
                                </Text>
                                <Text color="#0E6369" opacity={0.75}>
                                    {t("pregnancy:estimatedDueDate")}{" "}
                                    {formatDate(new Date(expectedAt))}
                                </Text>
                            </Box>
                        )}
                        {bornAt ? (
                            <IconButton
                                variant="ghostLink"
                                iconName="close"
                                size="md"
                                onPress={() =>
                                    closePregnancy() as unknown as void
                                }
                            />
                        ) : (
                            <ActionButton
                                iconName="overflowMenuHorizontal"
                                size="lg"
                                maxWidth={240}
                                actions={[
                                    {
                                        text: t("editData"),
                                        iconName: "edit",
                                        onPress: () => goToLink("onboarding"),
                                    },
                                    {
                                        text: t("pregnancy:pregnancyState"),
                                        iconName: "heart",
                                        submenu: [
                                            {
                                                key: "1",
                                                text: t(
                                                    "pregnancy:iAmPregnant"
                                                ),
                                                iconName: "checkmark",
                                                iconColor:
                                                    status ===
                                                    PregnancyStatus.NotBorn
                                                        ? "#1FBA9C"
                                                        : "transparent",
                                                disabled:
                                                    status ===
                                                    PregnancyStatus.NotBorn,
                                            },
                                            {
                                                key: "2",
                                                text: t(
                                                    "pregnancy:myBabyIsBorn"
                                                ),
                                                iconName: "checkmark",
                                                iconColor:
                                                    status ===
                                                    PregnancyStatus.Born
                                                        ? "#1FBA9C"
                                                        : "transparent",
                                                disabled:
                                                    status ===
                                                    PregnancyStatus.Born,
                                                onPress: () =>
                                                    setModalState(
                                                        ModalState.Born
                                                    ),
                                            },
                                            {
                                                key: "3",
                                                text: t(
                                                    "pregnancy:iHaveAproblem"
                                                ),
                                                iconName: "checkmark",
                                                iconColor: "transparent",
                                                subSubmenu: [
                                                    {
                                                        key: "31",
                                                        text: t(
                                                            "pregnancy:iMiscarried"
                                                        ),
                                                        onPress: () =>
                                                            setModalState(
                                                                ModalState.Miscarried
                                                            ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ]}
                            />
                        )}
                    </Box>
                    <Box px={4} pb={4} width={"100%"}>
                        {pregnancyCurrentWeek >= pregnancyEndWeek - 1 &&
                            !bornAt && (
                                <PregnancySoonborn
                                    infants={infants}
                                    week={pregnancyCurrentWeek}
                                    openBirthModal={() =>
                                        setModalState(ModalState.Born)
                                    }
                                />
                            )}
                        {bornAt ? (
                            <PregnancyWidgetsWrapper>
                                <PregnancyDasboardBlock
                                    image={<BabyBirth />}
                                    text={
                                        <BirthBlockText
                                            label={t("pregnancy:timeOfBirth")}
                                            value={formatDateTime(
                                                new Date(bornAt)
                                            )}
                                        />
                                    }
                                />
                                <PregnancyDasboardBlock
                                    image={<BabyWeight />}
                                    text={
                                        <BirthBlockText
                                            label={t("pregnancy:babyWeight")}
                                            value={infants[0].weight ?? "-"}
                                            measure="g"
                                        />
                                    }
                                />
                                <PregnancyDasboardBlock
                                    image={<BabyLength />}
                                    text={
                                        <BirthBlockText
                                            label={t("pregnancy:babyLength")}
                                            value={infants[0].length ?? "-"}
                                            measure="cm"
                                        />
                                    }
                                />
                                <PregnancyDasboardBlock
                                    image={<BabyCap />}
                                    text={
                                        <BirthBlockText
                                            label={t(
                                                "pregnancy:headCircumference"
                                            )}
                                            value={
                                                infants[0].headCircumference ??
                                                "-"
                                            }
                                            measure="cm"
                                        />
                                    }
                                />
                            </PregnancyWidgetsWrapper>
                        ) : (
                            <PregnancyWidgetsWrapper>
                                <PregnancyDasboardBlock
                                    image={
                                        <PregnancyDataImage
                                            week={pregnancyCurrentWeek}
                                        />
                                    }
                                    text={
                                        <PregnancyDashDatas
                                            weight={
                                                getBabyStats(
                                                    pregnancyCurrentWeek
                                                ).weight
                                            }
                                            height={
                                                getBabyStats(
                                                    pregnancyCurrentWeek
                                                ).height
                                            }
                                        />
                                    }
                                    link="pregnancy_baby"
                                />
                                <PregnancyDasboardBlock
                                    image={
                                        <PregnancyPieGraph
                                            percentage={percentage}
                                            weekNr={pregnancyCurrentWeek}
                                            trimesterNr={Math.ceil(
                                                percentage / 33
                                            )}
                                        />
                                    }
                                    text={t("pregnancy:timeTillDelivery", {
                                        weeks: timeRemaining.weeks,
                                        days: timeRemaining.days,
                                    })}
                                    link="pregnancy_body"
                                />
                                <PregnancyDasboardBlock
                                    image={
                                        <PregnancyPlacentaImage
                                            week={Math.ceil(percentage / 6)}
                                        />
                                    }
                                    text={
                                        <Text fontWeight="medium">
                                            {t("pregnancy:placentaGrowth")}
                                        </Text>
                                    }
                                    link="pregnancy_baby"
                                />
                                <PregnancyDasboardBlock
                                    image={
                                        <PregnancyBodyImage
                                            week={Math.ceil(percentage / 12)}
                                        />
                                    }
                                    text={
                                        <Text fontWeight="medium">
                                            {t("pregnancy:bodyChanges")}
                                        </Text>
                                    }
                                    link="pregnancy_body"
                                />
                            </PregnancyWidgetsWrapper>
                        )}
                    </Box>
                </Paper>
            </Box>
            {modalState === ModalState.Miscarried && (
                <Modal
                    open={modalState === ModalState.Miscarried}
                    size="sm"
                    onClose={closeModal}
                    hasCloseButton={false}
                >
                    <ApiFormWrapper
                        defaultValues={{
                            miscarriedAt:
                                miscarriedAt ?? new Date().toISOString(),
                            removePregnancyTracking: "yes",
                            miscarriedAmount: miscarriedAmount ?? 1,
                        }}
                        mutation={(data) =>
                            sendMiscarried(
                                data
                            ) as unknown as MutationActionCreatorResult<unknown>
                        }
                        resetOnSuccess
                    >
                        {({ submitForm, isSuccess }) => (
                            <BirthAbortionModal
                                isSuccess={isSuccess}
                                onClose={closeModal}
                                onSubmitPress={submitForm}
                            />
                        )}
                    </ApiFormWrapper>
                </Modal>
            )}
            {modalState === ModalState.Born && (
                <Modal
                    open={modalState === ModalState.Born}
                    size="sm"
                    onClose={closeModal}
                >
                    <ApiFormWrapper
                        defaultValues={{
                            id: infants[0].id,
                            givenName: infants[0].givenName ?? "",
                            bornAt: bornAt ?? new Date().toISOString(),
                            length: infants[0].length ?? 0,
                            weight: infants[0].weight ?? 0,
                            headCircumference:
                                infants[0].headCircumference ?? 0,
                        }}
                        mutation={(data) =>
                            sendCongratulation(
                                data
                            ) as unknown as MutationActionCreatorResult<unknown>
                        }
                        resetOnSuccess
                    >
                        {({ submitForm, isSuccess }) => (
                            <BirthCongratulationsModal
                                isSuccess={isSuccess}
                                onClose={closeModal}
                                onSubmitPress={submitForm}
                            />
                        )}
                    </ApiFormWrapper>
                </Modal>
            )}
        </>
    );
};

export default PregnancyDashboard;
