import { Dimensions } from "react-native";

const sizes = {
    xs: {
        contentSize: {
            width: "80%",
            maxWidth: "400",
        },
    },
    sm: {
        contentSize: {
            width: "60%",
            maxWidth: "640",
        },
    },
    md: {
        contentSize: {
            width: "75%",
            maxWidth: "1024",
        },
    },
    full: {
        contentSize: {
            width: "full",
        },
    },
};

const defaultProps = {
    size: "md",
    closeOnOverlayClick: true,
};

export const Modal = {
    baseStyle: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        _web: {
            pointerEvents: "box-none",
        },
        _backdropFade: {
            exitDuration: 150,
            entryDuration: 200,
        },
        _slide: {
            overlay: false,
            duration: 200,
        },
        _fade: {
            exitDuration: 100,
            entryDuration: 200,
        },
    },
    sizes,
    defaultProps,
};

export const ModalContent = {
    baseStyle: () => ({
        shadow: 1,
        rounded: "sm",
        maxHeight: `${Dimensions.get("window").height - 150}px`,
        overflow: "hidden",
        bg: "muted.50",
        _text: {
            color: "text.900",
        },
    }),
};

export const ModalCloseButton = {
    baseStyle: () => ({
        position: "absolute",
        right: 5,
        top: 5,
        zIndex: 1,
        p: 2,
        bg: "transparent",
        _web: {
            outlineWidth: 0,
            cursor: "pointer",
        },
        _icon: {
            color: "gray.800",
            size: 3,
        },
        _hover: {
            bg: "none",
            bgColor: "none",
        },
        _pressed: {
            bg: "none",
            bgColor: "none",
        },
    }),
};

export const ModalHeader = {
    baseStyle: () => ({
        p: 6,
        borderBottomWidth: "1",
        _text: {
            color: "text.900",
            fontSize: "lg",
            fontWeight: "medium",
            lineHeight: "sm",
        },

        bg: "muted.50",
        borderColor: "muted.300",
    }),
};

export const ModalBody = {
    baseStyle: () => ({
        p: 6,

        _text: {
            color: "text.900",
        },
    }),
};

export const ModalFooter = {
    baseStyle: () => ({
        p: 6,
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        borderTopWidth: 1,

        bg: "white",
        borderColor: "primary.500",
    }),
};

export const ModalOverlay = {
    baseStyle: {
        opacity: 80,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
};
