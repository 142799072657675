import AsyncStorage from "@react-native-async-storage/async-storage";

const PREFIX = "nha_";

export type Jsonable =
    | string
    | number
    | boolean
    | null
    | { [property: string]: Jsonable }
    | Record<string, unknown>
    | Jsonable[];

const addPrefix = (key: string) => `${PREFIX}${key}`;

export const setStorageValue = async <T extends Jsonable>(
    key: string,
    value: T
) => {
    if (value === null) {
        await removeStorageValue(key);
    } else {
        await AsyncStorage.setItem(addPrefix(key), JSON.stringify(value));
    }
};

export const getStorageValue = async <T extends Jsonable>(
    key: string
): Promise<T | null> => {
    const jsonValue = await AsyncStorage.getItem(addPrefix(key));

    return jsonValue != null ? (JSON.parse(jsonValue) as T) : null;
};

export const removeStorageValue = async (key: string) => {
    await AsyncStorage.removeItem(addPrefix(key));
};
