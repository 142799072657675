import type { ReactNode } from "react";
import { StoreProvider } from "@madmedical/store";
import { AuthWrapper } from "@madmedical/auth";
import { UserWrapper } from "@madmedical/user";
import { AppleProvider, BleProvider } from "@madmedical/device";
import type { AppConfig } from "@madmedical/config";
import { AppConfigProvider } from "@madmedical/config";
import { UiProvider } from "@madmedical/ui";
import { useEffect, useMemo } from "react";
import { initI18n, useTranslation } from "@madmedical/i18n";

interface Props {
    readonly appConfig: AppConfig;
    readonly children: ReactNode;
}

void initI18n();

/**
 * Popover in Dropdown component doesn't work with react <StrictMode>
 * TODO: put back StrictMode when react-native-web supports it
 */
const Root = ({ appConfig, children }: Props) => {
    const memoisedConfig = useMemo(() => appConfig, [appConfig]);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (appConfig.language) {
            void i18n.changeLanguage(appConfig.language);
        }
    }, [appConfig.language, i18n]);

    return (
        <AppConfigProvider appConfig={memoisedConfig}>
            <StoreProvider config={memoisedConfig}>
                <AuthWrapper config={memoisedConfig}>
                    <UserWrapper>
                        <UiProvider>
                            <BleProvider>
                                <AppleProvider>{children}</AppleProvider>
                            </BleProvider>
                        </UiProvider>
                    </UserWrapper>
                </AuthWrapper>
            </StoreProvider>
        </AppConfigProvider>
    );
};

export default Root;
