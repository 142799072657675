import { useCallback, useEffect, useMemo } from "react";
import { Box, HStack, ScrollView, VStack } from "native-base";
import { currentPregnancyWeek, invariant } from "@madmedical/utils";
import type { PregnancyWellBeing } from "@madmedical/medical";
import {
    PREGNANCY_SYMPTOMS_FOR_HUMANS,
    PregnancySymptom,
    PregnancyWellBeingStatus,
    WELL_BEING_STATUS_FOR_HUMANS,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import ListHeader from "../../molecules/ListHeader";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import PregnancyPageTabs from "../../organisms/Pregnancy/PregnancyPageTabs";
import Button from "../../atoms/Button";
import PregnancyFeelingTable from "../../organisms/Pregnancy/PregnancyFeelingTable";
import useResponsive from "../../util/useResponsive";
import { ModalState, usePregnancy } from "../../providers/pregnancy";
import FilterButton from "../../molecules/FilterButton";
import PregnancyMoodListMobile from "./PregnancyMoodListMobile.native";

interface Props {
    isDoctor?: boolean;
}

type HeaderType = {
    title: string;
    badge: number;
};

type SectionType = {
    header: HeaderType;
    data: PregnancyWellBeing[];
};

type SectionGroupType = Record<string, PregnancyWellBeing[]>;

interface Item {
    key: string;
    text: string;
    isSelected: boolean;
}

type DesktopLayoutProps = {
    sections: SectionType[];
    isDoctor?: boolean;
};

const DesktopLayout = ({ sections, isDoctor = false }: DesktopLayoutProps) => {
    const lastIndex = sections.length - 1;

    return (
        <>
            {sections.map((section, index) => (
                <Box
                    width="full"
                    py={4}
                    key={`feeling-table-section-${index + lastIndex}`}
                >
                    <ListHeader
                        title={section.header.title}
                        badge={section.header.badge}
                        variant="inverted"
                        size="md"
                        px={0}
                        pt={0}
                        pb={0}
                    />
                    <PregnancyFeelingTable
                        data={section.data ?? []}
                        isDoctor={isDoctor}
                    />
                </Box>
            ))}
        </>
    );
};

const PregnancyFeelingPageContent = ({ isDoctor = false }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    const symptomChoices = Object.values(PregnancySymptom).map((value) => ({
        key: value,
        text: t(`pregnancy:${PREGNANCY_SYMPTOMS_FOR_HUMANS[value]}`),
        isSelected: false,
    }));

    const feelingChoicesTransLated = Object.values(
        PregnancyWellBeingStatus
    ).map((value) => ({
        key: value,
        text: t(`prengnany:${WELL_BEING_STATUS_FOR_HUMANS[value]}`),
        isSelected: false,
    }));

    const {
        setModal,
        pregnancyWellBeing,
        adjustWellBeingFilters,
        patient,
        pregnancyData,
    } = usePregnancy();

    const sections = useMemo(() => {
        const pregnancyStart = pregnancyData?.startedAt
            ? new Date(pregnancyData?.startedAt)
            : new Date();
        const groups = pregnancyWellBeing
            .map((item) => ({
                ...item,
                editable: true,
            }))
            .reduce<SectionGroupType>((prev, measurement) => {
                const difference = currentPregnancyWeek(
                    pregnancyStart,
                    new Date(measurement.createdAt)
                );
                if (!prev[difference]) {
                    prev[difference] = [];
                }
                prev[difference].push(measurement);

                return prev;
            }, {});

        return Object.keys(groups)
            .sort((a, b) => parseInt(b) - parseInt(a))
            .map<SectionType>((week) => ({
                header: {
                    title: `${week}. hét`,
                    badge: groups[week].length,
                },
                data: groups[week],
            }));
    }, [pregnancyWellBeing, pregnancyData]);

    const AddEntryButton = useCallback(
        () => (
            <Button
                leftIconName="add"
                variant="outlined"
                size="sm"
                onPress={() => setModal(ModalState.Feeling)}
                width={isSmallScreen ? "full" : "auto"}
                mb={isSmallScreen ? 2 : 0}
                forceIcon
            >
                {t("newTextInput")}
            </Button>
        ),
        [isSmallScreen, setModal, t]
    );

    const handleStatusFilterChange = (selected: Item["key"][]) => {
        adjustWellBeingFilters({
            status: selected[0] as PregnancyWellBeingStatus,
        });
        feelingChoicesTransLated.forEach((choice) => {
            if (choice.key === selected[0]) {
                choice.isSelected = true;
            } else {
                choice.isSelected = false;
            }
        });
    };

    const handleSymptomsFilterChange = (selected: Item["key"][]) => {
        adjustWellBeingFilters({
            symptoms: [...selected] as PregnancySymptom[],
        });
        symptomChoices.forEach((choice) => {
            if ([...selected].includes(choice.key)) {
                choice.isSelected = true;
            } else {
                choice.isSelected = false;
            }
        });
    };

    const handleResetPress = () => {
        adjustWellBeingFilters({ status: undefined, symptoms: undefined });
        feelingChoicesTransLated.forEach((choice) => {
            choice.isSelected = false;
        });
        symptomChoices.forEach((choice) => {
            choice.isSelected = false;
        });
    };

    invariant(pregnancyWellBeing);

    // cleanup filters on mount and  unmount
    useEffect(() => {
        adjustWellBeingFilters({ status: undefined, symptoms: undefined });

        return () => {
            adjustWellBeingFilters({ status: undefined, symptoms: undefined });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ScrollView>
            {isDoctor ? (
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="dashboard" params={{}}>
                                    {t("health")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            {patient && (
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patient"
                                        params={{ patientId: patient.userId }}
                                    >
                                        {patient.fullName}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                            )}
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("pregnancy:pregnancy2")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
            ) : (
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="dashboard" params={{}}>
                                    {t("health")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("pregnancy:pregnancy2")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
            )}
            <VStack space={4} bg={isSmallScreen ? "transparent" : "white"}>
                <PregnancyPageTabs
                    addEntryButton={!isDoctor ? <AddEntryButton /> : undefined}
                    isDoctor={isDoctor}
                />
                <Box p={isSmallScreen ? 0 : 4}>
                    {!isSmallScreen && (
                        <HStack space={4} mb={4}>
                            <FilterButton
                                title={t("feeling")}
                                items={feelingChoicesTransLated}
                                onChange={handleStatusFilterChange}
                            />
                            <FilterButton
                                title={t("symptoms")}
                                items={symptomChoices}
                                onChange={handleSymptomsFilterChange}
                                isMultiselect={true}
                                isSearchable={true}
                            />

                            {feelingChoicesTransLated.some(
                                (item) => item.isSelected
                            ) ||
                            symptomChoices.some((item) => item.isSelected) ? (
                                <Button
                                    onPress={handleResetPress}
                                    size="xs"
                                    variant="link"
                                >
                                    {t("setback")}
                                </Button>
                            ) : null}
                        </HStack>
                    )}
                    {isSmallScreen ? (
                        <PregnancyMoodListMobile
                            sections={sections}
                            isDoctor={isDoctor}
                        />
                    ) : (
                        <DesktopLayout
                            sections={sections}
                            isDoctor={isDoctor}
                        />
                    )}
                </Box>
            </VStack>
        </ScrollView>
    );
};

export default PregnancyFeelingPageContent;
