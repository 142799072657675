import { useLocation } from "@madmedical/routing";
import useAppConfig from "./useAppConfig";

// TODO: Use route ids, incorporate basePath from integrator
const OPEN_ROUTES = ["/login", "/oauth-result"];

export default () => {
    const { basePath } = useAppConfig();
    const { pathname } = useLocation();

    return OPEN_ROUTES.map((path) => `${basePath}${path}`).includes(pathname);
};
