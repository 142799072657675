import { useTranslation } from "@madmedical/i18n";
import { usePregnancyData } from "../../providers";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";

const PregnancyDoctorEditBreadcrumbs = () => {
    const { patient } = usePregnancyData();
    const { t } = useTranslation();

    return (
        <Headline
            minHeight={12}
            borderBottomWidth={1}
            borderBottomColor="gray.100"
            borderBottomStyle="solid"
            mb={4}
            leftContent={
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Breadcrumb.Link route="dashboard" params={{}}>
                            {t("health")}
                        </Breadcrumb.Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Breadcrumb.Link
                            route="patient"
                            params={{ patientId: patient.userId }}
                        >
                            {patient.fullName}
                        </Breadcrumb.Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <Breadcrumb.Text>
                            {t("pregnancy:editPregnancy")}
                        </Breadcrumb.Text>
                    </Breadcrumb.Item>
                </Breadcrumb>
            }
        />
    );
};

export default PregnancyDoctorEditBreadcrumbs;
