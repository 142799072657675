import { ScrollView } from "native-base";
import type { PregnancyWellBeing } from "@madmedical/medical";
import Card from "../atoms/Card/Card";
import MoodListItem from "./ListItem/MoodListItem";

interface Props {
    status?: PregnancyWellBeing[];
}

const MoodListCard = ({ status }: Props) =>
    status?.length ? (
        <Card flex={0} px={0} py={0} width={92} shadow={8}>
            <ScrollView
                maxHeight={80}
                nestedScrollEnabled
                width={"100%"}
                zIndex={1000}
            >
                {status.map((item, index) => (
                    <MoodListItem
                        statusItem={item}
                        key={`${index}-${item.id}`}
                    />
                ))}
            </ScrollView>
        </Card>
    ) : (
        <></>
    );

export default MoodListCard;
