export enum UnderlyingCondition {
    HighBloodPressure = "hypertension", // Magas vérnyomás
    HeartCondition = "heart", // Szívbetegség, mellkasi fájdalom
    Diabetes = "diabetes", // Cukorbetegség
    LungsCondition = "lungs", // Asztma, tüdőbetegség
    Arrhythmia = "arrhythmia", // Szívritmuszavar
    BloodThinner = "bloodthinner", // Használ vérhígítót
    Insulin = "insulin", // Inszulin
    Pregnancy = "pregnancy", // Terhesség
}

const FOR_HUMANS = {
    [UnderlyingCondition.HighBloodPressure]: "highBloodPressure", // Magas vérnyomás
    [UnderlyingCondition.HeartCondition]: "heartDisease", //"Szívbetegség, mellkasi fájdalom",
    [UnderlyingCondition.Diabetes]: "diabetes", //"Cukorbetegség",
    [UnderlyingCondition.LungsCondition]: "asthma", //"Asztma, tüdőbetegség",
    [UnderlyingCondition.Arrhythmia]: "arrhythmia", //"Szívritmuszavar",

    [UnderlyingCondition.Insulin]: "insulin2", // "Használsz inzulint",
    [UnderlyingCondition.BloodThinner]: "useBloodThinner", //Használsz vérhígítót",
    [UnderlyingCondition.Pregnancy]: "pregnant",
};

const FOR_HUMANS_DOCTOR = {
    [UnderlyingCondition.HighBloodPressure]: "highBloodPressure",
    [UnderlyingCondition.HeartCondition]: "heartDisease",
    [UnderlyingCondition.Diabetes]: "diabetes",
    [UnderlyingCondition.LungsCondition]: "asthma",
    [UnderlyingCondition.Arrhythmia]: "arrhythmia",

    [UnderlyingCondition.Insulin]: "insulin2",
    [UnderlyingCondition.BloodThinner]: "useBloodThinner",
    [UnderlyingCondition.Pregnancy]: "pregnant",
};

export const isIllness = (underlying: UnderlyingCondition) =>
    underlying !== UnderlyingCondition.BloodThinner &&
    underlying !== UnderlyingCondition.Insulin &&
    underlying !== UnderlyingCondition.Pregnancy;

export const isNotIllness = (underlying: UnderlyingCondition) =>
    underlying === UnderlyingCondition.BloodThinner;

export const getUnderlyingConditionChoices = (
    selected: UnderlyingCondition[] | undefined,
    isDoctor = false
) =>
    Object.values(UnderlyingCondition)
        .map((underlying) => ({
            key: underlying,
            text: isDoctor
                ? FOR_HUMANS_DOCTOR[underlying]
                : FOR_HUMANS[underlying],
            isSelected: !!selected?.includes(underlying),
        }))
        .sort(({ text: a }, { text: b }) => a.localeCompare(b));

export const underlyingForHumans = (condition: UnderlyingCondition) =>
    FOR_HUMANS[condition];

export const underlyingForHumansDoctor = (condition: UnderlyingCondition) =>
    FOR_HUMANS_DOCTOR[condition];
