import type { ArrayElement } from "@madmedical/utils";

export enum MetricType {
    BloodOxygen = "spo2",
    BloodPressure = "bp",
    BloodSugar = "glucose",
    BodyHeight = "height",
    BodyMassIndex = "bmi",
    BodyTemperature = "temperature",
    BodyWeight = "weight",
    DailySteps = "steps",
    FoetalHeartRate = "foetal_hr",
    FoetalHeartRateVariability = "foetal_hrv",
    HeartRateVariability = "hrv",
    RestingHeartRate = "resting_hr",
    SleepTime = "sleep",
    UterineActivity = "uterine",
    Arrhythmia = "arrhythmia",
    AbdominalCircumference = "abd_circum",
}

interface MetricBase {
    type: MetricType;
    displayValue: string;
}

export interface BloodOxygen extends MetricBase {
    type: MetricType.BloodOxygen;
    spO2: number;
}

export interface BloodPressure extends MetricBase {
    type: MetricType.BloodPressure;
    diastolic: number;
    systolic: number;
}

export enum MealSituation {
    Fasting = "fasting",
    BeforeMeal = "before_meal",
    AfterMeal = "after_meal",
    Random = "random",
}

export interface BloodSugar extends MetricBase {
    type: MetricType.BloodSugar;
    mmolPerL: number;
    mealSituation: MealSituation;
}

export interface BodyHeight extends MetricBase {
    type: MetricType.BodyHeight;
    cm: number;
}

export interface BodyMassIndex extends MetricBase {
    type: MetricType.BodyMassIndex;
    bmi: number;
}

export interface BodyTemperature extends MetricBase {
    type: MetricType.BodyTemperature;
    celsius: number;
}

export interface BodyWeight extends MetricBase {
    type: MetricType.BodyWeight;
    kg: number;
}

export interface DailySteps extends MetricBase {
    type: MetricType.DailySteps;
    steps: number;
}

export interface FoetalHeartRate extends MetricBase {
    type: MetricType.FoetalHeartRate;
    bpm: number;
}

export interface FoetalHeartRateVariability extends MetricBase {
    type: MetricType.FoetalHeartRateVariability;
    ms: number;
}

export interface HeartRateVariability extends MetricBase {
    type: MetricType.HeartRateVariability;
    ms: number;
}

export interface RestingHeartRate extends MetricBase {
    type: MetricType.RestingHeartRate;
    bpm: number;
}

export interface SleepTime extends MetricBase {
    type: MetricType.SleepTime;
    totalHours: number;
}

export interface UterineActivity extends MetricBase {
    type: MetricType.UterineActivity;
}

interface Arrhythmia extends MetricBase {
    type: MetricType.Arrhythmia;
}

interface AbdominalCircumference extends MetricBase {
    type: MetricType.AbdominalCircumference;
    circumference: number;
}

export type Metric =
    | BloodOxygen
    | BloodPressure
    | BloodSugar
    | BodyHeight
    | BodyMassIndex
    | BodyTemperature
    | BodyWeight
    | DailySteps
    | FoetalHeartRate
    | FoetalHeartRateVariability
    | HeartRateVariability
    | RestingHeartRate
    | SleepTime
    | UterineActivity
    | Arrhythmia
    | AbdominalCircumference;

export const metricTypesManualAllowed = [
    MetricType.BodyWeight,
    MetricType.BodyHeight,
    MetricType.BodyTemperature,
    MetricType.BloodPressure,
    MetricType.BloodSugar,
    MetricType.BloodOxygen,
    MetricType.AbdominalCircumference,
] as const;

export type MetricTypeManualAllowed = ArrayElement<
    typeof metricTypesManualAllowed
>;

export const isManualAllowed = (
    metricType: MetricType
): metricType is MetricTypeManualAllowed =>
    metricTypesManualAllowed.includes(metricType as MetricTypeManualAllowed); // This is ugly TODO: Try without casting

export type MetricManualAllowed = Metric & { type: MetricTypeManualAllowed };

export const isDaily = (metricType: MetricType) =>
    [MetricType.DailySteps, MetricType.SleepTime].includes(metricType);

export enum AlertLevel {
    Unevaluated = 0,
    Ok = 1,
    Warning = 2,
    Alert = 3,
}
