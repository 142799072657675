import type { ComponentProps } from "react";
import type { FormControl as BaseFormControl } from "native-base";
import { TextArea } from "native-base";
import FormControlWrapper from "./FormControlWrapper";

type TextAreaProps = ComponentProps<typeof TextArea>;
type FormControlProps = ComponentProps<typeof BaseFormControl>;

interface Props
    extends Omit<TextAreaProps, "autoCompleteType">,
        Pick<FormControlProps, "isInvalid" | "isRequired"> {
    error?: string;
    hint?: string;
    label?: string;
    placeholder?: string;
    labelProps?: ComponentProps<typeof BaseFormControl.Label>;
    textareaHeight?: number;
}

const FormTextAreaControl = ({
    isInvalid = false,
    isRequired = false,
    error,
    hint,
    label,
    placeholder,
    labelProps,
    textareaHeight = 154,
    ...rest
}: Props) => (
    <FormControlWrapper
        isInvalid={isInvalid}
        isRequired={isRequired}
        error={error}
        hint={hint}
        label={label}
        labelProps={labelProps}
    >
        <TextArea
            autoCompleteType="off"
            h={textareaHeight}
            placeholder={placeholder}
            isInvalid={isInvalid}
            isRequired={isRequired}
            {...rest}
        />
    </FormControlWrapper>
);

export default FormTextAreaControl;
