import useResponsive from "../util/useResponsive";
import AddCommentButtonDesktop from "./AddCommentButtonDesktop";
import AddCommentButtonMobile from "./AddCommentButtonMobile";

interface Props {
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const AddCommentButton = (props: Props) => {
    const { isSmallScreen } = useResponsive();

    return isSmallScreen ? (
        <AddCommentButtonMobile {...props} />
    ) : (
        <AddCommentButtonDesktop {...props} />
    );
};

export default AddCommentButton;
