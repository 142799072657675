import {
    Box,
    FormControl,
    HStack,
    Popover,
    Pressable,
    Text,
    VStack,
    useTheme,
} from "native-base";
import type { ComponentProps } from "react";
import { useEffect, useState } from "react";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../icons/Icon";

interface Item {
    key: string;
    text: string;
}

interface Props extends ComponentProps<typeof Pressable> {
    list: Item[];
    values?: string[];
    onChange: (values: string[]) => void;
    size?: "sm" | "md";
    isRequired?: boolean;
    isInvalid?: boolean;
    error?: string;
    hint?: string;
    label?: string;
    allSelectedLabel?: string;
}

const MultiCheckboxSelect = ({
    list,
    onChange,
    values = [],
    isRequired = false,
    isInvalid = false,
    error,
    hint,
    label,
    size = "sm",
    allSelectedLabel,
    ...rest
}: Props) => {
    const [selected, setSelected] = useState<string[]>(
        values.map((item) => item.toString())
    );
    const { colors } = useTheme();
    const { t } = useTranslation();
    useEffect(() => {
        setSelected(values.map((item) => item.toString()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onChange(selected);
    }, [selected, onChange]);

    const getHeight = () => {
        switch (size) {
            case "sm":
                return "8";
            case "md":
                return "11";
        }
    };

    const handleSelectPress = (key: string) => () => {
        setSelected((ps) => [...ps, key]);
    };

    const handleRemovePress = (key: string) => () => {
        setSelected((previous) => previous.filter((s) => s !== key));
    };

    const toggleAll = () => {
        if (selected.length === list.length) {
            setSelected([]);
        } else {
            setSelected(list.map((l) => l.key));
        }
    };

    return (
        <FormControl flex={1} isInvalid={isInvalid} isRequired={isRequired}>
            {label && (
                <FormControl.Label
                    testID="base-form-control-label"
                    fontWeight="medium"
                    _text={{
                        color: "gray.800",
                    }}
                >
                    {label}
                </FormControl.Label>
            )}
            <Popover
                useRNModal
                placement="bottom left"
                trigger={(triggerProps) => (
                    <Pressable
                        {...triggerProps}
                        _focus={{ borderColor: "primary.500" }}
                        _hover={{ borderColor: "primary.500" }}
                        borderWidth="1"
                        borderColor="gray.200"
                        minHeight={getHeight()}
                        px="3"
                        py="2"
                        pt={1}
                        height="35"
                        width="64"
                        borderRadius="md"
                        bgColor="white"
                        alignItems="center"
                        {...rest}
                    >
                        <HStack width="full">
                            <HStack flex="1" flexWrap="nowrap">
                                <Text noOfLines={1} isTruncated lineHeight={24}>
                                    {selected.length === list.length ? (
                                        <Text color={colors.gray[800]}>
                                            {allSelectedLabel ?? t("selectAll")}
                                        </Text>
                                    ) : (
                                        list
                                            .filter(({ key }) =>
                                                selected.includes(key)
                                            )
                                            .map(({ key, text }, index) => (
                                                <Text
                                                    key={key}
                                                    color={colors.gray[800]}
                                                >
                                                    {text}
                                                    {index !==
                                                    list.filter(({ key }) =>
                                                        selected.includes(key)
                                                    ).length -
                                                        1
                                                        ? ", "
                                                        : ""}
                                                </Text>
                                            ))
                                    )}
                                </Text>
                            </HStack>
                            <Box marginRight="-4px" marginTop="4px">
                                <Icon
                                    name="chevronDown"
                                    size={16}
                                    fill={colors.gray[800]}
                                />
                            </Box>
                        </HStack>
                    </Pressable>
                )}
            >
                <Popover.Content
                    background="white"
                    borderWidth="0"
                    shadow="2"
                    overflowY="auto"
                    p="0.5"
                    width="64"
                    flex={1}
                >
                    {isWeb ? (
                        <VStack>
                            <Pressable
                                onPress={toggleAll}
                                _hover={{
                                    backgroundColor: "blueGray.50",
                                }}
                            >
                                <Box
                                    px="4"
                                    py="3"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <Checkbox
                                        value="all"
                                        isChecked={
                                            selected.length === list.length
                                        }
                                        // onChange={toggleAll}
                                    >
                                        <Text lineHeight="1.1rem">
                                            {allSelectedLabel ?? t("selectAll")}
                                        </Text>
                                    </Checkbox>
                                </Box>
                            </Pressable>
                            {list.map(({ key, text }) => (
                                <Pressable
                                    key={key}
                                    onPress={
                                        selected.includes(key)
                                            ? handleRemovePress(key)
                                            : handleSelectPress(key)
                                    }
                                    _hover={{
                                        backgroundColor: "blueGray.50",
                                    }}
                                >
                                    <Box
                                        px="4"
                                        py="3"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        key={key}
                                    >
                                        <Checkbox
                                            value={text}
                                            isChecked={selected.includes(key)}
                                            /*
                                            onChange={
                                                selected.includes(key)
                                                    ? handleRemovePress(key)
                                                    : handleSelectPress(key)
                                            }
                                            */
                                        >
                                            <Text lineHeight="1.1rem">
                                                {text}
                                            </Text>
                                        </Checkbox>
                                    </Box>
                                </Pressable>
                            ))}
                        </VStack>
                    ) : (
                        <VStack>
                            <Box
                                px="4"
                                py="3"
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Checkbox
                                    value="all"
                                    isChecked={selected.length === list.length}
                                    onChange={toggleAll}
                                >
                                    <Text lineHeight="1.1rem">
                                        {allSelectedLabel ?? t("selectAll")}
                                    </Text>
                                </Checkbox>
                            </Box>
                            {list.map(({ key, text }) => (
                                <Box
                                    px="4"
                                    py="3"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    key={key}
                                >
                                    <Checkbox
                                        value={text}
                                        isChecked={selected.includes(key)}
                                        onChange={
                                            selected.includes(key)
                                                ? handleRemovePress(key)
                                                : handleSelectPress(key)
                                        }
                                    >
                                        <Text lineHeight="1.1rem">{text}</Text>
                                    </Checkbox>
                                </Box>
                            ))}
                        </VStack>
                    )}
                </Popover.Content>
            </Popover>
            {hint && !error && (
                <Text mt={1} fontSize="sm" color="gray.600">
                    {hint}
                </Text>
            )}
            {error && (
                <FormControl.ErrorMessage mt={1} _text={{ fontSize: "sm" }}>
                    {error}
                </FormControl.ErrorMessage>
            )}
        </FormControl>
    );
};

export default MultiCheckboxSelect;
