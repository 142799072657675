import type { ReactNode } from "react";
import { Box, Center, Stack } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../../providers/currentPatient";
import ButtonLink from "../../atoms/ButtonLink";
import useResponsive from "../../util/useResponsive";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import Breadcrumb from "../../molecules/Breadcrumb";
import AvatarTexts from "../../molecules/AvatarTexts";
import PredictionsTable from "../../organisms/PredictionsTable";
import ThresholdsProtocolsTable from "../../organisms/ThresholdsProtocolsTable";
import HealthEvaluationTable from "../../organisms/HealthEvaluationTable";
import DocumentsTableGeneral from "../../organisms/DocumentsTableGeneral";
import PatientDetailsSection from "../../organisms/Patient/PatientDetailsSection";
import DeviceList from "../../organisms/DeviceList";
import type { PatientDoctor } from "../../providers/patient";
import Button from "../../atoms/Button";
import Charts from "./Charts";
import Devices from "./Devices";
import PreOpAlert from "./PreOpAlert";

interface Props {
    charts: ReactNode;
    onEditPress: () => unknown;
    onEditThresholdPress: (metricType: MetricType) => void;
    insulinList?: ReactNode;
    pregnancyList?: ReactNode;
    handleUploadPress: () => void;
}

const Patient = ({
    charts,
    onEditPress,
    onEditThresholdPress,
    insulinList,
    pregnancyList,
    handleUploadPress,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient<PatientDoctor>();
    const { t } = useTranslation();

    return (
        <>
            <Paper width="full" mb={3}>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {patient.fullName}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />

                <Headline
                    size="lg"
                    leftContent={
                        <AvatarTexts
                            source={patient.avatarUrl}
                            hasAvatar={!!patient.avatarUrl}
                            label={patient.fullName}
                            subText={patient.socialSecurityNumber}
                            size="3xl"
                            initials={patient.initials}
                        />
                    }
                    borderBottomRadius="lg"
                />
            </Paper>

            <Box
                px={{
                    base: 4,
                    md: 0,
                }}
                width="100%"
            >
                <PreOpAlert />

                {pregnancyList && <Box mb={3}>{pregnancyList}</Box>}
                <Stack
                    space={{
                        base: 4,
                        md: 2,
                    }}
                    direction={{
                        base: "column",
                        md: "row",
                    }}
                >
                    <PatientDetailsSection
                        onEditPress={onEditPress}
                        isDoctor={true}
                    />
                </Stack>

                <Charts charts={charts} />
            </Box>

            <Box
                mb={3}
                px={{
                    base: 4,
                    md: 0,
                }}
            >
                <ThresholdsProtocolsTable
                    title={t("measures:measureSetup")}
                    onEditPress={onEditThresholdPress}
                />
            </Box>
            {insulinList && (
                <Box
                    mb={3}
                    px={{
                        base: 4,
                        md: 0,
                    }}
                >
                    {insulinList}
                </Box>
            )}
            <Box
                mb={3}
                px={{
                    base: 4,
                    md: 0,
                }}
            >
                <Paper>
                    <Headline title={t("results")} size="xs" />
                    <Box px={isSmallScreen ? 0 : 6} py={4} width="full">
                        <HealthEvaluationTable />
                        <Center my={4} width="full">
                            <ButtonLink
                                size="sm"
                                variant="outlined"
                                route="opinions"
                                params={{}}
                            >
                                {t("viewAll")}
                            </ButtonLink>
                        </Center>
                    </Box>
                </Paper>
            </Box>
            <Box
                mb={3}
                px={{
                    base: 4,
                    md: 0,
                }}
            >
                <Paper>
                    <Headline
                        title={t("documents")}
                        size="xs"
                        rightContent={
                            <Button
                                variant="outlined"
                                leftIconName="documentAdd"
                                forceIcon
                                onPress={handleUploadPress}
                            >
                                {t("newUpload")}
                            </Button>
                        }
                    />
                    <Box px={isSmallScreen ? 0 : 6} py={4} width="full">
                        <DocumentsTableGeneral showOnlyGeneral={false} />
                        <Center my={4} width="full">
                            <ButtonLink
                                size="sm"
                                variant="outlined"
                                route="documents_opinion"
                                params={{}}
                            >
                                {t("viewAll")}
                            </ButtonLink>
                        </Center>
                    </Box>
                </Paper>
            </Box>

            <Box
                mb={3}
                px={{
                    base: 4,
                    md: 0,
                }}
            >
                <Paper mb={4}>
                    <Headline title={t("predictions")} size="xs" />
                    <Box px={isSmallScreen ? 0 : 6} py={4} width="full">
                        <PredictionsTable />
                        <Center my={4} width="full">
                            <ButtonLink
                                size="sm"
                                variant="outlined"
                                route="predictions"
                                params={{}}
                            >
                                {t("viewAll")}
                            </ButtonLink>
                        </Center>
                    </Box>
                </Paper>

                {isSmallScreen ? (
                    <Box borderRadius="lg" overflow="hidden">
                        <DeviceList />
                    </Box>
                ) : (
                    <Devices />
                )}
            </Box>
        </>
    );
};

export default Patient;
