import { Box, HStack, useTheme } from "native-base";
import type { ComponentProps, ReactNode } from "react";
import Icon from "../icons/Icon";

interface Props {
    icon: ComponentProps<typeof Icon>["name"];
    children: ReactNode;
}

const IconListItem = ({ icon, children }: Props) => {
    const { colors } = useTheme();

    return (
        <HStack space={5} alignItems="flex-start" w="full">
            <Icon name={icon} size={24} fill={colors.green[500]} />
            <Box flex={1}>{children}</Box>
        </HStack>
    );
};

export default IconListItem;
