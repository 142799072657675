import { Box, HStack, Stack, Text } from "native-base";
import { formatDateRange, isWeb } from "@madmedical/utils";
import type { ReactNode } from "react";
import { useTranslation } from "@madmedical/i18n";
import { Syringe } from "../../../icons/custom/Syringe";
import Button from "../../../atoms/Button";
import Paper from "../../../atoms/Paper";
import useResponsive from "../../../util/useResponsive";
import StatusBadge from "../../../atoms/StatusBadge";
import DateRangePicker from "../../../organisms/DateRangePicker";
import Metrics from "../../../molecules/Metrics";
import { useChart } from "../../../providers/chart";

interface MainInsulinChartProps {
    addCommentButton: ReactNode;
    onAddInsulinAdministration: () => void;
    onAddInsulinReminder: () => void;
    isPatient?: boolean;
}

const MainInsulinChart = ({
    addCommentButton,
    onAddInsulinAdministration,
    onAddInsulinReminder,
    isPatient = true,
}: MainInsulinChartProps) => {
    const { isSmallScreen } = useResponsive();
    const { chart, displayRange, alertLevel, dateRange } = useChart();
    const { t } = useTranslation();

    console.log("CHART", chart);
    console.log("displayRange", displayRange);

    return (
        <Paper borderTopLeftRadius={0} borderTopRightRadius={0}>
            <Box p={6} width="full">
                <Stack
                    alignItems="flex-start"
                    direction={isSmallScreen ? "column-reverse" : "row"}
                    space={6}
                >
                    <Box flex="1">
                        <Text color="gray.600">
                            {formatDateRange(dateRange)}
                        </Text>
                        <HStack alignItems="center">
                            {displayRange && (
                                <Metrics displayValue={[displayRange]} />
                            )}

                            {alertLevel ? (
                                <Box mt={2}>
                                    <StatusBadge size="xs" level={alertLevel} />
                                </Box>
                            ) : null}
                        </HStack>
                    </Box>

                    <Box>
                        <DateRangePicker periods={["day", "week"]} />
                    </Box>
                </Stack>
            </Box>
            <Box width="100%">{chart}</Box>
            <Stack
                direction={isSmallScreen ? "column" : "row"}
                p={isSmallScreen ? 4 : 6}
                space={2}
                justifyContent="space-between"
                position="relative"
                width={"full"}
            >
                <HStack
                    alignItems="center"
                    space={2}
                    direction={isSmallScreen ? "column" : "row"}
                >
                    {isPatient && (
                        <Button
                            onPress={onAddInsulinAdministration}
                            variant="outlined"
                            forceIcon
                            width={isSmallScreen ? "full" : "auto"}
                            {...(isWeb ? {} : { leftIconName: "taskAdd" })}
                        >
                            {isWeb ? (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    space={2}
                                >
                                    <Syringe width={20} height={20} />
                                    <Text>
                                        {t("measures:addInsulinInjection")}
                                    </Text>
                                </Stack>
                            ) : (
                                <Text>{t("measures:addInsulinInjection")}</Text>
                            )}
                        </Button>
                    )}
                    {addCommentButton}
                </HStack>
                {isPatient ? (
                    <Button
                        leftIconName="alarm"
                        variant="outlined"
                        onPress={onAddInsulinReminder}
                        forceIcon
                    >
                        <Text>
                            {t("measures:injectionReminder")} {t("setup")}
                        </Text>
                    </Button>
                ) : (
                    <Button
                        leftIconName="download"
                        variant="outlined"
                        onPress={() => console.log("adatok letöltése")}
                    >
                        {t("downloadData")}
                    </Button>
                )}
            </Stack>
        </Paper>
    );
};

export default MainInsulinChart;
