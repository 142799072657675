import { Box } from "native-base";
import { useState } from "react";
import { useFormContext } from "@madmedical/form";
import Checkbox from "../../../atoms/Checkbox";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Statement = ({ onPreviousPress, onNextPress }: Props) => {
    const [isChecked, setChecked] = useState(false);
    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            isSubmitting={isSubmitting}
            isNextDisabled={!isChecked}
        >
            <Box
                flex={1}
                maxWidth={{ base: "full", md: "1/2" }}
                mr={10}
                p={{
                    base: 4,
                    md: 6,
                }}
            >
                <Checkbox
                    value="statement"
                    isChecked={isChecked}
                    onChange={setChecked}
                >
                    Kijelentem, hogy fenti adatok hitelesek, legjobb tudásom
                    szerint válaszoltam a kérdésekre, nem hallgattam el
                    egészségi állapotomat érintő betegséget, állapotot,
                    fogyatékosságot.
                </Checkbox>
            </Box>
        </PreOpWrapper>
    );
};

export default Statement;
