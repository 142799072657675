import type { ComponentProps, ReactNode } from "react";
import { forwardRef, useCallback, useMemo } from "react";
import {
    Input as BaseInput,
    InputGroup as BaseInputGroup,
    Box,
    HStack,
    InputLeftAddon,
    InputRightAddon,
    Text,
    useTheme,
} from "native-base";
import type { TextInput } from "react-native";
import { isWeb } from "@madmedical/utils";
import Icon from "../../icons/Icon";
import Tooltip from "../../atoms/Tooltip";

type IconName = ComponentProps<typeof Icon>["name"];

interface Props extends Omit<ComponentProps<typeof BaseInput>, "onChange"> {
    size?: "sm" | "md";
    leftAddon?: ReactNode;
    rightAddon?: ReactNode;
    suffix?: ReactNode;
    tooltip?: string;
    leftIconName?: IconName;
    rightIconName?: IconName;
    fullSize?: boolean;
    onChange?: (value: string) => void;
}

const Input = forwardRef<TextInput, Props>(
    (
        {
            size = "sm",
            leftAddon,
            rightAddon,
            suffix,
            tooltip,
            leftIconName,
            fullSize,
            InputLeftElement,
            InputRightElement,
            onChange,
            ...rest
        },
        ref
    ) => {
        const { colors } = useTheme();

        const iconSizes = useMemo(
            () => ({
                sm: {
                    px: 2,
                },
                md: {
                    px: 3.5,
                },
            }),
            []
        );

        const renderIcon = useCallback(
            (iconName: IconName) => (
                <Box pl={iconSizes[size].px}>
                    <Icon name={iconName} size={14} fill={colors.gray[600]} />
                </Box>
            ),
            [colors, iconSizes, size]
        );

        const renderInputRightElement = useCallback(() => {
            let elements = [];

            if (suffix) {
                const s = <Text color="gray.600">{suffix}</Text>;
                elements.push(s);
            }

            if (tooltip) {
                const t = (
                    <Tooltip label={tooltip}>
                        {renderIcon("information")}
                    </Tooltip>
                );
                elements.push(t);
            }

            if (InputRightElement && !Array.isArray(InputRightElement)) {
                elements.push(InputRightElement);
            } else if (InputRightElement) {
                elements = [...elements, ...InputRightElement];
            }

            if (elements.length > 0) {
                return elements;
            }

            return [];
        }, [tooltip, suffix, renderIcon, InputRightElement]);

        const css = `
            input[type="number"][data-testid="input"]::-webkit-outer-spin-button,
            input[type="number"][data-testid="input"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type="number"][data-testid="input"] {
                -moz-appearance: textfield;
            }
            input[type="time"]::-webkit-calendar-picker-indicator{
                padding-right: 7px;
              }
        `;

        return (
            <BaseInputGroup
                testID="nativebase-input-group"
                maxWidth="full"
                width={fullSize ? "full" : "auto"}
            >
                {isWeb && <style>{css}</style>}
                {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
                <BaseInput
                    testID="input"
                    ref={ref ?? undefined}
                    _input={{
                        pr: InputRightElement ? 0 : undefined,
                    }}
                    InputLeftElement={
                        InputLeftElement ??
                        (leftIconName && renderIcon(leftIconName))
                    }
                    InputRightElement={
                        <HStack>
                            {renderInputRightElement().map((e, i) => (
                                <Box key={i}>{e}</Box>
                            ))}
                        </HStack>
                    }
                    size={size}
                    height={size === "sm" ? 33 : 46}
                    maxWidth="full"
                    width="auto"
                    flex={1}
                    onChangeText={onChange}
                    overflowY="hidden"
                    {...rest}
                />
                {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
            </BaseInputGroup>
        );
    }
);

Input.displayName = "Input";

export default Input;
