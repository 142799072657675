import { Avatar as BaseAvatar } from "native-base";

interface Props {
    hasBorder?: boolean;
    status?: "active" | "inactive";
}

const StatusIndicator = ({ status, hasBorder = true }: Props) => (
    <BaseAvatar.Badge
        bg={`${status === "active" ? "green" : "rose"}.500`}
        borderWidth={hasBorder ? 1 : 0}
        size={2}
        top={0}
        zIndex={1}
    />
);

export default StatusIndicator;
