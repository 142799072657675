import type { ReactReduxContextValue } from "react-redux";
import {
    createDispatchHook,
    createSelectorHook,
    createStoreHook,
} from "react-redux";
import { createContext } from "react";
import {
    buildCreateApi,
    coreModule,
    reactHooksModule,
} from "@reduxjs/toolkit/query/react";
import type { createApi as baseCreateApi } from "@reduxjs/toolkit/query/react";
import type { AppDispatch, RootState } from "./store";

type ContextValue = ReactReduxContextValue<RootState>;
export const storeContext = createContext<ContextValue>(
    null as unknown as ContextValue // This is just to satisfy TS, can never be null during runtime
);

export const useDispatch = createDispatchHook(
    storeContext
) as () => AppDispatch;
export const useSelector = createSelectorHook(storeContext);
const useStore = createStoreHook(storeContext);

// Not sure why type casting is needed
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
export const createApi = buildCreateApi(
    coreModule(),
    reactHooksModule({ useDispatch, useSelector, useStore })
) as typeof baseCreateApi;
