import { Representatives as RepresentativesUi, Spinner } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { invariant } from "@madmedical/utils";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useMe } from "@madmedical/user";
import { useGetInvitesQuery, useGetRepresentativesQuery } from "./api";
import InviteModal from "./modals/InviteModal";
import EditInviteModal from "./modals/EditInviteModal";
import EditRepresentativeModal from "./modals/EditRepresentativeModal";
import DeleteInviteDialog from "./modals/DeleteInviteDialog";
import DeleteRepresentativeDialog from "./modals/DeleteRepresentativeDialog";
import ReInviteDialog from "./modals/ReInviteDialog";

type RepresentativeItem = ComponentProps<typeof RepresentativesUi>["data"][0];

enum ModalMode {
    Closed,
    Invite,
    EditInvite,
    EditRepresentative,
    DeleteInvite,
    DeleteRepresentative,
    ReInvite,
}

interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}

const Representatives = () => {
    const { patient } = useMe();
    const { data: representatives, isLoading: isRepresentativesLoading } =
        useGetRepresentativesQuery();
    const { data: invites, isLoading: isInvitesLoading } = useGetInvitesQuery();
    const [modalMode, setModalMode] = useState(ModalMode.Closed);
    const [idForModal, setIdForModal] = useState<Ulid>();

    if (isRepresentativesLoading || isInvitesLoading) {
        return <Spinner />;
    }

    const handleInvitePress = () => {
        setModalMode(ModalMode.Invite);
    };

    const handleEditPress = ({ id, status }: RepresentativeItem) => {
        setIdForModal(id);
        setModalMode(
            status ? ModalMode.EditRepresentative : ModalMode.EditInvite
        );
    };

    const handleReInvitePress = ({ id, status }: RepresentativeItem) => {
        invariant(!status);

        setIdForModal(id);
        setModalMode(ModalMode.ReInvite);
    };

    const handleDeletePress = ({ id, status }: RepresentativeItem) => {
        setIdForModal(id);
        setModalMode(
            status ? ModalMode.DeleteRepresentative : ModalMode.DeleteInvite
        );
    };

    const handleModalClose = () => {
        setModalMode(ModalMode.Closed);
        setIdForModal(undefined);
    };

    invariant(representatives);
    invariant(invites);
    invariant(patient);

    const data = representatives
        .map(({ userId, fullName, note }) => ({
            id: userId,
            nameOrEmail: fullName,
            note,
            status: true,
        }))
        .concat(
            invites.map(({ id, email, note }) => ({
                id,
                nameOrEmail: email,
                note,
                status: false,
            }))
        );

    const packages = (patient?.packages ?? []) as MyPackages[];

    if (patient && packages.some((item) => item?.type === "basic")) {
        return null;
    }

    return (
        <>
            <RepresentativesUi
                data={data}
                maxRepresentatives={patient.maxRepresentativeCount}
                onInvitePress={handleInvitePress}
                onResendPress={handleReInvitePress}
                onEditPress={handleEditPress}
                onDeletePress={handleDeletePress}
            />
            <InviteModal
                open={modalMode === ModalMode.Invite}
                onClose={handleModalClose}
            />
            <EditInviteModal
                id={idForModal}
                open={modalMode === ModalMode.EditInvite}
                onClose={handleModalClose}
            />
            <EditRepresentativeModal
                userId={idForModal}
                open={modalMode === ModalMode.EditRepresentative}
                onClose={handleModalClose}
            />
            <DeleteInviteDialog
                id={idForModal}
                open={modalMode === ModalMode.DeleteInvite}
                onClose={handleModalClose}
            />
            <DeleteRepresentativeDialog
                userId={idForModal}
                open={modalMode === ModalMode.DeleteRepresentative}
                onClose={handleModalClose}
            />
            <ReInviteDialog
                id={idForModal}
                open={modalMode === ModalMode.ReInvite}
                onClose={handleModalClose}
            />
        </>
    );
};

export default Representatives;
