import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Pressable, Text, useTheme } from "native-base";
import { Circle, G, Path, Rect, Svg, Text as SvgText } from "react-native-svg";
import type { GestureResponderEvent } from "react-native";
import type { InsulinType } from "@madmedical/medical";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../util/useResponsive";
import { useChart } from "../providers/chart";
import { dayList, dayListFull } from "../util/calendar";
import type { InsulinTooltipType } from "./types";
import InsulinTooltip from "./InsulinTooltip";

type PointData = {
    y: number;
    value: number;
    status: "OK" | "DIFFERENCE" | "ALERT";
    insertions: {
        [InsulinType.Rapid]?: number;
        [InsulinType.Basal]?: number;
        [InsulinType.Other]?: number;
    };
    administeredAt: Date;
};

type Props = {
    pointData: PointData[];
};

const GraphDivision = ({
    width,
    isSolo = false,
    isLast = false,
}: {
    width?: number | string;
    isSolo?: boolean;
    isLast?: boolean;
}) => (
    <Box
        borderTopWidth={2}
        borderColor="gray.100"
        borderLeftWidth={isSolo ? 0 : 2}
        borderLeftStyle="dashed"
        borderRightWidth={isLast ? 2 : 0}
        borderRightStyle="dashed"
        width={width}
        height="256px"
    >
        <Box borderBottomWidth={1} borderBottomColor="gray.100" height="25%" />
        <Box borderBottomWidth={1} borderBottomColor="gray.100" height="25%" />
        <Box borderBottomWidth={1} borderBottomColor="gray.100" height="25%" />
        <Box borderBottomWidth={2} borderBottomColor="gray.100" height="25%" />
    </Box>
);

const InsulinPortioningGraph = ({ pointData }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { breakpoints } = useTheme();
    const { t } = useTranslation();
    const { dateRange } = useChart();

    const getWeeksInMonthFromDate = useMemo((): number => {
        const date = dateRange.from;
        const dateTo = dateRange.to;

        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = Math.abs(
            dateTo.getTime() - date.getTime()
        );
        const differenceInDays = differenceInMilliseconds / millisecondsPerDay;
        if (differenceInDays < 7) {
            return 1;
        }
        const month = date.getMonth();
        const year = date.getFullYear();

        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);

        const firstWeekday = firstDayOfMonth.getDay() || 7;
        const totalDaysInMonth = lastDayOfMonth.getDate();

        return Math.ceil((totalDaysInMonth + firstWeekday - 1) / 7);
    }, [dateRange.from, dateRange.to]);

    const weekCount = getWeeksInMonthFromDate;

    const graphWidth = isWeb
        ? (document.getElementById("portioning-graph")?.clientWidth ?? 1000) -
          70
        : 320;
    const basicBlock = graphWidth / 7;
    const divideWidth = graphWidth / (weekCount * 7);

    const offsetRatio = Math.round(divideWidth / 1.6);
    const offsetX = graphWidth < breakpoints.md ? 90 : offsetRatio;

    // correct tot the BE type?
    const daysOfWeekDesktop: string[] = dayListFull;

    const daysOfWeekMobile: string[] = dayList;
    const weekNumbers: string[] = [
        "1.",
        "5.",
        "10.",
        "15.",
        "20.",
        "25.",
        "30.",
    ];

    /*
    const daysDividing: string[] = Array(weekCount)
        .fill([...daysOfWeekMobile])
        .flat() as string[];
    */

    const calculateCoordinate = useCallback((value: number) => value + 110, []);

    // insert the calculated coordinates into the path
    const pathCoordinates = `M0,${calculateCoordinate(
        pointData[0].y
    )} ${pointData
        .map((item, index) =>
            index > 0
                ? `L${divideWidth * index + offsetX},${calculateCoordinate(
                      item.y
                  )}`
                : `L${offsetX},${calculateCoordinate(pointData[0].y)}`
        )
        .join(" ")}
        L${
            divideWidth * pointData.length + offsetX + graphWidth / 7
        },${calculateCoordinate(pointData[pointData.length - 1].y)}`;

    /*
    L${offsetX},${calculateCoordinate(pointData[0].y)} L${
        divideWidth + offsetX
    },${calculateCoordinate(pointData[1].y)} L${
        divideWidth * 2 + offsetX
    },${calculateCoordinate(pointData[2].y)} L${
        divideWidth * 3 + offsetX
    },${calculateCoordinate(pointData[3].y)} L${
        divideWidth * 4 + offsetX
    },${calculateCoordinate(pointData[4].y)} L${
        divideWidth * 5 + offsetX
    },${calculateCoordinate(pointData[5].y)} L${
        divideWidth * 6 + offsetX
    },${calculateCoordinate(pointData[6].y)} L${
        divideWidth * 7 + offsetX
    },${calculateCoordinate(pointData[6].y)}`;
    */

    const pathStroke = "#8D39B5";

    const maxValue = Math.max(
        ...pointData.map((item) => (item.value ? Math.ceil(item.value) : 0))
    );

    const maxInsertions = Math.max(
        ...pointData.map((item) => {
            const { insertions } = item;

            return Object.values(insertions).reduce(
                (acc, curr) => acc + curr,
                0
            );
        })
    );
    const svgSpaceHeight = 230;
    const barWidth = 28 / weekCount;
    const proportionalValue = svgSpaceHeight / maxInsertions;

    const showInsertGraph = useCallback(
        (insulinProduct: PointData) => {
            const { insertions } = insulinProduct;

            const numberOfInsertionTypes = Object.values(insertions).filter(
                (item) => item > 0
            ).length;

            const calculatedValues = Object.entries(insertions).reduce(
                (
                    acc: {
                        shapes: {
                            height: number;
                            y: number;
                            type: InsulinType;
                        }[];
                        yCoord: number;
                    },
                    curr
                ) => {
                    const height = Math.ceil(proportionalValue * curr[1]);
                    const shape = {
                        height,
                        y: Math.ceil(acc.yCoord - height),
                        type: curr[0] as InsulinType,
                    };
                    acc.shapes.push(shape);
                    acc.yCoord -= height;

                    return acc;
                },
                {
                    shapes: [],
                    yCoord: svgSpaceHeight,
                }
            );

            return (
                <>
                    {calculatedValues.shapes.reverse().map((item, index) => {
                        if (item?.type === "other") {
                            return (
                                <Fragment key={`other-${index}`}>
                                    <Rect
                                        width={barWidth}
                                        height={item.height}
                                        fill="#A9B5BB"
                                        y={item.y}
                                    />
                                    {item.height > 0 ? (
                                        <Rect
                                            width={barWidth}
                                            rx={5}
                                            ry={5}
                                            height={10}
                                            fill="#A9B5BB"
                                            y={item.y - 5}
                                        />
                                    ) : null}
                                </Fragment>
                            );
                        }
                        if (item.type === "basal") {
                            return (
                                <Fragment key={`basal-${index}`}>
                                    <Rect
                                        width={barWidth}
                                        height={item.height}
                                        fill="#8DBBFA"
                                        y={item.y}
                                    />
                                    {numberOfInsertionTypes === 1 &&
                                    item.height ? (
                                        <Rect
                                            width={barWidth}
                                            rx={5}
                                            ry={5}
                                            height={10}
                                            fill="#8DBBFA"
                                            y={item.y - 5}
                                        />
                                    ) : null}
                                </Fragment>
                            );
                        }

                        if (item.type === "rapid") {
                            return (
                                <Fragment key={`rapid-${index}`}>
                                    <Rect
                                        width={barWidth}
                                        height={item.height}
                                        fill="#418DFF"
                                        y={item.y}
                                    />
                                    {numberOfInsertionTypes === 1 &&
                                    item.height ? (
                                        <Rect
                                            width={barWidth}
                                            rx={5}
                                            ry={5}
                                            height={10}
                                            fill="#418DFF"
                                            y={item.y - 5}
                                        />
                                    ) : null}
                                </Fragment>
                            );
                        }
                    })}
                </>
            );
        },
        [barWidth, proportionalValue]
    );

    const [tooltip, setTooltip] = useState<InsulinTooltipType>({
        bloodSugar: 0,
        insertions: {
            rapid: 0,
            basal: 0,
            other: 0,
        },
        administeredAt: new Date(),
        status: "OK",
        onShow: false,
    });

    const showTooltip = useCallback(
        (event: GestureResponderEvent, point: PointData) => {
            const toolTipOffset = 100;
            const originalLeft =
                event.nativeEvent.pageX - (isSmallScreen ? -35 : 200);
            const left = originalLeft < toolTipOffset ? 150 : originalLeft;

            const { value, insertions, administeredAt, status } = point;
            setTooltip({
                bloodSugar: value,
                insertions: {
                    rapid: insertions?.rapid ?? 0,
                    basal: insertions.basal ?? 0,
                    other: insertions.other ?? 0,
                },
                administeredAt: new Date(administeredAt),
                status,
                onShow: true,
                bottom: 70,
                left,
            });
        },
        [isSmallScreen]
    );

    useEffect(() => {
        setTooltip((state) => ({ ...state, onShow: false }));
    }, []);

    return (
        <Box
            w="100%"
            overflow="hidden"
            paddingBottom="24px"
            nativeID="portioning-graph"
        >
            <Pressable
                onPress={() =>
                    setTooltip((state) => ({ ...state, onShow: false }))
                }
            >
                <Box
                    position="relative"
                    // width={isSmallScreen ? "410px" : "1000px"}
                    background="white"
                    paddingBottom="24px"
                >
                    <Box
                        ml="25px"
                        mb="-14px"
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        width="100%"
                    >
                        {daysOfWeekMobile?.map((day, iDay) => (
                            <Text
                                fontSize="xs"
                                color="gray.600"
                                paddingTop="6px"
                                marginBottom="-6px"
                                width={basicBlock}
                                key={day}
                            >
                                {graphWidth > breakpoints.md && weekCount === 1
                                    ? daysOfWeekDesktop[iDay]
                                    : weekCount > 1
                                    ? weekNumbers[iDay]
                                    : day}
                            </Text>
                        ))}
                    </Box>
                    <Box
                        position="absolute"
                        right={
                            graphWidth > breakpoints.md && weekCount === 1
                                ? "15px"
                                : "5px"
                        }
                        textAlign={
                            graphWidth > breakpoints.md && weekCount === 1
                                ? "right"
                                : "left"
                        }
                        zIndex={0}
                    >
                        <Text
                            fontSize="xs"
                            color="gray.600"
                            paddingTop="6px"
                            marginBottom="6px"
                            width={
                                graphWidth > breakpoints.md && weekCount === 1
                                    ? `48px`
                                    : `40px`
                            }
                        >
                            {graphWidth > breakpoints.md && weekCount === 1
                                ? daysOfWeekDesktop[0]
                                : weekCount > 1
                                ? weekNumbers[0]
                                : daysOfWeekMobile[0]}
                        </Text>
                        <GraphDivision
                            width={
                                graphWidth > breakpoints.md && weekCount === 1
                                    ? `48px`
                                    : `40px`
                            }
                            isSolo
                        />
                    </Box>
                    <Box
                        position="absolute"
                        zIndex={-1}
                        top="0"
                        left="24px"
                        paddingTop="30px"
                        w="100%"
                        h="100%"
                        display="flex"
                        flexDirection="row"
                        borderRightStyle="dashed"
                        borderRightWidth={2}
                    >
                        {daysOfWeekMobile.map((day, index) => (
                            <GraphDivision
                                key={`${day}-${index}`}
                                width={basicBlock}
                                isLast={index === daysOfWeekMobile.length - 1}
                            />
                        ))}
                    </Box>
                    <Box position="absolute" top="30px" left="12px">
                        <Box
                            h="255px"
                            w="24px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                >
                                    {maxInsertions}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                >
                                    {maxInsertions / 2}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="blue.500"
                                    textAlign="center"
                                >
                                    0
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            background="white"
                            px={1}
                            shadow="1"
                            borderRadius={4}
                            mt="10px"
                        >
                            <Text fontSize="xs" color="blue.500">
                                {t("measures:portion")}
                            </Text>
                        </Box>
                    </Box>
                    <Svg
                        width={graphWidth > 1000 ? graphWidth : 1000}
                        height="300"
                        viewBox={`0 0 ${
                            graphWidth > 1000 ? graphWidth : 1000
                        } 300`}
                        fill="none"
                        preserveAspectRatio="xMaxYMin meet"
                    >
                        <G
                            x={isSmallScreen ? (weekCount === 1 ? 50 : 0) : 0}
                            y="0"
                        >
                            {/* bars */}
                            <G
                                x={
                                    weekCount === 1
                                        ? isSmallScreen
                                            ? offsetRatio - 43
                                            : offsetRatio - 5
                                        : isSmallScreen
                                        ? 25
                                        : 25
                                }
                            >
                                {pointData.map((day, i) => (
                                    <G
                                        x={i === 0 ? 0 : i * divideWidth}
                                        y="50"
                                        key={`insugraph-${i}`}
                                    >
                                        {showInsertGraph(day)}
                                    </G>
                                ))}
                            </G>
                            {/* generated path */}
                            <G
                                x={
                                    weekCount === 1
                                        ? isSmallScreen
                                            ? offsetX - 180
                                            : 10
                                        : isSmallScreen
                                        ? offsetX - 150
                                        : 0
                                }
                            >
                                <Path
                                    d={pathCoordinates}
                                    fill="none"
                                    stroke={pathStroke}
                                    strokeWidth={2}
                                />
                            </G>
                            {/* points  */}
                            <G
                                x={
                                    weekCount === 1
                                        ? isSmallScreen
                                            ? offsetX - 180
                                            : offsetRatio - 80
                                        : isSmallScreen
                                        ? offsetX - 150
                                        : offsetX - 90
                                }
                            >
                                {pointData.map((point, i) => (
                                    <G
                                        x={i === 0 ? 0 : i * divideWidth}
                                        y={point.y}
                                        key={`points-${point.y}-${i}`}
                                    >
                                        {weekCount === 1 && (
                                            <Rect
                                                x="80"
                                                y="90"
                                                width="22"
                                                height="16"
                                                rx="4"
                                                fill="#8D39B5"
                                            />
                                        )}
                                        <Circle
                                            cx="90"
                                            cy="110"
                                            r="4.5"
                                            fill={
                                                point.status === "ALERT"
                                                    ? "RED"
                                                    : point.status === "OK"
                                                    ? "#4CC8B0"
                                                    : "#FFCF47"
                                            }
                                            stroke="#8D39B5"
                                            strokeWidth="2"
                                        />

                                        {weekCount === 1 && (
                                            <SvgText
                                                fill="white"
                                                fontSize="10px"
                                                fontFamily="Inter"
                                                x="84"
                                                y="102"
                                                textAnchor="start"
                                            >
                                                {point.value
                                                    ? point.value.toFixed(1)
                                                    : "0.0"}
                                            </SvgText>
                                        )}
                                    </G>
                                ))}
                            </G>
                        </G>
                    </Svg>
                    {/* The pressable areas */}
                    <Box
                        position="absolute"
                        top="30px"
                        left="24px"
                        width="100%"
                        height="100%"
                    >
                        {pointData.map((point, i) => (
                            <Pressable
                                onPress={(e) => showTooltip(e, point)}
                                position="absolute"
                                left={
                                    (i === 0 ? 0 : i * divideWidth) +
                                    (isSmallScreen
                                        ? i === 0
                                            ? 4
                                            : i === 1 // fix the strange bug that breaks second pressable
                                            ? 13
                                            : 14
                                        : offsetRatio - 40 / weekCount)
                                }
                                bottom="70px"
                                key={`pressable-${point.y}-${i}`}
                                width={`${32 / weekCount}px`}
                                height={260}
                                //borderColor="black"
                                //borderWidth={1}
                            />
                        ))}
                    </Box>
                    <Box
                        position="absolute"
                        top="30px"
                        right={graphWidth < breakpoints.md ? "10px" : "35px"}
                        alignItems="flex-end"
                    >
                        <Box
                            h="255px"
                            w="24px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="violet.600"
                                    textAlign="center"
                                >
                                    {maxValue}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="violet.600"
                                    textAlign="center"
                                >
                                    {maxValue / 2}
                                </Text>
                            </Box>
                            <Box background="white" shadow="1" borderRadius={4}>
                                <Text
                                    fontSize="xs"
                                    color="violet.600"
                                    textAlign="center"
                                >
                                    0
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            background="white"
                            px={1}
                            shadow="1"
                            borderRadius={4}
                            mt="10px"
                        >
                            <Text fontSize="xs" color="violet.600">
                                mmol/l
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Pressable>
            <InsulinTooltip tooltip={tooltip} />
        </Box>
    );
};

export default InsulinPortioningGraph;
