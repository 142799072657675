import type { ITheme } from "native-base";

export interface Props {
    theme: ITheme;
}

const baseStyle = () => ({
    borderRadius: "sm", // '4px'
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    _web: {
        _disabled: {
            cursor: "not-allowed",
        },
        _loading: {
            cursor: "not-allowed",
        },
        cursor: "pointer",
        userSelect: "none",
    },
    _focus: {
        borderColor: `green.400`,
    },
    _focusVisible: {
        _web: {
            style: {
                outlineWidth: "2px",
                outlineColor: "green.500",
                outlineStyle: "solid",
            },
        },
    },
    _loading: {
        opacity: "40",
    },
    _disabled: {
        opacity: "40",
    },
});

// Filled Green
const primary = ({ theme }: Props) => ({
    bg: "green.500",
    _text: {
        color: theme.colors.white,
    },
    _hover: {
        bg: "green.600",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.green[200]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "green.200",
    },
});

// Filled Red
const destructive = ({ theme }: Props) => ({
    bg: "error.400",
    _text: {
        color: "white",
    },
    _hover: {
        bg: "error.500",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.error[200]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "error.100",
    },
});

// Filled Gray
const gray = ({ theme }: Props) => ({
    bg: "gray.75",
    _hover: {
        bg: "gray.100",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.gray[100]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "gray.75",
    },
});

// Ghost Green
const ghostGreen = ({ theme }: Props) => ({
    bg: "transparent",
    _text: {
        color: "green.500",
    },
    _hover: {
        bg: "green.50",
        _text: {
            color: "green.600",
        },
    },
    _focus: {
        _text: {
            color: "green.600",
        },
        style: {
            boxShadow: `${theme.colors.green[100]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        _text: {
            color: "green.200",
        },
        bg: "transparent ",
    },
});

// Filled White
const outlined = () => ({
    bg: "white",
    borderWidth: 1,
    borderColor: "gray.200",
    _text: {
        color: "gray.800",
    },

    _hover: {
        bg: "white",
        _text: {
            color: "gray.800",
        },
    },
    _focus: {
        bg: "white",
        _text: {
            color: "gray.800",
        },
    },
    _disabled: {
        bg: "white",
        _text: {
            color: "gray.300",
        },
    },
});

// Ghost Gray
const ghost = ({ theme }: Props) => ({
    bg: "transparent",
    _text: {
        color: "gray.800",
    },

    _hover: {
        bg: "gray.50",
        _text: {
            color: "gray.900",
        },
    },
    _focus: {
        bg: "gray.50",
        _text: {
            color: "gray.900",
        },
        style: {
            boxShadow: `${theme.colors.gray[100]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "transparent",
        _text: {
            color: "gray.200",
        },
    },
});

// Link Green
const link = () => ({
    bg: "transparent",
    _text: {
        color: "green.500",
    },
    _hover: {
        _text: {
            color: "green.600",
        },
    },
    _focus: {
        _text: {
            color: "green.600",
        },
    },
    _disabled: {
        _text: {
            color: "green.200",
        },
    },
});

// Link Gray
const ghostLink = () => ({
    bg: "transparent",
    _text: {
        color: "gray.800",
    },
    _hover: {
        _text: {
            color: "gray.900",
        },
    },
    _focus: {
        _text: {
            color: "gray.900",
        },
    },
    _disabled: {
        _text: {
            color: "gray.200",
        },
    },
});

// Ghost Link Gray
const ghostGrayLink = () => ({
    bg: "transparent",
    _text: {
        color: "rose.800",
    },
    _hover: {
        _text: {
            color: "gray.900",
        },
    },
    _focus: {
        _text: {
            color: "gray.900",
        },
    },
    _disabled: {
        _text: {
            color: "gray.200",
        },
    },
});

// Dark Navigation Button
const navDark = () => ({
    bg: "navBgDark",
});

const variants = {
    primary,
    gray,
    destructive,
    outlined,
    ghost,
    ghostGreen,
    link,
    ghostLink,
    ghostGrayLink,
    navDark,
};

const sizes = {
    xs: {
        p: 2,
        minHeight: 8,
        minWidth: 8,
    },
    sm: {
        p: 2,
        minHeight: 9,
        minWidth: 9,
    },
    md: {
        p: 2,
        minHeight: 10,
        minWidth: 10,
    },
    lg: {
        p: 2,
        minHeight: 11,
        minWidth: 11,
    },
    xl: {
        p: 2,
        minHeight: 12,
        minWidth: 12,
    },
    "2xl": {
        p: 2,
        minHeight: 14,
        minWidth: 14,
    },
};

const defaultProps = {
    variant: "primary",
    size: "md",
};

export default {
    baseStyle,
    variants,
    sizes,
    defaultProps,
};
