import type { ReactNode } from "react";
import { useMemo } from "react";
import { Box, Stack, Text, VStack } from "native-base";
import { forHumans, riskForHumans } from "@madmedical/medical";
import {
    firstToUpper,
    formatMonthDay,
    formatPercentWithSign,
} from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../providers/currentPatient";
import Headline from "../molecules/Headline";
import useResponsive from "../util/useResponsive";
import Breadcrumb from "../molecules/Breadcrumb";
import Paper from "../atoms/Paper";
import SimpleListItem from "../molecules/ListItem/SimpleListItem";
import type { Prediction as PredictionModel } from "../providers";

interface Props {
    prediction: PredictionModel;
    charts: ReactNode;
}

const Prediction = ({ prediction, charts }: Props) => {
    const { patient } = useCurrentPatient();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const title = useMemo(
        () =>
            prediction.trends
                .map(({ metricType }) => forHumans(metricType))
                .join(" – ") + ` (${formatMonthDay(prediction.dateRange.to)})`,
        [prediction.dateRange.to, prediction.trends]
    );

    return (
        <>
            <Box
                borderRadius={!isSmallScreen ? "lg" : undefined}
                borderColor="gray.100"
                borderWidth={1}
                borderStyle="solid"
                bgColor="white"
                borderBottomRadius={!isSmallScreen ? "lg" : undefined}
                overflow="hidden"
                mb="3"
                width="100%"
            >
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    borderTopRadius={!isSmallScreen ? "lg" : undefined}
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link
                                    route="patient"
                                    params={{ patientId: patient.userId }}
                                >
                                    {patient.fullName}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>{title}</Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title={title}
                />
                {charts}
            </Box>

            {isSmallScreen && (
                <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    color="gray.800"
                    px="4"
                    py="3"
                >
                    {title}
                </Text>
            )}

            <Paper>
                {!isSmallScreen && (
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        size="xs"
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        title={title}
                    />
                )}
                <Box
                    px={{
                        base: 0,
                        md: 6,
                    }}
                    width="full"
                    py={{ base: 0, md: 4 }}
                >
                    <SimpleListItem
                        label={t("measures:alert")}
                        content={prediction.risks
                            .map((risk) => riskForHumans(risk))
                            .join(", ")}
                        px={{ base: 4, md: 0 }}
                    />
                    <Stack
                        width="full"
                        px={{
                            base: 4,
                            md: 0,
                        }}
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        {prediction.trends.map(
                            ({
                                slope,
                                averageDisplay,
                                currentDisplay,
                                metricType,
                            }) => (
                                <VStack
                                    key={metricType}
                                    width={{
                                        base: "full",
                                        md: "1/2",
                                    }}
                                >
                                    <SimpleListItem
                                        label={firstToUpper(
                                            t(
                                                `measures:${forHumans(
                                                    metricType
                                                )}`
                                            )
                                        )}
                                        content={formatPercentWithSign(slope)}
                                        borderBottomWidth={0}
                                        px={0}
                                    />
                                    <SimpleListItem
                                        label={t("average")}
                                        content={averageDisplay}
                                        borderBottomWidth={0}
                                        px={0}
                                    />
                                    <SimpleListItem
                                        label={t("measures:latestMeasurement")}
                                        content={currentDisplay}
                                        borderBottomWidth={0}
                                        px={0}
                                    />
                                </VStack>
                            )
                        )}
                    </Stack>
                </Box>
            </Paper>
        </>
    );
};

export default Prediction;
