import type { BloodSugar } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper, useResponsive } from "@madmedical/ui";
import { ceil2, floor2 } from "@madmedical/utils";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";
import { transformGroups } from "../../insulin/util/transformLabel";
import type { InsulinAdministration } from "../../insulin";

type Props = CommonProps<BloodSugar> & {
    insulinAdministrations?: InsulinAdministration[];
};

const BloodSugarChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
    insulinAdministrations,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BloodSugar, widget);
    const { isSmallScreen } = useResponsive();

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: insulinAdministrations ? "line-single-dots" : "line-single",
            unit: "mmol/l",
            limits: {
                yMax: max ? ceil2(max.mmolPerL + 0.25) : 12,
                yMin: min ? floor2(min.mmolPerL - 0.25) : 2,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.mmolPerL,
            })),
            insulin: transformGroups(insulinAdministrations, isSmallScreen),
        } as const;
    }, [data, dateRange, thresholds, insulinAdministrations, isSmallScreen]);

    return (
        <GraphWrapper
            metricType={MetricType.BloodSugar}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BloodSugarChart;
