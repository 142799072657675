import type { RestingHeartRate } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import { ceil2, floor2 } from "@madmedical/utils";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<RestingHeartRate>;

const RestingHeartRateChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.RestingHeartRate, widget);

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "line-single",
            unit: "bpm",
            limits: {
                yMax: max ? ceil2(max.bpm + 1) : 80,
                yMin: min ? floor2(min.bpm - 1) : 40,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.bpm,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.RestingHeartRate}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default RestingHeartRateChart;
