import Svg, { Path, Rect } from "react-native-svg";

const HappyMood = () => (
    <Svg width="100%" height="100%" viewBox="0 0 65 64" fill="none">
        <Rect x="0.0998535" width="64" height="64" rx="4" fill="#FEE6DF" />
        <Path
            d="M14.4862 39.0916C14.4862 39.0916 12.5014 31.4171 18.985 33.1368C18.985 33.1368 30.4881 39.3734 44.9187 33.1368C51.4023 31.4177 49.4176 39.0916 49.4176 39.0916C45.7131 50.4706 31.9515 49.544 31.9515 49.544C31.9515 49.544 18.1906 50.4706 14.4862 39.0916ZM18.6737 18.1607C18.6737 15.8633 20.537 14 22.8344 14C25.1311 14 26.9944 15.8633 26.9944 18.1607C26.9944 20.4594 25.1311 22.3227 22.8344 22.3227C20.537 22.3227 18.6737 20.4588 18.6737 18.1607ZM37.5394 18.1607C37.5394 15.8633 39.4047 14 41.7014 14C43.9988 14 45.8621 15.8633 45.8621 18.1607C45.8621 20.4594 43.9988 22.3227 41.7014 22.3227C39.4047 22.3227 37.5394 20.4588 37.5394 18.1607Z"
            fill="#EBA388"
        />
    </Svg>
);

export default HappyMood;
