import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Radio as BaseRadio, useTheme } from "native-base";

interface Props extends ComponentProps<typeof BaseRadio> {
    variant?: "primary" | "greenFilled" | "orange" | "orangeFilled";
}

const Radio = ({
    children,
    variant = "primary",
    value,
    size = "sm",
    ...rest
}: Props) => {
    const { colors } = useTheme();

    const buttonVariants = useMemo(
        () => ({
            primary: colors.green[500],
            greenFilled: colors.green[500],
            orange: colors.orange[500],
            orangeFilled: colors.orange[500],
        }),
        [colors]
    );
    const bgVariants = useMemo(
        () => ({
            primary: "white",
            greenFilled: colors.green[100],
            orange: "white",
            orangeFilled: colors.orange[100],
        }),
        [colors]
    );

    return (
        <BaseRadio
            value={value}
            size={size}
            borderWidth={1}
            bg={bgVariants[variant]}
            borderColor={buttonVariants[variant]}
            _checked={{
                bg: bgVariants[variant],
                borderColor: buttonVariants[variant],
                _icon: {
                    color: buttonVariants[variant],
                },
                _hover: {
                    borderColor: buttonVariants[variant],
                },
            }}
            _hover={{
                borderColor: buttonVariants[variant],
            }}
            {...rest}
        >
            {children}
        </BaseRadio>
    );
};

Radio.Group = BaseRadio.Group;

export default Radio;
