import type { PatientDoctor } from "@madmedical/ui";
import {
    CurrentPatientProvider,
    PredictionPage,
    Spinner,
} from "@madmedical/ui";
import { useParams } from "@madmedical/routing";
import { invariant } from "@madmedical/utils";
import { usePatient } from "@madmedical/user";
import { useGetPredictionQuery } from "../api";
import adaptPrediction from "../adaptPrediction";
import PredictionChart from "./PredictionChart";

const Prediction = () => {
    const { predictionId } = useParams<"predictionId">();
    invariant(predictionId);
    const { patient, isLoading: isPatientLoading } = usePatient();
    const { data: prediction, isLoading: isPredictionLoading } =
        useGetPredictionQuery(predictionId);

    if (isPatientLoading || isPredictionLoading) {
        return <Spinner />;
    }

    invariant(prediction);
    invariant(patient);

    return (
        <CurrentPatientProvider value={{ patient: patient as PatientDoctor }}>
            <PredictionPage
                prediction={adaptPrediction(prediction)}
                charts={prediction.trends.map((trend) => (
                    <PredictionChart
                        key={trend.metricType}
                        prediction={prediction}
                        trend={trend}
                    />
                ))}
            />
        </CurrentPatientProvider>
    );
};

export default Prediction;
