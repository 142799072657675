/// <reference path="carbonIcons.d.ts"/>
import {
    Activity24,
    Add24,
    Alarm24,
    ArrowLeft24,
    ArrowRight24,
    ArrowUp24,
    Bullhorn24,
    Calendar24,
    CaretDown24,
    ChartCustom24,
    ChartLineData24,
    ChartNetwork24,
    Chat24,
    Checkmark24,
    CheckmarkOutline24,
    ChevronDown24,
    ChevronLeft24,
    ChevronRight24,
    ChevronUp24,
    CircleSolid24,
    Close24,
    Copy24,
    Devices24,
    DocumentAdd24,
    DocumentAttachment24,
    DocumentBlank24,
    DocumentDownload24,
    DocumentView24,
    Download24,
    Edit24,
    Email24,
    Favorite24,
    Filter24,
    HealthCross24,
    Help24,
    Home24,
    Information24,
    MailAll24,
    Notification24,
    Number_024,
    Number_124,
    Number_224,
    Number_324,
    Number_424,
    Number_524,
    Number_624,
    Number_724,
    Number_824,
    Number_924,
    OverflowMenuHorizontal24,
    OverflowMenuVertical24,
    ReportData24,
    Search24,
    Settings24,
    ShoppingBag24,
    Stethoscope24,
    Subtract24,
    Task24,
    TaskAdd24,
    Time24,
    TrashCan24,
    User24,
    UserAdmin24,
    View24,
    Warning24,
} from "@carbon/icons/es";
import { toString } from "@carbon/icon-helpers";

// Remember to add any new icons to carbonIcons.d.ts too

const ICONS = {
    alarm: Alarm24,
    activity: Activity24,
    add: Add24,
    arrowLeft: ArrowLeft24,
    arrowRight: ArrowRight24,
    arrowUp: ArrowUp24,
    bullhorn: Bullhorn24,
    calendar: Calendar24,
    caretDown: CaretDown24,
    chartCustom: ChartCustom24,
    chartLineData: ChartLineData24,
    chartNetwork: ChartNetwork24,
    chat: Chat24,
    checkmark: Checkmark24,
    checkmarkOutline: CheckmarkOutline24,
    chevronDown: ChevronDown24,
    chevronLeft: ChevronLeft24,
    chevronRight: ChevronRight24,
    chevronUp: ChevronUp24,
    circleSolid: CircleSolid24,
    close: Close24,
    copy: Copy24,
    devices: Devices24,
    documentAdd: DocumentAdd24,
    documentAttachment: DocumentAttachment24,
    documentBlank: DocumentBlank24,
    documentDownload: DocumentDownload24,
    documentView: DocumentView24,
    download: Download24,
    edit: Edit24,
    email: Email24,
    filter: Filter24,
    heart: Favorite24,
    healthCross: HealthCross24,
    help: Help24,
    home: Home24,
    information: Information24,
    mailAll: MailAll24,
    notification: Notification24,
    number_0: Number_024,
    number_1: Number_124,
    number_2: Number_224,
    number_3: Number_324,
    number_4: Number_424,
    number_5: Number_524,
    number_6: Number_624,
    number_7: Number_724,
    number_8: Number_824,
    number_9: Number_924,
    overflowMenuHorizontal: OverflowMenuHorizontal24,
    overflowMenuVertical: OverflowMenuVertical24,
    reportData: ReportData24,
    search: Search24,
    settings: Settings24,
    shoppingBag: ShoppingBag24,
    stethoscope: Stethoscope24,
    subtract: Subtract24,
    task: Task24,
    taskAdd: TaskAdd24,
    time: Time24,
    trashCan: TrashCan24,
    user: User24,
    userAdmin: UserAdmin24,
    view: View24,
    warning: Warning24,
} as const;

export type IconName = keyof typeof ICONS;

export interface Props {
    name: IconName;
    size?: number;
    fill?: string;
}

export const getIconSvgString = ({
    name,
    size = 16,
    fill = "currentColor",
}: Props) => {
    const iconDefinition = ICONS[name];

    return toString({
        ...iconDefinition,
        attrs: {
            ...iconDefinition.attrs,
            width: size,
            height: size,
            size,
            fill,
        },
        size,
    });
};
