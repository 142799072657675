import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta09 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M90.4148 94.4912C90.6267 95.5932 90.1181 96.8223 89.3552 97.6841C88.8183 98.3058 88.1685 98.8568 87.5327 99.3653C86.9111 99.874 86.2047 100.34 85.4135 100.524C83.7464 100.919 82.2206 99.8457 81.2034 98.6166C80.61 97.9102 80.1297 97.1331 79.5504 96.4126C79.0277 95.7627 78.4061 95.1694 77.7138 94.6749C76.6259 93.912 75.3827 93.3892 74.0829 93.1491C71.4693 94.5195 68.6437 95.466 65.6486 95.24C62.4415 94.9857 59.8279 93.1632 57.6804 90.8886C55.3917 88.4727 53.3996 85.7319 51.6054 82.9487C49.7688 80.0807 47.9887 77.0432 46.9291 73.7938C46.0673 71.1519 45.7706 68.2557 46.5194 65.5714C46.2085 65.4442 45.9118 65.3029 45.601 65.1334C43.1145 63.8478 41.0377 61.8134 39.5825 59.4257C37.9437 56.7273 37.1243 53.5909 37.0113 50.4545C36.8135 44.2806 39.2293 37.923 43.6514 33.5716C48.0169 29.2768 54.4451 27.0304 60.4919 28.4008C63.3316 29.0507 66.03 30.4211 68.1492 32.4273C70.339 34.5041 71.8083 37.2025 72.5147 40.127C73.9416 46.089 71.9213 52.2911 68.0644 56.911C69.2936 58.3944 69.6468 60.4571 68.9545 62.2937C69.6609 62.548 70.2825 63.0425 70.664 63.6782C71.1443 64.4694 71.2573 65.4584 71.003 66.3484C72.2887 66.8146 73.4895 67.4221 74.6057 68.2133C77.0357 69.9369 78.9429 72.3669 79.9743 75.1501C80.9632 77.8485 81.161 80.8013 80.5535 83.6127C81.4012 84.1637 82.1782 84.7995 82.8705 85.5341C83.4215 86.1134 83.9301 86.735 84.3539 87.4131C84.58 87.7663 84.7919 88.1337 84.9755 88.501C85.1451 88.8259 85.3005 89.165 85.5548 89.4334C86.2471 90.1963 87.0947 89.4758 87.8011 89.1085C88.4086 88.7835 89.0868 88.6988 89.7649 88.8401C91.0929 89.1226 92.209 90.2387 92.1949 91.6515C92.1949 92.8524 91.4603 93.9544 90.4148 94.4488V94.4912Z"
            fill="#FFDCD0"
        />
        <Path
            d="M68.656 61.9404C66.3673 62.0676 64.1633 63.0565 62.5104 64.6247C61.6627 65.43 61.0269 66.3625 60.5042 67.3938C60.3771 67.6622 60.2358 67.9307 60.0097 68.1285C59.7413 68.3686 59.374 68.411 59.0208 68.4393C58.2437 68.4817 57.4667 68.4958 56.6897 68.524C54.9237 68.5947 53.1577 68.6512 51.3776 68.7218C51.0103 68.7218 51.0809 69.2869 51.4341 69.2728C52.9882 69.2163 54.5564 69.1598 56.1104 69.1033C56.9016 69.075 57.6928 69.0468 58.4839 69.0185C59.0914 68.9903 59.7696 69.0185 60.2782 68.6371C60.7444 68.2839 60.9563 67.7187 61.2247 67.2243C61.4932 66.7157 61.8322 66.2353 62.1996 65.7832C62.9483 64.879 63.8949 64.1161 64.9263 63.5651C66.0989 62.9435 67.3845 62.5762 68.7125 62.5055C69.0799 62.4914 69.0092 61.9263 68.656 61.9546V61.9404Z"
            fill="#DD8F8C"
        />
        <Path
            d="M70.8057 66.4907C69.6613 67.6492 67.9942 68.2002 66.3978 68.0024C66.313 67.96 66.2282 67.96 66.1576 68.0306C66.1011 68.073 66.0587 68.1295 66.0305 68.1861C65.4371 69.1326 64.8155 70.0651 64.1514 70.9834C63.4874 71.9017 62.8234 72.82 62.0464 73.6536C61.2835 74.4871 60.3934 75.0381 59.2632 75.1794C58.1047 75.3207 56.8897 75.1935 55.7171 75.0946C54.3184 74.9816 52.9339 74.812 51.5493 74.5719C51.1961 74.5154 51.1114 75.0664 51.4646 75.1229C52.7361 75.3348 54.0076 75.5043 55.2932 75.6173C56.5224 75.7304 57.7798 75.8575 59.023 75.7586C60.1391 75.6739 61.1281 75.2924 61.9475 74.5436C62.7952 73.7807 63.4733 72.8341 64.1514 71.9158C64.9567 70.828 65.7338 69.6977 66.4543 68.5534C68.2203 68.737 70.0004 68.0872 71.2437 66.8298C71.498 66.5755 71.06 66.2223 70.8057 66.4766V66.4907Z"
            fill="#DD8F8C"
        />
        <Path
            d="M80.1181 83.3295C79.9485 83.3013 79.8355 83.4143 79.7931 83.5697C79.5953 84.2902 79.2563 84.9684 78.79 85.5618C78.564 85.8443 79.002 86.1975 79.228 85.9149C79.7507 85.2368 80.1322 84.4739 80.3441 83.6545C80.3865 83.5132 80.2452 83.3578 80.1039 83.3295H80.1181Z"
            fill="#DD8F8C"
        />
        <Path
            d="M89.212 92.6403C88.3785 92.3012 87.4884 92.4425 86.669 92.7392C85.7789 93.0642 84.6205 93.9825 83.7021 93.3467C82.9251 92.8099 82.4024 91.835 81.9503 91.0439C81.0461 89.4615 80.3114 87.7238 78.9269 86.4805C77.5282 85.2232 75.6492 84.8982 73.8409 85.3079C71.6793 85.8024 69.8992 87.2011 68.2179 88.5715C67.9354 88.7975 68.2603 89.2496 68.5429 89.0236C69.9981 87.851 71.4956 86.636 73.2899 86.0426C75.0276 85.4633 76.9631 85.5622 78.4042 86.7631C79.7605 87.8792 80.4951 89.5322 81.3145 91.0439C82.035 92.3436 83.1512 94.5476 84.9595 94.0531C85.9909 93.7706 86.8527 93.0924 87.9546 92.9935C88.8164 92.9229 89.876 93.3608 89.9891 94.3215C90.0314 94.6747 90.5824 94.6182 90.5401 94.265C90.4553 93.5021 89.9043 92.9229 89.212 92.6262V92.6403Z"
            fill="#DD8F8C"
        />
        <Path
            d="M59.8818 58.606C58.7798 60.0894 56.1661 58.5071 57.254 56.9106C58.2571 54.8056 61.3652 56.84 59.8818 58.606Z"
            fill="#5D6982"
        />
        <Mask
            id="mask0_1024_101264"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101264)">
            <Path
                d="M78.5 75C88.5 69 80 61.5 86.5 53.5C93.8125 44.5 100.516 67.4823 107.5 59.5C111 55.5 109 30.5001 112.5 29.5"
                stroke="#DD8F8C"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </G>
    </Svg>
);

export default Placenta09;
