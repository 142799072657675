import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../util/useResponsive";
import DeviceList from "./DeviceList";
import ConnectedDevicesTable from "./ConnectedDevicesTable";

const ConnectedDevicesList = () => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return isSmallScreen ? (
        <DeviceList title={t("connectedDevices")} />
    ) : (
        <Box px={6} py={4} width="full">
            <ConnectedDevicesTable />
        </Box>
    );
};

export default ConnectedDevicesList;
