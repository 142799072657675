import type { ComponentProps, ReactElement } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Radio from "../atoms/Radio";
import useResponsive from "../util/useResponsive";

interface Props extends Pick<ComponentProps<typeof VStack>, "pl"> {
    name: string;
    value?: boolean | string | number;
    content: string;
    answerInput?: ReactElement;
    customInput?: ReactElement;
    hasOptionalAnswer?: boolean;
    isLongInput?: boolean;
    onChange?: (value: boolean) => void;
}

const OnboardingRow = ({
    name,
    value,
    content,
    answerInput,
    customInput,
    hasOptionalAnswer = true,
    onChange,
    isLongInput,
    ...stackProps
}: Props) => {
    const handleChange = (value: string) => {
        onChange?.(value === "yes");
    };

    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const isLong = isLongInput && isSmallScreen;

    return (
        <VStack
            width="full"
            space={3}
            p={{
                base: 4,
                md: 6,
            }}
            borderLeftWidth={2}
            borderLeftColor={value ? "error.300" : "transparent"}
            {...stackProps}
        >
            <HStack
                width="full"
                space={3}
                alignItems={{
                    base: isLong ? "flex-start" : "center",
                    md: "flex-start",
                }}
                direction={isLong ? "column" : "row"}
            >
                <Box flex={1}>
                    <Text fontSize="md">{content}</Text>
                </Box>

                {hasOptionalAnswer && (
                    <HStack space={4}>
                        <Radio.Group
                            name={name}
                            flexDirection="row"
                            value={value ? "yes" : "no"}
                            onChange={handleChange}
                            defaultValue="no"
                        >
                            <Radio value="yes">{t("yes")}</Radio>
                            <Radio value="no" ml={4}>
                                {t("no")}
                            </Radio>
                        </Radio.Group>
                    </HStack>
                )}
                {customInput && customInput}
            </HStack>

            {answerInput && (
                <HStack width="full" justifyContent="flex-start">
                    <Box width={{ base: "full", md: "1/2" }}>{answerInput}</Box>
                </HStack>
            )}
        </VStack>
    );
};

export default OnboardingRow;
