import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import DeviceList from "../organisms/DeviceList";
import DevicesTable from "../organisms/DevicesTable";
import useResponsive from "../util/useResponsive";

const DevicesList = () => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return isSmallScreen ? (
        <>
            <DeviceList
                title={t("cloudDevices")}
                deviceTypes={["cloud", "apple"]}
            />
            <DeviceList title={t("bluetoothDevices")} deviceTypes={["ble"]} />
        </>
    ) : (
        <Box px={6} py={4} width="full">
            <DevicesTable />
        </Box>
    );
};

export default DevicesList;
