import type { ComponentProps, ReactNode } from "react";
import { useRef } from "react";
import { Box, HStack, ScrollView, VStack } from "native-base";
import type { ScrollView as RNScrollView } from "react-native";
import type { FieldValues } from "@madmedical/form";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Paper from "../../../atoms/Paper";
import Headline from "../../../molecules/Headline";
import Breadcrumb from "../../../molecules/Breadcrumb";
import Button from "../../../atoms/Button";
import IconButton from "../../../atoms/IconButton";
import useResponsive from "../../../util/useResponsive";
import PreOpNextButton from "./PreOpNextButton";

interface Props<TFieldValues extends FieldValues>
    extends ComponentProps<typeof Paper> {
    children: ReactNode;
    isLastScreen?: boolean;
    isSubmitting?: boolean;
    isNextDisabled?: boolean;
    onPreviousPress?: () => void;
    onNextPress: () => void;
    title?: string;
    validate?: (values: Partial<TFieldValues>) => boolean;
}

const PreOpWrapper = <TFieldValues extends FieldValues>({
    children,
    isLastScreen = false,
    isSubmitting = false,
    isNextDisabled = false,
    onPreviousPress,
    onNextPress,
    title = "Altatás előtti kérdőív",
    validate,
    ...rest
}: Props<TFieldValues>) => {
    const { isSmallScreen } = useResponsive();
    const scrollViewRef = useRef<RNScrollView>();
    const { t } = useTranslation();
    const nextButtonLabel = isSubmitting
        ? t("save")
        : isLastScreen
        ? t("ok")
        : isSmallScreen
        ? t("continue")
        : t("next");

    const scrollToTop = () => {
        if (isWeb) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
            scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
    };

    const handlePrevPress = () => {
        if (!onPreviousPress) return;
        scrollToTop();
        onPreviousPress();
    };

    const handleNextPress = () => {
        scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        scrollToTop();
        onNextPress();
    };

    return (
        <Paper
            justifyContent="space-between"
            alignItems="flex-start"
            height="full"
            flex={1}
            {...rest}
        >
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link route="dashboard" params={{}}>
                                {t("health")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>{title}</Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <Headline
                justifyContent="center"
                alignItems="center"
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                title={title}
            />
            <ScrollView w="full" ref={scrollViewRef}>
                <VStack width="full" flex={1}>
                    {children}
                </VStack>
            </ScrollView>
            <HStack
                width="full"
                px={6}
                py={4}
                justifyContent="space-between"
                borderTopWidth={1}
                borderTopColor="gray.100"
                space={2}
            >
                {onPreviousPress ? (
                    isSmallScreen ? (
                        <IconButton
                            iconName="arrowLeft"
                            size="md"
                            onPress={handlePrevPress}
                            variant="outlined"
                        />
                    ) : (
                        <Button
                            size="md"
                            variant="outlined"
                            onPress={handlePrevPress}
                        >
                            Vissza
                        </Button>
                    )
                ) : (
                    // TODO: Replace Box with null and implement a better solution for alignment
                    <Box />
                )}
                {validate ? (
                    <PreOpNextButton
                        isSubmitting={isSubmitting}
                        isDisabled={isNextDisabled}
                        onPress={handleNextPress}
                        validate={validate}
                        label={nextButtonLabel}
                    />
                ) : (
                    <Button
                        size="md"
                        isDisabled={isNextDisabled}
                        isLoading={isSubmitting}
                        isLoadingText={`${nextButtonLabel}…`}
                        onPress={handleNextPress}
                        flex={isSmallScreen ? 1 : "unset"}
                    >
                        {nextButtonLabel}
                    </Button>
                )}
            </HStack>
        </Paper>
    );
};

export default PreOpWrapper;
