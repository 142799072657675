import type { Metric } from "@madmedical/medical";
import { UnderlyingCondition } from "@madmedical/medical";
import type { PatientMe } from "@madmedical/user";
import { usePatient, usePatientId } from "@madmedical/user";
import type { Ulid } from "@madmedical/utils";
import { invariant, rangeToString } from "@madmedical/utils";
import {
    EvaluationProvider,
    MeasurementCommentProvider,
    PregnancyMoodProvider,
    Spinner,
} from "@madmedical/ui";
import { useCallback, useState } from "react";
import { useAuth } from "@madmedical/store";
import { useParams } from "@madmedical/routing";
import { useGetOpinionsQuery } from "../opinion/api";
import adaptOpinion from "../opinion/adaptOpinion";
import { useGetCommentsQuery } from "../comment/api";
import DeleteCommentDialog from "../comment/components/DeleteCommentDialog";
import type { InsulinAdministration } from "../insulin/model";
import { useGetPregnancyWellBeingsQuery } from "../pregnancy/api";
import Chart from "./Chart";
import type { CommonProps } from "./model";

/**
 * A chart with evaluations and comments and thresholds
 */

interface Props<TMetric extends Metric> extends CommonProps<TMetric> {
    metricType: TMetric["type"];
    insulinAdministrations?: InsulinAdministration[];
}

const ChartDecorated = <TMetric extends Metric>(props: Props<TMetric>) => {
    const dateRangeString = rangeToString(props.dateRange);

    const { patientId } = usePatientId();
    const { patient } = usePatient();

    const { opinionId } = useParams<"opinionId">();
    const { userId } = useAuth();
    const { data: opinionsData, isLoading: isOpinionsLoading } =
        useGetOpinionsQuery({
            patientId,
            metricTypes: [props.metricType],
            dateRange: dateRangeString,
            page: 1,
            pageSize: 7,
        });
    const { data: commentsData, isLoading: isCommentsLoading } =
        useGetCommentsQuery({
            patientId,
            dateRange: dateRangeString,
        });
    const [deleteCommentId, setDeleteCommentId] = useState<Ulid>();

    const handleDialogClose = useCallback(() => {
        setDeleteCommentId(undefined);
    }, []);

    const typedPatient = patient as PatientMe;
    const isPregnant = typedPatient?.underlyingConditions?.includes(
        UnderlyingCondition.Pregnancy
    );

    const { data: wellBeingData, isLoading: isWellbeingLoading } =
        useGetPregnancyWellBeingsQuery(
            {
                patientId,
            },
            {
                skip: !isPregnant,
            }
        );

    if (isOpinionsLoading || isCommentsLoading || isWellbeingLoading) {
        return <Spinner />;
    }

    invariant(opinionsData);
    invariant(commentsData);

    return (
        <>
            <EvaluationProvider
                value={{
                    evaluations: opinionsData.items.map(adaptOpinion),
                    selectedId: opinionId,
                    pagination: opinionsData.pagination,
                    onSortChange: undefined,
                    onSearchChange: undefined,
                    filterChoices: undefined,
                    onFilterChange: undefined,
                }}
            >
                <MeasurementCommentProvider
                    value={{
                        comments: commentsData.map(
                            ({ date, author, ...rest }) => ({
                                date: new Date(date),
                                author: {
                                    ...author,
                                    isSelf: userId === author.id,
                                },
                                ...rest,
                            })
                        ),
                        onDelete: setDeleteCommentId,
                    }}
                >
                    <PregnancyMoodProvider
                        value={{
                            pregnancyWellBeing: wellBeingData?.items ?? [],
                        }}
                    >
                        <Chart {...props} />
                    </PregnancyMoodProvider>
                </MeasurementCommentProvider>
            </EvaluationProvider>
            <DeleteCommentDialog
                id={deleteCommentId}
                open={!!deleteCommentId}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default ChartDecorated;
