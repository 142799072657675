import { Box, Text } from "native-base";
import { useState } from "react";
import { Linking } from "react-native";
import { useTranslation } from "@madmedical/i18n";
import Checkbox from "../../atoms/Checkbox";
import PreOpWrapper from "../PreOp/Patient/PreOpWrapper";

interface Props {
    isSaving: boolean;
    onPreviousPress: () => void;
    onNextPress: () => void;
}

// TODO: Add PDFs

const OnboardingConfirmation = ({
    isSaving,
    onPreviousPress,
    onNextPress,
}: Props) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const { t } = useTranslation();

    return (
        <PreOpWrapper
            isLastScreen
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            isNextDisabled={!termsAccepted || !privacyAccepted}
            isSubmitting={isSaving}
        >
            <Box
                flex={1}
                maxWidth={{ base: "full", md: "4/6" }}
                p={{
                    base: 4,
                    md: 6,
                }}
                mx={6}
            >
                <Checkbox
                    value="terms"
                    isChecked={termsAccepted}
                    onChange={setTermsAccepted}
                    mb={10}
                >
                    <Text fontWeight="medium">
                        {t("readAndAcknowledge")}:{" "}
                        <Text
                            color="green.500"
                            underline
                            onPress={() => void Linking.openURL("#")}
                        >
                            {t("termsAndConditions")}
                        </Text>{" "}
                    </Text>
                </Checkbox>
                <Checkbox
                    value="privacy"
                    isChecked={privacyAccepted}
                    onChange={setPrivacyAccepted}
                >
                    <Text fontWeight="medium">
                        {t("readAndAcknowledge")}:{" "}
                        <Text
                            color="green.500"
                            underline
                            onPress={() => void Linking.openURL("#")}
                        >
                            {t("privacyPolicy")}
                        </Text>{" "}
                        {t("consentToPersonalData")}
                    </Text>
                </Checkbox>
            </Box>
        </PreOpWrapper>
    );
};

export default OnboardingConfirmation;
