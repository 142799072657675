import type { MetricType } from "@madmedical/medical";
import type { GraphProps } from "../types";
import type { Trend } from "../../providers/prediction";
import GraphPredictionWrapper from "./GraphPredictionWrapper";
import { ChartWidget } from "./model";
import GraphThumbnailWrapper from "./GraphThumbnailWrapper";
import GraphWideWrapper from "./GraphWideWrapper";

interface Props {
    metricType: MetricType;
    widget: ChartWidget;
    displayLast: string | null;
    predictionTrend?: Trend;
    graphProps: Pick<GraphProps, "type" | "unit" | "limits" | "coords">;
}

const GraphWrapper = ({
    widget,
    displayLast,
    predictionTrend,
    ...props
}: Props) =>
    predictionTrend ? (
        <GraphPredictionWrapper {...props} predictionTrend={predictionTrend} />
    ) : widget === ChartWidget.Page ? (
        <GraphWideWrapper {...props} />
    ) : (
        <GraphThumbnailWrapper
            widget={widget}
            displayLast={displayLast}
            {...props}
        />
    );

export default GraphWrapper;
