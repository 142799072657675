import { useCallback, useMemo } from "react";
import { PatientSelect as PatientSelectUi } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { invariant } from "@madmedical/utils";
import useRouteNavigate from "../routing/useRouteNavigate";
import useMe from "../useMe";
import { useRepresentedPatient } from "../representedPatient";

const PatientSelect = () => {
    const { patient, representative } = useMe();
    const { representedPatient, setRepresentedPatient } =
        useRepresentedPatient();
    const navigate = useRouteNavigate();

    const patients = useMemo(() => {
        const patients = representative?.representedPatients.slice() ?? [];
        if (patient) {
            patients.unshift(patient);
        }

        return patients;
    }, [representative, patient]);

    const handleChange = useCallback(
        (patientId: Ulid) => {
            const selected = patients.find(
                (patient) => patient.userId === patientId
            );
            invariant(selected);

            setRepresentedPatient(selected);
            navigate("dashboard", {});
        },
        [patients, setRepresentedPatient, navigate]
    );

    if (!representedPatient) {
        return null;
    }

    return (
        <PatientSelectUi
            selectedPatient={representedPatient}
            me={patient}
            patients={patients}
            onChange={handleChange}
        />
    );
};

export default PatientSelect;
