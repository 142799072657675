import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { MetricType } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type {
    FilterItem,
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "./model";

enum DocumentService {
    Pregnancy = "pregnancy",
    Manager = "manager",
    PrePostOp = "pre_post_op",
    Basic = "basic",
}

export interface DocumentGeneral {
    id: string;
    filename: string;
    size: number;
    mimeType: string;
    note: string | null;
    createdAt: Date;
    previewUrl: string;
    downloadUrl: string;
    service?: {
        value: DocumentService;
        forHumans: string;
    };
}

export interface DocumentEvaluation extends DocumentGeneral {
    metricType: MetricType;
    doctor: {
        fullName: string;
        avatarUrl?: string;
        initials: string;
    };
    patient: {
        fullName: string;
    };
}

export type Document = DocumentGeneral | DocumentEvaluation;

type FilterChoices = {
    readonly metricTypes: FilterItem<MetricType>[];
    readonly doctors: FilterItem<Ulid>[];
};

interface ContextValue<TDocument extends Document = Document> {
    documents: TDocument[];
    onSortChange: OnSortChange | undefined;
    onSearchChange: OnSearchChange | undefined;
    filterChoices: FilterChoices;
    onFilterChange: OnFilterChange<FilterChoices> | undefined;
    stats: {
        totalWithOpinionCount: number;
        totalWithoutOpinionCount: number;
    };
    pregnancyDocuments?: TDocument[];
}

const DocumentContext = createContext<ContextValue | undefined>(undefined);

export const DocumentProvider =
    DocumentContext.Provider as ReactProvider<ContextValue>;

export const useDocuments = <
    TType extends "general" | "evaluations" | "both",
    TDocument extends Document = TType extends "general"
        ? DocumentGeneral
        : DocumentEvaluation
>(
    type: TType
): ContextValue<TDocument> => {
    const { documents, pregnancyDocuments, ...rest } =
        useEnsureContext(DocumentContext);

    return {
        documents: documents.filter((document) => {
            switch (type) {
                case "evaluations":
                    return "metricType" in document;
                case "general":
                    return !("metricType" in document);
                case "both":
                    return true;
            }
        }) as TDocument[],
        pregnancyDocuments: pregnancyDocuments as TDocument[],
        ...rest,
    };
};
