import Svg, { Path, Rect } from "react-native-svg";

const Poppy = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M25.5 91.5L44.6706 73.3474L40.2755 69.219L21.436 87.1652L25.5 91.5Z"
            fill="#7B5F5E"
        />
        <Path
            d="M24 89.9L42.7532 71.5465L40.2755 69.219L21.436 87.1652L24 89.9Z"
            fill="#AA8D8C"
        />
        <Path
            d="M70.3332 33.8566L57.81 33.7982C49.9101 33.7599 42.5952 37.9623 38.6454 44.8034L38.4022 45.2247C35.5917 50.0925 34.8457 55.7628 36.2746 61.0869C36.3143 61.2348 36.3539 61.3827 36.3935 61.5306C37.8343 66.9032 41.3399 71.4894 46.1465 74.2898L46.3945 74.4348C51.8341 77.6044 58.2532 78.654 64.4184 77.3846L64.8875 77.2876C70.3942 76.1544 75.0691 72.7743 77.8209 67.983L77.9503 67.7588C80.6548 63.0526 81.988 57.6834 81.7969 52.2585L81.5692 45.7797L90.1693 42.4207C90.6611 42.2281 91.0718 41.8745 91.3354 41.4179C91.9772 40.3062 91.5964 38.885 90.4848 38.2432C89.2196 37.5128 87.6504 37.5599 86.4301 38.3647L85.4923 38.9848L87.176 36.0684C87.9795 34.6768 87.5028 32.8976 86.1112 32.0942C85.1572 31.5434 83.9732 31.5792 83.0537 32.1863L81.6256 33.1284L82.3053 29.8044C82.4933 28.8856 82.0727 27.9506 81.2614 27.4822C80.4501 27.0138 79.4301 27.1171 78.7284 27.7393L76.1896 29.9899L76.5017 24.7795C76.5677 23.6796 76.0067 22.6364 75.0527 22.0856C73.6611 21.2822 71.8819 21.7589 71.0785 23.1505C70.49 24.1697 70.1898 25.33 70.2106 26.5053L70.3332 33.8566Z"
            fill="#7B9791"
        />
        <Path
            d="M75.0527 22.0856C73.6611 21.2822 71.8819 21.7589 71.0785 23.1505C70.49 24.1697 70.1898 25.33 70.2106 26.5053L70.3332 33.8566L57.81 33.7982C49.9101 33.7599 42.5952 37.9623 38.6454 44.8034L38.4022 45.2247C35.5917 50.0925 34.8457 55.7628 36.2746 61.0869C36.3143 61.2348 36.3539 61.3827 36.3935 61.5306C36.8214 63.1276 37.4528 64.6434 38.226 66.0755C39.862 68.1725 41.8879 69.9673 44.2308 71.3326L44.4773 71.4768C49.917 74.6464 56.336 75.696 62.5012 74.4266L62.9704 74.3296C68.4771 73.1964 73.152 69.8163 75.9038 65.0251L76.0332 64.8008C78.7377 60.0946 80.0709 54.7254 79.8798 49.3005L79.6535 42.8225L88.2536 39.4635C88.7453 39.2709 89.1568 38.9159 89.4204 38.4593C89.5232 38.2813 89.59 38.0934 89.6423 37.9025C88.566 37.5855 87.3916 37.7301 86.4312 38.3643L85.4923 38.9848L87.176 36.0684C87.9795 34.6768 87.5028 32.8976 86.1112 32.0942C85.1572 31.5434 83.9732 31.5792 83.0537 32.1863L81.6256 33.1284L82.3053 29.8044C82.4933 28.8856 82.0727 27.9506 81.2614 27.4822C80.4501 27.0138 79.4301 27.1171 78.7284 27.7393L76.1896 29.9899L76.5017 24.7795C76.5677 23.6796 76.0067 22.6364 75.0527 22.0856Z"
            fill="#B6D2C6"
        />
        <Path
            d="M75.0527 22.0856C73.6611 21.2822 71.8819 21.7589 71.0785 23.1505C70.49 24.1697 70.1898 25.33 70.2106 26.5053L70.3332 33.8566L71.6922 34.6418C76.1035 37.1887 79.5689 41.0969 81.5692 45.7797L90.1693 42.4207C90.6611 42.2281 91.0718 41.8745 91.3354 41.4179C91.9772 40.3062 91.5964 38.885 90.4848 38.2432C89.2196 37.5128 87.6504 37.5599 86.4301 38.3647L85.4923 38.9848L87.176 36.0684C87.9795 34.6768 87.5028 32.8976 86.1112 32.0942C85.1572 31.5434 83.9732 31.5792 83.0537 32.1863L81.6256 33.1284L82.3053 29.8044C82.4933 28.8856 82.0727 27.9506 81.2614 27.4822C80.4501 27.0138 79.4301 27.1171 78.7284 27.7393L76.1896 29.9899L76.5017 24.7795C76.5677 23.6796 76.0067 22.6364 75.0527 22.0856Z"
            fill="#7B5F5E"
        />
        <Path
            d="M86.1112 32.0942C85.1572 31.5434 83.9732 31.5792 83.0537 32.1863L81.6256 33.1284L82.3053 29.8044C82.4933 28.8856 82.0727 27.9506 81.2614 27.4822C80.4501 27.0138 79.4301 27.1171 78.7284 27.7393L76.1896 29.9899L76.5017 24.7795C76.5677 23.6796 76.0067 22.6364 75.0527 22.0856C73.6611 21.2822 71.8819 21.7589 71.0785 23.1505C70.49 24.1697 70.1898 25.33 70.2106 26.5053L70.3332 33.8566L71.6922 34.6418C75.0938 36.6057 77.9248 39.3847 79.9629 42.6995L88.2529 39.4605C88.7452 39.27 89.1568 38.9159 89.4204 38.4593C89.5232 38.2813 89.59 38.0934 89.6423 37.9025C88.566 37.5855 87.3916 37.7301 86.4312 38.3643L85.4923 38.9848L87.176 36.0684C87.9795 34.6768 87.5028 32.8976 86.1112 32.0942Z"
            fill="#AA8D8C"
        />
        <Path
            d="M65 40.0004C61.3334 38.3338 52.1178 38.001 45.9999 44.5003C41 49.8118 36.0002 64.5009 45.5003 72.0009"
            stroke="#7B9791"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <Path
            d="M61 49C56.5 49.3333 47.2 53.1 46 65.5"
            stroke="#7B9791"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <Path
            d="M73.5 44.5C72.5 52.1667 65.7 68.4 46.5 72"
            stroke="#7B9791"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <Path
            d="M74 62C72.6667 64.3333 68.1 69.8 60.5 73"
            stroke="#7B9791"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <Path
            d="M106.5 83C107.881 83 109 81.8807 109 80.5C109 79.1193 107.881 78 106.5 78C105.119 78 104 79.1193 104 80.5C104 81.8807 105.119 83 106.5 83Z"
            fill="#464156"
        />
        <Path
            d="M106 81C106.552 81 107 80.5523 107 80C107 79.4477 106.552 79 106 79C105.448 79 105 79.4477 105 80C105 80.5523 105.448 81 106 81Z"
            fill="#B5AFD8"
        />
        <Path
            d="M105.5 69C106.881 69 108 67.8807 108 66.5C108 65.1193 106.881 64 105.5 64C104.119 64 103 65.1193 103 66.5C103 67.8807 104.119 69 105.5 69Z"
            fill="#464156"
        />
        <Path
            d="M105 67C105.552 67 106 66.5523 106 66C106 65.4477 105.552 65 105 65C104.448 65 104 65.4477 104 66C104 66.5523 104.448 67 105 67Z"
            fill="#B5AFD8"
        />
        <Path
            d="M93.5 79C94.8807 79 96 77.8807 96 76.5C96 75.1193 94.8807 74 93.5 74C92.1193 74 91 75.1193 91 76.5C91 77.8807 92.1193 79 93.5 79Z"
            fill="#464156"
        />
        <Path
            d="M93 77C93.5523 77 94 76.5523 94 76C94 75.4477 93.5523 75 93 75C92.4477 75 92 75.4477 92 76C92 76.5523 92.4477 77 93 77Z"
            fill="#B5AFD8"
        />
        <Path
            d="M96.5 96C97.8807 96 99 94.8807 99 93.5C99 92.1193 97.8807 91 96.5 91C95.1193 91 94 92.1193 94 93.5C94 94.8807 95.1193 96 96.5 96Z"
            fill="#464156"
        />
        <Path
            d="M96 94C96.5523 94 97 93.5523 97 93C97 92.4477 96.5523 92 96 92C95.4477 92 95 92.4477 95 93C95 93.5523 95.4477 94 96 94Z"
            fill="#B5AFD8"
        />
        <Path
            d="M69.5 111C70.8807 111 72 109.881 72 108.5C72 107.119 70.8807 106 69.5 106C68.1193 106 67 107.119 67 108.5C67 109.881 68.1193 111 69.5 111Z"
            fill="#464156"
        />
        <Path
            d="M69 109C69.5523 109 70 108.552 70 108C70 107.448 69.5523 107 69 107C68.4477 107 68 107.448 68 108C68 108.552 68.4477 109 69 109Z"
            fill="#B5AFD8"
        />
        <Path
            d="M83.5 106C84.8807 106 86 104.881 86 103.5C86 102.119 84.8807 101 83.5 101C82.1193 101 81 102.119 81 103.5C81 104.881 82.1193 106 83.5 106Z"
            fill="#464156"
        />
        <Path
            d="M83 104C83.5523 104 84 103.552 84 103C84 102.448 83.5523 102 83 102C82.4477 102 82 102.448 82 103C82 103.552 82.4477 104 83 104Z"
            fill="#B5AFD8"
        />
        <Path
            d="M83.5 94C84.8807 94 86 92.8807 86 91.5C86 90.1193 84.8807 89 83.5 89C82.1193 89 81 90.1193 81 91.5C81 92.8807 82.1193 94 83.5 94Z"
            fill="#464156"
        />
        <Path
            d="M83 92C83.5523 92 84 91.5523 84 91C84 90.4477 83.5523 90 83 90C82.4477 90 82 90.4477 82 91C82 91.5523 82.4477 92 83 92Z"
            fill="#B5AFD8"
        />
        <Path
            d="M87.5 65C88.8807 65 90 63.8807 90 62.5C90 61.1193 88.8807 60 87.5 60C86.1193 60 85 61.1193 85 62.5C85 63.8807 86.1193 65 87.5 65Z"
            fill="#464156"
        />
        <Path
            d="M87 63C87.5523 63 88 62.5523 88 62C88 61.4477 87.5523 61 87 61C86.4477 61 86 61.4477 86 62C86 62.5523 86.4477 63 87 63Z"
            fill="#B5AFD8"
        />
        <Path
            d="M96.5 52C97.8807 52 99 50.8807 99 49.5C99 48.1193 97.8807 47 96.5 47C95.1193 47 94 48.1193 94 49.5C94 50.8807 95.1193 52 96.5 52Z"
            fill="#464156"
        />
        <Path
            d="M96 50C96.5523 50 97 49.5523 97 49C97 48.4477 96.5523 48 96 48C95.4477 48 95 48.4477 95 49C95 49.5523 95.4477 50 96 50Z"
            fill="#B5AFD8"
        />
        <Path
            d="M95.5 109C96.8807 109 98 107.881 98 106.5C98 105.119 96.8807 104 95.5 104C94.1193 104 93 105.119 93 106.5C93 107.881 94.1193 109 95.5 109Z"
            fill="#464156"
        />
        <Path
            d="M95 107C95.5523 107 96 106.552 96 106C96 105.448 95.5523 105 95 105C94.4477 105 94 105.448 94 106C94 106.552 94.4477 107 95 107Z"
            fill="#B5AFD8"
        />
    </Svg>
);

export default Poppy;
