import { ProviderConnectionStatus } from "@madmedical/medical";
import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import type { Device } from "../../providers/device";
import ListItem from "../../atoms/ListItem";
import AvatarTexts from "../../molecules/AvatarTexts";
import getImage from "./getImage";

interface Props {
    device: Device;
}

export const DeviceItem = ({
    device: { provider, manufacturer, deviceName, status },
}: Props) => {
    const { t } = useTranslation();

    return (
        <Box width={"full"}>
            <ListItem link={{ route: "device", params: { provider } }}>
                <AvatarTexts
                    key={provider}
                    size="xl"
                    isCircle={false}
                    source={getImage(provider)}
                    label={deviceName}
                    subText={t(`backend:${manufacturer}`)}
                    status={
                        status === ProviderConnectionStatus.Off
                            ? undefined
                            : status
                    }
                />
            </ListItem>
        </Box>
    );
};
