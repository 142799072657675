import type { ComponentProps, ReactNode } from "react";
import { Stack } from "native-base";

interface Props
    extends Pick<
        ComponentProps<typeof Stack>,
        | "justifyContent"
        | "alignItems"
        | "alignSelf"
        | "flex"
        | "minWidth"
        | "maxWidth"
        | "width"
        | "height"
        | "borderColor"
        | "borderStyle"
        | "borderColor"
        | "borderStyle"
        | "borderRadius"
        | "borderWidth"
        | "borderWidth"
        | "borderTopWidth"
        | "borderTopRadius"
        | "borderTopLeftRadius"
        | "borderTopRightRadius"
        | "borderBottomLeftRadius"
        | "borderBottomRightRadius"
        | "bgColor"
        | "direction"
        | "flexDirection"
        | "flexWrap"
        | "overflow"
        | "position"
        | "zIndex"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
        | "backgroundColor"
    > {
    children: ReactNode;
}

const Paper = ({
    children,
    alignItems = "flex-start",
    maxWidth,
    p,
    flexWrap,
    flexDirection,
    borderRadius,
    ...rest
}: Props) => (
    <Stack
        width="full"
        direction="column"
        justifyContent="flex-start"
        alignItems={alignItems}
        borderColor="gray.100"
        borderWidth={1}
        borderStyle="solid"
        backgroundColor="white"
        maxWidth={maxWidth}
        p={p}
        flexWrap={flexWrap}
        flexDirection={flexDirection}
        borderRadius={borderRadius ? borderRadius : "lg"}
        {...rest}
    >
        {children}
    </Stack>
);

export default Paper;
