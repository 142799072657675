import { MetricType } from "@madmedical/medical";
import { usePatientId } from "@madmedical/user";
import {
    BodyTemperatureWidget,
    ChartWidget,
    DailyStepsWidget,
    EmptyDashboardWidget,
    SleepTimeWidget,
} from "@madmedical/ui";
import { rangeToDate } from "@madmedical/utils";
import { useEffect } from "react";
import { useGetThresholdsForChartQuery } from "@madmedical/threshold";
import { useTranslation } from "@madmedical/i18n";
import { useGetMeasurementRangeQuery } from "../measurement";
import Chart from "./Chart";

interface Props {
    metricType: MetricType;
    onLoadingFinished?: (metricType: MetricType) => void;
}

/**
 * A single widget with a week of metrics if available
 */
const DashboardWidget = ({ metricType, onLoadingFinished }: Props) => {
    const { patientId } = usePatientId();
    const { data, isLoading } = useGetMeasurementRangeQuery({
        userId: patientId,
        metricType,
    });
    const { data: thresholds, isLoading: isThresholdsLoading } =
        useGetThresholdsForChartQuery(
            { patientId, metricType },
            { skip: metricType !== MetricType.DailySteps }
        );

    useEffect(() => {
        if (!isLoading && !isThresholdsLoading) {
            onLoadingFinished?.(metricType);
        }
    }, [isLoading, isThresholdsLoading, onLoadingFinished, metricType]);

    const showAlways = [
        MetricType.BloodSugar,
        MetricType.BloodPressure,
        MetricType.BloodOxygen,
        MetricType.BodyHeight,
        MetricType.BodyTemperature,
        MetricType.BodyWeight,
    ];

    const { t } = useTranslation();
    if (!data?.items.length || isThresholdsLoading) {
        if (showAlways.includes(metricType)) {
            return <EmptyDashboardWidget metricType={metricType} />;
        }

        return null;
    }

    const {
        metric: lastMetric,
        alertLevel: lastAlertLevel,
        measuredAt: lastMeasuredAt,
    } = data.items[data.items.length - 1];

    if (metricType === MetricType.SleepTime) {
        const displayParts = lastMetric.displayValue.split(":");

        return (
            <SleepTimeWidget
                value={[displayParts[0], displayParts[1]]}
                unit={[t("hour"), t("minute")]}
                alertLevel={lastAlertLevel}
                measuredAt={new Date(lastMeasuredAt)}
            />
        );
    }

    if (metricType === MetricType.BodyTemperature) {
        const displayParts = lastMetric.displayValue.split("°");

        return (
            <BodyTemperatureWidget
                value={displayParts[0]}
                unit={data.stats.unit}
                alertLevel={lastAlertLevel}
                measuredAt={new Date(lastMeasuredAt)}
            />
        );
    }

    if (metricType === MetricType.DailySteps && "steps" in lastMetric) {
        if (!thresholds?.length) {
            return null;
        }
        const threshold = thresholds[0];

        return (
            <DailyStepsWidget
                steps={lastMetric.steps}
                warningThreshold={threshold.warningThreshold}
                alertThreshold={threshold.alertThreshold}
                measuredAt={new Date(lastMeasuredAt)}
            />
        );
    }

    return (
        <Chart
            metricType={metricType}
            dateRange={rangeToDate(data.stats.dateRange)}
            data={data}
            widget={ChartWidget.DashboardSingle}
        />
    );
};

export default DashboardWidget;
