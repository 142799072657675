import { Box, Divider, Stack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import DeviceCard from "../../organisms/DeviceCard";
import { useDevices } from "../../providers/device";
import ButtonLink from "../../atoms/ButtonLink";

const Devices = () => {
    const { devices } = useDevices();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return devices.length ? (
        <Paper>
            <Headline
                title={t("devices")}
                size="xs"
                py={isSmallScreen ? 0 : 6}
                rightContent={
                    <ButtonLink
                        size="sm"
                        variant="link"
                        p={0}
                        route="devices_connected"
                        params={{}}
                    >
                        {t("editDevices")}
                    </ButtonLink>
                }
            />

            <Stack
                direction={{
                    md: "column",
                    lg: "row",
                }}
                p={4}
                width="full"
                space={isSmallScreen ? 2 : 0}
                divider={isSmallScreen ? <Divider /> : undefined}
                flexWrap="wrap"
            >
                {devices.map((device) => (
                    <Box
                        key={device.provider}
                        width={{
                            md: "full",
                            lg: "1/2",
                            xl: "1/3",
                        }}
                        // px={isSmallScreen ? 4 : 0}
                        p={1}
                    >
                        <DeviceCard device={device} />
                    </Box>
                ))}
            </Stack>
        </Paper>
    ) : null;
};

export default Devices;
