import type { ComponentProps } from "react";
import type { Column, IdType } from "react-table";
import { useSortBy, useTable } from "react-table";
import { useEffect, useState } from "react";
import { Box, HStack, VStack, useTheme } from "native-base";
import { useInView } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import type { OnSortChange } from "../providers/model";
import Icon from "../icons/Icon";
import useResponsive from "../util/useResponsive";
import EmptyState from "../atoms/EmptyState";
import Spinner from "../atoms/Spinner";
import Table, { Tbody, Td, Tfoot, Th, Thead, Tr } from "./Table";

interface Props<Data extends object> {
    columns: Column<Data>[];
    data: Data[];
    onPress?: (v: Data) => void;
    onSortChange?: OnSortChange;
    onEndReached?: () => unknown;
    hiddenColumns?: IdType<Data>[];
    scrollX?: boolean;
    emptyState?: ComponentProps<typeof EmptyState>;
}

const ReactTable = <Data extends object>({
    columns,
    data,
    onPress,
    onSortChange,
    onEndReached,
    scrollX = false,
    emptyState,
    hiddenColumns = [],
    ...rest
}: Props<Data>) => {
    const { colors } = useTheme();
    const { isSmallScreen } = useResponsive();
    const [bottomRef, setBottomRef] = useState<HTMLElement | null>(null);
    const isFetching = useInView({
        element: bottomRef,
        onReached: onEndReached,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            manualSortBy: !!onSortChange,
            disableMultiSort: true,
            initialState: {
                hiddenColumns,
            },
        },
        useSortBy
    );
    const { t } = useTranslation();
    useEffect(() => {
        if (!sortBy.length || !onSortChange) {
            return;
        }
        const { id, desc } = sortBy[0];

        onSortChange(id, !desc ? "ASC" : "DESC");
    }, [onSortChange, sortBy]);

    const emptyTitle = t("measures:noData");
    const emptyMessage =
        columns?.[0]?.accessor === "firstOccurrence"
            ? t("measures:tryAgainOrSetupAnotherReminder")
            : t("noResultsAfterFilter");

    if (isSmallScreen) return null;

    if (data.length === 0) {
        return (
            <EmptyState
                title={emptyState?.title ?? emptyTitle}
                message={emptyState?.message ?? emptyMessage}
                my={4}
            />
        );
    }

    return (
        // Render the UI for your table
        <Box overflowX={scrollX ? "auto" : undefined}>
            <Table {...getTableProps()} {...rest} scrollX={scrollX}>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`react-table-thead-tr-${index}`}
                        >
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps
                                    )}
                                    key={`react-table-thead-tr-th-${index}`}
                                    style={{
                                        width: column.width
                                            ? column.width
                                            : undefined,
                                    }}
                                >
                                    <HStack>
                                        <Box _text={{ color: "gray.600" }}>
                                            {column.render("Header")}
                                        </Box>
                                        {column.defaultCanSort && (
                                            <VStack
                                                nativeID={`sort-icons-${index}`}
                                                ml={2}
                                            >
                                                <Icon
                                                    name="chevronUp"
                                                    size={12}
                                                    fill={
                                                        column.isSorted &&
                                                        !column.isSortedDesc
                                                            ? colors
                                                                  .primary[500]
                                                            : colors.gray[600]
                                                    }
                                                />
                                                <Icon
                                                    name="chevronDown"
                                                    size={12}
                                                    fill={
                                                        column.isSorted &&
                                                        column.isSortedDesc
                                                            ? colors
                                                                  .primary[500]
                                                            : colors.gray[600]
                                                    }
                                                />
                                            </VStack>
                                        )}
                                    </HStack>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);

                        return (
                            <Tr
                                {...row.getRowProps()}
                                key={`react-table-tbody-tr-${rowIndex}`}
                                onClick={() => {
                                    onPress?.(row.original);
                                }}
                                hasOnHover={!!onPress}
                            >
                                {row.cells.map((cell, cellIndex) => (
                                    <Td
                                        {...cell.getCellProps()}
                                        key={`react-table-tbody-tr-td-${cellIndex}`}
                                        style={{
                                            width: cell.column.width
                                                ? cell.column.width
                                                : undefined,
                                            minWidth:
                                                cell.column.minWidth &&
                                                cell.column.minWidth > 10
                                                    ? cell.column.minWidth
                                                    : undefined,
                                            maxWidth:
                                                cell.column.maxWidth &&
                                                cell.column.maxWidth > 10
                                                    ? cell.column.maxWidth
                                                    : undefined,
                                        }}
                                    >
                                        <Box
                                            flexDirection="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            {cell.render("Cell")}
                                        </Box>
                                    </Td>
                                ))}
                            </Tr>
                        );
                    })}
                </Tbody>
                {onEndReached && (
                    <Tfoot ref={setBottomRef}>
                        {isFetching && <Spinner />}
                    </Tfoot>
                )}
            </Table>
        </Box>
    );
};

export default ReactTable;
