import fonts, { fontConfig } from "./fonts";

const typography = {
    fontConfig: {
        ...fontConfig,
    },
    fonts: {
        ...fonts,
    },
    fontSizes: {
        "2xs": 10,
        xs: 12,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 20,
        "2xl": 22,
        "3xl": 24,
        "4xl": 28,
        "5xl": 32,
        "6xl": 48,
        "7xl": 60,
        "8xl": 72,
        "9xl": 96,
        "10xl": 128,
    },
    letterSpacings: {
        xs: "-0.05em",
        sm: "-0.025em",
        md: 0,
        lg: "0.025em",
        xl: "0.05em",
        "2xl": "0.1em",
    },
    lineHeights: {
        "2xs": "1em",
        xs: "1.125em",
        sm: "1.25em",
        md: "1.375em",
        lg: "1.5em",
        xl: "1.75em",
        "2xl": "2em",
        "3xl": "2.5em",
        "4xl": "3em",
        "5xl": "4em",
    },
    fontWeights: {
        hairline: "100",
        thin: "200",
        light: "300",
        normal: "400",
        medium: "500",
        semibold: "600",
        bold: "700",
        extrabold: "800",
        black: "900",
        extraBlack: "950",
    },
};

export default typography;
