import { AlertLevel, forHumans, isDaily } from "@madmedical/medical";
import { Box, HStack, Text, VStack } from "native-base";
import { dateToRange, formatDateRange, rangeToString } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../../atoms/PressableLink";
import type { PatientMeasurements } from "../../providers/patientMeasurements";
import type { Period } from "../DatePeriodStepper";
import StatusBadge from "../../atoms/StatusBadge";
import Badge from "../../atoms/Badge";
import Tag from "../../atoms/Tag";
import Bar from "../../atoms/Bar";

interface Props {
    data: PatientMeasurements;
    period: Period;
}

const PatientMeasurementListItem = ({
    data: {
        patient,
        dateTrunc,
        dateRange,
        measurementCount,
        metricType,
        displayRange,
        worstAlertLevel,
        alertLevelCounts,
    },
    period,
}: Props) => {
    const { t } = useTranslation();

    return (
        <PressableLink
            route="measurements"
            params={{ patientId: patient.userId, metricType }}
            search={rangeToString(dateToRange(dateTrunc, period))}
        >
            <HStack
                space={4}
                justifyContent="space-between"
                alignItems="center"
                bgColor="white"
                px={4}
                py={5.5}
                borderBottomColor="gray.100"
                borderBottomWidth={1}
                borderBottomStyle="solid"
                width="full"
            >
                <VStack width="full">
                    <HStack justifyContent="space-between" alignItems="center">
                        <Text fontWeight="medium">{patient.fullName}</Text>
                        <Box flexDirection="row" alignItems="center" ml="auto">
                            <Text>
                                {formatDateRange(
                                    dateRange,
                                    period === "day" && !isDaily(metricType)
                                        ? "time"
                                        : "long"
                                )}
                            </Text>
                            <Box>
                                <Badge
                                    size="xs"
                                    ml={1}
                                    hasIndicator={false}
                                    variant="base"
                                >
                                    {measurementCount}
                                </Badge>
                            </Box>
                        </Box>
                    </HStack>

                    <HStack space={1.5} alignItems="center" mt={1}>
                        <Tag size="lg">
                            {t(`measures:${forHumans(metricType)}`)}
                        </Tag>
                        <Text
                            lineHeight={{
                                base: "initial",
                                md: 1,
                            }}
                            fontSize="xs"
                            color="gray.600"
                        >
                            {displayRange}
                        </Text>
                        {period !== "month" ? (
                            <StatusBadge
                                size="lg"
                                level={worstAlertLevel}
                                hasText={false}
                            />
                        ) : (
                            <HStack alignItems="center" width={36}>
                                <Bar
                                    maxWidth={15}
                                    height={5}
                                    data={[
                                        {
                                            color: "green.500",
                                            value: alertLevelCounts[
                                                AlertLevel.Ok
                                            ],
                                        },
                                        {
                                            color: "orange.500",
                                            value: alertLevelCounts[
                                                AlertLevel.Warning
                                            ],
                                        },
                                        {
                                            color: "rose.500",
                                            value: alertLevelCounts[
                                                AlertLevel.Alert
                                            ],
                                        },
                                    ]}
                                />
                                <Text ml={2} fontSize="xs" color="gray.500">
                                    (
                                    {Object.values(alertLevelCounts).reduce(
                                        (acc, value) => acc + value,
                                        0
                                    )}
                                    )
                                </Text>
                            </HStack>
                        )}
                    </HStack>
                </VStack>
            </HStack>
        </PressableLink>
    );
};

export default PatientMeasurementListItem;
