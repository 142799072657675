import type { BaseFilterChoices, FilterItem } from "../../providers/model";

export const hasActiveFilter = (
    filterChoices: Record<string, FilterItem<string | number>[]>
) =>
    Object.values(filterChoices).some((items) =>
        items.some(({ isSelected }) => isSelected)
    );

export const extractSelected = <
    TChoices extends BaseFilterChoices,
    TKey extends keyof TChoices
>(
    filterChoices: TChoices,
    key: TKey
) =>
    filterChoices[key]
        .filter(({ isSelected }) => isSelected)
        .map(({ key }) => key) as TChoices[TKey][0]["key"][];
