import { useEffect, useState } from "react";

export default <TValue>(
    value: TValue,
    delay: number,
    sideEffectCallback?: () => unknown
): TValue => {
    const [debouncedValue, setDebouncedValue] = useState<TValue>(value);

    useEffect(() => {
        if (value === debouncedValue) {
            return;
        }

        const timer = setTimeout(() => {
            setDebouncedValue(value);
            sideEffectCallback?.();
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, debouncedValue, delay, sideEffectCallback]);

    return debouncedValue;
};
