import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import useResponsive from "../../util/useResponsive";

interface Props {
    data?: string;
}

const MedicalAdviceSection = ({ data }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    if (!data) return null;

    return (
        <Box width="full">
            {isSmallScreen && (
                <ListHeader
                    title={t("doctor:opinion")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}
            <Paper borderRadius={{ base: "lg", md: "none" }}>
                {!isSmallScreen && (
                    <Headline title={t("doctor:opinion")} size="xs" />
                )}
                <Box
                    px={{
                        base: 4,
                        md: 6,
                    }}
                    py={4}
                >
                    {data ?? "-"}
                </Box>
            </Paper>
        </Box>
    );
};

export default MedicalAdviceSection;
