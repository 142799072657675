import type { ComponentProps } from "react";
import { Box, Text } from "native-base";
import { useAppConfig } from "@madmedical/config";
import { useTranslation } from "@madmedical/i18n";
import openExternalUrl from "../util/openExternalUrl";
import Alert from "../molecules/Alert";

interface Props extends ComponentProps<typeof Box> {
    onDismiss: () => void;
}

const AppDownloadAlert = ({ onDismiss, ...boxProps }: Props) => {
    const { appStoreUrl, googlePlayUrl } = useAppConfig();
    const { t } = useTranslation();
    const handleStorePress = (platform: "ios" | "android") => () => {
        openExternalUrl(platform === "ios" ? appStoreUrl : googlePlayUrl);
    };

    return (
        <Box {...boxProps}>
            <Alert
                status="info"
                title={t("connectDevice")}
                description={t("connectDeviceWithTdoc")}
                onDismiss={onDismiss}
                links={[
                    { text: "App Store", onPress: handleStorePress("ios") },
                    {
                        text: "Google Play",
                        onPress: handleStorePress("android"),
                    },
                ]}
                linkSeparator={
                    <Text fontSize="sm" color="gray.500" alignSelf="flex-end">
                        {t("or")}
                    </Text>
                }
            />
        </Box>
    );
};

export default AppDownloadAlert;
