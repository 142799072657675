import { Box } from "native-base";
import Placeholder from "../../pics/Pregnancy/fruits/Placeholder";
import Poppy from "../../pics/Pregnancy/fruits/Poppy";
import Sesame from "../../pics/Pregnancy/fruits/Sesame";
import Pea from "../../pics/Pregnancy/fruits/Pea";
import Blueberry from "../../pics/Pregnancy/fruits/Blueberry";
import Raspberry from "../../pics/Pregnancy/fruits/Raspberry";
import Cherry from "../../pics/Pregnancy/fruits/Cherry";
import Olive from "../../pics/Pregnancy/fruits/Olive";
import Figs from "../../pics/Pregnancy/fruits/Figs";
import Lime from "../../pics/Pregnancy/fruits/Lime";
import Peach from "../../pics/Pregnancy/fruits/Peach";
import Lemon from "../../pics/Pregnancy/fruits/Lemon";
import Apple from "../../pics/Pregnancy/fruits/Apple";
import Avocado from "../../pics/Pregnancy/fruits/Avocado";
import Pear from "../../pics/Pregnancy/fruits/Pear";
import CalifornianPaprika from "../../pics/Pregnancy/fruits/CalifornianPaprika";
import Pommegranate from "../../pics/Pregnancy/fruits/Pommegranate";
import Artichoke from "../../pics/Pregnancy/fruits/Artichoke";
import Corn from "../../pics/Pregnancy/fruits/Corn";
import Grape from "../../pics/Pregnancy/fruits/Grape";
import Mango from "../../pics/Pregnancy/fruits/Mango";
import Broccoli from "../../pics/Pregnancy/fruits/Broccoli";
import SweetPotato from "../../pics/Pregnancy/fruits/SweetPotato";
import Zucchini from "../../pics/Pregnancy/fruits/Zucchini";
import Papaya from "../../pics/Pregnancy/fruits/Papaya";
import YellowMelon from "../../pics/Pregnancy/fruits/YellowMelon";
import Pomelo from "../../pics/Pregnancy/fruits/Pomelo";
import Cauliflower from "../../pics/Pregnancy/fruits/Cauliflower";
import GreenCoco from "../../pics/Pregnancy/fruits/GreenCoco";
import Banana from "../../pics/Pregnancy/fruits/Banana";
import Pineaple from "../../pics/Pregnancy/fruits/Pineaple";
import Salad from "../../pics/Pregnancy/fruits/Salad";
import IcicleRaddish from "../../pics/Pregnancy/fruits/IcicleRaddish";
import Pumpkin from "../../pics/Pregnancy/fruits/Pumpkin";
import RedCabbage from "../../pics/Pregnancy/fruits/RedCabbage";
import SavoyCabbage from "../../pics/Pregnancy/fruits/SavoyCabbage";
import Watermelon from "../../pics/Pregnancy/fruits/Watermelon";
import HalloweenPumpkin from "../../pics/Pregnancy/fruits/HalloweenPumpkin";

interface PregnancyDataImageProps {
    week: number; // from 1
}

const PregnancyDataImage = ({ week }: PregnancyDataImageProps) => {
    switch (true) {
        case week < 4:
            return <Placeholder />;
        case week === 4:
            return <Poppy />;
        case week === 5:
            return <Sesame />;
        case week === 6:
            return <Pea />;
        case week === 7:
            return <Blueberry />;
        case week === 8:
            return <Raspberry />;
        case week === 9:
            return <Cherry />;
        case week === 10:
            return <Olive />;
        case week === 11:
            return <Figs />;
        case week === 12:
            return <Lime />;
        case week === 13:
            return <Peach />;
        case week === 14:
            return <Lemon />;
        case week === 15:
            return <Apple />;
        case week === 16:
            return <Avocado />;
        case week === 17:
            return <Pear />;
        case week === 18:
            return <CalifornianPaprika />;
        case week === 19:
            return <Pommegranate />;
        case week === 20:
            return <Artichoke />;
        case week === 21:
            return <Corn />;
        case week === 22:
            return <Grape />;
        case week === 23:
            return <Mango />;
        case week === 24:
            return <Broccoli />;
        case week === 25:
            return <SweetPotato />;
        case week === 26:
            return <Zucchini />;
        case week === 27:
            return <Papaya />;
        case week === 28:
            return <YellowMelon />;
        case week === 29:
            return <Pomelo />;
        case week === 30:
            return <Cauliflower />;
        case week === 31:
            return <GreenCoco />;
        case week === 32:
            return <Banana />;
        case week === 33:
            return <Pineaple />;
        case week === 34:
            return <Salad />;
        case week === 35:
            return <IcicleRaddish />;
        case week === 36:
            return <Pumpkin />;
        case week === 37:
            return <RedCabbage />;
        case week === 38:
            return <SavoyCabbage />;
        case week === 39:
            return <Watermelon />;
        case week >= 40:
            return <HalloweenPumpkin />;
        default:
            return (
                <Box
                    width={130}
                    height={130}
                    borderRadius={65}
                    bgColor="white"
                />
            );
    }
};

export default PregnancyDataImage;
