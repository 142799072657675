import { useMediaQuery, useTheme } from "native-base";
import { isWeb } from "@madmedical/utils";

export default (minWidth?: number, maxWidth?: number) => {
    const { breakpoints } = useTheme();
    let [isSmallScreen] = useMediaQuery({
        minWidth: minWidth ?? breakpoints.base,
        maxWidth: maxWidth ?? breakpoints.md,
    });
    let [isMediumScreen] = useMediaQuery({
        minWidth: minWidth ?? breakpoints.base,
        maxWidth: maxWidth ?? breakpoints.xl,
    });

    if (!isWeb) {
        // It's a temporary workaround to render the small screen version
        // of the UI elements and layouts on non-web platforms until the
        // native compatible versions are built.
        isSmallScreen = true;
        isMediumScreen = true;
    }

    return { isSmallScreen, isMediumScreen };
};
