import { useCallback, useEffect, useRef } from "react";

// Use the right version (browser vs node)
const { setTimeout, clearTimeout } = window;

export default (
    callback: () => unknown,
    delayOrDate: number | Date | null,
    autostart = false
) => {
    const savedCallback = useRef(callback);
    const timerId = useRef<number>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const start = useCallback(() => {
        if (delayOrDate === null) {
            return;
        }

        const delay =
            typeof delayOrDate === "number"
                ? delayOrDate
                : delayOrDate.getTime() - Date.now();

        // Second-level safe-guarding against infinite loops by setting minimum delay.
        timerId.current = setTimeout(
            () => savedCallback.current(),
            Math.max(delay, 10)
        );
    }, [delayOrDate]);

    const clear = useCallback(() => {
        if (!timerId.current) {
            return;
        }

        clearTimeout(timerId.current);
        timerId.current = undefined;
    }, []);

    const restart = useCallback(() => {
        clear();
        start();
    }, [clear, start]);

    useEffect(() => {
        if (!autostart) {
            return;
        }

        start();

        return clear;
    }, [autostart, clear, start]);

    return { start, clear, restart };
};
