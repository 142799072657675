import type { ComponentProps, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { Badge as BaseBadge, Box, Text, useTheme } from "native-base";
import Icon from "../icons/Icon";
import Avatar from "../atoms/Avatar/Avatar";

type IconName = ComponentProps<typeof Icon>["name"];

interface Props
    extends Pick<
        ComponentProps<typeof BaseBadge>,
        | "pointerEvents"
        | "opacity"
        | "position"
        | "top"
        | "right"
        | "borderColor"
        | "borderWidth"
        | "bgColor"
        | "_text"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
    variant?:
        | "success"
        | "ghostSuccess"
        | "base"
        | "ghost"
        | "dark"
        | "light"
        | "error"
        | "ghostError"
        | "warning"
        | "ghostWarning";
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
    hasIndicator?: boolean;
    leftIconName?: IconName;
    rightIconName?: IconName;
    avatar?: ComponentProps<typeof Avatar>["source"];
    hasText?: boolean;
}

const Badge = ({
    variant = "success",
    size = "xs",
    hasIndicator = true,
    hasText = true,
    children,
    leftIconName,
    rightIconName,
    avatar,
    ...rest
}: Props) => {
    const { colors } = useTheme();

    const indicatorVariants = useMemo(
        () => ({
            success: colors.green[300],
            ghostSuccess: colors.green[300],
            base: colors.green[300],
            ghost: colors.gray[500],
            dark: colors.green[500],
            light: colors.green[500],
            error: colors.rose[200],
            ghostError: colors.rose[500],
            warning: colors.yellow[200],
            ghostWarning: colors.yellow[500],
        }),
        [colors]
    );

    const gaps = useMemo(
        () => ({
            xs: "1",
            sm: "1",
            md: "1",
            lg: "1.5",
            xl: "1.5",
            xxl: "1.5",
        }),
        []
    );

    const minWidths = useMemo(
        () => ({
            xs: "4.5",
            sm: "5.5",
            md: "5.5",
            lg: "6",
            xl: "7",
            xxl: "8",
        }),
        []
    );

    const iconVariants = useMemo(
        () => ({
            success: colors.white,
            ghostSuccess: colors.green[600],
            base: colors.white,
            ghost: colors.gray[600],
            dark: colors.white,
            light: colors.black,
            error: colors.white,
            ghostError: colors.rose[500],
            warning: colors.white,
            ghostWarning: colors.yellow[600],
        }),
        [colors]
    );

    const avatarPaddings = useMemo(
        () => ({
            xs: {
                p: 0,
            },
            sm: {
                p: 0.5,
                pr: 1.5,
            },
            md: {
                p: 0.75,
                pr: 2,
            },
            lg: {
                p: 1,
                pr: 2,
            },
            xl: {
                p: 1.5,
                pr: 2,
            },
            xxl: {
                p: 1.5,
                pr: 2,
            },
        }),
        []
    );

    const renderLeftIcon = useCallback(() => {
        if (avatar) {
            if (size === "xs") {
                return;
            }

            return <Avatar size="2xs" source={avatar} />;
        }

        if (hasIndicator) {
            return (
                <Box mr={gaps[size]}>
                    <Icon
                        name="circleSolid"
                        fill={indicatorVariants[variant]}
                        size={6}
                    />
                </Box>
            );
        }

        if (leftIconName) {
            return (
                <Box mr={hasText ? gaps[size] : 0}>
                    <Icon
                        name={leftIconName}
                        fill={iconVariants[variant]}
                        size={11}
                    />
                </Box>
            );
        }

        return undefined;
    }, [
        avatar,
        gaps,
        hasIndicator,
        hasText,
        indicatorVariants,
        iconVariants,
        leftIconName,
        size,
        variant,
    ]);

    return (
        <BaseBadge
            leftIcon={renderLeftIcon()}
            rightIcon={
                rightIconName && (
                    <Box ml={gaps[size]}>
                        {
                            <Icon
                                name={rightIconName}
                                fill={iconVariants[variant]}
                                size={12}
                            />
                        }
                    </Box>
                )
            }
            variant={variant}
            size={size}
            minWidth={minWidths[size]}
            alignItems="center"
            justifyContent="center"
            {...(avatar && size !== "xs" ? avatarPaddings[size] : undefined)}
            {...rest}
        >
            <Text color={iconVariants[variant]} fontSize="xs">
                {children}
            </Text>
        </BaseBadge>
    );
};

export default Badge;
