import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import type { CellProps, Column } from "react-table";
import {
    ProviderConnectionStatus,
    forHumans,
    getServiceColour,
    logisticsForHumans,
    serviceForHumans,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../../providers/routing";
import Avatar from "../../atoms/Avatar/Avatar";
import Headline from "../../molecules/Headline";
import Breadcrumb from "../../molecules/Breadcrumb";
import Paper from "../../atoms/Paper";
import SimpleListItem from "../../molecules/ListItem/SimpleListItem";
import Tag from "../../atoms/Tag";
import Badge from "../../atoms/Badge";
import ActionButton from "../../molecules/ActionButton";
import ReactTable from "../../molecules/ReactTable";
import { useCurrentPatient } from "../../providers/currentPatient";
import type { LogisticsDevice } from "../../providers/callCenterDevice";

const MAX_TAGS = 3;

interface Props {
    devices: LogisticsDevice[];
    onEditPress: (id: string) => unknown;
}

const CallCenterPatient = ({ devices, onEditPress }: Props) => {
    const { patient } = useCurrentPatient();
    const navigate = useRouteNavigate();

    const handleRowPress = ({ id }: LogisticsDevice) => {
        navigate("callcenter_device", { deviceId: id });
    };
    const { t } = useTranslation();
    const columns = useMemo<Column<LogisticsDevice>[]>(
        () => [
            {
                Header: t("serialNumber"),
                accessor: "serialNumber",
                width: "10%",
            },
            {
                Header: t("device"),
                id: "deviceTypeName",
                width: "auto",
                Cell: ({
                    row: {
                        original: { deviceType },
                    },
                }: CellProps<LogisticsDevice>) => <>{deviceType.name}</>,
            },
            {
                Header: t("measures:type"),
                id: "metricTypes",
                width: "12%",
                Cell: ({
                    row: {
                        original: {
                            deviceType: { metricTypes },
                        },
                    },
                }: CellProps<LogisticsDevice>) => {
                    const count = metricTypes.length;
                    if (!count) {
                        return <Text>-</Text>;
                    }

                    const types = metricTypes.slice(0, MAX_TAGS);
                    const remains = count - types.length;

                    return (
                        <>
                            {types.map((metricType) => (
                                <Tag key={metricType} mr={2}>
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Tag>
                            ))}
                            {remains ? (
                                <Tag key={remains} mr={2}>
                                    +{remains}
                                </Tag>
                            ) : null}
                        </>
                    );
                },
            },
            {
                Header: t("logistics"),
                accessor: "status",
                width: "12%",
                Cell: ({ value: status }) => (
                    <Text>{logisticsForHumans(status)}</Text>
                ),
            },
            {
                Header: t("connectionState"),
                accessor: "connectionStatus",
                width: "12%",
                Cell: ({ value: connectionStatus }) => {
                    let badgeData: {
                        variant: ComponentProps<typeof Badge>["variant"];
                        text: string;
                    } | null = null;

                    switch (connectionStatus) {
                        case ProviderConnectionStatus.Active:
                            badgeData = {
                                variant: "ghostSuccess",
                                text: t("active"),
                            };

                            break;

                        case ProviderConnectionStatus.Inactive:
                            badgeData = {
                                variant: "ghostError",
                                text: t("inactive"),
                            };

                            break;
                        default:
                            badgeData = {
                                variant: "ghost",
                                text: t("notConnected"),
                            };
                    }

                    return (
                        <Badge mt={2} size="sm" variant={badgeData.variant}>
                            {badgeData.text}
                        </Badge>
                    );
                },
            },
            {
                Header: "",
                id: "actions",
                width: 40,
                Cell: ({ row }: CellProps<LogisticsDevice>) => (
                    <ActionButton
                        actions={[
                            {
                                text: t("edit"),
                                onPress: () => onEditPress(row.original.id),
                            },
                        ]}
                    />
                ),
            },
        ],
        [onEditPress, t]
    );

    return (
        <VStack space="3">
            <Paper>
                <Headline
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    borderTopRadius="lg"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {patient.fullName}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
                <HStack p="6" alignItems="center">
                    <Avatar
                        source="https://randomuser.me/api/portraits/women/17.jpg"
                        size="3xl"
                    />
                    <Text fontSize="2xl">{patient.fullName}</Text>
                </HStack>
            </Paper>

            <Paper>
                <Headline title={t("personalData")} size="xs" />
                <HStack px="5" width="full">
                    <SimpleListItem
                        label={t("name")}
                        content={patient.fullName}
                        justifyContent="flex-start"
                        flex="1"
                        px="0"
                    />
                    <SimpleListItem
                        label={t("packages")}
                        content={
                            <HStack
                                space={1}
                                flexWrap="wrap"
                                flex={1}
                                width="full"
                            >
                                {patient.services.map((service) => (
                                    <Tag
                                        key={service}
                                        variant={getServiceColour(service)}
                                        mb={2}
                                    >
                                        {t(
                                            `backend:${serviceForHumans(
                                                service
                                            )}`
                                        ).toLowerCase()}
                                    </Tag>
                                ))}
                            </HStack>
                        }
                        justifyContent="flex-start"
                        flex="1"
                        px="0"
                        borderBottomWidth="0"
                        width="full"
                    />
                </HStack>
            </Paper>
            <Paper>
                <Headline title={t("devices")} size="xs" />
                <Box p="5" width="full">
                    <ReactTable
                        columns={columns}
                        data={devices}
                        onPress={handleRowPress}
                    />
                </Box>
            </Paper>
        </VStack>
    );
};
export default CallCenterPatient;
