import spacing from "./space";

const container = {
    sm: 343,
    md: 722,
    lg: 976,
    xl: 1232,
    "2xl": 1232,
};

const sizes = {
    ...spacing,
    ...{
        "3xs": 224,
        "2xs": 256,
        xs: 320,
        sm: 384,
        md: 448,
        lg: 512,
        xl: 576,
        "2xl": 672,
    },
    container,
};

export default sizes;
