import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lng/en.json";
import hu from "./lng/hu.json";

export const initI18n = async () => {
    await i18n.use(initReactI18next).init({
        ns: [
            "dashboard",
            "measures",
            "onboarding",
            "doctor",
            "preop",
            "pregnancy",
            "defaultMessages",
            "common",
        ],
        defaultNS: "common",
        resources: {
            en,
            hu,
        },
        fallbackLng: "hu",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });
};
