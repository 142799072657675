import type { RouteNavigate } from "@madmedical/routing";
import { useNavigate } from "@madmedical/routing";
import { useCallback } from "react";
import useGeneratePath from "./useGeneratePath";

export default () => {
    const baseNavigate = useNavigate();
    const generatePath = useGeneratePath();

    const navigate: RouteNavigate = useCallback(
        (route, params, options = {}) => {
            const { search, ...restOptions } = options;

            baseNavigate(generatePath(route, params, search), restOptions);
        },
        [baseNavigate, generatePath]
    );

    return navigate;
};
