import { Box, HStack, ScrollView, VStack } from "native-base";
import { formatDateRange } from "@madmedical/utils";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useChart } from "../providers/chart";
import { useRouteNavigate } from "../providers/routing";
import type { Evaluation } from "../providers/evaluation";
import Headline from "../molecules/Headline";
import Paper from "../atoms/Paper";
import Metrics from "../molecules/Metrics";
import StatusBadge from "../atoms/StatusBadge";
import DeviceSection from "../organisms/DeviceSection";
import ProtocolSection from "../organisms/EvaluationDetails/ProtocolSection";
import MedicalAdviceSection from "../organisms/EvaluationDetails/MedicalAdviceSection";
import AttachmentSection from "../organisms/EvaluationDetails/AttachmentSection";
import DetailsSection from "../organisms/EvaluationDetails/DetailsSection";
import useResponsive from "../util/useResponsive";
import IconButton from "../atoms/IconButton";
import { useThresholdsProtocols } from "../providers/thresholdsProtocol";
import MeasurementsTabs from "../organisms/MeasurementsTabs";
import Breadcrumb from "../molecules/Breadcrumb";

interface Props {
    evaluation: Evaluation;
}

const EvaluationDetails = ({ evaluation }: Props) => {
    const navigate = useRouteNavigate();
    const { isSmallScreen } = useResponsive();
    const { chart, displayRange, alertLevel } = useChart();
    const { thresholdsProtocols } = useThresholdsProtocols();
    const { t } = useTranslation();
    const handleClosePress = () => {
        navigate("measurements", { metricType: evaluation.metricType });
    };

    // Assuming first and only record is for this metric type
    const protocol = thresholdsProtocols.length
        ? thresholdsProtocols[0].protocol
        : null;

    return (
        <ScrollView>
            <VStack
                space={3}
                mb={{
                    base: 24,
                    md: 6,
                }}
            >
                <Paper bgColor="transparent" overflow="visible">
                    <Box width="full" borderBottomRadius="md">
                        <Box opacity={0.4} bg="white">
                            <Headline
                                minHeight={12}
                                borderBottomWidth={1}
                                borderBottomColor="gray.100"
                                borderBottomStyle="solid"
                                leftContent={
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Breadcrumb.Link
                                                route="dashboard"
                                                params={{}}
                                            >
                                                {t("health")}
                                            </Breadcrumb.Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Breadcrumb.Text>
                                                {forHumans(
                                                    evaluation.metricType
                                                )}
                                            </Breadcrumb.Text>
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                }
                            />

                            <MeasurementsTabs
                                metricType={evaluation.metricType}
                                isPatient
                            />
                        </Box>

                        <Box
                            borderStyle="dashed"
                            borderWidth={1}
                            borderColor="gray.600"
                            borderRadius="xl"
                            shadow="4"
                            width="full"
                            bg="white"
                        >
                            <Headline
                                borderTopRadius="xl"
                                leftContent={
                                    <>
                                        <VStack>
                                            <Box>
                                                {formatDateRange(
                                                    evaluation.dateRange,
                                                    "long"
                                                )}
                                            </Box>
                                            <HStack alignItems="center">
                                                {displayRange && (
                                                    <Metrics
                                                        displayValue={[
                                                            displayRange,
                                                        ]}
                                                        valueFontSize="5xl"
                                                    />
                                                )}
                                                {alertLevel ? (
                                                    <Box pt={3.5}>
                                                        <StatusBadge
                                                            level={alertLevel}
                                                            size="lg"
                                                        />
                                                    </Box>
                                                ) : null}
                                            </HStack>
                                        </VStack>
                                    </>
                                }
                                rightContent={
                                    <IconButton
                                        variant="ghost"
                                        iconName="close"
                                        onPress={handleClosePress}
                                    />
                                }
                            />
                            <Box mt={6} width="100%">
                                {chart}
                            </Box>
                            <VStack
                                width="full"
                                space={3}
                                p={{
                                    base: 4,
                                    md: 6,
                                }}
                            >
                                {evaluation.text && (
                                    <MedicalAdviceSection
                                        data={evaluation.text}
                                    />
                                )}
                                {evaluation.documents.length > 0 && (
                                    <AttachmentSection
                                        documents={evaluation.documents}
                                    />
                                )}
                                <DetailsSection
                                    evaluation={evaluation}
                                    measurementRange={displayRange}
                                    alertLevel={alertLevel}
                                />
                            </VStack>
                        </Box>
                    </Box>
                </Paper>

                <Box
                    bgColor="gray.50"
                    pb={isSmallScreen ? 12 : undefined}
                    opacity={0.4}
                >
                    {protocol && (
                        <ProtocolSection
                            metricType={evaluation.metricType}
                            instructions={protocol}
                        />
                    )}
                    <DeviceSection />
                </Box>
            </VStack>
        </ScrollView>
    );
};

export default EvaluationDetails;
