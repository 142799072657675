import type { CellProps, Column } from "react-table";
import { useMemo } from "react";
import { Box, FlatList, HStack, Text, VStack } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import type { ThresholdsProtocol } from "../providers/thresholdsProtocol";
import { useThresholdsProtocols } from "../providers/thresholdsProtocol";
import ReactTable from "../molecules/ReactTable";
import Tag from "../atoms/Tag";
import IconButton from "../atoms/IconButton";
import useResponsive from "../util/useResponsive";
import Paper from "../atoms/Paper";
import Headline from "../molecules/Headline";

interface Props {
    onEditPress?: (metricType: MetricType) => void;
    title: string;
}

const ThresholdsProtocolsTable = ({ onEditPress, title }: Props) => {
    const { thresholdsProtocols } = useThresholdsProtocols();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const MobileList = () => (
        <FlatList
            data={thresholdsProtocols}
            renderItem={({ item }) => (
                <HStack space={2}>
                    <VStack flex={1} alignItems="flex-start" space={2}>
                        <Tag>{t(`measures:${forHumans(item.metricType)}`)}</Tag>
                        <Text>{item.protocol}</Text>
                    </VStack>

                    {item.isThresholdEditable && onEditPress && (
                        <IconButton
                            iconName="edit"
                            variant="lightGray"
                            size="xs"
                            onPress={() => {
                                onEditPress(item.metricType);
                            }}
                        />
                    )}
                </HStack>
            )}
        />
    );

    const columns = useMemo<Column<ThresholdsProtocol>[]>(
        () => [
            {
                Header: t("measures:type"),
                accessor: "metricType",
                Cell: ({ value }) => (
                    <Tag>{t(`measures:${forHumans(value)}`)}</Tag>
                ),
                width: "10%",
            },
            {
                Header: t("measures:measureProtocol"),
                accessor: "protocol",
                Cell: ({ value }) => (
                    <Box
                        flexShrink={1}
                        justifyContent="space-between"
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                    >
                        {value && t(`backend:${value}`)}
                    </Box>
                ),
                width: "65%",
            },
            {
                Header: t("measures:warning"),
                id: "warningForHumans",
                accessor: "thresholds",
                Cell: ({ value }) => (
                    <>
                        {value.map(({ type, warningForHumans }) => (
                            <Box
                                key={type}
                                flexShrink={1}
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                                flexDirection="row"
                            >
                                {warningForHumans}
                            </Box>
                        ))}
                    </>
                ),
                width: "10%",
            },
            {
                Header: t("measures:alert"),
                id: "alertForHumans",
                accessor: "thresholds",
                Cell: ({ value }) => (
                    <>
                        {value.map(({ type, alertForHumans }) => (
                            <Box
                                key={type}
                                flexShrink={1}
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                                flexDirection="row"
                            >
                                {alertForHumans}
                            </Box>
                        ))}
                    </>
                ),
                width: "10%",
            },
            {
                Header: "",
                id: "action",
                Cell: ({ row }: CellProps<ThresholdsProtocol>) => {
                    const { metricType, isThresholdEditable } = row.original;

                    return isThresholdEditable && onEditPress ? (
                        <IconButton
                            iconName="edit"
                            variant="lightGray"
                            size="xs"
                            onPress={() => {
                                onEditPress(metricType);
                            }}
                        />
                    ) : null;
                },
                width: "5%",
            },
        ],
        [onEditPress, t]
    );

    if (!thresholdsProtocols.length) return null;

    return (
        <Paper>
            <Headline title={title} size="xs" />

            <Box px={6} py={4} w="full">
                {isSmallScreen ? (
                    <MobileList />
                ) : (
                    <ReactTable columns={columns} data={thresholdsProtocols} />
                )}
            </Box>
        </Paper>
    );
};

export default ThresholdsProtocolsTable;
