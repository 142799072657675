import type { ComponentProps } from "react";
import { useState } from "react";
import { Popover } from "native-base";
import { isWeb } from "@madmedical/utils";
import IconButton from "../atoms/IconButton";
import Dropdown from "./Dropdown";

type Action = {
    route?: string;
};

interface Props {
    size?: ComponentProps<typeof IconButton>["size"];
    actions: (ComponentProps<typeof Dropdown>["list"][0] & Action)[];
    dropdownPosition?: ComponentProps<typeof Popover>["placement"];
    iconName?: ComponentProps<typeof IconButton>["iconName"];
    maxWidth?: number;
}

const ActionButton = ({
    size = "sm",
    dropdownPosition = "bottom right",
    actions,
    iconName = "overflowMenuVertical",
    maxWidth = 44,
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    if (!actions.length) {
        return null;
    }

    return (
        <Popover
            placement={dropdownPosition}
            trigger={(triggerProps) => (
                <IconButton
                    {...triggerProps}
                    alignSelf="center"
                    iconName={iconName}
                    variant="ghostLink"
                    opacity="60"
                    size={size}
                    onPress={() => (actions ? setIsOpen(true) : null)}
                    flex={1}
                />
            )}
            isOpen={isOpen}
            onClose={() => setIsOpen((prevState) => !prevState)}
        >
            <Popover.Content
                borderColor="gray.100"
                bgColor="transparent"
                overflow={"hidden"}
                top={!isWeb ? -40 : 0}
            >
                <Dropdown
                    maxWidth={maxWidth}
                    list={actions}
                    hasUI={false}
                    triggerClose={() => setIsOpen(false)}
                />
            </Popover.Content>
        </Popover>
    );
};

export default ActionButton;
