import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const Placenta03 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#D67088"
        />
        <G clipPath="url(#clip0_1024_101143)">
            <Path
                d="M66.5001 114.529C94.5676 114.529 117.321 92.3487 117.321 64.9877C117.321 37.6267 94.5676 15.4463 66.5001 15.4463C38.4325 15.4463 15.6792 37.6267 15.6792 64.9877C15.6792 92.3487 38.4325 114.529 66.5001 114.529Z"
                fill="#C65D6F"
            />
            <Path
                d="M109.878 64.0436C109.161 70.0217 106.105 75.7075 101.482 79.5866C102.598 85.0598 100.42 90.7722 97.1515 95.1561C93.7773 99.6994 88.9417 103.153 83.5216 104.801C78.7126 106.262 73.5847 106.315 68.7491 105.04C63.1962 107.856 56.3414 107.351 50.5227 105.332C44.279 103.153 38.7526 99.1149 34.5547 94.0136C34.2093 93.5885 33.8905 93.1634 33.5716 92.7383C28.9486 87.5307 25.6275 81.101 24.5913 74.1665C23.5019 67.0459 25.1492 59.5534 29.666 53.8677C30.7553 52.4861 32.0306 51.2373 33.4122 50.1746C33.4122 50.148 33.4122 50.0948 33.4122 50.0683C32.8543 41.2208 36.7068 32.0544 43.8007 26.6077C47.6001 23.6851 52.17 22.1973 56.9524 22.8881C59.5562 23.26 62.0272 24.0837 64.5778 24.6682C67.2878 25.3059 69.9713 25.5184 72.7345 25.4121C77.8889 25.173 83.2293 23.9774 88.3306 25.173C90.5092 25.6778 92.5551 26.6343 94.0961 28.2816C95.5042 29.796 96.3545 31.6824 96.7796 33.6751C97.2578 35.9069 97.1781 38.1919 96.7264 40.4237C99.0114 41.5396 101.137 43.054 102.944 44.8607C107.992 49.8557 110.702 56.8965 109.852 63.9905L109.878 64.0436Z"
                fill="#FFDCD0"
            />
            <Path
                d="M101.53 78.895C99.9619 74.4845 96.9862 70.5789 93.1602 67.8688C88.9889 64.9196 83.9142 63.5115 78.8395 63.7772C75.9966 63.9366 73.2068 64.6274 70.6296 65.7964C69.9654 64.3085 69.248 62.8207 68.4244 61.4125C78.1487 60.9874 87.315 55.9658 92.7883 47.8888C94.3027 45.6836 95.4983 43.2924 96.4017 40.7683C96.6142 40.1838 95.7109 39.7587 95.4983 40.3698C92.2835 49.4298 84.8707 56.4972 75.6512 59.2338C73.1006 59.9778 70.4702 60.3763 67.8399 60.456C66.6443 58.543 65.2892 56.7363 63.7217 55.0891C60.0551 51.21 55.3258 48.2077 50.0651 47.0918C44.1668 45.843 37.6839 47.012 32.928 50.838C32.4232 51.2365 33.0078 52.0336 33.5126 51.6351C37.6042 48.3405 43.0243 47.0918 48.1787 47.756C53.3597 48.4202 58.089 51.024 61.8618 54.5842C63.8014 56.4175 65.4752 58.5165 66.8834 60.7483C66.8302 61.014 66.9631 61.3328 67.2819 61.3859C68.6369 63.6443 69.7794 66.0621 70.7093 68.533C71.719 71.2962 72.4895 74.1657 73.074 77.0617C73.6851 80.1703 74.1368 83.3055 74.2165 86.4672C74.3493 92.8703 72.6755 99.5392 68.0259 104.136C67.5742 104.587 68.3447 105.198 68.7964 104.773C72.9677 100.655 74.9073 94.7833 75.173 89.0178C75.3324 85.6435 75.0135 82.2958 74.4556 78.9747C73.9508 75.9724 73.2334 72.9701 72.3035 70.074C71.9315 68.9316 71.5064 67.8157 71.0547 66.6998C75.5715 64.6539 80.7259 64.1757 85.5349 65.3713C89.9985 66.4872 94.037 69.1176 96.9596 72.6778C98.5538 74.6439 99.8025 76.8492 100.653 79.2404C100.865 79.8515 101.795 79.4795 101.583 78.8684L101.53 78.895Z"
                fill="#DD8F8C"
            />
            <Path
                d="M77.9614 36.7578C71.9037 42.0451 61.0635 40.6635 57.1047 33.6493C56.653 25.2534 69.725 29.5842 74.5606 30.7798C77.9614 31.258 82.0265 33.6493 77.9614 36.7844V36.7578Z"
                fill="#DD8F8C"
            />
            <Path
                d="M91.6478 91.4106C89.6551 93.111 86.9185 92.1279 84.6601 91.5965C78.8414 91.2511 74.2981 86.9735 78.0444 81.2877C83.7036 72.6262 98.5557 83.9181 91.6478 91.4106Z"
                fill="#DD8F8C"
            />
            <Path
                d="M105.839 61.8124C104.777 70.6599 91.5718 67.5779 89.8448 60.5637C87.5333 49.4046 106.663 52.2741 105.839 61.8124Z"
                fill="#DD8F8C"
            />
            <Path
                d="M53.9491 79.4001C55.3573 83.8371 50.8937 87.9554 46.5363 86.7598C37.4762 84.7936 34.6068 66.1155 46.5629 67.7363C51.0796 69.5164 52.4878 75.2288 53.9225 79.4001H53.9491Z"
                fill="#DD8F8C"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_1024_101143">
                <Rect
                    width="114"
                    height="126"
                    fill="white"
                    transform="translate(7)"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default Placenta03;
