import Svg, { Path, Rect } from "react-native-svg";

const Banana = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M102.942 42.6552L101.217 45.6858C100.359 47.1924 99.9198 48.8986 99.9429 50.6297C100.028 57.2562 99.0788 75.7219 87.1772 91.6011C72.3754 111.35 52.9536 104.795 52.9536 104.795C52.9536 104.795 41.1477 102.411 40.2907 90.4944L98.7277 39.5059L102.942 42.6552Z"
            fill="#EAA369"
        />
        <Path
            d="M88.4862 34.1025C87.4666 35.1135 86.1022 35.7059 84.6639 35.7621C82.719 35.8181 79.3949 36.823 75.4983 41.784C69.2627 49.7212 58.5338 54.6478 48.9969 48.9914C44.9155 46.539 41.4075 43.2478 38.7075 39.3379C37.7456 37.9485 36.2768 36.9874 34.6135 36.6591C33.7634 36.4867 32.8899 36.4609 32.0311 36.5828L25.062 38.6822C25.062 38.6822 22.3109 43.3347 25.7963 52.0019C25.7963 52.0019 35.4243 67.682 58.9001 65.5441C75.2625 64.0537 86.3697 50.4382 91.5957 42.3244C91.4418 38.8865 90.5002 36.0711 88.4862 34.1025Z"
            fill="#FACD8A"
        />
        <Path
            d="M70.5045 56.4264C47.203 66.5751 32.0311 49.2109 32.0311 49.2109C29.6105 45.8556 26.1613 46.1846 24.2798 46.699C24.5873 48.517 25.0959 50.2957 25.7963 52.0025C25.7963 52.0025 35.4028 67.4261 58.9001 65.5447C64.7536 65.0757 69.279 62.4448 72.534 59.6025C74.1805 58.1627 72.5122 55.5518 70.5045 56.4264Z"
            fill="#EAA369"
        />
        <Path
            d="M84.6639 35.7621C82.719 35.8181 79.3949 36.823 75.4983 41.784C75.1498 42.2276 74.7871 42.6612 74.4104 43.0847C72.8957 58.8367 57.9286 59.5516 57.9286 59.5516L59.2238 65.5105C75.4085 63.8713 86.4026 50.3846 91.5954 42.3244C91.4423 38.8879 90.5007 36.0711 88.4867 34.1025C87.467 35.1136 86.1024 35.706 84.6639 35.7621Z"
            fill="#EAA369"
        />
        <Path
            d="M95.0291 36.3096L90.9024 31.748L88.4862 34.1022L91.5943 42.3248C92.8609 40.391 94.008 38.3822 95.0291 36.3096Z"
            fill="#EAA369"
        />
        <Path
            d="M80.2703 50.3389C73.6295 57.4083 62.048 65.5067 46.2657 60.6418C39.15 58.4481 33.8811 55.4765 29.5392 52.1028L27.3797 53.0304C25.8423 53.693 24.5479 54.8116 23.6733 56.2334C22.7988 57.6553 22.3867 59.311 22.4934 60.9746C22.7875 65.6083 24.3757 70.0683 27.0792 73.8527C27.0792 73.8527 63.419 75.4338 63.7788 75.5204C68.3083 76.6081 90.4044 52.6159 80.2703 50.3389Z"
            fill="#FACD8A"
        />
        <Path
            d="M76.6963 63.1271C65.1852 71.5562 54.3755 70.8305 48.4421 69.4069C46.2812 68.8699 44.2061 68.036 42.2764 66.9294C36.1499 63.4824 31.7757 64.467 29.8923 65.2266C29.5379 65.3719 29.1565 65.4405 28.7735 65.4277C28.3904 65.4148 28.0145 65.3209 27.6708 65.1522L27.6642 65.1489C26.742 64.6908 25.6826 64.5861 24.6877 64.8548L23.1478 65.2704C23.9038 68.3518 25.2378 71.2633 27.0798 73.8521C27.0798 73.8521 63.4157 75.4529 63.7794 75.5198C69.9742 76.6587 75.9563 71.9653 79.9394 66.2835C81.4328 64.1537 78.7987 61.5874 76.6963 63.1271Z"
            fill="#EAA369"
        />
        <Path
            d="M90.0811 38.3203L89.4857 38.9248C87.9122 40.527 86.4898 42.2695 85.2364 44.1304C83.728 46.3545 82.0437 48.4554 80.1994 50.4129C80.3152 54.3483 79.3875 62.6705 70.2451 66.9951C65.3674 69.3021 63.3518 72.4788 62.6901 75.4659C63.4244 75.5002 63.8199 75.52 63.7802 75.52C63.4671 75.52 84.4232 63.127 93.3388 46.9326C93.6135 42.9685 92.475 40.1406 90.0811 38.3203Z"
            fill="#EAA369"
        />
        <Path
            d="M93.9755 34.3691L90.0811 38.3197L93.3369 46.9312C94.9682 44.0529 96.1434 40.9414 96.8212 37.7066L93.9755 34.3691Z"
            fill="#EAA369"
        />
        <Path
            d="M29.5398 52.103C29.5398 52.103 30.0215 54.5021 27.1225 55.9091C24.4269 57.2174 23.3099 56.8968 23.3099 56.8968L23.0306 53.8199C22.9989 53.1666 23.1521 52.5175 23.4727 51.9464C23.7932 51.3754 24.2684 50.9052 24.8441 50.5894C25.2851 50.3476 25.784 50.23 26.2871 50.2491C26.7902 50.2681 27.2786 50.4232 27.6999 50.6976C28.3525 51.1127 28.9681 51.5829 29.5398 52.103Z"
            fill="#AA8D8C"
        />
        <Path
            d="M91.6672 42.5176C90.3557 44.141 89.3241 45.9698 88.6146 47.9293C86.3417 54.246 78.9363 68.7376 58.0026 72.7861C50.96 74.1477 45.4025 72.8237 41.0343 70.1216C38.7379 68.6606 36.0826 67.8545 33.3575 67.7911C30.8553 67.764 28.0211 68.2819 25.9957 70.2588C21.5081 74.6394 23.7943 81.5472 26.3345 84.4958C28.8748 87.4444 37.3422 96.458 58.172 93.2569C78.1351 90.189 93.5855 71.6475 94.8385 50.9042C94.4852 47.3952 93.5591 44.4661 91.6672 42.5176Z"
            fill="#FACD8A"
        />
        <Path
            d="M98.3619 41.3474L96.3602 37.1533L92.2204 41.8616C92.0311 42.0769 91.8469 42.2963 91.6678 42.5196L94.8385 50.9079C94.9665 48.9453 95.4929 47.029 96.386 45.2745L98.3619 41.3474Z"
            fill="#EAA369"
        />
        <Path
            d="M23.4802 69.2212L24.4321 68.4773C24.8229 68.1718 25.2808 67.9625 25.7683 67.8664C26.2559 67.7704 26.7594 67.7903 27.2378 67.9245L28.4345 68.2601C28.5759 68.3 28.7065 68.3712 28.8163 68.4684C28.9262 68.5656 29.0126 68.6863 29.0689 68.8214C29.1253 68.9565 29.1502 69.1025 29.1419 69.2485C29.1335 69.3946 29.092 69.5368 29.0206 69.6647C28.2569 71.0447 26.6106 73.3073 23.8182 73.8537C23.2573 72.7884 22.9543 71.6074 22.9335 70.405C22.924 70.1787 22.9687 69.9535 23.0637 69.7477C23.1587 69.5419 23.3015 69.3615 23.4802 69.2212Z"
            fill="#AA8D8C"
        />
        <Path
            d="M88.5322 30.735L98.3619 41.347L103.819 32.4121L96.0762 24.9799C95.5504 24.4753 94.8546 24.1835 94.1244 24.1615C93.3942 24.1395 92.6819 24.3888 92.1263 24.8608L88.7106 27.7637C88.4982 27.9442 88.3245 28.1654 88.2 28.4142C88.0756 28.663 88.0028 28.9342 87.9862 29.2116C87.9695 29.489 88.0093 29.7669 88.1031 30.0287C88.1969 30.2905 88.3428 30.5307 88.5322 30.735Z"
            fill="#FACD8A"
        />
        <Path
            d="M103.819 32.4121L98.3619 41.3469L101.621 44.4565C101.82 44.6457 102.059 44.7868 102.321 44.8688C102.583 44.9509 102.861 44.9716 103.132 44.9294C103.404 44.8873 103.662 44.7834 103.887 44.6258C104.111 44.4682 104.296 44.2612 104.427 44.0209L107.184 38.9609C107.464 38.4333 107.574 37.833 107.499 37.2412C107.424 36.6493 107.168 36.0947 106.766 35.6524L103.819 32.4121Z"
            fill="#EAA369"
        />
        <Path
            d="M32.0311 36.5835L30.625 35.3336C30.2962 35.0413 29.8946 34.8419 29.4622 34.7562C29.0297 34.6706 28.582 34.7018 28.1658 34.8465L27.2367 35.1713C26.7944 35.3251 26.3979 35.5867 26.0834 35.9321C25.7688 36.2775 25.5461 36.6957 25.4357 37.1487L25.062 38.6835C25.062 38.6835 29.5109 40.4445 32.0311 36.5835Z"
            fill="#AA8D8C"
        />
        <Path
            d="M65.891 79.3176C65.6664 79.3176 65.4491 79.2383 65.2777 79.094C65.1063 78.9497 64.9918 78.7496 64.9546 78.5293C64.9174 78.3089 64.9599 78.0826 65.0745 77.8905C65.1891 77.6984 65.3684 77.5528 65.5807 77.4798C74.146 74.5342 80.3775 69.4782 84.1022 62.4524C84.1592 62.3406 84.2379 62.2411 84.3339 62.1599C84.4299 62.0786 84.5412 62.0172 84.6612 61.9791C84.7813 61.9411 84.9078 61.9272 85.0333 61.9383C85.1589 61.9493 85.2809 61.9852 85.3924 62.0436C85.5039 62.1021 85.6025 62.1821 85.6826 62.2789C85.7627 62.3757 85.8227 62.4874 85.8589 62.6074C85.8952 62.7275 85.9071 62.8535 85.894 62.9782C85.8808 63.1029 85.8428 63.2238 85.7822 63.3337C81.8261 70.7979 75.2385 76.1582 66.2024 79.2657C66.1022 79.3001 65.997 79.3177 65.891 79.3176Z"
            fill="#EAA369"
        />
        <Path
            d="M50.511 81.2179C44.8324 81.2179 40.765 79.4937 36.1374 75.3856C35.0386 74.4102 33.9733 73.9193 33.0572 73.9715C32.9327 73.9784 32.8081 73.9608 32.6904 73.9197C32.5728 73.8787 32.4644 73.8149 32.3715 73.7322C32.1838 73.5651 32.0706 73.3307 32.0566 73.0805C32.0427 72.8303 32.1293 72.5849 32.2972 72.3982C32.4652 72.2115 32.7008 72.0989 32.9523 72.085C34.406 72.0038 35.8991 72.6413 37.4018 73.9759C42.6509 78.6362 46.9247 79.9288 54.3301 79.0938C54.5786 79.0691 54.8268 79.1428 55.021 79.2989C55.2153 79.4549 55.34 79.6808 55.3681 79.9277C55.3962 80.1745 55.3254 80.4224 55.1712 80.6177C55.0169 80.8131 54.7916 80.9401 54.5439 80.9714C53.2053 81.1288 51.8589 81.2111 50.511 81.2179Z"
            fill="#EAA369"
        />
        <Path
            d="M36.6483 85.6935C37.9683 84.6413 37.8635 82.3296 36.4144 80.5304C34.9653 78.7311 32.7205 78.1255 31.4005 79.1777C30.0806 80.23 30.1853 82.5416 31.6344 84.3409C33.0836 86.1402 35.3283 86.7458 36.6483 85.6935Z"
            fill="#FFF7E1"
        />
        <Path
            d="M34.295 62.0548C35.1917 61.3399 35.1207 59.7695 34.1362 58.5472C33.1518 57.3249 31.6268 56.9135 30.7301 57.6284C29.8333 58.3433 29.9044 59.9137 30.8888 61.136C31.8732 62.3583 33.3982 62.7696 34.295 62.0548Z"
            fill="#FFF7E1"
        />
        <Path
            d="M60.2358 80.581C60.7695 80.581 61.2021 80.1506 61.2021 79.6196C61.2021 79.0886 60.7695 78.6582 60.2358 78.6582C59.7021 78.6582 59.2695 79.0886 59.2695 79.6196C59.2695 80.1506 59.7021 80.581 60.2358 80.581Z"
            fill="#DD9051"
        />
    </Svg>
);

export default Banana;
