import { useState } from "react";
import { Box, Text, useTheme } from "native-base";
import { type Ulid, isWeb } from "@madmedical/utils";
import type { ProfileWithId } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import DropdownForComponents from "../../molecules/DropdownForComponents";
import Icon from "../../icons/Icon";
import useResponsive from "../../util/useResponsive";
import ProfileItem from "./ProfileItem";

export interface Props {
    selectedPatient: ProfileWithId;
    me: ProfileWithId | null;
    patients: ProfileWithId[];
    onChange: (patientId: Ulid) => void;
}

const PatientSelect = ({ selectedPatient, me, patients, onChange }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { colors } = useTheme();
    const { t } = useTranslation();
    const { isMediumScreen } = useResponsive();

    const isSelfSelected = selectedPatient?.userId === me?.userId;

    const $colors = isSelfSelected
        ? {
              color: colors.gray[600],
              border: colors.gray[100],
              bg: colors.white,
              cardBorder: colors.gray[100],
          }
        : {
              color: colors.blue[700],
              border: colors.blue[500],
              bg: "#E2EEFF", // not available in Theme colors
              cardBorder: colors.blue[300],
          };

    const styles = {
        slot: {
            backgroundColor: $colors.bg,
            borderColor: $colors.border,
            color: $colors.color,
            borderWidth: 1,
            borderRadius: 8,
            columnGap: 12,
            paddingBlock: 8,
            paddingInline: 24,
            width: "100%",
            height: isMediumScreen ? (isWeb ? 80 : 70) : "auto",
            overflow: isOpen ? undefined : ("hidden" as unknown as "hidden"),
        },

        svg: {
            flexShrink: 0,
        },

        text: {
            box: {
                flex: 1,
                minHeight: isMediumScreen ? 50 : "auto",
            },
            base: {
                color: "inherit",
            },
            trusteeName: {
                color: colors.blue[500],
            },
            userName: {
                color: "inherit",
            },
        },

        dropdown: {
            absolute: {
                top: isMediumScreen ? 0 : -25,
                height: isMediumScreen ? 50 : "auto",
                width: isMediumScreen ? "100%" : "auto",
                padding: isMediumScreen ? 8 : 0,
                zIndex: 100,
            },
            border: {
                backgroundColor: $colors.bg,
                borderColor: $colors.cardBorder,
                borderWidth: 1,
                borderRadius: 4,
                height: 50,
                width: "100%",
            },
            selections: {
                backgroundColor: $colors.bg,
                borderColor: $colors.cardBorder,
                borderBottomWidth: 1,
                top: 0,
                height: 150,
            },
        },
    };

    return (
        <Box
            style={styles.slot}
            flexDirection={{
                base: "column",
                xl: "row",
            }}
            alignItems={{
                base: "flex-start",
                xl: "center",
            }}
            justifyContent={{
                base: "flex-start",
                xl: "space-between",
            }}
            mb={3}
            zIndex={1}
            minHeight={"70px"}
        >
            {!isMediumScreen && (
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    height={{
                        base: 50,
                        md: "auto",
                    }}
                    //alignItems="flex-start"
                    px={{
                        base: 2,
                        md: 0,
                    }}
                    pt={2}
                >
                    {isSelfSelected ? (
                        <Box>
                            <Icon
                                name="user"
                                size={24}
                                fill={colors.green[400]}
                            />
                        </Box>
                    ) : (
                        <Box h="25" alignItems={"center"} flexDirection={"row"}>
                            <Icon
                                name="userAdmin"
                                size={24}
                                fill={colors.blue[500]}
                            />
                        </Box>
                    )}

                    <Box
                        style={styles.text.box}
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                        ml={{
                            base: 2,
                            md: 2,
                        }}
                    >
                        <Text style={styles.text.base}>
                            {isSelfSelected ? (
                                <Text style={styles.text.userName}>
                                    {`Üdvözöljük, ${me?.fullName}!`}
                                </Text>
                            ) : (
                                <>
                                    Jelenleg
                                    <Text
                                        style={styles.text.trusteeName}
                                        fontWeight="500"
                                    >
                                        {` ${selectedPatient?.fullName} `}
                                    </Text>
                                    adatlapjának megtekintése, mint:
                                    <Text
                                        style={styles.text.userName}
                                        fontWeight="500"
                                    >
                                        {` ${me?.fullName ?? ""} `}
                                    </Text>
                                    {isSelfSelected
                                        ? `(${t("ownAccount")})`
                                        : `(${t("authorizedPersonsAccount")})`}
                                </>
                            )}
                        </Text>
                    </Box>
                </Box>
            )}

            <DropdownForComponents
                isOpen={isOpen}
                propStyles={{
                    absolute: styles.dropdown.absolute,
                    dropdown: styles.dropdown.selections,
                }}
                triggerChild={
                    <ProfileItem
                        profile={selectedPatient}
                        isTrigger={true}
                        userId={me?.userId ?? ""}
                        onPress={() => setIsOpen((state) => !state)}
                        propStyles={styles.dropdown.border}
                    />
                }
                dropdownChildren={patients.map(($profile) => (
                    <ProfileItem
                        key={$profile?.userId}
                        profile={$profile}
                        userId={me?.userId ?? ""}
                        onPress={() => {
                            onChange?.($profile?.userId);
                            setIsOpen((state) => !state);
                        }}
                        //propStyles={styles.dropdown.selections}
                    />
                ))}
            />
        </Box>
    );
};

export default PatientSelect;
