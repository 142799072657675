import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type {
    Gender,
    Profile,
    Service,
    UnderlyingCondition,
} from "@madmedical/medical";
import type { Pagination } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type {
    FilterItem,
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "./model";

export interface PatientBase {
    userId: string;
    initials?: string;
    fullName: string;
    avatarUrl?: string;
    services: Service[];
    birthDate: Date | null;
    packages?: MyPackages[];
    underlyingConditions?: UnderlyingCondition[];
}
export interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}
export interface PatientCallCenter extends PatientBase {
    deviceCount: number;
}

export interface PatientDoctor extends PatientBase {
    socialSecurityNumber: string | null;
    gender: Gender | null;
    representatives: (Profile & { userId: Ulid })[];
    underlyingConditions: UnderlyingCondition[];
}

export type Patient = PatientCallCenter | PatientDoctor;

export type FilterChoices = {
    readonly services: FilterItem<Service>[];
    readonly genders: FilterItem<Gender>[];
    readonly underlyingConditions: FilterItem<UnderlyingCondition>[];
    readonly deviceTypes: FilterItem<Ulid>[];
};

interface ContextValue<TPatient extends Patient = Patient> {
    patients: TPatient[];
    pagination: Pagination;
    onSortChange: OnSortChange | undefined;
    onSearchChange: OnSearchChange | undefined;
    filterChoices: FilterChoices;
    onFilterChange: OnFilterChange<FilterChoices> | undefined;
}

const PatientContext = createContext<ContextValue | undefined>(undefined);

export const DoctorPatientProvider = PatientContext.Provider as ReactProvider<
    ContextValue<PatientDoctor>
>;
export const CallCenterPatientProvider =
    PatientContext.Provider as ReactProvider<ContextValue<PatientCallCenter>>;

export const usePatients = <
    TPatient extends Patient
>(): ContextValue<TPatient> =>
    useEnsureContext(PatientContext) as ContextValue<TPatient>;
