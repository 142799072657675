import { useSelector } from "../context";
import {
    isLoadingSelector,
    isTokenAvailableSelector,
    userIdSelector,
} from "./selectors";

const useAuth = () => {
    const isTokenAvailable = useSelector(isTokenAvailableSelector);
    const isLoading = useSelector(isLoadingSelector);
    const userId = useSelector(userIdSelector);

    return { isLoading, isTokenAvailable, userId };
};

export default useAuth;
