import {
    PRENATAL_TESTING_METHOD_FOR_HUMANS,
    type Pregnancy,
    genderBabyForHumans,
} from "@madmedical/medical";
import { Box, HStack, Stack, Text, VStack, useTheme } from "native-base";
import { formatDate, isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import ListHeader from "../../molecules/ListHeader";
import Paper from "../../atoms/Paper";
import Headline from "../../molecules/Headline";
import SimpleListItem from "../../molecules/ListItem/SimpleListItem";
import Icon from "../../icons/Icon";
import Tag from "../../atoms/Tag";
import ButtonLink from "../../atoms/ButtonLink";
import { useDocuments } from "../../providers/document";

interface Props {
    data: Pregnancy | undefined;
    onEditPregnancy?: () => unknown;
}

const PregnancyDoctorInfos = ({ data, onEditPregnancy }: Props) => {
    const { isSmallScreen } = useResponsive();
    //const { patient } = useCurrentPatient<PatientDoctor>();
    const { colors } = useTheme();
    const { t } = useTranslation();
    const doctorGender =
        data?.infants[0]?.doctorGender ?? data?.infants[0]?.gender ?? undefined;

    const { pregnancyDocuments } = useDocuments("both");

    return (
        <Box
            flex={{
                base: isWeb ? "initial" : 0,
                md: 1,
            }}
        >
            {isSmallScreen && (
                <ListHeader
                    title={t("pregnancy:pregnancy2")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}
            <Paper borderRadius="lg" flex={isWeb ? 1 : 0}>
                {!isSmallScreen && (
                    <Headline
                        title={t("pregnancy:pregnancy2")}
                        rightContent={
                            onEditPregnancy && (
                                <ButtonLink
                                    p={0}
                                    size="xs"
                                    variant="link"
                                    route="doctor_edit_pregnancy"
                                    params={{}}
                                >
                                    {t("edit")}
                                </ButtonLink>
                            )
                        }
                        size="xs"
                    />
                )}

                {!data ? (
                    <Box
                        px={{
                            base: 4,
                            md: 6,
                        }}
                        py={{
                            base: 6,
                            md: 8,
                        }}
                        width="full"
                    >
                        <Text color="gray.600" fontSize="sm">
                            {t("pregnancy:noAddedInformationToPregnancy")}
                        </Text>
                    </Box>
                ) : (
                    <Box
                        px={{
                            base: 4,
                            md: 6,
                        }}
                        width="full"
                    >
                        <Stack
                            width="full"
                            direction={{
                                base: "column",
                                md: "row",
                            }}
                            borderBottomWidth={1}
                            borderBottomColor="gray.100"
                        >
                            <VStack
                                width={{
                                    base: "full",
                                    md: "1/2",
                                }}
                            >
                                <SimpleListItem
                                    label={t("pregnancy:estimatedDeliveryDate")}
                                    content={
                                        data?.expectedAt
                                            ? formatDate(data?.expectedAt)
                                            : "–"
                                    }
                                    borderBottomWidth={0}
                                    px={0}
                                />
                                <SimpleListItem
                                    label={t("appendix")}
                                    content={
                                        pregnancyDocuments?.length ? (
                                            <HStack space={4}>
                                                {pregnancyDocuments.map(
                                                    (document) => (
                                                        <ButtonLink
                                                            key={document?.id}
                                                            p={0}
                                                            size="xs"
                                                            variant="link"
                                                            route="document"
                                                            params={{
                                                                documentId:
                                                                    document?.id,
                                                            }}
                                                        >
                                                            <HStack
                                                                space={1.5}
                                                                alignItems={
                                                                    "center"
                                                                }
                                                            >
                                                                <Icon
                                                                    name="documentAttachment"
                                                                    size={16}
                                                                    fill={
                                                                        colors
                                                                            .green[500]
                                                                    }
                                                                />
                                                                <Text
                                                                    color="green.500"
                                                                    fontSize="sm"
                                                                >
                                                                    {
                                                                        document?.filename
                                                                    }
                                                                </Text>
                                                            </HStack>
                                                        </ButtonLink>
                                                    )
                                                )}
                                            </HStack>
                                        ) : (
                                            <Text
                                                color="gray.600"
                                                fontSize="sm"
                                            >
                                                {t("noAppendix")}
                                            </Text>
                                        )
                                    }
                                    borderBottomWidth={0}
                                    px={0}
                                />
                            </VStack>
                            <VStack
                                width={{
                                    base: "full",
                                    md: "1/2",
                                }}
                            >
                                <SimpleListItem
                                    label="Rizikó"
                                    content={
                                        data?.isHighRisk ? (
                                            <Tag
                                                iconName="warning"
                                                variant="error"
                                            >
                                                {t("high")}
                                            </Tag>
                                        ) : (
                                            <Tag
                                                iconName="checkmark"
                                                variant="primary"
                                            >
                                                {t("normal")}
                                            </Tag>
                                        )
                                    }
                                    borderBottomWidth={0}
                                    px={0}
                                />
                            </VStack>
                        </Stack>
                        <Stack
                            width="full"
                            direction={{
                                base: "column",
                                md: "row",
                            }}
                            pb={6}
                            borderBottomWidth={1}
                            borderBottomColor="gray.100"
                        >
                            <VStack
                                width={{
                                    base: "full",
                                    md: "1/2",
                                }}
                            >
                                <SimpleListItem
                                    label={t("pregnancy:gender")}
                                    content={
                                        doctorGender
                                            ? t(
                                                  `pregnancy:${genderBabyForHumans(
                                                      doctorGender
                                                  )}`
                                              )
                                            : t("pregnancy:keepItASurprise")
                                    }
                                    borderBottomWidth={0}
                                    px={0}
                                />
                                <SimpleListItem
                                    label={t("comment")}
                                    content={data?.infantComment ?? "–"}
                                    borderBottomWidth={0}
                                    px={0}
                                />
                            </VStack>
                            <VStack
                                width={{
                                    base: "full",
                                    md: "1/2",
                                }}
                            >
                                <SimpleListItem
                                    label="Nem megállapításának módja"
                                    content={
                                        data?.genderMethod
                                            ? PRENATAL_TESTING_METHOD_FOR_HUMANS[
                                                  data?.genderMethod
                                              ]
                                            : "–"
                                    }
                                    borderBottomWidth={0}
                                    px={0}
                                />
                            </VStack>
                        </Stack>
                        <Stack width="full" direction={"row"} my={4} space={2}>
                            <ButtonLink
                                variant="outlined"
                                size="md"
                                leftIconName="reportData"
                                route="pregnancy_feeling"
                                params={{}}
                            >
                                {t("pregnancy:feelingAndSymptoms")}
                            </ButtonLink>
                            <ButtonLink
                                variant="outlined"
                                size="md"
                                leftIconName="chartLineData"
                                route="pregnancy_measurement"
                                params={{}}
                            >
                                {t("measures:title")}
                            </ButtonLink>
                        </Stack>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default PregnancyDoctorInfos;
