import type { ComponentProps } from "react";
import { useMemo, useState } from "react";
import type { CellProps, Column } from "react-table";
import { Box, HStack, Text } from "native-base";
import {
    ProviderConnectionStatus,
    forHumans,
    logisticsForHumans,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../../providers/routing";
import type { LogisticsDevice } from "../../providers/callCenterDevice";
import { useCallCenterDevices } from "../../providers/callCenterDevice";
import Tag from "../../atoms/Tag";
import ReactTable from "../../molecules/ReactTable";
import Badge from "../../atoms/Badge";
import SearchInput from "../../molecules/Form/SearchInput";
import ActionButton from "../../molecules/ActionButton";
import CallCenterDeviceDesktopFilter from "../filters/desktop/CallCenterDeviceDesktopFilter";

const MAX_TAGS = 3;

interface Props {
    onEndReached: () => unknown;
    onEditPress: (id: string) => unknown;
}

const CallCenterDeviceList = ({ onEndReached, onEditPress }: Props) => {
    const { devices, onSearchChange } = useCallCenterDevices();
    const [search, setSearch] = useState("");
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange(search);
    };

    const handleRowPress = ({ id }: LogisticsDevice) => {
        navigate("callcenter_device", { deviceId: id });
    };

    const columns = useMemo<Column<LogisticsDevice>[]>(
        () => [
            {
                Header: t("serialNumber"),
                accessor: "serialNumber",
                width: "10%",
            },
            {
                Header: t("device"),
                id: "deviceTypeName",
                width: "auto",
                Cell: ({
                    row: {
                        original: { deviceType },
                    },
                }: CellProps<LogisticsDevice>) => <>{deviceType.name}</>,
            },
            {
                Header: t("measures:type"),
                id: "metricTypes",
                width: "auto",
                Cell: ({
                    row: {
                        original: {
                            deviceType: { metricTypes },
                        },
                    },
                }: CellProps<LogisticsDevice>) => {
                    const count = metricTypes.length;
                    if (!count) {
                        return <Text>-</Text>;
                    }

                    const types = metricTypes.slice(0, MAX_TAGS);
                    const remains = count - types.length;

                    return (
                        <HStack w="full" flexWrap="wrap">
                            {types.map((metricType) => (
                                <Tag key={metricType} mr={2} mb={2}>
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Tag>
                            ))}
                            {remains ? (
                                <Tag key={remains} mr={2} mb={2}>
                                    +{remains}
                                </Tag>
                            ) : null}
                        </HStack>
                    );
                },
            },
            {
                Header: t("logistics"),
                accessor: "status",
                width: "12%",
                Cell: ({ value: status }) => (
                    <Text>{logisticsForHumans(status)}</Text>
                ),
            },
            {
                Header: t("connectionState"),
                accessor: "connectionStatus",
                width: "12%",
                Cell: ({ value: connectionStatus }) => {
                    let badgeData: {
                        variant: ComponentProps<typeof Badge>["variant"];
                        text: string;
                    } | null = null;

                    switch (connectionStatus) {
                        case ProviderConnectionStatus.Active:
                            badgeData = {
                                variant: "ghostSuccess",
                                text: t("active"),
                            };

                            break;

                        case ProviderConnectionStatus.Inactive:
                            badgeData = {
                                variant: "ghostError",
                                text: t("inactive"),
                            };

                            break;
                        default:
                            badgeData = {
                                variant: "ghost",
                                text: t("notConnected"),
                            };
                    }

                    return (
                        <Badge mt={2} size="sm" variant={badgeData.variant}>
                            {badgeData.text}
                        </Badge>
                    );
                },
            },
            {
                Header: "",
                id: "actions",
                width: 40,
                Cell: ({ row }: CellProps<LogisticsDevice>) => (
                    <ActionButton
                        actions={[
                            {
                                text: t("edit"),
                                onPress: () => onEditPress(row.original.id),
                            },
                        ]}
                    />
                ),
            },
        ],
        [onEditPress, t]
    );

    return (
        <Box px="6" py="4" width="full">
            <HStack mb="5" width="full">
                <CallCenterDeviceDesktopFilter />

                <SearchInput
                    placeholder={t("search")}
                    onChangeText={handleSearchChange}
                    value={search}
                />
            </HStack>
            <ReactTable
                columns={columns}
                data={devices}
                onEndReached={onEndReached}
                onPress={handleRowPress}
            />
        </Box>
    );
};

export default CallCenterDeviceList;
