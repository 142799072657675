import { Box, HStack } from "native-base";
import { useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import ListHeader from "../../../molecules/ListHeader";
import Checkbox from "../../../atoms/Checkbox";
import Button from "../../../atoms/Button";
import type { FilterItem } from "../../../providers/model";
import Radio from "../../../atoms/Radio";

const LIMIT = 3;

interface Props<TValue extends string | number> {
    filterChoices: FilterItem<TValue>[];
    selected: TValue[];
    isMultiSelect: boolean;
    title: string;
    onChange: (selected: TValue[]) => void;
}

const MobileFilterList = <TValue extends string | number>({
    filterChoices,
    selected,
    isMultiSelect,
    title,
    onChange,
}: Props<TValue>) => {
    const [isLimited, setLimited] = useState(true);
    const { t } = useTranslation();

    return (
        <>
            <ListHeader title={title} badge={selected.length} mb={2} />
            {isMultiSelect ? (
                <Checkbox.Group
                    value={selected.map((key) => String(key))}
                    onChange={(values: TValue[]) => {
                        onChange(values);
                    }}
                >
                    {filterChoices.map(({ key, text }) => (
                        <Box px={4} py={1} key={key}>
                            <Checkbox value={String(key)}>
                                {t(`measures:${text}`)}
                            </Checkbox>
                        </Box>
                    ))}
                </Checkbox.Group>
            ) : (
                <Radio.Group
                    name="dontCare"
                    value={String(selected[0])}
                    onChange={(value) => {
                        onChange([value as TValue]);
                    }}
                >
                    {filterChoices.map(({ key, text }) => (
                        <Box px={4} py={1} key={key}>
                            <Radio value={String(key)}>
                                {t(`measures:${text}`)}
                            </Radio>
                        </Box>
                    ))}
                </Radio.Group>
            )}
            {filterChoices.length > LIMIT && (
                <HStack justifyContent="flex-start" p={4}>
                    <Button
                        size="xs"
                        variant="link"
                        p={0}
                        onPress={() => {
                            setLimited((prevState) => !prevState);
                        }}
                    >{`${isLimited ? t("showMore") : t("showLess")} `}</Button>
                </HStack>
            )}
        </>
    );
};

export default MobileFilterList;
