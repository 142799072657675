import { useCallback, useState } from "react";
import { InsulinReminderContent } from "@madmedical/ui";
import { useMe } from "@madmedical/user";
import type { Ulid } from "@madmedical/utils";
import type { InsulinReminder } from "../model";
import InsulinReminderProviders from "./InsulinReminderProviders";
import DeleteInsulinReminderModal from "./DeleteInsulinReminderModal";
import EditInsulinReminderModalForm from "./EditInsulinReminderModalForm";

export const InsulinReminderPage = () => {
    const {
        roles: { isPatient },
    } = useMe();

    const [editReminderData, setEditReminderData] = useState<
        InsulinReminder | undefined
    >();

    const [deleteReminderId, setDeleteReminderId] = useState<Ulid>();

    const handleDeletePress = useCallback((id: Ulid) => {
        setDeleteReminderId(id);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDeleteReminderId(undefined);
    }, []);

    return (
        <InsulinReminderProviders>
            <InsulinReminderContent
                isPatient={isPatient}
                deleteReminder={handleDeletePress}
                editReminder={setEditReminderData}
            />
            <DeleteInsulinReminderModal
                id={deleteReminderId}
                open={!!deleteReminderId}
                onClose={handleDialogClose}
            />
            <EditInsulinReminderModalForm
                open={!!editReminderData}
                onClose={() => setEditReminderData(undefined)}
                reminderData={editReminderData}
            />
        </InsulinReminderProviders>
    );
};
