import type { ReactNode, Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { DateRange } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type { AlertLevel, MetricType } from "@madmedical/medical";

interface ContextValue {
    metricType: MetricType;
    chart: ReactNode;
    alertLevel: AlertLevel | null;
    displayRange: string | null;
    dateRange: DateRange;
    setDateRange?: (dateRange: DateRange) => void;
    selectedRange?: DateRange | null;
    onSelectRange?: (range: DateRange | null) => void;
}

const ChartContext = createContext<ContextValue | undefined>(undefined);

export const ChartProvider =
    ChartContext.Provider as ReactProvider<ContextValue>;

export const useChart = () => useEnsureContext(ChartContext);
