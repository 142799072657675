import { Box, Text, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../../form/SubmitButton";
import DateInput from "../../form/DateInput";
import Button from "../../atoms/Button";
import ModalContent from "../../molecules/ModalContent";
import FormControl from "../../form/FormControl";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const CalculateBirthTimeModal = ({
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("pregnancy:expectedDateOfBirth2")}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmitPress}
                        label={t("pregnancy:count")}
                    />
                </>
            }
        >
            <VStack>
                <DateInput
                    name="lastMenstruationDate"
                    isRequired
                    label={t("pregnancy:lastMenstruation")}
                />
                <Box mt={6}>
                    <FormControl
                        label={t("pregnancy:cycleLength")}
                        name="menstruationCycleLength"
                        labelProps={{
                            _text: { color: "gray.600" },
                        }}
                        type="number"
                        height="44px"
                        maxLength={2}
                        InputRightElement={
                            <Box px={2}>
                                <Text color="gray.500" fontSize="xs">
                                    {t("day")}
                                </Text>
                            </Box>
                        }
                    />
                </Box>
            </VStack>
        </ModalContent>
    );
};

export default CalculateBirthTimeModal;
