import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { hasActiveFilter } from "../utils";
import FilterButton from "../../../molecules/FilterButton";
import Button from "../../../atoms/Button";
import { usePatients } from "../../../providers/patient";

const PatientsDesktopFilter = () => {
    const { onFilterChange, filterChoices } = usePatients();
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof typeof filterChoices>(key: TFilterKey) =>
        (selected: (typeof filterChoices)[TFilterKey][0]["key"][]) => {
            onFilterChange?.(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange?.("services", []);
        onFilterChange?.("genders", []);
        onFilterChange?.("underlyingConditions", []);
    };
    const translatedServices = filterChoices.services.map((item) => ({
        ...item,
        text: `${t(`backend:services.${item.key}`)}`,
    }));

    const translatedGenders = filterChoices.genders.map((item) => ({
        ...item,
        text: `${t(`${item.key}`)}`,
    }));

    const translatedunderlyingConditions =
        filterChoices.underlyingConditions.map((item) => ({
            ...item,
            text: `${t(`measures:${item.key}`)}`,
        }));

    return (
        <HStack width="full" space={2}>
            <FilterButton
                title={t("package")}
                items={translatedServices}
                onChange={handleFilterChange("services")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("gender")}
                items={translatedGenders}
                onChange={handleFilterChange("genders")}
                isMultiselect={false}
            />
            <FilterButton
                title={t("illness")}
                items={translatedunderlyingConditions}
                onChange={handleFilterChange("underlyingConditions")}
                isMultiselect={false}
            />

            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default PatientsDesktopFilter;
