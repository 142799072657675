import type { ReactNode } from "react";
import {
    CurrentPatientProvider,
    InsulinReminderProvider,
    Spinner,
} from "@madmedical/ui";
import { adaptPatient, usePatient } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import { useGetInsulinRemindersQuery } from "../api";
import adaptInsulinReminders from "../adaptInsulinReminders";

interface Props {
    children: ReactNode;
}

const InsulinReminderProviders = ({ children }: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();

    const { data: insulinReminders, isLoading: isReminderLoading } =
        useGetInsulinRemindersQuery({
            patientId,
        });

    if (isPatientLoading || isReminderLoading) {
        return <Spinner />;
    }

    invariant(patient);
    invariant(insulinReminders);

    return (
        <InsulinReminderProvider
            value={{
                reminders: insulinReminders?.map(adaptInsulinReminders) ?? [],
            }}
        >
            <CurrentPatientProvider value={{ patient: adaptPatient(patient) }}>
                {children}
            </CurrentPatientProvider>
        </InsulinReminderProvider>
    );
};

export default InsulinReminderProviders;
