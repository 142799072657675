import { ScrollView } from "native-base";
import type { ComponentProps } from "react";
import Card from "../atoms/Card/Card";
import type GraphSlot from "../charts/GraphSlot";
import CommentListItem from "./ListItem/CommentListItem";

type Comment = ComponentProps<typeof CommentListItem>;

interface Props {
    comments?: Comment[];
    setCommentState?: ComponentProps<typeof GraphSlot>["setCommentState"];
}

const CommentListCard = ({ comments, setCommentState }: Props) => {
    const onDelete = (comment: Comment) => {
        if (setCommentState) {
            setCommentState((ps) => ({ ...ps, onShow: false }));
        }
        comment.onDeleted();
    };

    return comments?.length ? (
        <Card flex={0} px={0} py={0} width={92} shadow={8}>
            <ScrollView maxHeight={80}>
                {comments.map((item, index) => (
                    <CommentListItem
                        key={`comment-list-item-${index}`}
                        {...item}
                        onDeleted={() => onDelete(item)}
                        borderBottomWidth={
                            index === comments.length - 1 ? 0 : 1
                        }
                    />
                ))}
            </ScrollView>
        </Card>
    ) : (
        <></>
    );
};

export default CommentListCard;
