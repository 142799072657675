import Svg, { Path, Rect } from "react-native-svg";

const Blueberry = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M97.2246 39.7931C97.9678 40.4011 98.6705 41.0569 99.3282 41.7565C104.743 47.5103 106.796 54.7532 107.645 58.9781C107.645 58.9781 89.5629 60.4006 80.3237 41.5135C80.1422 41.1423 79.9567 40.7942 79.7671 40.4693C84.2064 38.7682 90.3195 38.8572 97.2246 39.7931Z"
            fill="#B6D2C6"
        />
        <Path
            d="M101.344 54.8466C101.875 54.8466 102.305 54.4162 102.305 53.8852C102.305 53.3543 101.875 52.9238 101.344 52.9238C100.813 52.9238 100.382 53.3543 100.382 53.8852C100.382 54.4162 100.813 54.8466 101.344 54.8466Z"
            fill="#7B9791"
        />
        <Path
            d="M77.8089 33.2029C77.6892 33.1704 77.5643 33.1618 77.4412 33.1776C77.3182 33.1934 77.1994 33.2332 77.0918 33.2949C76.9842 33.3565 76.8897 33.4388 76.8139 33.537C76.738 33.6351 76.6822 33.7473 76.6497 33.867C76.6172 33.9867 76.6086 34.1116 76.6244 34.2347C76.6401 34.3577 76.68 34.4765 76.7417 34.5841C76.8033 34.6917 76.8856 34.7862 76.9837 34.862C77.0819 34.9379 77.194 34.9937 77.3137 35.0262C77.4271 35.0573 88.7675 38.2746 98.1093 50.9703C98.1825 51.071 98.2749 51.1562 98.3812 51.2211C98.4875 51.2859 98.6055 51.3291 98.7286 51.3482C98.8516 51.3673 98.9772 51.3618 99.0981 51.3322C99.219 51.3026 99.3329 51.2494 99.4332 51.1756C99.5335 51.1019 99.6182 51.009 99.6825 50.9024C99.7468 50.7958 99.7894 50.6775 99.8078 50.5544C99.8263 50.4312 99.8202 50.3057 99.7899 50.1849C99.7597 50.0641 99.7059 49.9505 99.6316 49.8506C89.8761 36.5929 78.2971 33.335 77.8089 33.2029Z"
            fill="#7B9791"
        />
        <Path
            d="M97.2234 39.794C87.085 31.5107 73.4116 35.7216 73.4116 35.7216C73.4116 35.7216 76.9157 35.5904 79.748 40.4366C84.0547 41.1031 91.7713 41.8421 97.2234 39.794Z"
            fill="#7B9791"
        />
        <Path
            d="M106.646 22.9597C106.646 22.9597 105.596 23.6903 100.297 28.2773C94.8013 33.0345 85.8299 37.444 78.4678 36.5973C77.4905 36.4763 76.5055 36.4281 75.5211 36.4534C73.535 36.5219 70.3994 36.823 67.6846 37.9357C67.6846 37.9357 76.9262 15.5778 106.646 22.9597Z"
            fill="#B6D2C6"
        />
        <Path
            d="M104.632 72.9733C103.9 71.1457 102.805 69.4851 101.414 68.0917C99.402 66.1513 95.6846 63.8975 89.4002 64.1681C80.9687 64.5359 76.2296 60.4816 71.1691 55.2054C69.5185 53.3865 67.6836 51.3563 65.4055 48.5787C65.0743 48.1747 63.8499 48.9846 63.5114 49.3816C63.1729 49.7785 63.0057 51.2741 63.3729 51.645C66.3864 54.6891 71.9478 58.2076 69.6335 65.6856C68.1761 70.3938 68.2245 74.1239 68.5411 75.9812C68.5603 76.105 68.6039 76.2237 68.6694 76.3305C68.735 76.4373 68.821 76.53 68.9227 76.6032C69.0244 76.6764 69.1396 76.7286 69.2616 76.7569C69.3836 76.7852 69.5101 76.7889 69.6336 76.7679C69.7571 76.7468 69.8751 76.7014 69.9809 76.6343C70.0867 76.5672 70.1781 76.4798 70.2498 76.377C70.3215 76.2743 70.372 76.1583 70.3985 76.0359C70.4249 75.9134 70.4267 75.787 70.4038 75.6638C70.1744 74.3168 69.8126 70.712 71.4085 66.3323C72.206 64.1908 72.4493 61.8824 72.1159 59.6217C75.5943 63.897 81.0899 71.7597 83.9027 81.5935L85.7197 81.0737C84.5728 77.1228 83.0175 73.3021 81.0788 69.6735C83.1123 71.3296 85.3296 72.746 87.6871 73.8947C89.4424 74.7233 91.129 75.6903 92.7309 76.7865C93.8823 77.5942 95.6993 79.8327 95.6993 79.8327L97.2662 78.7761C96.3436 77.3947 95.1746 76.195 93.8176 75.2368C92.1325 74.0819 90.3581 73.0633 88.5111 72.1905C83.8902 69.9579 80.4589 66.7871 78.4127 64.5491C80.92 65.5232 84.501 66.2695 89.4836 66.0555C100.074 65.593 102.536 73.0802 102.536 73.0802L104.632 72.9733Z"
            fill="#B6D2C6"
        />
        <Path
            d="M52.0722 69.9153C51.5247 67.8499 51.4415 65.6889 51.8287 63.5875C52.5276 65.2102 53.5623 66.6663 54.8647 67.8601C57.1298 70.027 57.4604 73.1547 57.3389 75.3966C57.3303 75.5215 57.3466 75.647 57.3869 75.7656C57.4272 75.8842 57.4906 75.9936 57.5736 76.0874C57.6566 76.1813 57.7574 76.2577 57.8701 76.3122C57.9829 76.3668 58.1054 76.3984 58.2304 76.4051C58.3555 76.4119 58.4807 76.3938 58.5987 76.3517C58.7167 76.3097 58.8251 76.2446 58.9177 76.1603C59.0104 76.076 59.0853 75.974 59.1382 75.8605C59.191 75.7469 59.2208 75.624 59.2257 75.4988C59.3686 72.8614 58.5222 69.5473 56.1708 66.4945C50.8383 59.5674 58.3731 51.9807 58.3731 51.9807L57.0093 48.8164C57.0093 48.8164 53.4086 55.9042 44.9341 59.5915C34.3495 64.1964 32.8437 74.0777 32.7847 74.496L34.6558 74.7596C34.6688 74.6676 36.0628 65.5117 45.6879 61.3238C47.9668 60.3462 50.1476 59.1543 52.201 57.7642C51.8173 58.2329 43.0205 66.3267 38.3867 76.9733L40.0873 77.8005C43.1493 71.1692 47.2617 65.9398 50.0839 62.7969C49.9356 63.4856 49.8286 64.1825 49.7633 64.8839C49.4355 68.6403 50.2954 72.4048 52.2216 75.6463C52.2237 75.6501 52.2251 75.6542 52.2275 75.658L52.2316 75.6644C52.4461 76.0346 53.7341 78.6287 53.8713 79.6031C54.4252 83.538 53.8629 88.4446 48.0483 89.4972L48.3475 91.3642C53.1142 90.3441 56.1721 87.2383 55.9219 81.5917C57.7186 84.1081 58.884 87.9697 59.3792 90.6994L61.2386 90.408C61.0024 86.4717 56.847 79 54.9339 76.2081C53.6106 74.2989 52.6412 72.1674 52.0722 69.9153Z"
            fill="#B6D2C6"
        />
        <Path
            d="M95.6169 26.6031C95.7393 26.5787 95.8557 26.5304 95.9593 26.4608C96.0629 26.3912 96.1517 26.3018 96.2206 26.1977C96.2894 26.0937 96.337 25.977 96.3605 25.8544C96.384 25.7319 96.383 25.6058 96.3576 25.4837C96.3321 25.3615 96.2828 25.2456 96.2123 25.1426C96.1418 25.0396 96.0516 24.9516 95.9469 24.8837C95.8422 24.8158 95.7251 24.7692 95.6023 24.7468C95.4795 24.7244 95.3536 24.7265 95.2316 24.7531C85.274 26.8277 77.4908 29.5763 72.0963 32.9227C68.1208 35.3885 65.3587 38.2056 63.6494 41.5355C62.2764 44.2121 62.0142 46.7817 62.8706 49.1728C62.958 49.4049 63.1331 49.5933 63.3581 49.6974C63.5832 49.8015 63.8401 49.813 64.0735 49.7294C64.307 49.6458 64.4982 49.4737 64.6059 49.2504C64.7137 49.0271 64.7293 48.7704 64.6495 48.5356C63.9762 46.6561 64.2057 44.5912 65.3308 42.3981C68.9959 35.2513 78.903 30.085 95.6169 26.6031Z"
            fill="#7B9791"
        />
        <Path
            d="M30.5716 73.041C30.5716 73.041 29.5301 81.6135 25.1328 85.336L41.7891 105.101L50.2929 99.612L43.8167 79.7178L30.5716 73.041Z"
            fill="#656FA2"
        />
        <Path
            d="M69.0645 89.3274L70.747 83.0957L85.0835 83.4537L90.0651 87.8315L98.0518 83.9621L100.285 89.7016C100.285 89.7016 97.917 103.436 86.9492 100.962C75.9815 98.4881 73.8006 98.112 73.8006 98.112L69.0645 89.3274Z"
            fill="#656FA2"
        />
        <Path
            d="M99.7952 93.8941C102.466 93.8941 104.631 91.7289 104.631 89.0579C104.631 86.3869 102.466 84.2217 99.7952 84.2217C97.1242 84.2217 94.959 86.3869 94.959 89.0579C94.959 91.7289 97.1242 93.8941 99.7952 93.8941Z"
            fill="#656FA2"
        />
        <Path
            d="M98.0519 101.97C100.698 101.97 102.842 99.8254 102.842 97.1799C102.842 94.5343 100.698 92.3896 98.0519 92.3896C95.4064 92.3896 93.2617 94.5343 93.2617 97.1799C93.2617 99.8254 95.4064 101.97 98.0519 101.97Z"
            fill="#656FA2"
        />
        <Path
            d="M92.5475 107.75C95.3319 107.75 97.5891 105.493 97.5891 102.709C97.5891 99.9242 95.3319 97.667 92.5475 97.667C89.7631 97.667 87.5059 99.9242 87.5059 102.709C87.5059 105.493 89.7631 107.75 92.5475 107.75Z"
            fill="#656FA2"
        />
        <Path
            d="M85.0839 105.101C88.1389 105.101 90.6155 102.625 90.6155 99.5697C90.6155 96.5147 88.1389 94.0381 85.0839 94.0381C82.0288 94.0381 79.5522 96.5147 79.5522 99.5697C79.5522 102.625 82.0288 105.101 85.0839 105.101Z"
            fill="#656FA2"
        />
        <Path
            d="M74.7211 103.945C77.4011 103.945 79.5736 101.773 79.5736 99.0927C79.5736 96.4127 77.4011 94.2402 74.7211 94.2402C72.0412 94.2402 69.8687 96.4127 69.8687 99.0927C69.8687 101.773 72.0412 103.945 74.7211 103.945Z"
            fill="#656FA2"
        />
        <Path
            d="M84.3755 88.2773C87.0393 88.2773 89.1987 86.1179 89.1987 83.4541C89.1987 80.7903 87.0393 78.6309 84.3755 78.6309C81.7117 78.6309 79.5522 80.7903 79.5522 83.4541C79.5522 86.1179 81.7117 88.2773 84.3755 88.2773Z"
            fill="#656FA2"
        />
        <Path
            d="M68.0042 88.2067C70.3484 88.2067 72.2487 86.3064 72.2487 83.9622C72.2487 81.6181 70.3484 79.7178 68.0042 79.7178C65.6601 79.7178 63.7598 81.6181 63.7598 83.9622C63.7598 86.3064 65.6601 88.2067 68.0042 88.2067Z"
            fill="#656FA2"
        />
        <Path
            d="M75.7843 85.8625C78.3327 85.8625 80.3986 83.7966 80.3986 81.2481C80.3986 78.6997 78.3327 76.6338 75.7843 76.6338C73.2358 76.6338 71.1699 78.6997 71.1699 81.2481C71.1699 83.7966 73.2358 85.8625 75.7843 85.8625Z"
            fill="#656FA2"
        />
        <Path
            d="M70.1589 96.981C72.958 96.981 75.2271 94.7119 75.2271 91.9128C75.2271 89.1138 72.958 86.8447 70.1589 86.8447C67.3599 86.8447 65.0908 89.1138 65.0908 91.9128C65.0908 94.7119 67.3599 96.981 70.1589 96.981Z"
            fill="#656FA2"
        />
        <Path
            d="M57.6049 107.522C60.7015 107.522 63.2118 105.012 63.2118 101.915C63.2118 98.8189 60.7015 96.3086 57.6049 96.3086C54.5083 96.3086 51.998 98.8189 51.998 101.915C51.998 105.012 54.5083 107.522 57.6049 107.522Z"
            fill="#656FA2"
        />
        <Path
            d="M30.3752 105.248C33.0992 105.248 35.3074 103.04 35.3074 100.316C35.3074 97.5921 33.0992 95.3838 30.3752 95.3838C27.6511 95.3838 25.4429 97.5921 25.4429 100.316C25.4429 103.04 27.6511 105.248 30.3752 105.248Z"
            fill="#656FA2"
        />
        <Path
            d="M30.5719 76.5686C32.8971 76.5686 34.782 74.6837 34.782 72.3585C34.782 70.0334 32.8971 68.1484 30.5719 68.1484C28.2467 68.1484 26.3618 70.0334 26.3618 72.3585C26.3618 74.6837 28.2467 76.5686 30.5719 76.5686Z"
            fill="#656FA2"
        />
        <Path
            d="M30.5718 84.7745C33.0318 84.7745 35.026 82.7803 35.026 80.3204C35.026 77.8604 33.0318 75.8662 30.5718 75.8662C28.1119 75.8662 26.1177 77.8604 26.1177 80.3204C26.1177 82.7803 28.1119 84.7745 30.5718 84.7745Z"
            fill="#656FA2"
        />
        <Path
            d="M38.0615 80.0312C40.5031 80.0312 42.4824 78.0519 42.4824 75.6103C42.4824 73.1687 40.5031 71.1895 38.0615 71.1895C35.6199 71.1895 33.6406 73.1687 33.6406 75.6103C33.6406 78.0519 35.6199 80.0312 38.0615 80.0312Z"
            fill="#656FA2"
        />
        <Path
            d="M44.319 86.308C46.8597 86.308 48.9193 84.2483 48.9193 81.7077C48.9193 79.167 46.8597 77.1074 44.319 77.1074C41.7784 77.1074 39.7188 79.167 39.7188 81.7077C39.7188 84.2483 41.7784 86.308 44.319 86.308Z"
            fill="#656FA2"
        />
        <Path
            d="M25.1327 91.0622C27.5073 91.0622 29.4323 89.1372 29.4323 86.7625C29.4323 84.3879 27.5073 82.4629 25.1327 82.4629C22.758 82.4629 20.833 84.3879 20.833 86.7625C20.833 89.1372 22.758 91.0622 25.1327 91.0622Z"
            fill="#656FA2"
        />
        <Path
            d="M30.7125 96.4456C33.2502 96.4456 35.3074 94.3884 35.3074 91.8507C35.3074 89.3131 33.2502 87.2559 30.7125 87.2559C28.1749 87.2559 26.1177 89.3131 26.1177 91.8507C26.1177 94.3884 28.1749 96.4456 30.7125 96.4456Z"
            fill="#656FA2"
        />
        <Path
            d="M36.485 101.915C38.8326 101.915 40.7357 100.012 40.7357 97.6647C40.7357 95.3171 38.8326 93.4141 36.485 93.4141C34.1375 93.4141 32.2344 95.3171 32.2344 97.6647C32.2344 100.012 34.1375 101.915 36.485 101.915Z"
            fill="#656FA2"
        />
        <Path
            d="M45.8157 95.8599C48.5319 95.8599 50.7339 93.6579 50.7339 90.9416C50.7339 88.2254 48.5319 86.0234 45.8157 86.0234C43.0994 86.0234 40.8975 88.2254 40.8975 90.9416C40.8975 93.6579 43.0994 95.8599 45.8157 95.8599Z"
            fill="#656FA2"
        />
        <Path
            d="M41.6626 108.535C44.2148 108.535 46.2837 106.466 46.2837 103.914C46.2837 101.362 44.2148 99.293 41.6626 99.293C39.1104 99.293 37.0415 101.362 37.0415 103.914C37.0415 106.466 39.1104 108.535 41.6626 108.535Z"
            fill="#656FA2"
        />
        <Path
            d="M48.919 104.482C51.6082 104.482 53.7883 102.302 53.7883 99.6124C53.7883 96.9232 51.6082 94.7432 48.919 94.7432C46.2298 94.7432 44.0498 96.9232 44.0498 99.6124C44.0498 102.302 46.2298 104.482 48.919 104.482Z"
            fill="#656FA2"
        />
        <Path
            d="M60.3099 99.2566C63.4793 99.2566 66.0486 96.6873 66.0486 93.5179C66.0486 90.3486 63.4793 87.7793 60.3099 87.7793C57.1406 87.7793 54.5713 90.3486 54.5713 93.5179C54.5713 96.6873 57.1406 99.2566 60.3099 99.2566Z"
            fill="#656FA2"
        />
        <Path
            d="M79.9024 95.3703C82.7639 95.3703 85.0836 93.0506 85.0836 90.189C85.0836 87.3275 82.7639 85.0078 79.9024 85.0078C77.0409 85.0078 74.7212 87.3275 74.7212 90.189C74.7212 93.0506 77.0409 95.3703 79.9024 95.3703Z"
            fill="#656FA2"
        />
        <Path
            d="M91.152 96.7396C94.3011 96.7396 96.8539 94.1868 96.8539 91.0378C96.8539 87.8887 94.3011 85.3359 91.152 85.3359C88.003 85.3359 85.4502 87.8887 85.4502 91.0378C85.4502 94.1868 88.003 96.7396 91.152 96.7396Z"
            fill="#656FA2"
        />
        <Path
            d="M96.4958 86.8266C98.9594 86.8266 100.956 84.8295 100.956 82.3659C100.956 79.9024 98.9594 77.9053 96.4958 77.9053C94.0323 77.9053 92.0352 79.9024 92.0352 82.3659C92.0352 84.8295 94.0323 86.8266 96.4958 86.8266Z"
            fill="#656FA2"
        />
        <Path
            d="M104.138 82.5708C106.915 82.5708 109.166 80.3196 109.166 77.5427C109.166 74.7658 106.915 72.5146 104.138 72.5146C101.361 72.5146 99.1104 74.7658 99.1104 77.5427C99.1104 80.3196 101.361 82.5708 104.138 82.5708Z"
            fill="#656FA2"
        />
        <Path
            d="M61.3513 27.0771H62.8709C62.8709 27.0771 61.9509 34.4799 62.0692 40.7318C62.1078 43.2427 63.0055 45.6645 64.6127 47.594C66.1636 49.4508 67.5319 51.4528 68.6986 53.5723C68.7702 53.7001 68.7985 53.8476 68.7793 53.9928C68.7601 54.138 68.6944 54.273 68.5921 54.3778C68.4898 54.4826 68.3563 54.5514 68.2116 54.5741C68.067 54.5967 67.9188 54.5719 67.7894 54.5034C66.2344 53.6858 64.757 52.7286 63.3753 51.6436C62.6492 51.0675 61.7345 50.7829 60.8098 50.8452C59.8851 50.9075 59.0168 51.3122 58.3746 51.9805C56.9245 53.4721 55.5627 55.0471 54.296 56.6974C54.2436 56.7667 54.1684 56.8153 54.0836 56.8345C53.9988 56.8536 53.91 56.8422 53.8329 56.8021C53.7558 56.762 53.6954 56.6959 53.6623 56.6155C53.6293 56.5351 53.6258 56.4456 53.6525 56.3629C54.3487 54.2407 55.6116 50.9114 57.4094 48.1887C58.3994 46.6766 58.8668 44.8818 58.7401 43.0789C58.4329 38.4793 58.4949 33.8624 58.9255 29.2727C58.9857 28.6711 59.2672 28.1133 59.7155 27.7076C60.1637 27.3019 60.7467 27.0772 61.3513 27.0771Z"
            fill="#B6D2C6"
        />
        <Path
            d="M29.4327 72.9532C30.2234 72.9532 30.8643 72.3122 30.8643 71.5215C30.8643 70.7308 30.2234 70.0898 29.4327 70.0898C28.642 70.0898 28.001 70.7308 28.001 71.5215C28.001 72.3122 28.642 72.9532 29.4327 72.9532Z"
            fill="#A6B0E1"
        />
        <Path
            d="M24.0113 87.2559C24.802 87.2559 25.443 86.615 25.443 85.8243C25.443 85.0336 24.802 84.3926 24.0113 84.3926C23.2206 84.3926 22.5796 85.0336 22.5796 85.8243C22.5796 86.615 23.2206 87.2559 24.0113 87.2559Z"
            fill="#A6B0E1"
        />
        <Path
            d="M30.0874 92.4942C30.8781 92.4942 31.5191 91.8532 31.5191 91.0625C31.5191 90.2718 30.8781 89.6309 30.0874 89.6309C29.2967 89.6309 28.6558 90.2718 28.6558 91.0625C28.6558 91.8532 29.2967 92.4942 30.0874 92.4942Z"
            fill="#A6B0E1"
        />
        <Path
            d="M43.3565 82.0079C44.1472 82.0079 44.7882 81.3669 44.7882 80.5762C44.7882 79.7855 44.1472 79.1445 43.3565 79.1445C42.5658 79.1445 41.9248 79.7855 41.9248 80.5762C41.9248 81.3669 42.5658 82.0079 43.3565 82.0079Z"
            fill="#A6B0E1"
        />
        <Path
            d="M34.7818 85.0753C35.5725 85.0753 36.2135 84.4343 36.2135 83.6436C36.2135 82.8529 35.5725 82.2119 34.7818 82.2119C33.9911 82.2119 33.3501 82.8529 33.3501 83.6436C33.3501 84.4343 33.9911 85.0753 34.7818 85.0753Z"
            fill="#A6B0E1"
        />
        <Path
            d="M36.7388 76.1729C37.5295 76.1729 38.1705 75.5319 38.1705 74.7413C38.1705 73.9506 37.5295 73.3096 36.7388 73.3096C35.9481 73.3096 35.3071 73.9506 35.3071 74.7413C35.3071 75.5319 35.9481 76.1729 36.7388 76.1729Z"
            fill="#A6B0E1"
        />
        <Path
            d="M29.1402 100.726C29.9309 100.726 30.5719 100.085 30.5719 99.294C30.5719 98.5033 29.9309 97.8623 29.1402 97.8623C28.3495 97.8623 27.7085 98.5033 27.7085 99.294C27.7085 100.085 28.3495 100.726 29.1402 100.726Z"
            fill="#A6B0E1"
        />
        <Path
            d="M47.8052 100.097C48.5959 100.097 49.2369 99.4558 49.2369 98.6651C49.2369 97.8744 48.5959 97.2334 47.8052 97.2334C47.0145 97.2334 46.3735 97.8744 46.3735 98.6651C46.3735 99.4558 47.0145 100.097 47.8052 100.097Z"
            fill="#A6B0E1"
        />
        <Path
            d="M56.003 101.956C56.7937 101.956 57.4347 101.315 57.4347 100.524C57.4347 99.7338 56.7937 99.0928 56.003 99.0928C55.2123 99.0928 54.5713 99.7338 54.5713 100.524C54.5713 101.315 55.2123 101.956 56.003 101.956Z"
            fill="#A6B0E1"
        />
        <Path
            d="M58.2622 93.3448C59.0529 93.3448 59.6939 92.7038 59.6939 91.9131C59.6939 91.1224 59.0529 90.4814 58.2622 90.4814C57.4716 90.4814 56.8306 91.1224 56.8306 91.9131C56.8306 92.7038 57.4716 93.3448 58.2622 93.3448Z"
            fill="#A6B0E1"
        />
        <Path
            d="M67.2017 84.5274C67.9924 84.5274 68.6334 83.8864 68.6334 83.0957C68.6334 82.305 67.9924 81.6641 67.2017 81.6641C66.411 81.6641 65.77 82.305 65.77 83.0957C65.77 83.8864 66.411 84.5274 67.2017 84.5274Z"
            fill="#A6B0E1"
        />
        <Path
            d="M78.4707 90.4815C79.2614 90.4815 79.9024 89.8405 79.9024 89.0499C79.9024 88.2592 79.2614 87.6182 78.4707 87.6182C77.68 87.6182 77.0391 88.2592 77.0391 89.0499C77.0391 89.8405 77.68 90.4815 78.4707 90.4815Z"
            fill="#A6B0E1"
        />
        <Path
            d="M75.2271 81.8643C76.0178 81.8643 76.6588 81.2234 76.6588 80.4327C76.6588 79.642 76.0178 79.001 75.2271 79.001C74.4364 79.001 73.7954 79.642 73.7954 80.4327C73.7954 81.2234 74.4364 81.8643 75.2271 81.8643Z"
            fill="#A6B0E1"
        />
        <Path
            d="M73.7959 99.4591C74.5866 99.4591 75.2276 98.8181 75.2276 98.0274C75.2276 97.2367 74.5866 96.5957 73.7959 96.5957C73.0052 96.5957 72.3643 97.2367 72.3643 98.0274C72.3643 98.8181 73.0052 99.4591 73.7959 99.4591Z"
            fill="#A6B0E1"
        />
        <Path
            d="M84.1954 99.4229C84.9861 99.4229 85.627 98.782 85.627 97.9913C85.627 97.2006 84.9861 96.5596 84.1954 96.5596C83.4047 96.5596 82.7637 97.2006 82.7637 97.9913C82.7637 98.782 83.4047 99.4229 84.1954 99.4229Z"
            fill="#A6B0E1"
        />
        <Path
            d="M91.4239 102.691C92.2146 102.691 92.8556 102.051 92.8556 101.26C92.8556 100.469 92.2146 99.8281 91.4239 99.8281C90.6332 99.8281 89.9922 100.469 89.9922 101.26C89.9922 102.051 90.6332 102.691 91.4239 102.691Z"
            fill="#A6B0E1"
        />
        <Path
            d="M96.9053 97.2628C97.696 97.2628 98.337 96.6218 98.337 95.8311C98.337 95.0404 97.696 94.3994 96.9053 94.3994C96.1146 94.3994 95.4736 95.0404 95.4736 95.8311C95.4736 96.6218 96.1146 97.2628 96.9053 97.2628Z"
            fill="#A6B0E1"
        />
        <Path
            d="M89.4673 91.1407C90.258 91.1407 90.899 90.4997 90.899 89.709C90.899 88.9183 90.258 88.2773 89.4673 88.2773C88.6766 88.2773 88.0356 88.9183 88.0356 89.709C88.0356 90.4997 88.6766 91.1407 89.4673 91.1407Z"
            fill="#A6B0E1"
        />
        <Path
            d="M95.4737 83.0225C96.2644 83.0225 96.9054 82.3816 96.9054 81.5909C96.9054 80.8002 96.2644 80.1592 95.4737 80.1592C94.683 80.1592 94.042 80.8002 94.042 81.5909C94.042 82.3816 94.683 83.0225 95.4737 83.0225Z"
            fill="#A6B0E1"
        />
        <Path
            d="M98.6138 89.2891C99.4045 89.2891 100.045 88.6482 100.045 87.8575C100.045 87.0668 99.4045 86.4258 98.6138 86.4258C97.8231 86.4258 97.1821 87.0668 97.1821 87.8575C97.1821 88.6482 97.8231 89.2891 98.6138 89.2891Z"
            fill="#A6B0E1"
        />
        <Path
            d="M103.2 77.9054C103.991 77.9054 104.632 77.2644 104.632 76.4737C104.632 75.683 103.991 75.042 103.2 75.042C102.41 75.042 101.769 75.683 101.769 76.4737C101.769 77.2644 102.41 77.9054 103.2 77.9054Z"
            fill="#A6B0E1"
        />
        <Path
            d="M37.6451 96.2774C38.4358 96.2774 39.0767 95.6364 39.0767 94.8457C39.0767 94.055 38.4358 93.4141 37.6451 93.4141C36.8544 93.4141 36.2134 94.055 36.2134 94.8457C36.2134 95.6364 36.8544 96.2774 37.6451 96.2774Z"
            fill="#A6B0E1"
        />
        <Path
            d="M40.4932 104.466C41.2839 104.466 41.9249 103.825 41.9249 103.034C41.9249 102.244 41.2839 101.603 40.4932 101.603C39.7025 101.603 39.0615 102.244 39.0615 103.034C39.0615 103.825 39.7025 104.466 40.4932 104.466Z"
            fill="#A6B0E1"
        />
        <Path
            d="M44.7876 91.7882C45.5783 91.7882 46.2193 91.1472 46.2193 90.3565C46.2193 89.5658 45.5783 88.9248 44.7876 88.9248C43.9969 88.9248 43.356 89.5658 43.356 90.3565C43.356 91.1472 43.9969 91.7882 44.7876 91.7882Z"
            fill="#A6B0E1"
        />
    </Svg>
);

export default Blueberry;
