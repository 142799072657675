import { Controller, useField } from "@madmedical/form";
import { Box } from "native-base";
import BaseTimeInput from "../molecules/Form/TimeInput";
import useResponsive from "../util/useResponsive";

interface Props {
    readonly name: string;
    readonly isRequired: boolean;
    readonly label?: string;
    readonly previousTime?: string;
}
type Time = `${number}:${number}`;

const TimeInput = ({ name, isRequired, label, previousTime }: Props) => {
    const { control, isError, error } = useField(name);
    const { isSmallScreen } = useResponsive();

    return (
        <Box width={isSmallScreen ? "auto" : 170}>
            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => {
                    const time = value ? new Date(value as Date) : new Date();
                    const hoursAndMinutes =
                        `0${time.getHours()}`.slice(-2) +
                        ":" +
                        `0${time.getMinutes()}`.slice(-2);

                    const convertChange = (time: string) => {
                        const hours = parseInt(time.split(":")[0], 10);
                        const minutes = parseInt(time.split(":")[1], 10);
                        const now = previousTime
                            ? new Date(previousTime)
                            : new Date();
                        const date = new Date(
                            now.getFullYear(),
                            now.getMonth(),
                            now.getDate(),
                            hours,
                            minutes,
                            0,
                            0
                        )
                            .toTimeString()
                            .replace(/\s\(.+?\)/, "");

                        onChange(date);
                    };

                    return (
                        <BaseTimeInput
                            onChange={convertChange}
                            value={hoursAndMinutes as Time}
                            label={label}
                            isInvalid={isError}
                            error={error}
                        />
                    );
                }}
            />
        </Box>
    );
};

export default TimeInput;
