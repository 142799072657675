import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta08 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M48.3082 91.143C51.9616 95.688 56.4029 99.882 62.0097 101.797C67.2493 103.593 73.4098 103.129 77.7131 99.3749C80.8825 96.6015 82.3842 92.1421 81.3799 88.1023C82.8415 87.5708 84.1019 86.57 84.9755 85.2841C86.7813 86.1467 88.7699 86.6122 90.8027 86.5513C92.5358 86.5146 96.3103 86.0628 96.1418 83.5943C96.0431 82.1431 94.3085 81.2665 93.2718 80.5091C91.9636 79.5877 90.6309 78.6972 89.2082 77.9462C86.7516 76.6505 83.9145 75.7865 81.1189 76.1282C79.1685 76.3644 77.2814 77.232 75.9094 78.616C75.3299 75.5627 73.5146 72.8181 70.927 71.0783C71.1992 69.9196 71.9612 69.0169 72.6998 68.1057C73.503 67.1253 74.2688 66.0651 74.4183 64.7696C74.5584 63.5369 74.0821 62.3994 73.0276 61.7282C72.2137 61.1967 71.2714 61.0952 70.3476 61.1987C70.3078 60.2451 70.0702 59.326 69.6415 58.4968C69.6575 58.4893 69.6734 58.4818 69.7052 58.4668C73.8795 54.1903 75.7099 47.865 74.5049 42.033C73.369 36.5963 69.5565 31.7955 64.5598 29.399C59.5556 26.9865 53.5282 27.1553 48.5716 29.602C43.5587 32.0945 40.1196 36.9006 38.6831 42.2434C37.133 48.048 38.1158 54.2761 40.5829 59.6899C38.1528 64.119 37.8414 69.4969 39.0459 74.3762C40.5711 80.6411 44.3177 86.2119 48.2997 91.1664L48.3082 91.143Z"
            fill="#FFDCD0"
        />
        <Path
            d="M49.0941 64.6356C50.9009 62.8527 53.7175 63.5902 55.8578 64.2956C58.1001 65.0113 60.1831 66.1326 62.0542 67.548C64.4628 64.9766 67.2254 62.7445 70.2529 60.9518C70.8442 60.5961 71.4602 61.4929 70.8614 61.8327C67.7618 63.6787 64.9589 65.9492 62.5249 68.5908C62.315 68.8062 61.9993 68.8379 61.7595 68.6589C60.0833 67.327 58.2111 66.2817 56.1898 65.5398C54.4495 64.8992 51.4523 63.8188 49.9116 65.3404C49.4195 65.8245 48.602 65.1197 49.0941 64.6356Z"
            fill="#DD8F8C"
        />
        <Path
            d="M50.2148 74.6309C54.1741 73.7033 57.6646 75.8743 61.2641 77.119C62.2401 77.4576 63.3107 77.7906 64.359 77.7452C65.4784 77.6857 66.2253 77.0818 66.8299 76.1752C68.1282 74.2619 69.2954 72.2352 70.5318 70.2732C70.9002 69.6916 71.8734 70.1483 71.5049 70.7298C70.2272 72.7696 69.0188 74.874 67.6634 76.8726C66.4879 78.592 64.7678 79.07 62.7623 78.6513C58.712 77.813 54.8997 74.6263 50.576 75.6474C49.9108 75.8045 49.5497 74.788 50.2308 74.6234L50.2148 74.6309Z"
            fill="#DD8F8C"
        />
        <Path
            d="M71.2507 87.282C71.8507 83.966 73.3434 80.8528 75.5853 78.3403C76.0389 77.8159 76.8564 78.5207 76.4028 79.0451C74.2978 81.4349 72.8726 84.3608 72.3082 87.5044C72.1815 88.1864 71.1314 87.9798 71.2423 87.3054L71.2507 87.282Z"
            fill="#DD8F8C"
        />
        <Path
            d="M46.1136 84.2402C45.8375 83.6115 46.8616 83.2663 47.1376 83.895C49.8376 90.0939 54.9213 95.0751 61.1488 97.6904C61.7801 97.9577 61.3235 98.9308 60.6922 98.6636C54.1996 95.9394 48.9334 90.694 46.1295 84.2327L46.1136 84.2402Z"
            fill="#DD8F8C"
        />
        <Path
            d="M62.3916 53.2118C62.6681 52.8484 63.1207 52.6941 63.56 52.7598C63.9918 52.8097 64.3776 53.1339 64.517 53.5546C64.575 53.7607 64.6012 53.9818 64.5411 54.1851C64.5242 54.2319 64.5073 54.2788 64.4905 54.3256C64.4642 54.4352 64.422 54.5523 64.3564 54.6609C64.2167 54.9016 64.0396 55.0626 63.7772 55.1665C63.5861 55.2564 63.3575 55.2666 63.1458 55.2299C62.7065 55.1642 62.3281 54.8558 62.1569 54.4501C61.9856 54.0444 62.1076 53.5592 62.3757 53.2193L62.3916 53.2118Z"
            fill="#5D6982"
        />
        <Mask
            id="mask0_1024_102099"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_102099)">
            <Path
                d="M73.5 76C83.5 70 71.6837 60.0518 80.5 57C93.5 52.5 89.5155 42.4823 96.5 34.5C100 30.5 109 30.5001 112.5 29.5"
                stroke="#DD8F8C"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </G>
    </Svg>
);

export default Placenta08;
