import type { ComponentProps } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Bar from "../atoms/Bar";

interface LabelAppearanceProps {
    hasNormalLabel?: boolean;
    hasWarningLabel?: boolean;
    hasAlertLabel?: boolean;
}

interface Props extends LabelAppearanceProps {
    title?: string;
    subtitle?: string;
    hasLegend?: boolean;
}

const MeasurementProtocolsOffsetSettingsTitle = ({
    title,
    subtitle,
    hasLegend = true,
    hasNormalLabel = true,
    hasWarningLabel = true,
    hasAlertLabel = true,
}: Props) => {
    const { t } = useTranslation();

    return (
        <HStack justifyContent="space-between" alignItems="flex-end">
            <Box>
                <Text fontSize="sm" fontWeight="medium">
                    {title ?? t("measures:offsetSettings")}
                </Text>
                {subtitle && (
                    <Text fontSize="xs" color="gray.600">
                        {subtitle}
                    </Text>
                )}
            </Box>
            {hasLegend && (
                <AlertStatusLegend
                    hasNormalLabel={hasNormalLabel}
                    hasWarningLabel={hasWarningLabel}
                    hasAlertLabel={hasAlertLabel}
                />
            )}
        </HStack>
    );
};

interface AlertStatusLegendProps
    extends Pick<ComponentProps<typeof HStack>, "justifyContent">,
        LabelAppearanceProps {}

export const AlertStatusLegend = ({
    hasNormalLabel = true,
    hasWarningLabel = true,
    hasAlertLabel = true,
    ...rest
}: AlertStatusLegendProps) => {
    const { t } = useTranslation();

    return (
        <HStack
            space={2}
            alignItems="center"
            justifyContent="flex-end"
            {...rest}
        >
            {hasNormalLabel && (
                <HStack alignItems="center">
                    <Bar
                        data={[{ color: "green.200", value: 1 }]}
                        width={2}
                        height={1}
                    />
                    <Text ml={1} fontSize="xs" color="gray.600">
                        {t("normal")}
                    </Text>
                </HStack>
            )}

            {hasWarningLabel && (
                <HStack alignItems="center">
                    <Bar
                        data={[{ color: "warning.200", value: 1 }]}
                        width={2}
                        height={1}
                    />
                    <Text ml={1} fontSize="xs" color="gray.600">
                        {t("measures:warning")}
                    </Text>
                </HStack>
            )}

            {hasAlertLabel && (
                <HStack alignItems="center">
                    <Bar
                        data={[{ color: "error.200", value: 1 }]}
                        width={2}
                        height={1}
                    />
                    <Text ml={1} fontSize="xs" color="gray.600">
                        {t("measures:alert")}
                    </Text>
                </HStack>
            )}
        </HStack>
    );
};

export default MeasurementProtocolsOffsetSettingsTitle;
