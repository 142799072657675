import useDevices from "./useDevices";

export default (provider: string | undefined) => {
    const { isLoading, isFetching, devices, connectedDeviceCount, refetch } =
        useDevices();

    return {
        isLoading,
        isFetching,
        refetch,
        device: devices?.find((device) => device.provider === provider),
        connectedDeviceCount,
    };
};
