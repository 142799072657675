import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type { PatientIdRequest } from "../insulin";
import type {
    DoctorPregnancyRequest,
    Pregnancy,
    PregnancyEmpathyRequest,
    PregnancyWelcomeRequest,
    PregnancyWellBeing,
    PregnancyWellBeingFilter,
    PregnancyWellBeingIdRequest,
    PregnancyWellBeingRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: ["pregnancies", "pregnancy-well-beings"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getPregnancyByPatient: build.query<Pregnancy, PatientIdRequest>({
                query: ({ patientId }) => ({
                    url: `medical/patients/${patientId}/pregnancies`,
                }),
                providesTags: ["pregnancies"],
            }),
            createWelcome: build.mutation<
                Pregnancy,
                PatientIdRequest & PregnancyWelcomeRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/pregnancies/welcome`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["pregnancies"],
            }),
            createEmpathy: build.mutation<
                Pregnancy,
                PatientIdRequest & PregnancyEmpathyRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/pregnancies/empathy`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["pregnancies"],
            }),
            upsertPregnancy: build.mutation<
                Pregnancy,
                PatientIdRequest & DoctorPregnancyRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/pregnancies`,
                    method: "PUT",
                    body,
                }),
                invalidatesTags: ["pregnancies"],
            }),
            deletePregnancy: build.mutation<Pregnancy, Ulid>({
                query: (id) => ({
                    url: `medical/pregnancies/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["pregnancies"],
            }),
            getPregnancyWellBeings: build.query<
                Paginated<PregnancyWellBeing>,
                PatientIdRequest & PregnancyWellBeingFilter & PageQuery
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/pregnancy-well-beings`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, status, symptoms, ...args } =
                        queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["pregnancy-well-beings"],
            }),
            createPregnancyWellBeing: build.mutation<
                PregnancyWellBeing,
                PatientIdRequest & PregnancyWellBeingRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/pregnancy-well-beings`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["pregnancy-well-beings"],
            }),
            updatePregnancyWellBeing: build.mutation<
                PregnancyWellBeing,
                PregnancyWellBeingIdRequest & PregnancyWellBeingRequest
            >({
                query: ({ id, ...body }) => ({
                    url: `medical/pregnancy-well-beings/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["pregnancy-well-beings"],
            }),
            deletePregnancyWellBeing: build.mutation<PregnancyWellBeing, Ulid>({
                query: (id) => ({
                    url: `medical/pregnancy-well-beings/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["pregnancy-well-beings"],
            }),
        }),
    });

export const {
    useGetPregnancyByPatientQuery,
    useUpsertPregnancyMutation,
    useCreateWelcomeMutation,
    useCreateEmpathyMutation,
    useDeletePregnancyMutation,
    useGetPregnancyWellBeingsQuery,
    useCreatePregnancyWellBeingMutation,
    useUpdatePregnancyWellBeingMutation,
    useDeletePregnancyWellBeingMutation,
} = api;
