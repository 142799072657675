import type { ComponentProps } from "react";
import { useMemo } from "react";
import { HStack, Text, VStack } from "native-base";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import AvatarTexts from "../AvatarTexts";
import Badge from "../../atoms/Badge";
import IconButton from "../../atoms/IconButton";

interface Props
    extends Pick<ComponentProps<typeof VStack>, "borderBottomWidth"> {
    comment?: string;
    date?: Date;
    canDelete: boolean;
    onDeleted: () => void;
    user: {
        avatarUrl?: string;
        fullName?: string;
        monogram?: string;
        // type: "patient" | "doctor";
        type: string;
    };
}

type UserTypes = Record<
    Props["user"]["type"],
    {
        name: string;
        variant: ComponentProps<typeof Badge>["variant"];
    }
>;

const CommentListItem = ({
    user,
    date,
    comment,
    canDelete,
    onDeleted,
    ...rest
}: Props) => {
    const { t } = useTranslation();
    const userTypes = useMemo<UserTypes>(
        () => ({
            doctor: {
                name: t("attendingDoctor"),
                variant: "ghost",
            },
            patient: {
                name: t("patient"),
                variant: "ghostSuccess",
            },
        }),
        [t]
    );

    const { fullName = "", avatarUrl = "", type, monogram = "" } = user;

    return (
        <VStack
            px={3}
            py={4}
            borderBottomWidth={1}
            borderBottomColor="gray.100"
            width="full"
            bgColor="white"
            {...rest}
        >
            <HStack justifyContent="space-between">
                <AvatarTexts
                    size="lg"
                    source={avatarUrl}
                    hasAvatar={!!avatarUrl}
                    initials={monogram}
                    label={
                        <>
                            <Text isTruncated>{fullName}</Text>
                            <Badge
                                ml={2}
                                variant={userTypes[type].variant}
                                hasIndicator={false}
                            >
                                {userTypes[type].name}
                            </Badge>
                        </>
                    }
                    subText={date ? formatDateTime(date) : ""}
                />
                {canDelete && (
                    <IconButton
                        variant="ghost"
                        iconName="trashCan"
                        size="xs"
                        onPress={onDeleted}
                    />
                )}
            </HStack>
            {comment && (
                <Text ml={11} mt={2} numberOfLines={99}>
                    {comment}
                </Text>
            )}
        </VStack>
    );
};

export default CommentListItem;
