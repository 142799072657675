import { Platform } from "react-native";

export const Select = {
    baseStyle: () => ({
        selection: {
            start: 0,
        },
        minWidth: 120,
        fontSize: "md",
        placeholderTextColor: "gray.800",
        overflow: "hidden",
        _hover: {
            borderBottomColor: "green.300",
            borderTopColor: "green.300",
            borderLeftColor: "green.300",
            borderRightColor: "green.300",
        },
        _focus: {
            bgColor: "white",
            borderWidth: 1,
            borderBottomColor: "green.300",
            borderTopColor: "green.300",
            borderLeftColor: "green.300",
            borderRightColor: "green.300",
            shadowColor: "green.100",
            shadowOffset: {
                width: 4,
                height: 2,
            },
            shadowOpacity: 0.05,
            shadowRadius: 4,
            ...Platform.select({
                android: {
                    elevation: 2,
                },
            }),
        },
        _disabled: {
            borderBottomColor: "gray.200",
            borderTopColor: "gray.200",
            borderLeftColor: "gray.200",
            borderRightColor: "gray.200",
            bgColor: "white",
            placeholderTextColor: "gray.600",
            opacity: "80",
        },
        _invalid: {
            borderBottomColor: "orange.300",
            borderTopColor: "orange.300",
            borderLeftColor: "orange.300",
            borderRightColor: "orange.300",
            shadowColor: "orange.300",
            shadowOffset: {
                width: 4,
                height: 2,
            },
            shadowOpacity: 0.05,
            shadowRadius: 4,
            ...Platform.select({
                android: {
                    elevation: 2,
                },
            }),
        },
        _webSelect: {
            style: {
                appearance: "none",
                WebkitAppearance: "none",
                MozAppearance: "none",
                position: "absolute",
                width: "100%",
                height: "100%",
                opacity: 0,
                zIndex: 1,
            },
        },
        _web: {
            pointerEvents: "none",
        },
        _actionSheetBody: {
            w: "full",
        },
        _actionSheetContent: {},
    }),
    defaultProps: {
        optimized: true,
    },
};

// SelectIcon - only for custom variant
export const SelectItem = {
    baseStyle: {
        px: 2,
        py: 1,
        borderRadius: 0,
        minHeight: 0,
    },
};
