import type { ComponentProps } from "react";
import { useState } from "react";
import { Box, HStack, Pressable, useTheme } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Icon from "../../icons/Icon";
import Input from "./Input";

type Props = Omit<ComponentProps<typeof Input>, "leftIconName"> & {
    wrapperProps?: ComponentProps<typeof HStack>;
};

const SearchInput = ({ placeholder, wrapperProps, ...rest }: Props) => {
    const { colors } = useTheme();
    const [value, setValue] = useState("");
    const { t } = useTranslation();
    const handleChange = (value: string) => {
        setValue(value);
    };

    const resetValue = () => setValue("");
    const placeholderDefault = t("search");

    return (
        <HStack position="relative" {...wrapperProps}>
            <Input
                value={value}
                testID="search-input"
                placeholder={placeholder ?? placeholderDefault}
                leftIconName="search"
                maxW={250}
                onChange={handleChange}
                {...rest}
            />
            {value !== "" ? (
                <Pressable
                    onPress={resetValue}
                    mr={1.5}
                    position="absolute"
                    right={0}
                    top={0.5}
                >
                    <Box p={2}>
                        <Icon name="close" size={16} fill={colors.gray[800]} />
                    </Box>
                </Pressable>
            ) : undefined}
        </HStack>
    );
};

export default SearchInput;
