export enum InsulinType {
    Rapid = "rapid",
    Basal = "basal",
    Other = "other",
}

export enum InsulinBodyPart {
    Arm = "arm",
    Waist = "waist",
    Abdomen = "abdomen",
    Glutes = "glutes",
    Thigh = "thigh",
}

export const BODY_PARTS_FOR_HUMANS = {
    [InsulinBodyPart.Arm]: "arm", //"Kéz",
    [InsulinBodyPart.Waist]: "waist", //"Derék",
    [InsulinBodyPart.Abdomen]: "abdomen", //"Has",
    [InsulinBodyPart.Glutes]: "glutes", //"Farizom",
    [InsulinBodyPart.Thigh]: "thigh", //"Comb",
};

export type AdministrationSide = "left" | "right";

export const SIDES_FOR_HUMANS = {
    left: "Bal oldal",
    right: "Jobb oldal",
};

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}
