import baseApi from "../api";
import resetStore from "../resetStore";
import type {
    IntegratorLoginRequest,
    PasswordLoginRequest,
    TokenResponse,
} from "./model";
import { updateTokens } from "./slice";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        passwordLogin: build.mutation<TokenResponse, PasswordLoginRequest>({
            query: (payload) => ({
                url: "security/login",
                method: "POST",
                body: payload,
            }),
            extraOptions: { withoutAuth: true },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                dispatch(updateTokens(data));
            },
        }),
        integratorLogin: build.mutation<TokenResponse, IntegratorLoginRequest>({
            query: ({ integrator, token }) => ({
                url: `security/login/${integrator}`,
                method: "POST",
                body: { token },
            }),
            extraOptions: { withoutAuth: true },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                dispatch(updateTokens(data));
            },
        }),
        logout: build.mutation<void, string>({
            query: (payload) => ({
                url: "security/invalidate",
                method: "POST",
                body: { refreshToken: payload },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                void dispatch(resetStore());
            },
        }),
    }),
});

export const {
    usePasswordLoginMutation,
    useIntegratorLoginMutation,
    useLogoutMutation,
} = api;
