import { Modal, RepresentativeModalForm, useShowToast } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import {
    useGetRepresentativesQuery,
    useUpdateRepresentativeMutation,
} from "../api";

interface Props {
    userId: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const EditRepresentativeModal = ({ userId, open, onClose }: Props) => {
    const { representative } = useGetRepresentativesQuery(undefined, {
        selectFromResult: ({ data: representatives }) => ({
            representative: representatives?.find(
                (representative) => representative.userId === userId
            ),
        }),
    });
    const [update] = useUpdateRepresentativeMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!userId || !representative) {
        return null;
    }

    const handleSuccess = () => {
        showToast(t("commentModified"), { variant: "success" });
    };

    const { fullName, note } = representative;

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{ userId, fullName, note: note ?? "" }}
                mutation={update}
                onSuccess={handleSuccess}
            >
                {({ submitForm, isSuccess }) => (
                    <RepresentativeModalForm
                        mode="editRepresentative"
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditRepresentativeModal;
