import { Box, Stack, Text, VStack } from "native-base";
import type { Service } from "@madmedical/medical";
import {
    UnderlyingCondition,
    isIllness,
    isNotIllness,
    underlyingForHumans,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Button from "../../atoms/Button";
import FormControl from "../../molecules/Form/FormControl";
import OnboardingRow from "../../molecules/OnboardingRow";
import PreOpWrapper from "../PreOp/Patient/PreOpWrapper";
import Radio from "../../atoms/Radio";
import DateInput from "../../molecules/Form/DateInput";

interface PatientMe {
    onboarded: boolean;
    underlyingConditions: UnderlyingCondition[];
    services: Service[];
    maxDeviceCount: number;
    maxRepresentativeCount: number;
    gender?: "male" | "female";
    birthDate?: string | undefined | null;
    bodyHeight?: number;
    isPregnant?: boolean;
}
interface PregnantDetails {
    name: string;
    label: string;
    value: boolean | string | number;
    isBoolean: boolean;
    isVisible?: boolean;
    twinIndex?: number;
}

export const genderForHumans = {
    surprise: "keepItASurprise",
    male: "boy",
    female: "girl",
};

interface Props {
    selectedUnderlyings: UnderlyingCondition[];
    bodyHeight?: number;
    onUnderlyingChange: (
        underlying: UnderlyingCondition,
        selected: boolean
    ) => void;
    onBodyHeightChange: (cm: number) => void;
    onPreviousPress?: () => void;
    onNextPress: () => void;
    isNextDisabled: boolean;
    pregnantDetails?: PregnantDetails[];
    onPregnantDetailsChange: (
        name: string,
        value?: boolean | string | null,
        isVisible?: boolean | null
    ) => void;
    openCalculateBirthTimeModal: () => void;
    isPregnant: boolean;
    setIsPregnant: (isPregnant: boolean) => void;
    patient: PatientMe | undefined | null;
    packages: {
        diabetes: boolean;
        pregnancy: boolean;
    };
}

const PatientOnboarding = ({
    selectedUnderlyings,
    bodyHeight,
    onUnderlyingChange,
    onBodyHeightChange,
    onPreviousPress,
    onNextPress,
    isNextDisabled,
    pregnantDetails,
    onPregnantDetailsChange,
    openCalculateBirthTimeModal,
    isPregnant,
    setIsPregnant,
    patient,
    packages,
}: Props) => {
    const handleUnderlyingChange =
        (underlying: UnderlyingCondition) => (value: boolean) => {
            onUnderlyingChange(underlying, value);
        };

    const handleBodyHeightChange = (value: string) => {
        onBodyHeightChange(parseInt(value));
    };
    const { t } = useTranslation();

    return (
        <PreOpWrapper
            width="full"
            justifyContent="flex-start"
            alignItems="flex-start"
            pb={20}
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            isNextDisabled={isNextDisabled}
            title={t("onboarding:baseInfoInput")}
        >
            <VStack>
                <OnboardingRow
                    name="illness"
                    content={t("onboarding:diseases")}
                    hasOptionalAnswer={false}
                />
                {Object.values(UnderlyingCondition)
                    .filter(isIllness)
                    .filter((underlying) => underlying !== "insulin")
                    .map((underlying, index) => (
                        <OnboardingRow
                            key={`${underlying}-${index}`}
                            name={underlying}
                            value={selectedUnderlyings.includes(underlying)}
                            onChange={handleUnderlyingChange(underlying)}
                            content={t(
                                `onboarding:${underlyingForHumans(underlying)}`
                            )}
                            pl={{
                                base: 8,
                                md: 10,
                            }}
                        />
                    ))}
                {Object.values(UnderlyingCondition)
                    .filter(isNotIllness)
                    .sort((a, b) => b.localeCompare(a))
                    .filter(
                        (underlying) =>
                            underlying !== "pregnancy" &&
                            underlying !== "insulin"
                    )
                    .map((underlying, index) => (
                        <OnboardingRow
                            key={`${underlying}-${index + 100}`}
                            name={underlying}
                            value={selectedUnderlyings.includes(underlying)}
                            onChange={handleUnderlyingChange(underlying)}
                            content={t(
                                `onboarding:${underlyingForHumans(underlying)}`
                            )}
                        />
                    ))}
                {packages.diabetes && (
                    <OnboardingRow
                        key={`insulin`}
                        name={`insulin`}
                        value={selectedUnderlyings.includes(
                            UnderlyingCondition.Insulin
                        )}
                        onChange={handleUnderlyingChange(
                            UnderlyingCondition.Insulin
                        )}
                        content={t(
                            `onboarding:${underlyingForHumans(
                                UnderlyingCondition.Insulin
                            )}`
                        )}
                    />
                )}
                {packages.pregnancy && patient?.gender === "female" && (
                    <OnboardingRow
                        key={`isPregnant`}
                        name={`isPregnant`}
                        value={isPregnant}
                        onChange={setIsPregnant}
                        content={t("onboarding:areYouPregnant")}
                    />
                )}
                {packages.pregnancy &&
                    patient?.gender === "female" &&
                    isPregnant && (
                        <>
                            {pregnantDetails?.map(
                                ({
                                    name,
                                    label,
                                    value,
                                    isBoolean,
                                    isVisible,
                                    twinIndex,
                                }) => {
                                    const isTwins = pregnantDetails?.find(
                                        ({ name }) => name === "twins"
                                    )?.value;
                                    if (isVisible) {
                                        return (
                                            <>
                                                {isTwins &&
                                                    name.includes("gender") &&
                                                    twinIndex && (
                                                        <Text>
                                                            {t(
                                                                "onboarding:placenta"
                                                            )}{" "}
                                                            {twinIndex}
                                                        </Text>
                                                    )}
                                                <OnboardingRow
                                                    key={name}
                                                    name={name}
                                                    content={label}
                                                    value={value}
                                                    hasOptionalAnswer={
                                                        isBoolean
                                                    }
                                                    onChange={(val) =>
                                                        onPregnantDetailsChange(
                                                            name,
                                                            val
                                                        )
                                                    }
                                                    pl={{
                                                        base: 8,
                                                        md: 10,
                                                    }}
                                                    isLongInput={
                                                        name.includes(
                                                            "gender"
                                                        ) ||
                                                        name.includes(
                                                            "nameChoose"
                                                        )
                                                    }
                                                    customInput={
                                                        name.includes(
                                                            "gender"
                                                        ) ? (
                                                            <>
                                                                <Radio.Group
                                                                    name={name}
                                                                    value={
                                                                        value as string
                                                                    }
                                                                    onChange={(
                                                                        val
                                                                    ) =>
                                                                        onPregnantDetailsChange(
                                                                            name,
                                                                            val
                                                                        )
                                                                    }
                                                                    defaultValue="surprise"
                                                                >
                                                                    <Stack
                                                                        direction={{
                                                                            base: "column",
                                                                            sm: "row",
                                                                        }}
                                                                        alignItems={{
                                                                            base: "flex-end",
                                                                            md: "flex-start",
                                                                        }}
                                                                        space={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Radio value="surprise">
                                                                            {t(
                                                                                `pregnancy:${genderForHumans["surprise"]}`
                                                                            )}
                                                                        </Radio>
                                                                        <Radio
                                                                            value="male"
                                                                            ml={
                                                                                4
                                                                            }
                                                                        >
                                                                            {t(
                                                                                `pregnancy:${genderForHumans["male"]}`
                                                                            )}
                                                                        </Radio>
                                                                        <Radio
                                                                            value="female"
                                                                            ml={
                                                                                4
                                                                            }
                                                                        >
                                                                            {t(
                                                                                `pregnancy:${genderForHumans["female"]}`
                                                                            )}
                                                                        </Radio>
                                                                    </Stack>
                                                                </Radio.Group>
                                                            </>
                                                        ) : undefined
                                                    }
                                                    answerInput={
                                                        name ===
                                                        "expectedAt" ? (
                                                            <Box>
                                                                <DateInput
                                                                    value={
                                                                        new Date(
                                                                            value as string
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        val
                                                                    ) =>
                                                                        onPregnantDetailsChange(
                                                                            name,
                                                                            val.toDateString()
                                                                        )
                                                                    }
                                                                />
                                                                <Button
                                                                    onPress={
                                                                        openCalculateBirthTimeModal
                                                                    }
                                                                    variant="link"
                                                                    justifyContent="flex-start"
                                                                    px={0}
                                                                    pt={3}
                                                                    pb={0}
                                                                >
                                                                    {t(
                                                                        "onboarding:doYouKnowTheDate"
                                                                    )}
                                                                </Button>
                                                            </Box>
                                                        ) : name ===
                                                          "twinsCount" ? (
                                                            <FormControl
                                                                type="number"
                                                                value={
                                                                    value
                                                                        ? value.toString()
                                                                        : undefined
                                                                }
                                                                onChangeText={(
                                                                    val
                                                                ) =>
                                                                    parseInt(
                                                                        val
                                                                    ) >= 2
                                                                        ? onPregnantDetailsChange(
                                                                              name,
                                                                              val
                                                                          )
                                                                        : undefined
                                                                }
                                                                size="md"
                                                                maxLength={1}
                                                            />
                                                        ) : name.includes(
                                                              "nameInput"
                                                          ) ? (
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    value as string
                                                                }
                                                                onChangeText={(
                                                                    val
                                                                ) =>
                                                                    onPregnantDetailsChange(
                                                                        name,
                                                                        val
                                                                    )
                                                                }
                                                                placeholder={t(
                                                                    "onboarding:pleaseFillIn"
                                                                )}
                                                                size="md"
                                                            />
                                                        ) : undefined
                                                    }
                                                />
                                            </>
                                        );
                                    }
                                }
                            )}
                        </>
                    )}
                <OnboardingRow
                    name="bodyHeight"
                    content=""
                    hasOptionalAnswer={false}
                    answerInput={
                        <FormControl
                            type="number"
                            label={t("onboarding:howTallAreYou")}
                            value={
                                bodyHeight ? bodyHeight.toString() : undefined
                            }
                            onChangeText={handleBodyHeightChange}
                            placeholder={t("onboarding:pleaseFillIn")}
                            size="md"
                            rightAddon="cm"
                            isRequired
                        />
                    }
                />
            </VStack>
        </PreOpWrapper>
    );
};

export default PatientOnboarding;
