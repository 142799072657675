import { Box, HStack, Pressable, Text } from "native-base";
import { useState } from "react";
import type { AdministrationSide, InsulinBodyPart } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { formatAdministrationSide } from "../util/insulinFormatters";
import InsulinAdministrationGraph from "./InsulinAdministrationGraph";

interface SideVisible {
    left: boolean;
    right: boolean;
}
interface BodyChartDataElement {
    bodyPart: InsulinBodyPart;
    side: AdministrationSide;
}

export interface BodyChartDataList {
    insulinBodyLocation: BodyChartDataElement;
    units: number;
}

interface InsulinBodyChartProps {
    insulinBody: BodyChartDataList[];
}

const InsulinBodyChart = ({ insulinBody }: InsulinBodyChartProps) => {
    const [sideVisible, setSideVisible] = useState<SideVisible>({
        left: true,
        right: true,
    });
    const { t } = useTranslation();
    if (!insulinBody) return null;

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            pt={8}
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                zIndex={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
            >
                <HStack alignItems="center" height="20px">
                    <Pressable
                        onPress={() =>
                            setSideVisible({
                                ...sideVisible,
                                left: !sideVisible.left,
                            })
                        }
                        width="80px"
                    >
                        <HStack alignItems="center">
                            <Box
                                width="20px"
                                height="20px"
                                borderColor="blue.500"
                                borderWidth="2px"
                                bgColor="blue.50"
                                mr={2}
                            >
                                &nbsp;
                            </Box>
                            <Text color="gray.600" fontSize="xs">
                                {t(formatAdministrationSide("left"))}
                            </Text>
                        </HStack>
                    </Pressable>
                    <Pressable
                        onPress={() =>
                            setSideVisible({
                                ...sideVisible,
                                right: !sideVisible.right,
                            })
                        }
                        width="80px"
                    >
                        <HStack alignItems="center">
                            <Box
                                width="20px"
                                height="20px"
                                borderColor="green.500"
                                borderWidth="2px"
                                bgColor="green.50"
                                mr={2}
                            >
                                &nbsp;
                            </Box>
                            <Text color="gray.600" fontSize="xs">
                                {t(formatAdministrationSide("right"))}
                            </Text>
                        </HStack>
                    </Pressable>
                </HStack>
            </Box>

            <Box display="flex">
                <InsulinAdministrationGraph
                    bodyChartData={insulinBody}
                    sideVisible={sideVisible}
                />
            </Box>
        </Box>
    );
};

export default InsulinBodyChart;
