import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { skipToken } from "@madmedical/store";
import { useTranslation } from "@madmedical/i18n";
import { useDeleteDocumentMutation, useGetDocumentQuery } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const DeleteDocumentDialog = ({ id, open, onClose }: Props) => {
    const { t } = useTranslation();
    const { data: document } = useGetDocumentQuery(id ?? skipToken, {
        skip: !open,
    });
    const [deleteDocument, { isLoading: isSubmitting }] =
        useDeleteDocumentMutation();
    const showToast = useShowToast();

    if (!id || !document) {
        return null;
    }

    const handleConfirm = () => {
        void deleteDocument(id)
            .unwrap()
            .then(() => {
                showToast(t("documentDeleted") + ".", { variant: "success" });
                onClose();
            })
            .catch((error) => {
                const typedError = error as { originalStatus?: number };
                const errorMessage =
                    typedError?.originalStatus === 403
                        ? t("notAuthorizedToDeleteDocument") + "."
                        : t("errorDeletingDocument") + ".";
                showToast(errorMessage, {
                    variant: "error",
                });
                setTimeout(() => {
                    onClose();
                }, 1000);
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            onClose={onClose}
            isSubmitting={isSubmitting}
            header={t("deleteDocument")}
        >
            <Text>
                {t("sureToDeleteDocument", { filename: document.filename })}
            </Text>
        </Dialog>
    );
};

export default DeleteDocumentDialog;
