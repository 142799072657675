import type { BodyMassIndex } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<BodyMassIndex>;

const BodyMassIndexChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BodyMassIndex, widget);

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "line-single",
            unit: "",
            limits: {
                yMax: max ? Math.ceil(max.bmi + 0.2) : 25,
                yMin: min ? Math.floor(min.bmi - 0.2) : 15,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.bmi,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.BodyMassIndex}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BodyMassIndexChart;
