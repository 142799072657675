import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { DateRangeStrings } from "@madmedical/utils";
import { endOfWeek, rangeToString, startOfWeek } from "@madmedical/utils";

export interface State {
    dateRange: DateRangeStrings;
    insulinRange: DateRangeStrings;
    bodyDateRange: DateRangeStrings;
    portioningDateRange: DateRangeStrings;
}

const initialState: State = {
    dateRange: rangeToString({
        from: startOfWeek(new Date()),
        to: endOfWeek(new Date()),
    }),
    insulinRange: rangeToString({
        from: new Date(),
        to: new Date(),
    }),
    bodyDateRange: rangeToString({
        from: new Date(),
        to: new Date(),
    }),
    portioningDateRange: rangeToString({
        from: startOfWeek(new Date()),
        to: endOfWeek(new Date()),
    }),
};

const slice = createSlice({
    name: "settingsSlice",
    initialState,
    reducers: {
        setDateRange(state, { payload }: PayloadAction<DateRangeStrings>) {
            state.dateRange = payload;
        },
        setInsulinRange(state, { payload }: PayloadAction<DateRangeStrings>) {
            state.insulinRange = payload;
        },
        setBodyDateRange(state, { payload }: PayloadAction<DateRangeStrings>) {
            state.bodyDateRange = payload;
        },
        setPortioningDateRange(
            state,
            { payload }: PayloadAction<DateRangeStrings>
        ) {
            state.portioningDateRange = payload;
        },
    },
});

export const {
    setDateRange,
    setInsulinRange,
    setBodyDateRange,
    setPortioningDateRange,
} = slice.actions;

export const settingsReducer = slice.reducer;
