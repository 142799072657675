import type { ComponentProps } from "react";
import { useMemo, useState } from "react";
import type { IconButton as BaseIconButton } from "native-base";
import {
    HStack,
    Pressable,
    Text,
    TextArea,
    VStack,
    useTheme,
} from "native-base";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Icon from "../icons/Icon";
import Button from "../atoms/Button";
import { cropFilename } from "../util/strings";
import useResponsive from "../util/useResponsive";
import Select from "../atoms/Select";
import {
    DOCUMENT_SERVICE_FOR_HUMANS,
    DocumentService,
} from "../form/upload/model";

type IconName = ComponentProps<typeof Icon>["name"];

interface Props
    extends Pick<
        ComponentProps<typeof BaseIconButton>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    variant?:
        | "primary" // Filled Green
        | "destructive" // Filled Red
        | "gray" // Filled Gray
        | "outlined" // Filled White
        | "ghost" // Ghost Gray
        | "ghostGreen" // Ghost Green
        | "link" // Link Green
        | "ghostLink" // Link Gray
        | "ghostGrayLink" // Ghost Gray
        | "black";
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    iconSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    onPress?: () => void;
    isButton?: boolean;
    iconName?: IconName;
    label?: string;
    subText?: string;
    comment?: string | null;
    onChangeComment?: (comment: string) => void;
    onChangeService?: (service: DocumentService) => void;
    isEditable?: boolean;
}
type VariantsType = {
    [key: string]: {
        labelSize: number;
        subTextSize?: number;
    };
};

const DocumentItem = ({
    variant = "primary",
    size = "md",
    isButton = false,
    onPress,
    iconName = "documentBlank",
    iconSize = "xl",
    label,
    subText,
    comment,
    onChangeComment,
    onChangeService,
    isEditable = false,
}: Props) => {
    const { colors } = useTheme();
    const { fontSizes } = useTheme();
    const [editing, setEditing] = useState(false);
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const variants: VariantsType = useMemo(
        () => ({
            "2xs": {
                labelSize: fontSizes.sm,
                lineHeight: 1,
            },
            xs: {
                labelSize: fontSizes.sm,
                lineHeight: 1,
            },
            sm: {
                labelSize: fontSizes.sm,
                lineHeight: 1,
            },
            md: {
                labelSize: fontSizes.sm,
                lineHeight: 1,
            },
            lg: {
                labelSize: fontSizes.md,
                lineHeight: 1,
            },
            xl: {
                labelSize: fontSizes.md,
                lineHeight: 1,
            },
            "2xl": {
                labelSize: fontSizes["2xl"],
            },
            "3xl": {
                labelSize: fontSizes.lg,
                subTextSize: fontSizes.sm,
            },
        }),
        [fontSizes]
    );

    const iconSizes = useMemo(
        () =>
            ({
                xs: 16,
                sm: 18,
                md: 20,
                lg: 20,
                xl: 20,
                "2xl": 28,
            } as const),
        []
    );

    const iconVariants = useMemo(
        () => ({
            primary: colors.white,
            destructive: colors.white,
            gray: colors.gray[800],
            outlined: colors.gray[800],
            ghost: colors.gray[900],
            ghostGreen: colors.green[500],
            link: colors.green[600],
            ghostLink: colors.gray[800],
            ghostGrayLink: colors.gray[500],
            black: "black",
        }),
        [colors]
    );
    const packageChoices = useMemo(
        () => [
            {
                value: DocumentService.Pregnancy,
                label: t(
                    `${DOCUMENT_SERVICE_FOR_HUMANS[DocumentService.Pregnancy]}`
                ),
            },
            {
                value: DocumentService.Manager,
                label: t(
                    `${DOCUMENT_SERVICE_FOR_HUMANS[DocumentService.Manager]}`
                ),
            },
            {
                value: DocumentService.PrePostOp,
                label: t(
                    `${DOCUMENT_SERVICE_FOR_HUMANS[DocumentService.PrePostOp]}`
                ),
            },
        ],
        [t]
    );

    const handleServiceChange = (value: string) => {
        onChangeService?.(value as DocumentService);
    };

    return (
        <VStack flex={1} flexDirection={"row"}>
            <Pressable onPress={onPress} disabled={!isButton}>
                <Icon
                    name={iconName}
                    fill={iconVariants[variant]}
                    size={iconSizes[iconSize]}
                />
            </Pressable>
            <VStack
                ml={2}
                width="100%"
                alignItems="flex-start"
                justifyContent="center"
            >
                <Text lineHeight="1.1em" fontWeight="bold">
                    {label && cropFilename(label, isSmallScreen ? 30 : 50)}
                </Text>

                {subText && (
                    <Text
                        color="gray.600"
                        lineHeight="1.1em"
                        mt={1}
                        fontWeight={"500"}
                        fontSize={variants[size].subTextSize ?? fontSizes.xs}
                    >
                        {subText}
                    </Text>
                )}
                {!editing && comment && (
                    <Text
                        color="gray.600"
                        lineHeight="1.1em"
                        fontWeight={"500"}
                        mt={2}
                        fontSize={variants[size].subTextSize ?? fontSizes.xs}
                    >
                        {comment}
                    </Text>
                )}
                <HStack
                    alignItems={editing ? "flex-start" : "flex-end"}
                    direction={editing ? "column" : "row"}
                    space={2}
                    width={"100%"}
                    pt={3}
                >
                    <Select
                        choices={packageChoices}
                        placeholder={t("selectPackage")}
                        onChange={handleServiceChange}
                    />
                    {isEditable && editing && (
                        <TextArea
                            value={comment ?? undefined}
                            autoCompleteType="off"
                            h={20}
                            mt={isWeb ? 2 : 0}
                            placeholder={t("addComment") + "..."}
                            onChangeText={onChangeComment}
                            width="95%"
                        />
                    )}
                    {isEditable && !editing && (
                        <Button
                            variant="outlined"
                            size="xs"
                            mt={2}
                            mb={isWeb ? 0 : 2}
                            transparent={true}
                            onPress={() => setEditing(true)}
                            leftIconName="add"
                        >
                            {t("addComment")}
                        </Button>
                    )}
                </HStack>
            </VStack>
        </VStack>
    );
};

export default DocumentItem;
