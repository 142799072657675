import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import {
    ChartProvider,
    ChartWidget,
    CurrentPatientProvider,
    InsulinAdministrationProvider,
    Spinner,
} from "@madmedical/ui";
import { useGetThresholdsProtocolsQuery } from "@madmedical/threshold";
import type { BloodSugar } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { invariant, rangeToString } from "@madmedical/utils";
import { adaptPatient, usePatient } from "@madmedical/user";
import { useDevices } from "@madmedical/device";
import { useInsulinDateRange } from "@madmedical/store";
import { useGetMeasurementRangeQuery } from "../../measurement/api";
import ChartDecorated from "../../charts/ChartDecorated";
import {
    useGetInsulinAdministrationRangeQuery,
    useGetInsulinAdministrationsQuery,
    useGetInsulinProductsQuery,
} from "../api";
import adaptAdministration from "../adaptAdministration";
import { calculateBloodSugarAveragesByDate } from "../util/groupBloodSugar";
import type { MeasurementRangeResponse } from "../../measurement/model";

interface Props {
    children: ReactNode;
}

const InsulinBloodSugarProvider = ({ children }: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const { isLoading: isDevicesLoading } = useDevices();

    const metricType = MetricType.BloodSugar;

    const { dateRange, setDateRange } = useInsulinDateRange();

    const [page, setPage] = useState(1);

    const { data: bloodSugarData, isLoading: isMeasurementsLoading } =
        useGetMeasurementRangeQuery({
            userId: patientId,
            metricType,
            queryRange: rangeToString(dateRange),
            statsRange: rangeToString(dateRange),
        });

    const { data: insulinAdministrations, isLoading: isInsulinLoading } =
        useGetInsulinAdministrationRangeQuery({
            patientId,
            dateRange: rangeToString(dateRange),
        });

    const { data: insulinProducts } = useGetInsulinProductsQuery();

    const { data: allData, isLoading: allIsLoading } =
        useGetInsulinAdministrationsQuery({
            patientId,
            page,
        });

    const {
        data: thresholdsProtocols,
        isLoading: isThresholdsProtocolsLoading,
    } = useGetThresholdsProtocolsQuery(patientId);

    const incrementPage = useCallback(() => {
        if (!allData) {
            return;
        }

        const { currentPage, pageCount } = allData.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [allData]);

    if (
        isDevicesLoading ||
        isMeasurementsLoading ||
        isPatientLoading ||
        isThresholdsProtocolsLoading ||
        isInsulinLoading ||
        allIsLoading
    ) {
        return <Spinner />;
    }

    invariant(bloodSugarData);
    invariant(patient);
    invariant(thresholdsProtocols);
    invariant(insulinAdministrations);

    const { worstAlertLevel, displayRange, unit } = bloodSugarData.stats;

    return (
        <ChartProvider
            value={{
                chart: (
                    <ChartDecorated
                        metricType={metricType}
                        data={bloodSugarData}
                        dateRange={dateRange}
                        widget={ChartWidget.Page}
                        insulinAdministrations={insulinAdministrations}
                    />
                ),
                metricType,
                dateRange,
                displayRange: displayRange ? `${displayRange} ${unit}` : null,
                alertLevel: worstAlertLevel,
                setDateRange,
            }}
        >
            <InsulinAdministrationProvider
                value={{
                    bloodSugareAverages: calculateBloodSugarAveragesByDate(
                        (bloodSugarData ??
                            []) as MeasurementRangeResponse<BloodSugar>
                    ),
                    products: insulinProducts ?? [],
                    administrations:
                        insulinAdministrations?.map(adaptAdministration) ?? [],
                    allAdministrations:
                        allData?.items?.map(adaptAdministration) ?? [],
                    incrementPage,
                }}
            >
                <CurrentPatientProvider
                    value={{ patient: adaptPatient(patient) }}
                >
                    {children}
                </CurrentPatientProvider>
            </InsulinAdministrationProvider>
        </ChartProvider>
    );
};

export default InsulinBloodSugarProvider;
