import { Box, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import FormSelect from "../../form/FormSelect";
import FormControl from "../../form/FormControl";

interface Props {
    protocols: string[];
}

const ProtocolSection = ({ protocols }: Props) => {
    const { t } = useTranslation();

    return (
        <VStack space={6}>
            <FormSelect
                choices={protocols.map((instruction) => ({
                    value: instruction,
                    label: instruction,
                }))}
                name="instructions"
                label={t("measures:protocol")}
                labelProps={{ _text: { color: "gray.800" } }}
            />
            <Box>
                <FormControl
                    label={t("measures:protocolDescription")}
                    name="instructions"
                    multiline
                    numberOfLines={4}
                    labelProps={{
                        _text: { color: "gray.800" },
                    }}
                />
            </Box>
        </VStack>
    );
};

export default ProtocolSection;
