import { invariant } from "@madmedical/utils";
import type { AuthResultMessage, OpenAuth } from "./model";

const openAuth: OpenAuth = async (getUrl, redirectUrl, onClose) => {
    window.addEventListener(
        "message",
        ({ data }: AuthResultMessage) => {
            if (data.type !== "oauthResult") {
                return;
            }

            onClose(data.result);
        },
        false
    );

    const width = 500;
    const height = 660;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const popup = window.open(
        undefined,
        "oauth",
        `toolbar=no,menubar=no,width=${width},height=${height},top=${top},left=${left}`
    );

    invariant(popup);

    popup.location = await getUrl();
    popup.onbeforeunload = () => {
        onClose("cancel");
    };
};

export default openAuth;
