import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useDeleteMeasurementMutation } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const DeleteMeasurementDialog = ({ id, open, onClose }: Props) => {
    const [deleteMeasurement, { isLoading: isSubmitting }] =
        useDeleteMeasurementMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!id) {
        return null;
    }

    const handleConfirm = () => {
        void deleteMeasurement(id)
            .unwrap()
            .then(() => {
                showToast(t("measureDeleted"), { variant: "success" });
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            onClose={onClose}
        >
            <Text>{t("sureToDeleteMeasurement")}</Text>
        </Dialog>
    );
};

export default DeleteMeasurementDialog;
