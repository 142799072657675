import type { ComponentProps } from "react";
import { Box, Spinner } from "native-base";
import type { IconName } from "../icons/icons";
import IconButton from "../atoms/IconButton";
import type { DocumentService } from "../form/upload/model";
import DocumentItem from "./DocumentItem";

interface Props
    extends Pick<
        ComponentProps<typeof Box>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    leftIconName?: IconName;
    rightIconName: IconName;
    label: string;
    subText: string;
    note: string | null;
    onChangeNote?: (note: string) => void;
    isEditable?: boolean;
    loading?: boolean;
    onRightIconPress: () => void;
    onChangeService?: (service: DocumentService) => void;
}

const DocumentListCard = ({
    leftIconName,
    rightIconName,
    label,
    subText,
    note,
    onChangeNote,
    onChangeService,
    isEditable,
    loading,
    onRightIconPress,
    ...rest
}: Props) => (
    <Box
        px={isEditable ? 0 : 4}
        py={isEditable ? 4 : 6}
        borderBottomWidth={isEditable ? 1 : undefined}
        borderColor="gray.100"
        width="full"
        justifyContent="space-between"
        flexDirection="row"
        {...rest}
    >
        <DocumentItem
            iconName={leftIconName}
            variant="ghostGreen"
            iconSize="2xl"
            label={label}
            subText={subText}
            comment={note}
            onChangeComment={onChangeNote}
            onChangeService={onChangeService}
            isEditable={isEditable}
        />
        {loading ? (
            <Box alignItems={"flex-start"} mr={2}>
                <Spinner color="green.500" />
            </Box>
        ) : (
            <IconButton
                alignItems={{
                    base: "flex-start",
                    md: "flex-start",
                }}
                onPress={onRightIconPress}
                iconName={rightIconName}
                variant="lightGray"
                size="sm"
            />
        )}
    </Box>
);

export default DocumentListCard;
