import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import { HStack, Radio, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import FormControlWrapper from "../molecules/Form/FormControlWrapper";

type Props = Omit<ComponentProps<typeof FormControlWrapper>, "children"> &
    Omit<
        ComponentProps<typeof Radio.Group>,
        "children" | "defaultValue" | "onChange"
    > & {
        name: string;
        defaultValue: boolean;
    };

const FormYesNoControl = ({
    name,
    isRequired,
    hint,
    label,
    labelProps,
    defaultValue,
    ...rest
}: Props) => {
    const { control, isError, error } = useField(name);
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => {
                const handleChange = (value: string) => {
                    onChange(value === "yes");
                };

                return (
                    <FormControlWrapper
                        isInvalid={isError}
                        isRequired={isRequired}
                        error={error}
                        hint={hint}
                        label={label}
                        labelProps={labelProps}
                    >
                        <Radio.Group
                            name={name}
                            value={value ? "yes" : "no"}
                            onChange={handleChange}
                            isInvalid={isError}
                            defaultValue={defaultValue ? "yes" : "no"}
                            accessibilityLabel=""
                            {...rest}
                        >
                            <HStack space={7}>
                                <Radio
                                    accessibilityLabel={t("yes")}
                                    key="yes"
                                    value="yes"
                                    borderWidth={1}
                                >
                                    <Text>{t("yes")}</Text>
                                </Radio>
                                <Radio
                                    accessibilityLabel={t("no")}
                                    key="no"
                                    value="no"
                                    borderWidth={1}
                                >
                                    <Text>{t("no")}</Text>
                                </Radio>
                            </HStack>
                        </Radio.Group>
                    </FormControlWrapper>
                );
            }}
        />
    );
};

export default FormYesNoControl;
