import { PregnancyFeelingPageContent } from "@madmedical/ui";
import { useState } from "react";
import { useMe } from "@madmedical/user";
import PregnancyMoodModalForm from "./PregnancyMoodModalForm";
import EditPregnancyMoodModalForm from "./EditPregnancyMoodModalForm";
import DeletePregnancyMoodModalForm from "./DeletePregnancyMoodModalForm";
import PregnancyProviderWrapper from "./PregnancyProviderWrapper";

enum ModalState {
    Closed,
    Measure,
    Feeling,
    Edit,
    Delete,
}

const PregnancyFeelingPage = () => {
    const {
        roles: { isDoctor },
    } = useMe();

    /*
    const { patientId } = usePatientId();
    */

    const [modal, setModal] = useState<ModalState>(ModalState.Closed);

    return (
        <>
            <PregnancyProviderWrapper setModal={setModal}>
                <PregnancyFeelingPageContent isDoctor={isDoctor} />
                <PregnancyMoodModalForm
                    open={modal === ModalState.Feeling}
                    onClose={() => setModal(ModalState.Closed)}
                />
                <EditPregnancyMoodModalForm
                    open={modal === ModalState.Edit}
                    onClose={() => setModal(ModalState.Closed)}
                />
                <DeletePregnancyMoodModalForm
                    open={modal === ModalState.Delete}
                    onClose={() => setModal(ModalState.Closed)}
                />
            </PregnancyProviderWrapper>
        </>
    );
};

export default PregnancyFeelingPage;
