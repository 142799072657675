import { useState } from "react";
import {
    AddCommentButton,
    OverlayProvider,
    useShowToast,
} from "@madmedical/ui";
import { useMe, usePatientId } from "@madmedical/user";
import { ApiFormWrapper } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import { useCreateCommentMutation } from "../api";

const AddCommentButtonForm = () => {
    const { patientId } = usePatientId();
    const {
        roles: { isAdmin },
    } = useMe();
    const [createComment] = useCreateCommentMutation();
    const showToast = useShowToast();
    const [isSent, setIsSent] = useState(false);
    const { t } = useTranslation();
    const handleSuccess = () => {
        setIsSent(true);
        showToast(t("commentAdded"), { variant: "success" });
        setTimeout(() => setIsSent(false), 3000);
    };

    if (isAdmin) {
        return null;
    }

    return (
        <ApiFormWrapper
            defaultValues={{ patientId, date: new Date().toISOString() }}
            mutation={createComment}
            onSuccess={handleSuccess}
            resetOnSuccess={true}
        >
            {({ submitForm }) => (
                <OverlayProvider>
                    <AddCommentButton
                        onSubmitPress={submitForm}
                        isSuccess={isSent}
                    />
                </OverlayProvider>
            )}
        </ApiFormWrapper>
    );
};

export default AddCommentButtonForm;
