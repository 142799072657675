import { Fragment } from "react";
import { Line } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import Thresholds from "./Thresholds";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

export default function GraphBar({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const {
        coords = [],
        randomIdFragment,
        dashboardThumbnail: { isDashboardThumbnail = false } = {},
    } = graphData;

    return (
        <>
            {!isDashboardThumbnail && <Thresholds graphData={graphData} />}

            {coords.length > 0 &&
                coords.map(({ x, yLow, yHigh }, i) => {
                    const id = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return (
                        <Fragment key={id}>
                            {yLow &&
                                yHigh &&
                                (isWeb ? (
                                    <Line
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        strokeLinecap="round"
                                        strokeWidth="6px"
                                        id={id}
                                        x1={x}
                                        y1={yLow}
                                        x2={x}
                                        y2={yHigh}
                                    />
                                ) : (
                                    <Line
                                        scaleY={-1}
                                        stroke={
                                            tooltip.onShow && tooltip.id === id
                                                ? colors.blue800
                                                : colors.blue400
                                        }
                                        strokeLinecap="round"
                                        strokeWidth="6px"
                                        id={id}
                                        x1={x}
                                        y1={yLow}
                                        x2={x}
                                        y2={yHigh}
                                    />
                                ))}
                        </Fragment>
                    );
                })}
        </>
    );
}
