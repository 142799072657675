import { InsulinReminderModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { useUpdateInsulinReminderMutation } from "../api";
import type { InsulinReminder } from "../model";

interface Props {
    open: boolean;
    onClose: () => void;
    reminderData: InsulinReminder | undefined;
}

const EditInsulinReminderModalForm = ({
    open,
    onClose,
    reminderData,
}: Props) => {
    const [updateReminder] = useUpdateInsulinReminderMutation();

    return (
        <Modal open={open} size="xs" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{
                    ...reminderData,
                    insulinReminderId: reminderData?.id,
                }}
                mutation={updateReminder}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <InsulinReminderModalForm
                        isSuccess={isSuccess}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isEdit
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditInsulinReminderModalForm;
