import { Box, Text, VStack } from "native-base";
import { useEffect } from "react";
import { isWeb } from "@madmedical/utils";
import { useFormContext } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../../form/SubmitButton";
import Button from "../../atoms/Button";
import ModalContent from "../../molecules/ModalContent";
import BabyAbortion from "../../pics/Pregnancy/BabyAbortion";
import DateInput from "../../form/DateInput";
import Radio from "../../atoms/Radio";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const BirthAbortionModal = ({ onClose, onSubmitPress, isSuccess }: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    const { setValue } = useFormContext<{
        removePregnancyTracking: "yes" | "no";
    }>();

    const handleChange = (value: "yes" | "no") => {
        setValue("removePregnancyTracking", value);
    };
    const { t } = useTranslation();

    return (
        <ModalContent
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton onPress={onSubmitPress} label={t("save")} />
                </>
            }
        >
            <VStack space={4}>
                <Box
                    my={1}
                    height={isWeb ? "auto" : 140}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <BabyAbortion />
                </Box>
                <Box mb={4}>
                    <Text fontSize="md">
                        {t("pregnancy:removePregnancyTracking")}
                    </Text>
                    <Radio.Group
                        name="removePregnancyTracking"
                        space={4}
                        direction={"row"}
                        mt={4}
                        onChange={(val) => handleChange(val as "yes" | "no")}
                        defaultValue="yes"
                    >
                        <Radio value="yes">
                            <Text fontWeight="medium" fontSize="md">
                                {t("pregnancy:removal")}
                            </Text>
                        </Radio>
                        <Radio value="no">
                            <Text fontWeight="medium" fontSize="md">
                                {t("pregnancy:keeping")}
                            </Text>
                        </Radio>
                    </Radio.Group>
                </Box>

                <Box maxWidth={300}>
                    <DateInput
                        name="miscarriedAt"
                        label={t("pregnancy:miscarriedAt")}
                        labelProps={{ _text: { color: "gray.800" } }}
                        isRequired={false}
                    />
                </Box>
            </VStack>
        </ModalContent>
    );
};

export default BirthAbortionModal;
