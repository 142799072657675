import { Box, HStack, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import Button from "../atoms/Button";
import ModalContent from "../molecules/ModalContent";
import FormSelect from "../form/FormSelect";
import FormControl from "../form/FormControl";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const InsulinProtocolModalForm = ({
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { t } = useTranslation();
    const protocolTypes = [
        { value: "daily1", label: "Napi 1x" },
        { value: "daily2", label: "Napi 2x (10:00, 18:00)" },
        { value: "daily3", label: "Napi 3x (reggel, délben, este)" },
        { value: "weekly2", label: "Heti 2x (hétfő, csütörtök)" },
        { value: "weekly3", label: "Heti 3x (hétfő, szerda, szombat)" },
    ];

    return (
        <ModalContent
            header={t("measures:insulinProtocolSetup")}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton onPress={onSubmitPress} label={t("save")} />
                </>
            }
        >
            <VStack overflowY="clip">
                <HStack mt={2}>
                    <FormSelect
                        choices={protocolTypes}
                        name="type"
                        label={t("measures:protocol")}
                        isRequired
                        labelProps={{ _text: { color: "gray.800" } }}
                    />
                </HStack>
                <Box mt={2}>
                    <FormControl
                        label={t("measures:protocolDescription")}
                        name="instructions"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        maxLength={100}
                        height={35}
                        multiline
                        isRequired
                    />
                </Box>
            </VStack>
        </ModalContent>
    );
};

export default InsulinProtocolModalForm;
