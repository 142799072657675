export enum Gender {
    Male = "male",
    Female = "female",
}

export enum GenderBaby {
    Male = "male",
    Female = "female",
    Surprise = "surprise",
}

const FOR_HUMANS = {
    [Gender.Male]: "male",
    [Gender.Female]: "female",
};

const FOR_HUMANS_BABY = {
    [GenderBaby.Male]: "boy",
    [GenderBaby.Female]: "girl",
    [GenderBaby.Surprise]: "keepItASurprise",
};

export const getGenderChoices = (selected: Gender | undefined) =>
    Object.values(Gender)
        .map((gender) => ({
            key: gender,
            text: FOR_HUMANS[gender],
            isSelected: gender === selected,
        }))
        .sort(({ text: a }, { text: b }) => a.localeCompare(b));

export const genderForHumans = (gender: Gender) => FOR_HUMANS[gender];

export const genderBabyForHumans = (gender: GenderBaby) =>
    FOR_HUMANS_BABY[gender];
