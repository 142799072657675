import { HStack, VStack } from "native-base";
import { formatDate } from "@madmedical/utils";
import type { Patient } from "../../providers/patient";
import AvatarTexts from "../AvatarTexts";

interface Props {
    item: Patient;
}

const SimpleAvatarListItem = ({ item }: Props) => {
    const subText = [
        item.birthDate ? formatDate(item.birthDate) : null,
        "socialSecurityNumber" in item ? item.socialSecurityNumber : null,
    ]
        .filter((fragment) => !!fragment)
        .join(" | ");

    return (
        <HStack
            space={4}
            justifyContent="space-between"
            alignItems="center"
            bgColor="white"
            px={4}
            py={5.5}
            borderBottomColor="gray.100"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            width="full"
        >
            <VStack width={"100%"}>
                <AvatarTexts
                    size="xl"
                    source={item.avatarUrl}
                    initials={item.initials}
                    label={item.fullName}
                    subText={subText ? subText : undefined}
                />
            </VStack>
        </HStack>
    );
};

export default SimpleAvatarListItem;
