const baseStyle = () => ({
    _light: {
        color: "black",
    },
    _dark: {
        color: "text.50",
    },
    fontWeight: "medium",
    lineHeight: "sm",
});

const sizes = {
    "4xl": {
        fontSize: { base: "6xl", md: "7xl" },
        letterSpacing: "xl",
    },
    "3xl": {
        fontSize: { base: "5xl", md: "6xl" },
        letterSpacing: "xl",
    },
    "2xl": {
        fontSize: "2xl",
    },
    xl: {
        fontSize: "2xl",
    },
    lg: {
        fontSize: "lg",
    },
    md: { fontSize: "md" },
    sm: { fontSize: "sm" },
    xs: { fontSize: "xs" },
};

const defaultProps = {
    size: "lg",
};

export default {
    baseStyle,
    sizes,
    defaultProps,
};
