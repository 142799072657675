export enum Risk {
    Cardiological = "cardiological",
    Dehydration = "dehydration",
    Exhaustion = "exhaustion",
    Illness = "illness",
    Infection = "infection",
    LackOfSleep = "lackOfSleep",
    Obesity = "obesity",
    Stress = "stress",
}

const FOR_HUMANS = {
    [Risk.Cardiological]: "riskCardiological",
    [Risk.Dehydration]: "riskDehydration",
    [Risk.Exhaustion]: "riskExhaustion",
    [Risk.Illness]: "riskIllness",
    [Risk.Infection]: "riskInfection",
    [Risk.LackOfSleep]: "riskLackOfSleep",
    [Risk.Obesity]: "riskObesity",
    [Risk.Stress]: "riskStress",
};

export const riskForHumans = (risk: Risk) => FOR_HUMANS[risk];
