import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const BabyBirth = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <G clipPath="url(#clip0_231_72866)">
            <Circle cx="65" cy="65" r="65" fill="white" fillOpacity="0.83" />
            <Path
                d="M65 17.9844C34.3441 17.9844 9.49243 42.8358 9.49243 73.492C9.49243 97.2733 24.4494 117.558 45.4683 125.462L48.8645 126.614C52.9444 127.852 57.2228 128.632 61.6408 128.896L65.2787 128.996C71.3834 128.966 77.2557 127.953 82.7435 126.102L87.4981 124.247C106.944 115.615 120.508 96.1387 120.508 73.4922C120.508 42.836 95.656 17.9844 65 17.9844Z"
                fill="white"
            />
            <Path
                d="M68.4352 96.2689C68.4352 95.2874 67.6397 94.4922 66.6582 94.4922C65.677 94.4922 64.8815 95.2877 64.8815 96.2689C64.8815 97.1808 64.5301 97.5753 63.7356 98.38C62.8196 99.308 61.5649 100.579 61.5649 102.988C61.5649 105.397 62.8194 106.668 63.7356 107.596C64.5304 108.401 64.8815 108.795 64.8815 109.707C64.8815 110.62 64.5301 111.014 63.7356 111.819C62.8196 112.747 61.5649 114.018 61.5649 116.427C61.5649 118.836 62.8194 120.107 63.7353 121.035C64.5301 121.841 64.8815 122.235 64.8815 123.147C64.8815 124.059 64.5301 124.454 63.7356 125.259C62.9517 126.053 61.9224 127.101 61.641 128.896C62.7525 128.963 63.8717 129 65 129C65.0931 129 65.1856 128.997 65.2787 128.997C65.4529 128.594 65.7737 128.253 66.2645 127.756C67.1805 126.828 68.4352 125.557 68.4352 123.148C68.4352 120.738 67.1805 119.467 66.2645 118.539C65.4697 117.734 65.1185 117.34 65.1185 116.428C65.1185 115.515 65.4699 115.121 66.2645 114.316C67.1805 113.388 68.4352 112.117 68.4352 109.708C68.4352 107.298 67.1805 106.028 66.2645 105.1C65.4697 104.294 65.1185 103.9 65.1185 102.988C65.1185 102.076 65.4699 101.682 66.2645 100.877C67.1805 99.9489 68.4352 98.6782 68.4352 96.2689Z"
                fill="#C3B7B6"
            />
            <Path
                d="M46.6971 120.005C46.1747 119.497 45.3006 118.647 45.249 118.291C45.1706 117.749 45.7635 116.691 46.2402 115.841C47.0041 114.477 47.7944 113.067 47.5221 111.518C47.1899 109.627 45.6688 108.324 44.4467 107.277C43.9467 106.849 43.1938 106.204 43.0699 105.908C42.8243 104.71 43.1333 104.308 43.7457 103.512C44.4381 102.612 45.4846 101.252 44.8792 98.9601C44.6286 98.0115 43.6567 97.4451 42.7076 97.6963C41.7588 97.9469 41.1928 98.9193 41.4436 99.8681C41.5984 100.454 41.507 100.595 40.9295 101.345C40.1651 102.339 39.0096 103.841 39.5953 106.653C39.886 108.049 41.0295 109.028 42.1351 109.976C42.9262 110.654 43.9106 111.497 44.0219 112.13C44.0563 112.469 43.4829 113.492 43.1403 114.104C42.3625 115.492 41.481 117.065 41.7325 118.8C41.957 120.351 43.1589 121.52 44.2192 122.552C44.8704 123.186 45.7623 124.054 45.8176 124.543C45.8385 124.727 45.6817 125.078 45.4689 125.462C46.5845 125.882 47.7167 126.267 48.8651 126.615C49.2222 125.84 49.4476 125.017 49.3488 124.143C49.1501 122.393 47.8469 121.124 46.6971 120.005Z"
                fill="#C3B7B6"
            />
            <Path
                d="M90.4994 98.5431C89.5501 98.2914 88.5784 98.8585 88.3276 99.8071C88.1667 100.416 87.9657 100.535 87.1399 100.942C86.0026 101.502 84.2834 102.349 83.6792 105.252C83.379 106.693 84.094 108.064 84.7857 109.39C85.2635 110.306 85.858 111.446 85.745 112.09C85.67 112.422 84.8086 113.209 84.2938 113.68C83.0908 114.78 81.7273 116.027 81.4684 117.815C81.2366 119.415 82.1231 120.889 82.9052 122.19C83.369 122.96 84.0038 124.016 83.9472 124.516C83.9096 124.849 83.2516 125.57 82.743 126.103C84.3632 125.556 85.9484 124.935 87.4979 124.248C87.4279 122.817 86.6494 121.521 85.9505 120.359C85.5957 119.769 84.9352 118.67 84.9851 118.325C85.0621 117.793 85.9659 116.967 86.692 116.303C87.8169 115.274 88.9803 114.21 89.2444 112.707C89.5671 110.873 88.6629 109.14 87.9365 107.747C87.6302 107.16 87.1691 106.276 87.1632 105.952C87.4147 104.768 87.823 104.567 88.7102 104.129C89.6949 103.645 91.1831 102.912 91.7634 100.714C92.0142 99.7661 91.4482 98.7937 90.4994 98.5431Z"
                fill="#C3B7B6"
            />
            <Path
                d="M20.8314 108.441C19.9041 108.441 19.1232 107.722 19.0604 106.783C18.9265 104.783 20.2974 103.681 21.1165 103.023C21.6224 102.616 21.8616 102.4 21.8528 102.27C21.8442 102.14 21.5782 101.957 21.0229 101.622C20.1234 101.079 18.6179 100.17 18.4838 98.1693C18.3499 96.1688 19.7208 95.0672 20.5401 94.4093C21.0458 94.0028 21.2848 93.7864 21.2762 93.6566C21.2107 92.6774 21.9513 91.8305 22.9303 91.7651C23.9095 91.6971 24.7562 92.4402 24.8219 93.4192C24.9558 95.4197 23.5849 96.5211 22.7656 97.1792C22.2599 97.5857 22.0207 97.802 22.0295 97.9319C22.0381 98.0619 22.3041 98.2445 22.8596 98.5798C23.7591 99.1231 25.2644 100.032 25.3985 102.032C25.5324 104.033 24.1615 105.135 23.3424 105.793C22.8365 106.199 22.5973 106.416 22.6061 106.546C22.6716 107.525 21.9309 108.372 20.952 108.437C20.9114 108.44 20.8711 108.441 20.8314 108.441Z"
                fill="#FAB398"
            />
            <Path
                d="M48.7396 30.7904C47.8123 30.7904 47.0313 30.071 46.9686 29.1322C46.8347 27.1316 48.2056 26.0301 49.0246 25.3719C49.5303 24.9655 49.7696 24.7491 49.7607 24.619C49.7521 24.489 49.4861 24.3064 48.9306 23.9711C48.0312 23.4278 46.5259 22.5188 46.3918 20.5183C46.2579 18.5179 47.6288 17.4164 48.4478 16.7582C48.9535 16.352 49.1927 16.1356 49.1839 16.0056C49.1184 15.0264 49.8588 14.1797 50.838 14.114C51.8175 14.0433 52.6641 14.7889 52.7296 15.7681C52.8635 17.7687 51.4926 18.8702 50.6736 19.5284C50.1679 19.9346 49.9286 20.151 49.9375 20.2808C49.9461 20.4109 50.2121 20.5932 50.7676 20.9288C51.667 21.4721 53.1723 22.3811 53.3064 24.3816C53.4403 26.3821 52.0694 27.4835 51.2504 28.1419C50.7447 28.5483 50.5054 28.7647 50.5143 28.8947C50.58 29.8739 49.8394 30.7206 48.8602 30.7863C48.8198 30.7892 48.7795 30.7904 48.7396 30.7904Z"
                fill="#F6DCB6"
            />
            <Path
                d="M76.5991 29.1252C75.8852 29.1252 75.2121 28.6918 74.9404 27.9861C74.5881 27.0701 75.0449 26.0419 75.9609 25.6896L78.5582 24.6905C79.4733 24.3375 80.5019 24.7952 80.8544 25.7107C81.2067 26.6267 80.7499 27.6548 79.8339 28.0071L77.2366 29.0063C77.0271 29.0869 76.8114 29.1252 76.5991 29.1252Z"
                fill="#FAB398"
            />
            <Path
                d="M38.864 28.1268C38.3953 28.1268 37.9272 27.9424 37.578 27.5765L35.657 25.5628C34.9798 24.8528 35.0063 23.7281 35.7161 23.0507C36.4262 22.3735 37.5509 22.3998 38.2283 23.1098L40.1493 25.1235C40.8265 25.8336 40.8 26.9582 40.0902 27.6357C39.746 27.9639 39.3047 28.1268 38.864 28.1268Z"
                fill="#B6D2C6"
            />
            <Path
                d="M94.6999 97.6515L92.3901 92.5731C91.5511 92.5824 90.7115 92.5201 89.8743 92.3751C89.0372 92.2301 88.2254 92.0065 87.4385 91.7158L83.5555 95.7219C82.5686 96.7403 82.8176 98.4239 84.0625 99.1029C85.433 99.8503 86.8959 100.378 88.442 100.646C89.988 100.914 91.5432 100.908 93.0851 100.665C94.4862 100.444 95.2869 98.9425 94.6999 97.6515Z"
                fill="#FACD8A"
            />
            <Path
                d="M93.5322 95.0843L92.3901 92.5731C91.5511 92.5824 90.7115 92.5199 89.8743 92.3751C89.0372 92.2301 88.2254 92.0065 87.4385 91.7158L83.5555 95.7219C82.5686 96.7403 82.8176 98.4241 84.0625 99.1029C85.433 99.8503 86.8957 100.378 88.442 100.646C88.4547 100.648 88.4676 100.649 88.4805 100.652C86.4822 94.1056 91.3791 94.5895 93.5322 95.0843Z"
                fill="#EDAF7C"
            />
            <Path
                d="M35.3002 97.6515L37.61 92.5731C38.4489 92.5824 39.2886 92.5201 40.1257 92.3751C40.9629 92.2301 41.7746 92.0065 42.5615 91.7158L46.4443 95.7219C47.4312 96.7403 47.1822 98.4239 45.9373 99.1029C44.5668 99.8503 43.1039 100.378 41.5578 100.646C40.0118 100.914 38.4566 100.908 36.9147 100.665C35.5138 100.444 34.7131 98.9425 35.3002 97.6515Z"
                fill="#B6D2C6"
            />
            <Path
                d="M44.1486 93.3532L42.5615 91.7158C41.7746 92.0065 40.9629 92.2301 40.1257 92.3751C39.2886 92.5201 38.4489 92.5824 37.61 92.5731L35.3002 97.6515C34.7131 98.9425 35.5138 100.444 36.9147 100.665C38.4566 100.908 40.0118 100.914 41.5578 100.646C41.8988 100.587 42.2348 100.513 42.5674 100.429C37.2416 96.5595 41.7012 94.242 44.1486 93.3532Z"
                fill="#7B9791"
            />
            <Path
                d="M70.655 93.1226L67.5126 88.5127C66.6877 88.6649 65.8496 88.7467 65 88.7467C64.1504 88.7467 63.3124 88.6649 62.4874 88.5127L59.345 93.1226C58.5464 94.2943 59.079 95.9109 60.4216 96.3676C61.8995 96.8704 63.4308 97.1406 65 97.1406C66.5692 97.1406 68.1005 96.8704 69.5784 96.3676C70.921 95.9109 71.4539 94.2941 70.655 93.1226Z"
                fill="#FAB398"
            />
            <Path
                d="M68.9873 90.6759L67.5126 88.5127C66.6877 88.6649 65.8496 88.7467 65 88.7467C64.1504 88.7467 63.3124 88.6649 62.4874 88.5127L59.345 93.1226C58.5464 94.2943 59.079 95.9109 60.4216 96.3676C61.8995 96.8704 63.4308 97.1406 65 97.1406C62.2167 91.1444 67.1655 90.661 68.9873 90.6759Z"
                fill="#C65151"
            />
            <Path
                d="M94.2929 25.0316L91.0497 23.5485C90.5329 23.3122 89.9221 23.5395 89.6858 24.0565L88.2025 27.3C87.9662 27.8167 88.1937 28.4273 88.7104 28.6636L91.9537 30.1467C92.4705 30.383 93.0813 30.1558 93.3176 29.639L94.8009 26.3955C95.0372 25.8785 94.8097 25.2679 94.2929 25.0316Z"
                fill="#F6DCB6"
            />
            <Path
                d="M62.2088 14.5765L65.452 13.0935C65.9688 12.8571 66.5796 13.0844 66.8159 13.6014L68.2992 16.8446C68.5356 17.3614 68.3081 17.972 67.7913 18.2083L64.5481 19.6916C64.0313 19.9279 63.4204 19.7007 63.1841 19.1839L61.7008 15.9404C61.4645 15.4234 61.692 14.8128 62.2088 14.5765Z"
                fill="#B6D2C6"
            />
            <Path
                d="M99.3612 37.5865C93.0249 36.4892 86.9161 37.8112 82.1413 41.4407C85.2015 45.3551 87.0183 50.6301 87.0183 57.0699C87.0183 66.2595 83.2277 76.3851 77.3386 82.6513C80.3134 87.9599 84.5873 91.8287 89.8122 92.7334C102.003 94.8443 114.748 80.0275 117.353 64.9849C119.957 49.9421 111.552 39.6976 99.3612 37.5865Z"
                fill="#F6DCB6"
            />
            <Path
                d="M42.9816 57.0698C42.9816 50.6368 44.7966 45.3588 47.8588 41.4407C43.084 37.8111 36.9754 36.4894 30.6389 37.5864C18.448 39.6973 10.0428 49.942 12.6476 64.9846C15.2524 80.0272 27.997 94.8439 40.1878 92.733C45.4128 91.8284 49.6867 87.9596 52.6615 82.6512C46.7722 76.3852 42.9816 66.2594 42.9816 57.0698Z"
                fill="#B6D2C6"
            />
            <Path
                d="M87.4021 57.0694C87.4021 72.3359 77.3724 89.1099 65 89.1099C52.6277 89.1099 42.598 72.3361 42.598 57.0694C42.598 41.8027 52.6277 33.1426 65 33.1426C77.3724 33.1426 87.4021 41.8029 87.4021 57.0694Z"
                fill="#FAB398"
            />
            <Path
                d="M77.4379 54.4551C79.8466 54.4551 81.7992 52.5024 81.7992 50.0938C81.7992 47.6851 79.8466 45.7324 77.4379 45.7324C75.0292 45.7324 73.0765 47.6851 73.0765 50.0938C73.0765 52.5024 75.0292 54.4551 77.4379 54.4551Z"
                fill="#FFF3F0"
            />
            <Path
                d="M70.4994 59.2695C71.9227 59.2695 73.0765 58.1157 73.0765 56.6924C73.0765 55.2691 71.9227 54.1152 70.4994 54.1152C69.0761 54.1152 67.9222 55.2691 67.9222 56.6924C67.9222 58.1157 69.0761 59.2695 70.4994 59.2695Z"
                fill="#FFF3F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_231_72866">
                <Rect width="130" height="130" fill="white" />
            </ClipPath>
        </Defs>
    </Svg>
);

export default BabyBirth;
