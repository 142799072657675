import type { ElementRef } from "react";
import { useRef, useState } from "react";
import type { Gender, Service, UnderlyingCondition } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { extractSelected } from "../utils";
import ActionSheet from "../../../molecules/ActionSheet";
import Button from "../../../atoms/Button";
import { usePatients } from "../../../providers/patient";
import MobileFilterList from "./MobileFilterList";

interface Selected {
    services: Service[];
    genders: Gender[];
    underlyingConditions: UnderlyingCondition[];
}

const emptySelected: Selected = {
    services: [],
    genders: [],
    underlyingConditions: [],
};

const PatientsMobileFilter = () => {
    const { onFilterChange, filterChoices } = usePatients();
    const actionSheetRef = useRef<ElementRef<typeof ActionSheet>>(null);
    const [selected, setSelected] = useState({
        services: extractSelected(filterChoices, "services"),
        genders: extractSelected(filterChoices, "genders"),
        underlyingConditions: extractSelected(
            filterChoices,
            "underlyingConditions"
        ),
    });
    const { t } = useTranslation();
    const handleTogglePress = () => {
        actionSheetRef.current?.toggle();
    };

    const handleResetPress = () => {
        setSelected(emptySelected);
    };

    const handleSubmitPress = () => {
        onFilterChange?.("services", selected.services);
        onFilterChange?.("genders", selected.genders);
        onFilterChange?.("underlyingConditions", selected.underlyingConditions);
        actionSheetRef.current?.toggle();
    };

    const handleChange =
        <
            TFilterKey extends keyof typeof selected,
            TValues extends (typeof selected)[TFilterKey]
        >(
            filterKey: TFilterKey
        ) =>
        (newSelected: TValues) => {
            setSelected((prevSelected) => ({
                ...prevSelected,
                [filterKey]: newSelected,
            }));
        };

    return (
        <>
            <ActionSheet
                title={t("filter")}
                footer={
                    <Button onPress={handleSubmitPress} size="xl">
                        {t("showResults")}
                    </Button>
                }
                rightContent={
                    <Button
                        position="absolute"
                        right={0}
                        top={0}
                        onPress={handleResetPress}
                        size="xs"
                        variant="link"
                        p={0}
                    >
                        {t("setback")}
                    </Button>
                }
                ref={actionSheetRef}
            >
                <MobileFilterList
                    filterChoices={filterChoices.services}
                    selected={selected.services}
                    isMultiSelect={true}
                    title={t("package")}
                    onChange={handleChange("services")}
                />
                <MobileFilterList
                    filterChoices={filterChoices.genders}
                    selected={selected.genders}
                    isMultiSelect={false}
                    title={t("no")}
                    onChange={handleChange("genders")}
                />
                <MobileFilterList
                    filterChoices={filterChoices.underlyingConditions}
                    selected={selected.underlyingConditions}
                    isMultiSelect={true}
                    title="Alapbetegség"
                    onChange={handleChange("underlyingConditions")}
                />
            </ActionSheet>
            <Button
                variant="outlined"
                size="xs"
                onPress={handleTogglePress}
                leftIconName="filter"
            >
                {t("filter")}
            </Button>
        </>
    );
};

export default PatientsMobileFilter;
