import { useParams } from "@madmedical/routing";
import type { DateRange, Ulid } from "@madmedical/utils";
import { invariant, rangeToDate } from "@madmedical/utils";
import { skipToken, useDateRange } from "@madmedical/store";
import { useEffect, useState } from "react";
import type { FileToUpload } from "@madmedical/ui";
import { EvaluatingProcess, Spinner, useShowToast } from "@madmedical/ui";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import { usePatientId, useRouteNavigate } from "@madmedical/user";
import {
    DeleteDocumentDialog,
    useAttachDocumentMutation,
    useUploadDocumentMutation,
} from "@madmedical/document";
import adaptOpinion from "../adaptOpinion";
import MeasurementProviders from "../../measurement/components/MeasurementProviders";
import {
    useCreateOpinionMutation,
    useGetOpinionQuery,
    useUpdateOpinionMutation,
} from "../api";

enum ModalMode {
    Closed,
    DeleteDocument,
}

interface FormValues {
    text: string;
    alertLevel: AlertLevel;
    newFiles: FileToUpload[];
}

const OpinionForm = () => {
    const { patientId } = usePatientId();
    const { opinionId } = useParams<"opinionId">();
    const { metricType: metricTypeParam } = useParams<{
        metricType: MetricType;
    }>();
    const {
        data: opinion,
        isLoading,
        refetch: refetchOpinion,
    } = useGetOpinionQuery(opinionId ?? skipToken);
    const [createOpinion, { isLoading: isCreateLoading }] =
        useCreateOpinionMutation();
    const [updateOpinion, { isLoading: isUpdateLoading }] =
        useUpdateOpinionMutation();
    const [upload, { isLoading: isUploadLoading }] =
        useUploadDocumentMutation();
    const [attach, { isLoading: isAttachLoading }] =
        useAttachDocumentMutation();
    const navigate = useRouteNavigate();
    const { setDateRange } = useDateRange();
    const [selectedRange, setSelectedRange] = useState<DateRange | null>(null);
    const [modalMode, setModalMode] = useState(ModalMode.Closed);
    const [idForModal, setIdForModal] = useState<Ulid>();
    const showToast = useShowToast();

    useEffect(() => {
        if (!opinion) {
            return;
        }

        setSelectedRange(rangeToDate(opinion.dateRange));
        setDateRange(opinion.extendedRange);
    }, [opinion, setDateRange]);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line prefer-const
        let timeout: string | number | NodeJS.Timeout | undefined;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setIsSaving(
                isCreateLoading ||
                    isUpdateLoading ||
                    isUploadLoading ||
                    isAttachLoading
            );
        }, 100);

        return () => clearTimeout(timeout);
    }, [isCreateLoading, isUpdateLoading, isUploadLoading, isAttachLoading]);

    const handleDeleteDocumentPress = (id: Ulid) => {
        setIdForModal(id);
        setModalMode(ModalMode.DeleteDocument);
    };

    const handleModalClose = () => {
        void refetchOpinion()
            .unwrap()
            .then(() => {
                setModalMode(ModalMode.Closed);
            });
    };

    if (isLoading) {
        return <Spinner />;
    }

    const resolvedMetricType = metricTypeParam ?? opinion?.metricType;
    invariant(resolvedMetricType);

    const handleSubmit = async ({ text, alertLevel, newFiles }: FormValues) => {
        invariant(selectedRange);

        const commonPayload = {
            dateRange: selectedRange,
            text,
            alertLevel,
        };

        try {
            const opinionResult = await (opinionId
                ? updateOpinion({
                      id: opinionId,
                      ...commonPayload,
                  })
                : createOpinion({
                      patientId,
                      metricType: resolvedMetricType,
                      ...commonPayload,
                  })
            ).unwrap();

            if (opinionResult) {
                if (newFiles.length) {
                    const formData = new FormData();
                    newFiles.forEach(({ file }) =>
                        formData.append("file[]", file)
                    );

                    const documents = await upload(formData).unwrap();
                    const promises = documents.map((document, index) =>
                        attach({
                            id: document.id,
                            patientId,
                            opinionId: opinionResult.id,
                            note: newFiles[index].note,
                        }).unwrap()
                    );
                    await Promise.all(promises);
                    // await refetchOpinion();
                }

                showToast("Sikeres mentés.", { variant: "success" });
                setTimeout(() => {
                    navigate(
                        "opinion",
                        { opinionId: opinionResult.id },
                        { replace: false }
                    );
                }, 1000);
            }
        } catch (err) {
            showToast("Hiba történt.", { variant: "error" });
        }
    };

    return (
        <MeasurementProviders
            metricType={resolvedMetricType}
            selectedRange={selectedRange}
            onSelectRange={setSelectedRange}
        >
            <EvaluatingProcess
                evaluation={opinion ? adaptOpinion(opinion) : undefined}
                metricType={resolvedMetricType}
                onSubmit={handleSubmit}
                onDeleteDocumentPress={handleDeleteDocumentPress}
                isSaving={isSaving}
                isUploading={isUploadLoading || isAttachLoading}
                isSubmitDisabled={!selectedRange}
            />
            <DeleteDocumentDialog
                id={idForModal}
                open={modalMode === ModalMode.DeleteDocument}
                onClose={handleModalClose}
            />
        </MeasurementProviders>
    );
};

export default OpinionForm;
