import { formatDate } from "@madmedical/utils";
import { Box, HStack, Text, VStack, useTheme } from "native-base";
import type { ComponentProps } from "react";
import { MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Icon from "../../icons/Icon";
import PressableLink from "../../atoms/PressableLink";
import {
    thumbnailRoot,
    thumbnailUnit,
    thumbnailValue,
    thumbnailValueSlot,
} from "../useStyles";

interface Props {
    steps: number;
    alertThreshold: number;
    warningThreshold: number;
    measuredAt: Date;
}

const DailyStepsWidget = ({
    steps,
    alertThreshold,
    warningThreshold,
    measuredAt,
}: Props) => {
    const { colors } = useTheme();
    const wholeStep = warningThreshold * 1.1;
    const { t } = useTranslation();
    const getPercent = (val: number) => (val / wholeStep) * 100;

    const getColor = (): ComponentProps<typeof Box>["bg"] => {
        if (steps < alertThreshold) {
            return "error.400";
        } else if (steps >= alertThreshold && steps < warningThreshold) {
            return "warning.400";
        }

        return "green.400";
    };

    return (
        <VStack style={thumbnailRoot}>
            <PressableLink
                route="measurements"
                params={{ metricType: MetricType.DailySteps }}
                flex={1}
            >
                <Text fontSize="sm" fontWeight="medium" color="black">
                    {t("measures:steps")[0].toUpperCase() +
                        t("measures:steps").slice(1)}
                </Text>

                <HStack style={thumbnailValueSlot} space={1}>
                    <Text numberOfLines={1} style={thumbnailValue}>
                        {steps}
                    </Text>
                    <Text style={thumbnailUnit}>{t("measures:steps")}</Text>
                </HStack>

                <VStack flex={1} justifyContent="flex-end" mb={2} minH="10">
                    <Box w="full" position="relative" mb={-1}>
                        <Box
                            w={0.5}
                            h={3}
                            position="absolute"
                            bottom={0}
                            left={`${getPercent(alertThreshold)}%`}
                        >
                            <VStack
                                position="absolute"
                                left="px"
                                bottom={0}
                                ml={-8}
                                width={16}
                                alignItems="center"
                            >
                                <Text fontSize="xs" color="gray.600" mb={-1}>
                                    {alertThreshold.toLocaleString()}
                                </Text>
                                <Icon
                                    fill={colors.warning[400]}
                                    name="caretDown"
                                />
                            </VStack>
                        </Box>

                        <Box
                            w={0.5}
                            h={3}
                            position="absolute"
                            bottom={0}
                            left={`${getPercent(warningThreshold)}%`}
                        >
                            <VStack
                                position="absolute"
                                left="px"
                                bottom={0}
                                ml={-8}
                                width={16}
                                alignItems="center"
                            >
                                <Text fontSize="xs" color="gray.600" mb={-1}>
                                    {warningThreshold.toLocaleString()}
                                </Text>
                                <Icon
                                    fill={colors.green[400]}
                                    name="caretDown"
                                />
                            </VStack>
                        </Box>
                    </Box>
                    <Box
                        bg="gray.100"
                        h={3}
                        borderRadius="xs"
                        overflow="hidden"
                        position="relative"
                    >
                        <Box
                            width={`${getPercent(steps)}%`}
                            maxW="full"
                            h={3}
                            bg={getColor()}
                        />
                        <Box
                            w={0.5}
                            h={3}
                            bg="white"
                            position="absolute"
                            top={0}
                            left={`${getPercent(alertThreshold)}%`}
                        />
                        <Box
                            w={0.5}
                            h={3}
                            bg="white"
                            position="absolute"
                            top={0}
                            left={`${getPercent(warningThreshold)}%`}
                        />
                    </Box>
                </VStack>

                <Text
                    fontSize="xs"
                    color="gray.600"
                    textAlign="right"
                    textTransform="capitalize"
                >
                    {formatDate(measuredAt)}
                </Text>
            </PressableLink>
        </VStack>
    );
};

export default DailyStepsWidget;
