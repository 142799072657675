import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { useRouteNavigate } from "@madmedical/user";
import { useTranslation } from "@madmedical/i18n";
import { useDeleteOpinionMutation } from "../api";

interface Props {
    id: Ulid;
    open: boolean;
    onClose: () => void;
}

const DeleteOpinionDialog = ({ id, open, onClose }: Props) => {
    const navigate = useRouteNavigate();
    const [deleteOpinion, { isLoading: isSubmitting }] =
        useDeleteOpinionMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    const handleConfirm = () => {
        void deleteOpinion(id)
            .unwrap()
            .then(() => {
                showToast(t("resultDeleted"), { variant: "success" });
                onClose();
                navigate("opinions", {});
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            onClose={onClose}
        >
            <Text>{t("sureToDeleteResult")}</Text>
        </Dialog>
    );
};

export default DeleteOpinionDialog;
