import { HStack, Text, VStack } from "native-base";
import type { DateTimeString, Ulid } from "@madmedical/utils";
import { formatTime, isWeb } from "@madmedical/utils";
import type { InsulinType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { formatType } from "../../util/insulinFormatters";
import ActionButton from "../ActionButton";
import type { InsulinReminder } from "../../providers/insulinReminder";

interface Props {
    id: Ulid;
    manual?: boolean;
    editable?: boolean;
    deleted?: boolean;
    onDeletePress: (id: Ulid) => void;
    onEditPress?: (reminder: InsulinReminder) => void;

    firstOccurrence: Date | DateTimeString;
    insulinType: InsulinType;
    daysOfWeek: number[];
    comment: string | null;

    isLast?: boolean;
}

const InsulinReminderListItem = ({
    id,
    firstOccurrence,
    insulinType,
    daysOfWeek,
    comment,
    deleted,
    editable,
    onDeletePress,
    onEditPress,
    isLast,
}: Props) => {
    const { t } = useTranslation();

    return (
        <HStack
            space={4}
            justifyContent="space-between"
            alignItems="center"
            bgColor="white"
            px={4}
            py={5.5}
            borderBottomColor="gray.100"
            borderBottomWidth={isLast ? 0 : 1}
            borderBottomStyle="solid"
            width="full"
        >
            <VStack width="full">
                <HStack
                    space={1}
                    alignItems="center"
                    mb={1}
                    justifyContent="space-between"
                    width="full"
                >
                    <Text>
                        {firstOccurrence
                            ? formatTime(firstOccurrence)
                            : t("noAppointment")}
                    </Text>
                    <ActionButton
                        actions={
                            !deleted && editable
                                ? [
                                      {
                                          text: t("edit"),
                                          iconName: "edit",
                                          onPress: () => {
                                              onEditPress?.({
                                                  id,
                                                  daysOfWeek,
                                                  firstOccurrence:
                                                      firstOccurrence as Date,
                                                  insulinType,
                                                  comment,
                                              });
                                          },
                                      },
                                      {
                                          text: t("delete"),
                                          iconName: "trashCan",
                                          onPress: () => {
                                              onDeletePress(id);
                                          },
                                      },
                                  ]
                                : []
                        }
                    />
                </HStack>
                <HStack space={2} alignItems="center" mb={1}>
                    <Text fontSize="xs" color="gray.600">
                        {t(`${formatType(insulinType)}`)}
                    </Text>
                </HStack>
                <Text
                    fontSize="xs"
                    color="gray.600"
                    isTruncated
                    maxW={isWeb ? "85vw" : "auto"}
                    w="98%"
                    noOfLines={3}
                >
                    {comment ?? "-"}
                </Text>
            </VStack>
        </HStack>
    );
};

export default InsulinReminderListItem;
