import { Box, Divider, HStack, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import MeasurementProtocolModalFooter from "../../../molecules/MeasurementProtocolModalFooter";
import Bar from "../../../atoms/Bar";
import MeasurementProtocolsOffsetSettingsTitle, {
    AlertStatusLegend,
} from "../../../molecules/MeasurementProtocolsOffsetSettingsTitle";
import ModalContent from "../../../molecules/ModalContent";
import type { Props } from "../model";

const BodyMassIndexMobile = ({ onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("measures:BMISetup")}
            footer={<MeasurementProtocolModalFooter onClose={onClose} />}
        >
            <VStack space={4} p={4}>
                <MeasurementProtocolsOffsetSettingsTitle
                    hasLegend={false}
                    hasAlertLabel={false}
                />
                <HStack space={0.5}>
                    <VStack space={1} height={71}>
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={1}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "green.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            width={1}
                            height="full"
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={1}
                            width={1}
                            height="full"
                        />
                    </VStack>
                    <VStack>
                        <Box flex={1} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={2} />
                        <Divider width={3} />
                        <Box flex={1} />
                    </VStack>
                    <VStack ml={1}>
                        <Box flex={1} />
                        <Text color="gray.600" minWidth={16}>
                            16,1
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            17
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            18,5
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            24,9
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            29,9
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            34,9
                        </Text>
                        <Box flex={2} />
                        <Text color="gray.600" minWidth={16}>
                            39,9
                        </Text>
                        <Box flex={1} />
                    </VStack>
                </HStack>
                <AlertStatusLegend
                    hasAlertLabel={false}
                    justifyContent="flex-start"
                />
            </VStack>
        </ModalContent>
    );
};

export default BodyMassIndexMobile;
