const baseStyle = () => ({
    py: 2,
    px: 3,
    shadow: "none",
    rounded: "sm",
    maxWidth: 320,
    _text: {
        fontSize: "xs",
    },
    _light: {
        bg: "gray.900",
        _text: {
            color: "white",
        },
    },
    _dark: {
        bg: "muted.50",
        _text: {
            color: "text.900",
        },
    },
});

export const Tooltip = {
    baseStyle,
};
