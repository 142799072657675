import type { DateTimeString, Ulid } from "@madmedical/utils";
import type { GenderBaby } from "./gender";

interface PregnancyCreatedBy {
    avatarUrl: string;
    familyName: string;
    fullName: string;
    givenName: string;
    initials: string;
    namePrefix: string | null;
    role: "patient" | "doctor";
    userId: string;
}
export interface Pregnancy {
    readonly id: Ulid;
    readonly expectedAt: DateTimeString;
    readonly startedAt: DateTimeString;
    readonly miscarriedAt: DateTimeString | null;
    readonly bornAt: DateTimeString | null;
    readonly miscarriedAmount: number | null;
    readonly status: PregnancyStatus;
    readonly twinPregnancyComment: string | null;
    readonly infantComment: string | null;
    readonly isHighRisk: boolean | null;
    readonly infants: Infant[];
    readonly genderMethod: PrenatalTestingMethod | null;
    readonly twinPregnancyMethod: PrenatalTestingMethod | null;
    readonly createdBy?: PregnancyCreatedBy;
}
export interface PregnancyWellBeing {
    readonly id: Ulid;
    readonly createdAt: DateTimeString;
    readonly status: PregnancyWellBeingStatus;
    readonly symptoms: PregnancySymptom[];
    readonly comment: string | null;
}
export interface Infant {
    readonly id: Ulid;
    readonly gender: GenderBaby | null;
    readonly doctorGender?: GenderBaby | null;
    readonly givenName: string | null;
    readonly length: number | null;
    readonly weight: number | null;
    readonly headCircumference: number | null;
}

export enum PregnancyWellBeingStatus {
    BadTemper = "badTemper",
    Moody = "moody",
    Fluctuating = "fluctuating",
    Calm = "calm",
    Cheerful = "cheerful",
}

export enum PregnancySymptom {
    NoSymptom = "noSymptom",
    FatigueExhaustion = "fatigueExhaustion",
    MorningNausea = "morningNausea",
    ShortnessOfBreath = "shortnessOfBreath",
    ColdFluSymptoms = "coldFluSymptoms",
    PullingSensation = "pullingSensation",
    Headache = "headache",
    Acne = "acne",
    Dizziness = "dizziness",
    VisionChanges = "visionChanges",
    NeckPain = "neckPain",
    GumNoseBleeding = "gumNoseBleeding",
    SensitiveBreasts = "sensitiveBreasts",
    BackPain = "backPain",
    ChestPain = "chestPain",
    Heartburn = "heartburn",
    SoreNipples = "soreNipples",
    SwollenBreasts = "swollenBreasts",
    Bloating = "bloating",
    AbdominalPain = "abdominalPain",
    StomachPain = "stomachPain",
    Constipation = "constipation",
    IncreasedAppetite = "increasedAppetite",
    DecreasedAppetite = "decreasedAppetite",
    Cravings = "cravings",
    Aversion = "aversion",
    Diarrhea = "diarrhea",
    Vomiting = "vomiting",
    AbdominalItching = "abdominalItching",
    BowelMovement = "bowelMovement",
    NoBowelMovement = "noBowelMovement",
    IncreasedThirst = "increasedThirst",
    FrequentUrination = "frequentUrination",
    RareUrination = "rareUrination",
    VaginalBleeding = "vaginalBleeding",
    HeavyVaginalBleeding = "heavyVaginalBleeding",
    PelvicDiscomfortPressure = "pelvicDiscomfortPressure",
    ContractionsBraxtonHicks = "contractionsBraxtonHicks",
    VaginalIrritation = "vaginalIrritation",
    AlteredVaginalDischarge = "alteredVaginalDischarge",
    LegCramps = "legCramps",
    JointPain = "jointPain",
    Swelling = "swelling",
    VividDreams = "vividDreams",
    IncreasedSexualDesire = "increasedSexualDesire",
    DecreasedSexualDesire = "decreasedSexualDesire",
    Insomnia = "insomnia",
    RestlessSleep = "restlessSleep",
}

export enum PregnancyStatus {
    NotBorn = "notBorn",
    Born = "born",
    Miscarriage = "miscarriage",
}

export type PrenatalTestingMethod = "ultrasound" | "genetic";

export const PRENATAL_TESTING_METHOD_FOR_HUMANS = {
    ultrasound: "Ultrahang vizsgálat",
    genetic: "Genetikai vizsgálat",
};

export const WELL_BEING_STATUS_FOR_HUMANS = {
    [PregnancyWellBeingStatus.BadTemper]: "badMood", //"Rosszkedvű",
    [PregnancyWellBeingStatus.Moody]: "unpleasant", //"Kedvetlen",
    [PregnancyWellBeingStatus.Fluctuating]: "changing", //"Hullámzó",
    [PregnancyWellBeingStatus.Calm]: "calm", //"Nyugodt",
    [PregnancyWellBeingStatus.Cheerful]: "cheerful", //"Derűs",
};

export const PREGNANCY_SYMPTOMS_FOR_HUMANS: Record<PregnancySymptom, string> = {
    [PregnancySymptom.NoSymptom]: "noSymptom",
    [PregnancySymptom.FatigueExhaustion]: "fatigueExhaustion",
    [PregnancySymptom.MorningNausea]: "morningNausea",
    [PregnancySymptom.ShortnessOfBreath]: "shortnessOfBreath",
    [PregnancySymptom.ColdFluSymptoms]: "coldFluSymptoms",
    [PregnancySymptom.PullingSensation]: "pullingSensation",
    [PregnancySymptom.Headache]: "headache",
    [PregnancySymptom.Acne]: "acne",
    [PregnancySymptom.Dizziness]: "dizziness",
    [PregnancySymptom.VisionChanges]: "visionChanges",
    [PregnancySymptom.NeckPain]: "neckPain",
    [PregnancySymptom.GumNoseBleeding]: "gumNoseBleeding",
    [PregnancySymptom.SensitiveBreasts]: "sensitiveBreasts",
    [PregnancySymptom.BackPain]: "backPain",
    [PregnancySymptom.ChestPain]: "chestPain",
    [PregnancySymptom.Heartburn]: "heartburn",
    [PregnancySymptom.SoreNipples]: "soreNipples",
    [PregnancySymptom.SwollenBreasts]: "swollenBreasts",
    [PregnancySymptom.Bloating]: "bloating",
    [PregnancySymptom.AbdominalPain]: "abdominalPain",
    [PregnancySymptom.StomachPain]: "stomachPain",
    [PregnancySymptom.Constipation]: "constipation",
    [PregnancySymptom.IncreasedAppetite]: "increasedAppetite",
    [PregnancySymptom.DecreasedAppetite]: "decreasedAppetite",
    [PregnancySymptom.Cravings]: "cravings",
    [PregnancySymptom.Aversion]: "aversion",
    [PregnancySymptom.Diarrhea]: "diarrhea",
    [PregnancySymptom.Vomiting]: "vomiting",
    [PregnancySymptom.AbdominalItching]: "abdominalItching",
    [PregnancySymptom.BowelMovement]: "bowelMovement",
    [PregnancySymptom.NoBowelMovement]: "noBowelMovement",
    [PregnancySymptom.IncreasedThirst]: "increasedThirst",
    [PregnancySymptom.FrequentUrination]: "frequentUrination",
    [PregnancySymptom.RareUrination]: "rareUrination",
    [PregnancySymptom.VaginalBleeding]: "vaginalBleeding",
    [PregnancySymptom.HeavyVaginalBleeding]: "heavyVaginalBleeding",
    [PregnancySymptom.PelvicDiscomfortPressure]: "pelvicDiscomfortPressure",
    [PregnancySymptom.ContractionsBraxtonHicks]: "contractionsBraxtonHicks",
    [PregnancySymptom.VaginalIrritation]: "vaginalIrritation",
    [PregnancySymptom.AlteredVaginalDischarge]: "alteredVaginalDischarge",
    [PregnancySymptom.LegCramps]: "legCramps",
    [PregnancySymptom.JointPain]: "jointPain",
    [PregnancySymptom.Swelling]: "swelling",
    [PregnancySymptom.VividDreams]: "vividDreams",
    [PregnancySymptom.IncreasedSexualDesire]: "increasedSexualDesire",
    [PregnancySymptom.DecreasedSexualDesire]: "decreasedSexualDesire",
    [PregnancySymptom.Insomnia]: "insomnia",
    [PregnancySymptom.RestlessSleep]: "restlessSleep",
};

export type PregnancyData = {
    expectedAt: DateTimeString;
    infants: InfantType[];
};

export type InfantType = {
    readonly id?: Ulid;
    readonly gender?: GenderBaby;
    readonly doctorGender?: GenderBaby;
    readonly givenName?: string;
};

export type BornInfant = {
    readonly id?: Ulid;
    readonly givenName: string;
    readonly length: number;
    readonly weight: number;
    readonly headCircumference: number;
    readonly bornAt?: string | undefined;
};

export type GestationalAge = {
    readonly week: number;
    readonly days: number;
    readonly trimester: number;
};

export type TimeRemaining = {
    readonly weeks: number;
    readonly days: number;
};
