const baseStyle = () => ({
    _disabled: {
        opacity: 0.4,
    },
    _invalid: {
        borderWidth: 2,
        borderRadius: 12,
    },
    onThumbColor: "green.50",
    offThumbColor: "green.50",
    _light: {
        offTrackColor: "muted.300",
        onTrackColor: `green.600`,
        _hover: {
            offTrackColor: "muted.400",
            onTrackColor: `green.700`,
        },
        _invalid: {
            borderColor: "error.600",
        },
    },
    _dark: {
        offTrackColor: "muted.700",
        onTrackColor: `muted.500`,
        _hover: {
            offTrackColor: "muted.600",
            onTrackColor: `muted.400`,
        },
        _invalid: {
            borderColor: "error.500",
        },
    },
});

const sizes = {
    sm: {
        style: {
            transform: [{ scale: 0.75 }],
        },
    },
    md: {},
    lg: {
        style: {
            transform: [{ scale: 1.25 }],
        },
        margin: 1,
    },
};

const defaultProps = {
    size: "md",
    colorScheme: "primary",
};

export default {
    baseStyle,
    sizes,
    defaultProps,
};
