import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const BabyWeek06 = () => {
    const { t } = useTranslation();

    return (
        <VStack>
            <Text>{t("pregnancy:babyWeek6")}</Text>
        </VStack>
    );
};

export default BabyWeek06;
