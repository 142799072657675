import borderWidths from "./borders";
import breakpoints from "./breakpoints";
import colors from "./colors";
import opacity from "./opacity";
import radii from "./radius";
import shadows from "./shadows";
import sizes from "./sizes";
import space from "./space";
import typography from "./typography";

const base = {
    borderWidths,
    breakpoints,
    colors,
    opacity,
    radii,
    shadows,
    sizes,
    space,
    ...typography,
};

export default base;
