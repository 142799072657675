import { Box, VStack } from "native-base";
import { isWeb } from "@madmedical/utils";
import FormControl from "../../form/FormControl";
import SubmitButton from "../../form/SubmitButton";
import FormError from "../../form/FormError";

interface Props {
    onSubmit: () => void;
}

const CallCenterLogin = ({ onSubmit }: Props) => (
    <Box
        alignItems="center"
        justifyContent="center"
        height={isWeb ? "100vh" : undefined}
        width={isWeb ? "100vw" : undefined}
        bgColor="gray.50"
        overflowX="hidden"
        overflowY="auto"
    >
        <VStack
            bgColor="white"
            borderRadius="xl"
            p="6"
            shadow="2"
            w="xs"
            space={4}
        >
            <FormControl size="sm" label="E-mail" name="email" type="email" />
            <FormControl
                size="sm"
                label="Jelszó"
                name="password"
                secureTextEntry
            />
            <FormError />
            <SubmitButton onPress={onSubmit} label="Bejelentkezés" />
        </VStack>
    </Box>
);

export default CallCenterLogin;
