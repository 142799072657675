import { InsulinAdministrationModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { useUpdateInsulinAdministrationMutation } from "../api";
import type { InsulinAdministration } from "../model";

interface Props {
    open: boolean;
    onClose: () => void;
    administrationData: InsulinAdministration | undefined;
}

const EditInsulinAdministrationModalForm = ({
    open,
    onClose,
    administrationData,
}: Props) => {
    const [updateAdministration] = useUpdateInsulinAdministrationMutation();

    return (
        <Modal open={open} onClose={onClose} size="xs">
            <ApiFormWrapper
                defaultValues={{
                    ...administrationData,
                }}
                mutation={updateAdministration}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <InsulinAdministrationModalForm
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                        isEdit
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditInsulinAdministrationModalForm;
