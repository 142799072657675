// import GraphDummy from "../../pics/GraphDummy";

import { Box, Text } from "native-base";
import Svg, { Circle, ClipPath, Defs, G, Path } from "react-native-svg";
import { useTranslation } from "@madmedical/i18n";

interface Props {
    weekNr: number;
    trimesterNr: number;
    percentage: number;
}

const PregnancyPieGraph = ({ weekNr, trimesterNr, percentage }: Props) => {
    // pie graph calculations
    const circumference = 204.1;
    const strokeDash = Math.round((percentage * circumference) / 100);
    const strokeDashArray = `${strokeDash} ${circumference}`;
    const { t } = useTranslation();

    return (
        <Box position="relative">
            <Svg height="130" width="130" viewBox="0 0 130 130">
                <Defs>
                    <ClipPath id="clip-path">
                        <Path d="M64.78,6.22a59,59,0,0,1,53,85l-7.2-3.53A51,51,0,0,0,64.78,14.24Z" />
                        <Path
                            d="M116.1,93.44A58.56,58.56,0,0,1,15.5,96.85l6.7-4.3a50.61,50.61,0,0,0,86.92-2.94Z"
                            fill="#72bf44"
                        />
                        <Path
                            d="M14.06,94.5A58.56,58.56,0,0,1,61.63,6.74l.43,8A50.59,50.59,0,0,0,21,90.52Z"
                            clipPath="url(#clip-path-2)"
                            fill="#72bf44"
                        />
                    </ClipPath>
                </Defs>

                <Circle r="65" cx="65" cy="65" fill="white" />
                <G clipPath="url(#clip-path)">
                    <Circle r="65" cx="65" cy="65" fill="#1FBA9C3D" />
                    <Circle
                        r="32.5"
                        cx="65"
                        cy="65"
                        fill="transparent"
                        stroke="#1FBA9C"
                        strokeWidth="65"
                        strokeDasharray={strokeDashArray}
                        transform="rotate(-90) translate(-130)"
                    />
                </G>
            </Svg>
            <Box
                position={"absolute"}
                top={0}
                left={0}
                height={130}
                width="100%"
                alignItems="center"
                justifyContent="center"
            >
                <Text fontWeight="medium" fontSize="md">
                    {t("pregnancy:weekNumber", { weekNr: weekNr })}
                </Text>
                <Text opacity={0.44} fontSize="xs">
                    {t("pregnancy:trimesterNumber", {
                        trimesterNr: trimesterNr,
                    })}
                </Text>
            </Box>
        </Box>
    );
};

export default PregnancyPieGraph;
