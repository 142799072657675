import type { Ulid } from "@madmedical/utils";
import type { ReactNode, Provider as ReactProvider } from "react";
import { createContext, useContext } from "react";

interface ContextValue {
    patientId: Ulid;
}

// Used with an empty value in UserWrapper by default for non-detached contexts
const DetachedPatientIdContext = createContext<ContextValue | undefined>(
    undefined
);

interface EmptyProviderProps {
    children: ReactNode;
}

export const NonDetachedPatientIdProvider = ({
    children,
}: EmptyProviderProps) => (
    <DetachedPatientIdContext.Provider value={undefined}>
        {children}
    </DetachedPatientIdContext.Provider>
);

export const DetachedPatientIdProvider =
    DetachedPatientIdContext.Provider as ReactProvider<ContextValue>;

export const useDetachedPatientId = () =>
    useContext(DetachedPatientIdContext) ?? { patientId: undefined };
