import { downloadFile, previewFile } from "@madmedical/file";
import { useCallback, useState } from "react";
import type { Document } from "../providers/document";
import useShowToast from "../atoms/useShowToast";

export default () => {
    const showToast = useShowToast();
    const [isDownloading, setDownloading] = useState(false);

    const preview = useCallback(
        async ({ previewUrl, filename, mimeType }: Document) => {
            setDownloading(true);

            try {
                await previewFile(previewUrl, filename, mimeType);
            } catch {
                showToast("Nem támogatott fájl típus.", { variant: "error" });
            } finally {
                setDownloading(false);
            }
        },
        [showToast]
    );

    const download = useCallback(
        async ({ downloadUrl, filename, mimeType }: Document) => {
            setDownloading(true);

            try {
                await downloadFile(downloadUrl, filename, mimeType);
            } catch {
                showToast("Sikertelen letöltés.", { variant: "error" });
            } finally {
                setDownloading(false);
                showToast("A letöltés befejeződött.", { variant: "success" });
            }
        },
        [showToast]
    );

    return { preview, download, isDownloading };
};
