import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { hasActiveFilter } from "../utils";
import FilterButton from "../../../molecules/FilterButton";
import Button from "../../../atoms/Button";
import { usePatients } from "../../../providers/patient";

const CallCenterPatientsDesktopFilter = () => {
    const { filterChoices, onFilterChange } = usePatients();
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof typeof filterChoices>(key: TFilterKey) =>
        (selected: (typeof filterChoices)[TFilterKey][0]["key"][]) => {
            onFilterChange?.(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange?.("deviceTypes", []);
        onFilterChange?.("services", []);
    };

    if (!filterChoices) {
        return null;
    }

    return (
        <HStack space={2}>
            <FilterButton
                title={t("package")}
                items={filterChoices.services}
                onChange={handleFilterChange("services")}
                isMultiselect
            />
            <FilterButton
                title={t("device")}
                items={filterChoices.deviceTypes}
                onChange={handleFilterChange("deviceTypes")}
                isMultiselect
            />

            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default CallCenterPatientsDesktopFilter;
