import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import { Radio, Text } from "native-base";
import FormControlWrapper from "../molecules/Form/FormControlWrapper";

interface Choice {
    value: string;
    label: string;
}

type Props = Omit<ComponentProps<typeof FormControlWrapper>, "children"> &
    Omit<ComponentProps<typeof Radio.Group>, "children"> & {
        name: string;
        choices: Choice[];
    };

const FormRadioControl = ({
    name,
    choices,
    isRequired,
    hint,
    label,
    labelProps,
    ...rest
}: Props) => {
    const { control, isError, error } = useField(name);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => (
                <FormControlWrapper
                    isInvalid={isError}
                    isRequired={isRequired}
                    error={error}
                    hint={hint}
                    label={label}
                    labelProps={labelProps}
                >
                    <Radio.Group
                        name={name}
                        value={value as string | undefined}
                        onChange={onChange}
                        isInvalid={isError}
                        {...rest}
                    >
                        {choices.map(({ value, label }) => (
                            <Radio key={value} value={value} borderWidth={1}>
                                <Text>{label}</Text>
                            </Radio>
                        ))}
                    </Radio.Group>
                </FormControlWrapper>
            )}
        />
    );
};

export default FormRadioControl;
