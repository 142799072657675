import { Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";

const Week01 = () => {
    const { t } = useTranslation();

    return (
        <VStack>
            <Text>{t("pregnancy:week1")}</Text>
        </VStack>
    );
};

export default Week01;
