const radii = {
    none: 0,
    "2xs": 1,
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 10,
    "2xl": 12,
    "3xl": 16,
    full: 9999,
};

export default radii;
