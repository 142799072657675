import type { Provider as ReactProvider } from "react";
import { createContext, useMemo } from "react";
import type { AppRoutes, RouteId, RouteNavigate } from "@madmedical/routing";
import { useEnsureContext } from "@madmedical/utils";
import { useLinkHandler } from "@madmedical/routing";

interface ContextValue {
    navigate: RouteNavigate;
    isActive: <TRouteId extends RouteId>(
        route: TRouteId,
        params: AppRoutes[TRouteId]
    ) => boolean;
    generatePath: <TRouteId extends RouteId>(
        route: TRouteId,
        params: AppRoutes[TRouteId],
        search?: Record<string, string>
    ) => string;
}

const RoutingContext = createContext<ContextValue | undefined>(undefined);

export const RoutingProvider =
    RoutingContext.Provider as ReactProvider<ContextValue>;

export const useRouteNavigate = () => {
    const { navigate } = useEnsureContext(RoutingContext);

    return navigate;
};

export const useLinking = <TRouteId extends RouteId>(
    route: TRouteId,
    params: AppRoutes[TRouteId],
    search?: Record<string, string>
) => {
    const { generatePath, isActive } = useEnsureContext(RoutingContext);
    const path = generatePath(route, params, search);
    const handlePress = useLinkHandler(path);

    return useMemo(
        () =>
            ({
                onPress: handlePress,
                accessibilityRole: "link",
                href: path,
                isActive: isActive(route, params),
            } as const),
        [handlePress, path, isActive, route, params]
    );
};
