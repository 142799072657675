import type { ProfileWithId } from "@madmedical/medical";
import { Box, Pressable, Text, useTheme } from "native-base";
import { Path, Svg } from "react-native-svg";
import { useTranslation } from "@madmedical/i18n";
import Avatar from "../../atoms/Avatar/Avatar";

interface Props {
    profile: ProfileWithId;
    userId: string;
    onPress?: () => void;
    isTrigger?: boolean;
    propStyles?: { [key: string]: string | number };
}

const ProfileItem = ({
    profile,
    userId,
    onPress,
    isTrigger,
    propStyles,
}: Props) => {
    const { colors } = useTheme();
    const { t } = useTranslation();
    const styles = {
        slot: {
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
        },

        svg: {
            flexShrink: 0,
        },

        text: {
            flex: 1,
            marginRight: 8,
        },

        name: {
            color: colors.black,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },

        trustee: {
            fontSize: 12,
            color: "#4E4E4E", // not available in Theme colors
        },
    };

    return (
        <Pressable
            onPress={onPress}
            style={{ ...styles.slot, ...propStyles }}
            flexDirection="row"
            alignItems="center"
        >
            <Avatar
                source={profile?.avatarUrl}
                initials={profile?.initials}
                size="lg"
            />

            <Box style={styles.text}>
                <Text style={styles.name} fontWeight="500" overflow="hidden">
                    {profile?.fullName}
                </Text>
                <Text style={styles.trustee}>
                    {profile?.userId === userId
                        ? t("ownAccount")
                        : t("authorizedPersonsAccount")}
                </Text>
            </Box>

            {isTrigger && (
                <Svg
                    style={styles.svg}
                    width="32"
                    height="38"
                    viewBox="0 0 32 38"
                    fill="none"
                >
                    <Path
                        d="M16 22.025L11.1875 17.2125L12.15 16.25L16 20.1L19.85 16.25L20.8125 17.2125L16 22.025Z"
                        fill={colors.gray[800]}
                        stroke={colors.gray[800]}
                        strokeWidth="0.3"
                    />
                </Svg>
            )}
        </Pressable>
    );
};

export default ProfileItem;
