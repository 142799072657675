import { HStack, VStack } from "native-base";
import type { Institution } from "@madmedical/medical";
import {
    DeviceOwnership,
    DeviceOperation as Operation,
    logisticsForHumans,
} from "@madmedical/medical";
import { useEffect } from "react";
import { useWatchForm } from "@madmedical/form";
import { formatDate } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import FormDateInput from "../../form/FormDateInput";
import type { Patient } from "../../providers/patient";
import SubmitButton from "../../form/SubmitButton";
import FormControl from "../../form/FormControl";
import Button from "../../atoms/Button";
import ModalContent from "../../molecules/ModalContent";
import FormSelect from "../../form/FormSelect";
import type { LogisticsDeviceType } from "../../providers/callCenterDevice";
import FormTextAreaControl from "../../form/FormTextAreaControl";
import FormError from "../../form/FormError";

interface Props {
    readonly operations: Operation[];
    readonly patients?: Patient[];
    readonly institutions?: Institution[];
    readonly deviceTypes?: LogisticsDeviceType[];
    readonly onClose: () => void;
    readonly onSubmitPress: () => void;
    readonly isSuccess: boolean;
    readonly isSubmitting: boolean;
}

const CallCenterDeviceModalForm = ({
    operations,
    patients,
    institutions,
    deviceTypes,
    onClose,
    onSubmitPress,
    isSuccess,
    isSubmitting,
}: Props) => {
    const operation = useWatchForm<{ operation: Operation }>({
        name: "operation",
    });
    const { t } = useTranslation();
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    return (
        <ModalContent
            header={t("editDevice")}
            footer={
                <HStack space={2}>
                    <Button variant="outlined" size="md" onPress={onClose}>
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmitPress}
                        isLoading={isSubmitting}
                        label={t("save")}
                    />
                </HStack>
            }
        >
            <VStack space="6">
                <FormError />
                {operation !== Operation.Create && (
                    <FormSelect
                        name="operation"
                        label={t("operation")}
                        isRequired
                        choices={operations.map((operation) => ({
                            value: operation,
                            label: logisticsForHumans(operation),
                        }))}
                    />
                )}
                <HStack space="3">
                    {operation === Operation.Create && deviceTypes && (
                        <FormSelect
                            name="deviceTypeId"
                            label={t("type")}
                            choices={deviceTypes.map(
                                ({ id, name, manufacturer }) => ({
                                    value: id,
                                    label: `${manufacturer.forHumans} ${name}`,
                                })
                            )}
                            isRequired
                        />
                    )}
                    <FormControl
                        name="serialNumber"
                        label={t("serialNumber")}
                        isRequired
                        flex="1"
                        type="text"
                    />
                </HStack>
                <HStack space="3">
                    <FormDateInput
                        name="warrantyExpiresAt"
                        label={t("endOfGuarantee")}
                    />
                </HStack>
                {operation === Operation.Ordered && (
                    <HStack space="3">
                        {patients && (
                            <FormSelect
                                name="patientId"
                                label={t("patient")}
                                isRequired
                                choices={patients.map(
                                    ({ userId, fullName, birthDate }) => ({
                                        value: userId,
                                        label: `${fullName} (${
                                            birthDate
                                                ? formatDate(birthDate)
                                                : "–"
                                        })`,
                                    })
                                )}
                            />
                        )}
                        <FormSelect
                            name="ownership"
                            label={t("settlement")}
                            isRequired
                            choices={Object.values(DeviceOwnership).map(
                                (ownership) => ({
                                    value: ownership,
                                    label: logisticsForHumans(ownership),
                                })
                            )}
                        />
                    </HStack>
                )}
                {[Operation.Create, Operation.ToStorage].includes(operation) &&
                    institutions && (
                        <FormSelect
                            name="institutionId"
                            label={t("institution")}
                            choices={[
                                { value: "", label: t("ownWarehouse") },
                                ...institutions.map(({ id, name }) => ({
                                    value: id,
                                    label: name,
                                })),
                            ]}
                        />
                    )}
                <FormTextAreaControl name="note" label={t("comment")} />
            </VStack>
        </ModalContent>
    );
};

export default CallCenterDeviceModalForm;
