import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const BabyLength = () => (
    <Svg width="146" height="130" viewBox="0 0 146 130" fill="none">
        <Circle cx="71.9991" cy="65" r="65" fill="white" fillOpacity="0.83" />
        <G clipPath="url(#clip0_231_72781)">
            <Path
                d="M100.542 59.3262H32.5V79.8211H100.542V59.3262Z"
                fill="#EDAF7C"
            />
            <Path
                d="M94.7375 72.4519V77.6168H92.0815V72.4519C92.0815 71.7189 92.6764 71.124 93.4094 71.124C94.1425 71.124 94.7375 71.7189 94.7375 72.4519Z"
                fill="#757575"
            />
            <Path
                d="M85.2646 74.1785V77.617H82.6086V74.1785C82.6086 73.4454 83.2035 72.8506 83.9366 72.8506C84.6698 72.8504 85.2646 73.4454 85.2646 74.1785Z"
                fill="#757575"
            />
            <Path
                d="M75.9687 75.241V77.6172H73.3127V75.241C73.3127 75.1331 73.3251 75.0267 73.3518 74.9277C73.4917 74.3452 74.0157 73.9131 74.6408 73.9131C75.3137 73.9131 75.8696 74.4141 75.9564 75.064C75.9653 75.1222 75.9687 75.1807 75.9687 75.241Z"
                fill="#757575"
            />
            <Path
                d="M49.2377 69.7533C49.2217 69.7817 49.2076 69.8241 49.204 69.8578C49.2128 69.8631 49.2252 69.8685 49.2394 69.8738C57.7543 73.2716 68.6543 75.1414 79.9315 75.1414C87.1733 75.1414 94.1938 74.3836 100.542 72.9493V79.8211H32.5V59.3262H100.542V63.6784C98.6557 65.4296 95.52 66.9275 91.1537 68.1599C84.5475 70.0244 75.7989 71.0513 66.5208 71.0513C60.4706 71.0513 54.5124 70.6051 49.2925 69.7605C49.2678 69.755 49.2501 69.755 49.2377 69.7533Z"
                fill="#EDAF7C"
            />
            <Path
                d="M75.9564 75.064C75.9653 75.1225 75.9687 75.1809 75.9687 75.2411V77.6172H73.3127V75.2411C73.3127 75.1331 73.3251 75.0267 73.3518 74.9277C74.2159 74.9843 75.0836 75.0303 75.9564 75.064Z"
                fill="#757575"
            />
            <Path
                d="M82.6086 75.1067C83.4993 75.0837 84.3846 75.05 85.2646 75.0039V77.6173H82.6086V75.1067Z"
                fill="#757575"
            />
            <Path
                d="M94.7375 74.0439V77.6171H92.0815V74.4104C92.9756 74.3006 93.861 74.1785 94.7375 74.0439Z"
                fill="#757575"
            />
            <Path
                d="M120.5 75.944V87.0246C120.5 88.8962 119.503 90.6225 117.888 91.5699C114.019 93.8398 109.234 95.7574 103.792 97.1952C100.43 98.0858 96.8179 98.7941 93.0146 99.2898C92.1399 99.405 91.2546 99.5076 90.3586 99.5997C86.9997 99.9433 83.5116 100.124 79.9315 100.124H79.7881C78.8975 100.122 78.0122 100.11 77.1322 100.085C73.4863 99.9892 69.9469 99.704 66.5615 99.2508C65.6638 99.1322 64.7785 99.0011 63.9055 98.8577C60.1483 98.2433 56.6034 97.42 53.3349 96.4161C52.4284 96.1381 51.5412 95.846 50.6789 95.5397C46.6029 94.1002 43.0316 92.365 40.1082 90.403C39.1521 89.7602 38.265 89.0945 37.4522 88.4058C35.4727 86.729 33.9358 84.923 32.9142 83.0213C32.6399 82.5097 32.5 81.9377 32.5 81.3569V59.3268C32.5 57.0178 35.3897 54.8985 40.2057 53.2412C46.4454 51.0952 55.9164 49.7266 66.5208 49.7266C77.1268 49.7266 86.5997 51.0952 92.8376 53.243C97.6537 54.8985 100.542 57.0178 100.542 59.3268C100.542 64.6298 85.3107 68.9271 66.5208 68.9271C60.3749 68.9271 54.6098 68.4685 49.6325 67.6629C47.0563 67.2468 46.0293 70.882 48.4533 71.8486C56.9222 75.227 67.916 77.2668 79.9315 77.2668C88.6076 77.2668 96.7488 76.2027 103.792 74.3399C107.176 73.4458 110.306 72.3658 113.12 71.1315C116.599 69.6053 120.5 72.1461 120.5 75.944Z"
                fill="#F6DCB6"
            />
            <Path
                d="M92.8375 53.242V57.0559C92.8375 59.8659 89.9177 62.0437 84.1579 63.5311C79.4196 64.7547 73.157 65.4293 66.5207 65.4293C59.8845 65.4293 53.6217 64.7547 48.8836 63.5311C43.1255 62.0437 40.2058 59.8659 40.2058 57.0559V53.2403C46.4456 51.0942 55.9166 49.7256 66.5209 49.7256C77.1268 49.7254 86.5997 51.0942 92.8375 53.242Z"
                fill="#EDAF7C"
            />
            <Path
                d="M120.5 75.9444V87.025C120.5 88.8965 119.503 90.6228 117.888 91.5702C114.019 93.8401 109.234 95.7578 103.792 97.1955V74.3402C107.176 73.4461 110.306 72.3661 113.12 71.1319C116.599 69.6056 120.5 72.1464 120.5 75.9444Z"
                fill="#EDAF7C"
            />
            <Path
                d="M120.5 75.9434V87.0237C120.5 88.8992 119.498 90.6255 117.88 91.5742C114.524 93.542 110.48 95.2436 105.917 96.5999V73.7446C108.5 72.977 110.916 72.0986 113.135 71.124C116.608 69.5995 120.5 72.1513 120.5 75.9434Z"
                fill="#A7A7A7"
            />
            <Path
                d="M113.209 86.9248C114.516 86.9248 115.576 84.9707 115.576 82.5601C115.576 80.1495 114.516 78.1953 113.209 78.1953C111.901 78.1953 110.841 80.1495 110.841 82.5601C110.841 84.9707 111.901 86.9248 113.209 86.9248Z"
                fill="#757575"
            />
            <Path
                d="M48.4534 71.7176C48.2917 71.7176 48.1275 71.6879 47.968 71.6252C47.6829 71.5133 40.9495 68.8257 37.6789 63.9781C37.2686 63.3702 37.4288 62.5447 38.0367 62.1346C38.6445 61.7243 39.4702 61.8845 39.8803 62.4926C42.6944 66.6633 48.8772 69.1289 48.9393 69.1534C49.622 69.4217 49.9578 70.1926 49.6895 70.8751C49.4841 71.3981 48.9833 71.7176 48.4534 71.7176Z"
                fill="#FACD8A"
            />
            <Path
                d="M90.7129 44.124V57.0566C90.7129 58.4625 88.9175 59.7604 85.8844 60.8014C85.0823 61.0793 84.1935 61.3378 83.2285 61.5752C81.3657 62.0355 79.2215 62.4181 76.8667 62.7066C76.008 62.8111 75.1227 62.9031 74.2107 62.9828C72.1956 63.1564 70.0622 63.2644 67.8489 63.2944C67.4098 63.3015 66.9672 63.3051 66.521 63.3051C66.0748 63.3051 65.6321 63.3015 65.1931 63.2944C62.9799 63.2644 60.8462 63.1563 58.8313 62.9828C57.9212 62.9031 57.0342 62.8111 56.1753 62.7066C53.8205 62.4181 51.6762 62.0337 49.8136 61.5735C48.8487 61.338 47.9597 61.0795 47.1576 60.8014C44.1262 59.7585 42.3308 58.4624 42.3308 57.0566V44.124H90.7129Z"
                fill="#F6DCB6"
            />
            <Path
                d="M66.5211 50.3734C79.8815 50.3734 90.7122 47.5757 90.7122 44.1247C90.7122 40.6736 79.8815 37.876 66.5211 37.876C53.1608 37.876 42.3301 40.6736 42.3301 44.1247C42.3301 47.5757 53.1608 50.3734 66.5211 50.3734Z"
                fill="#F6DCB6"
            />
            <Path
                d="M66.5212 46.7765C75.2871 46.7765 82.3933 45.7287 82.3933 44.4361C82.3933 43.1435 75.2871 42.0957 66.5212 42.0957C57.7552 42.0957 50.649 43.1435 50.649 44.4361C50.649 45.7287 57.7552 46.7765 66.5212 46.7765Z"
                fill="#EDAF7C"
            />
            <Path
                d="M40.1084 77.4381V90.4026C39.1522 89.7598 38.2652 89.0941 37.4524 88.4054V77.4383C37.4524 76.7052 38.0455 76.1104 38.7803 76.1104C39.5133 76.1102 40.1084 76.705 40.1084 77.4381Z"
                fill="#A7A7A7"
            />
            <Path
                d="M53.3348 83.6354V96.4158C52.4283 96.1379 51.5411 95.8457 50.6788 95.5394V83.6355C50.6788 82.9025 51.272 82.3076 52.0067 82.3076C52.74 82.3073 53.3348 82.9023 53.3348 83.6354Z"
                fill="#A7A7A7"
            />
            <Path
                d="M66.5615 86.6453V99.2504C65.6638 99.1318 64.7785 99.0007 63.9055 98.8573V86.6453C63.9055 85.9122 64.5004 85.3174 65.2334 85.3174C65.9665 85.3174 66.5615 85.9122 66.5615 86.6453Z"
                fill="#A7A7A7"
            />
            <Path
                d="M79.7882 87.8758V100.123C78.8975 100.122 78.0122 100.109 77.1322 100.084V87.8758C77.1322 87.1427 77.7271 86.5479 78.4601 86.5479C79.1932 86.5479 79.7882 87.1427 79.7882 87.8758Z"
                fill="#A7A7A7"
            />
            <Path
                d="M93.0146 87.3455V99.2901C92.14 99.4053 91.2546 99.5079 90.3586 99.6V87.3455C90.3586 86.6124 90.9535 86.0176 91.6866 86.0176C92.4198 86.0176 93.0146 86.6124 93.0146 87.3455Z"
                fill="#A7A7A7"
            />
            <Path
                d="M49.8131 54.7851V61.5737C48.8482 61.3382 47.9592 61.0797 47.1571 60.8016V54.7849C47.1571 54.0519 47.752 53.457 48.485 53.457C49.2199 53.4572 49.8131 54.0521 49.8131 54.7851Z"
                fill="#A7A7A7"
            />
            <Path
                d="M58.8309 56.6492V62.9828C57.9208 62.903 57.0338 62.8111 56.1749 62.7066V56.6492C56.1749 55.9144 56.77 55.3213 57.5028 55.3213C58.2361 55.3211 58.8309 55.9143 58.8309 56.6492Z"
                fill="#A7A7A7"
            />
            <Path
                d="M67.8486 57.3787V63.2943C67.4094 63.3013 66.9668 63.3049 66.5207 63.3049C66.0745 63.3049 65.6317 63.3013 65.1927 63.2943V57.3787C65.1927 56.6456 65.7876 56.0508 66.5207 56.0508C67.2537 56.0508 67.8486 56.6456 67.8486 57.3787Z"
                fill="#A7A7A7"
            />
            <Path
                d="M76.8666 57.5457V62.7071C76.0079 62.8116 75.1225 62.9035 74.2106 62.9833V57.5457C74.2106 56.8126 74.8054 56.2178 75.5385 56.2178C76.2715 56.2178 76.8666 56.8126 76.8666 57.5457Z"
                fill="#A7A7A7"
            />
            <Path
                d="M85.8844 56.5789V60.8019C85.0822 61.0798 84.1935 61.3383 83.2284 61.5756V56.5789C83.2284 55.8441 83.8232 55.251 84.5563 55.251C85.2893 55.2508 85.8844 55.8439 85.8844 56.5789Z"
                fill="#A7A7A7"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_231_72781">
                <Rect
                    width="88"
                    height="88"
                    fill="white"
                    transform="translate(32.5 25)"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default BabyLength;
