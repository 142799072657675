import type {
    BloodOxygen,
    BloodPressure,
    BloodSugar,
    BodyMassIndex,
    BodyTemperature,
    BodyWeight,
    DailySteps,
    HeartRateVariability,
    Metric,
    RestingHeartRate,
    SleepTime,
} from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { memo } from "react";
import type { CommonProps } from "./model";
import BloodOxygenChart from "./metric/BloodOxygenChart";
import BloodPressureChart from "./metric/BloodPressureChart";
import BloodSugarChart from "./metric/BloodSugarChart";
import BodyMassIndexChart from "./metric/BodyMassIndexChart";
import BodyTemperatureChart from "./metric/BodyTemperatureChart";
import BodyWeightChart from "./metric/BodyWeightChart";
import DailyStepsChart from "./metric/DailyStepsChart";
import HeartRateVariabilityChart from "./metric/HeartRateVariabilityChart";
import RestingHeartRateChart from "./metric/RestingHeartRateChart";
import SleepTimeChart from "./metric/SleepTimeChart";

interface Props<TMetric extends Metric> extends CommonProps<TMetric> {
    metricType: TMetric["type"];
}

const Chart = <TMetric extends Metric>({
    metricType,
    ...props
}: Props<TMetric>) => {
    // I failed to get generics to work again without type assertion. I am ashamed.
    switch (metricType) {
        case MetricType.BloodOxygen:
            return (
                <BloodOxygenChart {...(props as CommonProps<BloodOxygen>)} />
            );
        case MetricType.BloodPressure:
            return (
                <BloodPressureChart
                    {...(props as CommonProps<BloodPressure>)}
                />
            );
        case MetricType.BloodSugar:
            return <BloodSugarChart {...(props as CommonProps<BloodSugar>)} />;
        case MetricType.BodyMassIndex:
            return (
                <BodyMassIndexChart
                    {...(props as CommonProps<BodyMassIndex>)}
                />
            );
        case MetricType.BodyTemperature:
            return (
                <BodyTemperatureChart
                    {...(props as CommonProps<BodyTemperature>)}
                />
            );
        case MetricType.BodyWeight:
            return <BodyWeightChart {...(props as CommonProps<BodyWeight>)} />;
        case MetricType.DailySteps:
            return <DailyStepsChart {...(props as CommonProps<DailySteps>)} />;
        case MetricType.HeartRateVariability:
            return (
                <HeartRateVariabilityChart
                    {...(props as CommonProps<HeartRateVariability>)}
                />
            );
        case MetricType.RestingHeartRate:
            return (
                <RestingHeartRateChart
                    {...(props as CommonProps<RestingHeartRate>)}
                />
            );
        case MetricType.SleepTime:
            return <SleepTimeChart {...(props as CommonProps<SleepTime>)} />;
        default:
            return null;
    }
};

export default memo(Chart);
