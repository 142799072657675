import { Box, HStack, Text, useTheme } from "native-base";
import { formatBytes } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";
import Icon from "../icons/Icon";
import DocumentListCard from "../molecules/DocumentListCard";
import useResponsive from "../util/useResponsive";
import Div from "../atoms/Div";
import type { DocumentService } from "../form/upload/model";

interface SelectedFile {
    name: string;
    size: number;
    note: string | null;
}

interface Props {
    files: SelectedFile[];
    dropzoneProps?: Record<string, unknown>;
    inputProps?: unknown;
    isDragActive: boolean;
    isUploading: boolean;
    onSelectPress: () => void;
    onDeletePress: (index: number) => void;
    onChangeNote: (index: number, note: string) => void;
    onChangeService: (index: number, service: DocumentService) => void;
}

const DocumentUploadFormInner = ({
    files,
    dropzoneProps,
    inputProps,
    isDragActive,
    isUploading,
    onSelectPress,
    onDeletePress,
    onChangeNote,
    onChangeService,
}: Props) => {
    const { colors } = useTheme();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const content = {
        desktop: {
            title: t("dropFilesHere"),
            desc: t("orClickButtonToUpload"),
        },
        mobile: {
            title: t("uploadDocuments"),
            desc: t("attachGeneralDocument"),
        },
    };

    const handleDeletePress = (index: number) => () => {
        onDeletePress(index);
    };

    const handleNoteChange = (index: number) => (note: string) => {
        onChangeNote(index, note);
    };

    const handleServiceChange =
        (index: number) => (service: DocumentService) => {
            onChangeService(index, service);
        };

    return (
        <>
            {files.length === 0 ? (
                <Div {...dropzoneProps}>
                    <Box
                        borderStyle={"dashed"}
                        borderColor={isDragActive ? "green.500" : "gray.300"}
                        flex={1}
                        justifyContent={"center"}
                        alignItems={"center"}
                        minHeight={"300px"}
                        bgColor={isDragActive ? "green.25" : "transparent"}
                        pt={6}
                        pb={6}
                        borderWidth={isSmallScreen ? 0 : 2}
                    >
                        {inputProps && <input type="hidden" {...inputProps} />}
                        <Icon
                            name="documentBlank"
                            size={24}
                            fill={colors.green[500]}
                        />
                        <Text mt={4}>
                            {
                                content[isSmallScreen ? "mobile" : "desktop"]
                                    .title
                            }
                        </Text>
                        <Text mb={4} color="gray.400">
                            {content[isSmallScreen ? "mobile" : "desktop"].desc}
                        </Text>
                        <Button onPress={onSelectPress}>
                            {t("selectFiles")}
                        </Button>
                    </Box>
                </Div>
            ) : (
                <>
                    {files.map(({ name, size, note }, index) => (
                        <DocumentListCard
                            key={index}
                            label={name}
                            subText={formatBytes(size)}
                            note={note}
                            onChangeNote={handleNoteChange(index)}
                            rightIconName="trashCan"
                            isEditable={true}
                            loading={isUploading}
                            onRightIconPress={handleDeletePress(index)}
                            onChangeService={handleServiceChange(index)}
                        />
                    ))}
                    <HStack justifyContent="flex-start" pt={4}>
                        <Button
                            onPress={onSelectPress}
                            variant="outlined"
                            leftIconName="documentAttachment"
                        >
                            {t("attachOtherFiles")}
                        </Button>
                    </HStack>
                </>
            )}
        </>
    );
};

export default DocumentUploadFormInner;
