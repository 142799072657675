import { invariant } from "@madmedical/utils";
import { View } from "react-native";
import { BaseButton } from "@madmedical/ui";
import { getRefreshToken, useLogoutMutation } from "@madmedical/store";

const LogoutButton = () => {
    const [logout] = useLogoutMutation();

    const handlePress = () => {
        void (async () => {
            const refreshToken = await getRefreshToken();
            invariant(refreshToken, "refreshToken is required");

            void logout(refreshToken);
        })();
    };

    return (
        <View>
            <BaseButton onPress={handlePress}>Log Out</BaseButton>
        </View>
    );
};

export default LogoutButton;
