import type { ForwardRefRenderFunction, ReactNode } from "react";
import { Dimensions } from "react-native";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
    Actionsheet as BaseActionsheet,
    Box,
    Pressable,
    ScrollView,
    Stack,
    Text,
    useTheme,
} from "native-base";
import Icon from "../icons/Icon";
import useResponsive from "../util/useResponsive";

interface Props {
    children?: ReactNode;
    title: string;
    rightContent?: ReactNode;
    footer?: ReactNode;
    hasFullLayout?: boolean;
    hasDragIndicator?: boolean;
    onClose?: () => void;
    isOpen?: boolean;
}

type RefType = {
    toggle: () => void;
};

const ActionSheet: ForwardRefRenderFunction<RefType, Props> = (
    {
        children,
        title,
        rightContent,
        footer,
        hasFullLayout = false,
        hasDragIndicator = false,
        onClose,
        isOpen = false,
    }: Props,
    forwardedRef
) => {
    const { isSmallScreen } = useResponsive();
    const { colors } = useTheme();
    const [isActionSheetOpen, setIsActionSheetOpen] = useState(isOpen);
    const actionSheetRef = useRef(null);

    const toggle = () => {
        setIsActionSheetOpen((prevState) => !prevState);
    };

    const handleOnClose = () => {
        toggle();
        onClose?.();
    };

    useImperativeHandle(forwardedRef, () => ({
        toggle,
    }));

    const fullHeight = `${Dimensions.get("window").height - 50}px`;

    if (!isSmallScreen) return null;

    return (
        <BaseActionsheet
            ref={actionSheetRef}
            isOpen={isActionSheetOpen}
            onClose={handleOnClose}
            testID="base-action-sheet"
            hideDragIndicator={!hasDragIndicator}
        >
            <BaseActionsheet.Content
                testID="base-action-sheet-content"
                maxHeight={hasFullLayout ? fullHeight : undefined}
                minHeight={hasFullLayout ? fullHeight : undefined}
                roundedTop={hasDragIndicator ? 10 : 0}
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
                borderTopRadius="lg"
            >
                {title && (
                    <Stack
                        direction="row"
                        width="full"
                        justifyContent="space-between"
                        alignItems="center"
                        height={14}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        px={4}
                    >
                        <Pressable onPress={handleOnClose}>
                            <Icon
                                name="close"
                                size={20}
                                fill={colors.gray[800]}
                            />
                        </Pressable>
                        <Text fontSize="md" fontWeight="medium">
                            {title}
                        </Text>
                        <Box height={5} position="relative">
                            {rightContent}
                        </Box>
                    </Stack>
                )}
                <ScrollView width="full" maxHeight="full">
                    {children}
                </ScrollView>
                {footer && (
                    <Box
                        width="full"
                        p={4}
                        borderTopWidth={1}
                        borderTopColor="gray.100"
                    >
                        {footer}
                    </Box>
                )}
            </BaseActionsheet.Content>
        </BaseActionsheet>
    );
};

export default forwardRef(ActionSheet);
