import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type {
    DeviceOwnership,
    MetricType,
    Provider,
    ProviderConnectionStatus,
} from "@madmedical/medical";
import { useEnsureContext } from "@madmedical/utils";

export interface Device {
    type: "cloud" | "ble" | "apple";
    provider: Provider;
    deviceName: string;
    manufacturer: string;
    isConnected: boolean;
    connectedAt: Date | null;
    lastRecordedAt: Date | null;
    status: ProviderConnectionStatus;
    metricTypes: MetricType[];
    ownership: DeviceOwnership | null;
    serialNumber: string | null;
}

interface ContextValue {
    devices: Device[];
}

const DeviceContext = createContext<ContextValue | undefined>(undefined);

export const DeviceProvider =
    DeviceContext.Provider as ReactProvider<ContextValue>;

export const useDevices = () => useEnsureContext(DeviceContext);
