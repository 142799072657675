import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import type { DateRange, Ulid } from "@madmedical/utils";
import type { Pagination } from "@madmedical/store";
import { useEnsureContext } from "@madmedical/utils";
import type { DocumentGeneral } from "./document";
import type {
    FilterItem,
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "./model";

export interface Evaluation {
    id: string;
    metricType: MetricType;
    dateRange: DateRange;
    alertLevel: AlertLevel;
    doctor: {
        fullName: string;
        avatarUrl?: string;
        initials: string;
    };
    documentCount: number;
    text: string;
    documents: DocumentGeneral[];
}

export type FilterChoices = {
    readonly metricTypes: FilterItem<MetricType>[];
    readonly alertLevels: FilterItem<AlertLevel>[];
    readonly doctors: FilterItem<Ulid>[];
};

interface ContextValue {
    evaluations: Evaluation[];
    selectedId?: Ulid;
    pagination: Pagination;
    onSortChange: OnSortChange | undefined;
    onSearchChange: OnSearchChange | undefined;
    filterChoices: FilterChoices | undefined;
    onFilterChange: OnFilterChange<FilterChoices> | undefined;
}

const EvaluationContext = createContext<ContextValue | undefined>(undefined);

export const EvaluationProvider =
    EvaluationContext.Provider as ReactProvider<ContextValue>;

export const useEvaluations = () => useEnsureContext(EvaluationContext);
