import { useMemo, useState } from "react";
import { Box } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { ProfileRole, forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useMeasurementComments } from "../../providers/comment";
import { useRouteNavigate } from "../../providers/routing";
import type { GraphProps, MoodGroupType } from "../types";
import Graph from "../Graph";
import { useEvaluations } from "../../providers/evaluation";
import { usePregnancyMood } from "../../providers/pregnancy-mood";

interface Props {
    metricType: MetricType;
    graphProps: Pick<GraphProps, "type" | "unit" | "limits" | "coords">;
}

const GraphWideWrapper = ({ metricType, graphProps }: Props) => {
    const [graphWidth, setGraphWidth] = useState<number>();
    const { evaluations, selectedId } = useEvaluations();
    const { comments, onDelete } = useMeasurementComments();
    const { pregnancyWellBeing } = usePregnancyMood();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const resolvedProps = useMemo(
        () => ({
            ...graphProps,
            evaluations: evaluations.map(({ id, dateRange, doctor }) => ({
                id,
                msecStart: dateRange.from.getTime(),
                msecEnd: dateRange.to.getTime(),
                name: doctor.fullName,
                isSelected: id === selectedId,
                onPress: () => {
                    navigate("opinion", { opinionId: id });
                },
            })),
            comments: comments.map(({ id, date, author, comment }) => ({
                key: id,
                msec: date.getTime(),
                comment,
                canDelete: author.isSelf,
                onDeleted: () => {
                    onDelete(id);
                },
                user: {
                    ...author,
                    monogram: author.initials,
                    // Faking representative into patient, hotline into doctor TODO: tidy up
                    type: [ProfileRole.Doctor, ProfileRole.Hotline].includes(
                        author.role
                    )
                        ? "doctor"
                        : "patient",
                },
            })),
            slot: { x: graphWidth ?? 600, y: 400 },
            paddingPercents: { x: 3, y: 3 },
            dashboardThumbnail: {
                title: t(`measures:${forHumans(metricType)}`),
                isDashboardThumbnail: false,
                showLineGraphNodes: true,
            },
            moods: pregnancyWellBeing?.map(
                ({ id, createdAt, status, symptoms, comment }) => ({
                    id,
                    msec: new Date(createdAt).getTime(),
                    createdAt,
                    status,
                    symptoms,
                    comment,
                })
            ) as unknown as MoodGroupType[],
        }),
        [
            graphProps,
            evaluations,
            comments,
            graphWidth,
            t,
            metricType,
            pregnancyWellBeing,
            selectedId,
            navigate,
            onDelete,
        ]
    );

    return (
        <Box
            onLayout={(e) => setGraphWidth(e.nativeEvent.layout.width)}
            width="100%"
        >
            <Graph graphProps={resolvedProps} />
        </Box>
    );
};

export default GraphWideWrapper;
