import Svg, { Path, Rect } from "react-native-svg";

const Papaya = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M45.5206 21.3281L48.0084 23.3048C48.4374 23.6429 48.7198 24.1332 48.7969 24.674C48.874 25.2147 48.74 25.7644 48.4227 26.209C46.7534 28.4889 45.9269 31.2773 46.0842 34.0985H41.5757C41.5757 34.0985 40.9558 25.9831 45.5206 21.3281Z"
            fill="#6D6260"
        />
        <Path
            d="M61.5784 71.16C56.3533 61.7107 56.5346 55.9894 56.6034 53.2692C56.7157 48.8386 56.4865 44.5224 55.8216 41.5468C53.3863 36.0295 49.505 32.7445 44.1422 31.7488C44.1422 31.7488 44.0627 31.7434 43.9163 31.7396C42.3645 31.7003 33.2854 31.8673 31.2392 41.0262C30.5744 44.0026 30.2946 48.7774 30.4069 53.2081C30.4759 55.9285 30.7062 60.7929 25.4822 70.2421C25.4822 70.2421 16.3271 85.7844 32.7816 100.921C35.2153 103.161 42.7476 107.184 44.1436 106.359C72.5997 89.5205 61.5784 71.16 61.5784 71.16Z"
            fill="#B6D2C6"
        />
        <Path
            d="M42.4408 105.588C38.0494 103.175 33.0598 97.0993 31.5691 87.8133C31.5431 87.6589 31.5759 87.5003 31.6609 87.3688C31.7459 87.2373 31.877 87.1423 32.0284 87.1026C32.1799 87.0628 32.3407 87.0812 32.4794 87.154C32.618 87.2268 32.7244 87.3489 32.7776 87.4961C34.7258 92.8984 38.6942 100.417 46.1462 102.967L42.4408 105.588Z"
            fill="#7B9791"
        />
        <Path
            d="M31.7304 70.4777C31.7057 70.6953 31.7631 70.9142 31.8914 71.0917C32.0197 71.2693 32.2095 71.3925 32.4239 71.4374C32.6382 71.4823 32.8616 71.4456 33.0503 71.3345C33.2391 71.2234 33.3795 71.0459 33.4443 70.8367C34.1592 68.5532 35.1896 65.8909 36.5452 63.9097C40.3527 58.3456 38.6358 48.6555 38.6358 40.5335C38.6329 39.8003 38.6831 39.0678 38.786 38.3418C38.8271 38.0665 38.7663 37.7856 38.615 37.5519C38.4637 37.3182 38.2323 37.1478 37.9643 37.0727C37.6962 36.9975 37.41 37.0228 37.1592 37.1438C36.9085 37.2648 36.7106 37.4731 36.6026 37.7297C36.15 38.8213 35.9276 39.9947 35.9494 41.1762V50.4125C35.9494 50.4125 36.1118 59.6395 34.2561 63.0438C34.2561 63.0435 32.1858 66.4002 31.7304 70.4777Z"
            fill="#7B9791"
        />
        <Path
            d="M32.7824 100.919C35.5725 103.445 38.6198 105.67 41.8742 107.56C42.561 107.966 43.344 108.18 44.1417 108.18C44.9394 108.18 45.7224 107.966 46.4092 107.56C74.8667 90.7222 62.8028 70.2422 62.8028 70.2422C57.5778 60.793 57.808 55.9281 57.8781 53.2081C57.9901 48.7775 57.7106 44.0022 57.0458 41.0263C54.8064 31.0026 44.1428 31.7489 44.1428 31.7489C44.1428 31.7489 44.0665 31.7437 43.9279 31.7399L43.9166 31.7489C43.9166 31.7489 49.423 32.4121 49.423 40.5341C49.423 43.3187 49.2236 46.2126 49.0786 49.0576L48.986 52.6778C48.8765 56.9616 49.6841 61.2193 51.3548 65.1654L53.5038 70.2414C55.5835 76.0983 56.8626 84.6694 51.9549 96.6072C51.9544 96.6081 46.7199 109.803 32.7824 100.919Z"
            fill="#7B9791"
        />
        <Path
            d="M31.5596 80.6728C32.0906 80.6728 32.521 80.2424 32.521 79.7114C32.521 79.1804 32.0906 78.75 31.5596 78.75C31.0286 78.75 30.5982 79.1804 30.5982 79.7114C30.5982 80.2424 31.0286 80.6728 31.5596 80.6728Z"
            fill="#7B9791"
        />
        <Path
            d="M99.9151 44.5928C100.149 44.656 100.38 44.7312 100.606 44.8179C103.038 45.7379 105.015 47.5693 106.118 49.9233C107.221 52.2774 107.364 54.9687 106.515 57.426C106.349 57.9103 106.163 58.4458 105.958 59.026C104.421 63.423 103.9 68.111 104.434 72.7383C104.787 75.9335 104.709 79.1618 104.201 82.336C103.786 84.9484 103.043 87.4978 101.989 89.9241C95.0835 100.738 83.075 106.204 67.5273 107.963L99.9151 44.5928Z"
            fill="#B6D2C6"
        />
        <Path
            d="M79.6564 98.4257C77.0393 98.6344 74.436 98.9894 71.8586 99.4888L67.5273 107.963C71.0989 108.78 74.7952 108.894 78.4103 108.299C89.0499 106.627 97.8202 99.5096 101.984 89.9219C97.9097 93.949 91.0809 97.4556 79.6564 98.4257Z"
            fill="#7B9791"
        />
        <Path
            d="M94.6713 44.4539C94.5885 43.9288 94.3865 43.4296 94.081 42.9945C93.7754 42.5595 93.3743 42.2001 92.9085 41.9439C92.3582 41.6621 91.7447 41.5266 91.127 41.5506C90.5092 41.5745 89.908 41.7572 89.3813 42.0809C87.5876 43.1502 84.1457 45.7622 80.3802 51.5612C77.9636 55.2467 75.0498 58.5811 71.7214 61.4699C67.0857 65.5303 60.9422 72.3721 59.704 80.8943C58.1076 91.8832 62.582 102.389 64.6017 106.433C64.8667 106.963 65.2919 107.396 65.817 107.671C66.3421 107.946 66.9404 108.048 67.527 107.964C74.6811 106.941 98.6414 101.279 98.8208 71.2284C98.8632 66.8927 99.7493 62.6068 101.43 58.6097C102.9 55.0718 103.989 50.5024 102.055 46.9425C100.055 43.2625 97.4689 44.0632 95.9664 45.0051C95.8496 45.08 95.7163 45.1253 95.578 45.1369C95.4398 45.1485 95.3008 45.1262 95.1731 45.0719C95.0455 45.0175 94.933 44.9328 94.8456 44.8251C94.7581 44.7174 94.6983 44.59 94.6713 44.4539Z"
            fill="#EDAF7C"
        />
        <Path
            d="M89.7929 54.8567C89.8474 54.8088 89.9117 54.7735 89.9814 54.7535C90.0511 54.7334 90.1243 54.7291 90.1959 54.7408C90.2674 54.7524 90.3355 54.7798 90.3952 54.821C90.4549 54.8621 90.5047 54.916 90.5411 54.9787C90.974 55.7204 91.6367 57.3218 91.3331 59.8474C90.8591 63.788 88.9003 65.681 88.9003 71.2985C88.9003 71.2985 90.6415 84.5741 82.3861 90.9505C73.1187 98.1083 69.9374 98.7897 69.9374 98.7897C69.9374 98.7897 64.9018 88.871 69.0716 78.6141C73.2413 68.3572 85.9021 63.0033 85.9021 63.0033L85.02 58.9964C85.02 58.9964 87.5235 56.8493 89.7929 54.8567Z"
            fill="#DD9051"
        />
        <Path
            d="M90.681 62.7398C90.3058 62.4887 89.8815 62.3202 89.4363 62.2454C89.5879 61.6614 89.6006 61.0501 89.4734 60.4603C89.3463 59.8705 89.0828 59.3187 88.7041 58.849C88.3254 58.3793 87.842 58.0048 87.2926 57.7555C86.7432 57.5062 86.143 57.389 85.5402 57.4133C84.9373 57.4376 84.3485 57.6026 83.8209 57.8953C83.2934 58.188 82.8416 58.6002 82.5019 59.0988C82.1622 59.5974 81.944 60.1686 81.8647 60.7667C81.7853 61.3648 81.8472 61.9731 82.0452 62.543C81.1487 62.7517 80.3654 63.2942 79.8548 64.0601C79.3443 64.826 79.1448 65.7577 79.2971 66.6655C78.2989 66.4723 77.2655 66.5861 76.3333 66.9919C75.401 67.3976 74.6136 68.0763 74.0747 68.9385C73.5358 69.8007 73.2708 70.8059 73.3146 71.8217C73.3584 72.8375 73.7088 73.8162 74.3199 74.6289C73.7264 75.0889 73.2698 75.7023 72.9994 76.4028C72.9072 76.3972 72.8154 76.3888 72.7215 76.3888C71.505 76.3888 70.3384 76.872 69.4782 77.7322C68.618 78.5924 68.1348 79.759 68.1348 80.9755C68.1348 81.0791 68.1434 81.1809 68.1502 81.2829C67.5609 81.5186 67.0461 81.9091 66.6603 82.4131C66.2745 82.9171 66.032 83.516 65.9584 84.1464C65.8847 84.7768 65.9827 85.4155 66.242 85.9948C66.5012 86.5742 66.9121 87.0728 67.4313 87.438C67.137 88.146 67.0397 88.9203 67.1495 89.6791C67.2593 90.4379 67.5722 91.1529 68.0551 91.7484C68.538 92.3439 69.1729 92.7978 69.8926 93.062C70.6123 93.3262 71.39 93.391 72.1435 93.2494C72.0391 93.9612 72.1613 94.6878 72.4928 95.3263C72.8242 95.9648 73.3481 96.4829 73.9903 96.8071C75.6352 95.8652 81.0773 92.5751 84.3917 89.0596C89.7848 83.4584 89.0329 73.6052 89.0329 73.6052C88.9444 71.9362 88.9567 70.2634 89.0699 68.5958C89.3954 66.1565 90.1187 64.5173 90.681 62.7398Z"
            fill="#6D6260"
        />
        <Path
            d="M43.0919 71.8572C43.4046 69.7536 42.4165 67.8638 40.8851 67.6361C39.3536 67.4085 37.8586 68.9293 37.5459 71.0329C37.2333 73.1365 38.2213 75.0263 39.7528 75.254C41.2843 75.4816 42.7793 73.9608 43.0919 71.8572Z"
            fill="#DCEAE4"
        />
        <Path
            d="M76.2694 71.8476C76.8416 71.8476 77.3055 71.3837 77.3055 70.8115C77.3055 70.2393 76.8416 69.7754 76.2694 69.7754C75.6972 69.7754 75.2333 70.2393 75.2333 70.8115C75.2333 71.3837 75.6972 71.8476 76.2694 71.8476Z"
            fill="#AFAFAF"
        />
        <Path
            d="M70.2773 89.7592C70.8124 89.7592 71.2462 89.3254 71.2462 88.7903C71.2462 88.2551 70.8124 87.8213 70.2773 87.8213C69.7421 87.8213 69.3083 88.2551 69.3083 88.7903C69.3083 89.3254 69.7421 89.7592 70.2773 89.7592Z"
            fill="#AFAFAF"
        />
        <Path
            d="M71.3764 80.8264C71.8999 80.8264 72.3243 80.402 72.3243 79.8785C72.3243 79.355 71.8999 78.9307 71.3764 78.9307C70.8529 78.9307 70.4285 79.355 70.4285 79.8785C70.4285 80.402 70.8529 80.8264 71.3764 80.8264Z"
            fill="#AFAFAF"
        />
        <Path
            d="M76.3615 85.3037C76.883 85.3037 77.3058 84.8809 77.3058 84.3594C77.3058 83.8378 76.883 83.415 76.3615 83.415C75.8399 83.415 75.4171 83.8378 75.4171 84.3594C75.4171 84.8809 75.8399 85.3037 76.3615 85.3037Z"
            fill="#AFAFAF"
        />
        <Path
            d="M82.3306 75.4393C82.9109 75.4393 83.3813 74.9689 83.3813 74.3886C83.3813 73.8083 82.9109 73.3379 82.3306 73.3379C81.7503 73.3379 81.2799 73.8083 81.2799 74.3886C81.2799 74.9689 81.7503 75.4393 82.3306 75.4393Z"
            fill="#AFAFAF"
        />
        <Path
            d="M84.9859 61.3352C85.4708 61.3352 85.864 60.9421 85.864 60.4572C85.864 59.9722 85.4708 59.5791 84.9859 59.5791C84.501 59.5791 84.1078 59.9722 84.1078 60.4572C84.1078 60.9421 84.501 61.3352 84.9859 61.3352Z"
            fill="#AFAFAF"
        />
        <Path
            d="M82.7703 83.2232C83.2907 83.2232 83.7125 82.8014 83.7125 82.2811C83.7125 81.7607 83.2907 81.3389 82.7703 81.3389C82.25 81.3389 81.8281 81.7607 81.8281 82.2811C81.8281 82.8014 82.25 83.2232 82.7703 83.2232Z"
            fill="#AFAFAF"
        />
    </Svg>
);

export default Papaya;
