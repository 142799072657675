import type { ComponentProps, ReactNode } from "react";
import { Box } from "native-base";

interface Props
    extends Pick<
        ComponentProps<typeof Box>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
}

const CardFooter = ({ children, ...rest }: Props) => (
    <Box
        _text={{ fontSize: "xs", color: "gray.600" }}
        alignItems="flex-end"
        {...rest}
    >
        {children}
    </Box>
);

export default CardFooter;
