import { Modal, RepresentativeModalForm, useShowToast } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useGetInvitesQuery, useUpdateInviteMutation } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const EditInviteModal = ({ id, open, onClose }: Props) => {
    const { invite } = useGetInvitesQuery(undefined, {
        selectFromResult: ({ data: invites }) => ({
            invite: invites?.find((invite) => invite.id === id),
        }),
    });
    const [update] = useUpdateInviteMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!id || !invite) {
        return null;
    }

    const handleSuccess = () => {
        showToast(t("commentModified"), { variant: "success" });
    };

    const { email, note } = invite;

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{ id, email, note: note ?? "" }}
                mutation={update}
                onSuccess={handleSuccess}
            >
                {({ submitForm, isSuccess }) => (
                    <RepresentativeModalForm
                        mode="editInvite"
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditInviteModal;
