import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import type { Device } from "../providers/device";
import ListHeader from "../molecules/ListHeader";
import { useDevices } from "../providers/device";
import EmptyState from "../atoms/EmptyState";
import { DeviceItem } from "./DeviceItem";

interface Props {
    readonly title?: string;
    readonly deviceTypes?: Device["type"][];
}

const DeviceList = ({ title, deviceTypes }: Props) => {
    const { devices } = useDevices();
    const { t } = useTranslation();

    return (
        <>
            {devices.length > 0 && title && <ListHeader title={title} />}
            {devices.length ? (
                devices
                    .filter(
                        (device) =>
                            !deviceTypes || deviceTypes.includes(device.type)
                    )
                    .map((item, index) => (
                        <DeviceItem key={index} device={item} />
                    ))
            ) : (
                <EmptyState
                    title={isWeb ? t("measures:noData") : t("noDevice")}
                    message={
                        isWeb
                            ? t("noResultsAfterFilter")
                            : t("tryAgainOrNewDevice")
                    }
                    my={4}
                />
            )}
        </>
    );
};

export default DeviceList;
