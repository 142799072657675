import { Box, HStack, Stack, Text } from "native-base";
import type { ReactNode } from "react";
import type { DateTimeString, Ulid } from "@madmedical/utils";
import {
    displayPeriodOfDay,
    formatDate,
    formatDateRange,
    formatTime,
    isWeb,
} from "@madmedical/utils";
import type { AlertLevel, Metric } from "@madmedical/medical";
import { MetricType, forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import ConsultationButton from "../molecules/ConsultationButton";
import { useChart } from "../providers/chart";
import Button from "../atoms/Button";
import Paper from "../atoms/Paper";
import StatusBadge from "../atoms/StatusBadge";
import useResponsive from "../util/useResponsive";
import DateRangePicker from "../organisms/DateRangePicker";
import type { MeasurementPdfData } from "../util/pdf/downloadMeasurementData";
import { useCurrentPatient } from "../providers/currentPatient";
import type { MeasurementComment } from "../providers/comment";
import { useMeasurementComments } from "../providers/comment";
import downloadMeasurementData from "../util/pdf/downloadMeasurementData";
import Metrics from "./Metrics";

interface Measurement {
    readonly id: Ulid;
    readonly measuredAt: DateTimeString;
    readonly metric: Metric;
    readonly provider: string;
    readonly manual: boolean;
    readonly deleted: boolean;
    readonly alertLevel: AlertLevel | null;
}
interface Props {
    addCommentButton: ReactNode;
    onManualPress: (() => void) | null;
}

const MeasurementChartCard = ({ addCommentButton, onManualPress }: Props) => {
    const { chart, displayRange, alertLevel, dateRange, metricType } =
        useChart();
    const { patient } = useCurrentPatient();
    const { comments } = useMeasurementComments();

    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const handleExport = () => {
        const pacientName = patient?.fullName ?? t("patient");
        const period = formatDateRange(dateRange, "long", true);
        const birthDate = patient?.birthDate
            ? formatDate(patient.birthDate)
            : "-";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const measurements = (chart as any)?.props?.data
            ?.items as Measurement[];

        const groupedMeasurements: Record<string, Measurement[]> = {};
        measurements.forEach((measurement) => {
            const date = measurement.measuredAt.split("T")[0];
            if (!groupedMeasurements[date]) {
                groupedMeasurements[date] = [];
            }
            groupedMeasurements[date].push(measurement);
        });

        const groupedComments: Record<string, MeasurementComment[]> = {};
        comments.forEach((comment) => {
            const date = comment.date.toISOString().split("T")[0];
            if (!groupedComments[date]) {
                groupedComments[date] = [];
            }
            groupedComments[date].push(comment);
        });

        const groupAll = { ...groupedMeasurements, ...groupedComments };

        const mergedData: MeasurementPdfData[] = [];
        Object.keys(groupAll).forEach((date) => {
            const measurementData: MeasurementPdfData = {
                date: formatDate(date),
                measurement: groupedMeasurements[date]
                    ? groupedMeasurements[date]?.map((measurement) => {
                          const measureTime =
                              measurement?.metric.type ===
                                  MetricType.BloodSugar &&
                              measurement?.metric?.mealSituation
                                  ? forHumans(
                                        measurement?.metric?.mealSituation
                                    )
                                  : displayPeriodOfDay(measurement.measuredAt);

                          return {
                              measuredAt: formatTime(measurement.measuredAt),
                              value: measurement.metric.displayValue,
                              measureTime,
                              alert: measurement.alertLevel !== 0,
                          };
                      })
                    : [],
                comments: groupedComments[date]
                    ? groupedComments[date]?.map((comment) => ({
                          time: formatTime(comment.date),
                          patient:
                              comment.author.role === "patient"
                                  ? comment.comment
                                  : "-",
                          doctor:
                              comment.author.role === "doctor"
                                  ? comment.comment
                                  : "-",
                      }))
                    : [],
            };
            mergedData.push(measurementData);
        });

        const measurementType = t(`measures:${forHumans(metricType)}`);

        downloadMeasurementData(
            pacientName,
            period,
            birthDate,
            mergedData,
            measurementType
        );
    };

    return (
        <Paper borderTopLeftRadius={0} borderTopRightRadius={0}>
            <Box p={6} width="full">
                <Stack
                    alignItems="flex-start"
                    direction={isSmallScreen ? "column-reverse" : "row"}
                    space={6}
                >
                    <Box flex="1">
                        <Text color="gray.600">
                            {formatDateRange(dateRange)}
                        </Text>
                        <HStack alignItems="center">
                            {displayRange && (
                                <Metrics displayValue={[displayRange]} />
                            )}

                            {alertLevel ? (
                                <Box mt={2}>
                                    <StatusBadge size="xs" level={alertLevel} />
                                </Box>
                            ) : null}
                        </HStack>
                    </Box>

                    <Box>
                        <DateRangePicker />
                    </Box>
                </Stack>
            </Box>

            <Box width="100%">{chart}</Box>

            <Stack
                direction={isSmallScreen ? "column" : "row"}
                p={isSmallScreen ? 4 : 6}
                space={2}
                justifyContent="space-between"
                position="relative"
                width="full"
            >
                <Stack direction={isSmallScreen ? "column" : "row"} space={2}>
                    {onManualPress && (
                        <Button
                            onPress={onManualPress}
                            variant="outlined"
                            leftIconName="chartCustom"
                            forceIcon
                        >
                            {t("measures:addManualMeasurement")}
                        </Button>
                    )}

                    {isSmallScreen && <ConsultationButton />}
                    {addCommentButton && (
                        <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            space={2}
                        >
                            {addCommentButton}
                        </Stack>
                    )}
                </Stack>

                {isWeb &&
                    (metricType === MetricType.BloodSugar ||
                        metricType === MetricType.BloodPressure) && (
                        <Button
                            onPress={handleExport}
                            variant="outlined"
                            leftIconName="download"
                            ml="auto"
                            width={isSmallScreen ? "full" : "auto"}
                            forceIcon
                        >
                            {t("downloadData")}
                        </Button>
                    )}
            </Stack>
        </Paper>
    );
};

export default MeasurementChartCard;
