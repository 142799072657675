import { groupBy as _groupBy } from "lodash/fp";

type Item<DateKey extends string> = Record<string, unknown> & {
    [key in DateKey]: Date;
};

/**
 * Groups list of objects by date Creates object from array where keys are mindights in unix timestamp
 */
const groupByDay = <DateKey extends string, TItem extends Item<DateKey>>(
    list: TItem[],
    dateKey: DateKey
) =>
    _groupBy(
        (item) => new Date(item[dateKey].valueOf()).setHours(0, 0, 0, 0),
        list
    );

export default groupByDay;
