import { useState } from "react";
import { Box, FlatList, ScrollView } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import ListHeader from "../molecules/ListHeader";
import Badge from "../atoms/Badge";
import Headline from "../molecules/Headline";
import Paper from "../atoms/Paper";
import HealthEvaluationTable from "../organisms/HealthEvaluationTable";
import EvaluationsMobileFilter from "../organisms/filters/mobile/EvaluationsMobileFilter";
import EvaluationListItem from "../molecules/ListItem/EvaluationListItem";
import SearchInput from "../molecules/Form/SearchInput";
import { useEvaluations } from "../providers/evaluation";
import useResponsive from "../util/useResponsive";
import EmptyState from "../atoms/EmptyState";
import EvaluationsDesktopFilter from "../organisms/filters/desktop/EvaluationsDesktopFilter";

interface Props {
    onEndReached: () => unknown;
}

const Evaluations = ({ onEndReached }: Props) => {
    const { isSmallScreen } = useResponsive();
    const [search, setSearch] = useState("");
    const { evaluations, onSearchChange, pagination } = useEvaluations();
    const { t } = useTranslation();
    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange?.(search);
    };

    return (
        <ScrollView>
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title={t("results")}
                    accessory={
                        <Badge hasIndicator={false} variant="ghost">
                            {pagination.totalCount}
                        </Badge>
                    }
                />

                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        isSmallScreen ? (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        ) : (
                            <EvaluationsDesktopFilter />
                        )
                    }
                    rightContent={
                        isSmallScreen ? (
                            <EvaluationsMobileFilter />
                        ) : (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        )
                    }
                />

                {isSmallScreen ? (
                    evaluations.length ? (
                        <>
                            <ListHeader title={t("result")} />
                            <FlatList
                                minWidth="full"
                                showsVerticalScrollIndicator={false}
                                data={evaluations}
                                renderItem={({ item }) => (
                                    <EvaluationListItem evaluation={item} />
                                )}
                                keyExtractor={({ id }) => id}
                                overflow="hidden"
                                onEndReached={onEndReached}
                            />
                        </>
                    ) : (
                        <EmptyState
                            title={t("measures:noData")}
                            message={t("noResultsAfterFilter")}
                            my={10}
                            mx={6}
                        />
                    )
                ) : (
                    <Box px={6} py={4} width="full">
                        <HealthEvaluationTable onEndReached={onEndReached} />
                    </Box>
                )}
            </Paper>
            <Box w={1} h={140} />
        </ScrollView>
    );
};

export default Evaluations;
