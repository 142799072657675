import { useCallback, useMemo } from "react";
import { ScrollView, VStack } from "native-base";
import {
    currentPregnancyWeek,
    differenceInWeeks,
    invariant,
} from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import PregnancyPageTabs from "../../organisms/Pregnancy/PregnancyPageTabs";
import PregnancyGraphWeightMeasure from "../../molecules/Pregnancy/PregnancyGraphWeightMeasure";
import PregnancyCircumferenceMeasure from "../../molecules/Pregnancy/PregnancyCircumferenceMeasure";
import { usePregnancy } from "../../providers/pregnancy";

type measureType = "weight" | "circumference";
interface Props {
    onMeasurePress: (arg: measureType) => void;
    isDoctor?: boolean;
}

const PregnancyMeasurementPageContent = ({
    onMeasurePress,
    isDoctor = false,
}: Props) => {
    const {
        weightData,
        bmiData,
        circumferenceData,
        patient,
        pregnancyData,
        firstMeasuredBmi,
        firstMeasuredWeight,
    } = usePregnancy();
    const { t } = useTranslation();
    const pregnancyStart = pregnancyData?.startedAt ?? new Date().toISOString();
    const currentWeek = currentPregnancyWeek(
        new Date(pregnancyStart),
        new Date()
    );

    const weightItems = weightData?.items as {
        metric: { kg: number };
        measuredAt: string;
    }[];

    const calculateWeight = weightItems?.map((item) => {
        const { metric, measuredAt } = item;
        const pregnancyWeek = differenceInWeeks(
            new Date(pregnancyStart),
            new Date(measuredAt)
        );

        return {
            valueWeight: Math.round(metric.kg),
            measuredAt,
            pregnancyWeek,
        };
    });

    const bmiItems = bmiData?.items as {
        metric: { bmi: number };
        measuredAt: string;
    }[];

    const calculateBmi = bmiItems?.map((item) => {
        const { metric, measuredAt } = item;
        const pregnancyWeek = differenceInWeeks(
            new Date(pregnancyStart),
            new Date(measuredAt)
        );

        return {
            valueBmi: Math.round(metric.bmi),
            measuredAt,
            pregnancyWeek,
        };
    });

    const weightPointData = calculateWeight.map((a) => ({
        ...a,
        ...calculateBmi.find((b) => b.pregnancyWeek === a.pregnancyWeek),
    }));

    const circumferenceItems = circumferenceData?.items as {
        metric: { circumference: number };
        measuredAt: string;
    }[];

    const circumferencePointData = circumferenceItems?.map((item) => {
        const { metric, measuredAt } = item;
        const pregnancyWeek = differenceInWeeks(
            new Date(pregnancyStart),
            new Date(measuredAt)
        );

        return {
            valueCircumference: Math.round(metric.circumference),
            measuredAt,
            pregnancyWeek,
        };
    });

    const weightStats = weightData?.stats as {
        min: { kg: number };
        max: { kg: number };
    };

    const bmiStats = bmiData?.stats as {
        min: { bmi: number };
        max: { bmi: number };
    };

    const circumferenceStats = circumferenceData?.stats as {
        min: { circumference: number };
        max: { circumference: number };
    };

    const roundMin = useCallback((number: number) => Math.floor(number), []);

    const roundMax = useCallback(
        (number: number) => Math.ceil((number + 2) / 10) * 10,
        []
    );

    const weightMin = useMemo(
        () =>
            firstMeasuredWeight && firstMeasuredWeight < weightStats?.min?.kg
                ? firstMeasuredWeight
                : weightStats?.min?.kg,
        [weightStats?.min?.kg, firstMeasuredWeight]
    );

    const bmiMin = useMemo(
        () =>
            firstMeasuredBmi && firstMeasuredBmi < bmiStats?.min?.bmi
                ? firstMeasuredBmi
                : bmiStats?.min?.bmi,
        [bmiStats?.min?.bmi, firstMeasuredBmi]
    );

    const weightGraphRanges = {
        weight: {
            min: roundMin(weightMin),
            max: weightStats?.max ? roundMax(weightStats?.max?.kg) : 10,
        },
        bmi: {
            min: roundMin(bmiMin),
            max: bmiStats?.max ? roundMax(bmiStats?.max?.bmi) : 10,
        },
        percentile: {
            min: roundMin(weightMin),
            max: weightMin + 16,
        },
    };

    const circumferenceGraphRanges = {
        circumference: {
            min: circumferenceStats?.min
                ? roundMin(circumferenceStats?.min?.circumference)
                : 0,
            max: circumferenceStats?.max
                ? roundMax(circumferenceStats?.max?.circumference)
                : 10,
        },
    };

    /* ranges for weight gain
    const firstTrimesterGain = [0.5, 2];
    const normalGain = [0.35, 0.5];

    const gainRanges = {
        underweight: [0.44, 0.58],
        normal: [0.35, 0.5],
        overweight: [0.23, 0.33],
        obese: [0.17, 0.27],
    };
    */
    const normalWeightGain = [
        {
            pregnancyWeek: 0,
            valuePercentileMin: weightMin,
            valuePercentileMax: weightMin,
        },
        {
            pregnancyWeek: 1,
            valuePercentileMin: weightMin,
            valuePercentileMax: weightMin,
        },
        {
            pregnancyWeek: 13,
            valuePercentileMin: weightMin + 0.9,
            valuePercentileMax: weightMin + 2.5,
        },
        {
            pregnancyWeek: 40,
            valuePercentileMin: weightMin + 11.3,
            valuePercentileMax: weightMin + 15.9,
        },
    ];

    invariant(weightPointData);
    invariant(circumferencePointData);

    return (
        <ScrollView>
            {isDoctor ? (
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="dashboard" params={{}}>
                                    {t("health")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            {patient && (
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patient"
                                        params={{ patientId: patient.userId }}
                                    >
                                        {patient.fullName}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                            )}
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("pregnancy:pregnancy2")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
            ) : (
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="dashboard" params={{}}>
                                    {t("health")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("pregnancy:pregnancy2")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
            )}
            <VStack>
                <PregnancyPageTabs isDoctor={isDoctor} />
                <PregnancyGraphWeightMeasure
                    onMeasurePress={onMeasurePress}
                    weightPointData={weightPointData}
                    ranges={weightGraphRanges}
                    isDoctor={isDoctor}
                    currentWeek={currentWeek}
                    percentile={normalWeightGain}
                />
                <PregnancyCircumferenceMeasure
                    onMeasurePress={onMeasurePress}
                    circumferencePointData={circumferencePointData}
                    ranges={circumferenceGraphRanges}
                    isDoctor={isDoctor}
                    currentWeek={currentWeek}
                />
            </VStack>
        </ScrollView>
    );
};

export default PregnancyMeasurementPageContent;
