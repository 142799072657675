import type { ComponentProps, ReactNode } from "react";
import { useMemo } from "react";
import { Box, CircleIcon, HStack, Text, VStack, useTheme } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Avatar from "../atoms/Avatar/Avatar";
import Badge from "../atoms/Badge";

interface BaseProps
    extends Pick<
        ComponentProps<typeof Avatar>,
        "status" | "isCircle" | "initials" | "source"
    > {
    label?: ReactNode;
    hasStatusBadge?: boolean;
    hasAvatar?: boolean;
}

interface SmallAvatarProps extends BaseProps {
    size?: "2xs" | "xs" | "sm";
    subText: never;
    statusText: never;
}

interface BigAvatarProps extends BaseProps {
    size?: "md" | "lg" | "xl";
    subText?: string | null;
    statusText?: never;
}

interface StatusTextAvatarProps extends BaseProps {
    size?: "2xl" | "3xl";
    statusText?: string;
    subText?: string | null;
}

type Props = BigAvatarProps | SmallAvatarProps | StatusTextAvatarProps;

type VariantsType = {
    [key: string]: {
        labelSize: number;
        subTextSize: number;
        lineHeight: number;
        subtextLineHeight: number;
    };
};

const AvatarTexts = ({
    source,
    size = "md",
    label,
    subText,
    initials,
    isCircle = true,
    status,
    statusText = "",
    hasStatusBadge = false,
    hasAvatar = true,
}: Props) => {
    const { fontSizes } = useTheme();
    const { t } = useTranslation();

    const statuses = {
        active: t("active"),
        inactive: t("inactive"),
    };
    const variants: VariantsType = useMemo(
        () => ({
            "2xs": {
                labelSize: fontSizes.sm,
                lineHeight: 20,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            xs: {
                labelSize: fontSizes.sm,
                lineHeight: 20,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            sm: {
                labelSize: fontSizes.sm,
                lineHeight: 20,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            md: {
                labelSize: fontSizes.sm,
                lineHeight: 20,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            lg: {
                labelSize: fontSizes.md,
                lineHeight: 1,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            xl: {
                labelSize: fontSizes.md,
                lineHeight: 24,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            "2xl": {
                labelSize: fontSizes.md,
                lineHeight: 24,
                subTextSize: fontSizes.xs,
                subtextLineHeight: 18,
            },
            "3xl": {
                labelSize: isCircle ? fontSizes["2xl"] : fontSizes.lg,
                lineHeight: 26,
                subTextSize: fontSizes.sm,
                subtextLineHeight: 20,
            },
        }),
        [fontSizes, isCircle]
    );

    return (
        <HStack alignItems="center" maxWidth="full">
            {hasAvatar && (
                <Box mr={!label ? "0" : undefined}>
                    <Avatar
                        source={source}
                        initials={initials}
                        size={size}
                        isCircle={isCircle}
                        status={!hasStatusBadge ? status : undefined}
                    />
                </Box>
            )}

            {label && (
                <VStack
                    flex="1"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Text fontSize={variants[size].labelSize} numberOfLines={2}>
                        {label}
                    </Text>

                    {subText && !["2xs", "xs", "sm"].includes(size) && (
                        <Text
                            color="gray.600"
                            lineHeight={variants[size].labelSize}
                            fontWeight={"500"}
                            fontSize={
                                variants[size].subTextSize ?? fontSizes.xs
                            }
                        >
                            {subText}
                        </Text>
                    )}

                    {status && hasStatusBadge && (
                        <Badge
                            mt={2}
                            size="sm"
                            variant={`ghost${
                                status === "active" ? "Success" : "Error"
                            }`}
                        >
                            {statuses[status]}
                        </Badge>
                    )}

                    {statusText && ["2xl", "3xl"].includes(size) && (
                        <HStack
                            alignItems="center"
                            width="100%"
                            mt={subText ? 2.5 : undefined}
                        >
                            <CircleIcon size="1.5" mr="1" />
                            <Text color="gray.500">{statusText}</Text>
                        </HStack>
                    )}
                </VStack>
            )}
        </HStack>
    );
};

export default AvatarTexts;
