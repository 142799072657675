import { Box, HStack, Text } from "native-base";
import type { Infant } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Paper from "../../atoms/Paper";
import NewbornIcon from "../../pics/Pregnancy/NewbornIcon";
import Button from "../../atoms/Button";
import useResponsive from "../../util/useResponsive";
import { getInfantsNames } from "../../util/getInfantsNames";

interface Props {
    infants: Infant[];
    week: number;
    openBirthModal: () => void;
}

const PregnancySoonborn = ({ infants, week = 40, openBirthModal }: Props) => {
    const { t } = useTranslation();

    const { isSmallScreen } = useResponsive();
    const infantNames = t(`pregnancy:${getInfantsNames(infants)}`);
    const maintext =
        infants.length > 1
            ? t("pregnancy:yourChildrenAreComingSoon", { name: infantNames })
            : t("pregnancy:yourChildIsComingSoon", { name: infantNames });

    return (
        <Paper width="full" mb={2} px={4} py={2}>
            <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                direction={isSmallScreen ? "column" : "row"}
            >
                <HStack alignItems={"center"} flex={1}>
                    <Box>
                        <NewbornIcon />
                    </Box>
                    <Box px={4} width={"auto"} flex={1}>
                        <Text fontWeight="bold" color="#0E6369">
                            {maintext}
                        </Text>
                        <Text color="#0E6369" opacity={0.75} width={"full"}>
                            {t("pregnancy:pregnancyWeek", { week: week })}
                        </Text>
                    </Box>
                </HStack>
                <Box
                    h={"38px"}
                    justifyContent={"center"}
                    width={isSmallScreen ? "100%" : "auto"}
                    my={1}
                >
                    <Button
                        variant="primary"
                        size="sm"
                        px={8}
                        onPress={openBirthModal}
                    >
                        {t("pregnancy:birth")}
                    </Button>
                </Box>
            </HStack>
        </Paper>
    );
};

export default PregnancySoonborn;
