const breakpoints = {
    base: 0,
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1440,
};

export default breakpoints;
