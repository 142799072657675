import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Select as BaseSelect, Box, useTheme } from "native-base";
import { isWeb } from "@madmedical/utils";
import Icon from "../icons/Icon";

type DataType = {
    value: string;
    label: string;
};

interface Props
    extends Pick<
        ComponentProps<typeof BaseSelect>,
        | "size"
        | "isDisabled"
        | "placeholder"
        | "width"
        | "maxWidth"
        | "borderWidth"
        | "borderRightWidth"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    choices: DataType[];
    size?: "sm" | "md" | "xl";
    value?: string;
    onChange?: (v: string) => void;
}

const Select = ({ choices, size = "sm", value, onChange, ...rest }: Props) => {
    const { colors } = useTheme();
    const sizes = useMemo(
        () => ({
            sm: {
                fontSize: "sm",
                height: "35px",
                px: 3,
                py: 2,
                my: isWeb ? 0 : 2,
            },
            md: {
                fontSize: "md",
                height: "40px",
                px: 4,
                py: 2,
            },
            xl: {
                fontSize: "md",
                height: "50px",
                px: 4,
                py: 4,
            },
        }),
        []
    );

    return (
        <BaseSelect
            isFocusVisible={false}
            dropdownIcon={
                <Box p={2}>
                    <Icon
                        name="chevronDown"
                        size={16}
                        fill={colors.gray[800]}
                    />
                </Box>
            }
            selectedValue={value}
            onValueChange={onChange}
            {...sizes[size]}
            {...rest}
        >
            {choices.map((item) => (
                <BaseSelect.Item
                    key={item.value}
                    value={item.value}
                    label={item.label}
                />
            ))}
        </BaseSelect>
    );
};

export default Select;
