import { Box, HStack, Text } from "native-base";
import {
    ProviderConnectionStatus,
    forHumans,
    getServiceColour,
    logisticsForHumans,
    serviceForHumans,
} from "@madmedical/medical";
import { formatDate, formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import getImage from "../../organisms/DeviceItem/getImage";
import ActionButton from "../../molecules/ActionButton";
import AvatarTexts from "../../molecules/AvatarTexts";
import Headline from "../../molecules/Headline";
import Breadcrumb from "../../molecules/Breadcrumb";
import Paper from "../../atoms/Paper";
import Badge from "../../atoms/Badge";
import SimpleListItem from "../../molecules/ListItem/SimpleListItem";
import Tag from "../../atoms/Tag";
import type { LogisticsDevice } from "../../providers/callCenterDevice";
import type { PatientBase } from "../../providers/patient";
import Avatar from "../../atoms/Avatar/Avatar";

interface Props {
    device: LogisticsDevice;
    patient: PatientBase | null | undefined;
    onEditPress: () => unknown;
}

const CallCenterDevice = ({
    device: {
        deviceType,
        serialNumber,
        status,
        ownership,
        connectionStatus,
        warrantyExpiresAt,
        deliveredAt,
        returnedAt,
        note,
    },
    patient,
    onEditPress,
}: Props) => {
    const { t } = useTranslation();
    const badgeStatus =
        connectionStatus === ProviderConnectionStatus.Active
            ? ({
                  variant: "ghostSuccess",
                  text: t("active"),
              } as const)
            : connectionStatus === ProviderConnectionStatus.Inactive
            ? ({
                  variant: "ghostError",
                  text: t("inactive"),
              } as const)
            : ({
                  variant: "ghost",
                  text: t("notConnected"),
              } as const);

    return (
        <>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                borderTopRadius="lg"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link
                                route="callcenter_devices"
                                params={{}}
                            >
                                {t("devices")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>{serialNumber}</Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <Paper borderTopRadius="0" p={{ base: 4, md: 6 }} mb={4}>
                <HStack space="4" w="full">
                    <Avatar
                        size="3xl"
                        isCircle={false}
                        source={getImage(deviceType.provider)}
                    />
                    <Box flex={1}>
                        <Text fontSize="lg" fontWeight="500">
                            {deviceType.name}
                        </Text>
                        <Text
                            fontSize="sm"
                            color="gray.600"
                            fontWeight="500"
                            mb="3.5"
                        >
                            {deviceType.manufacturer.forHumans}
                        </Text>

                        <HStack>
                            <Badge variant={badgeStatus.variant}>
                                {badgeStatus.text}
                            </Badge>
                        </HStack>
                    </Box>
                    <ActionButton
                        actions={[
                            {
                                text: t("edit"),
                                iconName: "edit",
                                onPress: onEditPress,
                            },
                        ]}
                    />
                </HStack>
            </Paper>

            <HStack space="2">
                <Paper flex="1">
                    <Box width="full">
                        <Headline size="sm" title={t("details")} />
                        <SimpleListItem
                            label={t("serialNumber")}
                            content={serialNumber}
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("deviceName")}
                            content={deviceType.name}
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            label={t(`backend:${deviceType.manufacturer}`)}
                            content={deviceType.manufacturer.forHumans}
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("measures:type")}
                            content={
                                <HStack space={1}>
                                    {deviceType.metricTypes.map(
                                        (metricType) => (
                                            <Tag
                                                key={metricType}
                                                mb={{ base: 0, md: 2 }}
                                            >
                                                {t(
                                                    `measures:${forHumans(
                                                        metricType
                                                    )}`
                                                )}
                                            </Tag>
                                        )
                                    )}
                                </HStack>
                            }
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("logistics")}
                            content={logisticsForHumans(status)}
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("contact")}
                            content={
                                <Badge variant={badgeStatus.variant}>
                                    {badgeStatus.text}
                                </Badge>
                            }
                            borderBottomWidth="0"
                        />
                    </Box>
                </Paper>

                <Paper flex="1">
                    <Box width="full">
                        <Headline size="sm" title={t("settlement")} />
                        {patient && (
                            <Box px="4" mt="4">
                                <AvatarTexts
                                    size="lg"
                                    isCircle
                                    source={patient.avatarUrl}
                                    initials={patient.initials}
                                    label={patient.fullName}
                                />
                            </Box>
                        )}

                        {patient && (
                            <SimpleListItem
                                label={t("packages")}
                                content={
                                    <HStack space={1}>
                                        {patient.services.map((service) => (
                                            <Tag
                                                key={service}
                                                variant={getServiceColour(
                                                    service
                                                )}
                                            >
                                                {t(
                                                    `backend:${serviceForHumans(
                                                        service
                                                    )}`
                                                ).toLowerCase()}
                                            </Tag>
                                        ))}
                                    </HStack>
                                }
                                borderBottomWidth="0"
                            />
                        )}
                        <SimpleListItem
                            label={t("given")}
                            content={
                                deliveredAt ? formatDateTime(deliveredAt) : "–"
                            }
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("returned")}
                            content={
                                returnedAt ? formatDateTime(returnedAt) : "–"
                            }
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("settlement")}
                            content={
                                ownership ? logisticsForHumans(ownership) : "–"
                            }
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("guaranteeValidity")}
                            content={
                                warrantyExpiresAt
                                    ? formatDate(warrantyExpiresAt)
                                    : "–"
                            }
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("comments")}
                            content={note ?? "–"}
                            borderBottomWidth="0"
                        />
                    </Box>
                </Paper>
            </HStack>
        </>
    );
};

export default CallCenterDevice;
