import type { ComponentProps } from "react";
import type { FormControl as BaseFormControl } from "native-base";
import { Controller, useField } from "@madmedical/form";
import BaseDateInput from "../molecules/Form/DateInput";
import FormControlWrapper from "../molecules/Form/FormControlWrapper";

interface Props {
    readonly name: string;
    readonly isRequired: boolean;
    readonly label?: string;
    readonly isDefaultToday?: boolean;
    labelProps?: ComponentProps<typeof BaseFormControl.Label>;
}

const DateInput = ({
    name,
    isRequired,
    label,
    labelProps,
    isDefaultToday = true,
}: Props) => {
    const { control, isError, error } = useField(name);
    const dateValue = (value: string) => {
        if (!isDefaultToday && !value) {
            return undefined;
        }
        if (!value) {
            return new Date();
        }

        return new Date(value);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => (
                <FormControlWrapper
                    isInvalid={isError}
                    isRequired={isRequired}
                    error={error}
                    label={label}
                    labelProps={labelProps}
                >
                    <BaseDateInput
                        onChange={onChange}
                        value={dateValue(value as string)}
                        isInvalid={isError}
                    />
                </FormControlWrapper>
            )}
        />
    );
};

export default DateInput;
