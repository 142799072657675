import { useTranslation } from "@madmedical/i18n";
import Button from "../../atoms/Button";
import CallCenterDeviceList from "../../organisms/CallCenter/CallCenterDeviceList";
import Headline from "../../molecules/Headline";
import Paper from "../../atoms/Paper";

interface Props {
    onAddPress: () => unknown;
    onEditPress: (id: string) => unknown;
    onEndReached: () => unknown;
}

const CallCenterDevices = ({ onAddPress, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Paper>
            <Headline
                size="lg"
                title={t("devices")}
                rightContent={
                    <Button
                        leftIconName="add"
                        variant="outlined"
                        onPress={onAddPress}
                    >
                        {t("newDevice")}
                    </Button>
                }
            />
            <CallCenterDeviceList {...props} />
        </Paper>
    );
};

export default CallCenterDevices;
