import { Fragment, useMemo } from "react";
import { Circle, Line, Polygon } from "react-native-svg";
import type { GraphData, TimeData } from "./types";
import { colors } from "./useStyles";
import { normalizeCoords } from "./utils";

export default function Prediction({
    graphData = {},
    timeData,
}: {
    graphData?: GraphData;
    timeData: TimeData;
}) {
    const {
        coords = [],
        type = "",
        predictions,
        limits = {
            yMin: 0,
            yMax: 0,
            yMinTitle: "",
            yMaxTitle: "",
        },
        randomIdFragment = "",
        paddings,
        slot,
    } = graphData;

    const { yMinTitle, yMaxTitle } = limits;
    const yMin = Number(yMinTitle);
    const yMax = Number(yMaxTitle);

    interface Node {
        msec?: number;
        x?: number;
        yHigh?: number;
        yLow?: number;
    }

    const dashedLine = "5, 3";

    const coord = useMemo(() => {
        const aDayInMsec = 24 * 3600 * 1000;
        const gradientHigh = predictions?.high.regression.gradient;
        const gradientLow = predictions?.low?.regression.gradient;
        const extrapolationMsec = aDayInMsec * 3;
        const extrapolationDx = extrapolationMsec / timeData.xMsecRatio;
        const yStartHigh = predictions?.high.regression.startY;
        const yStartLow = predictions?.low?.regression.startY;

        const coordsWithMeasure = getCoordsWithMeasure();
        // purple solid line
        const coordsRegression = getRegressionCoords();
        // blue dashed line (extension of purple solid line)
        const coordsExtrapolation = getExtrapolationCoords();

        function getCoordsWithMeasure() {
            const coordsHaveMeasure: [Node?] = [];

            coords.forEach(({ msec, x, yHigh, yLow }) => {
                if (yHigh) coordsHaveMeasure.push({ msec, x, yHigh, yLow });
            });

            return {
                all: { ...coordsHaveMeasure },
                first: { ...coordsHaveMeasure[0] },
                last: { ...coordsHaveMeasure[coordsHaveMeasure.length - 1] },
            };
        }

        function getRegressionCoords(): {
            start: Node;
            end: Node;
            deltaMsec: number;
        } {
            const midnightOfLastMeasure =
                coordsWithMeasure.last.msec && timeData?.timeCoords.length
                    ? timeData.timeCoords.find(
                          ({ msec }) =>
                              msec >= (coordsWithMeasure.last.msec as number)
                      )
                    : undefined;

            const deltaMsecRegression =
                midnightOfLastMeasure?.msec && predictions?.startMsec
                    ? midnightOfLastMeasure.msec - predictions.startMsec
                    : 0;

            return {
                deltaMsec: deltaMsecRegression,
                start: {
                    msec: predictions?.startMsec,
                    x: predictions?.startX
                        ? predictions.startX
                        : coordsWithMeasure.first.x,
                    yHigh:
                        yStartHigh && paddings && slot
                            ? normalizeCoords({
                                  curr: yStartHigh,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                    yLow:
                        yStartLow && paddings && slot
                            ? normalizeCoords({
                                  curr: yStartLow,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                },
                end: {
                    x: midnightOfLastMeasure?.x,
                    yHigh:
                        gradientHigh && yStartHigh && paddings && slot
                            ? normalizeCoords({
                                  curr:
                                      yStartHigh +
                                      deltaMsecRegression * gradientHigh,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                    yLow:
                        gradientLow && yStartLow && paddings && slot
                            ? normalizeCoords({
                                  curr:
                                      yStartLow +
                                      deltaMsecRegression * gradientLow,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                },
            };
        }

        function getExtrapolationCoords() {
            return {
                start: {
                    ...coordsRegression.end,
                },
                end: {
                    x:
                        (coordsRegression.end.x ??
                            aDayInMsec * 7 * timeData.xMsecRatio) +
                        extrapolationDx,
                    yHigh:
                        yStartHigh && gradientHigh && paddings && slot
                            ? normalizeCoords({
                                  curr:
                                      yStartHigh +
                                      (coordsRegression.deltaMsec +
                                          extrapolationMsec) *
                                          gradientHigh,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                    yLow:
                        yStartLow && gradientLow && paddings && slot
                            ? normalizeCoords({
                                  curr:
                                      yStartLow +
                                      (coordsRegression.deltaMsec +
                                          extrapolationMsec) *
                                          gradientLow,
                                  min: yMin,
                                  delta: yMax - yMin,
                                  padding: (paddings.y / slot.y) * 100,
                                  slot: slot.y,
                              }) ?? 0
                            : undefined,
                },
            };
        }

        function getSpreadCoords() {
            const gradientPxHigh =
                coordsExtrapolation.end.yHigh &&
                coordsExtrapolation.start.yHigh &&
                coordsExtrapolation.start.x
                    ? (coordsExtrapolation.end.yHigh -
                          coordsExtrapolation.start.yHigh) /
                      (coordsExtrapolation.end.x - coordsExtrapolation.start.x)
                    : 0;

            const gradientPxLow =
                coordsExtrapolation.end.yLow &&
                coordsExtrapolation.start.yLow &&
                coordsExtrapolation.start.x
                    ? (coordsExtrapolation.end.yLow -
                          coordsExtrapolation.start.yLow) /
                      (coordsExtrapolation.end.x - coordsExtrapolation.start.x)
                    : 0;

            const halfDegInnerHigh = predictions?.high.spread.halfDegInner ?? 0;
            const halfDegOuterHigh = predictions?.high.spread.halfDegOuter ?? 0;
            const halfDegInnerLow = predictions?.low?.spread.halfDegInner ?? 0;
            const halfDegOuterLow = predictions?.low?.spread.halfDegOuter ?? 0;

            const radPerDeg = Math.PI / 180;

            const degPredictionHigh =
                gradientPxHigh && gradientPxHigh > -1
                    ? Math.atan(gradientPxHigh) / radPerDeg
                    : 0;

            const degPredictionLow =
                gradientPxLow && gradientPxLow > -1
                    ? Math.atan(gradientPxLow) / radPerDeg
                    : 0;

            // ######################################

            const degSpreadHighOuterTop = Math.min(
                degPredictionHigh + halfDegOuterHigh,
                90
            );
            const degSpreadHighInnerTop = Math.min(
                degPredictionHigh + halfDegInnerHigh,
                90
            );
            const degSpreadHighInnerBottom = Math.max(
                degPredictionHigh - halfDegInnerHigh,
                -90
            );
            const degSpreadHighOuterBottom = Math.max(
                degPredictionHigh - halfDegOuterHigh,
                -90
            );

            // ######################################

            const degSpreadLowOuterTop = Math.min(
                degPredictionLow + halfDegOuterLow,
                90
            );
            const degSpreadLowInnerTop = Math.min(
                degPredictionLow + halfDegInnerLow,
                90
            );
            const degSpreadLowInnerBottom = Math.max(
                degPredictionLow - halfDegInnerLow,
                -90
            );
            const degSpreadLowOuterBottom = Math.max(
                degPredictionLow - halfDegOuterLow,
                -90
            );

            // ######################################

            const dySpreadHighOuterTop =
                Math.tan(degSpreadHighOuterTop * radPerDeg) * extrapolationDx;

            const dySpreadHighInnerTop =
                Math.tan(degSpreadHighInnerTop * radPerDeg) * extrapolationDx;

            const dySpreadHighInnerBottom =
                Math.tan(degSpreadHighInnerBottom * radPerDeg) *
                extrapolationDx;

            const dySpreadHighOuterBottom =
                Math.tan(degSpreadHighOuterBottom * radPerDeg) *
                extrapolationDx;

            // ######################################

            const dySpreadLowOuterTop =
                Math.tan(degSpreadLowOuterTop * radPerDeg) * extrapolationDx;

            const dySpreadLowInnerTop =
                Math.tan(degSpreadLowInnerTop * radPerDeg) * extrapolationDx;

            const dySpreadLowInnerBottom =
                Math.tan(degSpreadLowInnerBottom * radPerDeg) * extrapolationDx;

            const dySpreadLowOuterBottom =
                Math.tan(degSpreadLowOuterBottom * radPerDeg) * extrapolationDx;

            // ######################################

            const yBaseHigh =
                coordsExtrapolation.start.yHigh ?? limits?.yMin ?? 0;
            const yBaseLow =
                coordsExtrapolation.start.yLow ?? limits?.yMin ?? 0;

            return {
                start: {
                    ...coordsRegression.end,
                },
                end: {
                    x: coordsExtrapolation.end.x,

                    yHighOuterTop: dySpreadHighOuterTop + yBaseHigh,
                    yHighInnerTop: dySpreadHighInnerTop + yBaseHigh,
                    yHighInnerBottom: dySpreadHighInnerBottom + yBaseHigh,
                    yHighOuterBottom: dySpreadHighOuterBottom + yBaseHigh,

                    yLowOuterTop: dySpreadLowOuterTop + yBaseLow,
                    yLowInnerTop: dySpreadLowInnerTop + yBaseLow,
                    yLowInnerBottom: dySpreadLowInnerBottom + yBaseLow,
                    yLowOuterBottom: dySpreadLowOuterBottom + yBaseLow,
                },
            };
        }

        return {
            measures: coordsWithMeasure,
            regression: coordsRegression,
            extrapolation: coordsExtrapolation,
            spread: getSpreadCoords(),
        };
    }, [predictions, timeData, coords, limits, paddings, yMax, yMin, slot]);

    const Spread = ({
        y = "High",
        shell = "Inner",
    }: {
        y: "High" | "Low";
        shell: "Inner" | "Outer";
    }) => {
        const { start, end } = coord.spread;
        const color = shell === "Inner" ? colors.blue300 : colors.blue100;
        const positions: ["Top", "Bottom"] = ["Top", "Bottom"];

        return end[`y${y}${shell}Top`] &&
            end[`y${y}${shell}Bottom`] &&
            start.x &&
            start[`y${y}`] ? (
            <>
                <Polygon
                    fill={`rgba(${colors.blue400Rgb}, 0.1)`}
                    points={`${start.x},${start[`y${y}`] ?? 0} ${end.x},${
                        end[`y${y}${shell}Top`]
                    } ${end.x},${end[`y${y}${shell}Bottom`]} ${start.x},${
                        start[`y${y}`] ?? 0
                    }`}
                />
                {positions.map((position, i) => (
                    <Fragment
                        key={`prediction-${randomIdFragment}-${i}-${shell}-${y}-${position}`}
                    >
                        <Line
                            fill="none"
                            stroke={color}
                            strokeWidth="2"
                            strokeDasharray={dashedLine}
                            x1={start.x}
                            y1={start[`y${y}`]}
                            x2={end.x}
                            y2={end[`y${y}${shell}${position ?? "Top"}`]}
                        />

                        <Circle
                            fill={colors.white}
                            strokeWidth="2"
                            stroke={color}
                            cx={end.x}
                            cy={end[`y${y}${shell}${position}`]}
                            r="4"
                        />
                    </Fragment>
                ))}
            </>
        ) : (
            <></>
        );
    };

    // purple solid line
    const Regression = ({ y }: { y: "yHigh" | "yLow" }) => {
        const { start, end } = coord.regression;

        return start.x && start[y] && end.x && end[y] ? (
            <Line
                fill="none"
                stroke={colors.violet500}
                strokeWidth="2"
                x1={start.x}
                y1={start[y]}
                x2={end.x}
                y2={end[y]}
            />
        ) : (
            <></>
        );
    };

    // blue dashed line (extension of purple solid line)
    const Extrapolation = ({ y }: { y: "yHigh" | "yLow" }) => {
        const { start, end } = coord.extrapolation;

        return start.x && start[y] && end.x && end[y] ? (
            <>
                <Line
                    fill="none"
                    stroke={colors.blue400}
                    strokeWidth="2"
                    strokeDasharray={dashedLine}
                    x1={start.x}
                    y1={start[y]}
                    x2={end.x}
                    y2={end[y]}
                />

                <Circle
                    fill={colors.white}
                    strokeWidth="2"
                    stroke={colors.blue400}
                    cx={end.x}
                    cy={end[y]}
                    r="4"
                />
            </>
        ) : (
            <></>
        );
    };

    // black vertical line
    const Separator = () => (
        <Line
            fill="none"
            stroke={colors.black}
            strokeWidth="2"
            x1={coord.regression.end.x}
            y1={limits?.yMin}
            x2={coord.regression.end.x}
            y2={limits?.yMax}
        />
    );

    return (
        <>
            <Separator />

            <Spread y="High" shell="Outer" />
            <Spread y="High" shell="Inner" />
            <Regression y="yHigh" />
            <Extrapolation y="yHigh" />

            {type === "line-paired" ? (
                <>
                    <Spread y="Low" shell="Outer" />
                    <Spread y="Low" shell="Inner" />
                    <Regression y="yLow" />
                    <Extrapolation y="yLow" />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
