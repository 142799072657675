import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { MetricType, Risk } from "@madmedical/medical";
import type { DateRange, Ulid } from "@madmedical/utils";
import type { Pagination } from "@madmedical/store";
import { useEnsureContext } from "@madmedical/utils";

export interface Trend {
    slope: number;
    intercept: number;
    averageDisplay: string;
    currentDisplay: string;
    metricType: MetricType;
}

export interface Prediction {
    id: Ulid;
    dateRange: DateRange;
    extendedRange: DateRange;
    risks: Risk[];
    trends: Trend[];
}

interface ContextValue {
    predictions: Prediction[];
    pagination: Pagination;
}

const PredictionContext = createContext<ContextValue | undefined>(undefined);

export const PredictionProvider =
    PredictionContext.Provider as ReactProvider<ContextValue>;

export const usePredictions = () => useEnsureContext(PredictionContext);
