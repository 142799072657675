import { Box } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { usePreOp } from "../../providers/preop";
import Alert from "../../molecules/Alert";
import { useRouteNavigate } from "../../providers/routing";
import { useCurrentPatient } from "../../providers/currentPatient";

const PreOpAlert = () => {
    const { questionnaire } = usePreOp();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();

    const { patient } = useCurrentPatient();

    const services = (patient?.services ?? []) as string[];

    if (questionnaire) {
        // Already completed
        return null;
    }

    if (patient && !services.includes("pre_post_op")) {
        return null;
    }

    return (
        <Box mb={3}>
            <Alert
                title={t("dashboard:preopFill")}
                description={t("dashboard:preopDescription")}
                links={[
                    {
                        text: t("fill"),
                        onPress: () => {
                            navigate("preop", {});
                        },
                    },
                ]}
            />
        </Box>
    );
};

export default PreOpAlert;
