import { useEffect, useState } from "react";
import type { Jsonable } from "./storage";
import { getStorageValue, setStorageValue } from "./storage";

export default <S extends Jsonable>(key: string, initialState: S) => {
    const [isLoading, setLoading] = useState(true);
    const [value, setValue] = useState<S>(initialState);

    // Read from storage
    useEffect(() => {
        setLoading(true);

        void (async () => {
            const stored = await getStorageValue<S>(key);
            if (stored !== null) {
                setValue(stored);
            }

            setLoading(false);
        })();
    }, [key]);

    // Write to storage
    useEffect(() => {
        if (isLoading) {
            // Do not overwrite until read
            return;
        }

        void setStorageValue(key, value);
    }, [isLoading, key, value]);

    return [value, setValue, { isLoading }] as const;
};
