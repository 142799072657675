import { Circle, G } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import { coordIdFactory } from "./utils";
import type { GraphData, TooltipType } from "./types";
import { colors } from "./useStyles";

export default function ManualMeasures({
    graphData = {},
    tooltip = {},
}: {
    graphData?: GraphData;
    tooltip?: TooltipType;
}) {
    const {
        manualMeasures: coords = [],
        randomIdFragment = "",
        type = "",
    } = graphData;

    return (
        <>
            {coords.length > 0 &&
                coords.map(({ x, yHigh, yLow }, i) => {
                    const idHigh = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    const idLow = coordIdFactory({
                        randomIdFragment,
                        coord: coords[i],
                    });

                    return isWeb ? (
                        <G key={idHigh + idLow}>
                            {yHigh && (
                                <Circle
                                    fill={colors.white}
                                    strokeWidth="2"
                                    stroke={
                                        tooltip.onShow && tooltip.id === idHigh
                                            ? colors.grey800
                                            : colors.grey400
                                    }
                                    id={idHigh}
                                    cx={x}
                                    cy={yHigh}
                                    r="4"
                                />
                            )}

                            {type === "line-paired" && yLow && (
                                <Circle
                                    fill={colors.white}
                                    strokeWidth="2"
                                    stroke={
                                        tooltip.onShow && tooltip.id === idLow
                                            ? colors.grey800
                                            : colors.grey400
                                    }
                                    id={idLow}
                                    cx={x}
                                    cy={yLow}
                                    r="4"
                                />
                            )}
                        </G>
                    ) : (
                        <G scaleY={-1} key={idHigh + idLow}>
                            {yHigh && (
                                <Circle
                                    fill={colors.white}
                                    strokeWidth="2"
                                    stroke={
                                        tooltip.onShow && tooltip.id === idHigh
                                            ? colors.grey800
                                            : colors.grey400
                                    }
                                    id={idHigh}
                                    cx={x}
                                    cy={yHigh}
                                    r="4"
                                />
                            )}

                            {type === "line-paired" && yLow && (
                                <Circle
                                    fill={colors.white}
                                    strokeWidth="2"
                                    stroke={
                                        tooltip.onShow && tooltip.id === idLow
                                            ? colors.grey800
                                            : colors.grey400
                                    }
                                    id={idLow}
                                    cx={x}
                                    cy={yLow}
                                    r="4"
                                />
                            )}
                        </G>
                    );
                })}
        </>
    );
}
