import { VStack } from "native-base";
import type { PreOpQuestionKey } from "@madmedical/medical";
import { useCallback } from "react";
import FormSelect from "../../../form/FormSelect";
import PreOpSurveyFormRow from "../../../molecules/PreOpSurveyFormRow";
import FormControl from "../../../form/FormControl";
import DateInput from "../../../form/DateInput";
import PreOpWrapper from "./PreOpWrapper";

interface Props {
    onPreviousPress?: () => void;
    onNextPress: () => void;
}

const Survey3 = ({ onPreviousPress, onNextPress }: Props) => {
    const validate = useCallback(
        ({
            answers,
            subAnswers,
        }: {
            answers?: Record<PreOpQuestionKey, boolean>;
            subAnswers?: {
                hadSurgery?: [string | undefined];
                anesthesiaComplications?: [string | undefined];
                feverInfection?: [string | undefined];
                pregnant?: [string | undefined];
                covidVaccine?: [string | undefined, string | undefined];
                hadCovid?: [string | undefined];
            };
        }) =>
            (!answers?.hadSurgery ||
                (subAnswers?.hadSurgery?.[0] ?? "").length > 3) &&
            (!answers?.anesthesiaComplications ||
                (subAnswers?.anesthesiaComplications?.[0] ?? "").length > 3) &&
            (!answers?.feverInfection ||
                (subAnswers?.feverInfection?.[0] ?? "").length > 3) &&
            (!answers?.pregnant ||
                (subAnswers?.pregnant?.[0] ?? "").length > 0) &&
            (!answers?.covidVaccine ||
                (subAnswers?.covidVaccine?.[1]?.toString() ?? "").length > 3) &&
            (!answers?.hadCovid ||
                (subAnswers?.hadCovid?.[0]?.toString() ?? "").length > 3),
        []
    );

    return (
        <PreOpWrapper
            onPreviousPress={onPreviousPress}
            onNextPress={onNextPress}
            validate={validate}
            width="full"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <VStack>
                <PreOpSurveyFormRow name="receivedBlood" />
                <PreOpSurveyFormRow name="bloodComplications" />
                <PreOpSurveyFormRow
                    name="hadSurgery"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.hadSurgery.0"
                            size="md"
                            placeholder="Kérjük részletezze."
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow name="anesthesia" />
                <PreOpSurveyFormRow
                    name="anesthesiaComplications"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.anesthesiaComplications.0"
                            size="md"
                            placeholder="Kérjük részletezze."
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow name="cold" />
                <PreOpSurveyFormRow
                    name="feverInfection"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.feverInfection.0"
                            size="md"
                            placeholder="Kérjük részletezze."
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow name="antibiotics" />
                <PreOpSurveyFormRow
                    name="pregnant"
                    optionalAnswerInput={
                        <FormControl
                            name="subAnswers.pregnant.0"
                            size="md"
                            placeholder="Hány hetes terhes?"
                            isRequired
                        />
                    }
                />
                <PreOpSurveyFormRow
                    name="covidVaccine"
                    optionalAnswerInput={[
                        <FormSelect
                            name="subAnswers.covidVaccine.0"
                            key="hanyOltast"
                            choices={Array.from(Array(5).keys()).map(
                                (item) => ({
                                    value: `${item}`,
                                    label: `${item}`,
                                })
                            )}
                            size="md"
                            placeholder="Hány oltást kapott?"
                            mb={2}
                        />,
                        <DateInput
                            name="subAnswers.covidVaccine.1"
                            key="utolsoOltas"
                            label="Mikor kapta az utolsó oltást?"
                            isRequired
                            isDefaultToday={false}
                        />,
                    ]}
                />
                <PreOpSurveyFormRow
                    name="hadCovid"
                    optionalAnswerInput={[
                        <DateInput
                            name="subAnswers.hadCovid.0"
                            key="utolsoOltas"
                            label="Mikor?"
                            isRequired
                            isDefaultToday={false}
                        />,
                    ]}
                    hasDivider={false}
                />
            </VStack>
        </PreOpWrapper>
    );
};

export default Survey3;
