import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    AggregatedMeasurementFilter,
    AggregatedMeasurementRequest,
    AggregatedMeasurements,
    CreateManualMeasurementRequest,
    Measurement,
    MeasurementListFilter,
    MeasurementRangeFilter,
    MeasurementRangeResponse,
    UserIdRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: ["measurements"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeasurementList: build.query<
                Paginated<Measurement>,
                UserIdRequest & MeasurementListFilter & PageQuery
            >({
                query: ({ userId, ...params }) => ({
                    url: `collect/users/${userId}/measurements/list`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["measurements"],
            }),
            getMeasurementRange: build.query<
                MeasurementRangeResponse,
                UserIdRequest & MeasurementRangeFilter
            >({
                query: ({ userId, ...params }) => ({
                    url: `collect/users/${userId}/measurements/range`,
                    params,
                }),
                providesTags: ["measurements"],
            }),
            getAggregatedMeasurements: build.query<
                Paginated<AggregatedMeasurements>,
                AggregatedMeasurementRequest &
                    AggregatedMeasurementFilter &
                    PageQuery
            >({
                query: ({ byPeriod, ...params }) => ({
                    url: `collect/measurements/aggregated/${byPeriod}`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["measurements"],
            }),
            createManual: build.mutation<
                Measurement[],
                UserIdRequest & CreateManualMeasurementRequest
            >({
                query: ({ userId, ...body }) => ({
                    url: `collect/users/${userId}/measurements`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["measurements"],
            }),
            deleteMeasurement: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `collect/measurements/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["measurements"],
            }),
        }),
    });

export const {
    useGetMeasurementListQuery,
    useGetMeasurementRangeQuery,
    useCreateManualMutation,
    useGetAggregatedMeasurementsQuery,
    useDeleteMeasurementMutation,
} = api;
