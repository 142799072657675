import type { ReactNode } from "react";
import styled from "styled-components";
import breakpoints from "../theme/base/breakpoints";

interface Props {
    children: ReactNode;
}

const Grid = styled.section`
    display: grid;
    grid-auto-flow: row dense;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px 8px;
    width: 100%;
    margin-bottom: 12px;
    & > * {
        margin-bottom: 0;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "main main"
            "main main";
    }
`;

const DashboardWidgetsWrapper = ({ children }: Props) => (
    <Grid>{children}</Grid>
);

export default DashboardWidgetsWrapper;
