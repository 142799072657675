import { CallCenterDeviceModalForm, Modal } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { DeviceOperation } from "@madmedical/medical";
import type { PatientForCallCenter } from "@madmedical/user";
import {
    adaptPatient,
    useGetInstitutionsQuery,
    useGetPatientsQuery,
} from "@madmedical/user";
import { useCreateDeviceMutation, useGetDeviceTypesQuery } from "./api";
import type { CreateDeviceRequest } from "./model";

interface Props {
    open: boolean;
    onClose: () => void;
}

const CreateDeviceForm = ({ open, onClose }: Props) => {
    const [createDevice, { isLoading: isSubmitting }] =
        useCreateDeviceMutation();
    const { data: deviceTypes } = useGetDeviceTypesQuery();
    const { data } = useGetPatientsQuery({ pageSize: 5000 }); // TODO: Autocomplete
    const { data: institutions } = useGetInstitutionsQuery();

    return (
        <Modal open={open} onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{ operation: DeviceOperation.Create }}
                mutation={(value: CreateDeviceRequest) =>
                    createDevice({
                        ...value,
                        serialNumber: `${value.serialNumber}\u200B`,
                    })
                }
            >
                {({ submitForm, isSuccess }) => (
                    <CallCenterDeviceModalForm
                        operations={[DeviceOperation.Create]}
                        patients={(
                            data?.items as PatientForCallCenter[] | undefined
                        )?.map(adaptPatient)}
                        institutions={institutions}
                        deviceTypes={deviceTypes}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                        isSubmitting={isSubmitting}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default CreateDeviceForm;
