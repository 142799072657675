import type { CellProps, Column } from "react-table";
import { Box } from "native-base";
import { useMemo } from "react";
import { useTranslation } from "@madmedical/i18n";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import Badge from "../atoms/Badge";
import type { RepresentativeData } from "../molecules/ListItem/RepresentativeListItem";

interface Props {
    data: RepresentativeData[];
    onResendPress: (item: RepresentativeData) => void;
    onEditPress: (item: RepresentativeData) => void;
    onDeletePress: (item: RepresentativeData) => void;
}

const RepresentativesTable = ({
    data,
    onResendPress,
    onEditPress,
    onDeletePress,
}: Props) => {
    const { t } = useTranslation();
    const columns = useMemo<Column<RepresentativeData>[]>(
        () => [
            {
                Header: t("nameOrEmail"),
                defaultCanSort: true,
                accessor: "nameOrEmail",
            },
            {
                Header: t("comment"),
                defaultCanSort: true,
                accessor: "note",
                Cell: (props: CellProps<RepresentativeData>) => {
                    const { note } = props.row.original;

                    return <>{note ?? "–"}</>;
                },
                disableSortBy: true,
            },
            {
                Header: t("state"),
                defaultCanSort: true,
                accessor: "status",
                Cell: (props: CellProps<RepresentativeData>) => {
                    const { status } = props.row.original;

                    return (
                        <Badge
                            size="sm"
                            variant={`ghost${status ? "Success" : "Warning"}`}
                        >
                            {status ? t("active") : t("invited")}
                        </Badge>
                    );
                },
            },
            {
                Header: "",
                id: "action",
                Cell: (props: CellProps<RepresentativeData>) => {
                    const item = props.row.original;

                    return (
                        <Box
                            flexDirection="row"
                            justifyContent="flex-end"
                            w="full"
                        >
                            <ActionButton
                                actions={[
                                    ...(!item.status
                                        ? ([
                                              {
                                                  text: t("resendEmail"),
                                                  iconName: "mailAll",
                                                  onPress: () => {
                                                      onResendPress(item);
                                                  },
                                              },
                                          ] as const)
                                        : []),
                                    {
                                        text: t("edit"),
                                        iconName: "edit",
                                        onPress: () => {
                                            onEditPress(item);
                                        },
                                    },
                                    {
                                        text: t("delete"),
                                        iconName: "trashCan",
                                        onPress: () => {
                                            onDeletePress(item);
                                        },
                                    },
                                ]}
                            />
                        </Box>
                    );
                },
            },
        ],
        [onDeletePress, onEditPress, onResendPress, t]
    );

    return <ReactTable columns={columns} data={data} />;
};

export default RepresentativesTable;
