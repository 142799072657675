import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import {
    useDeleteRepresentativeMutation,
    useGetRepresentativesQuery,
} from "../api";

interface Props {
    userId: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const DeleteRepresentativeDialog = ({ userId, open, onClose }: Props) => {
    const { representative } = useGetRepresentativesQuery(undefined, {
        selectFromResult: ({ data: representatives }) => ({
            representative: representatives?.find(
                (representative) => representative.userId === userId
            ),
        }),
    });
    const [deleteRepresentative, { isLoading: isSubmitting }] =
        useDeleteRepresentativeMutation();
    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!userId || !representative) {
        return null;
    }

    const handleConfirm = () => {
        void deleteRepresentative(userId)
            .unwrap()
            .then(() => {
                showToast(t("authorizationDeleted"), { variant: "success" });
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            onClose={onClose}
            isSubmitting={isSubmitting}
            header={representative.fullName}
        >
            <Text>{t("sureToDeleteAuthorization")}</Text>
        </Dialog>
    );
};

export default DeleteRepresentativeDialog;
