import type { Ulid } from "@madmedical/utils";
import type { PreOpQuestionKey } from "@madmedical/medical";
import { createContext } from "react";
import type { Provider as ReactProvider } from "react";
import { useEnsureContext } from "@madmedical/utils";

export interface PreOpQuestionnaire {
    id: Ulid;
    createdAt: Date;
    operation: string;
    answers: Partial<Record<PreOpQuestionKey, boolean>>;
    subAnswers: Partial<Record<PreOpQuestionKey, (string | number)[]>>;
    badAnswersCount: number;
}

interface ContextValue {
    questionnaire: PreOpQuestionnaire | null;
}

const PreOpContext = createContext<ContextValue | undefined>(undefined);

export const PreOpProvider =
    PreOpContext.Provider as ReactProvider<ContextValue>;

export const usePreOp = () => useEnsureContext(PreOpContext);
