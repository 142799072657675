import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    BindDeviceRequest,
    BoundDeviceResponse,
    ConnectRequest,
    ConnectResponse,
    DeviceResponse,
    DisconnectRequest,
    RawDataResponse,
    SubmitRawDataRequest,
    UnbindDeviceRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: ["devices"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getDevices: build.query<DeviceResponse[], Ulid>({
                query: (userId) => ({
                    url: `collect/users/${userId}/devices`,
                    method: "GET",
                }),
                providesTags: ["devices"],
            }),
            connectOauth: build.mutation<ConnectResponse, ConnectRequest>({
                query: ({ provider, ...body }) => ({
                    url: `oauth/connect/${provider}`,
                    method: "POST",
                    body,
                }),
            }),
            disconnectOauth: build.mutation<void, DisconnectRequest>({
                query: ({ provider }) => ({
                    url: `oauth/disconnect/${provider}`,
                    method: "POST",
                }),
                invalidatesTags: ["devices"],
            }),
            bindDevice: build.mutation<BoundDeviceResponse, BindDeviceRequest>({
                query: (body) => ({
                    url: "collect/devices",
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["devices"],
            }),
            unbindDevice: build.mutation<void, UnbindDeviceRequest>({
                query: ({ provider }) => ({
                    url: `collect/devices/${provider}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["devices"],
            }),
            submitRawData: build.mutation<
                RawDataResponse,
                SubmitRawDataRequest
            >({
                query: (body) => ({
                    url: "collect/rawdata",
                    method: "POST",
                    body,
                }),
            }),
        }),
    });

export const {
    useGetDevicesQuery,
    useConnectOauthMutation,
    useDisconnectOauthMutation,
    useBindDeviceMutation,
    useUnbindDeviceMutation,
    useSubmitRawDataMutation,
} = api;
