import { SectionList } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import type { Ulid } from "@madmedical/utils";
import ListHeader from "../../molecules/ListHeader";
import InsulinMeasurementListItem from "../../molecules/ListItem/InsulinMeasurementListItem";
import type { InsulinAdministration } from "../../providers/insulinAdministration";
import type { SectionType } from "./InsulinMeasurementList";

interface Props {
    sections: SectionType[];
    onEndReached?: () => void | undefined;
    onDeletePress?: (id: Ulid) => void | undefined;
    onEditPress?: (measurement: InsulinAdministration) => void | undefined;
}

const InsulinMeasurementContentMobile = ({
    sections,
    onDeletePress,
    onEditPress,
    onEndReached,
}: Props) => {
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;

    return (
        <SectionList
            onLayout={(event) =>
                flatListPosition === 0
                    ? setFlatListPosition(event.nativeEvent.layout.y)
                    : null
            }
            scrollEventThrottle={16}
            onScroll={(event) => {
                const { contentOffset, layoutMeasurement, contentSize } =
                    event.nativeEvent;
                const paddingToBottom = 20;
                if (
                    layoutMeasurement.height + contentOffset.y >=
                    contentSize.height - paddingToBottom
                ) {
                    onEndReached?.();
                }
            }}
            maxHeight={screenHeight - flatListPosition - 4}
            width="full"
            minWidth="full"
            showsVerticalScrollIndicator={false}
            sections={sections}
            renderSectionHeader={({ section: { header } }) => {
                if (!header) return null;
                const { title, badge } = header;

                return <ListHeader title={title} badge={badge} />;
            }}
            renderItem={({ item }) => (
                <InsulinMeasurementListItem
                    {...item}
                    onDeletePress={onDeletePress}
                    onEditPress={onEditPress}
                />
            )}
            keyExtractor={(item, index) => `section-list-item-${index}`}
            stickySectionHeadersEnabled
        />
    );
};

export default InsulinMeasurementContentMobile;
