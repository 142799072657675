import { rangeToDate } from "@madmedical/utils";
import type { RootState } from "../store";

export const dateRangeSelector = (state: RootState) =>
    rangeToDate(state.settings.dateRange);

export const insulinRangeSelector = (state: RootState) =>
    rangeToDate(state.settings.insulinRange);

export const bodyDateRangeSelector = (state: RootState) =>
    rangeToDate(state.settings.bodyDateRange);

export const portioningDateRangeSelector = (state: RootState) =>
    rangeToDate(state.settings.portioningDateRange);
