import { Fragment, useEffect, useRef, useState } from "react";
import { Path, Svg } from "react-native-svg";
import TooltipDragSelect from "./TooltipDragSelect";
import { boxShadow, colors } from "./useStyles";
import type {
    EvaluationBgHighlight,
    GraphData,
    Styles,
    TimeData,
} from "./types";

interface Props {
    bgHighlight: EvaluationBgHighlight;
    setBgHighlight: (props: EvaluationBgHighlight) => void;
    graphData?: GraphData;
    timeData: TimeData;
    offsetX: number;
}

export default function DragSelect({
    // backend also needs the direction of select (left to right or opposite)
    bgHighlight,
    setBgHighlight,
    graphData = {},
    timeData,
    offsetX,
}: Props) {
    const { timeCoords = [] } = timeData;
    const { slot = { x: 0, y: 0 } } = graphData;

    // ###############################

    const [hasMouse, setHasMouse] = useState(true);

    useEffect(() => {
        setHasMouse(window.matchMedia("(any-pointer: fine)").matches);
    }, []);

    // ###############################

    const dragSelectStartIndex = useRef<number | null>(null);

    const [hovered, setHovered] = useState<number | null>(null);

    const [dragger, setDragger] = useState<{
        left: number | null;
        width: number | null;
    }>({
        left: bgHighlight.start ? timeCoords[bgHighlight.start].x : null,
        width:
            bgHighlight.start && bgHighlight.end
                ? timeCoords[bgHighlight.end].x -
                  timeCoords[bgHighlight.start].x
                : null,
    });

    // ###############################

    const stylesSelector: Styles = {
        borderLeftWidth: "1px",
        zIndex: "1",
        top: "0",
        bottom: "0",
    };

    const stylesPlusSign: Styles = {
        pointerEvents: "none",
        userSelect: "none",
    };

    const stylesPlusSignLast: Styles = {
        borderRightWidth: "1px",
    };

    const stylesBorderWidth1: Styles = {
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
    };

    const stylesBorderWidth0: Styles = {
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
    };

    const stylesCommon: Styles = {
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        top: "8px",
        bottom: "8px",
        fontSize: "14px",
        lineHeight: "24px",
        borderStyle: "solid",
        borderColor: colors.grey150,
        color: colors.grey400,
        ...stylesBorderWidth0,
    };

    const stylesDragger: Styles = {
        position: "absolute",
        display: "flex",
        pointerEvents: "none",
        top: "8px",
        bottom: "8px",
        justifyContent: "space-between",
        borderRadius: "4px",
        paddingLeft: "6px",
        paddingRight: "6px",
        ...stylesBorderWidth0,
    };

    const stylesDraggerDynamic: Styles =
        bgHighlight.start !== null
            ? {
                  backgroundColor: colors.green25,
                  borderColor: colors.green600,
                  borderStyle: "solid",
                  color: colors.green700,
                  ...stylesBorderWidth1,
                  zIndex: "1",
              }
            : hovered !== null
            ? {
                  backgroundColor: colors.grey25,
                  borderStyle: "solid",
                  borderColor: colors.grey100,
                  ...stylesBorderWidth1,
              }
            : {};

    const stylesDraggerDecorationConstant: Styles = {
        width: "4px",
        borderStyle: "solid",
        borderColor: colors.green600,
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        marginTop: "3px",
        marginBottom: "3px",
    };
    const stylesDraggerDecorationDynamic: Styles =
        bgHighlight.start !== null
            ? {
                  borderColor: colors.green600,
              }
            : hovered !== null
            ? {
                  borderColor: colors.grey100,
              }
            : { borderColor: colors.transparent };
    const stylesDraggerIcon: Styles = {
        display: "flex",
        alignItems: "center",
    };

    const stylesContainer: Styles = {
        position: "relative",
        height: "40px",
        borderBottomWidth: "1px",
        borderBottomColor: colors.grey150,
        borderBottomStyle: "solid",
    };

    // ###############################

    return (
        <div style={stylesContainer}>
            {timeCoords.map(
                ({ x }, i) =>
                    i < timeCoords.length - 1 && (
                        <Fragment key={`drag-select-gridlines-${i}`}>
                            {/* "+" signs need to have a separate HTML tag to make
                            selector work */}
                            <div
                                style={
                                    i === timeCoords.length - 2
                                        ? {
                                              ...stylesCommon,
                                              ...stylesPlusSign,
                                              ...stylesPlusSignLast,
                                              left: `${x}px`,
                                              width: `${
                                                  i === timeCoords.length - 1
                                                      ? 0
                                                      : timeCoords[i + 1].x - x
                                              }px`,
                                          }
                                        : {
                                              ...stylesCommon,
                                              ...stylesPlusSign,
                                              left: `${x}px`,
                                              width: `${
                                                  i === timeCoords.length - 1
                                                      ? 0
                                                      : timeCoords[i + 1].x - x
                                              }px`,
                                          }
                                }
                            >
                                {bgHighlight.start === null ? "+" : ""}
                            </div>

                            {/* invisible selector */}
                            {hasMouse ? (
                                <div
                                    id={`drag-select-${i}`}
                                    style={{
                                        ...stylesCommon,
                                        ...stylesSelector,
                                        left: `${x}px`,
                                        width: `${
                                            i === timeCoords.length - 1
                                                ? 0
                                                : timeCoords[i + 1].x - x
                                        }px`,
                                    }}
                                    onPointerDown={() => {
                                        if (
                                            dragSelectStartIndex.current !==
                                            null
                                        )
                                            return;

                                        const start = i;
                                        const end = i + 1;

                                        const isSwitchingOff =
                                            start === bgHighlight.start &&
                                            end === bgHighlight.end;

                                        dragSelectStartIndex.current =
                                            isSwitchingOff ? null : i;

                                        // switch forth & back
                                        const newRange = isSwitchingOff
                                            ? {
                                                  ...bgHighlight,
                                                  start: null,
                                                  end: null,
                                              }
                                            : {
                                                  ...bgHighlight,
                                                  start,
                                                  end,
                                              };

                                        setBgHighlight(newRange);

                                        setDragger({
                                            left: timeCoords[i].x,
                                            width:
                                                timeCoords[i + 1].x -
                                                timeCoords[i].x,
                                        });
                                    }}
                                    onPointerEnter={(e) => {
                                        setHovered(i);

                                        if (bgHighlight.start === null) {
                                            setDragger({
                                                left: timeCoords[i].x,
                                                width:
                                                    timeCoords[i + 1].x -
                                                    timeCoords[i].x,
                                            });
                                        }

                                        if (
                                            dragSelectStartIndex.current ===
                                            null
                                        )
                                            return;

                                        const currentPosition = Number(
                                            (
                                                e.target as HTMLDivElement
                                            ).id.match(/\d+/)?.[0]
                                        );

                                        // backend also needs the direction of select (left to right or opposite)
                                        const start =
                                            currentPosition >=
                                            dragSelectStartIndex.current
                                                ? dragSelectStartIndex.current
                                                : dragSelectStartIndex.current +
                                                  1;

                                        const end =
                                            currentPosition >=
                                            dragSelectStartIndex.current
                                                ? currentPosition + 1
                                                : currentPosition;

                                        setBgHighlight({
                                            ...bgHighlight,
                                            start,
                                            end,
                                        });

                                        const left =
                                            timeCoords[
                                                end >= start ? start : end
                                            ].x;

                                        const right =
                                            timeCoords[
                                                end === start
                                                    ? end + 1
                                                    : end > start
                                                    ? end
                                                    : start
                                            ].x;
                                        const width = Math.abs(right - left);
                                        setDragger({ left, width });
                                    }}
                                    onPointerLeave={() => {
                                        setHovered(null);
                                    }}
                                    onPointerUp={() => {
                                        dragSelectStartIndex.current = null;
                                    }}
                                />
                            ) : (
                                // In case of touchscreen:
                                <div
                                    id={`drag-select-${i}`}
                                    style={{
                                        ...stylesCommon,
                                        ...stylesSelector,
                                        left: `${x}px`,
                                        width: `${
                                            i === timeCoords.length - 1
                                                ? 0
                                                : timeCoords[i + 1].x - x
                                        }px`,
                                    }}
                                    onTouchStart={() => {
                                        const start = i;
                                        const end = i + 1;

                                        // switch forth & back
                                        const newRange =
                                            start === bgHighlight.start &&
                                            end === bgHighlight.end
                                                ? {
                                                      ...bgHighlight,
                                                      start: null,
                                                      end: null,
                                                  }
                                                : {
                                                      ...bgHighlight,
                                                      start,
                                                      end,
                                                  };

                                        setBgHighlight(newRange);

                                        setDragger({
                                            left: timeCoords[i].x,
                                            width:
                                                timeCoords[i + 1].x -
                                                timeCoords[i].x,
                                        });
                                    }}
                                    onTouchMove={(e) => {
                                        const $currentPosition =
                                            timeCoords.findIndex(
                                                ({ x }) =>
                                                    e.touches[0].clientX -
                                                        offsetX <=
                                                    x
                                            );

                                        const currentPosition =
                                            $currentPosition === -1
                                                ? timeCoords.length - 2
                                                : Math.max(
                                                      0,
                                                      $currentPosition - 1
                                                  );

                                        const start =
                                            currentPosition >= i ? i : i + 1;

                                        const end =
                                            currentPosition > i
                                                ? currentPosition + 1
                                                : currentPosition;

                                        setBgHighlight({
                                            ...bgHighlight,
                                            start,
                                            end,
                                        });

                                        const left =
                                            timeCoords[
                                                end >= start ? start : end
                                            ].x;

                                        const right =
                                            timeCoords[
                                                end === start
                                                    ? end + 1
                                                    : end > start
                                                    ? end
                                                    : start
                                            ].x;
                                        const width = Math.abs(right - left);
                                        setDragger({ left, width });
                                    }}
                                />
                            )}
                        </Fragment>
                    )
            )}

            {/* visible indicator bar */}
            {dragger?.left && dragger.width ? (
                <div
                    style={{
                        ...stylesDragger,
                        ...stylesDraggerDynamic,
                        left: `${dragger.left + 4}px`,
                        width: `${dragger.width - 22}px`,
                        ...boxShadow,
                    }}
                >
                    <div
                        style={{
                            ...stylesDraggerDecorationConstant,
                            ...stylesDraggerDecorationDynamic,
                        }}
                    />

                    <div style={{ ...stylesDraggerIcon }}>
                        {bgHighlight.start !== null ? (
                            <Svg height="12px" viewBox="0 0 15 16" fill="none">
                                <Path
                                    d="M13.795 6.655L14.5 5.95L9.5 1L8.845 1.71L9.435 2.3L3.69 7.16L2.83 6.305L2.125 7L4.955 9.84L0.5 14.29L1.205 15L5.66 10.545L8.5 13.375L9.195 12.665L8.34 11.81L13.2 6.065L13.795 6.655ZM7.63 11.1L4.4 7.87L10.145 3L12.5 5.355L7.63 11.1Z"
                                    fill={colors.green600}
                                    stroke={colors.green600}
                                    strokeWidth="0.3"
                                />
                            </Svg>
                        ) : hovered !== null ? (
                            "+"
                        ) : (
                            ""
                        )}
                    </div>

                    <div
                        style={{
                            ...stylesDraggerDecorationConstant,
                            ...stylesDraggerDecorationDynamic,
                        }}
                    />
                </div>
            ) : (
                <></>
            )}

            <TooltipDragSelect
                tooltip={{
                    onShow: true,
                    left: slot.x / 2,
                }}
                transform={[{ translateX: -127 }, { translateY: -53 }]}
            />
        </div>
    );
}
