import { SectionList } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import InsulinMeasurementListItem from "../../molecules/ListItem/InsulinMeasurementListItem";
import type { MeasurementData } from "./InsulinMeasurementList";

interface Props {
    sections: MeasurementData[];
}

const DashboardInsulinMeasurementContentMobile = ({ sections }: Props) => {
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;

    return (
        <SectionList
            onLayout={(event) => {
                setFlatListPosition(event.nativeEvent.layout.y);
            }}
            maxHeight={screenHeight - flatListPosition - 4}
            width="full"
            minWidth="full"
            showsVerticalScrollIndicator={false}
            sections={[{ data: sections }]}
            renderItem={({ item }) => (
                <InsulinMeasurementListItem
                    {...item}
                    editable={false}
                    onDeletePress={() => console.log("delete")}
                />
            )}
            keyExtractor={(item, index) => `section-list-item-${index}`}
            stickySectionHeadersEnabled
        />
    );
};

export default DashboardInsulinMeasurementContentMobile;
