import type { AppRoutes, RouteId } from "@madmedical/routing";
import { useLocation } from "@madmedical/routing";
import { useCallback } from "react";
import useGeneratePath from "./useGeneratePath";

/**
 * Only active if fully matching
 */
export default () => {
    const { pathname } = useLocation();
    const generatePath = useGeneratePath();

    return useCallback(
        <TRouteId extends RouteId>(
            route: TRouteId,
            params: AppRoutes[TRouteId]
        ) => pathname === generatePath(route, params),
        [generatePath, pathname]
    );
};
