import type { ReactNode } from "react";
import AppleContext from "./AppleContext";

interface Props {
    children: ReactNode;
}

const AppleProvider = ({ children }: Props) => (
    <AppleContext.Provider
        value={{ connect: undefined, disconnect: undefined }}
    >
        {children}
    </AppleContext.Provider>
);

export default AppleProvider;
