import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { DayOfWeek, InsulinType } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";

export type InsulinReminder = {
    id: Ulid;
    daysOfWeek: DayOfWeek[];
    firstOccurrence: Date;
    insulinType: InsulinType;
    comment: string | null;
};

interface InsulinReminderContext {
    reminders: InsulinReminder[];
}

const InsulinReminderContext = createContext<
    InsulinReminderContext | undefined
>(undefined);

export const InsulinReminderProvider =
    InsulinReminderContext.Provider as ReactProvider<InsulinReminderContext>;

export const useInsulinReminder = () =>
    useEnsureContext(InsulinReminderContext);
