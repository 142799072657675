import { useEffect, useMemo } from "react";
import { Pressable } from "react-native";
import { Box, HStack, Text } from "native-base";
import { isWeb } from "@madmedical/utils";
import Icon from "../icons/Icon";
import type {
    CommentGroupType,
    EvaluationBgHighlight,
    GraphData,
    TimeData,
    TooltipType,
} from "./types";
import {
    colors,
    timeGridLinesHorizontalBottom,
    timeGridLinesVerticalSolid,
} from "./useStyles";

export default function Evaluations({
    graphData = {},
    timeData,
    evaluationBgHighlight,
    setEvaluationBgHighlight,
    setCommentState,
    setTooltip,
}: {
    graphData?: GraphData;
    timeData: TimeData;
    evaluationBgHighlight: EvaluationBgHighlight;
    setEvaluationBgHighlight: (
        callback: (state: EvaluationBgHighlight) => EvaluationBgHighlight
    ) => void;
    setCommentState: (
        callback: (state: CommentGroupType) => CommentGroupType
    ) => void;
    setTooltip: (callback: (state: TooltipType) => TooltipType) => void;
}) {
    const { coords: graphCoords = [], evaluations } = graphData;
    const { timeCoords } = timeData;

    interface EvaluationPositions {
        row: number;
        gridLineStart: number;
        gridLineEnd: number;
        xStart: number;
        xEnd: number;
        name: string;
    }

    // ###################################s

    const validEvaluations = useMemo(() => {
        const evaluationPositions: EvaluationPositions[] | undefined =
            evaluations?.reduce<EvaluationPositions[] | undefined>(
                (positions, { xStart, xEnd, name }, i) => {
                    const isValid =
                        !!graphCoords?.length &&
                        xStart &&
                        xStart >= graphCoords[0]?.x &&
                        xStart <= graphCoords[graphCoords.length - 1]?.x &&
                        xEnd &&
                        xEnd >= graphCoords[0]?.x &&
                        xEnd <= graphCoords[graphCoords.length - 1]?.x
                            ? true
                            : false;

                    if (!isValid) {
                        console.log(`Outside time-window: evaluation #${i}`);

                        return;
                    }

                    const round = (i: number) => Math.round(i * 100) / 100;

                    const xStartRounded = round(xStart as number);
                    const xEndRounded = round(xEnd as number);

                    const gridLineStart = Math.max(
                        0,
                        timeCoords.findIndex(
                            ({ x }) => round(x) > xStartRounded - 1
                        )
                    );

                    const gridLineEnd = Math.min(
                        timeCoords.length - 1,
                        timeCoords.findIndex(({ x }) => round(x) >= xEndRounded)
                    );

                    if (
                        i === 0 &&
                        positions &&
                        timeCoords[gridLineEnd]?.x !== undefined
                    ) {
                        positions.push({
                            row: 0,
                            gridLineStart,
                            gridLineEnd,
                            xStart: timeCoords[gridLineStart].x,
                            xEnd: timeCoords[gridLineEnd].x,
                            name,
                        });

                        return positions;
                    }

                    if (i > 0 && positions) {
                        const reservedRows: number[] = [];

                        positions.forEach((evPos) => {
                            if (
                                (gridLineStart >= evPos.gridLineStart &&
                                    gridLineStart < evPos.gridLineEnd) ||
                                (evPos.gridLineStart >= gridLineStart &&
                                    evPos.gridLineStart < gridLineEnd)
                            )
                                reservedRows.push(evPos.row);
                        });

                        const reservedRowsUnique = [...new Set(reservedRows)];

                        const rowIndexWithFreeSlot = reservedRowsUnique
                            .sort((a, b) => a - b)
                            .findIndex((row, i) => row !== i);

                        const row =
                            rowIndexWithFreeSlot === -1
                                ? reservedRowsUnique[
                                      reservedRowsUnique.length - 1
                                  ] + 1 || 0
                                : rowIndexWithFreeSlot;

                        positions.push({
                            row,
                            gridLineStart,
                            gridLineEnd,
                            xStart: timeCoords[gridLineStart].x,
                            xEnd: timeCoords[gridLineEnd].x,
                            name,
                        });

                        return positions;
                    }

                    return positions;
                },
                []
            );

        const rowsMapCallback: ({ row }: { row: number }) => number = ({
            row,
        }) => row;

        const totalRows = evaluationPositions
            ? 1 + Math.max(0, ...evaluationPositions.map(rowsMapCallback))
            : 0;

        return {
            positions: evaluationPositions,
            totalRows: isFinite(totalRows) ? totalRows : 1,
        };
    }, [evaluations, graphCoords, timeCoords]);

    // ###################################

    useEffect(() => {
        if (!(evaluations?.length && validEvaluations?.positions)) return;

        const index: number = evaluations.findIndex(
            (evaluation) => evaluation.isSelected
        );

        if (index === -1) return;

        setEvaluationBgHighlight((state) => ({
            ...state,
            start: validEvaluations.positions?.[index].gridLineStart ?? null,
            end: validEvaluations.positions?.[index].gridLineEnd ?? null,
            selected: index,
        }));
    }, [evaluations, setEvaluationBgHighlight, validEvaluations.positions]);

    // ###################################

    const evaluationBoxInner: { [key: string]: string | number } = {
        borderRadius: 24,
        borderWidth: 1,
        paddingLeft: 6,
        paddingRight: 6,
    };

    const evaluationBoxMiddle: { [key: string]: string | number } = {
        backgroundColor: colors.white,
        paddingBottom: 4,
        paddingTop: 4,
        borderRadius: 24,
    };

    const evaluationBoxOuter: { [key: string]: string | number } = {
        paddingLeft: 5,
        paddingRight: 3,
        position: "absolute",
        overflow: "hidden",
    };

    const evaluationText: {
        [key: string]: string | number;
    } = {
        borderRadius: isWeb ? 24 : 0,
        color: colors.grey800,
        fontSize: 12,
        lineHeight: 18,
        overflow: "hidden",
        overflowWrap: "normal",
        whiteSpace: "nowrap",
    };

    const fontWeight: (i: number) => {
        [key: string]: string | number;
    } = (i) => ({
        fontWeight: evaluationBgHighlight.selected === i ? "700" : "400",
    });

    // ###################################

    return validEvaluations.positions?.length ? (
        <Box
            position="relative"
            style={timeGridLinesHorizontalBottom}
            height={`${8 + 24 * validEvaluations.totalRows}px`}
        >
            {timeCoords.map(({ x }, i) => (
                <Box
                    style={timeGridLinesVerticalSolid}
                    left={`${x}px`}
                    key={`evaluation-gridlines-${i}`}
                />
            ))}

            {evaluations?.map(({ id, name, onPress }, i) =>
                validEvaluations.positions?.[i] ? (
                    <Box
                        style={evaluationBoxOuter}
                        left={`${validEvaluations.positions[i].xStart}px`}
                        top={`${24 * validEvaluations.positions[i].row}px`}
                        width={`${
                            validEvaluations.positions[i].xEnd -
                            validEvaluations.positions[i].xStart
                        }px`}
                        key={id}
                    >
                        <Pressable
                            onPress={() => {
                                onPress();
                                setEvaluationBgHighlight((state) =>
                                    state.selected === i
                                        ? {
                                              start: null,
                                              end: null,
                                              selected: -1,
                                          }
                                        : {
                                              start:
                                                  validEvaluations.positions?.[
                                                      i
                                                  ].gridLineStart ?? null,
                                              end:
                                                  validEvaluations.positions?.[
                                                      i
                                                  ].gridLineEnd ?? null,
                                              selected: i,
                                          }
                                );
                                setCommentState((state) => ({
                                    ...state,
                                    onShow: false,
                                }));
                                setTooltip((state) => ({
                                    ...state,
                                    onShow: false,
                                }));
                            }}
                        >
                            <Box style={evaluationBoxMiddle}>
                                <HStack
                                    style={evaluationBoxInner}
                                    alignItems="center"
                                    backgroundColor={
                                        evaluationBgHighlight.selected === i
                                            ? colors.green50
                                            : colors.grey50
                                    }
                                    borderColor={
                                        evaluationBgHighlight.selected === i
                                            ? colors.green300
                                            : colors.grey150
                                    }
                                >
                                    <Box mr={1}>
                                        <Icon
                                            name="circleSolid"
                                            fill={
                                                evaluationBgHighlight.selected ===
                                                i
                                                    ? colors.green300
                                                    : colors.grey150
                                            }
                                            size={6}
                                        />
                                    </Box>
                                    <Text
                                        numberOfLines={1}
                                        nativeID={id}
                                        // does not accept fontWeight neither as attribute nor as inline style
                                        style={[evaluationText, fontWeight(i)]}
                                    >
                                        {name}
                                    </Text>
                                </HStack>
                            </Box>
                        </Pressable>
                    </Box>
                ) : (
                    <></>
                )
            )}
        </Box>
    ) : (
        <></>
    );
}
