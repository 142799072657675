import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDate } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { Inter } from "./inter-normal";
import { InterBold } from "./inter-bold";

export interface MeasurementPdfData {
    date: string;
    measurement: {
        value: string;
        measuredAt: string;
        measureTime: string;
        alert: boolean;
    }[];
    comments: {
        time: string;
        patient: string | null;
        doctor: string | null;
    }[];
}

const headerCellStyle = {
    halign: "left" as const,
    fillColor: "#f4f7f8",
    textColor: "#768087",
    cellPadding: 2,
    font: "Inter",
};

const emptyStyle = {
    halign: "left" as const,
    textColor: "#000",
    cellPadding: 1,
    font: "Inter",
    fontStyle: "normal" as const,
};

const bodyStyles = {
    lineWidth: {
        top: 0,
        right: 0,
        bottom: 0.05,
        left: 0,
    },
    lineColor: 175,
    fillColor: "#fff",
    font: "Inter",
};

export default (
    pacientName: string,
    period: string,
    birthDate: string,
    measurementData: MeasurementPdfData[],
    measurementType = "Vércukor"
) => {
    const doc = new jsPDF();
    const { t } = useTranslation();
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    const pdfBottomContentMargin = pdfHeight / 4;

    doc.addFileToVFS("Inter-Regular.ttf", Inter);
    doc.addFileToVFS("Inter-SemiBold.ttf", InterBold);

    doc.addFont("Inter-Regular.ttf", "Inter", "normal", "normal");
    doc.addFont("Inter-SemiBold.ttf", "Inter", "normal", "bold");

    doc.setFont("Inter", "normal");

    const Header = () => {
        doc.setFont("Inter", "normal");
        doc.setFontSize(10);
        doc.setTextColor(75);

        doc.text(
            `Letöltés dátuma: ${formatDate(new Date())}`,
            pdfWidth - 15,
            15,
            {
                align: "right",
            }
        );
        doc.text(`${t("patientName")}: ${pacientName}`, 15, 15);
        doc.text(`Időszak: ${period}`, pdfWidth - 15, 20, {
            align: "right",
        });
        doc.text(`Születési dátum: ${birthDate}`, 15, 20);

        doc.setDrawColor(175);
        doc.line(15, 25, pdfWidth - 15, 25);
    };

    Header();

    if (measurementData.length === 0) {
        doc.setFont("Inter", "normal");
        doc.setTextColor(0);
        doc.setFontSize(12);
        doc.text(`Nincs adat`, 15, 30);
        doc.save("measurement.pdf");

        return;
    }

    measurementData.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        let finalY = ((doc as any).lastAutoTable.finalY || 23) as number;

        if (finalY > pdfHeight - pdfBottomContentMargin) {
            doc.addPage();
            Header();
            finalY = 23;
        }

        doc.setFont("Inter", "bold");
        doc.setTextColor(0);
        doc.setFontSize(11);
        doc.text(`${item.date}`, 15, finalY + 10);

        doc.setFontSize(10);
        doc.text(measurementType, 15, finalY + 17);

        if (item.measurement.length === 0) {
            autoTable(doc, {
                theme: "plain",
                head: [
                    [
                        {
                            content: "Erre a napra nincs mérés",
                            styles: emptyStyle,
                        },
                    ],
                ],
                startY: finalY + 20,
                tableWidth: "auto",
            });
        } else {
            autoTable(doc, {
                theme: "plain",
                head: [
                    [
                        {
                            content: "Időpont",
                            styles: headerCellStyle,
                        },
                        {
                            content: "Érték",
                            styles: headerCellStyle,
                        },
                        {
                            content: "Mérés ideje",
                            styles: headerCellStyle,
                        },
                        {
                            content: "Riasztás",
                            styles: headerCellStyle,
                        },
                    ],
                ],
                body: item.measurement.map((sugar) => [
                    {
                        content: sugar.measuredAt,
                        styles: { cellWidth: 35, styles: { halign: "start" } },
                    },
                    {
                        content: `${sugar.value}`,
                        styles: { cellWidth: 35 },
                    },
                    {
                        content: sugar.measureTime,
                        styles: { cellWidth: "auto" },
                    },
                    {
                        content: sugar.alert ? "Riasztás" : "Normál",
                        styles: { cellWidth: "auto" },
                    },
                ]),
                startY: finalY + 20,
                tableWidth: "auto",
                bodyStyles,
                pageBreak: "avoid",
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        finalY = (doc as any).lastAutoTable.finalY as number;

        if (finalY > pdfHeight - pdfBottomContentMargin) {
            doc.addPage();
            Header();
            finalY = 23;
        }

        doc.setFont("Inter", "bold");
        doc.text(`Megjegyzés`, 15, finalY + 12);

        if (item.comments.length === 0) {
            autoTable(doc, {
                theme: "plain",
                head: [
                    [
                        {
                            content: "Erre a napra nincs megjegyzés",
                            styles: emptyStyle,
                        },
                    ],
                ],
                startY: finalY + 15,
                tableWidth: "auto",
            });
        } else {
            autoTable(doc, {
                theme: "plain",
                head: [
                    [
                        { content: "Idöpont", styles: headerCellStyle },
                        { content: t("patient"), styles: headerCellStyle },
                        { content: "Orvos", styles: headerCellStyle },
                    ],
                ],
                body: item.comments.map((comment) => [
                    {
                        content: comment.time,
                        styles: { cellWidth: 35, styles: { halign: "start" } },
                    },
                    {
                        content: comment.patient ?? "-",
                        styles: { cellWidth: 80 },
                    },
                    {
                        content: comment.doctor ?? "-",
                        styles: { cellWidth: "auto" },
                    },
                ]),
                startY: finalY + 15,
                tableWidth: "auto",
                bodyStyles,
                pageBreak: "avoid",
            });
        }
    });

    doc.save("measurement.pdf");
};
