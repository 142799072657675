import {
    CallCenterPatientProvider,
    CallCenterPatients as CallCenterPatientsUi,
    Spinner,
} from "@madmedical/ui";
import type { PatientForCallCenter } from "@madmedical/user";
import { useProvidePatients } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import { useGetDeviceTypesQuery } from "./api";

const Patients = () => {
    const { data: deviceTypes } = useGetDeviceTypesQuery();

    const {
        patients,
        pagination,
        handleSortChange,
        handleSearchChange,
        filterChoices,
        handleFilterChange,
        handleEndReached,
        isLoading,
    } = useProvidePatients<PatientForCallCenter>({ deviceTypes });

    if (isLoading) {
        return <Spinner />;
    }

    invariant(patients, "No patients in sight");
    invariant(pagination, "No patients in sight");

    return (
        <CallCenterPatientProvider
            value={{
                patients,
                pagination,
                onSortChange: handleSortChange,
                onSearchChange: handleSearchChange,
                filterChoices,
                onFilterChange: handleFilterChange,
            }}
        >
            <CallCenterPatientsUi
                onEndReached={handleEndReached}
            ></CallCenterPatientsUi>
        </CallCenterPatientProvider>
    );
};

export default Patients;
