import type { ComponentProps } from "react";
import { useWatchForm } from "@madmedical/form";
import PreOpSurveyRow from "./PreOpSurveyRow";

type Props = Omit<ComponentProps<typeof PreOpSurveyRow>, "isYes">;

const PreOpSurveyFormRow = (props: Props) => {
    const resolvedName = `answers.${props.name}`;
    const isYes = !!useWatchForm({ name: resolvedName });

    return <PreOpSurveyRow isYes={isYes} {...props} />;
};

export default PreOpSurveyFormRow;
