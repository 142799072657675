import { Box, Divider, HStack, Text, VStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Bar from "../../../atoms/Bar";
import MeasurementProtocolModalFooter from "../../../molecules/MeasurementProtocolModalFooter";
import MeasurementProtocolsOffsetSettingsTitle from "../../../molecules/MeasurementProtocolsOffsetSettingsTitle";
import ModalContent from "../../../molecules/ModalContent";
import type { Props } from "../model";

const BodyMassIndex = ({ onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("measures:BMISetup")}
            footer={<MeasurementProtocolModalFooter onClose={onClose} />}
        >
            <VStack space={4}>
                <MeasurementProtocolsOffsetSettingsTitle />
                <VStack space={0.5}>
                    <HStack
                        width="full"
                        justifyContent="space-between"
                        alignItems="center"
                        space={1}
                    >
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={1}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "green.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={2}
                            height={1}
                        />
                        <Bar
                            data={[{ color: "warning.200", value: 1 }]}
                            flex={1}
                            height={1}
                        />
                    </HStack>
                    <HStack>
                        <Box flex={1} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={2} />
                        <Divider orientation="vertical" height={3} />
                        <Box flex={1} />
                    </HStack>
                    <HStack>
                        <Box flex={1} />
                        <Text minWidth={16} textAlign="center">
                            16,1
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            17
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            18,5
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            24,9
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            29,9
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            34,9
                        </Text>
                        <Box flex={2} />
                        <Text minWidth={16} textAlign="center">
                            39,9
                        </Text>
                        <Box flex={1} />
                    </HStack>
                </VStack>
            </VStack>
        </ModalContent>
    );
};

export default BodyMassIndex;
