import Svg, { Path, Rect } from "react-native-svg";

const Salad = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M83.6581 40.8666C86.7432 38.5958 90.35 37.1375 94.1464 36.6259C94.5473 36.567 94.9561 36.5945 95.3455 36.7065C95.7348 36.8185 96.0957 37.0126 96.404 37.2755C96.7122 37.5385 96.9606 37.8644 97.1325 38.2313C97.3044 38.5982 97.3959 38.9976 97.4008 39.4027C97.4278 43.2023 96.3433 49.8796 89.4146 55.0235C89.4146 55.0246 81.1687 51.3684 83.6581 40.8666Z"
            fill="#7B9791"
        />
        <Path
            d="M26.2875 96.0587L29.3302 81.8141C29.3302 81.8141 53.0321 35.1333 87.4488 32.4532C87.9136 32.419 88.3798 32.4967 88.8084 32.6797C89.2371 32.8627 89.6156 33.1458 89.9123 33.5051C90.2091 33.8645 90.4154 34.2897 90.5141 34.7452C90.6128 35.2007 90.6009 35.6732 90.4794 36.1232C89.9395 38.3419 89.7244 40.6273 89.8408 42.9079C90.1044 50.7244 85.0105 70.8361 54.3599 86.9956L40.3959 102.277C33.7898 103.677 28.7747 102.174 26.2875 96.0587Z"
            fill="#7B9791"
        />
        <Path
            d="M32.5413 101.688C32.5413 101.688 39.7817 101.617 42.7723 93.3475C43.9641 90.1674 45.9132 87.3259 48.4508 85.0691C49.6341 83.9921 50.8436 82.3361 51.7891 79.7883C56.6039 69.7181 68.2138 64.431 80.2685 56.4186C82.9897 55.1333 85.5185 53.4748 87.7819 51.4912C90.5709 49.0505 95.0661 46.421 101.76 45.929C102.363 45.8883 102.968 45.9714 103.538 46.1733C104.107 46.3753 104.629 46.6917 105.072 47.1031C105.515 47.5145 105.868 48.0122 106.111 48.5656C106.354 49.1189 106.481 49.7161 106.485 50.3204C106.521 54.0881 106.02 59.6073 103.365 64.155C102.222 66.0705 101.557 68.2331 101.428 70.4597C101.246 74.1852 99.4905 80.3092 90.9846 85.0591C88.3686 86.5419 86.1558 88.6431 84.5397 91.179C80.1603 97.9566 68.739 109.966 45.7011 102.001C40.1059 103.355 35.1836 103.895 32.5413 101.688Z"
            fill="#B6D2C6"
        />
        <Path
            d="M80.2682 56.418C79.0606 56.988 77.8183 57.4815 76.5487 57.8954C60.489 63.1261 53.6629 72.058 51.7869 79.7876C52.8105 77.6486 54.1987 76.3844 56.3983 75.8568C70.9746 72.8289 77.8023 63.1269 80.2682 56.418Z"
            fill="#7B9791"
        />
        <Path
            d="M74.9319 24.471C75.4684 24.4407 76.0053 24.5231 76.5079 24.713C77.0106 24.9029 77.4679 25.1961 77.8503 25.5736C78.2327 25.951 78.5318 26.4044 78.7282 26.9046C78.9246 27.4047 79.0141 27.9405 78.9908 28.4773C78.8855 30.8444 78.9496 34.4167 79.5203 39.6106C79.5203 39.6106 82.0925 65.3909 55.7356 71.3728C53.2219 71.9395 50.9745 73.3417 49.3605 75.3505C47.3086 77.9505 44.4942 79.8429 41.3122 80.7622C38.7656 81.4808 36.3769 82.6719 34.2706 84.2734C30.7998 84.9055 28.0523 88.3945 25.65 93.248C25.0793 90.1422 24.4894 84.8571 25.6053 79.4163C25.943 77.8123 25.9087 76.1523 25.5052 74.5636C24.4396 70.4626 24.0126 61.5962 37.6035 56.1982C37.6035 56.1982 34.8473 46.4086 44.8607 41.9466C48.3149 40.3808 51.2667 37.887 53.3874 34.7428C56.3179 30.4575 62.4448 25.1309 74.9319 24.471Z"
            fill="#B6D2C6"
        />
        <Path
            d="M30.8737 98.5774C32.9643 100.187 36.8345 101.297 43.9823 99.5361C53.6047 97.1658 63.5296 88.574 69.2618 82.8933C74.283 77.9168 79.4574 73.1029 84.7849 68.4515L91.5526 62.5697C91.6631 62.4718 91.806 62.4184 91.9536 62.42C92.1013 62.4215 92.2431 62.4779 92.3515 62.5781C92.4599 62.6783 92.5272 62.8152 92.5403 62.9623C92.5535 63.1094 92.5115 63.2561 92.4225 63.3739C83.5816 75.2695 56.8824 108.363 35.5329 105.341C30.9026 104.42 28.972 101.146 30.8737 98.5774Z"
            fill="#DCEAE4"
        />
        <Path
            d="M40.6693 71.1371C40.8002 70.9641 40.8647 70.7499 40.851 70.5335C40.8373 70.317 40.7463 70.1126 40.5946 69.9576C40.4428 69.8026 40.2405 69.7072 40.0244 69.6888C39.8082 69.6704 39.5927 69.7303 39.417 69.8575C31.0374 76.081 26.3476 90.9092 25.65 93.2476C25.6649 93.3288 25.6797 93.4122 25.6946 93.4912L27.878 92.3168C28.641 90.8291 29.526 89.4072 30.5238 88.0656C31.5914 86.635 32.853 85.36 34.2723 84.2774C35.6439 78.5801 38.4583 74.0343 40.6693 71.1371Z"
            fill="#7B9791"
        />
        <Path
            d="M35.5329 105.339C31.0047 103.688 30.7165 100.262 30.8742 98.577C29.5768 97.6146 28.7042 96.1856 28.4408 94.5918L24.8325 100.029C28.1459 103.141 31.7558 104.804 35.5329 105.339Z"
            fill="#B6D2C6"
        />
        <Path
            d="M26.9783 100.398C27.6553 100.242 28.251 99.8413 28.6512 99.2734C29.0514 98.7054 29.228 98.0098 29.1471 97.3197C28.6681 93.2255 28.7593 84.5829 35.6428 75.4819C37.8135 72.6425 40.3731 70.1229 43.2463 67.9972C47.9546 64.4774 60.1408 54.2567 63.5783 41.6124C63.6165 41.4707 63.6011 41.3199 63.5351 41.1889C63.4691 41.0578 63.3572 40.9557 63.2207 40.9019C63.0842 40.8481 62.9327 40.8465 62.7951 40.8973C62.6574 40.9482 62.5434 41.0479 62.4746 41.1775C59.7316 46.3371 53.3558 55.3293 38.8972 66.3853C38.8972 66.3853 23.153 77.0976 23.518 97.6764C23.5235 98.0984 23.6234 98.5139 23.8103 98.8923C23.9972 99.2708 24.2664 99.6026 24.5982 99.8635C24.9299 100.124 25.3159 100.308 25.7277 100.4C26.1395 100.493 26.5668 100.492 26.9783 100.398Z"
            fill="#DCEAE4"
        />
        <Path
            d="M50.733 58.8897C50.5438 58.8897 50.359 58.833 50.2025 58.7269C50.046 58.6207 49.9249 58.47 49.8549 58.2943C49.785 58.1186 49.7694 57.9259 49.8101 57.7412C49.8509 57.5565 49.9462 57.3883 50.0836 57.2583C55.0053 52.6056 52.4185 44.1594 52.3917 44.0747C52.3163 43.8357 52.339 43.5766 52.4547 43.3543C52.5705 43.132 52.7697 42.9648 53.0088 42.8894C53.2478 42.8141 53.5069 42.8368 53.7292 42.9525C53.9515 43.0682 54.1187 43.2675 54.1941 43.5065C54.3177 43.8986 57.1535 53.1746 51.3816 58.6324C51.2063 58.7981 50.9742 58.8903 50.733 58.8897Z"
            fill="#DCEAE4"
        />
        <Path
            d="M31.1237 77.2323C30.9497 77.2321 30.7791 77.1838 30.6308 77.0928C30.4824 77.0018 30.3621 76.8716 30.2831 76.7166C30.204 76.5615 30.1694 76.3876 30.1829 76.2141C30.1964 76.0406 30.2575 75.8742 30.3596 75.7333C30.5203 75.5087 34.2831 70.1264 30.1104 64.7006C28.6492 62.8019 28.1659 61.3215 28.0171 60.4142C27.997 60.2917 28.0012 60.1665 28.0296 60.0457C28.0579 59.9249 28.1097 59.8108 28.1821 59.7101C28.2545 59.6093 28.346 59.5237 28.4515 59.4583C28.5569 59.3929 28.6742 59.3489 28.7967 59.3289C28.9191 59.3088 29.0443 59.313 29.1651 59.3413C29.2859 59.3696 29.4 59.4215 29.5008 59.4939C29.6016 59.5662 29.6871 59.6578 29.7525 59.7632C29.8179 59.8687 29.8619 59.986 29.882 60.1084C29.9956 60.8019 30.3872 61.9614 31.6081 63.5487C36.6529 70.1083 31.9379 76.775 31.8897 76.8416C31.8022 76.9628 31.687 77.0614 31.5538 77.1293C31.4207 77.1973 31.2732 77.2326 31.1237 77.2323Z"
            fill="#DCEAE4"
        />
        <Path
            d="M42.7306 67.8109C42.5386 67.811 42.3511 67.7526 42.1931 67.6435C42.0351 67.5343 41.9142 67.3796 41.8463 67.2C41.7785 67.0203 41.767 66.8243 41.8134 66.638C41.8598 66.4517 41.9619 66.2839 42.1061 66.1571C44.5471 64.0089 49.0012 63.5037 49.8695 63.4241C56.3948 62.8272 61.6986 58.6415 61.7513 58.5993C61.8482 58.5218 61.9595 58.4642 62.0786 58.4298C62.1978 58.3953 62.3226 58.3846 62.4459 58.3984C62.5692 58.4122 62.6886 58.4501 62.7972 58.51C62.9059 58.5699 63.0017 58.6506 63.0791 58.7475C63.1565 58.8445 63.2141 58.9557 63.2486 59.0749C63.2831 59.1941 63.2937 59.3189 63.28 59.4421C63.2662 59.5654 63.2283 59.6848 63.1684 59.7935C63.1085 59.9021 63.0277 59.9979 62.9308 60.0753C62.6973 60.262 57.1256 64.6577 50.0416 65.3058C49.5724 65.3485 45.3975 65.7772 43.3543 67.5752C43.1822 67.7273 42.9603 67.8111 42.7306 67.8109Z"
            fill="#DCEAE4"
        />
        <Path
            d="M52.8758 97.91C52.6726 97.91 52.4747 97.8446 52.3117 97.7234C52.1486 97.6021 52.0289 97.4316 51.9704 97.237C51.9091 97.0121 51.9305 96.7727 52.0307 96.5622C52.1309 96.3518 52.3032 96.1842 52.5164 96.09C55.5949 94.668 56.0568 92.4657 56.6407 89.6803C56.7538 89.1418 56.8705 88.5849 57.0103 88.0156C57.2977 86.7509 57.6903 85.5124 58.1842 84.3132C58.2429 84.1804 58.3316 84.0629 58.4432 83.97C58.5549 83.8771 58.6865 83.8113 58.8278 83.7776C58.9691 83.744 59.1162 83.7434 59.2577 83.776C59.3993 83.8086 59.5314 83.8734 59.6437 83.9655C59.804 84.0953 59.9173 84.2741 59.9662 84.4745C60.015 84.6749 59.9968 84.8858 59.9143 85.0749C59.4656 86.1742 59.1084 87.3086 58.8463 88.4667C58.7142 89.0044 58.6009 89.5453 58.4913 90.0683C57.8792 92.9874 57.2466 96.0061 53.2689 97.8244C53.1456 97.8809 53.0114 97.9101 52.8758 97.91Z"
            fill="#DCEAE4"
        />
        <Path
            d="M60.5894 96.0122C60.3881 96.0125 60.1918 95.9486 60.0294 95.8296C59.8669 95.7106 59.7467 95.5428 59.6863 95.3508C59.6259 95.1587 59.6284 94.9523 59.6935 94.7618C59.7586 94.5712 59.8829 94.4064 60.0482 94.2915C62.6091 92.5121 64.9765 92.3849 66.7047 92.2921C66.9293 92.2799 67.1431 92.2686 67.3444 92.2537C73.9643 91.7607 77.2398 89.1373 77.272 89.1108C77.4653 88.9543 77.7126 88.8804 77.9601 88.905C78.2076 88.9297 78.4354 89.051 78.5941 89.2425C78.7528 89.4341 78.8295 89.6805 78.8077 89.9282C78.7859 90.176 78.6672 90.4052 78.4775 90.566C78.3289 90.6894 74.7566 93.5966 67.4848 94.1378C67.2711 94.1538 67.0446 94.1662 66.8059 94.1789C65.22 94.2642 63.2458 94.3702 61.1263 95.8433C60.9687 95.953 60.7814 96.012 60.5894 96.0122Z"
            fill="#DCEAE4"
        />
        <Path
            d="M77.8751 79.9292C77.6895 79.9291 77.508 79.8744 77.3533 79.7719C77.1986 79.6693 77.0775 79.5234 77.0052 79.3525C76.9328 79.1815 76.9124 78.993 76.9466 78.8106C76.9807 78.6281 77.0678 78.4598 77.197 78.3265C80.8072 74.6059 85.3442 73.037 89.3345 74.1324C89.574 74.2006 89.7769 74.3605 89.8991 74.5775C90.0213 74.7945 90.0529 75.0509 89.987 75.291C89.9212 75.5312 89.7632 75.7356 89.5474 75.8599C89.3316 75.9842 89.0755 76.0183 88.8348 75.9548C85.5192 75.0456 81.6764 76.4245 78.5524 79.6429C78.4645 79.7336 78.3592 79.8056 78.2428 79.8548C78.1265 79.904 78.0014 79.9293 77.8751 79.9292Z"
            fill="#DCEAE4"
        />
        <Path
            d="M65.4126 57.4716C65.9436 57.4716 66.374 57.0412 66.374 56.5102C66.374 55.9793 65.9436 55.5488 65.4126 55.5488C64.8817 55.5488 64.4512 55.9793 64.4512 56.5102C64.4512 57.0412 64.8817 57.4716 65.4126 57.4716Z"
            fill="#DCEAE4"
        />
    </Svg>
);

export default Salad;
