import Svg, { Path, Rect } from "react-native-svg";

const BadMood = () => (
    <Svg width="100%" height="100%" viewBox="0 0 65 64" fill="none">
        <Rect x="0.899902" width="64" height="64" rx="4" fill="#E8DDE9" />
        <Path
            d="M33.5496 37.2624C40.4572 37.2624 46.6223 41.1957 49.2555 47.2834C49.7062 48.3253 49.2267 49.535 48.1855 49.9858C47.1436 50.4365 45.9345 49.9563 45.4838 48.9151C43.5014 44.3337 38.818 41.3711 33.5503 41.3711C28.1606 41.3711 23.4485 44.3303 21.5469 48.9103C21.2188 49.6994 20.4536 50.1776 19.6487 50.1789C19.3857 50.1789 19.1192 50.1282 18.861 50.0214C17.8129 49.585 17.3163 48.3821 17.7513 47.3348C20.292 41.2156 26.4933 37.2624 33.5496 37.2624ZM17.0793 19.5221C16.3244 19.4721 15.7531 18.8193 15.8031 18.0644C15.8531 17.3102 16.495 16.7368 17.2587 16.7882C17.3183 16.7923 23.2039 17.1293 25.4172 14.077C25.8611 13.4632 26.7187 13.3289 27.3304 13.7721C27.9428 14.216 28.0791 15.073 27.6352 15.6853C25.0891 19.196 19.8255 19.5433 17.8451 19.5433C17.4101 19.5433 17.1341 19.5262 17.0793 19.5221ZM27.764 24.4514C27.764 25.3522 27.4818 26.1865 27.0037 26.8743C26.2317 25.8132 24.9836 25.12 23.5711 25.12C22.1271 25.12 20.8516 25.844 20.0844 26.9462C19.5748 26.2454 19.2699 25.3851 19.2699 24.4507C19.2699 22.1052 21.1722 20.203 23.517 20.203C25.8618 20.203 27.764 22.1059 27.764 24.4514ZM38.6666 15.66C38.2234 15.0476 38.3597 14.1907 38.9714 13.7468C39.5838 13.3043 40.4415 13.4385 40.8847 14.0516C43.0979 17.104 48.9835 16.767 49.0431 16.7629C49.8076 16.7122 50.4487 17.2855 50.4988 18.039C50.5488 18.7939 49.9775 19.4467 49.2226 19.4967C49.1678 19.5008 48.8917 19.5173 48.4561 19.5173C46.4764 19.518 41.2135 19.1707 38.6666 15.66ZM42.7856 20.1776C45.131 20.1776 47.0333 22.0799 47.0333 24.4254C47.0333 25.359 46.7285 26.2194 46.2188 26.9208C45.4516 25.8187 44.1768 25.0946 42.7321 25.0946C41.3196 25.0946 40.0709 25.7878 39.2989 26.8489C38.8214 26.1612 38.5392 25.3268 38.5392 24.4261C38.5385 22.0806 40.4415 20.1776 42.7856 20.1776ZM19.2377 37.5515C16.7717 37.5515 16.7717 34.8156 16.7717 34.8156C16.7724 32.4619 19.8919 29.654 19.8919 29.654C20.1919 31.5357 20.9331 32.6297 20.9331 32.6297C21.7675 33.875 21.7675 35.032 21.7675 35.032C21.7675 37.5748 19.2377 37.5515 19.2377 37.5515Z"
            fill="#A786A9"
        />
    </Svg>
);

export default BadMood;
