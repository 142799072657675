import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type { MetricType } from "@madmedical/medical";
import type {
    MetricTypeRequest,
    PatientIdRequest,
    Protocol,
    ThresholdForChart,
    ThresholdsProtocolResponse,
    UpdateThresholdProtocolRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["thresholds", "protocols"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getThresholdsProtocols: build.query<
                ThresholdsProtocolResponse,
                Ulid
            >({
                query: (patientId) => ({
                    url: `medical/patients/${patientId}/thresholds`,
                }),
                providesTags: ["thresholds"],
            }),
            getThresholdsForChart: build.query<
                ThresholdForChart[],
                PatientIdRequest & { metricType: MetricType }
            >({
                query: ({ patientId, metricType }) => ({
                    url: `medical/patients/${patientId}/thresholds/chart/${metricType}`,
                }),
                providesTags: ["thresholds"],
            }),
            upsertThreshold: build.mutation<
                ThresholdsProtocolResponse,
                PatientIdRequest & UpdateThresholdProtocolRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/thresholds`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["thresholds", "protocols"],
            }),
            getAllProtocols: build.query<Protocol[], MetricTypeRequest>({
                query: ({ metricType }) => `medical/protocols/${metricType}`,
                providesTags: ["protocols"],
            }),
            getPatientProtocols: build.query<Protocol[], PatientIdRequest>({
                query: ({ patientId }) =>
                    `medical/patients/${patientId}/protocols`,
                providesTags: ["protocols"],
            }),
        }),
    });

export const {
    useGetThresholdsProtocolsQuery,
    useGetThresholdsForChartQuery,
    useUpsertThresholdMutation,
    useGetAllProtocolsQuery,
    useGetPatientProtocolsQuery,
} = api;
