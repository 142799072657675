import type { ComponentProps } from "react";
import { Box, HStack, Stack, Text, VStack } from "native-base";
import { formatDateTimeLongMonth } from "@madmedical/utils";
import type { PregnancyWellBeing } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import {
    PREGNANCY_SYMPTOMS_FOR_HUMANS,
    WELL_BEING_STATUS_FOR_HUMANS,
} from "@madmedical/medical";
import Tag from "../../atoms/Tag";
import PregnantMoodPics from "../Pregnancy/PregnantMoodPics";

interface Props
    extends Pick<ComponentProps<typeof VStack>, "borderBottomWidth"> {
    statusItem: PregnancyWellBeing;
}

const MoodListItem = ({ statusItem, ...rest }: Props) => {
    const { createdAt, status, comment, symptoms } = statusItem;
    const { t } = useTranslation();

    return (
        <VStack
            px={3}
            py={4}
            borderBottomWidth={1}
            borderBottomColor="gray.100"
            width="full"
            bgColor="white"
            {...rest}
        >
            <HStack space={4} justifyContent={"flex-start"}>
                <Box w={"32px"} h={"32px"} alignItems={"center"} mt={1}>
                    <PregnantMoodPics status={status} />
                </Box>
                <Stack direction="column" width={"90%"}>
                    <Text fontWeight={"medium"}>
                        {t(`pregnancy:${WELL_BEING_STATUS_FOR_HUMANS[status]}`)}
                    </Text>
                    <Text fontSize="xs" color="gray.600" my={1}>
                        {formatDateTimeLongMonth(createdAt)}
                    </Text>
                    <HStack space={1} flexWrap={"wrap"} width={"full"}>
                        {symptoms?.map((symptom) => (
                            <Tag key={symptom} my={1}>
                                {t(
                                    `pregnancy:${PREGNANCY_SYMPTOMS_FOR_HUMANS[symptom]}`
                                )}
                            </Tag>
                        ))}
                    </HStack>
                    {comment && (
                        <Text numberOfLines={99} mt={1}>
                            {comment}
                        </Text>
                    )}
                </Stack>
            </HStack>
        </VStack>
    );
};

export default MoodListItem;
