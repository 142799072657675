import Svg, { Path, Rect } from "react-native-svg";

const Broccoli = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M36.814 62.8082C36.814 62.8082 24.551 65.5114 23.0965 56.2218C22.4084 51.8275 23.3945 48.9211 24.6248 47.0781C24.6248 47.0781 21.6886 40.988 27.7662 35.0023C30.7813 32.032 34.1141 32.1419 35.9019 32.032C35.9019 32.032 40.2189 24.7348 48.8777 26.2017C50.5287 26.4894 52.0863 27.1731 53.4184 28.1948C55.0821 29.4641 57.1598 30.0582 59.239 29.8592L62.4813 29.5275L67.396 45.9554L36.814 62.8082Z"
            fill="#7B9791"
        />
        <Path
            d="M61.8167 39.4037C61.8167 39.4037 56.9524 33.4389 61.4274 28.354C65.9025 23.2691 73.0034 25.4205 73.0034 25.4205C74.8065 23.8538 77.0034 22.8153 79.354 22.4187C81.7046 22.022 84.1185 22.2824 86.3318 23.1714C89.4417 24.3677 91.0667 26.7064 91.8945 29.0992C91.8945 29.0992 103.454 31.7764 100.146 43.2174C101.989 43.9052 103.628 45.0533 104.907 46.5535C108.928 56.8461 98.5143 59.1864 84.1542 59.3523C69.5685 58.7153 61.1695 46.341 61.8167 39.4037Z"
            fill="#B6D2C6"
        />
        <Path
            d="M50.7859 78.4211C55.2784 86.1517 57.7158 94.9143 57.8634 103.866C57.8855 104.908 58.3133 105.901 59.0548 106.63C59.7964 107.359 60.7927 107.767 61.8301 107.767H76.2492C76.7848 107.767 77.315 107.658 77.8077 107.447C78.3004 107.236 78.7455 106.927 79.1161 106.538C79.4867 106.15 79.7751 105.689 79.9639 105.186C80.1528 104.682 80.2381 104.145 80.2149 103.607C79.979 98.2238 80.2342 88.4921 84.0575 79.7072C79.3512 74.0554 73.9477 71.3869 67.8109 71.8577C67.6874 74.8436 67.2644 77.8092 66.5485 80.7097C66.5485 80.7097 66.0263 83.3236 64.3927 83.6364C62.7592 83.9492 61.2949 81.0929 61.2949 81.0929C60.43 79.0683 59.9304 76.9051 59.8194 74.7046C56.4885 74.4824 53.4793 75.7298 50.7859 78.4211Z"
            fill="#BDE3A4"
        />
        <Path
            d="M60.6214 66.7295L46.7572 72.7176C46.7572 72.7176 48.6002 74.7013 50.7851 78.4224C51.984 78.3173 53.1226 77.8475 54.0492 77.0757C54.8821 76.4195 55.8578 75.971 56.8964 75.7669C57.9251 75.5828 58.9151 75.2241 59.824 74.7059C59.6899 72.0219 59.9588 69.333 60.6214 66.7295Z"
            fill="#86A275"
        />
        <Path
            d="M67.6622 62.8096C67.9156 65.819 67.9644 68.8422 67.8084 71.8583C69.6142 72.4723 71.3088 73.377 72.8259 74.5371C75.3565 76.3915 79.4584 79.0156 84.0581 79.71C85.588 76.0941 87.7796 72.799 90.519 69.9959L67.6622 62.8096Z"
            fill="#86A275"
        />
        <Path
            d="M104.908 46.5531C105.254 48.826 105.288 51.031 101.819 52.6824C99.7771 53.6547 97.6935 53.0629 96.3997 52.2395C93.4747 50.3778 94.8552 46.5715 89.3494 45.0202C86.1006 44.1051 83.2968 45.2307 81.7165 46.1445C81.3194 46.3737 80.8635 46.4792 80.4066 46.4475C79.9497 46.4158 79.5125 46.2484 79.1505 45.9664C77.1708 44.4349 74.7061 43.6764 72.2127 43.8312V43.8291C64.683 44.2969 61.8175 39.4033 61.8175 39.4033C60.2786 40.7527 59.0167 42.3911 58.1025 44.2266V44.2287C55.1654 50.268 57.8284 55.929 57.8284 55.929L93.3369 66.881C108.416 69.4245 106.081 58.1781 106.081 58.1781C108.154 52.5423 106.828 48.8561 104.908 46.5531Z"
            fill="#7B9791"
        />
        <Path
            d="M75.4892 70.6784C80.6486 73.2506 88.3326 74.9451 94.5607 69.9256C97.6932 67.4011 99.0564 63.1904 97.6816 59.3998C96.9213 57.3046 95.2996 55.2308 92.0552 53.9577C92.0552 53.9577 88.4801 46.0917 80.3823 51.4737C80.3823 51.4737 74.5572 48.0518 69.4977 50.3913C68.2655 60.2854 70.3007 67.0106 75.4892 70.6784Z"
            fill="#B6D2C6"
        />
        <Path
            d="M73.5576 60.7812C74.9236 54.7774 72.0043 51.4459 69.4993 50.3936C67.432 51.3495 65.4921 53.2659 64.1302 56.7724L62.4827 67.288C62.4827 67.288 66.8487 64.7212 71.9558 68.53C73.0691 69.3482 74.2513 70.067 75.4892 70.6782C71.6799 66.9852 73.5576 60.7812 73.5576 60.7812Z"
            fill="#7B9791"
        />
        <Path
            d="M62.2033 53.9364C62.2033 53.9364 71.0306 56.6534 66.0193 65.0527C62.9075 70.2678 58.6072 71.4743 56.0829 71.7289C54.512 71.8957 53.0018 72.4302 51.6735 73.2894C48.8199 75.1156 43.6654 77.5106 39.4453 74.5363C33.2535 70.1723 35.9076 65.0809 35.9076 65.0809C35.9076 65.0809 32.0491 58.6966 39.0431 55.8679C39.0431 55.8679 38.3999 50.6959 44.1085 49.4836C46.2437 49.0067 48.4795 49.3646 50.3617 50.4848C50.3617 50.4848 58.4557 45.4616 62.2033 53.9364Z"
            fill="#B6D2C6"
        />
        <Path
            d="M74.2559 73.4043C73.9084 75.6661 73.3794 78.3019 73.5735 81.0349C73.6303 81.832 75.0003 82.0902 75.239 81.3415C75.8445 79.4341 76.587 77.2488 77.8897 75.2216L74.2559 73.4043Z"
            fill="#86A275"
        />
        <Path
            d="M66.4758 85.3928C66.3124 83.8346 66.3364 82.2624 66.5472 80.71C65.8741 83.2686 62.7702 83.4751 61.2936 81.0937C61.5146 82.1051 62.0956 83.7863 63.9889 86.3052C64.8501 87.4511 66.6344 86.8201 66.4758 85.3928Z"
            fill="#86A275"
        />
        <Path
            d="M39.0431 55.8683C39.0431 55.8683 41.4958 55.1431 43.1517 56.1194C43.2066 56.1526 43.2706 56.1673 43.3345 56.1613C43.3983 56.1554 43.4585 56.1291 43.5064 56.0862C43.5543 56.0434 43.5873 55.9863 43.6006 55.9233C43.6139 55.8603 43.6067 55.7946 43.5803 55.7359C43.1008 54.7256 41.9168 53.3891 39.0431 53.8356C39.0431 53.8356 38.3331 54.4599 39.0431 55.8683Z"
            fill="#7B9791"
        />
        <Path
            d="M62.2032 53.9367C62.2032 53.9367 60.0175 53.3295 58.4281 55.0881C58.3864 55.1338 58.331 55.1645 58.2702 55.1754C58.2095 55.1863 58.1469 55.1769 58.092 55.1485C58.0371 55.1202 57.9931 55.0745 57.9666 55.0185C57.9401 54.9625 57.9327 54.8993 57.9454 54.8386C58.202 53.6677 59.0061 51.9241 61.5089 51.5469C61.5089 51.5469 62.3483 52.7388 62.2032 53.9367Z"
            fill="#7B9791"
        />
        <Path
            d="M80.3831 51.4732C79.2726 52.2501 78.2321 53.0369 77.2506 53.8327C77.1714 53.8965 77.0733 53.9316 76.9719 53.9326C76.8706 53.9336 76.7718 53.9004 76.6914 53.8382C76.6111 53.776 76.5539 53.6885 76.5289 53.5897C76.504 53.491 76.5128 53.3866 76.5538 53.2935C77.0435 52.1919 77.863 50.7897 79.0901 49.9863L80.3831 51.4732Z"
            fill="#7B9791"
        />
        <Path
            d="M92.0554 53.9568C92.0554 53.9568 92.9102 56.1056 92.3338 58.3049C92.3208 58.3578 92.3254 58.4136 92.3469 58.4637C92.3684 58.5137 92.4057 58.5553 92.4529 58.5821C92.5002 58.6088 92.5549 58.6193 92.6086 58.6118C92.6624 58.6044 92.7122 58.5795 92.7505 58.5409C93.4039 57.8644 94.2538 56.5385 94.0497 54.3151C94.0502 54.3151 92.6442 53.6984 92.0554 53.9568Z"
            fill="#7B9791"
        />
        <Path
            d="M32.4296 43.8652C32.9579 43.8652 33.3861 43.4347 33.3861 42.9038C33.3861 42.3728 32.9579 41.9424 32.4296 41.9424C31.9014 41.9424 31.4731 42.3728 31.4731 42.9038C31.4731 43.4347 31.9014 43.8652 32.4296 43.8652Z"
            fill="#5D6D63"
        />
        <Path
            d="M37.0767 39.8789C37.605 39.8789 38.0332 39.4484 38.0332 38.9175C38.0332 38.3865 37.605 37.9561 37.0767 37.9561C36.5485 37.9561 36.1202 38.3865 36.1202 38.9175C36.1202 39.4484 36.5485 39.8789 37.0767 39.8789Z"
            fill="#5D6D63"
        />
        <Path
            d="M37.3974 45.5527C37.9257 45.5527 38.3539 45.1222 38.3539 44.5913C38.3539 44.0603 37.9257 43.6299 37.3974 43.6299C36.8692 43.6299 36.4409 44.0603 36.4409 44.5913C36.4409 45.1222 36.8692 45.5527 37.3974 45.5527Z"
            fill="#5D6D63"
        />
        <Path
            d="M50.2969 64.7578C50.8252 64.7578 51.2534 64.3273 51.2534 63.7964C51.2534 63.2654 50.8252 62.835 50.2969 62.835C49.7687 62.835 49.3405 63.2654 49.3405 63.7964C49.3405 64.3273 49.7687 64.7578 50.2969 64.7578Z"
            fill="#7B9791"
        />
        <Path
            d="M44.1938 64.4814C44.7221 64.4814 45.1503 64.051 45.1503 63.52C45.1503 62.989 44.7221 62.5586 44.1938 62.5586C43.6655 62.5586 43.2373 62.989 43.2373 63.52C43.2373 64.051 43.6655 64.4814 44.1938 64.4814Z"
            fill="#7B9791"
        />
        <Path
            d="M47.8144 60.1152C48.3427 60.1152 48.7709 59.6847 48.7709 59.1538C48.7709 58.6228 48.3427 58.1924 47.8144 58.1924C47.2861 58.1924 46.8579 58.6228 46.8579 59.1538C46.8579 59.6847 47.2861 60.1152 47.8144 60.1152Z"
            fill="#7B9791"
        />
        <Path
            d="M74.7042 34.3535C75.2324 34.3535 75.6607 33.923 75.6607 33.3921C75.6607 32.8611 75.2324 32.4307 74.7042 32.4307C74.1759 32.4307 73.7477 32.8611 73.7477 33.3921C73.7477 33.923 74.1759 34.3535 74.7042 34.3535Z"
            fill="#7B9791"
        />
        <Path
            d="M80.5153 30.5292C81.0436 30.5292 81.4718 30.0988 81.4718 29.5678C81.4718 29.0369 81.0436 28.6064 80.5153 28.6064C79.9871 28.6064 79.5588 29.0369 79.5588 29.5678C79.5588 30.0988 79.9871 30.5292 80.5153 30.5292Z"
            fill="#7B9791"
        />
        <Path
            d="M80.9683 36.6845C81.4966 36.6845 81.9248 36.2541 81.9248 35.7231C81.9248 35.1922 81.4966 34.7617 80.9683 34.7617C80.4401 34.7617 80.0118 35.1922 80.0118 35.7231C80.0118 36.2541 80.4401 36.6845 80.9683 36.6845Z"
            fill="#7B9791"
        />
        <Path
            d="M76.997 63.1748C77.5253 63.1748 77.9535 62.7443 77.9535 62.2134C77.9535 61.6824 77.5253 61.252 76.997 61.252C76.4688 61.252 76.0405 61.6824 76.0405 62.2134C76.0405 62.7443 76.4688 63.1748 76.997 63.1748Z"
            fill="#7B9791"
        />
        <Path
            d="M81.3192 59.4033C81.8474 59.4033 82.2757 58.9728 82.2757 58.4419C82.2757 57.9109 81.8474 57.4805 81.3192 57.4805C80.7909 57.4805 80.3627 57.9109 80.3627 58.4419C80.3627 58.9728 80.7909 59.4033 81.3192 59.4033Z"
            fill="#7B9791"
        />
        <Path
            d="M74.1014 86.6386C74.6296 86.6386 75.0579 86.2082 75.0579 85.6772C75.0579 85.1463 74.6296 84.7158 74.1014 84.7158C73.5731 84.7158 73.1449 85.1463 73.1449 85.6772C73.1449 86.2082 73.5731 86.6386 74.1014 86.6386Z"
            fill="#86A275"
        />
    </Svg>
);

export default Broccoli;
