import type { ReactNode } from "react";
import {
    ChartProvider,
    ChartWidget,
    CurrentPatientProvider,
    DeviceProvider,
    MeasurementCommentProvider,
    Spinner,
    ThresholdsProtocolsProvider,
} from "@madmedical/ui";
import {
    adaptThresholdsProtocol,
    useGetThresholdsProtocolsQuery,
} from "@madmedical/threshold";
import type { MetricType } from "@madmedical/medical";
import type { DateRange, DateRangeStrings } from "@madmedical/utils";
import { invariant, rangeToDate, rangeToString } from "@madmedical/utils";
import { adaptPatient, usePatient } from "@madmedical/user";
import { useDevices } from "@madmedical/device";
import { useDateRange } from "@madmedical/store";
import { useEffect } from "react";
import { useSearchParams } from "@madmedical/routing";
import { useGetMeasurementRangeQuery } from "../api";
import ChartDecorated from "../../charts/ChartDecorated";
import { useGetCommentsQuery } from "../../comment/api";

interface Props {
    children: ReactNode;
    metricType: MetricType;
    statsRange?: DateRange | DateRangeStrings;
    selectedRange?: DateRange | null;
    onSelectRange?: (range: DateRange | null) => void;
}

const MeasurementProviders = ({
    metricType,
    statsRange,
    selectedRange,
    onSelectRange,
    children,
}: Props) => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const { devices, isLoading: isDevicesLoading } = useDevices();

    const { dateRange, setDateRange } = useDateRange();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const from = searchParams.get("from");
        const to = searchParams.get("to");

        if (!from || !to) {
            return;
        }

        setDateRange({ from, to });
    }, [searchParams, setDateRange]);

    const { data: chartData, isLoading: isMeasurementsLoading } =
        useGetMeasurementRangeQuery({
            userId: patientId,
            metricType,
            queryRange: rangeToString(dateRange),
            statsRange: rangeToString(statsRange ?? dateRange),
        });
    const {
        data: thresholdsProtocols,
        isLoading: isThresholdsProtocolsLoading,
    } = useGetThresholdsProtocolsQuery(patientId);

    const { data: commentsData, isLoading: isCommentsLoading } =
        useGetCommentsQuery({
            patientId,
            dateRange: rangeToString(dateRange),
        });

    if (
        isDevicesLoading ||
        isMeasurementsLoading ||
        isPatientLoading ||
        isThresholdsProtocolsLoading ||
        isCommentsLoading
    ) {
        return <Spinner />;
    }

    invariant(chartData);
    invariant(patient);
    invariant(thresholdsProtocols);

    const { worstAlertLevel, displayRange, unit, providers } = chartData.stats;

    return (
        <ChartProvider
            value={{
                chart: (
                    <ChartDecorated
                        metricType={metricType}
                        data={chartData}
                        dateRange={dateRange}
                        widget={ChartWidget.Page}
                    />
                ),
                metricType,
                dateRange: statsRange ? rangeToDate(statsRange) : dateRange,
                displayRange: displayRange ? `${displayRange} ${unit}` : null,
                alertLevel: worstAlertLevel,
                setDateRange,
                selectedRange,
                onSelectRange,
            }}
        >
            <DeviceProvider
                value={{
                    devices: devices.filter((device) =>
                        providers.includes(device.provider)
                    ),
                }}
            >
                <CurrentPatientProvider
                    value={{ patient: adaptPatient(patient) }}
                >
                    <ThresholdsProtocolsProvider
                        value={{
                            thresholdsProtocols: thresholdsProtocols
                                .filter(
                                    (thresholdsProtocol) =>
                                        thresholdsProtocol.metricType ===
                                        metricType
                                )
                                .map(adaptThresholdsProtocol),
                        }}
                    >
                        <MeasurementCommentProvider
                            value={{
                                comments: commentsData
                                    ? commentsData.map(
                                          ({ date, author, ...rest }) => ({
                                              date: new Date(date),
                                              author: {
                                                  ...author,
                                                  isSelf:
                                                      patientId === author.id,
                                              },
                                              ...rest,
                                          })
                                      )
                                    : [],
                                onDelete: () => null,
                            }}
                        >
                            {children}
                        </MeasurementCommentProvider>
                    </ThresholdsProtocolsProvider>
                </CurrentPatientProvider>
            </DeviceProvider>
        </ChartProvider>
    );
};

export default MeasurementProviders;
