import type { ComponentProps } from "react";
import { forwardRef, useMemo } from "react";
import { IconButton as BaseIconButton, useTheme } from "native-base";
import Icon from "../icons/Icon";

type IconName = ComponentProps<typeof Icon>["name"];

interface Props
    extends Pick<
        ComponentProps<typeof BaseIconButton>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
        | "alignItems"
        | "borderRadius"
        | "position"
        | "bottom"
        | "right"
    > {
    variant?:
        | "primary" // Filled Green
        | "destructive" // Filled Red
        | "gray" // Filled Gray
        | "lightGray" // Filled Gray
        | "outlined" // Filled White
        | "ghost" // Ghost Gray
        | "ghostGreen" // Ghost Green
        | "link" // Link Green
        | "ghostLink" // Link Gray
        | "ghostGrayLink"; // Ghost Gray
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    isDisabled?: boolean;
    onPress: () => void;
    iconName?: IconName;
}

const IconButton = forwardRef<typeof BaseIconButton, Props>(
    (
        {
            variant = "primary",
            size = "md",
            isDisabled = false,
            onPress,
            iconName,
            ...rest
        }: Props,
        ref
    ) => {
        const { colors } = useTheme();

        const iconSizes = useMemo(
            () =>
                ({
                    xs: 16,
                    sm: 18,
                    md: 20,
                    lg: 20,
                    xl: 20,
                    "2xl": 22,
                } as const),
            []
        );

        const iconVariants = useMemo(
            () => ({
                primary: colors.white,
                destructive: colors.white,
                gray: colors.gray[800],
                lightGray: colors.gray[600],
                outlined: colors.gray[800],
                ghost: colors.gray[900],
                ghostGreen: colors.green[500],
                link: colors.green[600],
                ghostLink: colors.gray[800],
                ghostGrayLink: colors.gray[500],
            }),
            [colors]
        );

        return (
            <BaseIconButton
                {...rest}
                accessibilityRole="button"
                icon={
                    iconName && (
                        <Icon
                            name={iconName}
                            fill={iconVariants[variant]}
                            size={iconSizes[size]}
                        />
                    )
                }
                onPress={onPress}
                isDisabled={isDisabled}
                variant={variant}
                size={size}
                ref={ref}
            />
        );
    }
);

IconButton.displayName = "IconButton";
export default IconButton;
