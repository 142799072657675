export const getBabyStats = (
    weekNr: number
): {
    height: string;
    weight: string;
} => {
    switch (true) {
        case weekNr < 4:
            return { height: "-", weight: "-" };
        case weekNr === 4:
            return { height: "0,1", weight: "<1" };

        case weekNr === 5:
            return { height: "0,1-0,2", weight: "<1" };
        case weekNr === 6:
            return { height: "0,2-0,4", weight: "<1" };

        case weekNr === 7:
            return { height: "0,5-1,3", weight: "<1" };

        case weekNr === 8:
            return { height: "1,4-2", weight: "1,5" };

        case weekNr === 9:
            return { height: "2,2-3", weight: "2" };

        case weekNr === 10:
            return { height: "3,1-3,9", weight: "4" };

        case weekNr === 11:
            return { height: "4,1-5,1", weight: "7" };

        case weekNr === 12:
            return { height: "5,4-6,5", weight: "14" };

        case weekNr === 13:
            return { height: "6-7,4", weight: "23" };

        case weekNr === 14:
            return { height: "8-9,5", weight: "43" };

        case weekNr === 15:
            return { height: "10,1", weight: "70" };

        case weekNr === 16:
            return { height: "11-12", weight: "100" };

        case weekNr === 17:
            return { height: "13", weight: "100-150" };

        case weekNr === 18:
            return { height: "12,5-14", weight: "150-190" };

        case weekNr === 19:
            return { height: "15,3-17,6", weight: "240" };

        case weekNr === 20:
            return { height: "18-24,5", weight: "300" };

        case weekNr === 21:
            return { height: "25-26,7", weight: "360" };

        case weekNr === 22:
            return { height: "27,8-29", weight: "430" };

        case weekNr === 23:
            return { height: "28,9-30", weight: "450-500" };

        case weekNr === 24:
            return { height: "30-33", weight: "600" };

        case weekNr === 25:
            return { height: "34,6", weight: "660" };

        case weekNr === 26:
            return { height: "35,6", weight: "760" };

        case weekNr === 27:
            return { height: "36,6", weight: "875" };

        case weekNr === 28:
            return { height: "37,6", weight: "1005" };

        case weekNr === 29:
            return { height: "38,6", weight: "1153" };

        case weekNr === 30:
            return { height: "39,9", weight: "1319" };

        case weekNr === 31:
            return { height: "41,1", weight: "1502" };

        case weekNr === 32:
            return { height: "42,4", weight: "1702" };

        case weekNr === 33:
            return { height: "43,7", weight: "1918" };

        case weekNr === 34:
            return { height: "45", weight: "2146" };

        case weekNr === 35:
            return { height: "46,2", weight: "2383" };

        case weekNr === 36:
            return { height: "47,4", weight: "2622" };

        case weekNr === 37:
            return { height: "48,6", weight: "2859" };

        case weekNr === 38:
            return { height: "49,8", weight: "3083" };

        case weekNr === 39:
            return { height: "50,7", weight: "3288" };

        case weekNr === 40:
            return { height: "51,2", weight: "3462" };

        case weekNr >= 41:
            return { height: "51,5", weight: "3597" };

        default:
            return { height: "-", weight: "-" };
    }
};
