import Svg, { Path, Rect } from "react-native-svg";

const Lime = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M44.8172 33.3487C44.8172 33.3487 40.0522 53.7162 21.1613 50.9786C21.1613 50.9786 20.4213 39.5281 30.7419 35.3289C39.8915 31.6064 44.8172 33.3487 44.8172 33.3487Z"
            fill="#B6D2C6"
        />
        <Path
            d="M52.0613 31.7824C52.0122 31.537 51.8672 31.3209 51.6581 31.1818C51.449 31.0427 51.193 30.9918 50.9462 31.0405C50.5701 31.1149 45.319 32.1937 38.8457 35.4275C37.1993 36.0679 35.4059 36.2384 33.6673 35.92C33.4048 35.8713 33.1516 35.8286 32.9077 35.792C32.6608 35.7501 32.4071 35.8035 32.1984 35.9413C31.9897 36.0792 31.8417 36.291 31.7847 36.5337C31.7594 36.6592 31.76 36.7886 31.7864 36.914C31.8129 37.0393 31.8647 37.158 31.9387 37.2628C32.0127 37.3676 32.1074 37.4563 32.2169 37.5236C32.3264 37.5909 32.4485 37.6353 32.5759 37.6542C32.8138 37.6896 34.0342 37.8926 34.3774 37.9231C30.4237 40.354 26.85 43.349 23.7704 46.8122C23.5968 47.0068 23.5072 47.2616 23.5212 47.5214C23.5351 47.7812 23.6514 48.025 23.8449 48.2001C24.0356 48.3574 24.2805 48.4344 24.5274 48.4146C24.7743 48.3948 25.0037 48.2799 25.1667 48.0943C27.3134 45.6654 29.7261 43.4826 32.3599 41.5866C32.0207 42.7371 31.8213 43.9239 31.7659 45.1216C31.7633 45.2457 31.7853 45.369 31.8307 45.4846C31.876 45.6002 31.9438 45.7058 32.0301 45.7953C32.1165 45.8848 32.2197 45.9566 32.3339 46.0064C32.4481 46.0562 32.5711 46.0832 32.6958 46.0857H32.7165C32.9649 46.0857 33.2033 45.9888 33.3808 45.8158C33.5582 45.6429 33.6604 45.4076 33.6654 45.1606C33.7349 43.9394 33.9627 42.7324 34.3432 41.5693C34.684 40.4566 35.4322 39.5118 36.4398 38.9219C44.2102 34.3595 51.2119 32.9148 51.3169 32.8937C51.5637 32.8445 51.7808 32.6998 51.9203 32.4914C52.0599 32.283 52.1106 32.028 52.0613 31.7824Z"
            fill="#7B9791"
        />
        <Path
            d="M77.3764 50.4164C77.3764 50.4164 68.1464 50.1496 60.7881 56.049L71.3856 80.0459H99.8166C100.187 77.2802 100.274 74.484 100.074 71.7009C98.6621 49.7297 77.3764 50.4164 77.3764 50.4164Z"
            fill="#8E9F60"
        />
        <Path
            d="M43.2002 50.4155L44.0705 47.6747C44.3477 46.812 44.9267 46.0769 45.7024 45.6028C46.1356 45.3378 46.5475 45.0398 46.9344 44.7115C47.3876 44.314 47.7495 43.8241 47.9956 43.2751C48.2417 42.7262 48.3662 42.131 48.3607 41.5299V34.473C48.3606 33.7028 48.5677 32.9467 48.9605 32.283C49.3532 31.6193 49.9172 31.0722 50.5941 30.6985L51.5061 30.1944C51.5916 30.1471 51.6882 30.1229 51.7861 30.1241C51.884 30.1253 51.9798 30.1519 52.0642 30.2013C52.1486 30.2507 52.2186 30.3211 52.2672 30.4057C52.3158 30.4903 52.3413 30.586 52.3413 30.6834V43.4687C52.3453 44.2449 52.6564 44.9884 53.2073 45.5381C53.6704 45.985 54.0255 46.5307 54.2458 47.134C54.4661 47.7373 54.5459 48.3825 54.479 49.0209L43.2002 50.4155Z"
            fill="#7B9791"
        />
        <Path
            d="M51.8384 48.2968C51.8384 48.2968 27.6772 47.5806 25.9295 73.7668C24.8889 89.3562 34.2247 95.807 41.8338 98.4479C42.8104 98.7844 43.8077 99.0581 44.8196 99.2673C57.6024 100.469 66.0337 93.8647 69.9501 79.1577C74.7415 61.9115 69.1886 53.3165 58.3313 49.1579C56.214 48.5903 54.0312 48.3009 51.8384 48.2968Z"
            fill="#B6CD8A"
        />
        <Path
            d="M74.9716 70.4926C74.051 55.8389 64.8465 50.8503 58.3302 49.1572C58.3357 49.1602 64.5011 52.1104 67.3718 62.4972C68.5359 66.7087 66.2363 70.7683 61.8463 70.7683H51.4142C51.1231 70.7683 50.8352 70.8273 50.5679 70.9417C50.3005 71.0561 50.0594 71.2235 49.8592 71.4337C49.6591 71.6438 49.5041 71.8924 49.4036 72.1641C49.3032 72.4358 49.2594 72.7251 49.2751 73.0142C49.411 75.5823 50.1699 80.1536 53.3512 85.6804C56.731 91.5522 52.1063 98.9755 45.3113 99.252C45.1497 99.2585 44.9859 99.2627 44.8198 99.2645C50.9526 100.546 58.3131 99.83 63.4961 96.9166C69.8797 93.3302 75.9401 85.9101 74.9716 70.4926Z"
            fill="#8E9F60"
        />
        <Path
            d="M83.5901 99.8765C86.9074 99.8766 90.1921 99.2266 93.2569 97.9636C96.3216 96.7006 99.1063 94.8495 101.452 92.5158C103.798 90.1821 105.658 87.4116 106.928 84.3625C108.197 81.3134 108.85 78.0454 108.85 74.7451H104.064C90.5736 77.9592 76.8995 77.4602 63.1144 74.7451H58.3276C58.3276 78.0456 58.981 81.3138 60.2506 84.363C61.5202 87.4123 63.381 90.1829 65.7269 92.5166C68.0728 94.8503 70.8577 96.7014 73.9227 97.9642C76.9877 99.227 80.2727 99.8769 83.5901 99.8765Z"
            fill="#F1FCD4"
        />
        <Path
            d="M83.5901 95.1162C89.0203 95.1162 94.2281 92.9701 98.0678 89.15C101.908 85.3298 104.065 80.1486 104.065 74.7461H86.1663C85.3613 75.108 84.4882 75.2951 83.6049 75.2951C82.7216 75.2951 81.8485 75.108 81.0436 74.7461H63.1155C63.1154 77.4212 63.645 80.07 64.6739 82.5415C65.7029 85.0129 67.211 87.2585 69.1123 89.1501C71.0135 91.0416 73.2706 92.5421 75.7548 93.5657C78.2389 94.5894 80.9013 95.1163 83.5901 95.1162Z"
            fill="#D2D27D"
        />
        <Path
            d="M56.7235 59.284C56.9266 59.2842 57.1245 59.2196 57.288 59.0997C57.4516 58.9798 57.5721 58.8109 57.632 58.6177C57.6918 58.4246 57.6879 58.2175 57.6206 58.0267C57.5534 57.836 57.4265 57.6718 57.2585 57.5582C56.8636 57.3257 56.5741 56.9507 56.4502 56.5112C56.397 56.2663 56.2483 56.0523 56.0366 55.9165C55.8249 55.7807 55.5677 55.734 55.3215 55.7869C55.0752 55.8397 54.8602 55.9877 54.7237 56.1983C54.5871 56.4089 54.5403 56.6649 54.5934 56.9098C54.8168 57.8249 55.3894 58.6177 56.1899 59.1203C56.3472 59.227 56.5331 59.284 56.7235 59.284Z"
            fill="#8E9F60"
        />
        <Path
            d="M61.3555 66.3929C61.5587 66.393 61.7565 66.3284 61.92 66.2084C62.0834 66.0885 62.2039 65.9196 62.2638 65.7264C62.3236 65.5333 62.3197 65.3262 62.2525 65.1355C62.1853 64.9448 62.0584 64.7805 61.8905 64.6668C61.4962 64.434 61.2072 64.0589 61.0836 63.6196C61.0271 63.3781 60.8775 63.1683 60.6669 63.0355C60.4564 62.9027 60.2018 62.8576 59.9581 62.9099C59.7145 62.9622 59.5012 63.1078 59.3645 63.3151C59.2277 63.5225 59.1783 63.775 59.2271 64.0182C59.4506 64.9334 60.0232 65.7261 60.8238 66.2287C60.9805 66.3353 61.1657 66.3925 61.3555 66.3929Z"
            fill="#8E9F60"
        />
        <Path
            d="M54.6074 65.4949C54.8106 65.4951 55.0084 65.4306 55.1719 65.3106C55.3355 65.1907 55.456 65.0218 55.5159 64.8287C55.5757 64.6355 55.5718 64.4284 55.5045 64.2377C55.4373 64.047 55.3104 63.8827 55.1424 63.7691C54.7475 63.5367 54.4579 63.1617 54.3338 62.7222C54.2774 62.4806 54.1277 62.2708 53.9172 62.138C53.7066 62.0053 53.4521 61.9602 53.2084 62.0125C52.9647 62.0648 52.7515 62.2103 52.6147 62.4177C52.4779 62.625 52.4286 62.8775 52.4773 63.1208C52.7005 64.036 53.2731 64.8288 54.0738 65.3312C54.231 65.4381 54.417 65.4951 54.6074 65.4949Z"
            fill="#8E9F60"
        />
        <Path
            d="M87.2528 60.0978C87.1134 60.0977 86.9758 60.0671 86.8497 60.008C86.0483 59.6579 85.3952 59.0399 85.0036 58.2611C84.9006 58.0352 84.8909 57.7781 84.9768 57.5453C85.0627 57.3124 85.2371 57.1225 85.4625 57.0165C85.6878 56.9104 85.9461 56.8968 86.1815 56.9786C86.4168 57.0603 86.6105 57.2309 86.7205 57.4534C86.924 57.8352 87.2555 58.134 87.6575 58.2979C87.8529 58.3893 88.0112 58.5443 88.1062 58.7372C88.2012 58.9302 88.2273 59.1496 88.1802 59.3592C88.133 59.5689 88.0155 59.7563 87.8469 59.8906C87.6783 60.0249 87.4688 60.098 87.2528 60.0978Z"
            fill="#848D5F"
        />
        <Path
            d="M92.9526 66.3936C92.8131 66.3934 92.6754 66.3627 92.5492 66.3035C91.6791 65.9329 90.9882 65.2397 90.6228 64.371C90.5339 64.1379 90.5412 63.8794 90.643 63.6516C90.7449 63.4239 90.9331 63.2454 91.1666 63.1551C91.4001 63.0647 91.66 63.0698 91.8898 63.1693C92.1195 63.2688 92.3004 63.4546 92.3931 63.6861C92.5852 64.1007 92.9305 64.4257 93.3573 64.5934C93.5526 64.685 93.7108 64.84 93.8057 65.0329C93.9006 65.2258 93.9267 65.4451 93.8796 65.6548C93.8324 65.8644 93.715 66.0518 93.5465 66.1861C93.378 66.3204 93.1685 66.3936 92.9526 66.3936Z"
            fill="#848D5F"
        />
        <Path
            d="M86.1462 66.5633C86.0068 66.5632 85.869 66.5326 85.7429 66.4735C84.8728 66.1027 84.1818 65.4096 83.8162 64.5409C83.7273 64.3079 83.7346 64.0493 83.8364 63.8215C83.9382 63.5938 84.1264 63.4153 84.3599 63.325C84.5935 63.2346 84.8534 63.2398 85.0831 63.3392C85.3129 63.4387 85.4938 63.6245 85.5865 63.8561C85.7786 64.2706 86.1239 64.5954 86.5506 64.7631C86.7459 64.8546 86.9041 65.0096 86.999 65.2025C87.094 65.3954 87.12 65.6147 87.073 65.8243C87.0259 66.0339 86.9084 66.2213 86.74 66.3556C86.5715 66.49 86.3621 66.5632 86.1462 66.5633Z"
            fill="#848D5F"
        />
        <Path
            d="M65.9487 85.082C66.2713 85.6263 66.6182 86.1533 66.9896 86.6631L80.1448 77.6552C80.2591 77.577 80.3953 77.5365 80.5341 77.5396C80.6728 77.5426 80.8071 77.589 80.9179 77.6722C81.0287 77.7553 81.1104 77.8711 81.1514 78.003C81.1924 78.1349 81.1907 78.2763 81.1465 78.4072L75.9825 93.6575C76.5678 93.8908 77.1661 94.0977 77.7775 94.2782L83.2497 78.1133C83.3482 77.8222 83.5131 77.5577 83.7314 77.3405C83.9497 77.1233 84.2155 76.9593 84.5081 76.8612C84.8007 76.7631 85.1121 76.7337 85.418 76.7752C85.724 76.8167 86.0161 76.9281 86.2716 77.1005L100.272 86.5483C100.64 86.0358 100.983 85.5064 101.302 84.96L86.1661 74.7461H81.0437L65.9487 85.082Z"
            fill="#F1FCD4"
        />
        <Path
            d="M86.314 79.1131C86.2706 78.9967 86.2046 78.89 86.1197 78.7991C86.0349 78.7081 85.9328 78.6347 85.8194 78.583C85.706 78.5312 85.5834 78.5023 85.4588 78.4977C85.3341 78.4931 85.2098 78.5131 85.0928 78.5563C84.9759 78.5996 84.8687 78.6654 84.7773 78.7499C84.686 78.8344 84.6122 78.936 84.5604 79.0488C84.5085 79.1617 84.4795 79.2837 84.475 79.4077C84.4705 79.5317 84.4906 79.6554 84.5342 79.7717L90.2938 95.1796C90.3816 95.4144 90.5595 95.605 90.7885 95.7093C91.0174 95.8136 91.2787 95.8232 91.5147 95.7359C91.7508 95.6486 91.9423 95.4716 92.0472 95.2438C92.152 95.016 92.1617 94.7561 92.0739 94.5212L86.314 79.1131Z"
            fill="#F1FCD4"
        />
        <Path
            d="M83.5903 85.2061C83.3384 85.2061 83.0968 85.3056 82.9187 85.4828C82.7406 85.66 82.6405 85.9003 82.6405 86.151V86.8913C82.6384 87.0167 82.6614 87.1413 82.7081 87.2578C82.7549 87.3742 82.8245 87.4803 82.9129 87.5697C83.0013 87.6592 83.1067 87.7302 83.223 87.7787C83.3392 87.8272 83.464 87.8522 83.5901 87.8522C83.7162 87.8522 83.841 87.8272 83.9573 87.7787C84.0735 87.7302 84.179 87.6592 84.2673 87.5697C84.3557 87.4803 84.4253 87.3742 84.4721 87.2578C84.5189 87.1413 84.5419 87.0167 84.5397 86.8913V86.1518C84.5398 86.0276 84.5154 85.9047 84.4677 85.79C84.42 85.6753 84.3501 85.571 84.2619 85.4832C84.1738 85.3954 84.0691 85.3257 83.9538 85.2781C83.8386 85.2306 83.715 85.2061 83.5903 85.2061Z"
            fill="#F1FCD4"
        />
        <Path
            d="M76.4002 85.646L76.8672 85.1257C76.9502 85.0332 77.0142 84.9253 77.0553 84.8082C77.0964 84.691 77.114 84.567 77.107 84.4432C77.0999 84.3193 77.0684 84.198 77.0143 84.0863C76.9602 83.9745 76.8844 83.8745 76.7914 83.7918C76.6984 83.7092 76.59 83.6456 76.4723 83.6047C76.3545 83.5637 76.2299 83.5463 76.1054 83.5533C75.9809 83.5603 75.859 83.5916 75.7467 83.6455C75.6343 83.6993 75.5338 83.7747 75.4507 83.8672L74.9837 84.3875C74.9007 84.4801 74.8368 84.588 74.7956 84.7051C74.7545 84.8222 74.7369 84.9462 74.7439 85.0701C74.751 85.1939 74.7825 85.3152 74.8366 85.427C74.8907 85.5387 74.9665 85.6388 75.0595 85.7214C75.1525 85.804 75.2609 85.8676 75.3787 85.9086C75.4964 85.9495 75.621 85.967 75.7455 85.96C75.87 85.953 75.9919 85.9217 76.1042 85.8678C76.2166 85.8139 76.3171 85.7386 76.4002 85.646Z"
            fill="#F1FCD4"
        />
        <Path
            d="M71.6216 79.085C71.7207 79.0849 71.8192 79.0694 71.9134 79.039L72.5518 78.8342C72.7914 78.7573 72.9906 78.5888 73.1055 78.3658C73.2203 78.1428 73.2414 77.8835 73.1641 77.6451C73.0868 77.4066 72.9175 77.2084 72.6933 77.0942C72.4692 76.9799 72.2086 76.9589 71.9689 77.0358L71.3306 77.2407C71.1151 77.3097 70.9315 77.453 70.8128 77.6448C70.6942 77.8365 70.6483 78.0642 70.6834 78.2866C70.7185 78.5091 70.8323 78.7118 71.0043 78.8582C71.1763 79.0045 71.3952 79.085 71.6216 79.085Z"
            fill="#F1FCD4"
        />
        <Path
            d="M91.3789 83.4105C91.2053 83.2326 90.9683 83.1298 90.7191 83.1243C90.4699 83.1187 90.2285 83.2108 90.047 83.3807C89.8655 83.5506 89.7584 83.7847 89.7488 84.0325C89.7392 84.2803 89.8278 84.5219 89.9957 84.7052L90.4923 85.2304C90.6655 85.4106 90.9032 85.5154 91.1537 85.522C91.4042 85.5286 91.6472 85.4364 91.8296 85.2656C92.0121 85.0947 92.1194 84.8591 92.128 84.6099C92.1366 84.3608 92.0459 84.1183 91.8756 83.9354L91.3789 83.4105Z"
            fill="#F1FCD4"
        />
        <Path
            d="M94.5351 78.8251L95.3334 79.0438C95.4536 79.0767 95.5793 79.0857 95.703 79.0703C95.8268 79.055 95.9463 79.0155 96.0547 78.9542C96.1632 78.8929 96.2584 78.8109 96.335 78.713C96.4116 78.615 96.468 78.503 96.5011 78.3834C96.5342 78.2637 96.5433 78.1388 96.5278 78.0156C96.5124 77.8925 96.4727 77.7736 96.4111 77.6657C96.3494 77.5579 96.2671 77.4631 96.1686 77.3869C96.0702 77.3107 95.9576 77.2545 95.8374 77.2216L95.0391 77.0033C94.9183 76.9684 94.7917 76.9577 94.6666 76.9719C94.5416 76.9861 94.4207 77.0249 94.3109 77.086C94.2011 77.1471 94.1045 77.2293 94.0269 77.3279C93.9493 77.4264 93.8921 77.5393 93.8588 77.66C93.8254 77.7807 93.8164 77.9068 93.8324 78.031C93.8484 78.1551 93.8891 78.2749 93.952 78.3833C94.015 78.4917 94.0989 78.5866 94.199 78.6624C94.2991 78.7383 94.4134 78.7936 94.5351 78.8251Z"
            fill="#F1FCD4"
        />
        <Path
            d="M40.3219 62.9158C41.0759 60.9133 40.571 58.874 39.1943 58.3609C37.8175 57.8478 36.0902 59.0552 35.3362 61.0577C34.5822 63.0602 35.087 65.0995 36.4638 65.6127C37.8406 66.1258 39.5679 64.9183 40.3219 62.9158Z"
            fill="#F1FCD4"
        />
    </Svg>
);

export default Lime;
