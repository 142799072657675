import { Box, HStack, Text } from "native-base";

interface Props {
    label: string;
    value: string | number;
    measure?: string;
}

const BirthBlockText = ({ label, value, measure }: Props) => (
    <Box textAlign={"center"} alignItems={"center"}>
        <Text>{label}</Text>
        <HStack>
            <Text>
                <Text fontSize="md" fontWeight="medium" mb={1}>
                    {value}
                </Text>
                {measure && (
                    <Text fontSize={"sm"} color={"gray.600"} ml={1}>
                        {measure}
                    </Text>
                )}
            </Text>
        </HStack>
    </Box>
);

export default BirthBlockText;
