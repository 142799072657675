import { Box, Stack, VStack } from "native-base";
import { formatDateRange, startOfDay } from "@madmedical/utils";
import type { AlertLevel } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import Headline from "../../molecules/Headline";
import ListHeader from "../../molecules/ListHeader";
import SimpleListItem from "../../molecules/ListItem/SimpleListItem";
import Paper from "../../atoms/Paper";
import StatusBadge from "../../atoms/StatusBadge";
import Tag from "../../atoms/Tag";
import type { Evaluation } from "../../providers/evaluation";
import { useDevices } from "../../providers/device";

interface Props {
    evaluation: Evaluation;
    measurementRange: string | null;
    alertLevel: AlertLevel | null;
}

const DetailsSection = ({
    evaluation,
    measurementRange,
    alertLevel,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { devices } = useDevices();
    const { t } = useTranslation();

    return (
        <Box>
            {isSmallScreen && (
                <ListHeader
                    title={t("details")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}
            <Paper borderRadius={{ base: "lg", md: "none" }}>
                {!isSmallScreen && <Headline title={t("details")} size="xs" />}
                <Box
                    px={{
                        base: 4,
                        md: 6,
                    }}
                    width="full"
                >
                    <Stack
                        width="full"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                    >
                        <VStack
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("date")}
                                content={formatDateRange(
                                    {
                                        from: startOfDay(
                                            evaluation.dateRange.from
                                        ),
                                        to: startOfDay(evaluation.dateRange.to),
                                    },
                                    "long"
                                )}
                                borderBottomWidth={0}
                                px={0}
                            />
                            <SimpleListItem
                                label={t("value")}
                                content={measurementRange}
                                borderBottomWidth={0}
                                px={0}
                            />
                        </VStack>
                        <VStack
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("evaluatingDoctor")}
                                content={evaluation.doctor.fullName}
                                borderBottomWidth={0}
                                px={0}
                            />
                            <SimpleListItem
                                label={t("dataProviderDevice")}
                                content={devices
                                    .map((device) => device.deviceName)
                                    .join(", ")}
                                borderBottomWidth={0}
                                px={0}
                            />
                        </VStack>
                    </Stack>
                    <Stack
                        width="full"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        <Box
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("alertLevel")}
                                content={
                                    alertLevel ? (
                                        <StatusBadge level={alertLevel} />
                                    ) : (
                                        "-"
                                    )
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </Box>
                        <Box
                            width={{
                                base: "full",
                                md: "1/2",
                            }}
                        >
                            <SimpleListItem
                                label={t("measures:type")}
                                content={
                                    <Tag size="lg" variant="light">
                                        {t(
                                            `measures:${forHumans(
                                                evaluation.metricType
                                            )}`
                                        )}
                                    </Tag>
                                }
                                borderBottomWidth={0}
                                px={0}
                            />
                        </Box>
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default DetailsSection;
