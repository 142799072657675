import type { ReactNode } from "react";
import BleContext from "./BleContext";

interface Props {
    children: ReactNode;
}

const BleProvider = ({ children }: Props) => (
    <BleContext.Provider value={{ connect: undefined, disconnect: undefined }}>
        {children}
    </BleContext.Provider>
);

export default BleProvider;
