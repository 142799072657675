import type { ComponentProps, MutableRefObject, ReactNode } from "react";
import { forwardRef, useCallback, useMemo } from "react";
import { Button as BaseButton, useTheme } from "native-base";
import Icon from "../icons/Icon";
import useResponsive from "../util/useResponsive";
import type { IconName } from "../icons/icons";

interface Props
    extends Pick<
        ComponentProps<typeof BaseButton>,
        | "position"
        | "left"
        | "top"
        | "right"
        | "bottom"
        | "rounded"
        | "maxWidth"
        | "width"
        | "borderColor"
        | "borderWidth"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
        | "flex"
        | "isLoading"
        | "isLoadingText"
        | "spinnerPlacement"
        | "justifyContent"
    > {
    variant?:
        | "primary" // Filled Green
        | "destructive" // Filled Red
        | "gray" // Filled Gray
        | "outlined" // Filled White
        | "ghost" // Ghost Gray
        | "ghostGreen" // Ghost Green
        | "link" // Link Green
        | "ghostLink"; // Link Gray
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    isDisabled?: boolean;
    onPress: () => void;
    children: ReactNode;
    leftIconName?: IconName;
    rightIconName?: IconName;
    transparent?: boolean;
    forceIcon?: boolean;
}

const Button = forwardRef<typeof BaseButton, Props>(
    (
        {
            variant = "primary",
            size = "md",
            isDisabled = false,
            onPress,
            children,
            leftIconName,
            rightIconName,
            forceIcon,
            transparent = false,
            ...rest
        }: Props,
        ref
    ) => {
        const { colors } = useTheme();
        const { isSmallScreen } = useResponsive();

        const iconSizes = useMemo(
            () => ({
                xs: 16,
                sm: 18,
                md: 20,
                lg: 20,
                xl: 20,
                "2xl": 22,
            }),
            []
        );

        const iconVariants = useMemo(
            () => ({
                primary: colors.white,
                destructive: colors.white,
                gray: colors.gray[800],
                outlined: isDisabled ? colors.gray[300] : colors.gray[800],
                ghost: colors.gray[900],
                ghostGreen: colors.green[500],
                link: colors.green[600],
                ghostLink: colors.gray[800],
            }),
            [colors, isDisabled]
        );

        const renderIcon = useCallback(
            (iconName: IconName) => (
                <Icon
                    name={iconName}
                    fill={iconVariants[variant]}
                    size={iconSizes[size]}
                />
            ),
            [iconVariants, iconSizes, size, variant]
        );

        return (
            <BaseButton
                accessibilityRole="button"
                leftIcon={
                    !isSmallScreen || forceIcon
                        ? leftIconName && renderIcon(leftIconName)
                        : undefined
                }
                rightIcon={
                    !isSmallScreen || forceIcon
                        ? rightIconName && renderIcon(rightIconName)
                        : undefined
                }
                onPress={onPress}
                isDisabled={isDisabled}
                variant={variant}
                bgColor={transparent ? "transparent" : undefined}
                size={size}
                // For some reason the Button component from native-base
                // only accepts MutableRefObject, not ForwardedRef.
                ref={ref as MutableRefObject<typeof BaseButton>}
                {...rest}
            >
                {children}
            </BaseButton>
        );
    }
);

Button.displayName = "Button";

export default Button;
