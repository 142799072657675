import { AlertLevel, MetricType } from "@madmedical/medical";
import { HStack, Text, VStack } from "native-base";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../../atoms/PressableLink";
import {
    thumbnailRoot,
    thumbnailUnit,
    thumbnailValue,
    thumbnailValueSlot,
} from "../useStyles";
import RangeChart from "./RangeChart";

interface Props {
    value: string;
    unit: string;
    alertLevel: AlertLevel | null;
    measuredAt: Date;
}

const BodyTemperatureWidget = ({
    alertLevel,
    measuredAt,
    value,
    unit,
}: Props) => {
    const getIndex = () => {
        switch (alertLevel) {
            case AlertLevel.Ok:
                return 0;
            case AlertLevel.Warning:
                return 1;
            case AlertLevel.Alert:
                return 2;
            default:
                return 0;
        }
    };
    const { t } = useTranslation();

    return (
        <VStack style={thumbnailRoot}>
            <PressableLink
                route="measurements"
                params={{ metricType: MetricType.BodyTemperature }}
                flex={1}
            >
                <Text fontSize="sm" fontWeight="medium" color="black">
                    {t("measures:bodyTemp")}
                </Text>
                <HStack style={thumbnailValueSlot} space={1}>
                    <Text numberOfLines={1} style={thumbnailValue}>
                        {value}
                    </Text>
                    <Text style={thumbnailUnit}>{unit}</Text>
                </HStack>

                <VStack flex={1} justifyContent="flex-end" mb={2} minH="10">
                    {!!alertLevel && (
                        <RangeChart
                            length={3}
                            selectedIndex={getIndex()}
                            alertLevel={alertLevel}
                        />
                    )}
                </VStack>

                <Text
                    fontSize="xs"
                    color="gray.600"
                    textAlign="right"
                    textTransform="capitalize"
                >
                    {formatDateTime(measuredAt)}
                </Text>
            </PressableLink>
        </VStack>
    );
};

export default BodyTemperatureWidget;
