import {
    ConnectedDevices as ConnectedDevicesUi,
    DeviceProvider,
} from "@madmedical/ui";
import { useMe } from "@madmedical/user";
import { invariant, isWeb } from "@madmedical/utils";
import { useDismissPersist } from "@madmedical/storage";
import useDevices from "../useDevices";

const ConnectedDevices = () => {
    const { patient } = useMe();
    const { devices, isLoading } = useDevices();
    const { visible: appDownloadVisible, dismiss: dismissAppDownload } =
        useDismissPersist("appDownloadAlert");

    if (isLoading) {
        return null;
    }

    invariant(patient);

    return (
        <DeviceProvider
            value={{ devices: devices.filter((device) => device.isConnected) }}
        >
            <ConnectedDevicesUi
                maxDeviceCount={patient.maxDeviceCount}
                showAppDownload={isWeb && appDownloadVisible}
                onDismissAppDownload={dismissAppDownload}
            />
        </DeviceProvider>
    );
};

export default ConnectedDevices;
