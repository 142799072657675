import Svg, { Path, Rect } from "react-native-svg";

const Pea = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M91.1756 39.4746L43.4314 77.5168C32.5497 84.6685 26.2409 81.1554 23.1313 77.7555C22.8374 77.4302 22.6338 77.0336 22.5409 76.6051C22.448 76.1766 22.469 75.7313 22.6018 75.3135C22.7346 74.8956 22.9746 74.5199 23.2979 74.2238C23.6211 73.9276 24.0164 73.7213 24.4442 73.6255C29.4772 72.5161 34.3329 69.4043 37.6551 66.8573C39.8288 65.194 41.8203 63.3054 43.5965 61.2231C64.3719 36.8201 83.0482 31.3447 83.0482 31.3447L91.5282 34.1827L91.1756 39.4746Z"
            fill="#7B9791"
        />
        <Path
            d="M98.6439 24.3465C97.655 23.5736 96.5396 22.9779 95.3473 22.586C94.6472 22.3791 93.9057 22.3554 93.1939 22.5172C92.482 22.6791 91.8237 23.021 91.282 23.5104C88.7947 25.7165 82.7528 30.4353 76.9968 30.0058C76.9419 30.0027 76.8869 30.0116 76.8358 30.0321C76.7847 30.0526 76.7387 30.084 76.7012 30.1243C76.6637 30.1645 76.6355 30.2126 76.6186 30.265C76.6017 30.3174 76.5966 30.3728 76.6036 30.4274C76.7143 31.4048 77.4187 33.6068 81.5013 33.7446C89.5478 34.0165 94.5399 37.641 94.5399 37.641C94.5399 37.641 89.3088 33.0846 98.6439 24.3465Z"
            fill="#7B9791"
        />
        <Path
            d="M100.235 39.6592C100.235 39.6592 97.5354 48.7174 85.587 63.2588C72.0576 79.7249 46.2919 97.2593 46.2919 97.2593C46.2919 97.2593 40.3493 101.221 37.9873 103.431C37.9873 103.431 37.911 87.9641 62.6731 64.1163C87.4351 40.2686 99.6257 35.6973 99.6257 35.6973L100.235 39.6592Z"
            fill="#7B9791"
        />
        <Path
            d="M87.1126 54.6958C87.0813 54.7272 87.0496 54.7581 87.0177 54.7884C87.0177 54.7884 83.0671 57.3097 81.9945 51.9488C80.9218 46.5879 85.7813 46.3877 85.7813 46.3877C86.5139 46.7581 87.1483 47.2969 87.6324 47.9598C88.1166 48.6227 88.4368 49.3908 88.5667 50.2014C88.6967 51.0119 88.6328 51.8417 88.3802 52.6227C88.1276 53.4038 87.6935 54.1139 87.1135 54.6948L87.1126 54.6958Z"
            fill="#F6DCB6"
        />
        <Path
            d="M87.0138 54.7842C86.411 54.9353 85.7795 54.93 85.1794 54.7686C84.5792 54.6073 84.0302 54.2953 83.5844 53.8623C81.2909 51.7804 84.1026 48.1767 85.7786 46.39C84.9299 45.9611 83.9796 45.7736 83.0316 45.8479C82.0836 45.9223 81.1742 46.2557 80.4028 46.8117C79.6313 47.3676 79.0274 48.1249 78.657 49.0007C78.2866 49.8766 78.1639 50.8373 78.3024 51.7781C78.4408 52.7189 78.8351 53.6036 79.4421 54.3356C80.0491 55.0676 80.8455 55.6188 81.7444 55.929C82.6433 56.2392 83.6102 56.2964 84.5394 56.0946C85.4687 55.8927 86.3246 55.4394 87.0138 54.7842Z"
            fill="#FACD8A"
        />
        <Path
            d="M76.161 52.5558C77.1304 52.4888 78.0999 52.6851 78.9668 53.124C79.8338 53.5629 80.566 54.2279 81.086 55.0488C81.606 55.8697 81.8943 56.8159 81.9206 57.7873C81.9469 58.7586 81.7101 59.719 81.2353 60.5668C81.2353 60.5668 78.2424 63.5655 74.7088 59.7551C71.1751 55.9446 76.161 52.5558 76.161 52.5558Z"
            fill="#F6DCB6"
        />
        <Path
            d="M80.3423 61.7429C80.6908 61.3929 80.9892 60.9965 81.2293 60.565C79.9827 61.1792 78.1744 61.4671 76.3833 59.6122C74.0563 57.2021 75.1281 54.2971 76.1601 52.5615C75.292 52.6216 74.4514 52.8911 73.7102 53.347C72.969 53.8028 72.3493 54.4315 71.904 55.1791C71.4588 55.9267 71.2013 56.7711 71.1536 57.64C71.1059 58.5088 71.2694 59.3763 71.6301 60.1682C71.9908 60.9601 72.538 61.6528 73.2249 62.187C73.9117 62.7213 74.7177 63.0812 75.574 63.236C76.4303 63.3907 77.3114 63.3358 78.1418 63.0757C78.9722 62.8157 79.7272 62.3584 80.3423 61.7429Z"
            fill="#FACD8A"
        />
        <Path
            d="M67.1261 59.3603C68.2582 58.9496 69.4894 58.8985 70.6516 59.214C71.8138 59.5295 72.8502 60.1961 73.6191 61.1229C74.3881 62.0497 74.8521 63.1913 74.9478 64.3917C75.0434 65.5921 74.7661 66.7928 74.1535 67.8297C74.1535 67.8297 70.1175 71.2816 66.7273 66.9873C63.3371 62.6931 67.1261 59.3603 67.1261 59.3603Z"
            fill="#F6DCB6"
        />
        <Path
            d="M74.1535 67.8283C72.592 68.4894 70.6508 68.6146 68.7534 66.8707C65.9474 64.2917 66.468 61.1613 67.1266 59.3613C66.2465 59.6817 65.4562 60.209 64.8225 60.8987C64.1889 61.5885 63.7303 62.4204 63.4854 63.3245C63.2406 64.2286 63.2166 65.1783 63.4157 66.0935C63.6147 67.0088 64.0308 67.8628 64.629 68.5835C65.2272 69.3043 65.9898 69.8707 66.8527 70.235C67.7156 70.5993 68.6535 70.7508 69.5872 70.6767C70.5209 70.6027 71.4231 70.3053 72.2178 69.8096C73.0125 69.3138 73.6764 68.6343 74.1535 67.8283Z"
            fill="#FACD8A"
        />
        <Path
            d="M59.8092 66.535C60.9758 66.2295 62.2058 66.2679 63.3511 66.6456C64.4963 67.0233 65.508 67.7241 66.264 68.6636C67.02 69.6031 67.4882 70.7412 67.6121 71.9408C67.7361 73.1403 67.5104 74.3501 66.9624 75.4244C66.9624 75.4244 63.9502 78.7512 59.6644 74.7011C55.3785 70.6509 59.8092 66.535 59.8092 66.535Z"
            fill="#F6DCB6"
        />
        <Path
            d="M66.9611 75.4231C65.2478 76.109 62.8693 76.375 60.7254 74.1905C58.1784 71.5956 59.0237 68.3342 59.8095 66.5361C58.801 66.8008 57.8747 67.3136 57.1151 68.0278C56.3555 68.742 55.7866 69.6349 55.4603 70.6252C55.1341 71.6155 55.0608 72.6717 55.2471 73.6975C55.4334 74.7234 55.8735 75.6863 56.5272 76.4986C57.1808 77.3109 58.0274 77.9467 58.9897 78.3482C59.9519 78.7496 60.9994 78.9039 62.0365 78.797C63.0737 78.6901 64.0676 78.3254 64.9277 77.7361C65.7879 77.1468 66.4869 76.3516 66.9611 75.4231Z"
            fill="#FACD8A"
        />
        <Path
            d="M52.3655 73.6773C53.3662 73.4495 54.409 73.4869 55.3909 73.7858C56.3727 74.0846 57.2595 74.6346 57.9636 75.3813C58.6677 76.128 59.1646 77.0456 59.4053 78.0433C59.646 79.041 59.622 80.0842 59.3359 81.0698C59.3359 81.0698 56.5353 86.0741 52.2194 81.7574C47.9035 77.4407 52.3655 73.6773 52.3655 73.6773Z"
            fill="#F6DCB6"
        />
        <Path
            d="M57.8428 83.5923C58.5457 82.8911 59.0593 82.023 59.3353 81.0693C57.8471 82.3205 55.723 83.2686 53.6514 81.2755C51.2185 78.9352 51.7142 75.6821 52.3658 73.6787C51.4752 73.8816 50.6439 74.2888 49.9376 74.868C49.2313 75.4472 48.6693 76.1827 48.296 77.0164C47.9226 77.8501 47.7482 78.7591 47.7864 79.6718C47.8246 80.5844 48.0745 81.4757 48.5162 82.2752C48.9579 83.0748 49.5794 83.7607 50.3317 84.2788C51.084 84.797 51.9464 85.1332 52.8508 85.2609C53.7553 85.3887 54.6771 85.3044 55.5434 85.0148C56.4098 84.7252 57.1969 84.2382 57.8428 83.5923Z"
            fill="#FACD8A"
        />
        <Path
            d="M47.0598 91.4458C49.7332 91.4458 51.9003 89.2786 51.9003 86.6052C51.9003 83.9318 49.7332 81.7646 47.0598 81.7646C44.3864 81.7646 42.2192 83.9318 42.2192 86.6052C42.2192 89.2786 44.3864 91.4458 47.0598 91.4458Z"
            fill="#FACD8A"
        />
        <Path
            d="M95.0084 42.3722C93.6867 43.5692 92.2824 44.6716 90.8059 45.6712C84.1848 50.1459 47.5472 73.3908 41.4362 100.314C41.2133 101.299 40.7385 102.209 40.0584 102.955C39.0133 104.093 38.1515 105.385 37.5038 106.788C37.3935 107.037 37.2103 107.248 36.9781 107.392C36.746 107.535 36.4758 107.605 36.2031 107.593C35.9304 107.58 35.6679 107.485 35.4502 107.32C35.2324 107.156 35.0695 106.929 34.983 106.67C32.7444 100.068 31.1923 84.2417 54.2237 62.7502C56.5094 60.6171 58.7407 58.6327 60.9175 56.7973C68.1102 50.7508 75.9274 45.489 84.2365 41.1012C85.1581 40.6109 86.1098 40.0852 87.0236 39.5483C91.3736 39.0093 94.7056 39.3633 95.0084 42.3722Z"
            fill="#B6D2C6"
        />
        <Path
            d="M87.0234 39.5468C90.0521 42.5949 93.3974 42.6003 95.0082 42.3712C98.9336 38.8194 100.871 35.6064 100.871 35.6064L90.5698 37.2056C89.4388 38.0609 88.2543 38.8428 87.0234 39.5468Z"
            fill="#7B9791"
        />
        <Path
            d="M102.92 25.4844L106.086 27.3823C106.728 27.7677 107.198 28.3824 107.403 29.102C107.608 29.8215 107.532 30.5921 107.191 31.2576C106.662 32.242 106.018 33.1601 105.272 33.9925C102.682 36.9441 101.191 41.3952 102.341 45.2595C102.363 45.3324 102.362 45.4103 102.339 45.4827C102.315 45.5551 102.27 45.6186 102.209 45.6647C102.149 45.7108 102.076 45.7372 101.999 45.7406C101.923 45.7439 101.848 45.7239 101.784 45.6832C100.187 44.6683 97.1214 42.0633 98.5279 37.6449C98.5279 37.6449 93.0312 41.5414 87.7044 36.8188C87.6536 36.7727 87.6162 36.7137 87.5965 36.648C87.5768 36.5823 87.5753 36.5124 87.5924 36.446C87.6094 36.3795 87.6443 36.319 87.6932 36.2709C87.7421 36.2228 87.8033 36.189 87.87 36.1732C90.2055 35.6122 97.9137 33.2367 102.92 25.4844Z"
            fill="#B6D2C6"
        />
        <Path
            d="M71.5717 66.2518C72.635 66.2518 73.497 65.3899 73.497 64.3266C73.497 63.2633 72.635 62.4014 71.5717 62.4014C70.5084 62.4014 69.6465 63.2633 69.6465 64.3266C69.6465 65.3899 70.5084 66.2518 71.5717 66.2518Z"
            fill="#FFF3F0"
        />
        <Path
            d="M64.0073 73.8163C65.0705 73.8163 65.9325 72.9543 65.9325 71.8911C65.9325 70.8278 65.0705 69.9658 64.0073 69.9658C62.944 69.9658 62.082 70.8278 62.082 71.8911C62.082 72.9543 62.944 73.8163 64.0073 73.8163Z"
            fill="#FFF3F0"
        />
        <Path
            d="M79.1548 59.3194C79.9938 59.3194 80.6739 58.6393 80.6739 57.8003C80.6739 56.9614 79.9938 56.2812 79.1548 56.2812C78.3159 56.2812 77.6357 56.9614 77.6357 57.8003C77.6357 58.6393 78.3159 59.3194 79.1548 59.3194Z"
            fill="#FFF3F0"
        />
        <Path
            d="M86.1372 53.005C86.7845 53.005 87.3092 52.4803 87.3092 51.833C87.3092 51.1858 86.7845 50.6611 86.1372 50.6611C85.49 50.6611 84.9653 51.1858 84.9653 51.833C84.9653 52.4803 85.49 53.005 86.1372 53.005Z"
            fill="#FFF3F0"
        />
        <Path
            d="M56.7105 79.8936C57.3577 79.8936 57.8824 79.369 57.8824 78.7217C57.8824 78.0745 57.3577 77.5498 56.7105 77.5498C56.0633 77.5498 55.5386 78.0745 55.5386 78.7217C55.5386 79.369 56.0633 79.8936 56.7105 79.8936Z"
            fill="#FFF3F0"
        />
    </Svg>
);

export default Pea;
