import { Dialog, Text, useShowToast } from "@madmedical/ui";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useDeleteInsulinAdministrationMutation } from "../api";

interface Props {
    id: Ulid | undefined;
    open: boolean;
    onClose: () => void;
}

const DeleteInsulinAdministrationModal = ({ id, open, onClose }: Props) => {
    const [deleteAdministration, { isLoading: isSubmitting }] =
        useDeleteInsulinAdministrationMutation();

    const showToast = useShowToast();
    const { t } = useTranslation();
    if (!id) {
        return null;
    }

    const handleConfirm = () => {
        void deleteAdministration(id)
            .unwrap()
            .then(() => {
                showToast(t("injectionDeleted"), { variant: "success" });
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            confirmLabel={t("delete")}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            onClose={onClose}
        >
            <Text>{t("sureToDeleteInjection")}</Text>
        </Dialog>
    );
};

export default DeleteInsulinAdministrationModal;
