import {
    CurrentPatientProvider,
    MeasurementList as MeasurementsUi,
    Spinner,
} from "@madmedical/ui";
import { useParams } from "@madmedical/routing";
import type { MetricType } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { invariant } from "@madmedical/utils";
import { adaptPatient, usePatient } from "@madmedical/user";
import { useCallback, useState } from "react";
import type { Measurement } from "../model";
import { useGetMeasurementListQuery } from "../api";
import DeleteMeasurementDialog from "./DeleteMeasurementDialog";

const adapt = ({ measuredAt, metric, ...rest }: Measurement) => ({
    measuredAt: new Date(measuredAt),
    displayValue: metric.displayValue,
    ...rest,
});

const MeasurementList = () => {
    const { metricType } = useParams<{ metricType: MetricType }>();
    invariant(metricType);
    const {
        patientId: userId,
        isSelfOrRepresented,
        patient,
        isLoading: isPatientLoading,
    } = usePatient();
    const [page, setPage] = useState(1);
    const { currentData, isLoading } = useGetMeasurementListQuery({
        userId,
        metricType,
        page,
    });
    const [deleteMeasurementId, setDeleteMeasurementId] = useState<Ulid>();

    const handleDeletePress = useCallback((id: Ulid) => {
        setDeleteMeasurementId(id);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDeleteMeasurementId(undefined);
    }, []);

    const handleEndReached = useCallback(() => {
        if (!currentData) {
            return;
        }

        const { currentPage, pageCount } = currentData.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [currentData]);

    if (isLoading || isPatientLoading) {
        return <Spinner />;
    }

    invariant(patient);

    return (
        <CurrentPatientProvider value={{ patient: adaptPatient(patient) }}>
            <MeasurementsUi
                metricType={metricType}
                data={currentData?.items.map(adapt) ?? []}
                onEndReached={handleEndReached}
                isPatient={isSelfOrRepresented}
                onDeletePress={handleDeletePress}
            />
            <DeleteMeasurementDialog
                id={deleteMeasurementId}
                open={!!deleteMeasurementId}
                onClose={handleDialogClose}
            />
        </CurrentPatientProvider>
    );
};

export default MeasurementList;
