import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "@madmedical/form";
import {
    PREGNANCY_SYMPTOMS_FOR_HUMANS,
    PregnancySymptom,
    PregnancyWellBeingStatus,
    WELL_BEING_STATUS_FOR_HUMANS,
} from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../../form/SubmitButton";
import Button from "../../atoms/Button";
import ModalContent from "../../molecules/ModalContent";
import DateTimeInput from "../../form/DateTimeInput";
import FormTextAreaControl from "../../form/FormTextAreaControl";
import PregnantMoodPics from "../../molecules/Pregnancy/PregnantMoodPics";
import useResponsive from "../../util/useResponsive";
import MultiSelect from "../../form/MultiSelect";

const moodTypesList = Object.values(PregnancyWellBeingStatus);

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
    isEdit?: boolean;
}

const PregnancyMoodModal = ({
    onClose,
    onSubmitPress,
    isSuccess,
    isEdit = false,
}: Props) => {
    const [selectedStatus, setSelectedStatus] =
        useState<PregnancyWellBeingStatus>();
    const [isValid, setIsValid] = useState<boolean>(false);

    const { isSmallScreen } = useResponsive();

    const { getValues, setValue, watch } = useFormContext<{
        createdAt: Date;
        status: PregnancyWellBeingStatus;
        symptoms: PregnancySymptom[];
        comment: string | null;
    }>();

    const tunetekLista = Object.values(PregnancySymptom).map((value) => ({
        key: value,
        text: t(`pregnancy:${PREGNANCY_SYMPTOMS_FOR_HUMANS[value]}`),
    }));

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    const selectStatus = useCallback(
        (status: PregnancyWellBeingStatus) => {
            setSelectedStatus(status);
            setValue("status", status);
        },
        [setValue]
    );

    const chosenSmytoms = watch("symptoms");
    const userSelectedStatus = watch("status");

    useEffect(() => {
        if (isEdit) {
            //TODO check useEffect
            const { comment, symptoms, status, createdAt } = getValues();
            setValue("createdAt", createdAt);
            setValue("comment", comment);
            setValue("symptoms", symptoms);
            setValue("status", status);
            selectStatus(status);
        }
    }, [isEdit, setValue, getValues, selectStatus]);

    useEffect(() => {
        setIsValid(false);
        if (chosenSmytoms?.length > 0 && userSelectedStatus) {
            setIsValid(true);
        }
    }, [chosenSmytoms, userSelectedStatus]);
    const formatMood = useCallback((status: PregnancyWellBeingStatus) => {
        switch (status) {
            case PregnancyWellBeingStatus.BadTemper:
                return WELL_BEING_STATUS_FOR_HUMANS[
                    PregnancyWellBeingStatus.BadTemper
                ];
            case PregnancyWellBeingStatus.Moody:
                return WELL_BEING_STATUS_FOR_HUMANS[
                    PregnancyWellBeingStatus.Moody
                ];
            case PregnancyWellBeingStatus.Fluctuating:
                return WELL_BEING_STATUS_FOR_HUMANS[
                    PregnancyWellBeingStatus.Fluctuating
                ];
            case PregnancyWellBeingStatus.Calm:
                return WELL_BEING_STATUS_FOR_HUMANS[
                    PregnancyWellBeingStatus.Calm
                ];
            case PregnancyWellBeingStatus.Cheerful:
                return WELL_BEING_STATUS_FOR_HUMANS[
                    PregnancyWellBeingStatus.Cheerful
                ];
            default:
                return "";
        }
    }, []);

    const onSubmit = () => {
        onSubmitPress();
    };
    const { t } = useTranslation();

    return (
        <ModalContent
            header={`${isEdit ? t("editTextInput") : t("newTextInput")}`}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmit}
                        label={t("save")}
                        isDisabled={!isValid}
                    />
                </>
            }
        >
            <DateTimeInput
                name="createdAt"
                isRequired
                label={t("appointment")}
            />
            <VStack space={4} pb={6} mt={4}>
                <Box>
                    <Text color="gray.600" fontWeight="medium" mb={1}>
                        {t("generalFeeling")}
                    </Text>
                    <HStack direction={"row"} width={"100%"} flexWrap={"wrap"}>
                        {moodTypesList.map((mood) => (
                            <Pressable
                                onPress={() => selectStatus(mood)}
                                key={mood}
                                mr={2}
                                mb={2}
                            >
                                <Box
                                    borderColor={
                                        selectedStatus === mood
                                            ? "green.500"
                                            : "gray.100"
                                    }
                                    borderWidth={
                                        selectedStatus === mood ? 2 : 1
                                    }
                                    p={2}
                                    borderRadius={5}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    w={isSmallScreen ? 60 : 100}
                                    h={isSmallScreen ? 60 : 100}
                                >
                                    <PregnantMoodPics status={mood} />
                                    {!isSmallScreen && (
                                        <Text
                                            mt={2}
                                            mb={-1}
                                            textAlign={"center"}
                                            fontSize={"xs"}
                                        >
                                            {t(`pregnancy:${formatMood(mood)}`)}
                                        </Text>
                                    )}
                                </Box>
                            </Pressable>
                        ))}
                    </HStack>
                </Box>

                <Box>
                    <MultiSelect
                        label={t("realizedSymptoms")}
                        name="symptoms"
                        list={tunetekLista}
                        size="md"
                        width={"100%"}
                        maxItems={5}
                        hint="Maximum 5 db."
                    />
                </Box>
                <FormTextAreaControl
                    label={t("comment")}
                    name="comment"
                    labelProps={{
                        _text: { color: "gray.600" },
                    }}
                    maxLength={100}
                    textareaHeight={isSmallScreen ? 160 : 100}
                    placeholder={t("addComment") + "..."}
                />
            </VStack>
        </ModalContent>
    );
};

export default PregnancyMoodModal;
