import { HStack } from "native-base";
import { useEffect, useState } from "react";
import { useFormContext } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";
import Modal from "../molecules/Modal";
import ModalContent from "../molecules/ModalContent";
import SubmitButton from "../form/SubmitButton";
import DateTimeInput from "../form/DateTimeInput";
import FormTextAreaControl from "../form/FormTextAreaControl";

interface Props {
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const AddCommentButtonMobile = ({ onSubmitPress, isSuccess }: Props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const { setValue } = useFormContext();

    useEffect(() => {
        if (isSuccess) {
            setModalOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isModalOpen) {
            setValue("date", new Date().toISOString());
        }
    }, [isModalOpen, setValue]);
    const { t } = useTranslation();

    return (
        <>
            <Button
                variant="outlined"
                leftIconName="chat"
                width="full"
                forceIcon={true}
                onPress={() => setModalOpen(true)}
            >
                {t("addComment")}
            </Button>
            <Modal onClose={() => setModalOpen(false)} open={isModalOpen}>
                <ModalContent
                    header={t("addComment")}
                    footer={
                        <SubmitButton
                            onPress={onSubmitPress}
                            label={t("save")}
                            width="full"
                        />
                    }
                >
                    <HStack mb={4}>
                        <DateTimeInput
                            name="date"
                            isRequired
                            label={t("appointment")}
                        />
                    </HStack>
                    <FormTextAreaControl
                        name="comment"
                        isRequired
                        label={t("comment")}
                        placeholder={t("addAComment")}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddCommentButtonMobile;
