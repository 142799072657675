import type { ComponentProps } from "react";
import { Box, Text } from "native-base";

interface Props extends Pick<ComponentProps<typeof Box>, "my" | "mx"> {
    title: string;
    message?: string;
}

const EmptyState = ({ title, message, ...rest }: Props) => (
    <Box {...rest}>
        <Text
            textAlign="center"
            fontWeight="medium"
            fontSize="md"
            color="black"
        >
            {title}
        </Text>
        {message && (
            <Text textAlign="center" fontSize="sm" color="gray.600">
                {message}
            </Text>
        )}
    </Box>
);

export default EmptyState;
