import type {
    OnFilterChange,
    OnSearchChange,
    OnSortChange,
} from "@madmedical/ui";
import { EvaluationProvider, Evaluations, Spinner } from "@madmedical/ui";
import type { AlertLevel, MetricType } from "@madmedical/medical";
import {
    getAlertLevelChoices,
    getMetricTypeChoices,
} from "@madmedical/medical";
import { useGetTreatingDoctorsQuery, usePatientId } from "@madmedical/user";
import type { Ulid } from "@madmedical/utils";
import { invariant, useDebounce } from "@madmedical/utils";
import { useCallback, useMemo, useState } from "react";
import type { OpinionFilter } from "../model";
import { useGetOpinionsQuery } from "../api";
import adaptOpinion from "../adaptOpinion";

// Do not get confused, opinions === (doctor's) evaluations, at least for now TODO: Clear it up

const Opinions = () => {
    const { patientId } = usePatientId();
    const { data: doctors } = useGetTreatingDoctorsQuery(patientId);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<OpinionFilter>({});
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300, () => {
        setPage(1);
    });
    const { data, isLoading } = useGetOpinionsQuery(
        {
            patientId,
            ...filter,
            search: debouncedSearch,
            page,
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        }
    );

    const filterChoices = useMemo(
        () => ({
            metricTypes: getMetricTypeChoices(filter.metricTypes),
            alertLevels: getAlertLevelChoices(filter.alertLevel),
            doctors:
                doctors?.map(({ userId, fullName }) => ({
                    key: userId,
                    text: fullName,
                    isSelected: !!filter.doctorIds?.includes(userId),
                })) ?? [],
        }),
        [filter, doctors]
    );

    const handleFilterChange = useCallback<
        OnFilterChange<typeof filterChoices>
    >((key, selected) => {
        setPage(1);

        // TODO: Why does type inference fail me?
        const diff =
            key === "metricTypes"
                ? { metricTypes: selected as MetricType[] }
                : key === "alertLevels"
                ? { alertLevel: selected[0] as AlertLevel }
                : key === "doctors"
                ? { doctorIds: selected as Ulid[] }
                : {};

        setFilter((prevFilter) => ({
            ...prevFilter,
            ...diff,
        }));
    }, []);

    const handleSearchChange: OnSearchChange = (search) => {
        setSearch(search);
    };

    const handleSortChange = useCallback<OnSortChange>((sort, order) => {
        setPage(1);

        if (!sort) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                sort: undefined,
                order: undefined,
            }));

            return;
        }

        setFilter((prevFilter) => ({
            ...prevFilter,
            sort: sort as OpinionFilter["sort"], // TODO: Better types
            order,
        }));
    }, []);

    const handleEndReached = useCallback(() => {
        if (!data) {
            return;
        }

        const { currentPage, pageCount } = data.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [data]);

    if (isLoading) {
        return <Spinner />;
    }

    invariant(data, "No opinions in sight");

    const { items: opinions, pagination } = data;

    return (
        <EvaluationProvider
            value={{
                evaluations: opinions.map(adaptOpinion),
                onSortChange: handleSortChange,
                onSearchChange: handleSearchChange,
                filterChoices,
                onFilterChange: handleFilterChange,
                pagination,
            }}
        >
            <Evaluations onEndReached={handleEndReached} />
        </EvaluationProvider>
    );
};

export default Opinions;
