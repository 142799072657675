import { Box, HStack, Text, VStack } from "native-base";
import { useEffect, useState } from "react";
import { useFormContext } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../../form/SubmitButton";
import Button from "../../atoms/Button";
import ModalContent from "../../molecules/ModalContent";
import FormControl from "../../form/FormControl";
import BabyCongratulations from "../../pics/Pregnancy/BabyCongratulations";
import DateTimeInput from "../../form/DateTimeInput";
import useResponsive from "../../util/useResponsive";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
}

const BirthCongratulationsModal = ({
    onClose,
    onSubmitPress,
    isSuccess,
}: Props) => {
    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);
    const { isSmallScreen } = useResponsive();

    const [isValid, setIsValid] = useState(false);

    const { watch } = useFormContext<{
        length: number;
        weight: number;
        headCircumference: number;
    }>();

    const babyLength = watch("length");
    const babyWeight = watch("weight");
    const babyHeadCircumference = watch("headCircumference");

    useEffect(() => {
        if (babyLength > 0 && babyWeight > 0 && babyHeadCircumference > 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [babyLength, babyWeight, babyHeadCircumference]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={t("pregnancy:yourBabyIsBorn")}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmitPress}
                        label={t("save")}
                        isDisabled={!isValid}
                    />
                </>
            }
        >
            <VStack space={4}>
                <Box my={1} height={150} display={"flex"} alignItems={"center"}>
                    <BabyCongratulations />
                </Box>

                <FormControl
                    label="Gyermek neve"
                    name="givenName"
                    isRequired
                    labelProps={{
                        _text: { color: "gray.800" },
                    }}
                    height="44px"
                />

                <DateTimeInput
                    name="bornAt"
                    label="Születés időpontja"
                    isRequired
                />

                <HStack
                    justifyContent="space-between"
                    space={isSmallScreen ? 2 : 4}
                    direction={isSmallScreen ? "column" : "row"}
                >
                    <FormControl
                        label="Hossz"
                        name="length"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        type="number"
                        height="44px"
                        maxLength={4}
                        isRequired
                        InputRightElement={
                            <Box px={2}>
                                <Text color="gray.500" fontSize="sm">
                                    cm
                                </Text>
                            </Box>
                        }
                    />
                    <FormControl
                        label="Súly"
                        name="weight"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        type="number"
                        height="44px"
                        maxLength={4}
                        isRequired
                        InputRightElement={
                            <Box px={2}>
                                <Text color="gray.500" fontSize="sm">
                                    g
                                </Text>
                            </Box>
                        }
                    />
                    <FormControl
                        label="Fejkörméret"
                        name="headCircumference"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        type="number"
                        height="44px"
                        maxLength={4}
                        isRequired
                        InputRightElement={
                            <Box px={2}>
                                <Text color="gray.500" fontSize="sm">
                                    cm
                                </Text>
                            </Box>
                        }
                    />
                </HStack>
            </VStack>
        </ModalContent>
    );
};

export default BirthCongratulationsModal;
