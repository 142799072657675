import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import FormControlWrapper from "../molecules/Form/FormControlWrapper";
import BaseSelect from "../atoms/Select";

type Props = Omit<ComponentProps<typeof FormControlWrapper>, "children"> &
    Omit<ComponentProps<typeof BaseSelect>, "onChange" | "value"> & {
        name: string;
        isRequired?: boolean;
    };

const FormSelect = ({
    name,
    isRequired,
    hint,
    label,
    labelProps,
    ...rest
}: Props) => {
    const { control, isError, error } = useField(name);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => (
                <FormControlWrapper
                    isInvalid={isError}
                    isRequired={isRequired}
                    error={error}
                    hint={hint}
                    label={label}
                    labelProps={labelProps}
                >
                    <BaseSelect {...rest} {...field} />
                </FormControlWrapper>
            )}
        />
    );
};

export default FormSelect;
