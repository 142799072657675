import type { MetricType } from "@madmedical/medical";
import { SectionList } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import type { Ulid } from "@madmedical/utils";
import ListHeader from "../../molecules/ListHeader";
import MeasurementListItem from "../../molecules/ListItem/MeasurementListItem";
import type { SectionType } from "./MeasurementList";

interface Props {
    sections: SectionType[];
    onEndReached: () => void;
    metricType: MetricType;
    onDeletePress: (id: Ulid) => void;
}

const MeasurementContentMobile = ({
    sections,
    metricType,
    onDeletePress,
    onEndReached,
}: Props) => {
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;

    return (
        <SectionList
            onLayout={(event) => {
                setFlatListPosition(event.nativeEvent.layout.y);
            }}
            scrollEventThrottle={16}
            onScroll={(event) => {
                const { contentOffset, layoutMeasurement, contentSize } =
                    event.nativeEvent;
                const paddingToBottom = 20;
                if (
                    layoutMeasurement.height + contentOffset.y >=
                    contentSize.height - paddingToBottom
                ) {
                    onEndReached?.();
                }
            }}
            maxHeight={screenHeight - flatListPosition - 4}
            width="full"
            minWidth="full"
            showsVerticalScrollIndicator={false}
            sections={sections}
            renderSectionHeader={({ section: { header } }) => {
                const { title, badge } = header;

                return <ListHeader title={title} badge={badge} />;
            }}
            renderItem={({ item }) => (
                <MeasurementListItem
                    {...item}
                    metricType={metricType}
                    onDeletePress={onDeletePress}
                />
            )}
            keyExtractor={(item, index) => `section-list-item-${index}`}
            stickySectionHeadersEnabled
        />
    );
};

export default MeasurementContentMobile;
