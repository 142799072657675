import type { ComponentProps } from "react";
import { Controller, useField } from "@madmedical/form";
import BaseFormControl from "../molecules/Form/FormControl";

const getPattern = (
    type: "text" | "password" | "number" | "email" | undefined
) => (type === "number" ? /^\d+([.,]\d+)?$/ : /.*/);

type Props = Omit<ComponentProps<typeof BaseFormControl>, "onChange"> & {
    name: string;
};

const FormControl = (props: Props) => {
    const { control, isError, error } = useField(props.name);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{
                required: props.isRequired,
                pattern: getPattern(props.type),
            }}
            render={({ field: { onBlur, onChange, value } }) => (
                <BaseFormControl
                    {...props}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value as string | undefined}
                    isInvalid={isError}
                    error={error}
                />
            )}
        />
    );
};

export default FormControl;
