import { Box, Text } from "native-base";
import { formatMeasuredAtShort } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Tooltip from "./Tooltip";
import {
    boxShadow,
    colors,
    tooltipCoordContentBox,
    tooltipCoordText,
} from "./useStyles";
import type { InsulinTooltipType, Styles } from "./types";

export default function InsulinTooltip({
    tooltip = {
        bloodSugar: 0,
        insertions: {
            rapid: 0,
            basal: 0,
            other: 0,
        },
        administeredAt: new Date(),
        status: "OK",
    },
}: {
    tooltip: InsulinTooltipType;
}) {
    const { t } = useTranslation();
    if (!tooltip) return null;
    const { bloodSugar, insertions, administeredAt } = tooltip;

    const styleTitle: Styles = {
        color: colors.black,
        fontSize: 16,
        fontWeight: "500",
        lineHeight: 24,
        marginBottom: 2,
    };

    return (
        <Tooltip
            tooltip={tooltip}
            styleArrow={{ borderTopColor: colors.white }}
            styleContentBox={{ ...tooltipCoordContentBox, ...boxShadow }}
        >
            <Text style={tooltipCoordText}>{t("measures:bloodSugar")}</Text>
            <Box flexDirection="row" alignItems="center" my={1}>
                <Box
                    rounded="full"
                    height={3}
                    width={3}
                    background={
                        tooltip?.status === "ALERT"
                            ? "#ff0000"
                            : tooltip?.status === "OK"
                            ? "#4CC8B0"
                            : "#FFCF47"
                    }
                    mr={2}
                    borderColor="8D39B5"
                    borderWidth={2}
                />
                <Text style={[tooltipCoordText, styleTitle]}>
                    {bloodSugar ? bloodSugar.toFixed(1) : "0.0"} mmo/l
                </Text>
            </Box>

            <Text style={tooltipCoordText}>
                {t("measures:insulinInsertions")}
            </Text>
            <Box flexDirection="row" alignItems="center" mt={1}>
                <Box
                    rounded="full"
                    height={3}
                    width={3}
                    background="#418DFF"
                    mr={2}
                />
                <Text style={[tooltipCoordText, styleTitle]}>
                    Gyors: {insertions?.rapid} E
                </Text>
            </Box>
            <Box flexDirection="row" alignItems="center">
                <Box
                    rounded="full"
                    height={3}
                    width={3}
                    background="#8DBBFA"
                    mr={2}
                />
                <Text style={[tooltipCoordText, styleTitle]}>
                    Bázis: {insertions?.basal} E
                </Text>
            </Box>
            <Box flexDirection="row" alignItems="center" mb={1}>
                <Box
                    rounded="full"
                    height={3}
                    width={3}
                    background="#A9B5BB"
                    mr={2}
                />
                <Text style={[tooltipCoordText, styleTitle]}>
                    Egyéb: {insertions?.other} E
                </Text>
            </Box>

            <Text style={tooltipCoordText}>
                {formatMeasuredAtShort(administeredAt)}
            </Text>
        </Tooltip>
    );
}
