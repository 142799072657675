/**
 * Generate full URL taking integrator base Path into account
 *
 * Only to be used on web!
 */
import useAppConfig from "./useAppConfig";

export default () => {
    const { baseUri } = useAppConfig();

    // path does not have a leading slash, can end with query params
    return (path: string) => `${baseUri}${path}`;
};
