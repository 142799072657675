import type { ComponentProps } from "react";
import type { FormControl as BaseFormControl } from "native-base";
import { useEffect, useRef } from "react";
import type { TextInput } from "react-native";
import FormControlWrapper from "./FormControlWrapper";
import Input from "./Input";

type InputProps = ComponentProps<typeof Input>;
type FormControlProps = ComponentProps<typeof BaseFormControl>;

interface Props
    extends Omit<InputProps, "type">,
        Pick<FormControlProps, "isInvalid" | "isRequired"> {
    error?: string;
    hint?: string;
    label?: string;
    placeholder?: string;
    labelProps?: ComponentProps<typeof BaseFormControl.Label>;
    type?: "text" | "password" | "number" | "email";
    step?: 1 | 0.1 | 0.01;
}

const FormControl = ({
    isInvalid = false,
    isRequired = false,
    error,
    hint,
    label,
    placeholder,
    labelProps,
    type,
    step,
    multiline,
    ...rest
}: Props) => {
    const inputRef = useRef<TextInput>(null);

    useEffect(() => {
        if (type !== "number") {
            return;
        }

        const input = inputRef.current;
        if (!input) {
            return;
        }

        input.setNativeProps({
            type,
            step,
        });
    }, [type, step]);

    const resolvedType = type === "number" || type === "email" ? "text" : type;
    const keyboardType =
        type === "number"
            ? "decimal-pad"
            : type === "email"
            ? "email-address"
            : "default";

    return (
        <FormControlWrapper
            isInvalid={isInvalid}
            isRequired={isRequired}
            error={error}
            hint={hint}
            label={label}
            labelProps={labelProps}
        >
            <Input
                ref={inputRef}
                type={resolvedType}
                keyboardType={keyboardType}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isRequired={isRequired}
                minHeight={multiline ? 20 : undefined}
                multiline={multiline}
                {...rest}
            />
        </FormControlWrapper>
    );
};

export default FormControl;
