import { useAppConfig } from "@madmedical/config";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";

const ConsultationButton = () => {
    const { onConsultationPress } = useAppConfig();
    const { t } = useTranslation();
    if (!onConsultationPress) {
        return null;
    }

    return (
        <Button
            onPress={onConsultationPress}
            variant="outlined"
            leftIconName="stethoscope"
            forceIcon
        >
            {t("needConsult")}
        </Button>
    );
};

export default ConsultationButton;
