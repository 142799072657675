import type { PatientDoctor } from "@madmedical/ui";
import {
    CurrentPatientProvider,
    PredictionList,
    PredictionProvider,
    Spinner,
} from "@madmedical/ui";
import { usePatient } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import { useCallback, useState } from "react";
import { useGetPredictionsQuery } from "../api";
import adaptPrediction from "../adaptPrediction";

const Predictions = () => {
    const { patientId, patient, isLoading: isPatientLoading } = usePatient();
    const [page, setPage] = useState(1);
    const { data, isLoading: isPredictionsLoading } = useGetPredictionsQuery({
        patientId,
        page,
    });

    const handleEndReached = useCallback(() => {
        if (!data) {
            return;
        }

        const { currentPage, pageCount } = data.pagination;

        if (currentPage >= pageCount) {
            return;
        }

        setPage(currentPage + 1);
    }, [data]);

    if (isPatientLoading || isPredictionsLoading) {
        return <Spinner />;
    }

    invariant(patient);
    invariant(data);

    return (
        <CurrentPatientProvider value={{ patient: patient as PatientDoctor }}>
            <PredictionProvider
                value={{
                    predictions: data.items.map(adaptPrediction),
                    pagination: data.pagination,
                }}
            >
                <PredictionList onEndReached={handleEndReached} />
            </PredictionProvider>
        </CurrentPatientProvider>
    );
};

export default Predictions;
