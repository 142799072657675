import Svg, { G, Mask, Path } from "react-native-svg";

const AlertBabyIcon = () => (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <Mask
            id="mask0_1388_101614"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <Path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
        </Mask>
        <G mask="url(#mask0_1388_101614)">
            <Path
                d="M8.97937 7.29207C9.01984 6.64551 8.52945 6.09039 7.88289 6.0491C7.2371 6.00867 6.68078 6.49941 6.63992 7.14559C6.56691 8.31137 6.84343 9.46035 7.41624 10.4512"
                stroke="#1FBA9C"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
            <Path
                d="M6.93393 13.0664C7.94045 12.0599 7.84014 10.3656 6.63291 9.4962C5.68553 8.8139 4.35623 9.01495 3.53068 9.8405L3.48729 9.8839C3.02963 10.3416 3.02963 11.0835 3.48729 11.5412C3.94494 11.9988 4.68693 11.9988 5.14459 11.5412L5.27666 11.4091L5.28857 11.425C5.01729 11.7841 4.50697 13.954 6.10529 15.5523C7.47822 16.9253 9.7042 16.9253 11.0771 15.5523L11.6572 14.9723C12.7637 13.8658 13.3351 12.4268 13.3715 10.977C13.372 10.9566 13.3724 10.9362 13.3727 10.9158C13.9583 10.7636 14.5129 10.4593 14.9717 10.0004C16.3447 8.62749 16.3447 6.40151 14.9717 5.02859C13.5988 3.65566 11.3728 3.65566 9.9999 5.02859C8.62697 6.40151 8.62697 8.62749 9.9999 10.0004C10.3455 10.346 10.7452 10.6047 11.1718 10.7763"
                stroke="#1FBA9C"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
            <Path
                d="M10 19.4141C4.82234 19.4141 0.585938 15.1777 0.585938 10C0.585938 4.82234 4.82234 0.585939 10 0.585939C15.1777 0.585939 19.4141 4.82234 19.4141 10C19.4141 15.1777 15.1777 19.4141 10 19.4141Z"
                stroke="#1FBA9C"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
            <Path
                d="M3.69238 3.06356C3.89687 3.42184 4.06727 3.8018 4.19965 4.19895C4.63879 5.51637 5.49617 6.64469 6.62949 7.41992"
                stroke="#1FBA9C"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
        </G>
    </Svg>
);

export default AlertBabyIcon;
