import type { BodyWeight } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { useMemo } from "react";
import { GraphWrapper } from "@madmedical/ui";
import type { CommonProps } from "../model";
import { mapCoords } from "../model";
import useChartThresholds from "../useChartThresholds";
import adaptTrend from "../../prediction/adaptTrend";

type Props = CommonProps<BodyWeight>;

const BodyWeightChart = ({
    data,
    dateRange,
    widget,
    predictionTrend,
}: Props) => {
    const thresholds = useChartThresholds(MetricType.BodyWeight, widget);

    const graphProps = useMemo(() => {
        const { min, max } = data.stats;

        return {
            type: "line-single",
            unit: "kg",
            limits: {
                yMax: max ? Math.ceil(max.kg + 0.25) : 80,
                yMin: min ? Math.floor(min.kg - 0.25) : 50,
            },
            thresholds,
            coords: mapCoords(data.items, dateRange, (metric) => ({
                yHigh: metric.kg,
            })),
        } as const;
    }, [data, dateRange, thresholds]);

    return (
        <GraphWrapper
            metricType={MetricType.BodyWeight}
            displayLast={data.stats.lastMetricWithoutUnit}
            widget={widget}
            predictionTrend={
                predictionTrend ? adaptTrend(predictionTrend) : undefined
            }
            graphProps={graphProps}
        />
    );
};

export default BodyWeightChart;
