import { default as t } from "../base/typography";

const baseStyle = () => ({
    borderRadius: "full",
});

const success = () => ({
    bg: "green.500",
    _text: {
        color: "white",
    },
});

const ghostSuccess = () => ({
    bg: "green.50",
    _text: {
        color: "green.600",
    },
});

const base = () => ({
    bg: "gray.400",
    _text: {
        color: "white",
    },
});

const ghost = () => ({
    bg: "gray.50",
    _text: {
        color: "gray.600",
    },
});

const dark = () => ({
    bg: "black",
    _text: {
        color: "white",
    },
});

const light = () => ({
    bg: "white",
    _text: {
        color: "black",
    },
});

const error = () => ({
    bg: "error.400",
    _text: {
        color: "white",
    },
});

const ghostError = () => ({
    bg: "rose.50",
    _text: {
        color: "rose.500",
    },
});

const warning = () => ({
    bg: "yellow.500",
    _text: {
        color: "white",
    },
});

const ghostWarning = () => ({
    bg: "yellow.50",
    _text: {
        color: "yellow.600",
    },
});

const variants = {
    success,
    ghostSuccess,
    base,
    ghost,
    dark,
    light,
    error,
    ghostError,
    warning,
    ghostWarning,
};

const sizes = {
    xs: {
        px: 1.5,
        py: 0,
        minHeight: 4,
        _text: {
            fontSize: "2xs",
            textTransform: "uppercase",
            lineHeight: 1,
            style: {
                fontWeight: t.fontWeights.medium,
            },
        },
    },
    sm: {
        px: 1.5,
        py: 0.5,
        minHeight: 5,
        _text: {
            fontSize: "xs",
            lineHeight: 1,
            style: {
                fontWeight: t.fontWeights.medium,
            },
        },
    },
    md: {
        px: 2,
        py: 0.5,
        minHeight: "22px",
        _text: {
            fontSize: "xs",
            lineHeight: 1,
        },
    },
    lg: {
        px: 2,
        py: 0.5,
        minHeight: 6,
        _text: {
            fontSize: "sm",
            lineHeight: 1,
        },
    },
    xl: {
        px: 2.5,
        py: 1,
        minHeight: "28px",
        _text: {
            fontSize: "sm",
            lineHeight: 1,
        },
    },
    xxl: {
        px: 2.5,
        py: 1,
        minHeight: 8,
        _text: {
            fontSize: "sm",
            lineHeight: 1,
        },
    },
};

export default {
    baseStyle,
    variants,
    sizes,
};
