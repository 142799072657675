import {
    ChartProvider,
    ChartWidget,
    PredictionChartCard,
} from "@madmedical/ui";
import { invariant, rangeToDate } from "@madmedical/utils";
import { usePatientId } from "@madmedical/user";
import Chart from "../../charts/Chart";
import type { Prediction, Trend } from "../model";
import { useGetMeasurementRangeQuery } from "../../measurement/api";

interface Props {
    prediction: Prediction;
    trend: Trend;
}

const PredictionChart = ({
    prediction: { dateRange, extendedRange },
    trend,
}: Props) => {
    const { metricType } = trend;

    const { patientId } = usePatientId();
    const { data, isLoading } = useGetMeasurementRangeQuery({
        userId: patientId,
        metricType,
        queryRange: extendedRange,
        statsRange: dateRange,
    });

    if (isLoading) {
        return null;
    }

    invariant(data);

    const { worstAlertLevel, displayRange, unit } = data.stats;

    return (
        <ChartProvider
            value={{
                chart: (
                    <Chart
                        metricType={metricType}
                        data={data}
                        dateRange={rangeToDate(extendedRange)}
                        widget={ChartWidget.Page}
                        predictionTrend={trend}
                    />
                ),
                metricType,
                dateRange: rangeToDate(dateRange),
                displayRange: displayRange ? `${displayRange} ${unit}` : null,
                alertLevel: worstAlertLevel,
            }}
        >
            <PredictionChartCard />
        </ChartProvider>
    );
};

export default PredictionChart;
