import { useMemo, useState } from "react";
import type { MetricType } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import { Dimensions } from "react-native";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../../atoms/PressableLink";
import type { GraphProps } from "../types";
import GraphThumbnail from "../GraphThumbnail";
import { ChartWidget } from "./model";

type Widget = Exclude<ChartWidget, ChartWidget.Page>;

const screenWidth = Dimensions.get("window").width;

const getGraphSize = (widget: Widget, graphWidth: number | undefined) => {
    switch (widget) {
        case ChartWidget.DashboardSingle:
            return isWeb
                ? { x: graphWidth ?? 160, y: 120 }
                : {
                      x: graphWidth ?? screenWidth / 2.8,
                      y: screenWidth / 2.8 / 2,
                  };
        case ChartWidget.DashboardTwoByTwo:
            return isWeb
                ? { x: graphWidth ?? 320, y: 240 }
                : {
                      x: graphWidth ?? screenWidth / 2.8,
                      y: screenWidth / 2.8 / 2,
                  };
    }
};

interface Props {
    metricType: MetricType;
    widget: Widget;
    displayLast: string | null;
    graphProps: Pick<GraphProps, "type" | "unit" | "limits" | "coords">;
    boxHeight?: number;
}

const GraphThumbnailWrapper = ({
    metricType,
    widget,
    displayLast,
    graphProps,
}: Props) => {
    // TODO: Dashboard widget should probably be responsive too. See GraphWrapper.tsx
    const [graphWidth] = useState<number>();
    const { t } = useTranslation();
    const resolvedProps = useMemo(
        () => ({
            ...graphProps,
            slot: getGraphSize(widget, graphWidth),
            paddingPercents: { x: 2, y: 2 },
            dashboardThumbnail: {
                title: t(`measures:${forHumans(metricType)}`),
                isDashboardThumbnail: true,
                showLineGraphNodes: true,
                valueToDisplay: displayLast,
            },
        }),
        [graphProps, widget, graphWidth, t, metricType, displayLast]
    );

    return (
        <PressableLink route="measurements" params={{ metricType }}>
            <GraphThumbnail graphProps={resolvedProps} />
        </PressableLink>
    );
};

export default GraphThumbnailWrapper;
