import Svg, { Path, Rect } from "react-native-svg";

const HalloweenPumpkin = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M42.6764 52.7698C34.9498 57.9234 28.9828 65.6679 27.2799 74.7104C26.8233 77.0733 26.507 79.4612 26.3329 81.8615C27.8809 96.8949 43.8034 104.851 63.5436 103.418C86.4703 102.678 101.264 96.9273 103.719 83.5754C103.822 81.3595 103.739 79.1389 103.472 76.9368C102.112 65.2125 96.7272 58.2692 91.5287 54.2245C89.8815 52.942 88.0632 51.8958 86.1267 51.1162L65.9689 54.2851C57.3496 52.3842 46.8925 50.4481 42.6764 52.7698Z"
            fill="#EDAF7C"
        />
        <Path
            d="M32.5611 22.0241C32.5611 22.0241 29.2924 31.5488 35.6044 36.2262C35.6044 36.2262 34.8154 38.1425 31.3776 37.5226C31.3776 37.5226 32.054 40.0586 35.1536 40.6785L33.1242 43.0456C33.1242 43.0456 36.111 47.0657 41.9724 43.5056C44.3426 42.0717 46.2904 40.0352 47.6175 37.6035C47.6175 37.6035 56.3439 34.7607 54.9913 28.7304C54.6479 27.0933 54.0676 25.5151 53.2687 24.0454L50.9898 26.2507L49.6369 23.3763L47.2698 26.307C47.2698 26.307 44.1707 19.5442 32.5611 22.0241Z"
            fill="#7B9791"
        />
        <Path
            d="M89.5064 93.1953C89.5064 93.1953 92.3205 82.2581 88.6887 71.1662C88.6207 70.9624 88.481 70.7903 88.2955 70.6818C88.1101 70.5733 87.8916 70.5359 87.6807 70.5765C87.4697 70.6171 87.2807 70.7329 87.1488 70.9025C87.0168 71.072 86.951 71.2837 86.9634 71.4982C87.4378 79.9467 86.4902 95.0215 74.1557 97.6589C70.9286 98.3489 67.9037 97.4286 65.2804 96.2456C64.9906 96.1014 60.4872 93.7449 59.93 86.288C59.8489 85.2083 58.2156 85.2243 58.063 86.2961C57.7063 88.836 57.6252 91.407 57.8211 93.9643C57.8211 93.9643 58.2481 97.8995 54.3475 98.935C50.6153 99.8058 46.1133 99.1601 43.2548 95.2052C41.0579 89.1605 41.523 82.2408 42.2306 77.7252C42.2796 77.4176 42.2189 77.1025 42.0593 76.835C41.8996 76.5676 41.651 76.3647 41.357 76.2619C41.0629 76.159 40.7421 76.1628 40.4505 76.2724C40.159 76.3821 39.9152 76.5907 39.7618 76.8618C38.2091 79.5319 37.4618 82.6729 37.0253 85.8496C36.4051 90.3668 33.0165 89.7791 32.8661 89.751C30.1851 88.9909 27.542 86.706 26.3322 81.8631C25.706 91.22 27.8145 101.962 41.3977 103.475C42.7024 103.623 43.9193 104.205 44.8529 105.128C46.2045 106.462 49.0697 108.209 54.8067 108.209C57.6567 108.243 60.4567 107.459 62.8751 105.951C63.3136 105.661 63.8316 105.516 64.3566 105.536C64.8817 105.555 65.3876 105.738 65.8034 106.059C67.2348 107.169 69.9865 108.522 74.8887 108.522C79.7523 108.522 82.8981 106.426 84.6686 104.691C85.5055 103.88 86.5653 103.337 87.7124 103.131C92.1371 102.314 102.927 98.7629 103.716 83.5745C101.495 88.4381 97.3167 92.7659 89.5064 93.1953Z"
            fill="#DD9051"
        />
        <Path
            d="M46.3976 53.3182C48.0909 54.9323 46.5058 57.6663 46.5058 57.6663C45.0516 60.1941 44.1238 62.99 43.7783 65.8857C43.7456 66.1386 43.8039 66.395 43.9427 66.6089C44.0816 66.8228 44.2921 66.9803 44.5364 67.0533C44.7808 67.1264 45.0432 67.1101 45.2766 67.0075C45.5101 66.9049 45.6995 66.7226 45.8109 66.4932C48.1705 61.6824 51.0016 59.0977 52.5992 57.922C54.1131 60.1343 56.3661 61.599 58.9632 62.4173C57.9149 66.1392 58.1408 69.981 58.6552 72.9489C58.7035 73.2209 58.8448 73.4678 59.055 73.6471C59.2652 73.8265 59.5312 73.9273 59.8075 73.9323C60.0837 73.9372 60.3531 73.846 60.5696 73.6743C60.7861 73.5026 60.9362 73.261 60.9942 72.9908C61.498 70.7026 62.1226 68.4426 62.8653 66.2204C63.1486 65.3782 63.6688 64.6356 64.3634 64.0815C65.058 63.5273 65.8977 63.1853 66.7818 63.0962C71.5574 62.6024 76.2738 60.6003 79.1672 57.5275C79.2061 57.4861 79.241 57.4463 79.2784 57.4038C81.709 58.8626 83.7701 60.8628 85.3011 63.2485C85.3946 63.3937 85.5357 63.5018 85.7002 63.5544C85.8646 63.607 86.0422 63.6008 86.2026 63.5369C86.363 63.4729 86.4962 63.3552 86.5793 63.2039C86.6625 63.0526 86.6904 62.877 86.6584 62.7074C86.2587 60.592 85.6132 58.5306 84.7351 56.565L84.7332 56.5618C84.6323 56.3428 84.5273 56.1253 84.4182 55.9094C83.9311 54.722 83.3196 52.2345 86.1251 51.1156C83.5632 50.0828 80.8283 49.5466 78.0661 49.5357C78.0661 49.5357 71.7176 49.721 67.9976 52.0954C66.9532 52.7115 65.9517 53.3976 65 54.1489C65 54.1489 61.468 48.2409 54.2601 48.6254C50.1471 48.8449 47.695 49.8671 46.2028 50.6619C44.9941 51.3051 43.8193 52.0077 42.6783 52.7695C42.8291 52.6785 44.7678 51.7645 46.3976 53.3182Z"
            fill="#DD9051"
        />
        <Path
            d="M63.8931 51.5853C58.685 49.2623 57.5291 45.9782 56.9287 43.2328C58.7205 43.2796 60.9474 42.7444 61.6888 40.7296C62.085 39.6527 62.2958 38.5427 61.9578 37.3743C61.8049 36.8308 61.5117 36.3372 61.1076 35.943C60.7035 35.5487 60.2027 35.2678 59.6556 35.1284C59.1204 34.9883 58.5577 34.9916 58.0241 35.138C57.4905 35.2844 57.0049 35.5687 56.6161 35.9624C55.4553 37.1224 54.8549 38.8709 54.8216 41.1677C46.268 39.5972 40.5253 29.3638 40.4663 29.2556C40.3436 29.0395 40.1408 28.8805 39.9017 28.813C39.6626 28.7454 39.4065 28.7747 39.1889 28.8946C38.9713 29.0144 38.8096 29.2152 38.7389 29.4534C38.6682 29.6916 38.6942 29.948 38.8111 30.1672C39.0747 30.6459 45.2173 41.6147 54.8841 43.0943C55.6366 46.9637 58.997 54.9287 63.3581 56.1133L63.8931 51.5853ZM57.9517 37.2969C58.1082 37.1354 58.3049 37.0186 58.5216 36.9587C58.7383 36.8988 58.9671 36.898 59.1843 36.9562C59.4126 37.0153 59.6213 37.1334 59.7895 37.2987C59.9577 37.464 60.0794 37.6706 60.1424 37.8979C60.3573 38.553 60.3058 39.2663 59.999 39.8837C59.3821 41.1157 57.715 41.3354 56.7087 41.3501C56.7135 39.5019 57.1378 38.1103 57.9517 37.2969Z"
            fill="#7B9791"
        />
        <Path
            d="M63.3578 56.1139C62.8333 55.9505 62.372 55.6291 62.0369 55.1937C61.7019 54.7582 61.5094 54.23 61.4859 53.6811C61.3698 51.3305 61.7348 46.7965 64.9997 40.8682C67.5603 36.2189 68.3036 33.1667 68.4322 31.3164L71.7106 33.4768C72.2365 33.8212 72.6469 34.3156 72.8886 34.8959C73.1304 35.4762 73.1922 36.1158 73.0663 36.7317C72.7605 38.1953 72.0902 40.3668 70.631 43.1779C69.0672 46.1893 68.9988 49.9142 69.8614 52.8555C70.0498 53.4932 69.9916 54.1785 69.6985 54.7754C69.4055 55.3723 68.8988 55.8373 68.279 56.0782C67.1022 56.5231 65.4565 56.752 63.3578 56.1139Z"
            fill="#7B9791"
        />
        <Path
            d="M42.8667 72.4814C43.3976 72.4814 43.8281 72.051 43.8281 71.52C43.8281 70.989 43.3976 70.5586 42.8667 70.5586C42.3357 70.5586 41.9053 70.989 41.9053 71.52C41.9053 72.051 42.3357 72.4814 42.8667 72.4814Z"
            fill="#DD9051"
        />
        <Path
            d="M79.169 77.5998C81.236 77.3879 82.6779 74.9362 82.3896 72.1238C82.1013 69.3114 80.192 67.2033 78.125 67.4152C76.058 67.6271 74.616 70.0787 74.9044 72.8911C75.1927 75.7036 77.102 77.8117 79.169 77.5998Z"
            fill="#F6DCB6"
        />
    </Svg>
);

export default HalloweenPumpkin;
