import type { ReactNode } from "react";
import { useRef } from "react";
import { AlertDialog, Stack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";

interface Props {
    open: boolean;
    confirmLabel: string;
    onConfirm: () => void;
    onClose: () => void;
    isSubmitting: boolean;
    header?: ReactNode;
    children: ReactNode;
}

const Dialog = ({
    open,
    confirmLabel,
    onConfirm,
    onClose,
    isSubmitting,
    header,
    children,
}: Props) => {
    const cancelRef = useRef(null);
    const { t } = useTranslation();

    return (
        <AlertDialog
            size="xs"
            isOpen={open}
            onClose={onClose}
            closeOnOverlayClick
            leastDestructiveRef={cancelRef}
            useRNModal
        >
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>{header}</AlertDialog.Header>
                <AlertDialog.Body>{children}</AlertDialog.Body>
                <AlertDialog.Footer>
                    <Stack
                        direction={{
                            base: "column-reverse",
                            md: "row",
                        }}
                        justifyContent="flex-end"
                        space={3}
                        width="full"
                    >
                        <Button
                            variant="outlined"
                            onPress={onClose}
                            ref={cancelRef}
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            variant="destructive"
                            onPress={onConfirm}
                            isLoading={isSubmitting}
                            isLoadingText={`${confirmLabel}…`}
                        >
                            {confirmLabel}
                        </Button>
                    </Stack>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    );
};

export default Dialog;
