import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { ErrorPage } from "@madmedical/ui";
import initSentry from "./initSentry";
import type { SentryConfig } from "./model";

interface Props {
    readonly sentryConfig: SentryConfig;
    readonly children: ReactNode;
}

const ErrorBoundary = ({ sentryConfig, children }: Props) => {
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        initSentry(sentryConfig);
        setInitialised(true);
    }, [sentryConfig]);

    if (!initialised) {
        return null;
    }

    return (
        <SentryErrorBoundary fallback={<ErrorPage />} showDialog>
            {children}
        </SentryErrorBoundary>
    );
};

export default ErrorBoundary;
