import type { ComponentProps, ReactNode } from "react";
import { Modal as BaseModal } from "native-base";
import { isWeb } from "@madmedical/utils";
import useResponsive from "../util/useResponsive";

interface Props extends Pick<ComponentProps<typeof BaseModal>, "height"> {
    open: boolean;
    onClose: () => void;
    size?: "xs" | "sm" | "md" | "full";
    children: ReactNode;
    hasCloseButton?: boolean;
}

const Modal = ({
    children,
    open,
    onClose,
    size = "md",
    hasCloseButton = true,
}: Props) => {
    const { isSmallScreen } = useResponsive();

    return (
        <BaseModal
            isOpen={open}
            onClose={onClose}
            size={isSmallScreen ? "full" : size}
            avoidKeyboard
            safeArea
            useRNModal
            mt={6}
            pb={6}
        >
            <BaseModal.Content
                maxHeight={isWeb ? (isSmallScreen ? "100vh" : "72vh") : "100%"}
                height={isWeb ? (isSmallScreen ? "100vh" : "auto") : "100%"}
                bgColor="white"
            >
                {hasCloseButton && <BaseModal.CloseButton />}
                {children}
            </BaseModal.Content>
        </BaseModal>
    );
};

export default Modal;
