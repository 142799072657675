import { ErrorBoundary } from "@madmedical/error";
import { NextHealthContent, NextHealthProvider } from "@madmedical/sdk";
import { useEffect } from "react";
import Router from "./Router";
import getStandaloneAppConfig from "./getStandaloneAppConfig";

const appConfig = getStandaloneAppConfig();

const App = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const language = queryParameters.get("language");
    if (language) {
        appConfig.language = language;
    }

    useEffect(() => {
        console.log(window.location.search);
    }, []);

    return (
        <ErrorBoundary sentryConfig={appConfig}>
            <Router>
                <NextHealthProvider appConfig={appConfig}>
                    <NextHealthContent />
                </NextHealthProvider>
            </Router>
        </ErrorBoundary>
    );
};

export default App;
