import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    CreateInsulinAdministrationRequest,
    CreateInsulinReminder,
    InsulinAdministration,
    InsulinProduct,
    InsulinProtocol,
    InsulinRangeFilter,
    InsulinReminder,
    InsulinReminderIdRequest,
    PatientIdRequest,
    UpdateInsulinAdministrationRequest,
    UpdateInsulinProtocol,
} from "./model";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            "insulin-administrations",
            "insulin-protocols",
            "insulin-reminders",
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getInsulinProducts: build.query<InsulinProduct[], void>({
                query: () => ({
                    url: `medical/insulin-products`,
                }),
            }),
            getInsulinAdministrations: build.query<
                Paginated<InsulinAdministration>,
                PatientIdRequest & PageQuery
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/insulin-administrations`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["insulin-administrations"],
            }),
            getInsulinAdministrationRange: build.query<
                InsulinAdministration[],
                PatientIdRequest & InsulinRangeFilter
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/insulin-administrations/range`,
                    params: {
                        ...params,
                    },
                }),
                providesTags: ["insulin-administrations"],
            }),
            createInsulinAdministration: build.mutation<
                InsulinAdministration,
                PatientIdRequest & CreateInsulinAdministrationRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/insulin-administrations`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["insulin-administrations"],
            }),
            updateInsulinAdministration: build.mutation<
                InsulinAdministration,
                UpdateInsulinAdministrationRequest
            >({
                query: ({ id, ...body }) => ({
                    url: `medical/insulin-administrations/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["insulin-administrations"],
            }),
            deleteInsulinAdministration: build.mutation<
                InsulinAdministration,
                Ulid
            >({
                query: (id) => ({
                    url: `medical/insulin-administrations/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["insulin-administrations"],
            }),
            getInsulinProtocols: build.query<InsulinProtocol[], void>({
                query: () => ({
                    url: `medical/insulin-protocols`,
                }),
                providesTags: ["insulin-protocols"],
            }),
            updateInsulinProtocol: build.mutation<
                InsulinProtocol,
                PatientIdRequest & UpdateInsulinProtocol
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/insulin-protocols`,
                    method: "PUT",
                    body,
                }),
                invalidatesTags: ["insulin-protocols"],
            }),
            getInsulinProcolByPatient: build.query<
                InsulinProtocol,
                PatientIdRequest
            >({
                query: ({ patientId }) => ({
                    url: `medical/patients/${patientId}/insulin-protocols`,
                }),
            }),
            getInsulinReminders: build.query<
                InsulinReminder[],
                PatientIdRequest
            >({
                query: ({ patientId }) => ({
                    url: `medical/patients/${patientId}/insulin-reminders`,
                }),
                providesTags: ["insulin-reminders"],
            }),
            createInsulinReminder: build.mutation<
                InsulinReminder,
                PatientIdRequest & CreateInsulinReminder
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/insulin-reminders`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["insulin-reminders"],
            }),
            updateInsulinReminder: build.mutation<
                InsulinReminder,
                InsulinReminderIdRequest & CreateInsulinReminder
            >({
                query: ({ insulinReminderId, ...body }) => ({
                    url: `medical/insulin-reminders/${insulinReminderId}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["insulin-reminders"],
            }),
            deleteInsulinReminder: build.mutation<InsulinReminder, Ulid>({
                query: (id) => ({
                    url: `medical/patients/insulin-reminders/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["insulin-reminders"],
            }),
        }),
    });

export const {
    useGetInsulinProductsQuery,
    useGetInsulinAdministrationsQuery,
    useGetInsulinAdministrationRangeQuery,
    useCreateInsulinAdministrationMutation,
    useUpdateInsulinAdministrationMutation,
    useDeleteInsulinAdministrationMutation,
    useGetInsulinProtocolsQuery,
    useUpdateInsulinProtocolMutation,
    useGetInsulinProcolByPatientQuery,
    useGetInsulinRemindersQuery,
    useCreateInsulinReminderMutation,
    useUpdateInsulinReminderMutation,
    useDeleteInsulinReminderMutation,
} = api;
