import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const Placenta17 = () => (
    <Svg width="131" height="132" viewBox="0 0 131 132" fill="none">
        <Path
            d="M128.167 65.5021C128.167 101.678 101.493 129.08 65.3174 129.08C43.3079 129.08 25.8644 117.653 12.1414 103.5C4.49713 92.7842 0 79.6685 0 65.5021C0 29.3263 29.3263 0 65.5021 0C80.174 0 93.7194 4.82387 104.639 12.9722C118.748 27.3458 128.167 43.9982 128.167 65.5021Z"
            fill="#FFDCD0"
        />
        <Circle cx="65" cy="65" r="58" fill="#D67088" />
        <Path
            d="M131.001 65.501C131.001 101.676 101.674 131.003 65.499 131.003C43.4893 131.003 24.0152 120.148 12.1406 103.498C23.0611 111.648 36.6044 116.471 51.2758 116.471C87.4509 116.471 116.778 87.1443 116.778 50.9692C116.778 36.8039 112.281 23.6876 104.634 12.9727C120.639 24.9135 131.001 43.9974 131.001 65.501Z"
            fill="#FFC7B6"
        />
        <Path
            d="M123.095 65.501C123.095 80.7576 117.201 95.1128 106.489 105.959C95.9848 116.598 81.6956 122.769 66.7476 123.082C55.4027 123.319 43.3681 119.833 34 114C39.1585 115.307 45.7104 116.471 51.2776 116.471C87.4526 116.471 116.78 87.1443 116.78 50.9693C116.78 46.4381 115.878 39.7702 115 35.5C115.87 36.4122 117.049 39.7085 117.605 40.8868C121.2 48.4835 123.095 56.8473 123.095 65.501Z"
            fill="#C65D6F"
        />
        <Path
            d="M71.7414 30.6234C75.059 30.6234 77.7484 27.934 77.7484 24.6164C77.7484 21.2988 75.059 18.6094 71.7414 18.6094C68.4238 18.6094 65.7344 21.2988 65.7344 24.6164C65.7344 27.934 68.4238 30.6234 71.7414 30.6234Z"
            fill="#C65D6F"
        />
        <Path
            d="M108.523 78.0456C110.501 78.0456 112.104 76.4421 112.104 74.4642C112.104 72.4863 110.501 70.8828 108.523 70.8828C106.545 70.8828 104.941 72.4863 104.941 74.4642C104.941 76.4421 106.545 78.0456 108.523 78.0456Z"
            fill="#C65D6F"
        />
        <Path
            d="M46.1283 109.981C48.1062 109.981 49.7096 108.378 49.7096 106.4C49.7096 104.422 48.1062 102.818 46.1283 102.818C44.1503 102.818 42.5469 104.422 42.5469 106.4C42.5469 108.378 44.1503 109.981 46.1283 109.981Z"
            fill="#C65D6F"
        />
        <Path
            d="M107.347 50.9323C109.325 50.9323 110.928 49.3289 110.928 47.3509C110.928 45.373 109.325 43.7695 107.347 43.7695C105.369 43.7695 103.766 45.373 103.766 47.3509C103.766 49.3289 105.369 50.9323 107.347 50.9323Z"
            fill="#C65D6F"
        />
        <Path
            d="M21.3298 73.8686C23.8333 73.8686 25.8628 71.8392 25.8628 69.3357C25.8628 66.8322 23.8333 64.8027 21.3298 64.8027C18.8263 64.8027 16.7969 66.8322 16.7969 69.3357C16.7969 71.8392 18.8263 73.8686 21.3298 73.8686Z"
            fill="#C65D6F"
        />
        <Path
            d="M88.637 26.5923C89.7288 26.5923 90.6138 25.7073 90.6138 24.6155C90.6138 23.5237 89.7288 22.6387 88.637 22.6387C87.5452 22.6387 86.6602 23.5237 86.6602 24.6155C86.6602 25.7073 87.5452 26.5923 88.637 26.5923Z"
            fill="#C65D6F"
        />
        <Path
            d="M55.2464 23.2505C56.3381 23.2505 57.2232 22.3655 57.2232 21.2737C57.2232 20.1819 56.3381 19.2969 55.2464 19.2969C54.1546 19.2969 53.2695 20.1819 53.2695 21.2737C53.2695 22.3655 54.1546 23.2505 55.2464 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M45.1057 23.2505C46.1975 23.2505 47.0826 22.3655 47.0826 21.2737C47.0826 20.1819 46.1975 19.2969 45.1057 19.2969C44.014 19.2969 43.1289 20.1819 43.1289 21.2737C43.1289 22.3655 44.014 23.2505 45.1057 23.2505Z"
            fill="#C65D6F"
        />
        <Path
            d="M20.555 54.9478C21.6467 54.9478 22.5318 54.0627 22.5318 52.971C22.5318 51.8792 21.6467 50.9941 20.555 50.9941C19.4632 50.9941 18.5781 51.8792 18.5781 52.971C18.5781 54.0627 19.4632 54.9478 20.555 54.9478Z"
            fill="#C65D6F"
        />
        <Path
            d="M26.1253 86.106C27.217 86.106 28.1021 85.221 28.1021 84.1292C28.1021 83.0374 27.217 82.1523 26.1253 82.1523C25.0335 82.1523 24.1484 83.0374 24.1484 84.1292C24.1484 85.221 25.0335 86.106 26.1253 86.106Z"
            fill="#C65D6F"
        />
        <Path
            d="M23.2268 93.7505C24.3186 93.7505 25.2037 92.8655 25.2037 91.7737C25.2037 90.6819 24.3186 89.7969 23.2268 89.7969C22.1351 89.7969 21.25 90.6819 21.25 91.7737C21.25 92.8655 22.1351 93.7505 23.2268 93.7505Z"
            fill="#C65D6F"
        />
        <Path
            d="M31.9261 93.2232C33.0178 93.2232 33.9029 92.3381 33.9029 91.2464C33.9029 90.1546 33.0178 89.2695 31.9261 89.2695C30.8343 89.2695 29.9492 90.1546 29.9492 91.2464C29.9492 92.3381 30.8343 93.2232 31.9261 93.2232Z"
            fill="#C65D6F"
        />
        <Path
            d="M65.3167 112.336C66.4085 112.336 67.2935 111.451 67.2935 110.36C67.2935 109.268 66.4085 108.383 65.3167 108.383C64.2249 108.383 63.3398 109.268 63.3398 110.36C63.3398 111.451 64.2249 112.336 65.3167 112.336Z"
            fill="#C65D6F"
        />
        <Path
            d="M73.6604 109.963C74.7522 109.963 75.6373 109.078 75.6373 107.987C75.6373 106.895 74.7522 106.01 73.6604 106.01C72.5687 106.01 71.6836 106.895 71.6836 107.987C71.6836 109.078 72.5687 109.963 73.6604 109.963Z"
            fill="#C65D6F"
        />
        <Path
            d="M98.5352 101.118C99.627 101.118 100.512 100.233 100.512 99.1409C100.512 98.0491 99.627 97.1641 98.5352 97.1641C97.4434 97.1641 96.5583 98.0491 96.5583 99.1409C96.5583 100.233 97.4434 101.118 98.5352 101.118Z"
            fill="#B74E67"
        />
        <Path
            d="M102.051 86.6548C103.143 86.6548 104.028 85.7698 104.028 84.678C104.028 83.5862 103.143 82.7012 102.051 82.7012C100.959 82.7012 100.074 83.5862 100.074 84.678C100.074 85.7698 100.959 86.6548 102.051 86.6548Z"
            fill="#C65D6F"
        />
        <Path
            d="M118.742 69.4537C119.834 69.4537 120.719 68.5686 120.719 67.4768C120.719 66.3851 119.834 65.5 118.742 65.5C117.651 65.5 116.766 66.3851 116.766 67.4768C116.766 68.5686 117.651 69.4537 118.742 69.4537Z"
            fill="#B74E67"
        />
        <Path
            d="M105.273 98.9247C104.117 101.535 102.413 103.877 100.301 105.8C98.1983 107.713 95.6974 109.197 92.9873 110.074C89.7292 111.12 86.2219 111.28 82.8442 110.831C79.5562 110.393 76.2981 109.436 73.3588 107.882C70.5689 106.407 68.078 104.355 66.3045 101.734C64.8298 99.5624 63.8633 97.0117 63.684 94.3912C62.6278 94.3912 61.5617 94.3015 60.5155 94.1421C57.5463 93.6738 54.6768 92.5878 52.1261 90.9936C49.7149 89.489 47.6025 87.5162 45.9585 85.2046C45.5301 85.0751 45.1116 84.9257 44.6931 84.7662C38.3463 82.2056 34.4903 76.1675 31.262 70.4683C27.8644 64.4701 24.9749 58.0535 23.6996 51.2383C22.4541 44.5527 22.753 37.4386 25.5628 31.1714C27.9939 25.7412 32.2783 20.9586 37.8381 18.657C43.1288 16.4649 49.4259 16.5347 54.4476 19.3843C55.0255 17.7901 57.0083 17.0926 58.6025 17.3816C60.6849 17.7503 62.3289 19.3444 63.8932 20.6597C65.6269 22.1144 67.3506 23.6089 68.895 25.2529C70.2501 26.6977 71.6251 28.3417 72.0436 30.3245C72.3724 31.8888 72.0137 33.7121 70.7881 34.7982C71.2166 35.386 71.6251 36.0038 71.9838 36.6415C72.7311 36.8009 73.4584 37.0201 74.1559 37.329C76.049 38.1659 77.6631 39.5409 78.779 41.2846C79.6857 42.6894 80.2337 44.3036 80.4031 45.9675C80.8515 46.2465 81.2799 46.5753 81.6486 46.9639C82.6549 47.9702 83.3325 49.2954 83.5218 50.7003C83.721 52.1151 83.462 53.5599 82.8044 54.8253C84.5978 57.2265 86.5208 59.5281 88.5634 61.7102C89.1313 61.3814 89.8886 61.3913 90.4167 61.7999C90.875 62.1486 91.134 62.7464 91.0543 63.3243C91.0045 63.683 90.8252 63.9919 90.7554 64.3307C90.6757 64.6893 90.8451 64.9584 91.0444 65.2473C91.3532 65.7156 91.4828 66.2038 91.2536 66.7419C91.0942 67.1006 90.7953 67.3995 90.4366 67.5489C90.7754 68.0471 90.8949 68.7047 90.6757 69.2826C90.5761 69.5317 90.4366 69.7609 90.2572 69.9402C90.5063 70 90.7554 70.0598 91.0045 70.1196C93.904 70.8967 96.644 72.2917 98.9954 74.1648C101.367 76.048 103.35 78.4094 104.715 81.1096C106.11 83.8496 106.867 86.8985 106.986 89.9773C107.096 93.0361 106.548 96.1249 105.302 98.9247H105.273Z"
            fill="#FFDCD0"
        />
        <Path
            d="M73.6088 40.0383C72.0844 36.4115 70.0319 33.0139 67.5509 29.955C65.0699 26.8961 62.1605 24.196 58.9422 21.9342C57.1189 20.6588 55.1859 19.5329 53.1832 18.5665C52.954 18.4569 52.7448 18.7956 52.974 18.9052C56.5111 20.5991 59.8091 22.811 62.7285 25.4414C65.6279 28.0519 68.1686 31.061 70.2411 34.3689C71.4069 36.2321 72.4232 38.195 73.28 40.2276C73.3797 40.4667 73.7284 40.2674 73.6288 40.0383H73.6088Z"
            fill="#DD8F8C"
        />
        <Path
            d="M82.4583 54.9942C81.2826 53.4299 79.8578 52.0848 78.2337 51.0088C77.6458 51.7062 76.9683 52.3339 76.231 52.872C74.019 54.4861 71.3288 55.1138 68.6187 55.0839C65.8288 55.054 63.049 54.4263 60.3887 53.5993C59.9104 55.2234 59.6015 56.8973 59.3823 58.5712C58.645 64.3103 58.8343 70.428 55.9747 75.649C55.8452 75.8682 55.5164 75.659 55.636 75.4398C56.4032 74.0349 56.9611 72.5304 57.3696 70.986C58.8642 65.2569 58.3262 59.179 60.01 53.4897C59.9801 53.4897 59.9503 53.4698 59.9204 53.4598C59.203 53.2307 58.4856 52.9816 57.7881 52.7126C56.9014 52.3738 56.0245 52.0051 55.1577 51.5966C53.8823 51.0088 52.6369 50.3711 51.4113 49.6836C51.0327 49.4743 50.6541 49.2551 50.2755 49.0459C49.4186 50.112 48.4023 51.0287 47.2664 51.7859C47.0572 51.9254 46.8679 51.5767 47.0771 51.4472C48.1532 50.7397 49.1197 49.8629 49.9267 48.8566C47.7646 47.6311 45.5826 46.4354 43.1913 45.6881C42.9521 45.6084 43.0617 45.2398 43.3009 45.3095C46.4893 46.3059 49.3289 48.0994 52.2383 49.7035C53.155 50.2117 54.0916 50.6899 55.0381 51.1283C55.9149 51.5368 56.7918 51.9155 57.6885 52.2642C58.655 52.6428 59.6414 52.9816 60.6377 53.2805C63.4077 54.1174 66.337 54.7651 69.2464 54.6953C71.8968 54.6256 74.4873 53.8484 76.5398 52.1247C78.433 50.5405 79.788 48.3385 80.2862 45.9073C80.336 45.6582 80.7146 45.7678 80.6648 46.0169C80.605 46.3158 80.5353 46.6148 80.4357 46.9137C80.0271 48.2887 79.3397 49.574 78.4529 50.6999C80.0969 51.7859 81.5416 53.151 82.7174 54.7252C82.8668 54.9245 82.5878 55.2035 82.4284 55.0042L82.4583 54.9942Z"
            fill="#DD8F8C"
        />
        <Path
            d="M73.8283 75.021C74.0973 72.7493 74.3563 70.4875 74.6254 68.2158C74.9243 65.665 75.2232 63.1143 75.5221 60.5636C75.552 60.3145 75.1534 60.3046 75.1235 60.5636C74.6054 64.9775 74.0873 69.3915 73.5692 73.8054C73.3301 75.848 73.1607 77.9503 72.4632 79.9032C71.8455 81.6269 70.7594 83.0717 69.3247 84.1976C67.7902 85.4032 65.9669 86.2102 64.1136 86.7981C63.8745 86.8778 63.9642 87.2564 64.2133 87.1867C67.5312 86.1405 70.889 84.2872 72.4433 81.0191C73.3301 79.146 73.5891 77.0735 73.8382 75.0309L73.8283 75.021Z"
            fill="#DD8F8C"
        />
        <Path
            d="M90.5183 67.5098C90.5183 67.5098 90.4485 67.4301 90.3987 67.4102C90.3489 67.4002 90.2991 67.4102 90.2492 67.4301C90.2492 67.4301 90.2692 67.4301 90.2791 67.4201C90.2094 67.45 90.1396 67.46 90.0699 67.4699C90.0002 67.4699 89.9204 67.4699 89.8507 67.4699C89.8507 67.4699 89.8308 67.4699 89.8208 67.4699C89.771 67.46 89.7212 67.4699 89.6713 67.4899C89.6315 67.5098 89.5916 67.5596 89.5717 67.6094C89.5518 67.7091 89.6016 67.8286 89.7012 67.8585C89.8208 67.8884 89.9404 67.8984 90.05 67.8884C90.1895 67.8785 90.309 67.8486 90.4286 67.7987C90.4684 67.7788 90.5083 67.719 90.5282 67.6792C90.5382 67.6294 90.5282 67.5696 90.5083 67.5297L90.5183 67.5098Z"
            fill="#DD8F8C"
        />
        <Path
            d="M90.3486 69.9102C89.1828 69.3423 87.8776 69.3324 86.6421 69.0135C85.4464 68.7046 84.5397 67.9872 83.9419 66.9112C83.653 66.403 83.4836 65.8749 83.2943 65.3269C83.1348 64.8387 82.8658 64.3804 82.3178 64.2608C81.7598 64.1413 81.2218 64.3804 80.7734 64.6992C80.5343 64.8686 80.3151 65.0679 80.0561 65.2273C79.7073 65.4465 79.3785 65.4365 78.9899 65.307C78.3124 65.0878 77.6648 64.8985 76.9573 64.8786C76.24 64.8487 75.5226 64.9583 74.8351 65.1974C74.5959 65.2771 74.6856 65.6657 74.9347 65.586C76.1802 65.1575 77.4755 65.2074 78.711 65.6358C78.98 65.7354 79.259 65.8251 79.5579 65.8152C79.8468 65.8052 80.1158 65.6757 80.355 65.5162C80.8233 65.1974 81.2617 64.7192 81.8595 64.6494C82.1584 64.6095 82.4573 64.6793 82.6566 64.9184C82.8459 65.1476 82.9156 65.4465 82.9953 65.7155C83.1548 66.2237 83.354 66.7219 83.6231 67.1802C84.181 68.1367 84.9881 68.884 86.0343 69.2526C87.3595 69.7209 88.8441 69.6213 90.1294 70.259C90.3585 70.3686 90.5678 70.0298 90.3386 69.9202L90.3486 69.9102Z"
            fill="#DD8F8C"
        />
        <Path
            d="M105.132 83.7504C103.508 85.514 102.591 87.8654 102.601 90.2567C102.601 90.5158 102.999 90.5158 102.999 90.2567C102.999 87.9651 103.856 85.7133 105.401 84.0294C105.57 83.8401 105.301 83.5512 105.132 83.7405V83.7504Z"
            fill="#DD8F8C"
        />
        <Path
            d="M105.692 86.9685C104.506 87.8353 103.659 89.1306 103.321 90.5654C103.261 90.8145 103.639 90.9241 103.699 90.675C104.018 89.3 104.825 88.0844 105.961 87.2574C106.17 87.108 105.891 86.819 105.692 86.9685Z"
            fill="#DD8F8C"
        />
        <Path
            d="M106.298 89.3197C106.249 89.22 106.129 89.2001 106.029 89.2399C105.222 89.5986 104.535 90.1965 104.077 90.9437C103.947 91.1629 104.276 91.3722 104.415 91.153C104.844 90.4555 105.481 89.9175 106.219 89.5887C106.318 89.5488 106.348 89.4093 106.298 89.3197Z"
            fill="#DD8F8C"
        />
        <Path
            d="M80.3441 93.5533C80.374 92.3676 79.9655 91.1919 79.3278 90.2055C79.1883 89.9963 78.8396 90.1856 78.9791 90.3948C79.9954 91.9591 80.4139 94.1013 79.2083 95.6856C78.0824 97.1602 76.0896 97.6285 74.3559 97.1502C72.413 96.6221 70.9384 95.1475 69.9719 93.4338C69.4837 92.5669 69.0951 91.6303 68.8161 90.6738C68.5272 89.6974 68.3578 88.6412 68.567 87.6349C68.7663 86.6485 69.3243 85.672 70.2409 85.2037C71.0978 84.7653 72.2337 84.8251 72.9411 85.5126C73.1204 85.6919 73.4094 85.4229 73.23 85.2436C72.5625 84.5959 71.586 84.3967 70.6992 84.6159C69.7527 84.845 69.0054 85.5524 68.577 86.3994C67.5706 88.412 68.288 90.7834 69.1648 92.7064C70.0616 94.6593 71.4764 96.4229 73.509 97.2698C75.3523 98.047 77.5543 97.8377 79.0488 96.4528C79.8659 95.6955 80.3242 94.6593 80.3541 93.5533H80.3441Z"
            fill="#DD8F8C"
        />
        <Path
            d="M77.8517 92.4091C77.8018 91.3032 77.214 90.2171 76.3471 89.5296C75.8988 89.1709 75.3408 88.9318 74.7629 88.9318C74.185 88.9318 73.637 89.161 73.089 89.3303C72.4812 89.5097 71.8934 89.5595 71.2955 89.3403C70.7176 89.1311 70.2294 88.7026 69.9405 88.1546C69.8209 87.9255 69.4722 88.1148 69.5917 88.3439C70.0202 89.161 70.8472 89.7289 71.7638 89.8684C72.2521 89.9381 72.7204 89.8684 73.1886 89.7189C73.6968 89.5595 74.2149 89.3403 74.7629 89.3303C75.8689 89.3303 76.7656 90.3068 77.1841 91.2434C77.6125 92.2099 77.5627 93.3457 76.9151 94.2026C76.2176 95.1093 74.8127 95.3983 73.916 94.6012C73.7267 94.4318 73.4377 94.7008 73.627 94.8702C74.4142 95.5676 75.6098 95.6473 76.4966 95.0993C77.4033 94.5414 77.9115 93.4553 77.8616 92.3992L77.8517 92.4091Z"
            fill="#DD8F8C"
        />
        <Path
            d="M85.8545 71.1947C84.3998 71.9719 83.3336 73.3967 83.0248 75.0108C82.975 75.2599 83.3536 75.3695 83.4034 75.1204C83.7023 73.596 84.6787 72.2708 86.0438 71.5335C86.2729 71.4139 86.0836 71.0652 85.8545 71.1848V71.1947Z"
            fill="#5D6982"
        />
        <Path
            opacity="0.3"
            d="M79.6475 82.1348C78.9301 82.7426 77.9337 82.9219 77.037 82.7326C76.0605 82.5333 75.2535 81.8658 74.7254 81.0487C74.018 79.9527 73.8087 78.6176 73.998 77.3522C74.0479 76.1167 74.4863 74.9111 75.4129 74.0841C76.1801 73.4066 77.2263 73.0778 78.2227 73.3468C79.2888 73.6357 79.8766 74.5125 80.1556 75.5288C80.4844 76.6846 80.7036 77.95 80.7136 79.1556C80.7136 80.2218 80.4944 81.4174 79.6475 82.1348Z"
            fill="#D16B7D"
        />
        <Mask
            id="mask0_1024_101619"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="116"
            height="116"
        >
            <Circle cx="65" cy="65" r="58" fill="#D67088" />
        </Mask>
        <G mask="url(#mask0_1024_101619)">
            <Path
                d="M56.3819 53.5818L56.964 52.1994L54.1991 51.0352L53.617 52.4176L56.3819 53.5818ZM33.9987 36.9997L32.898 35.9807L33.9987 36.9997ZM59.5019 37.9997L58.2219 37.2175L59.5019 37.9997ZM113.441 30.6678C114.086 30.1481 114.188 29.2038 113.668 28.5587C113.148 27.9135 112.204 27.8118 111.559 28.3315L113.441 30.6678ZM53.617 52.4176C51.7822 56.7751 49.0826 58.5655 46.3859 58.9271C43.5881 59.3024 40.442 58.1877 37.7536 56.0197C35.0751 53.8595 33.027 50.7887 32.3503 47.5594C31.6856 44.3873 32.3255 41.0149 35.0994 38.0188L32.898 35.9807C29.4225 39.7347 28.5629 44.1124 29.4141 48.1746C30.2533 52.1798 32.7365 55.8276 35.8704 58.3549C38.9945 60.8744 42.942 62.4159 46.7847 61.9005C50.7287 61.3715 54.2165 58.7243 56.3819 53.5818L53.617 52.4176ZM35.0994 38.0188C37.0538 35.9077 39.0436 35.3691 41.0586 35.5445C43.1975 35.7307 45.4403 36.7291 47.7723 37.9343C49.9699 39.07 52.3896 40.4811 54.4849 41.0499C55.5745 41.3457 56.7543 41.472 57.9017 41.1294C59.0929 40.7738 60.0545 39.972 60.7818 38.7818L58.2219 37.2175C57.8109 37.8901 57.4066 38.1464 57.0435 38.2548C56.6365 38.3763 56.0735 38.3726 55.2708 38.1547C53.5817 37.6961 51.6193 36.5455 49.1496 35.2691C46.8144 34.0623 44.1048 32.7983 41.3188 32.5558C38.4088 32.3025 35.5011 33.1689 32.898 35.9807L35.0994 38.0188ZM60.7818 38.7818C64.7678 32.2592 68.7156 29.6037 72.5269 28.7865C76.4271 27.9502 80.5032 28.9565 84.952 30.4811C89.2701 31.9609 94.0251 33.9744 98.7375 34.6337C103.59 35.3127 108.56 34.5998 113.441 30.6678L111.559 28.3315C107.44 31.6497 103.347 32.2493 99.1532 31.6626C94.8191 31.0563 90.5434 29.2259 85.9245 27.6431C81.4364 26.1051 76.6692 24.8301 71.898 25.8531C67.0379 26.8953 62.4859 30.2399 58.2219 37.2175L60.7818 38.7818Z"
                fill="#DD8F8C"
            />
        </G>
    </Svg>
);

export default Placenta17;
