import { useEffect, useState } from "react";
import { Box, HStack, ScrollView, Text, VStack } from "native-base";
import type { Pregnancy } from "@madmedical/medical";
import { currentPregnancyWeek } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import PregnancyBodyImage from "../../molecules/Pregnancy/PregnancyBodyImage";
import PregnancyWeekInfosImage from "../../molecules/Pregnancy/PregnancyWeekInfosImage";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import PregnancyPageTabs from "../../organisms/Pregnancy/PregnancyPageTabs";
import PregnancyBodyWeekTexts from "../../molecules/Pregnancy/PregnancyBodyWeekTexts";
import useResponsive from "../../util/useResponsive";

interface Props {
    pregnancyData: Pregnancy;
}

const PregnancyBodyPageContent = ({ pregnancyData }: Props) => {
    const [weekNr, setWeekNr] = useState(1);
    const { t } = useTranslation();
    const { isSmallScreen } = useResponsive();

    useEffect(() => {
        if (pregnancyData) {
            const currentWeek = currentPregnancyWeek(
                new Date(pregnancyData?.startedAt),
                new Date()
            );
            setWeekNr(currentWeek);
        }
    }, [pregnancyData]);

    return (
        <ScrollView>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link route="dashboard" params={{}}>
                                {t("health")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>
                                {t("pregnancy:pregnancy2")}
                            </Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <VStack bg={"white"}>
                <PregnancyPageTabs />
                <Box width="full" p={isSmallScreen ? 0 : 6}>
                    <HStack
                        direction={isSmallScreen ? "column-reverse" : "row"}
                        justifyContent={"space-between"}
                    >
                        <Box
                            flex={1}
                            pl={isSmallScreen ? 4 : 0}
                            pr={4}
                            mt={isSmallScreen ? 4 : 0}
                            mb={isSmallScreen ? 16 : 8}
                        >
                            <Text fontWeight="bold" mb={6}>
                                {t("pregnancy:howYourBodyChanges")}
                            </Text>
                            <PregnancyBodyWeekTexts weekNr={weekNr} />
                        </Box>
                        <PregnancyWeekInfosImage
                            weekNr={weekNr}
                            dayNr={weekNr * 7}
                            setWeekNr={setWeekNr}
                        >
                            <PregnancyBodyImage week={weekNr} />
                        </PregnancyWeekInfosImage>
                    </HStack>
                </Box>
            </VStack>
        </ScrollView>
    );
};

export default PregnancyBodyPageContent;
