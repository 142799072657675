import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type {
    AdministrationSide,
    InsulinBodyPart,
    InsulinType,
} from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";

export type BloodSugarAverages = Record<string, number>;

export type InsulinProtocol = {
    id: Ulid;
    instructions: string;
};

export type InsulinBodyLocation = {
    side: AdministrationSide;
    bodyPart: InsulinBodyPart;
};

export type InsulinProduct = {
    id: Ulid;
    name: string;
    type: InsulinType;
};

export type InsulinAdministration = {
    id: Ulid;
    administeredAt: Date;
    bodyLocation: InsulinBodyLocation;
    product: InsulinProduct;
    units: number;
    comment: string | null;
};

interface InsulinAdministrationContext {
    allAdministrations?: InsulinAdministration[];
    administrations: InsulinAdministration[];
    products: InsulinProduct[];
    bloodSugareAverages: BloodSugarAverages;
    insulinProtocol?: InsulinProtocol[];
    incrementPage?: () => void;
}

const InsulinAdministrationContext = createContext<
    InsulinAdministrationContext | undefined
>(undefined);

export const InsulinAdministrationProvider =
    InsulinAdministrationContext.Provider as ReactProvider<InsulinAdministrationContext>;

export const useInsulinAdministration = () =>
    useEnsureContext(InsulinAdministrationContext);
