import { DeviceProvider, Devices } from "@madmedical/ui";
import { useDismissPersist } from "@madmedical/storage";
import useDevices from "../useDevices";

const AllDevices = () => {
    const { devices, isLoading } = useDevices();
    const { /*visible: webshopAlertVisible,*/ dismiss: dismissWebshopalert } =
        useDismissPersist("webshopAlert");

    if (isLoading) {
        return null;
    }

    return (
        <DeviceProvider value={{ devices }}>
            <Devices
                showWebshopAlert={false}
                onDismissWebshopAlert={dismissWebshopalert}
            />
        </DeviceProvider>
    );
};

export default AllDevices;
