import { useMemo, useState } from "react";
import { Box } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useChart } from "../../providers/chart";
import type { GraphProps } from "../types";
import Graph from "../Graph";
import type { Trend } from "../../providers/prediction";

interface Props {
    metricType: MetricType;
    graphProps: Pick<GraphProps, "type" | "unit" | "limits" | "coords">;
    predictionTrend: Trend;
}

const GraphPredictionWrapper = ({
    metricType,
    graphProps,
    predictionTrend,
}: Props) => {
    const [graphWidth, setGraphWidth] = useState<number>();
    const { dateRange } = useChart();
    const { t } = useTranslation();
    const resolvedProps = useMemo(
        () => ({
            ...graphProps,
            predictions: predictionTrend
                ? {
                      isPrediction: true,
                      startMsec: dateRange.from.getTime(),
                      high: {
                          regression: {
                              // slope is per day, chart needs per msec
                              gradient: predictionTrend.slope / 86400000, // y/msec
                              startY: predictionTrend.intercept,
                          },
                          spread: {
                              halfDegInner: 7,
                              halfDegOuter: 14,
                          },
                      },
                  }
                : undefined,
            slot: { x: graphWidth ?? 600, y: 400 },
            paddingPercents: { x: 3, y: 3 },
            dashboardThumbnail: {
                title: t(`measures:${forHumans(metricType)}`),
                isDashboardThumbnail: false,
                showLineGraphNodes: true,
            },
        }),
        [graphProps, predictionTrend, dateRange.from, graphWidth, t, metricType]
    );

    return (
        <Box
            onLayout={(e) => setGraphWidth(e.nativeEvent.layout.width)}
            width="100%"
        >
            <Graph graphProps={resolvedProps} />
        </Box>
    );
};

export default GraphPredictionWrapper;
