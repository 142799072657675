import {
    Actionsheet,
    Box,
    Divider,
    HStack,
    ScrollView,
    Text,
    VStack,
} from "native-base";
import { isIos, isWeb } from "@madmedical/utils";
import { Linking } from "react-native";
import { useTranslation } from "@madmedical/i18n";
import Button from "../atoms/Button";
import IconListItem from "../molecules/IconListItem";
import IconButton from "../atoms/IconButton";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    isConnectLoading: boolean;
    onConnectPress: () => unknown;
}

const DeviceConnectActionsheet = ({
    isOpen,
    onClose,
    isConnectLoading,
    onConnectPress,
}: Props) => {
    const handleOpenSettings = () => {
        if (isIos) {
            void Linking.openURL("App-Prefs:Bluetooth");
        } else {
            void Linking.sendIntent("android.settings.BLUETOOTH_SETTINGS");
        }
    };
    const { t } = useTranslation();

    return (
        <Actionsheet isOpen={isOpen} onClose={onClose}>
            <Actionsheet.Content borderTopRadius="xl">
                <ScrollView w="full">
                    <VStack w="full">
                        <HStack p={2} pt={0} justifyContent="flex-start">
                            <IconButton
                                iconName="close"
                                variant="ghostLink"
                                size="sm"
                                onPress={onClose}
                            />
                        </HStack>

                        <Divider />

                        <VStack w="full" p={4} space={8} flex="1">
                            <Text fontSize="5xl">Csatlakoztatás folyamata</Text>

                            {!isIos && !isWeb ? (
                                <VStack space={8}>
                                    <IconListItem icon="number_1">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Párosítsd az Accu-Chek Instant
                                                készülékedet
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Amennyiben az Accu-Chek Instant
                                                már be van kapcsolva, akkor a
                                                párosításhoz először kapcsold ki
                                                az eszközt. Következő lépésben
                                                indítsd el a készüléket
                                                párosítás üzemmódban. Ehhez
                                                tartsd nyomva néhány másodpercig
                                                az eszköz oldalán található alsó
                                                gombot, rövidesen a készülék
                                                képernyőjén villogni kezd a
                                                Bluetooth ikon. Ügyelj rá, hogy
                                                csak a párosítani kívánt eszköz
                                                legyen bekapcsolt állapotban!
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_2">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Engedélyezd a telefonodon a
                                                Bluetooth kapcsolatot
                                            </Text>

                                            <HStack
                                                mt={3}
                                                justifyContent="flex-start"
                                            >
                                                <Button
                                                    variant="gray"
                                                    onPress={handleOpenSettings}
                                                >
                                                    {t("bluetoothSettings")}
                                                </Button>
                                            </HStack>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_3">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Keresd meg és válaszd ki az
                                                eszközt
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                A Bluetooth beállítások
                                                felületén az Accu-Chek Instant
                                                készülékedet várhatóan hasonló
                                                elnevezéssel fogod megtalálni:{" "}
                                                <Text
                                                    color="black"
                                                    fontWeight="semibold"
                                                >
                                                    meter+XXXXXXXX
                                                </Text>
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_4">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Add meg a szükséges PIN kódot
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Az eszköz hátoldalon lévő címkén
                                                találod a 6 jegyű PIN kódot.
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_5">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Sikeres párosítás
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Amennyiben a készülék párosítása
                                                sikeresen megtörtént, a kijelzőn
                                                OK felirat jelenik meg, majd az
                                                eszköz néhány másodperc után
                                                automatikusan kikapcsol.
                                            </Text>
                                        </VStack>
                                    </IconListItem>
                                    <IconListItem icon="number_6">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Végül nyomj a Csatlakozás gombra
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Néhány másodperc múlva
                                                megtörténik az eszköz
                                                csatlakoztatása és megkezdődik a
                                                korábbi mérések szinkronizálása.
                                                A továbbiakban már nem kell újra
                                                párosítani az eszközt, elegendő
                                                csak bekapcsolni normál módban,
                                                a felső gomb megnyomásával.
                                            </Text>
                                        </VStack>
                                    </IconListItem>
                                </VStack>
                            ) : (
                                <VStack space={8}>
                                    <IconListItem icon="number_1">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Párosítsd az Accu-Chek Instant
                                                készülékedet
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                {" "}
                                                Amennyiben az Accu-Chek Instant
                                                már be van kapcsolva, akkor a
                                                párosításhoz először kapcsold ki
                                                az eszközt. Következő lépésben
                                                indítsd el a készüléket
                                                párosítás üzemmódban. Ehhez
                                                tartsd nyomva néhány másodpercig
                                                az eszköz oldalán található alsó
                                                gombot, rövidesen a készülék
                                                képernyőjén villogni kezd a
                                                Bluetooth ikon. Ügyelj rá, hogy
                                                csak a párosítani kívánt eszköz
                                                legyen bekapcsolt állapotban!
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_2">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Nyomd meg a telefonon a
                                                Kapcsolódás gombot
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Amennyiben a telefonodon nincs
                                                bekapcsolva a bluetooth, akkor
                                                azt előtte engedélyezd. Ezt
                                                követően a telefon és az
                                                Accu-Chek Instant készüléked már
                                                könnyedén megtalálja egymást.
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_3">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Add meg a szükséges PIN kódot
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Az eszköz hátoldalon lévő címkén
                                                találod a 6 jegyű PIN kódot.
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_4">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Sikeres párosítás
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Amennyiben a készülék párosítása
                                                sikeresen megtörtént, a kijelzőn
                                                OK felirat jelenik meg, majd az
                                                eszköz néhány másodperc után
                                                automatikusan kikapcsol. A
                                                továbbiakban már nem kell újra
                                                párosítanod az eszközt.
                                            </Text>
                                        </VStack>
                                    </IconListItem>

                                    <IconListItem icon="number_5">
                                        <VStack space={1}>
                                            <Text
                                                fontSize="lg"
                                                numberOfLines={99}
                                            >
                                                Mérések szinkronizálása
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                color="gray.600"
                                                numberOfLines={99}
                                            >
                                                Az Accu-Chek Instant készüléket
                                                a jobb felső gombbal kapcsold
                                                be. Néhány másodperc múlva
                                                megtörténik az eszköz
                                                csatlakoztatása az alkalmazásban
                                                és megkezdődik a korábbi mérések
                                                szinkronizálása.
                                            </Text>
                                        </VStack>
                                    </IconListItem>
                                </VStack>
                            )}
                        </VStack>

                        <Divider />

                        <Box w="full" p={4}>
                            <Button
                                onPress={onConnectPress}
                                width="full"
                                isLoading={isConnectLoading}
                            >
                                Csatlakoztatás
                            </Button>
                        </Box>
                    </VStack>
                </ScrollView>
            </Actionsheet.Content>
        </Actionsheet>
    );
};

export default DeviceConnectActionsheet;
