import type { ReactNode } from "react";
import { Box } from "native-base";
import type { ViewStyle } from "react-native";
import { tooltipArrow, tooltipContentBox } from "./useStyles";
import type { TooltipType } from "./types";

export default function Tooltip({
    children,
    styleArrow = {},
    styleContentBox = {},
    tooltip = {},
    transform = [{ translateX: -120 }, { translateY: -12 }],
    needsArrow = true,
}: {
    children: ReactNode;
    styleArrow: ViewStyle;
    styleContentBox: ViewStyle;
    tooltip: TooltipType;
    transform?: [{ translateX: number }, { translateY: number }];
    needsArrow?: boolean;
}) {
    const { onShow = false, left = 0, bottom = 0 } = tooltip;

    return (
        <Box
            display={onShow ? "flex" : "none"}
            position="absolute"
            zIndex={3}
            style={{
                bottom,
                left,
                transform,
            }}
        >
            <Box style={[tooltipContentBox, styleContentBox]}>
                {children}
                {needsArrow && <Box style={[tooltipArrow, styleArrow]} />}
            </Box>
        </Box>
    );
}
