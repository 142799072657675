import { Box, HStack, Text } from "native-base";
import { formatDateRange } from "@madmedical/utils";
import { forHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import Metrics from "../molecules/Metrics";
import Tag from "../atoms/Tag";
import { useChart } from "../providers/chart";

const PredictionChartCard = () => {
    const { metricType, chart, displayRange, alertLevel, dateRange } =
        useChart();
    const { t } = useTranslation();

    return (
        <Box width="100%">
            <HStack
                px={{
                    base: 4,
                    md: 6,
                }}
                py={4}
                alignItems="flex-end"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <Box>
                    <Text fontSize="sm" color="gray.600" mb="0.5">
                        {formatDateRange(dateRange)}
                    </Text>
                    {displayRange && (
                        <Metrics
                            displayValue={[displayRange]}
                            alertLevel={alertLevel}
                        />
                    )}
                    <HStack mt="1">
                        <Tag variant="ghost">
                            {t(`measures:${forHumans(metricType)}`)}
                        </Tag>
                    </HStack>
                </Box>
            </HStack>

            <Box width="100%">
                <Box
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="blueGray.50"
                    borderColor="blueGray.200"
                    borderWidth="1"
                    borderLeftWidth="0"
                    borderRightWidth="0"
                >
                    {chart}
                </Box>
            </Box>
        </Box>
    );
};

export default PredictionChartCard;
