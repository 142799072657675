import { Circle, G, Path, Polygon, Svg } from "react-native-svg";
import { Box, Text } from "native-base";
import { useMemo } from "react";
import { useTranslation } from "@madmedical/i18n";
import type { AdministrationSide } from "@madmedical/medical";
import { InsulinBodyPart } from "@madmedical/medical";
import { formatInsertPoint } from "../util/insulinFormatters";
import useResponsive from "../util/useResponsive";

interface SideVisible {
    left: boolean;
    right: boolean;
}
interface BodyChartDataElement {
    bodyPart: InsulinBodyPart;
    side: AdministrationSide;
}

interface BodyChartDataList {
    insulinBodyLocation: BodyChartDataElement;
    units: number;
}

interface InsulinAdministrationGraphProps {
    bodyChartData: BodyChartDataList[];
    sideVisible: SideVisible;
}

interface BodyChartData {
    bodyChartData: BodyChartDataList[] | undefined;
    isReversed?: boolean;
    sideVisible: SideVisible;
    bodyPart: InsulinBodyPart;
    leftUnit: number;
    rightUnit: number;
}

const ShowPointData = ({
    isReversed = false,
    sideVisible,
    bodyPart,
    leftUnit,
    rightUnit,
}: BodyChartData) => {
    const { left, right } = sideVisible;
    const { t } = useTranslation();

    return (
        <>
            {!isReversed && (
                <Text fontSize="xs" color="gray.500" mr="6px">
                    {t(formatInsertPoint(bodyPart))}
                </Text>
            )}
            <>
                <Box
                    opacity={left ? 1 : 0}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                >
                    <Box
                        bgColor="blue.500"
                        rounded="full"
                        w="6px"
                        h="6px"
                        mr="6px"
                    ></Box>
                    <Text fontSize="xs" color="blue.600" mr="6px">
                        {leftUnit}
                    </Text>
                </Box>
                <Box
                    opacity={right ? 1 : 0}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                >
                    <Box
                        bgColor="green.500"
                        rounded="full"
                        w="6px"
                        h="6px"
                        mr="6px"
                    ></Box>
                    <Text fontSize="xs" color="green.600" mr="6px">
                        {rightUnit}
                    </Text>
                </Box>
            </>
            {isReversed && (
                <Text fontSize="xs" color="gray.500" mx="6px">
                    {formatInsertPoint(bodyPart)}
                </Text>
            )}
        </>
    );
};

const degToRad = (deg: number): number => deg * (Math.PI / 180.0);

type Point = [number, number];

const svgY = (degrees: number) => degrees + 180;

const calculateEdgePointFn =
    (center: number, radius: number) =>
    (degree: number, scale = 1): Point => {
        const degreeInRadians = degToRad(degree);
        const degreeInRadiansY = degToRad(svgY(degree));

        return [
            center + Math.cos(degreeInRadians) * radius * scale,
            center + Math.sin(degreeInRadiansY) * radius * scale,
        ];
    };

const bodyPartProperties = {
    [InsulinBodyPart.Waist]: {
        default: {
            left: 150,
            top: -25,
        },
        smallScreen: {
            left: 83,
            top: -20,
        },
        order: 0,
    },
    [InsulinBodyPart.Arm]: {
        order: -1,
        default: {
            left: 355,
            top: 115,
        },
        smallScreen: {
            right: -35,
            top: 59,
        },
    },
    [InsulinBodyPart.Abdomen]: {
        order: -2,
        default: {
            left: 55,
            bottom: 0,
        },
        smallScreen: {
            left: 180,
            bottom: 0,
        },
    },
    [InsulinBodyPart.Glutes]: {
        order: -3,
        default: {
            left: 35,
            bottom: 0,
        },
        smallScreen: {
            left: -10,
            bottom: 0,
        },
    },
    [InsulinBodyPart.Thigh]: {
        order: -4,
        default: {
            left: -80,
            top: 115,
        },
        smallScreen: {
            left: -25,
            top: 59,
        },
    },
};

const defaultBodypartCounts = {
    [InsulinBodyPart.Waist]: 0,
    [InsulinBodyPart.Arm]: 0,
    [InsulinBodyPart.Abdomen]: 0,
    [InsulinBodyPart.Glutes]: 0,
    [InsulinBodyPart.Thigh]: 0,
};

const InsulinAdministrationGraph = ({
    bodyChartData,
    sideVisible,
}: InsulinAdministrationGraphProps) => {
    const isEmpty = bodyChartData.length === 0;
    const { isSmallScreen } = useResponsive();

    const { left, right } = bodyChartData.reduce<{
        left: Record<InsulinBodyPart, number>;
        right: Record<InsulinBodyPart, number>;
    }>(
        (acc, item) => {
            const { bodyPart, side } = item.insulinBodyLocation;
            acc[side][bodyPart] += 1;

            return acc;
        },
        {
            left: { ...defaultBodypartCounts },
            right: { ...defaultBodypartCounts },
        }
    );
    const MAX_NODE = !isEmpty
        ? Math.max(...Object.values(left), ...Object.values(right))
        : 1;

    const viewBoxSize = isSmallScreen ? 200 : 350;
    const viewBoxCenter = viewBoxSize * 0.5;
    const radius = viewBoxSize * 0.5;

    const BODY_PARTS = 5;
    const SCALE_FACTOR = 1;
    const DISTANCE_UNIT = SCALE_FACTOR / MAX_NODE;

    const grayStroke = "#C0C0C0";
    const greenStroke = "#85B725";
    const blueStroke = "#418DFF";
    const pointRadius = "3.2";

    const calculateEdgePoint = useMemo(
        () => calculateEdgePointFn(viewBoxCenter, radius),
        [viewBoxCenter, radius]
    );

    return (
        <Box position="relative" m={8}>
            {Object.entries(bodyPartProperties).map(
                ([bodyPart, property], i) => {
                    const bp = bodyPart as InsulinBodyPart;
                    const { order, ...rest } = property;
                    const screenProperties = isSmallScreen
                        ? rest.smallScreen
                        : rest.default;

                    return (
                        <Box
                            key={`${bp}-${i * order + i}`}
                            position={"absolute"}
                            zIndex={1}
                            display="flex"
                            flexDirection={
                                isSmallScreen && bp !== "waist"
                                    ? "column"
                                    : "row"
                            }
                            alignItems="center"
                            {...screenProperties}
                        >
                            <ShowPointData
                                bodyPart={bp}
                                leftUnit={left[bp]}
                                rightUnit={right[bp]}
                                bodyChartData={bodyChartData}
                                sideVisible={sideVisible}
                            />
                        </Box>
                    );
                }
            )}
            <Svg
                height={viewBoxSize + 10}
                width={viewBoxSize}
                viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
            >
                {Array.from({ length: BODY_PARTS }).map((_, i) => {
                    const [cx, cy] = calculateEdgePoint(
                        90 + (360 / BODY_PARTS) * i,
                        SCALE_FACTOR
                    );

                    return (
                        <G key={i}>
                            <Path
                                key={cx + cy + i}
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d={`M ${viewBoxCenter} ${viewBoxCenter} ${cx} ${cy} Z`}
                                stroke={grayStroke}
                            />
                            {Array.from({ length: MAX_NODE ?? 1 }).map(
                                (_, j) => {
                                    const [ox, oy] = calculateEdgePoint(
                                        90 + (360 / BODY_PARTS) * i,
                                        DISTANCE_UNIT * (j + 1)
                                    );

                                    const [nx, ny] = calculateEdgePoint(
                                        90 + (360 / BODY_PARTS) * (i + 1),
                                        DISTANCE_UNIT * (j + 1)
                                    );

                                    return (
                                        <Path
                                            key={nx + ny + i}
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d={`M ${ox} ${oy} ${nx} ${ny} Z`}
                                            stroke={grayStroke}
                                        />
                                    );
                                }
                            )}
                        </G>
                    );
                })}

                {sideVisible.left && (
                    <Polygon
                        stroke={blueStroke}
                        strokeWidth={2}
                        fill={blueStroke}
                        fillOpacity={0.15}
                        points={`${Object.entries(left)
                            .map(([bodyPart, units]) => {
                                const edgePoint = calculateEdgePoint(
                                    90 +
                                        (360 / 5) *
                                            bodyPartProperties[
                                                bodyPart as InsulinBodyPart
                                            ].order,
                                    units * DISTANCE_UNIT
                                );

                                return `${edgePoint[0]},${edgePoint[1]}`;
                            })
                            .join(" ")}`}
                    />
                )}

                {sideVisible.left &&
                    Object.entries(left).map(([bodyPart, units], i) => {
                        const [cx, cy] = calculateEdgePoint(
                            90 +
                                (360 / 5) *
                                    bodyPartProperties[
                                        bodyPart as InsulinBodyPart
                                    ].order,
                            units * DISTANCE_UNIT
                        );

                        return (
                            <Circle
                                key={i}
                                cx={cx}
                                cy={cy}
                                r={pointRadius}
                                fill="white"
                                stroke={blueStroke}
                                strokeWidth={2}
                            />
                        );
                    })}

                {sideVisible.right && (
                    <Polygon
                        stroke={greenStroke}
                        strokeWidth={2}
                        fill={greenStroke}
                        fillOpacity={0.15}
                        points={`${Object.entries(right)
                            .map(([bodyPart, units]) => {
                                const edgePoint = calculateEdgePoint(
                                    90 +
                                        (360 / 5) *
                                            bodyPartProperties[
                                                bodyPart as InsulinBodyPart
                                            ].order,
                                    units * DISTANCE_UNIT
                                );

                                return `${edgePoint[0]},${edgePoint[1]}`;
                            })
                            .join(" ")}`}
                    />
                )}

                {sideVisible.right &&
                    Object.entries(right).map(([bodyPart, units], i) => {
                        const [cx, cy] = calculateEdgePoint(
                            90 +
                                (360 / 5) *
                                    bodyPartProperties[
                                        bodyPart as InsulinBodyPart
                                    ].order,
                            units * DISTANCE_UNIT
                        );

                        return (
                            <Circle
                                key={i}
                                cx={cx}
                                cy={cy}
                                r={pointRadius}
                                fill="white"
                                stroke={greenStroke}
                                strokeWidth={2}
                            />
                        );
                    })}
            </Svg>
        </Box>
    );
};

export default InsulinAdministrationGraph;
