import { getStorageValue, setStorageValue } from "@madmedical/storage";
import type { BleDeviceType } from "@madmedical/medical";
import type { BoundBleDevice } from "@madmedical/ble";
import type { DateTimeString } from "@madmedical/utils";
import { useBindDeviceMutation, useUnbindDeviceMutation } from "../api";

const STORAGE_KEY = "bleDevices";

type NormalizedBleDevice = Omit<BoundBleDevice, "boundAt"> & {
    boundAt: DateTimeString;
};

const normalize = ({ boundAt, ...device }: BoundBleDevice) => ({
    boundAt: boundAt.toISOString(),
    ...device,
});

const denormalize = ({ boundAt, ...device }: NormalizedBleDevice) => ({
    boundAt: new Date(boundAt),
    ...device,
});

/**
 * We persist BLE devices into local storage and backend db as well.
 * Local storage because some needs pairing so we need to be on the same device
 * And backend so we can show connection status on other devices.
 */
export default () => {
    const [bind] = useBindDeviceMutation();
    const [unbind] = useUnbindDeviceMutation();

    const getDevices = async () =>
        // Only from local to be sure we are on the same device where we paired it - when necessary
        ((await getStorageValue<NormalizedBleDevice[]>(STORAGE_KEY)) ?? []).map(
            denormalize
        );

    const getDevice = async (type: BleDeviceType) => {
        const devices = await getDevices();

        return devices?.find((device) => device.provider === type) ?? null;
    };

    const addDevice = async (device: Omit<BoundBleDevice, "id">) => {
        const existing = await getDevice(device.provider);
        if (existing) {
            throw "Can't connect to multiple devices of the same type";
        }

        const boundDevice = await bind(device).unwrap();

        const devices = (await getDevices()) ?? [];
        devices.push(denormalize(boundDevice as NormalizedBleDevice));

        return setStorageValue(STORAGE_KEY, devices.map(normalize));
    };

    const removeDevice = async (type: BleDeviceType) => {
        const devices = (await getDevices()) ?? [];

        await unbind({ provider: type }).unwrap();

        return setStorageValue(
            STORAGE_KEY,
            devices.filter((device) => device.provider !== type).map(normalize)
        );
    };

    return { getDevices, addDevice, removeDevice };
};
