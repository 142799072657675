import type { Metric } from "@madmedical/medical";
import { MetricType, forHumans } from "@madmedical/medical";
import type { DateRange } from "@madmedical/utils";
import type { ChartWidget, Coord } from "@madmedical/ui";
import type {
    Measurement,
    MeasurementRangeResponse,
} from "../measurement/model";
import type { Trend } from "../prediction/model";

export interface CommonProps<TMetric extends Metric> {
    data: MeasurementRangeResponse<TMetric>;
    dateRange: DateRange;
    widget: ChartWidget;
    predictionTrend?: Trend;
}

// Note: There is no chart for BodyHeight as we only deal with adult users

type CoordValueMapper<TMetric extends Metric> = (metric: TMetric) => {
    yHigh: number;
    yLow?: number;
};

export const mapCoords = <TMetric extends Metric>(
    measurements: Measurement<TMetric>[],
    { from, to }: DateRange,
    mapper: CoordValueMapper<TMetric>
) => {
    const coords: Coord[] = measurements.map(
        ({ measuredAt, metric, manual: isManual }) => ({
            x: 100, // Just dummy value, TODO: Improve types
            msec: new Date(measuredAt).getTime(),
            displayValue: metric.displayValue,
            isManual,
            note:
                metric.type === MetricType.BloodSugar
                    ? forHumans(metric.mealSituation)
                    : undefined,
            ...mapper(metric),
        })
    );

    coords.unshift({ x: 100, msec: from.getTime() });
    coords.push({ x: 100, msec: to.getTime() });

    return coords;
};
