import { HStack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import { useCallCenterDevices } from "../../../providers/callCenterDevice";
import { hasActiveFilter } from "../utils";
import FilterButton from "../../../molecules/FilterButton";
import Button from "../../../atoms/Button";

const CallCenterDeviceDesktopFilter = () => {
    const { filterChoices, onFilterChange } = useCallCenterDevices();
    const { t } = useTranslation();
    const handleFilterChange =
        <TFilterKey extends keyof NonNullable<typeof filterChoices>>(
            key: TFilterKey
        ) =>
        (
            selected: NonNullable<typeof filterChoices>[TFilterKey][0]["key"][]
        ) => {
            onFilterChange(key, selected);
        };

    const handleResetPress = () => {
        onFilterChange("deviceTypes", []);
        onFilterChange("metricTypes", []);
        onFilterChange("statuses", []);
        onFilterChange("connectionStatuses", []);
        onFilterChange("ownerships", []);
    };

    if (!filterChoices) {
        return null;
    }

    return (
        <HStack space="2" mr="auto">
            <FilterButton
                isSearchable
                title={t("device")}
                items={filterChoices.deviceTypes}
                onChange={handleFilterChange("deviceTypes")}
                isMultiselect={true}
            />
            <FilterButton
                isSearchable
                title={t("measures:type")}
                items={filterChoices.metricTypes}
                onChange={handleFilterChange("metricTypes")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("logistics")}
                items={filterChoices.statuses}
                onChange={handleFilterChange("statuses")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("state")}
                items={filterChoices.connectionStatuses}
                onChange={handleFilterChange("connectionStatuses")}
                isMultiselect={true}
            />
            <FilterButton
                title={t("settlement")}
                items={filterChoices.ownerships}
                onChange={handleFilterChange("ownerships")}
                isMultiselect={true}
            />
            {hasActiveFilter(filterChoices) ? (
                <Button onPress={handleResetPress} size="xs" variant="link">
                    {t("setback")}
                </Button>
            ) : null}
        </HStack>
    );
};

export default CallCenterDeviceDesktopFilter;
