import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import { getIntegrations, init, setTag } from "./sentry";
import type { SentryConfig } from "./model";

const ignoreErrors = [
    /the native animated module is missing/,
    /setNativeProps is deprecated/,
];

export default (config: SentryConfig) => {
    if (process.env.NODE_ENV !== "production") {
        return;
    }

    const { environment, release, namespace } = config;

    init({
        dsn: "https://45c1f39bf0964dfbbc111a39eff4e3ad@o1340340.ingest.sentry.io/6613060",
        environment,
        release,
        attachStacktrace: true,
        integrations: [
            new ExtraErrorData(),
            new CaptureConsole({
                levels: ["warn", "error", "assert"],
            }),
            ...getIntegrations(),
        ],
        ignoreErrors,
        tracesSampleRate: 1.0,
    });
    setTag("namespace", namespace);
};
