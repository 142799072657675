import {
    CurrentPatientProvider,
    PreOpProvider,
    PreOpResults as PreOpResultsUi,
    Spinner,
} from "@madmedical/ui";
import type { PatientForDoctor } from "@madmedical/user";
import { adaptPatient, usePatient } from "@madmedical/user";
import type { DateTimeString } from "@madmedical/utils";
import { addDays, invariant } from "@madmedical/utils";
import { useGetMeasurementRangeQuery } from "@madmedical/measurement";
import type { BodyHeight, BodyWeight } from "@madmedical/medical";
import { MetricType } from "@madmedical/medical";
import { skipToken } from "@madmedical/store";
import { useCallback } from "react";
import { useGetLatestQuestionnaireQuery } from "../api";
import adaptQuestionnaire from "../adaptQuestionnaire";

const dayRange = (start: DateTimeString) => ({
    from: start,
    to: addDays(1, new Date(start)).toISOString(),
});

const PreOpResults = () => {
    const {
        patientId,
        patient,
        isLoading: isPatientLoading,
    } = usePatient<PatientForDoctor>();
    const { data: questionnaire, isLoading: isQuestionnaireLoading } =
        useGetLatestQuestionnaireQuery({ patientId });
    const getArgs = useCallback(
        (metricType: MetricType) => {
            const dateRange = questionnaire
                ? dayRange(questionnaire.createdAt)
                : undefined;

            return dateRange
                ? {
                      userId: patientId,
                      metricType,
                      queryRange: dateRange,
                      statsRange: dateRange,
                  }
                : skipToken;
        },
        [patientId, questionnaire]
    );
    const { data: bodyWeights, isLoading: isWeightsLoading } =
        useGetMeasurementRangeQuery(getArgs(MetricType.BodyWeight));
    const { data: bodyHeights, isLoading: isHeightsLoading } =
        useGetMeasurementRangeQuery(getArgs(MetricType.BodyHeight));

    if (
        isPatientLoading ||
        isQuestionnaireLoading ||
        isWeightsLoading ||
        isHeightsLoading
    ) {
        return <Spinner />;
    }

    invariant(questionnaire);
    invariant(patient);
    invariant(bodyWeights);
    invariant(bodyHeights);

    return (
        <CurrentPatientProvider value={{ patient: adaptPatient(patient) }}>
            <PreOpProvider
                value={{ questionnaire: adaptQuestionnaire(questionnaire) }}
            >
                <PreOpResultsUi
                    bodyWeight={bodyWeights.items[0]?.metric as BodyWeight}
                    bodyHeight={bodyHeights.items[0]?.metric as BodyHeight}
                />
            </PreOpProvider>
        </CurrentPatientProvider>
    );
};

export default PreOpResults;
