import { useCallback, useState } from "react";
import { InsulinAdministrationContent } from "@madmedical/ui";
import { useMe, usePatient } from "@madmedical/user";
import type { Ulid } from "@madmedical/utils";
import { UnderlyingCondition } from "@madmedical/medical";
import type { InsulinAdministration } from "../model";
import InsulinProviders from "./InsulinProviders";
import DeleteInsulinAdministrationModal from "./DeleteInsulinAdministrationModal";
import EditInsulinAdministrationModalForm from "./EditInsulinAdministrationModalForm";

export const InsulinAdministrationPage = () => {
    const {
        roles: { isPatient },
    } = useMe();

    const { patient } = usePatient();
    const [editAdministrationData, setEditAdministrationData] = useState<
        InsulinAdministration | undefined
    >();

    const [deleteAdministrationId, setDeleteAdministrationId] =
        useState<Ulid>();

    const handleDeletePress = useCallback((id: Ulid) => {
        setDeleteAdministrationId(id);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDeleteAdministrationId(undefined);
    }, []);

    const insulinUser =
        patient?.underlyingConditions?.includes(UnderlyingCondition.Insulin) &&
        patient?.packages?.some((p) => p.type === "diabetes");

    return (
        <InsulinProviders>
            <InsulinAdministrationContent
                isPatient={isPatient}
                patientName={patient?.fullName}
                deleteAdministration={handleDeletePress}
                editAdministration={setEditAdministrationData}
                insulinUser={insulinUser}
            />
            <DeleteInsulinAdministrationModal
                id={deleteAdministrationId}
                open={!!deleteAdministrationId}
                onClose={handleDialogClose}
            />
            <EditInsulinAdministrationModalForm
                open={!!editAdministrationData}
                onClose={() => setEditAdministrationData(undefined)}
                administrationData={editAdministrationData}
            />
        </InsulinProviders>
    );
};
