import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import { useEnsureContext } from "@madmedical/utils";
import type { Patient, PatientBase } from "./patient";

interface ContextValue<TPatient extends PatientBase = PatientBase> {
    patient: TPatient;
}

const CurrentPatientContext = createContext<ContextValue | undefined>(
    undefined
);

export const CurrentPatientProvider =
    CurrentPatientContext.Provider as ReactProvider<ContextValue>;

export const useCurrentPatient = <
    TPatient extends Patient = Patient
>(): ContextValue<TPatient> =>
    useEnsureContext(CurrentPatientContext) as ContextValue<TPatient>;
