import { useAuth, usePasswordLoginMutation } from "@madmedical/store";
import { useEffect } from "react";
import { useNavigate } from "@madmedical/routing";
import { ApiFormWrapper } from "@madmedical/form";
import { CallCenterLogin } from "@madmedical/ui";

const Login = () => {
    const { isTokenAvailable } = useAuth();
    const [login] = usePasswordLoginMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isTokenAvailable) {
            navigate("/");
        }
    }, [isTokenAvailable, navigate]);

    if (isTokenAvailable) {
        return null;
    }

    return (
        <ApiFormWrapper
            defaultValues={{ email: "", password: "" }}
            mutation={login}
            resetOnSuccess
        >
            {({ submitForm }) => <CallCenterLogin onSubmit={submitForm} />}
        </ApiFormWrapper>
    );
};

export default Login;
