export enum Service {
    Pregnancy = "pregnancy",
    Manager = "manager",
    PrePostOp = "pre_post_op",
    Basic = "basic",
    Diabetes = "diabetes",
}

const COLOURS = {
    [Service.Pregnancy]: "violet",
    [Service.Manager]: "lime",
    [Service.PrePostOp]: "info",
    [Service.Basic]: "primary",
    [Service.Diabetes]: "orange",
} as const;

export const getServiceColour = (service: Service) => COLOURS[service];

const FOR_HUMANS = {
    [Service.Pregnancy]: "services.pregnancy",
    [Service.Manager]: "services.manager",
    [Service.PrePostOp]: "services.pre_post_op",
    [Service.Basic]: "services.basic",
    [Service.Diabetes]: "services.diabetes",
};

export const getServiceChoices = (selected: Service[] | undefined) =>
    Object.values(Service)
        .map((service) => ({
            key: service,
            text: FOR_HUMANS[service],
            isSelected: !!selected?.includes(service),
        }))
        .sort(({ text: a }, { text: b }) => a.localeCompare(b));

export const serviceForHumans = (service: Service) => FOR_HUMANS[service];
