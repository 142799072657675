import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    AttachDocumentRequest,
    Document,
    DocumentExpanded,
    DocumentFilter,
    DocumentStats,
    PatientIdRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["documents"] })
    .injectEndpoints({
        endpoints: (build) => ({
            uploadDocument: build.mutation<Document[], FormData>({
                query: (body) => ({
                    url: "medical/documents",
                    method: "POST",
                    body,
                }),
            }),
            deleteDocument: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `medical/documents/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["documents"],
            }),
            getDocuments: build.query<
                Paginated<DocumentExpanded> & DocumentStats,
                PatientIdRequest & DocumentFilter & PageQuery
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/documents`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["documents"],
            }),
            getPregnancyDocuments: build.query<
                Paginated<DocumentExpanded>,
                PatientIdRequest & PageQuery
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/pregnancy-documents`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["documents"],
            }),
            getDocument: build.query<DocumentExpanded, Ulid>({
                query: (id) => ({
                    url: `medical/documents/${id}`,
                }),
            }),
            attachDocument: build.mutation<
                DocumentExpanded,
                AttachDocumentRequest
            >({
                query: ({ id, ...body }) => ({
                    url: `medical/documents/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["documents"],
            }),
        }),
    });

export const {
    useUploadDocumentMutation,
    useDeleteDocumentMutation,
    useGetDocumentsQuery,
    useGetPregnancyDocumentsQuery,
    useGetDocumentQuery,
    useAttachDocumentMutation,
} = api;
