import type { UseFormSetError } from "react-hook-form/dist/types/form";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { BaseQueryError } from "@madmedical/store";

const HANDLED_ERRORS = [400, 401, 403, 422];

interface Violation<Values extends FieldValues> {
    readonly propertyPath: FieldPath<Values> | "";
    readonly title: string;
}

interface ApiError<Values extends FieldValues> {
    readonly violations?: Violation<Values>[];
}

interface LoginError {
    readonly message?: string;
}

interface Props<Values extends FieldValues> {
    setError: UseFormSetError<Values>;
}

export const handleError =
    <Values extends FieldValues>({ setError }: Props<Values>) =>
    (error: BaseQueryError) => {
        if (
            typeof error.status !== "number" ||
            !HANDLED_ERRORS.includes(error.status)
        ) {
            return;
        }

        const violations = (error.data as ApiError<Values>).violations;
        if (!violations) {
            // Login error has different shape
            const loginError = (error.data as LoginError).message;
            if (loginError) {
                setError("root.formError", { message: loginError });
            }

            return;
        }

        // Show last validation error of each field
        violations
            .slice() // Clone array to avoid TypeError: 0 is read-only
            .reverse()
            .forEach(({ propertyPath, title }) => {
                if (propertyPath === "") {
                    setError("root.formError", { message: title });

                    return;
                }

                setError(propertyPath, { message: title });
            });
    };
