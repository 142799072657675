import { formatMonth, isWeb } from "@madmedical/utils";
import type { DateRange } from "@madmedical/utils";
import { dayList, dayListFull } from "../util/calendar";
import type {
    Coord,
    GraphData,
    GraphProps,
    PreCoord,
    TimeData,
    TooltipType,
} from "./types";

export const normalizeCoords = function ({
    curr,
    min,
    delta,
    slot,
    padding = 0,
}: {
    curr?: number;
    min?: number;
    delta?: number;
    slot?: number;
    padding?: number;
}) {
    // Scales down all coords to between 0..1.
    if (
        curr == null ||
        isNaN(curr) ||
        min == null ||
        isNaN(min) ||
        delta == null ||
        isNaN(delta) ||
        slot == null ||
        isNaN(slot)
    )
        return;

    return (
        ((((curr - min) / delta) * (100 - 2 * padding) + padding) / 100) * slot
    );
};

export const normalizeGraphProps = (
    graphProps: GraphProps = {}
): GraphData | undefined => {
    const {
        coords = [],
        limits,
        paddingPercents = { x: 0, y: 0 },
        slot = { x: 800, y: 400 },
        evaluations,
        comments = [],
        thresholds = [],
        predictions: p,
        insulin = [],
        moods = [],
    } = graphProps;

    if (!coords.length || !coords.every(({ msec }) => msec)) return;

    // ################### PREDICTIONS ####################

    const aDayInMsec = 24 * 3600 * 1000;

    if (p?.isPrediction) {
        coords.push({
            msec: Math.round(coords[coords.length - 1].msec + 3 * aDayInMsec),
        });

        coords.push({
            msec: Math.round(coords[coords.length - 1].msec + aDayInMsec),
        });
    }

    // #####################################################

    const xMax = coords[coords.length - 1].msec || 0;
    const xMin = coords[0].msec || 0;

    const yMax = limits?.yMax;
    const yMin = limits?.yMin;

    if (yMin === undefined || yMax === undefined) return;

    const xDelta = xMax - xMin;
    const yDelta = yMax - yMin;

    if (xDelta === 0 || yDelta === 0) return;

    // #####################################################

    const coordsAll = coords.reduce(
        (
            acc: { all: Coord[]; auto: Coord[]; manual: Coord[] },
            coord: PreCoord
        ) => {
            const {
                msec,
                yHigh,
                yLow,
                displayValue = "",
                note = "",
                isManual,
            } = coord;

            const normalizedCoord = {
                msec: Math.round(msec),
                x:
                    normalizeCoords({
                        curr: msec,
                        min: xMin,
                        delta: xDelta,
                        padding: paddingPercents.x,
                        slot: slot.x,
                    }) ?? 0,
                yHigh: normalizeCoords({
                    curr: yHigh,
                    min: yMin,
                    delta: yDelta,
                    padding: paddingPercents.y,
                    slot: slot.y,
                }),
                yLow: normalizeCoords({
                    curr: yLow,
                    min: yMin,
                    delta: yDelta,
                    padding: paddingPercents.y,
                    slot: slot.y,
                }),
                tooltip: {
                    displayValue,
                    note,
                    isManual,
                },
            };

            acc.all.push(normalizedCoord);
            acc[isManual ? "manual" : "auto"].push(normalizedCoord);

            return acc;
        },
        { all: [], auto: [], manual: [] }
    );

    // #####################################################

    const graphData = {
        ...graphProps,

        comments: comments.map((comment) => ({
            ...comment,
            date: new Date(comment.msec),
        })),

        coords: coordsAll.auto,

        coordsAll: coordsAll.all,

        evaluations: evaluations?.map(
            ({ id, isSelected, msecStart, msecEnd, name, onPress }) => ({
                id,
                isSelected,
                name,
                xStart: normalizeCoords({
                    curr: msecStart,
                    min: xMin,
                    delta: xDelta,
                    padding: paddingPercents.x,
                    slot: slot.x,
                }),
                xEnd: normalizeCoords({
                    curr: msecEnd,
                    min: xMin,
                    delta: xDelta,
                    padding: paddingPercents.x,
                    slot: slot.x,
                }),
                msecStart,
                msecEnd,
                onPress,
            })
        ),

        limits: {
            ...limits,
            yMax: ((100 - paddingPercents.y) / 100) * slot.y,
            yMin: (paddingPercents.y / 100) * slot.y,
            yMaxTitle: yMax.toString(),
            yMinTitle: yMin.toString(),
        },

        manualMeasures: coordsAll.manual,

        paddings: {
            x: (paddingPercents.x * slot.x) / 100,
            y: (paddingPercents.y * slot.y) / 100,
        },

        predictions: p?.high
            ? {
                  isPrediction: p.isPrediction,
                  startMsec: Math.round(p.startMsec),
                  startX:
                      normalizeCoords({
                          curr: p.startMsec,
                          min: xMin,
                          delta: xDelta,
                          padding: paddingPercents.x,
                          slot: slot.x,
                      }) ?? 0,
                  high: {
                      ...p.high,
                      regression: {
                          gradient: p.high.regression.gradient, // y/msec
                          startY: p.high.regression.startY, // not normalised
                      },
                  },
                  low: p.low
                      ? {
                            ...p.low,
                            regression: {
                                gradient: p.low?.regression.gradient, // y/msec
                                startY: p.low?.regression.startY, // not normalised
                            },
                        }
                      : undefined,
              }
            : undefined,

        thresholds: thresholds.map(({ limit, title }) => ({
            title,
            limit: normalizeCoords({
                curr: limit,
                min: yMin,
                delta: yDelta,
                padding: paddingPercents.y,
                slot: slot.y,
            }),
        })),

        insulin: insulin.map((i) => {
            const { msec, insuPos } = i;
            const newXcoord =
                normalizeCoords({
                    curr: insuPos ?? msec,
                    min: xMin,
                    delta: xDelta,
                    padding: paddingPercents.x,
                    slot: slot.x,
                }) ?? 0;

            return {
                ...i,
                x: newXcoord,
            };
        }),

        moods: moods.map((mood) => {
            const { msec } = mood;
            const newXcoord =
                normalizeCoords({
                    curr: msec as number,
                    min: xMin,
                    delta: xDelta,
                    padding: paddingPercents.x,
                    slot: slot.x,
                }) ?? 0;

            return {
                ...mood,
                x: newXcoord,
            };
        }),
    };

    delete graphData.paddingPercents;

    return graphData;
};

export const coordIdFactory = function ({
    randomIdFragment = "",
    coord = {},
}: {
    randomIdFragment?: string;
    coord?: {
        msec?: number;
        tooltip?: TooltipType;
    };
}) {
    const { msec = "", tooltip = {} } = coord;
    const { displayValue = "" } = tooltip;

    return `${randomIdFragment}-${msec}-${displayValue}`;
};

export const loadTooltipData = function ({
    coord,
    id = "",
    isManual = false,
    state = {},
    slot,
}: {
    coord: Coord;
    id?: string;
    isManual?: boolean;
    state?: { bottom?: number; left?: number; onShow?: boolean };
    slot?: { x: number; y: number };
}) {
    const { x = 0, y, yHigh, msec = 0, tooltip: tooltipProps = {} } = coord;

    const { displayValue, note, isGrouped, group } = tooltipProps;

    const $y = y ? y : yHigh;

    return {
        bottom: $y,
        displayValue,
        id,
        isManual,
        left: slot
            ? x < 125
                ? 125
                : x > slot.x - 125 && slot.x < 768
                ? slot.x - 40
                : x
            : x,
        msec,
        note,
        onShow: state.left === x && state.bottom === $y ? !state.onShow : true,
        isGrouped,
        group: group ?? undefined,
    };
};

export const $timeData: (props: { graphData: GraphData }) => TimeData = ({
    graphData,
}) => {
    const {
        coords: graphCoords = [],
        predictions,
        slot = { x: 0, y: 0 },
    } = graphData;

    const isDesktop = slot.x > 500;

    const aDayInMsec = 24 * 3600 * 1000;
    const startMidnight = Number(graphCoords[0].msec);
    const endMidnight = Number(graphCoords[graphCoords.length - 1].msec);
    const days = Math.round((endMidnight - startMidnight) / aDayInMsec);

    const startDate = new Date(startMidnight);
    const startHour = startDate.getHours();
    const startWeekDay = startDate.getDay();

    // rules for displaying periods longer than 7 days:
    // * fundamental principle: first and last coords' msec span/create the timewindow!!!!
    // * no max day limit - (can display periods longer than 31 days)
    // * measures can also start in the middle of the month
    // * timestamp starts with either "1" (if first day of month) or multiple of 5 (if not first day of month)
    // * subsequent intervals are all multiple of 5 days
    // * timestamps are displayed for the first month only(!). If timewindow extends into next month then no time stamps are displayed for those month(s)
    // * the last timestamp is the integer part of "the last day of first month / 5"
    // * first msec determines the start month

    const startMonth = startDate.getMonth(); // 1..31
    const startMonthDay = startDate.getDate(); // 1..31
    const endMonthDay = new Date(
        new Date(startMidnight).setMonth(startMonth + 1, 0)
    ).getDate();
    const gridOffset5Days = startMonthDay % 5;
    const hasOffset = startMonthDay > 1 && gridOffset5Days ? true : false;

    const startDate5Day = gridOffset5Days
        ? new Date(
              new Date(startMidnight).setDate(
                  startMonthDay + (5 - gridOffset5Days)
              )
          )
        : startDate;

    const startMonth5Day = startDate5Day.getDate();

    const startMonthName = formatMonth(startDate5Day);
    const weekdaysMobile = dayList;
    const weekdaysDesktop = dayListFull;

    const timeStamps = predictions?.isPrediction
        ? Array.from({ length: days }, ($, i) =>
              endMonthDay >= startMonthDay + i
                  ? startMonthDay + i
                  : i - (endMonthDay - startMonthDay)
          )
        : days === 1
        ? Array.from(
              { length: Math.ceil((days * 24 + 1) / 4) },
              ($, i) =>
                  `${(i * 4 + startHour) % 25}` +
                  (isWeb && isDesktop ? ":00" : "h")
          )
        : days <= 7
        ? Array.from({ length: days + 1 }, ($, i) =>
              isWeb && isDesktop
                  ? weekdaysDesktop[(i + startWeekDay) % 7]
                  : weekdaysMobile[(i + startWeekDay) % 7]
          )
        : Array.from(
              {
                  // TODO: test if 2 is correct in case of hasOffset === false
                  length: Math.ceil((days + (hasOffset ? 1 : 2)) / 5),
              },
              ($, i) =>
                  (i === 0 ? `${startMonthName} ` : "") +
                  (i <=
                  parseInt(
                      `${
                          (endMonthDay -
                              (hasOffset ? startMonth5Day : startMonthDay)) /
                          5
                      }`
                  )
                      ? i === 0 && startMonthDay === 1
                          ? "1."
                          : `${
                                ((startMonthDay === 1 ? 0 : startMonth5Day) +
                                    5 * i) %
                                    30 || 30
                            }.`
                      : "")
          );

    const timelineWidth =
        (graphCoords[graphCoords.length - 1].x ?? 0) - (graphCoords[0].x ?? 0);

    const timelineMsec = endMidnight - startMidnight;

    const xMsecRatio = timelineMsec / timelineWidth;

    const intervalMsec = predictions?.isPrediction
        ? aDayInMsec
        : days === 1
        ? aDayInMsec / 6
        : days <= 7
        ? aDayInMsec
        : 5 * aDayInMsec;

    const intervalPx = intervalMsec / xMsecRatio;

    const startMonth5DayMsec =
        days > 7 && gridOffset5Days ? (5 - gridOffset5Days) * aDayInMsec : 0;

    const startMonth5DayX = startMonth5DayMsec / xMsecRatio;

    const timeCoords = timeStamps.map((timeStamp, i) => ({
        x:
            Number(graphCoords[0].x) +
            (predictions?.isPrediction || days <= 7
                ? i * intervalPx
                : startMonthDay === 1
                ? i === 0
                    ? 0
                    : startMonth5DayX + (i - 1) * intervalPx
                : startMonth5DayX + i * intervalPx),
        text: timeStamp,
        msec:
            Number(graphCoords[0].msec) +
            (predictions?.isPrediction || days <= 7
                ? i * intervalMsec
                : startMonthDay === 1
                ? i === 0
                    ? 0
                    : startMonth5DayMsec + (i - 1) * intervalMsec
                : startMonth5DayMsec + i * intervalMsec),
    }));

    return {
        xMsecRatio,
        intervalMsec,
        intervalPx,
        timeStamps,
        timeCoords,
        startMonth5DayX,
    };
};

export const datesToTimeGridIndices = ({
    dates,
    timeData,
}: {
    dates: DateRange | null;
    timeData: TimeData;
}) => {
    if (!dates) {
        return { from: null, to: null };
    }

    const msecFrom = new Date(dates.from).getTime();
    const msecTo = new Date(dates.to).getTime();

    const msecStart = timeData.timeCoords[0].msec;
    const msecEnd = timeData.timeCoords[timeData.timeCoords.length - 1].msec;

    if (
        msecFrom < msecStart ||
        msecFrom > msecEnd ||
        msecTo < msecStart ||
        msecTo > msecEnd
    ) {
        console.log("initial drag-select range is outside time-window");

        return { from: null, to: null };
    }

    const indexFrom =
        timeData.timeCoords.findIndex(({ msec }) => msecFrom < msec) - 1;

    const indexTo = timeData.timeCoords.findIndex(({ msec }) => msecTo <= msec);

    return { from: indexFrom, to: indexTo };
};
