import Svg, { Path, Rect } from "react-native-svg";

const Zucchini = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M91.9895 31.8855H95.4445C95.9614 31.8864 96.4732 31.7835 96.9499 31.5827C97.4266 31.3819 97.8586 31.0873 98.2203 30.7163C99.568 29.3298 101.729 26.8274 103.08 23.8433C103.13 23.7347 103.207 23.6411 103.304 23.5719C103.402 23.5028 103.515 23.4605 103.634 23.4493C103.753 23.4381 103.872 23.4584 103.98 23.5081C104.089 23.5578 104.182 23.6353 104.252 23.7327C104.554 24.1574 104.809 24.6141 105.011 25.0948C105.866 29.0695 102.131 33.8137 97.7008 38.6746C93.8078 36.9739 90.9679 34.9753 91.9895 31.8855Z"
            fill="#C3B7B6"
        />
        <Path
            d="M88.9477 34.4376C88.6683 36.3796 90.3435 37.5643 90.3435 37.5643L96.1719 43.7813C96.2061 43.8 96.2397 43.8181 96.2737 43.8354C96.8283 44.1239 97.4476 44.2636 98.0717 44.241C98.6959 44.2183 99.3036 44.0341 99.8361 43.7062C100.369 43.3783 100.808 42.9178 101.111 42.3692C101.415 41.8206 101.572 41.2025 101.569 40.5748C101.567 39.9825 101.529 39.3889 101.476 38.8431C101.315 37.2147 101.704 35.5792 102.58 34.1998C103.526 32.7145 104.378 31.232 104.929 30.2468C105.47 29.2863 105.687 28.1757 105.548 27.0809C105.462 26.417 105.289 25.7675 105.034 25.1494C104.684 27.6829 103.499 30.0227 102.261 31.8646C101.47 33.034 100.371 33.9597 99.0877 34.5388C97.8042 35.1178 96.3859 35.3275 94.9909 35.1444L94.9494 35.139C91.8382 34.7355 91.9924 31.886 91.9924 31.886C91.2653 31.8753 90.5583 32.1268 89.9998 32.5949C89.4412 33.063 89.068 33.7167 88.9477 34.4376Z"
            fill="#AA8D8C"
        />
        <Path
            d="M96.6417 39.4686C98.0316 41.6875 99.5147 45.7958 97.361 52.4463C97.361 52.4463 80.6088 95.554 49.084 105.576C49.084 105.576 35.6099 109.73 27.5546 100.913C24.1357 97.1705 24.1661 93.166 24.6389 89.4235C25.3803 83.5572 28.8043 78.3914 33.748 75.1852C36.4856 73.4366 39.4037 71.9915 42.4513 70.8753C46.952 69.1933 51.1815 66.8538 55.0033 63.932C62.1888 58.4369 73.3811 49.2417 81.6608 39.4943C82.6846 38.2763 83.989 37.3281 85.4605 36.7323C86.932 36.1365 88.5261 35.9111 90.104 36.0757C91.0215 36.1355 91.9301 36.293 92.8146 36.5455C94.3952 37.0321 95.7519 38.0683 96.6417 39.4686Z"
            fill="#7B9791"
        />
        <Path
            d="M73.1221 78.6263C79.0005 72.2025 83.4976 66.7068 86.9147 62.0121C88.5419 59.8125 89.7294 57.3166 90.4117 54.6627C91.4917 50.3233 90.9746 46.5112 89.3833 44.1696C89.1246 43.7957 88.777 43.4927 88.3722 43.2882C87.9674 43.0836 87.5181 42.9841 87.0652 42.9984C86.6124 43.0128 86.1702 43.1407 85.7791 43.3705C85.3879 43.6003 85.06 43.9248 84.8253 44.3143C74.6035 61.0667 56.7295 73.0056 47.8615 78.212C45.2941 79.7059 42.6315 81.0281 39.8911 82.1701C31.0576 85.8929 26.8988 90.5462 25.5908 94.3685C24.8074 96.6585 25.7792 99.175 27.4068 100.961C28.8791 102.475 30.8397 103.414 32.9374 103.608C53.1692 105.189 73.1221 78.6263 73.1221 78.6263Z"
            fill="#B6D2C6"
        />
        <Path
            d="M39.603 97.7866C42.5335 96.086 43.833 92.8339 42.5055 90.5229C41.178 88.2119 37.7262 87.7171 34.7958 89.4178C31.8653 91.1184 30.5658 94.3705 31.8932 96.6815C33.2207 98.9925 36.6725 99.4873 39.603 97.7866Z"
            fill="#DFEFE8"
        />
        <Path
            d="M47.722 88.3829C48.8765 88.3829 49.8124 87.4422 49.8124 86.2818C49.8124 85.1214 48.8765 84.1807 47.722 84.1807C46.5675 84.1807 45.6316 85.1214 45.6316 86.2818C45.6316 87.4422 46.5675 88.3829 47.722 88.3829Z"
            fill="#B6D2C6"
        />
    </Svg>
);

export default Zucchini;
