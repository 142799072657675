import {
    DeviceOperation as Operation,
    LogisticsStatus as Status,
} from "@madmedical/medical";

export default (currentStatus: Status) => {
    switch (currentStatus) {
        case Status.Storage:
            return [
                Operation.Update,
                Operation.ToStorage,
                Operation.Ordered,
                Operation.ToRepairs,
                Operation.Discard,
            ];
        case Status.Ordered:
            return [Operation.Update, Operation.Processing];
        case Status.Processing:
            return [Operation.Update, Operation.Delivery];
        case Status.Patient:
            return [
                Operation.Update,
                Operation.ToStorage,
                Operation.ToRepairs,
                Operation.Discard,
            ];
        case Status.Repairs:
            return [Operation.Update, Operation.ToStorage, Operation.Discard];
        case Status.Discarded:
            return [Operation.Update];
        case Status.Delivery:
            return [Operation.Update, Operation.ToPatient];
        default:
            throw "Unexpected status";
    }
};
