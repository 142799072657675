export enum CloudDeviceType {
    Google = "google",
    Garmin = "garmin",
    Polar = "polar",
    IHealth = "ihealth",
    Withings = "withings",
    Omron = "omron",
}

export enum BleDeviceType {
    AccuChekInstant = "accu_check_inst",
    Aoj20aBt = "aoj_20a",
    AirBp2 = "air_bp2",
    B02T = "b02t",
    LescaleF4 = "lescale_f4",
    CheckmeO2Max = "check_o2_max",
    Oxyfit = "oxyfit",

    Bm1000B = "bm1000b",

    Sigmafon = "sigmafon",
}

export enum AppleDeviceType {
    Apple = "apple",
}

export enum ManualDeviceType {
    Manual = "manual",
}

export type Provider =
    | CloudDeviceType
    | BleDeviceType
    | AppleDeviceType
    | ManualDeviceType;

export enum ProviderConnectionStatus {
    Off = "off",
    Inactive = "inactive", // No recent measurement
    Active = "active", // Has recent measurement
}

const FOR_HUMANS = {
    [ProviderConnectionStatus.Off]: "–",
    [ProviderConnectionStatus.Inactive]: "Inactive",
    [ProviderConnectionStatus.Active]: "Active",
};

export const getConnectionStatusChoices = (
    selected: ProviderConnectionStatus[] | undefined
) =>
    Object.values(ProviderConnectionStatus).map((connectionStatus) => ({
        key: connectionStatus,
        text: FOR_HUMANS[connectionStatus],
        isSelected: !!selected?.includes(connectionStatus),
    }));

export const connectionStatusForHumans = (value: ProviderConnectionStatus) =>
    FOR_HUMANS[value];
