import type { ComponentProps, ReactNode } from "react";
import { Box, HStack, Heading, Stack, Text, useTheme } from "native-base";
import Tooltip from "../atoms/Tooltip";
import Avatar from "../atoms/Avatar/Avatar";
import Icon from "../icons/Icon";
import Badge from "../atoms/Badge";
import useResponsive from "../util/useResponsive";

interface Props
    extends Pick<
        ComponentProps<typeof Stack>,
        | "size"
        | "minHeight"
        | "bgColor"
        | "borderBottomWidth"
        | "borderBottomColor"
        | "borderBottomStyle"
        | "borderBottomRadius"
        | "borderTopRadius"
        | "borderTopWidth"
        | "borderTopColor"
        | "textTransform"
        | "alignItems"
        | "justifyContent"
        | "borderTopRadius"
        | "borderTopWidth"
        | "borderTopColor"
        | "textTransform"
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
        | "bgColor"
    > {
    accessory?: ReactNode;
    avatar?: string;
    info?: string;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    title?: ReactNode;
    _title?: ComponentProps<typeof Heading>;
    badge?: number;
}

const Headline = ({
    accessory,
    avatar,
    info,
    leftContent,
    rightContent,
    size = "lg",
    title,
    badge,
    _title,
    ...rest
}: Props) => {
    const { colors } = useTheme();
    const { isSmallScreen } = useResponsive();

    return (
        <Stack
            testID="headline"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            space={2}
            width="full"
            minHeight={22}
            bgColor="white"
            borderBottomWidth={{
                base: 1,
                md: 1,
            }}
            borderBottomColor="gray.100"
            borderBottomStyle="solid"
            borderTopRadius="lg"
            px={{
                base: 4,
                md: 6,
            }}
            pb={4}
            {...rest}
        >
            {avatar && (
                <Box>
                    <Avatar size="3xl" source={avatar} />
                </Box>
            )}

            {title && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    space={1.5}
                    mr="auto"
                    pt="4"
                    maxWidth="full"
                >
                    <Heading
                        size={size}
                        fontWeight="medium"
                        numberOfLines={9}
                        maxWidth="full"
                        textTransform={
                            ["xs", "sm"].includes(size) ? "uppercase" : "none"
                        }
                        {..._title}
                        {..._title}
                    >
                        {title}
                        {badge && (
                            <Badge ml={2} hasIndicator={false} variant="ghost">
                                <Text>{badge}</Text>
                            </Badge>
                        )}
                    </Heading>

                    {accessory}

                    {info && (
                        <Tooltip
                            hasArrow
                            label={info}
                            placement={isSmallScreen ? "bottom" : "right"}
                        >
                            <Box>
                                <Icon
                                    name="help"
                                    size={14}
                                    fill={colors.gray[600]}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Stack>
            )}

            <HStack
                justifyContent="space-between"
                flexWrap="wrap"
                width={!title ? "full" : undefined}
            >
                {leftContent && (
                    <Stack
                        testID="headline-left-content"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        space={4}
                        maxWidth="full"
                        flex={1}
                        pt="4"
                        mr="auto"
                    >
                        {leftContent}
                    </Stack>
                )}
                {rightContent && (
                    <Stack
                        testID="headline-right-content"
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        pt="4"
                        space={4}
                        maxWidth="full"
                        ml="auto"
                    >
                        {rightContent}
                    </Stack>
                )}
            </HStack>
        </Stack>
    );
};

export default Headline;
