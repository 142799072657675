import type { ReactNode } from "react";
import {
    useGeneratePath,
    useIsActive,
    useRouteNavigate,
} from "@madmedical/user";
import { RoutingProvider as UiRoutingProvider } from "@madmedical/ui";

interface Props {
    children: ReactNode;
}

const RoutingProvider = ({ children }: Props) => {
    const navigate = useRouteNavigate();
    const generatePath = useGeneratePath();
    const isActive = useIsActive();

    return (
        <UiRoutingProvider value={{ navigate, isActive, generatePath }}>
            {children}
        </UiRoutingProvider>
    );
};

export default RoutingProvider;
