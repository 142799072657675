import type { CellProps, Column } from "react-table";
import { useMemo } from "react";
import type { DateTimeString, Ulid } from "@madmedical/utils";
import { formatTime } from "@madmedical/utils";
import { Box, Text } from "native-base";
import type { InsulinType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import ReactTable from "../molecules/ReactTable";
import ActionButton from "../molecules/ActionButton";
import { formatType } from "../util/insulinFormatters";
import type { InsulinReminder } from "../providers/insulinReminder";

interface Data {
    id: Ulid;
    manual?: boolean;
    deleted?: boolean;
    editable?: boolean;
}

interface TableParams {
    firstOccurrence: Date | DateTimeString;
    insulinType: InsulinType;
    comment: string | null;
}

interface ExtendedData extends TableParams, Data {}

interface Props {
    data: ExtendedData[];
    onEndReached?: () => unknown;
    onDeletePress: (id: Ulid) => void;
    onEditPress?: (reminder: InsulinReminder) => void;
}

const InsulinReminderTable = ({ data, onDeletePress, onEditPress }: Props) => {
    const { t } = useTranslation();
    const columns = useMemo<Column<ExtendedData>[]>(
        () => [
            {
                Header: t("appointment"),
                accessor: "firstOccurrence",
                width: "100px",
                Cell: ({ value }) =>
                    value ? <>{formatTime(value)}</> : <Box>-</Box>,
            },
            {
                Header: t("type"),
                accessor: "insulinType",
                width: "30%",
                minWidth: 100,
                Cell: ({ value }: { value: InsulinType }) =>
                    value ? (
                        <Box>{t(`${formatType(value)}`)}</Box>
                    ) : (
                        <Box>-</Box>
                    ),
            },
            {
                Header: t("comment"),
                accessor: "comment",
                width: "50%",
                Cell: ({ value }) =>
                    value ? (
                        <Text isTruncated noOfLines={3} maxWidth="95%">
                            {value}
                        </Text>
                    ) : (
                        <Box>-</Box>
                    ),
            },
            {
                Header: "",
                id: "action",
                width: 40,
                Cell: ({
                    row: { original: measurement },
                }: CellProps<ExtendedData>) => (
                    <Box flexDirection="row" justifyContent="flex-end" w="full">
                        <ActionButton
                            size="xs"
                            actions={
                                measurement?.editable
                                    ? [
                                          {
                                              text: t("edit"),
                                              iconName: "edit",
                                              onPress: () => {
                                                  onEditPress?.(
                                                      measurement as InsulinReminder
                                                  );
                                              },
                                          },
                                          {
                                              text: t("delete"),
                                              iconName: "trashCan",
                                              onPress: () => {
                                                  onDeletePress(measurement.id);
                                              },
                                          },
                                      ]
                                    : []
                            }
                        />
                    </Box>
                ),
            },
        ],
        [onDeletePress, onEditPress, t]
    );

    return <ReactTable columns={columns} data={data} />;
};

export default InsulinReminderTable;
