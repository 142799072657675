import { Box, HStack, Stack, Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Button from "../../atoms/Button";
import Paper from "../../atoms/Paper";
import useResponsive from "../../util/useResponsive";
import PregnancyBodyGraph from "../../charts/PregnancyBodyGraph";
import type { PregnancyPointData } from "../../charts/types";

interface Props {
    onMeasurePress: (arg: "weight") => void;
    weightPointData: PregnancyPointData[];
    ranges: {
        weight: {
            min: number;
            max: number;
        };
        bmi: {
            min: number;
            max: number;
        };
        percentile: {
            min: number;
            max: number;
        };
    };
    isDoctor?: boolean;
    currentWeek?: number;
    percentile?: {
        pregnancyWeek: number;
        valuePercentileMin: number;
        valuePercentileMax: number;
    }[];
}

const PregnancyGraphWeightMeasure = ({
    onMeasurePress,
    weightPointData,
    ranges = {
        weight: {
            min: 0,
            max: 0,
        },
        bmi: {
            min: 0,
            max: 0,
        },
        percentile: {
            min: 0,
            max: 0,
        },
    },
    isDoctor = false,
    currentWeek = 0,
    percentile,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    const lastWeight =
        weightPointData[weightPointData.length - 1]?.valueWeight ?? 0;
    const lastBmi = weightPointData[weightPointData.length - 1]?.valueBmi ?? 0;

    return (
        <Paper
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            borderTopWidth={0}
        >
            <Box p={isSmallScreen ? 4 : 6} width="full">
                <Stack alignItems="flex-start" direction={"row"} space={6}>
                    <Box flex="1">
                        {weightPointData?.length > 0 ? (
                            <>
                                <HStack space={2}>
                                    <Text>{t("pregnancy:weightAndBMI")}</Text>
                                    <Text color="gray.600">
                                        {t("pregnancy:weekNumber", {
                                            weekNr: currentWeek,
                                        })}
                                    </Text>
                                </HStack>

                                <HStack alignItems="center" space={4} mt={-1}>
                                    <HStack space={1} alignItems={"baseline"}>
                                        <Text
                                            fontSize="3xl"
                                            fontWeight={"medium"}
                                            mt={2}
                                        >
                                            {lastWeight}
                                        </Text>
                                        <Text color="gray.600">kg</Text>
                                    </HStack>
                                    <HStack space={1} alignItems={"baseline"}>
                                        <Text
                                            fontSize="2xl"
                                            fontWeight={"medium"}
                                            mt={2}
                                        >
                                            {lastBmi}
                                        </Text>
                                        <Text color="gray.600">BMI</Text>
                                    </HStack>
                                </HStack>
                            </>
                        ) : (
                            <HStack space={2}>
                                <Text>{t("pregnancy:weightAndBMI")}</Text>
                                <Text color="gray.600">{t("noData")}</Text>
                            </HStack>
                        )}
                    </Box>

                    {!isDoctor && !isSmallScreen && (
                        <Box>
                            <Button
                                onPress={() => onMeasurePress("weight")}
                                variant="outlined"
                                leftIconName="chartCustom"
                                forceIcon
                            >
                                {t("measures:addManualMeasurement")}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Box>
            <Box width="100%" p={isSmallScreen ? 0 : 4}>
                {weightPointData?.length > 0 && (
                    <PregnancyBodyGraph
                        graphType="weight"
                        pointData={weightPointData}
                        ranges={ranges}
                        percentile={percentile}
                    />
                )}
            </Box>
            {!isDoctor && isSmallScreen && (
                <Box width={"full"} px={4} mt={-4} mb={4}>
                    <Button
                        onPress={() => onMeasurePress("weight")}
                        variant="outlined"
                        leftIconName="chartCustom"
                        forceIcon
                    >
                        {t("measures:addManualMeasurement")}
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

export default PregnancyGraphWeightMeasure;
