import type { PageQuery, Paginated } from "@madmedical/store";
import { baseApi } from "@madmedical/store";
import type { Ulid } from "@madmedical/utils";
import type {
    CreateOpinionRequest,
    Opinion,
    OpinionFilter,
    PatientIdRequest,
    UpdateOpinionRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["opinions"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getOpinion: build.query<Opinion, Ulid>({
                query: (id) => `medical/opinions/${id}`,
                providesTags: ["opinions"],
            }),
            getOpinions: build.query<
                Paginated<Opinion>,
                PatientIdRequest & OpinionFilter & PageQuery
            >({
                query: ({ patientId, ...params }) => ({
                    url: `medical/patients/${patientId}/opinions`,
                    params,
                }),
                serializeQueryArgs: ({ queryArgs }) => {
                    const { page, pageSize, ...args } = queryArgs;

                    return args;
                },
                merge: (cachedData, responseData) => {
                    if (responseData.pagination.currentPage === 1) {
                        // Reset to first page
                        return responseData;
                    }

                    cachedData.items.push(...responseData.items);
                    cachedData.pagination = responseData.pagination;
                },
                forceRefetch: ({ currentArg, previousArg }) =>
                    !currentArg ||
                    !previousArg ||
                    currentArg.page !== previousArg.page ||
                    currentArg.pageSize !== previousArg.pageSize,
                providesTags: ["opinions"],
            }),
            createOpinion: build.mutation<Opinion, CreateOpinionRequest>({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/opinions`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["opinions"],
            }),
            updateOpinion: build.mutation<Opinion, UpdateOpinionRequest>({
                query: ({ id, ...body }) => ({
                    url: `medical/opinions/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["opinions"],
            }),
            deleteOpinion: build.mutation<void, Ulid>({
                query: (id) => ({
                    url: `medical/opinions/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["opinions"],
            }),
        }),
    });

export const {
    useGetOpinionsQuery,
    useGetOpinionQuery,
    useCreateOpinionMutation,
    useUpdateOpinionMutation,
    useDeleteOpinionMutation,
} = api;
