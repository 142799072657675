import { DocumentPreview, Spinner, useShowToast } from "@madmedical/ui";
import { useParams } from "@madmedical/routing";
import { invariant } from "@madmedical/utils";
import { useEffect, useState } from "react";
import { usePatientId, useRouteNavigate } from "@madmedical/user";
import { useGetDocumentQuery } from "./api";
import DeleteDocumentDialog from "./modals/DeleteDocumentDialog";
import adaptDocument from "./adaptDocument";

const Document = () => {
    const { isSelfOrRepresented } = usePatientId();
    const { documentId } = useParams<"documentId">();
    invariant(documentId);
    const { data: document, isLoading } = useGetDocumentQuery(documentId);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const navigate = useRouteNavigate();
    const showToast = useShowToast();

    // It could have been deleted
    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!document) {
            showToast("A dokumentumot törölték.", { variant: "error" });
            navigate("documents_general", {}); // TODO: Navigate back instead
        }
    }, [isLoading, document, showToast, navigate]);

    if (!document) {
        return <Spinner />;
    }

    const handleDeletePress = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        navigate(
            "opinion" in document ? "documents_opinion" : "documents_general",
            {},
            { replace: true }
        );
    };

    const hasOpinion = "opinion" in document;
    const canDelete = !hasOpinion && isSelfOrRepresented;

    return (
        <>
            <DocumentPreview
                document={adaptDocument(document)}
                onDeletePress={canDelete ? handleDeletePress : undefined}
            />
            <DeleteDocumentDialog
                id={document.id}
                open={deleteOpen}
                onClose={handleDeleteClose}
            />
        </>
    );
};

export default Document;
