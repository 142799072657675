import { useMemo, useState } from "react";
import { Box, HStack, Text, useTheme } from "native-base";
import type { Column } from "react-table";
import { getServiceColour, serviceForHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../../providers/routing";
import useResponsive from "../../util/useResponsive";
import Badge from "../../atoms/Badge";
import Headline from "../../molecules/Headline";
import Paper from "../../atoms/Paper";
import SearchInput from "../../molecules/Form/SearchInput";
import AvatarTexts from "../../molecules/AvatarTexts";
import Tooltip from "../../atoms/Tooltip";
import Icon from "../../icons/Icon";
import Tag from "../../atoms/Tag";
import ReactTable from "../../molecules/ReactTable";
import CallCenterPatientsDesktopFilter from "../../organisms/filters/desktop/CallCenterPatientsDesktopFilter";
import CallCenterPatientsMobileFilter from "../../organisms/filters/mobile/CallCenterPatientsMobileFilter";
import type { Patient, PatientCallCenter } from "../../providers/patient";
import { usePatients } from "../../providers/patient";

interface Props {
    onEndReached: () => unknown;
}

const CallCenterPatients = ({ onEndReached }: Props) => {
    const [search, setSearch] = useState("");
    const { patients, onSortChange, pagination, onSearchChange } =
        usePatients<PatientCallCenter>();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const { colors } = useTheme();

    const { isSmallScreen } = useResponsive();

    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange?.(search);
    };

    const handleRowPress = ({ userId }: Patient) => {
        navigate("patient", { patientId: userId });
    };

    const columns = useMemo<Column<PatientCallCenter>[]>(
        () => [
            {
                Header: t("patientName"),
                accessor: "fullName",
                defaultCanSort: !!onSortChange,
                width: "auto",
                Cell: ({ row }) => {
                    const { fullName, initials, avatarUrl } = row.original;

                    return (
                        <AvatarTexts
                            initials={initials}
                            label={fullName}
                            source={avatarUrl}
                        />
                    );
                },
            },
            {
                Header: () => (
                    <HStack alignItems="center">
                        <Text>{t("package")}</Text>
                        <Tooltip
                            hasArrow
                            label={t("doctor:packageLabelDescription")}
                            placement="right"
                        >
                            <Box ml={2}>
                                <Icon
                                    name="help"
                                    size={14}
                                    fill={colors.gray[600]}
                                />
                            </Box>
                        </Tooltip>
                    </HStack>
                ),
                accessor: "services",
                width: "20%",
                Cell: ({ value: services }) => (
                    <HStack space={1}>
                        {services.map((service) => (
                            <Tag
                                key={service}
                                variant={getServiceColour(service)}
                            >
                                {t(
                                    `backend:${serviceForHumans(service)}`
                                ).toLowerCase()}
                            </Tag>
                        ))}
                    </HStack>
                ),
                disableSortBy: true,
            },
            {
                Header: t("numberOfDevices"),
                accessor: "deviceCount",
                width: 144,
                disableSortBy: true,
            },
        ],
        [colors, onSortChange, t]
    );

    return (
        <>
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title={t("patients")}
                    accessory={
                        <Badge hasIndicator={false} variant="ghost">
                            {pagination.totalCount}
                        </Badge>
                    }
                />

                <Headline
                    leftContent={
                        isSmallScreen ? (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        ) : (
                            <CallCenterPatientsDesktopFilter />
                        )
                    }
                    rightContent={
                        isSmallScreen ? (
                            <CallCenterPatientsMobileFilter />
                        ) : (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        )
                    }
                />

                <Box px={6} pb={1} width="full">
                    <ReactTable
                        columns={columns}
                        data={patients}
                        onSortChange={onSortChange}
                        onEndReached={onEndReached}
                        onPress={handleRowPress}
                    />
                </Box>
            </Paper>
        </>
    );
};

export default CallCenterPatients;
