import { Box, Pressable, View } from "native-base";
import { Svg } from "react-native-svg";
import { isWeb } from "@madmedical/utils";
import CommentListCard from "../molecules/CommentListCard";
import MoodListCard from "../molecules/MoodListCard";
import { loadTooltipData } from "./utils";
import type {
    CommentGroupType,
    EvaluationBgHighlight,
    GraphData,
    MoodGroupType,
    TimeData,
    TooltipType,
} from "./types";
import { graphSlot, svgNative, svgWeb } from "./useStyles";
import CommentDrop from "./CommentDrop";
import GraphBar from "./GraphBar";
import GraphColumn from "./GraphColumn";
import GraphDot from "./GraphDot";
import GraphLinePaired from "./GraphLinePaired";
import GraphLineSingle from "./GraphLineSingle";
import ManualMeasures from "./ManualMeasures";
import Prediction from "./Prediction";
import TootlipTouchAreas from "./TootlipTouchAreas";
import Tags from "./Tags";
import TimeGridLines from "./TimeGridLines";
import TooltipCoord from "./TooltipCoord";
import GraphLineSingleDot from "./GraphLineSingleDot";
import MoodDrop from "./MoodDrop";

export default function GraphSlot({
    graphData = {},
    evaluationBgHighlight,
    setEvaluationBgHighlight,
    timeData,
    commentState,
    setCommentState,
    tooltip,
    setTooltip,
    moodGroup,
    setMoodGroup,
}: {
    graphData?: GraphData;
    timeData: TimeData;
    evaluationBgHighlight: EvaluationBgHighlight;
    setEvaluationBgHighlight: (state: EvaluationBgHighlight) => void;
    commentState: CommentGroupType;
    setCommentState: (
        callback: (state: CommentGroupType) => CommentGroupType
    ) => void;
    tooltip: TooltipType;
    setTooltip: (callback: (state: TooltipType) => TooltipType) => void;
    moodGroup: MoodGroupType;
    setMoodGroup: (callback: (state: MoodGroupType) => MoodGroupType) => void;
}) {
    const {
        type,
        limits,
        slot = { x: 0, y: 0 },
        paddings = { x: 0, y: 0 },
        predictions = { isPrediction: false },
    } = graphData;

    const { isPrediction } = predictions;

    const commentPosition =
        commentState.x > slot.x - 370 ? slot.x - 370 : commentState.x;

    function SvgContent() {
        return (
            <>
                {isPrediction && (
                    <Prediction graphData={graphData} timeData={timeData} />
                )}

                {type === "line-single" && (
                    <GraphLineSingle graphData={graphData} tooltip={tooltip} />
                )}
                {type === "line-single-dots" && (
                    <GraphLineSingleDot
                        graphData={graphData}
                        tooltip={tooltip}
                    />
                )}
                {type === "line-paired" && (
                    <GraphLinePaired graphData={graphData} tooltip={tooltip} />
                )}
                {type === "column" && (
                    <GraphColumn graphData={graphData} tooltip={tooltip} />
                )}
                {type === "dot" && (
                    <GraphDot graphData={graphData} tooltip={tooltip} />
                )}
                {type === "bar" && (
                    <GraphBar graphData={graphData} tooltip={tooltip} />
                )}

                {!isPrediction && (
                    <ManualMeasures graphData={graphData} tooltip={tooltip} />
                )}
            </>
        );
    }

    return (
        <Pressable
            zIndex={4}
            onPress={() => {
                setTooltip((state) => ({ ...state, onShow: false }));
                setCommentState((state) => ({
                    ...state,
                    onShow: false,
                }));
                setEvaluationBgHighlight({
                    start: null,
                    end: null,
                    selected: -1,
                });
                setMoodGroup((state) => ({
                    ...state,
                    onShow: false,
                }));
            }}
        >
            <View
                style={{
                    width: slot.x,
                    height: slot.y,
                    ...graphSlot,
                }}
            >
                {limits ? (
                    <TimeGridLines
                        limits={limits}
                        timeData={timeData}
                        evaluationBgHighlight={evaluationBgHighlight}
                    />
                ) : (
                    <></>
                )}

                {isWeb ? (
                    <Svg viewBox={`0 0 ${slot.x} ${slot.y}`} style={svgWeb}>
                        <SvgContent />
                    </Svg>
                ) : (
                    <Svg
                        viewBox={`0 0 ${slot.x} ${slot.y}`}
                        style={svgNative}
                        transform={[{ scaleY: -1 }]}
                    >
                        <SvgContent />
                    </Svg>
                )}

                <TootlipTouchAreas
                    graphData={graphData}
                    onTooltipClick={(tooltipProps) => {
                        setTooltip((state) =>
                            loadTooltipData({ state, ...tooltipProps })
                        );
                        setCommentState((state) => ({
                            ...state,
                            onShow: false,
                        }));
                        setEvaluationBgHighlight({
                            start: null,
                            end: null,
                            selected: -1,
                        });
                    }}
                />

                <Tags graphData={graphData} />

                {!isPrediction && (
                    // insulin type doesn't have comments, is it OK like this?
                    <CommentDrop
                        commentState={commentState}
                        graphData={graphData}
                        timeData={timeData}
                        setEvaluationBgHighlight={setEvaluationBgHighlight}
                        setCommentState={setCommentState}
                        setTooltip={setTooltip}
                        setMoodGroup={setMoodGroup}
                    />
                )}

                <TooltipCoord tooltip={{ ...tooltip, type }} />

                {!isPrediction && (
                    <Box
                        position="absolute"
                        display={commentState.onShow ? "flex" : "none"}
                        zIndex={3}
                        style={{
                            top: paddings.y,
                            left: commentPosition,
                            transform: [{ translateY: 24 }],
                        }}
                    >
                        <CommentListCard
                            comments={commentState.comments}
                            setCommentState={setCommentState}
                        />
                    </Box>
                )}

                {moodGroup?.status && (
                    <>
                        <MoodDrop
                            moodGroup={moodGroup}
                            setMoodGroup={setMoodGroup}
                            graphData={graphData}
                            timeData={timeData}
                            setTooltip={setTooltip}
                            setCommentState={setCommentState}
                        />
                        <Box
                            position="absolute"
                            display={moodGroup.onShow ? "flex" : "none"}
                            zIndex={3}
                            // eslint-disable-next-line react-native/no-inline-styles
                            style={{
                                top: paddings.y,
                                left: isWeb ? moodGroup.x : 10,
                                transform: [{ translateY: 24 }],
                            }}
                        >
                            <MoodListCard status={moodGroup.status} />
                        </Box>
                    </>
                )}
            </View>
        </Pressable>
    );
}
