import { Box, Divider, HStack, Text, VStack } from "native-base";
import { forHumans } from "@madmedical/medical";
import { formatDateRange } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Tag from "../../atoms/Tag";
import EvaluationStatuses from "../../atoms/EvaluationStatuses";
import StatusBadge from "../../atoms/StatusBadge";
import type { Evaluation } from "../../providers/evaluation";
import PressableLink from "../../atoms/PressableLink";

interface Props {
    evaluation: Evaluation;
}

const EvaluationListItem = ({
    evaluation: {
        id,
        metricType,
        alertLevel,
        dateRange,
        doctor,
        documentCount,
    },
}: Props) => {
    const { t } = useTranslation();

    return (
        <PressableLink route="opinion" params={{ opinionId: id }}>
            <HStack
                justifyContent="space-between"
                bgColor="white"
                minWidth="full"
                borderBottomWidth={1}
                borderColor="gray.100"
                px={4}
                py={5}
            >
                <VStack>
                    <HStack space={1.5} mb={1}>
                        <Tag size="sm" variant="ghost">
                            {t(`measures:${forHumans(metricType)}`)}
                        </Tag>
                        <StatusBadge level={alertLevel} />
                    </HStack>
                    <HStack
                        space={1}
                        divider={
                            <Divider orientation="vertical" bg="gray.100" />
                        }
                    >
                        <Text color="gray.600" fontSize="xs">
                            {formatDateRange(dateRange, "short")}
                        </Text>
                        <Text color="gray.600" fontSize="xs">
                            {doctor.fullName}
                        </Text>
                    </HStack>
                </VStack>
                <Box ml="auto">
                    <EvaluationStatuses type="document" count={documentCount} />
                </Box>
            </HStack>
        </PressableLink>
    );
};
export default EvaluationListItem;
