import { Fragment, useState } from "react";
import { Divider, Pressable, Text } from "native-base";
import { formatMeasuredAt, formatMeasuredAtShort } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Tooltip from "./Tooltip";
import {
    boxShadow,
    colors,
    tooltipCoordContentBox,
    tooltipCoordManualTag,
    tooltipCoordText,
} from "./useStyles";
import type { Styles, TooltipType } from "./types";

export default function TooltipCoord({
    tooltip = {
        displayValue: "",
        msec: 0,
        note: "",
        isManual: false,
        type: "",
        isGrouped: false,
        group: [],
    },
}: {
    tooltip: TooltipType;
}) {
    const {
        type = "",
        displayValue = "",
        msec = 0,
        note = "",
        isManual = false,
        isGrouped = false,
        group = [],
    } = tooltip;

    const styleTitle: Styles = {
        color: colors.black,
        fontSize: 16,
        fontWeight: "500",
        lineHeight: 24,
        marginBottom: 2,
    };

    const styleLink: Styles = {
        color: colors.green500,
        fontSize: 14,
        fontWeight: "500",
        lineHeight: 24,
        whiteSpace: "nowrap",
        marginTop: 4,
    };

    const groupedItemTitle: Styles = {
        color: colors.black,
        fontSize: 14,
        lineHeight: 21,
        fontWeight: "500",
    };

    const boxWidth: Styles = {
        width: isGrouped ? 200 : 150,
    };

    const [groupLength, setGroupLength] = useState(4);
    const { t } = useTranslation();

    return (
        <Tooltip
            tooltip={tooltip}
            styleArrow={{ borderTopColor: colors.white }}
            styleContentBox={{
                ...tooltipCoordContentBox,
                ...boxShadow,
                ...boxWidth,
            }}
            needsArrow={false}
        >
            {isGrouped && group?.length > 0 ? (
                <>
                    {group.map((item, index) => {
                        if (index < groupLength) {
                            return (
                                <Fragment key={`${item.displayValue} ${index}`}>
                                    <Text
                                        style={[
                                            tooltipCoordText,
                                            groupedItemTitle,
                                        ]}
                                    >
                                        {item.displayValue}
                                    </Text>
                                    <Text style={tooltipCoordText}>
                                        {formatMeasuredAt(item.administeredAt)}
                                    </Text>
                                    <Divider thickness={0} mb={2} />
                                </Fragment>
                            );
                        }
                    })}
                    {group.length > groupLength && (
                        <Pressable onPress={() => setGroupLength(group.length)}>
                            <Text style={[tooltipCoordText, styleLink]}>
                                {t("measures:viewAllGroup", {
                                    count: group.length,
                                })}
                            </Text>
                        </Pressable>
                    )}
                </>
            ) : (
                <>
                    <Text style={[tooltipCoordText, styleTitle]}>
                        {displayValue}
                    </Text>
                    <Text style={tooltipCoordText}>
                        {type === "column"
                            ? formatMeasuredAtShort(msec)
                            : formatMeasuredAt(msec)}
                    </Text>
                    <Text style={tooltipCoordText}>{note}</Text>
                    {isManual && (
                        <Text style={tooltipCoordManualTag}>
                            {t("measures:manual").toUpperCase()}
                        </Text>
                    )}
                </>
            )}
        </Tooltip>
    );
}
