import Svg, { Path, Rect } from "react-native-svg";

const Apple = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M56.4828 39.0868C56.4828 39.0868 41.464 35.7408 29.5513 46.5161C26.9357 48.8959 24.8673 51.8198 23.4898 55.0844C22.1122 58.349 21.4586 61.876 21.5744 65.4202C21.8573 74.7837 24.6188 90.4924 33.7022 102.233C33.7022 102.233 40.1702 109.693 53.1928 104.122C68.8607 97.4186 85.4455 85.1905 87.1536 65.3883C87.4837 61.5608 87.2015 54.9355 85.4818 51.5044C82.1531 44.8658 73.4181 40.1714 56.4828 39.0868Z"
            fill="#C65151"
        />
        <Path
            d="M87.8431 49.5738C84.5157 42.9347 74.4608 35.9735 56.4828 39.0866L56.1407 42.3248C53.8793 42.5028 50.5899 42.3104 49.6797 42.3518C49.1715 42.3873 48.6661 42.4551 48.1665 42.5548C47.6352 42.6632 47.1439 42.9165 46.7463 43.2868C46.3487 43.6571 46.06 44.1302 45.9119 44.6543C45.7638 45.1785 45.762 45.7334 45.9067 46.2585C46.0514 46.7836 46.3369 47.2586 46.7321 47.6315C48.6115 49.3969 51.0664 50.4976 54.0333 50.9032C55.1181 51.0537 56.2118 51.1301 57.3069 51.1319C59.4378 51.1465 61.5572 50.8154 63.5832 50.1513C64.7506 49.7545 66.0054 49.6953 67.2046 49.9805C68.4038 50.2657 69.4992 50.8837 70.3659 51.7642L70.4054 51.8051C72.5457 54.0439 74.9583 57.8966 76.0731 64.3604C76.5348 67.0922 76.023 69.9002 74.6279 72.2901C73.2328 74.68 71.0437 76.4988 68.4459 77.4263C62.9985 79.4045 56.5857 83.5007 55.1885 91.9257C54.6662 95.2573 53.2284 98.3759 51.0373 100.93C48.0728 104.321 39.67 106.988 33.7068 102.229C34.7798 103.623 35.9387 104.948 37.1766 106.196C38.5672 107.567 40.3238 108.503 42.2333 108.891C44.1429 109.279 46.1233 109.101 47.9344 108.38C49.0452 107.944 50.0936 107.363 51.0521 106.65C51.7339 106.132 52.5565 105.835 53.4103 105.799C54.2641 105.763 55.1087 105.989 55.8316 106.447C57.6611 107.595 59.6744 108.416 61.7825 108.874C64.2837 109.44 66.9054 109.032 69.1189 107.731C75.5872 103.952 87.9317 93.155 90.6041 62.1754C90.9344 58.3479 89.5628 53.0049 87.8431 49.5738Z"
            fill="#B34747"
        />
        <Path
            d="M62.0991 41.9963C62.0991 41.9963 63.4627 25.7459 77.8394 21.7718C83.9297 20.0882 90.0076 21.0063 94.988 22.6239C94.988 22.6239 97.1551 23.3636 99.0834 23.2733C99.1742 23.2694 99.2645 23.2891 99.3456 23.3303C99.4267 23.3716 99.4959 23.4331 99.5467 23.5089C99.5974 23.5847 99.6279 23.6723 99.6353 23.7634C99.6427 23.8545 99.6268 23.9459 99.589 24.029C97.5688 28.4972 86.9809 48.5289 62.0991 41.9963Z"
            fill="#B6D2C6"
        />
        <Path
            d="M71.9053 84.7762L74.6004 83.0279C85.6917 80.4175 93.951 75.7542 97.0749 62.204C99.5662 59.2816 104.333 59.8682 105.915 63.4641C108.087 68.3985 109.643 75.699 107.183 85.1626C102.264 104.089 81.974 105.634 81.974 105.634C81.974 105.634 70.9338 107.594 68.6773 97.2137C66.6789 88.0208 71.9053 84.7762 71.9053 84.7762Z"
            fill="#F8D0C5"
        />
        <Path
            d="M71.9054 84.7764C70.837 86.7739 70.1442 88.9523 69.8618 91.2023C68.8571 100.741 76.7137 105.058 84.4418 104.441C92.8209 103.772 96.8569 99.4961 101.328 96.1708C101.328 96.1708 95.2424 108.182 81.2517 109.096C81.2517 109.096 68.2221 109.979 64.7573 100.672C60.6837 89.7298 71.9054 84.7764 71.9054 84.7764Z"
            fill="#C65151"
        />
        <Path
            d="M97.0751 62.2041C97.0751 62.2041 89.4751 78.769 74.6003 83.0275C75.1881 83.1618 75.7346 83.4378 76.1927 83.8317C78.7612 86.0356 82.5191 92.8991 91.1022 88.7431C95.0359 86.8385 96.8902 83.849 97.7449 81.1694C98.4909 78.77 98.5747 76.212 97.9872 73.7685C97.4235 71.3519 96.4972 66.438 97.0751 62.2041Z"
            fill="#FAB398"
        />
        <Path
            d="M91.9662 79.7475C92.0211 79.5523 92.0203 79.3455 91.9641 79.1507C91.9078 78.9559 91.7983 78.7809 91.6479 78.6455C91.4976 78.5102 91.3125 78.42 91.1136 78.3852C90.9148 78.3504 90.7103 78.3724 90.5233 78.4487C89.6693 78.8034 88.6622 79.5819 87.8183 81.2387C87.6389 81.5843 87.5494 81.9698 87.5582 82.3595C87.5787 83.0029 87.8673 83.9151 89.2168 84.1023C90.7602 84.3164 91.3974 81.7839 91.3974 81.7839L91.9662 79.7475Z"
            fill="#333333"
        />
        <Path
            d="M35.3866 69.857C38.2676 69.857 40.603 67.3328 40.603 64.219C40.603 61.1053 38.2676 58.5811 35.3866 58.5811C32.5056 58.5811 30.1702 61.1053 30.1702 64.219C30.1702 67.3328 32.5056 69.857 35.3866 69.857Z"
            fill="#FFE9E9"
        />
        <Path
            d="M88.771 26.7842C86.1309 27.0372 83.5188 27.5286 80.9664 28.2527C81.2154 27.656 81.4128 27.0169 81.6201 26.3426C81.784 25.8101 81.9533 25.2602 82.1647 24.669C82.2065 24.5523 82.225 24.4284 82.2192 24.3045C82.2134 24.1805 82.1833 24.059 82.1307 23.9467C82.0782 23.8345 82.0041 23.7337 81.9128 23.6502C81.8216 23.5668 81.7148 23.5022 81.5987 23.4601C81.3641 23.3753 81.1056 23.3875 80.8801 23.4942C80.6545 23.6009 80.4804 23.7933 80.396 24.029C80.1698 24.6601 79.9862 25.2572 79.8239 25.7844C79.3331 27.3808 79.0034 28.445 78.0092 29.2233C76.2612 29.872 74.5624 30.6472 72.926 31.543C73.1464 30.3756 73.6758 29.2895 74.4586 28.399C74.6359 28.2238 74.7368 27.9851 74.7394 27.7352C74.742 27.4853 74.646 27.2446 74.4724 27.0657C74.2988 26.8868 74.0618 26.7843 73.8132 26.7806C73.5646 26.7769 73.3247 26.8724 73.1459 27.0461C73.0571 27.1327 71.0631 29.1267 70.8846 32.7485C67.7914 34.595 64.6637 37.6061 63.2956 39.1092C60.6822 41.9801 58.5753 43.2849 56.4919 45.9881L57.2726 47.9539C59.685 44.761 62.0131 43.3028 64.7386 40.3233C66.2553 38.6669 67.9268 37.1606 69.73 35.8253C70.4177 35.4705 71.1827 35.2939 71.9555 35.3116C72.7284 35.3293 73.4846 35.5408 74.1555 35.9268C75.2147 36.4916 76.3981 36.78 77.597 36.7656C77.9329 36.7652 78.2685 36.746 78.6022 36.708C78.7262 36.6958 78.8464 36.6589 78.9561 36.5995C79.0657 36.5402 79.1625 36.4595 79.2409 36.3623C79.3192 36.265 79.3776 36.153 79.4126 36.0329C79.4475 35.9128 79.4584 35.7869 79.4445 35.6625C79.4307 35.5381 79.3924 35.4177 79.3318 35.3084C79.2713 35.199 79.1898 35.1028 79.092 35.0253C78.9941 34.9479 78.882 34.8908 78.762 34.8572C78.642 34.8237 78.5166 34.8145 78.3931 34.8301C77.246 34.9921 76.0771 34.7936 75.0465 34.2619C74.4615 33.9417 73.834 33.7069 73.1831 33.5646C77.2689 31.2156 82.4638 29.3015 88.9538 28.664C89.1989 28.6359 89.4232 28.5121 89.5783 28.3192C89.7334 28.1264 89.8069 27.88 89.7829 27.6333C89.7589 27.3865 89.6394 27.1591 89.4501 27.0001C89.2608 26.8411 89.0169 26.7632 88.771 26.7834V26.7842Z"
            fill="#7B9791"
        />
        <Path
            d="M55.232 48.5581H56.4539C56.7886 48.5578 57.1113 48.4323 57.3591 48.2062C57.6069 47.98 57.7621 47.6693 57.7946 47.3345C59.0087 34.7231 54.7383 26.9984 52.3504 23.7538C51.9834 23.2541 51.4356 22.9195 50.8252 22.8222C50.2149 22.7249 49.591 22.8727 49.0881 23.2337L46.1042 25.3759C53.0183 30.5296 54.0577 42.3762 54.1721 47.5141C54.1778 47.7929 54.292 48.0583 54.4901 48.2535C54.6883 48.4486 54.9546 48.558 55.232 48.5581Z"
            fill="#7B5F5E"
        />
    </Svg>
);

export default Apple;
