import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Checkbox as BaseCheckbox, useTheme } from "native-base";
import Icon from "../icons/Icon";

interface Props extends ComponentProps<typeof BaseCheckbox> {
    type?: "checkmark" | "subtract";
    variant?: "primary" | "greenFilled" | "orange" | "orangeFilled";
}

const Checkbox = ({
    children,
    type = "checkmark",
    variant = "primary",
    value,
    size = "sm",
    ...rest
}: Props) => {
    const { colors } = useTheme();

    const borderVariants = useMemo(
        () => ({
            primary: colors.green[500],
            greenFilled: colors.green[500],
            orange: "transparent",
            orangeFilled: "transparent",
        }),
        [colors]
    );

    const bgVariants = useMemo(
        () => ({
            primary: colors.green[25],
            greenFilled: colors.green[75],
            orange: colors.orange[400],
            orangeFilled: colors.orange[500],
        }),
        [colors]
    );

    const iconVariants = useMemo(
        () => ({
            primary: colors.green[500],
            greenFilled: colors.green[500],
            orange: colors.white,
            orangeFilled: colors.white,
        }),
        [colors]
    );

    return (
        <BaseCheckbox
            value={value.toString()}
            size={size}
            borderWidth={1}
            outlineStyle="none"
            icon={<Icon name={type} size={12} fill={iconVariants[variant]} />}
            borderColor={borderVariants[variant]}
            bgColor={bgVariants[variant]}
            alignItems="flex-start"
            _checked={{
                bg: bgVariants[variant],
                borderColor: borderVariants[variant],
                _icon: {
                    color: iconVariants[variant],
                    size: 12,
                },
                _hover: {
                    borderColor: borderVariants[variant],
                },
            }}
            _hover={{
                borderColor: borderVariants[variant],
            }}
            {...rest}
        >
            {children}
        </BaseCheckbox>
    );
};

Checkbox.Group = BaseCheckbox.Group;

export default Checkbox;
