import { useMemo } from "react";
import { Box, HStack, Text, VStack, useTheme } from "native-base";
import { AlertLevel } from "@madmedical/medical";
import Icon from "../icons/Icon";

interface Props {
    alertLevel?: AlertLevel | null;
    displayValue?: string[];
    valueFontSize?: "4xl" | "5xl";
    unitFontSize?: "xs" | "sm" | "md";
}

const Metrics = ({
    displayValue,
    alertLevel,
    valueFontSize = "4xl",
    unitFontSize = "sm",
}: Props) => {
    const { colors } = useTheme();
    const iconNames = useMemo(
        () =>
            ({
                [AlertLevel.Alert]: "bullhorn",
                [AlertLevel.Warning]: "warning",
                [AlertLevel.Ok]: "checkmark",
            } as const),
        []
    );

    const iconColors = useMemo(
        () =>
            ({
                [AlertLevel.Alert]: colors.rose[500],
                [AlertLevel.Warning]: colors.yellow[600],
                [AlertLevel.Ok]: colors.green[500],
            } as const),
        [colors]
    );

    if (!displayValue?.length) {
        return null;
    }

    return (
        <HStack space={1}>
            {displayValue.map((item, index) => {
                const [value, unit] = item.split(" ");

                return (
                    <Box
                        key={`metrics-item-${index}`}
                        mr={2}
                        flexDirection="row"
                    >
                        {value && (
                            <Text fontSize={valueFontSize}>
                                {Number(value)
                                    ? Number(value).toLocaleString()
                                    : value}
                            </Text>
                        )}
                        <VStack justifyContent="flex-end" ml={1} mb={1.5}>
                            {alertLevel ? (
                                <Icon
                                    name={iconNames[alertLevel]}
                                    size={12}
                                    fill={iconColors[alertLevel]}
                                />
                            ) : null}
                            {unit && (
                                <Text fontSize={unitFontSize} color="gray.600">
                                    {unit}
                                </Text>
                            )}
                        </VStack>
                    </Box>
                );
            })}
        </HStack>
    );
};

export default Metrics;
