import type { PreOpQuestionKey } from "@madmedical/medical";
import { invariant } from "@madmedical/utils";
import type { ComponentProps } from "react";
import PreOpSurveyRow from "../../../molecules/PreOpSurveyRow";
import { usePreOp } from "../../../providers/preop";

interface Props
    extends Pick<
        ComponentProps<typeof PreOpSurveyRow>,
        "hasOptionalAnswer" | "pl" | "hasDivider"
    > {
    questionkey: PreOpQuestionKey;
}

const PreOpResultRow = ({ questionkey, ...rest }: Props) => {
    const { questionnaire } = usePreOp();
    invariant(questionnaire);

    return (
        <PreOpSurveyRow
            isReadOnly
            name={questionkey}
            isYes={questionnaire.answers[questionkey] ?? false}
            answer={questionnaire.subAnswers[questionkey]}
            {...rest}
        />
    );
};

export default PreOpResultRow;
