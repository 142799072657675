import Svg, { Path, Rect } from "react-native-svg";

const ChangingMood = () => (
    <Svg width="100%" height="100%" viewBox="0 0 65 64" fill="none">
        <Rect x="0.5" width="64" height="64" rx="4" fill="#D7E7E0" />
        <Path
            d="M51.9413 19.3711C51.9413 20.6321 50.9197 21.6537 49.6587 21.6537H46.0446C45.2617 22.9786 43.8229 23.8704 42.1744 23.8704C39.6908 23.8704 37.6751 21.8576 37.6737 19.3725V19.3711V19.3704C37.6737 16.8867 39.69 14.8711 42.1744 14.8711C43.8229 14.8711 45.2624 15.7628 46.0453 17.0884H49.6587C50.919 17.0877 51.9413 18.1093 51.9413 19.3711ZM17.8989 21.6537H14.2834C13.0223 21.6537 12 20.6314 12 19.3711C12 18.1101 13.0223 17.0877 14.2834 17.0877H17.8989C18.6818 15.7628 20.1213 14.8711 21.7698 14.8711C24.2542 14.8711 26.2691 16.886 26.2691 19.3696V19.3711V19.3725C26.2669 21.8576 24.2535 23.8704 21.7698 23.8704C20.1213 23.8704 18.6833 22.9786 17.8989 21.6537ZM48.9041 43.471C48.6262 44.0631 46.0504 49.2683 41.1746 49.6231C41.004 49.6362 40.8321 49.6413 40.6609 49.6413C37.6018 49.6413 34.6321 47.6532 31.8256 43.7206C30.4063 41.734 28.2296 39.3831 25.8664 39.3831C25.8309 39.3831 25.796 39.3831 25.7612 39.3838C23.0955 39.4629 20.6931 42.4668 19.9922 43.6176C19.4973 44.4287 18.3321 44.748 17.3881 44.3214C16.4449 43.8969 16.0763 42.8949 16.5675 42.0808C16.7111 41.8421 20.1533 36.2298 25.6139 36.0578C29.0423 35.9526 32.2341 37.9414 35.1131 41.9741C37.1904 44.8851 39.2271 46.435 40.8502 46.3058C42.8535 46.16 44.7299 43.4935 45.3227 42.2245C45.7232 41.3719 46.8471 40.9591 47.8375 41.3037C48.8257 41.6491 49.3032 42.6192 48.9041 43.471Z"
            fill="#7B9791"
        />
    </Svg>
);

export default ChangingMood;
