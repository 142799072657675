import { Box, Stack } from "native-base";
import type { AppRoutes } from "@madmedical/routing";
import { useTranslation } from "@madmedical/i18n";
import RoutableTabsBreaking from "../../molecules/RoutableTabsBreaking";
import useResponsive from "../../util/useResponsive";

interface Props {
    addEntryButton?: JSX.Element;
    isDoctor?: boolean;
}

const PregnancyPageTabs = ({ addEntryButton, isDoctor = false }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const filterForDoctor = ["pregnancy_body", "pregnancy_baby"];
    const tabs = [
        {
            title: t("pregnancy:bodyChanges"),
            route: "pregnancy_body" as keyof AppRoutes,
            params: {},
        },
        {
            title: t("pregnancy:babyDevelopment"),
            route: "pregnancy_baby" as keyof AppRoutes,
            params: {},
        },
        {
            title: t("measures:title"),
            route: "pregnancy_measurement" as keyof AppRoutes,
            params: {},
        },
        {
            title: t("pregnancy:feelingAndSymptoms"),
            route: "pregnancy_feeling" as keyof AppRoutes,
            params: {},
        },
    ];

    return (
        <Box
            testID="tabs"
            px={{
                base: 2,
                md: 4,
            }}
            backgroundColor="white"
            borderColor="gray.100"
            borderBottomWidth={1}
            borderLeftWidth={1}
            borderRightWidth={1}
            //width={"full"}
        >
            <Stack
                direction={isSmallScreen ? "column" : "row"}
                space={2}
                //width="full"
                alignItems={"flex-start"}
                justifyContent={addEntryButton ? "space-between" : "flex-start"}
            >
                <RoutableTabsBreaking
                    tabs={
                        !isDoctor
                            ? [...tabs]
                            : tabs.filter(
                                  (tab) => !filterForDoctor.includes(tab.route)
                              )
                    }
                    _tab={{
                        px: 2,
                        py: {
                            base: 2,
                            sm: isSmallScreen && tabs.length > 2 ? 2 : 4,
                        },
                    }}
                />
                {addEntryButton}
            </Stack>
        </Box>
    );
};

export default PregnancyPageTabs;
