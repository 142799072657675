import type { Provider } from "@madmedical/medical";
import {
    AppleDeviceType,
    BleDeviceType,
    CloudDeviceType,
} from "@madmedical/medical";
import garmin from "./images/garmin.png";
import google from "./images/google.png";
import ihealth from "./images/ihealth.png";
import omron from "./images/omron.png";
import polar from "./images/polar.png";
import withings from "./images/withings.png";
import accuCheckInst from "./images/accu_check_inst.jpg";
import airBp2 from "./images/air_bp2.jpg";
import aoj20a from "./images/aoj_20a.jpg";
import b02t from "./images/b02t.jpg";
import bm1000b from "./images/bm1000b.jpg";
import checkO2Max from "./images/check_o2_max.jpg";
import lescaleF4 from "./images/lescale_f4.jpg";
import oxyfit from "./images/oxyfit.jpg";
import apple from "./images/apple.png";

export default (deviceType: Provider) => {
    switch (deviceType) {
        case AppleDeviceType.Apple:
            return apple;
        case CloudDeviceType.Garmin:
            return garmin;
        case CloudDeviceType.Google:
            return google;
        case CloudDeviceType.IHealth:
            return ihealth;
        case CloudDeviceType.Omron:
            return omron;
        case CloudDeviceType.Polar:
            return polar;
        case CloudDeviceType.Withings:
            return withings;
        case BleDeviceType.AccuChekInstant:
            return accuCheckInst;
        case BleDeviceType.AirBp2:
            return airBp2;
        case BleDeviceType.Aoj20aBt:
            return aoj20a;
        case BleDeviceType.B02T:
            return b02t;
        case BleDeviceType.Bm1000B:
            return bm1000b;
        case BleDeviceType.CheckmeO2Max:
            return checkO2Max;
        case BleDeviceType.LescaleF4:
            return lescaleF4;
        case BleDeviceType.Oxyfit:
            return oxyfit;
        default:
            return undefined;
    }
};
