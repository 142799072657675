import { Box } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import Paper from "../../atoms/Paper";
import ListHeader from "../../molecules/ListHeader";
import Headline from "../../molecules/Headline";
import ProtocolCard from "../../molecules/ProtocolCard";

interface Props {
    metricType: MetricType;
    instructions: string;
}

const ProtocolSection = ({ metricType, instructions }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    return (
        <Box
            width="full"
            px={{
                base: 4,
                md: 0,
            }}
            mb={3}
        >
            {isSmallScreen && (
                <ListHeader
                    title={t("measures:measureProtocol")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={0}
                />
            )}

            <Paper>
                {!isSmallScreen && (
                    <Headline title={t("measures:measureProtocol")} size="xs" />
                )}

                <ProtocolCard
                    protocols={[{ metricType, instructions }]}
                    borderWidth={0}
                    px={6}
                />
            </Paper>
        </Box>
    );
};

export default ProtocolSection;
