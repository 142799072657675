import Svg, { Path, Rect } from "react-native-svg";

const Avocado = () => (
    <Svg width="130" height="130" viewBox="0 0 130 130" fill="none">
        <Rect width="130" height="130" rx="65" fill="white" />
        <Path
            d="M39.1157 27.2291L41.5629 26.36C42.1643 26.1446 42.8227 26.1498 43.4206 26.3747C44.0186 26.5996 44.5172 27.0296 44.8276 27.5879C46.0357 29.7797 47.4812 33.891 46.4576 40.0775H42.9605C42.9605 40.0775 43.069 32.5237 39.1157 27.2291Z"
            fill="#B6D2C6"
        />
        <Path
            d="M61.2929 65.5651C58.3807 60.6832 56.3123 55.3453 55.1747 49.7758C54.1307 44.5166 51.5285 38.5068 45.0847 38.5068C38.6409 38.5068 36.036 44.5166 34.9918 49.7758C33.8541 55.3453 31.7857 60.6832 28.8735 65.5651C24.4088 73.1451 20.4931 82.8186 22.3063 91.8842C25.8004 109.35 45.0847 108.372 45.0847 108.372C45.0847 108.372 64.2277 108.469 67.8609 91.8842C69.8378 82.8533 65.7576 73.1448 61.2929 65.5651Z"
            fill="#B6D2C6"
        />
        <Path
            d="M32.6098 77.666C33.1408 77.666 33.5712 77.2355 33.5712 76.7046C33.5712 76.1736 33.1408 75.7432 32.6098 75.7432C32.0789 75.7432 31.6484 76.1736 31.6484 76.7046C31.6484 77.2355 32.0789 77.666 32.6098 77.666Z"
            fill="#7B9791"
        />
        <Path
            d="M32.1682 84.3251C32.6992 84.3251 33.1296 83.8947 33.1296 83.3637C33.1296 82.8328 32.6992 82.4023 32.1682 82.4023C31.6372 82.4023 31.2068 82.8328 31.2068 83.3637C31.2068 83.8947 31.6372 84.3251 32.1682 84.3251Z"
            fill="#7B9791"
        />
        <Path
            d="M38.5901 82.0146C39.121 82.0146 39.5515 81.5842 39.5515 81.0532C39.5515 80.5222 39.121 80.0918 38.5901 80.0918C38.0591 80.0918 37.6287 80.5222 37.6287 81.0532C37.6287 81.5842 38.0591 82.0146 38.5901 82.0146Z"
            fill="#7B9791"
        />
        <Path
            d="M31.3955 93.5814C27.8508 92.472 24.3824 90.3825 21.861 86.6292C21.8178 88.3917 21.9674 90.1536 22.3072 91.8835C25.8005 109.349 45.0848 108.372 45.0848 108.372C45.0848 108.372 64.2279 108.468 67.861 91.8835C69.8393 82.8526 65.7591 73.1444 61.2939 65.5644C60.2215 63.7476 59.2568 61.8694 58.4045 59.9395C59.4527 64.3955 59.8725 68.9763 59.6517 73.5487C58.8513 87.8044 45.0231 97.8426 31.3955 93.5814Z"
            fill="#7B9791"
        />
        <Path
            d="M71.675 103.581C71.675 103.581 84.5245 113.559 98.3191 105.262C108.642 99.0528 108.103 84.0149 107.178 76.8394C106.791 73.793 106.807 70.7086 107.227 67.6665L108.068 61.5017C108.255 60.1449 108.112 58.7632 107.651 57.4734C107.191 56.1836 106.426 55.0239 105.422 54.0924C104.683 53.4104 103.907 52.7696 103.097 52.1729L71.675 103.581Z"
            fill="#7B9791"
        />
        <Path
            d="M103.097 52.1718L103.105 52.1778C104.162 52.9354 104.943 54.017 105.33 55.2587C105.717 56.5004 105.688 57.8344 105.248 59.0583C104.141 62.162 102.838 66.6548 102.514 71.2959C102.451 72.2056 102.36 73.4195 102.215 74.8488C102.215 74.8488 98.5828 90.6333 83.5034 98.2498C68.4241 105.866 61.4075 85.5064 61.4075 85.5064C61.7254 73.9683 69.4388 67.1535 80.731 63.6429C80.731 63.6429 87.5904 61.8145 91.6809 56.9323C96.9925 50.5916 100.534 50.3878 103.097 52.1718Z"
            fill="#F0F4C3"
        />
        <Path
            d="M102.214 74.8496C94.0476 85.7705 80.3973 96.3343 61.4095 85.5073C61.3987 85.9004 61.3952 86.2979 61.4017 86.7022C61.4315 90.598 62.6414 94.3933 64.872 97.5874C68.2679 102.467 73.7668 105.43 79.6935 105.903C97.1775 107.298 101.152 85.3078 102.214 74.8496Z"
            fill="#D3D385"
        />
        <Path
            d="M86.4706 72.6387C84.3329 71.467 81.6574 71.3742 79.0462 72.1592C76.1471 73.0305 73.1203 74.9406 70.7364 78.7799C70.6493 78.9203 70.565 79.0597 70.4837 79.1983C69.8838 80.2126 69.455 81.3188 69.2144 82.4724C69.2144 82.4724 71.6748 98.7983 89.9106 86.5805C89.9106 86.5802 93.1452 76.2973 86.4706 72.6387Z"
            fill="#AA8D8C"
        />
        <Path
            d="M86.4697 72.6396C86.6635 72.7749 92.7944 77.1441 86.9606 85.0228C86.1193 86.1637 85.0513 87.1184 83.8236 87.827C82.5959 88.5355 81.235 88.9827 79.8262 89.1404C76.4344 89.559 73.0023 88.3062 70.8646 85.6392C70.1099 84.7032 69.5488 83.6264 69.214 82.4715C67.8029 89.3087 73.5581 96.262 81.1421 95.3244C85.4715 94.7889 90.3497 92.5885 92.3953 85.8822C92.554 85.3015 92.6764 84.7115 92.7617 84.1156C93.1119 81.7779 92.6862 79.3899 91.5498 77.3172C90.4134 75.2445 88.629 73.6015 86.4697 72.6396Z"
            fill="#7B5F5E"
        />
        <Path
            d="M79.8327 79.616C81.2657 78.598 81.7811 76.8629 80.9839 75.7407C80.1866 74.6185 78.3786 74.534 76.9456 75.5521C75.5125 76.5702 74.9971 78.3052 75.7944 79.4275C76.5917 80.5497 78.3997 80.6341 79.8327 79.616Z"
            fill="#EFD1CB"
        />
    </Svg>
);

export default Avocado;
