import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import { useEnsureContext } from "@madmedical/utils";
import type {
    DateRange,
    DateRangeStrings,
    DateTimeString,
    Ulid,
} from "@madmedical/utils";
import type {
    AlertLevel,
    Metric,
    Pregnancy,
    PregnancyWellBeing,
    Profile,
    ProfileRole,
    Provider,
    Service,
    UnderlyingCondition,
} from "@madmedical/medical";

type BasePatient = Profile & {
    userId: Ulid;
    role: ProfileRole.Patient;
    birthDate: DateTimeString | null;
};

type PatientMe = BasePatient & {
    onboarded: boolean;
    underlyingConditions: UnderlyingCondition[];
    services: Service[];
    maxDeviceCount: number;
    maxRepresentativeCount: number;
    gender?: "male" | "female";
};
export interface Measurement<TMetric extends Metric = Metric> {
    readonly id: Ulid;
    readonly measuredAt: DateTimeString;
    readonly metric: TMetric;
    readonly provider: string;
    readonly manual: boolean;
    readonly deleted: boolean;
    readonly alertLevel: AlertLevel | null;
}
interface MeasurementRangeResponse<TMetric extends Metric = Metric> {
    readonly items: Measurement<TMetric>[];
    readonly stats: {
        unit: string;
        min: TMetric | null;
        max: TMetric | null;
        displayRange: string | null;
        lastMetricWithoutUnit: string | null;
        worstAlertLevel: AlertLevel | null;
        providers: Provider[];
        dateRange: DateRangeStrings;
    };
}

export enum ModalState {
    Closed,
    Measure,
    Feeling,
    Edit,
    Delete,
}

interface ContextValue {
    selectedRange?: DateRange | null;
    weightData?: MeasurementRangeResponse;
    bmiData?: MeasurementRangeResponse;
    circumferenceData?: MeasurementRangeResponse;
    pregnancyData?: Pregnancy;
    pregnancyWellBeing: PregnancyWellBeing[];
    onEditPress: (mood: PregnancyWellBeing) => void;
    onDeletePress: (id: Ulid) => void;
    pregnancyWellBeingData: PregnancyWellBeing | undefined;
    deletePregncancyWellBeingId: Ulid | undefined;
    setModal: React.Dispatch<React.SetStateAction<ModalState>>;
    adjustWellBeingFilters: (filters: {
        status?: PregnancyWellBeing["status"];
        symptoms?: PregnancyWellBeing["symptoms"];
    }) => void;
    patient?: PatientMe | undefined;
    refetchBMI?: () => void;
    firstMeasuredBmi?: number | undefined;
    firstMeasuredWeight?: number | undefined;
}

const PregnancyContext = createContext<ContextValue | undefined>(undefined);

export const PregnancyProvider =
    PregnancyContext.Provider as ReactProvider<ContextValue>;

export const usePregnancy = () => useEnsureContext(PregnancyContext);
