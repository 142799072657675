import { baseApi } from "@madmedical/store";
import type {
    PatientIdRequest,
    PreOpQuestionnaire,
    SubmitQuestionnaireRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["preop"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getLatestQuestionnaire: build.query<
                PreOpQuestionnaire | null,
                PatientIdRequest
            >({
                query: ({ patientId }) => ({
                    url: `medical/patients/${patientId}/preop`,
                }),
                providesTags: ["preop"],
            }),
            submitQuestionnaire: build.mutation<
                PreOpQuestionnaire,
                PatientIdRequest & SubmitQuestionnaireRequest
            >({
                query: ({ patientId, ...body }) => ({
                    url: `medical/patients/${patientId}/preop`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["preop"],
            }),
        }),
    });

export const {
    useGetLatestQuestionnaireQuery,
    useSubmitQuestionnaireMutation,
} = api;
