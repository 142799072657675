import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const CalmMood = () => (
    <Svg width="100%" height="100%" viewBox="0 0 65 64" fill="none">
        <G clipPath="url(#clip0_316_72954)">
            <Rect x="0.299805" width="64" height="64" rx="4" fill="#FCE7C7" />
            <Path
                d="M37.0904 23.7221C37.0904 20.4376 39.7634 17.7646 43.0493 17.7646C46.3338 17.7646 49.0068 20.4369 49.0068 23.7221C49.0068 24.6677 48.2406 25.4338 47.2951 25.4338C46.3495 25.4338 45.5833 24.6677 45.5833 23.7221C45.5833 22.3246 44.4467 21.188 43.0493 21.188C41.6518 21.188 40.5139 22.3246 40.5139 23.7221C40.5139 24.6677 39.7477 25.4338 38.8021 25.4338C37.8566 25.4338 37.0904 24.667 37.0904 23.7221ZM18.1093 23.7036C18.1093 20.4191 20.7824 17.7461 24.0682 17.7461C27.3527 17.7461 30.0257 20.4185 30.0257 23.7036C30.0257 24.6492 29.2596 25.4153 28.314 25.4153C27.3684 25.4153 26.6023 24.6492 26.6023 23.7036C26.6023 22.3062 25.4657 21.1696 24.0682 21.1696C22.6701 21.1696 21.5328 22.3062 21.5328 23.7036C21.5328 24.6492 20.7666 25.4153 19.821 25.4153C18.8755 25.4153 18.1093 24.6492 18.1093 23.7036ZM17.35 39.0065C16.9145 37.9596 17.4109 36.7573 18.4592 36.3225C18.7166 36.2157 18.9837 36.165 19.2466 36.165C20.0518 36.165 20.8159 36.6409 21.1439 37.4317C23.0453 42.0096 27.7546 44.9661 33.1418 44.9661C38.4064 44.9661 43.0883 42.0075 45.0698 37.4276C45.5203 36.3862 46.7295 35.9055 47.7702 36.3568C48.811 36.808 49.2903 38.0165 48.8397 39.0579C46.2085 45.1427 40.0462 49.0743 33.1411 49.0743C26.0881 49.0743 19.8902 45.1229 17.35 39.0065Z"
                fill="#EBA46A"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_316_72954">
                <Rect x="0.299805" width="64" height="64" rx="4" fill="white" />
            </ClipPath>
        </Defs>
    </Svg>
);

export default CalmMood;
