import { useCallback } from "react";
import { useTranslation } from "@madmedical/i18n";
import type { FileToUpload } from "../form/upload/model";
import useUpload from "../form/upload/useUpload";
import DocumentUploadFormInner from "../organisms/DocumentUploadFormInner";
import ModalContent from "../molecules/ModalContent";
import Button from "../atoms/Button";
import useResponsive from "../util/useResponsive";

interface Props {
    isUploading: boolean;
    onClose: () => void;
    onSubmit: (files: FileToUpload[]) => Promise<void>;
}

const DocumentUploadModalForm = ({ onClose, onSubmit, isUploading }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { files, resetFiles, formProps } = useUpload();
    const { t } = useTranslation();
    const handleSubmitPress = useCallback(() => {
        void onSubmit(files).then(() => {
            resetFiles();
        });
    }, [files, onSubmit, resetFiles]);

    const showUploadButton =
        (isSmallScreen && files.length > 0) || !isSmallScreen;

    return (
        <ModalContent
            header={t("uploadDocument")}
            shadow="3"
            borderBottomWidth={0}
            footer={
                <>
                    {!isSmallScreen && (
                        <Button variant="outlined" onPress={onClose}>
                            {t("cancel")}
                        </Button>
                    )}
                    {showUploadButton && (
                        <Button
                            isLoading={isUploading}
                            isLoadingText={`${t("uploadFiles")}…`}
                            isDisabled={!files.length}
                            onPress={handleSubmitPress}
                            variant="primary"
                        >
                            {t("uploadFiles")}
                        </Button>
                    )}
                </>
            }
        >
            <DocumentUploadFormInner
                {...formProps}
                files={files.map(({ file: { name, size }, note }) => ({
                    name,
                    size,
                    note,
                }))}
                isUploading={isUploading}
            />
        </ModalContent>
    );
};

export default DocumentUploadModalForm;
