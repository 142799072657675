import { Box } from "native-base";
import type { EvaluationBgHighlight, Limits, TimeData } from "./types";
import {
    colors,
    timeGridLinesHorizontalTop,
    timeGridLinesVerticalDashed,
} from "./useStyles";

export default function TimeGridLines({
    limits,
    timeData,
    evaluationBgHighlight,
}: {
    limits: Limits;
    timeData: TimeData;
    evaluationBgHighlight: EvaluationBgHighlight;
}) {
    const { timeCoords } = timeData;

    const { start, end } = evaluationBgHighlight;

    const { yMax, yMin } = limits;

    const isWithinDragSelectRange = (i: number) =>
        start !== null && end !== null
            ? (i === start && i === end) ||
              (i >= Math.min(start, end) && i < Math.max(start, end))
            : false;

    return (
        <>
            {timeCoords.map(({ x }, i) => (
                <Box
                    style={timeGridLinesVerticalDashed}
                    left={`${x}px`}
                    top="4px"
                    bottom="4px"
                    width={`${
                        i < timeCoords.length - 1 ? timeCoords[i + 1].x - x : 0
                    }px`}
                    backgroundColor={
                        isWithinDragSelectRange(i)
                            ? `rgba(${colors.blue400Rgb}, 0.2)`
                            : colors.transparent
                    }
                    key={`gridlines-${i}`}
                />
            ))}

            <Box
                style={timeGridLinesHorizontalTop}
                left={0}
                right={0}
                top={`${yMax}px`}
            />

            <Box
                style={timeGridLinesHorizontalTop}
                left={0}
                right={0}
                top={`${yMin}px`}
            />

            <Box
                style={timeGridLinesHorizontalTop}
                left={0}
                right={0}
                top={`${(yMax + yMin) / 2}px`}
            />
        </>
    );
}
