import type { ITheme } from "native-base";
import type { ColorScheme } from "../base/colors";

export interface Props {
    theme: ITheme;
}

// Filled Green
const primary = ({ theme }: Props) => ({
    bg: "green.500",
    _text: {
        color: "white",
    },
    _hover: {
        bg: "green.600",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.green[200]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "green.200",
        opacity: 1,
    },
});

// Filled Red
const destructive = ({ theme }: Props) => ({
    bg: "error.400",
    _text: {
        color: "white",
    },
    _hover: {
        bg: "error.500",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.error[200]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "error.100",
        opacity: 1,
    },
});

// Filled Gray
const gray = ({ theme }: Props) => ({
    bg: "gray.75",
    _hover: {
        bg: "gray.100",
    },
    _focus: {
        style: {
            boxShadow: `${theme.colors.gray[100]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "gray.75",
        opacity: 1,
    },
});

// Ghost Green
const ghostGreen = () => ({
    bg: "green.50",
    borderWidth: 1,
    borderColor: "green.200",
    _text: {
        color: "green.500",
    },
    _hover: {
        bg: "green.50",
        _text: {
            color: "green.600",
        },
    },
    _focus: {
        _text: {
            color: "green.600",
        },
        bg: "green.50",
        borderWidth: 1,
        borderColor: "green.200",
        /* style: {
            boxShadow: `${theme.colors.green[100]} 0px 0px 0px 2px`,
        }, */
    },
    _disabled: {
        _text: {
            color: "green.200",
        },
        bg: "transparent ",
        opacity: 1,
    },
});

// Filled White
const outlined = () => ({
    bg: "white",
    borderWidth: 1,
    borderColor: "gray.200",
    /* style: {
        boxShadow: `${theme.colors.gray[200]} 0px 0px 0px 1px`,
    }, */
    _text: {
        color: "gray.800",
    },

    _hover: {
        bg: "white",
        _text: {
            color: "gray.800",
        },
        borderWidth: 1,
        borderColor: "gray.200",
        /* style: {
            boxShadow: `${theme.colors.gray[200]} 0px 0px 0px 1px`,
        }, */
    },
    _focus: {
        bg: "white",
        _text: {
            color: "gray.800",
        },
        borderWidth: 1,
        borderColor: "gray.200",
        /* style: {
            boxShadow: `${theme.colors.gray[300]} 0px 0px 0px 1px`,
        }, */
    },
    _disabled: {
        bg: "white",
        _text: {
            color: "gray.300",
        },
        opacity: 1,
        borderWidth: 1,
        borderColor: "gray.200",
        /* style: {
            boxShadow: `${theme.colors.gray[100]} 0px 0px 0px 1px`,
        }, */
    },
});

// Ghost Gray
const ghost = ({ theme }: Props) => ({
    bg: "transparent",
    _text: {
        color: "gray.800",
    },

    _hover: {
        bg: "gray.50",
        _text: {
            color: "gray.900",
        },
    },
    _focus: {
        bg: "gray.50",
        _text: {
            color: "gray.900",
        },
        style: {
            boxShadow: `${theme.colors.gray[100]} 0px 0px 0px 2px`,
        },
    },
    _disabled: {
        bg: "transparent",
        _text: {
            color: "gray.200",
        },
        opacity: 1,
    },
});

// Link Green
const link = ({ colorScheme }: { colorScheme: ColorScheme }) => ({
    bg: "transparent",
    _text: {
        color: `${colorScheme}.500`,
    },
    _hover: {
        _text: {
            color: `${colorScheme}.600`,
        },
    },
    _focus: {
        _text: {
            color: `${colorScheme}.600`,
        },
    },
    _disabled: {
        _text: {
            color: `${colorScheme}.200`,
        },
        opacity: 1,
    },
});

// Link Gray
const ghostLink = () => ({
    bg: "transparent",
    _text: {
        color: "gray.800",
    },
    _hover: {
        _text: {
            color: "gray.900",
            style: {
                textDecoration: "underline",
            },
        },
    },
    _focus: {
        _text: {
            color: "gray.900",
        },
    },
    _disabled: {
        _text: {
            color: "gray.200",
        },
        opacity: 1,
    },
});

const variants = {
    primary,
    gray,
    destructive,
    outlined,
    ghost,
    ghostGreen,
    link,
    ghostLink,
};

const sizes = {
    xs: {
        px: 3,
        py: 1.5,
        _text: {
            fontSize: "sm",
        },
    },
    sm: {
        px: 3.5,
        py: 2,
        _text: {
            fontSize: "sm",
        },
    },
    md: {
        px: 3.5,
        py: 2.5,
        _text: {
            fontSize: "sm",
        },
    },
    lg: {
        px: 4,
        py: 2.5,
        _text: {
            fontSize: "md",
        },
    },
    xl: {
        px: 4.5,
        py: 3,
        _text: {
            fontSize: "md",
        },
    },
    "2xl": {
        px: 3.5,
        py: 4,
        _text: {
            fontSize: "lg",
        },
    },
};

export default {
    variants,
    sizes,
};
