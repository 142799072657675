import { Box, Divider, HStack, Text, VStack } from "native-base";
import { formatDateTime } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import useResponsive from "../../util/useResponsive";
import { cropFilename } from "../../util/strings";
import PressableLink from "../../atoms/PressableLink";
import { useRouteNavigate } from "../../providers/routing";
import ActionButton from "../ActionButton";
import type { Document } from "../../providers/document";
import useDownloadPreview from "../../util/useDownloadPreview";

// TODO: Discriminate by withoutAction
interface Props {
    document: Document;
    onDeletePress?: (key: string) => void;
    withoutAction?: boolean;
}

const DocumentListItem = ({
    document,
    onDeletePress,
    withoutAction = false,
}: Props) => {
    const navigate = useRouteNavigate();
    const { download, isDownloading } = useDownloadPreview();
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();

    const handleViewPress = () => {
        navigate("document", { documentId: document.id });
    };

    if (isDownloading) {
        // TODO: Spinner
    }

    return (
        <PressableLink route="document" params={{ documentId: document.id }}>
            <HStack
                justifyContent="space-between"
                alignItems="center"
                bgColor="white"
                minWidth="full"
                borderBottomWidth={1}
                borderColor="gray.100"
                px={4}
                py={5}
            >
                <VStack>
                    <HStack mr={10} space={1.5} mb={1}>
                        <Text numberOfLines={1}>
                            {cropFilename(
                                document.filename,
                                isSmallScreen ? 20 : 40
                            )}
                        </Text>
                    </HStack>
                    <HStack
                        space={1}
                        divider={
                            <Divider orientation="vertical" bg="gray.100" />
                        }
                    >
                        <Text color="gray.600" fontSize="xs">
                            {formatDateTime(document.createdAt)}
                        </Text>
                    </HStack>
                </VStack>
                {!withoutAction && (
                    <Box ml="auto">
                        <ActionButton
                            actions={[
                                {
                                    text: t("view"),
                                    iconName: "documentView",
                                    onPress: handleViewPress,
                                },
                                {
                                    text: t("download"),
                                    iconName: "documentDownload",
                                    onPress: () => {
                                        void download(document);
                                    },
                                },
                                {
                                    text: t("delete"),
                                    iconName: "trashCan",
                                    onPress: () => {
                                        onDeletePress?.(document.id);
                                    },
                                },
                            ]}
                        />
                    </Box>
                )}
            </HStack>
        </PressableLink>
    );
};

export default DocumentListItem;
