import { useFormContext } from "react-hook-form";
import type { FieldError } from "react-hook-form/dist/types/errors";

/**
 * Extending react-hook-form to pre-filter via touched
 */
export default (fieldName: string) => {
    const {
        control,
        formState: { errors, dirtyFields },
    } = useFormContext();
    const formError = errors.root?.formError;

    // I don't like these type casts but can't bother right now
    const isDirty = dirtyFields[fieldName] as boolean;
    const error = errors[fieldName] as FieldError | undefined;

    return {
        control,
        isError: isDirty && (!!error || !!formError),
        error: isDirty ? error?.message : undefined,
    };
};
