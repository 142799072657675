import { FormControl as BaseFormControl, Box, HStack } from "native-base";
import type { DateTimeString } from "@madmedical/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { TextInput } from "react-native";
import { formatTime } from "@madmedical/utils";
import DateInput from "./DateInput";
import Input from "./Input";

// TODO: Add min/max

interface Props {
    value: DateTimeString;
    onChange: (value: DateTimeString) => void;
    label?: string;
    isInvalid?: boolean;
    isRequired?: boolean;
    error?: string;
}

const DateTimeInput = ({
    value,
    onChange,
    label,
    isRequired = false,
    isInvalid = false,
    error,
}: Props) => {
    const timeInputRef = useRef<TextInput>(null);
    const dateValue = useMemo(
        () => (value ? new Date(value) : new Date()),
        [value]
    );

    const handleDateChange = useCallback(
        (newValue: Date) => {
            onChange(
                new Date(
                    newValue.getFullYear(),
                    newValue.getMonth(),
                    newValue.getDate(),
                    dateValue.getHours(),
                    dateValue.getMinutes(),
                    0,
                    0
                ).toISOString()
            );
        },
        [dateValue, onChange]
    );

    const handleTimeChange = useCallback(
        (newValue: string) => {
            const hours = parseInt(newValue.slice(0, 2), 10);
            const minutes = parseInt(newValue.slice(3, 5), 10);

            onChange(
                new Date(
                    dateValue.getFullYear(),
                    dateValue.getMonth(),
                    dateValue.getDate(),
                    hours,
                    minutes,
                    0,
                    0
                ).toISOString()
            );
        },
        [dateValue, onChange]
    );

    useEffect(() => {
        const timeInput = timeInputRef.current;
        if (!timeInput) {
            return;
        }

        timeInput.setNativeProps({
            type: "time",
            pattern: "d{2}:d{2}",
        });
    }, []);

    return (
        <BaseFormControl flex={1} isInvalid={isInvalid} isRequired={isRequired}>
            {label && (
                <BaseFormControl.Label
                    fontWeight="medium"
                    isRequired={isRequired}
                    _text={{
                        color: "gray.600",
                    }}
                >
                    {label}
                </BaseFormControl.Label>
            )}
            <HStack space={2}>
                <Box flex="1">
                    <DateInput
                        isInvalid={isInvalid}
                        value={dateValue}
                        onChange={handleDateChange}
                    />
                </Box>
                <Box flex="1">
                    <Input
                        ref={timeInputRef}
                        value={formatTime(dateValue)}
                        onChange={handleTimeChange}
                        size="md"
                        isInvalid={isInvalid}
                    />
                </Box>
            </HStack>
            {error && (
                <BaseFormControl.ErrorMessage mt={1} _text={{ fontSize: "sm" }}>
                    {error}
                </BaseFormControl.ErrorMessage>
            )}
        </BaseFormControl>
    );
};

export default DateTimeInput;
