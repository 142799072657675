import { Text, VStack } from "native-base";
import { formatMeasuredAtShort } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import Tooltip from "./Tooltip";
import {
    boxShadow,
    colors,
    tooltipCoordContentBox,
    tooltipCoordText,
} from "./useStyles";
import type { MoodTooltipType, Styles } from "./types";

export default function MoodTooltip({
    tooltip = {
        valueWeight: 0,
        valueBmi: 0,
        valueCircumference: 0,
        measuredAt: new Date(),
        pregnancyWeek: 0,
        graphType: "weight",
    },
}: {
    tooltip: MoodTooltipType;
}) {
    const { t } = useTranslation();
    if (!tooltip) return null;
    const {
        valueWeight,
        valueCircumference,
        valueBmi,
        measuredAt,
        pregnancyWeek,
        graphType,
    } = tooltip;

    const styleTitle: Styles = {
        color: colors.black,
        fontSize: 16,
        fontWeight: "500",
        lineHeight: 24,
        marginBottom: 2,
    };

    return (
        <Tooltip
            tooltip={tooltip}
            styleArrow={{ borderTopColor: colors.white }}
            styleContentBox={{ ...tooltipCoordContentBox, ...boxShadow }}
        >
            {graphType == "weight" && valueWeight && (
                <VStack>
                    <Text style={tooltipCoordText}>
                        {t("measures:bodyWeight")}
                    </Text>
                    <Text style={[tooltipCoordText, styleTitle]}>
                        {valueWeight} kg
                    </Text>
                </VStack>
            )}

            {graphType == "weight" && valueBmi && (
                <VStack>
                    <Text style={tooltipCoordText}>BMI</Text>
                    <Text style={[tooltipCoordText, styleTitle]}>
                        {valueBmi} kg/m2
                    </Text>
                </VStack>
            )}

            {graphType == "circumference" && valueCircumference && (
                <VStack>
                    <Text style={[tooltipCoordText, styleTitle]}>
                        {valueCircumference} cm
                    </Text>
                </VStack>
            )}

            <Text style={tooltipCoordText}>
                {formatMeasuredAtShort(measuredAt)}, {pregnancyWeek}. hét
            </Text>
        </Tooltip>
    );
}
