import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { Ulid } from "@madmedical/utils";
import { useEnsureContext } from "@madmedical/utils";
import type { ProfileRole } from "@madmedical/medical";

export interface MeasurementComment {
    id: Ulid;
    author: {
        id: Ulid;
        role: ProfileRole;
        fullName: string;
        initials: string;
        avatarUrl?: string;
        isSelf: boolean;
    };
    comment: string;
    date: Date;
}

interface ContextValue {
    comments: MeasurementComment[];
    onDelete: (id: Ulid) => void;
}

const MeasurementCommentContext = createContext<ContextValue | undefined>(
    undefined
);

export const MeasurementCommentProvider =
    MeasurementCommentContext.Provider as ReactProvider<ContextValue>;

export const useMeasurementComments = () =>
    useEnsureContext(MeasurementCommentContext);
