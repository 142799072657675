import { Box, HStack, Text, VStack } from "native-base";
import {
    displayPeriodOfDay,
    formatDate,
    formatDateRange,
    formatTime,
    isWeb,
} from "@madmedical/utils";
import {
    MetricType,
    forHumans,
    genderForHumans,
    serviceForHumans,
    underlyingForHumans,
    underlyingForHumansDoctor,
} from "@madmedical/medical";
import type { ReactNode } from "react";
import { useTranslation } from "@madmedical/i18n";
import type { Measurement } from "../providers/pregnancy";
import ButtonLink from "../atoms/ButtonLink";
import DeviceSection from "../organisms/DeviceSection";
import ThresholdsProtocolsTable from "../organisms/ThresholdsProtocolsTable";
import { useCurrentPatient } from "../providers/currentPatient";
import MeasurementsTabs from "../organisms/MeasurementsTabs";
import { useChart } from "../providers/chart";
import Paper from "../atoms/Paper";
import StatusBadge from "../atoms/StatusBadge";
import Tag from "../atoms/Tag";
import Tooltip from "../atoms/Tooltip";
import Breadcrumb from "../molecules/Breadcrumb";
import Headline from "../molecules/Headline";
import Metrics from "../molecules/Metrics";
import SimpleListItem from "../molecules/ListItem/SimpleListItem";
import Icon from "../icons/Icon";
import DateRangePicker from "../organisms/DateRangePicker";
import useResponsive from "../util/useResponsive";
import type { PatientDoctor } from "../providers/patient";
import Button from "../atoms/Button";
import type { MeasurementComment } from "../providers/comment";
import { useMeasurementComments } from "../providers/comment";
import type { MeasurementPdfData } from "../util/pdf/downloadMeasurementData";
import downloadMeasurementData from "../util/pdf/downloadMeasurementData";

interface Props {
    metricType: MetricType;
    addCommentButton: ReactNode;
    canWriteEvaluation: boolean;
    onEditThresholdPress: () => void;
}

const PatientMeasurementDetails = ({
    metricType,
    addCommentButton,
    canWriteEvaluation,
    onEditThresholdPress,
}: Props) => {
    const { chart, alertLevel, dateRange, displayRange } = useChart();
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient<PatientDoctor>(); // TODO: Extract breadcrumbing
    const { comments } = useMeasurementComments();
    const { t } = useTranslation();
    const handleExport = () => {
        const pacientName = patient?.fullName ?? t("patient");
        const period = formatDateRange(dateRange, "long", true);
        const birthDate = patient?.birthDate
            ? formatDate(patient.birthDate)
            : "-";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const measurements = (chart as any)?.props?.data
            ?.items as Measurement[];

        const groupedMeasurements: Record<string, Measurement[]> = {};
        measurements.forEach((measurement) => {
            const date = measurement.measuredAt.split("T")[0];
            if (!groupedMeasurements[date]) {
                groupedMeasurements[date] = [];
            }
            groupedMeasurements[date].push(measurement);
        });

        const groupedComments: Record<string, MeasurementComment[]> = {};
        comments.forEach((comment) => {
            const date = comment.date.toISOString().split("T")[0];
            if (!groupedComments[date]) {
                groupedComments[date] = [];
            }
            groupedComments[date].push(comment);
        });

        const groupAll = { ...groupedMeasurements, ...groupedComments };

        const mergedData: MeasurementPdfData[] = [];
        Object.keys(groupAll).forEach((date) => {
            const measurementData: MeasurementPdfData = {
                date: formatDate(date),
                measurement: groupedMeasurements[date]
                    ? groupedMeasurements[date]?.map((measurement) => {
                          const measureTime =
                              measurement?.metric.type ===
                                  MetricType.BloodSugar &&
                              measurement?.metric?.mealSituation
                                  ? forHumans(
                                        measurement?.metric?.mealSituation
                                    )
                                  : displayPeriodOfDay(measurement.measuredAt);

                          return {
                              measuredAt: formatTime(measurement.measuredAt),
                              value: measurement.metric.displayValue,
                              measureTime,
                              alert: measurement.alertLevel !== 0,
                          };
                      })
                    : [],
                comments: groupedComments[date]
                    ? groupedComments[date]?.map((comment) => ({
                          time: formatTime(comment.date),
                          patient:
                              comment.author.role === "patient"
                                  ? comment.comment
                                  : "-",
                          doctor:
                              comment.author.role === "doctor"
                                  ? comment.comment
                                  : "-",
                      }))
                    : [],
            };
            mergedData.push(measurementData);
        });

        const measurementType = forHumans(metricType);

        downloadMeasurementData(
            pacientName,
            period,
            birthDate,
            mergedData,
            measurementType
        );
    };

    return (
        <VStack
            space={3}
            mb={{
                base: 24,
                md: 6,
            }}
        >
            <Paper>
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Link
                                route="patient"
                                params={{ patientId: patient.userId }}
                            >
                                <Breadcrumb.Text>
                                    {patient.fullName}
                                </Breadcrumb.Text>
                            </Breadcrumb.Link>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t(`measures:${forHumans(metricType)}`)}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />

                <Box w="full">
                    <MeasurementsTabs
                        metricType={metricType}
                        isPatient={false}
                    />
                </Box>

                <Headline
                    leftContent={
                        <>
                            <VStack>
                                <Box>{formatDateRange(dateRange, "long")}</Box>
                                <HStack alignItems="center">
                                    {displayRange && (
                                        <Metrics
                                            displayValue={[displayRange]}
                                            valueFontSize="5xl"
                                        />
                                    )}
                                    <Box pt={3.5}>
                                        <StatusBadge
                                            level={alertLevel}
                                            size="lg"
                                        />
                                    </Box>
                                </HStack>
                            </VStack>
                        </>
                    }
                    rightContent={<DateRangePicker />}
                />

                <Box mt={4} width="full">
                    {chart}
                    <HStack
                        space={2}
                        px={6}
                        py={4}
                        direction={isSmallScreen ? "column" : "row"}
                        width="full"
                    >
                        {canWriteEvaluation && (
                            <ButtonLink
                                route="opinion_create"
                                params={{ metricType }}
                                variant="outlined"
                                leftIconName="chartCustom"
                                width={isSmallScreen ? "full" : undefined}
                                forceIcon
                            >
                                {t("addResult")}
                            </ButtonLink>
                        )}
                        {addCommentButton}
                        {isWeb &&
                            (metricType === MetricType.BloodSugar ||
                                metricType === MetricType.BloodPressure) && (
                                <Button
                                    onPress={handleExport}
                                    variant="outlined"
                                    leftIconName="download"
                                    ml="auto"
                                    width={isSmallScreen ? "full" : "auto"}
                                    forceIcon
                                >
                                    {t("downloadData")}
                                </Button>
                            )}
                    </HStack>
                </Box>
            </Paper>

            <Paper zIndex={-1}>
                <Headline title={t("personalData")} size="xs" />
                <Box width="full">
                    <HStack px="5" direction={isSmallScreen ? "column" : "row"}>
                        <SimpleListItem
                            label={t("name")}
                            content={patient.fullName}
                            justifyContent={
                                isSmallScreen ? "space-between" : "flex-start"
                            }
                            flex="1"
                            px="0"
                            borderBottomWidth="0"
                        />
                        <SimpleListItem
                            label={t("socialSecurityNumber")}
                            content={patient.socialSecurityNumber}
                            justifyContent={
                                isSmallScreen ? "space-between" : "flex-start"
                            }
                            flex="1"
                            px="0"
                            borderBottomWidth="0"
                        />
                    </HStack>
                    <HStack px="5" direction={isSmallScreen ? "column" : "row"}>
                        {patient.birthDate && (
                            <SimpleListItem
                                label={t("dateOfBirth")}
                                content={formatDate(patient.birthDate)}
                                justifyContent={
                                    isSmallScreen
                                        ? "space-between"
                                        : "flex-start"
                                }
                                flex="1"
                                px="0"
                                borderBottomWidth="0"
                            />
                        )}
                        {patient.gender && (
                            <SimpleListItem
                                label={t("no")}
                                content={t(
                                    `pregnancy:${genderForHumans(
                                        patient.gender
                                    )}`
                                )}
                                justifyContent={
                                    isSmallScreen
                                        ? "space-between"
                                        : "flex-start"
                                }
                                flex="1"
                                px="0"
                                borderBottomWidth="0"
                            />
                        )}
                    </HStack>
                    <HStack px="5" direction={isSmallScreen ? "column" : "row"}>
                        {!!patient.representatives.length && (
                            <SimpleListItem
                                label={
                                    <HStack alignItems="center">
                                        {t("authorizedPersons")}{" "}
                                        <Tooltip
                                            label={t("authorizedPersons")}
                                            hasArrow
                                            placement="top"
                                        >
                                            <Box ml={1}>
                                                <Icon
                                                    name="information"
                                                    size={14}
                                                    fill="#768087"
                                                />
                                            </Box>
                                        </Tooltip>
                                    </HStack>
                                }
                                content={patient.representatives
                                    .map(({ fullName }) => fullName)
                                    .join(", ")}
                                justifyContent={
                                    isSmallScreen
                                        ? "space-between"
                                        : "flex-start"
                                }
                                flex="1"
                                px="0"
                            />
                        )}
                    </HStack>
                    <HStack px="5" direction={isSmallScreen ? "column" : "row"}>
                        {!!patient.services.length && (
                            <SimpleListItem
                                label={
                                    <HStack alignItems="center">
                                        {t("packages")}{" "}
                                        <Tooltip
                                            label={t("packages")}
                                            hasArrow
                                            placement="top"
                                        >
                                            <Box ml={1}>
                                                <Icon
                                                    name="information"
                                                    size={14}
                                                    fill="#768087"
                                                />
                                            </Box>
                                        </Tooltip>
                                    </HStack>
                                }
                                content={
                                    <HStack space="1" flexWrap="wrap">
                                        {patient.services.map((service) => (
                                            <Tag ml={1} key={service}>
                                                {t(
                                                    `backend:${serviceForHumans(
                                                        service
                                                    )}`
                                                ).toLowerCase()}
                                            </Tag>
                                        ))}
                                    </HStack>
                                }
                                justifyContent={
                                    isSmallScreen
                                        ? "space-between"
                                        : "flex-start"
                                }
                                flex="1"
                                px="0"
                                borderBottomWidth="0"
                            />
                        )}
                        <SimpleListItem
                            label={t("illnesses")}
                            content={
                                patient.underlyingConditions.length ? (
                                    <HStack
                                        space="1"
                                        flexWrap="wrap"
                                        alignItems="flex-start"
                                        justifyContent={{
                                            md: "flex-start",
                                            base: "flex-end",
                                        }}
                                    >
                                        {patient.underlyingConditions.map(
                                            (underlyingCondition) => (
                                                <Tag
                                                    ml={1}
                                                    key={underlyingCondition}
                                                    mb={1}
                                                >
                                                    {canWriteEvaluation // isDoctor
                                                        ? underlyingForHumansDoctor(
                                                              underlyingCondition
                                                          )
                                                        : underlyingForHumans(
                                                              underlyingCondition
                                                          )}
                                                </Tag>
                                            )
                                        )}
                                    </HStack>
                                ) : (
                                    <Text>–</Text>
                                )
                            }
                            justifyContent={
                                isSmallScreen ? "space-between" : "flex-start"
                            }
                            flex="1"
                            px="0"
                            borderBottomWidth="0"
                        />
                    </HStack>
                </Box>
            </Paper>

            <ThresholdsProtocolsTable
                title={t("measures:measureDetails")}
                onEditPress={onEditThresholdPress}
            />

            <DeviceSection />
        </VStack>
    );
};

export default PatientMeasurementDetails;
