import { MetricType } from "@madmedical/medical";
import { DashboardWidgetsWrapper, Spinner } from "@madmedical/ui";
import { useCallback, useState } from "react";
import { InsulinDashboardWidget } from "../insulin/charts/InsulinDashboardWidget";
import DashboardWidget from "./DashboardWidget";

const metricTypes = [
    MetricType.BloodOxygen,
    MetricType.BloodPressure,
    MetricType.BloodSugar,
    MetricType.BodyMassIndex,
    MetricType.BodyTemperature,
    MetricType.BodyWeight,
    MetricType.DailySteps,
    MetricType.HeartRateVariability,
    MetricType.RestingHeartRate,
    MetricType.SleepTime,
];

interface Props {
    isInsulin?: boolean;
}

const DashboardWidgets = ({ isInsulin }: Props) => {
    const [loadingMetricTypes, setLoadingMetricTypes] = useState(metricTypes);

    const handleLoadingFinished = useCallback((metricType: MetricType) => {
        setLoadingMetricTypes((prev) => prev.filter((mt) => mt != metricType));
    }, []);

    return (
        <>
            <DashboardWidgetsWrapper>
                {metricTypes.map((metricType) => (
                    <DashboardWidget
                        key={metricType}
                        metricType={metricType}
                        onLoadingFinished={handleLoadingFinished}
                    />
                ))}
                {isInsulin && <InsulinDashboardWidget />}
            </DashboardWidgetsWrapper>
            {!!loadingMetricTypes.length && <Spinner />}
        </>
    );
};

export default DashboardWidgets;
