import { _groupBy, groupByDay } from "@madmedical/utils";
import type { InsulinType } from "@madmedical/medical";
import type { InsulinAdministration } from "../model";
import adaptAdministration from "../adaptAdministration";

interface TooltipValue {
    administeredAt: Date | string;
    displayValue: string;
}

interface TooltipGroup {
    isGrouped?: boolean;
    group?: TooltipValue[];
}

interface InsulinLabel {
    tooltip: TooltipValue & TooltipGroup;
    x?: number;
    msec: number;
    yHigh: number;
    yLow: number;
    insuPos?: number;
    insulinType?: InsulinType | undefined;
}

export function transformGroups(
    input: InsulinAdministration[] | undefined,
    isSmallScreen: boolean
): InsulinLabel[] {
    if (!input) {
        return [];
    }

    const grouped = Object.values(
        groupByDay(input.map(adaptAdministration), "administeredAt")
    );

    return grouped.flatMap((item) => transformGrouped(item, isSmallScreen));
}

export function transformGrouped(
    inputArray: InsulinAdministration[],
    isSmallScreen: boolean
): InsulinLabel[] {
    const typeGrouped = _groupBy(
        (item) => item.product.type,
        inputArray.sort(
            (a, b) =>
                Date.parse(b.administeredAt.toDateString()) -
                Date.parse(a.administeredAt.toDateString())
        )
    );

    const msecOffset = 12000000;
    const divideCoefficient =
        (inputArray.length * msecOffset) / inputArray.length;
    const offsetCoefficient =
        inputArray.length === 3
            ? divideCoefficient
            : inputArray.length === 2
            ? divideCoefficient - msecOffset / inputArray.length
            : divideCoefficient + (msecOffset * 2) / inputArray.length;

    let wholeIndex = 0;

    const groupedTypes = Object.values(typeGrouped).map((types) => {
        if (inputArray.length < (isSmallScreen ? 2 : 5)) {
            let i = 0;
            const labels = [];

            while (i < types.length) {
                const input = types[i];

                const administeredAt = new Date(input.administeredAt);
                const msec = administeredAt.valueOf();
                const displayValue = `${input.units}E, ${input.product.name}`;

                const midday = new Date(input.administeredAt);
                midday.setHours(12, 0, 0, 0);

                const coefficient =
                    inputArray.length > 1
                        ? wholeIndex * msecOffset - offsetCoefficient
                        : 0;

                const insuPos = midday.valueOf() - coefficient;

                i++;
                wholeIndex++;

                labels.push({
                    tooltip: {
                        administeredAt: administeredAt.toLocaleString(),
                        displayValue: displayValue,
                        isGrouped: false,
                    },
                    insuPos,
                    msec,
                    yHigh: 70,
                    yLow: 55,
                    insulinType: input?.product?.type,
                });
            }

            return labels;
        }

        const administeredAt = new Date(types[0].administeredAt);
        administeredAt.setHours(12, 0, 0, 0);
        const msec = administeredAt.valueOf();
        const displayValue = `Grouped`;

        const group = inputArray.map((inputItem) => ({
            administeredAt: inputItem.administeredAt,
            displayValue: `${inputItem.units}E, ${inputItem.product.name}`,
        }));

        const coefficient =
            inputArray.length > 1
                ? wholeIndex * msecOffset - offsetCoefficient
                : 0;

        return [
            {
                tooltip: {
                    administeredAt: administeredAt.toLocaleString(),
                    displayValue: displayValue,
                    group: group,
                    isGrouped: true,
                },
                msec,
                insuPos: msec - coefficient,
                yHigh: 63,
                yLow: 55,
                insulinType: types[0]?.product?.type,
            },
        ];
    });

    return groupedTypes.flat();
}
