import type { ComponentProps } from "react";
import { useMemo } from "react";
import { AlertLevel } from "@madmedical/medical";
import { Text } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import Badge from "./Badge";

interface Props extends Pick<ComponentProps<typeof Badge>, "size"> {
    level: AlertLevel | null;
    hasText?: boolean;
}

const StatusBadge = ({
    level = AlertLevel.Ok,
    hasText = true,
    size = "sm",
}: Props) => {
    const { t } = useTranslation();
    const variants = useMemo(
        () =>
            ({
                [AlertLevel.Ok]: {
                    label: t("measures:ok"),
                    variant: "ghostSuccess",
                    icon: "checkmark",
                    textColor: "green.600",
                },
                [AlertLevel.Warning]: {
                    label: t("measures:warning"),
                    variant: "ghostWarning",
                    icon: "warning",
                    textColor: "warning.600",
                },
                [AlertLevel.Alert]: {
                    label: t("measures:alert"),
                    variant: "ghostError",
                    icon: "bullhorn",
                    textColor: "error.500",
                },
            } as const),
        [t]
    );

    if (!level) {
        return null;
    }

    return (
        <Badge
            size={size}
            hasIndicator={false}
            leftIconName={variants[level].icon}
            variant={variants[level].variant}
            hasText={hasText}
        >
            {hasText && (
                <Text color={variants[level].textColor} fontSize="xs">
                    {variants[level].label}
                </Text>
            )}
        </Badge>
    );
};

export default StatusBadge;
