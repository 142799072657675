import invariant from "tiny-invariant";
import type { Context } from "react";
import { useContext } from "react";

export default <TContextValue>(Context: Context<TContextValue | undefined>) => {
    const value = useContext(Context);

    invariant(value);

    return value;
};
