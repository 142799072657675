import { useMemo } from "react";
import type { Column } from "react-table";
import { ProviderConnectionStatus } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useRouteNavigate } from "../providers/routing";
import type { Device } from "../providers/device";
import ReactTable from "../molecules/ReactTable";
import AvatarTexts from "../molecules/AvatarTexts";
import Tag from "../atoms/Tag";
import { useDevices } from "../providers/device";
import getImage from "./DeviceItem/getImage";

const DevicesTable = () => {
    const { devices } = useDevices();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();
    const columns = useMemo<Column<Device>[]>(
        () => [
            {
                Header: t("deviceName"),
                accessor: "provider",
                width: "40%",
                Cell: ({ row: { original: device } }) => (
                    <AvatarTexts
                        size="xl"
                        isCircle={false}
                        source={getImage(device.provider)}
                        label={device.deviceName}
                        subText={t(`backend:${device.manufacturer}`)}
                        status={
                            device.status === ProviderConnectionStatus.Off
                                ? undefined
                                : device.status
                        }
                    />
                ),
            },
            {
                Header: "Adatszolgáltatás típusa",
                accessor: "type",
                width: "60%",
                Cell: ({ value: type }) => (
                    <Tag>
                        {type === "ble"
                            ? t("bluetoothDevice")
                            : t("cloudDevice")}
                    </Tag>
                ),
            },
        ],
        [t]
    );

    const handlePress = ({ provider }: Device) => {
        navigate("device", { provider });
    };

    return (
        <ReactTable columns={columns} data={devices} onPress={handlePress} />
    );
};

export default DevicesTable;
