import type { Provider as ReactProvider } from "react";
import { createContext } from "react";
import type { MetricType } from "@madmedical/medical";
import { useEnsureContext } from "@madmedical/utils";

interface Threshold {
    type: string;
    metricType: MetricType;
    warningForHumans: string;
    alertForHumans: string;
}

export interface ThresholdsProtocol {
    metricType: MetricType;
    protocol: string | null;
    thresholds: Threshold[];
    isThresholdEditable: boolean;
}

interface ContextValue {
    thresholdsProtocols: ThresholdsProtocol[];
}

const ThresholdsProtocolsContext = createContext<ContextValue | undefined>(
    undefined
);

export const ThresholdsProtocolsProvider =
    ThresholdsProtocolsContext.Provider as ReactProvider<ContextValue>;

export const useThresholdsProtocols = () =>
    useEnsureContext(ThresholdsProtocolsContext);
