import type { Metric } from "@madmedical/medical";
import { useGetThresholdsForChartQuery } from "@madmedical/threshold";
import { usePatientId } from "@madmedical/user";
import { ChartWidget } from "@madmedical/ui";

export default <TMetric extends Metric>(
    metricType: TMetric["type"],
    widget: ChartWidget
) => {
    const { patientId } = usePatientId();
    const { data } = useGetThresholdsForChartQuery(
        { patientId, metricType },
        { skip: widget !== ChartWidget.Page }
    );

    return (
        data?.map(({ alertThreshold, title }) => ({
            limit: alertThreshold,
            title,
        })) ?? []
    );
};
