import type { ComponentProps } from "react";
import { useFormContext } from "@madmedical/form";
import Button from "../atoms/Button";

interface Props
    extends Pick<ComponentProps<typeof Button>, "width" | "ml" | "isLoading"> {
    isDisabled?: boolean;
    onPress: () => void;
    label: string;
}

const SubmitButton = ({
    onPress,
    isDisabled = false,
    isLoading,
    label,
    ...rest
}: Props) => {
    const {
        formState: { isSubmitting, isValid },
    } = useFormContext();

    return (
        <Button
            isLoading={isSubmitting || isLoading}
            isLoadingText={`${label}…`}
            isDisabled={!isValid || isDisabled}
            variant="primary"
            onPress={onPress}
            {...rest}
        >
            {label}
        </Button>
    );
};

export default SubmitButton;
