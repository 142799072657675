import type { ComponentProps } from "react";
import { useMemo } from "react";
import { Box, Stack, useTheme } from "native-base";
import type { AppRoutes, RouteId } from "@madmedical/routing";
import Icon from "../icons/Icon";
import StatusIndicator from "../atoms/StatusIndicator";
import type IconButton from "../atoms/IconButton";
import PressableLink from "../atoms/PressableLink";

interface NavigationProps<TRouteId extends RouteId> {
    readonly hasNotification?: boolean;
    readonly iconName: ComponentProps<typeof IconButton>["iconName"];
    route: TRouteId;
    params: AppRoutes[TRouteId];
}

interface Props {
    readonly variant?: "primary" | "dark";
    readonly navigation: NavigationProps<RouteId>[];
}

const BottomNavigation = ({ variant = "primary", navigation }: Props) => {
    const { colors } = useTheme();
    const variants = useMemo(
        () => ({
            primary: {
                bg: colors.primary[500],
                iconColor: colors.primary[200],
            },
            dark: {
                bg: colors.navBgDark,
                iconColor: colors.gray[500],
            },
        }),
        [colors]
    );

    return (
        <Stack
            rounded="full"
            space={1.5}
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            overflow="hidden"
            minHeight={15}
            bgColor={variants[variant].bg}
            px={6}
            py={3}
        >
            {navigation.map(
                ({ iconName, hasNotification, route, params }, index) =>
                    iconName && (
                        <PressableLink
                            key={`bottom-navigation-button-key-${index}`}
                            p={1.5}
                            route={route}
                            params={params}
                        >
                            {({ isActive }) => (
                                <Box position="relative">
                                    {hasNotification && (
                                        <StatusIndicator
                                            hasBorder={false}
                                            status="inactive"
                                        />
                                    )}
                                    <Icon
                                        size={24}
                                        name={iconName}
                                        fill={
                                            isActive
                                                ? colors.white
                                                : variants[variant].iconColor
                                        }
                                    />
                                </Box>
                            )}
                        </PressableLink>
                    )
            )}
        </Stack>
    );
};

export default BottomNavigation;
