import { useEffect, useState } from "react";
import { Box } from "native-base";
import CrosshairCommon from "./CrosshairCommon";
import type { GraphData } from "./types";
import { crosshairCanvas } from "./useStyles";

export default function Crosshair({
    graphData = {},
    xMsecRatio,
    offsetX,
}: {
    graphData?: GraphData;
    xMsecRatio: number;
    offsetX: number;
}) {
    const [crosshairX, setCrosshairX] = useState<number>(0);

    useEffect(() => {
        const onMouseMove = (e: MouseEvent) =>
            setCrosshairX((e.clientX ?? 0) - (offsetX ?? 0));

        window.addEventListener("mousemove", onMouseMove);

        return () => window.removeEventListener("mousemove", onMouseMove);
    }, [offsetX]);

    return (
        <Box style={crosshairCanvas}>
            <CrosshairCommon
                crosshairX={crosshairX}
                graphData={graphData}
                xMsecRatio={xMsecRatio}
            />
        </Box>
    );
}
