// Fetches details of currently viewed patient
import usePatientId from "./usePatientId";
import { useGetPatientQuery } from "./api";
import type { Patient } from "./model";

export default <TPatient extends Patient>() => {
    const { patientId, ...idRest } = usePatientId();
    const {
        data: patient,
        isLoading,
        ...queryRest
    } = useGetPatientQuery(patientId);

    return {
        patientId,
        patient: patient as TPatient | undefined,
        isLoading,
        ...idRest,
        ...queryRest,
    };
};
