import {
    InsulinAdministrationProvider,
    InsulinWidget,
    Spinner,
} from "@madmedical/ui";
import { usePatientId } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import { useGetInsulinAdministrationRangeQuery } from "../api";
import adaptAdministration from "../adaptAdministration";

export const InsulinDashboardWidget = () => {
    const { patientId } = usePatientId();
    const { data, isLoading } = useGetInsulinAdministrationRangeQuery({
        patientId,
    });

    if (isLoading) {
        return <Spinner />;
    }

    invariant(data);

    return (
        <InsulinAdministrationProvider
            value={{
                bloodSugareAverages: {},
                products: [],
                administrations: data.map(adaptAdministration),
            }}
        >
            <InsulinWidget />
        </InsulinAdministrationProvider>
    );
};
