import { Modal, PregnancyMoodModal, usePregnancy } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { useUpdatePregnancyWellBeingMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}
const EditPregnancyMoodModalForm = ({ open, onClose }: Props) => {
    const [updatePregnancyWellBeing] = useUpdatePregnancyWellBeingMutation();
    const { pregnancyWellBeingData } = usePregnancy();

    return (
        <Modal open={open} onClose={onClose} size="sm">
            <ApiFormWrapper
                defaultValues={{
                    createdAt: pregnancyWellBeingData?.createdAt ?? undefined,
                    status: pregnancyWellBeingData?.status ?? undefined,
                    symptoms: pregnancyWellBeingData?.symptoms ?? [],
                    comment: pregnancyWellBeingData?.comment ?? undefined,
                    id: pregnancyWellBeingData?.id ?? undefined,
                }}
                mutation={updatePregnancyWellBeing}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <PregnancyMoodModal
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                        isEdit={true}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditPregnancyMoodModalForm;
