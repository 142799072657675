import { Fragment, useMemo } from "react";
import { Box, Image, Pressable, Text } from "native-base";
import { colors, commentDropItem, commentDropSlot } from "./useStyles";
import type {
    CommentGroupType,
    CommentType,
    EvaluationBgHighlight,
    GraphData,
    MoodGroupType,
    TimeData,
    TooltipType,
} from "./types";

export default function CommentDrop({
    graphData = {},
    commentState,
    setCommentState,
    setTooltip,
    setMoodGroup,
    timeData,
    setEvaluationBgHighlight,
}: {
    graphData: GraphData;
    commentState: CommentGroupType;
    setCommentState: (
        callback: (state: CommentGroupType) => CommentGroupType
    ) => void;
    setTooltip: (callback: (state: TooltipType) => TooltipType) => void;
    timeData: TimeData;
    setEvaluationBgHighlight: (state: EvaluationBgHighlight) => void;
    setMoodGroup: (callback: (state: MoodGroupType) => MoodGroupType) => void;
}) {
    const { limits, comments = [], randomIdFragment = "" } = graphData;

    const { timeStamps = [], timeCoords } = timeData;

    const memoComments = useMemo(() => {
        const groupedComments = Array.from(
            { length: timeStamps.length },
            (): CommentType[] => []
        );

        comments.forEach((comment) => {
            if (comment.msec) {
                const intervalIndex =
                    timeCoords.findIndex(({ msec }) => comment.msec <= msec) -
                    1;

                groupedComments[intervalIndex]?.push(comment);
            }
        });

        const mergedComments = groupedComments.map((group, groupIndex) => {
            if (group.length === 0) return null;

            return {
                comments: groupedComments[groupIndex],
                groupAvatarUrl:
                    group.length === 1 ? group[0].user.avatarUrl : "",
                groupMonogram:
                    group.length === 1
                        ? group[0].user.monogram
                        : `${group.length}`,
                hasDoctor: group.some(({ user }) => user.type === "doctor"),
                x: timeCoords[groupIndex].x,
            };
        });

        return {
            groupedComments,
            mergedComments,
        };
    }, [comments, timeCoords, timeStamps]);

    return (
        <>
            {!!memoComments.mergedComments.length &&
                memoComments.mergedComments.map((group, i) => {
                    const id = `${randomIdFragment}-comment-group-pos-${i}`;

                    return group ? (
                        <Box
                            position="absolute"
                            zIndex={2}
                            style={{
                                left: group.x,
                                bottom: limits?.yMax,
                                transform: [
                                    { translateY: 20 },
                                    {
                                        scale:
                                            commentState.onShow &&
                                            commentState.id === id
                                                ? 1.1
                                                : 1,
                                    },
                                ],
                            }}
                            key={id}
                        >
                            <Pressable
                                onPress={() => {
                                    setCommentState((state) => ({
                                        ...group,
                                        id,
                                        onShow:
                                            state.id === id
                                                ? !state.onShow
                                                : true,
                                    }));
                                    setTooltip((state) => ({
                                        ...state,
                                        onShow: false,
                                    }));
                                    setEvaluationBgHighlight({
                                        start: null,
                                        end: null,
                                        selected: -1,
                                    });
                                    setMoodGroup((state) => ({
                                        ...state,
                                        onShow: false,
                                    }));
                                }}
                            >
                                <Box
                                    nativeID={id}
                                    style={commentDropSlot}
                                    backgroundColor={
                                        group.hasDoctor
                                            ? colors.grey800
                                            : colors.green500
                                    }
                                    key={id}
                                >
                                    {group.groupAvatarUrl ? (
                                        <Image
                                            source={{
                                                uri: group.groupAvatarUrl,
                                            }}
                                            style={commentDropItem}
                                            alt=""
                                        />
                                    ) : (
                                        <Box
                                            bg={
                                                group.hasDoctor
                                                    ? "white"
                                                    : "green.300"
                                            }
                                            borderRadius="full"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text
                                                style={commentDropItem}
                                                color={
                                                    group.hasDoctor
                                                        ? "gray.800"
                                                        : "white"
                                                }
                                            >
                                                {group.groupMonogram}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            </Pressable>
                        </Box>
                    ) : (
                        <Fragment key={id}></Fragment>
                    );
                })}
        </>
    );
}
