import type { ReactElement } from "react";
import { Box } from "native-base";

interface StyleType {
    [key: string]: { [key: string]: string | number };
}

const DropdownForComponents = ({
    triggerChild,
    dropdownChildren,
    isOpen,
    propStyles = {},
}: {
    triggerChild: ReactElement;
    dropdownChildren: ReactElement[];
    isOpen: boolean;
    propStyles?: StyleType;
}) => {
    const ownStyles = {
        relative: {
            flexBasis: 256,
            flexShrink: 0,
            flexGrow: 0,
            width: "100%",
        },
        absolute: {
            top: 0,
            right: 0,
            left: 0,
            rowGap: 4,
        },
    };

    return (
        <Box
            position="relative"
            style={{ ...ownStyles.relative, ...propStyles.relative }}
        >
            <Box
                position="absolute"
                display="flex"
                zIndex={1}
                style={{ ...ownStyles.absolute, ...propStyles.absolute }}
            >
                {triggerChild}

                {isOpen && (
                    <Box style={propStyles.dropdown}>{dropdownChildren}</Box>
                )}
            </Box>
        </Box>
    );
};

export default DropdownForComponents;
