import { Box, ScrollView, Stack, Text, VStack } from "native-base";
import type { MetricType } from "@madmedical/medical";
import { UnderlyingCondition, forHumans } from "@madmedical/medical";
import type { ReactNode } from "react";
import { isNative } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useThresholdsProtocols } from "../../providers/thresholdsProtocol";
import DeviceSection from "../../organisms/DeviceSection";
import useResponsive from "../../util/useResponsive";
import Breadcrumb from "../../molecules/Breadcrumb";
import Headline from "../../molecules/Headline";
import MeasurementsTabs from "../../organisms/MeasurementsTabs";
import SimpleCard from "../../atoms/SimpleCard";
import MeasurementChartCard from "../../molecules/MeasurementChartCard";
import { useCurrentPatient } from "../../providers/currentPatient";

interface Props {
    metricType: MetricType;
    addCommentButton: ReactNode;
    onManualPress: (() => void) | null;
}

const MeasurementType = ({
    metricType,
    addCommentButton,
    onManualPress,
}: Props) => {
    const { isSmallScreen } = useResponsive();
    const { thresholdsProtocols } = useThresholdsProtocols();
    const { patient } = useCurrentPatient();

    // Assuming first and only record is for this metric type
    const protocol = thresholdsProtocols.length
        ? thresholdsProtocols[0].protocol
        : null;
    const { t } = useTranslation();

    const insulinUser =
        patient?.underlyingConditions?.includes(UnderlyingCondition.Insulin) &&
        patient?.packages?.some((p) => p.type === "diabetes");

    return (
        <ScrollView nestedScrollEnabled>
            <Headline
                minHeight={12}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                leftContent={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Breadcrumb.Link route="dashboard" params={{}}>
                                {t("health")}
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Breadcrumb.Text>
                                {t(`measures:${forHumans(metricType)}`)}
                            </Breadcrumb.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />

            <MeasurementsTabs
                metricType={metricType}
                isPatient
                insulinUser={insulinUser}
            />

            <VStack space={4}>
                <MeasurementChartCard
                    addCommentButton={addCommentButton}
                    onManualPress={onManualPress}
                />
                {protocol && (
                    <Stack
                        space={isSmallScreen ? 0 : 4}
                        direction={isSmallScreen ? "column" : "row"}
                        p={isSmallScreen ? 4 : 0}
                        pt="0"
                        mb={0}
                    >
                        <SimpleCard title={t("measures:measureProtocol")}>
                            <Box>
                                <Text fontSize="xs" color="gray.600">
                                    {t(`backend:${protocol}`)}
                                </Text>
                            </Box>
                        </SimpleCard>
                    </Stack>
                )}

                <DeviceSection />
            </VStack>
            <Box w={1} h={isNative ? "70px" : "40px"} />
        </ScrollView>
    );
};

export default MeasurementType;
