import { useState } from "react";
import { Box, FlatList, ScrollView } from "native-base";
import { isWeb } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../providers/currentPatient";
import Button from "../atoms/Button";
import Headline from "../molecules/Headline";
import ListHeader from "../molecules/ListHeader";
import Paper from "../atoms/Paper";
import DocumentsTableEvaluation from "../organisms/DocumentsTableEvaluation";
import SearchInput from "../molecules/Form/SearchInput";
import DocumentListItemEvaluation from "../molecules/ListItem/DocumentListItemEvaluation";
import RoutableTabs from "../molecules/RoutableTabs";
import Breadcrumb from "../molecules/Breadcrumb";
import { useDocuments } from "../providers/document";
import useResponsive from "../util/useResponsive";
import DocumentListEvaluationDesktopFilter from "../organisms/filters/desktop/DocumentListEvaluationDesktopFilter";
import EmptyState from "../atoms/EmptyState";

interface Props {
    readonly info?: string;
    readonly onUploadPress: () => void;
    readonly onEndReached: () => unknown;
}

const DocumentListEvaluation = ({ onUploadPress, onEndReached }: Props) => {
    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    const {
        patient: { userId: patientId },
    } = useCurrentPatient();
    const { documents, onSearchChange, stats } = useDocuments("evaluations");
    const [search, setSearch] = useState("");

    const handleSearchChange = (search: string) => {
        setSearch(search);
        onSearchChange?.(search);
    };

    return (
        <ScrollView>
            <Paper>
                {isSmallScreen && isWeb && (
                    <Headline
                        minHeight={12}
                        borderBottomWidth={1}
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        leftContent={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Link
                                        route="patient"
                                        params={{ patientId }}
                                    >
                                        {t("profile")}
                                    </Breadcrumb.Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Breadcrumb.Text>
                                        {`"${t("documents")}"`}
                                    </Breadcrumb.Text>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    />
                )}
                <Headline
                    minHeight={12}
                    title={t("documents")}
                    rightContent={
                        isSmallScreen ? (
                            <Button variant="outlined" onPress={onUploadPress}>
                                {t("newUpload")}
                            </Button>
                        ) : null
                    }
                />
                <Box
                    pl={isSmallScreen ? 2 : 6}
                    pr={isSmallScreen ? 2 : 6}
                    pb={4}
                    pt={4}
                    flexDir="row"
                    w={"100%"}
                    justifyContent="space-between"
                    borderColor="gray.100"
                    borderBottomWidth={1}
                    borderStyle="solid"
                    bgColor="white"
                >
                    <RoutableTabs
                        tabs={[
                            {
                                route: "documents_opinion",
                                params: {},
                                title: t("attachedToResult"),
                                badge: stats.totalWithOpinionCount
                                    ? stats.totalWithOpinionCount
                                    : undefined,
                            },
                            {
                                route: "documents_general",
                                params: {},
                                title: t("general"),
                                badge: stats.totalWithoutOpinionCount
                                    ? stats.totalWithoutOpinionCount
                                    : undefined,
                            },
                        ]}
                    />
                    {!isSmallScreen && (
                        <Button
                            leftIconName="documentAdd"
                            variant="outlined"
                            onPress={onUploadPress}
                        >
                            {t("newUpload")}
                        </Button>
                    )}
                </Box>
                <Headline
                    minHeight={12}
                    borderBottomWidth={0}
                    leftContent={
                        isSmallScreen ? (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        ) : (
                            <DocumentListEvaluationDesktopFilter />
                        )
                    }
                    rightContent={
                        isSmallScreen ? (
                            <Button
                                variant="outlined"
                                size="xs"
                                onPress={() => null}
                                leftIconName="filter"
                            >
                                {t("filter")}
                            </Button>
                        ) : (
                            <SearchInput
                                ml="auto"
                                size="sm"
                                value={search}
                                onChangeText={handleSearchChange}
                            />
                        )
                    }
                />
                {isSmallScreen ? (
                    documents.length ? (
                        <>
                            <ListHeader pt={8} title={t("document")} />
                            <FlatList
                                minWidth="full"
                                showsVerticalScrollIndicator={false}
                                data={documents}
                                renderItem={({ item }) => (
                                    <DocumentListItemEvaluation
                                        document={item}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                                overflow="hidden"
                                onEndReached={onEndReached}
                            />
                        </>
                    ) : (
                        <EmptyState
                            title={t("measures:noData")}
                            message={t("noResultsAfterFilter")}
                            my={4}
                            mx={10}
                        />
                    )
                ) : (
                    <Box px={6} w={"100%"} pb={1}>
                        <DocumentsTableEvaluation onEndReached={onEndReached} />
                    </Box>
                )}
            </Paper>
            <Box w={1} h={140} />
        </ScrollView>
    );
};

export default DocumentListEvaluation;
