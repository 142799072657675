import { extendTheme } from "native-base";
import base from "./base";
import components from "./components";

const theme = extendTheme({
    ...base,
    components,
});

type CustomTheme = typeof theme;

declare module "native-base" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ICustomTheme extends CustomTheme {}
}

declare module "styled-components" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface DefaultTheme extends CustomTheme {}
}

export default theme;
