import type { ComponentProps, ReactNode } from "react";
import { useMemo } from "react";
import { Box, Pressable, Text, useTheme } from "native-base";
import Icon from "../icons/Icon";

type IconName = ComponentProps<typeof Icon>["name"];

interface Props
    extends Pick<
        ComponentProps<typeof Box>,
        | "m"
        | "ml"
        | "mr"
        | "mt"
        | "mb"
        | "mx"
        | "my"
        | "p"
        | "pl"
        | "pr"
        | "pt"
        | "pb"
        | "px"
        | "py"
    > {
    children: ReactNode;
    hasClose?: boolean;
    iconName?: IconName;
    onPress?: () => void;
    variant?:
        | "ghost"
        | "primary"
        | "error"
        | "warning"
        | "light"
        | "info"
        | "violet"
        | "orange"
        | "lime"
        | "blue"
        | "dark";
    size?: "sm" | "md" | "lg" | "xl";
    h?: number;
}

const Tag = ({
    children,
    variant = "ghost",
    size = "sm",
    h,
    iconName,
    hasClose,
    onPress = () => undefined,
    ...rest
}: Props) => {
    const { colors } = useTheme();

    const variants = useMemo(
        () => ({
            ghost: {
                borderColor: "gray.100",
                bgColor: "gray.50",
            },
            primary: {
                borderColor: "green.100",
                bgColor: "green.50",
            },
            error: {
                borderColor: "rose.100",
                bgColor: "rose.50",
            },
            warning: {
                borderColor: "yellow.200",
                bgColor: "yellow.50",
            },
            light: {
                borderColor: "gray.100",
                bgColor: "white",
            },
            info: {
                borderColor: "blue.100",
                bgColor: "blue.50",
            },
            violet: {
                borderColor: "violet.100",
                bgColor: "violet.50",
            },
            orange: {
                borderColor: "orange.100",
                bgColor: "orange.50",
            },
            lime: {
                borderColor: "lime.200",
                bgColor: "lime.50",
            },
            blue: {
                borderColor: "blue.200",
                bgColor: "blue.50",
            },
            dark: {
                borderColor: "gray.500",
                bgColor: "gray.50",
            },
        }),
        []
    );

    const sizes = useMemo(
        () => ({
            sm: {
                px: 1,
                py: 0.5,
                minHeight: 5,
            },
            md: {
                px: 1.5,
                py: 0.5,
                minHeight: 5.5,
            },
            lg: {
                px: 2,
                py: 1,
                minHeight: 6,
            },
            xl: {
                px: 2,
                py: 1,
                minHeight: 7,
            },
        }),
        []
    );

    const fontVariants = useMemo(
        () => ({
            ghost: "gray.800",
            primary: "green.800",
            error: "rose.800",
            warning: "yellow.800",
            light: "gray.800",
            info: "blue.800",
            violet: "violet.800",
            orange: "orange.800",
            lime: "lime.800",
            blue: "blue.800",
            dark: "gray.900",
        }),
        []
    );

    const fontSizes = useMemo(
        () => ({
            sm: "xs",
            md: "sm",
            lg: "sm",
            xl: "md",
        }),
        []
    );

    const iconSizes = useMemo(
        () => ({
            sm: 11,
            md: 11,
            lg: 12,
            xl: 12,
        }),
        []
    );

    const iconVariants = useMemo(
        () => ({
            ghost: colors.gray[500],
            primary: colors.green[500],
            error: colors.rose[500],
            warning: colors.yellow[600],
            light: colors.gray[400],
            info: colors.blue[500],
            violet: colors.violet[500],
            orange: colors.orange[500],
            lime: colors.lime[600],
            blue: colors.blue[600],
            dark: colors.gray[400],
        }),
        [colors]
    );

    return (
        <Box
            flexDirection="row"
            alignItems="center"
            borderRadius="sm"
            borderStyle="solid"
            borderWidth={1}
            h={h}
            {...variants[variant]}
            {...sizes[size]}
            {...rest}
        >
            {iconName && (
                <Box mr={1}>
                    <Icon
                        name={iconName}
                        fill={iconVariants[variant]}
                        size={iconSizes[size]}
                    />
                </Box>
            )}
            <Text
                lineHeight={fontSizes[size]}
                fontSize={fontSizes[size]}
                color={fontVariants[variant]}
                isTruncated
                numberOfLines={4}
                maxWidth={40}
            >
                {children}
            </Text>
            {hasClose && (
                <Box ml={1}>
                    <Pressable onPress={onPress}>
                        <Icon
                            name={"close"}
                            fill={iconVariants[variant]}
                            size={iconSizes[size]}
                        />
                    </Pressable>
                </Box>
            )}
        </Box>
    );
};

export default Tag;
