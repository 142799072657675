// check https://github.com/GeekyAnts/NativeBase/blob/master/src/theme/components/alert.ts
import type { ComponentProps } from "react";
import type Alert from "../../molecules/Alert";

const solid = ({ status }: ComponentProps<typeof Alert>) => ({
    _light: {
        borderWidth: 1,
        bg: status && `${status}.25`,
        borderColor: status && `${status}.200`,
        borderRadius: "lg",
    },
});

const variants = {
    solid,
};

export default {
    defaultProps: {
        colorScheme: "default",
        variant: "solid",
    },
    variants,
};
