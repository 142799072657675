import { adaptDocument } from "@madmedical/document";
import { rangeToDate } from "@madmedical/utils";
import type { Opinion } from "./model";

export default ({ dateRange, documents, ...rest }: Opinion) =>
    ({
        dateRange: rangeToDate(dateRange),
        documents: documents.map(adaptDocument),
        documentCount: documents.length,
        ...rest,
    } as const);
