import { Box, Divider, HStack, Pressable, Text, VStack } from "native-base";
import { formatDateRange, isWeb } from "@madmedical/utils";
import { forHumans, riskForHumans } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useCurrentPatient } from "../providers/currentPatient";
import PredictionsTable from "../organisms/PredictionsTable";
import type { Prediction } from "../providers/prediction";
import { usePredictions } from "../providers/prediction";
import Headline from "../molecules/Headline";
import useResponsive from "../util/useResponsive";
import Breadcrumb from "../molecules/Breadcrumb";
import Badge from "../atoms/Badge";
import Tag from "../atoms/Tag";
import { useRouteNavigate } from "../providers/routing";

interface Props {
    readonly onEndReached: () => unknown;
}

const PredictionList = ({ onEndReached }: Props) => {
    const { predictions, pagination } = usePredictions();
    const { isSmallScreen } = useResponsive();
    const { patient } = useCurrentPatient();
    const { t } = useTranslation();
    const navigate = useRouteNavigate();

    const handleRowPress = ({ id }: Prediction) => {
        navigate("prediction", { predictionId: id });
    };

    return (
        <>
            <Box
                borderRadius={!isSmallScreen ? "lg" : undefined}
                borderColor="gray.100"
                borderWidth={1}
                borderStyle="solid"
                bgColor="white"
            >
                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    borderTopRadius={!isSmallScreen ? "lg" : undefined}
                    leftContent={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link route="patients" params={{}}>
                                    {t("patients")}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Link
                                    route="patient"
                                    params={{ patientId: patient.userId }}
                                >
                                    {patient.fullName}
                                </Breadcrumb.Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Breadcrumb.Text>
                                    {t("predictions")}
                                </Breadcrumb.Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />

                <Headline
                    minHeight={12}
                    borderBottomWidth={1}
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    title={t("predictions")}
                    accessory={
                        pagination.totalCount ? (
                            <Badge hasIndicator={false} variant="ghost">
                                {pagination.totalCount}
                            </Badge>
                        ) : undefined
                    }
                />

                {isWeb && !isSmallScreen && (
                    <Box
                        px={{
                            base: 4,
                            md: 6,
                        }}
                        py={4}
                    >
                        <PredictionsTable onEndReached={onEndReached} />
                    </Box>
                )}
            </Box>

            {!isWeb ||
                (isSmallScreen && (
                    <Box>
                        <Text
                            fontSize="xs"
                            textTransform="uppercase"
                            color="black"
                            mb="3"
                            mt="8"
                            px="4"
                        >
                            {t("predictions")}
                        </Text>
                        <Box bgColor="white">
                            <VStack divider={<Divider />}>
                                {predictions.map((prediction) => (
                                    <Pressable
                                        key={prediction.id}
                                        onPress={() =>
                                            handleRowPress(prediction)
                                        }
                                    >
                                        <VStack
                                            backgroundColor="white"
                                            px="4"
                                            py="5"
                                            space="1"
                                        >
                                            <HStack space="1" flexWrap="wrap">
                                                {prediction.trends.map(
                                                    ({ metricType }) => (
                                                        <Tag
                                                            key={metricType}
                                                            variant="ghost"
                                                        >
                                                            {forHumans(
                                                                metricType
                                                            )}
                                                        </Tag>
                                                    )
                                                )}
                                            </HStack>

                                            <HStack space="1" flexWrap="wrap">
                                                <Text
                                                    fontSize="xs"
                                                    color="gray.600"
                                                >
                                                    {formatDateRange(
                                                        prediction.dateRange,
                                                        "short"
                                                    )}
                                                </Text>
                                                <Divider orientation="vertical" />
                                                <Text
                                                    fontSize="xs"
                                                    color="gray.600"
                                                >
                                                    {prediction.risks
                                                        .map((risk) =>
                                                            riskForHumans(risk)
                                                        )
                                                        .join(", ")}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Pressable>
                                ))}
                            </VStack>
                        </Box>
                    </Box>
                ))}
        </>
    );
};

export default PredictionList;
